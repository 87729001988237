/*
 * Renders a group of checkboxes that can be used to select
 * multiple values from a set of options.
 *
 * The corresponding component when rendering the selected
 * values is PropertyGroup.
 *
 */

import Tippy from '@tippy.js/react';
import React from 'react';
import { arrayOf, node, shape, string, func, oneOf } from 'prop-types';
import classNames from 'classnames';
import { FieldArray } from 'react-final-form-arrays';
import { ValidationError } from '../../components';

import css from './FieldTiledSelector.css';
import { Field } from 'react-final-form';

export const FIELD_TYPE_CHECKBOX = 'checkbox';
export const FIELD_TYPE_RADIO = 'radio';

const TileWrapper = (props) => {
  const { children, showToolTip, toolTipContent } = props;
  return showToolTip ? (
    <Tippy className={css.tooltip} content={toolTipContent}>
      {children}
    </Tippy>
  ) : (
    <>{children}</>
  );
};

const FieldTiledSelectorComponent = (props) => {
  const {
    className,
    disabledHint,
    rootClassName,
    label,
    twoColumns,
    id,
    fields,
    onIsDisabled,
    options,
    meta,
    onChange,
    type,
  } = props;

  const classes = classNames(rootClassName || css.root, className);
  const listClasses = twoColumns ? classNames(css.list, css.twoColumns) : css.list;

  const handleChange = (onChange, inputOnChange) => (event) => {
    inputOnChange(event);

    if (onChange) {
      const value = event.nativeEvent.target.value;
      const checked = event.nativeEvent.target.checked;
      onChange({ value, checked });
    }
  };

  return (
    <fieldset className={classes}>
      {label ? <legend>{label}</legend> : null}
      <ul className={listClasses}>
        {options.map((option) => {
          const fieldId = `${id}.${option.key}`;

          const fieldProps = {
            type: type === FIELD_TYPE_CHECKBOX ? 'checkbox' : 'radio',
            id: fieldId,
            name: fields.name,
            value: option.key,
          };
          const isDisabled = onIsDisabled(option);

          return (
            <li key={fieldId} className={css.item}>
              <TileWrapper showToolTip={isDisabled} toolTipContent={disabledHint}>
                <span>
                  <Field
                    {...fieldProps}
                    render={({ input: { onChange: inputOnChange, ...inputProps } }) => {
                      return (
                        <input
                          {...fieldProps}
                          {...inputProps}
                          className={css.input}
                          disabled={isDisabled}
                          name={fields.name}
                          type={type === FIELD_TYPE_CHECKBOX ? 'checkbox' : 'radio'}
                          id={fieldId}
                          value={option.key}
                          onChange={handleChange(onChange, inputOnChange)}
                        />
                      );
                    }}
                  />
                  <label
                    htmlFor={fieldId}
                    className={classNames(css.label, isDisabled ? css.disabled : null)}
                  >
                    {option.label}
                  </label>
                </span>
              </TileWrapper>
            </li>
          );
        })}
      </ul>
      <ValidationError fieldMeta={{ ...meta }} />
    </fieldset>
  );
};

FieldTiledSelectorComponent.defaultProps = {
  disabledHint: 'This option is not available',
  rootClassName: null,
  className: null,
  label: null,
  onIsDisabled: () => false,
  twoColumns: false,
  type: FIELD_TYPE_CHECKBOX,
};

FieldTiledSelectorComponent.propTypes = {
  disabledHint: string,
  rootClassName: string,
  className: string,
  id: string.isRequired,
  label: node,
  options: arrayOf(
    shape({
      key: string.isRequired,
      label: node.isRequired,
    })
  ).isRequired,
  onChange: func,
  onIsDisabled: func,
  type: oneOf([FIELD_TYPE_CHECKBOX, FIELD_TYPE_RADIO]),
};

const FieldTiledSelector = (props) => (
  <FieldArray component={FieldTiledSelectorComponent} {...props} />
);

// Name and component are required fields for FieldArray.
// Component-prop we define in this file, name needs to be passed in
FieldTiledSelector.propTypes = {
  name: string.isRequired,
};

export default FieldTiledSelector;
