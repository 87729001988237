import React, { Children, Component } from 'react';
import css from './SectionThreeColumns.css';
import { arrayOf, node } from 'prop-types';

class SectionThreeColumns extends Component {
  render() {
    const children = Children.toArray(this.props.children || null).slice(0, 3);

    const columns = Children.map(children, (child, index) => (
      <div className={css.column} key={index}>
        {child}
      </div>
    ));

    return <div className={css.section}>{columns}</div>;
  }
}

SectionThreeColumns.propTypes = {
  children: arrayOf(node).isRequired,
};

export default SectionThreeColumns;
