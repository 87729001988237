import React, { useState } from 'react';
import classNames from 'classnames';
import css from '../LandingPage.css';
import { FormattedMessage } from 'react-intl';
import { SecondaryButton } from '../../../components';

import GmPromoImageURL from './gm-promo-image.png';
import GmPromoImageURL_webp from './gm-promo-image.webp';
import GmTabImageSustainableEvents from './good-marketing-tabs-sustainable-events.png';
import GmTabImageSustainableEvents_webp from './good-marketing-tabs-sustainable-events.webp';
import GmTabImageGiftingURL from './good-marketing-tabs-gifting.png';
import GmTabImageGiftingURL_webp from './good-marketing-tabs-gifting.webp';
import GmTabImagePurposeURL from './good-marketing-tabs-purpose.png';
import GmTabImagePurposeURL_webp from './good-marketing-tabs-purpose.webp';

import useMediaQuery from '../../../util/useMediaQuery';

const GM_PROMO_TABS = {
  SUSTAINABLE_EVENTS: 0,
  GIFTING: 1,
  PURPOSE: 2,
};

const SustainableEventsTab = () => {
  return (
    <div className={css['feature-block-2022-tab-content']}>
      <picture>
        <source srcSet={GmTabImageSustainableEvents_webp} type="image/webp" />
        <img src={GmTabImageSustainableEvents} aria-hidden="true" width="464" height="348" />
      </picture>
      <h4>
        <FormattedMessage id="LandingPage.feature.goodMarketing.tab.sustainableEvents.title" />
      </h4>
      <p>
        <FormattedMessage id="LandingPage.feature.goodMarketing.tab.sustainableEvents.subtext" />
      </p>
      <a href="https://company.givsly.com/marketing/">
        <FormattedMessage id="LandingPage.feature.goodMarketing.tab.sustainableEvents.link" />
        <svg
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M6 12L10 8L6 4"
            stroke="#1C1A34"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
            fill="none"
          />
        </svg>
      </a>
    </div>
  );
};

const GiftingTab = () => {
  return (
    <div className={css['feature-block-2022-tab-content']}>
      <picture>
        <source srcSet={GmTabImageGiftingURL_webp} type="image/webp" />
        <img src={GmTabImageGiftingURL} aria-hidden="true" width="464" height="348" />
      </picture>
      <h4>
        <FormattedMessage id="LandingPage.feature.goodMarketing.tab.gifting.title" />
      </h4>
      <p>
        <FormattedMessage id="LandingPage.feature.goodMarketing.tab.gifting.subtext" />
      </p>
      <a href="https://company.givsly.com/marketing/">
        <FormattedMessage id="LandingPage.feature.goodMarketing.tab.gifting.link" />
        <svg
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M6 12L10 8L6 4"
            stroke="#1C1A34"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
            fill="none"
          />
        </svg>
      </a>
    </div>
  );
};

const PurposeTab = () => {
  return (
    <div className={css['feature-block-2022-tab-content']}>
      <picture>
        <source srcSet={GmTabImagePurposeURL_webp} type="image/webp" />
        <img src={GmTabImagePurposeURL} aria-hidden="true" width="464" height="348" />
      </picture>
      <h4>
        <FormattedMessage id="LandingPage.feature.goodMarketing.tab.purpose.title" />
      </h4>
      <p>
        <FormattedMessage id="LandingPage.feature.goodMarketing.tab.purpose.subtext" />
      </p>
      <a href="https://company.givsly.com/marketing/">
        <FormattedMessage id="LandingPage.feature.goodMarketing.tab.purpose.link" />
        <svg
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M6 12L10 8L6 4"
            stroke="#1C1A34"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
            fill="none"
          />
        </svg>
      </a>
    </div>
  );
};

const ActiveTab = ({ tab }) => {
  switch (tab) {
    case GM_PROMO_TABS.SUSTAINABLE_EVENTS:
      return <SustainableEventsTab />;
    case GM_PROMO_TABS.GIFTING:
      return <GiftingTab />;
    case GM_PROMO_TABS.PURPOSE:
      return <PurposeTab />;
    default:
      return null;
  }
};

const LandingPageFeatureGoodMarketing = () => {
  const [activeTab, setActiveTab] = useState(GM_PROMO_TABS.SUSTAINABLE_EVENTS);
  const showTabs = useMediaQuery('(min-width: 768px)');

  return (
    <div className={css['feature-block-2022']}>
      <div className={css['feature-block-2022-row']}>
        <div className={css['feature-block-2022-item']}>
          <picture>
            <source srcSet={GmPromoImageURL_webp} type="image/webp" />
            <img src={GmPromoImageURL} aria-hidden="true" width="528" height="528" />
          </picture>
        </div>
        <div className={css['feature-block-2022-item']}>
          <h2>
            <FormattedMessage id="LandingPage.feature.goodMarketing.title" />
          </h2>
          <p>
            <FormattedMessage id="LandingPage.feature.goodMarketing.subtext" />
          </p>
          <a
            href="https://company.givsly.com/marketing/"
            target="_blank" rel="noopener noreferrer"
            className={css['section-2022-link']}
          >
            <SecondaryButton className={css['section-2022-link-button']}>
              <FormattedMessage id="LandingPage.feature.goodMarketing.link" />
            </SecondaryButton>
          </a>
        </div>
      </div>
      {showTabs && (
        <div className={css['feature-block-2022-row']}>
          <div className={css['feature-block-2022-item']}>
            <h3>
              <FormattedMessage id="LandingPage.feature.goodMarketing.tabs.title" />
            </h3>
            <ul className={css['feature-block-2022-tabs']}>
              <li
                className={classNames({
                  [css['feature-block-2022-tab']]: true,
                  [css['feature-block-2022-tab-active']]:
                    activeTab === GM_PROMO_TABS.SUSTAINABLE_EVENTS,
                })}
              >
                <button
                  type="button"
                  onClick={() => setActiveTab(GM_PROMO_TABS.SUSTAINABLE_EVENTS)}
                >
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g clipPath="url(#clip0_1357_2007)">
                      <g clipPath="url(#clip1_1357_2007)">
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M24 0V9.6C24 11.8365 22.4704 13.7158 20.4002 14.2487L20.4 21.5142L23.4 22.8V24H15V22.8L18 21.5142L18.0004 14.2489C15.9299 13.7162 14.4 11.8367 14.4 9.6V0H24ZM16.8 4.2C16.4686 4.2 16.2 4.46863 16.2 4.8V9.6L16.2028 9.73013C16.2709 11.3266 17.5867 12.6 19.2 12.6C19.5314 12.6 19.8 12.3314 19.8 12C19.8 11.6686 19.5314 11.4 19.2 11.4L19.0942 11.3969C18.1494 11.3422 17.4 10.5586 17.4 9.6V4.8L17.396 4.73003C17.3613 4.43162 17.1077 4.2 16.8 4.2ZM7.2 0V3.6H9.6C10.9255 3.6 12 4.67452 12 6C12 7.32548 10.9255 8.4 9.6 8.4H7.2V9.6H9.6C10.9255 9.6 12 10.6745 12 12C12 13.3255 10.9255 14.4 9.6 14.4H7.2V24H4.8V14.4H2.4C1.07452 14.4 0 13.3255 0 12C0 10.6745 1.07452 9.6 2.4 9.6H4.8V8.4H2.4C1.07452 8.4 0 7.32548 0 6C0 4.67452 1.07452 3.6 2.4 3.6H4.8V0H7.2Z"
                          fill="currentColor"
                        />
                      </g>
                    </g>
                    <defs>
                      <clipPath id="clip0_1357_2007">
                        <rect width="24" height="24" fill="white" />
                      </clipPath>
                      <clipPath id="clip1_1357_2007">
                        <rect width="24" height="24" fill="white" />
                      </clipPath>
                    </defs>
                  </svg>
                  <FormattedMessage id="LandingPage.feature.goodMarketing.tab.sustainableEvents.label" />
                </button>
              </li>
              <li
                className={classNames({
                  [css['feature-block-2022-tab']]: true,
                  [css['feature-block-2022-tab-active']]: activeTab === GM_PROMO_TABS.GIFTING,
                })}
              >
                <button type="button" onClick={() => setActiveTab(GM_PROMO_TABS.GIFTING)}>
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g clipPath="url(#clip0_1357_2016)">
                      <g clipPath="url(#clip1_1357_2016)">
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M22.2 14.4V21.6C22.2 22.9255 21.1255 24 19.8 24H13.2V14.4H22.2ZM10.8 24H4.2C2.87452 24 1.8 22.9255 1.8 21.6V14.4H10.8V24ZM15 0C17.3196 0 19.2 1.8804 19.2 4.2C19.2 4.84433 19.0549 5.45478 18.7956 6.00044L21.6 6C22.9255 6 24 7.07452 24 8.4V9.6C24 10.9255 22.9255 12 21.6 12H13.2V6H10.8V12H2.4C1.07452 12 0 10.9255 0 9.6V8.4C0 7.07452 1.07452 6 2.4 6L5.20439 6.00044C4.94509 5.45478 4.8 4.84433 4.8 4.2C4.8 1.8804 6.6804 0 9 0C10.1754 0 11.238 0.482839 12.0004 1.26099C12.762 0.482839 13.8246 0 15 0ZM9 2.4C8.00589 2.4 7.2 3.20589 7.2 4.2C7.2 5.15861 7.94935 5.9422 8.89424 5.99694L9 6H10.8V4.2C10.8 3.2769 10.1051 2.51609 9.20992 2.41211L9.10576 2.40306L9 2.4ZM15 2.4L14.8942 2.40306L14.7901 2.41211C13.8949 2.51609 13.2 3.2769 13.2 4.2V6H15L15.1058 5.99694C16.0506 5.9422 16.8 5.15861 16.8 4.2C16.8 3.20589 15.9941 2.4 15 2.4Z"
                          fill="currentColor"
                        />
                      </g>
                    </g>
                    <defs>
                      <clipPath id="clip0_1357_2016">
                        <rect width="24" height="24" fill="white" />
                      </clipPath>
                      <clipPath id="clip1_1357_2016">
                        <rect width="24" height="24" fill="white" />
                      </clipPath>
                    </defs>
                  </svg>
                  <FormattedMessage id="LandingPage.feature.goodMarketing.tab.gifting.label" />
                </button>
              </li>
              <li
                className={classNames({
                  [css['feature-block-2022-tab']]: true,
                  [css['feature-block-2022-tab-active']]: activeTab === GM_PROMO_TABS.PURPOSE,
                })}
              >
                <button type="button" onClick={() => setActiveTab(GM_PROMO_TABS.PURPOSE)}>
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <rect width="24" height="24" fill="white" />
                    <g clipPath="url(#clip0_1357_2024)">
                      <path
                        d="M14.8 22.2C14.7415 22.7427 14.4367 23.1783 13.8856 23.507C13.3344 23.8357 12.6372 24 11.7937 24C10.942 24 10.2426 23.8357 9.69562 23.507C9.14864 23.1783 8.8501 22.7427 8.8 22.2H14.8ZM15.4 19.2C15.7314 19.2 16 19.4686 16 19.8V20.4C16 20.7314 15.7314 21 15.4 21H8.2C7.86863 21 7.6 20.7314 7.6 20.4V19.8C7.6 19.4686 7.86863 19.2 8.2 19.2H15.4ZM11.7947 0C12.9636 0 14.0268 0.179338 14.9844 0.538015C15.942 0.896692 16.7659 1.41381 17.4559 2.08938C18.146 2.76495 18.6758 3.56936 19.0455 4.50261C19.4152 5.43587 19.6 6.47359 19.6 7.61579C19.6 8.4794 19.4803 9.24202 19.2409 9.90366C19.0015 10.5653 18.7075 11.1608 18.359 11.6901C18.0104 12.2194 17.6601 12.7104 17.3081 13.1631C16.956 13.6158 16.662 14.0563 16.4261 14.4846C16.1903 14.9129 16.0723 15.3674 16.0723 15.8479V17.3941C16.0723 17.5752 16.016 17.7214 15.9033 17.8329C15.7907 17.9443 15.6428 18 15.4597 18H8.14028C7.95017 18 7.80054 17.9443 7.6914 17.8329C7.58226 17.7214 7.52769 17.5752 7.52769 17.3941V15.8479C7.52769 15.3674 7.40799 14.9129 7.16859 14.4846C6.92918 14.0563 6.63521 13.6158 6.28666 13.1631C5.93812 12.7104 5.58781 12.2194 5.23575 11.6901C4.88368 11.1608 4.58971 10.5653 4.35383 9.90366C4.11794 9.24202 4 8.4794 4 7.61579C4 6.47359 4.18483 5.43587 4.5545 4.50261C4.92417 3.56936 5.45403 2.76495 6.14408 2.08938C6.83412 1.41381 7.65619 0.896692 8.61029 0.538015C9.56439 0.179338 10.6259 0 11.7947 0ZM11.8 2.4C8.81766 2.4 6.4 4.81766 6.4 7.8C6.4 8.13137 6.66863 8.4 7 8.4C7.33137 8.4 7.6 8.13137 7.6 7.8C7.6 5.4804 9.4804 3.6 11.8 3.6C12.1314 3.6 12.4 3.33137 12.4 3C12.4 2.66863 12.1314 2.4 11.8 2.4Z"
                        fill="currentColor"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_1357_2024">
                        <rect width="15.6" height="24" fill="white" transform="translate(4)" />
                      </clipPath>
                    </defs>
                  </svg>

                  <FormattedMessage id="LandingPage.feature.goodMarketing.tab.purpose.label" />
                </button>
              </li>
            </ul>
          </div>

          <div className={css['feature-block-2022-item']}>
            <ActiveTab tab={activeTab} />
          </div>
        </div>
      )}
      {!showTabs && (
        <div className={css['feature-block-2022-row']}>
          <div className={css['feature-block-2022-item']}>
            <h3>
              <FormattedMessage id="LandingPage.feature.goodMarketing.tabs.title" />
            </h3>
          </div>
          <div className={css['feature-block-2022-item']}>
            <SustainableEventsTab />
            <GiftingTab />
            <PurposeTab />
          </div>
        </div>
      )}
    </div>
  );
};

export default LandingPageFeatureGoodMarketing;
