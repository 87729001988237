import React from 'react';
import css from './CalendarIntegrationContent.css';
import { Button, ExternalLink } from '../index';
import uuid from 'uuid/v4';
import classNames from 'classnames';

class CalendarIntegrationContent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      buttonReadyState: false,
    };
    this.copyToCalendarUrlToClipboard = this.copyToCalendarUrlToClipboard.bind(this);
    this.fieldUuid = uuid();
  }

  copyToCalendarUrlToClipboard() {
    const element = document.getElementById(`calendarIntegrationUrl-${this.fieldUuid}`);
    element.select();
    element.setSelectionRange(0, 99999);
    document.execCommand('copy');
    element.setSelectionRange(0, 0);
    this.setState({
      buttonReadyState: true,
    });

    setTimeout(() => {
      this.setState({
        buttonReadyState: false,
      });
    }, 1500);
  }

  render() {
    const { ensuredCurrentUser } = this.props;
    const { calendarToken } = ensuredCurrentUser.attributes.profile.privateData;
    const feedUrl = ensuredCurrentUser.id
      ? `https://${process.env.REACT_APP_GIVSLY_CALENDAR_HOST}/feed?u=${ensuredCurrentUser.id.uuid}&ct=${calendarToken}`
      : null;

    return (
      <>
        <p className={css.description}>
          Keep track of your Givsly events easily and make them visible on your own work- or
          personal calendar by subscribing to your very own Givsly calendar feed. Copy the calendar
          feed URL below, go to your own calendar to add this link. You will only need to do this
          once for all events.
        </p>
        <div className={css.calendarIntegration}>
          <input
            className={css.urlField}
            id={`calendarIntegrationUrl-${this.fieldUuid}`}
            onClick={this.copyToCalendarUrlToClipboard}
            readOnly
            value={feedUrl}
          />
          <Button
            className={classNames(
              css.copyButton,
              this.state.buttonReadyState ? css.copyButtonCopied : null
            )}
            disabled={!!this.state.buttonReadyState}
            onClick={this.copyToCalendarUrlToClipboard}
            ready={this.state.buttonReadyState}
            type="button"
          >
            Copy url
          </Button>
        </div>
        <p className={css.description}>
          For specific instructions on how to add your calendar feed to popular calendars see e.g.
        </p>
        <ol className={css.instructions}>
          <li className={css.instruction}>
            <ExternalLink
              className={css.externalLink}
              href="https://support.google.com/calendar/answer/37100?co=GENIE.Platform%3DDesktop&hl=en&oco=1"
            >
              Google Calendar
            </ExternalLink>
          </li>
          <li className={css.instruction}>
            <ExternalLink
              className={css.externalLink}
              href="https://support.office.com/en-us/article/import-or-subscribe-to-a-calendar-in-outlook-on-the-web-503ffaf6-7b86-44fe-8dd6-8099d95f38df"
            >
              Microsoft Outlook
            </ExternalLink>
          </li>
          <li className={css.instruction}>
            <ExternalLink
              className={css.externalLink}
              href="https://support.apple.com/guide/calendar/subscribe-to-calendars-icl1022/mac"
            >
              Apple Calendar
            </ExternalLink>
          </li>
        </ol>
      </>
    );
  }
}

export default CalendarIntegrationContent;
