import React from 'react';
import { arrayOf, bool, func, shape, string } from 'prop-types';
import SectionBackground from '../../../../components/sections/SectionBackground/SectionBackground';
import EventLandingPageButton from '../EventLandingPageButton/EventLandingPageButton';
import css from './EventLandingPageTextBlock.css';
import { ExternalLink, IconExternalLink } from '../../../../components';

export const EventLandingPageTextBlock = ({
  buttons,
  className,
  eventKey,
  isAuthenticated,
  links,
  title,
  text,
  trackEvent,
}) => {
  const buttonElemements =
    buttons &&
    buttons.map((button, index) => (
      <EventLandingPageButton
        isAuthenticated={isAuthenticated}
        button={button}
        eventKey={eventKey}
        key={index}
        buttonType={index === 0 ? 'primary' : 'inline-link'}
        className={css.button}
        trackEvent={(params) => trackEvent({ ...params, label: 'Text block' })}
      />
    ));

  const linkElements =
    links &&
    links.map((link, index) => {
      const isExternal = link.url.startsWith('http://') || link.url.startsWith('https://');

      return (
        <ExternalLink
          href={link.url}
          key={index}
          className={css.link}
          onClick={() => trackEvent({ action: 'Click', label: 'Text block', value: link.text })}
        >
          {link.text} {isExternal && <IconExternalLink className={css.externalLinkIcon} />}
        </ExternalLink>
      );
    });

  return (
    <div className={className ? className : css.section}>
      <h2 className={css.title}>{title}</h2>
      <p className={css.text}>{text}</p>
      {buttonElemements}
      {linkElements}
    </div>
  );
};

EventLandingPageTextBlock.propTypes = {
  buttons: arrayOf(
    shape({
      loggedIn: shape({
        label: string.isRequired,
        name: string.isRequired,
      }),
      notLoggedIn: shape({
        label: string.isRequired,
        name: string.isRequired,
      }),
    })
  ),
  classnames: string,
  eventKey: string.isRequired,
  isAuthenticated: bool.isRequired,
  links: arrayOf(
    shape({
      url: string.isRequired,
      text: string.isRequired,
    })
  ),
  title: string.isRequired,
  text: string.isRequired,
  trackEvent: func.isRequired,
};

export const EventLandingPageTextBlockWithBackground = ({ background, ...props }) => (
  <SectionBackground background={background} className={css.background}>
    <EventLandingPageTextBlock className={css.sectionWithBackground} {...props} />
  </SectionBackground>
);

EventLandingPageTextBlockWithBackground.propTypes = {
  ...EventLandingPageTextBlock.propTypes,
  background: string.isRequired,
};

export default EventLandingPageTextBlock;
