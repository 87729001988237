import React from 'react';
import {
  Footer,
  LayoutSingleColumn,
  LayoutWrapperFooter,
  LayoutWrapperMain,
  LayoutWrapperTopbar,
  NamedLink,
  Page,
  PrimaryButton,
  SecondaryButton,
} from '../../components';
import { MEETINGS_GONE_ID } from '../FaqPage/FaqPage';
import TopbarContainer from '../TopbarContainer/TopbarContainer';
import css from './MeetingSunsetPage.css';

const MeetingSunsetPage = ({ history, location, match }) => (
  <Page
    title="Connect with prospects and clients using Givsly Donation Offers!"
    scrollingDisabled={false}
  >
    <LayoutSingleColumn>
      <LayoutWrapperTopbar>
        <TopbarContainer
          currentPage="MeetingSunsetPage"
          history={history}
          location={location}
          match={match}
        />
      </LayoutWrapperTopbar>
      <LayoutWrapperMain>
        {/* <AlertBanner /> */}
        <div className={css['bg-meeting-sunset']}>
          <div className={css['card-meeting-sunset']}>
            <h1>Connect with prospects and clients using Givsly Donation Offers!</h1>
            <p>
              We have made some exciting changes to how you schedule meetings via Givsly! Starting
              in January 2023, we have removed the previous way of utilizing Givsly to book meetings
              with members who have a Givsly profile.
            </p>
            <p>
              Instead you can now send a Donation Offer over email or other messaging platforms to
              anyone that you would like to connect with, not just Givsly platform members. Donation
              Offers are part of the Givsly Good Marketing toolkit.
            </p>
            <p>
              To use Donation Offers on Givsly you need a corporate subscription to Givsly Good
              Marketing.
            </p>
            <div className={css.row}>
              <a
                href="https://form.typeform.com/to/yKoPyBT9"
                target="_blank" rel="noopener noreferrer"
                className={css['link-meeting-sunset']}
              >
                <PrimaryButton>Request a Donation Offer demo</PrimaryButton>
              </a>
              <a
                href="https://form.typeform.com/to/HDQogf6n"
                target="_blank" rel="noopener noreferrer"
                className={css['link-meeting-sunset']}
              >
                <SecondaryButton>Discuss corporate subscriptions</SecondaryButton>
              </a>
            </div>
            <p>
              <small className={css['small-meeting-sunset']}>
                Huh, what is Givsly’s{' '}
                <a href="https://company.givsly.com/marketing/" target="_blank" rel="noopener noreferrer">
                  Good Marketing
                </a>
                ? How about Givsly’s{' '}
                <a href="https://company.givsly.com/advertising/" target="_blank" rel="noopener noreferrer">
                  Good Advertising
                </a>
                ? Can I still book{' '}
                <NamedLink name="FaqPage" to={{ hash: MEETINGS_GONE_ID }}>
                  Givsly Meetings
                </NamedLink>
                ?
              </small>
            </p>
          </div>
        </div>
      </LayoutWrapperMain>
      <LayoutWrapperFooter>
        <Footer />
      </LayoutWrapperFooter>
    </LayoutSingleColumn>
  </Page>
);

export default MeetingSunsetPage;
