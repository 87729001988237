import utils from '@givsly/sharetribe-utils';
import { storableError } from '../util/errors';
import {
  createAvailabilityException,
  deleteAvailabilityException,
} from './availabilityException.duck';
import { showOwnListing, updateOwnListing } from './ownListing.duck';
import { fetchCurrentUser } from './user.duck';
import { computeRecurringTimes, convertToUtc } from '../util/reservableSlot';

const { ensureReservableSlot, formatDateTime } = utils.common;

// Action types
const CLEAR_QUEUE = 'app/reservableSlot/CLEAR_QUEUE';
const CREATE_RECURRING_RESERVABLE_SLOT_REQUEST =
  'app/reservableSlot/CREATE_RECURRING_RESERVABLE_SLOT_REQUEST';
const CREATE_RECURRING_RESERVABLE_SLOT_SUCCESS =
  'app/reservableSlot/CREATE_RECURRING_RESERVABLE_SLOT_SUCCESS';
const CREATE_RECURRING_RESERVABLE_SLOT_FAILURE =
  'app/reservableSlot/CREATE_RECURRING_RESERVABLE_SLOT_FAILURE';
const CREATE_RESERVABLE_SLOT_REQUEST = 'app/reservableSlot/CREATE_RESERVABLE_SLOT_REQUEST';
const CREATE_RESERVABLE_SLOT_SUCCESS = 'app/reservableSlot/CREATE_RESERVABLE_SLOT_SUCCESS';
const CREATE_RESERVABLE_SLOT_FAILURE = 'app/reservableSlot/CREATE_RESERVABLE_SLOT_FAILURE';
const DELETE_RESERVABLE_SLOT_REQUEST = 'app/reservableSlot/DELETE_RESERVABLE_SLOT_REQUEST';
const DELETE_RESERVABLE_SLOT_SUCCESS = 'app/reservableSlot/DELETE_RESERVABLE_SLOT_SUCCESS';
const DELETE_RESERVABLE_SLOT_FAILURE = 'app/reservableSlot/DELETE_RESERVABLE_SLOT_FAILURE';
const ENQUEUE = 'app/reservableSlot/ENQUEUE';
const INITIALIZE_RESERVABLE_SLOTS_REQUEST =
  'app/reservableSlot/INITIALIZE_RESERVABLE_SLOTS_REQUEST';
const INITIALIZE_RESERVABLE_SLOTS_SUCCESS =
  'app/reservableSlot/INITIALIZE_RESERVABLE_SLOTS_SUCCESS';
const INITIALIZE_RESERVABLE_SLOTS_FAILURE =
  'app/reservableSlot/INITIALIZE_RESERVABLE_SLOTS_FAILURE';
const PERSIST_RESERVABLE_SLOTS_REQUEST = 'app/reservableSlot/PERSIST_RESERVABLE_SLOTS_REQUEST';
const PERSIST_RESERVABLE_SLOTS_SUCCESS = 'app/reservableSlot/PERSIST_RESERVABLE_SLOTS_SUCCESS';
const PERSIST_RESERVABLE_SLOTS_FAILURE = 'app/reservableSlot/PERSIST_RESERVABLE_SLOTS_FAILURE';
const UPDATE_RESERVABLE_SLOT_REQUEST = 'app/reservableSlot/UPDATE_RESERVABLE_SLOT_REQUEST';
const UPDATE_RESERVABLE_SLOT_SUCCESS = 'app/reservableSlot/UPDATE_RESERVABLE_SLOT_SUCCESS';
const UPDATE_RESERVABLE_SLOT_FAILURE = 'app/reservableSlot/UPDATE_RESERVABLE_SLOT_FAILURE';

// Initial state
const initialState = {
  archivedReservableSlots: [],
  createRecurringReservableSlotError: null,
  createRecurringReservableSlotInProgress: false,
  createReservableSlotError: null,
  createReservableSlotInProgress: false,
  deleteReservableSlotId: null,
  deleteReservableSlotError: null,
  deleteReservableSlotInProgress: false,
  initializeError: null,
  initializeInProgress: false,
  isDirty: false,
  isInitialized: false,
  persistError: null,
  persistInProgress: false,
  queue: [],
  reservableSlots: [],
  updateReservableSlotError: null,
  updateReservableSlotInProgress: false,
};

// Reducer
export default function reducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case CLEAR_QUEUE:
      return {
        ...state,
        queue: [],
      };
    case CREATE_RECURRING_RESERVABLE_SLOT_REQUEST:
      return {
        ...state,
        createRecurringReservableSlotError: null,
        createRecurringReservableSlotInProgress: true,
      };
    case CREATE_RECURRING_RESERVABLE_SLOT_SUCCESS:
      return {
        ...state,
        createRecurringReservableSlotInProgress: false,
      };
    case CREATE_RECURRING_RESERVABLE_SLOT_FAILURE:
      return {
        ...state,
        createRecurringReservableSlotError: payload.err,
        createRecurringReservableSlotInProgress: false,
      };
    case CREATE_RESERVABLE_SLOT_REQUEST:
      return {
        ...state,
        createReservableSlotError: null,
        createReservableSlotInProgress: true,
      };
    case CREATE_RESERVABLE_SLOT_SUCCESS:
      return {
        ...state,
        createReservableSlotInProgress: false,
        isDirty: true,
        reservableSlots: [...state.reservableSlots, payload.reservableSlot],
      };
    case CREATE_RESERVABLE_SLOT_FAILURE:
      return {
        ...state,
        createReservableSlotError: payload.err,
        createReservableSlotInProgress: false,
      };
    case DELETE_RESERVABLE_SLOT_REQUEST:
      return {
        ...state,
        deleteReservableSlotId: payload.id,
        deleteReservableSlotError: null,
        deleteReservableSlotInProgress: true,
      };
    case DELETE_RESERVABLE_SLOT_SUCCESS:
      return {
        ...state,
        deleteReservableSlotInProgress: false,
        isDirty: true,
        reservableSlots: payload.reservableSlots,
      };
    case DELETE_RESERVABLE_SLOT_FAILURE:
      return {
        ...state,
        deleteReservableSlotError: payload.err,
        deleteReservableSlotInProgress: false,
      };
    case ENQUEUE:
      return {
        ...state,
        queue: [...state.queue, payload.promise],
      };
    case INITIALIZE_RESERVABLE_SLOTS_REQUEST:
      return {
        ...state,
        initializeError: null,
        initializeInProgress: true,
        isInitialized: true,
      };
    case INITIALIZE_RESERVABLE_SLOTS_SUCCESS:
      return {
        ...state,
        initializeInProgress: false,
        isInitialized: true,
        archivedReservableSlots: payload.archivedReservableSlots,
        reservableSlots: payload.reservableSlots,
      };
    case INITIALIZE_RESERVABLE_SLOTS_FAILURE:
      return {
        ...state,
        initializeError: payload.err,
        initializeInProgress: false,
      };
    case PERSIST_RESERVABLE_SLOTS_REQUEST:
      return {
        ...state,
        persistError: null,
        persistInProgress: true,
      };
    case PERSIST_RESERVABLE_SLOTS_SUCCESS:
      return {
        ...state,
        isDirty: false,
        persistInProgress: false,
      };
    case PERSIST_RESERVABLE_SLOTS_FAILURE:
      return {
        ...state,
        persistError: payload.err,
        persistInProgress: false,
      };
    case UPDATE_RESERVABLE_SLOT_REQUEST:
      return {
        ...state,
        updateReservableSlotError: null,
        updateReservableSlotInProgress: true,
      };
    case UPDATE_RESERVABLE_SLOT_SUCCESS:
      return {
        reservableSlots: [...payload.reservableSlots],
        updateReservableSlotInProgress: false,
      };
    case UPDATE_RESERVABLE_SLOT_FAILURE:
      return {
        ...state,
        updateReservableSlotError: payload.err,
        updateReservableSlotInProgress: false,
      };
    default:
      return state;
  }
}

// Action creators
const clearQueue = () => ({
  type: CLEAR_QUEUE,
});
const createRecurringReservableSlotRequest = () => ({
  type: CREATE_RECURRING_RESERVABLE_SLOT_REQUEST,
});
const createRecurringReservableSlotSuccess = () => ({
  type: CREATE_RECURRING_RESERVABLE_SLOT_SUCCESS,
});
const createRecurringReservableSlotFailure = (err) => ({
  type: CREATE_RECURRING_RESERVABLE_SLOT_FAILURE,
  payload: {
    err,
  },
});
const createReservableSlotRequest = () => ({
  type: CREATE_RESERVABLE_SLOT_REQUEST,
});
const createReservableSlotSuccess = (reservableSlot) => ({
  type: CREATE_RESERVABLE_SLOT_SUCCESS,
  payload: {
    reservableSlot,
  },
});
const createReservableSlotFailure = (err) => ({
  type: CREATE_RESERVABLE_SLOT_FAILURE,
  payload: {
    err,
  },
});
const deleteReservableSlotRequest = (id) => ({
  type: DELETE_RESERVABLE_SLOT_REQUEST,
  payload: {
    id,
  },
});
const deleteReservableSlotSuccess = (reservableSlots) => ({
  type: DELETE_RESERVABLE_SLOT_SUCCESS,
  payload: {
    reservableSlots,
  },
});
const deleteReservableSlotFailure = (err) => ({
  type: DELETE_RESERVABLE_SLOT_FAILURE,
  payload: {
    err,
  },
});
const enqueue = (promise) => ({
  type: ENQUEUE,
  payload: {
    promise,
  },
});
const initializeReservableSlotsRequest = () => ({
  type: INITIALIZE_RESERVABLE_SLOTS_REQUEST,
});
const initializeReservableSlotsSuccess = (reservableSlots, archivedReservableSlots) => ({
  type: INITIALIZE_RESERVABLE_SLOTS_SUCCESS,
  payload: {
    archivedReservableSlots,
    reservableSlots,
  },
});
const initializeReservableSlotsFailure = (err) => ({
  type: INITIALIZE_RESERVABLE_SLOTS_FAILURE,
  payload: {
    err,
  },
});
const persistReservableSlotsRequest = () => ({
  type: PERSIST_RESERVABLE_SLOTS_REQUEST,
});
const persistReservableSlotsSuccess = () => ({
  type: PERSIST_RESERVABLE_SLOTS_SUCCESS,
});
const persistReservableSlotsFailure = (err) => ({
  type: PERSIST_RESERVABLE_SLOTS_FAILURE,
  payload: {
    err,
  },
});
// const updateReservableSlotRequest = () => ({
//   type: UPDATE_RESERVABLE_SLOT_REQUEST
// });
// const updateReservableSlotSuccess = reservableSlots => ({
//   type: UPDATE_RESERVABLE_SLOT_SUCCESS,
//   payload: {
//     reservableSlots
//   }
// });
// const updateReservableSlotFailure = err => ({
//   type: UPDATE_RESERVABLE_SLOT_FAILURE,
//   payload: {
//     err
//   }
// });

// Thunks
/**
 * Creates a single reservable slot and its accompanying availability exception. While the
 * availability exception is directly persisted, the reservable slot itself is not. This must be
 * done manually by using the persist thunk in this file.
 *
 * @see persistReservableSlots
 * @param {Date} start
 * @param {Date} end
 * @param {Array} methods
 * @param {String} recurrence
 * @param {String} recurrenceParent
 * @param {String} event
 * @returns {function(*, *, *): *}
 */
export const createReservableSlot = (
  start,
  end,
  methods,
  recurrence = null,
  recurrenceParent = null,
  event
) => (dispatch, getState) => {
  dispatch(createReservableSlotRequest());

  // Ensure reservable slots have been initialized
  const { isInitialized } = getState().reservableSlot;
  const promise = new Promise((resolve) => {
    if (isInitialized) {
      return resolve();
    }

    return dispatch(initializeReservableSlots());
  })
    // @todo Check weekly limit?
    .then(async () => {
      // First fetch the listing ID, this should be available already in the user state object
      const listingId = await dispatch(getListingId());

      // Create availability exception
      const availabilityException = await dispatch(
        createAvailabilityException(listingId, start, end)
      );

      // Generate the time slot, this only creates the slot and stores it in the
      // state. At the end the new reservable slots should be persisted to the
      // listing using the persist thunk.
      const reservableSlot = ensureReservableSlot({
        id: availabilityException.id.uuid,
        end: formatDateTime(end),
        start: formatDateTime(start),
        timezone: 'UTC',
        methods,
        recurrence,
        recurrenceParent: recurrenceParent || availabilityException.id.uuid,
        event,
      });

      dispatch(createReservableSlotSuccess(reservableSlot));
      return reservableSlot;
    })
    .catch((err) => {
      // Catch any errors with the request (mainly in the creation of the availability exception. We
      // capture and store the error but still reject the promise so it can be further dealt with by
      // the implementation
      dispatch(createReservableSlotFailure(storableError(err)));
      return Promise.reject(err);
    });

  dispatch(enqueue(promise));
  return promise;
};

/**
 * Creates a recurring reservable slot. This will also automatically create all following recurring
 * reservable slots up to 365 days into the future. This is done through an internal queue. This
 * method will only return the first slot created in the series.
 *
 * @param start
 * @param end
 * @param methods
 * @param recurrence
 * @returns {function(*=, *, *): *}
 */
export const createRecurringReservableSlot = (start, end, methods, recurrence) => (dispatch) => {
  dispatch(createRecurringReservableSlotRequest());

  return dispatch(createReservableSlot(start, end, methods, recurrence))
    .then(async (reservableSlot) => {
      const recurringTimes = await computeRecurringTimes();
      // Remove the first item from the recurring series because it has already been created
      recurringTimes.shift();

      // Loop through each of the recurring times, this can be done asynchronously because this duck
      // keeps track through an internal queue.
      recurringTimes.forEach((rt) => {
        dispatch(createReservableSlot(rt.start, rt.end, methods, recurrence, reservableSlot.id));
      });

      dispatch(createRecurringReservableSlotSuccess());
      return reservableSlot;
    })
    .catch((err) => {
      dispatch(createRecurringReservableSlotFailure(storableError(err)));
      return Promise.reject(err);
    });
};

/**
 * Delete a specific reservable slot and its associated availability exception.
 *
 * @param id
 * @returns {function(*, *, *): Promise<*>}
 */
export const deleteReservableSlot = (id) => (dispatch, getState, sdk) => {
  dispatch(deleteReservableSlotRequest(id));

  const { isInitialized } = getState().reservableSlot;
  const promise = new Promise((resolve) => {
    // Ensure reservable slots have been initialized
    if (isInitialized) {
      const { reservableSlots } = getState().reservableSlot;
      return resolve(reservableSlots);
    }

    return dispatch(initializeReservableSlots());
  })
    .then((reservableSlots) => {
      // Remove the availability exception
      const reservableSlot = reservableSlots.find((r) => r.id === id);
      return dispatch(deleteAvailabilityException(reservableSlot.id)).then(() => {
        return reservableSlots;
      });
    })
    .then((reservableSlots) => {
      // Remove the actual reservable slot from the array
      const filteredReservableSlots = reservableSlots.filter((r) => r.id !== id);
      dispatch(deleteReservableSlotSuccess(filteredReservableSlots));
      return filteredReservableSlots;
    })
    .catch((err) => {
      dispatch(deleteReservableSlotFailure(storableError(err)));
      return Promise.reject(err);
    });

  dispatch(enqueue(promise));
  return promise;
};

/**
 * Initializes the reservable slots for the current user. This must be done before any changes or
 * persistence can be done.
 *
 * @see createReservableSlot
 * @see persistReservableSlots
 * @param reinitialize
 * @returns {function(*, *, *): (*)}
 */
export const initializeReservableSlots = (reinitialize = false) => (dispatch, getState, sdk) => {
  const { initializeInProgress, isInitialized } = getState().reservableSlot;

  // Only initialize if reservable slots have not yet been initialized and if there is no
  // initialization already in progress.
  if ((!!!isInitialized || reinitialize) && !!!initializeInProgress) {
    dispatch(initializeReservableSlotsRequest());
    // @todo handle reinitialization with unfinished queue
    return dispatch(showOwnListing())
      .then(async (ownListing) => {
        // Process listing data and map them to UTC
        const reservableSlots = await Promise.all(
          ownListing.attributes.publicData.reservableSlots.map((rs) => convertToUtc(rs))
        );

        // Split between archived and active
        const archiveThreshold = getArchiveThreshold();
        const activeReservableSlots = reservableSlots.filter(
          (rs) => new Date(rs.start) >= archiveThreshold
        );

        // Store and finalize
        dispatch(
          initializeReservableSlotsSuccess(
            activeReservableSlots,
            reservableSlots.filter((rs) => new Date(rs.start) < archiveThreshold)
          )
        );

        return activeReservableSlots;
      })
      .catch((err) => {
        // Catch any errors during the request, these most like would happen within the fetch
        // listing request.
        dispatch(initializeReservableSlotsFailure(storableError(err)));
        return Promise.reject(err);
      });
  }
};

/**
 * Persist any queued reservable slots to the listing. This is done manually because there are
 * situations where we want to first create multiple reservable slots, and then persist them all at
 * once. This is especially useful when using recurring slots.
 *
 * This function assumes that all (if more than a single) reservable slots have been created and
 * promises brought forward from that process have resolved.
 *
 * @returns {function(*, *, *)}
 */
export const persistReservableSlots = () => (dispatch, getState) => {
  const { isDirty, queue } = getState().reservableSlot;

  if (isDirty) {
    dispatch(persistReservableSlotsRequest());

    return Promise.all(queue)
      .then(async () => {
        // Clear the queue
        dispatch(clearQueue());

        // Update the users listing
        const { archivedReservableSlots = [], reservableSlots = [] } = getState().reservableSlot;
        const ownListing = await dispatch(
          updateOwnListing({
            publicData: {
              reservableSlots: [...archivedReservableSlots, ...reservableSlots],
            },
          })
        );

        // Return the newly stored reservable slots (for verification purposes)
        dispatch(persistReservableSlotsSuccess());
        return ownListing.attributes.publicData.reservableSlots;
      })
      .catch((err) => {
        console.log('Persist failed', err);
        dispatch(persistReservableSlotsFailure(storableError(err)));
        return Promise.reject(err);
      });
  }

  dispatch(persistReservableSlotsSuccess());
  return getState().reservableSlot.reservableSlots;
};

// @todo implement updating existing reservable slots
// export const updateReservableSlot = (id, start, end, methods, recurrenceParent = null, updateFutureSiblings = false) => (dispatch, getState) => {
//   const { isInitialized } = getState().reservableSlot;
//   const promise = new Promise(resolve => {
//     // Ensure reservable slots have been initialized
//     if (isInitialized) {
//       const { reservableSlots } = getState().reservableSlot;
//       return resolve(reservableSlots);
//     }
//
//     return dispatch(initializeReservableSlots());
//   })
//     .then(reservableSlots => {
//       const reservableSlot = reservableSlots.find(r => r.id === id);
//       if (!reservableSlot) {
//         return Promise.reject(`Reservable slot not found ${id}`);
//       }
//
//       const rootRecurrenceParent = reservableSlot.recurrenceParent;
//
//       // Update future siblings
//       if (updateFutureSiblings) {
//         const futureSiblings = reservableSlots.filter(r => r.recurrenceParent === reservableSlots.recurrenceParent);
//       }
//
//     })
//     .catch(err => {
//       dispatch(updateReservableSlotFailure(storableError(err)));
//       return Promise.reject(err);
//     });
//
//   dispatch(enqueue(promise));
//   return promise;
// };

// Helpers
/**
 * Gets just the listing ID for the current user.
 *
 * @returns {*}
 */
const getListingId = () => (dispatch) => {
  return dispatch(fetchCurrentUser({}, false)).then((user) => {
    // We only need the listingId from the user profile.
    const currentUser = utils.user.ensureCurrentUser(user);
    return currentUser.attributes.profile.publicData.listingId;
  });
};

const getArchiveThreshold = () => {
  const archiveThreshold = new Date();
  archiveThreshold.setUTCDate(1);
  archiveThreshold.setUTCHours(0, 0, 0, 0);
  return archiveThreshold;
};
