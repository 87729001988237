import { number, oneOfType } from 'prop-types';
import React from 'react';
import { useMessages } from '../../util/localization';
import { cropText } from '../../util/richText';
import { createSlug } from '../../util/urlHelpers';
import ImageFromFile from '../ImageFromFile/ImageFromFile';
import { NamedLink } from '../index';
import css from './NonprofitImpactCard.css';
import { propTypes } from '../../util/types';
import marketplaceConfig from '../../config';

const NonprofitImpactCard = (props) => {
  const getMessage = useMessages('NonprofitImpactCard');
  const { meetingPrice, nonprofit } = props;
  const { images } = nonprofit;
  const { publicData, description, title } = nonprofit.attributes;
  const { impact, organizationCategory, organizationLocation } = publicData;
  const { pictureId } = impact;

  const quantity = meetingPrice
    ? Math.ceil((meetingPrice / impact.donationSum) * parseInt(impact.impactUnits))
    : impact.impactUnits;
  const location = organizationLocation
    ? marketplaceConfig.custom.nonprofitLocations.find((l) => l.key === organizationLocation)
    : null;
  const picture = pictureId
    ? images.find((i) => {
        return (
          (typeof i.id === 'string' && i.id.startsWith(pictureId)) ||
          (typeof i.id === 'object' && i.id.uuid === pictureId)
        );
      })
    : null;
  const hasDefinedImpact =
    quantity > 0 && (impact.impactType.plural || impact.impactType.singular) && impact.description;

  return (
    <div className={css.root}>
      <div className={css.content}>
        <span className={css.name}>
          {title}
          <NamedLink
            className={css.link}
            name="ListingPage"
            params={{
              id: nonprofit.id.uuid,
              slug: createSlug(title),
            }}
            target="_blank" rel="noopener noreferrer"
          >
            <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12">
              <g fill="none" fillRule="evenodd" stroke="#FAC51D" strokeWidth="1.5">
                <path d="M8.75 8.25v3h-8v-8h3M6.25.75h5v5" />
                <path strokeLinecap="square" d="M10.25 1.75l-5 5" />
              </g>
            </svg>
          </NamedLink>
        </span>
        <span className={css.category}>{organizationCategory}</span>
        <p className={css.description}>{cropText(description, 250)}</p>
        {location ? <span className={css.location}>{location.label}</span> : null}
      </div>
      <div className={css.impact}>
        {picture && picture.file ? (
          <ImageFromFile
            aspectRatioClassName={css.pictureAspectWrapper}
            className={css.picture}
            id={picture.id}
            file={picture.file}
          />
        ) : picture ? (
          <img
            alt={''}
            className={css.picture}
            src={picture.attributes.variants['square-small2x'].url}
          />
        ) : (
          <svg
            className={css.picture}
            xmlns="http://www.w3.org/2000/svg"
            width="80"
            height="80"
            viewBox="0 0 80 80"
          >
            <g fill="none" fillRule="evenodd">
              <g>
                <g>
                  <g>
                    <g>
                      <g>
                        <g transform="translate(-904 -1892) translate(407 44) translate(85 1592) translate(0 56) translate(0 200) translate(412)">
                          <mask id="jgoa9j5lob" fill="#fff">
                            <circle id="vgmsrckp9a" cx="40" cy="40" r="40" />
                          </mask>
                          <circle fill="#1C1A34" id="vgmsrckp9a" cx="40" cy="40" r="40" />
                          <path
                            fill="#FAC51D"
                            d="M53.199 43.798l3.78 3.77-17.146 17.099-17.145-17.1 3.78-3.77 13.365 13.33L53.2 43.797zm-18.85-9.456c3.132-3.123 8.21-3.123 11.341 0 3.132 3.124 3.132 8.187 0 11.31-3.132 3.124-8.21 3.124-11.341 0-3.132-3.123-3.132-8.186 0-11.31zm6.051-8.7c5.316-5.3 13.965-5.3 19.28 0 5.316 5.301 5.316 13.927 0 19.228L55.9 41.1c3.23-3.223 3.23-8.465 0-11.688-3.231-3.222-8.488-3.222-11.72 0zm-20.414 0c5.316-5.3 13.965-5.3 19.28 0l-3.78 3.77c-3.231-3.222-8.488-3.222-11.72 0-3.23 3.223-3.23 8.465 0 11.688l-3.78 3.77c-5.315-5.301-5.315-13.927 0-19.228z"
                            mask="url(#jgoa9j5lob)"
                          />
                        </g>
                      </g>
                    </g>
                  </g>
                </g>
              </g>
            </g>
          </svg>
        )}
        {hasDefinedImpact ? (
          <>
            <span className={css.header}>{getMessage('meetingEquals')}</span>
            <span className={css.quantity}>
              {quantity} {quantity === 1 ? impact.impactType.singular : impact.impactType.plural}
            </span>
            <span className={css.impactDescription}>{impact.description}</span>
          </>
        ) : null}
      </div>
    </div>
  );
};

NonprofitImpactCard.propTypes = {
  meetingPrice: number,
  nonprofit: oneOfType([propTypes.listing, propTypes.ownListing]).isRequired,
};

NonprofitImpactCard.defaultProps = {
  meetingPrice: 10000,
};

export default NonprofitImpactCard;
