import config from '../config';
import utils from '@givsly/sharetribe-utils';
import {
  applyFeesAndDeductions as applyTransactionFeesAndDeductions,
  transferOutreachCredit,
} from '../util/credit';
import { denormalisedResponseEntities } from '../util/data';
import { storableError } from '../util/errors';
import { handleCardPayment } from './stripe.duck';

// Actions
const APPLY_FEES_DEDUCTIONS_REQUEST = 'app/payment/APPLY_FEES_DEDUCTIONS_REQUEST';
const APPLY_FEES_DEDUCTIONS_SUCCESS = 'app/payment/APPLY_FEES_DEDUCTIONS_SUCCESS';
const APPLY_FEES_DEDUCTIONS_FAILURE = 'app/payment/APPLY_FEES_DEDUCTIONS_FAILURE';
const CONFIRM_PAYMENT_REQUEST = 'app/payment/CONFIRM_PAYMENT_REQUEST';
const CONFIRM_PAYMENT_SUCCESS = 'app/payment/CONFIRM_PAYMENT_SUCCESS';
const CONFIRM_PAYMENT_FAILURE = 'app/payment/CONFIRM_PAYMENT_FAILURE';
const CREATE_PAYMENT_INTENT_REQUEST = 'app/payment/CREATE_PAYMENT_INTENT_REQUEST';
const CREATE_PAYMENT_INTENT_SUCCESS = 'app/payment/CREATE_PAYMENT_INTENT_SUCCESS';
const CREATE_PAYMENT_INTENT_FAILURE = 'app/payment/CREATE_PAYMENT_INTENT_FAILURE';
const FETCH_TRANSACTION_REQUEST = 'app/payment/FETCH_TRANSACTION_REQUEST';
const FETCH_TRANSACTION_SUCCESS = 'app/payment/FETCH_TRANSACTION_SUCCESS';
const FETCH_TRANSACTION_FAILURE = 'app/payment/FETCH_TRANSACTION_FAILURE';
const HANDLE_PAYMENT_REQUEST = 'app/payment/HANDLE_PAYMENT_REQUEST';
const HANDLE_PAYMENT_SUCCESS = 'app/payment/HANDLE_PAYMENT_SUCCESS';
const HANDLE_PAYMENT_FAILURE = 'app/payment/HANDLE_PAYMENT_FAILURE';
const INITIATE_TRANSACTION_REQUEST = 'app/payment/INITIATE_TRANSACTION_REQUEST';
const INITIATE_TRANSACTION_SUCCESS = 'app/payment/INITIATE_TRANSACTION_SUCCESS';
const INITIATE_TRANSACTION_FAILURE = 'app/payment/INITIATE_TRANSACTION_FAILURE';
const REQUIRE_PAYMENT_REQUEST = 'app/payment/REQUIRE_PAYMENT_REQUEST';
const REQUIRE_PAYMENT_SUCCESS = 'app/payment/REQUIRE_PAYMENT_SUCCESS';
const REQUIRE_PAYMENT_FAILURE = 'app/payment/REQUIRE_PAYMENT_FAILURE';
const OMIT_PAYMENT_REQUEST = 'app/payment/OMIT_PAYMENT_REQUEST';
const OMIT_PAYMENT_SUCCESS = 'app/payment/OMIT_PAYMENT_SUCCESS';
const OMIT_PAYMENT_FAILURE = 'app/payment/OMIT_PAYMENT_FAILURE';

// Initial state
const initialState = {
  applyFeesDeductionsError: null,
  applyFeesDeductionsInProgress: false,
  confirmPaymentError: null,
  confirmPaymentInProgress: false,
  createPaymentIntentError: null,
  createPaymentIntentInProgress: false,
  fetchTransactionError: null,
  fetchTransactionInProgress: false,
  initiateTransactionError: null,
  initiateTransactionInProgress: false,
  omitPaymentError: null,
  omitPaymentInProgress: false,
  paymentRequestError: null,
  paymentRequestInProgress: false,
  requirePaymentError: null,
  requirePaymentInProgress: false,
  requirePayment: true,
  transaction: null,
};

// Generic query parameters for all queries
const queryParams = {
  include: ['booking', 'provider'],
  expand: true,
};

// Reducer
export default function paymentReducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    default:
      return state;
    case APPLY_FEES_DEDUCTIONS_REQUEST:
      return {
        ...state,
        applyFeesDeductionsError: null,
        applyFeesDeductionsInProgress: true,
      };
    case APPLY_FEES_DEDUCTIONS_SUCCESS:
      return {
        ...state,
        applyFeesDeductionsInProgress: false,
      };
    case APPLY_FEES_DEDUCTIONS_FAILURE:
      return {
        ...state,
        applyFeesDeductionsError: payload,
        applyFeesDeductionsInProgress: false,
      };
    case CONFIRM_PAYMENT_REQUEST:
      return {
        ...state,
        confirmPaymentError: null,
        confirmPaymentInProgress: true,
      };
    case CONFIRM_PAYMENT_SUCCESS:
      return {
        ...state,
        confirmPaymentInProgress: false,
      };
    case CONFIRM_PAYMENT_FAILURE:
      return {
        ...state,
        confirmPaymentError: payload,
        confirmPaymentInProgress: false,
      };
    case CREATE_PAYMENT_INTENT_REQUEST:
      return {
        ...state,
        createPaymentIntentError: null,
        createPaymentIntentInProgress: true,
      };
    case CREATE_PAYMENT_INTENT_SUCCESS:
      return {
        ...state,
        createPaymentIntentInProgress: false,
        transaction: payload,
      };
    case CREATE_PAYMENT_INTENT_FAILURE:
      return {
        ...state,
        createPaymentIntentError: payload,
        createPaymentIntentInProgress: false,
      };
    case FETCH_TRANSACTION_REQUEST:
      return {
        ...state,
        fetchTransactionError: null,
        fetchTransactionInProgress: true,
      };
    case FETCH_TRANSACTION_SUCCESS:
      return {
        ...state,
        fetchTransactionInProgress: false,
      };
    case FETCH_TRANSACTION_FAILURE:
      return {
        ...state,
        fetchTransactionError: payload,
        fetchTransactionInProgress: false,
      };
    case HANDLE_PAYMENT_REQUEST:
      return {
        ...state,
        paymentRequestError: null,
        paymentRequestInProgress: true,
      };
    case HANDLE_PAYMENT_SUCCESS:
      return {
        ...state,
        paymentRequestInProgress: false,
      };
    case HANDLE_PAYMENT_FAILURE:
      return {
        ...state,
        paymentRequestError: payload,
        paymentRequestInProgress: false,
      };
    case INITIATE_TRANSACTION_REQUEST:
      return {
        ...state,
        initiateTransactionError: null,
        initiateTransactionInProgress: true,
      };
    case INITIATE_TRANSACTION_SUCCESS:
      return {
        ...state,
        initiateTransactionInProgress: false,
        transaction: payload,
      };
    case INITIATE_TRANSACTION_FAILURE:
      return {
        ...state,
        initiateTransactionError: payload,
        initiateTransactionInProgress: false,
      };
    case OMIT_PAYMENT_REQUEST:
      return {
        ...state,
        omitPaymentError: null,
        omitPaymentInProgress: true,
        requirePayment: false,
      };
    case OMIT_PAYMENT_SUCCESS:
      return {
        ...state,
        omitPaymentInProgress: false,
      };
    case OMIT_PAYMENT_FAILURE:
      return {
        ...state,
        omitPaymentError: payload,
        omitPaymentInProgress: false,
      };
    case REQUIRE_PAYMENT_REQUEST:
      return {
        ...state,
        requirePayment: true,
        requirePaymentError: null,
        requirePaymentInProgress: true,
      };
    case REQUIRE_PAYMENT_SUCCESS:
      return {
        ...state,
        requirePaymentInProgress: false,
      };
    case REQUIRE_PAYMENT_FAILURE:
      return {
        ...state,
        requirePaymentError: payload,
        requirePaymentInProgress: false,
      };
  }
}

// Action creators
const applyFeesDeductionsRequest = () => ({
  type: APPLY_FEES_DEDUCTIONS_REQUEST,
});
const applyFeesDeductionsSuccess = () => ({
  type: APPLY_FEES_DEDUCTIONS_SUCCESS,
});
const applyFeesDeductionsFailure = (err) => ({
  type: APPLY_FEES_DEDUCTIONS_FAILURE,
  payload: storableError(err),
});
const confirmPaymentRequest = () => ({
  type: CONFIRM_PAYMENT_REQUEST,
});
const confirmPaymentSuccess = () => ({
  type: CONFIRM_PAYMENT_SUCCESS,
});
const confirmPaymentFailure = (err) => ({
  type: CONFIRM_PAYMENT_FAILURE,
  payload: storableError(err),
});
const createPaymentIntentRequest = () => ({
  type: CREATE_PAYMENT_INTENT_REQUEST,
});
const createPaymentIntentSuccess = (transaction) => ({
  type: CREATE_PAYMENT_INTENT_SUCCESS,
  payload: transaction,
});
const createPaymentIntentFailure = (err) => ({
  type: CREATE_PAYMENT_INTENT_FAILURE,
  payload: storableError(err),
});
const fetchTransactionRequest = () => ({
  type: FETCH_TRANSACTION_REQUEST,
});
const fetchTransactionSuccess = () => ({
  type: FETCH_TRANSACTION_SUCCESS,
});
const fetchTransactionFailure = (err) => ({
  type: FETCH_TRANSACTION_FAILURE,
  payload: storableError(err),
});
const handlePaymentRequest = () => ({
  type: HANDLE_PAYMENT_REQUEST,
});
const handlePaymentSuccess = () => ({
  type: HANDLE_PAYMENT_SUCCESS,
});
const handlePaymentFailure = (err) => ({
  type: HANDLE_PAYMENT_FAILURE,
  payload: storableError(err),
});
const omitPaymentRequest = () => ({
  type: OMIT_PAYMENT_REQUEST,
});
const omitPaymentSuccess = () => ({
  type: OMIT_PAYMENT_SUCCESS,
});
const omitPaymentFailure = (err) => ({
  type: OMIT_PAYMENT_FAILURE,
  payload: storableError(err),
});
const requirePaymentRequest = () => ({
  type: REQUIRE_PAYMENT_REQUEST,
});
const requirePaymentSuccess = () => ({
  type: REQUIRE_PAYMENT_SUCCESS,
});
const requirePaymentFailure = (err) => ({
  type: REQUIRE_PAYMENT_FAILURE,
  payload: storableError(err),
});

// Thunks
const fetchTransaction = (id) => (dispatch, getState, sdk) => {
  dispatch(fetchTransactionRequest());

  return sdk.transactions
    .show({ id })
    .then((sdkResponse) => {
      dispatch(fetchTransactionSuccess());
      const entities = denormalisedResponseEntities(sdkResponse);
      return utils.transaction.ensure(entities[0]);
    })
    .catch((err) => {
      dispatch(fetchTransactionFailure(err));
      Promise.reject('Fetch transaction failed');
    });
};

/**
 * Initiates the payment transaction process with Stripe payment included. This will first apply
 * all fees and deductions, and follow by creating and confirming the payment intent. If this is
 * successful it will return an ensured transaction in the preauthorized-with-payment state.
 *
 * @param ensuredTransaction
 * @param stripe
 * @param stripePaymentMethodId
 * @param {string} processAlias
 * @returns {function(*, *, *): *}
 */
const requirePayment = (
  ensuredTransaction,
  stripe,
  stripePaymentMethodId,
  processAlias = config.paymentBookingProcessAlias
) => (dispatch, getState, sdk) => {
  dispatch(requirePaymentRequest());

  const {
    bookingEnd,
    bookingStart,
    lineItems,
    listingId,
    protectedData,
  } = ensuredTransaction.attributes;
  const bodyParams = {
    processAlias,
    transition: utils.transaction.TRANSITION_REQUIRE_PAYMENT,
    params: {
      bookingEnd,
      bookingStart,
      lineItems,
      listingId,
      protectedData,
    },
  };

  return sdk.transactions
    .initiate(bodyParams, queryParams)
    .then((sdkResponse) => {
      dispatch(requirePaymentSuccess());
      const entities = denormalisedResponseEntities(sdkResponse);
      return utils.transaction.ensure(entities[0]);
    })
    .then((ensuredTransaction) => {
      return dispatch(applyFeesDeductions(ensuredTransaction));
    })
    .then((ensuredTransaction) => {
      return dispatch(createPaymentIntent(ensuredTransaction, stripePaymentMethodId));
    })
    .then(async (ensuredTransaction) => {
      const {
        stripePaymentIntentClientSecret,
      } = ensuredTransaction.attributes.protectedData.stripePaymentIntents.default;
      return await dispatch(
        handleCardPayment({
          stripe,
          stripePaymentIntentClientSecret,
        })
      ).then(() => ensuredTransaction);
    })
    .then((ensuredTransaction) => {
      return dispatch(confirmPayment(ensuredTransaction));
    })
    .catch((err) => {
      dispatch(requirePaymentFailure(err));
      return Promise.reject('Require payment failed');
    });
};

/**
 * Transitions the transaction to the preauthorized-with-payment state by confirming the payment
 * intent with Stripe. This might have already been successful when the payment intent was created,
 * this mechanism provides a fallback for when the bank requires the user to verify the transaction
 * manually somehow.
 *
 * @param ensuredTransaction
 * @returns {function(*, *, *): *}
 */
const confirmPayment = (ensuredTransaction) => (dispatch, getState, sdk) => {
  dispatch(confirmPaymentRequest());

  const bodyParams = {
    id: ensuredTransaction.id.uuid,
    transition: utils.transaction.TRANSITION_CONFIRM_PAYMENT,
    params: {},
  };

  return sdk.transactions
    .transition(bodyParams, queryParams)
    .then((sdkResponse) => {
      dispatch(confirmPaymentSuccess());
      const entities = denormalisedResponseEntities(sdkResponse);
      return utils.transaction.ensure(entities[0]);
    })
    .catch((err) => {
      dispatch(confirmPaymentFailure(err));
      return Promise.reject('Payment confirmation failed');
    });
};

/**
 * Initiates the transaction process with the Stripe payment process omitted. If this is successful
 * the ensured transaction that is returned is in the preauthorized-without-payment state.
 *
 * @param ensuredTransaction
 * @param stripe
 * @param stripePaymentMethodId
 * @param {string} processAlias
 * @returns {function(*, *, *): *}
 */
const omitPayment = (
  ensuredTransaction,
  stripe,
  stripePaymentMethodId,
  processAlias = config.paymentBookingProcessAlias
) => (dispatch, getState, sdk) => {
  dispatch(omitPaymentRequest());

  const {
    bookingEnd,
    bookingStart,
    lineItems,
    listingId,
    protectedData,
  } = ensuredTransaction.attributes;
  const bodyParams = {
    processAlias,
    transition: utils.transaction.TRANSITION_OMIT_PAYMENT,
    params: {
      bookingEnd,
      bookingStart,
      lineItems,
      listingId,
      protectedData,
    },
  };

  return sdk.transactions
    .initiate(bodyParams, queryParams)
    .then((sdkResponse) => {
      dispatch(omitPaymentSuccess());
      const entities = denormalisedResponseEntities(sdkResponse);
      return utils.transaction.ensure(entities[0]);
    })
    .then((ensuredTransaction) => {
      return dispatch(applyFeesDeductions(ensuredTransaction));
    })
    .catch((err) => {
      dispatch(omitPaymentFailure(err));
      return Promise.reject('Omit payment failed');
    });
};

/**
 * Applies fees and deductions to the transaction and verifies its integrity. This is handled by the
 * Givsly transaction API. If this is successful the ensured transaction that is returned is either
 * in the preauthorized-with-payment or preauthorized-without-payment state. This call is payment
 * agnostic, as the transaction API will check if payment is required or not.
 *
 * @param ensuredTransaction
 * @returns {function(*, *, *): Promise<*>}
 */
const applyFeesDeductions = (ensuredTransaction) => (dispatch, getState) => {
  dispatch(applyFeesDeductionsRequest());

  const { deductFee: deductFees = false } = ensuredTransaction.attributes.protectedData;
  const applyCredit = utils.transaction.getAppliedCredit(ensuredTransaction);

  const ensuredCurrentUser = utils.user.ensureCurrentUser(getState().user.currentUser);
  return applyTransactionFeesAndDeductions(
    ensuredCurrentUser,
    ensuredTransaction.id.uuid,
    deductFees,
    applyCredit
  )
    .then(() => {
      dispatch(applyFeesDeductionsSuccess());
      return dispatch(fetchTransaction(ensuredTransaction.id));
    })
    .catch((err) => {
      dispatch(applyFeesDeductionsFailure(err));
      return Promise.reject('Apply transaction fees and deductions failed');
    });
};

export const createPaymentIntent = (ensuredTransaction, stripePaymentMethodId) => (
  dispatch,
  getState,
  sdk
) => {
  dispatch(createPaymentIntentRequest());

  const bodyParams = {
    id: ensuredTransaction.id.uuid,
    transition: utils.transaction.TRANSITION_CREATE_PAYMENT_INTENT,
    params: {
      paymentMethod: stripePaymentMethodId,
    },
  };
  return sdk.transactions
    .transition(bodyParams, queryParams)
    .then((sdkResponse) => {
      const entities = denormalisedResponseEntities(sdkResponse);
      const transaction = utils.transaction.ensure(entities[0]);
      dispatch(createPaymentIntentSuccess(transaction));
      return transaction;
    })
    .catch((err) => {
      // @todo log to Sentry
      dispatch(createPaymentIntentFailure(err));
      return Promise.reject('Payment intent creation failed');
    });
};

/**
 * Handles the initiation of the payment transaction, application of fees and deductions to that
 * transaction and the actual credit card payment (if required for the transaction). If this is
 * successful the returned ensured transaction will be in the preauthorized-with-payment or
 * preauthorized-without-payment state.
 *
 * @param ensuredTransaction
 * @param stripe
 * @param stripePaymentMethodId
 * @param {string} processAlias
 * @param ensuredCurrentUser
 * @returns {function(*, *, *): void}
 */
export const handlePayment = (
  ensuredTransaction,
  stripe,
  stripePaymentMethodId = null,
  processAlias = config.paymentBookingProcessAlias,
  ensuredCurrentUser = null
) => (dispatch) => {
  dispatch(handlePaymentRequest());

  const initiateTransaction = utils.transaction.requiresPayment(ensuredTransaction)
    ? requirePayment
    : omitPayment;
  return dispatch(
    initiateTransaction(ensuredTransaction, stripe, stripePaymentMethodId, processAlias)
  )
    .then((ensuredTransaction) => {
      dispatch(handlePaymentSuccess());
      return ensuredTransaction;
    })
    .then(async (ensuredTransaction) => {
      // Outreach payments require direct manual credit transfers after payment
      if (
        ensuredTransaction.attributes.processName ===
        utils.transaction.PROCESS_NAME_OUTREACH_PAYMENT
      ) {
        await transferOutreachCredit(ensuredCurrentUser, ensuredTransaction.id.uuid);
      }
      return ensuredTransaction;
    })
    .catch((reason) => {
      dispatch(handlePaymentFailure(reason));
      return Promise.reject(reason);
    });
};
