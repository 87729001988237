import { IconSpinner, NonprofitCard, PaginationLinks } from '../index';
import css from '../NonprofitSelector/NonprofitSelector.css';
import React from 'react';
import { propTypes } from '../../util/types';
import { array, bool, number, object, string } from 'prop-types';
import { FormattedMessage } from 'react-intl';

const NonprofitSelector = (props) => {
  const {
    isLoading,
    listings,
    search,
    selectionLimit,
    pageName,
    pagePathParams,
    pagination,
    onToggleNonprofit,
    selectedIdentifiers,
  } = props;

  const paginationLinks =
    pagination && pagination.totalPages > 1 ? (
      <PaginationLinks
        className={css.pagination}
        pageName={pageName}
        pagePathParams={pagePathParams}
        pageSearchParams={search}
        pagination={pagination}
        showNextPreviousControls={false}
      />
    ) : null;

  return (
    <div>
      {isLoading ? (
        <div className={css.isLoading}>
          <IconSpinner />
        </div>
      ) : null}
      {listings.length === 0 && !isLoading ? (
        <div className={css.noResults}>
          <FormattedMessage id="NonprofitSelector.noResults" />
        </div>
      ) : null}
      {listings.map((listing) => (
        <NonprofitCard
          key={listing.id.uuid}
          listing={listing}
          onToggleNonprofit={onToggleNonprofit}
          isSelected={selectedIdentifiers.indexOf(listing.id.uuid) >= 0}
          blockNewToggle={selectedIdentifiers.length === selectionLimit}
        />
      ))}
      {paginationLinks}
    </div>
  );
};

NonprofitSelector.defaultProps = {
  isLoading: false,
  listings: [],
  pagination: null,
  search: null,
  selectedIdentifiers: [],
};

NonprofitSelector.propTypes = {
  isLoading: bool,
  listings: array,
  pageName: string,
  pagePathParams: object,
  pagination: propTypes.pagination,
  search: object,
  selectedIdentifiers: array,
  selectionLimit: number.isRequired,
};

export default NonprofitSelector;
