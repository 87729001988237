import * as jwt from 'jsonwebtoken';

export const permittedHiddenFields = [
  'boardMemberNPOs',
  'companyName',
  'companyUrl',
  'eventRoles',
  'signUpSource',
  'weeklyTimeLimit',
  'isProposalDisabled',
  'activationDate',
];

export const getInitialSignUpValuesFromInvitationToken = (token) => {
  if (token) {
    const decodedToken = jwt.verify(token, process.env.REACT_APP_GIVSLY_SIGNUP_SECRET);
    return {
      ...getBaseSignUpValuesFromDecodedToken(decodedToken),
      ...getHiddenValuesFromDecodedToken(decodedToken),
    };
  }
  return {};
};

export const getBaseSignUpValuesFromDecodedToken = (decodedToken) => {
  const { firstName = '', lastName = '', email = '' } = decodedToken;
  return {
    fname: firstName,
    lname: lastName,
    email,
  };
};

export const getHiddenValuesFromDecodedToken = (decodedToken) => {
  const hiddenValues = {};
  Object.keys(decodedToken).forEach((key) => {
    if (permittedHiddenFields.indexOf(key) >= 0) {
      hiddenValues[key] = decodedToken[key];
    }
  });
  return hiddenValues;
};
