import React, { Component } from 'react';
import css from './FieldDateTimeDurationSelector.css';
import { FieldDateInput, FieldSelect, FieldTimeSelect, IconEarth } from '../../components';
import moment from 'moment';
import config from '../../config';
import { array, bool, func, instanceOf, oneOf, shape, string } from 'prop-types';
import { intlShape } from '../../util/reactIntl';
import classNames from 'classnames';
import { VALID, required } from '../../util/validators';
import FieldDateSelect from '../../components/FieldDateSelect/FieldDateSelect';

const DATE_FORMAT = 'YYYY-MM-DD';
const SLOT_SIZE = 15;
const MAX_SLOT_SIZE = 60;

export const DATE_FIELD_TYPE_CALENDAR = 'calendar';
export const DATE_FIELD_TYPE_SELECT = 'select';

class FieldDateTimeDurationSelector extends Component {
  constructor(props) {
    super(props);

    this.state = {
      disallowedDurations: [],
      disallowedTimes: [],
    };

    this.validateSelectedTime = this.validateSelectedTime.bind(this);
    this.validateSelectedDuration = this.validateSelectedDuration.bind(this);
    this.renderDay = this.renderDay.bind(this);

    this.datesWithReservableSlots = this.getDatesWithReservableSlots();
  }

  componentDidMount() {
    if (!this.props.bookingDate.date) return;
    this.updateDisallowedTimesAndDurations();
  }

  componentDidUpdate(prevProps, prevState) {
    if (!this.props.bookingDate.date) return;

    const bookingDateUpdated =
      !prevProps.bookingDate.date ||
      moment.tz(prevProps.bookingDate.date, this.props.timezone).format(DATE_FORMAT) !==
        this.selectedDate.format(DATE_FORMAT);

    if (!bookingDateUpdated) {
      return;
    }

    this.updateDisallowedTimesAndDurations();
  }

  updateDisallowedTimesAndDurations() {
    this.computeDisallowedTimes(this.selectedDate);
    this.computeDisallowedDurations(this.selectedDate);
  }

  get originalDate() {
    return this.props.originalDate || moment().tz(this.props.timezone);
  }

  get selectedDate() {
    return (
      this.props.bookingDate.date && moment.tz(this.props.bookingDate.date, this.props.timezone)
    );
  }

  getDatesWithReservableSlots() {
    const { reservableSlots, timezone } = this.props;

    if (!reservableSlots) return {};

    return reservableSlots.reduce((obj, slot) => {
      if (!slot || !slot.start) return obj;

      const slotDate = moment.tz(slot.start, timezone).format(DATE_FORMAT);
      return {
        ...obj,
        [slotDate]: slot,
      };
    }, {});
  }

  isDayBlocked = (day) => {
    return this.props.exceedsWeeklyLimit(day, 15);
  };

  handleCalendarDateChanged = (value) => {
    const { timezone } = this.props;
    this.computeDisallowedTimes(moment.tz(value.date, timezone));
  };

  handleSelectDateChanged = (value) => {
    const { timezone } = this.props;

    if (!value) return;

    this.computeDisallowedTimes(moment.tz(value, timezone));
  };

  handleTimeChanged = (value) => {
    const [hours, minutes] = value.split(':');
    this.computeDisallowedDurations(
      this.selectedDate
        ? this.selectedDate.clone().set({
            hours,
            minutes,
          })
        : null
    );
  };

  computeDisallowedDurations(useDate = null, callback = undefined) {
    const { exceedsWeeklyLimit, inEditMode, originalDuration } = this.props;
    const hours = useDate.format('HH');
    const minutes = useDate.format('mm');
    const slotTime = this.originalDate.clone();
    slotTime.set({
      hours,
      minutes,
    });
    const disallowedDurations = [];
    let slotSize = SLOT_SIZE;
    let disallowRemaining = false;

    const reduceSlotSize = slotTime.isSame(useDate, 'day') && inEditMode ? originalDuration : 0;

    while (slotSize <= MAX_SLOT_SIZE) {
      if (
        disallowRemaining ||
        (this.state.disallowedTimes.indexOf(slotTime.format('HH:mm')) >= 0 && !inEditMode) ||
        (!this.props.isEvent && exceedsWeeklyLimit(slotTime, slotSize - reduceSlotSize))
      ) {
        disallowedDurations.push(slotSize);
        // This flag prevents following durations from being accepted
        disallowRemaining = true;
      }
      slotSize += SLOT_SIZE;
      slotTime.add(SLOT_SIZE, 'minutes');
    }

    // Update the allowed durations
    this.setState(
      {
        disallowedDurations,
      },
      callback
    );
  }

  computeDisallowedTimes(useDate = null) {
    const { reservableSlots, timeSlotId, timezone, allowedDates, eventTimezone } = this.props;
    const disallowedTimes = [];

    reservableSlots
      .filter(
        (slot) =>
          moment.tz(slot.start, slot.timezone).format(DATE_FORMAT) ===
            useDate.format(DATE_FORMAT) && slot.id !== timeSlotId
      )
      .forEach((slot) => {
        const slotStart = moment.tz(slot.start, slot.timezone);
        const slotEnd = moment.tz(slot.end, slot.timezone);

        while (slotStart.isBefore(slotEnd)) {
          disallowedTimes.push(slotStart.format('HH:mm'));
          slotStart.add(SLOT_SIZE, 'minutes');
        }
      });

    // Add any times that are in the past as well as disallowed
    const now = moment().tz(timezone);
    if (useDate.format(DATE_FORMAT) === now.format(DATE_FORMAT)) {
      const today = now.clone().set({
        hours: 0,
        minutes: 0,
        seconds: 0,
      });
      now.add(config.custom.minTimeSlotDistance, 'minutes');

      while (today.isSameOrBefore(now)) {
        disallowedTimes.push(today.format('HH:mm'));
        today.add(SLOT_SIZE, 'minutes');
      }
    }

    if (allowedDates && allowedDates.startDate && allowedDates.endDate) {
      const start = moment.tz(allowedDates.startDate, eventTimezone).tz(timezone);
      const end = moment.tz(allowedDates.endDate, eventTimezone).tz(timezone);

      if (start.format(DATE_FORMAT) === useDate.format(DATE_FORMAT)) {
        const time = start.clone().set({
          hours: 0,
          minutes: 0,
          seconds: 0,
        });

        while (time.isBefore(start)) {
          disallowedTimes.push(time.format('HH:mm'));
          time.add(SLOT_SIZE, 'minutes');
        }
      }

      if (end.format(DATE_FORMAT) === useDate.format(DATE_FORMAT)) {
        const time = end.clone();

        while (time.isSameOrBefore(end.endOf('day'))) {
          disallowedTimes.push(time.format('HH:mm'));
          time.add(SLOT_SIZE, 'minutes');
        }
      }
    }

    this.setState(
      {
        disallowedTimes,
      },
      this.props.form.mutators.touch('bookingTime')
    );
  }

  validateSelectedTime = (value) => {
    const message = this.props.intl.formatMessage({
      id: 'TimeSlotForm.invalidTime',
    });
    return typeof value !== 'string' || this.state.disallowedTimes.indexOf(value) > -1
      ? message
      : VALID;
  };

  validateSelectedDuration = (value) => {
    const message = this.props.intl.formatMessage({
      id: 'TimeSlotForm.invalidDuration',
    });
    return typeof value !== 'string' || this.state.disallowedDurations.indexOf(value) > -1
      ? message
      : VALID;
  };

  renderDay = (date) => {
    const { timezone } = this.props;
    const today = moment.tz(new Date(), timezone);
    const isToday = today.isSame(date, 'd');

    const isSelectedDate = this.selectedDate && date.isSame(this.selectedDate, 'd');

    const isBlocked = today.isAfter(date, 'd') || this.isDayBlocked(date);

    const dayDate = date.format('YYYY-MM-DD');

    const hasReservableSlots = !!this.datesWithReservableSlots[dayDate];

    return (
      <div
        className={classNames(css.calendarDay, {
          [css.dayHasSlots]: hasReservableSlots,
          [css.daySelected]: isSelectedDate,
          [css.dayToday]: isToday,
          [css.dayBlocked]: isBlocked,
        })}
        id={`day-${date.format('YYYYMMDD')}`}
      >
        <span className={classNames(css.dayName)}>
          <span>{date.date()}</span>
        </span>
      </div>
    );
  };

  get dateField() {
    const { allowedDates, intl, type, timezone, eventTimezone } = this.props;

    const commonFieldProps = {
      label: intl.formatMessage({ id: 'TimeSlotForm.date' }),
      id: 'bookingDate',
    };

    if (type === DATE_FIELD_TYPE_SELECT) {
      return (
        <FieldDateSelect
          endDate={
            allowedDates &&
            allowedDates.endDate &&
            moment.tz(allowedDates.endDate, eventTimezone || timezone)
          }
          startDate={
            allowedDates &&
            allowedDates.startDate &&
            moment.tz(allowedDates.startDate, eventTimezone || timezone)
          }
          timezone={timezone}
          validate={required(intl.formatMessage({ id: 'TimeSlotForm.date.required' }))}
          name="bookingDate.date"
          showDefaultOption={true}
          defaultOptionLabel={intl.formatMessage({ id: 'TimeSlotForm.date.placeholder' })}
          defaultOptionValue={''}
          onChange={this.handleSelectDateChanged}
          {...commonFieldProps}
        />
      );
    }

    return (
      <FieldDateInput
        isDayBlocked={(day) => this.isDayBlocked(day)}
        renderDayContents={(date) => this.renderDay(date)}
        name="bookingDate"
        onChange={this.handleCalendarDateChanged}
        {...commonFieldProps}
      />
    );
  }

  render() {
    const { form, intl, onFetchTimeSlots, timezone } = this.props;

    return (
      <div className={css.container}>
        <div className={css.dateSelector}>{this.dateField}</div>
        <div className={css.timeSelector}>
          <FieldTimeSelect
            bookingDate={this.selectedDate}
            currentMonth={this.selectedDate}
            disallowedTimes={this.state.disallowedTimes}
            form={form}
            intl={intl}
            name="bookingTime"
            id="bookingTime"
            onBookingTimeChanged={this.handleTimeChanged}
            onFetchTimeSlots={onFetchTimeSlots}
            label={intl.formatMessage({ id: 'TimeSlotForm.time' })}
            selectedTime="07:00 AM"
            timezone={timezone}
            validate={this.validateSelectedTime}
          />
        </div>
        <div className={css.durationSelector}>
          <FieldSelect
            name="duration"
            id="duration"
            label={intl.formatMessage({ id: 'TimeSlotForm.duration' })}
            validate={this.validateSelectedDuration}
            disabled={!this.selectedDate}
          >
            {config.custom.timeSlotDurations.map((duration) => {
              return (
                <option
                  key={duration}
                  value={duration}
                  disabled={this.state.disallowedDurations.indexOf(duration) >= 0}
                >
                  {duration} {intl.formatMessage({ id: 'TimeSlotForm.durationOption' })}
                </option>
              );
            })}
          </FieldSelect>
        </div>
        {this.selectedDate && (
          <span className={css.timezone}>
            <IconEarth />{' '}
            <span className={css.timezoneText}>
              {timezone} (UTC {this.selectedDate.format('Z')})
            </span>
          </span>
        )}
      </div>
    );
  }
}

FieldDateTimeDurationSelector.propTypes = {
  allowedDates: shape({
    startDate: string,
    endDate: string,
  }),
  bookingDate: shape({
    date: instanceOf(Date),
  }),
  bookingTime: string,
  duration: string,
  exceedsWeeklyLimit: func.isRequired,
  eventTimezone: string,
  form: shape({
    mutators: shape({
      touch: func.isRequired,
    }).isRequired,
  }).isRequired,
  inEditMode: bool.isRequired,
  intl: intlShape.isRequired,
  isEvent: bool,
  onFetchTimeSlots: func.isRequired,
  originalDuration: string.isRequired,
  reservableSlots: array,
  timeSlotId: string,
  timezone: string.isRequired,
  type: oneOf([DATE_FIELD_TYPE_CALENDAR, DATE_FIELD_TYPE_SELECT]),
};

FieldDateTimeDurationSelector.defaultProps = {
  bookingDate: {
    date: null,
  },
  bookingTime: null,
  duration: null,
  isEvent: false,
  reservableSlots: [],
  type: DATE_FIELD_TYPE_CALENDAR,
};

export default FieldDateTimeDurationSelector;
