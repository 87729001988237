import React, { Component } from 'react';
import sharetribe from '@givsly/sharetribe-utils';
import css from '../NonprofitProfile.css';
import SectionMaybe from '../../SectionMaybe';
import SectionImages from '../../SectionImages';
import { ExternalLink } from '../../../../components';
import NonprofitSocialSectionMaybe from './NonprofitSocialSectionMaybe';
import NonprofitAddressMaybe from './NonprofitAddressMaybe';
import { businessUrlToUrl, formatEinNumber } from '../../../../util/richText';
import { propTypes } from '../../../../util/types';
import { array, bool, func, string } from 'prop-types';
import classNames from 'classnames';

class NonProfitTabAbout extends Component {
  constructor(props) {
    super(props);
    this.state = {
      imageCarouselOpen: false,
    };
  }

  render() {
    const {
      currentListing,
      description,
      getListing,
      getTranslation,
      isOwnListing,
      onManageDisableScrolling,
      supporterIds,
    } = this.props;

    const nonprofit = sharetribe.listing.ensureNonprofitListing(currentListing);
    const {
      address,
      einNumber,
      email,
      facebookUrl,
      instagramUrl,
      isApproved,
      linkedInUrl,
      nonprofitType,
      phone,
      twitterUrl,
      website,
      youtubeUrl,
    } = nonprofit.attributes.publicData;

    const websiteUrl = businessUrlToUrl(website);
    const supporters = supporterIds.map((id) => getListing(id));

    return (
      <>
        <div className={css.mainContent}>
          <div className={css.sectionHeader}>
            <h2 className={css.sectionHeader}>{getTranslation('aboutUs')}</h2>
            <div className={classNames(css.sectionContent, css.richText)}>{description}</div>
          </div>
          <SectionImages
            handleViewPhotosClick={() => this.setState({ imageCarouselOpen: true })}
            imageCarouselOpen={this.state.imageCarouselOpen}
            isOwnListing={isOwnListing}
            listing={nonprofit}
            onImageCarouselClose={() => this.setState({ imageCarouselOpen: false })}
            onManageDisableScrolling={onManageDisableScrolling}
            title={currentListing.attributes.title}
          />
          <SectionMaybe
            content={
              website && websiteUrl ? (
                <ExternalLink className={css.nonprofitWebsite} href={websiteUrl}>
                  {website}
                </ExternalLink>
              ) : null
            }
            title={getTranslation('website')}
          />
          <NonprofitSocialSectionMaybe
            facebookUrl={facebookUrl}
            instagramUrl={instagramUrl}
            linkedInUrl={linkedInUrl}
            title={getTranslation('socialMedia')}
            twitterUrl={twitterUrl}
            youtubeUrl={youtubeUrl}
          />
          <NonprofitAddressMaybe address={address} title={getTranslation('address')} />
          <SectionMaybe content={phone} title={getTranslation('phone')} />
          <SectionMaybe content={email} title={getTranslation('email')} />
          <SectionMaybe
            content={
              nonprofitType
                ? getTranslation(`nonprofitType.${nonprofitType}`, {
                    name: currentListing.attributes.title,
                  })
                : null
            }
            title={getTranslation('nonprofitType')}
          />
          <SectionMaybe content={formatEinNumber(einNumber)} title={getTranslation('einNumber')} />
        </div>
      </>
    );
  }
}

NonProfitTabAbout.propTypes = {
  currentListing: propTypes.listing,
  description: string.isRequired,
  getListing: func.isRequired,
  getTranslation: func.isRequired,
  isOwnListing: bool.isRequired,
  onManageDisableScrolling: func.isRequired,
  supporterIds: array,
};

NonProfitTabAbout.defaultProps = {
  supporterIds: [],
};

export default NonProfitTabAbout;
