import React from 'react';
import classNames from 'classnames';
import { useMessages } from '../../util/localization';
import { bool, object, string } from 'prop-types';
import config from '../../config';
import * as validators from '../../util/validators';
import getCountryCodes from '../../translations/countryCodes';
import { FieldTextInput, FieldSelect } from '../../components';

import css from './StripePaymentAddress.css';

const StripePaymentAddress = (props) => {
  const { className, disabled, form, fieldId, card } = props;
  const getMessage = useMessages('StripePaymentAddress');
  const optionalText = getMessage('optionalText');

  const handleOnChange = (event) => {
    const value = event.target.value;
    form.change('postal', value);
    card.update({ value: { postalCode: value } });
  };

  // Use tha language set in config.locale to get the correct translations of the country names
  const countryCodes = getCountryCodes(config.locale);

  return (
    <div className={className ? className : css.root}>
      <div className={css.formRow}>
        <FieldTextInput
          id={`${fieldId}.addressLine1`}
          name="addressLine1"
          disabled={disabled}
          className={css.field}
          type="text"
          autoComplete="billing address-line1"
          label={getMessage('addressLine1Label')}
          placeholder={getMessage('addressLine1Placeholder')}
          validate={validators.required(getMessage('addressLine1Required'))}
          onUnmount={() => form.change('addressLine1', undefined)}
        />

        <FieldTextInput
          id={`${fieldId}.addressLine2`}
          name="addressLine2"
          disabled={disabled}
          className={css.field}
          type="text"
          autoComplete="billing address-line2"
          label={getMessage('addressLine2Label', { optionalText }, null, null)}
          placeholder={getMessage('addressLine2Placeholder')}
          onUnmount={() => form.change('addressLine2', undefined)}
        />
      </div>
      <div className={classNames(css.formRow, css.mobileTwoColumn)}>
        <FieldTextInput
          id={`${fieldId}.postalCode`}
          name="postal"
          disabled={disabled}
          className={css.field}
          type="text"
          autoComplete="billing postal-code"
          label={getMessage('postalCodeLabel')}
          placeholder={getMessage('postalCodePlaceholder')}
          validate={validators.required(getMessage('postalCodeRequired'))}
          onUnmount={() => form.change('postal', undefined)}
          onChange={(event) => handleOnChange(event)}
        />

        <FieldTextInput
          id={`${fieldId}.city`}
          name="city"
          disabled={disabled}
          className={css.field}
          type="text"
          autoComplete="billing address-level2"
          label={getMessage('cityLabel')}
          placeholder={getMessage('cityPlaceholder')}
          validate={validators.required(getMessage('cityRequired'))}
          onUnmount={() => form.change('city', undefined)}
        />
      </div>
      <div className={classNames(css.formRow, css.mobileTwoColumn)}>
        <FieldTextInput
          id={`${fieldId}.state`}
          name="state"
          disabled={disabled}
          className={css.field}
          type="text"
          autoComplete="billing address-level1"
          label={getMessage('stateLabel', { optionalText }, null, null)}
          placeholder={getMessage('statePlaceholder')}
          onUnmount={() => form.change('state', undefined)}
        />

        <FieldSelect
          id={`${fieldId}.country`}
          name="country"
          disabled={disabled}
          className={css.field}
          label={getMessage('countryLabel')}
          validate={validators.required(getMessage('countryRequired'))}
        >
          <option disabled value="">
            {getMessage('countryPlaceholder')}
          </option>
          {countryCodes.map((country) => {
            return (
              <option key={country.code} value={country.code}>
                {country.name}
              </option>
            );
          })}
        </FieldSelect>
      </div>
    </div>
  );
};
StripePaymentAddress.defaultProps = {
  country: null,
  disabled: false,
  fieldId: null,
};

StripePaymentAddress.propTypes = {
  country: string,
  disabled: bool,
  form: object.isRequired,
  fieldId: string,
};

export default StripePaymentAddress;
