import React from 'react';

const IconDone = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40">
    <defs>
      <filter
        id="prefix__a"
        width="101.5%"
        height="121%"
        x="-.7%"
        y="-10.5%"
        filterUnits="objectBoundingBox"
      >
        <feOffset dy="-1" in="SourceAlpha" result="shadowOffsetOuter1" />
        <feGaussianBlur in="shadowOffsetOuter1" result="shadowBlurOuter1" stdDeviation="2" />
        <feColorMatrix
          in="shadowBlurOuter1"
          result="shadowMatrixOuter1"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"
        />
        <feMerge>
          <feMergeNode in="shadowMatrixOuter1" />
          <feMergeNode in="SourceGraphic" />
        </feMerge>
      </filter>
    </defs>
    <g fill="none" fillRule="evenodd" filter="url(#prefix__a)" transform="translate(-184 -30)">
      <g transform="translate(192 38)">
        <circle cx="12" cy="12" r="12" fill="#2ECC71" />
        <path
          fill="#FFF"
          fillRule="nonzero"
          d="M15.215 6.727c.427-.71 1.349-.94 2.059-.512.668.402.91 1.242.58 1.931l-.069.128-5.417 9c-.475.788-1.531.96-2.229.393l-.107-.096-3.582-3.513c-.592-.58-.601-1.53-.021-2.12.544-.555 1.412-.598 2.006-.124l.115.102 2.226 2.183 4.439-7.372z"
        />
      </g>
    </g>
  </svg>
);

export default IconDone;
