import React from 'react';
import { bool, string } from 'prop-types';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import classNames from 'classnames';
import config from '../../config';
import {
  IconSocialMediaFacebook,
  IconSocialMediaInstagram,
  IconSocialMediaTwitter,
  IconSocialMediaLinkedIn,
  IconSocialMediaYoutube,
  Logo,
  ExternalLink,
  NamedLink,
} from '../../components';
import css from './Footer.css';
import { propTypes } from '../../util/types';
import { compose } from 'redux';
import { connect } from 'react-redux';

const THIS_YEAR = new Date().getFullYear();

const { companySiteUrl: COMPANY_SITE_URL } = config.custom;

class Footer extends React.Component {
  companyLinks = [
    {
      key: 'about',
      link: COMPANY_SITE_URL,
    },
    {
      key: 'team',
      link: `${COMPANY_SITE_URL}/team`,
    },
    {
      key: 'blog',
      link: `${COMPANY_SITE_URL}/content`,
    },
    {
      key: 'contact',
      link: `${COMPANY_SITE_URL}/contact`,
    },
    {
      name: 'FaqPage',
      key: 'faq',
    },
  ];

  extraLinks = [
    {
      className: css.legalLink,
      key: 'webPrivacyPolicy',
      name: 'PrivacyPolicyPage',
    },
    {
      className: css.legalLink,
      key: 'platformPrivacyPolicy',
      name: 'PlatformPrivacyPolicyPage',
    },
    {
      className: css.legalLink,
      key: 'termsOfService',
      name: 'TermsOfServicePage',
    },
    {
      className: css.legalLink,
      key: 'dmca',
      name: 'TermsOfServicePage',
      target: '_blank',
      to: { hash: 'digital-millennium-copyright-act' },
    },
    {
      className: classNames(css.legalLink, css.copyright),
      key: 'copyright',
      name: 'LandingPage',
      values: {
        year: THIS_YEAR,
      },
    },
  ];

  get serviceLinks() {
    // const { isAuthenticated } = this.props;

    return [
      {
        key: 'goodAdvertising',
        link: `${COMPANY_SITE_URL}/advertising/`,
      },
      {
        key: 'goodMarketing',
        link: `${COMPANY_SITE_URL}/marketing/`,
      },
      {
        key: 'experiences',
        link: `${COMPANY_SITE_URL}/experiences`,
      },
      {
        name: 'NonprofitsLandingPage',
        key: 'nonprofits',
      },
      {
        key: 'requestDemo',
        link: 'https://form.typeform.com/to/yKoPyBT9',
      },
    ];
  }

  renderLinks = (links, prefix) => {
    return links.map((l) => {
      return l.name ? (
        <li className={css.listItem} key={`${prefix}-${l.key}`}>
          <NamedLink
            className={l.className || css.link}
            name={l.name}
            params={l.params || {}}
            target={l.targer || '_self'}
            to={l.to || undefined}
          >
            <FormattedMessage id={`Footer.${l.key}`} values={l.values || {}} />
          </NamedLink>
        </li>
      ) : l.link ? (
        <li className={css.listItem} key={`${prefix}-${l.key}`}>
          <ExternalLink className={l.className || css.link} href={l.link}>
            <FormattedMessage id={`Footer.${l.key}`} values={l.values || {}} />
          </ExternalLink>
        </li>
      ) : null;
    });
  };

  render() {
    const { rootClassName, className } = this.props;
    const classes = classNames(rootClassName || css.root, className);

    return (
      <div className={classes}>
        <div className={css.topBorderWrapper}>
          <div className={css.content}>
            <div className={css.links}>
              <div className={css.organization} id="organization">
                <NamedLink name="LandingPage" className={css.logoLink}>
                  <Logo format="desktop" className={css.logo} />
                </NamedLink>
                <div className={css.slogan}>
                  <FormattedMessage id="Footer.slogan" values={{ br: <br /> }} />
                </div>
              </div>
              <div className={css.service}>
                <h2 className={css.header}>
                  <FormattedMessage id="Footer.solutions" />
                </h2>
                <ul className={css.list}>{this.renderLinks(this.serviceLinks, 'serviceLink')}</ul>
              </div>
              <div className={css.company}>
                <h2 className={css.header}>
                  <FormattedMessage id="Footer.company" />
                </h2>
                <ul className={css.list}>{this.renderLinks(this.companyLinks, 'companyLink')}</ul>
              </div>
              <div className={css.extraLinks}>
                <ul className={css.someLinks}>
                  <li className={css.socialMediaLink}>
                    <a href={config.siteFacebookPage} title="Facebook Page">
                      <IconSocialMediaFacebook />
                    </a>
                  </li>
                  <li className={css.socialMediaLink}>
                    <a href={config.siteTwitterPage} title="Twitter Profile">
                      <IconSocialMediaTwitter />
                    </a>
                  </li>
                  <li className={css.socialMediaLink}>
                    <a href={config.siteInstagramPage} title="Instagram Profile">
                      <IconSocialMediaInstagram />
                    </a>
                  </li>
                  <li className={css.socialMediaLink}>
                    <a href={config.siteLinkedInPage} title="Linkedin Profile">
                      <IconSocialMediaLinkedIn />
                    </a>
                  </li>
                  <li className={css.socialMediaLink}>
                    <a href={config.siteYoutubePage} title="Youtube Profile">
                      <IconSocialMediaYoutube />
                    </a>
                  </li>
                </ul>
                <div className={css.legalMatters}>
                  <ul className={css.tosAndPrivacy}>
                    {this.renderLinks(this.extraLinks, 'extraLink')}
                  </ul>
                </div>
              </div>
            </div>
            <div className={css.copyrightAndTermsMobile}>
              <NamedLink
                name="PrivacyPolicyPage"
                className={classNames(css.privacy, css.mobileLink)}
              >
                <FormattedMessage id="Footer.webPrivacyPolicy" />
              </NamedLink>
              <NamedLink
                name="TermsOfServicePage"
                className={classNames(css.terms, css.mobileLink)}
              >
                <FormattedMessage id="Footer.termsOfService" />
              </NamedLink>
              <NamedLink
                name="TermsOfServicePage"
                className={classNames(css.terms, css.mobileLink)}
                target="_blank" rel="noopener noreferrer"
                to={{ hash: 'digital-millennium-copyright-act' }}
              >
                <FormattedMessage id="Footer.dmca" />
              </NamedLink>
              <NamedLink
                name="LandingPage"
                className={classNames(css.organizationCopyrightMobile, css.mobileLink)}
              >
                <FormattedMessage id="Footer.copyright" values={{ year: THIS_YEAR }} />
              </NamedLink>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

Footer.defaultProps = {
  rootClassName: null,
  className: null,
  isAuthenticated: false,
};

Footer.propTypes = {
  currentUser: propTypes.currentUser,
  rootClassName: string,
  className: string,
  intl: intlShape.isRequired,
  isAuthenticated: bool,
};

const mapStateToProps = (state) => {
  const { isAuthenticated } = state.Auth;

  return {
    isAuthenticated,
  };
};

export default compose(connect(mapStateToProps), injectIntl)(Footer);
