var merge = require("lodash.merge");

var moment = require("moment-timezone");

var ensureGeolocation = function ensureGeolocation(geolocation) {
  var empty = {
    bounds: {
      ne: {
        latitude: null,
        longitude: null
      },
      sw: {
        latitude: null,
        longitude: null
      }
    },
    city: null,
    country: {
      name: null,
      shortCode: null
    },
    latitude: null,
    longitude: null,
    state: {
      name: null,
      shortCode: null
    }
  };
  return merge(empty, geolocation);
};

var ensureReservableSlot = function ensureReservableSlot(reservableSlot) {
  var empty = {
    id: null,
    timezone: "UTC",
    start: null,
    end: null,
    methods: [],
    recurrence: null,
    recurrenceParent: null,
    event: null
  };
  return merge(empty, reservableSlot);
};

var ensureTimeSlot = function ensureTimeSlot(timeSlot) {
  return ensureReservableSlot();
};

var formatDateTime = function formatDateTime(input) {
  var defaultValue = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : null;

  if (moment.isMoment(input)) {
    return input.toDate().toISOString();
  } else if (input instanceof Date) {
    return input.toISOString();
  } else if (typeof input === "string") {
    return new Date(input).toISOString();
  } else {
    return defaultValue;
  }
};

module.exports = {
  ensureGeolocation: ensureGeolocation,
  ensureReservableSlot: ensureReservableSlot,
  // @deprecated, replaced with ensureReservableSlot
  ensureTimeSlot: ensureReservableSlot,
  formatDateTime: formatDateTime
};