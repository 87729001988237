import { func, bool } from 'prop-types';
import React from 'react';
import css from './CalendarDownloadOptions.css';
import { CalendarIntegrationContent, Modal } from '../index';
import { propTypes } from '../../util/types';
import { intlShape } from '../../util/reactIntl';

export const CalendarDownloadOptionsModalConnect = (props) => {
  const { ensuredCurrentUser, intl, ...modalProps } = props;
  return (
    <Modal {...modalProps}>
      <h2 className={css.modalHeader}>
        {intl.formatMessage({ id: 'CalendarDownloadOptions.connectToYourCalendar' })}
      </h2>
      <CalendarIntegrationContent ensuredCurrentUser={ensuredCurrentUser} />
    </Modal>
  );
};

CalendarDownloadOptionsModalConnect.propTypes = {
  ensuredCurrentUser: propTypes.currentUser.isRequired,
  intl: intlShape.isRequired,
  onManageDisableScrolling: func.isRequired,
  onClose: func.isRequired,
  isOpen: bool,
};

CalendarDownloadOptionsModalConnect.defaultValues = {
  isOpen: false,
};
