import moment from 'moment-timezone';

export const createGoogleCalendarUrl = (dataUrl) => {
  const dateFormat = 'YYYYMMDDTHHmmss';
  try {
    return fetch(dataUrl)
      .then((response) => {
        return response.json();
      })
      .then((eventData) => {
        const urlParts = [
          'https://calendar.google.com/calendar/r/eventedit?dates=',
          moment.tz(eventData.start, eventData.timezone).format(dateFormat),
          '/',
          moment.tz(eventData.end, eventData.timezone).format(dateFormat),
          `&location=${eventData.location}`,
          `&ctz=${eventData.timezone}`,
        ];

        if (eventData.description) {
          const descriptionParts = eventData.description.split('\n\n');
          urlParts.push(`&text=${descriptionParts.shift()}`);
          if (descriptionParts.length > 0) {
            urlParts.push(`&details=${descriptionParts.join('\n\n').trim()}`);
          }
        }

        return urlParts.join('');
      });
  } catch (e) {
    return null;
  }
};

export const createOutlookLiveUrl = (dataUrl) => {
  const dateFormat = 'YYYYMMDDTHHmmssZ';
  try {
    return fetch(dataUrl)
      .then((response) => {
        return response.json();
      })
      .then((eventData) => {
        const urlParts = [
          'https://outlook.live.com/owa/?path=/calendar/view/Month&rru=addevent&startdt=',
          moment.tz(eventData.start, eventData.timezone).format(dateFormat),
          '&enddt=',
          moment.tz(eventData.end, eventData.timezone).format(dateFormat),
          `&location=${eventData.location}`,
          `&ctz=${eventData.timezone}`,
        ];

        if (eventData.description) {
          const descriptionParts = eventData.description.split('\n\n');
          urlParts.push(`&subject=${descriptionParts.shift()}`);
          if (descriptionParts.length > 0) {
            urlParts.push(`&body=${descriptionParts.join('\n\n').trim()}`);
          }
        }

        return urlParts.join('');
      });
  } catch (e) {
    return null;
  }
};
