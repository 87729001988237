import React from 'react';
import * as css from '../NonprofitPreferencesPanel.css';
import { useMessages } from '../../../util/localization';
import LiteButton from '../../LiteButton/LiteButton';
import { ICON_PLUS, STYLE_LIGHT } from '../../LiteButton/constants';
import FieldTextInput from '../../FieldTextInput/FieldTextInput';
import RemoveFieldButton from '../RemoveFieldButton';
import { BRAND_TEMPLATE } from '../constants';

const PartnershipsSection = (props) => {
  const { values, form } = props;
  const getMessage = useMessages('NonprofitPreferencesForm');

  return (
    <section className={css.section}>
      <h3 className={css.sectionTitle}>
        {getMessage('section.partners')}
        <span className={css.optional}>{getMessage('optional')}</span>
      </h3>
      <p className={css.small}>{getMessage('paragraph.partners')}</p>
      {values.partners.map((brand, index) => (
        <div key={index} className={css.inputRow}>
          {index > 0 && <br />}
          <FieldTextInput
            className={css.field}
            id={`partners.${index}.name`}
            label={getMessage('field.partners.label')}
            name={`partners.${index}.name`}
            placeholder={getMessage('field.partners.placeholder')}
            type={'text'}
          />
          <RemoveFieldButton
            onClick={() =>
              form.change(
                'partners',
                values.partners.filter((_brand) => _brand !== brand)
              )
            }
          />
        </div>
      ))}
      <br />
      <LiteButton
        icon={ICON_PLUS}
        onClick={() => form.change('partners', [...values.partners, { ...BRAND_TEMPLATE }])}
        style={STYLE_LIGHT}
      >
        {getMessage('liteButton.new')}
      </LiteButton>
    </section>
  );
};

export default PartnershipsSection;
