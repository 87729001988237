import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import classNames from 'classnames';
import facebookImage from '../../assets/givslyFacebook-1200x630.png';
import twitterImage from '../../assets/givslyTwitter-600x314.png';
import {
  IconSpinner,
  Logo,
  Page,
  NamedLink,
} from '../../components';
import config from '../../config';
import { listings, showListing } from '../../ducks/Listings.duck';
import { getMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import { createOutreachRequest, getOutreachOffer } from '../../ducks/outreach.duck';
import { isScrollingDisabled } from '../../ducks/UI.duck';
import { OutreachRequestForm } from '../../forms';
import { useMessages } from '../../util/localization';
import { types as sdkTypes } from '../../util/sdkLoader';
import { bool, func, object } from 'prop-types';
import { showUser } from '../ProfilePage/ProfilePage.duck';
import sharetribe from '@givsly/sharetribe-utils';
import css from './OutreachLandingPage.css';
import { FormattedMessage } from '../../util/reactIntl';
import { getDonationOfferMiniAppUrl } from '../../util/getDonationOfferMiniAppUrl';
const { UUID } = sdkTypes;

const THIS_YEAR = new Date().getFullYear();

const OutreachLandingPage = (props) => {
  const getMessage = useMessages('OutreachLandingPage');

  // Schema data is used inside the page header and social tags
  const schemaTitle = getMessage('metaTitle');
  const schemaDescription = getMessage('metaDescription');

  const { outreachOffer, getUser, getListing, onCreateOutreachRequest } = props;
  const user = sharetribe.user.ensureUser(outreachOffer ? getUser(outreachOffer.authorId) : {});
  const listing = sharetribe.listing.ensureListing(
    user.id ? getListing(user.attributes.profile.publicData.listingId) : {}
  );
  const showRequestForm = outreachOffer && user.id && listing.id;

  // Handler for the outreach creation. This will trigger the API and create the actual request.
  const handleCreateOutreachRequest = async (values) => {
    await onCreateOutreachRequest(values);
  };

  return (
    <Page
      className={css.root}
      scrollingDisabled={props.scrollingDisabled}
      contentType="website"
      description={schemaDescription}
      title={schemaTitle}
      facebookImages={[{ url: facebookImage, width: 1200, height: 630 }]}
      twitterImages={[
        { url: `${config.canonicalRootURL}${twitterImage}`, width: 600, height: 314 },
      ]}
      schema={{
        '@context': 'http://schema.org',
        '@type': 'WebPage',
        description: schemaDescription,
        name: schemaTitle,
      }}
    >
      {showRequestForm ? (
        <OutreachRequestForm
          getListing={getListing}
          listing={listing}
          onCreateOutreachRequest={handleCreateOutreachRequest}
          outreachOffer={outreachOffer}
          user={user}
          id={props.params.id}
        />
      ) : (
        <div className={css.loader}>
          <IconSpinner />
        </div>
      )}
      <footer className={css.offerFooter}>
        <div className={css.offerFooterInner}>
          <ul>
            <li>
              <NamedLink
                name="PrivacyPolicyPage"
                className={classNames(css.privacy, css.mobileLink)}
              >
                <FormattedMessage id="Footer.webPrivacyPolicy" />
              </NamedLink>
            </li>
            <li>
              <NamedLink
                name="LandingPage"
                className={classNames(css.organizationCopyrightMobile, css.mobileLink)}
              >
                <FormattedMessage id="Footer.copyright" values={{ year: THIS_YEAR }} />
              </NamedLink>
            </li>
          </ul>
          <a href="/?utm_source=donationoffer&utm_medium=landingpage&utm_campaign=poweredby">
            <span style={{ fontSize: 14, marginRight: 12 }}>Powered by</span>
            <Logo className={css.logo} />
          </a>
        </div>
      </footer>
    </Page>
  );
};

// Prop types
OutreachLandingPage.propTypes = {
  getListing: func.isRequired,
  getUser: func.isRequired,
  outreachOffer: object,
  getOutreachOfferInProgress: bool,
  onCreateOutreachRequest: func.isRequired,
};

// Default props
OutreachLandingPage.defaultProps = {
  outreachOffer: null,
  getOutreachOfferInProgress: true,
};

// State props
const mapStateToProps = (state) => {
  const { getOutreachOfferInProgress, outreachOffer } = state.outreach;

  // Get listing method, retrieves the listing with the given UUID from the global state
  const getListing = (id) => {
    const ref = { id: new UUID(id), type: 'listing' };
    const listings = getMarketplaceEntities(state, [ref]);
    return listings.length === 1 ? listings[0] : null;
  };

  // Get user method, retrieves the user with the given UUID from the global state
  const getUser = (id) => {
    const ref = { id: new UUID(id), type: 'user' };
    const users = getMarketplaceEntities(state, [ref]);
    return users.length === 1 ? users[0] : null;
  };

  return {
    getListing,
    getUser,
    getOutreachOfferInProgress,
    outreachOffer,
    scrollingDisabled: isScrollingDisabled(state),
  };
};

// Dispatch props
const mapDispatchToProps = (dispatch) => ({
  onCreateOutreachRequest: (data) => dispatch(createOutreachRequest(data)),
});

// Load data for this page
OutreachLandingPage.loadData = (params) => async (dispatch) => {
  console.log(`loadData ==> ${params.id}`)

  // Fetch the outreach offer from the off-platform API
  const outreachOffer = await dispatch(getOutreachOffer(params.id));

  // Verify this offer exists, if not then redirect to 404
  if (!outreachOffer || !outreachOffer.id || !outreachOffer.authorId) {
    console.log(`Failed to access offer ${params.id}`)
    // @todo throw 404
    return
  }

  // redirect to new mini app if offer has a custom slug
  const offerType = (outreachOffer && outreachOffer.offerType) || 0;
  const offerId = (outreachOffer && outreachOffer.id) || '';
  const customSlug = (outreachOffer && outreachOffer.customSlug) || '';
  const tenantId = (outreachOffer && outreachOffer.tenantID) || '';

  const miniAppUrl = getDonationOfferMiniAppUrl(offerId, customSlug, tenantId);

  if (offerType === 1 && miniAppUrl) {
    console.log(`Detected new mini-app`)
    window.location = miniAppUrl;
    return;
  }

  // Fetch associated user listing
  const sdkResponse = await dispatch(showUser(outreachOffer.authorId));
  const user = sharetribe.user.ensureUser(sdkResponse.data.data);

  // Verify this user still exists, if not redirect to 404
  if (!user || !user.id) {
    console.log(`Failed to find author ${outreachOffer.authorId}`)
    // @todo throw 404
    return 
  }

  // Fetch the listing associated with the user
  await dispatch(showListing(new UUID(user.attributes.profile.publicData.listingId)));

  // Fetch associated nonprofits
  if (outreachOffer && Array.isArray(outreachOffer.nonProfitListingIds)) {
    dispatch(listings(outreachOffer.nonProfitListingIds));
  }
};

export default compose(connect(mapStateToProps, mapDispatchToProps))(OutreachLandingPage);
