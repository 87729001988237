import { storableError } from '../../util/errors';
import { addMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import { convertUnitToSubUnit, unitDivisor } from '../../util/currency';
import config from '../../config';
import { allNPOListings, getNPOIdsFromResponseUsers } from '../../ducks/Listings.duck';
import { parse } from '../../util/urlHelpers';

const RESULT_PAGE_SIZE = 12;

// ================ Action types ================ //

export const SEARCH_LISTINGS_REQUEST = 'app/SearchPage/SEARCH_LISTINGS_REQUEST';
export const SEARCH_LISTINGS_SUCCESS = 'app/SearchPage/SEARCH_LISTINGS_SUCCESS';
export const SEARCH_LISTINGS_ERROR = 'app/SearchPage/SEARCH_LISTINGS_ERROR';

export const SEARCH_MAP_SET_ACTIVE_LISTING = 'app/SearchPage/SEARCH_MAP_SET_ACTIVE_LISTING';

// ================ Reducer ================ //

const initialState = {
  pagination: null,
  searchParams: null,
  searchInProgress: false,
  searchListingsError: null,
  currentPageResultIds: [],
  searchMapListingIds: [],
  searchMapListingsError: null,
};

const resultIds = (data) => data.data.map((l) => l.id);

const listingPageReducer = (state = initialState, action = {}) => {
  const { type, payload } = action;
  switch (type) {
    case SEARCH_LISTINGS_REQUEST:
      return {
        ...state,
        searchParams: payload.searchParams,
        searchInProgress: true,
        searchMapListingIds: [],
        searchListingsError: null,
      };
    case SEARCH_LISTINGS_SUCCESS:
      return {
        ...state,
        currentPageResultIds: resultIds(payload.data),
        pagination: payload.data.meta,
        searchInProgress: false,
      };
    case SEARCH_LISTINGS_ERROR:
      // eslint-disable-next-line no-console
      console.error(payload);
      return { ...state, searchInProgress: false, searchListingsError: payload };

    case SEARCH_MAP_SET_ACTIVE_LISTING:
      return {
        ...state,
        activeListingId: payload,
      };
    default:
      return state;
  }
};

export default listingPageReducer;

// ================ Action creators ================ //

export const searchListingsRequest = (searchParams) => ({
  type: SEARCH_LISTINGS_REQUEST,
  payload: { searchParams },
});

export const searchListingsSuccess = (response) => ({
  type: SEARCH_LISTINGS_SUCCESS,
  payload: { data: response.data },
});

export const searchListingsError = (e) => ({
  type: SEARCH_LISTINGS_ERROR,
  error: true,
  payload: e,
});

const parseSearchParamsToSave = (searchParams) => {
  const params = { ...searchParams };
  if (params.pub_eventRoles && params.pub_eventRoles.startsWith('has_any:')) {
    // If event roles includes has_any, it is searching all event attendees, this parameter will not be shown on query params.
    params.pub_eventRoles = undefined;
  }

  return params;
};

export const searchListings = (searchParams) => (dispatch, getState, sdk) => {
  dispatch(searchListingsRequest(parseSearchParamsToSave(searchParams)));

  const priceSearchParams = (priceParam) => {
    const inSubunits = (value) =>
      convertUnitToSubUnit(value, unitDivisor(config.currencyConfig.currency));
    const values = priceParam ? priceParam.split(',') : [];
    return priceParam && values.length === 2
      ? {
          price: [inSubunits(values[0]), inSubunits(values[1]) + 1].join(','),
        }
      : {};
  };

  const { perPage, price, dates, ...rest } = searchParams;
  const priceMaybe = priceSearchParams(price);

  // Apply additional filter settings when searching availability
  if (rest.start && rest.end) {
    rest.availability = 'time-partial';
    rest.minDuration = 15;
    rest.seats = 1;
    rest.pub_isBookable = true;
  }

  const params = {
    ...rest,
    ...priceMaybe,
    per_page: perPage,
  };

  // The page number must be removed from the parameters when the availability search is active,
  // since the API will return an error otherwise. The real page number is stored so we can later
  // use it to add our own paging to the results.
  const realPage = rest.page;
  if (params.start && params.end) {
    params.page = 1;
  }

  return sdk.listings
    .query(params)
    .then((response) => {
      const npoIds = getNPOIdsFromResponseUsers(response);

      if (npoIds.length) {
        let thisState = getState().SearchPage;
        const existingNPOIds =
          thisState && thisState.npoListingNames ? Object.keys(thisState.npoListingNames) : [];

        // If the NPO listings are not yet in the npoListingNames, fetch them
        // TODO: I dont think that condition works, it is always true, because filter returns always an array, empty, if none matched.
        // Empty array = true
        if (npoIds.filter((i) => !existingNPOIds.includes(i))) {
          dispatch(allNPOListings());
        }
      }

      // Paging workaround when availability search is used. Sharetribe does not support paging when
      // applying filters related to availability. Instead it will return a maximum of 100 results.
      //
      // We can still add our own paging to that set of results with these simple changes.
      if (response.data.meta.paginationUnsupported) {
        response.data.meta = {
          ...response.data.meta,
          page: realPage,
          perPage: RESULT_PAGE_SIZE,
          totalItems: response.data.data.length,
          totalPages: Math.ceil(response.data.data.length / RESULT_PAGE_SIZE),
        };

        response.data.data = response.data.data.splice(
          realPage * RESULT_PAGE_SIZE - RESULT_PAGE_SIZE,
          RESULT_PAGE_SIZE
        );
      }

      dispatch(addMarketplaceEntities(response));
      dispatch(searchListingsSuccess(response));

      return response;
    })
    .catch((e) => {
      console.log('Error?!');
      dispatch(searchListingsError(storableError(e)));
      throw e;
    });
};

export const loadPeopleSearchData = (params, search, searchListingParams = {}) => {
  const queryParams = parse(search);
  const { page = 1, address, origin, ...rest } = queryParams;
  const originMaybe = config.sortSearchByDistance && origin ? { origin } : {};

  const resultPageSize = queryParams.start && queryParams.end ? 100 : RESULT_PAGE_SIZE;

  return searchListings({
    ...searchListingParams,
    ...rest,
    ...originMaybe,
    pub_isNPOListing: false,
    meta_isListingVisible: true,
    page,
    perPage: resultPageSize,
    include: ['author', 'images', 'author.user', 'author.profileImage'],
    'fields.image': [
      // Listing images
      'variants.landscape-crop',
      'variants.landscape-crop2x',

      // Author profile image
      'variants.square-small',
      'variants.square-small2x',
    ],
    'limit.images': 1,
  });
};

export const setActiveListing = (listingId) => ({
  type: SEARCH_MAP_SET_ACTIVE_LISTING,
  payload: listingId,
});
