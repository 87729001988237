import { func, string } from 'prop-types';
import React from 'react';
import Button from '../Button/Button';
import css from './SectionCallToActionFooter.css';
import classNames from 'classnames';

const SectionCallToActionFooter = props => {
  const {
    bodyText,
    buttonText,
    className,
    onClick,
    subTitle,
    title,
  } = props;
  return (
    <li className={classNames(css.root, className)}>
      {title ? <h2 className={css.title}>{title}</h2> : null}
      {subTitle ? <h3 className={css.subTitle}>{subTitle}</h3> : null}
      {bodyText ? <p className={css.bodyText}>{bodyText}</p> : null}
      {buttonText && typeof onClick === 'function' ? (
        <Button className={css.actionButton} onClick={onClick}>{buttonText}</Button>
      ) : null}
    </li>
  )
}

SectionCallToActionFooter.propTypes = {
  bodyText: string,
  buttonText: string,
  className: string,
  onClick: func,
  subTitle: string,
  title: string,
};

SectionCallToActionFooter.defaultProps = {
  bodyText: null,
  buttonText: null,
  className: null,
  onClick: null,
  subTitle: null,
  title: null,
};

export default SectionCallToActionFooter;
