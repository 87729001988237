import React from 'react';
import { withMessages } from '../../util/localization';
import { Button, Modal, NamedLink } from '../index';
import { bool, func, number, shape, string } from 'prop-types';
import css from './SoftWall.css';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import { createResourceLocatorString } from '../../util/routes';
import routeConfiguration from '../../routeConfiguration';
import Markdown from 'react-markdown';
import classNames from 'classnames';

/**
 * The soft wall component is used to stimulate users to sign up. It shows up during search results
 * for users who are not signed up or not logged in and wish to browse the search results.
 *
 * The modal is configurable via the marketplace custom configuration and uses the soft wall
 * utility.
 */
class SoftWall extends React.Component {
  handleClose = () => {
    const { onClose, onTrackEvent, trackingLabel } = this.props;
    onTrackEvent({
      category: 'SoftWall',
      action: 'Close',
      label: trackingLabel,
    });
    onClose();
  };

  handlePrimaryButtonClick = () => {
    const { history, onClose, onTrackEvent, trackingLabel } = this.props;
    onClose();
    onTrackEvent({
      category: 'SoftWall',
      action: 'PrimaryClick',
      label: trackingLabel,
      value: 'SignUp',
    });
    const url = createResourceLocatorString('SignupPage', routeConfiguration());
    history.push(url);
  };

  handleSecondaryButtonClick = () => {
    const { history, onClose, onTrackEvent, trackingLabel } = this.props;
    onClose();
    onTrackEvent({
      category: 'SoftWall',
      action: 'SecondaryClick',
      label: trackingLabel,
      value: 'Login',
    });
    const url = createResourceLocatorString('LoginPage', routeConfiguration());
    history.push(url);
  };

  handleBrowseLinkClick = () => {
    const { onClose, onTrackEvent, trackingLabel } = this.props;
    onClose();
    onTrackEvent({
      category: 'SoftWall',
      action: 'FooterClick',
      label: trackingLabel,
      value: 'BrowseNonprofits',
    });
  };

  render() {
    const { canClose, getMessage, isOpen, onManageDisableScrolling, variant } = this.props;
    const realVariant = variant < 0 ? 0 : variant;
    const classes = classNames(css.modal, css[`modal-variant${realVariant}`]);

    return (
      <Modal
        className={classes}
        hasCloseButton={canClose}
        id={'softWall'}
        isOpen={isOpen}
        onClose={this.handleClose}
        onManageDisableScrolling={onManageDisableScrolling}
      >
        <div className={css.image} />
        <div className={css.content}>
          <h2 className={css.header}>{getMessage(`${realVariant}.header`)}</h2>
          <div className={css.body}>
            <Markdown source={getMessage(`${realVariant}.body`)} />
          </div>
          <div className={css.controls}>
            <Button
              className={css.primaryButton}
              onClick={this.handlePrimaryButtonClick}
              type={'button'}
            >
              {getMessage(`${realVariant}.primaryButtonText`)}
            </Button>
            <Button
              className={css.secondaryButton}
              onClick={this.handleSecondaryButtonClick}
              type={'button'}
            >
              {getMessage(`${realVariant}.secondaryButtonText`)}
            </Button>
          </div>
          <p className={css.footer}>
            {getMessage(`${realVariant}.footer`, {
              discoverLink: (
                <NamedLink
                  className={css.footerLink}
                  key={`softWall-${realVariant}-link`}
                  name={'NonprofitSearchPage'}
                  onClick={this.handleBrowseLinkClick}
                >
                  {getMessage(`${realVariant}.footer.linkTitle`)}
                </NamedLink>
              ),
            })}
          </p>
        </div>
      </Modal>
    );
  }
}

SoftWall.propTypes = {
  canClose: bool,
  history: shape({
    push: func.isRequired,
  }).isRequired,
  isOpen: bool.isRequired,
  onClose: func.isRequired,
  onManageDisableScrolling: func.isRequired,
  onTrackEvent: func.isRequired,
  trackingLabel: string,
  variant: number,
};

SoftWall.defaultProps = {
  canClose: true,
  isOpen: false,
  variant: 0,
  trackingLabel: 'Generic',
};

const withLocalizedMessages = (component) => withMessages(component, 'SoftWall');

export default compose(withLocalizedMessages, withRouter)(SoftWall);
