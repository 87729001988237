import React, { useCallback } from 'react';
import { bool, func, string } from 'prop-types';
import { compose } from 'redux';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import { Form as FinalForm } from 'react-final-form';
import classNames from 'classnames';
import * as validators from '../../util/validators';
import { Form, PrimaryButton, FieldTextInput, NamedLink } from '../../components';

import css from './SignupForm.css';
import FieldCheckboxComponent from '../../components/FieldCheckbox/FieldCheckbox';
import {
  getHiddenValuesFromDecodedToken,
  getInitialSignUpValuesFromInvitationToken,
} from '../../util/invitation';

const IconCheck = () => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M13.3334 4L6.00008 11.3333L2.66675 8"
      stroke="#1C1A34"
      strokeLinecap="round"
      fill="none"
      strokeLinejoin="round"
    />
  </svg>
);

const IconTimes = () => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M12 4L4 12" stroke="#1C1A34" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M4 4L12 12" stroke="#1C1A34" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);

const SignupFormComponent = (props) => {
  const { token, geoip, organization, isNpoSignup, intl } = props;
  const initialValues = getInitialSignUpValuesFromInvitationToken(token);
  const hiddenValues = getHiddenValuesFromDecodedToken(initialValues);
  const agreeMarketing = geoip.country === 'US' ? ['Yes'] : [];

  const getTranslation = useCallback(
    (name, variables = {}) => {
      return intl.formatMessage({ id: `SignupForm.${name}` }, variables);
    },
    [intl]
  );

  return (
    <FinalForm
      {...props}
      initialValues={{ ...initialValues, agreeMarketing }}
      render={(fieldRenderProps) => {
        const {
          rootClassName,
          className,
          formId,
          handleSubmit,
          inProgress,
          invalid,
          intl,
          form,
          values,
        } = fieldRenderProps;

        const submitDisabled = invalid || inProgress;
        const agreeToServiceLabel = getTranslation('agreeToService');

        if (!isNpoSignup) {
          form.change('tenant_id', organization);
        }

        const passwordFieldState = form.getFieldState('password');

        return (
          <Form
            className={classNames(rootClassName || css.root, className)}
            onSubmit={handleSubmit}
            autoComplete="off"
          >
            <div>
              <p className={css.leadText}>
                <FormattedMessage id="SignupForm.leadMessage" />
              </p>
              <FieldTextInput
                type="email"
                id={formId ? `${formId}.email` : 'email'}
                name="email"
                autoComplete="email"
                label={getTranslation('emailLabel')}
                placeholder={getTranslation('emailPlaceholder')}
                validate={validators.composeValidators(
                  validators.required(getTranslation('emailRequired')),
                  validators.emailFormatValid(getTranslation('emailInvalid'))
                )}
              />
              <div className={css.name}>
                <FieldTextInput
                  className={css.firstNameRoot}
                  type="text"
                  id={formId ? `${formId}.fname` : 'fname'}
                  name="fname"
                  autoComplete="given-name"
                  label={getTranslation('firstNameLabel')}
                  placeholder={getTranslation('firstNamePlaceholder')}
                  validate={validators.required(getTranslation('firstNameRequired'))}
                />
                <FieldTextInput
                  className={css.lastNameRoot}
                  type="text"
                  id={formId ? `${formId}.lname` : 'lname'}
                  name="lname"
                  autoComplete="family-name"
                  label={getTranslation('lastNameLabel')}
                  placeholder={getTranslation('lastNamePlaceholder')}
                  validate={validators.required(getTranslation('lastNameRequired'))}
                />
              </div>
              <FieldTextInput
                className={css.password}
                type="password"
                id={formId ? `${formId}.password` : 'password'}
                name="password"
                autoComplete="new-password"
                label={getTranslation('passwordLabel')}
                placeholder={getTranslation('passwordPlaceholder')}
                validate={validators.composeValidators(
                  validators.requiredStringNoTrim(getTranslation('passwordRequired')),
                  validators.minLength(
                    getTranslation('passwordTooShort', {
                      minLength: validators.PASSWORD_MIN_LENGTH,
                    }),
                    validators.PASSWORD_MIN_LENGTH
                  ),
                  validators.maxLength(
                    getTranslation('passwordTooLong', {
                      maxLength: validators.PASSWORD_MAX_LENGTH,
                    }),
                    validators.PASSWORD_MAX_LENGTH
                  ),
                  validators.matches(
                    getTranslation('passwordMissingLowercaseLetter'),
                    validators.PASSWORD_LOWERCASE_REGEX
                  ),
                  validators.matches(
                    getTranslation('passwordMissingUppercaseLetter'),
                    validators.PASSWORD_UPPERCASE_REGEX
                  ),
                  validators.matches(
                    getTranslation('passwordMissingNumber'),
                    validators.PASSWORD_NUMBER_REGEX
                  ),
                  validators.matches(
                    getTranslation('passwordMissingSymbol'),
                    validators.PASSWORD_SYMBOL_REGEX
                  )
                )}
              />
              {passwordFieldState && passwordFieldState.error && passwordFieldState.touched && (
                <ul className={css['password-validator-list']}>
                  <h4>Password requirements</h4>
                  <li>
                    {validators.minLength(
                      <IconTimes />,
                      validators.PASSWORD_MIN_LENGTH
                    )(values.password) || <IconCheck />}{' '}
                    {getTranslation('passwordTooShort', {
                      minLength: validators.PASSWORD_MIN_LENGTH,
                    })}
                  </li>
                  <li>
                    {validators.matches(
                      <IconTimes />,
                      validators.PASSWORD_LOWERCASE_REGEX
                    )(values.password) || <IconCheck />}{' '}
                    {getTranslation('passwordMissingLowercaseLetter')}
                  </li>
                  <li>
                    {validators.matches(
                      <IconTimes />,
                      validators.PASSWORD_UPPERCASE_REGEX
                    )(values.password) || <IconCheck />}{' '}
                    {getTranslation('passwordMissingUppercaseLetter')}
                  </li>
                  <li>
                    {validators.matches(
                      <IconTimes />,
                      validators.PASSWORD_NUMBER_REGEX
                    )(values.password) || <IconCheck />}{' '}
                    {getTranslation('passwordMissingNumber')}
                  </li>
                  <li>
                    {validators.matches(
                      <IconTimes />,
                      validators.PASSWORD_SYMBOL_REGEX
                    )(values.password) || <IconCheck />}{' '}
                    {getTranslation('passwordMissingSymbol')}
                  </li>
                </ul>
              )}
              <div className={css.checkboxes}>
                <FieldCheckboxComponent
                  value="Yes"
                  name="agreeTermsAndConditions"
                  id="agreeTermsAndConditions"
                  validate={validators.required(
                    intl.formatMessage({ id: 'SignupForm.termsAndConditionsRequired' })
                  )}
                  label={
                    <>
                      {agreeToServiceLabel}{' '}
                      <NamedLink name="TermsOfServicePage" target="_blank" rel="noopener noreferrer">
                        <FormattedMessage id="SignupForm.termsAndConditions" />
                      </NamedLink>
                    </>
                  }
                />
                <FieldCheckboxComponent
                  value="Yes"
                  name="agreePrivacyPolicy"
                  id="agreePrivacyPolicy"
                  validate={validators.required(
                    intl.formatMessage({ id: 'SignupForm.privacyPolicyRequired' })
                  )}
                  label={
                    <>
                      {agreeToServiceLabel}{' '}
                      <NamedLink name="PrivacyPolicyPage" target="_blank" rel="noopener noreferrer">
                        <FormattedMessage id="SignupForm.privacyPolicy" />
                      </NamedLink>
                    </>
                  }
                />

                <FieldCheckboxComponent
                  value="Yes"
                  name="agreeMarketing"
                  id="agreeMarketing"
                  label={<FormattedMessage id="SignupForm.agreeMarketing" />}
                />

                {Object.keys(hiddenValues).map((key) => {
                  return <FieldTextInput id={key} key={key} name={key} type="hidden" />;
                })}
              </div>
            </div>
            <div className={css.bottomWrapper}>
              <PrimaryButton type="submit" inProgress={inProgress} disabled={submitDisabled}>
                <FormattedMessage id="SignupForm.signUp" />
              </PrimaryButton>
            </div>
          </Form>
        );
      }}
    />
  );
};

SignupFormComponent.propTypes = {
  inProgress: bool,
  intl: intlShape.isRequired,
  onOpenTermsOfService: func.isRequired,
  token: string,
};

SignupFormComponent.defaultProps = {
  inProgress: false,
  token: null,
  organization: null,
};

const SignupForm = compose(injectIntl)(SignupFormComponent);
SignupForm.displayName = 'SignupForm';

export default SignupForm;
