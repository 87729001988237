import React from 'react';
import { string, func } from 'prop-types';
import classNames from 'classnames';
import { propTypes } from '../../../../util/types';
import { ensureListing, ensureUser } from '../../../../util/data';
import { richText } from '../../../../util/richText';
import { createSlug } from '../../../../util/urlHelpers';
import { NamedLink } from '../../../../components';
import css from './TestimonialCard.css';
import { AvatarExtraLarge } from '../../../../components/Avatar/Avatar';

const MIN_LENGTH_FOR_LONG_WORDS = 10;

class TestimonialCard extends React.Component {
  constructor(props) {
    super(props);
    this.handleClick = this.handleClick.bind(this);
  }

  handleClick(listingTitle) {
    const { trackEvent, trackingCategory } = this.props;
    trackEvent({
      category: trackingCategory,
      action: 'ClickProfile',
      label: 'TestimonialCard',
      value: listingTitle,
    });
  }

  render() {
    const { className, rootClassName, listing, setActiveListing } = this.props;
    const classes = classNames(rootClassName || css.root, className);
    const currentListing = ensureListing(listing);
    const currentAuthor = ensureUser(currentListing.author);
    const id = currentListing.id.uuid;
    const { title = '' } = currentListing.attributes;
    const slug = createSlug(title);

    const { companyName, jobTitle, testimonial } = currentListing.attributes.publicData;

    return (
      <NamedLink
        className={classes}
        name="ListingPage"
        onClick={(e) => {
          this.handleClick(title);
          return e;
        }}
        params={{ id, slug }}
      >
        <div
          className={css.threeToTwoWrapper}
          onMouseEnter={() => setActiveListing(currentListing.id)}
          onMouseLeave={() => setActiveListing(null)}
        >
          <div className={css.avatarWrapper}>
            <AvatarExtraLarge user={currentAuthor} />
          </div>
          <div className={css.info}>
            <div className={css.mainInfo}>
              <div className={css.title}>
                {richText(title, {
                  longWordMinLength: MIN_LENGTH_FOR_LONG_WORDS,
                  longWordClass: css.longWord,
                })}
              </div>
              <div className={css.jobTitle}>
                {richText(jobTitle, {
                  longWordMinLength: MIN_LENGTH_FOR_LONG_WORDS,
                  longWordClass: css.longWord,
                })}
              </div>
              <div className={css.company}>
                {richText(companyName, {
                  longWordMinLength: MIN_LENGTH_FOR_LONG_WORDS,
                  longWordClass: css.longWord,
                })}
              </div>
              <div className={css.quote}>{testimonial}</div>
            </div>
          </div>
        </div>
      </NamedLink>
    );
  }
}

TestimonialCard.propTypes = {
  className: string,
  rootClassName: string,
  listing: propTypes.listing.isRequired,
  renderSizes: string,
  setActiveListing: func,
  trackEvent: func.isRequired,
  trackingCategory: string.isRequired,
};

TestimonialCard.defaultProps = {
  className: null,
  rootClassName: null,
  renderSizes: null,
  setActiveListing: () => null,
};

export default TestimonialCard;
