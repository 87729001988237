import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import css from './AddImages.css';

const RemoveImageButton = (props) => {
  const { className, rootClassName, onClick } = props;
  const classes = classNames(rootClassName || css.removeImage, className);
  return <button className={classes} onClick={onClick} type="button" />;
};

RemoveImageButton.defaultProps = { className: null, rootClassName: null };

const { func, string } = PropTypes;

RemoveImageButton.propTypes = {
  className: string,
  rootClassName: string,
  onClick: func.isRequired,
};

export default RemoveImageButton;
