import React from 'react';
import { intlShape, injectIntl } from '../../util/reactIntl';
import classNames from 'classnames';
import { func, object, string } from 'prop-types';
import moment from 'moment-timezone';
import css from './BookingDateAndSlotPicker.css';
import ReservableSlot from './ReservableSlot';
import { propTypes } from '../../util/types';
import { getEvent } from '../../util/events';

const getRsId = (rs) => {
  return rs.start + rs.end;
};

const getSlotEvent = (reservableSlot) => {
  if (!reservableSlot.event) return null;

  return getEvent(reservableSlot.event);
};

const getEventTitle = (currentDateReservableSlots) => {
  if (!currentDateReservableSlots || currentDateReservableSlots.length === 0) return null;

  const slotWithEvent = currentDateReservableSlots.find((slot) => slot.event);
  if (!slotWithEvent) return null;

  const event = getEvent(slotWithEvent.event);
  return event ? event.title : null;
};

export const ReservableSlotsComponent = (props) => {
  const {
    rootClassName,
    currentUser,
    currentDateReservableSlots,
    intl,
    isAccessCodeRequiredForSlot,
    methodPriceChoices,
    selectedDate,
    selectedReservableSlot,
    onReservableSlotSelect,
    selectedMethod,
    onMethodSelect,
    timezone,
  } = props;

  if (!selectedDate || !currentDateReservableSlots || !currentDateReservableSlots.length) {
    return null;
  }

  const classes = classNames(rootClassName || css.reservableSlots);
  const eventTitle = getEventTitle(currentDateReservableSlots);
  const lowerBound = moment().tz(timezone).add(6, 'hours');

  return (
    <div className={classes}>
      {eventTitle && (
        <span className={css.partOfEventText}>
          {intl.formatMessage(
            { id: 'BookingDateAndSlotPicker.meetingIsPartOfEvent' },
            { event: eventTitle }
          )}
        </span>
      )}
      {currentDateReservableSlots.map((rs) => {
        const start = moment.tz(rs.start, rs.timezone);
        return start.isBefore(lowerBound) ? null : (
          <ReservableSlot
            currentUser={currentUser}
            key={getRsId(rs)}
            id={getRsId(rs)}
            isAccessCodeRequired={isAccessCodeRequiredForSlot(rs)}
            event={getSlotEvent(rs)}
            reservableSlot={rs}
            methodPriceChoices={methodPriceChoices}
            selectedReservableSlot={selectedReservableSlot}
            onReservableSlotSelect={onReservableSlotSelect}
            selectedMethod={selectedMethod}
            onMethodSelect={onMethodSelect}
            timezone={timezone}
          />
        );
      })}
    </div>
  );
};

ReservableSlotsComponent.defaultProps = {};

ReservableSlotsComponent.propTypes = {
  currentUser: propTypes.currentUser,
  id: string,
  intl: intlShape.isRequired,
  isAccessCodeRequiredForSlot: func.isRequired,
  reservableSlot: object,
  methodPriceChoices: object.isRequired,
  timezone: string.isRequired,
};

export default injectIntl(ReservableSlotsComponent);
