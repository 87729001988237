import React from 'react';
import { array, bool, func, object, shape } from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { intlShape } from '../../util/reactIntl';
import { isScrollingDisabled, manageDisableScrolling } from '../../ducks/UI.duck';
import config from '../../config';
import {
  Page,
  LayoutSingleColumn,
  LayoutWrapperTopbar,
  LayoutWrapperMain,
  LayoutWrapperFooter,
  Footer,
  FeaturedNonprofits,
} from '../../components';
import { TopbarContainer } from '../../containers';

import facebookImage from '../../assets/givslyFacebook-1200x630.png';
import twitterImage from '../../assets/givslyTwitter-600x314.png';
import css from './SummerCampaignPage.css';

import { queryNonProfits } from '../../ducks/NonprofitListing.duck';
import { getMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import { ensureListing } from '../../util/data';
import { listings } from '../../ducks/Listings.duck';
import classNames from 'classnames';
import { types as sdkTypes } from '../../util/sdkLoader';
import { withMessages } from '../../util/localization';
import { CallToAction, Hero, HowItWorks, Steps, Testimonials } from './components';
import { trackEventAction } from '../../ducks/Analytics.duck';

const { UUID } = sdkTypes;
// Number 1, 2 and 3
const featuredTestimonials = config.custom.featuredTestimonials.slice(0, 3);
const trackingCategory = 'Summerone';

class SummerCampaignOnePageComponent extends React.Component {
  constructor(props) {
    super(props);
    this.getTranslation = this.getTranslation.bind(this);
  }

  getTranslation = (name, variables = {}) => {
    return this.props.intl.formatMessage({ id: `LandingPage.${name}` }, variables);
  };

  render() {
    const {
      getListing,
      history,
      intl,
      getMessage,
      nonprofitListingIds,
      nonprofitLoadingInProgress,
      scrollingDisabled,
      listingLoadingInProgress,
      trackEvent,
    } = this.props;

    // Schema for search engines (helps them to understand what this page is about)
    // http://schema.org
    // We are using JSON-LD format
    const siteTitle = config.siteTitle;
    const schemaTitle = intl.formatMessage({ id: 'LandingPage.schemaTitle' }, { siteTitle });
    const schemaDescription = intl.formatMessage({ id: 'LandingPage.schemaDescription' });
    const schemaImage = `${config.canonicalRootURL}${facebookImage}`;

    const testimonialListings = featuredTestimonials.map((id) => {
      return getListing(new UUID(id));
    });

    return (
      <Page
        className={css.root}
        scrollingDisabled={scrollingDisabled}
        contentType="website"
        description={schemaDescription}
        title={schemaTitle}
        facebookImages={[{ url: facebookImage, width: 1200, height: 630 }]}
        twitterImages={[
          { url: `${config.canonicalRootURL}${twitterImage}`, width: 600, height: 314 },
        ]}
        schema={{
          '@context': 'http://schema.org',
          '@type': 'WebPage',
          description: schemaDescription,
          name: schemaTitle,
          image: [schemaImage],
        }}
      >
        <LayoutSingleColumn>
          <LayoutWrapperTopbar>
            <TopbarContainer currentPage="LandingPage" />
          </LayoutWrapperTopbar>
          <LayoutWrapperMain className={css.layoutMainWrapper}>
            <div className={css.heroContainer}>
              <Hero
                buttonLabel={getMessage('hero.buttonLabel')}
                text={getMessage('hero.text')}
                title={getMessage('hero.title')}
                trackEvent={trackEvent}
                trackingCategory={trackingCategory}
              />
            </div>
            <ul className={css.sections}>
              <li className={css.section}>
                <HowItWorks
                  buttonLabel={getMessage('howItWorks.buttonLabel')}
                  subTitle={getMessage('howItWorks.subTitle')}
                  text={getMessage('howItWorks.text')}
                  title={getMessage('howItWorks.title')}
                  trackEvent={trackEvent}
                  trackingCategory={trackingCategory}
                />
                <Testimonials
                  browseAllLabel={getMessage('testimonials.browseAllLabel')}
                  browseAllMobileLabel={getMessage('testimonials.browseAllMobileLabel')}
                  loadingInProgress={listingLoadingInProgress}
                  listings={testimonialListings}
                  title={getMessage('testimonials.title')}
                  trackEvent={trackEvent}
                  trackingCategory={trackingCategory}
                />
              </li>
              <li className={classNames(css.section, css.steps)}>
                <div className={css.sectionContent}>
                  <Steps
                    buttonLabel={getMessage('steps.buttonLabel')}
                    steps={[
                      {
                        title: getMessage('steps.simplify.title'),
                        subTitle: getMessage('steps.simplify.subTitle'),
                        text: getMessage('steps.simplify.text'),
                      },
                      {
                        title: getMessage('steps.connect.title'),
                        subTitle: getMessage('steps.connect.subTitle'),
                        text: getMessage('steps.connect.text'),
                      },
                      {
                        title: getMessage('steps.amplify.title'),
                        subTitle: getMessage('steps.amplify.subTitle'),
                        text: getMessage('steps.amplify.text'),
                      },
                    ]}
                    title={getMessage('steps.title')}
                    trackEvent={trackEvent}
                    trackingCategory={trackingCategory}
                  />
                </div>
              </li>
              {!nonprofitLoadingInProgress ? (
                <li className={css.section}>
                  <div className={css.sectionContent}>
                    <FeaturedNonprofits
                      browseAllLabel={getMessage('nonprofits.browseAllLabel')}
                      browseAllMobileLabel={getMessage('nonprofits.browseAllMobileLabel')}
                      cardClassName={css.featuredNonprofitCard}
                      className={css.featuredNonprofits}
                      isFeaturedCategory={false}
                      name="featuredNonprofits"
                      history={history}
                      nonprofits={nonprofitListingIds.map((id) => {
                        return ensureListing(getListing(id));
                      })}
                      title={getMessage('nonprofits.title')}
                      trackEvent={trackEvent}
                      trackingCategory={trackingCategory}
                    />
                  </div>
                </li>
              ) : null}
              <li className={classNames(css.section, css.lastSection)}>
                <div className={css.sectionContent}>
                  <CallToAction
                    buttonLabel={getMessage('callToAction.buttonLabel')}
                    subTitle={getMessage('callToAction.subTitle')}
                    text={getMessage('callToAction.text')}
                    title={getMessage('callToAction.title')}
                    trackEvent={trackEvent}
                    trackingCategory={trackingCategory}
                  />
                </div>
              </li>
            </ul>
          </LayoutWrapperMain>
          <LayoutWrapperFooter>
            <Footer />
          </LayoutWrapperFooter>
        </LayoutSingleColumn>
      </Page>
    );
  }
}

SummerCampaignOnePageComponent.propTypes = {
  getListing: func.isRequired,
  getMessage: func.isRequired,
  history: shape({
    push: func.isRequired,
  }).isRequired,
  intl: intlShape.isRequired,
  isAuthenticated: bool,
  listingIds: array,
  listingLoadingInProgress: bool,
  location: object.isRequired,
  nonprofitListingIds: array,
  nonprofitLoadingInProgress: bool,
  scrollingDisabled: bool.isRequired,
};

SummerCampaignOnePageComponent.defaultProps = {
  nonprofitListingIds: [],
  nonprofitLoadingInProgress: true,
  listingIds: [],
  listingLoadingInProgress: true,
};

const mapStateToProps = (state) => {
  const { isAuthenticated } = state.Auth;
  const {
    currentPageResultIds: nonprofitListingIds,
    searchInProgress: nonprofitLoadingInProgress,
  } = state.NonprofitListing;

  const {
    currentPageResultIds: listingIds,
    listingsInProgress: listingLoadingInProgress,
  } = state.Listings;

  const getListing = (id) => {
    const ref = { id, type: 'listing' };
    const listings = getMarketplaceEntities(state, [ref]);
    return listings.length === 1 ? listings[0] : null;
  };

  const { currentUser } = state.user;

  return {
    currentUser,
    getListing,
    isAuthenticated: isAuthenticated,
    nonprofitListingIds,
    nonprofitLoadingInProgress,
    listingIds,
    listingLoadingInProgress,
    scrollingDisabled: isScrollingDisabled(state),
  };
};

const mapDispatchToProps = (dispatch) => ({
  onManageDisableScrolling: (componentId, disableScrolling) =>
    dispatch(manageDisableScrolling(componentId, disableScrolling)),
  trackEvent: (params) => dispatch(trackEventAction(params)),
});

const withLocalizedMessages = (component) => {
  return withMessages(component, 'SummerCampaignOne');
};

const SummerCampaignOnePage = compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  withLocalizedMessages
)(SummerCampaignOnePageComponent);

SummerCampaignOnePage.loadData = () => (dispatch, getState) => {
  return Promise.all([
    dispatch(queryNonProfits({}, 3)),
    dispatch(listings(featuredTestimonials.concat(config.custom.landingPageFeaturedNonprofits))),
  ]);
};

export default SummerCampaignOnePage;
