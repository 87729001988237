import { LOCATION_CHANGED } from '../ducks/Routing.duck';

// Create a Redux middleware from the given analytics handlers. Each
// handler should have the following methods:
//
// - trackPageView(canonicalPath, previousPath): called when the URL is changed
export const createMiddleware = (handlers) => (store) => (next) => (action) => {
  const { type, payload } = action;

  if (type === LOCATION_CHANGED) {
    try {
      const state = store ? store.getState() : {};
      const { Routing } = state || {};
      const { currentCanonicalUrl: previousPath } = Routing || {};
      const { canonicalUrl } = payload;
      handlers.forEach((handler) => {
        handler.trackPageView(canonicalUrl, previousPath);
      });
    } catch (err) {
      console.warn(err);
    }
  }

  next(action);
};
