import React, { Component } from 'react';
import PropTypes, { func } from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { FormattedMessage, intlShape, injectIntl } from '../../util/reactIntl';
import { Button, IconClose, NamedLink } from '../../components';
import classNames from 'classnames';

import css from './CookieConsent.css';
import { acceptCookies } from '../../ducks/CookieConsent.duck';

class CookieConsentComponent extends Component {
  constructor(props) {
    super(props);
    this.state = { show: true };
    this.handleClose = this.handleClose.bind(this);
  }

  handleClose() {
    this.props.acceptCookies();
    this.setState({ show: false });
  }

  render() {
    const { className, rootClassName, cookiesAccepted, disableCookies, intl } = this.props;

    // We don't show the cookie consent banner if cookies are already accepted
    // or cookies should be disabled already (set to true in the backend if the
    // visitor is from the EU) or the user has closed the banner.
    if (cookiesAccepted || disableCookies || !this.state.show) {
      return null;
    } else {
      const closeMessage = intl.formatMessage({ id: 'CookieConsent.close' });
      const cookieLink = (
        <NamedLink name="PrivacyPolicyPage" className={css.cookieLink}>
          <FormattedMessage id="CookieConsent.learnMore" />
        </NamedLink>
      );
      const classes = classNames(rootClassName || css.root, className);

      return (
        <div className={classes}>
          <Button
            onClick={this.handleClose}
            rootClassName={css.close}
            title={closeMessage}
            type={'button'}
          >
            <IconClose rootClassName={css.closeIcon} size={'small'} />
          </Button>
          <div className={css.message}>
            <FormattedMessage id="CookieConsent.message" values={{ cookieLink }} />
          </div>
          <button
            className={css.continueBtn}
            onClick={() => {
              // We create date object and modify it to show date 10 years into the future.
              let expirationDate = new Date();
              expirationDate.setFullYear(expirationDate.getFullYear() + 10);

              // Save the cookie with expiration date
              document.cookie =
                'cookiesAccepted=1; path=/; expires=' + expirationDate.toGMTString();
              this.handleClose();
            }}
            type={'button'}
          >
            <FormattedMessage id="CookieConsent.continue" />
          </button>
        </div>
      );
    }
  }
}

const { string } = PropTypes;

CookieConsentComponent.defaultProps = {
  className: null,
  rootClassName: null,
};

CookieConsentComponent.propTypes = {
  acceptCookies: func.isRequired,
  className: string,
  rootClassName: string,
  intl: intlShape.isRequired,
};

const mapStateToProps = (state) => {
  const { country, disableCookies, cookiesAccepted } = state.CookieConsent;

  return {
    country,
    disableCookies,
    cookiesAccepted,
  };
};

const mapDispatchToProps = (dispatch) => ({
  acceptCookies: () => dispatch(acceptCookies()),
});

const CookieConsent = compose(
  connect(mapStateToProps, mapDispatchToProps),
  injectIntl
)(CookieConsentComponent);

export default CookieConsent;
