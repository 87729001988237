/* eslint-disable no-underscore-dangle */
import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import createReducer from './reducers';
import * as analytics from './analytics/analytics';
import config from './config';

/**
 * Create a new store with the given initial state. Adds Redux
 * middleware and enhancers.
 */
const store = (initialState = {}, sdk = null, analyticsHandlers = []) => {
  const middlewares = [thunk.withExtraArgument(sdk), analytics.createMiddleware(analyticsHandlers)];

  // Enable Redux Devtools in client side dev mode.
  const composeEnhancers =
    // @ts-ignore
    config.dev && typeof window === 'object' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
      ? // @ts-ignore
        window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
      : compose;

  const enhancer = composeEnhancers(applyMiddleware(...middlewares));

  // Add the analytics handlers to the store
  initialState.Analytics = {
    analyticsHandlers,
  };

  return createStore(createReducer(), initialState, enhancer);
};

export default store;
