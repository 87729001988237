import React from 'react';
import css from '../PartnershipsPage.css';
import { FormattedMessage } from '../../../util/reactIntl';

const EmptyTab = () => {
  return (
    <div className={css.content}>
      <h1 className={css.title}>
        <FormattedMessage id="PartnershipsPage.EmptyTab.title" />
      </h1>
      <p>
        <FormattedMessage id="PartnershipsPage.EmptyTab.description" />
      </p>
    </div>
  );
};

export default EmptyTab;
