import { listings } from './Listings.duck';
import config from '../config';
import { storableError } from '../util/errors';

// ================ Action types ================ //

export const MONTHLY_FEATURES_ERROR = 'app/GivslyCMS/MONTHLY_FEATURES_ERROR';
export const MONTHLY_FEATURES_STORE_IDS = 'app/GivslyCMS/MONTHLY_FEATURES_STORE_IDS';

// ================ Reducer ================ //

const initialState = {
  fetchError: null,
  storedIds: config.custom.featuredListings.concat(config.custom.landingPageFeaturedListings),
};

export default function reducer(state = initialState, action = {}) {
  const { type, payload } = action;

  switch (type) {
    case MONTHLY_FEATURES_STORE_IDS:
      return {
        ...state,
        storedIds: payload,
      };
    case MONTHLY_FEATURES_ERROR:
      // eslint-disable-next-line no-console
      console.error(payload);

      return {
        ...state,
        fetchError: payload.error,
      };
    default:
      return state;
  }
}

// ================ Selectors ================ //

// ================ Action creators ================ //

export const monthlyCausesError = (error) => ({
  type: MONTHLY_FEATURES_ERROR,
  payload: { error },
});

const storeIds = (ids) => ({
  type: MONTHLY_FEATURES_STORE_IDS,
  payload: ids,
});

// ================ Thunks ================ //

/**
 * load listings with possible to provide optional extra listings.
 *
 * This thunk exists so we can load additional listings without overriding
 * the one we need in the global state.
 */
export const loadListings = (extraIds = []) => async (dispatch, getState) => {
  try {
    const { storedIds } = getState().GivslyCMS;
    const expectedIds = storedIds.concat(extraIds);

    if (!expectedIds.every((expectedId) => storedIds.some((storedId) => storedId === expectedId))) {
      dispatch(storeIds(expectedIds));

      await dispatch(listings(expectedIds));
    }
  } catch (e) {
    console.log('Error?!');
    dispatch(monthlyCausesError(storableError(e)));

    throw e;
  }
};
