import React, { Component } from 'react';
import { bool, func } from 'prop-types';
import {
  ExternalLink,
  FieldTextInput,
  IconSpinner,
  Modal,
  NamedLink,
  PrimaryButton,
} from '../../components';
import css from './BookingAccessCodeModal.css';
import { Form as FinalForm } from 'react-final-form';
import Form from '../../components/Form/Form';
import { propTypes } from '../../util/types';
import { FORM_ERROR } from 'final-form';
import { validateBookingAccessCode } from '../../util/bookingAccessCodeValidator';
import { composeValidators, required, minLength, maxLength } from '../../util/validators';
import { withMessages } from '../../util/localization';

class BookingAccessCodeModal extends Component {
  constructor(props) {
    super(props);

    this.submit = this.submit.bind(this);
  }

  async submit({ accessCode }) {
    return validateBookingAccessCode(accessCode).then((validation) => {
      if (!!validation) {
        return this.props.onSubmit(accessCode);
      }

      return {
        [FORM_ERROR]: this.props.intl.formatMessage({
          id: 'BookingDateAndSlotPicker.accessCode.error',
        }),
      };
    });
  }

  render() {
    const { getMessage, onManageDisableScrolling, isOpen, event, onClose } = this.props;

    return (
      <Modal
        onManageDisableScrolling={onManageDisableScrolling}
        isOpen={isOpen}
        onClose={onClose}
        id="booking-access-code-modal"
      >
        {event && (
          <>
            <span className={css.title}>{getMessage('title')}</span>
            <p className={css.text}>
              To book this Advertising Week 2020 Speaker & VIP meeting you will have to had register
              as an official delegate for Advertising Week 2020 and obtained a pass code which opens
              this booking. Log in to AW2020 and visit your{' '}
              <ExternalLink href={'https://virtual.advertisingweek.com/account/'}>
                account dashboard
              </ExternalLink>{' '}
              to locate your pass code or{' '}
              <ExternalLink href={'https://virtual.advertisingweek.com/register/'}>
                register as a delegate for AW2020.
              </ExternalLink>
            </p>
            <p className={css.text}>
              Questions? Visit our{' '}
              <NamedLink name="EventFaqPage" params={{ eventKey: event.key }} target="_blank" rel="noopener noreferrer">
                AW2020 FAQ on Givsly.
              </NamedLink>
            </p>
          </>
        )}

        <FinalForm
          onSubmit={this.submit}
          render={({
            handleSubmit,
            hasSubmitErrors,
            dirtySinceLastSubmit,
            submitError,
            submitting,
            valid,
          }) => {
            return (
              <Form onSubmit={handleSubmit}>
                <FieldTextInput
                  className={hasSubmitErrors && !dirtySinceLastSubmit ? css.error : null}
                  type="text"
                  id="accessCode"
                  name="accessCode"
                  label={getMessage('input.label')}
                  validate={composeValidators(
                    required(getMessage('input.validate.required')),
                    minLength(getMessage('input.validate.minLength'), 32),
                    maxLength(getMessage('input.validate.maxLength'), 32)
                  )}
                />

                <div className={css.errorContainer}>
                  {hasSubmitErrors && !dirtySinceLastSubmit && (
                    <span className={css.error}>{submitError}</span>
                  )}
                </div>

                <PrimaryButton
                  type="submit"
                  disabled={
                    submitting ||
                    (hasSubmitErrors && !dirtySinceLastSubmit) ||
                    (!valid && !hasSubmitErrors)
                  }
                >
                  {!submitting && getMessage('submit')}
                  {submitting && <IconSpinner />}
                </PrimaryButton>
              </Form>
            );
          }}
        />
      </Modal>
    );
  }
}

BookingAccessCodeModal.propTypes = {
  getMessage: func.isRequired,
  onManageDisableScrolling: func.isRequired,
  onClose: func.isRequired,
  onSubmit: func.isRequired,
  isOpen: bool.isRequired,
  event: propTypes.event,
};

export default withMessages(BookingAccessCodeModal, 'BookingAccessCodeModal');
