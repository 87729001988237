/*
 * Renders a group of checkboxes that can be used to select
 * multiple values from a set of options.
 *
 * The corresponding component when rendering the selected
 * values is PropertyGroup.
 *
 */

import React from 'react';
import { arrayOf, bool, node, shape, string, func, oneOf } from 'prop-types';
import classNames from 'classnames';
import { FieldArray } from 'react-final-form-arrays';
import { FieldCheckbox, FieldRadioButton, ValidationError } from '../../components';

import css from './FieldCheckboxRadioGroup.css';

export const FIELD_TYPE_CHECKBOX = 'checkbox';
export const FIELD_TYPE_RADIO = 'radio';

const FieldCheckboxRadioRenderer = (props) => {
  const {
    className,
    rootClassName,
    label,
    twoColumns,
    id,
    fields,
    options,
    meta,
    onChange,
    type,
  } = props;

  const classes = classNames(rootClassName || css.root, className);
  const listClasses = twoColumns ? classNames(css.list, css.twoColumns) : css.list;

  return (
    <fieldset className={classes}>
      {label ? <legend>{label}</legend> : null}
      <ul className={listClasses}>
        {options.map((option, index) => {
          const fieldId = `${id}.${option.key}`;
          return (
            <li key={fieldId} className={css.item}>
              {type === FIELD_TYPE_CHECKBOX && (
                <FieldCheckbox
                  id={fieldId}
                  name={fields.name}
                  label={option.label}
                  value={option.key}
                  onChange={onChange}
                />
              )}
              {type === FIELD_TYPE_RADIO && (
                <FieldRadioButton
                  id={fieldId}
                  name={fields.name}
                  value={option.key}
                  label={option.label}
                  onChange={onChange}
                />
              )}
            </li>
          );
        })}
      </ul>
      <ValidationError fieldMeta={{ ...meta }} />
    </fieldset>
  );
};

FieldCheckboxRadioRenderer.defaultProps = {
  rootClassName: null,
  className: null,
  label: null,
  twoColumns: false,
  type: FIELD_TYPE_CHECKBOX,
};

FieldCheckboxRadioRenderer.propTypes = {
  rootClassName: string,
  className: string,
  id: string.isRequired,
  label: node,
  options: arrayOf(
    shape({
      key: string.isRequired,
      label: node.isRequired,
    })
  ).isRequired,
  twoColumns: bool,
  onChange: func,
  type: oneOf([FIELD_TYPE_CHECKBOX, FIELD_TYPE_RADIO]),
};

const FieldCheckboxRadioGroup = (props) => (
  <FieldArray component={FieldCheckboxRadioRenderer} {...props} />
);

// Name and component are required fields for FieldArray.
// Component-prop we define in this file, name needs to be passed in
FieldCheckboxRadioGroup.propTypes = {
  name: string.isRequired,
};

export default FieldCheckboxRadioGroup;
