import React from 'react';
import { string } from 'prop-types';
import css from './IconEarth.css';

const IconEarth = (props) => {
  const className = props.className || css.icon;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      className={className}
    >
      <path
        fillRule="evenodd"
        d="M10.259 14.087v-.672l2-2V9.939H11.21L9.275 8.002h-3.51l1.292 1.29v.804l1.246 1.246v3.142c-.102.005-.201.016-.303.016-3.584 0-6.5-2.916-6.5-6.5 0-1.22.344-2.356.93-3.332l3.334 3.334v-2h2v-2h1l1-1V1.75C12.492 2.52 14.5 5.028 14.5 8c0 2.789-1.77 5.166-4.241 6.087M7.999 0C3.583 0 0 3.582 0 8s3.583 8 8 8c4.419 0 8-3.582 8-8s-3.581-8-8-8"
      />
    </svg>
  );
};

IconEarth.propTypes = {
  className: string,
};

export default IconEarth;
