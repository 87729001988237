import React from 'react';
import classNames from 'classnames';
import { AvatarHuge, NamedLink } from '../../components';

import css from './TransactionPanel.css';
import { FormattedMessage } from 'react-intl';

const DetailCardProfile = (props) => {
  const {
    className,
    rootClassName,
    avatarWrapperClassName,
    provider,
    customer,
    isCustomer,
    listingTitle,
  } = props;
  const classes = classNames(rootClassName || css.detailCardProfile, className);
  const detailsUser = isCustomer ? provider : customer;
  const publicData =
    detailsUser.attributes &&
    detailsUser.attributes.profile &&
    detailsUser.attributes.profile.publicData
      ? detailsUser.attributes.profile.publicData
      : {};
  const detailCardTitle = isCustomer ? listingTitle : detailsUser.attributes.profile.displayName;

  return (
    <>
      <div className={classes}>
        <div className={avatarWrapperClassName || css.avatarWrapper}>
          <AvatarHuge user={detailsUser} />
        </div>

        <div className={css.detailCardHeadings}>
          <div className={css.detailCardTitle}>{detailCardTitle}</div>
          <div className={css.detailCardSubtitle}>{publicData.jobTitle}</div>
          <div className={css.detailCardSubtitle}>{publicData.companyName}</div>
          {!isCustomer && publicData.listingId ? (
            <div className={css.detailCardProfileLink}>
              <NamedLink name="ListingPageCanonical" params={{ id: publicData.listingId }}>
                <FormattedMessage id="DetailCardProfile.viewProfile" />
                <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12">
                  <g fill="none" fillRule="evenodd" stroke="#FAC51D" strokeWidth="1.5">
                    <path d="M8.75 8.25v3h-8v-8h3M6.25.75h5v5" />
                    <path strokeLinecap="square" d="M10.25 1.75l-5 5" />
                  </g>
                </svg>
              </NamedLink>
            </div>
          ) : null}
        </div>
      </div>
    </>
  );
};

export default DetailCardProfile;
