var merge = require("lodash.merge");

var union = require("lodash.union");

var sharetribeSdk = require("sharetribe-flex-sdk");

var UUID = sharetribeSdk.types.UUID;
/**
 * Merges request options (e.g. default vs. additional) into a single set. Filters out duplicate include options
 *
 * @param optionsA
 * @param optionsB
 * @return {Object}
 */

var mergeOptions = function mergeOptions(optionsA, optionsB) {
  var result = merge(optionsA, optionsB);

  if (optionsA.include || optionsB.include) {
    result.include = union(optionsA.include || [], optionsB || []);
  }

  return result;
};
/**
 * Shortcut for creating Sharetribe acceptable UUID objects
 *
 * @param uuid
 * @returns {*}
 */


var createUUid = function createUUid(uuid) {
  return new UUID(uuid);
};

module.exports = {
  createUUid: createUUid,
  mergeOptions: mergeOptions
};