import pick from 'lodash/pick';
import config from '../../config';
import { queryTimeSlots } from '../../ducks/timeSlot.duck';
import {
  denormalisedResponseEntities,
  ensureCurrentUser,
  ensurePaymentMethodCard,
  ensureStripeCustomer,
  ensureTransaction,
} from '../../util/data';
import { storableError } from '../../util/errors';
import { findBookedReservableSlots } from '../../util/reservableSlot';
import {
  getTotalDonation,
  TRANSITION_BOOK,
  TRANSITION_BOOK_AFTER_ENQUIRY,
  TRANSITION_CONFIRM_PAYMENT,
  TRANSITION_PROPOSE,
  TRANSITION_REQUEST_PAYMENT,
} from '../../util/transaction';
import * as log from '../../util/log';
import { fetchCurrentUser, fetchCurrentUserHasOrdersSuccess } from '../../ducks/user.duck';
import { types as sdkTypes } from '../../util/sdkLoader';
import { canSpeculatePaymentTransaction } from './CheckoutPageHelpers';
import { applyFeesAndDeductions as applyTransactionFeesAndDeductions } from '../../util/credit';
import { createPaymentIntent } from '../../ducks/payment.duck';
import sharetribe from '@givsly/sharetribe-utils';
import utils from '@givsly/sharetribe-utils';
import givslyConfig from '@givsly/config';
import {
  METHOD_CREDIT_CARD,
  METHOD_GIVSLY_CREDIT,
} from '../../components/PaymentMethods/constants';

const { TRANSITION_REQUIRE_PAYMENT, TRANSITION_OMIT_PAYMENT } = sharetribe.transaction;

const { Money } = sdkTypes;

// ================ Action types ================ //

export const SET_INITAL_VALUES = 'app/CheckoutPage/SET_INITIAL_VALUES';

export const APPLY_FEES_AND_DEDUCTIONS_REQUEST =
  'app/CheckoutPage/APPLY_FEES_AND_DEDUCTIONS_REQUEST';
export const APPLY_FEES_AND_DEDUCTIONS_SUCCESS =
  'app/CheckoutPage/APPLY_FEES_AND_DEDUCTIONS_SUCCESS';
export const APPLY_FEES_AND_DEDUCTIONS_FAILURE =
  'app/CheckoutPage/APPLY_FEES_AND_DEDUCTIONS_FAILURE';

export const FETCH_BLOCKED_SLOTS_REQUEST = 'app/CheckoutPage/FETCH_BLOCKED_SLOTS_REQUEST';
export const FETCH_BLOCKED_SLOTS_SUCCESS = 'app/CheckoutPage/FETCH_BLOCKED_SLOTS_SUCCESS';
export const FETCH_BLOCKED_SLOTS_FAILURE = 'app/CheckoutPage/FETCH_BLOCKED_SLOTS_FAILURE';

export const INITIATE_ORDER_REQUEST = 'app/CheckoutPage/INITIATE_ORDER_REQUEST';
export const INITIATE_ORDER_SUCCESS = 'app/CheckoutPage/INITIATE_ORDER_SUCCESS';
export const INITIATE_ORDER_ERROR = 'app/CheckoutPage/INITIATE_ORDER_ERROR';

export const INITIATE_BOOKING_ORDER_REQUEST = 'app/CheckoutPage/INITIATE_BOOKING_ORDER_REQUEST';
export const INITIATE_BOOKING_ORDER_SUCCESS = 'app/CheckoutPage/INITIATE_BOOKING_ORDER_SUCCESS';
export const INITIATE_BOOKING_ORDER_ERROR = 'app/CheckoutPage/INITIATE_BOOKING_ORDER_ERROR';

export const CONFIRM_PAYMENT_REQUEST = 'app/CheckoutPage/CONFIRM_PAYMENT_REQUEST';
export const CONFIRM_PAYMENT_SUCCESS = 'app/CheckoutPage/CONFIRM_PAYMENT_SUCCESS';
export const CONFIRM_PAYMENT_ERROR = 'app/CheckoutPage/CONFIRM_PAYMENT_ERROR';

export const SPECULATE_BOOKING_TRANSACTION_REQUEST =
  'app/ListingPage/SPECULATE_BOOKING_TRANSACTION_REQUEST';
export const SPECULATE_BOOKING_TRANSACTION_SUCCESS =
  'app/ListingPage/SPECULATE_BOOKING_TRANSACTION_SUCCESS';
export const SPECULATE_BOOKING_TRANSACTION_ERROR =
  'app/ListingPage/SPECULATE_BOOKING_TRANSACTION_ERROR';

export const SPECULATE_PROPOSAL_TRANSACTION_REQUEST =
  'app/ListingPage/SPECULATE_PROPOSAL_TRANSACTION_REQUEST';
export const SPECULATE_PROPOSAL_TRANSACTION_SUCCESS =
  'app/ListingPage/SPECULATE_PROPOSAL_TRANSACTION_SUCCESS';
export const SPECULATE_PROPOSAL_TRANSACTION_ERROR =
  'app/ListingPage/SPECULATE_PROPOSAL_TRANSACTION_ERROR';

export const SPECULATE_PAYMENT_TRANSACTION_REQUEST =
  'app/ListingPage/SPECULATE_PAYMENT_TRANSACTION_REQUEST';
export const SPECULATE_PAYMENT_TRANSACTION_SUCCESS =
  'app/ListingPage/SPECULATE_PAYMENT_TRANSACTION_SUCCESS';
export const SPECULATE_PAYMENT_TRANSACTION_ERROR =
  'app/ListingPage/SPECULATE_PAYMENT_TRANSACTION_ERROR';

export const SPECULATE_OUTREACH_PAYMENT_TRANSACTION_REQUEST =
  'app/ListingPage/SPECULATE_OUTREACH_PAYMENT_TRANSACTION_REQUEST';
export const SPECULATE_OUTREACH_PAYMENT_TRANSACTION_SUCCESS =
  'app/ListingPage/SPECULATE_OUTREACH_PAYMENT_TRANSACTION_SUCCESS';
export const SPECULATE_OUTREACH_PAYMENT_TRANSACTION_ERROR =
  'app/ListingPage/SPECULATE_OUTREACH_PAYMENT_TRANSACTION_ERROR';

export const STRIPE_CUSTOMER_REQUEST = 'app/CheckoutPage/STRIPE_CUSTOMER_REQUEST';
export const STRIPE_CUSTOMER_SUCCESS = 'app/CheckoutPage/STRIPE_CUSTOMER_SUCCESS';
export const STRIPE_CUSTOMER_ERROR = 'app/CheckoutPage/STRIPE_CUSTOMER_ERROR';

export const NPO_LISTINGS_SUCCESS = 'app/CheckoutPage/NPO_LISTINGS_SUCCESS';

// ================ Reducer ================ //

const initialState = {
  listing: null,

  applyFeesAndDeductionsError: null,
  applyFeesAndDeductionsInProgress: false,

  blockedSlots: [],
  bookingData: null,
  bookingDates: null,
  event: null,
  eventNonprofits: [],
  fetchBlockedSlotsError: null,
  fetchBlockedSlotsInProgress: false,
  speculateBookingTransactionInProgress: false,
  speculateBookingTransactionError: null,
  speculatedBookingTransaction: null,

  speculatePaymentTransactionInProgress: false,
  speculatePaymentTransactionError: null,
  speculatedPaymentTransaction: null,

  speculateOutreachPaymentTransactionInProgress: false,
  speculateOutreachPaymentTransactionError: null,
  speculatedOutreachPaymentTransaction: null,

  transaction: null,
  initiateOrderError: null,
  confirmPaymentError: null,
  stripeCustomerFetched: false,

  bookingTransaction: null,
  initiateBookingOrderError: null,
};

export default function checkoutPageReducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case APPLY_FEES_AND_DEDUCTIONS_REQUEST:
      return {
        ...state,
        applyFeesAndDeductionsError: null,
        applyFeesAndDeductionsInProgress: true,
      };
    case APPLY_FEES_AND_DEDUCTIONS_SUCCESS:
      return {
        ...state,
        applyFeesAndDeductionsInProgress: false,
      };
    case APPLY_FEES_AND_DEDUCTIONS_FAILURE:
      return {
        ...state,
        applyFeesAndDeductionsError: payload,
        applyFeesAndDeductionsInProgress: false,
      };

    case FETCH_BLOCKED_SLOTS_REQUEST:
      return {
        ...state,
        fetchBlockedSlotsError: null,
        fetchBlockedSlotsInProgress: true,
      };
    case FETCH_BLOCKED_SLOTS_SUCCESS:
      return {
        ...state,
        blockedSlots: payload.blockedSlots,
        fetchBlockedSlotsInProgress: false,
      };
    case FETCH_BLOCKED_SLOTS_FAILURE:
      return {
        ...state,
        fetchBlockedSlotsError: payload.err,
        fetchBlockedSlotsInProgress: false,
      };
    case SET_INITAL_VALUES:
      return { ...initialState, ...payload };

    case SPECULATE_BOOKING_TRANSACTION_REQUEST:
      return {
        ...state,
        speculateBookingTransactionInProgress: true,
        speculateBookingTransactionError: null,
        speculatedBookingTransaction: null,
      };
    case SPECULATE_BOOKING_TRANSACTION_SUCCESS:
      return {
        ...state,
        speculateBookingTransactionInProgress: false,
        speculatedBookingTransaction: payload.transaction,
      };
    case SPECULATE_BOOKING_TRANSACTION_ERROR:
      console.error(payload); // eslint-disable-line no-console
      return {
        ...state,
        speculateBookingTransactionInProgress: false,
        speculateBookingTransactionError: payload,
      };

    case SPECULATE_PAYMENT_TRANSACTION_REQUEST:
      return {
        ...state,
        speculatePaymentTransactionInProgress: true,
        speculatePaymentTransactionError: null,
        speculatedPaymentTransaction: null,
      };
    case SPECULATE_PAYMENT_TRANSACTION_SUCCESS:
      return {
        ...state,
        speculatePaymentTransactionInProgress: false,
        speculatedPaymentTransaction: payload.transaction,
      };
    case SPECULATE_PAYMENT_TRANSACTION_ERROR:
      console.error(payload); // eslint-disable-line no-console
      return {
        ...state,
        speculatePaymentTransactionInProgress: false,
        speculatePaymentTransactionError: payload,
      };

    // Outreach payment
    case SPECULATE_OUTREACH_PAYMENT_TRANSACTION_REQUEST:
      return {
        ...state,
        speculateOutreachPaymentTransactionInProgress: true,
        speculateOutreachPaymentTransactionError: null,
        speculatedOutreachPaymentTransaction: null,
      };
    case SPECULATE_OUTREACH_PAYMENT_TRANSACTION_SUCCESS:
      return {
        ...state,
        speculateOutreachPaymentTransactionInProgress: false,
        speculatedOutreachPaymentTransaction: payload.transaction,
      };
    case SPECULATE_OUTREACH_PAYMENT_TRANSACTION_ERROR:
      console.error(payload); // eslint-disable-line no-console
      return {
        ...state,
        speculateOutreachPaymentTransactionInProgress: false,
        speculateOutreachPaymentTransactionError: payload,
      };

    case INITIATE_ORDER_REQUEST:
      return { ...state, initiateOrderError: null };
    case INITIATE_ORDER_SUCCESS:
      return { ...state, transaction: payload };
    case INITIATE_ORDER_ERROR:
      console.error(payload); // eslint-disable-line no-console
      return { ...state, initiateOrderError: payload };

    case INITIATE_BOOKING_ORDER_REQUEST:
      return { ...state, initiateBookingOrderError: null };
    case INITIATE_BOOKING_ORDER_SUCCESS:
      return { ...state, bookingTransaction: payload };
    case INITIATE_BOOKING_ORDER_ERROR:
      console.error(payload); // eslint-disable-line no-console
      return { ...state, initiateBookingOrderError: payload };

    case CONFIRM_PAYMENT_REQUEST:
      return { ...state, confirmPaymentError: null };
    case CONFIRM_PAYMENT_SUCCESS:
      return state;
    case CONFIRM_PAYMENT_ERROR:
      console.error(payload); // eslint-disable-line no-console
      return { ...state, confirmPaymentError: payload };

    case STRIPE_CUSTOMER_REQUEST:
      return { ...state, stripeCustomerFetched: false };
    case STRIPE_CUSTOMER_SUCCESS:
      return { ...state, stripeCustomerFetched: true };
    case STRIPE_CUSTOMER_ERROR:
      console.error(payload); // eslint-disable-line no-console
      return { ...state, stripeCustomerFetchError: payload };

    case NPO_LISTINGS_SUCCESS:
      return {
        ...state,
        npoListingsFetched: true,
        npoListingChoices: payload,
      };

    default:
      return state;
  }
}

// ================ Selectors ================ //

// ================ Action creators ================ //

export const setInitialValues = (initialValues) => ({
  type: SET_INITAL_VALUES,
  payload: pick(initialValues, Object.keys(initialState)),
});

const applyFeesAndDeductionsRequest = () => ({ type: APPLY_FEES_AND_DEDUCTIONS_REQUEST });
const applyFeesAndDeductionsSuccess = () => ({ type: APPLY_FEES_AND_DEDUCTIONS_SUCCESS });
const applyFeesAndDeductionsFailure = (err) => ({
  type: APPLY_FEES_AND_DEDUCTIONS_FAILURE,
  error: true,
  payload: storableError(err),
});
const fetchBlockedSlotsRequest = () => ({ type: FETCH_BLOCKED_SLOTS_REQUEST });
const fetchBlockedSlotsSuccess = (blockedSlots) => ({
  type: FETCH_BLOCKED_SLOTS_SUCCESS,
  payload: {
    blockedSlots,
  },
});
const fetchBlockedSlotsFailure = (err) => ({
  type: FETCH_BLOCKED_SLOTS_FAILURE,
  payload: {
    err,
  },
});

const initiateOrderRequest = () => ({ type: INITIATE_ORDER_REQUEST });

const initiateOrderSuccess = (order) => ({
  type: INITIATE_ORDER_SUCCESS,
  payload: order,
});

const initiateOrderError = (e) => ({
  type: INITIATE_ORDER_ERROR,
  error: true,
  payload: e,
});

const initiateBookingOrderRequest = () => ({ type: INITIATE_BOOKING_ORDER_REQUEST });

const initiateBookingOrderSuccess = (order) => ({
  type: INITIATE_BOOKING_ORDER_SUCCESS,
  payload: order,
});

const initiateBookingOrderError = (e) => ({
  type: INITIATE_BOOKING_ORDER_ERROR,
  error: true,
  payload: e,
});

const confirmPaymentRequest = () => ({ type: CONFIRM_PAYMENT_REQUEST });

const confirmPaymentSuccess = (orderId) => ({
  type: CONFIRM_PAYMENT_SUCCESS,
  payload: orderId,
});

const confirmPaymentError = (e) => ({
  type: CONFIRM_PAYMENT_ERROR,
  error: true,
  payload: e,
});

const ACTION_TYPES_BY_TRANSACTION_TYPE = {
  booking: {
    request: SPECULATE_BOOKING_TRANSACTION_REQUEST,
    success: SPECULATE_BOOKING_TRANSACTION_SUCCESS,
    error: SPECULATE_BOOKING_TRANSACTION_ERROR,
  },
  proposal: {
    request: SPECULATE_PROPOSAL_TRANSACTION_REQUEST,
    success: SPECULATE_PROPOSAL_TRANSACTION_SUCCESS,
    error: SPECULATE_PROPOSAL_TRANSACTION_ERROR,
  },
  payment: {
    request: SPECULATE_PAYMENT_TRANSACTION_REQUEST,
    success: SPECULATE_PAYMENT_TRANSACTION_SUCCESS,
    error: SPECULATE_PAYMENT_TRANSACTION_ERROR,
  },
  outreachPayment: {
    request: SPECULATE_OUTREACH_PAYMENT_TRANSACTION_REQUEST,
    success: SPECULATE_OUTREACH_PAYMENT_TRANSACTION_SUCCESS,
    error: SPECULATE_OUTREACH_PAYMENT_TRANSACTION_ERROR,
  },
};

export const speculateTransactionRequest = (transactionType) => ({
  type: ACTION_TYPES_BY_TRANSACTION_TYPE[transactionType]['request'],
});

export const speculateTransactionSuccess = (transactionType, transaction) => ({
  type: ACTION_TYPES_BY_TRANSACTION_TYPE[transactionType]['success'],
  payload: { transaction },
});

export const speculateTransactionError = (transactionType, e) => ({
  type: ACTION_TYPES_BY_TRANSACTION_TYPE[transactionType]['error'],
  error: true,
  payload: e,
});

export const stripeCustomerRequest = () => ({ type: STRIPE_CUSTOMER_REQUEST });
export const stripeCustomerSuccess = () => ({ type: STRIPE_CUSTOMER_SUCCESS });
export const stripeCustomerError = (e) => ({
  type: STRIPE_CUSTOMER_ERROR,
  error: true,
  payload: e,
});

export const NPOListingsSuccess = (listings) => ({
  type: NPO_LISTINGS_SUCCESS,
  payload: listings,
});

/* ================ Thunks ================ */
export const initiateOrder = (orderParams, transactionId) => (dispatch, getState, sdk) => {
  dispatch(initiateOrderRequest());

  const bodyParams = {
    processAlias: orderParams.protectedData.processAlias,
    transition: TRANSITION_REQUEST_PAYMENT,
    params: orderParams,
  };
  const queryParams = {
    include: ['booking', 'provider'],
    expand: true,
  };

  const createOrder = transactionId ? sdk.transactions.transition : sdk.transactions.initiate;

  return createOrder(bodyParams, queryParams)
    .then((response) => {
      const entities = denormalisedResponseEntities(response);
      const order = entities[0];
      dispatch(initiateOrderSuccess(order));
      dispatch(fetchCurrentUserHasOrdersSuccess(true));
      return order;
    })
    .catch((e) => {
      dispatch(initiateOrderError(storableError(e)));
      const transactionIdMaybe = transactionId ? { transactionId: transactionId.uuid } : {};
      log.error(e, 'initiate-order-failed', {
        ...transactionIdMaybe,
        listingId: orderParams.listingId.uuid,
        bookingStart: orderParams.bookingStart,
        bookingEnd: orderParams.bookingEnd,
      });
      throw e;
    });
};

export const initiateBookingOrder = (bookingType, orderParams, transactionId) => (
  dispatch,
  getState,
  sdk
) => {
  dispatch(initiateBookingOrderRequest());

  let bodyParams;

  switch (bookingType) {
    case 'proposal':
      bodyParams = {
        processAlias: config.timeBookingProcessAlias,
        transition: TRANSITION_PROPOSE,
        params: orderParams,
      };
      break;

    case 'booking':
      bodyParams = transactionId
        ? {
            id: transactionId,
            transition: TRANSITION_BOOK_AFTER_ENQUIRY,
            params: orderParams,
          }
        : {
            processAlias: config.timeBookingProcessAlias,
            transition: TRANSITION_BOOK,
            params: orderParams,
          };
      break;

    default:
      // TODO: emit error
      break;
  }

  const queryParams = {
    include: ['booking', 'provider'],
    expand: true,
  };

  const createOrder = transactionId ? sdk.transactions.transition : sdk.transactions.initiate;

  return createOrder(bodyParams, queryParams)
    .then((response) => {
      const entities = denormalisedResponseEntities(response);
      const order = entities[0];
      dispatch(initiateBookingOrderSuccess(order));
      dispatch(fetchCurrentUserHasOrdersSuccess(true));
      return order;
    })
    .catch((e) => {
      dispatch(initiateBookingOrderError(storableError(e)));
      const transactionIdMaybe = transactionId ? { transactionId: transactionId.uuid } : {};
      log.error(e, 'initiate-booking-order-failed', {
        ...transactionIdMaybe,
        listingId: orderParams.listingId.uuid,
        bookingStart: orderParams.bookingStart,
        bookingEnd: orderParams.bookingEnd,
      });
      throw e;
    });
};

export const applyFeesAndDeductions = (
  ensuredCurrentUser,
  transactionId,
  deductFees,
  applyCredit
) => (dispatch) => {
  dispatch(applyFeesAndDeductionsRequest());

  return applyTransactionFeesAndDeductions(
    ensuredCurrentUser,
    transactionId,
    deductFees,
    applyCredit
  )
    .then(() => {
      dispatch(applyFeesAndDeductionsSuccess());
    })
    .catch((err) => {
      // @todo add logging
      dispatch(applyFeesAndDeductionsFailure(err));
      throw err;
    });
};

export const confirmPayment = (orderParams) => (dispatch, getState, sdk) => {
  dispatch(confirmPaymentRequest());

  const bodyParams = {
    id: orderParams.transactionId,
    transition: TRANSITION_CONFIRM_PAYMENT,
    params: {},
  };

  return sdk.transactions
    .transition(bodyParams)
    .then((response) => {
      const order = response.data.data;
      dispatch(confirmPaymentSuccess(order.id));
      return order;
    })
    .catch((e) => {
      dispatch(confirmPaymentError(storableError(e)));
      const transactionIdMaybe = orderParams.transactionId
        ? { transactionId: orderParams.transactionId.uuid }
        : {};
      log.error(e, 'initiate-order-failed', {
        ...transactionIdMaybe,
      });
      throw e;
    });
};

export const sendMessage = (params) => (dispatch, getState, sdk) => {
  const message = params.message;
  const orderId = params.id;

  if (message) {
    return sdk.messages
      .send({ transactionId: orderId, content: message })
      .then(() => {
        return { orderId, messageSuccess: true };
      })
      .catch((e) => {
        log.error(e, 'initial-message-send-failed', { txId: orderId });
        return { orderId, messageSuccess: false };
      });
  } else {
    return Promise.resolve({ orderId, messageSuccess: true });
  }
};

/**
 * Initiate the speculative transaction with the given booking details
 *
 * The API allows us to do speculative transaction initiation and
 * transitions. This way we can create a test transaction and get the
 * actual pricing information as if the transaction had been started,
 * without affecting the actual data.
 *
 * We store this speculative transaction in the page store and use the
 * pricing info for the booking breakdown to get a proper estimate for
 * the price with the chosen information.
 */
export const speculateTransaction = (transactionType, processAlias, params) => (
  dispatch,
  getState,
  sdk
) => {
  dispatch(speculateTransactionRequest(transactionType));
  let bodyParams = null;
  switch (transactionType) {
    case 'booking':
      bodyParams = {
        transition: TRANSITION_BOOK,
        processAlias,
        params: {
          ...params,
        },
      };
      break;
    case 'proposal':
      bodyParams = {
        transition: TRANSITION_PROPOSE,
        processAlias,
        params: {
          ...params,
        },
      };
      break;
    case 'payment':
      bodyParams = {
        transition: TRANSITION_REQUIRE_PAYMENT,
        processAlias,
        params: {
          ...params,
          cardToken: 'CheckoutPage_speculative_card_token',
        },
      };
      break;
    case 'outreachPayment':
      bodyParams = {
        transition: TRANSITION_REQUIRE_PAYMENT,
        processAlias,
        params: {
          ...params,
        },
      };
      break;

    default:
      // TODO: emit error
      break;
  }

  const queryParams = {
    include: ['booking', 'provider'],
    expand: true,
  };

  return sdk.transactions
    .initiateSpeculative(bodyParams, queryParams)
    .then((response) => {
      const entities = denormalisedResponseEntities(response);
      if (entities.length !== 1) {
        throw new Error('Expected a resource in the sdk.transactions.initiateSpeculative response');
      }
      const tx = entities[0];
      dispatch(speculateTransactionSuccess(transactionType, tx));
    })
    .catch((e) => {
      const { listingId, bookingStart, bookingEnd } = params;
      log.error(e, 'speculate-transaction-failed', {
        listingId: listingId.uuid,
        bookingStart,
        bookingEnd,
      });
      return dispatch(speculateTransactionError(transactionType, storableError(e)));
    });
};

export const getLineItemsForPayment = (
  methodPriceChoices,
  meetingMethod,
  quantity,
  deductFee,
  creditTotal = 0
) => {
  // Get the Money object from the price of the selected method. The format for these options is
  // `choice_xxx`, where `xxx` represents and integer of floating point value of the price.
  const price = new Money(
    parseFloat(methodPriceChoices[meetingMethod].split('_').pop()) * 100,
    config.currency
  );

  // Due to Sharetribe improperly calculating the line total (it can't handle decimal numbers) we
  // need to calculate the final amount here and set the quantity to 1. Otherwise we may end up with
  // $0.01 or $0.02 offsets in price after NPO selection.
  //
  // This is because Sharetribe is unable to handle decimal amounts for monetary values (instead it
  // shows monetary values in whole cents; $15.00 => 1500).
  const donationTotal = getTotalDonation(price, quantity, deductFee, creditTotal, true);

  return [
    {
      code: config.donationLineItem,
      includeFor: ['customer', 'provider'],
      unitPrice: donationTotal,
      quantity: 1,
      lineTotal: donationTotal,
      reversal: false,
    },
  ];
};

export const speculatePaymentTransaction = (pageData, transaction = null) => (
  dispatch,
  getState,
  sdk
) => {
  const ensuredTransaction = ensureTransaction(transaction);

  if (canSpeculatePaymentTransaction(pageData, ensuredTransaction)) {
    const paymentListingId = pageData.selectedNPO;
    const { bookingStart, bookingEnd } = pageData.bookingDates;
    const { meetingMethod, quantity, deductFee } = pageData.bookingData;
    const publicData = pageData.listing.attributes.publicData;
    const methodPriceChoices = publicData['methodPriceChoices']
      ? publicData['methodPriceChoices']
      : {};
    const lineItems = getLineItemsForPayment(
      methodPriceChoices,
      meetingMethod,
      quantity,
      deductFee
    );
    const processAlias = config.paymentBookingProcessAlias;
    console.log('DBG And this');

    return dispatch(
      speculateTransaction('payment', processAlias, {
        listingId: paymentListingId,
        bookingStart,
        bookingEnd,
        lineItems,
        quantity,
        protectedData: {
          meetingMethod,
          deductFee,
        },
      })
    );
  } else {
    return Promise.resolve();
  }
};

export const speculateOutreachPaymentTransaction = ({
  outreachOffer,
  outreachRequest,
  paymentMethod = METHOD_CREDIT_CARD,
}) => async (dispatch, getState, sdk) => {
  const deductFee = false; // will from now on always be false...
  const processAlias = config.outreachPaymentBookingProcessAlias;

  const currentUser = await dispatch(fetchCurrentUser());
  const operatorPercentage =
    currentUser &&
    currentUser.attributes &&
    currentUser.attributes.profile &&
    currentUser.attributes.profile.metadata &&
    currentUser.attributes.profile.metadata.operatorPercentage;

  const breakdown = calculateBreakdown(
    outreachRequest.donationAmount,
    1,
    deductFee,
    paymentMethod === METHOD_GIVSLY_CREDIT ? getState().credit.creditTotal : 0,
    operatorPercentage
  );

  return dispatch(
    speculateTransaction(
      'outreachPayment',
      processAlias,
      getTransactionParams({
        outreachOffer,
        outreachRequest,
        breakdown,
      })
    )
  );
};

// StripeCustomer is a relationship to the currentUser
// We need to fetch currentUser with correct params to include relationship
export const stripeCustomer = () => (dispatch, getState, sdk) => {
  dispatch(stripeCustomerRequest());

  return dispatch(fetchCurrentUser({ include: ['stripeCustomer.defaultPaymentMethod'] }))
    .then(() => {
      dispatch(stripeCustomerSuccess());
    })
    .then(() => {
      return getState().user.currentUser.stripeCustomer;
    })
    .catch((e) => {
      return dispatch(stripeCustomerError(storableError(e)));
    });
};

// @todo move to other duck file, these are general listings so this does not need to happen in here
export const NPOListings = (listingIds) => async (dispatch, getState, sdk) => {
  let listings = await Promise.all(
    listingIds.map((listingId) =>
      sdk.listings
        .show({
          id: listingId,
        })
        .then((result) => result.data.data)
        .catch(() => {
          return null;
        })
    )
  );

  listings = listings.filter((item) => Boolean(item));

  return dispatch(NPOListingsSuccess(listings));
};

export const fetchBlockedSlots = () => (dispatch, getState, sdk) => {
  dispatch(fetchBlockedSlotsRequest());

  const listing = sharetribe.listing.ensureListing(getState().CheckoutPage.listing);
  const { reservableSlots } = listing.attributes.publicData;
  const startDate = new Date();
  startDate.setHours(startDate.getHours() + givslyConfig.transaction.proposal.bookingOffset);

  // The end is the last day of the month which is 3 months into the future.
  const endDate = new Date();
  endDate.setDate(endDate.getDate() + 90);
  return dispatch(queryTimeSlots(listing.id.uuid, startDate, endDate))
    .then(async (timeSlots) => {
      // Find booked reservable slots
      const bookedReservableSlots = await findBookedReservableSlots(reservableSlots, timeSlots);
      const blockedSlots = [];

      // Map to blocked slots
      /* eslint-disable no-unused-vars */
      for (const reservableSlot of bookedReservableSlots) {
        blockedSlots.push({
          start: new Date(reservableSlot.start),
          end: new Date(reservableSlot.end),
        });
      }
      return blockedSlots;
    })
    .then((blockedSlots) => {
      dispatch(fetchBlockedSlotsSuccess(blockedSlots));
      return blockedSlots;
    })
    .catch((err) => {
      // This is allowed to fail, since this is non-critical
      dispatch(fetchBlockedSlotsFailure(storableError(err)));
    });
};

//FIXME move me elsewhere
// Outreach stuff

const {
  calculateBreakdown,
  convertToMoneyBreakdown,
  convertBreakdownToLineItems,
} = utils.transaction;

export function getTransactionParams({
  outreachRequest,
  outreachOffer,
  deductFee = true,
  breakdown,
}) {
  const startDate = getNearestHour(Date.now());

  return {
    listingId: outreachRequest.selectedNonProfitId,
    bookingStart: startDate,
    bookingEnd: new Date(+startDate + 1000 * 60 * 5), // 5min
    // @todo Add/check line items for outreach request
    lineItems: convertBreakdownToLineItems(convertToMoneyBreakdown(breakdown)),
    protectedData: {
      offerId: outreachOffer.id,
      requestId: outreachRequest.id,
      donationAmount: outreachRequest.donationAmount,
      deductFee,
    },
  };
}

export function getNearestHour(time) {
  const nearestHour = new Date(time);

  nearestHour.setHours(nearestHour.getHours() + 1);
  nearestHour.setMinutes(0);
  nearestHour.setSeconds(0);
  nearestHour.setMilliseconds(0);

  return nearestHour;
}

function getStripePaymentMethodId(ensuredCurrentUser) {
  const ensuredStripeCustomer = ensureStripeCustomer(ensuredCurrentUser.stripeCustomer);
  const ensuredDefaultPaymentMethod = ensurePaymentMethodCard(
    ensuredStripeCustomer.defaultPaymentMethod
  );
  const { stripePaymentMethodId = null } = ensuredDefaultPaymentMethod.attributes;

  console.dir({ ensuredStripeCustomer, ensuredDefaultPaymentMethod, stripePaymentMethodId });
  return stripePaymentMethodId;
}
