import React from 'react';
import { node, number, object, string, shape } from 'prop-types';
import { compose } from 'redux';
import { useMessages } from '../../util/localization';
import { withViewport } from '../../util/contextHelpers';
import { LayoutWrapperSideNav } from '../../components';
import { ensureCurrentUser } from '../../util/data';
import {
  IMPACT,
  NONPROFIT_INFO,
  NONPROFIT_PHOTOS,
  PAYOUT_DETAILS,
  // PREFERENCES,
} from '../MyNonprofitWizard/constants';
import { ONBOARDING_PROGRESS } from '../NonprofitOnboardingWizard/constants';

const MAX_HORIZONTAL_NAV_SCREEN_WIDTH = 1023;

const scrollToTab = (activeTab) => {
  const el = document.querySelector(`#${activeTab}Tab`);

  if (el) {
    el.scrollIntoView({
      block: 'end',
      inline: 'end',
      behavior: 'smooth',
    });
  }
};

const LayoutWrapperNonprofitOnboardingSideNavComponent = (props) => {
  const { activeTab, viewport, currentUser, pageName } = props;
  const getMessage = useMessages('LayoutWrapperNonprofitOnboardingSideNav');

  let hasScrolledToTab = false;

  const user = ensureCurrentUser(currentUser);
  const { onboardingProgress, onboardingCompleted } = user.attributes.profile.privateData;
  const { width } = viewport;
  const hasViewport = width > 0;
  const hasHorizontalTabLayout = hasViewport && width <= MAX_HORIZONTAL_NAV_SCREEN_WIDTH;
  const hasVerticalTabLayout = hasViewport && width > MAX_HORIZONTAL_NAV_SCREEN_WIDTH;
  const hasFontsLoaded = hasViewport && document.documentElement.classList.contains('fontsLoaded');

  // Check if scrollToTab call is needed (tab is not visible on mobile)
  if (!hasVerticalTabLayout && hasHorizontalTabLayout && !hasScrolledToTab && hasFontsLoaded) {
    scrollToTab(activeTab);
  }

  const tabs = [
    {
      text: getMessage('nonprofitInfo'),
      selected: activeTab === NONPROFIT_INFO,
      completed: onboardingProgress >= ONBOARDING_PROGRESS[NONPROFIT_INFO] || onboardingCompleted,
      id: NONPROFIT_INFO,
      linkProps: {
        name: pageName,
        params: {
          tab: NONPROFIT_INFO,
        },
      },
    },
    {
      text: getMessage('nonprofitPhotos'),
      selected: activeTab === NONPROFIT_PHOTOS,
      completed: onboardingProgress >= ONBOARDING_PROGRESS[NONPROFIT_PHOTOS] || onboardingCompleted,
      disabled:
        !onboardingProgress ||
        (onboardingProgress < ONBOARDING_PROGRESS[NONPROFIT_INFO] &&
          activeTab !== NONPROFIT_PHOTOS),
      id: NONPROFIT_PHOTOS,
      linkProps: {
        name: pageName,
        params: {
          tab: NONPROFIT_PHOTOS,
        },
      },
    },
    {
      text: getMessage('impact'),
      selected: activeTab === IMPACT,
      completed: onboardingProgress >= ONBOARDING_PROGRESS[IMPACT] || onboardingCompleted,
      disabled:
        !onboardingProgress ||
        (onboardingProgress < ONBOARDING_PROGRESS[NONPROFIT_PHOTOS] && activeTab !== IMPACT),
      id: IMPACT,
      linkProps: {
        name: pageName,
        params: {
          tab: IMPACT,
        },
      },
    },
    // {
    //   text: getMessage('preferences'),
    //   selected: activeTab === PREFERENCES,
    //   completed: onboardingProgress >= ONBOARDING_PROGRESS[PREFERENCES] || onboardingCompleted,
    //   disabled:
    //     !onboardingProgress ||
    //     (onboardingProgress < ONBOARDING_PROGRESS[IMPACT] && activeTab !== PREFERENCES),
    //   id: PREFERENCES,
    //   linkProps: {
    //     name: pageName,
    //     params: {
    //       tab: PREFERENCES,
    //     },
    //   },
    // },
    {
      text: getMessage('payoutDetails'),
      selected: activeTab === PAYOUT_DETAILS,
      completed: onboardingProgress >= ONBOARDING_PROGRESS[PAYOUT_DETAILS] || onboardingCompleted,
      disabled:
        !onboardingProgress ||
        (onboardingProgress < ONBOARDING_PROGRESS[NONPROFIT_PHOTOS] &&
          activeTab !== PAYOUT_DETAILS),
      id: PAYOUT_DETAILS,
      linkProps: {
        name: pageName,
        params: {
          tab: PAYOUT_DETAILS,
        },
      },
    },
  ];

  return <LayoutWrapperSideNav tabs={tabs} />;
};

LayoutWrapperNonprofitOnboardingSideNavComponent.defaultProps = {
  className: null,
  rootClassName: null,
  children: null,
  activeTab: null,
  pageName: 'NonprofitOnboardingPage',
};

LayoutWrapperNonprofitOnboardingSideNavComponent.propTypes = {
  activeTab: string.isRequired,
  currentUser: object,
  children: node,
  className: string,
  rootClassName: string,
  pageName: string,

  // from withViewport
  viewport: shape({
    width: number.isRequired,
    height: number.isRequired,
  }).isRequired,
};

const LayoutWrapperNonprofitOnboardingSideNav = compose(withViewport)(
  LayoutWrapperNonprofitOnboardingSideNavComponent
);

export default LayoutWrapperNonprofitOnboardingSideNav;
