import { types as sdkTypes } from './util/sdkLoader';
import { notificationDataUtil } from '@givsly/sharetribe-utils';

const { LatLng: SDKLatLng, LatLngBounds: SDKLatLngBounds } = sdkTypes;
const { FREQUENCY_DAILY, FREQUENCY_HOURLY } = notificationDataUtil;

/*
 * Marketplace specific configuration.
 */
export const amenities = [];

export const categories = [
  {
    key: 'Animals',
    label: 'Animals',
  },
  {
    key: 'Arts',
    label: 'Arts',
  },
  {
    key: 'Education',
    label: 'Education',
  },
  {
    key: 'Environment',
    label: 'Environment',
  },
  {
    key: 'Health',
    label: 'Health',
  },
  {
    key: 'Human Services',
    label: 'Human Services',
  },
  {
    key: 'Human and Civil Rights',
    label: 'Human and Civil Rights',
  },
  {
    key: 'International Development and Relief Services',
    label: 'International Development and Relief Services',
  },
  {
    key: 'Youth Development',
    label: 'Youth Development',
  },
];

export const interests = [
  {
    key: 'Career Development',
    label: 'Career Development',
  },
  {
    key: 'Product Development',
    label: 'Product Development',
  },
  {
    key: 'Industry Expertise',
    label: 'Industry Expertise',
  },
  {
    key: 'Company Recruitment',
    label: 'Company Recruitment',
  },
  {
    key: 'Investment',
    label: 'Investment',
  },
  {
    key: 'Sales & Marketing',
    label: 'Sales & Marketing',
  },
  {
    key: 'Networking',
    label: 'Networking',
  },
  {
    key: 'Software',
    label: 'Software',
  },
  {
    key: 'New Business',
    label: 'New Business',
  },
  {
    key: 'Strategy',
    label: 'Strategy',
  },
  {
    key: 'Partnerships',
    label: 'Partnerships',
  },
  {
    key: 'Technology',
    label: 'Technology',
  },
];

export const industries = [
  'Advertising & Marketing',
  'Software & IT Services',
  'Education',
  'Hardware & Networking',
  'Finance',
  'Manufacturing',
  'Corporate Services',
  'Healthcare',
  'Media & Communications',
  'Consumer Goods',
  'Entertainment',
  'Energy & Mining',
  'Retail',
  'Wellness & Fitness',
  'Transportation & Logistics',
  'Public Administration',
  'Recreation & Travel',
  'Nonprofit',
  'Real Estate',
  'Public Safety',
  'Legal',
  'Design',
  'Construction',
  'Arts',
  'Agriculture',
  'Restaurants',
];

export const locations = [
  {
    key: 'newyork',
    label: 'New York, New York',
    abbreviatedLabel: 'New York, NY',
  },
  {
    key: 'chicago',
    label: 'Chicago, Illinois',
    abbreviatedLabel: 'Chicago, IL',
  },
  {
    key: 'atlanta',
    label: 'Atlanta, Georgia',
    abbreviatedLabel: 'Atlanta, GA',
  },
  {
    key: 'sanfrancisco',
    label: 'San Francisco, California',
    abbreviatedLabel: 'San Francisco, CA',
  },
  {
    key: 'losangeles',
    label: 'Los Angeles, California',
    abbreviatedLabel: 'Los Angeles, CA',
  },
  {
    key: 'dallas',
    label: 'Dallas, Texas',
    abbreviatedLabel: 'Dallas, TX',
  },
  {
    key: 'washington',
    label: 'Washington, District of Colombia',
    abbreviatedLabel: 'Washington, DC',
  },
  {
    key: 'denver',
    label: 'Denver, Colorado',
    abbreviatedLabel: 'Denver, CO',
  },
  {
    key: 'portland',
    label: 'Portland, Oregon',
    abbreviatedLabel: 'Portland, OR',
  },
  {
    key: 'austin',
    label: 'Austin, Texas',
    abbreviatedLabel: 'Austin, TX',
  },
  {
    key: 'boston',
    label: 'Boston, Massachusetts',
    abbreviatedLabel: 'Boston, MA',
  },
  {
    key: 'minneapolis',
    label: 'Minneapolis, Minnesota',
    abbreviatedLabel: 'Minneapolis, MN',
  },
  {
    key: 'sandiego',
    label: 'San Diego, California',
    abbreviatedLabel: 'San Diego, CA',
  },
];

export const nonprofitLocations = [
  ...locations,
  {
    key: 'usa',
    label: 'National',
    abbreviatedLabel: 'National',
  },
];

export const featuredLocations = [
  {
    place: 'Anywhere',
    region: 'USA',
    origin: null,
  },
  {
    place: 'Atlanta',
    region: 'Georgia',
    origin: new SDKLatLng(33.7676931, -84.4906434),
    bounds: new SDKLatLngBounds(
      [34.07283101, -84.2183133275553], // NE latitude, longitude
      [33.5613169945145, -84.6715948207409] // SW latitude, longitude
    ),
  },
  {
    place: 'Chicago',
    region: 'Illinois',
    origin: new SDKLatLng(41.8336479, -87.8720455),
    bounds: new SDKLatLngBounds(
      [42.0234323628388, -87.523686109734], // NE latitude, longitude
      [41.6299229800457, -87.9058109309507] // SW latitude, longitude
    ),
  },
  {
    place: 'Los Angeles',
    region: 'California',
    origin: new SDKLatLng(34.0201613, -118.6919101),
    bounds: new SDKLatLngBounds(
      [34.161440999758, -118.121305008073], // NE latitude, longitude
      [33.9018913203336, -118.521456965901] // SW latitude, longitude
    ),
  },
  {
    place: 'New York',
    region: 'New York',
    origin: new SDKLatLng(40.6974034, -74.1197626),
    bounds: new SDKLatLngBounds(
      [40.917576401307, -73.7008392055224], // NE latitude, longitude
      [40.477399, -74.2590879797556] // SW latitude, longitude
    ),
  },
  {
    place: 'San Francisco',
    region: 'California',
    origin: new SDKLatLng(37.7576948, -122.4726193),
    bounds: new SDKLatLngBounds(
      [37.8324430069081, -122.354995082683], // NE latitude, longitude
      [37.6044780500533, -122.517910874663] // SW latitude, longitude
    ),
  },
];

export const meetingTypes = [
  {
    key: 'chat',
    label: 'Audio/video call',
  },
  {
    key: 'inPerson',
    label: 'In person',
  },
  // When this option is enabled, the pricing for events will be enabled automatically
  // {
  //   key: 'event',
  //   label: 'Event',
  // }
];

export const fixedMethodPrices = {
  chat: {
    choice_50: {
      donation: 5000,
      fee: 750,
      currency: 'USD',
    },
    choice_75: {
      donation: 7500,
      fee: 1125,
      currency: 'USD',
    },
    choice_100: {
      donation: 10000,
      fee: 1500,
      currency: 'USD',
    },
    choice_125: {
      donation: 12500,
      fee: 1875,
      currency: 'USD',
    },
    choice_150: {
      donation: 15000,
      fee: 2250,
      currency: 'USD',
    },
    choice_200: {
      donation: 20000,
      fee: 3000,
      currency: 'USD',
    },
    choice_250: {
      donation: 25000,
      fee: 3750,
      currency: 'USD',
    },
    choice_500: {
      donation: 50000,
      fee: 7500,
      currency: 'USD',
    },
    choice_750: {
      donation: 75000,
      fee: 3750, //fixme should not be in use anymore; needs testing
      currency: 'USD',
    },
    choice_1000: {
      donation: 100000,
      fee: 3750,
      currency: 'USD',
    },
    choice_1250: {
      donation: 125000,
      fee: 3750,
      currency: 'USD',
    },
    choice_1500: {
      donation: 150000,
      fee: 3750,
      currency: 'USD',
    },
    choice_2500: {
      donation: 250000,
      fee: 3750,
      currency: 'USD',
    },
  },
  inPerson: {
    choice_75: {
      donation: 7500,
      fee: 1125,
      currency: 'USD',
    },
    choice_100: {
      donation: 10000,
      fee: 1500,
      currency: 'USD',
    },
    choice_125: {
      donation: 12500,
      fee: 1875,
      currency: 'USD',
    },
    choice_150: {
      donation: 15000,
      fee: 2250,
      currency: 'USD',
    },
    choice_175: {
      donation: 17500,
      fee: 2625,
      currency: 'USD',
    },
    choice_200: {
      donation: 20000,
      fee: 3000,
      currency: 'USD',
    },
    choice_250: {
      donation: 25000,
      fee: 3750,
      currency: 'USD',
    },
    choice_500: {
      donation: 50000,
      fee: 7500,
      currency: 'USD',
    },
    choice_750: {
      donation: 75000,
      fee: 3750, //fixme should not be in use anymore; needs testing
      currency: 'USD',
    },
    choice_1000: {
      donation: 100000,
      fee: 3750,
      currency: 'USD',
    },
    choice_1250: {
      donation: 125000,
      fee: 3750,
      currency: 'USD',
    },
    choice_1500: {
      donation: 150000,
      fee: 3750,
      currency: 'USD',
    },
    choice_2500: {
      donation: 250000,
      fee: 3750,
      currency: 'USD',
    },
  },
  event: {
    choice_100: {
      donation: 10000,
      fee: 1500,
      currency: 'USD',
    },
    choice_150: {
      donation: 15000,
      fee: 2250,
      currency: 'USD',
    },
    choice_200: {
      donation: 20000,
      fee: 3000,
      currency: 'USD',
    },
  },
};

// Price filter configuration
// Note: unlike most prices this is not handled in subunits
export const priceFilterConfig = {
  min: 0,
  max: 1000,
  step: 5,
};

// Activate booking dates filter on search page
export const dateRangeFilterConfig = {
  active: true,
};

// Activate keyword filter on search page

// NOTE: If you are ordering search results by distance the keyword search can't be used at the same time.
// You can turn off ordering by distance in config.js file
export const keywordFilterConfig = {
  active: true,
};

export const timeSlotDurations = [15, 30, 45, 60];

// The minimal time between now and a time slot that needs to exist before it can be created or
// booked
export const minTimeSlotDistance = 360;

export const limitUi = false;
export const dayCountAvailableForTimeSlots = 365;
export const calendarVisibilityDays = 90;
export const calendarStartTimeOffSetHours = 6;
export const requireSignUpToken = false;
export const featuredListings = // Landing page "Meet professionals on Givsly" people
  process.env.REACT_APP_ENV === 'production'
    ? [
        '6132b2da-194f-4912-88a5-4a2a5acbe64e', // Jenika Newsum - 13th Sept update
        '60f1931b-f219-452e-bb16-5347b21045c3', // Patrick Schwing
        '612679ed-543f-462f-b279-6dc8cdcd8c5f', // Vanessa Phillips
      ]
    : [
        // Test listings
        '60ed0ea4-04d6-4171-b706-a3f093b27b74',
        '60f6c21d-8485-4872-b5ce-647fc30d2eb6',
        '5e2724ab-b172-4631-a27e-13b42bc29980',
      ];
export const featuredTestimonials = // Campaign landing page people
  process.env.REACT_APP_ENV === 'production'
    ? [
        '5ea7062b-2984-48e9-b61e-b237df2d27a5', // Stacy-Ann Effs
        '5e9310bc-d3cb-41de-ab4e-a729e67664ff', // Gregory Trani
        '5e1dc5ed-19e4-4720-a455-bb55bea8d754', // Joshua Lowcock
        '5e9a35b0-207b-47ba-90f3-140ed826045f', // Tara Nolan
      ]
    : [
        // Test listings
        '5ea1d6d1-8309-40b9-999e-5d2caa0b6455', // Luke Earthwalker
        '5ed0fe7f-4622-4750-aaeb-8c2662cd7d1b', // Severus Snape
        '5eceb27b-1434-46cb-beef-9e74a49e4019', // Doug Hurley
        '5ecec5a3-f481-4f13-9dfa-26e2f8549006', // Bob Behnken
      ];
/**
 * Landing page feature
 */
export const enableLandingPageFeature = true;
export const landingPageFeaturedNonprofitsA = // Landing page feature block, Havas Media
  process.env.REACT_APP_ENV === 'production'
    ? [
        '60abe212-a379-410b-a700-beababef54b1', // Queens of Castro
        '609b25d1-b549-4c10-bbb6-fca9cafbd7ff', // Color Bloq
        '5e7ca897-ead2-4607-bbd8-17e352a92f0a', // The Trevor Project
      ]
    : [
        '5fb73f29-b088-4c66-817a-119c8111d2bd', // Dogs for Better Lives
        '5dfb32ba-74df-411a-9b41-3a4e2e976135', // Free Arts NYC
        '5df9ad44-7008-4a4a-ac60-7007e2e2bb55', // The Marsha P. Johnson Institute
      ];
export const landingPageFeaturedNonprofitsB = // Landing page feature block, Omnicom Media Group
  process.env.REACT_APP_ENV === 'production'
    ? [
        '604a3008-d7d1-4bad-97ad-7984badfd8e5', // Active Minds
        '5e824cd1-45a9-4c32-9036-633253aee78d', // Sounds of Saving
        '5e792511-079f-412c-b04a-3f7de3aa34c5', // National Alliance on Mental Illness (NAMI)
      ]
    : [
        '5fb73f29-b088-4c66-817a-119c8111d2bd', // Dogs for Better Lives
        '5dfb32ba-74df-411a-9b41-3a4e2e976135', // Free Arts NYC
        '5df9ad44-7008-4a4a-ac60-7007e2e2bb55', // The Marsha P. Johnson Institute
      ];
export const landingPageFeaturedListings = // Landing page feature block, if people listings used
  process.env.REACT_APP_ENV === 'production'
    ? [
        '5e1a5b23-b52c-4a71-95d4-3525a3ebbbf2', // Ashley Casiano
        '5ea7062b-2984-48e9-b61e-b237df2d27a5', // Stacy-Ann Effs
        '5ebabfcd-4389-41de-b1ab-ceb32ab229e0', // Pedro L Rodriguez
      ]
    : [
        '5ecec5a3-f481-4f13-9dfa-26e2f8549006', // Bob Behnken
        '5ee73744-090c-4543-804e-c8c957d963a1', // Seppo Taalasmaa
        '5ea9f85a-6cad-4c89-8bca-74b1e39f0a3f', // John Wayne
      ];
export const directDonationLink = 'https://www.givsly.com/';
export const companySiteUrl = 'https://company.givsly.com';

export const isProduction = process.env.REACT_APP_ENV === 'production';
export const isSandbox = process.env.REACT_APP_ENV === 'sandbox';

// The terms of service and privacy modal which is shown to NPOs prior to the NPO sign-up process.
// At this stage there is no self-service for NPOs, so NPO sign-ups are processed by Givsly. For
// this reason NPOs never agree to terms of service and the privacy policy. This is enforced by
// showing a modal in which they must accept those before they can continue. The modal can be
// enabled or disabled with this flag.
export const enableNonprofitTermsAndPrivacyApprovalModal = true;

export const nonprofitTermsAndPrivacyApprovalVersions = ['2020-01-04', '2020-11-15'];

// Payment fees
export const OPERATOR_FEE_PERCENTAGE = 0.15;
export const PAYMENT_FEE_PERCENTAGE = 1 / (1 - 0.029);
export const PAYMENT_FEE_ADDITION = 0.3;

export const priceOptions = {
  chat: [50, 75, 100, 125, 150, 175],
  inPerson: [50, 75, 100, 125, 150, 175],
};

export const mentoringModalEnabled = process.env.REACT_APP_MENTORING_MODAL_ENABLED === 'true';

export const aw2020BookingAccessCodeValidationUrl =
  'https://virtual.advertisingweek.com/system/webservice/openapi.php?action=isValidPassCode';

export const notifications = {
  defaultFollowerDigestFrequency: FREQUENCY_DAILY,
  defaultFollowingDigestFrequency: FREQUENCY_HOURLY,
};

export const softWall = {
  excludePages: [1],
  excludePagesFromCount: [1],
  disabledBrowsingAfterFinalTrigger: true,
  pageTriggers: [2, 3],
};

// Campaigns
export const impactEstimateListings = // Landing page "Meet professionals on Givsly" people
  process.env.REACT_APP_ENV === 'production'
    ? [
        '5ea7062b-2984-48e9-b61e-b237df2d27a5', // Stacy-Ann Effs
        '5e9e1694-527c-481a-863e-96902c5cf4e1', // Doug Rozen
        '5ff8b640-ef59-4c96-b5c8-c63b4dad091c', // Jiyoung Kim
      ]
    : [
        // Test listings
        '5f4e4729-11b0-4b25-b657-be8237523286',
        '5e25b747-e2df-4bfd-a634-1db4fe4f32e2',
        '5e2724ab-b172-4631-a27e-13b42bc29980',
      ];
export const impactEstimateHighlightedNonprofit =
  process.env.REACT_APP_ENV === 'production'
    ? '5e98b0b2-6c6a-4a3f-8ea2-0278b24379df'
    : '5e15c995-d611-415b-9f02-2935d40067e7';
