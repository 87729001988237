import { object, string } from 'prop-types';
import React from 'react';
import classNames from 'classnames';
import css from './SectionVideo.css';

const SectionVideo = props => {
  const {
    children,
    className,
    innerRef,
    subTitle,
    title,
  } = props;

  return (
    <li className={classNames(css.root, className)} ref={innerRef}>
      {title ? (
        <h2 className={css.title}>{title}</h2>
      ) : null}
      {subTitle ? (
        <h3 className={css.subTitle}>{subTitle}</h3>
      ) : null}
      <span className={css.videoContainer}>
        {children}
      </span>
    </li>
  );
}

SectionVideo.propTypes = {
  className: string,
  innerRef: object,
  subTitle: string,
  title: string,
}

SectionVideo.defaultProps = {
  className: null,
  innerRef: null,
  subTitle: null,
  title: null,
}

export default SectionVideo;
