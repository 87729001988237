import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import debounce from 'lodash/debounce';
import { withRouter } from 'react-router-dom';
import classNames from 'classnames';
import { FormattedMessage } from 'react-intl';

import { intlShape, injectIntl } from '../../util/reactIntl';
import { IconSpinner } from '../../components';
import IconHourGlass from '../LocationAutocompleteInput/IconHourGlass';
import { onChangeSearchString, doSearch } from '../../ducks/NonprofitSearch.duck';
import { getMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import { createSlug } from '../../util/urlHelpers';

import css from './NonprofitSearch.css';
import { createResourceLocatorString } from '../../util/routes';
import routeConfiguration from '../../routeConfiguration';

const DEBOUNCE_WAIT_TIME = 600;
const MINIMUM_KEYWORDS_LENGTH = 3;

const getNonprofitsFromResults = (results, searchString) => {
  if (!searchString || !results || !results.length) {
    return [];
  }

  return results.filter((l) => {
    return l.attributes.title.search(new RegExp(searchString, 'i')) !== -1;
  });
};

const debouncedSearch = debounce(
  (onSearch, id, searchString) => {
    onSearch(id, searchString);
  },
  DEBOUNCE_WAIT_TIME,
  { leading: false, trailing: true }
);

class NonprofitSearchComponent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      inputHasFocus: false,
      keywords: null,
    };

    // ref to this component
    this.rootNode = null;
    this.onChange = this.onChange.bind(this);
    this.handleOnFocus = this.handleOnFocus.bind(this);
    this.handleOnBlur = this.handleOnBlur.bind(this);
    this.handleClickResult = this.handleClickResult.bind(this);
    this.onKeyUp = this.onKeyUp.bind(this);
  }

  handleOnFocus(e) {
    this.setState({ inputHasFocus: true });
  }

  handleOnBlur(e) {
    // FocusEvent is fired faster than the link elements native click handler
    // gets its own event. Therefore, we need to check the origin of this FocusEvent.
    // console.log('Blurring that shit!');
    if (!this.rootNode.contains(e.relatedTarget)) {
      debouncedSearch.cancel();
      this.setState({ inputHasFocus: false });
    }
  }

  handleClickResult(url) {
    debouncedSearch.cancel();
    this.setState({
      inputHasFocus: false,
    });
    this.props.history.push(url);
  }

  onChange(e) {
    const { id, onChange, onSearch } = this.props;
    const newValue = e.target.value;

    onChange(id, newValue);
    this.setState({ keywords: newValue });

    if (newValue && newValue.length >= MINIMUM_KEYWORDS_LENGTH) {
      debouncedSearch(onSearch, id, newValue);
    }
  }

  onKeyUp(e) {
    if (e.keyCode === 13) {
      // Enter
      debouncedSearch.flush();
    }
    if (e.keyCode === 27) {
      // Esc
      debouncedSearch.cancel();
    }
  }

  render() {
    const {
      id,
      rootClassName,
      className,
      searchString,
      searchResults,
      searchInProgress,
      searchError,
      intl,
    } = this.props;

    const placeholder = intl.formatMessage({ id: 'NonprofitSearch.placeholder' });
    const classes = classNames(css.root, rootClassName, className);

    const nonprofitResults = getNonprofitsFromResults(searchResults, searchString);

    const hasResults = nonprofitResults.length > 0;
    const hasKeywords =
      this.state.keywords && this.state.keywords.length >= MINIMUM_KEYWORDS_LENGTH;

    const renderResults = this.state.inputHasFocus && hasResults && hasKeywords;
    const error = searchError ? (
      <p className={css.error}>
        <FormattedMessage id="NonprofitSearch.searchFailed" />
      </p>
    ) : null;

    return (
      <div
        id={id}
        className={classes}
        ref={(node) => {
          this.rootNode = node;
        }}
      >
        {searchInProgress ? <IconSpinner className={css.iconSpinner} /> : <IconHourGlass />}
        <input
          className={css.input}
          type="search"
          autoComplete="off"
          autoFocus={false}
          placeholder={placeholder}
          name={`${id}-input`}
          value={searchString}
          disabled={false}
          onFocus={this.handleOnFocus}
          onBlur={this.handleOnBlur}
          onChange={this.onChange}
          onKeyUp={this.onKeyUp}
        />
        {renderResults ? (
          <div className={css.searchResults}>
            {error}
            {nonprofitResults && nonprofitResults.length ? (
              <ul className={css.searchResultList}>
                {nonprofitResults.map((l) => {
                  const title = l.attributes.title;
                  const subTitle = l.attributes.publicData.organizationCategory;
                  const url = createResourceLocatorString(
                    'ListingPageCanonical',
                    routeConfiguration(),
                    { id: l.id.uuid, slug: createSlug(title) },
                    {}
                  );

                  return (
                    <li
                      key={l.id.uuid}
                      className={classNames(css.searchResultItem, css.searchResultVolunteer)}
                    >
                      <a href={url} onMouseDown={() => this.handleClickResult(url)}>
                        <div className={css.searchResultItemTitle}>{title}</div>
                        {subTitle ? (
                          <div className={css.searchResultItemSubTitle}>{subTitle}</div>
                        ) : null}
                      </a>
                    </li>
                  );
                })}
              </ul>
            ) : null}
          </div>
        ) : null}
      </div>
    );
  }
}

const { string } = PropTypes;

NonprofitSearchComponent.defaultProps = {
  rootClassName: null,
  className: null,
};

NonprofitSearchComponent.propTypes = {
  id: string.isRequired,
  rootClassName: string,
  className: string,

  // from injectIntl
  intl: intlShape.isRequired,
};

const mapStateToProps = (state, props) => {
  const { searchStrings, searchResults, searchesInProgress, searchErrors } = state.NonprofitSearch;

  const listings =
    searchResults[props.id] && searchResults[props.id].length
      ? getMarketplaceEntities(state, searchResults[props.id])
      : [];

  return {
    // If the searchString is undefined at the start, React thinks the input is unmanaged
    searchString: searchStrings[props.id] ? searchStrings[props.id] : '',
    searchResults: listings,
    searchInProgress: searchesInProgress[props.id],
    searchError: searchErrors[props.id],
  };
};

const mapDispatchToProps = (dispatch) => ({
  onChange: (componentId, searchString) =>
    dispatch(onChangeSearchString(componentId, searchString)),
  onSearch: (componentId, searchString) => dispatch(doSearch(componentId, searchString)),
});

const NonprofitSearch = compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  injectIntl
)(NonprofitSearchComponent);

export default NonprofitSearch;
