import SectionMaybe from '../../SectionMaybe';
import css from '../NonprofitProfile.css';
import React from 'react';
import { shape, string } from 'prop-types';

class NonprofitAddressMaybe extends SectionMaybe {
  render() {
    const { address, title } = this.props;
    return address && title ? (
      <div className={css.section}>
        <h2 className={css.sectionHeader}>{title}</h2>
        <div className={css.sectionContent}>
          {address.street}
          <br />
          {address.city}, {address.state} {address.postalCode}
        </div>
      </div>
    ) : null;
  }
}

NonprofitAddressMaybe.propTypes = {
  title: string,
  address: shape({
    city: string,
    postalCode: string,
    state: string,
    street: string,
  }),
};

export default NonprofitAddressMaybe;
