import classNames from 'classnames';
import { bool, func, object } from 'prop-types';
import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { compose } from 'redux';
import { formatMoney } from '../../util/currency';
import { withMessages } from '../../util/localization';
import {
  ESTIMATED_TRANSACTION_ID,
  TRANSITION_CUSTOMER_CANCEL,
  TRANSITION_DECLINE,
  TRANSITION_DECLINE_PROPOSAL,
  TRANSITION_EXPIRE,
  TRANSITION_PROVIDER_CANCEL,
} from '../../util/transaction';
import {
  LINE_ITEM_GIVSLY_CREDIT,
  LINE_ITEM_OPERATOR_FEE,
  LINE_ITEM_PAYMENT_FEE,
  propTypes,
} from '../../util/types';
import css from './BookingBreakdown.css';
import FullRefundMaybe from './FullRefundMaybe';
import LineItemDonation from './LineItemDonation';
import LineItemWithTooltip from './LineItemWithTooltip';

class CustomerBreakdown extends React.Component {
  get footNoteMessageId() {
    const { ensuredBookingTransaction } = this.props;
    const { lastTransition } = ensuredBookingTransaction.attributes;

    switch (lastTransition) {
      case TRANSITION_CUSTOMER_CANCEL:
        return 'customer.customerCancelled';
      case TRANSITION_PROVIDER_CANCEL:
        return 'customer.providerCancelled';
      case TRANSITION_DECLINE_PROPOSAL:
      case TRANSITION_DECLINE:
        return 'customer.providerDeclined';
      case TRANSITION_EXPIRE:
        return 'customer.expired';
      default:
        return null;
    }
  }

  get footNote() {
    const { getMessage } = this.props;
    const messageId = this.footNoteMessageId;
    return messageId ? (
      <div className={css.customerFootnoteRed}>{getMessage(messageId)}</div>
    ) : null;
  }

  get lineItemPaymentFee() {
    const { ensuredPaymentTransaction, getMessage } = this.props;
    const lineItem = ensuredPaymentTransaction.attributes.lineItems.find(
      (lineItem) => lineItem.code === LINE_ITEM_PAYMENT_FEE
    );

    return lineItem && lineItem.lineTotal.amount > 0 ? (
      <LineItemWithTooltip
        label={getMessage('paymentFee')}
        tooltipLabel={getMessage('paymentFee')}
        tooltipBody={getMessage('paymentFee.tooltip')}
        moneyValue={lineItem.lineTotal}
      />
    ) : null;
  }

  get lineItemAppliedGivslyCredit() {
    const { ensuredPaymentTransaction, getMessage, intl } = this.props;
    const lineItem = ensuredPaymentTransaction.attributes.lineItems.find(
      (lineItem) => lineItem.code === LINE_ITEM_GIVSLY_CREDIT
    );

    return lineItem && lineItem.lineTotal.amount < 0 ? (
      <div key={'givsly-credit'} className={classNames(css.lineItem, css.lineItemRegular)}>
        <span className={css.itemLabel}>{getMessage('appliedGivslyCredit')}</span>
        <span className={css.itemValue}>{formatMoney(intl, lineItem.lineTotal)}</span>
      </div>
    ) : null;
  }

  get lineItemOperatorFee() {
    const { ensuredPaymentTransaction, getMessage } = this.props;
    const lineItem = ensuredPaymentTransaction.attributes.lineItems.find(
      (lineItem) => lineItem.code === LINE_ITEM_OPERATOR_FEE
    );

    return lineItem ? (
      <LineItemWithTooltip
        label={getMessage('commission')}
        tooltipLabel={getMessage('operatorFee')}
        tooltipBody={getMessage('operatorFee.tooltip')}
        moneyValue={lineItem.lineTotal}
      />
    ) : null;
  }

  get lineItemTotalPrice() {
    const { ensuredPaymentTransaction, getMessage, intl } = this.props;
    const { payinTotal } = ensuredPaymentTransaction.attributes;

    return (
      <>
        <hr className={css.totalDivider} />
        <div className={css.lineItemTotal}>
          <div className={css.totalLabel}>{getMessage('total')}</div>
          <div className={css.totalPrice}>{formatMoney(intl, payinTotal)}</div>
        </div>
      </>
    );
  }

  get deductFeeOption() {
    const { deductFee, ensuredPaymentTransaction, getMessage, onDeductFeeChange } = this.props;
    return !ensuredPaymentTransaction.id ||
      ensuredPaymentTransaction.id.uuid === ESTIMATED_TRANSACTION_ID ? (
      <div className={css.commissionFeeContainer}>
        <input
          className={css.commissionFeeInput}
          id={'commissionFeeInput'}
          type={'checkbox'}
          value={1}
          checked={deductFee ? 'checked' : ''}
          onChange={onDeductFeeChange}
        />
        <label className={css.commissionFeeLabel} htmlFor={'commissionFeeInput'}>
          {getMessage('commissionFeeLabel')}
        </label>
      </div>
    ) : null;
  }

  render() {
    const { ensuredPaymentTransaction } = this.props;

    return ensuredPaymentTransaction.id ? (
      <>
        <h2 className={css.title}>
          <FormattedMessage id="BookingBreakdown.title" />
        </h2>
        <LineItemDonation transaction={ensuredPaymentTransaction} />
        {this.lineItemOperatorFee}
        {this.lineItemPaymentFee}
        {this.lineItemAppliedGivslyCredit}
        {this.deductFeeOption}
        {this.lineItemTotalPrice}
        <FullRefundMaybe transaction={ensuredPaymentTransaction} />
        {this.footNote}
        <p className={css.creditCardDisclaimer}>
          For non U.S. credit cards the credit card issuer or your bank may additionally charge a
          foreign transaction and/or exchange fee not visible in this Givsly payment breakdown.
          These charges are not controlled or refundable by Givsly.
        </p>
      </>
    ) : null;
  }
}

CustomerBreakdown.propTypes = {
  deductFee: bool,
  ensuredBookingTransaction: object,
  ensuredPaymentTransaction: propTypes.transaction.isRequired,
  onDeductFeeChange: func,
};

CustomerBreakdown.defaultProps = {
  deductFee: false,
  onDeductFeeChange: () => {},
};

const withLocalizedMessages = (container) => {
  return withMessages(container, 'BookingBreakdown');
};

export default compose(withLocalizedMessages, injectIntl)(CustomerBreakdown);
