import React from 'react';
import PropTypes from 'prop-types';

import css from './IconDefaultUserAvatar.css';

const IconDefaultUserAvatar = (props) => {
  const { className } = props;
  return (
    <svg className={className} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 240 240">
      <g fill="none" fillRule="evenodd">
        <circle className={css.background} cx="120" cy="120" r="120" />
        <g className={css.foreground}>
          <path d="M119.8 121c-13.254 0-24-10.746-24-24s10.746-24 24-24c13.256 0 24 10.746 24 24s-10.744 24-24 24M160.6 168.2h-16c0-13.674-11.126-24.8-24.8-24.8-13.674 0-24.8 11.126-24.8 24.8H79c0-22.497 18.302-40.8 40.8-40.8 22.498 0 40.8 18.303 40.8 40.8" />
        </g>
      </g>
    </svg>
  );
};

IconDefaultUserAvatar.defaultProps = { className: null };

const { string } = PropTypes;

IconDefaultUserAvatar.propTypes = { className: string };

export default IconDefaultUserAvatar;
