import moment from 'moment-timezone';
import { events } from '../assets/events/events-configuration';
import { events as eventsTestOverrides } from '../assets/events/events-configuration-test';
import { events as eventsSandboxOverrides } from '../assets/events/events-configuration-sandbox';
import { isSandboxMarketplace, isTestMarketplace } from './environment';
import merge from 'lodash/merge';
import { ensureCurrentUser, ensureOwnListing } from './data';
import { isBefore, isSameOrAfter } from './timeSlots';

/**
 * List all user's visible event roles for profile page
 * Include only roles from events, where booking has been started
 */
export const getUserVisibleEventRoleNames = (events, ensuredListing, timezone) => {
  if (!events || !ensuredListing) return [];
  const userEventRoles = ensuredListing.attributes.publicData.eventRoles;

  if (!userEventRoles) return [];

  const allEventRoles = flatMapEventUserRoles(
    events.filter((event) => isEventBookingOpen(event, timezone))
  );

  return formatEventRoles(userEventRoles, allEventRoles);
};

/**
 * List all user's visible event roles for listing card badges
 * Include only roles from events, where booking has been started and event is not ended
 */
export const getUserVisibleEventRoleBadges = (ensuredListing) => {
  const events = getEvents();
  const userEventRoles = ensuredListing.attributes.publicData.eventRoles;
  if (!userEventRoles) return [];

  // This should be removed, as timezone is not important here. It's required by the event functions
  const timezone = moment.tz.guess();
  const allEventRoles = flatMapEventUserRoles(
    events.filter((event) => isEventBookingOpen(event, timezone) && !isEventEnded(event, timezone))
  );

  return formatEventRoles(userEventRoles, allEventRoles);
};

const formatEventRoles = (userEventRoles, allEventRoles) =>
  userEventRoles.reduce((roles, roleKey) => {
    const role = allEventRoles.find((role) => role.key === roleKey);
    if (!role || !role.showAsBadge) return roles;

    return [...roles, `${role.event.shortTitle || role.event.title} ${role.label}`];
  }, []);

export const isEventBookingOpen = (event, timezone) => {
  const eventBookingOpen = moment.tz(event.bookingOpen.from, event.timezone).tz(timezone);
  return moment().tz(timezone).isAfter(eventBookingOpen);
};

export const isEventEnded = (event, timezone) => {
  const eventActiveTo = moment.tz(event.active.to, event.timezone).tz(timezone);
  return moment().tz(timezone).isAfter(eventActiveTo);
};

export const flatMapEventUserRoles = (events) => {
  return events.reduce(
    (arr, event) => [...arr, ...event.userRoles.map((r) => ({ ...r, event }))],
    []
  );
};

const getUserEventRole = (currentUserListing, roles) => {
  const userEventRoles =
    ensureOwnListing(currentUserListing).attributes.publicData.eventRoles || [];

  return roles.find((role) => userEventRoles.includes(role)) || 'default';
};

export const isVolunteeringOpenForUser = (event, currentUserListing, currentUserTimezone) => {
  if (!event.volunteeringOpen) return true; // No volunteer opening time defined. Volunteering is open for all.

  const eventVolunteerOpenTimeRoles = Object.keys(event.volunteeringOpen).filter(
    (role) => role !== 'default'
  );

  const volunteerOpenTimeKey = getUserEventRole(currentUserListing, eventVolunteerOpenTimeRoles);

  return isSameOrAfter(
    new Date(),
    currentUserTimezone,
    event.volunteeringOpen[volunteerOpenTimeKey].from,
    event.timezone,
    'minute'
  );
};

export const getEventsAvailableForVolunteering = (currentUser, currentUserListing) => {
  const timezone = ensureCurrentUser(currentUser).attributes.profile.publicData.timezone;

  return getEvents()
    .filter((event) => isBefore(new Date(), timezone, event.active.to, event.timezone, 'minute')) // Filter out events that are ended
    .filter((event) => isVolunteeringOpenForUser(event, currentUserListing, timezone)); // Filter out events that are not open for volunteering for current user
};

/**
 * Handles event configuration loading and environment specific overrides
 *
 * @param eventKey
 * @returns {*}
 */
export const getEvent = (eventKey) => {
  if (isSandboxMarketplace()) {
    return overrideEventConfiguration(
      events.find((event) => event.key === eventKey),
      eventsSandboxOverrides.find((event) => event.key === eventKey)
    );
  } else if (isTestMarketplace()) {
    return overrideEventConfiguration(
      events.find((event) => event.key === eventKey),
      eventsTestOverrides.find((event) => event.key === eventKey)
    );
  } else {
    return events.find((event) => event.key === eventKey);
  }
};

export const getEvents = () => {
  if (isSandboxMarketplace()) {
    return overrideEventConfiguration(events, eventsSandboxOverrides);
  } else if (isTestMarketplace()) {
    return overrideEventConfiguration(events, eventsTestOverrides);
  } else {
    return events;
  }
};

const overrideEventConfiguration = (eventConfiguration, eventConfigurationOverrides) => {
  return merge(eventConfiguration, eventConfigurationOverrides);
};

export const getEventBookingOpenMoment = (event) => {
  if (event.bookingOpen) {
    return moment.tz(event.bookingOpen.from, event.timezone);
  } else {
    return moment().tz(event.timezone);
  }
};

/**
 * Determines whether or not an access code is required for booking a meeting with the specified
 * time slot. This only applies to event time slots. Any match between the user roles and the roles
 * specified inside the event configuration will be sufficient to trigger the access code
 * requirement.
 *
 * @param slot
 * @param ensuredListing
 * @returns {boolean}
 */
export const isAccessCodeRequiredForSlot = (slot, ensuredListing) => {
  if (!slot || !slot.event) return false;
  const eventRolesNeedingAccessCode = getEvent(slot.event).accessCodeForBookingRequired || [];
  const listingRoles = ensuredListing.attributes.publicData.eventRoles || [];
  return eventRolesNeedingAccessCode.some((role) => listingRoles.includes(role));
};
