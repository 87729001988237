import React, { Component } from 'react';
import css from './FieldDateTimeSelector.css';
import { FieldDateInput, FieldTimeSelect } from '../../components';
import moment from 'moment';
import { array, func, instanceOf, number, oneOf, shape, string } from 'prop-types';
import { intlShape } from '../../util/reactIntl';
import classNames from 'classnames';

export const DATE_FIELD_TYPE_CALENDAR = 'calendar';
export const DATE_FIELD_TYPE_SELECT = 'select';

class FieldDateTimeSelector extends Component {
  constructor(props) {
    super(props);

    this.state = {
      disallowedTimes: [],
    };

    this.updateDisallowedTimes = this.updateDisallowedTimes.bind(this);
  }

  get selectedDate() {
    return this.props.value.date.date && moment.tz(this.props.value.date.date, this.props.timezone);
  }

  renderDay = (date) => {
    const { timezone } = this.props;
    const today = moment().tz(timezone);
    const isToday = today.isSame(date, 'd');
    const isSelectedDate = this.selectedDate && date.isSame(this.selectedDate, 'd');
    const isBlocked = this.isDayBlocked(date);

    return (
      <div
        className={classNames(css.calendarDay, {
          [css.daySelected]: isSelectedDate,
          [css.dayToday]: isToday,
          [css.dayBlocked]: isBlocked,
        })}
        id={`day-${date.format('YYYYMMDD')}`}
      >
        <span className={classNames(css.dayName)}>
          <span>{date.date()}</span>
        </span>
      </div>
    );
  };

  isDayBlocked = (date) => {
    const { availabilityOffset, timezone } = this.props;
    const today = moment().tz(timezone);
    const offset = today.clone().add(availabilityOffset.value, availabilityOffset.unit);
    return offset.isAfter(date, 'd');
  };

  get dateField() {
    const { datePlaceholder, id, name, intl } = this.props;

    const commonFieldProps = {
      label: intl.formatMessage({ id: 'TimeSlotForm.date' }),
      id: `${id}-date`,
    };

    return (
      <FieldDateInput
        renderDayContents={(date) => this.renderDay(date)}
        name={`${name}.date`}
        isDayBlocked={this.isDayBlocked}
        placeholderText={datePlaceholder}
        onChange={this.updateDisallowedTimes}
        {...commonFieldProps}
      />
    );
  }

  /**
   * Updates the disallowed times based on the selected date. Uses standard date handling as much as
   * possible and avoids using moment where possible.
   *
   * @param   {Date}  date
   * @returns {Promise<void>}
   */
  async updateDisallowedTimes({ date }) {
    const { blockedSlots, timezone } = this.props;
    const disallowedTimes = [];

    // Determine the start and end boundaries of the filter
    const startBound = moment.tz(date, timezone).toDate();
    const endBound = moment.tz(date, timezone).add(1, 'day').toDate();

    // Filter out only such that the slots within the bound remain, this will be faster when running
    // conversions to the users timezone
    return Promise.all(
      blockedSlots
        .filter((slot) => {
          return (
            (slot.start >= startBound && slot.start < endBound) ||
            (slot.end > startBound && slot.end <= endBound)
          );
        })
        .map((slot) => {
          return new Promise((resolve) => {
            const slotIterator = moment(slot.start).tz(timezone);
            const slotEnd = moment(slot.end).tz(timezone);

            while (slotIterator.isBefore(slotEnd)) {
              // Increments the iterator with a 15 minute step
              disallowedTimes.push(slotIterator.format('HH:mm'));
              slotIterator.add(15, 'minutes');

              // Failsafe, if for some reason the range goes beyond 24 hours or end < start
              if (disallowedTimes.length >= 96) break;
            }

            resolve(slot);
          });
        })
    ).then(() => {
      this.setState({
        disallowedTimes,
      });
    });
  }

  render() {
    const { id, form, intl, name, timePlaceholder, timezone } = this.props;
    return (
      <div className={css.container}>
        <div className={css.dateSelector}>{this.dateField}</div>
        <div className={css.timeSelector}>
          <FieldTimeSelect
            bookingDate={this.selectedDate}
            disallowedTimes={this.state.disallowedTimes}
            form={form}
            intl={intl}
            name={`${name}.time`}
            id={`${id}-time`}
            onFetchTimeSlots={() => {}}
            label={intl.formatMessage({ id: 'ProposeMeetingForm.starts' })}
            timezone={timezone}
            placeholderTime={timePlaceholder}
          />
        </div>
      </div>
    );
  }
}

FieldDateTimeSelector.propTypes = {
  allowedDates: shape({
    startDate: string,
    endDate: string,
  }),
  availabilityOffset: shape({
    unit: oneOf(['day', 'days', 'hour', 'hours', 'minute', 'minutes']).isRequired,
    value: number.isRequired,
  }),
  blockedSlots: array,
  datePlaceholder: string,
  name: string,
  value: shape({
    date: shape({ date: instanceOf(Date) }),
    time: string,
  }),
  form: shape({
    mutators: shape({
      touch: func.isRequired,
    }).isRequired,
  }).isRequired,
  intl: intlShape.isRequired,
  timePlaceholder: string,
  timezone: string.isRequired,
  type: oneOf([DATE_FIELD_TYPE_CALENDAR, DATE_FIELD_TYPE_SELECT]),
};

FieldDateTimeSelector.defaultProps = {
  availabilityOffset: {
    unit: 'hours',
    value: 0,
  },
  blockedSlots: [],
  datePlaceholder: null,
  duration: null,
  isEvent: false,
  timePlaceholder: null,
  type: DATE_FIELD_TYPE_CALENDAR,
  value: {
    date: { date: null },
    time: null,
  },
};

export default FieldDateTimeSelector;
