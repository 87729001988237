import React from 'react';
import { FormattedMessage } from '../../util/reactIntl';
import { propTypes } from '../../util/types';

import css from './BookingBreakdown.css';

const LineItemMeetingMethod = (props) => {
  const { transaction } = props;

  if (
    !transaction ||
    !transaction.attributes ||
    !transaction.attributes.protectedData ||
    !transaction.attributes.protectedData.meetingMethod
  ) {
    return null;
  }

  return (
    <div className={css.detailItem}>
      <div className={css.detailItemLabel}>
        <FormattedMessage id="BookingBreakdown.meetingMethod" />
      </div>
      <div className={css.detailItemValue}>
        <FormattedMessage
          id={`MeetingMethod.${transaction.attributes.protectedData.meetingMethod}`}
        />
      </div>
    </div>
  );
};

LineItemMeetingMethod.propTypes = {
  transaction: propTypes.transaction,
};

export default LineItemMeetingMethod;
