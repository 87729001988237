import { array, number, string } from 'prop-types';
import React from 'react';
import { FormattedDate, FormattedMessage } from '../../util/reactIntl';
import css from './BookingBreakdown.css';
import moment from 'moment-timezone';
import classNames from 'classnames';

const LineItemProposedTimes = (props) => {
  const { meetingDuration, suggestedTimes, timezone } = props;
  const dateFormatOptions = {
    weekday: 'long',
    month: 'long',
    day: 'numeric',
  };

  return Array.isArray(suggestedTimes) && suggestedTimes.length ? (
    <div className={css.detailItem}>
      <div className={css.detailItemLabel}>
        <FormattedMessage id="BookingBreakdown.proposedTimes" />
      </div>
      {suggestedTimes.map((suggestedTime, index) => {
        const startMoment = moment.tz(suggestedTime, 'UTC').tz(timezone);
        const endMoment = startMoment.clone().add(meetingDuration, 'minutes');
        return (
          <div
            className={classNames(css.detailItemValue, css.proposedDetailItemValue)}
            key={`proposedTime${index}`}
          >
            <div>
              <FormattedDate
                value={startMoment.toDate()}
                {...dateFormatOptions}
                timeZone={timezone}
              />
            </div>
            <div>
              {startMoment.format('h:mm a')}
              {' - '}
              {endMoment.format('h:mm a')} ({endMoment.zoneAbbr()})
            </div>
          </div>
        );
      })}
    </div>
  ) : null;
};

LineItemProposedTimes.propTypes = {
  meetingDuration: number.isRequired,
  suggestedTimes: array,
  timezone: string.isRequired,
};

LineItemProposedTimes.defaultProps = {
  suggestedTimes: [],
};

export default LineItemProposedTimes;
