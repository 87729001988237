import { InlineTextButton, NamedLink, PrimaryButton } from '../../../../components';
import React from 'react';
import { bool, func, oneOf, shape, string } from 'prop-types';
import classNames from 'classnames';
import css from './EventLandingPageButton.css';

const BUTTON_PRIMARY = 'primary';
const BUTTON_LINK = 'inline-link';

const EventLandingPageButton = (props) => {
  const { button, className, isAuthenticated, buttonType, trackEvent } = props;

  const type = buttonType || BUTTON_PRIMARY;

  const selectedButton = isAuthenticated ? button.loggedIn : button.notLoggedIn;

  if (selectedButton && type === BUTTON_PRIMARY) {
    return (
      <NamedLink name={selectedButton.name} params={selectedButton.params}>
        <PrimaryButton
          type="button"
          className={classNames(className, css.button)}
          onClick={() => trackEvent({ action: 'Click', value: selectedButton.label })}
        >
          {selectedButton.label}
        </PrimaryButton>
      </NamedLink>
    );
  }

  if (selectedButton && type === BUTTON_LINK) {
    return (
      <NamedLink name={selectedButton.name} params={selectedButton.params}>
        <InlineTextButton
          type="button"
          className={css.inlineTextButton}
          onClick={() => trackEvent({ action: 'Click', value: selectedButton.label })}
        >
          {selectedButton.label}
        </InlineTextButton>
      </NamedLink>
    );
  }

  return null;
};

EventLandingPageButton.propTypes = {
  button: shape({
    loggedIn: shape({
      label: string.isRequired,
      name: string.isRequired,
    }),
    notLoggedIn: shape({
      label: string.isRequired,
      name: string.isRequired,
    }),
  }),
  buttonType: oneOf([BUTTON_LINK, BUTTON_PRIMARY]),
  className: string,
  eventKey: string,
  listingId: string,
  isAuthenticated: bool.isRequired,
  trackEvent: func.isRequired,
};

export default EventLandingPageButton;
