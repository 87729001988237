import React from 'react';
import { oneOf, string, object, bool, func, number, array } from 'prop-types';
import { compose } from 'redux';
import { withMessages } from '../../util/localization';
import { intlShape, injectIntl } from '../../util/reactIntl';
import classNames from 'classnames';
import { propTypes } from '../../util/types';
import CustomerBreakdown from './CustomerBreakdown';
import LineItemBookingPeriod from './LineItemBookingPeriod';
import LineItemCopyable from './LineItemCopyable';
import LineItemMeetingDuration from './LineItemMeetingDuration';
import LineItemMeetingMethod from './LineItemMeetingMethod';
import css from './BookingBreakdown.css';
import { FormattedMessage, useIntl } from 'react-intl';
import LineItemListingTitle from './LineItemListingTitle';
import utils from '@givsly/sharetribe-utils';
import { ensureTransaction } from '../../util/data';
import LineItemProposedTimes from './LineItemProposedTimes';
import moment from 'moment-timezone';

const {
  TRANSITION_BOOK,
  TRANSITION_COMPLETE,
  TRANSITION_CUSTOMER_CANCEL,
  TRANSITION_PROVIDER_CANCEL,
  DECLINED_TRANSITIONS,
  EXPIRED_TRANSITIONS,
} = utils.transaction;

export const BookingBreakdownComponent = (props) => {
  const {
    booking,
    bookingTransaction,
    className,
    deductFee,
    event,
    getMessage,
    isProposal,
    meetingDuration,
    nonprofit,
    onDeductFeeChange,
    rootClassName,
    showBookingDetails,
    suggestedTimes,
    timezone,
    transaction,
    unitType,
    userRole,
  } = props;

  const intl = useIntl();
  const isCustomer = userRole === 'customer';
  const isProvider = userRole === 'provider';

  const classes = classNames(rootClassName || css.root, className);

  const ensuredBookingTransaction = ensureTransaction(bookingTransaction);
  const ensuredPaymentTransaction = ensureTransaction(transaction);

  const { lastTransition } = ensuredBookingTransaction.attributes;
  const isProviderCancelled = lastTransition === TRANSITION_PROVIDER_CANCEL;
  const isCustomerCancelled = lastTransition === TRANSITION_CUSTOMER_CANCEL;
  const isCancelled = isCustomerCancelled || isProviderCancelled;
  const isDeclined = DECLINED_TRANSITIONS.includes(lastTransition);
  const isExpired = EXPIRED_TRANSITIONS.includes(lastTransition);
  const isPending = lastTransition === TRANSITION_BOOK;
  const isProposed = utils.transaction.isProposal(ensuredBookingTransaction) || isProposal;
  const isCompleted = lastTransition === TRANSITION_COMPLETE;
  const isAccepted = utils.transaction.isAccepted(ensuredBookingTransaction);
  const isOutreachOffer =
    transaction.attributes.processName === utils.transaction.PROCESS_NAME_OUTREACH_PAYMENT;

  const { donationValue } = ensuredBookingTransaction.attributes.protectedData;
  const { exposeEmail, meetingDetails } = ensuredBookingTransaction.attributes.protectedData;

  /**
   * BookingBreakdown contains different line items:
   *
   * LineItemBookingPeriod: prints booking start and booking end types. Prop dateType
   * determines if the date and time or only the date is shown
   *
   * LineItemUnitsMaybe: if he unitType is line-item/unit print the name and
   * quantity of the unit
   * This line item is not used by default in the BookingBreakdown.
   *
   * LineItemUnitPriceMaybe: prints just the unit price, e.g. "Price per night $32.00".
   *
   * LineItemBasePriceMaybe: prints the base price calculation for the listing, e.g.
   * "$150.00 * 2 nights $300"
   *
   *
   * LineItemUnknownItemsMaybe: prints the line items that are unknown. In ideal case there
   * should not be unknown line items. If you are using custom pricing, you should create
   * new custom line items if you need them.
   *
   * LineItemSubTotalMaybe: prints subtotal of line items before possible
   * commission or refunds
   *
   * LineItemRefundMaybe: prints the amount of refund
   *
   * LineItemCustomerCommissionMaybe: prints the amount of customer commission
   * The default transaction process used by FTW doesn't include the customer commission.
   *
   * LineItemCustomerCommissionRefundMaybe: prints the amount of refunded customer commission
   *
   * LineItemProviderCommissionMaybe: prints the amount of provider commission
   *
   * LineItemProviderCommissionRefundMaybe: prints the amount of refunded provider commission
   *
   * LineItemTotalPrice: prints total price of the transaction
   *
   */

  const showLineItemBookingPeriod =
    !isOutreachOffer &&
    ((!!booking.attributes.displayStart && !!booking.attributes.displayEnd) ||
      (!!booking.attributes.start && !!booking.attributes.end)) &&
    (!isProposed || (isProposed && isAccepted));
  const realMeetingDuration = isProposed
    ? ensuredBookingTransaction.attributes.protectedData.quantity * 15
    : moment
        .duration(
          moment.tz(booking.attributes.end, 'UTC').diff(moment.tz(booking.attributes.start, 'UTC'))
        )
        .asMinutes();

  return (
    <div className={classes}>
      {showBookingDetails ? (
        <>
          <hr className={css.totalDivider} />
          {showLineItemBookingPeriod ? (
            <LineItemBookingPeriod booking={booking} unitType={unitType} timezone={timezone} />
          ) : null}
          {isProposed && !isAccepted ? (
            <LineItemProposedTimes
              meetingDuration={meetingDuration}
              suggestedTimes={
                suggestedTimes.length
                  ? suggestedTimes
                  : ensuredBookingTransaction.attributes.protectedData.suggestedTimes
              }
              timezone={timezone}
            />
          ) : null}
          {exposeEmail ? (
            <LineItemCopyable label={getMessage('exposedEmail')} value={exposeEmail} />
          ) : null}
          <LineItemMeetingMethod transaction={transaction} />
          {!isOutreachOffer && (realMeetingDuration > 0 || meetingDuration > 0) ? (
            <LineItemMeetingDuration meetingDuration={meetingDuration | realMeetingDuration} />
          ) : null}
          {(isPending || isAccepted) &&
          meetingDetails &&
          typeof meetingDetails === 'object' &&
          meetingDetails.audioVideoCallURL ? (
            <LineItemCopyable
              label={getMessage('meetingDetails')}
              value={meetingDetails.audioVideoCallURL}
            />
          ) : null}
          {(!isDeclined && !isCancelled && !isExpired) || isOutreachOffer ? (
            <LineItemListingTitle
              intl={intl}
              isCompleted={isCompleted}
              listing={nonprofit}
              donation={donationValue}
              event={event}
            />
          ) : null}
          {isOutreachOffer ? <hr className={css.totalDivider} /> : null}
          {isProvider ? (
            <>
              {isProposed && !isCancelled && !isExpired && !isDeclined ? (
                <div className={css.providerFootnote}>
                  <FormattedMessage id="BookingBreakdown.provider.proposed.footNote" />
                </div>
              ) : null}
              {isExpired ? (
                <div className={css.providerFootnoteRed}>
                  <FormattedMessage
                    id="BookingBreakdown.provider.expired"
                    values={{
                      customerName:
                        ensuredBookingTransaction.customer.attributes.profile.publicData.firstName,
                    }}
                  />
                </div>
              ) : null}
              {isProviderCancelled ? (
                <div className={css.providerFootnoteRed}>
                  <FormattedMessage id="BookingBreakdown.provider.providerCancelled" />
                </div>
              ) : null}
              {isCustomerCancelled ? (
                <div className={css.providerFootnoteRed}>
                  <FormattedMessage id="BookingBreakdown.provider.customerCancelled" />
                </div>
              ) : null}
              {isDeclined && !isProposed ? (
                <div className={css.providerFootnoteRed}>
                  <FormattedMessage id="BookingBreakdown.provider.providerDeclined" />
                </div>
              ) : null}
              {isDeclined && isProposed ? (
                <div className={css.providerFootnoteRed}>
                  <FormattedMessage id="BookingBreakdown.provider.providerDeclinedProposal" />
                </div>
              ) : null}
            </>
          ) : null}
        </>
      ) : null}
      {isCustomer ? (
        <CustomerBreakdown
          deductFee={deductFee}
          ensuredBookingTransaction={ensuredBookingTransaction}
          ensuredPaymentTransaction={ensuredPaymentTransaction}
          onDeductFeeChange={onDeductFeeChange}
        />
      ) : null}
    </div>
  );
};

BookingBreakdownComponent.defaultProps = {
  applyCredit: false,
  booking: null,
  bookingTransaction: null,
  rootClassName: null,
  className: null,
  dateType: null,
  isProposal: false,
  timezone: null,
  deductFee: null,
  onDeductFeeChange: null,
  showBookingDetails: true,
  suggestedTimes: [],
};

BookingBreakdownComponent.propTypes = {
  applyCredit: bool,
  booking: object,
  bookingTransaction: propTypes.transaction,
  className: string,
  creditTotal: number,
  dateType: propTypes.dateType,
  deductFee: bool,
  event: propTypes.event,
  getMessage: func.isRequired,
  intl: intlShape.isRequired,
  isProposal: bool,
  nonprofit: propTypes.listing,
  onDeductFeeChange: func,
  rootClassName: string,
  showBookingDetails: bool,
  suggestedTimes: array,
  timezone: string.isRequired,
  transaction: propTypes.transaction.isRequired,
  unitType: propTypes.bookingUnitType.isRequired,
  userRole: oneOf(['customer', 'provider']).isRequired,
};

const withLocalizedMessages = (component) => withMessages(component, 'BookingBreakdown');

const BookingBreakdown = compose(injectIntl, withLocalizedMessages)(BookingBreakdownComponent);
BookingBreakdown.displayName = 'BookingBreakdown';
export default BookingBreakdown;
