import React, { useEffect } from 'react';
import FieldCheckboxComponent from '../FieldCheckbox/FieldCheckbox';
import * as css from './NonprofitPreferencesPanel.css';
import { useMessages } from '../../util/localization';
import Button from '../Button/Button';

import ApprovalContactInfo from './FormSections/ApprovalContactInfo';
import ImpactAreaSection from './FormSections/ImpactArea';
import ExclusionSection from './FormSections/Exclusions';
import EventsSection from './FormSections/Events';
import CausesSection from './FormSections/Causes';
import PartnershipsSection from './FormSections/Partnerships';
import BrandingGuidelinesSection from './FormSections/Guidelines';
import CollaborationWishesSection from './FormSections/CollaborationWishes';

const NonprofitPreferencesForm = (formRenderProps) => {
  const getMessage = useMessages('NonprofitPreferencesForm');
  const { values = {}, handleSubmit, isSubmitting, ensuredCurrentUser } = formRenderProps;

  const approvePortfolioPresentation =
    values.approvePortfolioPresentation &&
    values.approvePortfolioPresentation.length === 1 &&
    values.approvePortfolioPresentation.includes('Yes');

  useEffect(() => {
    console.log(values);
  }, [values]);

  return (
    <form onSubmit={handleSubmit}>
      <section className={css.section}>
        <h3 className={css.sectionTitle}>
          {getMessage('section.advertisingAndPlatforms')}
          <span className={css.optional}>{getMessage('optional')}</span>
        </h3>
        <FieldCheckboxComponent
          id="approvePortfolioPresentation"
          label={getMessage('field.approvePortfolioPresentation')}
          name="approvePortfolioPresentation"
          value="Yes"
          kind="checkbox"
        />
        <span className={css.disclaimer}>{getMessage('paragraph.portfolio')}</span>
      </section>
      {approvePortfolioPresentation && (
        <>
          <ApprovalContactInfo />
          <ImpactAreaSection {...formRenderProps} ensuredCurrentUser={ensuredCurrentUser} />
          <EventsSection {...formRenderProps} />

          <CausesSection {...formRenderProps} />
          <PartnershipsSection {...formRenderProps} />
          <CollaborationWishesSection {...formRenderProps} />

          <ExclusionSection {...formRenderProps} ensuredCurrentUser={ensuredCurrentUser} />
          <BrandingGuidelinesSection {...formRenderProps} />
        </>
      )}
      <section className={css.section}>
        <Button className={css.submit} type="submit" inProgress={isSubmitting}>
          {getMessage('button.save')}
        </Button>
      </section>
    </form>
  );
};

export default NonprofitPreferencesForm;
