import React from 'react';
import { FormattedMessage, FormattedDate } from '../../util/reactIntl';
import { propTypes } from '../../util/types';
import moment from 'moment-timezone';

import css from './BookingBreakdown.css';
import { string } from 'prop-types';

const BookingPeriod = (props) => {
  const { startDate, endDate, timezone } = props;

  const dateFormatOptions = {
    weekday: 'long',
    month: 'long',
    day: 'numeric',
  };

  const startMoment = moment.tz(startDate.toISOString(), timezone);
  const endMoment = moment.tz(endDate.toISOString(), timezone);

  return (
    <>
      <div className={css.detailItemLabel}>
        <FormattedMessage id="BookingBreakdown.dateAndTime" />
      </div>
      <div className={css.detailItemValue}>
        <div>
          <FormattedDate value={startDate} {...dateFormatOptions} timeZone={timezone} />
        </div>
        <div>
          {startMoment.format('h:mm a')}
          {' - '}
          {endMoment.format('h:mm a')} ({endMoment.zoneAbbr()})
        </div>
      </div>
    </>
  );
};

const LineItemBookingPeriod = (props) => {
  const { booking, timezone } = props;

  // Attributes: displayStart and displayEnd can be used to differentiate shown time range
  // from actual start and end times used for availability reservation. It can help in situations
  // where there are preparation time needed between bookings.
  // Read more: https://www.sharetribe.com/api-reference/marketplace.html#bookings
  const { start, end, displayStart, displayEnd } = booking.attributes;
  const localStartDate = displayStart || start;
  const localEndDateRaw = displayEnd || end;

  return (
    <>
      <div className={css.detailItem}>
        <BookingPeriod startDate={localStartDate} endDate={localEndDateRaw} timezone={timezone} />
      </div>
    </>
  );
};
LineItemBookingPeriod.defaultProps = { dateType: null };

LineItemBookingPeriod.propTypes = {
  booking: propTypes.booking.isRequired,
  dateType: propTypes.dateType,
  timezone: string.isRequired,
};

export default LineItemBookingPeriod;
