import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import {
  Footer,
  IconSpinner,
  LayoutSideNavigation,
  LayoutWrapperFooter,
  LayoutWrapperMain,
  LayoutWrapperSideNav,
  LayoutWrapperTopbar,
  NotificationRow,
  Page,
  TabNav,
} from '../../components';
import { TopbarContainer } from '../index';
import css from './NotificationsPage.css';
import { FormattedMessage } from '../../util/reactIntl';
import { array, bool, func, number, shape, string } from 'prop-types';
import { withMessages } from '../../util/localization';
import { loadData } from './NotificationsPage.duck';
import { getMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import { NotificationSettingsForm } from '../../forms';
import { propTypes } from '../../util/types';
import { updateProfile } from '../../ducks/UserProfile.duck';
import { requestUpdateListing } from '../../ducks/UserListing.duck';
import { fetchCurrentUser } from '../../ducks/user.duck';
import { ensureCurrentUser } from '../../util/data';
import { isScrollingDisabled } from '../../ducks/UI.duck';
import { archiveNotification } from '../../ducks/notifications.duck';

export const TAB_NOTIFICATIONS = 'notifications';
export const TAB_SETTINGS = 'settings';

class NotificationsPageComponent extends React.Component {
  constructor(props) {
    super(props);

    this.handleDeleteNotification = this.handleDeleteNotification.bind(this);
    this.handleSubmitNotificationSettings = this.handleSubmitNotificationSettings.bind(this);
    this.handleUnfollow = this.handleUnfollow.bind(this);
  }

  get tabs() {
    const { getMessage, newNotificationCount, params } = this.props;
    const badge =
      newNotificationCount > 0 ? (
        <strong className={css.badge} key={`${TAB_NOTIFICATIONS}-badge`}>
          {newNotificationCount > 9 ? '9+' : newNotificationCount}
        </strong>
      ) : (
        ''
      );

    return [
      {
        className: css.tabLink,
        completed: true,
        id: TAB_NOTIFICATIONS,
        linkProps: {
          name: 'NotificationsPage',
          params: {
            tab: TAB_NOTIFICATIONS,
          },
        },
        selected: params.tab === TAB_NOTIFICATIONS || !params.tab,
        text: getMessage('notifications', { badge }),
      },
      {
        className: css.tabLink,
        completed: true,
        id: TAB_SETTINGS,
        linkProps: {
          name: 'NotificationsPage',
          params: {
            tab: TAB_SETTINGS,
          },
        },
        selected: params.tab === TAB_SETTINGS,
        text: getMessage('settings'),
      },
    ];
  }

  handleDeleteNotification(ensuredNotification) {
    const { currentUser, onArchiveNotification } = this.props;
    const ensuredCurrentUser = ensureCurrentUser(currentUser);
    return onArchiveNotification(ensuredNotification, ensuredCurrentUser);
  }

  handleUnfollow(unfollowId) {
    const { currentUser, onFetchCurrentUser, onUpdateListing, onUpdateProfile } = this.props;
    const ensuredCurrentUser = ensureCurrentUser(currentUser);
    const { following, listingId } = ensuredCurrentUser.attributes.profile.publicData;

    const updatedValues = {
      publicData: {
        following: following.filter((followingId) => followingId !== unfollowId),
      },
    };

    return onUpdateProfile(updatedValues)
      .then(() => {
        return onUpdateListing({
          id: listingId,
          ...updatedValues,
        });
      })
      .then(() => {
        return onFetchCurrentUser();
      });
  }

  handleSubmitNotificationSettings(submittedValues) {
    const { currentUser, onFetchCurrentUser, onUpdateListing, onUpdateProfile } = this.props;
    const ensuredCurrentUser = ensureCurrentUser(currentUser);
    const { listingId } = ensuredCurrentUser.attributes.profile.publicData;

    return onUpdateProfile({
      privateData: submittedValues,
    })
      .then(() => {
        return onUpdateListing({
          id: listingId,
          privateData: submittedValues,
        });
      })
      .then(() => {
        return onFetchCurrentUser();
      });
  }

  render() {
    const {
      currentUser,
      getListing,
      getMessage,
      queryNotificationsInProgress,
      scrollingDisabled,
      notifications,
      params,
      timezone,
    } = this.props;
    const { tab: activeTab = TAB_NOTIFICATIONS } = params;

    return (
      <Page title={getMessage('title')} scrollingDisabled={scrollingDisabled}>
        <LayoutSideNavigation>
          <LayoutWrapperTopbar>
            <TopbarContainer
              className={css.topbar}
              mobileRootClassName={css.mobileTopbar}
              desktopClassName={css.desktopTopbar}
              currentPage="InboxPage"
            />
          </LayoutWrapperTopbar>
          <LayoutWrapperSideNav className={css.navigation}>
            <h1 className={css.title}>
              <FormattedMessage id="InboxPage.title" />
            </h1>
            <TabNav className={css.tabs} tabs={this.tabs} />
          </LayoutWrapperSideNav>
          <LayoutWrapperMain>
            {activeTab !== TAB_SETTINGS ? (
              <>
                <ul className={css.itemList}>
                  {!queryNotificationsInProgress && notifications.length > 0 ? (
                    notifications.map((notification) => {
                      return (
                        <NotificationRow
                          currentUser={currentUser}
                          onDelete={this.handleDeleteNotification}
                          notification={notification}
                          key={`notification-${notification.id.uuid}`}
                          timezone={timezone}
                        />
                      );
                    })
                  ) : !queryNotificationsInProgress ? (
                    <li className={css.noNotifications}>{getMessage('noNotifications')}</li>
                  ) : (
                    <li className={css.listItemsLoading}>
                      <IconSpinner />
                    </li>
                  )}
                </ul>
              </>
            ) : (
              <NotificationSettingsForm
                currentUser={currentUser}
                getListing={getListing}
                onSubmit={this.handleSubmitNotificationSettings}
                onUnfollow={this.handleUnfollow}
              />
            )}
          </LayoutWrapperMain>
          <LayoutWrapperFooter>
            <Footer />
          </LayoutWrapperFooter>
        </LayoutSideNavigation>
      </Page>
    );
  }
}

NotificationsPageComponent.propTypes = {
  currentUser: propTypes.currentUser,
  getListing: func.isRequired,
  newNotificationCount: number.isRequired,
  notifications: array,
  onArchiveNotification: func.isRequired,
  onFetchCurrentUser: func.isRequired,
  onUpdateListing: func.isRequired,
  onUpdateProfile: func.isRequired,
  params: shape({
    tab: string,
  }).isRequired,
  queryNotificationsInProgress: bool.isRequired,
  scrollingDisabled: bool.isRequired,
  timezone: string.isRequired,
};

NotificationsPageComponent.defaultProps = {
  notification: [],
  queryNotificationsInProgress: false,
  params: {
    tab: null,
  },
  scrollingDisabled: false,
};

const mapStateToProps = (state) => {
  const { currentUser, timezone } = state.user;
  const { newNotificationCount, notifications, queryNotificationsInProgress } = state.notifications;

  const getListing = (id) => {
    const ref = { id, type: 'listing' };
    const listings = getMarketplaceEntities(state, [ref]);
    return listings.length === 1 ? listings[0] : null;
  };

  return {
    currentUser,
    getListing,
    newNotificationCount,
    notifications: notifications['default'],
    queryNotificationsInProgress,
    scrollingDisabled: isScrollingDisabled(state),
    timezone,
  };
};

const mapDispatchToProps = (dispatch) => ({
  onArchiveNotification: (ensuredNotification, ensuredCurrentUser) => {
    return dispatch(archiveNotification(ensuredNotification, ensuredCurrentUser));
  },
  onFetchCurrentUser: () => {
    return dispatch(fetchCurrentUser());
  },
  onUpdateProfile: (updatedValues) => {
    return dispatch(updateProfile(updatedValues));
  },
  onUpdateListing: (updatedValues) => {
    return dispatch(requestUpdateListing(updatedValues));
  },
});

const withLocalizedMessages = (component) => {
  return withMessages(component, 'NotificationsPage');
};

const NotificationsPage = compose(
  withLocalizedMessages,
  connect(mapStateToProps, mapDispatchToProps)
)(NotificationsPageComponent);

NotificationsPage.loadData = loadData;
export default NotificationsPage;
