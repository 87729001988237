import React from 'react';
import css from './ListingPage.css';
import { string } from 'prop-types';

class SectionMaybe extends React.PureComponent {
  render() {
    const { content, title, className } = this.props;

    const sectionClass = className || css.section;

    return content && title ? (
      <div className={sectionClass}>
        <h2 className={css.sectionHeader}>{title}</h2>
        <div className={css.sectionContent}>{content}</div>
      </div>
    ) : null;
  }
}

SectionMaybe.propTypes = {
  title: string,
  className: string,
};

export default SectionMaybe;
