import React from 'react';
import {
  Footer,
  LayoutSingleColumn,
  LayoutWrapperFooter,
  LayoutWrapperMain,
  LayoutWrapperTopbar,
  Page,
} from '../../components';
import TopbarContainer from '../TopbarContainer/TopbarContainer';
import css from './CurrentUserLoadingPage.css';

const CurrentUserLoadingPage = ({ history, location, match }) => {
  return (
    <Page title="Givsly 2023">
      <LayoutSingleColumn>
        <LayoutWrapperTopbar>
          <TopbarContainer
            currentPage="MeetingSunsetPage"
            history={history}
            location={location}
            match={match}
          />
        </LayoutWrapperTopbar>
        <LayoutWrapperMain>
          <div className={css['container']}>
            <p>Validating your subscription...</p>
          </div>
        </LayoutWrapperMain>
        <LayoutWrapperFooter>
          <Footer />
        </LayoutWrapperFooter>
      </LayoutSingleColumn>
    </Page>
  );
};

export default CurrentUserLoadingPage;
