import React from 'react';
import ReactDOMServer from 'react-dom/server';
import { StaticPage, TopbarContainer } from '../../containers';
import {
  LayoutSingleColumn,
  LayoutWrapperTopbar,
  LayoutWrapperMain,
  LayoutWrapperFooter,
  Footer,
  NamedLink,
} from '../../components';
import { SECTION_OUTREACH } from './constants';

import css from './OutreachFaqPage.css';
import OutreachFaqPageContent from './OutreachFaqPageContent';

const OutreachFaqPage = () => {
  const topText = (
    <>
      Got any questions or concerns? We’re here to help you. If you have additional questions not
      answered in this FAQ sheet please reach out to the Givsly team at{' '}
      <a href="mailto:hello@givsly.com">hello@givsly.com</a>.
    </>
  );

  return (
    <StaticPage
      title="FAQ for Givsly Donation Offer Beta"
      description={
        'Read more to know how you can support nonprofit organizations by volunteering and booking meetings on the Givsly social impact service.'
      }
      schema={{
        '@context': 'http://schema.org',
        '@type': 'FaqPage',
        description:
          'Read more to know how you can support nonprofit organizations by volunteering and booking meetings on the Givsly social impact service.',
        name: 'FAQ for Givsly Donation Offer Beta',
      }}
    >
      <LayoutSingleColumn>
        <LayoutWrapperTopbar>
          <TopbarContainer alwaysAccessible={true} currentPage="FaqPage" />
        </LayoutWrapperTopbar>

        <LayoutWrapperMain className={css.staticPageWrapper}>
          <OutreachFaqPageContent title="FAQ" text={topText} />
        </LayoutWrapperMain>

        <LayoutWrapperFooter>
          <Footer />
        </LayoutWrapperFooter>
      </LayoutSingleColumn>
    </StaticPage>
  );
};

export default OutreachFaqPage;
