import React, { Component } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { propTypes } from '../../util/types';
import { intlShape } from '../../util/reactIntl';
import css from './NonprofitCard.css';
import { ensureListing } from '../../util/data';
import { bool, func } from 'prop-types';
import classNames from 'classnames';
import { cropText } from '../../util/richText';
import { Avatar, AvatarLarge, NamedLink } from '..';
import config from '../../config';
import { createSlug } from '../../util/urlHelpers';

class NonprofitCardComponent extends Component {
  constructor(props) {
    super(props);
    this.state = { isSelected: false };
    this.toggleSelection = this.toggleSelection.bind(this);
    this.button = React.createRef();
  }

  toggleSelection() {
    this.props.onToggleNonprofit(this.props.listing.id.uuid, this.props.listing.attributes.title);
    this.button.current.blur();
  }

  render() {
    const { blockNewToggle, isSelected, listing, selectable, type } = this.props;
    const currentListing = ensureListing(listing);
    const { title, description } = currentListing.attributes;
    const { organizationLocation, organizationCategory } = currentListing.attributes.publicData;

    const filteredLocations = config.custom.locations.filter(
      (location) => location.key === organizationLocation
    );
    const locationLabel = filteredLocations.length > 0 ? filteredLocations[0].label : null;
    const typeClass = type ? css[`card-${type}`] : null;

    return (
      <div className={classNames(css.card, typeClass, isSelected ? css.active : null)}>
        <div className={css.logoWrapper}>
          <AvatarLarge
            className={classNames(css.logo, css.logoLarge)}
            user={currentListing.author}
            title={title}
          />
          <Avatar
            className={classNames(css.logo, css.logoSmall)}
            user={currentListing.author}
            title={title}
          />
        </div>
        <div className={css.content}>
          <h3 className={css.heading}>
            <span className={css.title}>{title}</span>
            <NamedLink
              className={css.organizationLink}
              name="ListingPage"
              params={{
                id: currentListing.id.uuid,
                slug: createSlug(currentListing.attributes.title),
              }}
              target="_blank" rel="noopener noreferrer"
            >
              <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12">
                <g fill="none" fillRule="evenodd" stroke="#FAC51D" strokeWidth="1.5">
                  <path d="M8.75 8.25v3h-8v-8h3M6.25.75h5v5" />
                  <path strokeLinecap="square" d="M10.25 1.75l-5 5" />
                </g>
              </svg>
            </NamedLink>
          </h3>
          <h4 className={css.subHeading}>{organizationCategory}</h4>
          <p className={css.description}>{cropText(description, 250)}</p>
          {locationLabel ? <p className={css.foot}>{locationLabel}</p> : null}
        </div>
        {selectable && (
          <button
            className={css.selector}
            disabled={blockNewToggle && !isSelected}
            onClick={this.toggleSelection}
            ref={this.button}
            type="button"
          >
            <span
              className={classNames(css.selectorLabel, {
                [css.selectedLabel]: isSelected,
              })}
            >
              {isSelected ? (
                <FormattedMessage id="NonprofitCard.selected" />
              ) : (
                <FormattedMessage id="NonprofitCard.select" />
              )}
            </span>
            <svg xmlns="http://www.w3.org/2000/svg" width="17" height="16" viewBox="0 0 17 16">
              <path
                fill={isSelected ? '#FAC51D' : 'none'}
                fillRule="nonzero"
                stroke="#1C1A34"
                strokeWidth={isSelected ? 0 : 1.5}
                d="M8.495 15.25c.002-.002.153-.063.273-.143 4.828-3.157 7.482-6.594 7.482-9.816 0-2.667-1.749-4.541-4.123-4.541-1.277 0-2.337.58-3.007 1.588l-.63.946-.622-.951C7.218 1.339 6.141.75 4.864.75 2.492.75.75 2.622.75 5.29c0 3.226 2.65 6.663 7.465 9.812.135.086.282.146.28.148z"
              />
            </svg>
          </button>
        )}
      </div>
    );
  }
}

NonprofitCardComponent.defaultProps = {
  blockNewToggle: false,
  isSelected: false,
  selectable: true,
  onToggleNonprofit: () => {},
};

NonprofitCardComponent.propTypes = {
  blockNewToggle: bool,
  intl: intlShape.isRequired,
  listing: propTypes.listing.isRequired,
  selectable: bool,
  isSelected: bool.isRequired,
  onToggleNonprofit: func,
};

export default injectIntl(NonprofitCardComponent);
