import React, { Component } from 'react';
import { array, bool, func, object, string, number } from 'prop-types';
import { compose } from 'redux';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import { Form as FinalForm } from 'react-final-form';
import isEqual from 'lodash/isEqual';
import classNames from 'classnames';
import { ensureCurrentUser } from '../../util/data';
import { propTypes } from '../../util/types';
import {
  Form,
  Slots,
  NonprofitFilters,
  NonprofitSelector,
  FieldTextInput,
  ProfileSubmitButtonWrapper,
} from '../../components';
import arrayMutators from 'final-form-arrays';
import css from './NonprofitsForm.css';
import FormSectionHeading from '../../components/FormSectionHeading/FormSectionHeading';
import NonprofitSearchFiltersMobile from '../../containers/NonprofitSearchPage/NonprofitSearchFiltersMobile';
import {
  ONBOARDING_TAB_NUMBERS,
  SUPPORTED_NONPROFITS,
} from '../../components/OnboardingWizard/constants';

export const SUPPORTED_NONPROFIT_LIMIT = 3;

class NonprofitsFormComponent extends Component {
  constructor(props) {
    super(props);
    this.submittedValues = {};
    this.form = React.createRef();

    // Form structure is difficult, because NPO selector and NPO filters contains form -tag and cannot be inside form -tag of this component.
    // That's why bio -text field is not inside form, because it should be above NPO selector and NPO filters.
    // NPO selector "handleToggleNonprofit" uses forceUpdate, so bio will be saved to the class variable for preventing changes to be reset on NPO toggle.
    // NPO selector should be formatted to not use form tag, but instead being more like single form field.
    this.bio = this.getUserData().bio ? `${this.getUserData().bio}` : '';

    this.getTranslation = this.getTranslation.bind(this);
  }

  getUserData() {
    const user = ensureCurrentUser(this.props.currentUser);
    return user.attributes.profile;
  }

  isSubmitDisabled(fieldRenderProps) {
    const { updateInProgress, values, invalid } = fieldRenderProps;
    const { supportedNonprofits } = this.props;
    const {
      bio,
      publicData: { supportedNPOs, onboardingCompleted },
    } = this.getUserData();

    const submitInProgress = updateInProgress;
    const submittedOnce = Object.keys(this.submittedValues).length > 0;

    const flattenedStoredSelection = supportedNPOs.join(',');
    const flattenedSelection = supportedNonprofits.map((nonprofit) => nonprofit.id.uuid).join(',');
    const hasChanges = flattenedSelection !== flattenedStoredSelection || bio !== values.bio;

    const pristineSinceLastSubmit = submittedOnce && isEqual(values, this.submittedValues);

    const submitDisabledOnBoardingProcess =
      invalid || submitInProgress || !supportedNonprofits || supportedNonprofits.length === 0;
    const submitDisabledProfileEdit =
      invalid ||
      submitInProgress ||
      pristineSinceLastSubmit ||
      !hasChanges ||
      supportedNonprofits.length === 0;

    return onboardingCompleted ? submitDisabledProfileEdit : submitDisabledOnBoardingProcess;
  }

  getTranslation(key, values = {}) {
    return this.props.intl.formatMessage({ id: `NonprofitSearchPage.${key}` }, values);
  }

  render() {
    const { supportedNonprofits } = this.props;
    const selectedIdentifiers = supportedNonprofits.map((nonprofit) => nonprofit.id.uuid);

    const {
      handleToggleNonprofit,
      nonprofitListings,
      nonprofitListingsError,
      nonprofitListingsInProgress,
      nonprofitPagination,
      onManageDisableScrolling,
      onOpenModal,
      onCloseModal,
      pageName,
      pagePathParams,
      primaryFilters,
      searchParamsAreInSync,
      showAsModalMaxWidth,
      urlQueryParams,
      intl,
      trackEvent,
    } = this.props;
    const hasPaginationInfo = !!nonprofitPagination && nonprofitPagination.totalItems != null;
    const totalItems =
      searchParamsAreInSync && hasPaginationInfo ? nonprofitPagination.totalItems : 0;
    const listingsAreLoaded =
      !nonprofitListingsInProgress && searchParamsAreInSync && hasPaginationInfo;

    return (
      <>
        <FinalForm
          {...this.props}
          mutators={{ ...arrayMutators }}
          initialValues={{
            supportedNPOs: selectedIdentifiers.join(','),
            bio: this.bio,
          }}
          render={(fieldRenderProps) => {
            const {
              className,
              handleSubmit,
              rootClassName,
              updateInProgress,
              updateNonprofitsError,
              values,
            } = fieldRenderProps;

            const { onboardingCompleted } = this.getUserData().publicData;

            this.bio = values.bio;

            const submitError = updateNonprofitsError ? (
              <div className={css.error}>
                <FormattedMessage id="NonprofitsForm.updateNonprofitsFailed" />
              </div>
            ) : null;

            const classes = classNames(rootClassName || css.root, className);
            const submitInProgress = updateInProgress;
            const submittedOnce = Object.keys(this.submittedValues).length > 0;
            const pristineSinceLastSubmit = submittedOnce && isEqual(values, this.submittedValues);

            const submitDisabled = this.isSubmitDisabled(fieldRenderProps);

            const submitLabel = onboardingCompleted
              ? 'NonprofitsForm.saveChanges'
              : 'NonprofitsForm.next';

            const filterParamNames = Object.values(primaryFilters).map((f) => f.paramName);

            return (
              <div id="NonprofitsForm">
                <div className={css.sectionContainer}>
                  <FieldTextInput
                    className={css.bio}
                    type="textarea"
                    id="bio"
                    name="bio"
                    label={intl.formatMessage({ id: 'NonprofitsForm.bio' })}
                    labelSuffix={intl.formatMessage({ id: 'NonprofitsForm.bio.optional' })}
                    placeholder={intl.formatMessage({ id: 'NonprofitsForm.bio.placeholder' })}
                    rows={3}
                  />
                </div>

                <div className={classNames(css.sectionContainer, css.lastSection)}>
                  <FormSectionHeading
                    header={intl.formatMessage({ id: 'NonprofitsForm.nonprofitsSelectorHeading' })}
                    subHeader={intl.formatMessage({
                      id: 'NonprofitsForm.nonprofitsSelectorSubHeading',
                    })}
                  />
                  <Slots
                    name="supportedNPOs"
                    fieldName="supportedNPOs"
                    onClickHandler={handleToggleNonprofit}
                    slots={supportedNonprofits.map((nonprofit) => {
                      return {
                        key: nonprofit.id.uuid,
                        label: nonprofit.attributes.title,
                      };
                    })}
                    slotLimit={SUPPORTED_NONPROFIT_LIMIT}
                  />
                  <NonprofitFilters
                    rootClassName={css.nonprofitFilters}
                    hash="#NonprofitsForm"
                    listingsAreLoaded={listingsAreLoaded}
                    onManageDisableScrolling={onManageDisableScrolling}
                    pageName={pageName}
                    pagePathParams={pagePathParams}
                    resultsCount={totalItems}
                    searchInProgress={nonprofitListingsInProgress}
                    searchListingsError={nonprofitListingsError}
                    urlQueryParams={urlQueryParams}
                    trackEvent={trackEvent}
                    {...primaryFilters}
                  />
                  <NonprofitSearchFiltersMobile
                    filterParamNames={filterParamNames}
                    rootClassName={css.nonprofitFiltersMobile}
                    getTranslation={this.getTranslation}
                    urlQueryParams={urlQueryParams}
                    listingsAreLoaded={listingsAreLoaded}
                    pageName={pageName}
                    pagePathParams={pagePathParams}
                    resultsCount={totalItems}
                    searchInProgress={nonprofitListingsInProgress}
                    searchListingsError={nonprofitListingsError}
                    showAsModalMaxWidth={showAsModalMaxWidth}
                    onManageDisableScrolling={onManageDisableScrolling}
                    onOpenModal={onOpenModal}
                    onCloseModal={onCloseModal}
                    selectedFiltersCount={totalItems}
                    {...primaryFilters}
                  />
                  <NonprofitSelector
                    listings={nonprofitListings}
                    onToggleNonprofit={handleToggleNonprofit}
                    pageName={pageName}
                    pagePathParams={pagePathParams}
                    pagination={nonprofitPagination}
                    isLoading={nonprofitListingsInProgress}
                    search={urlQueryParams}
                    selectedIdentifiers={selectedIdentifiers}
                    selectionLimit={SUPPORTED_NONPROFIT_LIMIT}
                  />
                </div>
                <Form
                  className={classes}
                  onSubmit={(e) => {
                    this.submittedValues = values;
                    handleSubmit(e);
                  }}
                >
                  {submitError}
                  <FieldTextInput id="bio" name="bio" type="hidden" value={this.bio} />
                  <FieldTextInput id="supportedNPOs" name="supportedNPOs" type="hidden" />
                  <ProfileSubmitButtonWrapper
                    stepNumber={ONBOARDING_TAB_NUMBERS[SUPPORTED_NONPROFITS]}
                    inProgress={submitInProgress}
                    disabled={submitDisabled}
                    ready={pristineSinceLastSubmit}
                    onboardingCompleted={onboardingCompleted}
                  >
                    <FormattedMessage id={submitLabel} />
                  </ProfileSubmitButtonWrapper>
                </Form>
              </div>
            );
          }}
        />
      </>
    );
  }
}

NonprofitsFormComponent.defaultProps = {
  className: null,
  initialValues: {},
  listings: [],
  rootClassName: null,
  supportedNonprofits: [],
  updateNonprofitsError: null,
  updateNonprofitsReady: false,
};

NonprofitsFormComponent.propTypes = {
  className: string,
  handleToggleNonprofit: func.isRequired,
  initialValues: object,
  intl: intlShape.isRequired,
  nonprofitPagination: propTypes.pagination,
  nonprofitListingsError: propTypes.error,
  nonprofitListingsInProgress: bool,
  nonprofitListings: array,
  onManageDisableScrolling: func.isRequired,
  onOpenModal: func.isRequired,
  onCloseModal: func.isRequired,
  showAsModalMaxWidth: number,
  pageName: string.isRequired,
  pagePathParams: object.isRequired,
  rootClassName: string,
  supportedNonprofits: array,
  updateInProgress: bool.isRequired,
  updateNonprofitsError: propTypes.error,
  updateNonprofitsReady: bool,
  trackEvent: func,
};

const NonprofitsForm = compose(injectIntl)(NonprofitsFormComponent);
NonprofitsForm.displayName = 'NonprofitsForm';
export default NonprofitsForm;
