import React, { Component } from 'react';
import NonprofitPhotosForm from '../../forms/NonprofitPhotosForm/NonprofitPhotosForm';
import { array, bool, func, oneOfType } from 'prop-types';
import { intlShape } from '../../util/reactIntl';
import { propTypes } from '../../util/types';
import { NONPROFIT_PHOTOS } from '../MyNonprofitWizard/constants';
import { ensureOwnListing } from '../../util/data';

class NonprofitPhotosPanel extends Component {
  render() {
    const {
      currentUserListing,
      images,
      intl,
      nonprofit,
      onImageUpload,
      onRemoveImage,
      onUpdateImageOrder,
      updateInProgress,
    } = this.props;

    const imageIds = (images) => {
      return images ? images.map((img) => img.imageId || img.id) : null;
    };

    const handleSubmit = (updateValues) => {
      // Normalize images for API call
      const { images: updatedImages, ...otherValues } = updateValues;
      const imageProperty =
        typeof updatedImages !== 'undefined' ? { images: imageIds(updatedImages) } : {};
      const listingValues = { ...otherValues, ...imageProperty };

      this.props.handleSubmit({}, listingValues, NONPROFIT_PHOTOS);
    };

    const listing = ensureOwnListing(currentUserListing);

    return (
      <NonprofitPhotosForm
        images={images}
        intl={intl}
        isPublished={listing.attributes.state !== 'draft'}
        nonprofit={nonprofit}
        onImageUpload={onImageUpload}
        onRemoveImage={onRemoveImage}
        onSubmit={(values) => {
          const { addImage, ...updateValues } = values;
          handleSubmit(updateValues);
        }}
        onUpdateImageOrder={onUpdateImageOrder}
        updated={true}
        updateInProgress={updateInProgress}
      />
    );
  }
}

NonprofitPhotosPanel.propTypes = {
  currentUser: propTypes.currentUser.isRequired,
  currentUserListing: propTypes.ownListing.isRequired,
  handleSubmit: func.isRequired,
  images: array,
  intl: intlShape.isRequired,
  nonprofit: oneOfType([propTypes.listing, propTypes.ownListing]).isRequired,
  onImageUpload: func.isRequired,
  onRemoveImage: func.isRequired,
  onUpdateImageOrder: func.isRequired,
  panelUpdated: bool.isRequired,
  updateInProgress: bool.isRequired,
};

NonprofitPhotosForm.defaultProps = {
  images: [],
};

export default NonprofitPhotosPanel;
