import { storableError } from '../util/errors';
import {
  createOffer,
  createRequest,
  getOffer,
  getRequest,
  queryOffers,
  queryRequests,
  deleteOffer,
  deleteRequest,
  updateRequest,
  uploadImage,
  updateOffer,
  queryUnsplashAPI,
  queryUnsplashAPIforGivslyCollection,
  unsplashDownload,
} from '../util/outreach';
import { fetchCurrentUser } from './user.duck';
import sharetribe from '@givsly/sharetribe-utils';

// Action types
const CREATE_OUTREACH_OFFER_REQUEST = 'app/outreach/CREATE_OUTREACH_OFFER_REQUEST';
const CREATE_OUTREACH_OFFER_SUCCESS = 'app/outreach/CREATE_OUTREACH_OFFER_SUCCESS';
const CREATE_OUTREACH_OFFER_FAILURE = 'app/outreach/CREATE_OUTREACH_OFFER_FAILURE';
const CREATE_OUTREACH_REQUEST_REQUEST = 'app/outreach/CREATE_OUTREACH_REQUEST_REQUEST';
const CREATE_OUTREACH_REQUEST_SUCCESS = 'app/outreach/CREATE_OUTREACH_REQUEST_SUCCESS';
const CREATE_OUTREACH_REQUEST_FAILURE = 'app/outreach/CREATE_OUTREACH_REQUEST_FAILURE';
const DELETE_OUTREACH_OFFER_REQUEST = 'app/outreach/DELETE_OUTREACH_OFFER_REQUEST';
const DELETE_OUTREACH_OFFER_SUCCESS = 'app/outreach/DELETE_OUTREACH_OFFER_SUCCESS';
const DELETE_OUTREACH_OFFER_FAILURE = 'app/outreach/DELETE_OUTREACH_OFFER_FAILURE';
const DELETE_OUTREACH_REQUEST_REQUEST = 'app/outreach/DELETE_OUTREACH_REQUEST_REQUEST';
const DELETE_OUTREACH_REQUEST_SUCCESS = 'app/outreach/DELETE_OUTREACH_REQUEST_SUCCESS';
const DELETE_OUTREACH_REQUEST_FAILURE = 'app/outreach/DELETE_OUTREACH_REQUEST_FAILURE';
const GET_OUTREACH_OFFER_REQUEST = 'app/outreach/GET_OUTREACH_OFFER_REQUEST';
const GET_OUTREACH_OFFER_SUCCESS = 'app/outreach/GET_OUTREACH_OFFER_SUCCESS';
const GET_OUTREACH_OFFER_FAILURE = 'app/outreach/GET_OUTREACH_OFFER_FAILURE';
const GET_OUTREACH_REQUEST_REQUEST = 'app/outreach/GET_OUTREACH_REQUEST_REQUEST';
const GET_OUTREACH_REQUEST_SUCCESS = 'app/outreach/GET_OUTREACH_REQUEST_SUCCESS';
const GET_OUTREACH_REQUEST_FAILURE = 'app/outreach/GET_OUTREACH_REQUEST_FAILURE';
const QUERY_OUTREACH_OFFERS_REQUEST = 'app/outreach/QUERY_OUTREACH_OFFERS_REQUEST';
const QUERY_OUTREACH_OFFERS_SUCCESS = 'app/outreach/QUERY_OUTREACH_OFFERS_SUCCESS';
const QUERY_OUTREACH_OFFERS_FAILURE = 'app/outreach/QUERY_OUTREACH_OFFERS_FAILURE';
const QUERY_OUTREACH_REQUESTS_REQUEST = 'app/outreach/QUERY_OUTREACH_REQUESTS_REQUEST';
const QUERY_OUTREACH_REQUESTS_SUCCESS = 'app/outreach/QUERY_OUTREACH_REQUESTS_SUCCESS';
const QUERY_OUTREACH_REQUESTS_FAILURE = 'app/outreach/QUERY_OUTREACH_REQUESTS_FAILURE';
const UPDATE_OUTREACH_OFFER_REQUEST = 'app/outreach/UPDATE_OUTREACH_OFFER_REQUEST';
const UPDATE_OUTREACH_OFFER_SUCCESS = 'app/outreach/UPDATE_OUTREACH_OFFER_SUCCESS';
const UPDATE_OUTREACH_OFFER_FAILURE = 'app/outreach/UPDATE_OUTREACH_OFFER_FAILURE';
const UPDATE_OUTREACH_REQUEST_REQUEST = 'app/outreach/UPDATE_OUTREACH_REQUEST_REQUEST';
const UPDATE_OUTREACH_REQUEST_SUCCESS = 'app/outreach/UPDATE_OUTREACH_REQUEST_SUCCESS';
const UPDATE_OUTREACH_REQUEST_FAILURE = 'app/outreach/UPDATE_OUTREACH_REQUEST_FAILURE';

// Initial state
const initialState = {
  createOutreachOfferError: null,
  createOutreachOfferInProgress: false,
  createOutreachRequestError: null,
  createOutreachRequestInProgress: false,
  deleteOutreachOfferId: null,
  deleteOutreachOfferInProgress: false,
  deleteOutreachOfferError: null,
  deleteOutreachRequestId: null,
  deleteOutreachRequestInProgress: false,
  deleteOutreachRequestError: null,
  getOutreachOfferError: null,
  getOutreachOfferId: null,
  getOutreachOfferInProgress: false,
  getOutreachRequestError: null,
  getOutreachRequestId: null,
  getOutreachRequestInProgress: false,
  outreachOffer: null,
  outreachOffers: [],
  outreachRequest: null,
  outreachRequests: [],
  queryOutreachOffersError: null,
  queryOutreachOffersInProgress: false,
  queryOutreachRequestsError: null,
  queryOutreachRequestsInProgress: false,
  updateOutreachOfferError: null,
  updateOutreachOfferInProgress: false,
  updateOutreachRequestError: null,
  updateOutreachRequestInProgress: false,
};

// Reducer
export default function outreachReducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    default:
      return state;
    case CREATE_OUTREACH_OFFER_REQUEST:
      return {
        ...state,
        createOutreachRequestError: null,
        createOutreachOfferInProgress: true,
      };
    case CREATE_OUTREACH_OFFER_SUCCESS:
      return {
        ...state,
        createOutreachOfferInProgress: false,
        outreachOffer: payload.outreachOffer,
      };
    case CREATE_OUTREACH_OFFER_FAILURE:
      return {
        ...state,
        createOutreachOfferError: payload.err,
        createOutreachOfferInProgress: false,
      };
    case CREATE_OUTREACH_REQUEST_REQUEST:
      return {
        ...state,
        createOutreachRequestError: null,
        createOutreachRequestInProgress: true,
      };
    case CREATE_OUTREACH_REQUEST_SUCCESS:
      return {
        ...state,
        createOutreachRequestInProgress: false,
        outreachRequest: payload.outreachRequest,
      };
    case CREATE_OUTREACH_REQUEST_FAILURE:
      return {
        ...state,
        createOutreachRequestError: payload.err,
        createOutreachRequestInProgress: false,
      };
    case DELETE_OUTREACH_OFFER_REQUEST:
      return {
        ...state,
        deleteOutreachOfferError: null,
        deleteOutreachOfferId: payload.id,
        deleteOutreachOfferInProgress: true,
      };
    case DELETE_OUTREACH_OFFER_SUCCESS:
      return {
        ...state,
        deleteOutreachOfferInProgress: false,
      };
    case DELETE_OUTREACH_OFFER_FAILURE:
      return {
        ...state,
        deleteOutreachOfferError: payload.err,
        deleteOutreachOfferInProgress: false,
      };

    case DELETE_OUTREACH_REQUEST_REQUEST:
      return {
        ...state,
        deleteOutreachRequestError: null,
        deleteOutreachRequestId: payload.id,
        deleteOutreachRequestInProgress: true,
      };
    case DELETE_OUTREACH_REQUEST_SUCCESS:
      return {
        ...state,
        deleteOutreachRequestInProgress: false,
      };
    case DELETE_OUTREACH_REQUEST_FAILURE:
      return {
        ...state,
        deleteOutreachRequestError: payload.err,
        deleteOutreachRequestInProgress: false,
      };
    case GET_OUTREACH_OFFER_REQUEST:
      return {
        ...state,
        getOutreachOfferError: null,
        getOutreachOfferId: payload.id,
        getOutreachOfferInProgress: true,
      };
    case GET_OUTREACH_OFFER_SUCCESS:
      return {
        ...state,
        getOutreachOfferInProgress: false,
        outreachOffer: payload.outreachOffer,
      };
    case GET_OUTREACH_OFFER_FAILURE:
      return {
        ...state,
        getOutreachOfferError: payload.err,
        getOutreachOfferInProgress: false,
      };
    case GET_OUTREACH_REQUEST_REQUEST:
      return {
        ...state,
        getOutreachRequestError: null,
        getOutreachRequestId: payload.id,
        getOutreachRequestInProgress: true,
      };
    case GET_OUTREACH_REQUEST_SUCCESS:
      return {
        ...state,
        getOutreachRequestInProgress: false,
        outreachRequest: payload.outreachRequest,
      };
    case GET_OUTREACH_REQUEST_FAILURE:
      return {
        ...state,
        getOutreachRequestError: payload.err,
        getOutreachRequestInProgress: false,
      };
    case QUERY_OUTREACH_OFFERS_REQUEST:
      return {
        ...state,
        outreachOffers: [],
        queryOutreachOffersError: null,
        queryOutreachOffersInProgress: true,
      };
    case QUERY_OUTREACH_OFFERS_SUCCESS:
      return {
        ...state,
        outreachOffers: payload.outreachOffers,
        queryOutreachOffersInProgress: false,
      };
    case QUERY_OUTREACH_OFFERS_FAILURE:
      return {
        ...state,
        queryOutreachOffersError: payload.err,
        queryOutreachOffersInProgress: false,
      };
    case QUERY_OUTREACH_REQUESTS_REQUEST:
      return {
        ...state,
        outreachRequests: [],
        queryOutreachRequestsError: null,
        queryOutreachRequestsInProgress: true,
      };
    case QUERY_OUTREACH_REQUESTS_SUCCESS:
      return {
        ...state,
        outreachRequests: payload.outreachRequests,
        queryOutreachRequestsInProgress: false,
      };
    case QUERY_OUTREACH_REQUESTS_FAILURE:
      return {
        ...state,
        queryOutreachRequestsError: payload.err,
        queryOutreachRequestsInProgress: false,
      };
    case UPDATE_OUTREACH_OFFER_REQUEST:
      return {
        ...state,
        outreachOffer: payload.outreachOffer,
        updateOutreachOfferError: null,
        updateOutreachOfferInProgress: true,
      };
    case UPDATE_OUTREACH_OFFER_SUCCESS:
      return {
        ...state,
        updateOutreachOfferInProgress: false,
      };
    case UPDATE_OUTREACH_OFFER_FAILURE:
      return {
        ...state,
        updateOutreachOfferError: payload.err,
        updateOutreachOfferInProgress: false,
      };
    case UPDATE_OUTREACH_REQUEST_REQUEST:
      return {
        ...state,
        outreachRequest: payload.outreachRequest,
        updateOutreachRequestError: null,
        updateOutreachRequestInProgress: true,
      };
    case UPDATE_OUTREACH_REQUEST_SUCCESS:
      return {
        ...state,
        updateOutreachRequestInProgress: false,
      };
    case UPDATE_OUTREACH_REQUEST_FAILURE:
      return {
        ...state,
        updateOutreachRequestError: payload.err,
        updateOutreachRequestInProgress: false,
      };
  }
}

// Action creators
const createOutreachOfferRequest = (outreachOffer) => ({
  type: CREATE_OUTREACH_OFFER_REQUEST,
  payload: {
    outreachOffer,
  },
});
const createOutreachOfferSuccess = (outreachOffer) => ({
  type: CREATE_OUTREACH_OFFER_SUCCESS,
  payload: {
    outreachOffer,
  },
});
const createOutreachOfferFailure = (err) => ({
  type: CREATE_OUTREACH_OFFER_FAILURE,
  payload: {
    err,
  },
});
const createOutreachRequestRequest = (outreachRequest) => ({
  type: CREATE_OUTREACH_REQUEST_REQUEST,
  payload: {
    outreachRequest,
  },
});
const createOutreachRequestSuccess = (outreachRequest) => ({
  type: CREATE_OUTREACH_REQUEST_SUCCESS,
  payload: {
    outreachRequest,
  },
});
const createOutreachRequestFailure = (err) => ({
  type: CREATE_OUTREACH_REQUEST_FAILURE,
  payload: {
    err,
  },
});
const deleteOutreachOfferRequest = (id) => ({
  type: DELETE_OUTREACH_OFFER_REQUEST,
  payload: {
    id,
  },
});
const deleteOutreachOfferSuccess = () => ({
  type: DELETE_OUTREACH_OFFER_SUCCESS,
});
const deleteOutreachOfferFailure = (err) => ({
  type: DELETE_OUTREACH_OFFER_FAILURE,
  payload: {
    err,
  },
});
const deleteOutreachRequestRequest = (id) => ({
  type: DELETE_OUTREACH_REQUEST_REQUEST,
  payload: {
    id,
  },
});
const deleteOutreachRequestSuccess = () => ({
  type: DELETE_OUTREACH_REQUEST_SUCCESS,
});
const deleteOutreachRequestFailure = (err) => ({
  type: DELETE_OUTREACH_REQUEST_FAILURE,
  payload: {
    err,
  },
});
const getOutreachOfferRequest = (id) => ({
  type: GET_OUTREACH_OFFER_REQUEST,
  payload: {
    id,
  },
});
const getOutreachOfferSuccess = (outreachOffer) => ({
  type: GET_OUTREACH_OFFER_SUCCESS,
  payload: {
    outreachOffer,
  },
});
const getOutreachOfferFailure = (err) => ({
  type: GET_OUTREACH_OFFER_FAILURE,
  payload: {
    err,
  },
});
const queryOutreachOffersRequest = () => ({
  type: QUERY_OUTREACH_OFFERS_REQUEST,
});
const queryOutreachOffersSuccess = (outreachOffers) => ({
  type: QUERY_OUTREACH_OFFERS_SUCCESS,
  payload: {
    outreachOffers,
  },
});
const queryOutreachOffersFailure = (err) => ({
  type: QUERY_OUTREACH_OFFERS_FAILURE,
  payload: {
    err,
  },
});
const getOutreachRequestRequest = (id) => ({
  type: GET_OUTREACH_REQUEST_REQUEST,
  payload: {
    id,
  },
});
const getOutreachRequestSuccess = (outreachRequest) => ({
  type: GET_OUTREACH_REQUEST_SUCCESS,
  payload: {
    outreachRequest,
  },
});
const getOutreachRequestFailure = (err) => ({
  type: GET_OUTREACH_REQUEST_FAILURE,
  payload: {
    err,
  },
});
const queryOutreachRequestsRequest = () => ({
  type: QUERY_OUTREACH_REQUESTS_REQUEST,
});
const queryOutreachRequestsSuccess = (outreachRequests) => ({
  type: QUERY_OUTREACH_REQUESTS_SUCCESS,
  payload: {
    outreachRequests,
  },
});
const queryOutreachRequestsFailure = (err) => ({
  type: QUERY_OUTREACH_REQUESTS_FAILURE,
  payload: {
    err,
  },
});
const updateOutreachOfferRequest = (outreachOffer) => ({
  type: UPDATE_OUTREACH_OFFER_REQUEST,
  payload: {
    outreachOffer,
  },
});
const updateOutreachOfferSuccess = () => ({
  type: UPDATE_OUTREACH_OFFER_SUCCESS,
});
const updateOutreachOfferFailure = (err) => ({
  type: UPDATE_OUTREACH_OFFER_FAILURE,
  payload: {
    err,
  },
});
const updateOutreachRequestRequest = (outreachRequest) => ({
  type: UPDATE_OUTREACH_REQUEST_REQUEST,
  payload: {
    outreachRequest,
  },
});
const updateOutreachRequestSuccess = () => ({
  type: UPDATE_OUTREACH_REQUEST_SUCCESS,
});
const updateOutreachRequestFailure = (err) => ({
  type: UPDATE_OUTREACH_REQUEST_FAILURE,
  payload: {
    err,
  },
});

// Thunks
/**
 * Creates a new outreach offer
 *
 * @param {object} offer
 * @return {function(*): Promise<T>}
 */
export const createOutreachOffer = (offer) => async (dispatch) => {
  dispatch(createOutreachOfferRequest(offer));
  const ensuredCurrentUser = await dispatch(getEnsuredCurrentUser());
  const tenantID = ensuredCurrentUser.attributes.profile.metadata.cognitoTenantId;

  return createOffer(ensuredCurrentUser, { ...offer, tenantID })
    .then((outreachOffer) => {
      dispatch(createOutreachOfferSuccess(outreachOffer));
      return outreachOffer;
    })
    .catch((err) => {
      dispatch(createOutreachOfferFailure(storableError(err)));
    });
};

/**
 * Creates an new outreach request
 *
 * @param {object} request
 * @return {function(*): Promise<T>}
 */
export const createOutreachRequest = (request) => async (dispatch) => {
  dispatch(createOutreachRequestRequest(request));

  return createRequest(request)
    .then((outreachRequest) => {
      dispatch(createOutreachRequestSuccess(outreachRequest));
      return outreachRequest;
    })
    .catch((err) => {
      dispatch(createOutreachRequestFailure(storableError(err)));
    });
};

/**
 * Deletes the given outreach offer
 *
 * @param {number} id
 * @return {function(*): Promise<T>}
 */
export const deleteOutreachOffer = (id) => async (dispatch) => {
  dispatch(deleteOutreachOfferRequest(id));
  const ensuredCurrentUser = await dispatch(getEnsuredCurrentUser());

  return deleteOffer(ensuredCurrentUser, id)
    .then(() => {
      dispatch(deleteOutreachOfferSuccess());
    })
    .catch((err) => {
      dispatch(deleteOutreachOfferFailure(storableError(err)));
    });
};

/**
 * Deletes the given outreach request
 *
 * @param {number} id
 * @return {function(*): Promise<T>}
 */
export const deleteOutreachRequest = (id) => async (dispatch) => {
  dispatch(deleteOutreachRequestRequest(id));
  const ensuredCurrentUser = await dispatch(getEnsuredCurrentUser());

  return deleteRequest(ensuredCurrentUser, id)
    .then(() => {
      dispatch(deleteOutreachRequestSuccess());
    })
    .catch((err) => {
      dispatch(deleteOutreachRequestFailure(storableError(err)));
    });
};

/**
 * Get a single outreach offer
 *
 * @param {string} id
 * @return {function(*): Promise<T>}
 */
export const getOutreachOffer = (id) => (dispatch) => {
  dispatch(getOutreachOfferRequest(id));

  return getOffer(id)
    .then((outreachOffer) => {
      dispatch(getOutreachOfferSuccess(outreachOffer));
      return outreachOffer;
    })
    .catch((err) => {
      dispatch(getOutreachOfferFailure(storableError(err)));
    });
};

/**
 * Get a single outreach offer
 *
 * @param {string} id
 * @return {function(*): Promise<T>}
 */
export const getOutreachRequest = (id) => async (dispatch) => {
  dispatch(getOutreachRequestRequest(id));
  const ensuredCurrentUser = await dispatch(getEnsuredCurrentUser());

  return getRequest(ensuredCurrentUser, id)
    .then((outreachRequest) => {
      dispatch(getOutreachRequestSuccess(outreachRequest));
      return outreachRequest;
    })
    .catch((err) => {
      dispatch(getOutreachRequestFailure(storableError(err)));
    });
};

/**
 * Query all outreach offers for this user
 *
 * @return {function(*): Promise<T>}
 */
export const queryOutreachOffers = () => async (dispatch) => {
  dispatch(queryOutreachOffersRequest());
  const ensuredCurrentUser = await dispatch(getEnsuredCurrentUser());

  return queryOffers(ensuredCurrentUser)
    .then((outreachOffers) => {
      dispatch(queryOutreachOffersSuccess(outreachOffers));
      return outreachOffers;
    })
    .catch((err) => {
      dispatch(queryOutreachOffersFailure(storableError(err)));
    });
};

/**
 * Query all outreach requests for this user
 *
 * @return {function(*): Promise<T>}
 */
export const queryOutreachRequests = () => async (dispatch) => {
  dispatch(queryOutreachRequestsRequest());
  const ensuredCurrentUser = await dispatch(getEnsuredCurrentUser());

  return queryRequests(ensuredCurrentUser)
    .then((outreachRequests) => {
      dispatch(queryOutreachRequestsSuccess(outreachRequests));
      return outreachRequests;
    })
    .catch((err) => {
      dispatch(queryOutreachRequestsFailure(storableError(err)));
    });
};

/**
 * Updates the given outreach offer
 *
 * @param outreachOffer
 * @return {function(*): *}
 */
export const updateOutreachOffer = (outreachOffer) => async (dispatch) => {
  dispatch(updateOutreachOfferRequest(outreachOffer));
  const ensuredCurrentUser = await dispatch(getEnsuredCurrentUser());
  const tenantID = ensuredCurrentUser.attributes.profile.metadata.cognitoTenantId;

  return updateOffer(ensuredCurrentUser, { ...outreachOffer, tenantID })
    .then((outreachOffer) => {
      dispatch(updateOutreachOfferSuccess(outreachOffer));
      return outreachOffer;
    })
    .catch((err) => {
      dispatch(updateOutreachOfferFailure(storableError(err)));
    });
};

/**
 * Updates the given outreach request
 *
 * @param outreachRequest
 * @return {function(*): *}
 */
export const updateOutreachRequest = (outreachRequest) => async (dispatch) => {
  dispatch(updateOutreachRequestRequest(outreachRequest));
  const ensuredCurrentUser = await dispatch(getEnsuredCurrentUser());

  return updateRequest(ensuredCurrentUser, outreachRequest)
    .then((outreachRequests) => {
      dispatch(updateOutreachRequestSuccess(outreachRequests));
      return outreachRequests;
    })
    .catch((err) => {
      dispatch(updateOutreachRequestFailure(storableError(err)));
    });
};

/**
 * Upload the image to aws s3 bucket and return the endpoint
 *
 * @param obj
 * @return {function(*): *}
 */
export const uploadOfferImage = (obj) => async (dispatch) => {
  const ensuredCurrentUser = await dispatch(getEnsuredCurrentUser());
  return uploadImage(ensuredCurrentUser, obj);
};

/**
 * query unsplach API
 *
 * @param obj
 * @return {function(*): *}
 */
export const queryUnsplash = (obj) => async (dispatch) => {
  const ensuredCurrentUser = await dispatch(getEnsuredCurrentUser());
  return queryUnsplashAPI(ensuredCurrentUser, obj);
};

/**
 * query unsplach API for givsly collection
 *
 * @param obj
 * @return {function(*): *}
 */
export const queryUnsplashForGivslyCollection = (obj) => async (dispatch) => {
  const ensuredCurrentUser = await dispatch(getEnsuredCurrentUser());
  return queryUnsplashAPIforGivslyCollection(ensuredCurrentUser, obj);
};

/**
 * ping download unsplach API
 *
 * @param obj
 * @return {function(*): *}
 */
export const pingUnsplashDownload = (obj) => async (dispatch) => {
  const ensuredCurrentUser = await dispatch(getEnsuredCurrentUser());
  return unsplashDownload(ensuredCurrentUser, obj);
};

/**
 * Helper thunk that ensures the current user data is available and loaded if possible. This is
 * needed to authenticate with the API.
 *
 * @return {function(*, *): *}
 */
const getEnsuredCurrentUser = () => async (dispatch, getState) => {
  let currentUser = getState().user.currentUser;
  if (!currentUser || !currentUser.id || !currentUser.id.uuid) {
    currentUser = await dispatch(fetchCurrentUser(null, true));
  }

  return sharetribe.user.ensureCurrentUser(currentUser);
};
