import css from './FaqPage.css';
import {
  Accordion,
  AccordionItem,
  AccordionItemButton,
  AccordionItemHeading,
  AccordionItemPanel,
} from 'react-accessible-accordion';
import React from 'react';
import { arrayOf, node, oneOfType, string } from 'prop-types';
import { propTypes } from '../../util/types';
import { MEETINGS_GONE_ID, MEETINGS_GONE_UUID } from './FaqPage';

export const FaqPageContent = ({ questions, text, title, footNote, hash }) => (
  <>
    <h1 className={css.pageTitle}>{title}</h1>
    <p className={css.description}>{text}</p>
    <div className={css.contentWrapper}>
      <div className={css.contentMain}>
        {questions.map((section, i) => (
          <div className={css.section} key={`faq-${i}`}>
            <h2 className={css.sectionHeader} id={section.id}>
              {section.title}
            </h2>
            <Accordion
              allowMultipleExpanded={false}
              allowZeroExpanded={false}
              className={css.faqList}
              preExpanded={hash === '#'.concat(MEETINGS_GONE_ID) ? [MEETINGS_GONE_UUID] : []}
            >
              {section.items.map((item, j) => (
                <AccordionItem
                  className={css.faqItem}
                  key={`faq-${i}-${j}`}
                  uuid={item.uuid}
                  id={item.id}
                >
                  <AccordionItemHeading>
                    <AccordionItemButton className={css.faqItemButton}>
                      {item.question}
                    </AccordionItemButton>
                  </AccordionItemHeading>
                  <AccordionItemPanel className={css.faqItemBody}>{item.answer}</AccordionItemPanel>
                </AccordionItem>
              ))}
            </Accordion>
          </div>
        ))}
      </div>
    </div>
    <div className={css.footNotes}>
      {footNote && <p className={css.footNote}>{footNote}</p>}
      <p className={css.footNote}>
        If you have additional questions not answered in this FAQ sheet please reach out to the
        givsly team at <a href="mailto:hello@givsly.com">hello@givsly.com</a>
      </p>
      <p className={css.footNote}>
        If you are experiencing technical difficulties with the Givsly.com service please reach out
        to our support team at <a href="mailto:support@givsly.com">support@givsly.com</a>
      </p>
      <p className={css.footNote}>
        If you have questions or concerns regarding your membership information, terms & conditions
        or data privacy on Givsly, please reach out to us at{' '}
        <a href="mailto:privacy@givsly.com">privacy@givsly.com</a>
      </p>
    </div>
  </>
);

FaqPageContent.defaultProps = {
  title: 'FAQ',
};

FaqPageContent.propTypes = {
  questions: arrayOf(propTypes.faqData).isRequired,
  title: oneOfType([string, node]),
  text: oneOfType([string, node]),
  footNote: oneOfType([string, node]),
};

export default FaqPageContent;
