var merge = require("lodash.merge");
/**
 * Ensures the booking data structure
 *
 * @param booking
 * @return {*}
 */


var ensureBooking = function ensureBooking(booking) {
  var empty = {
    type: "booking",
    attributes: {}
  };
  return merge(empty, booking);
};

module.exports = {
  ensureBooking: ensureBooking
};