import React, { Component, useEffect } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect, useDispatch } from 'react-redux';
import { Switch, Route, withRouter } from 'react-router-dom';
import { NotFoundPage } from './containers';
import { NamedRedirect } from './components';
import { locationChanged } from './ducks/Routing.duck';
import { propTypes } from './util/types';
import * as log from './util/log';
import { canonicalRoutePath } from './util/routes';
import routeConfiguration from './routeConfiguration';
import GivslyChangesPage from './containers/GivslyChangesPage/GivslyChangesPage';
import CurrentUserLoadingPage from './containers/Paywall/CurrentUserLoadingPage';
import { fetchCurrentUser } from './ducks/user.duck';
import { validateSubscription } from './util/validateSubsccription';

const { arrayOf, bool, object, func, shape, string } = PropTypes;

const PaywallHoc = ({ componentName, hasFetchedCurrentUser, ...forwardProps }) => {
  if (!hasFetchedCurrentUser) return <CurrentUserLoadingPage {...forwardProps} />;

  switch (componentName) {
    default:
      return <GivslyChangesPage {...forwardProps} />;
  }
};

const canShowComponent = (props) => {
  const { isAuthenticated, route } = props;
  const { auth } = route;
  return !auth || isAuthenticated;
};

const callLoadData = (props) => {
  const { match, location, route, dispatch, logoutInProgress } = props;
  const { loadData, name } = route;
  const shouldLoadData =
    typeof loadData === 'function' && canShowComponent(props) && !logoutInProgress;

  if (shouldLoadData) {
    dispatch(loadData(match.params, location.search))
      .then(() => {
        // eslint-disable-next-line no-console
        console.log(`loadData success for ${name} route`);
      })
      .catch((e) => {
        log.error(e, 'load-data-failed', { routeName: name });
      });
  }
};

const setPageScrollPosition = (location) => {
  if (!location.hash) {
    // No hash, scroll to top
    window.scroll({
      top: 0,
      left: 0,
    });
  } else {
    const el = document.querySelector(location.hash);
    if (el) {
      // Found element with the given fragment identifier, scrolling
      // to that element.
      //
      // NOTE: This isn't foolproof. It works when navigating within
      // the application between pages and within a single page. It
      // also works with the initial page load. However, it doesn't
      // seem work work properly when refreshing the page, at least
      // not in Chrome.
      //
      // TODO: investigate why the scrolling fails on refresh
      el.scrollIntoView({
        block: 'start',
        behavior: 'smooth',
      });
    }
  }
};

const handleLocationChanged = (dispatch, location) => {
  setPageScrollPosition(location);
  const url = canonicalRoutePath(routeConfiguration(), location);
  dispatch(locationChanged(location, url));
};
/**
 * RouteComponentRenderer handles loadData calls on client-side.
 * It also checks authentication and redirects unauthenticated users
 * away from routes that are for authenticated users only
 * (aka "auth: true" is set in routeConfiguration.js)
 *
 * This component is a container: it needs to be connected to Redux.
 */
class RouteComponentRenderer extends Component {
  componentDidMount() {
    // Calling loadData on initial rendering (on client side).
    callLoadData(this.props);
    handleLocationChanged(this.props.dispatch, this.props.location);
  }

  componentDidUpdate(prevProps) {
    // Calling loadData after initial rendering (on client side).
    // This makes it possible to use loadData as default client side data loading technique.
    // However it is better to fetch data before location change to avoid "Loading data" state.
    if (prevProps.location.key !== this.props.location.key) {
      callLoadData(this.props);
      handleLocationChanged(this.props.dispatch, this.props.location);
    }
  }

  render() {
    const {
      route,
      match,
      location,
      staticContext,
      currentUser,
      hasFetchedCurrentUser,
      isAuthenticated,
    } = this.props;
    const {
      component: RouteComponent,
      authPage = 'LoginPage',
      paywallPage = 'GivslyChangesPage',
      auth,
    } = route;
    const canShow = canShowComponent(this.props);
    if (!canShow) {
      staticContext.unauthorized = true;
    }

    const hasSubscription = validateSubscription(currentUser);

    switch (true) {
      case auth === true && isAuthenticated && hasSubscription === true: {
        return <RouteComponent params={match.params} location={location} />;
      }
      // allow NPOs to complete onboarding with "profileType:new"
      case match.path.indexOf('/onboarding/nonprofit/:tab') > -1: {
        return <RouteComponent params={match.params} location={location} />;
      }
      case auth === true && isAuthenticated && hasSubscription === false: {
        return (
          <PaywallHoc
            componentName={paywallPage}
            hasFetchedCurrentUser={hasFetchedCurrentUser}
            params={match.params}
            location={location}
          />
        );
        // return <NamedRedirect name={paywallPage} />;
      }
      case canShow: {
        return <RouteComponent params={match.params} location={location} />;
      }
      default: {
        return (
          <NamedRedirect
            name={authPage}
            state={{ from: `${location.pathname}${location.search}${location.hash}` }}
          />
        );
      }
    }
  }
}

RouteComponentRenderer.defaultProps = {
  staticContext: {},
};

RouteComponentRenderer.propTypes = {
  isAuthenticated: bool.isRequired,
  logoutInProgress: bool.isRequired,
  route: propTypes.route.isRequired,
  match: shape({
    params: object.isRequired,
    url: string.isRequired,
  }).isRequired,
  location: shape({
    search: string.isRequired,
  }).isRequired,
  staticContext: object.isRequired,
  dispatch: func.isRequired,
};

const mapStateToProps = (state) => {
  const { isAuthenticated, logoutInProgress } = state.Auth;
  const { currentUser, hasFetchedCurrentUser } = state.user;
  return { isAuthenticated, logoutInProgress, currentUser, hasFetchedCurrentUser };
};

const RouteComponentContainer = compose(connect(mapStateToProps))(RouteComponentRenderer);

/**
 * Routes component creates React Router rendering setup.
 * It needs routeConfiguration (named as "routes") through props.
 * Using that configuration it creates navigation on top of page-level
 * components. Essentially, it's something like:
 * <Switch>
 *   <Route render={pageA} />
 *   <Route render={pageB} />
 * </Switch>
 */

const SUBSCRIPTION_SESSION_CHECK_KEY = 'subscription-session-check';

const Routes = (props) => {
  const { isAuthenticated, logoutInProgress, routes } = props;
  const dispatch = useDispatch();

  const toRouteComponent = (route) => {
    const renderProps = {
      isAuthenticated,
      logoutInProgress,
      route,
    };

    // By default, our routes are exact.
    // https://reacttraining.com/react-router/web/api/Route/exact-bool
    const isExact = route.exact != null ? route.exact : true;
    return (
      <Route
        key={route.name}
        path={route.path}
        exact={isExact}
        render={(matchProps) => (
          <RouteComponentContainer
            {...renderProps}
            match={matchProps.match}
            location={matchProps.location}
            staticContext={matchProps.staticContext}
          />
        )}
      />
    );
  };

  // Every time router renders see if we need to check subscription status
  useEffect(() => {
    const subscriptionLastChecked = sessionStorage.getItem(SUBSCRIPTION_SESSION_CHECK_KEY);
    const now = new Date().getTime();
    const timeSinceLastCheck = now - parseInt(subscriptionLastChecked, 10);
    // check subscription status every hour
    if (timeSinceLastCheck > 1000 * 60 * 60) {
      sessionStorage.setItem(SUBSCRIPTION_SESSION_CHECK_KEY, now);
      dispatch(fetchCurrentUser());
    }
  });

  // N.B. routes prop within React Router needs to stay the same,
  // so that React is is not rerendering page component.
  // That's why we pass-in props.routes instead of calling routeConfiguration here.
  return (
    <Switch>
      {routes.map(toRouteComponent)}
      <Route component={NotFoundPage} />
    </Switch>
  );
};

Routes.propTypes = {
  routes: arrayOf(propTypes.route).isRequired,
};

export default withRouter(Routes);
