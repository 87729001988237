import moment from 'moment';
import { addMarketplaceEntities } from './marketplaceData.duck';
import { types as sdkTypes } from '../util/sdkLoader';

const { UUID } = sdkTypes;

// ================ Action types ================ //

export const FETCH_BOOKINGS_REQUEST = 'app/Bookings/FETCH_BOOKINGS_REQUEST';
export const FETCH_BOOKINGS_SUCCESS = 'app/Bookings/FETCH_BOOKINGS_SUCCESS';
export const FETCH_BOOKINGS_ERROR = 'app/Bookings/FETCH_BOOKINGS_ERROR';

// ================ Reducer ================ //

const entityRefs = (entities) =>
  entities.map((entity) => ({
    id: entity.id,
    type: entity.type,
  }));

const initialState = {
  fetchBookingsInProgress: false,
  fetchBookingsError: null,
  bookingRefs: [],
  bookingsFetched: null,
};

export default function transactionsReducer(state = initialState, action = {}) {
  const { type, payload } = action;

  switch (type) {
    case FETCH_BOOKINGS_REQUEST:
      return {
        ...state,
        fetchBookingsInProgress: true,
        fetchBookingsError: null,
        bookingsFetched: null,
      };
    case FETCH_BOOKINGS_SUCCESS:
      return {
        ...state,
        fetchBookingsInProgress: false,
        bookingRefs: payload,
        bookingsFetched: new Date(),
      };
    case FETCH_BOOKINGS_ERROR:
      return {
        ...state,
        fetchBookingsInProgress: false,
        fetchBookingsError: payload,
        bookingsFetched: null,
      };

    default:
      return state;
  }
}

// ================ Action creators ================ //

const fetchBookingsRequest = () => ({ type: FETCH_BOOKINGS_REQUEST });
const fetchBookingsSuccess = (payload) => ({ type: FETCH_BOOKINGS_SUCCESS, payload });
const fetchBookingsError = (e) => ({ type: FETCH_BOOKINGS_ERROR, payload: e });

// ================ Thunks ================ //
export const currentUserBookings = (currentUser, state = undefined) => (
  dispatch,
  getState,
  sdk
) => {
  const bookingsFetched = getState().Bookings.bookingsFetched;
  if (bookingsFetched) return;

  dispatch(fetchBookingsRequest());

  const { timezone, listingId } = currentUser.attributes.profile.publicData;
  const currentUserListingId = new UUID(listingId);

  const today = moment().tz(timezone);
  const startOfMonth = today.clone();
  startOfMonth.startOf('month');
  const end = today.clone();
  end.add(90, 'days');

  const apiQueryParams = {
    listingId: currentUserListingId,
    state,
  };

  const futureBookingsParams = {
    ...apiQueryParams,
    start: today.toDate(),
    end: end.toDate(),
  };

  const pastBookingsParams = {
    ...apiQueryParams,
    start: startOfMonth.toDate(),
    end: today.toDate(),
  };

  return Promise.all([
    sdk.bookings.query(futureBookingsParams),
    sdk.bookings.query(pastBookingsParams),
  ])
    .then((responses) => {
      const entities = responses.reduce((refs, response) => {
        dispatch(addMarketplaceEntities(response));
        return [...refs, ...entityRefs(response.data.data)];
      }, []);

      dispatch(fetchBookingsSuccess(entities));
    })
    .catch((e) => {
      dispatch(fetchBookingsError(e));
      throw e;
    });
};
