// import * as importedSdk from 'sharetribe-flex-sdk';
import utils from '@givsly/sharetribe-utils';
const importedSdk = utils.sdk.marketplaceSdk;

let exportSdk;
let exportUtils;

const isServer = () => typeof window === 'undefined';

if (isServer()) {
  // Use eval to skip webpack from bundling SDK in Node
  // eslint-disable-next-line no-eval
  exportUtils = eval('require')('@givsly/sharetribe-utils');
  exportSdk = exportUtils.sdk.marketplaceSdk;
} else {
  exportSdk = importedSdk;
}

const { createInstance, types } = exportSdk;
export { createInstance, types };
