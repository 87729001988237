import React from 'react';
import { useIntl } from 'react-intl';
import { formatMoney } from '../../util/currency';
import { propTypes } from '../../util/types';
import css from './BookingBreakdown.css';
import { string } from 'prop-types';
import Tippy from '@tippy.js/react';
import classNames from 'classnames';

const LineItemWithTooltip = (props) => {
  const { label, moneyValue, tooltipHeader, tooltipBody } = props;
  const intl = useIntl();

  return (
    <div className={classNames(css.lineItem, css.lineItemCustomerCommission)}>
      <span className={css.itemLabel}>
        {`${label} `}
        <Tippy
          className={css.givslyFeeTooltip}
          maxWidth={320}
          content={
            <>
              {tooltipHeader ? <h5 className={css.tooltipHeading}>{tooltipHeader}</h5> : null}
              <div className={css.tooltipText}>{tooltipBody}</div>
            </>
          }
        >
          <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12">
            <path
              fill="#FAC51D"
              d="M6 0a6 6 0 1 1 0 12A6 6 0 0 1 6 0zm.5 5.5h-1A.5.5 0 0 0 5 6v3.5a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5V6a.5.5 0 0 0-.5-.5zM6 2a1.25 1.25 0 1 0 0 2.5A1.25 1.25 0 0 0 6 2z"
            />
          </svg>
        </Tippy>
      </span>
      <span className={css.itemValue}>{formatMoney(intl, moneyValue)}</span>
    </div>
  );
};

LineItemWithTooltip.propTypes = {
  label: string.isRequired,
  moneyValue: propTypes.money.isRequired,
  tooltipHeader: string,
  tooltipBody: string.isRequired,
};

export default LineItemWithTooltip;
