import React, { Component } from 'react';
import { ButtonTabNavHorizontal } from '../../components';
import { array, arrayOf, bool, func, object, oneOf, string } from 'prop-types';
import { propTypes } from '../../util/types';
import TimeSlotFormRegular from './TimeSlotFormRegular';
import { compose } from 'redux';
import { injectIntl } from 'react-intl';
import { intlShape } from '../../util/reactIntl';
import TimeSlotFormEvent from './TimeSlotFormEvent';
import css from './TimeSlotForm.css';

export const TIMESLOT_FORM_TAB_REGULAR = 'regular';
export const TIMESLOT_FORM_TAB_EVENT = 'event';

class TimeSlotFormContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      activeTab:
        this.props.tab ||
        (this.showRegularTab ? TIMESLOT_FORM_TAB_REGULAR : TIMESLOT_FORM_TAB_EVENT),
    };
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.state.activeTab === TIMESLOT_FORM_TAB_REGULAR && this.isEventTimeslotEditMode) {
      this.setState({ activeTab: TIMESLOT_FORM_TAB_EVENT });
    }
  }

  get isEventTimeslotEditMode() {
    return this.props.inEditMode && this.props.initialValues && this.props.initialValues.event;
  }

  get isRegularTimeslotEditMode() {
    return this.props.inEditMode && this.props.initialValues && !this.props.initialValues.event;
  }

  get hasEvents() {
    return this.props.eventsOpenVolunteering.length > 0;
  }

  get showEventTab() {
    return !this.isRegularTimeslotEditMode && this.hasEvents;
  }

  get showRegularTab() {
    return !this.isEventTimeslotEditMode;
  }

  get tabs() {
    const tabs = [];

    if (this.showRegularTab) {
      tabs.push(TIMESLOT_FORM_TAB_REGULAR);
    }

    if (this.showEventTab) {
      tabs.push(TIMESLOT_FORM_TAB_EVENT);
    }

    return tabs.map((tab) => ({
      text: this.getTabName(tab),
      onClick: () => this.switchTab(tab),
      selected: this.state.activeTab === tab,
      button: {
        className: css.tabButton,
        selectedClassName: css.tabButtonSelected,
      },
    }));
  }

  getTabName(tab) {
    // If only one event to volunteer, show events name as tab name
    if (tab === TIMESLOT_FORM_TAB_EVENT && this.props.eventsOpenVolunteering.length === 1) {
      return this.props.eventsOpenVolunteering[0].title;
    }

    return this.props.intl.formatMessage({ id: `TimeSlotFormContainer.tab.${tab}` });
  }

  get initialValues() {
    if (this.state.activeTab === TIMESLOT_FORM_TAB_REGULAR) {
      return {
        ...this.props.initialValues,
        event: undefined,
      };
    }

    if (this.props.eventsOpenVolunteering.length === 1) {
      return {
        ...this.props.initialValues,
        event: this.props.eventsOpenVolunteering[0].key,
      };
    }

    return this.props.initialValues;
  }

  get tabContent() {
    const { eventsOpenVolunteering, initialValues, ...formProps } = this.props;

    switch (this.state.activeTab) {
      case TIMESLOT_FORM_TAB_REGULAR:
        return <TimeSlotFormRegular {...formProps} initialValues={this.initialValues} />;
      case TIMESLOT_FORM_TAB_EVENT:
        return (
          this.hasEvents && (
            <TimeSlotFormEvent
              {...formProps}
              initialValues={this.initialValues}
              events={eventsOpenVolunteering}
            />
          )
        );
      default:
        return null;
    }
  }

  switchTab(tab) {
    if (this.props.trackEvent) {
      this.props.trackEvent({
        category: 'Availability',
        action: 'OpenTab',
        label: tab,
      });
    }
    this.setState({ activeTab: tab });
  }

  render() {
    return (
      <div>
        {this.tabs.length >= 2 && (
          <div className={css.tabNavContainer}>
            <ButtonTabNavHorizontal tabs={this.tabs} />
          </div>
        )}
        <div>{this.tabContent}</div>
      </div>
    );
  }
}

TimeSlotFormContainer.propTypes = {
  eventsOpenVolunteering: arrayOf(propTypes.event),
  exceedsWeeklyLimit: func.isRequired,
  inEditMode: bool.isRequired,
  initialValues: object,
  intl: intlShape.isRequired,
  isVisible: bool,
  onClose: func.isRequired,
  onFetchTimeSlots: func.isRequired,
  onSubmit: func.isRequired,
  originalDate: object,
  originalDuration: string.isRequired,
  reservableSlots: array,
  selectedDate: object,
  tab: oneOf([TIMESLOT_FORM_TAB_REGULAR, TIMESLOT_FORM_TAB_EVENT]),
  timezone: string.isRequired,
  trackEvent: func,
};

TimeSlotFormContainer.defaultProps = {
  className: null,
  eventsOpenVolunteering: [],
  isVisible: false,
  reservableSlots: [],
  updateError: null,
  updateInProgress: false,
};

export default compose(injectIntl)(TimeSlotFormContainer);
