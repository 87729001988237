var merge = require("lodash.merge");

var _require = require("./user"),
    ensureUser = _require.ensureUser;

var _require2 = require("../listing/index"),
    ensureListing = _require2.ensureListing;
/***********************************************************************************************************************
 * Transitions
 **********************************************************************************************************************/


var TRANSITION_CREATE_NOTIFICATION = "transition/create-notification";
var TRANSITION_PROVIDER_ARCHIVE = "transition/provider-archive";
var TRANSITION_PROVIDER_SEEN = "transition/provider-seen";
var TRANSITION_PROVIDER_SEEN_SENT = "transition/provider-seen-sent";
var TRANSITION_PROVIDER_UPDATE = "transition/provider-update";
var TRANSITION_CUSTOMER_ARCHIVE = "transition/customer-archive";
var TRANSITION_CUSTOMER_SEEN = "transition/customer-seen";
var TRANSITION_CUSTOMER_SEEN_SENT = "transition/customer-seen-sent";
var TRANSITION_CUSTOMER_UPDATE = "transition/customer-update";
var TRANSITION_OPERATOR_SEND_NEW = "transition/operator-send-new";
var TRANSITION_OPERATOR_SEND_SEEN = "transition/operator-send-seen";
var TRANSITION_OPERATOR_UPDATE_NEW = "transition/operator-update-new";
var TRANSITION_OPERATOR_UPDATE_SEEN = "transition/operator-update-seen";
var TRANSITION_OPERATOR_UPDATE_ARCHIVED = "transition/operator-update-archived";
var TRANSITION_OPERATOR_ARCHIVE_NEW = "transition/operator-archive-new";
var TRANSITION_OPERATOR_ARCHIVE_SEEN = "transition/operator-archive-seen";
var TRANSITION_OPERATOR_ARCHIVE_SEEN_SENT = "transition/operator-archive-seen-sent";
var TRANSITION_OPERATOR_ARCHIVE_SENT = "transition/operator-archive-sent";
/***********************************************************************************************************************
 * Capabilities
 **********************************************************************************************************************/

var CAPABILITY_ARCHIVE = "archive";
var CAPABILITY_SEE = "see";
var CAPABILITY_UPDATE = "update";
/***********************************************************************************************************************
 * States
 **********************************************************************************************************************/

var STATE_ARCHIVED = "archived";
var STATE_NEW = "new";
var STATE_NEW_SENT = "new-sent";
var STATE_SEEN = "seen";
var STATE_SEEN_SENT = "seen-sent";
/***********************************************************************************************************************
 * Notification types
 **********************************************************************************************************************/

var TYPE_ASK_FOR_TIME = "ask-for-time";
var TYPE_NEW_TIMES_AVAILABLE = "new-times-available";
/***********************************************************************************************************************
 * Roles
 **********************************************************************************************************************/

var ROLE_CUSTOMER = "customer";
var ROLE_PROVIDER = "provider";
/***********************************************************************************************************************
 * Delivery frequencies
 **********************************************************************************************************************/

var FREQUENCY_HOURLY = "hourly";
var FREQUENCY_DAILY = "daily";
var FREQUENCY_WEEKLY = "weekly";
/***********************************************************************************************************************
 * Bundled transitions by user role
 **********************************************************************************************************************/

var CUSTOMER_TRANSITIONS = [TRANSITION_CREATE_NOTIFICATION, TRANSITION_CUSTOMER_ARCHIVE, TRANSITION_CUSTOMER_SEEN, TRANSITION_CUSTOMER_SEEN_SENT, TRANSITION_CUSTOMER_UPDATE];
var OPERATOR_TRANSITIONS = [TRANSITION_OPERATOR_ARCHIVE_NEW, TRANSITION_OPERATOR_ARCHIVE_SEEN, TRANSITION_OPERATOR_ARCHIVE_SEEN_SENT, TRANSITION_OPERATOR_ARCHIVE_SENT, TRANSITION_OPERATOR_SEND_NEW, TRANSITION_OPERATOR_SEND_SEEN, TRANSITION_OPERATOR_UPDATE_NEW, TRANSITION_OPERATOR_UPDATE_SEEN, TRANSITION_OPERATOR_UPDATE_ARCHIVED];
var PROVIDER_TRANSITIONS = [TRANSITION_PROVIDER_ARCHIVE, TRANSITION_PROVIDER_SEEN, TRANSITION_PROVIDER_SEEN_SENT, TRANSITION_PROVIDER_UPDATE];
/***********************************************************************************************************************
 * Bundled transitions by target state
 **********************************************************************************************************************/

var ALL_TRANSITIONS = [].concat(CUSTOMER_TRANSITIONS, OPERATOR_TRANSITIONS, PROVIDER_TRANSITIONS);
var NEW_TRANSITIONS_ONLY = [TRANSITION_CREATE_NOTIFICATION, TRANSITION_CUSTOMER_UPDATE, TRANSITION_OPERATOR_UPDATE_NEW, TRANSITION_PROVIDER_UPDATE, TRANSITION_OPERATOR_SEND_NEW];
var SEEN_TRANSITIONS_ONLY = [TRANSITION_CUSTOMER_SEEN, TRANSITION_CUSTOMER_SEEN_SENT, TRANSITION_PROVIDER_SEEN, TRANSITION_PROVIDER_SEEN_SENT, TRANSITION_OPERATOR_SEND_SEEN];
var SENT_TRANSITIONS_ONLY = [TRANSITION_CUSTOMER_SEEN_SENT, TRANSITION_OPERATOR_SEND_NEW, TRANSITION_OPERATOR_SEND_SEEN, TRANSITION_PROVIDER_SEEN_SENT];
var ARCHIVED_TRANSITIONS_ONLY = [TRANSITION_CUSTOMER_ARCHIVE, TRANSITION_PROVIDER_ARCHIVE, TRANSITION_OPERATOR_ARCHIVE_NEW, TRANSITION_OPERATOR_ARCHIVE_SEEN, TRANSITION_OPERATOR_ARCHIVE_SEEN_SENT, TRANSITION_OPERATOR_ARCHIVE_SENT, ""];
var OPERATOR_ARCHIVED_TRANSITIONS_ONLY = [TRANSITION_OPERATOR_ARCHIVE_NEW, TRANSITION_OPERATOR_ARCHIVE_SEEN, TRANSITION_OPERATOR_ARCHIVE_SEEN_SENT, TRANSITION_OPERATOR_ARCHIVE_SENT];
/***********************************************************************************************************************
 * Miscellaneous bundles
 **********************************************************************************************************************/
// Transitions that indicate a notification is visible for the provider

var VISIBLE_TRANSITIONS_ONLY = [TRANSITION_CREATE_NOTIFICATION, TRANSITION_CUSTOMER_SEEN, TRANSITION_CUSTOMER_SEEN_SENT, TRANSITION_CUSTOMER_UPDATE, TRANSITION_OPERATOR_UPDATE_NEW, TRANSITION_OPERATOR_UPDATE_SEEN, TRANSITION_OPERATOR_SEND_NEW, TRANSITION_OPERATOR_SEND_SEEN, TRANSITION_PROVIDER_SEEN, TRANSITION_PROVIDER_SEEN_SENT, TRANSITION_PROVIDER_UPDATE];
var UNSEEN_TRANSITIONS_ONLY = [].concat(NEW_TRANSITIONS_ONLY, [TRANSITION_OPERATOR_SEND_NEW]);
/***********************************************************************************************************************
 * Helper methods
 **********************************************************************************************************************/

var ensureNotification = function ensureNotification(notification) {
  var empty = {
    id: null,
    type: "transaction",
    attributes: {
      lineItems: [],
      protectedData: {}
    },
    customer: ensureUser(notification ? notification.customer : {}),
    provider: ensureUser(notification ? notification.provider : {}),
    listing: ensureListing(notification ? notification.listing : {})
  };
  return merge(empty, notification);
};
/**
 * Gets the current state for the given notification
 *
 * @param ensuredNotification
 * @returns {string}
 */


var getCurrentState = function getCurrentState(ensuredNotification) {
  var lastTransition = ensuredNotification.attributes.lastTransition;
  var isSent = SENT_TRANSITIONS_ONLY.includes(lastTransition);
  var isSeen = SEEN_TRANSITIONS_ONLY.includes(lastTransition);
  var isNew = NEW_TRANSITIONS_ONLY.includes(lastTransition);

  if (isNew && isSent) {
    return STATE_NEW_SENT;
  } else if (isSeen && isSent) {
    return STATE_SEEN_SENT;
  } else if (isSeen) {
    return STATE_SEEN;
  } else if (isNew) {
    return STATE_NEW;
  } else {
    return STATE_ARCHIVED;
  }
};
/**
 * Gets the role for the given user inside the given notification
 *
 * @param ensuredUser
 * @param ensuredNotification
 * @returns {string|null}
 */


var getRoleForUser = function getRoleForUser(ensuredUser, ensuredNotification) {
  if (ensuredNotification.customer.id && ensuredNotification.provider.id) {
    if (ensuredNotification.customer.id.uuid === ensuredUser.id.uuid) {
      return ROLE_CUSTOMER;
    } else if (ensuredNotification.provider.id.uuid === ensuredUser.id.uuid) {
      return ROLE_PROVIDER;
    } else {
      return null;
    }
  }

  return null;
};
/**
 * Gets the send transition for the notification based on the current state/last transition.
 *
 * @param ensuredNotification
 * @returns {string|null}
 */


var getSendTransition = function getSendTransition(ensuredNotification) {
  var currentState = getCurrentState(ensuredNotification);

  if (currentState === STATE_NEW) {
    return TRANSITION_OPERATOR_SEND_NEW;
  } else if (currentState === STATE_SEEN) {
    return TRANSITION_OPERATOR_SEND_SEEN;
  } else {
    return null;
  }
};
/**
 * Checks if a notification can be sent out (does not respect user notification settings).
 *
 * @param ensuredNotification
 * @returns {boolean|boolean}
 */


var canSend = function canSend(ensuredNotification) {
  var currentState = getCurrentState(ensuredNotification);
  return !isSent(ensuredNotification) && (currentState === STATE_NEW || currentState === STATE_SEEN);
};
/**
 * Checks whether the notification has been sent out already.
 *
 * @param ensuredNotification
 * @returns {boolean}
 */


var isSent = function isSent(ensuredNotification) {
  var currentState = getCurrentState(ensuredNotification);
  return currentState === STATE_SEEN_SENT || currentState === STATE_NEW_SENT;
};

module.exports = {
  TRANSITION_CREATE_NOTIFICATION: TRANSITION_CREATE_NOTIFICATION,
  TRANSITION_PROVIDER_ARCHIVE: TRANSITION_PROVIDER_ARCHIVE,
  TRANSITION_PROVIDER_SEEN: TRANSITION_PROVIDER_SEEN,
  TRANSITION_PROVIDER_SEEN_SENT: TRANSITION_PROVIDER_SEEN_SENT,
  TRANSITION_PROVIDER_UPDATE: TRANSITION_PROVIDER_UPDATE,
  TRANSITION_CUSTOMER_ARCHIVE: TRANSITION_CUSTOMER_ARCHIVE,
  TRANSITION_CUSTOMER_SEEN: TRANSITION_CUSTOMER_SEEN,
  TRANSITION_CUSTOMER_SEEN_SENT: TRANSITION_CUSTOMER_SEEN_SENT,
  TRANSITION_CUSTOMER_UPDATE: TRANSITION_CUSTOMER_UPDATE,
  TRANSITION_OPERATOR_SEND_NEW: TRANSITION_OPERATOR_SEND_NEW,
  TRANSITION_OPERATOR_SEND_SEEN: TRANSITION_OPERATOR_SEND_SEEN,
  TRANSITION_OPERATOR_UPDATE_NEW: TRANSITION_OPERATOR_UPDATE_NEW,
  TRANSITION_OPERATOR_UPDATE_SEEN: TRANSITION_OPERATOR_UPDATE_SEEN,
  TRANSITION_OPERATOR_UPDATE_ARCHIVED: TRANSITION_OPERATOR_UPDATE_ARCHIVED,
  TRANSITION_OPERATOR_ARCHIVE_NEW: TRANSITION_OPERATOR_ARCHIVE_NEW,
  TRANSITION_OPERATOR_ARCHIVE_SEEN: TRANSITION_OPERATOR_ARCHIVE_SEEN,
  TRANSITION_OPERATOR_ARCHIVE_SEEN_SENT: TRANSITION_OPERATOR_ARCHIVE_SEEN_SENT,
  TRANSITION_OPERATOR_ARCHIVE_SENT: TRANSITION_OPERATOR_ARCHIVE_SENT,
  CAPABILITY_ARCHIVE: CAPABILITY_ARCHIVE,
  CAPABILITY_SEE: CAPABILITY_SEE,
  CAPABILITY_UPDATE: CAPABILITY_UPDATE,
  STATE_ARCHIVED: STATE_ARCHIVED,
  STATE_NEW: STATE_NEW,
  STATE_NEW_SENT: STATE_NEW_SENT,
  STATE_SEEN: STATE_SEEN,
  STATE_SEEN_SENT: STATE_SEEN_SENT,
  TYPE_ASK_FOR_TIME: TYPE_ASK_FOR_TIME,
  TYPE_NEW_TIMES_AVAILABLE: TYPE_NEW_TIMES_AVAILABLE,
  ROLE_CUSTOMER: ROLE_CUSTOMER,
  ROLE_PROVIDER: ROLE_PROVIDER,
  FREQUENCY_HOURLY: FREQUENCY_HOURLY,
  FREQUENCY_DAILY: FREQUENCY_DAILY,
  FREQUENCY_WEEKLY: FREQUENCY_WEEKLY,
  CUSTOMER_TRANSITIONS: CUSTOMER_TRANSITIONS,
  OPERATOR_TRANSITIONS: OPERATOR_TRANSITIONS,
  PROVIDER_TRANSITIONS: PROVIDER_TRANSITIONS,
  ALL_TRANSITIONS: ALL_TRANSITIONS,
  NEW_TRANSITIONS_ONLY: NEW_TRANSITIONS_ONLY,
  SEEN_TRANSITIONS_ONLY: SEEN_TRANSITIONS_ONLY,
  SENT_TRANSITIONS_ONLY: SENT_TRANSITIONS_ONLY,
  OPERATOR_ARCHIVED_TRANSITIONS_ONLY: OPERATOR_ARCHIVED_TRANSITIONS_ONLY,
  ARCHIVED_TRANSITIONS_ONLY: ARCHIVED_TRANSITIONS_ONLY,
  VISIBLE_TRANSITIONS_ONLY: VISIBLE_TRANSITIONS_ONLY,
  UNSEEN_TRANSITIONS_ONLY: UNSEEN_TRANSITIONS_ONLY,
  canSend: canSend,
  ensureNotification: ensureNotification,
  getCurrentState: getCurrentState,
  getRoleForUser: getRoleForUser,
  getSendTransition: getSendTransition,
  isSent: isSent
};