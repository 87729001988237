import React, { useRef, useState } from 'react';
import { array, bool, func, object, shape } from 'prop-types';
import { FOOTER_TYPE_IMPACT_TOTAL } from '../../components/ListingCard/constants';
import { useScrollTracker } from '../../util/analytics';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { trackEventAction } from '../../ducks/Analytics.duck';
import { getMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import routeConfiguration from '../../routeConfiguration';
import { isTestMarketplace } from '../../util/environment';
import { withMessages } from '../../util/localization';
import { injectIntl } from '../../util/reactIntl';
import { isScrollingDisabled, manageDisableScrolling } from '../../ducks/UI.duck';
import config from '../../config';
import {
  Page,
  LayoutSingleColumn,
  LayoutWrapperTopbar,
  LayoutWrapperMain,
  LayoutWrapperFooter,
  Footer,
  SectionCallToAction,
  SectionFeaturedMembers,
  SectionImpactCards,
  SectionVideo,
  SectionCallToActionFooter,
  SectionImpactHero,
} from '../../components';
import { TopbarContainer } from '../../containers';
import sharetribe from '@givsly/sharetribe-utils';

import facebookImage from '../../assets/givslyFacebook-1200x630.png';
import twitterImage from '../../assets/givslyTwitter-600x314.png';
import { createResourceLocatorString } from '../../util/routes';
import css from './ImpactEstimatePage.css';
import { loadData } from './ImpactEstimatePage.duck';
import { types as sdkTypes } from '../../util/sdkLoader';
import {
  IMAGE_DONATED,
  IMAGE_NONPROFITS,
  IMAGE_PROFESSIONALS,
} from '../../components/SectionImpactCards/constants';
import { ReactTypeformEmbed } from 'react-typeform-embed';
const { UUID } = sdkTypes;

const CATEGORY = 'Impact Estimate Landing Page';
const ACTION_OPEN_QUIZ = 'Open Quiz';
const ACTION_NAVIGATE_BROWSE_NONPROFITS = 'Navigate to browse nonprofits';
const ACTION_NAVIGATE_IMPACT_REPORT = 'Navigate to impact report';
const ACTION_NAVIGATE_PROFILE = 'Navigate to profile';
const ACTION_NAVIGATE_SEARCH_MEMBERS = 'Navigate to search members';
const ACTION_NAVIGATE_SIGN_UP = 'Navigate to sign up';
const ACTION_SCROLL = 'Scroll depth';
const ACTION_SCROLL_MEET_GIVSLY = 'Scroll to Meet Givsly';
const VALUE_CENTER_CTA = 'Center CTA';
const VALUE_FOOTER_CTA = 'Footer CTA';
const VALUE_HERO = 'Hero';
const VALUE_IMPACT_CARDS = 'Impact Cards';
const VALUE_OTHER_MEMBERS = 'Other Members';

export const ImpactEstimatePageComponent = (props) => {
  const {
    getMessage,
    highlightedNonprofit,
    history,
    listings,
    loadingInProgress,
    location,
    nonprofitImpactMap,
    nonprofitNameMap,
    scrollingDisabled,
    trackEvent,
  } = props;
  const meetGivslyRef = useRef(null);

  // Placeholder for the embedded typeform, used to open the Typeform modal
  let typeFormEmbed;

  const [quizOpenedFromHero, setQuizOpenedFromHero] = useState(false);
  const [quizOpenedFromCenter, setQuizOpenedFromCenter] = useState(false);

  // Scroll tracking
  useScrollTracker([25, 50, 75, 100], ({ scrollY }) => {
    trackEvent(CATEGORY, ACTION_SCROLL, `${scrollY}%`, location.pathname);
  });

  // Schema props
  const schemaTitle = getMessage('schemaTitle');
  const schemaDescription = getMessage('schemaDescription');
  const schemaImage = `${config.canonicalRootURL}${facebookImage}`;

  return (
    <Page
      className={css.root}
      scrollingDisabled={scrollingDisabled}
      contentType="website"
      description={schemaDescription}
      title={schemaTitle}
      facebookImages={[{ url: facebookImage, width: 1200, height: 630 }]}
      twitterImages={[
        { url: `${config.canonicalRootURL}${twitterImage}`, width: 600, height: 314 },
      ]}
      schema={{
        '@context': 'http://schema.org',
        '@type': 'WebPage',
        description: schemaDescription,
        name: schemaTitle,
        image: [schemaImage],
      }}
    >
      <LayoutSingleColumn>
        <LayoutWrapperTopbar>
          <TopbarContainer currentPage="LandingPage" />
        </LayoutWrapperTopbar>
        <LayoutWrapperMain className={css.layoutMainWrapper}>
          <ul className={css.sections}>
            <SectionImpactHero
              bodyText={getMessage('hero.bodyText')}
              highlightedNonprofit={highlightedNonprofit}
              primaryButtonOnClick={() => {
                setQuizOpenedFromHero(true);
                trackEvent(
                  CATEGORY,
                  ACTION_OPEN_QUIZ,
                  getMessage('hero.primaryButtonText'),
                  VALUE_HERO
                );
                typeFormEmbed.typeform.open();
              }}
              primaryButtonText={getMessage('hero.primaryButtonText')}
              secondaryButtonOnClick={() => {
                trackEvent(
                  CATEGORY,
                  ACTION_SCROLL_MEET_GIVSLY,
                  getMessage('hero.secondaryButtonText'),
                  VALUE_HERO
                );
                meetGivslyRef.current.scrollIntoView({
                  behavior: 'smooth',
                });
              }}
              secondaryButtonText={getMessage('hero.secondaryButtonText')}
              title={getMessage('hero.title')}
            />
            <SectionFeaturedMembers
              className={css.sectionOtherImpacters}
              footerItemLimit={3}
              footerType={FOOTER_TYPE_IMPACT_TOTAL}
              loadingInProgress={loadingInProgress}
              listings={listings}
              mobileTitle={getMessage('otherSocialImpacters.title.mobile')}
              nonprofitImpactMap={nonprofitImpactMap}
              nonprofitNameMap={nonprofitNameMap}
              onClick={(memberName) => {
                trackEvent(CATEGORY, ACTION_NAVIGATE_PROFILE, memberName, VALUE_OTHER_MEMBERS);
              }}
              showNonprofitImpact={true}
              title={getMessage('otherSocialImpacters.title.desktop')}
            />
            <SectionCallToAction
              bodyText={getMessage('callToAction.bodyText')}
              linkButtonLabel={getMessage('callToAction.buttonLabel')}
              onClick={() => {
                trackEvent(
                  CATEGORY,
                  ACTION_OPEN_QUIZ,
                  getMessage('callToAction.buttonLabel'),
                  VALUE_CENTER_CTA
                );
                setQuizOpenedFromCenter(true);
                typeFormEmbed.typeform.open();
              }}
              title={getMessage('callToAction.title')}
            />
            <SectionVideo
              className={css.sectionVideo}
              innerRef={meetGivslyRef}
              subTitle={getMessage('video.subTitle')}
              title={getMessage('video.title')}
            >
              <iframe
                className={css.embeddedVideo}
                src="https://www.youtube.com/embed/U_6iTmj1yQ4"
                title={getMessage('video.title')}
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              />
            </SectionVideo>
            <SectionImpactCards
              cards={[
                {
                  bodyText: getMessage('impactCards.donated.bodyText'),
                  image: IMAGE_DONATED,
                  linkTarget: 'https://company.givsly.com/2020-impact-report/',
                  linkTitle: getMessage('impactCards.donated.linkTitle'),
                  onClick: () => {
                    trackEvent(
                      CATEGORY,
                      ACTION_NAVIGATE_IMPACT_REPORT,
                      getMessage('impactCards.donated.linkTitle'),
                      VALUE_IMPACT_CARDS
                    );
                  },
                  title: getMessage('impactCards.donated.title'),
                },
                {
                  bodyText: getMessage('impactCards.nonprofits.bodyText'),
                  image: IMAGE_NONPROFITS,
                  linkTarget: 'NonprofitSearchPage',
                  linkTitle: getMessage('impactCards.nonprofits.linkTitle'),
                  onClick: () => {
                    trackEvent(
                      CATEGORY,
                      ACTION_NAVIGATE_BROWSE_NONPROFITS,
                      getMessage('impactCards.nonprofits.linkTitle'),
                      VALUE_IMPACT_CARDS
                    );
                  },
                  title: getMessage('impactCards.nonprofits.title'),
                },
                {
                  bodyText: getMessage('impactCards.professionals.bodyText'),
                  image: IMAGE_PROFESSIONALS,
                  linkTarget: 'SearchPage',
                  linkTitle: getMessage('impactCards.professionals.linkTitle'),
                  onClick: () => {
                    trackEvent(
                      CATEGORY,
                      ACTION_NAVIGATE_SEARCH_MEMBERS,
                      getMessage('impactCards.professionals.linkTitle'),
                      VALUE_IMPACT_CARDS
                    );
                  },
                  title: getMessage('impactCards.professionals.title'),
                },
              ]}
              className={css.sectionImpactCards}
            />
            <SectionCallToActionFooter
              className={css.sectionCallToActionFooter}
              bodyText={getMessage('callToActionFooter.bodyText')}
              buttonText={getMessage('callToActionFooter.buttonText')}
              onClick={() => {
                trackEvent(
                  CATEGORY,
                  ACTION_NAVIGATE_SIGN_UP,
                  getMessage('callToActionFooter.buttonText'),
                  VALUE_FOOTER_CTA
                );
                history.push(createResourceLocatorString('SignupPage', routeConfiguration()));
              }}
              subTitle={getMessage('callToActionFooter.subTitle')}
              title={getMessage('callToActionFooter.title')}
            />
          </ul>
        </LayoutWrapperMain>
        <LayoutWrapperFooter>
          <Footer />
          <ReactTypeformEmbed
            autoClose={10000}
            mode={'popup'}
            popup={true}
            ref={(tf) => {
              typeFormEmbed = tf;
            }}
            url="https://form.typeform.com/to/Bk1bpwqE?typeform-medium=embed-snippet"
          />
          {quizOpenedFromHero && !isTestMarketplace() ? (
            <img
              height="1"
              width="1"
              style={{ display: 'none' }}
              alt=""
              src="https://px.ads.linkedin.com/collect/?pid=2244585&conversionId=4303052&fmt=gif"
            />
          ) : null}
          {quizOpenedFromCenter && !isTestMarketplace() ? (
            <img
              height="1"
              width="1"
              style={{ display: 'none' }}
              alt=""
              src="https://px.ads.linkedin.com/collect/?pid=2244585&conversionId=4303060&fmt=gif"
            />
          ) : null}
        </LayoutWrapperFooter>
      </LayoutSingleColumn>
    </Page>
  );
};

ImpactEstimatePageComponent.propTypes = {
  history: shape({
    push: func.isRequired,
  }).isRequired,
  loadingInProgress: bool,
  listings: array,
  location: object.isRequired,
  nonprofitImpactMap: object,
  nonprofitNameMap: object,
  onManageDisableScrolling: func.isRequired,
  scrollingDisabled: bool.isRequired,
  trackEvent: func.isRequired,
};

ImpactEstimatePageComponent.defaultProps = {
  listings: [],
  loadingInProgress: true,
  nonprofitImpactMap: {},
  nonprofitNameMap: {},
};

const mapStateToProps = (state) => {
  const { listingsInProgress: listingLoadingInProgress } = state.Listings;

  const getListing = (id) => {
    const ref = { id: new UUID(id), type: 'listing' };
    const listings = getMarketplaceEntities(state, [ref]);
    return listings.length === 1 ? listings[0] : null;
  };

  // Featured listings
  const listings = [];
  if (!listingLoadingInProgress) {
    config.custom.impactEstimateListings.forEach((listingId) => {
      listings.push(sharetribe.listing.ensureListing(getListing(listingId)));
    });
  }

  // Highlighted nonprofit (hero)
  const highlightedNonprofit = sharetribe.listing.ensureNonprofitListing(
    getListing(config.custom.impactEstimateHighlightedNonprofit)
  );

  // Nonprofit name map (used in featured listings)
  const {
    nonprofitImpactMap,
    nonprofitNameMap,
    searchInProgress: nonprofitLoadingInProgress,
  } = state.NonprofitListing;

  return {
    highlightedNonprofit,
    loadingInProgress: nonprofitLoadingInProgress || listingLoadingInProgress,
    listings,
    nonprofitImpactMap,
    nonprofitNameMap,
    scrollingDisabled: isScrollingDisabled(state),
  };
};

const mapDispatchToProps = (dispatch) => ({
  onManageDisableScrolling: (componentId, disableScrolling) =>
    dispatch(manageDisableScrolling(componentId, disableScrolling)),
  trackEvent: (category, action, label, value = null) =>
    dispatch(
      trackEventAction({
        category,
        action,
        label,
        value,
      })
    ),
});

// Helper to use in compose, since compose only passes the component itself and doesn't allow for
// more arguments.
const withLocalizedMessages = (component) => {
  return withMessages(component, 'ImpactEstimatePage');
};

const ImpactEstimatePage = compose(
  withRouter,
  withLocalizedMessages,
  connect(mapStateToProps, mapDispatchToProps),
  injectIntl
)(ImpactEstimatePageComponent);

// Load data
ImpactEstimatePage.loadData = loadData;

export default ImpactEstimatePage;
