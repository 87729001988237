import classNames from 'classnames';
import { array, bool, func, object, number, string } from 'prop-types';
import React from 'react';
import { ListingCard } from '../../components';
import { AVATAR_SIZES_EXTRA_LARGE } from '../Avatar/Avatar';
import { FOOTER_TYPE_DEFAULT } from '../ListingCard/constants';
import SectionTitle from '../SectionTitle/SectionTitle';
import css from './SectionFeaturedMembers.css';

const SectionFeaturedMembers = props => {
  const {
    className,
    footerItemLimit,
    footerType,
    loadingInProgress,
    listings,
    mobileTitle,
    nonprofitImpactMap,
    nonprofitNameMap,
    onClick,
    showNonprofitImpact,
    title,
  } = props;

  const panelMediumWidth = 50;
  const panelLargeWidth = 62.5;

  const cardRenderSizes = [
    '(max-width: 767px) 100vw',
    `(max-width: 1023px) ${panelMediumWidth}vw`,
    `(max-width: 1920px) ${panelLargeWidth / 2}vw`,
    `${panelLargeWidth / 3}vw`,
  ].join(', ');

  return (
    <li className={classNames(css.root, className)}>
      <SectionTitle
        className={css.title}
        mobileTitle={mobileTitle}
        title={title}
      />
      <div
        className={css.sectionContent}
      >
        {!loadingInProgress && listings.length
          ? listings.map(listing => {
            return listing && listing.id ? (
              <ListingCard
                avatarSize={AVATAR_SIZES_EXTRA_LARGE}
                footerType={footerType}
                footerItemLimit={footerItemLimit}
                rootClassName={css.listingCard}
                key={`volunteer-${listing.id.uuid}`}
                listing={listing}
                renderSizes={cardRenderSizes}
                setActiveListing={() => null}
                nonprofitImpactMap={nonprofitImpactMap}
                npoListingNames={nonprofitNameMap}
                onClick={onClick}
                showNonprofitImpact={showNonprofitImpact}
              />
            ) : null;
          })
          : null}
      </div>
    </li>
  )
}

SectionFeaturedMembers.propTypes = {
  className: string,
  footerItemLimit: number,
  footerType: string,
  loadingInProgress: bool,
  listings: array,
  mobileTitle: string,
  nonprofitImpactMap: object,
  nonprofitNameMap: object,
  onClick: func,
  showNonprofitImpact: bool,
  title: string,
}

SectionFeaturedMembers.defaultProps = {
  className: null,
  footerItemLimit: 3,
  footerType: FOOTER_TYPE_DEFAULT,
  loadingInProgress: true,
  listing: [],
  mobileTitle: null,
  nonprofitImpactMap: {},
  nonprofitNameMap: {},
  onClick: () => {},
  showNonprofitImpact: false,
}

export default SectionFeaturedMembers;

