import React from 'react';
import ReactDOMServer from 'react-dom/server';
import { StaticPage, TopbarContainer } from '../../containers';
import {
  LayoutSingleColumn,
  LayoutWrapperTopbar,
  LayoutWrapperMain,
  LayoutWrapperFooter,
  Footer,
} from '../../components';
import {
  SECTION_DONATION_OFFERS,
  SECTION_NPO_DONATION,
  SECTION_GENERAL,
  SECTION_GIVSLY_CREDIT_AND_PAYMENT,
} from './constants';

import css from './FaqPage.css';
import FaqPageContent from './FaqPageContent';

export const MEETINGS_GONE_UUID = '4b5016d7-91be-40de-9150-c2cc8a0eb437';
export const MEETINGS_GONE_ID = 'cant-find-givsly-meetings';

const FaqPage = ({ location }) => {
  const frequentlyAskedQuestions = [
    {
      title: 'General Questions',
      id: SECTION_GENERAL,
      items: [
        {
          question: 'What is Givsly?',
          answer:
            'Givsly is a purpose-driven marketing and advertising solution that drives better business results through giving back to communities. By utilizing Givsly’s suite of business tools such as e.g. Givsly Donation Offers and Givsly Good Advertising marketers are able to drive business outcomes and conversions while simultaneously contributing to their social and environmental commitments. For our nonprofit partners of all sizes Givsly provides new opportunities for fundraising and corporate collaboration.',
        },
        {
          question: 'Is Givsly a nonprofit?',
          answer:
            'No, Givsly is a for profit organization. Givsly is technically categorized as a benefit corporation, defined by being committed to creating public benefit and sustainable value in addition to generating profit.',
        },
        {
          question: 'Is this ethical?',
          answer:
            'Yes. While Givsly is creating social impact it is also a lead generation and marketing platform for businesses prospecting both new and existing clients to take business meetings, register for events, download content and convert business with other types of calls to action. Companies already exist that offer services to connect business people for meetings with the profits primarily going to the company for the introduction. There are also gifting and incentivization platforms that businesses use to engage each other through physical goods. It is common for business people to spend money on lunches, concerts, and other experiences in order to get time from and connect with prospects for business. With Givsly, we want to create a more purpose led option to make these business connections and conversions, with the money going back to causes that support communities in the U.S. and globally.',
        },
        {
          question: 'Do you have to pay to get access to the Givsly platform?',
          answer: (
            <>
              Yes. Givsly provides different types of Corporate Subscription Plans to companies who
              want to to use Givsly as part of their on-going marketing and advertising activities.
              Corporate subscribers will not pay fees to Givsly for single transactions and are able
              to use enhanced marketing tools and receive discounts to Givsly experience programs.
              Corporate subscribers also are eligible for impression volume discounts in Givsly good
              advertising. Contact us at <a href="mailto:hello@givsly.com">hello@givsly.com</a> for
              more details on Corporate Subscriptions for Good Marketing and Good Advertising or
              request a demo{' '}
              <a href="https://form.typeform.com/to/yKoPyBT9" target="_blank" rel="noopener noreferrer">
                here
              </a>
              .
              <br />
              <br />
              Nonprofits do not need a paid subscription, they just need to onboard to Givsly and
              respond to collaboration queries from partners to participate in the programs. Contact
              us at <a href="mailto:nonprofits@givsly.com">nonprofits@givsly.com</a> to onboard as a
              nonprofit.
            </>
          ),
        },
        {
          question:
            'What if I do not seem to receive a verification email from Givsly upon sign-up?',
          answer: (
            <>
              When you first sign up for Givsly using your name and your work email, you are asked
              to verify your email with Givsly, so we know that this email is yours and that you
              have access to it, since it is an essential part of interacting with the Givsly
              service.
              <br />
              <br />
              If you do not see the verification email come through from Givsly (
              <a href="mailto:hello@givsly.com">hello@givsly.com</a>) you can go to the sign-up and
              ask for the verification email to be re-sent. Occasionally corporate email systems do
              not let verification emails through. If you feel this may be the case please contact
              us <a href="mailto:support@givsly.com">support@givsly.com</a> so we can help you
              troubleshoot.
            </>
          ),
        },
        {
          question: "Why can't I find my Givsly Meetings ?",
          answer:
            'Starting January 2023 we have removed the old platform-member-only Givsly meetings feature, since most of our members prefer to use the Givsly Donation offers to do outreach to their prospects. You can send a Donation Offer to anyone that you would like to connect with, not just Givsly platform members!',
          uuid: MEETINGS_GONE_UUID,
          id: MEETINGS_GONE_ID,
        },
      ],
    },
    {
      title: 'Givsly Donation Offer questions',
      id: SECTION_DONATION_OFFERS,
      items: [
        {
          question: 'What Are Donation Offers on Givsly?',
          answer: (
            <>
              Givsly’s Donation Offers allow you to reach out to prospects, partners and pretty much
              any professional you would like to connect with or whom you are asking for a call to
              action or their time & attention and to do this in a meaningful way by using the power
              of Givsly donations; even if they are not a member on Givsly.
              <br />
              <br />
              As a corporate subscriber and logged in Givsly member, you can generate a Donation
              Offer in the form of a URL which you can include in your communications in emails,
              corporate pages, chats, phone texts, social posts and so on. When creating a Donation
              Offer, you will choose the donation amount and one to three nonprofits from Givsly
              that your recipient can select from if they accept your offer.
              <br />
              <br />
              The same Donation Offer (like a campaign link) can be sent out to multiple people that
              you would like to reach. If they choose to click through, choose a NPO for your
              donation, leave their information and submit the Donation Offer response back to you,
              then you know that they are interested in connecting with you. Next steps after that
              are for you to process the donation payment via Givsly (paying with credit card or
              Givsly Credits) and to reach back to the person who accepted your Donation Offer and
              expressed interest in connecting.
              <br />
              <br />
              Givsly facilitates the payment of donations to the nonprofit organizations on its
              platform. Once you process the accepted donation and make nonprofit payment, Givsly
              will also communicate back to the person who accepted your Donation Offer with a thank
              you email and impact messaging from their selected nonprofit and Givsly.
            </>
          ),
        },
        {
          question: 'What can I utilize Donation Offers for?',
          answer: (
            <>
              While many members utilize Donation Offers when sending client outreach or meeting
              requests, you can also use them for many other types of calls to action in a business
              context. Givsly members and corporations successfully use Donation Offers for example
              to ask for product feedback, as a thank you for event registration or for event
              participation. They can also be used to thank people for visiting your booth at a
              trade show or to show client/partner/employee appreciation. Feel free to reach out to
              us at <a href="mailto:hello@givsly.com">hello@givsly.com</a> for specific case
              examples!
            </>
          ),
        },
        {
          question: 'How many Donation Offers can I create?',
          answer:
            'At this point we want you to be able to experiment with as many Donation Offers as you can and have not limited the number of links you can create.',
        },
        {
          question:
            'When creating Donation Offers, can I choose nonprofits other than those currently on my profile?',
          answer: (
            <>
              Yes, when you create a Donation Offer on Givsly, the offer generation template is
              initially populated with those nonprofits which are on your existing profile. You can
              however exchange these for up to any three nonprofits as long as they exist on Givsly.
              <br />
              <br />
              If there is a nonprofit that you are interested in supporting that is not currently
              available on the Givsly platform, please reach out to us at{' '}
              <a href="mailto:nonprofits@givsly.com">nonprofits@givsly.com</a> to request that we
              reach out to them to see if we can onboard them to Givsly
            </>
          ),
        },
        {
          question: 'What Donation Offer sums can I choose from?',
          answer:
            'You can freely choose the individual donation sum for each Donation Offer from USD 10 upwards.',
        },
        {
          question:
            'Can I see what the Donation Offer recipient can see when they click on the link?',
          answer:
            'Once you have created a Donation Offer you can view an offer details page. Here you will also find a preview button to see how the landing page looks for a Donation Offer recipient that comes through to your link.',
        },
        {
          question: 'What does the Donation Offer landing page say?',
          answer: (
            <>
              When creating a Donation Offer on Givsly you will see an automatically created default
              copy on the creation form. This default copy lists your name and company name (or
              optionally only your company name), the individual donation value you have chosen, the
              selected nonprofits you want to present for the recipient to choose from and their
              impact examples from those nonprofits.
              <br />
              <br />
              If you would like to alter the default copy you can make changes to the text paragraph
              as well as customize the top image of the Donation Offer by uploading your own image
              and logo. You can also pick images from our integration with an image bank.
              <br />
              <br />
              The resulting Donation Offer landing page is where the Donation Offer recipient is
              asked to make a selection on the nonprofit and submit their information for your
              verification. They can leave a short message to you as well. The landing page also
              explains that Givsly is the third-party partner who works with you and your company to
              get the donations to the nonprofit.
            </>
          ),
        },
        {
          question: 'Can I customize the look of my Donation Offer landing page ?',
          answer: (
            <>
              Givsly’s corporate subscriber members can additionally customize their Donation Offer
              landing pages with their specific images, logos and text. For more on our corporate
              subscriptions contact us at <a href="mailto:hello@givsly.com">hello@givsly.com</a>
            </>
          ),
        },
        {
          question: 'Can I edit a Donation Offer once I have created it?',
          answer:
            'Yes, you can edit a Donation Offer link that you have created. If you do not like to keep a Donation Offer live, you can also make the link inactive.',
        },
        {
          question: 'For Donation Offers, when do I make the donation payment to the nonprofit ?',
          answer:
            'Once a person responds to your Donation Offer, including selecting the nonprofit and leaving their name, work email and company (optional) so that you can view it on Givsly, you are then asked to confirm this specific donation and connection by making the payment. The payment is done on Givsly using your credit card or your corporate Givsly Credits or a combination of both. When you have made the actual donation we will automatically confirm this to the person who responded to your Donation Offer with a thank you -email from the nonprofit.',
        },
        {
          question:
            "What if someone responds who is not someone that I've sent the Donation Offer to?",
          answer:
            'Since Donation Offers can be sent out freely it is possible that if forwarded, someone outside of your original outreach could respond and select a donation. We are not able to verify the respondents but you do have complete control of which response you will pay the donation on. You can simply discard the acceptance and not make a donation payment, if it feels like this is not the person you were looking to connect with.',
        },
        {
          question: 'Can I control the budget that I will spend on a Donation Offer ?',
          answer: (
            <>
              To help keep track of the budget you have paid out as donations for a specific
              Donation Offer, you can optionally set a “cap” on your donation payments during the
              offer creation step.
              <br />
              <br />
              This means that you can confirm donation payments for that Donation Offer until the
              cap is reached and then get a warning to either edit the Donation Offer to increase
              the cap or close out this Donation Offer and make it inactive.
              <br />
              <br />
              After the set cap USD amount has been reached, the Donation Offer link will start
              directing the next person who clicks on the link to a separate landing page which will
              inform them that this donation campaign has reached its target and thank them for
              their interest.
            </>
          ),
        },
        {
          question: 'What happens if the Donation Offer cap that I set is reached?',
          answer:
            'After the set cap USD amount has been reached, the Donation Offer link will start directing the next person who clicks on the Donation Offer link to a separate landing page which will inform them that this donation campaign has reached its target and thank them for their interest. You can decide to edit your existing Donation Offer to make it active again or make the Donation Offer inactive.',
        },
        {
          question: 'Can I manually deactivate a Donation Offer?',
          answer:
            'If you would like to manually deactivate an offer link so that visitors to that link URL are not able to accept your Donation Offer anymore, you can do so from your dashboard under each offer link. Making a link inactive means that visitors are directed to a thank you page which states that this donation campaign has reached its target and thank them for their interest.',
        },
        {
          question: 'Can I send the same Donation Offer link to multiple people?',
          answer:
            'When a Donation Offer is created it is not currently tied to any specific recipient. It can be sent out as part of your communication to as many people as you see fit.',
        },
        {
          question: 'How will I be notified if someone responds to my Donation Offer?',
          answer:
            'Your Givsly account will have a dashboard to keep track of Donation Offers you have created and the responses that have been received.  You will also receive an email about the acceptance to the email address defined on your Givsly profile. To submit an acceptance for your donation, the recipient is asked to also give their first and last name, their work email, their company name (optional), and if they want to leave a short message for you. You can view this information on the dashboard for each accepted donation offer and complete the donation payment for the chosen nonprofit.',
        },
        {
          question:
            'How does the person know if I actually made a donation to their chosen nonprofit ?',
          answer:
            'Once you confirm a donation and make the payment, Givsly will send a thank you note informing the person responding to your Donation Offer that you have made the donation and it is in the process of being paid out to their nonprofit.',
        },
        {
          question:
            'Can Givsly help me if the person I connected with does not respond to me after my initial outreach and donation to the NPO they chose?',
          answer:
            'Since the Donation Offer links can be sent out freely, using any of your channels and accompanying such a variety of asks to the person that you are reaching out to, Givsly is unfortunately not able to follow up in a similar manner as it can when you are booking meetings between two Givsly members.',
        },
        {
          question: 'Does 100% of my payment go to the NPO donation?',
          answer:
            'The donation sum chosen by you for the Donation Offer goes to the nonprofit. Additionally there is a payment processing fee for our transaction providers (e.g. Stripe or GiftUp) added to the donation amount to make the complete payment. You can see the donation + payment processing fee total initially when creating the offer and later when processing the payment.',
        },
        {
          question: 'Can I get a refund of the donation payment for a Donation Offer',
          answer:
            'Since the Donation Offer links can be sent out freely, using any of your channels and accompanying such a variety of requests to the person that you are reaching out to, Givsly is unfortunately not able to refund Donation Offer payments once you have confirmed them.',
        },
      ],
    },
    {
      title: 'Nonprofit donation questions',
      id: SECTION_NPO_DONATION,
      items: [
        {
          question: 'Do all of the donations go to the nonprofit?',
          answer:
            'When processing Donation Offers the donation sum chosen by you for the Donation Offer goes to the nonprofit. Additionally there is a payment processing fee for our transaction providers (e.g. Stripe and GiftUp) added to the donation amount to make the complete payment amount. You can see the donation + payment processing fee total initially when creating the Donation Offer and later when processing the payment.',
        },
        {
          question: 'Is the donation a tax write off?',
          answer:
            'No. The donation made to a nonprofit on the platform is not a tax write off because there is value in the business conversion such as for example an event sign-up, contact info or meeting in exchange for the donation.',
        },
        {
          question: 'Who sets the donation amount for a Donation Offer ?',
          answer:
            'The Givsly member who creates the offer determines a donation amount for each accepted Donation Offer. Minimum offer presently is 10 US Dollars.',
        },
        {
          question: 'When does the donation go to the nonprofit?',
          answer:
            'With Givsly Donation Offers the person creating an offer and receiving acceptances to that offer is responsible for processing the payments to complete the donation. From each of these interactions, when marked complete, the donations are typically sent to the nonprofit within 90 days of payment.',
        },
      ],
    },
    {
      title: 'Payment and Givsly Credits questions',
      id: SECTION_GIVSLY_CREDIT_AND_PAYMENT,
      items: [
        {
          question: 'How can I pay for Givsly Donation Offers?',
          answer: (
            <>
              When making a Donation Offer payment on Givsly you can pay using major credit cards.
              We support major credit cards from our customer’s banks in and outside the U.S. for
              the following brands: Visa, Mastercard, Diners and American Express - as supported by
              our payment processor Stripe. Please note that when you are making payments from
              certain countries your bank may require a 3D Secure authentication and verification
              process as part of your payment.
              <br />
              <br />
              All Givsly donations and fees on our service are presented in US Dollars. Please note
              that if your credit card is under a different currency or from a country outside of
              the U.S. then your credit card company and bank may additionally charge you a foreign
              exchange fee or a foreign charge fee. Givsly is not able to determine or influence if
              these fees are charged by your credit card company and what they are. They will be
              applied on top of the donations and fees you pay to Givsly by your credit card company
              or bank.
              <br />
              <br />
              If your company has acquired pre-purchased Givsly Credits, you can apply these as
              payment methods for the Donation Offers. You can also pay partly by Givsly Credits and
              partly by a credit card. In this case any available Givsly Credit balance in your
              profile will be applied to your donation first and the remaining balance can then be
              paid by card. This is intended for when your Givsly Credit balance does not cover the
              whole payment.
            </>
          ),
        },
        {
          question: 'What are Givsly Credits?',
          answer: (
            <>
              Givsly Credits are prepaid credits that let you pay for the donations you make through
              Givsly platform instead of using your credit card. Givsly Credits are currently sold
              to our corporate customers and you can inquire about purchasing Givsly Credits or
              becoming a corporate customer by reaching out to our service team at{' '}
              <a href={'mailto:hello@givsly.com'}>hello@givsly.com</a>.
            </>
          ),
        },
        {
          question: 'How do Givsly Credits work?',
          answer: (
            <>
              Givsly Credits are distributed in the form of credit codes which you can connect to
              your own Givsly profile on the service and have the value associated with the credit
              code be available to you as a method of payment for a donation.
              <br />
              <br />
              You can have multiple Givsly Credit codes applied to your Givsly profile. Credits will
              be automatically used based on the date of their expiration, so that the one with the
              closest expiration date will be used first. Givsly Credits are valid for 12 months
              from the date of their issuance to your company unless otherwise agreed by your
              company and Givsly.
            </>
          ),
        },
        {
          question: 'Where do I find information on my Givsly Credit balance?',
          answer: (
            <>
              You can find your current Givsly credit balance under{' '}
              <a href={'/account/payment-methods'}>Payment Methods</a> in the Account Settings tab
              of your Givsly membership profile.
              <br />
              <br />
              If you have applied any credit codes to your profile, you can see a list of the
              corresponding Givsly Credit codes, their expiration date and the current available
              balance for each code. Givsly Credits are valid for 12 months from the date they are
              issued to your company.
              <br />
              <br />
              If you have yet to apply Givsly Credit or want to apply additional credit value to
              your profile you can do it in Payment Methods, by clicking “Add Givsly Credits to your
              account” and inserting the Givsly prepaid credit code you have received. You can also
              apply Givsly Credit at the payment step, when making a booking.
            </>
          ),
        },
        {
          question: 'Can my colleague share the same Givsly Credit code with me?',
          answer: (
            <>
              No, because one credit code, after first applied to a Givsly profile on the platform,
              is then associated with that Givsly profile and cannot be applied to another profile
              simultaneously or used again.
              <br />
              <br />
              If you are a corporate customer’s Givsly Credit administrator and need help on this
              issue contact your account manager from Givsly or reach out to us at{' '}
              <a href="mailto:support@givsly.com">support@givsly.com</a>
            </>
          ),
        },
        {
          question: 'What if I have lost my codes or need more Givsly Credit?',
          answer: (
            <>
              If you have lost your Givsly Credit codes before applying them to your Givsly profile,
              please contact your company’s Givsly representative or the colleague who initially
              provided you your Givsly Credit codes. Givsly service team is not authorized to
              distribute Givsly Credit codes outside of the named company representative.
              <br />
              <br />
              Givsly Credit is currently sold to our company customers and cannot (yet) be purchased
              directly on-line - please contact{' '}
              <a href={'mailto:hello@givsly.com'}>hello@givsly.com</a> for more information on how
              your company can acquire Givsly credit for your employees!
            </>
          ),
        },
        {
          question: 'What is the Givsly fee?',
          answer:
            'The Givsly subscription fees enable access to Givsly platform, support its technology development and maintenance, safe fund transfers to NPOs and the value of qualified lead generation and other business connections or conversions made through using Givsly tools.',
        },
        {
          question: 'Can I get a refund?',
          answer:
            'Givsly Donation Offer payments are processed by the sender after the Donation Offer has been accepted by the recipient. There are no refunds for processed Givsly Donation Offers.',
        },
      ],
    },
  ];

  const topText = (
    <>
      Got any questions or concerns? We’re here to help you. If you have additional questions not
      answered in this FAQ sheet please reach out to the Givsly team at{' '}
      <a href="mailto:hello@givsly.com">hello@givsly.com</a>.
    </>
  );

  const schemaMainEntity = frequentlyAskedQuestions.reduce(
    (questionEntities, section) => [
      ...questionEntities,
      ...section.items.map((item) => ({
        '@type': 'Question',
        name: ReactDOMServer.renderToString(item.question),
        acceptedAnswer: {
          '@type': 'Answer',
          text: ReactDOMServer.renderToString(item.answer),
        },
      })),
    ],
    []
  );

  return (
    <StaticPage
      title="FAQ for Givsly Good Business Solutions"
      description={
        'Read more to know how you can support nonprofit organizations by volunteering and booking meetings on the Givsly social impact service.'
      }
      schema={{
        '@context': 'http://schema.org',
        '@type': 'FaqPage',
        description:
          'Read more to know how you can support nonprofit organizations by volunteering and booking meetings on the Givsly social impact service.',
        name: 'FAQ for Givsly Good Business Solutions',
        mainEntity: schemaMainEntity,
      }}
    >
      <LayoutSingleColumn>
        <LayoutWrapperTopbar>
          <TopbarContainer alwaysAccessible={true} currentPage="FaqPage" />
        </LayoutWrapperTopbar>

        <LayoutWrapperMain className={css.staticPageWrapper}>
          <FaqPageContent
            title="FAQ"
            text={topText}
            questions={frequentlyAskedQuestions}
            hash={location.hash}
          />
        </LayoutWrapperMain>

        <LayoutWrapperFooter>
          <Footer />
        </LayoutWrapperFooter>
      </LayoutSingleColumn>
    </StaticPage>
  );
};

export default FaqPage;
