import React from 'react';
import { func, string, element, object, bool } from 'prop-types';
import { propTypes } from '../../util/types';
import { isUploadImageOverLimitError } from '../../util/errors';
import css from './PersonalInfoForm.css';
import { FormattedMessage } from '../../util/reactIntl';

export const FieldFileUpload = (props) => {
  const { accept, id, input, onImageUpload, disabled, uploadImageError, label } = props;
  const { onChange, onBlur } = input;

  const onChangeFunc = (e) => {
    const file = e.target.files[0];
    onChange(file);
    onBlur();
    if (file != null) {
      const tempId = `${file.name}_${Date.now()}`;
      onImageUpload({ id: tempId, file });
    }
  };

  let error = null;

  if (isUploadImageOverLimitError(uploadImageError)) {
    error = (
      <div className={css.error}>
        <FormattedMessage id="PersonalInfoForm.imageUploadFailedFileTooLarge" />
      </div>
    );
  } else if (uploadImageError) {
    error = (
      <div className={css.error}>
        <FormattedMessage id="PersonalInfoForm.imageUploadFailed" />
      </div>
    );
  }

  return (
    <div className={css.uploadAvatarWrapper}>
      <label className={css.label} htmlFor={id}>
        {label}
      </label>
      <input
        accept={accept}
        id={id}
        className={css.uploadAvatarInput}
        disabled={disabled}
        onChange={onChangeFunc}
        type="file"
      />
      {error}
    </div>
  );
};

FieldFileUpload.propTypes = {
  accept: string,
  id: string.isRequired,
  input: object.isRequired,
  onImageUpload: func.isRequired,
  label: element.isRequired,
  disabled: bool,
  uploadImageError: propTypes.error,
};

FieldFileUpload.defaultProps = {
  accept: 'image/*',
};
