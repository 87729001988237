import React from 'react';
import { LISTING_STATE_PUBLISHED, propTypes } from '../../util/types';
import { array, arrayOf, func, shape, string } from 'prop-types';
import { AvailabilityPanel, IconSpinner, PanelHeader } from '../index';
import {
  ensureCurrentUser,
  ensureOwnListing,
  isBookableListing,
  PROFILE_TYPE_VOLUNTEER,
} from '../../util/data';
import { FormattedMessage } from 'react-intl';
import css from './MyAvailabilityWizard.css';
import { PUBLISH } from '../OnboardingWizard/constants';
import { intlShape } from '../../util/reactIntl';
import merge from 'lodash/merge';

export const AVAILABILITY = 'availability';

class MyAvailabilityWizard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      activeTab: AVAILABILITY,
    };
  }

  getTranslation = (key, values = {}) => {
    return this.props.intl.formatMessage({ id: `MyAvailabilityWizard.${key}` }, values);
  };

  render() {
    const {
      availabilityExceptions,
      bookings,
      currentUser,
      currentUserListing,
      eventsOpenVolunteering,
      history,
      onCreateAvailabilityException,
      onDeleteAvailabilityException,
      onFetchAvailabilityExceptions,
      onFetchTimeSlots,
      onLogActivity,
      onManageDisableScrolling,
      onPublishListing,
      onUpdateListing,
      onUpdateOrCreateNotifications,
      onUpdateProfile,
      params,
      trackEvent,
    } = this.props;

    const user = ensureCurrentUser(currentUser);
    const listing = ensureOwnListing(currentUserListing);
    const { timezone } = user.attributes.profile.publicData;

    /**
     * Handles submission of tabbed forms. Generally works for all profile and listing tabs. Pushes
     * data (listing and user) back to the ShareTribe API, keeps track of onboarding progress and
     * redirects to the next step.
     *
     * @param action
     * @param updatedValues
     */
    const handleSubmit = (action, updatedValues) => {
      let profileData = {
        publicData: {
          isVolunteer: true,
          profileType: PROFILE_TYPE_VOLUNTEER,
        },
      };
      let listingData = {
        ...updatedValues,
        id: listing.id.uuid,
      };
      listingData.publicData = { ...updatedValues.publicData }; // Decouple
      listingData.publicData.isVolunteerListing = true;
      listingData.publicData.listingType = PROFILE_TYPE_VOLUNTEER;

      listing.attributes = merge(listing.attributes, listingData);
      listingData.publicData.isBookable = isBookableListing(listing);

      const promises = [onUpdateProfile(profileData), onUpdateListing(listingData)];

      if (action === PUBLISH && listing.attributes.state !== LISTING_STATE_PUBLISHED) {
        promises.push(onPublishListing(listing.id.uuid));
      }

      return Promise.all([...promises]);
    };

    return (
      <div>
        <PanelHeader header={<FormattedMessage id="MyAvailabilityWizard.availabilityHeading" />} />
        {currentUserListing.id ? (
          <AvailabilityPanel
            availabilityExceptions={availabilityExceptions}
            bookings={bookings}
            currentUser={currentUser}
            currentUserListing={currentUserListing}
            eventKey={params.eventKey}
            eventsOpenVolunteering={eventsOpenVolunteering}
            handleSubmit={handleSubmit}
            history={history}
            onCreateAvailabilityException={onCreateAvailabilityException}
            onDeleteAvailabilityException={onDeleteAvailabilityException}
            onFetchAvailabilityExceptions={onFetchAvailabilityExceptions}
            onFetchTimeSlots={onFetchTimeSlots}
            onLogActivity={onLogActivity}
            onManageDisableScrolling={onManageDisableScrolling}
            onUpdateListing={onUpdateListing}
            onUpdateOrCreateNotifications={onUpdateOrCreateNotifications}
            timezone={timezone || 'America/New_York'}
            trackEvent={trackEvent}
          />
        ) : (
          <div className={css.isLoading}>
            <IconSpinner />
          </div>
        )}
      </div>
    );
  }
}

MyAvailabilityWizard.propTypes = {
  availabilityExceptions: array,
  bookings: arrayOf(propTypes.booking),
  currentUser: propTypes.currentUser.isRequired,
  currentUserListing: propTypes.ownListing,
  eventsOpenVolunteering: arrayOf(propTypes.event),
  history: shape({
    push: func.isRequired,
  }).isRequired,
  intl: intlShape.isRequired,
  onCreateAvailabilityException: func.isRequired,
  onDeleteAvailabilityException: func.isRequired,
  onFetchAvailabilityExceptions: func.isRequired,
  onFetchTimeSlots: func.isRequired,
  onLogActivity: func.isRequired,
  onManageDisableScrolling: func.isRequired,
  onPublishListing: func.isRequired,
  onUpdateListing: func.isRequired,
  onUpdateOrCreateNotifications: func.isRequired,
  onUpdateProfile: func.isRequired,
  params: shape({
    tab: string.isRequired,
    eventKey: string,
  }).isRequired,
  trackEvent: func,
};

MyAvailabilityWizard.defaultProps = {
  availabilityExceptions: [],
  currentUserListing: { id: null },
};

export default MyAvailabilityWizard;
