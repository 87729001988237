import React, { Component } from 'react';
import { array, func, shape, string } from 'prop-types';
import { momentObj } from 'react-moment-proptypes';
import { filterWithinRange } from '../../util/timeSlots';
import css from './AvailabilityFormMonthlySlots.css';
import { FormattedMessage, intlShape } from '../../util/reactIntl';
import { Button, CalendarDownloadOptions } from '../../components';
import moment from 'moment';
import classNames from 'classnames';
import { RECURS_NEVER } from '../../util/dates';
import { propTypes } from '../../util/types';
import { ensureCurrentUser } from '../../util/data';
import { getEvent, getEventBookingOpenMoment, isEventBookingOpen } from '../../util/events';

const ENABLE_SINGLE_DOWNLOADS = false;

class AvailabilityFormMonthlySlots extends Component {
  get monthlySlots() {
    const nextMonth = this.props.selectedMonth.clone().add(1, 'month');

    return filterWithinRange(
      this.props.reservableSlotsWithBookings,
      this.props.selectedMonth,
      nextMonth
    );
  }

  get ensuredCurrentUser() {
    return ensureCurrentUser(this.props.currentUser);
  }

  get calendarDownloadOptions() {
    if (this.monthlySlots.length === 0) return null;

    return (
      <CalendarDownloadOptions
        contentClassName={css.calendarDownloadOptions}
        ensuredCurrentUser={this.ensuredCurrentUser}
        history={this.props.history}
        intl={this.props.intl}
        name="download-all"
        onManageDisableScrolling={this.props.onManageDisableScrolling}
      >
        <div
          className={css.listHeaderButton}
          onClick={() => {
            this.setState({ activeDownloadOptions: 'download-all' });
          }}
        >
          <FormattedMessage id="AvailabilityForm.saveAllTimeSlots" />
        </div>
      </CalendarDownloadOptions>
    );
  }

  getCalendarDownloadOptionsSingle(availability) {
    if (!ENABLE_SINGLE_DOWNLOADS) return null;

    return (
      <CalendarDownloadOptions
        ensuredCurrentUser={this.ensuredCurrentUser}
        intl={this.props.intl}
        name={availability.id}
        onManageDisableScrolling={this.props.onManageDisableScrolling}
      >
        <div
          className={classNames(css.button, css.buttonDownload)}
          onClick={() => {
            this.setState({ activeDownloadOptions: availability.id });
          }}
        />
      </CalendarDownloadOptions>
    );
  }

  getMeetingTypeLabel(methods) {
    let labelId = '';
    if (methods.length === 1) {
      labelId = `AvailabilityForm.${methods[0]}`;
    } else if (methods.length === 2) {
      labelId = 'AvailabilityForm.doubleOption';
    } else if (methods.length === 3) {
      labelId = 'AvailabilityForm.tripleOption';
    }

    return this.props.intl.formatMessage(
      {
        id: labelId,
      },
      Object.assign(
        {},
        methods.map((method) => this.props.intl.formatMessage({ id: `AvailabilityForm.${method}` }))
      )
    );
  }

  getDurationText(duration, methods) {
    return `${duration} ${this.props.intl.formatMessage({
      id: 'AvailabilityForm.minutes',
    })} ${this.getMeetingTypeLabel(methods)}`;
  }

  getStatusElement(availability) {
    const { timezone } = this.props;
    const bookingStatus = availability.booked ? 'booked' : 'available';
    const status = availability.pendingToOpen ? 'pendingToOpen' : bookingStatus;
    const statusLabel = {
      override: false,
      label: '',
    };

    let availableInFuture = false;
    let availableFrom = moment().tz(timezone);
    if (availability.event) {
      const event = getEvent(availability.event);

      if (event.availability.statusLabel) {
        statusLabel.override = true;
        statusLabel.label = event.availability.statusLabel;
      }

      availableInFuture = !isEventBookingOpen(event, timezone);
      availableFrom = getEventBookingOpenMoment(event).tz(timezone);
    }

    return (
      <span
        className={classNames(css.status, {
          [css.statusBooked]: !!availability.booked,
          [css.statusPending]: !!availability.pendingToOpen,
          [css.event]: !!availability.event,
        })}
      >
        {statusLabel.override ? (
          statusLabel.label
        ) : availableInFuture ? (
          <FormattedMessage
            id={`AvailabilityForm.availableFrom`}
            values={{
              date: availableFrom.format('MMM D, YYYY'),
            }}
          />
        ) : (
          <FormattedMessage id={`AvailabilityForm.${status}`} />
        )}
      </span>
    );
  }

  getEventName(availability) {
    if (!availability.event) return null;

    const event = getEvent(availability.event);
    return event ? event.title : null;
  }

  getRenderedMonthlySlot(availability) {
    const now = moment();
    const startDate = moment.tz(availability.start, availability.timezone).tz(this.props.timezone);
    const endDate = moment.tz(availability.end, availability.timezone).tz(this.props.timezone);
    const duration = moment.duration(endDate.diff(startDate)).asMinutes();
    const isLocked = startDate.isSameOrBefore(now);

    return (
      <div key={availability.id} className={css.listItem}>
        <div
          className={classNames(css.dateTimeInfo, {
            [css.recurrenceRegular]: !availability.event,
            [css.recurring]: availability.recurrence !== RECURS_NEVER,
            [css.recurrenceEvent]: !!availability.event,
            [css.booked]: availability.booked,
          })}
        >
          <span className={css.date}>{startDate.format('dddd, D MMMM')}</span>
          <span className={css.time}>{startDate.format('hh:mm a (z)')}</span>
        </div>

        <div className={css.listItemTypeInfo}>
          {availability.event && (
            <span className={css.eventName}>{this.getEventName(availability)}</span>
          )}
          <span className={css.what}>{this.getDurationText(duration, availability.methods)}</span>
          {this.getStatusElement(availability)}
        </div>

        <div className={css.listItemControls}>
          {this.getCalendarDownloadOptionsSingle(availability)}
          {!availability.booked && (
            <>
              <Button
                className={classNames(css.button, css.buttonEdit, css.listItemControlButton)}
                disabled={isLocked}
                onClick={this.props.handleEditTimeSlot}
                type="button"
                value={availability.id}
              />
              <Button
                className={classNames(css.button, css.buttonDelete, css.listItemControlButton)}
                disabled={isLocked}
                onClick={() => this.props.handleConfirmDeleteTimeSlot(availability)}
                type="button"
                value={availability.id}
              />
            </>
          )}
        </div>
      </div>
    );
  }

  get renderedMonthlySlots() {
    if (this.monthlySlots.length === 0) {
      return (
        <p className={css.noSlots}>
          <FormattedMessage id="AvailabilityForm.noSlotsDuringThisPeriod" />
        </p>
      );
    }

    return this.monthlySlots.map((availability) => this.getRenderedMonthlySlot(availability));
  }

  render() {
    return (
      <div className={css.list}>
        <div className={css.listHeader}>
          <h2 className={css.listHeaderText}>
            <FormattedMessage
              id="AvailabilityForm.addedTimeSlotsForm"
              values={{ month: this.props.selectedMonth.format('MMMM YYYY') }}
            />
          </h2>
          {this.calendarDownloadOptions}
        </div>

        <div>{this.renderedMonthlySlots}</div>
      </div>
    );
  }
}

AvailabilityFormMonthlySlots.propTypes = {
  currentUser: propTypes.currentUser.isRequired,
  handleConfirmDeleteTimeSlot: func.isRequired,
  handleEditTimeSlot: func.isRequired,
  history: shape({
    push: func.isRequired,
  }).isRequired,
  intl: intlShape.isRequired,
  onManageDisableScrolling: func.isRequired,
  selectedMonth: momentObj.isRequired,
  reservableSlotsWithBookings: array,
  timezone: string.isRequired,
};

AvailabilityFormMonthlySlots.defaultProps = {
  reservableSlotsWithBookings: [],
};

export default AvailabilityFormMonthlySlots;
