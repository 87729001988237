import React from 'react';
import { func, object, shape, string } from 'prop-types';
import classNames from 'classnames';

import css from './SectionCards.css';

export const CARD_MODE_CTA = 'cta';
export const CARD_MODE_PROFILE = 'profile';

const Card = (props) => {
  const cardClasses = classNames(
    css.card,
    props.className,
    props.link ? css.linked : null,
    props.cardMode === CARD_MODE_CTA ? css.cta : null,
    {
      [css.extraBottomPadding]: !!props.footer,
    }
  );

  const openLink = (link) => {
    props.history.push(link);
  };

  return (
    <div className={cardClasses} onClick={() => (props.link ? openLink(props.link) : null)}>
      {props.image ? (
        <div className={css.imageWrapper}>
          <img className={css.image} src={props.image} alt="" />
        </div>
      ) : null}

      <div className={css.cardTitle}>{props.title}</div>
      {props.subTitle ? <div className={css.cardSubTitle}>{props.subTitle}</div> : null}
      <p className={css.text}>{props.text}</p>
      {props.footer ? <div className={css.footerText}>{props.footer}</div> : null}
    </div>
  );
};

const SectionCards = (props) => {
  const { cards, cardMode, className, name, rootClassName, title } = props;

  const classes = classNames(
    rootClassName || css.root,
    className,
    props.cardMode === CARD_MODE_CTA ? css.cta : null
  );
  return (
    <div className={classes}>
      <div className={css.title}>{title}</div>

      <div className={css.cards}>
        {cards.map((card, index) => (
          <Card {...card} cardMode={cardMode} key={`sc-${name}-${index}`} history={props.history} />
        ))}
      </div>
    </div>
  );
};

SectionCards.defaultProps = {
  cardMode: CARD_MODE_PROFILE,
  css: css,
  className: null,
  history: shape({
    push: func.isRequired,
  }).isRequired,
  name: 'noname',
  rootClassName: null,
};

SectionCards.propTypes = {
  cardMode: string,
  className: string,
  css: object,
  name: string,
  rootClassName: string,
  title: string.isRequired,
};

export default SectionCards;
