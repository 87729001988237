import React from 'react';
import { bool, func } from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { isScrollingDisabled } from '../../ducks/UI.duck';
import { TopbarContainer } from '../../containers';
import {
  Page,
  LayoutSideNavigation,
  LayoutWrapperMain,
  LayoutWrapperSideNav,
  LayoutWrapperTopbar,
  LayoutWrapperFooter,
  Footer,
} from '../../components';
import css from './PlatformPrivacyPolicyPage.css';
import { withMessages } from '../../util/localization';
import PlatformPrivacyPolicy from '../../components/PlatformPrivacyPolicy/PlatformPrivacyPolicy';
import PlatformPrivacyPolicyUpdate from '../../components/PlatformPrivacyPolicy/PlatformPrivacyPolicyUpdate';

class PlatformPrivacyPolicyPageComponent extends React.Component {
  get schema() {
    return {
      '@context': 'http://schema.org',
      '@type': 'WebPage',
      name: this.schemaTitle,
      description: this.schemaDescription,
    };
  }

  get schemaDescription() {
    return this.props.getMessage('schemaDescription');
  }

  get schemaTitle() {
    return this.props.getMessage('schemaTitle');
  }

  get tabs() {
    const { getMessage } = this.props;
    return [
      {
        text: getMessage('privacyTabTitle'),
        selected: false,
        linkProps: {
          name: 'WebPrivacyPolicyPage',
        },
      },
      {
        text: getMessage('platformPrivacyTabTitle'),
        selected: true,
        linkProps: {
          name: 'PlatformPrivacyPolicyPage',
        },
      },
      {
        text: getMessage('tosTabTitle'),
        selected: false,
        linkProps: {
          name: 'TermsOfServicePage',
        },
      },
      {
        // link doesn't work when running dev -script. Start command runs both server and client.
        text: 'ToS Media Addendum',
        selected: false,
        type: 'anchor',
        linkProps: {
          href: '/terms-of-service/media_addendum',
          target: '_blank',
        },
      },
      {
        text: getMessage('tosNPOTabTitle'),
        selected: false,
        linkProps: {
          name: 'TermsOfServiceNPOPage',
        },
      },
    ];
  }

  render() {
    const { getMessage, updates, scrollingDisabled } = this.props;
    return (
      <Page
        description={this.schemaDescription}
        title={this.schemaTitle}
        scrollingDisabled={scrollingDisabled}
        schema={this.schema}
      >
        <LayoutSideNavigation>
          <LayoutWrapperTopbar>
            <TopbarContainer currentPage="PrivacyPolicyPlatformPage" alwaysAccessible={true} />
          </LayoutWrapperTopbar>
          <LayoutWrapperSideNav tabs={this.tabs} />

          <LayoutWrapperMain>
            <div className={css.content}>
              {updates ? 
                 <PlatformPrivacyPolicyUpdate /> : 
                 <PlatformPrivacyPolicy /> 
              }
            </div>
          </LayoutWrapperMain>
   
          <LayoutWrapperFooter>
            <Footer />
          </LayoutWrapperFooter>
        </LayoutSideNavigation>
      </Page>
    );
  }
}

PlatformPrivacyPolicyPageComponent.defaultProps = {
  updates: false,
};

PlatformPrivacyPolicyPageComponent.propTypes = {
  getMessage: func.isRequired,
  scrollingDisabled: bool.isRequired,
  updates: bool
};

const mapStateToProps = (state) => {
  return {
    scrollingDisabled: isScrollingDisabled(state),
  };
};

// Wrapper for withMessages in order to use it with compose
const withLocalizedMessages = (component) => {
  return withMessages(component, 'PlatformPrivacyPolicyPage');
};

const PlatoformPrivacyPolicyPage = compose(
  connect(mapStateToProps),
  withLocalizedMessages
)(PlatformPrivacyPolicyPageComponent);

export default PlatoformPrivacyPolicyPage;
