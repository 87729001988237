import React, { Component } from 'react';
import { injectIntl } from 'react-intl';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { compose } from 'redux';
import sharetribe from '@givsly/sharetribe-utils';
import LayoutSideNavigation from '../../components/LayoutSideNavigation/LayoutSideNavigation';
import {
  Footer,
  IconSpinner,
  LayoutWrapperFooter,
  LayoutWrapperMain,
  LayoutWrapperNonprofitOnboardingSideNav,
  LayoutWrapperTopbar,
  Page,
} from '../../components';
import { TopbarContainer } from '../index';
import css from './NonprofitOnboardingPage.css';
import NonprofitOnboardingWizard from '../../components/NonprofitOnboardingWizard/NonprofitOnboardingWizard';
import { PAYOUT_DETAILS } from '../../components/MyNonprofitWizard/constants';
import { updateProfile, uploadImage } from '../../ducks/UserProfile.duck';
import {
  requestPublishListingDraft,
  requestShowListing,
  requestUpdateListing,
} from '../../ducks/UserListing.duck';
import { isScrollingDisabled } from '../../ducks/UI.duck';
import { fetchCurrentUser } from '../../ducks/user.duck';
import {
  requestImageUpload,
  removeListingImage,
  updateImageOrder,
} from '../../ducks/UserListing.duck';
import { types as sdkTypes } from '../../util/sdkLoader';
import { getMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import { createResourceLocatorString } from '../../util/routes';
import routeConfiguration from '../../routeConfiguration';
import {
  fetchStripeAccount,
  getStripeConnectAccountLink,
  stripeAccountClearError,
} from '../../ducks/stripeConnectAccount.duck';
import { setInitialValues } from '../../ducks/StripePayout.duck';
import { propTypes } from '../../util/types';
import { bool, func, oneOf, shape, string } from 'prop-types';
import { savePayoutDetails } from '../StripePayoutPage/StripePayoutPage.duck';
import { uploadOfferImage } from '../../ducks/outreach.duck';

const { UUID } = sdkTypes;
const { STRIPE_ONBOARDING_RETURN_URL_TYPES } = require('./constants');

class NonprofitOnboardingPageComponent extends Component {
  render() {
    const {
      createStripeAccountError,
      currentUser,
      getAccountLinkInProgress,
      fetchStripeAccountError,
      getOwnListing,
      history,
      intl,
      onGetStripeConnectAccountLink,
      onListingImageUpload,
      onPayoutDetailsFormChange,
      onPayoutDetailsFormSubmit,
      onProfileImageUpload,
      onUpdateImageOrder,
      onUpdateProfile,
      onUpdateListing,
      onPublishListing,
      onRemoveListingImage,
      params,
      payoutDetailsSaveInProgress,
      payoutDetailsSaved,
      profileImage,
      scrollingDisabled,
      showListingInProgress,
      stripeAccountFetched,
      stripeAccount,
      uploadProfileImageError,
      uploadProfileImageInProgress,
      updateProfileInProgress,
      updateProfileError,
      updateStripeAccountError,
      userListing,
      onUploadS3Image,
    } = this.props;

    const user = sharetribe.user.ensureCurrentUser(currentUser);
    const listing = sharetribe.listing.ensureOwnNonprofitListing(
      getOwnListing(new UUID(user.attributes.profile.publicData.listingId))
    );

    // Require the user to verify their e-mail first
    if (user.id && user.attributes.emailVerified === false) {
      history.push(createResourceLocatorString('LoginPage', routeConfiguration(), {}, {}));
    }

    // Images are passed to EditListingForm so that it can generate thumbnails out of them
    const currentListingImages = listing.images || [];

    // Images not yet connected to the listing
    const imageOrder = userListing.imageOrder || [];
    const unattachedImages = imageOrder.map((i) => userListing.images[i]);

    const allImages = currentListingImages.concat(unattachedImages);
    const removedImageIds = userListing.removedImageIds || [];
    const images = allImages.filter((img) => {
      return !removedImageIds.includes(img.id);
    });

    const nonprofitOnboardingWizard =
      user.id && !showListingInProgress && listing.id ? (
        <NonprofitOnboardingWizard
          createStripeAccountError={createStripeAccountError}
          currentUser={user}
          currentUserListing={listing}
          fetchStripeAccountError={fetchStripeAccountError}
          getAccountLinkInProgress={getAccountLinkInProgress}
          history={history}
          images={images}
          intl={intl}
          onGetStripeConnectAccountLink={onGetStripeConnectAccountLink}
          onListingImageUpload={onListingImageUpload}
          onPayoutDetailsFormChange={onPayoutDetailsFormChange}
          onPayoutDetailsFormSubmit={onPayoutDetailsFormSubmit}
          onProfileImageUpload={onProfileImageUpload}
          onPublishListing={onPublishListing}
          onRemoveListingImage={onRemoveListingImage}
          onUpdateImageOrder={onUpdateImageOrder}
          onUpdateListing={onUpdateListing}
          onUpdateProfile={onUpdateProfile}
          params={params}
          payoutDetailsSaved={payoutDetailsSaved}
          payoutDetailsSaveInProgress={payoutDetailsSaveInProgress}
          profileImage={profileImage}
          stripeAccount={stripeAccount}
          stripeAccountFetched={stripeAccountFetched}
          uploadProfileImageError={uploadProfileImageError}
          uploadProfileImageInProgress={uploadProfileImageInProgress}
          updateProfileInProgress={updateProfileInProgress}
          updateProfileError={updateProfileError}
          updateStripeAccountError={updateStripeAccountError}
          onUploadS3Image={onUploadS3Image}
        />
      ) : (
        <div className={css.isLoading}>
          <IconSpinner />
        </div>
      );

    return (
      <Page
        className={css.root}
        title={intl.formatMessage({ id: 'OnboardingPage.title' })}
        scrollingDisabled={scrollingDisabled}
      >
        <LayoutSideNavigation>
          <LayoutWrapperTopbar>
            <TopbarContainer currentPage="OnboardingPage" />
          </LayoutWrapperTopbar>
          <LayoutWrapperNonprofitOnboardingSideNav
            activeTab={params.tab}
            currentUser={currentUser}
          />
          <LayoutWrapperMain>
            <div className={css.content}>{nonprofitOnboardingWizard}</div>
          </LayoutWrapperMain>
          <LayoutWrapperFooter>
            <Footer />
          </LayoutWrapperFooter>
        </LayoutSideNavigation>
      </Page>
    );
  }
}

NonprofitOnboardingPageComponent.propTypes = {
  createStripeAccountError: propTypes.error,
  currentUser: propTypes.currentUser,
  fetchStripeAccountError: propTypes.error,
  getAccountLinkInProgress: bool.isRequired,
  onGetStripeConnectAccountLink: func.isRequired,
  onPayoutDetailsFormChange: func.isRequired,
  onPayoutDetailsFormSubmit: func.isRequired,
  params: shape({
    returnURLType: oneOf(STRIPE_ONBOARDING_RETURN_URL_TYPES),
    tab: string.isRequired,
  }),
  payoutDetailsSaved: bool.isRequired,
  payoutDetailsSaveInProgress: bool.isRequired,
  scrollingDisabled: bool.isRequired,
  stripeAccount: propTypes.stripeAccount,
  updateStripeAccountError: propTypes.error,
};

NonprofitOnboardingPageComponent.defaultProps = {
  createStripeAccountError: null,
  fetchStripeAccountError: null,
  params: {
    returnURLType: null,
  },
  stripeAccount: null,
  updateStripeAccountError: null,
};

const mapStateToProps = (state) => {
  const { currentUser } = state.user;

  const {
    image,
    uploadImageError,
    uploadInProgress,
    updateInProgress,
    updateProfileError,
  } = state.UserProfile;

  const {
    getAccountLinkInProgress,
    createStripeAccountError,
    updateStripeAccountError,
    fetchStripeAccountError,
    stripeAccount,
    stripeAccountFetched,
  } = state.stripeConnectAccount;
  const { payoutDetailsSaveInProgress, payoutDetailsSaved } = state.StripePayout;

  const { showListingInProgress } = state.UserListing;

  const getOwnListing = (id) => {
    const listings = getMarketplaceEntities(state, [{ id, type: 'ownListing' }]);
    return listings.length === 1 ? listings[0] : null;
  };

  return {
    createStripeAccountError,
    currentUser,
    fetchStripeAccountError,
    getAccountLinkInProgress,
    getOwnListing,
    payoutDetailsSaved,
    payoutDetailsSaveInProgress,
    profileImage: image,
    scrollingDisabled: isScrollingDisabled(state),
    showListingInProgress,
    stripeAccount,
    stripeAccountFetched,
    uploadProfileImageError: uploadImageError,
    uploadProfileImageInProgress: uploadInProgress,
    updateProfileInProgress: updateInProgress,
    updateProfileError,
    updateStripeAccountError,
    userListing: state.UserListing,
  };
};

const mapDispatchToProps = (dispatch) => ({
  onGetStripeConnectAccountLink: (params) => dispatch(getStripeConnectAccountLink(params)),
  onPayoutDetailsFormChange: () => dispatch(stripeAccountClearError()),
  onPayoutDetailsFormSubmit: (values, isUpdateCall) =>
    dispatch(savePayoutDetails(values, isUpdateCall)),
  onProfileImageUpload: (data) => dispatch(uploadImage(data)),
  onListingImageUpload: (data) => dispatch(requestImageUpload(data)),
  onUpdateProfile: (data) => dispatch(updateProfile(data)),
  onUpdateListing: (data) => dispatch(requestUpdateListing(data)),
  onPublishListing: (listingId) => dispatch(requestPublishListingDraft(listingId)),
  onUpdateImageOrder: (imageOrder) => dispatch(updateImageOrder(imageOrder)),
  onRemoveListingImage: (imageId) => dispatch(removeListingImage(imageId)),
  onUploadS3Image: (imageData) => dispatch(uploadOfferImage(imageData)),
});

const NonprofitOnboardingPage = compose(
  injectIntl,
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(NonprofitOnboardingPageComponent);

/**
 * Load data on page load
 *
 * @param params
 * @returns {function(*=, *, *): *}
 */
NonprofitOnboardingPage.loadData = (params) => (dispatch, getState) => {
  // Before anything, assure the user data is loaded
  return dispatch(fetchCurrentUser()).then(() => {
    const user = sharetribe.user.ensureCurrentUser(getState().user.currentUser);

    // Fetch the listing
    const promises = [
      dispatch(
        requestShowListing({
          id: new UUID(user.attributes.profile.publicData.listingId),
          include: ['author', 'images'],
          'fields.image': [
            'variants.landscape-crop',
            'variants.landscape-crop2x',
            'variants.square-small2x',
          ],
        })
      ).then((response) => {
        return response;
      }),
    ];

    // Payout specific data
    if (params.tab === PAYOUT_DETAILS) {
      promises.push(dispatch(setInitialValues()));
      promises.push(
        dispatch(fetchCurrentUser()).then((response) => {
          const currentUser = getState().user.currentUser;
          if (currentUser && currentUser.stripeAccount) {
            dispatch(fetchStripeAccount());
          }
          return response;
        })
      );
    }

    return Promise.all(promises);
  });
};

export default NonprofitOnboardingPage;
