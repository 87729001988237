import React from 'react';
import css from './SlidePanel.css';
import { node, number, string } from 'prop-types';
import classNames from 'classnames';

export const CONTENT_INITIAL = 0;
export const CONTENT_MAIN = 1;
export const CONTENT_SIDE = 2;

const SlidePanel = (props) => {
  const { activeContent, className, mainContent, sideContent } = props;
  const classes = classNames(
    css.root,
    activeContent === CONTENT_MAIN
      ? css.slideToMain
      : activeContent === CONTENT_INITIAL
      ? css.showMain
      : css.slideToSide,
    className
  );

  return (
    <div className={classes}>
      <div className={css.mainPanel}>{mainContent}</div>
      <div className={css.sidePanel}>{sideContent}</div>
    </div>
  );
};

SlidePanel.propTyps = {
  activeContent: number,
  className: string,
  mainContent: node.isRequired,
  sideContent: node.isRequired,
};

SlidePanel.defaultProps = {
  activeContent: CONTENT_INITIAL,
  className: null,
};

export default SlidePanel;
