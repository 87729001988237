import React from 'react';
import { compose } from 'redux';
import { object, string, bool, number, func, shape, array, arrayOf, oneOf } from 'prop-types';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import classNames from 'classnames';
import { withRouter } from 'react-router-dom';
import omit from 'lodash/omit';

import {
  InlineTextButton,
  SearchLocationFilter,
  SearchTypeMenu,
  SelectMultipleFilter,
  SelectSingleFilter,
} from '../../components';
import routeConfiguration from '../../routeConfiguration';
import { createResourceLocatorString } from '../../util/routes';
import { propTypes } from '../../util/types';
import css from './SearchFilters.css';
import SearchAvailabilityFilter from '../SearchAvailabilityFilter/SearchAvailabilityFilter';
import moment from 'moment';
import { getDateSelectOptions } from '../../util/dates';
import NamedLink from '../NamedLink/NamedLink';

// Dropdown container can have a positional offset (in pixels)
const FILTER_DROPDOWN_OFFSET = -14;
export const PARAM_START = 'start';
export const PARAM_END = 'end';

export const FILTER_TYPE_MENU = 'type-menu';
export const FILTER_LOCATION = 'location';
export const FILTER_AVAILABILITY = 'availability';
export const FILTER_AVAILABILITY_EVENT = 'availabilitySimple';
export const FILTER_INDUSTRY = 'industry';
export const FILTER_INTERESTS = 'interests';
export const FILTER_EVENT_ROLES = 'eventRoles';

export const FILTERS = [
  FILTER_EVENT_ROLES,
  FILTER_INTERESTS,
  FILTER_INDUSTRY,
  FILTER_AVAILABILITY,
  FILTER_AVAILABILITY_EVENT,
  FILTER_LOCATION,
  FILTER_TYPE_MENU,
];

const EVENT_DATE_FILTER_FORMAT = 'ddd, D MMM';
const EVENT_DATE_FILTER_KEY_FORMAT = 'YYYYMMDD';

// resolve initial values for a multi value filter
const initialValues = (queryParams, paramName) => {
  return !!queryParams[paramName] ? queryParams[paramName].split(',') : [];
};

const initialValue = (queryParams, paramName) => {
  return !!queryParams[paramName] ? queryParams[paramName] : '';
};

class SearchFiltersComponent extends React.Component {
  constructor(props) {
    super(props);
    this.getTranslation = this.getTranslation.bind(this);
    this.handleAvailabilitySelection = this.handleAvailabilitySelection.bind(this);
    this.handleAvailabilityEventDateSelection = this.handleAvailabilityEventDateSelection.bind(
      this
    );
    this.clearAllFilters = this.clearAllFilters.bind(this);
  }

  getTranslation = (name) => {
    return this.props.intl.formatMessage({
      id: `SearchFilters.${name}`,
    });
  };

  handleAvailabilitySelection(urlParam, options) {
    const { history, urlQueryParams, pageName, pathParams } = this.props;
    const queryParams =
      options !== null && typeof options === 'object'
        ? {
            ...urlQueryParams,
            [PARAM_START]: options.start,
            [PARAM_END]: options.end,
          }
        : omit(omit(urlQueryParams, PARAM_START), PARAM_END);

    history.push(
      createResourceLocatorString(pageName, routeConfiguration(), pathParams, queryParams)
    );
  }

  handleAvailabilityEventDateSelection(urlParam, date) {
    if (!date) {
      this.handleAvailabilitySelection(urlParam, null);
      return null;
    }

    const options = {
      start: moment.tz(date, this.props.timezone).startOf('day').toDate().toISOString(),
      end: moment.tz(date, this.props.timezone).endOf('day').toDate().toISOString(),
    };

    return this.handleAvailabilitySelection(urlParam, options);
  }

  hasAnyFilterSelected() {
    const { urlQueryParams, filterParamNames } = this.props;

    if (!urlQueryParams || Object.keys(urlQueryParams).length === 0 || !filterParamNames) {
      return false;
    }

    return !!Object.keys(urlQueryParams).find(
      (key) => filterParamNames.includes(key) && !!urlQueryParams[key]
    );
  }

  clearAllFilters(e) {
    const { urlQueryParams, history, filterParamNames, pageName, pathParams } = this.props;

    const queryParams = omit(urlQueryParams, filterParamNames);
    history.push(
      createResourceLocatorString(pageName, routeConfiguration(), pathParams, queryParams)
    );

    // blur event target if event is passed
    if (e && e.currentTarget) {
      e.currentTarget.blur();
    }
  }

  get eventDateFilterOptions() {
    if (!this.props.event) return [];

    const { active } = this.props.event;

    if (!active.from || !active.to) return [];

    return getDateSelectOptions(
      active.from,
      active.to,
      EVENT_DATE_FILTER_KEY_FORMAT,
      EVENT_DATE_FILTER_FORMAT
    );
  }

  showFilter(filterName) {
    return (
      !this.props.showOnlySelectedFilters || this.props.showOnlySelectedFilters.includes(filterName)
    );
  }

  render() {
    const {
      rootClassName,
      className,
      event,
      intl,
      urlQueryParams,
      listingsAreLoaded,
      resultsCount,
      searchInProgress,
      industryFilter,
      interestsFilter,
      eventRolesFilter,
      isSearchFiltersPanelOpen,
      toggleSearchFiltersPanel,
      searchFiltersPanelSelectedCount,
      history,
      pageName,
      pathParams,
    } = this.props;

    const hasNoResult = listingsAreLoaded && resultsCount === 0;
    const classes = classNames(
      rootClassName || css.root,
      { [css.longInfo]: hasNoResult },
      className
    );

    const initialIndustry = industryFilter
      ? initialValues(urlQueryParams, industryFilter.paramName)
      : null;

    const initialInterests = interestsFilter
      ? initialValues(urlQueryParams, interestsFilter.paramName)
      : null;

    const initialEventRole = eventRolesFilter
      ? initialValue(urlQueryParams, eventRolesFilter.paramName)
      : null;

    const initialAvailabilityFilterValues =
      urlQueryParams.start && urlQueryParams.end
        ? {
            start: urlQueryParams.start,
            end: urlQueryParams.end,
          }
        : {};

    const handleSelectOptions = (urlParam, options) => {
      const optionsArray = (options && (Array.isArray(options) ? options : [options])) || [];
      const optionPrefix =
        urlParam === 'pub_industry' || urlParam === 'pub_eventRoles' ? '' : 'has_any:';

      const queryParams =
        optionsArray && optionsArray.length > 0
          ? {
              ...urlQueryParams,
              [urlParam]: optionPrefix + optionsArray.join(','),
            }
          : omit(urlQueryParams, urlParam);

      history.push(
        createResourceLocatorString(pageName, routeConfiguration(), pathParams, queryParams)
      );
    };

    const toggleSearchFiltersPanelButtonClasses =
      isSearchFiltersPanelOpen || searchFiltersPanelSelectedCount > 0
        ? css.searchFiltersPanelOpen
        : css.searchFiltersPanelClosed;
    const toggleSearchFiltersPanelButton = toggleSearchFiltersPanel ? (
      <button
        className={toggleSearchFiltersPanelButtonClasses}
        onClick={() => {
          toggleSearchFiltersPanel(!isSearchFiltersPanelOpen);
        }}
      >
        <FormattedMessage
          id="SearchFilters.moreFiltersButton"
          values={{ count: searchFiltersPanelSelectedCount }}
        />
      </button>
    ) : null;

    return (
      <>
        <div className={classes}>
          <div className={css.filters}>
            {event && event.title && (
              <span className={css.eventTitle}>
                <NamedLink name="EventLandingPage" params={{ eventKey: event.key }}>
                  <InlineTextButton className={css.eventTitleButton}>
                    {event.title}
                  </InlineTextButton>
                </NamedLink>
              </span>
            )}

            {this.showFilter(FILTER_TYPE_MENU) && (
              <SearchTypeMenu activeItem="people" history={history} intl={intl} />
            )}

            {this.showFilter(FILTER_LOCATION) && (
              <SearchLocationFilter
                history={history}
                intl={intl}
                urlQueryParams={urlQueryParams}
                pageName={pageName}
                pathParams={pathParams}
              />
            )}

            {this.showFilter(FILTER_AVAILABILITY) && (
              <SearchAvailabilityFilter
                id={'SearchFilters.availabilityFilter'}
                initialValues={initialAvailabilityFilterValues}
                onSubmit={this.handleAvailabilitySelection}
              />
            )}

            {this.showFilter(FILTER_AVAILABILITY_EVENT) && (
              <SearchAvailabilityFilter
                id={'SearchFilters.eventDateFilter'}
                initialValues={initialAvailabilityFilterValues}
                onSubmit={this.handleAvailabilitySelection}
                minimumDate={event && moment.tz(event.active.from, event.timezone).startOf('day')}
                maximumDate={event && moment.tz(event.active.to, event.timezone).startOf('day')}
                showPreDefinedOptions={false}
              />
            )}

            {this.showFilter(FILTER_EVENT_ROLES) && (
              <SelectSingleFilter
                id="SearchFilters.eventRolesFilter"
                name="eventRoles"
                options={eventRolesFilter.options}
                urlParam={eventRolesFilter.paramName}
                label={this.getTranslation('allAttendeesLabel')}
                onSelect={handleSelectOptions}
                initialValue={initialEventRole}
                showAsPopup
              />
            )}

            {this.showFilter(FILTER_INDUSTRY) && (
              <SelectMultipleFilter
                id={'SearchFilters.industryFilter'}
                name="industry"
                urlParam={industryFilter.paramName}
                label={this.getTranslation('industryLabel')}
                onSubmit={handleSelectOptions}
                showAsPopup
                options={industryFilter.options}
                initialValues={initialIndustry}
                contentPlacementOffset={FILTER_DROPDOWN_OFFSET}
              />
            )}

            {this.showFilter(FILTER_INTERESTS) && (
              <SelectMultipleFilter
                id={'SearchFilters.interestsFilter'}
                name="interests"
                urlParam={interestsFilter.paramName}
                label={this.getTranslation('interestsLabel')}
                onSubmit={handleSelectOptions}
                showAsPopup
                options={interestsFilter.options}
                initialValues={initialInterests}
                contentPlacementOffset={FILTER_DROPDOWN_OFFSET}
              />
            )}

            {this.hasAnyFilterSelected() && (
              <InlineTextButton className={css.clearAllFilters} onClick={this.clearAllFilters}>
                {this.getTranslation('clearAll')}
              </InlineTextButton>
            )}
            {toggleSearchFiltersPanelButton}
          </div>
          <div className={css.results}>
            {listingsAreLoaded && resultsCount > 0 ? (
              <div className={css.searchResultSummary}>
                <span className={css.resultsFound}>
                  <FormattedMessage
                    id="SearchFilters.foundResults"
                    values={{ count: resultsCount }}
                  />
                </span>
              </div>
            ) : null}

            {hasNoResult ? (
              <div className={css.noSearchResults}>
                <FormattedMessage id="SearchFilters.noResults" />
              </div>
            ) : null}
          </div>
        </div>
        <div className={css.loading}>
          {searchInProgress ? (
            <div className={css.loadingResults}>
              <FormattedMessage id="SearchFilters.loadingResults" />
            </div>
          ) : null}
        </div>
      </>
    );
  }
}

SearchFiltersComponent.defaultProps = {
  rootClassName: null,
  className: null,
  resultsCount: null,
  searchingInProgress: false,
  industryFilter: null,
  interestsFilter: null,
  isSearchFiltersPanelOpen: false,
  toggleSearchFiltersPanel: null,
  searchFiltersPanelSelectedCount: 0,
  pageName: 'SearchPage',
  pathParams: {},
};

SearchFiltersComponent.propTypes = {
  className: string,
  event: propTypes.event,
  intl: intlShape.isRequired,
  urlQueryParams: object.isRequired,
  listingsAreLoaded: bool.isRequired,
  resultsCount: number,
  searchingInProgress: bool,
  onManageDisableScrolling: func.isRequired,
  industryFilter: propTypes.filterConfig,
  interestsFilter: propTypes.filterConfig,
  isSearchFiltersPanelOpen: bool,
  rootClassName: string,
  searchFiltersPanelSelectedCount: number,
  toggleSearchFiltersPanel: func,
  filterParamNames: array,
  pageName: string,
  pathParams: object,
  showOnlySelectedFilters: arrayOf(oneOf(FILTERS)),
  timezone: string.isRequired,

  // from withRouter
  history: shape({
    push: func.isRequired,
  }).isRequired,
};

const SearchFilters = compose(withRouter, injectIntl)(SearchFiltersComponent);

export default SearchFilters;
