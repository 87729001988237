export const PERSONAL_INFO = 'personal-info';
export const INTERESTS = 'interests';
export const SUPPORTED_NONPROFITS = 'supported-nonprofits';
export const AVAILABILITY = 'availability';
export const PUBLISH = 'publish';

export const ONBOARDING_PAGE_NAME = 'OnboardingPage';

export const ONBOARDING_PROGRESS = {
  [PERSONAL_INFO]: 10,
  [SUPPORTED_NONPROFITS]: 20,
  [INTERESTS]: 30,
};

export const ONBOARDING_TAB_NUMBERS = {
  [PERSONAL_INFO]: 1,
  [SUPPORTED_NONPROFITS]: 2,
  [INTERESTS]: 3,
};
