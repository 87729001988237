import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';

import css from './SectionQuote.css';
import profileImg from './profile-img-tara-nolan.png';

const SectionQuote = (props) => {
  const { rootClassName, className } = props;

  const classes = classNames(rootClassName || css.root, className);
  return (
    <div className={classes}>
      <div className={css.container}>
        <div className={css.title}>
          <span className={css.titleRow}>
            <FormattedMessage id="SectionQuote.title.row1" />
          </span>
          <span className={css.titleRow}>
            {' '}
            <FormattedMessage id="SectionQuote.title.row2" />
          </span>
          <span className={css.titleRow}>
            {' '}
            <FormattedMessage id="SectionQuote.title.row3" />
          </span>
        </div>
        <div className={css.quote}>
          <img className={css.quoteImage} src={profileImg} alt={'Tara Nolan'} />
          <blockquote>
            “It’s so nice to have a platform and space to connect with other professionals in a way
            that truly adds value back to where it matters most, outside commercial gain and paying
            it forward to charities and organizations doing good in the world. I didn’t realize how
            much we needed something like Givsly until Chad and the team created it!”
          </blockquote>
          <div className={css.quoteName}>Tara Nolan</div>
          <div className={css.quoteJobTitle}>
            Vice President, Global Growth
            <br />
            Havas Media Group
          </div>
        </div>
      </div>
    </div>
  );
};

SectionQuote.defaultProps = { rootClassName: null, className: null };

const { string } = PropTypes;

SectionQuote.propTypes = {
  rootClassName: string,
  className: string,
};

export default SectionQuote;
