import React, { Component } from 'react';
import { bool, oneOfType, string } from 'prop-types';
import { momentObj } from 'react-moment-proptypes';
import { FieldSelect } from '../index';
import moment from 'moment-timezone';
import css from './FieldDateSelect.css';

const LABEL_DATE_FORMAT = 'ddd, D MMM';
const VALUE_DATE_FORMAT = 'YYYY-MM-DDTHH:mm:ss.SSS';

class FieldDateSelect extends Component {
  options(currentDate, endDate, options = []) {
    if (!currentDate || !endDate) return [];

    if (currentDate.isAfter(endDate, 'd')) {
      return options;
    }

    const option = {
      key: currentDate.format(VALUE_DATE_FORMAT),
      value: currentDate.format(VALUE_DATE_FORMAT),
      label: currentDate.format(LABEL_DATE_FORMAT),
    };

    return this.options(currentDate.add(1, 'd'), endDate, [...options, option]);
  }

  parseValueToDate = (value) => {
    return value && value !== this.props.defaultOptionValue
      ? moment.tz(value, this.props.timezone).toDate()
      : null;
  };

  formatValueToString = (value) => {
    return value
      ? moment.tz(value, this.props.timezone).startOf('day').format(VALUE_DATE_FORMAT)
      : null;
  };

  render() {
    const { startDate, endDate, timezone, ...fieldProps } = this.props;

    const start = startDate && moment.tz(startDate, timezone).startOf('day');
    const end = endDate && moment.tz(endDate, timezone).startOf('day');

    const options = this.options(start, end);

    return (
      <FieldSelect
        {...fieldProps}
        options={options}
        parse={this.parseValueToDate}
        format={this.formatValueToString}
        disabled={options.length === 0}
        className={css.fieldSelect}
      />
    );
  }
}

FieldDateSelect.propTypes = {
  defaultOptionLabel: string,
  defaultOptionValue: string,
  endDate: oneOfType([string, momentObj]),
  id: string,
  label: string.isRequired,
  name: string.isRequired,
  showDefaultOption: bool,
  startDate: oneOfType([string, momentObj]),
  timezone: string.isRequired,
};

export default FieldDateSelect;
