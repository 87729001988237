import utils from '@givsly/sharetribe-utils';
import Tippy from '@tippy.js/react';
import moment from 'moment';
import React, { Component } from 'react';
import { array, arrayOf, bool, func, number, object, shape, string } from 'prop-types';
import { getMarketplaceForEnvironment } from '../../util/environment';
import { withMessages } from '../../util/localization';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import classNames from 'classnames';
import {
  TRANSITION_EXPIRE,
  TRANSITION_REQUEST_PAYMENT_AFTER_ENQUIRY,
  TRANSITION_PROVIDER_CANCEL,
  TRANSITION_CUSTOMER_CANCEL,
  txIsAccepted,
  txIsCanceled,
  txIsDeclined,
  txIsEnquired,
  txIsPaymentExpired,
  txIsPending,
  txIsPaymentPending,
  txIsProposed,
  txIsRequested,
  txHasBeenDelivered,
} from '../../util/transaction';
import { propTypes } from '../../util/types';
import {
  ensureBooking,
  ensureCurrentUser,
  ensureListing,
  ensureTransaction,
  ensureUser,
} from '../../util/data';
import { isMobileSafari } from '../../util/userAgent';
import {
  Button,
  Hint,
  PrimaryButton,
  SecondaryButton,
  Modal,
  NamedLink,
  MobileAvatarHero,
  ReviewModal,
  CalendarDownloadOptions,
  ButtonTabNavHorizontal,
} from '../../components';
import { AcceptProposalForm, ExposeEmailForm, SendMessageForm } from '../../forms';
import { ICON_ENVELOPE } from '../LiteButton/constants';
import LiteButton from '../LiteButton/LiteButton';

// These are internal components that make this file more readable.
import AddressLinkMaybe from './AddressLinkMaybe';
import BreakdownMaybe from './BreakdownMaybe';
import DetailCardProfile from './DetailCardProfile';
import FeedSection from './FeedSection';
import SaleActionButtonsMaybe from './SaleActionButtonsMaybe';
import PanelHeading, {
  HEADING_ENQUIRED,
  HEADING_PENDING,
  HEADING_PAYMENT_PENDING,
  HEADING_PAYMENT_EXPIRED,
  HEADING_REQUESTED,
  HEADING_ACCEPTED,
  HEADING_DECLINED,
  HEADING_CUSTOMER_CANCELED,
  HEADING_PROVIDER_CANCELED,
  HEADING_DELIVERED,
  HEADING_EXPIRED,
  HEADING_PROPOSED,
} from './PanelHeading';
import { types as sdkTypes } from '../../util/sdkLoader';
import css from './TransactionPanel.css';
import config from '../../config';
import givslyConfig from '@givsly/config';

const { Money } = sdkTypes;

// Helper function to get display names for different roles
const displayNames = (currentUser, currentProvider, currentCustomer) => {
  const currentUserIsCustomer =
    currentUser.id && currentCustomer.id && currentUser.id.uuid === currentCustomer.id.uuid;

  const {
    firstName: customerFirstName,
    lastName: customerLastName,
  } = currentCustomer.attributes.profile.publicData;
  const {
    firstName: providerFirstName,
    lastName: providerLastName,
  } = currentProvider.attributes.profile.publicData;
  const customerName = `${customerFirstName} ${customerLastName}`;
  const providerName = `${providerFirstName} ${providerLastName}`;

  return {
    providerName,
    customerName,
    otherUserName: currentUserIsCustomer ? providerName : customerName,
  };
};

// Mobile tabs
const TAB_BOOKING_SUMMARY = 'booking-summary';
const TAB_MESSAGES = 'messages';

export class TransactionPanelComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeTab: TAB_BOOKING_SUMMARY,
      exposeEmail: null,
      exposeEmailInitialized: false,
      isHintClosed: false,
      sendMessageFormFocused: false,
      isReviewModalOpen: false,
      reviewSubmitted: false,
      isCancelBookingModalOpen: false,
    };
    this.isMobSaf = false;
    this.sendMessageFormName = 'TransactionPanel.SendMessageForm';

    this.handleOnCloseHint = this.handleOnCloseHint.bind(this);
    this.handleBrowseTab = this.handleBrowseTab.bind(this);
    this.handleToggleExposeEmail = this.handleToggleExposeEmail.bind(this);
    this.onOpenReviewModal = this.onOpenReviewModal.bind(this);
    this.onSubmitReview = this.onSubmitReview.bind(this);
    this.onSendMessageFormFocus = this.onSendMessageFormFocus.bind(this);
    this.onSendMessageFormBlur = this.onSendMessageFormBlur.bind(this);
    this.onMessageSubmit = this.onMessageSubmit.bind(this);
    this.scrollToMessage = this.scrollToMessage.bind(this);
    this.onOpenCancelBookingModal = this.onOpenCancelBookingModal.bind(this);
    this.onCloseCancelBookingModal = this.onCloseCancelBookingModal.bind(this);
    this.onCancelBookingExecute = this.onCancelBookingExecute.bind(this);
  }

  /**
   * Set the default value for email exposure (only once). This is mainly intended for future use
   * where this setting can be pre-defined from the account settings (on or off).
   *
   * @param prevProps
   * @param prevState
   * @param snapshot
   */
  componentDidUpdate(prevProps, prevState, snapshot) {
    if (!this.state.exposeEmailInitialized && this.props.currentUser.id) {
      const ensuredCurrentUser = utils.user.ensureCurrentUser(this.props.currentUser);
      this.setState({
        exposeEmail: ensuredCurrentUser.attributes.email,
        exposeEmailInitialized: true,
      });
    }
  }

  componentDidMount() {
    this.isMobSaf = isMobileSafari();
  }

  handleBrowseTab(tab) {
    this.setState({
      activeTab: tab,
    });
  }

  handleToggleExposeEmail({ value, checked }) {
    this.setState({
      exposeEmail: checked ? value : null,
    });
  }

  onOpenReviewModal() {
    this.setState({ isReviewModalOpen: true });
  }

  onSubmitReview(values) {
    const { onSendReview, transaction, transactionRole } = this.props;
    const currentTransaction = ensureTransaction(transaction);
    const { reviewRating, reviewContent } = values;
    const rating = Number.parseInt(reviewRating, 10);
    onSendReview(transactionRole, currentTransaction, rating, reviewContent)
      .then((r) => this.setState({ isReviewModalOpen: false, reviewSubmitted: true }))
      .catch((e) => {
        // Do nothing.
      });
  }

  onSendMessageFormFocus() {
    this.setState({ sendMessageFormFocused: true });
    if (this.isMobSaf) {
      // Scroll to bottom
      window.scroll({ top: document.body.scrollHeight, left: 0, behavior: 'smooth' });
    }
  }

  onSendMessageFormBlur() {
    this.setState({ sendMessageFormFocused: false });
  }

  onMessageSubmit(values, form) {
    const message = values.message ? values.message.trim() : null;
    const { transaction, onSendMessage } = this.props;
    const ensuredTransaction = ensureTransaction(transaction);

    if (!message) {
      return;
    }
    onSendMessage(ensuredTransaction.id, message)
      .then((messageId) => {
        form.reset();
        this.scrollToMessage(messageId);
      })
      .catch((e) => {
        // Ignore, Redux handles the error
      });
  }

  scrollToMessage(messageId) {
    const selector = `#msg-${messageId.uuid}`;
    const el = document.querySelector(selector);
    if (el) {
      el.scrollIntoView({
        block: 'start',
        behavior: 'smooth',
      });
    }
  }

  onOpenCancelBookingModal() {
    this.setState({ isCancelBookingModalOpen: true });
  }

  onCloseCancelBookingModal() {
    this.setState({ isCancelBookingModalOpen: false });
  }

  onCancelBookingExecute() {
    const { transaction, onCancelBooking, transactionRole } = this.props;

    onCancelBooking(transaction.id.uuid, transactionRole)
      .then((r) => {
        this.setState({ isCancelBookingModalOpen: false });
      })
      .catch((e) => {
        // Ignore, Redux handles the error
      });
  }

  handleOnCloseHint() {
    this.setState({
      isHintClosed: true,
    });
  }

  get canSendMessages() {
    const transaction = ensureTransaction(this.props.transaction);
    return utils.transaction.MESSAGING_ENABLED_TRANSITIONS.includes(
      transaction.attributes.lastTransition
    );
  }

  get showHint() {
    const { messages, transaction } = this.props;

    return (
      !this.state.isHintClosed &&
      messages.length === 0 &&
      this.canSendMessages &&
      !utils.transaction.isProposal(transaction) &&
      utils.transaction.isOpen(transaction)
    );
  }

  /**
   * Whether or not the contact info hint should be displayed.
   * - Messaging should be enabled
   * - The current user should be the customer in the transaction
   * - There should not yet be any messages if the email is not exposed
   * - Transaction should be an open proposal or open booking with email exposed (not declined)
   *
   * @returns {boolean|boolean|[*]|*}
   */
  get showContactInfoHint() {
    const { currentUser, messages, transaction } = this.props;
    const ensuredCurrentUser = utils.user.ensureCurrentUser(currentUser);
    const ensuredTransaction = utils.transaction.ensure(transaction);
    const { exposeEmail } = ensuredTransaction.attributes.protectedData;

    return (
      !this.state.isHintClosed &&
      this.canSendMessages &&
      ensuredTransaction.customer.id.uuid === ensuredCurrentUser.id.uuid &&
      (messages.length <= 1 || exposeEmail) &&
      (utils.transaction.isProposal(transaction) || exposeEmail) &&
      utils.transaction.isOpen(transaction) &&
      !utils.transaction.isDeclined(transaction)
    );
  }

  render() {
    const {
      history,
      getMessage,
      rootClassName,
      className,
      currentUser,
      event,
      transaction,
      paymentTransaction,
      totalMessagePages,
      oldestMessagePageFetched,
      messages,
      nonprofit,
      initialMessageFailed,
      savePaymentMethodFailed,
      fetchMessagesInProgress,
      fetchMessagesError,
      sendMessageInProgress,
      sendMessageError,
      sendReviewInProgress,
      sendReviewError,
      /*
      onFetchTimeSlots,
*/
      onManageDisableScrolling,
      onShowMoreMessages,
      transactionRole,
      intl,
      onBookAfterProposal,
      onAcceptSale,
      onAcceptSaleLegacy,
      onDeclineSale,
      onDeclineSaleLegacy,
      onTrackEvent,
      acceptInProgress,
      declineInProgress,
      cancelBookingInProgress,
      acceptSaleError,
      declineSaleError,
      cancelBookingError,
      /*
      onSubmitBookingRequest,
      monthlyTimeSlots,
*/
      nextTransitions,
      timezone,
    } = this.props;

    const { sendMessageFormFocused } = this.state;

    const currentTransaction = ensureTransaction(transaction);
    const { exposeEmail } = currentTransaction.attributes.protectedData;
    const currentListing = ensureListing(currentTransaction.listing);
    const currentProvider = ensureUser(currentTransaction.provider);
    const currentCustomer = ensureUser(currentTransaction.customer);
    const ensuredCurrentUser = ensureCurrentUser(currentUser);
    const isCustomer = transactionRole === 'customer';
    const isProvider = transactionRole === 'provider';

    const listingLoaded = !!currentListing.id;
    const listingDeleted = listingLoaded && currentListing.attributes.deleted;
    const iscustomerLoaded = !!currentCustomer.id;
    const isCustomerBanned = iscustomerLoaded && currentCustomer.attributes.banned;
    const isCustomerDeleted = iscustomerLoaded && currentCustomer.attributes.deleted;
    const isProviderLoaded = !!currentProvider.id;
    const isProviderBanned = isProviderLoaded && currentProvider.attributes.banned;
    const isProviderDeleted = isProviderLoaded && currentProvider.attributes.deleted;

    const stateDataFn = (tx) => {
      const { lastTransition } = tx.attributes;

      if (txIsEnquired(tx)) {
        const transitions = Array.isArray(nextTransitions)
          ? nextTransitions.map((transition) => {
              return transition.attributes.name;
            })
          : [];
        const hasCorrectNextTransition =
          transitions.length > 0 && transitions.includes(TRANSITION_REQUEST_PAYMENT_AFTER_ENQUIRY);
        return {
          headingState: HEADING_ENQUIRED,
          showBookingPanel: isCustomer && !isProviderBanned && hasCorrectNextTransition,
        };
      } else if (txIsProposed(tx)) {
        return {
          headingState: HEADING_PROPOSED,
          showAcceptProposalForm: isProvider && !isCustomerBanned,
        };
      } else if (txIsPending(tx)) {
        return {
          headingState: HEADING_PENDING,
          showDetailCardHeadings: isCustomer,
          showSaleButtons: isProvider && !isCustomerBanned,
        };
      } else if (txIsPaymentPending(tx)) {
        return {
          headingState: HEADING_PAYMENT_PENDING,
          showDetailCardHeadings: isCustomer,
        };
      } else if (txIsPaymentExpired(tx)) {
        return {
          headingState: HEADING_PAYMENT_EXPIRED,
          showDetailCardHeadings: isCustomer,
        };
      } else if (txIsRequested(tx)) {
        return {
          headingState: HEADING_REQUESTED,
          showDetailCardHeadings: isCustomer,
          showSaleButtons: isProvider && !isCustomerBanned,
        };
      } else if (
        txIsAccepted(tx) ||
        lastTransition === utils.transaction.TRANSITION_ACCEPT_AFTER_BOOK_PROPOSAL
      ) {
        const transitions = Array.isArray(nextTransitions)
          ? nextTransitions.map((transition) => {
              return transition.attributes.name;
            })
          : [];

        const cancelTransition =
          transactionRole === 'provider' ? TRANSITION_PROVIDER_CANCEL : TRANSITION_CUSTOMER_CANCEL;
        const canCancel = transitions.length > 0 && transitions.includes(cancelTransition);

        return {
          headingState: HEADING_ACCEPTED,
          showDetailCardHeadings: isCustomer,
          showAddress: isCustomer,
          showCancelButton: canCancel,
        };
      } else if (txIsDeclined(tx)) {
        return {
          headingState: lastTransition === TRANSITION_EXPIRE ? HEADING_EXPIRED : HEADING_DECLINED,
          showDetailCardHeadings: isCustomer,
        };
      } else if (txIsCanceled(tx)) {
        return {
          headingState:
            lastTransition === TRANSITION_CUSTOMER_CANCEL
              ? HEADING_CUSTOMER_CANCELED
              : HEADING_PROVIDER_CANCELED,
          showDetailCardHeadings: isCustomer,
        };
      } else if (txHasBeenDelivered(tx)) {
        return {
          headingState: HEADING_DELIVERED,
          showDetailCardHeadings: isCustomer,
          showAddress: isCustomer,
        };
      } else {
        return { headingState: 'unknown' };
      }
    };
    const stateData = stateDataFn(currentTransaction);

    const deletedListingTitle = intl.formatMessage({
      id: 'TransactionPanel.deletedListingTitle',
    });

    const { customerName, otherUserName, providerName } = displayNames(
      currentUser,
      currentProvider,
      currentCustomer,
      false,
      intl
    );

    const { publicData, geolocation } = currentListing.attributes;
    const location = publicData && publicData.location ? publicData.location : {};
    const listingTitle = currentListing.attributes.deleted
      ? deletedListingTitle
      : currentListing.attributes.title;

    const firstImage =
      currentListing.images && currentListing.images.length > 0 ? currentListing.images[0] : null;
    const marketplace = getMarketplaceForEnvironment();
    const hasCreditCapability =
      givslyConfig.capability.booking.credit[marketplace] <=
      currentTransaction.attributes.processVersion;
    const transactionId = currentTransaction.id.uuid;

    const saleButtons = (
      <SaleActionButtonsMaybe
        showButtons={stateData.showSaleButtons}
        acceptInProgress={acceptInProgress}
        declineInProgress={declineInProgress}
        acceptSaleError={acceptSaleError}
        declineSaleError={declineSaleError}
        onAcceptSale={() => {
          return hasCreditCapability
            ? onAcceptSale(transactionId, ensuredCurrentUser, this.state.exposeEmail)
            : onAcceptSaleLegacy(transactionId);
        }}
        onDeclineSale={() => {
          return hasCreditCapability
            ? onDeclineSale(transactionId, ensuredCurrentUser)
            : onDeclineSaleLegacy(transactionId);
        }}
      />
    );

    const showSendMessageForm =
      !isCustomerBanned &&
      !isCustomerDeleted &&
      !isProviderBanned &&
      !isProviderDeleted &&
      this.canSendMessages;

    const sendMessagePlaceholder = intl.formatMessage(
      { id: 'TransactionPanel.sendMessagePlaceholder' },
      { name: otherUserName }
    );

    const paymentMethodsPageLink = (
      <NamedLink name="PaymentMethodsPage">
        <FormattedMessage id="TransactionPanel.paymentMethodsPageLink" />
      </NamedLink>
    );

    const classes = classNames(
      rootClassName || css.root,
      this.state.activeTab === TAB_BOOKING_SUMMARY
        ? css.displayBookingSummary
        : css.displayMessages,
      className
    );

    const panelButtons = (
      <>
        {stateData.headingState === HEADING_ACCEPTED ? (
          <CalendarDownloadOptions
            className={css.saveToCalendar}
            contentClassName={css.saveToCalendarOptions}
            ensuredCurrentUser={ensuredCurrentUser}
            history={history}
            intl={intl}
            name="booking"
            onManageDisableScrolling={onManageDisableScrolling}
            transactionId={currentTransaction.id.uuid}
          >
            <span className={css.saveToCalendarButton}>
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
                <path
                  fill="#1C1A34"
                  fillRule="evenodd"
                  d="M14 0a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2h12zm-.333 3.5H2.333a.833.833 0 0 0-.827.729l-.006.104v9.334c0 .425.318.775.729.827l.104.006h11.334a.833.833 0 0 0 .827-.729l.006-.104V4.333a.833.833 0 0 0-.833-.833zM5 11v1H4v-1h1zm2.333 0v1h-1v-1h1zm2.334 0v1h-1v-1h1zM5 8.5v1H4v-1h1zm2.333 0v1h-1v-1h1zm2.334 0v1h-1v-1h1zM12 8.5v1h-1v-1h1zM5 6v1H4V6h1zm2.333 0v1h-1V6h1zm2.334 0v1h-1V6h1zM12 6v1h-1V6h1z"
                />
              </svg>
              <FormattedMessage id="TransactionPanel.saveToCalendarButton" />
            </span>
          </CalendarDownloadOptions>
        ) : null}
        {stateData.showCancelButton ? (
          <Button className={css.cancelButton} onClick={this.onOpenCancelBookingModal}>
            <FormattedMessage id="TransactionPanel.cancel" />
          </Button>
        ) : null}
      </>
    );

    const nonprofitName = ensureListing(nonprofit).attributes.title;
    const donation = currentTransaction.attributes.protectedData.donationValue
      ? new Money(currentTransaction.attributes.protectedData.donationValue, config.currency)
      : null;
    const quantity = currentTransaction.attributes.protectedData.quantity;

    const booking = ensureBooking(currentTransaction.booking);
    const { end: bookingEnd, start: bookingStart } = booking.attributes;
    const duration =
      bookingEnd && bookingStart
        ? Math.floor((bookingEnd - bookingStart) / 60000)
        : config.custom.timeSlotDurations[quantity - 1];
    const showPanelButtons = stateData.headingState === HEADING_ACCEPTED;
    const isProposal = utils.transaction.isProposal(currentTransaction);

    return (
      <div className={classes}>
        <div className={css.container}>
          <MobileAvatarHero user={isCustomer ? currentProvider : currentCustomer} />
          <div className={css.mobileTabNav}>
            <ButtonTabNavHorizontal
              tabs={[
                {
                  className: classNames(
                    css.mobileTab,
                    this.state.activeTab === TAB_BOOKING_SUMMARY ? css.mobileTabSelected : null
                  ),
                  selected: this.state.activeTab === TAB_BOOKING_SUMMARY,
                  text: getMessage('tab.bookingSummary'),
                  onClick: () => this.handleBrowseTab(TAB_BOOKING_SUMMARY),
                },
                {
                  className: classNames(
                    css.mobileTab,
                    this.state.activeTab === TAB_MESSAGES ? css.mobileTabSelected : null
                  ),
                  selected: this.state.activeTab === TAB_MESSAGES,
                  text: getMessage('tab.messages'),
                  onClick: () => this.handleBrowseTab(TAB_MESSAGES),
                },
              ]}
            />
          </div>
          <div className={css.txInfo}>
            <div className={css.bookingSummary}>
              <div className={css.panelHeadingWrapper}>
                <PanelHeading
                  donation={donation}
                  duration={duration}
                  panelHeadingState={stateData.headingState}
                  transactionRole={transactionRole}
                  providerName={providerName}
                  customerName={customerName}
                  intl={intl}
                  isCustomerBanned={isCustomerBanned}
                  isProposal={isProposal}
                  listingId={currentListing.id && currentListing.id.uuid}
                  listingTitle={listingTitle}
                  listingDeleted={listingDeleted}
                  nonprofitName={nonprofitName}
                  className={stateData.showAcceptProposalForm ? css.bookingProposalHeader : null}
                />
                {this.showContactInfoHint ? (
                  <Hint
                    className={css.hint}
                    canClose={true}
                    onClose={this.handleOnCloseHint}
                    text={
                      currentTransaction.attributes.protectedData.exposeEmail ? (
                        <>
                          <span className={css.proposalHintText}>
                            {getMessage('proposalHint.exposedEmail.text', {
                              providerName: otherUserName,
                            })}
                          </span>
                          <LiteButton
                            className={css.proposalHintButton}
                            icon={ICON_ENVELOPE}
                            onClick={() => (window.location.href = `mailto:${exposeEmail}`)}
                          >
                            {exposeEmail}
                          </LiteButton>
                        </>
                      ) : (
                        <span className={css.proposalHintText}>
                          {getMessage('proposalHint.unexposedEmail.text', {
                            providerName: otherUserName,
                          })}
                        </span>
                      )
                    }
                    title={getMessage(
                      currentTransaction.attributes.protectedData.exposeEmail
                        ? 'proposalHint.exposedEmail.title'
                        : 'proposalHint.unexposedEmail.title'
                    )}
                  />
                ) : null}
              </div>

              {showPanelButtons ? (
                <div className={css.panelButtonsMobile}>{panelButtons}</div>
              ) : null}

              {!stateData.showAcceptProposalForm ? (
                <div className={css.bookingDetailsMobile}>
                  <AddressLinkMaybe
                    rootClassName={css.addressMobile}
                    location={location}
                    geolocation={geolocation}
                    showAddress={stateData.showAddress}
                  />
                  <BreakdownMaybe
                    bookingTransaction={transaction}
                    transaction={paymentTransaction}
                    transactionRole={transactionRole}
                    timezone={timezone}
                  />
                </div>
              ) : null}

              {savePaymentMethodFailed ? (
                <p className={css.genericError}>
                  <FormattedMessage
                    id="TransactionPanel.savePaymentMethodFailed"
                    values={{ paymentMethodsPageLink }}
                  />
                </p>
              ) : null}
            </div>
            <div className={css.feedWrapper}>
              <FeedSection
                rootClassName={css.feedContainer}
                currentTransaction={currentTransaction}
                currentUser={currentUser}
                event={event}
                fetchMessagesError={fetchMessagesError}
                fetchMessagesInProgress={fetchMessagesInProgress}
                initialMessageFailed={initialMessageFailed}
                messages={messages}
                oldestMessagePageFetched={oldestMessagePageFetched}
                onOpenReviewModal={this.onOpenReviewModal}
                onShowMoreMessages={() => onShowMoreMessages(currentTransaction.id)}
                totalMessagePages={totalMessagePages}
              />

              {this.showHint ? (
                <Hint
                  className={css.hint}
                  canClose={true}
                  onClose={this.handleOnCloseHint}
                  text={intl.formatMessage({ id: 'TransactionPanel.hint.text' })}
                  title={intl.formatMessage({ id: 'TransactionPanel.hint.title' })}
                />
              ) : null}

              {showSendMessageForm ? (
                <SendMessageForm
                  formId={this.sendMessageFormName}
                  rootClassName={classNames(
                    css.sendMessageForm,
                    stateData.showSaleButtons ? css.sendMessageFormGiveRoom : null,
                    sendMessageFormFocused ? css.sendMessageFormFocused : null
                  )}
                  messagePlaceholder={sendMessagePlaceholder}
                  inProgress={sendMessageInProgress}
                  sendMessageError={sendMessageError}
                  onFocus={this.onSendMessageFormFocus}
                  onBlur={this.onSendMessageFormBlur}
                  onSubmit={this.onMessageSubmit}
                />
              ) : null}
            </div>
            {stateData.showAcceptProposalForm && (
              <div className={css.proposedTimesWrapper}>
                <h2 className={css.suggestedTimesTitle}>{getMessage('suggestedTimes.title')}</h2>
                <p className={css.suggestedTimesLabel}>
                  {getMessage('suggestedTimes.label', { customerName })}{' '}
                  <Tippy content={getMessage('suggestedTimes.toolTip')}>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="12"
                      height="12"
                      viewBox="0 0 12 12"
                    >
                      <path
                        fill="#FAC51D"
                        d="M6 0a6 6 0 1 1 0 12A6 6 0 0 1 6 0zm.5 5.5h-1A.5.5 0 0 0 5 6v3.5a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5V6a.5.5 0 0 0-.5-.5zM6 2a1.25 1.25 0 1 0 0 2.5A1.25 1.25 0 0 0 6 2z"
                      />
                    </svg>
                  </Tippy>
                </p>
                <AcceptProposalForm
                  bookingDetails={
                    <div
                      className={classNames(css.bookingDetailsMobile, css.bookingDetailsProposal)}
                    >
                      <AddressLinkMaybe
                        rootClassName={css.addressMobile}
                        location={location}
                        geolocation={geolocation}
                        showAddress={stateData.showAddress}
                      />
                      <BreakdownMaybe
                        bookingTransaction={transaction}
                        transaction={paymentTransaction}
                        transactionRole={transactionRole}
                        timezone={timezone}
                      />
                    </div>
                  }
                  currentUser={ensuredCurrentUser}
                  timezone={timezone}
                  duration={duration}
                  actionButtonsClassName={css.actionButtons}
                  errorsClassName={css.actionErrors}
                  actionButtonWrapperClassName={css.actionButtonWrapper}
                  acceptInProgress={acceptInProgress}
                  declineInProgress={declineInProgress}
                  acceptProposalError={acceptSaleError}
                  declineProposalError={declineSaleError}
                  onAcceptProposal={({ selectedMeetingTime, exposeEmail: exposeEmailValues }) => {
                    const { quantity } = currentTransaction.attributes.protectedData;
                    const exposeEmail =
                      Array.isArray(exposeEmailValues) && exposeEmailValues.length
                        ? exposeEmailValues[0]
                        : null;

                    const timeSlot = config.custom.timeSlotDurations[quantity - 1];

                    const startTime = selectedMeetingTime;
                    const endTime = moment
                      .tz(startTime, timezone)
                      .add(timeSlot, 'minutes')
                      .toISOString();

                    onTrackEvent({
                      category: 'Propose meeting',
                      action: 'Submit',
                      label: 'Accept proposal',
                    });

                    onBookAfterProposal(
                      transactionId,
                      currentListing.id,
                      ensuredCurrentUser,
                      startTime,
                      endTime,
                      exposeEmail
                    );
                  }}
                  onDeclineProposal={() => {
                    onTrackEvent({
                      category: 'Propose meeting',
                      action: 'Submit',
                      label: 'Decline proposal',
                    });

                    onDeclineSale(transactionId, ensuredCurrentUser);
                  }}
                  suggestedTimes={currentTransaction.attributes.protectedData.suggestedTimes}
                />
              </div>
            )}
            {stateData.showSaleButtons && !sendMessageFormFocused ? (
              <div className={css.mobileActionButtons}>{saleButtons}</div>
            ) : null}
            {stateData.showSaleButtons ? (
              <>
                <div className={css.desktopActionButtons}>{saleButtons}</div>
                <ExposeEmailForm
                  currentUser={ensuredCurrentUser}
                  isChecked={!!this.state.exposeEmail}
                  onToggle={this.handleToggleExposeEmail}
                />
              </>
            ) : null}
          </div>

          <div className={css.asideDesktop}>
            <div className={css.detailCard}>
              <DetailCardProfile
                avatarWrapperClassName={css.avatarWrapperDesktop}
                listingTitle={listingTitle}
                image={firstImage}
                provider={currentProvider}
                customer={currentCustomer}
                isCustomer={isCustomer}
              />
              {showPanelButtons ? <div className={css.panelButtons}>{panelButtons}</div> : null}
              <BreakdownMaybe
                bookingTransaction={currentTransaction}
                className={css.breakdownContainer}
                event={event}
                nonprofit={nonprofit}
                transaction={paymentTransaction}
                transactionRole={transactionRole}
                timezone={timezone}
              />
            </div>
          </div>
        </div>
        <ReviewModal
          id="ReviewOrderModal"
          isOpen={this.state.isReviewModalOpen}
          onCloseModal={() => this.setState({ isReviewModalOpen: false })}
          onManageDisableScrolling={onManageDisableScrolling}
          onSubmitReview={this.onSubmitReview}
          revieweeName={otherUserName}
          reviewSent={this.state.reviewSubmitted}
          sendReviewInProgress={sendReviewInProgress}
          sendReviewError={sendReviewError}
        />
        {stateData.showCancelButton ? (
          <Modal
            id={'cancelBookingModal'}
            containerClassName={css.cancelBookingModalContainer}
            contentClassName={css.cancelBookingmodalContent}
            isOpen={this.state.isCancelBookingModalOpen}
            onClose={this.onCloseCancelBookingModal}
            onManageDisableScrolling={onManageDisableScrolling}
            closeButtonMessage={'Close'}
          >
            <p className={css.modalTitle}>
              <FormattedMessage
                id="TransactionPanel.cancelBookingModalTitle"
                values={{ name: otherUserName }}
              />
            </p>
            <p className={css.modalMessage}>
              {isCustomer ? (
                <FormattedMessage
                  id="TransactionPanel.cancelBookingModalCustomerDescription"
                  values={{ name: otherUserName }}
                />
              ) : (
                <FormattedMessage
                  id="TransactionPanel.cancelBookingModalProviderDescription"
                  values={{ name: otherUserName }}
                />
              )}
            </p>
            {cancelBookingError ? (
              <p className={css.modalError}>
                <FormattedMessage id="TransactionPanel.cancelBookingError" />
              </p>
            ) : null}

            <div className={classNames(css.actionButtonWrapper, css.modalButtonWrapper)}>
              <SecondaryButton onClick={() => this.onCloseCancelBookingModal()}>
                <FormattedMessage id="TransactionPanel.cancelBookingModalNotNow" />
              </SecondaryButton>
              <PrimaryButton
                inProgress={cancelBookingInProgress}
                onClick={this.onCancelBookingExecute}
              >
                <FormattedMessage id="TransactionPanel.cancelBookingModalCancelMeeting" />
              </PrimaryButton>
            </div>
          </Modal>
        ) : null}
      </div>
    );
  }
}

TransactionPanelComponent.defaultProps = {
  rootClassName: null,
  className: null,
  currentUser: null,
  acceptSaleError: null,
  declineSaleError: null,
  cancelBookingError: null,
  fetchMessagesError: null,
  initialMessageFailed: false,
  savePaymentMethodFailed: false,
  sendMessageError: null,
  sendReviewError: null,
  monthlyTimeSlots: null,
  nextTransitions: null,
  downloadIcs: false,
};

TransactionPanelComponent.propTypes = {
  rootClassName: string,
  className: string,

  history: shape({
    push: func.isRequired,
  }).isRequired,
  getMessage: func.isRequired,
  currentUser: propTypes.currentUser,
  event: propTypes.event,
  nonprofit: propTypes.listing,
  transaction: propTypes.transaction.isRequired,
  totalMessagePages: number.isRequired,
  oldestMessagePageFetched: number.isRequired,
  messages: arrayOf(propTypes.message).isRequired,
  initialMessageFailed: bool,
  savePaymentMethodFailed: bool,
  fetchMessagesInProgress: bool.isRequired,
  fetchMessagesError: propTypes.error,
  sendMessageInProgress: bool.isRequired,
  sendMessageError: propTypes.error,
  sendReviewInProgress: bool.isRequired,
  sendReviewError: propTypes.error,
  onFetchTimeSlots: func.isRequired,
  onManageDisableScrolling: func.isRequired,
  onShowMoreMessages: func.isRequired,
  onSendMessage: func.isRequired,
  onSendReview: func.isRequired,
  onSubmitBookingRequest: func.isRequired,
  monthlyTimeSlots: object,
  nextTransitions: array,
  downloadIcs: bool,

  // Sale related props
  onBookAfterProposal: func.isRequired,
  onAcceptSale: func.isRequired,
  onAcceptSaleLegacy: func.isRequired,
  onDeclineSale: func.isRequired,
  onDeclineSaleLegacy: func.isRequired,
  onCancelBooking: func.isRequired,
  onTrackEvent: func.isRequired,
  acceptInProgress: bool.isRequired,
  declineInProgress: bool.isRequired,
  cancelBookingInProgress: bool.isRequired,
  acceptSaleError: propTypes.error,
  declineSaleError: propTypes.error,
  cancelBookingError: propTypes.error,

  // from injectIntl
  intl: intlShape,
  timezone: string.isRequired,
};

const TransactionPanel = withMessages(injectIntl(TransactionPanelComponent), 'TransactionPanel');

export default TransactionPanel;
