import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import css from './AnchorTitle.css';

const AnchorTitle = ({ children, id, className = '' }) => {
  const verifiedId = useMemo(
    () =>
      id ||
      (typeof children === 'string' &&
        encodeURIComponent(
          children
            .replaceAll(/([0-9]+)|([!@#$%^&*(),.?":{}|<>’])|(–\s)/g, '')
            .trim()
            .replaceAll(/\s/g, '-')
            .toLocaleLowerCase()
        )),
    [id, children]
  );

  return (
    <h2 className={[css.anchorTitle, className].join(' ')} id={verifiedId}>
      {children} <a href={`#${verifiedId}`}>#</a>
    </h2>
  );
};

AnchorTitle.propTypes = {
  id: PropTypes.string,
  className: PropTypes.string,
  children: PropTypes.string,
};

export default AnchorTitle;
