import React from 'react';
import css from './PartnershipsPage.css';
import { Button } from '../../components';
import { useMessages } from '../../util/localization';

const PartnershipDisclaimer = ({ setDisclaimerVisible }) => {
  const getMessage = useMessages('PartnershipsPage');
  return (
    <div>
      <h1>{getMessage('disclaimer.title')}</h1>
      <p style={{ whiteSpace: 'pre-line' }}>{getMessage('disclaimer.text')}</p>
      <Button className={css.disclaimerButton} onClick={() => setDisclaimerVisible(false)}>
        {getMessage('disclaimer.button')}
      </Button>
    </div>
  );
};

export default PartnershipDisclaimer;
