import React, { Component } from 'react';
import { array, bool, func, oneOf, object, shape, string } from 'prop-types';
import { injectIntl } from '../../util/reactIntl';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import classNames from 'classnames';
import config from '../../config';
import { parse, stringify } from '../../util/urlHelpers';
import { propTypes } from '../../util/types';
import { getListingsById } from '../../ducks/marketplaceData.duck';
import { manageDisableScrolling, isScrollingDisabled } from '../../ducks/UI.duck';
import {
  Footer,
  LayoutWrapperFooter,
  LayoutWrapperMain,
  LayoutWrapperTopbar,
  Page,
  SoftWall,
} from '../../components';
import { TopbarContainer } from '../../containers';

import { loadPeopleSearchData, setActiveListing } from './SearchPage.duck';
import {
  pickSearchParamsOnly,
  validURLParamsForExtendedData,
  createSearchResultSchema,
} from './SearchPage.helpers';
import MainPanel from './MainPanel';
import css from './SearchPage.css';
import {
  FILTER_AVAILABILITY,
  FILTER_AVAILABILITY_EVENT,
  FILTER_EVENT_ROLES,
  FILTER_INDUSTRY,
  FILTER_INTERESTS,
  FILTER_LOCATION,
  FILTER_TYPE_MENU,
} from '../../components/SearchFilters/SearchFilters';
import { fetchCurrentUser } from '../../ducks/user.duck';
import moment from 'moment';
import { getEvent } from '../../util/events';
import {
  canCloseModal,
  canRenderModal,
  getVariant,
  increasePageCount,
  VARIANT_DEFAULT,
} from '../../util/softWall';
import { trackEventAction } from '../../ducks/Analytics.duck';

// Pagination page size might need to be dynamic on responsive page layouts
// Current design has max 3 columns 12 is divisible by 2 and 3
// So, there's enough cards to fill all columns on full pagination pages
// const RESULT_PAGE_SIZE = 24;
const MODAL_BREAKPOINT = 768; // Search is in modal on mobile layout

export class SearchPageComponent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      currentPage: 1,
      isMobileModalOpen: false,
      softWall: {
        isOpen: false,
        pageCount: 0,
        trackingLabel: 'Variant0',
      },
      softWallVariant: VARIANT_DEFAULT,
    };

    this.filters = this.filters.bind(this);
    this.handleCloseSoftWall = this.handleCloseSoftWall.bind(this);
    this.handleUnload = this.handleUnload.bind(this);
    this.onOpenMobileModal = this.onOpenMobileModal.bind(this);
    this.onCloseMobileModal = this.onCloseMobileModal.bind(this);
  }

  /**
   * Handles the soft wall popping up
   *
   * @param props
   * @param state
   * @returns {null|{softWall: {pageCount: *}, currentPage: number}}
   */
  static getDerivedStateFromProps(props, state) {
    // Current page check is needed since otherwise the state change when closing the modal would
    // open it directly again.
    const { isAuthenticated, location, onTrackEvent, pagination } = props;
    const currentPage = pagination && pagination.page ? pagination.page : 1;

    // To ensure that the pagination object is up to date it must be compared to the page parameter
    // inside the query string. This prevents the modal from showing up on excluded pages when
    // browsing back to the search results after it the modal was initially visible.
    const queryString = parse(location.search);

    if (
      !isAuthenticated &&
      !state.softWall.isOpen &&
      currentPage !== 1 &&
      currentPage !== state.currentPage &&
      (queryString.page || 1) === currentPage
    ) {
      const pageCount = increasePageCount(currentPage);
      const trackingLabel = `Variant${getVariant(pageCount)}`;

      // Keep track of when the modal is fired up
      onTrackEvent({
        category: 'SoftWall',
        action: 'Show',
        label: trackingLabel,
      });

      // Populate the updated state
      return {
        currentPage,
        softWall: {
          isOpen: canRenderModal(currentPage, pageCount),
          pageCount,
          trackingLabel,
        },
      };
    } else if (
      currentPage === 1 &&
      currentPage !== state.currentPage &&
      (queryString.page || 1) === currentPage
    ) {
      // Close the modal if it's open on the first page. This prevents the modal from showing up
      // if the user browses back.
      return {
        currentPage,
        softWall: {
          isOpen: false,
        },
      };
    }
    return null;
  }

  /**
   * Mount events
   */
  componentDidMount() {
    window.addEventListener('beforeunload', this.handleUnload);
  }

  /**
   * Unmount events
   */
  componentWillUnmount() {
    window.removeEventListener('beforeunload', this.handleUnload);
  }

  /**
   * Unload handling, only applicable when the soft wall modal is visible. Used to track escaping
   * users.
   */
  handleUnload = () => {
    if (this.state.softWall.isOpen) {
      const { onTrackEvent } = this.props;
      onTrackEvent({
        category: 'SoftWall',
        action: 'Escape',
        label: this.state.softWall.trackingLabel,
      });
    }
  };

  get event() {
    if (!this.props.params.eventKey) return null;

    return getEvent(this.props.params.eventKey);
  }

  filters() {
    const { industries, interests } = this.props;

    industries.sort();
    interests.sort((a, b) => (a.label > b.label ? 1 : -1));

    return {
      industryFilter: {
        paramName: 'pub_industry',
        options: industries.map((i) => ({ key: i, label: i })),
      },
      interestsFilter: {
        paramName: 'pub_interests',
        options: interests,
      },
      eventRoles: {
        paramName: 'pub_eventRoles',
        options: this.event ? this.event.userRoles : [],
      },
    };
  }

  /**
   * Handles closing of the soft wall modal, also verifies the modal can actually be closed.
   */
  handleCloseSoftWall() {
    if (canCloseModal(this.state.currentPage, this.state.softWall.pageCount)) {
      this.setState({
        softWall: {
          isOpen: false,
        },
      });
    }
  }

  // Invoked when a modal is opened from a child component,
  // for example when a filter modal is opened in mobile view
  onOpenMobileModal() {
    this.setState({ isMobileModalOpen: true });
  }

  // Invoked when a modal is closed from a child component,
  // for example when a filter modal is opened in mobile view
  onCloseMobileModal() {
    this.setState({ isMobileModalOpen: false });
  }

  get showOnlyFilters() {
    if (this.event) {
      return [FILTER_EVENT_ROLES, FILTER_INTERESTS, FILTER_AVAILABILITY_EVENT];
    }

    return [
      FILTER_TYPE_MENU,
      FILTER_LOCATION,
      FILTER_AVAILABILITY,
      FILTER_INDUSTRY,
      FILTER_INTERESTS,
    ];
  }

  render() {
    const {
      history,
      intl,
      listings,
      location,
      onManageDisableScrolling,
      onTrackEvent,
      pagination,
      scrollingDisabled,
      searchInProgress,
      searchListingsError,
      searchParams,
      onActivateListing,
      npoListingNames,
      timezone,
    } = this.props;
    // eslint-disable-next-line no-unused-vars
    const { page, ...searchInURL } = parse(location.search);
    const filters = this.filters();

    // urlQueryParams doesn't contain page specific url params
    // like mapSearch, page or origin (origin depends on config.sortSearchByDistance)
    const urlQueryParams = pickSearchParamsOnly(searchInURL, filters);

    // Page transition might initially use values from previous search
    const urlQueryString = stringify(urlQueryParams);
    const paramsQueryString = stringify(pickSearchParamsOnly(searchParams, filters));
    const searchParamsAreInSync = urlQueryString === paramsQueryString;

    const validQueryParams = validURLParamsForExtendedData(searchInURL, filters);

    const { address } = searchInURL || {};
    const { title, description, schema } = createSearchResultSchema(listings, address, intl);

    // Set topbar class based on if a modal is open in
    // a child component
    const topbarClasses = this.state.isMobileModalOpen
      ? classNames(css.topbarBehindModal, css.topbar)
      : css.topbar;

    // N.B. openMobileMap button is sticky.
    // For some reason, stickyness doesn't work on Safari, if the element is <button>
    /* eslint-disable jsx-a11y/no-static-element-interactions */
    return (
      <Page
        scrollingDisabled={scrollingDisabled}
        description={description}
        title={title}
        schema={schema}
      >
        <LayoutWrapperTopbar>
          <TopbarContainer
            className={topbarClasses}
            currentPage="SearchPage"
            currentSearchParams={urlQueryParams}
          />
        </LayoutWrapperTopbar>
        <LayoutWrapperMain className={css.container}>
          <MainPanel
            event={this.event}
            history={history}
            urlQueryParams={validQueryParams}
            listings={listings}
            searchInProgress={searchInProgress}
            searchListingsError={searchListingsError}
            searchParamsAreInSync={searchParamsAreInSync}
            onActivateListing={onActivateListing}
            onManageDisableScrolling={onManageDisableScrolling}
            onOpenModal={this.onOpenMobileModal}
            onCloseModal={this.onCloseMobileModal}
            pagination={pagination}
            searchParamsForPagination={parse(location.search)}
            showAsModalMaxWidth={MODAL_BREAKPOINT}
            showOnlySelectedFilters={this.showOnlyFilters}
            primaryFilters={{
              industryFilter: filters.industryFilter,
              interestsFilter: filters.interestsFilter,
              eventRolesFilter: filters.eventRoles,
            }}
            npoListingNames={npoListingNames}
            pageName={this.event ? 'EventAttendeesPage' : 'SearchPage'}
            pathParams={this.event ? { eventKey: this.event.key } : {}}
            timezone={timezone}
          />
          <SoftWall
            canClose={canCloseModal(this.state.currentPage, this.state.softWall.pageCount)}
            isOpen={this.state.softWall.isOpen}
            onClose={this.handleCloseSoftWall}
            onManageDisableScrolling={onManageDisableScrolling}
            onTrackEvent={onTrackEvent}
            trackingLabel={this.state.softWall.trackingLabel}
            variant={getVariant(this.state.softWall.pageCount)}
          />
        </LayoutWrapperMain>
        <LayoutWrapperFooter>
          <Footer />
        </LayoutWrapperFooter>
      </Page>
    );
    /* eslint-enable jsx-a11y/no-static-element-interactions */
  }
}

SearchPageComponent.defaultProps = {
  isAuthenticated: false,
  listings: [],
  pagination: null,
  searchListingsError: null,
  searchParams: {},
  tab: 'listings',
  industries: config.custom.industries,
  interests: config.custom.interests,
  activeListingId: null,
  timezone: moment.tz.guess(),
};

SearchPageComponent.propTypes = {
  isAuthenticated: bool,
  listings: array,
  onActivateListing: func.isRequired,
  onManageDisableScrolling: func.isRequired,
  pagination: propTypes.pagination,
  scrollingDisabled: bool.isRequired,
  searchInProgress: bool.isRequired,
  searchListingsError: propTypes.error,
  searchParams: object,
  tab: oneOf(['filters', 'listings']).isRequired,
  industries: array,
  interests: array,
  getEvent: func.isRequired,
  timezone: string.isRequired,

  // from withRouter
  history: shape({
    push: func.isRequired,
  }).isRequired,
  location: shape({
    search: string.isRequired,
  }).isRequired,
  params: object.isRequired,
  onTrackEvent: func.isRequired,
};

const mapStateToProps = (state) => {
  const { isAuthenticated } = state.Auth;
  const {
    currentPageResultIds,
    pagination,
    searchInProgress,
    searchListingsError,
    searchParams,
    activeListingId,
  } = state.SearchPage;
  const pageListings = getListingsById(state, currentPageResultIds);

  const { npoListingNames } = state.Listings;

  const { timezone } = state.user;

  const { events } = state.Events;

  const getEvent = (eventKey) => events.find((event) => event.key === eventKey);

  return {
    getEvent,
    isAuthenticated,
    listings: pageListings,
    pagination,
    scrollingDisabled: isScrollingDisabled(state),
    searchInProgress,
    searchListingsError,
    searchParams,
    activeListingId,
    npoListingNames,
    timezone,
  };
};

const mapDispatchToProps = (dispatch) => ({
  onManageDisableScrolling: (componentId, disableScrolling) =>
    dispatch(manageDisableScrolling(componentId, disableScrolling)),
  onActivateListing: (listingId) => dispatch(setActiveListing(listingId)),
  onTrackEvent: (params) => dispatch(trackEventAction(params)),
});

// Note: it is important that the withRouter HOC is **outside** the
// connect HOC, otherwise React Router won't rerender any Route
// components since connect implements a shouldComponentUpdate
// lifecycle hook.
//
// See: https://github.com/ReactTraining/react-router/issues/4671
const SearchPage = compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  injectIntl
)(SearchPageComponent);

SearchPage.loadData = (params, search) => async (dispatch) => {
  const additionalParams = {};

  if (params.eventKey) {
    // Is event attendees page
    const dispatchedEvent = getEvent(params.eventKey);

    const roles = dispatchedEvent.userRoles
      ? dispatchedEvent.userRoles.map((role) => role.key)
      : [];

    additionalParams['pub_eventRoles'] = `has_any:${roles.join(',')}`;
  }

  dispatch(fetchCurrentUser());
  return dispatch(loadPeopleSearchData(params, search, additionalParams));
};

export default SearchPage;
