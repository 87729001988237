import React, { Component } from 'react';
import { FieldSelect, FilterPlain } from '../index';
import { arrayOf, bool, func, node, shape, string } from 'prop-types';
import classNames from 'classnames';

import css from './MultiSelectFilter.css';

class MultiSelectFilter extends Component {
  render() {
    const {
      className,
      defaultOptionKey,
      defaultOptionLabel,
      defaultOptionValue,
      id,
      initialValue,
      options,
      label,
      name,
      onSelect,
      rootClassName,
      selectClassName,
      showAsPopup,
      showDefaultOption,
      urlParam,
      ...rest
    } = this.props;
    const classes = classNames(rootClassName, css.root, className);
    const hasInitialValue = !!initialValue;

    const selectOptions = options.map((option) => (
      <option key={option.key} value={option.key}>
        {option.label}
      </option>
    ));
    const defaultOption = showDefaultOption ? (
      <option key={defaultOptionKey || defaultOptionValue} value={defaultOptionValue}>
        {defaultOptionLabel}
      </option>
    ) : null;

    // Uncontrolled input needs to be cleared through the reference to DOM element.
    const handleClear = () => {
      if (this.mobileInputRef && this.mobileInputRef.current) {
        this.mobileInputRef.current.value = '';
      }
    };

    return (
      <FilterPlain
        className={classes}
        rootClassName={rootClassName}
        label={label}
        isSelected={hasInitialValue}
        id={`${id}.plain`}
        liveEdit
        onSubmit={onSelect}
        onClear={handleClear}
        initialValues={{
          [name]: initialValue,
        }}
        urlParam={urlParam}
        {...rest}
      >
        <label>{label}</label>
        <FieldSelect name={name} id={`${id}.select`} selectClassName={selectClassName}>
          {defaultOption}
          {selectOptions}
        </FieldSelect>
      </FilterPlain>
    );
  }
}

MultiSelectFilter.defaultProps = {
  className: null,
  defaultOptionKey: null,
  defaultOptionLabel: null,
  defaultOptionValue: null,
  initialValue: null,
  options: [],
  rootClassName: null,
  selectClassName: null,
  showAsPopup: false,
  showDefaultOption: false,
};

MultiSelectFilter.propTypes = {
  className: string,
  defaultOptionKey: string,
  defaultOptionLabel: string,
  defaultOptionValue: string,
  id: string.isRequired,
  initialValue: string,
  label: string,
  name: string.isRequired,
  onSelect: func,
  options: arrayOf(
    shape({
      key: string.isRequired,
      label: node.isRequired,
    })
  ).isRequired,
  rootClassName: string,
  selectClassName: string,
  showAsPopup: bool,
  showDefaultOption: bool,
  urlParam: string.isRequired,
};

export default MultiSelectFilter;
