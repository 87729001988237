import { getMarketplaceEntities } from '../ducks/marketplaceData.duck';
import { types as sdkTypes } from '../util/sdkLoader';
const { UUID } = sdkTypes;

export const getListing = (id, state) => {
  const ref = {
    id: new UUID(id),
    type: 'listing',
  };
  const listings = getMarketplaceEntities(state, [ref]);
  return listings.length === 1 ? listings[0] : null;
};
