import React, { Component } from 'react';
import { array, bool, func, number, shape, string } from 'prop-types';
import { Field } from 'react-final-form';
import classNames from 'classnames';
import { ValidationError } from '..';

import css from './FieldAutoCompleteInput.css';

const DIRECTION_UP = 'up';
const DIRECTION_DOWN = 'down';
const TOUCH_TAP_RADIUS = 5; // Movement within 5px from touch start is considered a tap

const getTouchCoordinates = (nativeEvent) => {
  const touch = nativeEvent && nativeEvent.changedTouches ? nativeEvent.changedTouches[0] : null;
  return touch ? { x: touch.screenX, y: touch.screenY } : null;
};

const PredictionList = ({
  suggestionClassname,
  rootClassName,
  className,
  suggestions,
  highlightedIndex,
  renderSuggestion,
  onSelectStart,
  onSelectMove,
  onSelectEnd,
}) => {
  const item = (suggestion, index) => {
    const isHighlighted = index === highlightedIndex;

    return (
      <li
        className={classNames(
          suggestionClassname || css.suggestion,
          isHighlighted ? css.highlighted : null
        )}
        key={index}
        onTouchStart={(e) => {
          e.preventDefault();
          onSelectStart(getTouchCoordinates(e.nativeEvent));
        }}
        onMouseDown={(e) => {
          e.preventDefault();
          onSelectStart();
        }}
        onTouchMove={(e) => {
          e.preventDefault();
          onSelectMove(getTouchCoordinates(e.nativeEvent));
        }}
        onTouchEnd={(e) => {
          e.preventDefault();
          onSelectEnd(suggestion);
        }}
        onMouseUp={(e) => {
          e.preventDefault();
          onSelectEnd(suggestion);
        }}
      >
        {renderSuggestion(suggestion)}
      </li>
    );
  };

  return (
    <div className={classNames(rootClassName || css.suggestionsRoot, className)}>
      <ul className={css.suggestions}>{suggestions.map(item)}</ul>
    </div>
  );
};

PredictionList.defaultProps = {
  renderSuggestion: (x) => x,
};

PredictionList.propTypes = {
  suggestionClassname: string,
  rootClass: string,
  className: string,
  highlightedIndex: number.isRequired,
  suggestions: array.isRequired,
  renderSuggestion: func,
  onSelectEnd: func.isRequired,
  onSelectMove: func.isRequired,
  onSelectStart: func.isRequired,
};

class FieldAutoCompleteInputComponent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      highlightedIndex: -1,
      inputHasFocus: false,
      isSwipe: false,
      selectionInProgress: false,
      touchStartedFrom: null,
    };

    this.handleBlur = this.handleBlur.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleFocus = this.handleFocus.bind(this);
    this.handleKeyDown = this.handleKeyDown.bind(this);
    this.handleSuggestionSelectEnd = this.handleSuggestionSelectEnd.bind(this);
    this.handleSuggestionSelectMove = this.handleSuggestionSelectMove.bind(this);
    this.handleSuggestionSelectStart = this.handleSuggestionSelectStart.bind(this);
  }

  // Change the currently highlighted item by calculating the new
  // index from the current state and the given direction number
  // (DIRECTION_UP or DIRECTION_DOWN)
  changeHighlight(direction) {
    this.setState((prevState) => {
      const { highlightedIndex } = prevState;

      if (direction === DIRECTION_UP) {
        return {
          highlightedIndex: Math.max(0, highlightedIndex - 1),
        };
      } else if (direction === DIRECTION_DOWN) {
        return {
          highlightedIndex: Math.min(this.props.suggestions.length - 1, highlightedIndex + 1),
        };
      }
    });
  }

  handleBlur() {
    this.setState({ inputHasFocus: false });
  }

  handleChange(e) {
    const onChange = this.props.input.onChange;

    const newValue = e.target.value;

    // Clear the current values since the input content is changed
    onChange(newValue);

    if (typeof this.props.onInputChange === 'function') {
      this.props.onInputChange(newValue);
    }
  }

  handleFocus() {
    this.setState({ inputHasFocus: true });
  }

  handleKeyDown(e) {
    const key = e.key;

    if (key === 'ArrowDown') {
      e.preventDefault();

      this.changeHighlight(DIRECTION_DOWN);
    } else if (key === 'ArrowUp') {
      e.preventDefault();

      this.changeHighlight(DIRECTION_UP);
    } else if (e.key === 'Enter') {
      e.preventDefault();

      if (this.state.highlightedIndex > -1 && this.props.suggestions[this.state.highlightedIndex]) {
        this.props.input.onChange(this.props.suggestions[this.state.highlightedIndex]);
      }

      this.input.blur();
    } else if (e.key === 'Escape') {
      e.preventDefault();

      this.input.blur();
    }
  }

  handleSuggestionSelectStart(touchCoordinates) {
    this.setState({
      selectionInProgress: true,
      touchStartedFrom: touchCoordinates,
      isSwipe: false,
    });
  }

  handleSuggestionSelectMove(touchCoordinates) {
    this.setState((prevState) => {
      const touchStartedFrom = prevState.touchStartedFrom;
      const isTouchAction = !!touchStartedFrom;
      const isSwipe = isTouchAction
        ? Math.abs(touchStartedFrom.y - touchCoordinates.y) > TOUCH_TAP_RADIUS
        : false;

      return { selectionInProgress: false, isSwipe };
    });
  }

  handleSuggestionSelectEnd(suggestion) {
    let selectAndFinalize = false;
    this.setState(
      (prevState) => {
        if (!prevState.isSwipe) {
          selectAndFinalize = true;
        }
        return { selectionInProgress: false, touchStartedFrom: null, isSwipe: false };
      },
      () => {
        if (selectAndFinalize) {
          if (selectAndFinalize) {
            this.props.input.onChange(suggestion);
            this.setState({ inputHasFocus: false, highlightedIndex: -1 });
          }
        }
      }
    );
  }

  render() {
    const {
      autoFocus,
      className,
      input,
      inputClassName,
      inputRef,
      label,
      labelClassName,
      meta,
      placeholder,
      rootClassName,
      suggestionsClassName,
      validClassName,
    } = this.props;

    const { name } = input;

    const { touched, valid } = meta || {};
    const isValid = valid && touched;

    const rootClass = classNames(rootClassName || css.root, className);
    const inputClass = classNames(inputClassName || css.input, { [validClassName]: isValid });

    // Only render suggestions when the input has focus. For
    // development and easier workflow with the browser devtools, you
    // might want to hardcode this to `true`. Otherwise the dropdown
    // list will disappear.
    const renderSuggestions = this.state.inputHasFocus && this.props.suggestions.length > 0;

    return (
      <div className={rootClass}>
        {label ? (
          <label className={labelClassName} htmlFor={input.name}>
            {label}
          </label>
        ) : null}

        <input
          className={inputClass}
          type="search"
          autoComplete="off"
          autoFocus={autoFocus}
          placeholder={placeholder}
          name={name}
          value={input.value}
          onFocus={this.handleFocus}
          onBlur={this.handleBlur}
          onChange={this.handleChange}
          onKeyDown={this.handleKeyDown}
          ref={(node) => {
            this.input = node;
            if (inputRef) {
              inputRef(node);
            }
          }}
        />
        {renderSuggestions ? (
          <PredictionList
            rootClassName={suggestionsClassName}
            suggestions={this.props.suggestions}
            highlightedIndex={this.state.highlightedIndex}
            onSelectStart={this.handleSuggestionSelectStart}
            onSelectMove={this.handleSuggestionSelectMove}
            onSelectEnd={this.handleSuggestionSelectEnd}
          />
        ) : null}
        <ValidationError fieldMeta={meta} />
      </div>
    );
  }
}

FieldAutoCompleteInputComponent.defaultProps = {
  suggestions: [],
  rootClassName: null,
  className: null,
  inputRootClass: null,
  onUnmount: null,
  customErrorText: null,
  id: null,
  label: null,
  labelSuffix: null,
  isUncontrolled: false,
  inputRef: null,
};

FieldAutoCompleteInputComponent.propTypes = {
  autoFocus: bool,
  className: string,
  input: shape({
    name: string.isRequired,
    value: string,
    onChange: func.isRequired,
    onFocus: func.isRequired,
    onBlur: func.isRequired,
  }),
  inputClassName: string,
  inputRef: func,
  label: string,
  labelClassName: string,
  meta: shape({
    valid: bool.isRequired,
    touched: bool.isRequired,
  }),
  placeholder: string,
  rootClassName: string,
  suggestionsClassName: string,
  validClassName: string,
};

class FieldAutoCompleteInput extends Component {
  componentWillUnmount() {
    // Unmounting happens too late if it is done inside Field component
    // (Then Form has already registered its (new) fields and
    // changing the value without corresponding field is prohibited in Final Form
    if (this.props.onUnmount) {
      this.props.onUnmount();
    }
  }

  render() {
    return <Field component={FieldAutoCompleteInputComponent} {...this.props} />;
  }
}

export default FieldAutoCompleteInput;
