import React from 'react';
import { NamedLink, AvatarMega, AvatarMedium } from '../../../../components';

import css from '../NonprofitProfile.css';

const NonprofitAvatar = (props) => {
  const { user, params } = props;
  return (
    <div className={css.nonprofitAvatar}>
      <NamedLink name="ListingPage" params={params} to={{ hash: '#host' }}>
        <AvatarMega user={user} className={css.avatarDesktop} />
      </NamedLink>
      <NamedLink name="ListingPage" params={params} to={{ hash: '#host' }}>
        <AvatarMedium user={user} className={css.avatarMobile} />
      </NamedLink>
    </div>
  );
};

export default NonprofitAvatar;
