import React, { Component } from 'react';
import css from '../OnboardingPage/OnboardingPage.css';
import LayoutSideNavigation from '../../components/LayoutSideNavigation/LayoutSideNavigation';
import {
  Footer,
  LayoutWrapperFooter,
  LayoutWrapperMain,
  LayoutWrapperOnboardingSideNav,
  LayoutWrapperTopbar,
  OnboardingWizard,
  Page,
} from '../../components';
import { TopbarContainer } from '../index';
import { injectIntl } from 'react-intl';
import { bool, func, object, shape, string } from 'prop-types';
import { propTypes } from '../../util/types';
import { intlShape } from '../../util/reactIntl';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { ensureCurrentUser, ensureOwnListing, getSelectedNonprofits } from '../../util/data';
import { updateProfile, uploadImage } from '../../ducks/UserProfile.duck';
import { withRouter } from 'react-router-dom';
import { requestPublishListingDraft, requestUpdateListing } from '../../ducks/UserListing.duck';
import { fetchCurrentUser } from '../../ducks/user.duck';
import { fetchSelectedNonprofits, queryNonProfits } from '../../ducks/NonprofitListing.duck';
import { getListingsById } from '../../ducks/marketplaceData.duck';
import { isScrollingDisabled, manageDisableScrolling } from '../../ducks/UI.duck';
import { createResourceLocatorString } from '../../util/routes';
import routeConfiguration from '../../routeConfiguration';
import { trackEventAction } from '../../ducks/Analytics.duck';
import { createOwnListing, showOwnListing } from '../../ducks/ownListing.duck';
import { SUPPORTED_NONPROFITS } from '../../components/OnboardingWizard/constants';
import { NONPROFIT_INFO } from '../../components/MyNonprofitWizard/constants';

const MODAL_BREAKPOINT = 768;

export class OnboardingPageComponent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isMobileModalOpen: false,
    };

    this.trackEvent = this.trackEvent.bind(this);
    this.pageCloseEventListener = this.pageCloseEventListener.bind(this);
    this.currentLocationEventListener = this.currentLocationEventListener.bind(this);
    this.onOpenMobileModal = this.onOpenMobileModal.bind(this);
    this.onCloseMobileModal = this.onCloseMobileModal.bind(this);
    this.currentLocation = window.location.href;
  }

  componentDidMount() {
    // Track event if user closes the browser or moves to the another website
    window.addEventListener('beforeunload', this.pageCloseEventListener);
    window.addEventListener('locationchange', this.currentLocationEventListener);
  }

  componentWillUnmount() {
    window.removeEventListener('beforeunload', this.pageCloseEventListener);
    window.removeEventListener('locationchange', this.currentLocationEventListener);

    // Track event if user changes the page within the app
    this.pageCloseEventListener();
  }

  pageCloseEventListener() {
    this.trackEvent({
      action: 'OnboardingExit',
      label: this.currentLocation,
    });
  }

  currentLocationEventListener() {
    this.currentLocation = window.location.href;
  }

  trackEvent(payload) {
    this.props.trackEvent({
      category: 'Onboarding',
      ...payload,
    });
  }

  onOpenMobileModal() {
    this.setState({ isMobileModalOpen: true });
  }

  onCloseMobileModal() {
    this.setState({ isMobileModalOpen: false });
  }

  render() {
    const {
      currentUser,
      currentUserListing,
      history,
      intl,
      nonprofitListings,
      nonprofitListingsError,
      nonprofitListingsInProgress,
      nonprofitPagination,
      onManageDisableScrolling,
      onImageUpload,
      onPublishListing,
      onUpdateListing,
      onUpdateProfile,
      params,
      profileImage,
      scrollingDisabled,
      supportedNonprofits,
      uploadProfileImageError,
      uploadProfileImageInProgress,
      updateProfileInProgress,
      updateProfileProfileError,
      updateListingError,
      updateListingInProgress,
    } = this.props;

    const user = ensureCurrentUser(currentUser);

    // Prevent NPO users from entering the regular onboarding process
    if (user.id && user.attributes.profile.publicData.isNPO) {
      history.push(
        createResourceLocatorString('MyNonprofitPage', routeConfiguration(), {
          tab: NONPROFIT_INFO,
        })
      );
    }

    // Require the user to verify their e-mail first
    if (user.id && user.attributes.emailVerified === false) {
      history.push(createResourceLocatorString('LoginPage', routeConfiguration()));
    }

    const onboardingWizard = user.id ? (
      <OnboardingWizard
        currentUser={currentUser}
        currentUserListing={currentUserListing}
        history={history}
        nonprofitListings={nonprofitListings}
        nonprofitListingsError={nonprofitListingsError}
        nonprofitListingsInProgress={nonprofitListingsInProgress}
        nonprofitPagination={nonprofitPagination}
        onOpenModal={this.onOpenMobileModal}
        onCloseModal={this.onCloseMobileModal}
        onImageUpload={onImageUpload}
        onManageDisableScrolling={onManageDisableScrolling}
        onUpdateListing={onUpdateListing}
        onUpdateProfile={onUpdateProfile}
        onPublishListing={onPublishListing}
        params={params}
        profileImage={profileImage}
        showAsModalMaxWidth={MODAL_BREAKPOINT}
        supportedNonprofits={supportedNonprofits}
        updateListingError={updateListingError}
        updateListingInProgress={updateListingInProgress}
        uploadProfileImageError={uploadProfileImageError}
        uploadProfileImageInProgress={uploadProfileImageInProgress}
        updateProfileInProgress={updateProfileInProgress}
        updateProfileProfileError={updateProfileProfileError}
        trackEvent={this.trackEvent}
      />
    ) : null;

    return (
      <Page
        className={css.root}
        title={intl.formatMessage({ id: 'OnboardingPage.title' })}
        scrollingDisabled={scrollingDisabled}
      >
        <LayoutSideNavigation>
          <LayoutWrapperTopbar>
            <TopbarContainer currentPage="OnboardingPage" />
          </LayoutWrapperTopbar>
          <LayoutWrapperOnboardingSideNav activeTab={params.tab} currentUser={currentUser} />
          <LayoutWrapperMain>
            <div className={css.content}>{onboardingWizard}</div>
          </LayoutWrapperMain>
          <LayoutWrapperFooter>
            <Footer />
          </LayoutWrapperFooter>
        </LayoutSideNavigation>
      </Page>
    );
  }
}

OnboardingPageComponent.propTypes = {
  currentUserListing: object,
  history: shape({
    push: func.isRequired,
  }).isRequired,
  intl: intlShape.isRequired,
  onImageUpload: func,
  onPublishListing: func,
  onUpdateListing: func,
  onUpdateProfile: func,
  params: shape({
    tab: string.isRequired,
  }).isRequired,
  profileImage: object,
  updateListingError: propTypes.error,
  updateListingInProgress: bool,
  uploadProfileImageError: propTypes.error,
  uploadProfileImageInProgress: bool,
  updateProfileInProgress: bool,
  updateProfileProfileError: propTypes.error,
  trackEvent: func,
};

OnboardingPageComponent.defaultProps = {
  currentUser: null,
  updateListingError: null,
  updateListingInProgress: false,
  uploadProfileImageError: null,
  uploadProfileImageInProgress: false,
  updateProfileInProgress: false,
  updateProfileProfileError: null,
};

const mapStateToProps = (state) => {
  const { currentUser } = state.user;

  const { ownListing } = state.ownListing;

  const {
    image,
    uploadImageError,
    uploadInProgress,
    updateInProgress,
    updateProfileError,
  } = state.UserProfile;

  const { availabilityExceptions, updateListingError, updateListingInProgress } = state.UserListing;

  const {
    pagination,
    searchInProgress,
    searchListingsError,
    currentPageResultIds,
    selectedNonprofits,
  } = state.NonprofitListing;
  const nonprofitListings = getListingsById(state, currentPageResultIds);

  return {
    availabilityExceptions,
    currentUser: ensureCurrentUser(currentUser),
    currentUserListing: ensureOwnListing(ownListing),
    nonprofitPagination: pagination,
    nonprofitListingsError: searchListingsError,
    nonprofitListingsInProgress: searchInProgress,
    nonprofitListings,
    profileImage: image,
    scrollingDisabled: isScrollingDisabled(state),
    supportedNonprofits: selectedNonprofits,
    updateListingError,
    updateListingInProgress,
    uploadProfileImageError: uploadImageError,
    uploadProfileImageInProgress: uploadInProgress,
    updateProfileInProgress: updateInProgress,
    updateProfileProfileError: updateProfileError,
  };
};

const mapDispatchToProps = (dispatch) => ({
  onImageUpload: (data) => dispatch(uploadImage(data)),
  onManageDisableScrolling: (componentId, disableScrolling) =>
    dispatch(manageDisableScrolling(componentId, disableScrolling)),
  onUpdateProfile: (data) => dispatch(updateProfile(data)),
  onUpdateListing: (data) =>
    dispatch(requestUpdateListing(data)).then((response) => {
      // Refresh the listing after updating it
      return dispatch(showOwnListing()).then(() => response);
    }),
  onPublishListing: (listingId) => dispatch(requestPublishListingDraft(listingId)),
  trackEvent: (params) => dispatch(trackEventAction(params)),
});

const OnboardingPage = compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  injectIntl
)(OnboardingPageComponent);

/**
 * Data loader for the user onboarding
 *
 * - Fetches user
 * - Fetches user listing
 * - Fetches NPO listing (under supported NPOs tab only)
 * - Fetches selected NPOs (under supported NPOs tab only)
 *
 * @param params
 * @param search
 * @returns {function(*=, *, *): Promise<unknown[]>}
 */
OnboardingPage.loadData = (params, search) => (dispatch, getState) => {
  return Promise.all([dispatch(fetchCurrentUser())]).then(() => {
    const user = ensureCurrentUser(getState().user.currentUser);

    // Ensure the listing is loaded or created if not bound
    if (!user.attributes.profile.listingId) {
      dispatch(createOwnListing());
    } else {
      dispatch(showOwnListing());
    }

    // Fetch additional data for supported NPOs (NPOs, selected NPOs)
    if (params.tab === SUPPORTED_NONPROFITS) {
      const selectedNonprofits = getSelectedNonprofits(user);
      dispatch(queryNonProfits(search));
      dispatch(fetchSelectedNonprofits(selectedNonprofits));
    }
  });
};

export default OnboardingPage;
