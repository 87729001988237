import React from 'react';
import css from '../NonprofitProfile.css';
import { propTypes } from '../../../../util/types';
import { bool, func, object } from 'prop-types';
import config from '../../../../config';
import NonprofitAvatar from './NonprofitAvatar';
import classNames from 'classnames';
import { ensureLocation } from '../../../../util/data';

class NonprofitHero extends React.Component {
  render() {
    const { getTranslation, isApproved, listing, params, user, tabsVisible } = this.props;

    const name = listing.attributes.title;
    const { organizationCategory, organizationLocation } = listing.attributes.publicData;
    const location = ensureLocation(
      config.custom.nonprofitLocations.find((l) => l.key === organizationLocation)
    );
    const className = classNames(css.nonprofitHero, {
      [css.nonprofitHeroApproved]: isApproved,
      [css.tabsVisible]: tabsVisible,
    });

    return (
      <div className={className}>
        {!isApproved ? (
          <div className={css.nonprofitPending}>{getTranslation('pendingForApproval')}</div>
        ) : null}
        <div className={css.nonprofitHeader}>
          <div className={css.nonprofitDetails}>
            <h1 className={css.authorName}>{name}</h1>
            <div className={css.authorDescription}>{organizationCategory}</div>
            <div className={css.authorCompany}>{location.label}</div>
          </div>
          <NonprofitAvatar user={user} params={params} />
        </div>
      </div>
    );
  }
}

NonprofitHero.propTypes = {
  getTranslation: func.isRequired,
  isApproved: bool.isRequired,
  listing: propTypes.listing,
  params: object.isRequired,
  user: propTypes.user.isRequired,
  tabsVisible: bool,
};

NonprofitHero.defaultProps = {
  tabsVisible: false,
};

export default NonprofitHero;
