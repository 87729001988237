import React, { Component } from 'react';
import { withMessages } from '../../util/localization';
import { LISTING_STATE_DRAFT, propTypes } from '../../util/types';
import { array, bool, func, object, oneOf, shape, string } from 'prop-types';
import { createResourceLocatorString } from '../../util/routes';
import routeConfiguration from '../../routeConfiguration';
import { NonprofitInfoPanel, PanelHeader } from '../index';
import { intlShape } from '../../util/reactIntl';
import { ensureCurrentUser, ensureOwnListing, PROFILE_TYPE_NONPROFIT } from '../../util/data';
import NonprofitImpactPanel from '../NonprofitImpactPanel/NonprofitImpactPanel';
import NonprofitPhotosPanel from '../NonprofitPhotosPanel/NonprofitPhotosPanel';
import NonprofitPreferencesPanel from '../NonprofitPreferencesPanel/NonprofitPreferencesPanel';
import { STRIPE_ONBOARDING_RETURN_URL_TYPES } from '../../containers/NonprofitOnboardingPage/constants';
import PayoutDetailsPanel from '../PayoutDetailsPanel/PayoutDetailsPanel';
import { createSlug } from '../../util/urlHelpers';
import {
  IMPACT,
  NONPROFIT_INFO,
  NONPROFIT_PHOTOS,
  PAYOUT_DETAILS,
  PREFERENCES,
} from '../MyNonprofitWizard/constants';
import { ONBOARDING_PROGRESS } from './constants';

class NonprofitOnboardingWizard extends Component {
  constructor(props) {
    super(props);
    this.onSubmit = this.onSubmit.bind(this);
    this.onPublish = this.onPublish.bind(this);
    this.redirectToProfile = this.redirectToProfile.bind(this);
  }

  /**
   * Redirects the user to the given tab. Should be used to 'progress' the user along the
   * onboarding process.
   *
   * @param tab
   */
  redirectToTab = (tab) => {
    const to = createResourceLocatorString(
      'NonprofitOnboardingPage',
      routeConfiguration(),
      { tab },
      {}
    );
    this.props.history.push(to);
  };

  redirectToProfile = () => {
    const { currentUserListing, history } = this.props;
    const listing = ensureOwnListing(currentUserListing);

    const to = createResourceLocatorString(
      'ListingPage',
      routeConfiguration(),
      { id: listing.id.uuid, slug: createSlug(listing.attributes.title) },
      {}
    );

    history.push(to);
  };

  /**
   * Publish a listing
   *
   * It will be placed in the published state, but will still contain the stripeApproved=false flag
   * which must be cleared manually from the ShareTribe console.
   *
   * @returns {Promise<unknown>|*}
   */
  onPublish = () => {
    const { currentUserListing, onPublishListing, onUpdateListing, onUpdateProfile } = this.props;
    const listing = ensureOwnListing(currentUserListing);

    if (listing.attributes.state === LISTING_STATE_DRAFT) {
      return Promise.all([
        // Update the listing, to disable it for the public
        onUpdateListing({
          id: listing.id.uuid,
          privateData: {
            onboardingProgress: ONBOARDING_PROGRESS[PAYOUT_DETAILS],
            stripeApproved: false,
          },
        }),
        // Finish onboarding in the profile
        onUpdateProfile({
          privateData: {
            onboardingCompleted: true,
          },
        }),
      ]).then(() => {
        // Publish the actual listing
        onPublishListing(listing.id.uuid).then(() => {
          this.redirectToProfile();
        });
      });
    } else {
      this.redirectToProfile();
    }
  };

  onSubmit = async (profileData, listingData, activeTab) => {
    const {
      currentUser,
      currentUserListing,
      onPublishListing,
      onUpdateListing,
      onUpdateProfile,
    } = this.props;
    const user = ensureCurrentUser(currentUser);
    const listing = ensureOwnListing(currentUserListing);

    // Ensure NPO listing
    listingData.publicData = {
      ...listingData.publicData,
      isNPOListing: true,
      isPitcherListing: false,
      isVolunteerListing: false,
    };

    // Ensure NPO profile
    profileData.publicData = {
      isNPO: true,
      isPitcher: false,
      isVolunteer: false,
      profileType: PROFILE_TYPE_NONPROFIT,
    };

    // Update the onboarding progress if needed
    profileData.privateData = {
      onboardingProgress:
        ONBOARDING_PROGRESS[activeTab] >= user.attributes.profile.privateData.onboardingProgress ||
        !user.attributes.profile.privateData.onboardingProgress
          ? ONBOARDING_PROGRESS[activeTab]
          : user.attributes.profile.privateData.onboardingProgress,
    };

    if (activeTab === IMPACT) {
      profileData.privateData.onboardingCompleted = true;
    }

    listingData.id = listing.id.uuid;
    if (!listing.attributes.publicData.isApproved) {
      listingData.publicData.isApproved = false;
    }

    // Store all data before proceeding
    await Promise.all([onUpdateProfile(profileData), onUpdateListing(listingData)]);

    let nextTab;
    switch (activeTab) {
      case NONPROFIT_INFO:
        nextTab = NONPROFIT_PHOTOS;
        break;
      case NONPROFIT_PHOTOS:
        nextTab = IMPACT;
        break;
      case IMPACT:
        // Publish the listing after the impact card is set, this can be async
        if (listing.attributes.state === LISTING_STATE_DRAFT) {
          onPublishListing(listing.id.uuid);
        }
        nextTab = PAYOUT_DETAILS;
        break;
      case PAYOUT_DETAILS:
        break;
      default:
        nextTab = null;
        break;
    }

    if (nextTab) {
      this.redirectToTab(nextTab);
    }
  };

  render() {
    const {
      createStripeAccountError,
      currentUser,
      currentUserListing,
      getAccountLinkInProgress,
      fetchStripeAccountError,
      getMessage,
      images,
      intl,
      onGetStripeConnectAccountLink,
      onListingImageUpload,
      onPayoutDetailsFormChange,
      onPayoutDetailsFormSubmit,
      onProfileImageUpload,
      onRemoveListingImage,
      onUpdateImageOrder,
      params,
      payoutDetailsSaved,
      payoutDetailsSaveInProgress,
      profileImage,
      stripeAccountFetched,
      stripeAccount,
      uploadProfileImageError,
      uploadProfileImageInProgress,
      updateProfileInProgress,
      updateProfileError,
      updateStripeAccountError,
      onUpdateListing,
      onUploadS3Image,
    } = this.props;
    const { tab: activeTab } = params;
    const nonprofit = ensureOwnListing(currentUserListing);

    let tabContent;
    switch (activeTab) {
      case NONPROFIT_INFO:
        tabContent = (
          <>
            <PanelHeader
              header={getMessage('nonprofitInfoHeading')}
              subHeader={getMessage('nonprofitInfoSubHeading')}
            />
            <NonprofitInfoPanel
              currentUser={currentUser}
              currentUserListing={currentUserListing}
              handleSubmit={this.onSubmit}
              intl={intl}
              onImageUpload={onProfileImageUpload}
              image={profileImage}
              uploadProfileImageError={uploadProfileImageError}
              uploadProfileImageInProgress={uploadProfileImageInProgress}
              updateProfileInProgress={updateProfileInProgress}
              updateProfileError={updateProfileError}
            />
          </>
        );
        break;

      case NONPROFIT_PHOTOS:
        tabContent = (
          <>
            <PanelHeader header={getMessage('nonprofitPhotosHeading')} />
            <NonprofitPhotosPanel
              currentUser={currentUser}
              currentUserListing={currentUserListing}
              handleSubmit={this.onSubmit}
              images={images}
              intl={intl}
              nonprofit={nonprofit}
              onImageUpload={onListingImageUpload}
              onRemoveImage={onRemoveListingImage}
              onSubmit={() => {}}
              onUpdateImageOrder={onUpdateImageOrder}
              panelUpdated={false}
              updateInProgress={false}
            />
          </>
        );
        break;
      case IMPACT:
        tabContent = (
          <>
            <PanelHeader
              header={getMessage('impact.title')}
              subHeader={getMessage('impact.description')}
            />
            <NonprofitImpactPanel
              images={images}
              nonprofit={nonprofit}
              onImageUpload={onListingImageUpload}
              onRemoveImage={onRemoveListingImage}
              onSubmit={this.onSubmit}
              onUpdateImageOrder={onUpdateImageOrder}
              currentUser={currentUser}
              currentUserListing={currentUserListing}
            />
          </>
        );
        break;
      case PREFERENCES:
        tabContent = (
          <>
            <PanelHeader
              header={getMessage('preferences.title')}
              subHeader={getMessage('preferences.description')}
            />
            <NonprofitPreferencesPanel
              currentUserListing={currentUserListing}
              onUpdateListing={onUpdateListing}
              updateProfileInProgress={updateProfileInProgress}
              onUploadS3Image={onUploadS3Image}
              currentUser={currentUser}
            />
          </>
        );
        break;
      case PAYOUT_DETAILS:
        tabContent = (
          <>
            <PanelHeader
              header={getMessage('payoutDetailsHeading')}
              subHeader={getMessage('payoutDetailsSubHeading')}
            />
            <PayoutDetailsPanel
              createStripeAccountError={createStripeAccountError}
              currentUser={currentUser}
              currentUserListing={currentUserListing}
              fetchStripeAccountError={fetchStripeAccountError}
              getAccountLinkInProgress={getAccountLinkInProgress}
              handlePublish={this.onPublish}
              intl={intl}
              onGetStripeConnectAccountLink={onGetStripeConnectAccountLink}
              onPayoutDetailsFormChange={onPayoutDetailsFormChange}
              onPayoutDetailsFormSubmit={onPayoutDetailsFormSubmit}
              pageName="NonprofitOnboardingPage"
              params={params}
              payoutDetailsSaved={payoutDetailsSaved}
              payoutDetailsSaveInProgress={payoutDetailsSaveInProgress}
              stripeAccountFetched={stripeAccountFetched}
              stripeAccount={stripeAccount}
              updateStripeAccountError={updateStripeAccountError}
            />
          </>
        );
        break;
      default:
        tabContent = null;
    }
    return tabContent;
  }
}

NonprofitOnboardingWizard.propTypes = {
  createStripeAccountError: propTypes.error,
  currentUser: propTypes.currentUser.isRequired,
  currentUserListing: propTypes.ownListing.isRequired,
  fetchStripeAccountError: propTypes.error,
  getAccountLinkInProgress: bool.isRequired,
  getMessage: func.isRequired,
  history: shape({
    push: func.isRequired,
  }).isRequired,
  images: array.isRequired,
  intl: intlShape.isRequired,
  params: shape({
    returnURLType: oneOf(STRIPE_ONBOARDING_RETURN_URL_TYPES),
    tab: string.isRequired,
  }).isRequired,
  onGetStripeConnectAccountLink: func.isRequired,
  onListingImageUpload: func.isRequired,
  onPayoutDetailsFormChange: func.isRequired,
  onPayoutDetailsFormSubmit: func.isRequired,
  onProfileImageUpload: func.isRequired,
  onPublishListing: func.isRequired,
  onUpdateListing: func.isRequired,
  onUpdateProfile: func.isRequired,
  profileImage: object,
  payoutDetailsSaved: bool.isRequired,
  payoutDetailsSaveInProgress: bool.isRequired,
  stripeAccount: propTypes.stripeAccount,
  updateListingError: propTypes.error,
  updateListingInProgress: bool,
  updateProfileError: propTypes.error,
  uploadProfileImageError: propTypes.error,
  uploadProfileImageInProgress: bool,
  updateProfileInProgress: bool,
  updateStripeAccountError: propTypes.error,
};

NonprofitOnboardingWizard.defaultProps = {
  createStripeAccountError: null,
  currentUserListing: {},
  fetchStripeAccountError: null,
  params: {
    returnURLType: null,
  },
  stripeAccount: null,
  updateListingError: null,
  updateListingInProgress: false,
  updateProfileError: null,
  uploadProfileImageError: null,
  uploadProfileImageInProgress: false,
  updateProfileInProgress: false,
  updateStripeAccountError: null,
};

export default withMessages(NonprofitOnboardingWizard, 'NonprofitOnboardingWizard');
