import React, { Component } from 'react';
import { func, node, shape, string } from 'prop-types';
import { propTypes } from '../../../util/types';
import { NonprofitFeatureCard } from '../../index';
import css from './SectionNonprofitHighlightBox.css';

class SectionNonprofitHighlightBox extends Component {
  render() {
    const { children, history, listing, background } = this.props;

    const backgroundStyle = background ? { backgroundImage: `url(${background})` } : {};

    return (
      <div className={css.container}>
        <div className={css.featureContent} style={backgroundStyle}>
          {children}
        </div>
        <div className={css.featureCardContainer}>
          <NonprofitFeatureCard history={history} listing={listing} className={css.featureCard} />
        </div>
      </div>
    );
  }
}

SectionNonprofitHighlightBox.propTypes = {
  background: string,
  children: node.isRequired,
  history: shape({
    push: func.isRequired,
  }).isRequired,
  listing: propTypes.listing.isRequired,
};

export default SectionNonprofitHighlightBox;
