import { markNotificationAsSeen, showNotification } from '../../ducks/notifications.duck';
import { ensureCurrentUser, ensureListing, ensureNotification } from '../../util/data';
import { showListing } from '../../ducks/Listings.duck';
import {
  fetchMonthlyTimeSlots,
  selectDate,
  selectReservableSlot,
} from '../ListingPage/ListingPage.duck';
import { isNewForCurrentUser, TYPE_NEW_TIMES_AVAILABLE } from '../../util/notifications';
import { getListing } from '../../util/listings';
import { fetchCurrentUser } from '../../ducks/user.duck';
import { findFirstAvailableReservableSlot } from '../../util/timeSlots';
import moment from 'moment-timezone';
import { calendarStartTimeOffSetHours } from '../../marketplace-custom-config';

/**
 * The initial data loader for the notification detail page performs the following actions
 * - Fetch current user
 * - Fetch current notification
 * - Mark notification as seen if needed (transition on the notification transaction)
 * - Fetch monthly time slots (if needed)
 * - Pre-select the first time slot available (if needed and possible)
 *
 * @param params
 * @returns {function(*=, *, *): Promise<[unknown, unknown]>}
 */
export const loadData = (params) => (dispatch, getState, sdk) => {
  const { id } = params;
  return Promise.all([dispatch(fetchCurrentUser()), dispatch(showNotification(id))]).then(
    async () => {
      const ensuredNotification = ensureNotification(getState().notifications.notification);
      const ensuredCurrentUser = ensureCurrentUser(getState().user.currentUser);
      const timezone = getState().user.timezone;

      // Mark notification as seen f needed
      if (isNewForCurrentUser(ensuredNotification, ensuredCurrentUser)) {
        await dispatch(markNotificationAsSeen(ensuredNotification, ensuredCurrentUser));
      }

      // Load listing data
      const listingId =
        ensuredNotification.customer.id &&
        ensuredNotification.customer.id.uuid === ensuredCurrentUser.id.uuid
          ? ensuredNotification.provider.attributes.profile.publicData.listingId
          : ensuredNotification.customer.attributes.profile.publicData.listingId;
      await dispatch(showListing(listingId));

      // Pre-select one of the offered time slots if needed
      if (ensuredNotification.attributes.protectedData.type === TYPE_NEW_TIMES_AVAILABLE) {
        // Get target listing
        const ensuredListing = ensureListing(getListing(listingId, getState()));

        // Fetch time slots
        await fetchMonthlyTimeSlots(dispatch, ensuredListing);
        const allTimeSlots = [];
        const { monthlyTimeSlots } = getState().ListingPage;
        Object.keys(monthlyTimeSlots).forEach((monthId) => {
          allTimeSlots.push(...monthlyTimeSlots[monthId].timeSlots);
        });

        // Filter out time slots included in the notification
        const notificationTimeSlots = ensuredListing.attributes.publicData.reservableSlots.filter(
          (r) => {
            return (
              ensuredNotification.attributes.protectedData.timeSlots.includes(r.id) ||
              ensuredNotification.attributes.protectedData.timeSlots.includes(r.recurrenceParent)
            );
          }
        );

        // Define now and extend it with the configured offset in which meetings can be booked
        const now = moment().tz(timezone);
        now.add(calendarStartTimeOffSetHours, 'hours');

        // Attempt to get the first available time slot
        const firstTimeSlot = findFirstAvailableReservableSlot(
          allTimeSlots,
          notificationTimeSlots,
          now,
          timezone
        );

        // If a first time slot was found, pre-select it (on-load, so it happens only once). This is
        // done synchronously to prevent the UI from twitching and flickering
        if (firstTimeSlot) {
          await dispatch(
            selectDate(moment.tz(firstTimeSlot.start, firstTimeSlot.timezone).tz(timezone))
          );
          await dispatch(selectReservableSlot(firstTimeSlot));
        }
      }

      return listingId;
    }
  );
};
