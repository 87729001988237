import React from 'react';
import { AvatarMega, AvatarMedium } from '../../components';

import css from './ListingPage.css';

const UserAvatar = (props) => {
  const { ensuredUser } = props;
  return (
    <div className={css.userAvatar}>
      <AvatarMega user={ensuredUser} className={css.avatarDesktop} />
      <AvatarMedium user={ensuredUser} className={css.avatarMobile} />
    </div>
  );
};

export default UserAvatar;
