import React from 'react';
import { NamedLink } from '../../../components';
import { PARTNERSHIP_TABS } from '../../PartnershipsPage/constants';
import css from './NonprofitProfile.css';

const GoodAdvertisingPromoBanner = () => {
  return (
    <div className={css.goodAdvertisingBanner}>
      <p>
        Raise more funds for your nonprofit by joining <b>Givsly Good Advertising</b> program{' '}
        <NamedLink name="PartnershipsTabPage" params={{ tab: PARTNERSHIP_TABS.APPROVAL }}>
          here!
        </NamedLink>
      </p>
    </div>
  );
};

export default GoodAdvertisingPromoBanner;
