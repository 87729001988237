import { array, func } from 'prop-types';
import React from 'react';
import { propTypes } from '../../util/types';
import { IMPACT } from '../MyNonprofitWizard/constants';
import css from './NonprofitImpactPanel.css';
import { NonprofitImpactForm } from '../../forms';

const NonprofitImpactPanel = (props) => {
  const {
    images,
    nonprofit,
    onImageUpload,
    onRemoveImage,
    onSubmit,
    currentUserListing,
    currentUser,
  } = props;
  const handleSubmit = (values) => {
    const {
      description,
      impactType,
      impactUnits,
      pictureId,
      impactArea,
      impactCost = 0,
      impactVerb = '',
      impactUnitCount = 0,
      impactUnitNameSingle = '',
      impactUnitNamePlural = '',
      impactRecipient = '',
      impactPreposition = '',
      impactImageUrl = '',
    } = values;

    const updatedListingValues = {
      publicData: {
        impactArea,
        impact: {
          description: typeof description === 'string' ? description.trim() : description,
          impactType: {
            plural: impactType,
            singular: impactType,
          },
          impactUnits: parseFloat(impactUnits, 10) || parseFloat(impactUnitCount, 10),
          pictureId,
          impactCost: parseFloat(impactCost, 10),
          impactCostCents: parseFloat(impactCost, 10) * 100,
          impactVerb: typeof impactVerb === 'string' ? impactVerb.trim() : impactVerb,
          impactUnitCount: parseFloat(impactUnitCount, 10),
          impactUnitNameSingle:
            typeof impactUnitNameSingle === 'string'
              ? impactUnitNameSingle.trim()
              : impactUnitNameSingle,
          impactUnitNamePlural:
            typeof impactUnitNamePlural === 'string'
              ? impactUnitNamePlural.trim()
              : impactUnitNamePlural,
          impactRecipient:
            typeof impactRecipient === 'string' ? impactRecipient.trim() : impactRecipient,
          impactPreposition:
            typeof impactPreposition === 'string' ? impactPreposition.trim() : impactPreposition,
          impactImageUrl:
            typeof impactImageUrl === 'string' ? impactImageUrl.trim() : impactImageUrl,
          impactVideoUrl: '',
        },
      },
    };

    if (Array.isArray(images)) {
      updatedListingValues.images = images.map((img) => img.imageId || img.id);
    }

    return onSubmit({}, updatedListingValues, IMPACT).catch(console.error);
  };

  return (
    <div className={css.root}>
      <NonprofitImpactForm
        images={images}
        nonprofit={nonprofit}
        onImageUpload={onImageUpload}
        onSubmit={handleSubmit}
        onRemoveImage={onRemoveImage}
        impactArea={currentUserListing.attributes.publicData.impactArea}
        currentUser={currentUser}
      />
    </div>
  );
};

NonprofitImpactPanel.propTypes = {
  images: array,
  nonprofit: propTypes.ownListing,
  onImageUpload: func.isRequired,
  onRemoveImage: func.isRequired,
  onSubmit: func.isRequired,
};

NonprofitImpactPanel.defaultProps = {
  images: [],
};

export default NonprofitImpactPanel;
