import React from 'react';
import css from './Notification.css';
import { withMessages } from '../../util/localization';
import { propTypes } from '../../util/types';
import classNames from 'classnames';
import { humanReadableDate } from '../../util/notifications';
import { bool, func, object, shape, string } from 'prop-types';
import { ensureListing } from '../../util/data';
import moment from 'moment-timezone';
import { Button } from '../index';
import { calendarStartTimeOffSetHours } from '../../marketplace-custom-config';
import { parse, stringify } from '../../util/urlHelpers';
import { withRouter } from 'react-router-dom';
import { isTimeSlotBooked, sortByStart } from '../../util/timeSlots';

const openBookModal = (history, location) => {
  const { pathname, search, state } = location;
  const searchString = `?${stringify({ ...parse(search), book: true })}`;
  history.push(`${pathname}${searchString}`, state);
};

class NewTimesAvailableNotification extends React.Component {
  constructor(props) {
    super(props);
    this.renderButton = this.renderButton.bind(this);
  }

  get timeSlots() {
    const { getListing, notification, otherUser } = this.props;
    const { listingId } = otherUser.attributes.profile.publicData;
    const listing = ensureListing(getListing(listingId));
    const { timeSlots } = notification.attributes.protectedData;

    // First collect all time slots linked to the notification together with their children if they
    // have any. Finally sort the result by date ascending and show only the first 3 items.
    return sortByStart(
      listing.attributes.publicData.reservableSlots.filter((timeSlot) => {
        return (
          timeSlots.indexOf(timeSlot.id) >= 0 || timeSlots.indexOf(timeSlot.recurrenceParent) >= 0
        );
      })
    ).slice(0, 3);
  }

  get availabilityLowerBound() {
    const { timezone } = this.props;
    return moment().tz(timezone).add(calendarStartTimeOffSetHours, 'hours');
  }

  renderButton = (timeSlot) => {
    const {
      canRenderCalendar,
      getMessage,
      history,
      location,
      monthlyTimeSlots,
      onSelectDate,
      onSelectReservableSlot,
      onScrollToBookingInfo,
      timezone,
    } = this.props;
    const localizedStart = moment.tz(timeSlot.start, timeSlot.timezone).tz(timezone);
    const isExpired = localizedStart.isBefore(this.availabilityLowerBound);
    const isBooked = isTimeSlotBooked(monthlyTimeSlots, timeSlot, timezone);

    if (isExpired) {
      return <span className={css.error}>{getMessage('timeSlot.expired')}</span>;
    } else if (isBooked && canRenderCalendar) {
      return <span className={css.warning}>{getMessage('timeSlot.booked')}</span>;
    } else if (canRenderCalendar) {
      return (
        <Button
          className={css.optionSelectButton}
          disabled={localizedStart.isBefore(this.availabilityLowerBound)}
          onClick={() => {
            return Promise.all([
              onSelectDate(moment.tz(timeSlot.start, timeSlot.timezone).tz(timezone)),
              onSelectReservableSlot(timeSlot),
            ]).then(() => {
              onScrollToBookingInfo();
              openBookModal(history, location);
            });
          }}
          type={'button'}
        >
          {getMessage('select')}
        </Button>
      );
    }

    return null;
  };

  render() {
    const { currentUser, getMessage, notification, otherUser, timezone } = this.props;
    const providerName = currentUser.attributes.profile.publicData.firstName;
    const customerName = otherUser.attributes.profile.publicData.firstName;

    return (
      <div className={css.root}>
        <h1 className={css.header}>{getMessage('title', { customerName, providerName })}</h1>
        <p className={css.introduction}>{getMessage('introduction', { customerName })}</p>
        <div className={css.messages}>
          <div className={css.theirMessage}>
            <div className={classNames(css.message, css.messageFullWidth)}>
              <ol className={css.options}>
                {this.timeSlots.map((timeSlot) => {
                  const localizedStart = moment.tz(timeSlot.start, timeSlot.timezone).tz(timezone);
                  return (
                    <li className={css.option} key={`timeSlot-${timeSlot.id}`}>
                      <span>
                        <strong className={css.optionDate}>
                          {localizedStart.format('dddd, D MMMM')}
                        </strong>
                        {` ${localizedStart.format('hh:mm a')} (${localizedStart
                          .tz(timezone)
                          .zoneAbbr()})`}
                      </span>
                      {this.renderButton(timeSlot)}
                    </li>
                  );
                })}
              </ol>
              <div className={classNames(css.theirMessageTimestamp, css.timestamp)}>
                {humanReadableDate(notification.attributes.createdAt, timezone)}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

NewTimesAvailableNotification.propTypes = {
  canRenderCalendar: bool.isRequired,
  getListing: func.isRequired,
  history: shape({
    push: func.isRequired,
  }).isRequired,
  location: object.isRequired,
  monthlyTimeSlots: object,
  notification: propTypes.notification,
  onScrollToBookingInfo: func.isRequired,
  onSelectDate: func.isRequired,
  onSelectReservableSlot: func.isRequired,
  otherUser: propTypes.user,
  timezone: string.isRequired,
};

NewTimesAvailableNotification.defaultProps = {
  monthlyTimeSlots: {},
};

export default withRouter(
  withMessages(NewTimesAvailableNotification, 'Notification.NewTimesAvailableNotification')
);
