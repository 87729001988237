import React, { useState } from 'react';
import { event } from '../../ducks/Events.duck';
import NamedLink from '../NamedLink/NamedLink';
import css from './OutreachNonprofitCards.css';

type OutreachNonprofitCardsProps = {
  nonprofits: object[];
  donationAmount: number;
  onSelectNonprofit: (id: string) => void;
};

const OutreachNonprofitCards = (props: OutreachNonprofitCardsProps) => {
  const [activeNpo, setActiveNpo] = useState(-1);
  const { nonprofits, onSelectNonprofit } = props;

  return (
    <div className={css.container}>
      {nonprofits.map((nonprofit: any, i: number) => {
        // Selector
        const onSelect = () => {
          setActiveNpo(i);
          onSelectNonprofit(nonprofit.id.uuid);
        };

        // Prop destructuring
        const { id, attributes } = nonprofit;
        const { title } = attributes;
        const { organizationCategory, impact } = nonprofit.attributes.publicData;
        const donationAmount = props.donationAmount;

        const {
          impactCost,
          impactVerb,
          impactUnitCount,
          impactUnitNameSingle,
          impactUnitNamePlural,
          impactRecipient,
          impactPreposition,
        } = impact;

        const hasNewImpactData = !!(
          impactCost &&
          impactVerb &&
          impactUnitCount &&
          impactUnitNameSingle &&
          impactUnitNamePlural &&
          impactRecipient &&
          impactPreposition
        );

        // Impact image
        const picture = impact.pictureId
          ? nonprofit.images.find((i: any) => {
              return (
                (typeof i.id === 'string' && i.id.startsWith(impact.pictureId)) ||
                (typeof i.id === 'object' && i.id.uuid === impact.pictureId)
              );
            })
          : nonprofit.images.length
          ? nonprofit.images[0]
          : null;

        // if only one nonprofit, select it. bit hacky
        if (nonprofits.length === 1 && nonprofit.id && activeNpo !== 0) {
          setActiveNpo(i);
          onSelectNonprofit(nonprofit.id.uuid);
        }

        // Render
        return (
          <div key={i} className={activeNpo === i ? css.cardActive : css.card} onClick={onSelect}>
            {id && id.uuid && (
              <NamedLink
                name="ListingPageCanonical"
                params={{ id: id.uuid }}
                target="_blank"
                onClick={(e: MouseEvent) => e.stopPropagation()}
                className={css.npoLink}
              >
                <svg width="12" height="12" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg">
                  <g stroke="#FAC51D" strokeWidth="1.5" fill="none" fillRule="evenodd">
                    <path d="M8.75 8.25v3h-8v-8h3M6.25.75h5v5" />
                    <path strokeLinecap="square" d="m10.25 1.75-5 5" />
                  </g>
                </svg>
              </NamedLink>
            )}
            {picture ? (
              <img
                className={css.img}
                src={picture.attributes.variants['square-small2x'].url}
                alt="alt text"
              />
            ) : null}
            <p className={css.title}>{title}</p>
            <p className={css.subtitle}>{organizationCategory}</p>
            <div className={css.divider} />
            {hasNewImpactData && (
              <div className={css.stack}>
                <p className={css.title}>$ 100</p>
                <p className={css.subtitle}>{impactVerb}</p>
                <p className={css.title}>{Math.round((100 / impactCost) * impactUnitCount)}</p>
                <p className={css.subtitle}>
                  {(100 / impactCost) * impactUnitCount > 1
                    ? impactUnitNamePlural
                    : impactUnitNameSingle}{' '}
                  {impactPreposition} {impactRecipient}
                </p>
              </div>
            )}
            {!hasNewImpactData && impact.impactUnits && impact.description && (
              <div className={css.stack}>
                <p className={css.subtitle}>An average Givsly connection equals</p>
                <p className={css.title}>
                  {impact.impactUnits} {impact.impactType.plural}
                </p>
                <p className={css.blackThin}>{impact.description}</p>
              </div>
            )}
            {!hasNewImpactData && !impact.impactUnits && !impact.description && (
              <div className={css.stack}>
                <p className={css.black}>${donationAmount} donation</p>
                <p className={css.blackThin}>to connect with you</p>
              </div>
            )}
            {nonprofits.length > 1 && (
              <div className={css.selectBox}>
                <span className={css.circle} />
                <span className={css.selectWord}>Select</span>
              </div>
            )}
          </div>
        );
      })}
    </div>
  );
};

export default OutreachNonprofitCards;
