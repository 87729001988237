import * as moment from 'moment';
import {
  addOwnEntities,
  getOwnListingsById,
} from '../containers/ManageListingsPage/ManageListingsPage.duck';
import { PROFILE_TYPE_NEW } from '../util/data';
import { storableError } from '../util/errors';
import sharetribe from '@givsly/sharetribe-utils';
import { fetchCurrentUser } from './user.duck';
import { types as sdkTypes } from '../util/sdkLoader';
import { requestCreateListingDraft } from './UserListing.duck';
import { updateProfile } from './UserProfile.duck';
const { UUID } = sdkTypes;

// Action types
export const CREATE_OWN_LISTING_REQUEST = 'app/ownListing/CREATE_OWN_LISTING_REQUEST';
export const CREATE_OWN_LISTING_SUCCESS = 'app/ownListing/CREATE_OWN_LISTING_SUCCESS';
export const CREATE_OWN_LISTING_FAILURE = 'app/ownListing/CREATE_OWN_LISTING_FAILURE';
export const SHOW_OWN_LISTING_REQUEST = 'app/ownListing/SHOW_OWN_LISTING_REQUEST';
export const SHOW_OWN_LISTING_SUCCESS = 'app/ownListing/SHOW_OWN_LISTING_SUCCESS';
export const SHOW_OWN_LISTING_FAILURE = 'app/ownListing/SHOW_OWN_LISTING_FAILURE';
export const UPDATE_OWN_LISTING_REQUEST = 'app/ownListing/UPDATE_OWN_LISTING_REQUEST';
export const UPDATE_OWN_LISTING_SUCCESS = 'app/ownListing/UPDATE_OWN_LISTING_SUCCESS';
export const UPDATE_OWN_LISTING_FAILURE = 'app/ownListing/UPDATE_OWN_LISTING_FAILURE';
export const SET_OWN_LISTING = 'app/ownListing/SET_OWN_LISTING';

// Initial state
const initialState = {
  createOwnListingError: null,
  createOwnListingInProgress: false,
  ownListing: null,
  ownListingId: null,
  showOwnListingError: null,
  showOwnListingInProgress: false,
  updateOwnListingError: null,
  updateOwnListingInProgress: false,
};

// Reducer
export default function reducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case CREATE_OWN_LISTING_REQUEST:
      return {
        ...state,
        createOwnListingError: null,
        createOwnListingInProgress: true,
      };
    case CREATE_OWN_LISTING_SUCCESS:
      return {
        ...state,
        createOwnListingInProgress: false,
        ownListing: payload.ownListing,
      };
    case CREATE_OWN_LISTING_FAILURE:
      return {
        ...state,
        createOwnListingError: payload.err,
        createOwnListingInProgress: false,
      };
    case SET_OWN_LISTING:
      return {
        ...state,
        ownListing: payload.ownListing,
        ownListingId: payload.ownListing.id.uuid,
      };
    case SHOW_OWN_LISTING_REQUEST:
      return {
        ...state,
        ownListingId: payload.ownListingId,
        showOwnListingError: null,
        showOwnListingInProgress: true,
      };
    case SHOW_OWN_LISTING_SUCCESS:
      return {
        ...state,
        ownListing: payload.ownListing,
        showOwnListingInProgress: false,
      };
    case SHOW_OWN_LISTING_FAILURE:
      return {
        ...state,
        showOwnListingError: payload.err,
        showOwnListingInProgress: false,
      };
    case UPDATE_OWN_LISTING_REQUEST:
      return {
        ...state,
        updateOwnListingError: null,
        updateOwnListingInProgress: false,
      };
    case UPDATE_OWN_LISTING_SUCCESS:
      return {
        ...state,
        ownListing: payload.ownListing,
        updateOwnListingInProgress: false,
      };
    case UPDATE_OWN_LISTING_FAILURE:
      return {
        ...state,
        updateOwnListingError: payload.err,
        updateOwnListingInProgress: false,
      };
    default:
      return state;
  }
}

// Action creators
const createOwnListingRequest = () => ({
  type: CREATE_OWN_LISTING_REQUEST,
});
const createOwnListingSuccess = (ownListing) => ({
  type: CREATE_OWN_LISTING_SUCCESS,
  payload: {
    ownListing,
  },
});
const createOwnListingFailure = (err) => ({
  type: CREATE_OWN_LISTING_FAILURE,
  payload: {
    err,
  },
});
const setOwnListing = (ownListing) => ({
  type: SET_OWN_LISTING,
  payload: {
    ownListing,
  },
});
const showOwnListingRequest = (ownListingId) => ({
  type: SHOW_OWN_LISTING_REQUEST,
  payload: {
    ownListingId,
  },
});
const showOwnListingSuccess = (ownListing) => ({
  type: SHOW_OWN_LISTING_SUCCESS,
  payload: {
    ownListing,
  },
});
const showOwnListingFailure = (err) => ({
  type: SHOW_OWN_LISTING_FAILURE,
  payload: {
    err,
  },
});
const updateOwnListingRequest = () => ({
  type: UPDATE_OWN_LISTING_REQUEST,
});
const updateOwnListingSuccess = (ownListing) => ({
  type: UPDATE_OWN_LISTING_SUCCESS,
  payload: {
    ownListing,
  },
});
const updateOwnListingFailure = (err) => ({
  type: UPDATE_OWN_LISTING_FAILURE,
  payload: {
    err,
  },
});

// Thunks
/**
 * Show the users own listing. This can be simplified from traditional Sharetribe point of view,
 * since a user can only have a single listing. We therefore do not need to pass along an identifier
 * for the users listing but can instead fetch the identifier from the state.
 *
 * @returns {function(*, *, *): *}
 */
export const showOwnListing = () => (dispatch, getState, sdk) => {
  dispatch(showOwnListingRequest());

  return dispatch(fetchCurrentUser(null, false))
    .then((user) => {
      return sharetribe.user.ensureCurrentUser(user);
    })
    .then((currentUser) => {
      if (!currentUser.id) {
        return Promise.reject('User not authenticated');
      }

      return sdk.ownListings.show({
        id: new UUID(currentUser.attributes.profile.publicData.listingId),
      });
    })
    .then(async (sdkResponse) => {
      await dispatch(addOwnEntities(sdkResponse));
      return getOwnListingsById(getState(), [sdkResponse.data.data.id]);
    })
    .then((ownListings) => {
      const ownListing = sharetribe.listing.ensureOwnListing(ownListings[0]);
      dispatch(showOwnListingSuccess(ownListing));
      return ownListing;
    })
    .catch((err) => {
      dispatch(showOwnListingFailure(storableError(err)));
      return Promise.reject(err);
    });
};

/**
 * Update the users own listing. This can be simplified from traditional Sharetribe point of view,
 * since a user can only have a single listing. We therefore do not need to pass along an identifier
 * for the users listing but can instead fetch the identifier from the state.
 *
 * @param params
 * @returns {function(*, *, *): *}
 */
export const updateOwnListing = (params) => (dispatch, getState, sdk) => {
  dispatch(updateOwnListingRequest());
  return dispatch(fetchCurrentUser(null, true))
    .then((user) => {
      return sharetribe.user.ensureCurrentUser(user);
    })
    .then((currentUser) => {
      return sdk.ownListings.update({
        id: currentUser.attributes.profile.publicData.listingId,
        ...params,
      });
    })
    .then(async (sdkResponse) => {
      await dispatch(addOwnEntities(sdkResponse));
      return getOwnListingsById(getState(), [sdkResponse.data.data.id]);
    })
    .then((ownListings) => {
      const ownListing = sharetribe.listing.ensureOwnListing(ownListings[0]);
      dispatch(updateOwnListingSuccess(ownListing));
      return ownListing;
    })
    .catch((err) => {
      console.error('Failed to update own listing', err);
      dispatch(updateOwnListingFailure(storableError(err)));
      return Promise.reject(err);
    });
};

/**
 * Ensures the creation or proper binding of the users listing.
 *
 * @return {function(*, *, *): Promise<unknown>}
 */
export const createOwnListing = () => (dispatch, getState, sdk) => {
  dispatch(createOwnListingRequest());

  return new Promise(async (resolve, reject) => {
    // Fetch the current user (in case this was not done already)
    const currentUser = sharetribe.user.ensureCurrentUser(
      await dispatch(fetchCurrentUser(null, true))
    );

    // If the current users listing ID is set, we know that there must be a listing and return to
    // the default behavior where the listing is simply shown
    if (currentUser.attributes.profile.publicData.listingId) {
      const ownListing = await dispatch(showOwnListing());
      return resolve(ownListing);
    }

    // At this point we can be sure that the listing either does not exist or is the user profile
    // data is not up to date. To prevent duplicate listings we will first check if the user has any
    // listings.
    const sdkResponse = await sdk.ownListings.query({});
    if (sdkResponse.data.meta.totalItems > 0) {
      // At this stage we know the user has at least one or more listings. We will pick the one that
      // was created last and bind it to the user.
      await dispatch(setOwnListing(sharetribe.listing.ensureOwnListing(sdkResponse.data.data[0])));
    } else {
      // At this point we are certain the user has no listings whatsoever. So we can safely create
      // one and bind it to them.
      const { firstName, lastName } = currentUser.attributes.profile.publicData;
      const { calendarToken, signUpSource } = currentUser.attributes.profile.privateData;
      const createListingSdkResponse = await dispatch(
        requestCreateListingDraft({
          availabilityPlan: {
            type: 'availability-plan/time',
            timezone: moment.tz.guess(),
            entries: [],
          },
          title: `${firstName} ${lastName}`,
          publicData: {
            firstName,
            isPendingActivation: true,
            lastName,
            listingType: PROFILE_TYPE_NEW,
          },
          privateData: {
            calendarToken,
            signUpSource,
          },
        })
      );
      await dispatch(
        setOwnListing(sharetribe.listing.ensureOwnListing(createListingSdkResponse.data.data))
      );
    }

    // Final check
    const { ownListing } = getState().ownListing;
    if (!ownListing || !ownListing.id || !ownListing.id.uuid) {
      dispatch(createOwnListingFailure('Own listing was not proporly created'));
      return reject('Create own listing failed!');
    }

    // Ensure binding to the user profile
    await dispatch(
      updateProfile({
        publicData: {
          listingId: ownListing.id.uuid,
        },
      })
    );
    await dispatch(fetchCurrentUser(null, true));
    dispatch(createOwnListingSuccess(ownListing));

    return resolve(ownListing);
  });
};
