import utils from '@givsly/sharetribe-utils';
import Decimal from 'decimal.js';
import { ensureTransaction } from './data';
import { convertMoneyToNumber } from './currency';
import {
  OPERATOR_FEE_PERCENTAGE,
  PAYMENT_FEE_PERCENTAGE,
  PAYMENT_FEE_ADDITION,
} from '../marketplace-custom-config';
import { types as sdkTypes } from './sdkLoader';
import config from '../config';
import {
  LINE_ITEM_DONATION,
  LINE_ITEM_GIVSLY_CREDIT,
  LINE_ITEM_OPERATOR_FEE,
  LINE_ITEM_PAYMENT_FEE,
} from './types';

const { Money, UUID } = sdkTypes;

/**
 * Transitions
 *
 * These strings must sync with values defined in Flex API,
 * since transaction objects given by API contain info about last transitions.
 * All the actions in API side happen in transitions,
 * so we need to understand what those strings mean.
 *
 * @todo move all functionality over to @givsly/sharetribe-utils, partially obsolete
 */

export const ESTIMATED_TRANSACTION_ID = 'estimated-transaction';

// When a customer makes a booking to a listing, a transaction is
// created with the initial request-payment transition.
// At this transition a PaymentIntent is created by Marketplace API.
// After this transition, the actual payment must be made on client-side directly to Stripe.
export const TRANSITION_REQUEST_PAYMENT = 'transition/request-payment';

export const TRANSITION_BOOK = 'transition/book';
export const TRANSITION_PROPOSE = 'transition/propose';
export const TRANSITION_BOOK_AFTER_ENQUIRY = 'transition/book-after-enquiry';
export const TRANSITION_BOOK_AFTER_PROPOSAL = 'transition/book-after-proposal';

// A customer can also initiate a transaction with an enquiry, and
// then transition that with a request.
export const TRANSITION_ENQUIRE = 'transition/enquire';
export const TRANSITION_REQUEST_PAYMENT_AFTER_ENQUIRY = 'transition/request-payment-after-enquiry';

// Stripe SDK might need to ask 3D security from customer, in a separate front-end step.
// Therefore we need to make another transition to Marketplace API,
// to tell that the payment is confirmed.
export const TRANSITION_CONFIRM_PAYMENT = 'transition/confirm-payment';

// If the payment is not confirmed in the time limit set in transaction process (by default 15min)
// the transaction will expire automatically.
export const TRANSITION_EXPIRE_PAYMENT = 'transition/expire-payment';

// When the provider accepts or declines a transaction from the
// SalePage, it is transitioned with the accept or decline transition.
export const TRANSITION_ACCEPT = 'transition/accept';
export const TRANSITION_DECLINE = 'transition/decline';
export const TRANSITION_DECLINE_PROPOSAL = 'transition/decline-proposal';

// The backend automatically expire the transaction.
export const TRANSITION_EXPIRE = 'transition/expire';

// Admin can also cancel the transition.
export const TRANSITION_CANCEL = 'transition/cancel';

export const TRANSITION_CUSTOMER_CANCEL = 'transition/customer-cancel';
export const TRANSITION_PROVIDER_CANCEL = 'transition/provider-cancel';

// The backend will mark the transaction completed.
export const TRANSITION_COMPLETE = 'transition/complete';

// Reviews are given through transaction transitions. Review 1 can be
// by provider or customer, and review 2 will be the other party of
// the transaction.
export const TRANSITION_REVIEW_1_BY_PROVIDER = 'transition/review-1-by-provider';
export const TRANSITION_REVIEW_2_BY_PROVIDER = 'transition/review-2-by-provider';
export const TRANSITION_REVIEW_1_BY_CUSTOMER = 'transition/review-1-by-customer';
export const TRANSITION_REVIEW_2_BY_CUSTOMER = 'transition/review-2-by-customer';
export const TRANSITION_EXPIRE_CUSTOMER_REVIEW_PERIOD = 'transition/expire-customer-review-period';
export const TRANSITION_EXPIRE_PROVIDER_REVIEW_PERIOD = 'transition/expire-provider-review-period';
export const TRANSITION_EXPIRE_REVIEW_PERIOD = 'transition/expire-review-period';

// Cancel transitions for the payment transaction
export const TRANSITION_CANCEL_AFTER_CAPTURE = 'transition/cancel-after-capture';
export const TRANSITION_CANCEL_BEFORE_CAPTURE = 'transition/cancel-before-capture';

/**
 * Actors
 *
 * There are 4 different actors that might initiate transitions:
 */

// Roles of actors that perform transaction transitions
export const TX_TRANSITION_ACTOR_CUSTOMER = 'customer';
export const TX_TRANSITION_ACTOR_PROVIDER = 'provider';
export const TX_TRANSITION_ACTOR_SYSTEM = 'system';
export const TX_TRANSITION_ACTOR_OPERATOR = 'operator';

export const TX_TRANSITION_ACTORS = [
  TX_TRANSITION_ACTOR_CUSTOMER,
  TX_TRANSITION_ACTOR_PROVIDER,
  TX_TRANSITION_ACTOR_SYSTEM,
  TX_TRANSITION_ACTOR_OPERATOR,
];

/**
 * States
 *
 * These constants are only for making it clear how transitions work together.
 * You should not use these constants outside of this file.
 *
 * Note: these states are not in sync with states used transaction process definitions
 *       in Marketplace API. Only last transitions are passed along transaction object.
 */
const STATE_INITIAL = 'initial';
const STATE_ENQUIRY = 'enquiry';
const STATE_PENDING_PAYMENT = 'pending-payment';
const STATE_PAYMENT_EXPIRED = 'payment-expired';
const STATE_EXPIRED = 'expired';
const STATE_PREAUTHORIZED = 'preauthorized';
const STATE_PENDING = 'pending';
const STATE_PROPOSAL = 'proposal';
const STATE_DECLINED = 'declined';
const STATE_ACCEPTED = 'accepted';
const STATE_CANCELED = 'canceled';
const STATE_DELIVERED = 'delivered';
const STATE_REVIEWED = 'reviewed';
const STATE_REVIEWED_BY_CUSTOMER = 'reviewed-by-customer';
const STATE_REVIEWED_BY_PROVIDER = 'reviewed-by-provider';

/**
 * Description of transaction process
 *
 * You should keep this in sync with transaction process defined in Marketplace API
 *
 * Note: we don't use yet any state machine library,
 *       but this description format is following Xstate (FSM library)
 *       https://xstate.js.org/docs/
 */
const stateDescription = {
  // id is defined only to support Xstate format.
  // However if you have multiple transaction processes defined,
  // it is best to keep them in sync with transaction process aliases.
  id: 'no-payment-time-booking/release-2',

  // This 'initial' state is a starting point for new transaction
  initial: STATE_INITIAL,

  // States
  states: {
    [STATE_INITIAL]: {
      on: {
        [TRANSITION_ENQUIRE]: STATE_ENQUIRY,
        [TRANSITION_BOOK]: STATE_PENDING,
        [TRANSITION_PROPOSE]: STATE_PROPOSAL,
      },
    },
    [STATE_ENQUIRY]: {
      on: {
        [TRANSITION_BOOK_AFTER_ENQUIRY]: STATE_PENDING,
      },
    },
    [STATE_PROPOSAL]: {
      on: {
        [TRANSITION_DECLINE_PROPOSAL]: STATE_DECLINED,
        [TRANSITION_BOOK_AFTER_PROPOSAL]: STATE_PENDING,
      },
    },
    [STATE_PENDING]: {
      on: {
        [TRANSITION_DECLINE]: STATE_DECLINED,
        [TRANSITION_EXPIRE]: STATE_DECLINED,
        [TRANSITION_ACCEPT]: STATE_ACCEPTED,
      },
    },

    [STATE_DECLINED]: {},
    [STATE_ACCEPTED]: {
      on: {
        [TRANSITION_CANCEL]: STATE_CANCELED,
        [TRANSITION_CUSTOMER_CANCEL]: STATE_CANCELED,
        [TRANSITION_PROVIDER_CANCEL]: STATE_CANCELED,
        [TRANSITION_COMPLETE]: STATE_DELIVERED,
      },
    },

    [STATE_CANCELED]: {},
    [STATE_DELIVERED]: {},
  },
};

const paymentStateDescription = {
  // id is defined only to support Xstate format.
  // However if you have multiple transaction processes defined,
  // it is best to keep them in sync with transaction process aliases.
  id: 'payment-only/release-2',

  // This 'initial' state is a starting point for new transaction
  initial: STATE_INITIAL,

  // States
  states: {
    [STATE_INITIAL]: {
      on: {
        [TRANSITION_REQUEST_PAYMENT]: STATE_PENDING_PAYMENT,
      },
    },

    [STATE_PENDING_PAYMENT]: {
      on: {
        [TRANSITION_EXPIRE_PAYMENT]: STATE_PAYMENT_EXPIRED,
        [TRANSITION_CONFIRM_PAYMENT]: STATE_PREAUTHORIZED,
      },
    },

    [STATE_PAYMENT_EXPIRED]: {},
    [STATE_PREAUTHORIZED]: {
      on: {
        [TRANSITION_EXPIRE]: STATE_EXPIRED,
        [TRANSITION_ACCEPT]: STATE_ACCEPTED,
      },
    },

    [STATE_ACCEPTED]: {
      on: {
        [TRANSITION_COMPLETE]: STATE_DELIVERED,
      },
    },

    [STATE_DELIVERED]: {},
  },
};

// Note: currently we assume that state description doesn't contain nested states.
const statesFromStateDescription = (description) => description.states || {};

// Get all the transitions from states object in an array
const getTransitions = (states) => {
  const stateNames = Object.keys(states);

  const transitionsReducer = (transitionArray, name) => {
    const stateTransitions = states[name] && states[name].on;
    const transitionKeys = stateTransitions ? Object.keys(stateTransitions) : [];
    return [
      ...transitionArray,
      ...transitionKeys.map((key) => ({ key, value: stateTransitions[key] })),
    ];
  };

  return stateNames.reduce(transitionsReducer, []);
};

export const PAYMENT_TRANSITIONS = getTransitions(
  statesFromStateDescription(paymentStateDescription)
).map((t) => t.key);

// This is a list of all the transitions that this app should be able to handle.
export const TRANSITIONS = getTransitions(statesFromStateDescription(stateDescription))
  .map((t) => t.key)
  .concat(PAYMENT_TRANSITIONS);

// This function returns a function that has given stateDesc in scope chain.
const getTransitionsToStateFn = (stateDesc) => (state) =>
  getTransitions(statesFromStateDescription(stateDesc))
    .filter((t) => t.value === state)
    .map((t) => t.key);

// Get all the transitions that lead to specified state.
const getTransitionsToState = getTransitionsToStateFn(stateDescription);

// This is needed to fetch transactions that need response from provider.
// I.e. transactions which provider needs to accept or decline
export const transitionsToRequested = getTransitionsToState(STATE_PENDING);

/**
 * Helper functions to figure out if transaction is in a specific state.
 * State is based on lastTransition given by transaction object and state description.
 */

const txLastTransition = (tx) => ensureTransaction(tx).attributes.lastTransition;

export const txIsProposed = (tx) =>
  getTransitionsToState(STATE_PROPOSAL).includes(txLastTransition(tx));

export const txIsPending = (tx) =>
  getTransitionsToState(STATE_PENDING).includes(txLastTransition(tx));

export const txIsEnquired = (tx) =>
  getTransitionsToState(STATE_ENQUIRY).includes(txLastTransition(tx));

export const txIsPaymentPending = (tx) =>
  getTransitionsToState(STATE_PENDING_PAYMENT).includes(txLastTransition(tx));

export const txIsPaymentExpired = (tx) =>
  getTransitionsToState(STATE_PAYMENT_EXPIRED).includes(txLastTransition(tx));

// Note: state name used in Marketplace API docs (and here) is actually preauthorized
// However, word "requested" is used in many places so that we decided to keep it.
export const txIsRequested = (tx) =>
  getTransitionsToState(STATE_PREAUTHORIZED).includes(txLastTransition(tx));

export const txIsAccepted = (tx) =>
  getTransitionsToState(STATE_ACCEPTED).includes(txLastTransition(tx));

export const txIsDeclined = (tx) =>
  getTransitionsToState(STATE_DECLINED).includes(txLastTransition(tx));

export const txIsCanceled = (tx) =>
  getTransitionsToState(STATE_CANCELED).includes(txLastTransition(tx));

export const txIsDelivered = (tx) =>
  getTransitionsToState(STATE_DELIVERED).includes(txLastTransition(tx));

const firstReviewTransitions = [
  ...getTransitionsToState(STATE_REVIEWED_BY_CUSTOMER),
  ...getTransitionsToState(STATE_REVIEWED_BY_PROVIDER),
];
export const txIsInFirstReview = (tx) => firstReviewTransitions.includes(txLastTransition(tx));

export const txIsInFirstReviewBy = (tx, isCustomer) =>
  isCustomer
    ? getTransitionsToState(STATE_REVIEWED_BY_CUSTOMER).includes(txLastTransition(tx))
    : getTransitionsToState(STATE_REVIEWED_BY_PROVIDER).includes(txLastTransition(tx));

export const txIsReviewed = (tx) =>
  getTransitionsToState(STATE_REVIEWED).includes(txLastTransition(tx));

/**
 * Helper functions to figure out if transaction has passed a given state.
 * This is based on transitions history given by transaction object.
 */

const txTransitions = (tx) => ensureTransaction(tx).attributes.transitions || [];
const hasPassedTransition = (transitionName, tx) =>
  !!txTransitions(tx).find((t) => t.transition === transitionName);

const hasPassedStateFn = (state) => (tx) =>
  getTransitionsToState(state).filter((t) => hasPassedTransition(t, tx)).length > 0;

export const txHasBeenAccepted = hasPassedStateFn(STATE_ACCEPTED);
export const txHasBeenDelivered = hasPassedStateFn(STATE_DELIVERED);

/**
 * Other transaction related utility functions
 */

export const transitionIsReviewed = (transition) =>
  getTransitionsToState(STATE_REVIEWED).includes(transition);

export const transitionIsFirstReviewedBy = (transition, isCustomer) =>
  isCustomer
    ? getTransitionsToState(STATE_REVIEWED_BY_CUSTOMER).includes(transition)
    : getTransitionsToState(STATE_REVIEWED_BY_PROVIDER).includes(transition);

export const getReview1Transition = (isCustomer) =>
  isCustomer ? TRANSITION_REVIEW_1_BY_CUSTOMER : TRANSITION_REVIEW_1_BY_PROVIDER;

export const getReview2Transition = (isCustomer) =>
  isCustomer ? TRANSITION_REVIEW_2_BY_CUSTOMER : TRANSITION_REVIEW_2_BY_PROVIDER;

// Check if a transition is the kind that should be rendered
// when showing transition history (e.g. ActivityFeed)
// The first transition and most of the expiration transitions made by system are not relevant
export const isRelevantPastTransition = (transition) => {
  return [
    TRANSITION_PROPOSE,
    TRANSITION_BOOK,
    TRANSITION_ACCEPT,
    utils.transaction.TRANSITION_ACCEPT_AFTER_BOOK_PROPOSAL,
    TRANSITION_CANCEL,
    TRANSITION_PROVIDER_CANCEL,
    TRANSITION_CUSTOMER_CANCEL,
    TRANSITION_COMPLETE,
    TRANSITION_CONFIRM_PAYMENT,
    TRANSITION_DECLINE,
    TRANSITION_DECLINE_PROPOSAL,
    TRANSITION_EXPIRE,
    TRANSITION_REVIEW_1_BY_CUSTOMER,
    TRANSITION_REVIEW_1_BY_PROVIDER,
    TRANSITION_REVIEW_2_BY_CUSTOMER,
    TRANSITION_REVIEW_2_BY_PROVIDER,
  ].includes(transition);
};

export const isCustomerReview = (transition) => {
  return [TRANSITION_REVIEW_1_BY_CUSTOMER, TRANSITION_REVIEW_2_BY_CUSTOMER].includes(transition);
};

export const isProviderReview = (transition) => {
  return [TRANSITION_REVIEW_1_BY_PROVIDER, TRANSITION_REVIEW_2_BY_PROVIDER].includes(transition);
};

export const getUserTxRole = (currentUserId, transaction) => {
  const tx = ensureTransaction(transaction);
  const customer = tx.customer;
  if (currentUserId && currentUserId.uuid && tx.id && customer.id) {
    // user can be either customer or provider
    return currentUserId.uuid === customer.id.uuid
      ? TX_TRANSITION_ACTOR_CUSTOMER
      : TX_TRANSITION_ACTOR_PROVIDER;
  } else {
    throw new Error(`Parameters for "userIsCustomer" function were wrong.
      currentUserId: ${currentUserId}, transaction: ${transaction}`);
  }
};

/**
 * Calculate the operator fee from the price. Takes the fee deduction of the total price into
 * account if applicable.
 *
 * @param unitPrice
 * @param quantity
 */
export const getOperatorFee = (unitPrice, quantity) => {
  const price = convertMoneyToNumber(unitPrice) * quantity;
  return new Money(price * OPERATOR_FEE_PERCENTAGE * 100, config.currency);
};

/**
 * Calculate the payment fee from the price. Takes the fee deduction of the total price into account
 * if applicable.
 *
 * @param unitPrice
 * @param quantity
 * @param deductFee
 * @param creditTotal
 */
export const getPaymentFee = (unitPrice, quantity, deductFee, creditTotal = 0) => {
  const price = convertMoneyToNumber(unitPrice) * quantity - creditTotal;
  const paymentFee = new Money(0, config.currency);

  // console.log('getPaymentFee transactions.js');
  // console.log(unitPrice, quantity, deductFee, creditTotal);

  if (deductFee) {
    paymentFee.amount =
      (((price - PAYMENT_FEE_ADDITION) / (1 + PAYMENT_FEE_PERCENTAGE)) * PAYMENT_FEE_PERCENTAGE +
        PAYMENT_FEE_ADDITION) *
      100;
    // console.log('===!===');
    // console.log('deduct fee...:', paymentFee.amount);
  } else {
    const operatorFee = convertMoneyToNumber(getOperatorFee(unitPrice, quantity));
    paymentFee.amount =
      ((price + operatorFee) * PAYMENT_FEE_PERCENTAGE + PAYMENT_FEE_ADDITION) * 100;

    // console.log('===!===');
    // console.log('no deduct fee... current formula:', paymentFee.amount);
    // console.log(
    //   'no deduct fee... potential formula:',
    //   ((price + operatorFee + PAYMENT_FEE_ADDITION) * PAYMENT_FEE_PERCENTAGE +
    //     PAYMENT_FEE_ADDITION) *
    //     100
    // );

    // TODO @PAULBROWNE - check this...
    // paymentFee.amount = ((price + operatorFee + PAYMENT_FEE_ADDITION) * PAYMENT_FEE_PERCENTAGE + PAYMENT_FEE_ADDITION) * 100;
    // ALl of the above is a bit of a mystery as to where and how it is used
  }

  return paymentFee;
};

/**
 * Calculate the total fee from the price. This includes the payment and operator fees. Takes the
 * fee deduction of the total price into account.
 *
 * @param unitPrice
 * @param quantity
 * @param deductFee
 * @param creditTotal
 */
export const getTotalFees = (unitPrice, quantity, deductFee, creditTotal = 0) => {
  return new Money(
    (convertMoneyToNumber(getPaymentFee(unitPrice, quantity, deductFee, creditTotal)) +
      convertMoneyToNumber(getOperatorFee(unitPrice, quantity))) *
      100,
    config.currency
  );
};

export const getTotalDonation = (
  unitPrice,
  quantity,
  deductFee,
  creditTotal = 0,
  roundValue = false
) => {
  const donation = new Money(convertMoneyToNumber(unitPrice) * quantity * 100, config.currency);

  if (deductFee) {
    const totalFees = getTotalFees(unitPrice, quantity, deductFee, creditTotal);
    donation.amount =
      (convertMoneyToNumber(unitPrice) * quantity - convertMoneyToNumber(totalFees)) * 100;
  }

  // When creating a payment transaction the donation value must be round because it's the only
  // calculated value in the transaction. Unfortunately Sharetribe does not handle round well on
  // their side so it must be done here.
  //
  // For example `126.5051` will be rounded up by Sharetribe to `126.50`, while it should be rounded
  // up to `126.51`. Making a $0.01 difference on the total transaction value.
  if (roundValue) {
    donation.amount = Math.round(donation.amount);
  }

  return donation;
};

export const getTotal = (unitPrice, quantity, deductFee, creditTotal = 0) => {
  const donation = getTotalDonation(unitPrice, quantity, deductFee, creditTotal);
  const fees = getTotalFees(unitPrice, quantity, deductFee, creditTotal);

  return new Money(donation.amount + fees.amount, config.currency);
};

export const getDonationLineItem = (ensuredTransaction) => {
  return ensuredTransaction.attributes.lineItems.find((lineItem) => {
    return lineItem.code === LINE_ITEM_DONATION && !lineItem.reversal;
  });
};

export const isDeductFee = (ensuredTransaction) => {
  const { deductFee = null } = ensuredTransaction.attributes.protectedData;
  return deductFee;
};

export const getQuantity = (ensuredTransaction) => {
  const donationLineItem = getDonationLineItem(ensuredTransaction);
  return parseInt(donationLineItem ? donationLineItem.quantity : null);
};

export const getDonationUnitPrice = (ensuredTransaction) => {
  const deductFee = isDeductFee(ensuredTransaction);
  const quantity = getQuantity(ensuredTransaction);
  const donationLineItem = getDonationLineItem(ensuredTransaction);

  return new Money(
    ((deductFee
      ? convertMoneyToNumber(ensuredTransaction.attributes.payinTotal)
      : convertMoneyToNumber(donationLineItem.lineTotal)) /
      quantity) *
      100,
    config.currency
  );
};

export const getRefundTotalFromTransaction = (transaction) => {
  return 0;
};

export const getRefundPaymentFeeFromTransaction = (transaction) => {
  return 0;
};

export const txRoleIsProvider = (userRole) => userRole === TX_TRANSITION_ACTOR_PROVIDER;
export const txRoleIsCustomer = (userRole) => userRole === TX_TRANSITION_ACTOR_CUSTOMER;

/**
 * This must be done inside this service, otherwise the exact type matching will not work. This
 * could be migrated at some point, but that would mean all Sharetribe types will need to be pulled
 * from the same location.
 *
 * @param breakdown
 * @returns {{creditDeduction: *, operatorFee: *, paymentFee: *, total: *, donation: *, subTotal: *}}
 */
const convertToMoneyBreakdown = (breakdown) => {
  const { donation, operatorFee, paymentFee, creditDeduction, subTotal, total } = breakdown;
  const { currency } = config;

  return {
    donation: new Money(donation, currency),
    operatorFee: new Money(operatorFee, currency),
    paymentFee: new Money(paymentFee, currency),
    creditDeduction: new Money(creditDeduction, currency),
    subTotal: new Money(subTotal, currency),
    total: new Money(total, currency),
  };
};

export const getEstimatedLineItems = (breakdown) => {
  const { donation, operatorFee, paymentFee, creditDeduction } = breakdown;

  // Base line items, these are always visible
  const lineItems = [
    {
      code: LINE_ITEM_DONATION,
      includeFor: ['customer', 'provider'],
      unitPrice: donation,
      quantity: new Decimal(1),
      lineTotal: donation,
      reversal: false,
    },
    {
      code: LINE_ITEM_OPERATOR_FEE,
      includeFor: ['customer', 'provider'],
      unitPrice: operatorFee,
      quantity: new Decimal(1),
      lineTotal: operatorFee,
      reversal: false,
    },
  ];

  // Add payment fee if any
  if (paymentFee.amount) {
    lineItems.push({
      code: LINE_ITEM_PAYMENT_FEE,
      includeFor: ['customer'],
      unitPrice: paymentFee,
      quantity: new Decimal(1),
      lineTotal: paymentFee,
      reversal: false,
    });
  }

  // Add credit deduction if any
  if (creditDeduction.amount > 0) {
    lineItems.push({
      code: LINE_ITEM_GIVSLY_CREDIT,
      includeFor: ['customer'],
      unitPrice: creditDeduction,
      quantity: new Decimal(-1),
      lineTotal: creditDeduction,
      reversal: true,
    });
  }

  return lineItems;
};

/**
 * When we cannot speculatively initiate a transaction (i.e. logged out), we must estimate the
 * booking breakdown. This function creates an estimated transaction object for that use case.
 *
 * @param startDate
 * @param endDate
 * @param meetingMethod
 * @param methodPriceChoices
 * @param quantity
 * @param deductFee
 * @param availableCredit
 * @param operatorPercentage
 * @returns {{booking: {attributes: {start: Date, end: Date}, id: *, type: string}, attributes: {lineItems: [], createdAt: Date, lastTransitionedAt: Date, payinTotal: *, payoutTotal: *, lastTransition: string, protectedData: {deductFee: *, meetingMethod: *}, transitions: [{createdAt: *, by: string, transition: string}]}, id: *, type: string}}
 */
export const getEstimatedTransaction = (
  startDate,
  endDate,
  meetingMethod,
  methodPriceChoices,
  quantity,
  deductFee,
  availableCredit = 0,
  operatorPercentage
) => {
  const now = new Date();

  // Get the Money object from the price of the selected method. The format for these options is
  // `choice_xxx`, where `xxx` represents and integer of floating point value of the price.
  const price = new Money(
    parseFloat(methodPriceChoices[meetingMethod].split('_').pop()) * 100,
    config.currency
  );

  const breakdown = convertToMoneyBreakdown(
    utils.transaction.calculateBreakdown(
      price,
      quantity,
      deductFee,
      availableCredit,
      operatorPercentage
    )
  );
  const lineItems = utils.transaction.convertBreakdownToLineItems(breakdown);

  // As the current structure doesn't allow for ordering of multiple items. The code is simplified
  // to automatically default to a single of each line item (`quantity`).
  return {
    id: new UUID(ESTIMATED_TRANSACTION_ID),
    type: 'transaction',
    attributes: {
      createdAt: now,
      lastTransitionedAt: now,
      lastTransition: TRANSITION_REQUEST_PAYMENT,
      payinTotal: breakdown.total,
      payoutTotal: breakdown.donation,
      lineItems: lineItems,
      transitions: [
        {
          createdAt: now,
          by: TX_TRANSITION_ACTOR_CUSTOMER,
          transition: TRANSITION_REQUEST_PAYMENT,
        },
      ],
      protectedData: {
        deductFee,
        meetingMethod,
      },
    },
    booking: {
      id: new UUID('estimated-booking'),
      type: 'booking',
      attributes: {
        start: startDate && startDate.toDate(),
        end: endDate && endDate.toDate(),
      },
    },
  };
};
