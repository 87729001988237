import React from 'react';
import classNames from 'classnames';
import css from './IconAddMeeting.css';

const IconAddMeeting = ({ className }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 16 16"
    className={classNames(className, css.icon)}
  >
    <path d="M14 3c1.105 0 2 .895 2 2v9c0 1.105-.895 2-2 2H5c-1.105 0-2-.895-2-2v-4h1.5v3.574c0 .475.357.866.818.92l.108.006h8.148c.475 0 .866-.357.92-.818l.006-.108V5.426c0-.475-.357-.866-.818-.92l-.108-.006H10V3h4zm-6 8v1H7v-1h1zm2 0v1H9v-1h1zM8 9v1H7V9h1zm2 0v1H9V9h1zm2 0v1h-1V9h1zM8 7v1H7V7h1zm2 0v1H9V7h1zm2 0v1h-1V7h1zM4.5 0v3h3v1.5h-3v3H3v-3H0V3h3V0h1.5z" />
  </svg>
);

export default IconAddMeeting;
