import { bool } from 'prop-types';
import React from 'react';
import { useMessages } from '../../util/localization';
import NamedLink from '../NamedLink/NamedLink';
import { useHistory, useLocation } from 'react-router-dom';
import { AVAILABILITY } from '../OnboardingWizard/constants';
import css from './ValueProps.css';

import img1 from './img-usp-1@3x.png';
import img2 from './img-usp-2@3x.png';
import img3 from './img-usp-3@3x.png';
import img4 from './img-usp-4@3x.png';

const MOBILE_SEARCH_KEY = 'mobilesearch';

const ValueProps = (props) => {
  const getMessage = useMessages('ValueProps');
  const history = useHistory();
  const location = useLocation();

  const { isAuthenticated } = props;

  return (
    <section className={css.root}>
      <div className={css.wrapper}>
        {[img1, img2, img3, img4].map((img, index) => {
          const step = getMessage(`${index + 1}.step`);
          const title = getMessage(`${index + 1}.title`);
          const copy = getMessage(`${index + 1}.copy`);
          const ctaText = getMessage(`${index + 1}.cta.text`);
          const ctaName = getMessage(`${index + 1}.cta.name`);
          const ctaHref = getMessage(`${index + 1}.cta.href`);

          return (
            <div className={css.valuePropContainer}>
              <div className={css.texts}>
                <p className={css.step}>{step}</p>
                <h1 className={css.title}>{title}</h1>
                <p className={css.copy}>{copy}</p>
                {index === 0 ? (
                  <NamedLink className={css.cta} name={ctaName}>
                    <span>{ctaText}</span>
                  </NamedLink>
                ) : (
                  <a className={css.cta} href={ctaHref}>
                    <span>{ctaText}</span>
                  </a>
                )}
              </div>
              <div className={css.image}>
                <img src={img} />
              </div>
            </div>
          );
        })}
      </div>
    </section>
  );
};

ValueProps.propTypes = {
  isAuthenticated: bool,
};

ValueProps.defaultProps = {
  isAuthenticated: false,
};

export default ValueProps;
