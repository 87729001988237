import React from 'react';
import { bool, func, object, oneOfType, string } from 'prop-types';
import { withMessages } from '../../util/localization';
import css from './Hint.css';
import classNames from 'classnames';

const Hint = (props) => {
  const { canClose, className, onClose, title, text } = props;
  const classes = classNames(css.root, className);

  return (
    <div className={classes}>
      {title ? <span className={css.title}>{title}</span> : null}
      <p className={css.text}>{text}</p>
      {canClose ? <button className={css.close} type={'button'} onClick={onClose} /> : null}
    </div>
  );
};

Hint.propTypes = {
  className: string,
  canClose: bool,
  onClose: func,
  title: string,
  text: oneOfType([string, object]).isRequired,
};

Hint.defaultProps = {
  className: null,
  canClose: false,
  onClose: null,
};

export default withMessages(Hint);
