import React, { useState, useMemo, useCallback, useEffect } from 'react';
import { Form } from 'react-final-form';
import { createResourceLocatorString } from '../../util/routes';
import routeConfiguration from '../../routeConfiguration';
import {
  BRAND_TEMPLATE,
  EVENT_TEMPLATE,
  EXCLUDED_AREA_TEMPLATE,
  IMPACT_AREA_TEMPLATE,
  ONBOARDING_STEPS,
  PARTNERSHIP_TABS,
} from './constants';

const AutoSaveForm = ({
  savePartnershipInformation,
  handleSubmit,
  children,
  ...formRenderProps
}) => {
  const { values, invalid } = formRenderProps;

  useEffect(() => {
    console.log({ invalid });
    if (!invalid) {
      const timeout = setTimeout(() => {
        savePartnershipInformation(values);
      }, 3000);

      return () => {
        window.clearTimeout(timeout);
      };
    }
  }, [values]);

  return <form onSubmit={handleSubmit}>{children(formRenderProps)}</form>;
};

const PartnershipsForm = ({
  user,
  listing,
  onUpdateListing,
  onUploadS3Image,
  children,
  setDisclaimerVisible,
  history,
}) => {
  const {
    id: listingId,
    attributes: {
      publicData: {
        goodAdvertisingOnboardingProgress = {},
        timestamps: {
          good_advertising_partnership_created,
          good_advertising_partnership_updated,
        } = {},
        approvePortfolioPresentation,
        partners,
        collaborationWishes,
        events,
        brandingGuidelines,
        approvalContact = {},
        exclusions: { exclude, brands, industries, keywords, areas } = {},
        impactArea,
        causes,
      } = {},
    } = {},
  } = listing || {};

  useEffect(() => {
    setDisclaimerVisible(!approvePortfolioPresentation);
  }, []); // we only want initial state on load, disregard future changes

  const PARTNERSHIP_INFORMATION_COMPLETE = !!goodAdvertisingOnboardingProgress[
    ONBOARDING_STEPS.PARTNERSHIP_INFORMATION
  ];
  const CAUSES_AND_EVENTS_COMPLETE = !!goodAdvertisingOnboardingProgress[
    ONBOARDING_STEPS.CAUSES_AND_EVENTS
  ];
  const BRAND_COLLABORATIONS_COMPLETE = !!goodAdvertisingOnboardingProgress[
    ONBOARDING_STEPS.BRAND_COLLABORATIONS
  ];
  const EXCLUSIONS_COMPLETE = !!goodAdvertisingOnboardingProgress[ONBOARDING_STEPS.EXCLUSIONS];

  const initialValues = useMemo(
    () => ({
      good_advertising_partnership_created: good_advertising_partnership_created || '',
      good_advertising_partnership_updated: good_advertising_partnership_updated || '',
      approvePortfolioPresentation: approvePortfolioPresentation ? ['Yes'] : [],
      approvalContact: {
        name: approvalContact.name || '',
        email: approvalContact.email || '',
      },
      exclusions: {
        exclude: exclude ? ['Yes'] : [],
        brands: brands && brands.length > 0 ? brands : [{ ...BRAND_TEMPLATE }],
        industries: industries && industries.length > 0 ? industries : [],
        keywords: keywords && keywords.length > 0 ? keywords : [''],
        areas: areas && areas.length > 0 ? areas : [{ ...EXCLUDED_AREA_TEMPLATE }],
      },
      impactArea: impactArea || [{ ...IMPACT_AREA_TEMPLATE }],
      partners: partners && partners.length > 0 ? partners : [{ ...BRAND_TEMPLATE }],
      collaborationWishes:
        collaborationWishes && collaborationWishes.length > 0
          ? collaborationWishes
          : [{ ...BRAND_TEMPLATE }],
      events:
        events && events.length > 0
          ? events.map((event) => ({
              ...event,
              start: event.start ? { date: new Date(event.start) } : {},
              end: event.end ? { date: new Date(event.end) } : {},
              yearRound: event.yearRound ? ['Yes'] : [],
            }))
          : [{ ...EVENT_TEMPLATE }],
      brandingGuidelines: brandingGuidelines || {
        guidelineUrl: '',
        logoLightUrl: '',
        logoDarkUrl: '',
      },
      causes: causes && causes.length > 0 ? causes : [''],
    }),
    [listingId] // <- kind of risky but works
  );

  // Easily navigate between tabs with history preservation
  const navigateToTab = useCallback(
    (tab = PARTNERSHIP_TABS.APPROVAL) => {
      history.push(
        createResourceLocatorString('PartnershipsTabPage', routeConfiguration(), { tab }, {})
      );
    },
    [history]
  );

  const [isSubmitting, setIsSubmitting] = useState(false);
  const savePartnershipInformation = useCallback(
    async (values, routes = {}) => {
      const {
        brandingGuidelines: { guidelineUrl, logoLightUrl, logoDarkUrl },
        approvalContact,
        impactArea,
      } = values;

      const approvePortfolioPresentation =
        Array.isArray(values.approvePortfolioPresentation) &&
        values.approvePortfolioPresentation.length === 1 &&
        values.approvePortfolioPresentation.includes('Yes');

      const exclude =
        Array.isArray(values.exclusions.exclude) &&
        values.exclusions.exclude.length === 1 &&
        values.exclusions.exclude.includes('Yes');

      const [logoLightUploadResult, logoDarkUploadResult] = await Promise.allSettled(
        [logoLightUrl, logoDarkUrl].map(async (url) => {
          // if blob data url
          if (url && /^data:image/.test(url)) {
            const { message: s3Url } = await onUploadS3Image({ image: url, type: 'logo' });
            return s3Url;
          }
          return url;
        })
      );

      const brandingGuidelines = {
        guidelineUrl,
        logoLightUrl:
          logoLightUploadResult.status === 'fulfilled' ? logoLightUploadResult.value : '',
        logoDarkUrl: logoDarkUploadResult.status === 'fulfilled' ? logoDarkUploadResult.value : '',
      };

      const filteredEvents = values.events
        .filter((event) => event.name != '')
        .map((event) => {
          const start = event.start.date ? event.start.date.toJSON() : null;
          const end = event.end.date ? event.end.date.toJSON() : null;
          const yearRound =
            Array.isArray(event.yearRound) &&
            event.yearRound.length === 1 &&
            event.yearRound.includes('Yes');
          return { ...event, start, end, yearRound };
        });
      const filteredPartners = values.partners.filter((brand) => brand.name != '');
      const filteredCollaborationWishes = values.collaborationWishes.filter(
        (brand) => brand.name != ''
      );
      const filteredExcludedBrands = values.exclusions.brands.filter((brand) => brand.name != '');
      const filteredExclusionKeywords = values.exclusions.keywords.filter(
        (keyword) => keyword != ''
      );
      const filteredCauses = values.causes.filter((cause) => cause != '');
      const filteredExcludedAreas = values.exclusions.areas.filter((area) => area.country != '');

      const good_advertising_partnership_created =
        values.good_advertising_partnership_created || new Date().toJSON();
      const good_advertising_partnership_updated = new Date().toJSON();

      const { isApprovalRoute, isCausesRoute, isCollaborationRoute, isExclusionRoute } = routes;

      const updatedListing = {
        id: listingId,
        publicData: {
          goodAdvertisingOnboardingProgress: {
            [ONBOARDING_STEPS.PARTNERSHIP_INFORMATION]:
              isApprovalRoute || PARTNERSHIP_INFORMATION_COMPLETE,
            [ONBOARDING_STEPS.CAUSES_AND_EVENTS]: isCausesRoute || CAUSES_AND_EVENTS_COMPLETE,
            [ONBOARDING_STEPS.BRAND_COLLABORATIONS]:
              isCollaborationRoute || BRAND_COLLABORATIONS_COMPLETE,
            [ONBOARDING_STEPS.EXCLUSIONS]: isExclusionRoute || EXCLUSIONS_COMPLETE,
          },
          timestamps: {
            good_advertising_partnership_created,
            good_advertising_partnership_updated,
          },
          approvePortfolioPresentation,
          approvalContact,
          exclusions: {
            exclude,
            brands: filteredExcludedBrands,
            industries: values.exclusions.industries,
            keywords: filteredExclusionKeywords,
            areas: filteredExcludedAreas,
          },
          collaborationWishes: filteredCollaborationWishes,
          events: filteredEvents,
          causes: filteredCauses,
          partners: filteredPartners,
          brandingGuidelines,
          impactArea,
        },
      };

      await onUpdateListing(updatedListing);
    },
    [
      listingId,
      onUpdateListing,
      EXCLUSIONS_COMPLETE,
      PARTNERSHIP_INFORMATION_COMPLETE,
      CAUSES_AND_EVENTS_COMPLETE,
      BRAND_COLLABORATIONS_COMPLETE,
    ]
  );

  const onSubmit = useCallback(
    async (values) => {
      try {
        setIsSubmitting(true);

        const path = window && window.location && window.location.pathname;
        // if we submit on first page and step is incomplate
        const isApprovalRoute = /\/partnerships\/approval/.test(path);
        const isCausesRoute = /\/partnerships\/causes/.test(path);
        const isCollaborationRoute = /\/partnerships\/collaborations/.test(path);
        const isExclusionRoute = /\/partnerships\/exclusions/.test(path);

        const routes = {
          isApprovalRoute,
          isCausesRoute,
          isCollaborationRoute,
          isExclusionRoute,
        };

        await savePartnershipInformation(values, routes);
        const navigateToCauses = isApprovalRoute && !PARTNERSHIP_INFORMATION_COMPLETE;
        const navigateToCollaborations = isCausesRoute && !CAUSES_AND_EVENTS_COMPLETE;
        const navigateToExclusions = isCollaborationRoute && !BRAND_COLLABORATIONS_COMPLETE;
        const navigateToSuccess = isExclusionRoute && !EXCLUSIONS_COMPLETE;

        if (navigateToCauses) {
          navigateToTab(PARTNERSHIP_TABS.CAUSES);
        }
        if (navigateToCollaborations) {
          navigateToTab(PARTNERSHIP_TABS.COLLABORATIONS);
        }
        if (navigateToExclusions) {
          navigateToTab(PARTNERSHIP_TABS.EXCLUSIONS);
        }

        if (navigateToSuccess) {
          navigateToTab(PARTNERSHIP_TABS.SUCCESS);
        }

        setIsSubmitting(false);
      } catch (err) {
        console.warn(err);
        setIsSubmitting(false);
      }
    },
    [
      savePartnershipInformation,
      EXCLUSIONS_COMPLETE,
      PARTNERSHIP_INFORMATION_COMPLETE,
      CAUSES_AND_EVENTS_COMPLETE,
      BRAND_COLLABORATIONS_COMPLETE,
    ]
  );

  return (
    <Form
      initialValues={initialValues}
      onSubmit={onSubmit}
      isSubmitting={isSubmitting}
      user={user}
      partnershipInformationComplete={PARTNERSHIP_INFORMATION_COMPLETE}
      causesAndEventsComplete={CAUSES_AND_EVENTS_COMPLETE}
      brandCollaborationsComplete={BRAND_COLLABORATIONS_COMPLETE}
      exclusionsComplete={EXCLUSIONS_COMPLETE}
      listingId={listing.id.uuid}
      listingTitle={listing.attributes.title}
      savePartnershipInformation={savePartnershipInformation}
      render={({ ...formRenderProps }) => <AutoSaveForm {...formRenderProps} children={children} />}
    />
  );
};

export default PartnershipsForm;
