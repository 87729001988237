import React from 'react';
import css from './FeaturedNonprofitsV2.css';
import { array, bool, func, shape, string } from 'prop-types';
import { NamedLink, NonprofitFeatureCard, SecondaryButton } from '../index';
import { ensureListing } from '../../util/data';
import { createResourceLocatorString } from '../../util/routes';
import routeConfiguration from '../../routeConfiguration';
import classNames from 'classnames';

class FeaturedNonprofitsV2 extends React.Component {
  constructor(props) {
    super(props);
    this.redirectToSearch = this.redirectToSearch.bind(this);
  }

  redirectToSearch() {
    const { history, isFeaturedCategory, title, trackEvent, trackingCategory } = this.props;

    if (trackEvent && trackingCategory) {
      trackEvent({
        category: trackingCategory,
        action: 'ClickBrowseAll',
        label: 'FeaturedNonprofits',
      });
    }

    history.push(
      createResourceLocatorString(
        'NonprofitSearchPage',
        routeConfiguration(),
        {},
        isFeaturedCategory ? { pub_organizationCategory: title } : {}
      )
    );
  }

  render() {
    const {
      cardClassName,
      className,
      history,
      name,
      nonprofits,
      title,
      copy,
      trackEvent,
      trackingCategory,
    } = this.props;
    return (
      <div className={classNames(css.root, className)}>
        <div className={css.header}>
          <h2 className={css.headerText}>{title}</h2>
          <p className={css.headerCopy}>{copy}</p>
          <div className={css['btn-row']}>
            <NamedLink name="NonprofitSearchPage">
              <SecondaryButton className={css['row-btn']} style={{backgroundColor: "#faf4d5",  border: "1px solid black"}} onClick={this.redirectToSearch}>
                View nonprofits on Givsly
              </SecondaryButton>
            </NamedLink>
            <a href="https://company.givsly.com/contact-us/" target="_blank" rel="noopener noreferrer">
              <SecondaryButton className={css['row-btn']} style={{backgroundColor: "#faf4d5",  border: "1px solid black"}} onClick={this.redirectToSearch}>
                I'm a nonprofit
              </SecondaryButton>
            </a>
          </div>
        </div>
        <div className={css.cards}>
          {nonprofits.map((nonprofit) => {
            const ensuredListing = ensureListing(nonprofit);
            return ensuredListing.id ? (
              <NonprofitFeatureCard
                className={classNames(css.card, cardClassName)}
                history={history}
                key={`${name}-${ensuredListing.id.uuid}`}
                listing={ensuredListing}
                trackEvent={trackEvent}
                trackingCategory={trackingCategory}
              />
            ) : null;
          })}
        </div>
        {/* <Button className={css.footerBrowse} onClick={this.redirectToSearch}>
          {browseAllMobileLabel}
        </Button> */}
      </div>
    );
  }
}

FeaturedNonprofitsV2.propTypes = {
  browseAllLabel: string,
  browseAllMobileLabel: string,
  cardClassName: string,
  className: string,
  history: shape({
    push: func.isRequired,
  }).isRequired,
  isFeaturedCategory: bool,
  name: string.isRequired,
  nonprofits: array,
  title: string,
  copy: string,
  trackEvent: func,
  trackingCategory: string,
};

FeaturedNonprofitsV2.defaultProps = {
  className: null,
  browseAllLabel: 'View nonprofits on Givsly',
  browseAllMobileLabel: 'View nonprofits on Givsly',
  isFeaturedCategory: false,
  nonprofits: [],
  trackEvent: null,
  trackingCategory: null,
};

export default FeaturedNonprofitsV2;
