import { array, bool, func, number, string } from 'prop-types';
import React from 'react';
import { Form as FinalForm } from 'react-final-form';
import arrayMutators from 'final-form-arrays';
import { compose } from 'redux';
import {
  Button,
  FieldCheckboxRadioGroup,
  FieldTextInput,
  FieldTiledSelector,
  Form,
  Hint,
  IconEarth,
  ValidationError,
} from '../../components';
import { withMessages } from '../../util/localization';
import css from './ProposeMeetingForm.css';
import classNames from 'classnames';
import { FIELD_TYPE_RADIO } from '../../components/FieldCheckboxRadioGroup/FieldCheckboxRadioGroup';
import FieldDateTimeSelector from './FieldDateTimeSelector';
import moment from 'moment';
import { FieldArray } from 'react-final-form-arrays';
import { required, VALID } from '../../util/validators';
import { types as sdkTypes } from '../../util/sdkLoader';
import { formatMoney } from '../../util/currency';
import { injectIntl } from 'react-intl';
import config from '../../config';
import givslyConfig from '@givsly/config';

const { Money } = sdkTypes;

class ProposeMeetingForm extends React.PureComponent {
  constructor(props) {
    super(props);

    this.handleMeetingTypeChange = this.handleMeetingTypeChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.validateSuggestedTimes = this.validateSuggestedTimes.bind(this);
  }

  handleMeetingTypeChange({ value }) {
    const { onQuantityChange } = this.props;

    switch (value) {
      case 'QUANTITY_1':
        onQuantityChange(1);
        break;
      case 'QUANTITY_2':
        onQuantityChange(2);
        break;
      default:
        console.error('Invalid quantity');
        break;
    }
  }

  validateSuggestedTimes(value) {
    const { getMessage } = this.props;
    const errorMessage = getMessage('suggestedTimes.error');
    if (!value) return errorMessage;

    const filteredValues = value.filter(
      (x) => x !== null && x !== undefined && x.date.date !== null && x.time !== null
    );
    return filteredValues.length === 0 ? errorMessage : VALID;
  }

  handleSubmit(values) {
    const { onSubmit, onTrackEvent, timezone } = this.props;
    const { initialMessage, keywords, saveMessage } = values;
    const suggestedTimes = values.suggestedTimes
      .filter((x) => x !== null && x !== undefined && x.date.date !== null && x.time !== null)
      .map(({ date: { date }, time }) => {
        const [hours, minutes] = time.split(':');

        return moment.tz(date, timezone).hours(hours).minutes(minutes).toDate();
      });

    const finalizedValues = {
      initialMessage,
      keywords,
      saveMessage: saveMessage.length,
      suggestedTimes,
    };

    onTrackEvent({
      category: 'Propose meeting',
      action: 'Submit',
      label: 'Meeting proposal details',
    });
    onSubmit(finalizedValues);
  }

  render() {
    const {
      authorFirstName,
      authorInterests,
      blockedSlots,
      defaultInitialMessage,
      getMessage,
      timezone,
      unitPrice,
      onSubmit,
      ...rest
    } = this.props;

    return (
      <FinalForm
        mutators={{
          ...arrayMutators,
          touch: ([name], state, { changeValue }) => changeValue(state, name, (value) => value),
        }}
        initialValues={{
          saveMessage: defaultInitialMessage ? ['yes'] : [],
          initialMessage: defaultInitialMessage,
          suggestedTimes: [
            { date: { date: null }, time: '06:00' },
            { date: { date: null }, time: '06:00' },
            { date: { date: null }, time: '06:00' },
          ],
        }}
        onSubmit={this.handleSubmit}
        {...rest}
        render={(formRenderProps) => {
          const {
            formId,
            form,
            handleSubmit,
            invalid,
            intl,
            pristine,
            submitting,
            values,
          } = formRenderProps;

          const keywordOptions = authorInterests.map((topic) => {
            return {
              key: topic,
              label: topic,
            };
          });

          const meetingTypeOptions = [
            {
              key: 'QUANTITY_1',
              label: (
                <div className={css.meetingType}>
                  <span>{getMessage('meetingType.chat')}</span>
                  <span>
                    {config.custom.timeSlotDurations[0]} {getMessage('meetingType.timeSlot')}
                  </span>
                  <span>{formatMoney(intl, new Money(unitPrice, config.currency))}</span>
                </div>
              ),
            },
            {
              key: 'QUANTITY_2',
              label: (
                <div className={css.meetingType}>
                  <span>{getMessage('meetingType.chat')}</span>
                  <span>
                    {config.custom.timeSlotDurations[1]} {getMessage('meetingType.timeSlot')}
                  </span>
                  <span>{formatMoney(intl, new Money(unitPrice * 2, config.currency))}</span>
                </div>
              ),
            },
          ];

          const disabled = invalid || submitting || pristine;

          return (
            <Form onSubmit={handleSubmit}>
              <p className={css.introduction}>
                {getMessage('introduction', { name: authorFirstName })}
              </p>
              <h3 className={css.blockHeading}>{getMessage('message.title')}</h3>
              <p className={css.blockContent}>{getMessage('message.description')}</p>
              <FieldTextInput
                type="textarea"
                id={`${formId}-message`}
                name="initialMessage"
                label={getMessage('message.label')}
                placeholder={getMessage('message.placeholder')}
                className={css.message}
                validate={required(getMessage('message.required'))}
              />
              <FieldCheckboxRadioGroup
                className={css.saveMessage}
                id={`${formId}-saveMessage`}
                name="saveMessage"
                options={[
                  {
                    key: 'yes',
                    label: getMessage('saveMessage'),
                    value: 'yes',
                  },
                ]}
                twoColumns={false}
              />
              <Hint
                text={getMessage('initialMessageHint.text')}
                title={getMessage('initialMessageHint.title')}
              />

              {keywordOptions.length ? (
                <>
                  <h3 className={classNames(css.blockHeading, css.keywordsHeading)}>
                    {getMessage('keywords.title')}
                  </h3>

                  <FieldCheckboxRadioGroup
                    className={css.keywords}
                    id={`${formId}-keywords`}
                    name="keywords"
                    options={keywordOptions}
                    twoColumns={true}
                  />
                </>
              ) : null}

              <h3 className={classNames(css.blockHeading, css.meetingTypeHeader)}>
                {getMessage('meetingType.title')}
              </h3>

              <FieldTiledSelector
                id={`${formId}-meetingType`}
                name="meetingType"
                options={meetingTypeOptions}
                twoColumns={true}
                onChange={this.handleMeetingTypeChange}
                type={FIELD_TYPE_RADIO}
                validate={required('meetingType.error')}
              />

              <h3 className={classNames(css.blockHeading, css.suggestedTimes)}>
                {getMessage('suggestedTimes.title')}
              </h3>

              <p className={css.blockContent}>
                {getMessage('suggestedTimes.description', { name: authorFirstName })}
              </p>

              <FieldArray
                id={`${formId}-suggestedTimes`}
                name="suggestedTimes"
                validate={this.validateSuggestedTimes}
                render={({ id, meta, fields }) => (
                  <>
                    {[0, 1, 2].map((index) => {
                      const fieldId = `${id}[${index}]`;

                      return (
                        <FieldDateTimeSelector
                          availabilityOffset={{
                            value: givslyConfig.transaction.proposal.bookingOffset,
                            unit: 'hours',
                          }}
                          blockedSlots={blockedSlots}
                          key={fieldId}
                          datePlaceholder={getMessage('date.placeholder')}
                          id={fieldId}
                          name={`${fields.name}[${index}]`}
                          value={values.suggestedTimes && values.suggestedTimes[index]}
                          form={form}
                          intl={intl}
                          timePlaceholder={getMessage('time.placeholder')}
                          timezone={timezone}
                        />
                      );
                    })}
                    <ValidationError fieldMeta={meta} />
                  </>
                )}
              />

              <span className={css.timezone}>
                <span className={css.timezoneLabel}>{getMessage('timezone')}:</span>
                <IconEarth />{' '}
                <span className={css.timezoneText}>
                  {timezone.replace('_', ' ')} (UTC {moment.tz(timezone).format('Z')})
                </span>
              </span>

              <Button className={css.submit} disabled={disabled}>
                {getMessage('submit')}
              </Button>
            </Form>
          );
        }}
      />
    );
  }
}

ProposeMeetingForm.propTypes = {
  authorFirstName: string,
  authorInterests: array,
  blockedSlots: array,
  defaultInitialMessage: string,
  formId: string.isRequired,
  inProgress: bool,
  onQuantityChange: func.isRequired,
  onSubmit: func.isRequired,
  onTrackEvent: func.isRequired,
  timezone: string.isRequired,
  unitPrice: number.isRequired,
};

ProposeMeetingForm.defaultProps = {
  authorFirstName: null,
  authorInterests: [],
  blockedSlots: [],
  defaultInitialMessage: '',
  inProgress: false,
  timezone: 'UTC',
};

const withLocalizedMessages = (component) => withMessages(component, 'ProposeMeetingForm');
export default compose(injectIntl, withLocalizedMessages)(ProposeMeetingForm);
