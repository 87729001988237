import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './IconLink.css';

const IconLink = (props) => {
  const { className } = props;
  return (
    <svg
      className={classNames(className, css.root)}
      xmlns="http://www.w3.org/2000/svg"
      width="12"
      height="12"
      viewBox="0 0 12 12"
    >
      <g fill="none" fillRule="evenodd" stroke="#FAC51D" strokeWidth="1.5">
        <path d="M8.75 8.25v3h-8v-8h3M6.25.75h5v5" />
        <path strokeLinecap="square" d="M10.25 1.75l-5 5" />
      </g>
    </svg>
  );
};

IconLink.defaultProps = { className: null };

const { string } = PropTypes;

IconLink.propTypes = {
  className: string,
};

export default IconLink;
