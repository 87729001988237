import React, { useState, useEffect, useMemo } from 'react';
import { Form as FinalForm, Field } from 'react-final-form';
import css from './OutreachOfferForm.css';
import { img1, img2, img3, img4, img5 } from './images.js';
import {
  Button,
  Form,
  FieldTextInput,
  FieldSelect,
  FieldRadioButton,
  Modal,
  IconSpinner,
} from '../../components';
import FieldCheckboxComponent from '../../components/FieldCheckbox/FieldCheckbox';
import { useMessages } from '../../util/localization';
import Select from 'react-select';
import * as validators from '../../util/validators';

type PrePopulate = {
  nonProfitListingIds: string[];
  donationAmount: string;
  donationCap: string;
  sender: string;
  name: string;
  template: string;
  title: any;
  image: any;
  ingress: any;
  logo: any;
  unsplashAttrib: any;
  unsplashDownloadLink: any;
  emailOwner: boolean;
  emailThankYou: boolean;
};

type TemplateOffer = {
  key: string;
  value: string;
  label: string;
};

type OutreachOfferFormProps = {
  customizable: boolean;
  createOutreachOfferInProgress: boolean;
  currentUser: CurrentUser;
  isLoading: boolean;
  nonprofits: Record<string, string>;
  ownListing: OwnListing;
  onCancel: () => void;
  onSubmit: (offerDetails: any) => void;
  setIsImageInstructionModalOpen: (boolean: boolean) => void;
  setIsBackgroundImageModalOpen: (boolean: boolean) => void;
  setIsLogoImageModalOpen: (boolean: boolean) => void;
  uploadedBackgroundImageUrl: string;
  uploadedLogoImageUrl: string;
  prePopulate: PrePopulate;
  template: TemplateOffer;
  outreachRequests: OutreachRequest[];
  unsplashAttrib: string;
  unsplashDownloadLink: string;
};

type OutreachOfferFromInitialValues = {
  nonprofits?: string[];
  donationAmount: string;
  donationCap: string;
  nameTitleCompanyOrCompany: string;
  name: string;
  title: any;
  image: any;
  ingress: any;
  logo: any;
  emailThankYou: string[];
  emailOwner: string[];
};

const minValue = (min: number, message: string) => (value: number) =>
  !isNaN(value) && value >= min ? undefined : message;

const twodp = () => (value: number) =>
  !isNaN(value) && /^\d{1,9}(\.\d{0,2})?$/.test(value.toString())
    ? undefined
    : 'Amount can have up to two decimals only';

const OutreachOfferForm = (props: OutreachOfferFormProps) => {
  const customizable = props.customizable;

  // const [uploadedImage, setUploadedImage] = useState('');

  // if (
  //   !uploadedImage &&
  //   props.prePopulate &&
  //   props.prePopulate.image &&
  //   props.prePopulate.image !== uploadedImage
  // ) {
  //   setUploadedImage(props.prePopulate.image);
  // }

  const getMessage = useMessages('OutreachOfferForm');

  const nonprofitOptions = Object.keys(props.nonprofits).map((k) => {
    return {
      value: k,
      label: props.nonprofits[k],
    };
  });

  // const {
  //   firstName,
  //   lastName,
  //   companyName,
  //   jobTitle
  // } = props.currentUser.attributes.profile.publicData;

  // const senderNameTitleCompany = `${firstName} ${lastName}, ${jobTitle ? (jobTitle + " ") : " "}${companyName}`;
  // const senderCompany = companyName || senderNameTitleCompany;

  // Initial values

  const defaulBackground = '/static/images/default-background.png';
  const companyCopy =
    '{{company}} is committed to giving back. To show our appreciation, {{company}} is making a nonprofit donation. Simply select a nonprofit to receive a {{donation}} donation and fill out the brief form below. Thanks for doing good business!';
  const nameTitleCompanyCopy =
    '{{myname}} ({{title}}, {{company}}) is committed to giving back. To show our appreciation, {{company}} is making a nonprofit donation. Simply select a nonprofit to receive a {{donation}} donation and fill out the brief form below. Thanks for doing good business!';
  const copy = props.prePopulate ? props.prePopulate.ingress : nameTitleCompanyCopy;

  const totalOfConfirmedRequests = props.outreachRequests
    ? props.outreachRequests
        .filter((req) => req.state === 'CONFIRMED')
        .reduce((prev, current) => prev + current.donationAmount, 0)
    : 0;

  let supportedNPOs = props.prePopulate
    ? props.prePopulate.nonProfitListingIds
    : props.ownListing.attributes.publicData.supportedNPOs
    ? props.ownListing.attributes.publicData.supportedNPOs
    : [];

  let initialValues: OutreachOfferFromInitialValues = useMemo(
    () =>
      props.prePopulate
        ? {
            nonprofits: supportedNPOs,
            donationAmount: (Number(props.prePopulate.donationAmount) / 100).toFixed(),
            donationCap:
              totalOfConfirmedRequests >= Number(props.prePopulate.donationCap)
                ? '0'
                : (Number(props.prePopulate.donationCap) / 100).toFixed(),
            nameTitleCompanyOrCompany: props.prePopulate.sender,
            name: props.prePopulate.name,
            logo: customizable ? props.prePopulate.logo : null,
            image: props.prePopulate.image || defaulBackground,
            ingress: copy,
            title: props.prePopulate.title || 'Hi!',
            emailThankYou: props.prePopulate.emailThankYou ? ['Yes'] : [],
            emailOwner: props.prePopulate.emailOwner ? ['Yes'] : [],
          }
        : {
            nonprofits: supportedNPOs,
            donationAmount: '50',
            donationCap: '0',
            nameTitleCompanyOrCompany: customizable ? 'company' : 'nameTitleCompany',
            name: '',
            logo: customizable ? '' : null,
            image: defaulBackground,
            ingress: copy,
            title: 'Hi!',
            emailThankYou: ['Yes'],
            emailOwner: ['Yes'],
          },
    [props.prePopulate, supportedNPOs, totalOfConfirmedRequests, customizable]
  );

  // Handle submit
  const handleSubmit = async (e: any, values: any) => {
    e.preventDefault();
    const nonprofitIds = values.nonprofits.map((nonprofit: any) => {
      return nonprofit.value ? nonprofit.value : nonprofit;
    });

    const emailOwner =
      (values && Array.isArray(values.emailOwner) && values.emailOwner.includes('Yes')) || false;
    const emailThankYou =
      (values && Array.isArray(values.emailThankYou) && values.emailThankYou.includes('Yes')) ||
      false;

    const offer = {
      name: values.name,
      donationAmount: Number(values.donationAmount) * 100,
      donationCap: Number(values.donationCap) * 100,
      nonProfitListingIds: nonprofitIds,
      sender: values.nameTitleCompanyOrCompany,
      template: customizable ? 'custom' : 'default',
      title: values.title,
      image:
        props.uploadedBackgroundImageUrl ||
        (props.prePopulate ? props.prePopulate.image : defaulBackground) ||
        defaulBackground,
      logo: showLogoUpload ? props.uploadedLogoImageUrl : null,
      ingress: customizable
        ? values.ingress
        : values.nameTitleCompanyOrCompany === 'company'
        ? companyCopy
        : nameTitleCompanyCopy,
      unsplashAttrib: props.unsplashAttrib,
      unsplashDownloadLink: props.unsplashDownloadLink,
      emailOwner,
      emailThankYou,
      ownerID: props.currentUser.attributes.email,
    };

    return props.onSubmit(offer);
  };

  // const handleImageUpload = (event: any) => {
  //   var reader = new FileReader();
  //   reader.onload = function (e: any) {
  //     setUploadedImage(e.target.result);
  //   };
  //   reader.readAsDataURL(event.target.files[0]);
  // };

  // const handleImageSelect = (props: any, img: string) => {
  //   props.input.onChange(img);
  // };

  // const handleBackgroundImageModal = () => {
  //   console.log("click!!");

  // }

  const [reren, setReren] = useState(0);
  const [inputHasBeenChangedManually, setInputHasBeenChangedManually] = useState(false);

  const handleChange = (e: any) => {
    if (!inputHasBeenChangedManually) {
      initialValues.nameTitleCompanyOrCompany = e;
      if (e == 'company') {
        console.log('company');
        initialValues.ingress = companyCopy;
        setReren(reren + 1);
      } else if (e == 'nameTitleCompany') {
        console.log('nameTitleCompany');
        initialValues.ingress = nameTitleCompanyCopy;
        setReren(reren + 1);
      }
    }
  };

  const [showLogoUpload, setShowLogoUpload] = useState(false);

  const toggleShowLogoUpload = () => {
    setShowLogoUpload(!showLogoUpload);
  };

  useEffect(() => {
    if (initialValues.logo) {
      setShowLogoUpload(true);
    }
  }, []);

  return !props.isLoading ? (
    <FinalForm
      initialValues={initialValues}
      onSubmit={() => console.log('Submitted')}
      render={(formRenderProps) => {
        const { values, submitting, invalid } = formRenderProps;
        const submitDisabled =
          submitting ||
          invalid ||
          props.createOutreachOfferInProgress ||
          (values.nonprofits && values.nonprofits.length === 0);

        const { label, value } = props.template;

        // @ts-ignore
        const operatorPercentage =
          props.currentUser &&
          props.currentUser.attributes &&
          props.currentUser.attributes.profile &&
          props.currentUser.attributes.profile.metadata &&
          // @ts-ignore
          props.currentUser.attributes.profile.metadata.operatorPercentage >= 0
            ? // @ts-ignore
              props.currentUser.attributes.profile.metadata.operatorPercentage
            : 15;

        const amount = Number(values.donationAmount) * 100;
        const op = amount * (operatorPercentage / 100);
        const serviceFee = (amount + op + 30) * (1 / 0.971 - 1) + 30;
        const totalFee = (
          Math.round(((amount + op + serviceFee) / 100) * 100) / 100
        ).toLocaleString('en-US');

        return (
          <Form>
            <h2 className={css.header}>{getMessage('title')}</h2>
            {value !== 'default' && <p className={css.tamplateDetails}>Using template {label}</p>}
            <FieldTextInput
              id="name"
              label={getMessage('name.label')}
              name="name"
              type="text"
              disabled={initialValues.name}
              validate={validators.required(getMessage('name.required'))}
            />

            {!customizable && (
              <>
                <h3 className={css.subHeader}>Donation offer sent</h3>

                <FieldRadioButton
                  id="nameTitleCompany"
                  label={'Including my name, title and company'}
                  name="nameTitleCompanyOrCompany"
                  value={'nameTitleCompany'}
                />

                <FieldRadioButton
                  id="company"
                  label={'Including my company name only'}
                  name="nameTitleCompanyOrCompany"
                  value={'company'}
                />
              </>
            )}

            {customizable && (
              <>
                <h3 className={css.subHeader}>Text content</h3>
                <FieldTextInput
                  id="title"
                  label={getMessage('title.label')}
                  name="title"
                  type="text"
                  validate={validators.required(getMessage('title.required'))}
                  maxLength={80}
                />
                <p className={css.smallPrintGray}>Max. 80 characters</p>

                <h4>Customizable Donation Offer message</h4>
                <Field
                  name="ingress"
                  className={css.ingress}
                  component="textarea"
                  validate={validators.required('required')}
                  maxLength={500}
                ></Field>
                <p className={css.smallPrintGray}>Max. 500 characters</p>
                <p className={css.smallPrintGray}>
                  Tip: You can add dynamic text content using curly brackets. Using
                  &#123;&#123;company&#125;&#125; adds your company name, using
                  &#123;&#123;title&#125;&#125; adds your title, using
                  &#123;&#123;myname&#125;&#125; adds your first and last name, using
                  &#123;&#123;nonprofit-1&#125;&#125; adds a nonprofit (use a number to identify a
                  nonprofit), and using &#123;&#123;donation&#125;&#125; adds the donation amount
                  you've chosen.
                </p>

                <div className={css.bgImageSection}>
                  <div>
                    <h3 className={css.subHeader}>Background image</h3>
                    <div
                      className={css.uploadedImageContainer}
                      onClick={() => props.setIsBackgroundImageModalOpen(true)}
                    >
                      <img
                        className={css.uploadedImage}
                        src={
                          props.uploadedBackgroundImageUrl ||
                          initialValues.image ||
                          defaulBackground
                        }
                      />
                      <span className={css.replaceImg}></span>
                    </div>
                  </div>
                  <span
                    className={css.instruc}
                    onClick={() => props.setIsImageInstructionModalOpen(true)}
                  >
                    Instructions
                  </span>
                </div>

                <div className={css.bgImageSection}>
                  <div>
                    <h3 className={css.subHeader}>Branding</h3>
                    <p className={css.toggleParagraph} onClick={toggleShowLogoUpload}>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="28"
                        height="16"
                        viewBox="0 0 28 16"
                      >
                        <g fill="none" fillRule="evenodd">
                          {showLogoUpload ? (
                            <>
                              <rect fill="#FAC51D" width="28" height="16" rx="8" />
                              <circle fill="#FFF" cx="20" cy="8" r="6" />
                            </>
                          ) : (
                            <>
                              <rect fill="#DADADA" width="28" height="16" rx="8" />
                              <circle fill="#FFF" cx="8" cy="8" r="6" />
                            </>
                          )}
                        </g>
                      </svg>
                      <span>&nbsp;&nbsp;Include company logo in the offer</span>
                    </p>

                    {showLogoUpload && (
                      <>
                        <div
                          className={css.uploadedImageContainerLogo}
                          onClick={() => props.setIsLogoImageModalOpen(true)}
                        >
                          <img
                            className={css.uploadedImage}
                            src={props.uploadedLogoImageUrl || initialValues.logo}
                          />
                          <span className={css.replaceImg}></span>
                        </div>
                      </>
                    )}
                  </div>
                </div>
              </>
            )}

            <h3 className={css.subHeader}>{getMessage('nonprofits.title')}</h3>
            <p>
              {getMessage('nonprofits.description', {
                supportByVolunteeringlink: (
                  <a
                    key="supportByVolunteeringlink"
                    className={css.descriptionLink}
                    href="https://www.givsly.com/nonprofits/s"
                  >
                    here
                  </a>
                ),
              })}
            </p>

            <div className={css.nonprofitSelector}>
              <Field name="nonprofits">
                {(props) => (
                  <Select
                    className={css.select}
                    defaultValue={nonprofitOptions.filter((options) =>
                      supportedNPOs.includes(options.value)
                    )}
                    id={props.input.name}
                    isMulti={true}
                    name={props.input.name}
                    options={props.input.value.length < 3 ? nonprofitOptions : []}
                    onChange={props.input.onChange}
                  />
                )}
              </Field>
            </div>

            <h3 className={css.subHeader}>{getMessage('donationAmount.title')}</h3>
            <p>{getMessage('donationAmount.description')}</p>
            <div className={css.donationAmounts}>
              <FieldTextInput
                id="donationAmount"
                label={getMessage('donationAmount.label')}
                name="donationAmount"
                type="number"
                min="10"
                validate={validators.composeValidators(
                  minValue(10, 'Minimun donation amount $10'),
                  validators.required('Minimun donation amount $10'),
                  twodp()
                )}
              />
            </div>
            {amount >= 1000 ? (
              <p className={css.smallPrint}>
                <b>${totalFee}</b> in total (including platform and payment processing fees)
              </p>
            ) : null}
            <div>
              <h3 className={css.subHeader}>{getMessage('donationAmount.total')}</h3>
              <p>{getMessage('donationAmount.totalDescription')}</p>
              {/* <FieldSelect
                id="donationCap"
                label={getMessage('donationCap.label')}
                name="donationCap"
                options={capOptions}
              />*/}

              <FieldTextInput
                id="donationCap"
                label={getMessage('donationCap.label')}
                name="donationCap"
                type="number"
                min="10"
                validate={validators.composeValidators(
                  validators.required(getMessage('donationCap.required')),
                  minValue(0, 'Donation Cap is required, set as 0 for no Cap'),
                  twodp()
                )}
              />
            </div>
            <div style={{ marginTop: 36 }}>
              <FieldCheckboxComponent
                name="emailOwner"
                kind="checkbox"
                id="emailOwner"
                label="I want to receive an email every time someone accepts my Donation Offer."
                value="Yes"
              />
              <FieldCheckboxComponent
                name="emailThankYou"
                kind="checkbox"
                id="emailThankYou"
                label="I want the Donation Offer acceptor to receive a Thank You -email when the donation has been processed."
                value="Yes"
              />
            </div>
            <div className={css.formControls}>
              <Button onClick={props.onCancel}>{getMessage('cancel')}</Button>
              <Button
                disabled={submitDisabled}
                onClick={(e: any) => handleSubmit(e, values)}
                inProgress={submitting}
              >
                {getMessage('save')}
              </Button>
            </div>
          </Form>
        );
      }}
    />
  ) : (
    <div className={css.spinner}>
      <IconSpinner />
    </div>
  );
};

export default OutreachOfferForm;
