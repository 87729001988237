import React from 'react';
import { bool, func, number, object, shape, string } from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { propTypes } from '../../util/types';
import { sendVerificationEmail, hasCurrentUserErrors } from '../../ducks/user.duck';
import { logout, authenticationInProgress } from '../../ducks/Auth.duck';
import { manageDisableScrolling } from '../../ducks/UI.duck';
import { Modal, Topbar } from '../../components';
import { changeMarket } from '../../ducks/TopBar.duck';
import { ensureCurrentUser, PROFILE_TYPE_NONPROFIT } from '../../util/data';
import config from '../../config';
import { injectIntl } from 'react-intl';
import { intlShape } from '../../util/reactIntl';
import { NonprofitTermsConditionsAndPrivacyForm } from '../../forms';
import { updateProfile } from '../../ducks/UserProfile.duck';
import moment from 'moment';

class TopbarContainerComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isNonprofitModalOpen: false,
      nonprofitModalSubmitInProgress: false,
    };
    this.closeNonprofitModal = this.closeNonprofitModal.bind(this);
    this.handleStoreNonprofitSettings = this.handleStoreNonprofitSettings.bind(this);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (
      !this.state.isNonprofitModalOpen &&
      this.props.alwaysAccessible === false &&
      config.custom.enableNonprofitTermsAndPrivacyApprovalModal &&
      this.props.currentUser &&
      this.props.currentUser.id
    ) {
      const ensuredUser = ensureCurrentUser(this.props.currentUser);
      const { isNPO, profileType } = ensuredUser.attributes.profile.publicData;
      const { nonprofitTermsAndPrivacyApprovals } = ensuredUser.attributes.profile.privateData;

      const onboardingCompleted =
        ensuredUser.attributes.profile.privateData.onboardingCompleted ||
        ensuredUser.attributes.profile.publicData.onboardingCompleted;

      const latestVersion =
        config.custom.nonprofitTermsAndPrivacyApprovalVersions[
          config.custom.nonprofitTermsAndPrivacyApprovalVersions.length - 1
        ];

      const { agreePrivacyPolicy, agreeTermsOfService } =
        (nonprofitTermsAndPrivacyApprovals && nonprofitTermsAndPrivacyApprovals[latestVersion]) ||
        {};

      const showNonprofitTermsAndPrivacyApprovalModal =
        onboardingCompleted &&
        (isNPO || profileType === PROFILE_TYPE_NONPROFIT) &&
        (!agreePrivacyPolicy || !agreeTermsOfService) &&
        config.custom.enableNonprofitTermsAndPrivacyApprovalModal;

      if (showNonprofitTermsAndPrivacyApprovalModal) {
        this.setState({
          isNonprofitModalOpen: true,
        });
      }
    }
  }

  closeNonprofitModal() {
    this.setState({
      isNonprofitModalOpen: false,
      nonprofitModalSubmitInProgress: false,
    });
  }

  handleStoreNonprofitSettings(values) {
    this.setState({
      nonprofitModalSubmitInProgress: true,
    });

    const latestVersion =
      config.custom.nonprofitTermsAndPrivacyApprovalVersions[
        config.custom.nonprofitTermsAndPrivacyApprovalVersions.length - 1
      ];

    const ensuredUser = ensureCurrentUser(this.props.currentUser);

    const {
      nonprofitAgreePrivacyPolicy,
      nonprofitAgreeTermsOfService,
      nonprofitAgreedOn,
    } = ensuredUser.attributes.profile.privateData;

    const {
      nonprofitTermsAndPrivacyApprovals = nonprofitAgreePrivacyPolicy &&
        nonprofitAgreeTermsOfService && {
          [config.custom.nonprofitTermsAndPrivacyApprovalVersions[0]]: {
            agreePrivacyPolicy: nonprofitAgreePrivacyPolicy,
            agreeTermsOfService: nonprofitAgreeTermsOfService,
            agreendOn: nonprofitAgreedOn,
          },
        },
    } = ensuredUser.attributes.profile.privateData;

    this.props
      .onUpdateProfile({
        privateData: {
          nonprofitTermsAndPrivacyApprovals: {
            ...nonprofitTermsAndPrivacyApprovals,
            [latestVersion]: {
              agreePrivacyPolicy: values.agreePrivacyPolicy,
              agreeTermsOfService: values.agreeTermsOfService,
              agreedOn: moment().format('YYYY-MM-DD HH:mm:ss'),
            },
          },
        },
      })
      .then(() => {
        this.closeNonprofitModal();
      });
  }

  render() {
    const {
      authInProgress,
      currentPage,
      currentSearchParams,
      currentUser,
      currentUserHasListings,
      currentUserListing,
      currentUserListingFetched,
      currentUserHasOrders,
      hasNewNotifications,
      history,
      intl,
      isAuthenticated,
      hasGenericError,
      location,
      notificationCount,
      onLogout,
      onManageDisableScrolling,
      sendVerificationEmailInProgress,
      sendVerificationEmailError,
      onResendVerificationEmail,
      currentMarket,
      searchString,
      searchResults,
      ...rest
    } = this.props;
    const { isNonprofitModalOpen } = this.state;

    return (
      <>
        {isNonprofitModalOpen ? (
          <Modal
            id="NonprofitTermsConditionsAndPrivacyModal"
            hasCloseButton={false}
            isOpen={isNonprofitModalOpen}
            onClose={this.closeNonprofitModal}
            onManageDisableScrolling={onManageDisableScrolling}
          >
            <NonprofitTermsConditionsAndPrivacyForm
              currentUser={currentUser}
              handleSubmit={this.handleStoreNonprofitSettings}
              inProgress={this.state.nonprofitModalSubmitInProgress}
              intl={intl}
            />
          </Modal>
        ) : null}
        <Topbar
          authInProgress={authInProgress}
          currentPage={currentPage}
          currentSearchParams={currentSearchParams}
          currentUser={currentUser}
          currentUserHasListings={currentUserHasListings}
          currentUserListing={currentUserListing}
          currentUserListingFetched={currentUserListingFetched}
          currentUserHasOrders={currentUserHasOrders}
          hasNewNotifications={hasNewNotifications}
          history={history}
          isAuthenticated={isAuthenticated}
          location={location}
          notificationCount={notificationCount}
          onLogout={onLogout}
          onManageDisableScrolling={onManageDisableScrolling}
          onResendVerificationEmail={onResendVerificationEmail}
          sendVerificationEmailInProgress={sendVerificationEmailInProgress}
          sendVerificationEmailError={sendVerificationEmailError}
          showGenericError={hasGenericError}
          currentMarket={currentMarket}
          searchString={searchString}
          searchResults={searchResults}
          {...rest}
        />
      </>
    );
  }
}

TopbarContainerComponent.defaultProps = {
  alwaysAccessible: false,
  currentPage: null,
  currentSearchParams: null,
  currentUser: null,
  currentUserHasOrders: null,
  hasNewNotifications: false,
  notificationCount: 0,
  sendVerificationEmailError: null,
  currentUserListing: null,
};

TopbarContainerComponent.propTypes = {
  alwaysAccessible: bool,
  authInProgress: bool.isRequired,
  currentPage: string,
  currentSearchParams: object,
  currentUser: propTypes.currentUser,
  currentUserHasListings: bool.isRequired,
  currentUserListingFetched: bool.isRequired,
  currentUserListing: propTypes.ownListing,
  currentUserHasOrders: bool,
  intl: intlShape.isRequired,
  isAuthenticated: bool.isRequired,
  hasNewNotifications: bool,
  notificationCount: number,
  onLogout: func.isRequired,
  onManageDisableScrolling: func.isRequired,
  sendVerificationEmailInProgress: bool.isRequired,
  sendVerificationEmailError: propTypes.error,
  onResendVerificationEmail: func.isRequired,
  hasGenericError: bool.isRequired,

  // from withRouter
  history: shape({
    push: func.isRequired,
  }).isRequired,
  location: shape({ state: object }).isRequired,
};

const mapStateToProps = (state) => {
  const { currentMarket, searchString, searchResults } = state.TopBar;
  // Topbar needs isAuthenticated
  const { isAuthenticated, logoutError } = state.Auth;
  // Topbar needs user info.
  const {
    currentUser,
    currentUserHasListings,
    currentUserListing,
    currentUserListingFetched,
    currentUserHasOrders,
    currentUserNotificationCount: notificationCount,
    sendVerificationEmailInProgress,
    sendVerificationEmailError,
  } = state.user;
  const { newNotificationCount } = state.notifications;
  const hasGenericError = !!(logoutError || hasCurrentUserErrors(state));
  return {
    authInProgress: authenticationInProgress(state),
    currentUser,
    currentUserHasListings,
    currentUserListing,
    currentUserListingFetched,
    currentUserHasOrders,
    hasNewNotifications: (newNotificationCount && newNotificationCount > 0) || false,
    notificationCount,
    isAuthenticated,
    sendVerificationEmailInProgress,
    sendVerificationEmailError,
    hasGenericError,
    currentMarket,
    searchString,
    searchResults,
  };
};

const mapDispatchToProps = (dispatch) => ({
  onChangeMarket: (market) => dispatch(changeMarket(market)),
  onLogout: (historyPush) => dispatch(logout(historyPush)),
  onManageDisableScrolling: (componentId, disableScrolling) =>
    dispatch(manageDisableScrolling(componentId, disableScrolling)),
  onUpdateProfile: (data) => dispatch(updateProfile(data)),
  onResendVerificationEmail: () => dispatch(sendVerificationEmail()),
});

// Note: it is important that the withRouter HOC is **outside** the
// connect HOC, otherwise React Router won't rerender any Route
// components since connect implements a shouldComponentUpdate
// lifecycle hook.
//
// See: https://github.com/ReactTraining/react-router/issues/4671
const TopbarContainer = compose(
  withRouter,
  injectIntl,
  connect(mapStateToProps, mapDispatchToProps)
)(TopbarContainerComponent);

export default TopbarContainer;
