import React from 'react';
import { oneOfType, string, number } from 'prop-types';
import css from './EventLandingPageImage.css';

const EventLandingPageImage = (props) => {
  return (
    <img
      className={css.image}
      src={props.url}
      style={{ width: props.width, height: props.width }}
      alt={props.alt}
    />
  );
};

EventLandingPageImage.defaultProps = {
  alt: '',
};

EventLandingPageImage.propTypes = {
  alt: string,
  url: string.isRequired,
  width: oneOfType([string, number]),
  height: oneOfType([string, number]),
};

export default EventLandingPageImage;
