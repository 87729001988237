import React, { Component } from 'react';
import { InterestsForm } from '../../forms/index';
import { INTERESTS } from '../OnboardingWizard/constants';
import { func } from 'prop-types';
import { propTypes } from '../../util/types';
import { bool } from 'prop-types';
import { ensureCurrentUser } from '../../util/data';

import css from './InterestsPanel.css';
import config from '../../config';

class InterestsPanel extends Component {
  componentDidMount() {
    if (this.props.trackEvent) {
      this.props.trackEvent({ action: 'OpenTab', label: INTERESTS });
    }
  }

  render() {
    const {
      currentUser,
      handleSubmit,
      updateInProgress,
      updateProfileError,
      trackEvent,
    } = this.props;

    const user = ensureCurrentUser(currentUser);

    const onSubmit = (values) => {
      const customInterests = values.customInterests
        ? [...values.customInterests.filter((interest) => !!interest)]
        : [];

      const combinedInterests = values.interests.concat(customInterests);
      const updatedValues = {
        publicData: {
          interests: combinedInterests,
        },
      };

      if (trackEvent && customInterests && customInterests.length > 0) {
        trackEvent({
          action: 'Input',
          label: 'CustomInterests',
          value: customInterests.join(', '),
        });
      }

      handleSubmit(INTERESTS, updatedValues);
    };

    const combinedInterests = user.attributes.profile.publicData.interests || [];
    const defaultInterests = config.custom.interests.map((interest) => interest.label);
    const interests = combinedInterests.filter((v) => defaultInterests.includes(v));
    const customInterests = combinedInterests.filter((v) => !defaultInterests.includes(v));

    return (
      <div className={css.root}>
        <InterestsForm
          className={css.form}
          currentUser={currentUser}
          initialValues={{
            interests,
            customInterests,
          }}
          updateInProgress={updateInProgress}
          updateInterestsError={updateProfileError}
          onSubmit={onSubmit}
          trackEvent={trackEvent}
        />
      </div>
    );
  }
}

InterestsPanel.defaultProps = {
  updateProfileError: null,
  updateProfileReady: false,
};

InterestsPanel.propTypes = {
  currentUser: propTypes.currentUser.isRequired,
  handleSubmit: func.isRequired,
  updateInProgress: bool.isRequired,
  updateProfileError: propTypes.error,
  updateProfileReady: bool,
  trackEvent: func,
};

export default InterestsPanel;
