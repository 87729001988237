import React from 'react';
import { FieldSelect } from '../index';
import { dateIsAfter, isInRange, nextMonthFn, resetToStartOfDay } from '../../util/dates';
import { array, func, object, string } from 'prop-types';
import { propTypes } from '../../util/types';
import { intlShape } from '../../util/reactIntl';
import css from './FieldTimeSelect.css';
import moment from 'moment';
import classNames from 'classnames';

const MAX_TIME_SLOTS_RANGE = 180;
const TODAY = new Date();

const endOfRange = (date, timezone) => {
  return resetToStartOfDay(date, timezone, MAX_TIME_SLOTS_RANGE - 1);
};

class FieldTimeSelect extends React.Component {
  constructor(props) {
    super(props);

    this.fetchMonthData = this.fetchMonthData.bind(this);
    this.getAllTimes = this.getAllTimes.bind(this);
  }

  getAllTimes = () => {
    const { bookingDate, timezone } = this.props;
    const startOfDay = moment.tz(bookingDate, timezone).set({ hours: 0, minutes: 0, seconds: 0 });
    let addedMinutes = 0;
    const times = [];

    while (addedMinutes < 1440) {
      times.push(startOfDay.clone().add(addedMinutes, 'minutes'));
      addedMinutes += 15;
    }

    return times;
  };

  fetchMonthData(date) {
    const { listingId, timezone, onFetchTimeSlots } = this.props;
    const endOfRangeDate = endOfRange(TODAY, timezone);

    // Don't fetch timeSlots for past months or too far in the future
    if (isInRange(date, TODAY, endOfRangeDate)) {
      // Use "today", if the first day of given month is in the past
      const start = dateIsAfter(TODAY, date) ? TODAY : date;

      // Use endOfRangeDate, if the first day of the next month is too far in the future
      const nextMonthDate = nextMonthFn(date, timezone);
      const end = dateIsAfter(nextMonthDate, endOfRangeDate)
        ? resetToStartOfDay(endOfRangeDate, timezone, 0)
        : nextMonthDate;

      // Fetch time slots for given time range
      onFetchTimeSlots(listingId, start, end, timezone);
    }
  }

  render() {
    const {
      bookingDate,
      currentMonth,
      currentUser,
      className,
      disallowedTimes,
      formId,
      label,
      listingId,
      monthlyTimeSlots,
      name,
      onBookingTimeChanged,
      onFetchTimeSlots,
      placeholderTime,
      selectedTime,
      timezone,
      ...fieldSelectProps
    } = this.props;

    const availableStartTimes = this.getAllTimes();

    return (
      <FieldSelect
        name={name}
        label={label}
        id={formId ? `${formId}.bookingStartTime` : 'bookingStartTime'}
        className={classNames(bookingDate ? css.fieldSelect : css.fieldSelectDisabled, className)}
        selectClassName={bookingDate ? css.select : css.selectDisabled}
        disabled={!bookingDate}
        onChange={onBookingTimeChanged}
        {...fieldSelectProps}
      >
        {bookingDate ? (
          availableStartTimes.map((p) => {
            const value = p.format('HH:mm');
            return (
              <option key={value} value={value} disabled={disallowedTimes.indexOf(value) >= 0}>
                {p.format('hh:mm A')}
              </option>
            );
          })
        ) : (
          <option>{placeholderTime}</option>
        )}
      </FieldSelect>
    );
  }
}

FieldTimeSelect.defaultProps = {
  rootClassName: null,
  className: null,
  currentMonth: null,
  disallowedTimes: [],
  listingId: null,
  monthlyTimeSlots: null,
  onBookingTimeChanged: null,
  placeholderTime: '',
  timezone: null,
};

FieldTimeSelect.propTypes = {
  bookingDate: object,
  className: string,
  currentMonth: object,
  disallowedTimes: array,
  form: object.isRequired,
  formId: string,
  intl: intlShape.isRequired,
  listingId: propTypes.uuid,
  monthlyTimeSlots: object,
  name: string,
  onBookingTimeChanged: func,
  onFetchTimeSlots: func.isRequired,
  placeholderTime: string,
  rootClassName: string,
  selectedTime: string,
  timezone: string,
};

export default FieldTimeSelect;
