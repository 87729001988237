import React from 'react';
import css from './CalendarDownloadOptions.css';
import { intlShape } from '../../util/reactIntl';
import classNames from 'classnames';
import { Button, Menu, MenuContent, MenuItem, MenuLabel } from '../index';
import { func, string } from 'prop-types';
import { propTypes } from '../../util/types';
import { CalendarDownloadOptionsButtonICS } from './CalendarDownloadOptionsButtonICS';
import { CalendarDownloadOptionsModalConnect } from './CalendarDownloadOptionsModalConnect';

export const GOOGLE = 'google';
export const OUTLOOK_LIVE = 'outlookLive';
export const DOWNLOAD = 'download';
export const DOWNLOAD_ALL = 'downloadAll';
export const SUBSCRIBE = 'subscribe';

class CalendarDownloadOptions extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modalIsOpen: false,
      menuIsOpen: false,
    };
    this.getTranslation = this.getTranslation.bind(this);
    this.onModalClose = this.onModalClose.bind(this);
    this.onToggleActive = this.onToggleActive.bind(this);
  }

  feedOptions = [DOWNLOAD_ALL];

  singleSlotOptions = [DOWNLOAD, GOOGLE, OUTLOOK_LIVE];

  getTranslation = (name) => {
    return this.props.intl.formatMessage({ id: `CalendarDownloadOptions.${name}` });
  };

  onModalClose() {
    this.setState({
      modalIsOpen: false,
    });
  }

  onToggleActive(newState) {
    this.setState({
      menuIsOpen: newState,
    });
  }

  render() {
    const {
      children,
      className,
      contentClassName,
      ensuredCurrentUser,
      name,
      intl,
      onManageDisableScrolling,
      transactionId,
    } = this.props;

    const defaultMenuItems = [
      <MenuItem key={`${name}-${SUBSCRIBE}`}>
        <Button
          className={classNames(css.button, css[SUBSCRIBE])}
          onClick={() =>
            this.setState({
              modalIsOpen: true,
            })
          }
          type="button"
        >
          {this.getTranslation(SUBSCRIBE)}
        </Button>
      </MenuItem>,
    ];

    if (transactionId) {
      defaultMenuItems.push(
        <MenuItem key={`${name}-separatorLabel`}>
          <span className={css.separatorLabel}>{this.getTranslation('thisEventOnly')}</span>
        </MenuItem>
      );
    }

    return (
      <>
        <Menu
          className={className}
          isOpen={this.state.menuIsOpen}
          onToggleActive={this.onToggleActive}
        >
          <MenuLabel className={css.menuButton}>{children}</MenuLabel>
          <MenuContent
            hideArrow={true}
            className={classNames(
              css.menuContent,
              contentClassName,
              transactionId ? css.singleEventDownloads : null
            )}
            contentClassName={css.menu}
          >
            {defaultMenuItems}
            {(transactionId ? this.singleSlotOptions : this.feedOptions).map((option) => {
              return (
                <MenuItem key={`${name}-${option}`}>
                  <CalendarDownloadOptionsButtonICS
                    option={option}
                    beforeDownload={() => this.onToggleActive(false)}
                    transactionId={transactionId}
                    ensuredCurrentUser={ensuredCurrentUser}
                  >
                    {this.getTranslation(option)}
                  </CalendarDownloadOptionsButtonICS>
                </MenuItem>
              );
            })}
          </MenuContent>
        </Menu>
        <CalendarDownloadOptionsModalConnect
          id="CalendarDownloadOptions.modal"
          isOpen={this.state.modalIsOpen}
          onClose={this.onModalClose}
          onManageDisableScrolling={onManageDisableScrolling}
          ensuredCurrentUser={ensuredCurrentUser}
          intl={intl}
        />
      </>
    );
  }
}

CalendarDownloadOptions.propTypes = {
  className: string,
  contentClassName: string,
  ensuredCurrentUser: propTypes.currentUser,
  name: string.isRequired,
  intl: intlShape.isRequired,
  onManageDisableScrolling: func.isRequired,
  transactionId: string,
};

CalendarDownloadOptions.defaultProps = {
  className: null,
  contentClassName: null,
  transactionId: null,
};

export default CalendarDownloadOptions;
