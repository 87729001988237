import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import ExternalLink from '../ExternalLink/ExternalLink';

import css from './TermsOfService.css';
import AnchorTitle from '../AnchorTitle/AnchorTitle';

const tableHeaderColStyle = {
  backgroundColor: 'rgb(238, 238, 238)',
};

const TermsOfService = (props) => {
  const { rootClassName, className } = props;
  const classes = classNames(rootClassName || css.root, className);

  const mailToHelloAtGivsly = <a href="mailto:hello@givsly.com">hello@givsly.com</a>;
  const mailToSupportAtGivsly = <a href="mailto:support@givsly.com">support@givsly.com</a>;

  const extStripe1 = (
    <ExternalLink href="https://stripe.com/ssa" target="_blank" rel="noopener noreferrer nofollow">
      Stripe’s terms of service
    </ExternalLink>
  );
  const extStripe2 = (
    <ExternalLink href="https://stripe.com/ssa" target="_blank" rel="noopener noreferrer nofollow">
      Stripe
    </ExternalLink>
  );
  const extGiftup = (
    <ExternalLink
      href="https://help.giftup.com/article/39-terms-of-service"
      target="_blank"
      rel="noopener noreferrer nofollow"
    >
      Giftup, Inc
    </ExternalLink>
  );
  const extAdrOrg = (
    <ExternalLink href="https://www.adr.org/" target="_blank" rel="noopener noreferrer nofollow">
      www.adr.org
    </ExternalLink>
  );
  const extDcaOrg = (
    <ExternalLink
      href="https://www.dca.ca.gov/about_us/contactus.shtml"
      target="_blank"
      rel="noopener noreferrer nofollow"
    >
      https://www.dca.ca.gov/about_us/contactus.shtml
    </ExternalLink>
  );
  const givslyPrivacyPolicy = (
    <ExternalLink href="https://www.givsly.com/privacy-policy" target="_blank" rel="noopener noreferrer">
      Givsly’s Privacy Policy
    </ExternalLink>
  );
  const givslyCom = (
    <ExternalLink href="https://www.givsly.com/" target="_blank" rel="noopener noreferrer">
      www.givsly.com
    </ExternalLink>
  );
  const givslyCompany = (
    <ExternalLink href="https://company.givsly.com/" target="_blank" rel="noopener noreferrer">
      company.givsly.com
    </ExternalLink>
  );

  const address = (
    <>
      Givsly PBC,
      <br />
      353 Lexington Avenue, Suite #400
      <br />
      New York, NY 10016
      <br />
      {mailToHelloAtGivsly}
    </>
  );

  return (
    <div className={classes}>
      <p className={css.lastUpdated}>Last updated on October 1, 2023</p>
      <p>
        Givsly operates an online platform for purpose-led advertising and marketing through the
        Responsible Marketing and Responsible Advertising services (the “Platform”, “Givsly Platform”, and
        together with the Platform, the “Services”). Companies (“Client”, “you”) may use with the
        Givsly Platform to receive Services from Givsly. Clients may include advertising and
        marketing agencies or corporations using Givsly Platform for themselves or working on behalf
        of their advertiser clients, brands, or other commercial entities. Please note, nonprofit
        organizations using the Platform are subject to our Terms for nonprofits. Client’s use of
        the Platform is subject to the following terms and conditions (these "Terms of Service" or
        “Terms”). As used herein, the terms "we", "us", "our", "Givsly” mean Givsly PBC. By
        affirmatively checking the box under Givsly sign-in, registration page, donation offer
        response or by using any of the Services, you agree to be bound by and to abide by these
        Terms of Service. If you do not agree to these terms, you may not access or use the
        Services.
      </p>

      <p>
        In consideration of the foregoing, and the mutual covenants, terms and conditions set forth
        herein, and other good and valuable consideration, the receipt and sufficiency of which are
        hereby acknowledged, the parties hereto hereby agree as follows:
      </p>

      <AnchorTitle id="services" style={{ textDecoration: 'underline' }}>
        1. Services
      </AnchorTitle>
      <ul>
        <li data-marker="1.1">
          <u>Overview.</u> Givsly shall perform the Services as set forth in one or more mutually
          agreed upon statements of work (each a “Statement of Work”). Givsly processes personal
          information pursuant to Givsly’s Platform and Services Privacy Policy provided that, in
          the event of any conflicts between those documents and these Terms, these Terms shall
          always prevail.
        </li>
        <li data-marker="1.2">
          <u>Changes.</u> Givsly’s obligations are solely as expressly set forth in the in these
          Terms. Changes must be requested in writing with sufficient detail to enable the other
          party to assess the impact of the requested change. Upon receipt of a request for any
          changes the receiving party shall set forth in writing within ten (10) days of such
          request any changes to the services, products, processes or costs necessary to implement
          such changes in the form of a Statement of Work. All changes must be mutually agreed to in
          writing by both parties. Until a change is agreed to in writing by both parties, these
          Terms shall apply.
        </li>
        <li data-marker="1.3">
          <u>Cooperation.</u> Givsly shall perform the Services, its responsibilities and
          obligations: (i) in a timely and professional manner consistent with industry standards
          and any specifications set forth in these Terms and any applicable Statement(s) of Work;
          (ii) in compliance with all applicable laws. Client acknowledges that the rendering of the
          Services will require the good faith cooperation of Client. Therefore, Client agrees to
          provide all information reasonably requested by Givsly in order to perform the Services,
          to timely respond to inquiries and to promptly review any deliverables. Givsly reserves
          the right to reasonably extend the delivery schedule and/or assess reasonable additional
          charges to the extent required because of Client’s failure to cooperate as set forth
          herein. Client shall be responsible for all materials and information provided by, on
          behalf of or approved by Client for use in connection with the Services. Givsly may rely
          upon all decisions and approvals from Client, and Givsly shall not be liable for any
          matter as a result of following Client’s specific instructions.
        </li>
        <li data-marker="1.4">
          <u>Platform Access.</u> Subject to the terms and conditions of these Terms, including
          payment of the fees, Givsly hereby agrees to provide Client with access to and use of the
          Platform as set forth in these Terms. Client may only use the Platform for Client's own
          business purposes, and not in the operation of a service bureau or for the benefit of any
          other person or entity, except as set forth herein. Client agrees that it shall not use
          any persons, means, devices or arrangements to exceed its permitted access to the
          Platform, including interfering with other clients or manipulating results or information.
          Client shall be granted the number of user IDs and passwords as set forth on the Statement
          of Work. The user IDs may not be used by any party other than Client’s employees without
          the prior written consent of Givsly. Client acknowledges that Givsly may, from time to
          time, monitor the logon times and usage under each user ID for the purpose of verifying
          that the Client and its permitted users are the sole holder and user of the user IDs and
          are in compliance with these Terms, and as otherwise necessary, for Givsly to provide
          support and operate the Service. Client shall be responsible for all usage of the Platform
          under its user IDs, whether authorized or unauthorized by Client, except to the extent
          caused for a negligent act or omission by Givsly. Client agrees to notify Givsly promptly
          of any unauthorized use of its user IDs or any other suspected breach of security relating
          to the Platform
          <ul>
            <li data-marker="1.4.1" style={{ marginLeft: '2.5em' }}>
              Further, Client shall not use the Platform to
            </li>
            <li data-marker="1.4.2" style={{ marginLeft: '2.5em' }}>
              To transmit, or procure the sending of, any advertising or promotional material
              including any “junk mail”, “chain letter” or “spam” or any other similar solicitation,
              or otherwise post or transmit sales pitches for outside products or services or
              content that drive traffic to a third party’s website (including for personal,
              political or monetary gain) except in express accordance with these Terms;
            </li>
            <li data-marker="1.4.3" style={{ marginLeft: '2.5em' }}>
              Use any robot, spider or other automatic device, process or means to access the
              Platform for any purpose, including monitoring or copying any of the material on the
              Platform;
            </li>
            <li data-marker="1.4.4" style={{ marginLeft: '2.5em' }}>
              Use any device, software or routine that interferes with the proper working of the
              Platform;
            </li>
            <li data-marker="1.4.5" style={{ marginLeft: '2.5em' }}>
              Introduce any viruses, Trojan horses, worms, logic bombs or other material which is
              malicious or technologically harmful;
            </li>
            <li data-marker="1.4.6" style={{ marginLeft: '2.5em' }}>
              Attempt to gain unauthorized access to, interfere with, damage or disrupt any parts of
              the Platform, or any server, computer or database connected to the Platform;
            </li>
            <li data-marker="1.4.7" style={{ marginLeft: '2.5em' }}>
              Circumvent any technical measures we use to provide the Services; and
            </li>
            <li data-marker="1.4.8" style={{ marginLeft: '2.5em' }}>
              Otherwise attempt to interfere with the proper working of the Services.
            </li>
          </ul>
        </li>
        <li data-marker="1.5">
          Givsly is not responsible for any offers, promises, rewards or promotions made or offered
          by Clients. We do not and cannot verify the information that nonprofits supply, nor do we
          represent or guarantee that the donations will be used in accordance with any fundraising
          purpose prescribed by a nonprofit or in accordance with applicable laws. Notwithstanding
          the foregoing, we take possible fraudulent activity and the misuse of funds raised very
          seriously. If you have reason to believe that a nonprofit is not raising or using the
          funds for their stated purpose, please contact us to alert our team of this potential
          issue at {mailToSupportAtGivsly} and we will investigate.
        </li>
      </ul>
      <AnchorTitle id="term-and-termination">2. Term and Termination.</AnchorTitle>
      <ul>
        <li data-marker="2.1">
          <u>Term.</u> These Terms shall commence on the date accepted and shall continue until
          terminated as set forth herein (the “Term”).
        </li>
        <li data-marker="2.2">
          <u>Termination.</u>
          <ul>
            <li data-marker="2.2.1">
              Either party may terminate their agreement under these Terms or a Statement of Work
              upon thirty (30) days prior written notice if the other party is in material breach of
              these Terms or a Statement of Work and fails to cure such breach within the notice
              period.
            </li>
            <li data-marker="2.2.2">
              Either party may terminate their agreement under these Terms for convenience upon
              ninety (90) days prior written notice, provided that, pre-paid fees for annual
              subscriptions and Givsly Credits are non-refundable absent any breach of these Terms
              by Givsly.
            </li>
          </ul>
        </li>
        <li data-marker="2.3">
          <u>Effect of Termination.</u> Upon the termination of these Terms or any Statement of Work
          for any reason, Client shall pay all fees, donation payments corresponding to qualifying
          donation offer responses and expenses due or owing, and each party shall reasonably
          cooperate with the other party to engage in an orderly termination of the Services. The
          provisions of these Terms that are logically intended to survive termination shall
          survive.
        </li>
      </ul>

      <AnchorTitle id="cost-and-expenses">3. Costs and Expenses</AnchorTitle>

      <ul>
        <li data-marker="3.1">
          <u>Invoices and Payment.</u> Givsly shall submit invoices to Client for all Services from
          time to time. Invoices are due in full within thirty (30) days of the date of such
          invoice. If Client disputes any invoice in good faith, Client must notify Givsly in
          writing within thirty (30) days of the invoice date, otherwise such invoice shall be
          deemed to be final. If Client has provided credit card details for payment purposes or has
          purchased pre-paid Givsly credits for any Service, then Client hereby authorizes Givsly to
          charge the amounts due to such credit card or deduct such amounts from the Client's credit
          balance.
          <ul>
            <li data-marker="3.1.1">
              Client agrees that in some instances a certain minimum donation amount may apply, and
              that all donations are final and will not be refunded unless Givsly, in its sole
              discretion, agrees to issue a refund. Givsly uses third-party payment processing
              partners to bill you through credit card or will separately invoice you directly to
              your billing account for any donations made.
            </li>
          </ul>
        </li>
        <li data-marker="3.2">
          <u>Reporting.</u> Givsly’s numbers reported via the Pixel (defined below) or the Platform
          shall govern for all payment obligations triggered by display of the Client Materials
          (defined below). Notwithstanding the foregoing, in the event that the numbers reported by
          Client differ from the numbers reported by Givsly by more than ten percent (10%), then
          upon request from Client, Givsly and Client shall promptly engage in good faith
          discussions to resolve such discrepancies.
        </li>
        <li data-marker="3.3">
          <u>Taxes.</u> Each party shall be responsible for the collection and payment of all taxes
          of whatever nature in connection with its obligations under these Terms. If Givsly is
          required by law to collect any taxes from Client, then Client shall pay such taxes as
          invoiced.
        </li>
        <li data-marker="3.4">
          <u>Expenses Generally.</u> Each party shall be solely responsible for all of its expenses
          incurred in connection with the performance under these Terms.
        </li>
        <li data-marker="3.5">
          <u>Givsly Credits.</u> Client may purchase pre-paid credits for the Platform (“Givsly
          Credits”, “Credits”). Credits are non-refundable, absent a breach of these Terms by
          Givsly. If Client is using pre-paid credits for processing donations, then the donation
          and any associated payment processing fees will be deducted from such pre-paid credits.
          From each donation made, Givsly receives any agreed upon platform-, lead generation- and
          administrative fees immediately, the payment processor receives any prevailing, applicable
          payment processing fees, (collectively “Fees”), and the remainder of the donation will be
          sent to the nonprofit using a payment service provider. Client shall not sell or resell
          such Prepaid Credits or otherwise transfer such Prepaid Credits for value. Client agrees
          and acknowledges that any donation amount exceeding the Prepaid Credits available and
          applied, Client shall be required to be paid by Client using a payment instrument that is
          not Prepaid Credits. Additionally, as a Client applying or receiving Prepaid Credits you
          acknowledge and agree to the following: (i) unused Prepaid Credits will be removed from
          your Billing Account or twelve (12) months after the date of their funding by, unless
          prohibited by law; (iii) Givsly will not refund unused Prepaid Credits except as described
          in these Terms of Service or required by law. Access to Prepaid Credits on any given day
          is limited to $2,000.
        </li>
      </ul>

      <AnchorTitle id="intellectual-property-and-data">
        4. Intellectual Property & Data.
      </AnchorTitle>
      <ul>
        <li data-marker="4.1">
          <u>Client Materials.</u> Client may from time to time provide Givsly with, upload to
          Givsly Platform, or otherwise make available to Givsly, content and materials for use in
          connection with the Services, such as advertising creative, photos, copy, logos and other
          promotional materials (“
          <u>Client Materials</u>”). Client grants Givsly a limited, non-exclusive, worldwide,
          royalty-free right and license to display, distribute and otherwise use the Client
          Materials solely in connection with the Services.
        </li>
        <li data-marker="4.2">
          <u>Platform Ownership.</u> Givsly hereby grants Client a limited, non-exclusive,
          non-transferrable, worldwide, royalty-free right and license to use the Platform during
          the Term. Except as set forth herein, Givsly owns all right, title and interest in and to
          the Platform, its trademarks, software and other proprietary materials made available by
          Givsly. Client may from time to time provide suggestions, comments, improvements,
          enhancements, or other feedback ("
          <u>Feedback</u>") to Givsly related to the Platform. Client hereby grants Givsly a
          perpetual right and license to use such Feedback for any purpose.
        </li>
        <li data-marker="4.3">
          <u>Responsible Advertising Pixel Data.</u> Givsly may provide Client with pixels to attach to
          advertising creative served online in connection with Responsible Advertising (the “<u>Pixel</u>
          ”). As between Givsly and Client, Client shall own all data generated by the Pixel when
          attached to advertising creative owned by the Client (the “<u>Pixel Data</u>”). Givsly may
          use the Pixel Data to provide the Services to Client, including sharing such Pixel Data
          with vendors engaged in providing such Services to Client. Client shall ensure that all
          publishers, ad creatives, and services on which the Pixel is used make all necessary
          disclosures pursuant to applicable privacy laws in order to collect and transmit the Pixel
          Data to Givsly and Client as contemplated by these Terms. Notwithstanding the foregoing,
          Client hereby grants Givsly a perpetual right and license to use the Pixel Data for
          Givsly's internal business purposes, provided that, Givsly shall not publicly disclose
          such Pixel Data in a manner that identifies Client or contains any personal information.
        </li>
        <li data-marker="4.4">
          <u>Responsible Marketing Donation Data.</u> In connection with Responsible Marketing campaigns, Givsly
          may receive personal information from recipients of Clients' donation offers, such as
          names, email address and other information of individuals to whom Client wishes to send a
          donation offer (the “<u>Donation Data</u>”). As between Givsly and Client, Client shall
          own all Donation Data. Client shall ensure that it has all rights in order to collect and
          transmit the Donation Data to Givsly as contemplated by these Terms. Notwithstanding the
          foregoing, Client hereby grants Givsly a perpetual right and license to use the Donation
          Data for Givsly's internal business purposes, provided that, Givsly shall not publicly
          disclose such Donation Data in a manner that identifies Client or contains any personal
          information.). A Client who generates a donation offer link agrees to fund a donation to
          whichever nonprofits is selected by the Client. Clients sending donation offers do so at
          their own discretion and are required to have obtained any necessary consent (where
          applicable) from the user receiving the donation offer. Givsly only sends donation offers
          as instructed to do so by Client.
        </li>
        <li data-marker="4.5">
          <u>Rights.</u> Except for the rights provided under these Terms, neither party shall
          acquire any right, license or interest in the intellectual property of the other party by
          virtue of entering into these Terms. Each party shall be free to use and employ its
          general skills, know-how, methodologies, algorithms, techniques and expertise to provide
          services to third parties, provided that in doing so it does not breach any of its
          obligations under these Terms.
        </li>
        <li data-marker="4.6">
          <u>Data Privacy.</u> The parties shall comply with all applicable privacy and security
          laws, rules, regulations, standards and guidelines, including those set forth in the Data
          Processing Addendum (“
          <u>DPA</u>”) attached hereto as <u>Exhibit 1</u>.
        </li>
      </ul>

      <AnchorTitle id="representations-limitation-of-liability">
        5. Representations; Limitation of Liability.
      </AnchorTitle>
      <ul>
        <li data-marker="5.1">
          <u>Mutual Representations.</u> Each party represents and warrants to the other party that:
          (i) it shall perform its obligations hereunder in a timely, workmanlike and professional
          manner and with due care; (ii) it has the full right, power and authority to enter into
          these Terms and to perform the acts required of it hereunder; (iii) the execution of these
          Terms and the performance of its obligations hereunder, do not and will not violate any
          agreement to which it is a party or by which it is bound; and (iv) it shall at all times
          be in compliance with all applicable laws, rules and regulations in connection with its
          respective obligations under these Terms. Givsly may from time to time provide Client with
          suggestions regarding consumer disclosures, provided that, Client shall always remain
          solely responsible for all consumer disclosures made by or on behalf of Client. For the
          avoidance of doubt, Givsly is a provider of the Platform and performs Professional
          Services, but is not responsible for Client’s own obligations to comply with applicable
          charitable solicitation laws.
        </li>
        <li data-marker="5.2">
          <u>Client Materials Representations.</u> Client further represents and warrants that: (i)
          the Client Materials do not violate the intellectual property or personal rights of any
          third party, (ii) the Client Materials do not contain any content that is illegal,
          obscene, deceptive, misleading or otherwise violates any laws or the policies of third
          parties (such as publishers or social media platforms) where the Client Materials are
          displayed and (iii) it makes all disclosures required by applicable laws, including but
          not limited to, disclosures regarding charitable solicitations/donations.
        </li>
      </ul>

      <AnchorTitle id="limitation-of-liability">6. LIMITATION OF LIABILITY.</AnchorTitle>
      <p>
        NEITHER PARTY SHALL HAVE ANY LIABILITY TO THE OTHER PARTY FOR ANY INCIDENTAL OR
        CONSEQUENTIAL DAMAGES, INCLUDING WITHOUT LIMITATION LOSS OF USE, REVENUE OR PROFIT ARISING
        FROM ANY FAILURE, DELAY OR DEFECT OF PERFORMANCE, ARISING OUT OF OR RELATING TO ANY SERVICES
        PROVIDED HEREIN, EVEN IF SUCH DAMAGES ARE FORESEEABLE AND WHETHER OR NOT IT HAS BEEN ADVISED
        OF THE POSSIBILITY THEREOF. GIVSLY IS NOT LIABLE TO CLIENT OR ANY PARTY WHO HAS NOT AGREED
        TO THESE TERMS FOR ANY GIFTS OR DONATIONS PROVIDED BY CLIENT TO, OR RECEIVED FROM, A THIRD
        PARTY. IN NO EVENT SHALL GIVSLY’S LIABILITY FOR ANY DAMAGES TO CLIENT OR ANY OTHER PARTY
        EXCEED THE FEES PAID BY CLIENT TO GIVSLY UNDER THE STATEMENT OF WORK TO WHICH SUCH CLAIM
        RELATES DURING THE PRIOR TWELVE (12) MONTHS.
      </p>
      <AnchorTitle>7. DISCLAIMER OF WARRANTIES.</AnchorTitle>
      <p>
        EXCEPT AS EXPRESSLY PROVIDED HEREIN, NEITHER PARTY MAKES ANY WARRANTIES OF ANY KIND
        REGARDING THE PERFORMANCE OR OUTCOME OF THE SERVICES WHETHER EXPRESS OR IMPLIED, STATUTORY
        OR OTHERWISE, INCLUDING BUT NOT LIMITED TO ANY IMPLIED WARRANTY OF MERCHANTABILITY OR
        FITNESS OR FROM A COURSE OF DEALING OR USAGE IN TRADE. NOTWITHSTANDING ANYTHING TO THE
        CONTRARY, GIVSLY SHALL NOT BE LIABLE TO CLIENT OR ANY OTHER PARTY FOR CLIENT’S USE OF THE
        SERVICES. NOTWITHSTANDING ANYTHING IN THIS SECTION TO THE CONTRARY, NEITHER PARTY EXCLUDES
        NOR LIMITS ITS LIABILITY TO THE OTHER PARTY FOR DAMAGES RESULTING FROM THE GROSS NEGLIGENCE
        OR WILLFUL MISCONDUCT OF ITS AGENTS OR EMPLOYEES, FRAUDULENT MISREPRESENTATION, OR FOR ANY
        OTHER LIABILITY WHICH CANNOT BE EXCLUDED OR LIMITED BY APPLICABLE LAW.
      </p>
      <AnchorTitle id="third-party-content-and-services">
        8. Third Party Content and Services
      </AnchorTitle>
      <p>
        Certain Services may be dependent upon the products and services of third parties, such as
        payment providers (such as Stripe), hosting providers (such as Amazon Web Services), email
        service providers, social media platforms, online service companies and similar third
        parties not controlled by Givsly. While Givsly shall make commercially reasonable efforts to
        resolve any issues, Givsly shall not be liable for the acts or omissions of such third
        parties, including the failure of the products or services of such third parties to operate
        as intended. In addition, content may be made available by third parties, such as nonprofits
        or users of the Platform, and Givsly is not liable in any manner for the content provided by
        such third parties. Without limiting the foregoing, Givsly is not responsible for the acts,
        omissions or misrepresentations of any charity or nonprofit used in connection with the
        Service.
      </p>
      <AnchorTitle id="digital-millennium-copyright-act">
        9. DIGITAL MILLENNIUM COPYRIGHT ACT ("DMCA") NOTICE
      </AnchorTitle>
      <p>
        Materials may be made available via the Service by third parties not within our control
        (such as by Clients and Nonprofits participating in the Service). We are under no obligation
        to, and do not, scan content used in connection with the Service for the inclusion of
        illegal or impermissible content. However, we respect the copyright interests of others. It
        is our policy not to permit materials known by us to infringe another party’s copyright to
        remain on the Service.
      </p>
      <p>
        If you believe any materials on the Service infringe a copyright, you should provide us with
        written notice that at a minimum contains:
      </p>
      <ul>
        <li data-marker="(i)">
          A physical or electronic signature of a person authorized to act on behalf of the owner of
          an exclusive right that is allegedly infringed;
        </li>
        <li data-marker="(ii)">
          Identification of the copyrighted work claimed to have been infringed, or, if multiple
          copyrighted works at a single online site are covered by a single notification, a
          representative list of such works at that site;
        </li>
        <li data-marker="(iii)">
          Identification of the material that is claimed to be infringing or to be the subject of
          infringing activity and that is to be removed or access to which is to be disabled, and
          information reasonably sufficient to permit us to locate the material;
        </li>
        <li data-marker="(iv)">
          Information reasonably sufficient to permit us to contact the complaining party, such as
          an address, telephone number, and, if available, an electronic mail address at which the
          complaining party may be contacted;
        </li>
        <li data-marker="(v)">
          A statement that the complaining party has a good faith belief that use of the material in
          the manner complained of is not authorized by the copyright owner, its agent, or the law;
          and
        </li>
        <li data-marker="(vi)">
          A statement that the information in the notification is accurate, and under penalty of
          perjury, that the complaining party is authorized to act on behalf of the owner of an
          exclusive right that is allegedly infringed.
        </li>
      </ul>
      <p>All DMCA notices should be sent to our designated agent as follows:</p>
      <p>Givsly, PBC</p>
      <p>Attn: Copyright Manager</p>
      <p>353 Lexington Avenue, Suite 400</p>
      <p>New York, NY 10016</p>
      <p>and followed up via Email to: {mailToSupportAtGivsly}</p>
      <p>
        It is our policy to terminate relationships regarding content with parties who repeatedly
        infringe the copyrights of others.
      </p>
      <AnchorTitle id="force-majeure">10. Force majeure</AnchorTitle>
      <p>
        Except for payment obligations, neither party shall be held liable for any delay or failure
        in performance of any part of this agreement from any cause beyond its reasonable control
        and without its fault or negligence, including, but not limited to, acts of god, acts of
        civil or military authority, embargoes, epidemics, war, terrorist acts (actual or
        threatened), riots, insurrections, fires, explosions, earthquakes, nuclear accidents,
        floods, strikes, telecommunication service provider failures or power blackouts.
      </p>
      <AnchorTitle id="confidental-information">11. Confidential Information.</AnchorTitle>
      <ul>
        <li data-marker="11.1">
          <u>Definition.</u> In connection with these Terms, each party may disclose, or may learn
          of or have access to, certain confidential proprietary information owned by the other
          party (“<u>Confidential Information</u>”). Confidential Information means any data or
          information, oral or written, that relates to a party, including but not limited to its
          business activities, product plans, designs, costs, technology, software, business
          opportunities, personnel, customers, research, development, know-how, financial
          information, customer and supplier lists, forecasts, and projections. Confidential
          Information also includes the provisions of these Terms. Notwithstanding the foregoing,
          Confidential Information is deemed not to include information that: (i) is publicly
          available or in the public domain at the time disclosed; (ii) is or becomes publicly
          available or enters the public domain through no fault of the receiving party; (iii) is
          rightfully communicated to the receiving party by persons not bound by confidentiality
          obligations with respect thereto; (iv) is already in the receiving party's possession free
          of any confidentiality obligations with respect thereto; (v) is independently developed by
          a party without use of any Confidential Information of the other party; or (vi) is
          approved for release or disclosure by the disclosing party without restriction.
        </li>
        <li data-marker="11.2">
          <u>Obligations.</u> Except as expressly permitted under these Terms, each party shall
          maintain the Confidential Information of the other party in strict confidence and shall
          not disclose, publish or copy any part of such Confidential Information except as required
          to perform authorized obligations under these Terms. Each party shall take all necessary
          precautions in handling the Confidential Information of the other party and limit
          disclosures on a strict need-to-know basis, provided that, each party shall be liable for
          the acts or omissions of any party that obtains Confidential Information through such
          party. However, a party may disclose Confidential Information of the other party pursuant
          to the order or requirement of a court, administrative agency, or other governmental body,
          provided that such party gives reasonable prior notice to the other party to contest such
          order or requirement. Upon the termination or expiration of these Terms, each party shall
          return to the other party, or certify the destruction of, all Confidential Information of
          the other party, provided that, neither party shall be required to purge any materials
          that have been archived pursuant to its normal document retention policy or is required to
          be maintained by applicable law if the obligations in this Section 10 are otherwise
          strictly observed.
        </li>
      </ul>
      <AnchorTitle id="indemnification">12. Indemnification</AnchorTitle>
      <ul>
        <li data-marker="12.1">
          <u>Givsly</u> Givsly shall defend, indemnify and hold harmless Client, its affiliates,
          successors and assigns, and each of their officers, directors, employees and agents
          (“Indemnitees”), against and from any and all third party claims, liabilities, damages,
          fines, penalties or costs of whatsoever nature (including reasonable attorney’s fees and
          costs) (“Losses”), arising out of or in any way connected with (i) a claim that the
          Platform infringes upon the intellectual property rights of any third party or (ii) any
          violation by Givsly of applicable law applicable to Givsly’s obligations under these
          Terms.
        </li>
        <li data-marker="12.2">
          <u>Client.</u> Client shall defend, indemnify and hold harmless Givsly and its Indemnitees
          against and from any and all Losses arising out of or in any way connected with: (i)
          Client Materials, (ii) any violation by Client of any applicable law applicable to Client
          and (iii) Client’s transactions with any third party, such as e.g. a consumer or
          publisher.
        </li>
        <li data-marker="12.3">
          <u>Process.</u> The indemnifying party's obligations are conditioned upon the indemnified
          party: (i) giving the indemnifying party prompt written notice of any claim, action, suit
          or proceeding for which the indemnified party is seeking indemnity (provided that a
          failure or delay in providing such notice shall not relieve the indemnifying party's
          obligations, except to the extent prejudiced by such failure or delay); (ii) granting
          complete control of the defense and settlement to the indemnifying party, provided that
          the indemnifying party will not acquiesce to any judgment or settlement without the
          indemnified party's prior written consent, unless it obtains a full and final release of
          all claims against the indemnified party and such judgment or settlement does not impose
          any requirements or restrictions upon the indemnified party; and (iii) reasonably
          cooperating with the indemnifying party, at the indemnifying party's expense, in the
          defense and settlement of such claim. The indemnifying party shall provide the indemnified
          party the option to engage separate counsel, at the indemnified party's expense, to
          participate in any claim giving rise to indemnification hereunder. The indemnifying party
          may settle any claim, to the extent it seeks a money payment, with or without the consent
          of the indemnified party. The indemnifying party must obtain the indemnified party's
          consent to any settlement to the extent it consents to injunctive relief or contains
          contract terms governing future activities that would materially affect the indemnified
          party's business or interests, said consent not to be unreasonably withheld, conditioned
          or delayed.
        </li>
      </ul>
      <AnchorTitle id="general">13. General</AnchorTitle>
      <ul>
        <li data-marker="13.1">
          <u>Anti-Corruption.</u> Clients shall not offer, give or promise to give any payment,
          consideration, financial or non-financial advantage to another person or persons directly
          or indirectly in connection with our Services or these Terms.
        </li>
        <li data-marker="13.2">
          <u>Independent Contractors.</u> Each party to these Terms is an independent contractor in
          relation to the other party with respect to all matters arising under these Terms. Nothing
          herein shall be deemed to establish a partnership, joint venture, association or
          employment relationship between the parties.
        </li>
        <li data-marker="13.3">
          <u>Breaches and Remedies.</u> Except as otherwise provided herein, any and all remedies
          herein expressly conferred upon a party will be deemed cumulative with and not exclusive
          of any other remedy conferred hereby, or by law or equity upon such party, and the
          exercise by a party of any one remedy will not preclude the exercise of any other remedy.
        </li>
        <li data-marker="13.4">
          <u>Publicity.</u> Givsly may identify Client as a customer of Givsly by using its name,
          logo and trademark, together with information about publicly disclosed Services, for
          Givsly’s own promotional purposes, such as on its website or on social media platforms.
        </li>
        <li data-marker="13.5">
          <u>Notices.</u> Any official notice given pursuant to these Terms shall be sent to the
          other party by: (i) certified mail return receipt requested, (ii) overnight courier or
          (iii) electronic mail followed by a hard copy by one of the prior methods.
        </li>
        <li data-marker="13.6">
          <u>Severability.</u> If, for any reason, a court of competent jurisdiction finds any
          provision of these Terms, or portion thereof, to be invalid or unenforceable, such
          provision of the Terms will be enforced to the maximum extent permissible so as to effect
          the intent of the parties, and the remainder of these Terms will continue in full force
          and effect. The parties agree to negotiate in good faith an enforceable substitute
          provision for any unenforceable provision that most nearly achieves the intent and
          economic effect of the unenforceable provision.
        </li>
        <li data-marker="13.7">
          <u>Assignment.</u> These Terms and the rights granted hereunder are not transferable or
          assignable without the prior written consent of the non-assigning party, except in
          connection with a merger, sale or reorganization of a party. Givsly may engage
          subcontractors in the performance of its duties herein, provided that, Givsly shall remain
          responsible for such subcontractors pursuant to these Terms. Except as otherwise expressly
          provided herein, the provisions hereof shall inure to the benefit of, and be binding upon,
          the successors, permitted assigns, heirs, executors and administrators of the parties
          hereto.
        </li>
        <li data-marker="13.8">
          <u>No Third-Party Beneficiaries.</u> These Terms are for the sole benefit of the parties
          hereto and their permitted assigns and nothing herein expressed or implied shall give or
          be construed to give to any person, other than the parties hereto and such assigns, any
          legal or equitable rights hereunder.
        </li>
        <li data-marker="13.9">
          <u>Export Controls.</u> Client agrees to comply fully with all relevant export laws and
          regulations of the United States, including but not limited to the U.S. Export
          Administration Regulations, administered by the Department of Commerce, Bureau of Industry
          and Security (the “<u>U.S. Export Controls</u>”). Without limiting the generality of the
          foregoing, Client expressly agrees that it shall not, and shall not permit, use of the
          Services in connection with anyone listed on the OFAC Sanctions List or located in an
          embargoed or sanctioned country.
        </li>
        <li data-marker="13.10">
          <u>Amendment; Waiver.</u> No amendment or waiver of any term, condition or obligation of
          these Terms shall be valid unless in writing and signed by both parties. No failure or
          delay by either party at any time to require the other party to perform strictly in
          accordance with the terms hereof shall preclude that party from requiring performance by
          the other party at any later time. No waiver of any one or several of the terms,
          conditions or obligations of these Terms and no partial waiver thereof, shall be construed
          as a waiver of any of the other terms, conditions or obligations of these Terms.
        </li>
        <li data-marker="13.11">
          <u>Entire Agreement.</u> These Terms set forth the entire agreement between the parties on
          this subject matter contained herein and supersedes all prior negotiations, understandings
          and agreements between the parties concerning this subject matter.
        </li>
        <li data-marker="13.12">
          <u>Governing Law; Jurisdiction.</u> This Terms shall be governed by the laws of the State
          of New York and each party submits to exclusive jurisdiction and venue in the courts
          located in New York County, New York.
        </li>
        <li data-marker="13.13">
          <u>Headings.</u> The headings of the various sections of these Terms have been inserted
          for convenience of reference only and will not be deemed to be a part of these Terms.
        </li>
        <li data-marker="13.14">
          <u>Counterparts.</u> These Terms may be executed in any number of counterparts, each of
          which shall be an original and all of which together shall constitute one and the same
          document.
        </li>
      </ul>
      <h2>EXHIBIT 1</h2>
      <AnchorTitle id="data-processing-addendum">DATA PROCESSING ADDENDUM</AnchorTitle>
      <p>
        This Data Processing Addendum (“<b>DPA</b>”) forms part of the Master Services Agreement
        between Givsly, PBC and Client (the “<b>Agreement</b>”) to reflect the parties’ agreement
        with regard to the Processing of Client Personal Information.
      </p>
      <p>
        In the course of providing the Services to Client, Givsly may Process Client Personal
        Information on behalf of Client, and in such case, the parties agree to comply with the
        following provisions with respect to Client Personal Information.
      </p>
      <AnchorTitle id="dpa-definitions">Definitions</AnchorTitle>
      <ul>
        <li>
          Capitalized terms not otherwise defined herein shall have the meaning given to them in the
          Agreement. In this DPA, the following terms shall have the meanings set out below:
        </li>
        <li>
          “<b>Aggregate Data</b>” means information that relates to a group or category of
          individuals, from which individual identities have been removed, and that is not linked or
          reasonably linkable to any individual or household.
        </li>
        <li>
          “<b>Client Personal Information</b>” means any Personal Information provided by or on
          behalf of Client to Givsly and Processed by Givsly or Givsly’s Subprocessor, solely on
          behalf of Client, pursuant to the Agreement.
        </li>
        <li>
          “<b>Data Protection Assessment</b>” means an assessment of the impact of processing
          operations on the protection of Personal Information and the rights of Data Subjects, or
          is otherwise defined as a “Data Protection Assessment,” “Data Protection Impact
          Assessment,” or “Risk Assessment” by applicable Data Protection Laws.
        </li>
        <li>
          “<b>Data Protection Laws</b>” means any and all applicable U.S. data protection, security,
          or privacy-related laws, statutes, directives, or regulations, including but not limited
          to: (a) the California Consumer Privacy Act of 2018, Cal. Civ. Code § 1798.100 et seq. (“
          <b>CCPA</b>”), together with any amending or replacement legislation, including the
          California Privacy Rights Act of 2020 and any regulations promulgated thereunder; (b) the
          Virginia Consumer Data Protection Act of 2021, Va. Code Ann. § 59.1-571 to -581; (c) the
          Colorado Privacy Act of 2021, Co. Rev. Stat. § 6-1-1301 et seq.; (d) Connecticut Public
          Act No. 22-15, “An Act Concerning Personal Data Privacy and Online Monitoring”; (e) the
          Utah Consumer Privacy Act of 2022, Utah Code Ann. § 13-61-101 et seq.; (f) all other
          equivalent or similar laws and regulations in the United States relating to Personal
          Information and privacy; (g) Regulation 2016/679 of the European Parliament and of the
          Council on the protection of natural persons with regard to the Processing of Personal
          Information and on the free movement of such data (General Data Protection Regulation)
          (the "<b>EU GDPR</b>"); (ii) the GDPR as saved into United Kingdom law by virtue of
          section 3 of the United Kingdom's European Union (Withdrawal) Act 2018 (the "
          <b>UK GDPR</b>"); (iii) the EU e-Privacy Directive (Directive 2002/58/EC); and (iv) any
          and all applicable national data protection laws made under, pursuant to or that apply in
          conjunction with any of (i), (ii) or (iii); in each case as may be amended or superseded
          from time to time;.
        </li>
        <li>
          “<b>Data Subject</b>” means an identified or identifiable natural person whose Personal
          Information is being Processed. The term “Data Subject” shall refer to a “Consumer” as
          that term is defined under Data Protection Laws.
        </li>
        <li>
          “<b>Deidentified Data</b>” means information that cannot reasonably identify, relate to,
          describe, be capable of being associated with, be linked directly or indirectly with, or
          be reasonably be used to infer information about an identifiable natural person.
        </li>
        <li>
          “<b>Personal Information</b>” or “personal data” as referenced under Data Protection Laws,
          means information that is protected by applicable Data Protection Laws or that otherwise
          that identifies, relates to, describes, is capable of being associated with, or can
          reasonably be linked, directly or indirectly, with a particular individual or household.
        </li>
        <li>
          “<b>Personnel</b>” means officers, directors, employees, Subprocessors, agents and
          representatives.
        </li>
        <li>
          "<b>Restricted Transfer</b>" means: (i) where the EU GDPR applies, a transfer of personal
          information from the EEA to a country outside of the EEA which is not subject to an
          adequacy determination by the European Commission; and (ii) where the UK GDPR applies, a
          transfer of personal information from the United Kingdom to any other country which is not
          subject based on adequacy regulations pursuant to Section 17A of the United Kingdom Data
          Protection Act 2018.
        </li>
        <li>
          "<b>SCCs</b>" means: (i) where the EU GDPR applies, the contractual clauses annexed to the
          European Commission's Implementing Decision 2021/914 of 4 June 2021 on standard
          contractual clauses for the transfer of personal information to third countries pursuant
          to Regulation (EU) 2016/679 of the European Parliament and of the Council ("EU SCCs"); and
          (ii) where the UK GDPR applies, standard data protection clauses adopted pursuant to
          Article 46(2)(c) or (d) of the UK GDPR ("UK SCCs")
        </li>
        <li>
          “<b>Security Breach</b>” means any security incident that adversely impacts the security
          of Client Personal Information.
        </li>
        <li>
          “<b>Subprocessor</b>” means any third party appointed by Givsly to Process Client Personal
          Information as a Service Provider or Processor on behalf of Client in connection with the
          Agreement.
        </li>
        <li>
          The terms “<b>Business</b>,” “<b>Business Purpose</b>,” “<b>Controller</b>,” “
          <b>Process</b>,” “<b>Processor</b>,” “<b>Sell</b>,” “<b>Service Provider</b>,” and “
          <b>Share</b>” shall have the same meaning as in the Data Protection Laws, and their
          cognate terms shall be construed accordingly.
        </li>
      </ul>
      <AnchorTitle id="dpa-processing-of-personal-information">
        PROCESSING OF PERSONAL INFORMATION
      </AnchorTitle>
      <p>
        <b>Roles of the Parties</b>. The parties acknowledge and agree that with regard to the
        Processing of Client Personal Information, Client is the Controller or Business (as
        applicable), Givsly is the Processor or Service Provider (as applicable), and that Givsly
        may engage Subprocessors pursuant to the requirements set forth in Section 5 below. Where
        Givsly makes any Personal Information from consumers or other end users (that does not
        constitute Client Personal Information) available to Client in connection with the Services,
        the parties acknowledge and agree that: (i) Givsly and Client are each separate and
        independent Controllers for the purposes of such data; and (ii) each party is responsible
        for its (and its Personnel’s) own compliance with any obligations it may have as a
        Controller or a Business (as applicable) under Data Protection Laws. The parties acknowledge
        and agree that neither party has reason to believe that the other party is unable to comply
        with the provisions of this DPA or otherwise that such party is in violation of any Data
        Protection Law. For clarity, Givsly is not responsible for compliance with any Data
        Protection Laws applicable to Client or Client’s industry that are not otherwise generally
        applicable to Givsly.
      </p>
      <p>
        <b>Givsly’s Processing of Personal Information</b>. Givsly shall treat Client Personal
        Information as confidential and shall only Process Client Personal Information as necessary
        to perform its obligations on behalf of and in accordance with Client’s documented
        instructions for the following permitted purposes: (i) in accordance with the Agreement and
        applicable order or scope of work and applicable Data Protection Laws; and/or (ii) as
        applicable, if initiated by Data Subjects in their use of the Services. Givsly shall not (A)
        Sell, Share, or otherwise make available Client Personal Information to any third party in
        exchange for monetary or other valuable consideration, and (B) retain, use or disclose
        Client Personal Information outside of the direct business relationship with the Client or
        for any other purpose than what is specified in the Agreement and/or this DPA. When acting
        as a Service Provider under the CCPA, Givsly shall not combine Client Personal Information
        with Personal Information it receives from, or on behalf of, another person or persons, or
        processes as a Controller or a Business (as applicable), except as expressly permitted by
        Data Protection Laws. Givsly shall notify Client after it makes a determination that it can
        no longer meet its obligations under applicable Data Protection Laws. Nothing herein shall
        limit or restrict Givsly’s right to use Aggregate Data and/or Deidentified Data.
      </p>
      <p>
        <b>Client’s Processing of Personal Information</b>. Client shall, in its use of the
        Services, Process Personal Information in accordance with the requirements of Data
        Protection Laws. Client’s instructions to Givsly related to the Processing of Client
        Personal Information shall comply with Data Protection Laws. Client instructs Givsly (and
        authorizes Givsly to instruct each Subprocessor) to Process Client Personal Information, and
        in particular, transfer Client Personal Information to any jurisdiction, as necessary for
        the provision of the Services and consistent with the Agreement and this DPA. Client
        represents and warrants that it shall (i) not provide Givsly with (or instruct Givsly to
        Process) any Personal Information unless it shall first have given and received the
        necessary notices and consents under Data Protection Laws; and (ii) comply with any other
        requirements under applicable Data Protection Laws.
      </p>
      <p>
        The parties agree that, when the transfer of Personal Information from Client to Givsly is a
        Restricted Transfer, it shall be subject to the appropriate SCCs as follows: (a) in relation
        to data that is protected by the EU GDPR, the EU SCCs will apply completed as follows: (i)
        Module Two will apply; (ii) in Clause 7, the optional docking clause will apply; (iii) in
        Clause 9, Option 2 will apply, and the time period for prior notice of subprocessor changes
        shall be as set out in this DPA; (iv) in Clause 11, the optional language will not apply;
        (v) in Clause 17, Option 1 will apply, and the EU SCCs will be governed by Irish law; (vi)
        in Clause 18(b), disputes shall be resolved before the courts of Ireland; (vii) Annex I of
        the EU SCCs shall be deemed completed with the information set out in Annex I to this
        Agreement; and (viii) Annex II of the EU SCCs shall be deemed completed with the information
        set out in Annex II to this Agreement; (b) in relation to data that is protected by the UK
        GDPR, the UK SCCs will apply completed as follows: (i) Table 1 of the UK SCCs shall be
        deemed completed with the information set out Annex I to this Agreement; and (ii) Table 2 of
        the UK SCCs shall be deemed completed with the information set out below: The parties
        select: the Approved EU SCCs, including the Appendix, Information and with only the
        following modules, clauses or optional provisions of the Approved EU SCCs brought into
        effect for the purposes of this Addendum: Module 1 with selections as set forth in Section 1
        of this Addendum (iii) Table 3 of the UK SCCs shall be deemed completed with the information
        set out Annexes I and II to this Agreement; and (iv) Table 4 of the UK SCCs shall be deemed
        completed by selecting: neither party (c) in the event that any provision of this Agreement
        contradicts, directly or indirectly, the Standard Contractual Clauses, the Standard
        Contractual Clauses shall prevail.
      </p>
      <p>
        <b>Details of the Processing</b>. The subject matter of Processing, the duration of the
        Processing, the nature and purpose of the Processing, the types of Client Personal
        Information, and categories of Data Subjects Processed under this DPA are specified in Annex
        I attached hereto.
      </p>
      <AnchorTitle id="dpa-rights-of-data-subjects">RIGHTS OF DATA SUBJECTS</AnchorTitle>
      <p>
        The parties shall reasonably cooperate in responding to Data Subject rights requests (“
        <b>Data Subject Request</b>”) and complying with requirements of Data Protection Laws in
        relation thereto.
      </p>
      <p>
        If a Data Subject Request is made directly to Givsly regarding Client Personal Information,
        Givsly will promptly inform Client and will advise the Data Subject to submit the request to
        Client. Client will be solely responsible for responding substantively to any such Data
        Subject Requests or other communications involving Personal Information.
      </p>
      <AnchorTitle id="dpa-givsly-personnel">GIVSLY PERSONNEL</AnchorTitle>
      <p>
        <b>Confidentiality</b>. Givsly shall ensure that its Personnel engaged in the Processing of
        Client Personal Information are informed of the confidential nature of the Client Personal
        Information, and have received appropriate training regarding the Processing of Client
        Personal Information.{' '}
      </p>
      <p>
        <b>Reliability</b>. Givsly shall endeavor, in the exercise of its reasonable business
        discretion, to ensure the reliability of any Personnel engaged in the Processing of Client
        Personal Information.
      </p>
      <p>
        <b>Limitation of Access</b>. Givsly shall ensure that Givsly’s access to Client Personal
        Information is limited to those Personnel performing the Services in accordance with the
        Agreement.
      </p>
      <AnchorTitle id="dpa-subprocessors">SUBPROCESSORS</AnchorTitle>
      <p>
        <b>Appointment of Subprocessors</b>. With respect to the Processing of Client Personal
        Information, Client authorizes Givsly to appoint Subprocessors to Process Client Personal
        Information for a business purpose on behalf of Client, and consistent with the business
        purpose set forth herein, pursuant to a written contract that includes obligations that are
        at least as protective as those set out in this DPA and as required by Data Protection Laws.{' '}
      </p>
      <p>
        If Client objects to the engagement of any Subprocessor, it must inform Givsly within five
        (5) business days’ of Givsly’s notice of the engagement of such Subprocessor, on justifiable
        data protection grounds, and then either Givsly, will not engage the Subprocessor to process
        the Personal Informaion, Client may elect to suspend or terminate the processing of Personal
        Information under the Agreement(s) without penalty, or Givsly may terminate the Agreement(s)
        upon advance written notice to Client.
      </p>
      <AnchorTitle id="dpa-security">SECURITY</AnchorTitle>
      <p>
        <b>Controls for the Protection of Client Personal Information</b>. Givsly shall maintain
        appropriate physical, technical and organizational measures designed to protect the
        security, confidentiality, and integrity of Client Personal Information. In the event of any
        (i) unauthorized acquisition, alteration, or disclosure of Client Personal Information that
        requires notification to an individual, government or regulatory body, or law enforcement
        authority under Data Protection Laws, or (ii) breach of Data Protection Laws with respect to
        Client Personal Information, Givsly shall notify Client promptly.
      </p>
      <p>
        <b>Data Security Incident Management and Notification</b>. Givsly shall maintain security
        incident management policies and procedures, and if at any time Givsly determines that there
        has been a Security Breach, Givsly shall promptly: (i) notify Client in writing of such
        Security Breach; (ii) investigate and take steps to remediate the Security Breach, and (iii)
        provide information regarding the specific Client Personal Information adversely impacted by
        the Security Breach as reasonably requested by Client.
      </p>
      <AnchorTitle id="dpa-information-provision-and-cooperation">
        INFORMATION PROVISION AND COOPERATION
      </AnchorTitle>
      <p>
        <b>Audits and Assessments</b>. If required of Givsly under applicable Data Protection Laws,
        Givsly shall reasonably cooperate with Client at Client’s expense, in relation to any audit
        of Givsly reasonably necessary to enable Client to comply with its obligations under Data
        Protection Laws (“Audit”), and shall seek the equivalent cooperation from relevant
        Subprocessors. Any Audit shall be: (i) subject to a mutually agreed upon scope; (ii)
        conducted by an independent third party who has signed a nondisclosure agreement with Givsly
        or the Subprocessor, as the case may be; and (iii) subject to the confidentiality
        obligations set forth in the Agreement. Client shall use reasonable endeavors to minimize
        any disruption caused to the Givsly’s (or, Subprocessor’s, as the case may be) business
        activities as a result of an Audit. Audits shall take place no more than once in any
        calendar year except as otherwise required of Givsly under applicable Data Protection Laws.
        In addition, if required of Givsly under applicable Data Protection Laws, Givsly shall allow
        Client to take reasonable and appropriate steps to (a) ensure that Givsly’s Use of Client
        Personal Information is consistent with Client’s obligations under applicable Data
        Protection Laws, and (b) stop and remediate unauthorized use of Client Personal Information.
        Any information disclosed in connection with an Audit shall be the Confidential Information
        of Givsly (and/or Subprocessor, as the case may be).
      </p>
      <p>
        <b>Data Protection Assessments</b>. Upon Client’s request and to the extent required of
        Givsly under applicable Data Protection Laws, Givsly shall provide Client, at Client’s
        reasonable expense with the reasonably necessary information needed for Client to carry out
        a Data Protection Assessment related to Client’s use of the Services, to the extent that
        Client does not otherwise have access to the relevant information and that such information
        is reasonably available to Givsly.
      </p>
      <AnchorTitle id="dpa-return-and-deletion-of-data">RETURN AND DELETION OF DATA</AnchorTitle>
      <p>
        Givsly shall, on the written request of Client, return all Client Personal Information to
        Client and/or at Client’s request delete the same from its systems, except as otherwise
        permitted by applicable Data Protection Laws.
      </p>

      <AnchorTitle id="dpa-change-in-data-protection-laws">
        CHANGE IN DATA PROTECTION LAWS
      </AnchorTitle>
      <p>
        In the event of any change to or new Data Protection Law(s), the parties shall mutually
        agree upon any reasonably necessary amendments or revisions to this DPA.
      </p>
      <AnchorTitle id="annex-1-to-statement-of-work-exhiit-2-givsly-dpa">
        ANNEX I to Statement of Work Exhibit 2 – Givsly DPA{' '}
      </AnchorTitle>
      <p>
        <u>Details of Processing Activities</u>
      </p>
      <h3>1. LIST OF PARTIES</h3>
      <p>
        <b>Processor/Importer</b>
      </p>
      <table>
        <tbody>
          <tr>
            <td style={tableHeaderColStyle}>Name</td>
            <td>Givsly PBC</td>
          </tr>
          <tr>
            <td style={tableHeaderColStyle}>Address</td>
            <td>353 Lexington Avenue, Suite 400, New York NY 10016</td>
          </tr>
          <tr>
            <td style={tableHeaderColStyle}>
              Contact person’s name, position and contact details:
            </td>
            <td>privacy@givsly.com</td>
          </tr>
          <tr>
            <td style={tableHeaderColStyle}>
              Activities relevant to the data transferred under these Clauses:
            </td>
            <td>To enable the Services under the Agreement</td>
          </tr>
          <tr>
            <td style={tableHeaderColStyle}>Signature and date: </td>
            <td>
              This Annex I shall automatically be deemed executed when the DPA is executed by the
              parties.
            </td>
          </tr>
          <tr>
            <td style={tableHeaderColStyle}>Role (controller/processor):</td>
            <td>Processor</td>
          </tr>
        </tbody>
      </table>
      <p>
        <b>Controller/Exporter</b>
      </p>
      <table>
        <tbody>
          <tr>
            <td style={tableHeaderColStyle}>Name:</td>
            <td>
              The Party to this Agreement utilizing the Givsly Services and outlined in the
              Statement of Work.
            </td>
          </tr>
          <tr>
            <td style={tableHeaderColStyle}>Address:</td>
            <td>Same as Statement of Work.</td>
          </tr>
          <tr>
            <td style={tableHeaderColStyle}>
              Contact person’s name, position and contact details:
            </td>
            <td>Same as Statement of Work.</td>
          </tr>
          <tr>
            <td style={tableHeaderColStyle}>
              Activities relevant to the data transferred under these Clauses:
            </td>
            <td>To enable the Services under the Agreement.</td>
          </tr>
          <tr>
            <td style={tableHeaderColStyle}>Signature and date:</td>
            <td>
              This Annex I shall automatically be deemed executed when the DPA is executed by the
              parties.
            </td>
          </tr>
          <tr>
            <td style={tableHeaderColStyle}>Role (controller/processor):</td>
            <td>Controller</td>
          </tr>
        </tbody>
      </table>
      <ul>
        <li data-marker="2.">
          <u>Subject Matter of Processing of Client Personal Information.</u>
        </li>
        <li data-marker="3.">
          The subject matter of Processing of Client Personal Information is the provision of the
          Givsly Responsible Advertising, Responsible Marketing Platform Services and Events Services to Client.
          <u>Duration of Processing of Client Personal Information.</u>
          <br />
          <br />
          The duration of Processing of Client Personal Information is as set forth in the
          Agreement.
        </li>
        <li data-marker="4.">
          <u>Nature of Processing of Client Personal Information.</u>
          <br />
          <br />
          Givsly will Process Client Personal Information solely within Givsly’s systems for the
          purposes of providing the Services to Client in accordance with Client’s instructions.
        </li>
        <li data-marker="5.">
          <u>Purpose of Processing of Client Personal Information.</u>
          <br />
          <br />
          Client Personal Information will be Processed solely for the purposes expressly set forth
          in the Agreement.
        </li>
        <li data-marker="6.">
          <u>
            Types of Client Personal Information Processed (including any “sensitive” Client
            Personal Information).{' '}
          </u>
          <br />
          <br />
          First and last name of Platform user, Donation Offer recipient or event participant.
          Business email address of Platform user, Donation Offer recipient or event participant.
          Business phone number of Platform user, Donation Offer recipient or event participant.
          Employment information (company, title, business address, zip, country, industry) of
          Platform user, Donation Offer recipient or event participant. Responsible Advertising Pixel Data
        </li>
        <li data-marker="7.">
          <u>Categories of Data Subjects included in the Processed Personal Information</u>
          <br />
          <br />
          Client’s employees who have access to Givsly Responsible Marketing and/or Givsly Responsible Advertising
          Platforms or who attend an event in conjunction with Givsly Services.
          <br />
          <br />
          Data Subjects (i.e. Client’s clients, prospective clients, industry partners, nonprofit
          supporters, event attendees) who receive a Donation Offer or advertising created and
          distributed by Client or who attend an event and who upon accepting the Donation Offer or
          upon registering to an event provide Personal Information as defined in Section 5.
        </li>
      </ul>
      <AnchorTitle id="annex-2">Annex II</AnchorTitle>
      <p>
        Technical and Organisational
        <br />
        Security Measures
      </p>
      <p>
        Description of the technical and organizational measures implemented by the processor(s) /
        data importer(s) (including any relevant certifications) to ensure an appropriate level of
        security, taking into account the nature, scope, context and purpose of the processing, and
        the risks for the rights and freedoms of natural persons.
      </p>
      <table>
        <thead>
          <tr>
            <td style={{ backgroundColor: '#333', color: '#fff' }}>Measure</td>
            <td style={{ backgroundColor: '#333', color: '#fff' }}>Description</td>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td style={tableHeaderColStyle}>
              Measures of pseudonymization and encryption of personal data
            </td>
            <td>
              Implements data security controls, including logical segregation of data, restricted
              (e.g. role-based) access and monitoring, and where applicable, utilization of
              commercially available and industry-standard encryption technologies. All data is
              stored on AWS/Heroku which employ industry standard security.
            </td>
          </tr>
          <tr>
            <td style={tableHeaderColStyle}>
              Measures for ensuring ongoing confidentiality, integrity, availability and resilience
              of processing systems and services
            </td>
            <td>
              Maintains operational procedures and controls to provide for configuration,
              monitoring, and maintenance of technology and information systems according to
              prescribed internal and adopted industry standards. Evaluates key third-party software
              and service vendors while onboarding and, where appropriate, conducts additional
              periodic security reviews.
            </td>
          </tr>
          <tr>
            <td style={tableHeaderColStyle}>
              Measures for ensuring the ability to restore the availability and access to personal
              data in a timely manner in the event of a physical or technical incident
            </td>
            <td>
              Maintains incident response plans designed to allow company to investigate, respond
              to, mitigate, and notify of events related to company technology and information
              assets. These incident response plans include severity levels, escalations, and
              internal and external communications procedures. Data is categorized and is backed up
              by hosting provider and restoration ability is ensured.
            </td>
          </tr>
          <tr>
            <td style={tableHeaderColStyle}>
              Processes for regularly testing, assessing and evaluating the effectiveness of
              technical and organisational measures in order to ensure the security of the
              processing
            </td>
            <td>
              Conducts periodic reviews to evaluate risk. We also utilise vulnerability assessments,
              patch management and threat protection technologies.
            </td>
          </tr>
          <tr>
            <td style={tableHeaderColStyle}>Measures for user identification and authorisation</td>
            <td>
              Utilizes logical access controls to manage electronic access to data and system
              functionality based on authority levels and job functions.
            </td>
          </tr>
          <tr>
            <td style={tableHeaderColStyle}>
              Measures for the protection of data during transmission
            </td>
            <td>
              Utilizes cryptographic protocols such as TLS to protect information in transit over
              public and internal networks, where possible.  Utilizes firewalls, load balancers, and
              third-party DDoS protection at the network edge to filter and/or mitigate for threats.
            </td>
          </tr>
          <tr>
            <td style={tableHeaderColStyle}>Measures for the protection of data during storage</td>
            <td>Utilizes AES-256, block-level storage encryption for databases at rest.</td>
          </tr>
          <tr>
            <td style={tableHeaderColStyle}>
              Measures for ensuring physical security of locations at which personal data are
              processed
            </td>
            <td style={tableHeaderColStyle}>
              Company hosting provider enforces physical and environmental security of data centers,
              server room facilities, and other areas containing client confidential information
              designed to: (i) protect information assets from unauthorized physical access, (ii)
              manage, monitor and log movement of persons into and out of company facilities, and
              (iii) guard against environmental hazards such as heat, fire and water damage
            </td>
          </tr>
          <tr>
            <td style={tableHeaderColStyle}>Measures for ensuring events logging</td>
            <td>Implement logging systems to capture security, system errors and API activity.</td>
          </tr>
          <tr>
            <td style={tableHeaderColStyle}>
              Measures for ensuring system configuration, including default configuration
            </td>
            <td>
              Consistent and version controlled configuration using CDK, while our CI/CD also
              ensures that these configurations are deployed throughout our infrastructure.
            </td>
          </tr>
          <tr>
            <td style={tableHeaderColStyle}>
              Measures for internal IT and IT security governance and management
            </td>
            <td>Appointed Security Information Officer and Data Protection Officer.</td>
          </tr>
          <tr>
            <td style={tableHeaderColStyle}>
              Measures for certification/assurance of processes and products
            </td>
            <td>
              Maintains operational procedures and controls to provide for configuration,
              monitoring, and maintenance of technology and information systems according to
              prescribed internal standards.
            </td>
          </tr>
          <tr>
            <td style={tableHeaderColStyle}>Measures for ensuring data minimisation</td>
            <td>Only process the minimum data necessary to provide our Services.</td>
          </tr>
          <tr>
            <td style={tableHeaderColStyle}>Measures for ensuring limited data retention</td>
            <td>Follows a company data retention and purging policy.</td>
          </tr>
          <tr>
            <td style={tableHeaderColStyle}>Measures for ensuring accountability</td>
            <td>Data Protection Officer and annual review of policies and procedures. </td>
          </tr>
          <tr>
            <td style={tableHeaderColStyle}>
              Measures for allowing data portability and ensuring erasure
            </td>
            <td>
              Process to process data requests and removal, meeting expected timeline standards as
              set forth by regulation.
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

TermsOfService.defaultProps = {
  rootClassName: null,
  className: null,
};

const { string } = PropTypes;

TermsOfService.propTypes = {
  rootClassName: string,
  className: string,
};

export default TermsOfService;
