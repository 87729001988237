import React from 'react';
import { bool, func, shape, string } from 'prop-types';
import { FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';

import css from './SectionHowItWorks.css';
import Button from '../Button/Button';
import { createResourceLocatorString } from '../../util/routes';
import routeConfiguration from '../../routeConfiguration';

class SectionHowItWorks extends React.Component {
  constructor(props) {
    super(props);
    this.redirectToSignUp = this.redirectToSignUp.bind(this);
  }

  redirectToSignUp = () => {
    const to = createResourceLocatorString('SignupPage', routeConfiguration(), {}, {});
    this.props.history.push(to);
  };

  render() {
    const { className, isAuthenticated, rootClassName } = this.props;

    const classes = classNames(rootClassName || css.root, className);
    return (
      <div className={classes}>
        <div className={css.title}>
          <FormattedMessage id="SectionHowItWorks.titleLineOne" />
          <br />
          <FormattedMessage id="SectionHowItWorks.titleLineTwo" />
        </div>

        <div className={css.steps}>
          <div className={css.step}>
            <div className={css.stepNumber}>1</div>
            <h2 className={css.stepTitle}>
              <FormattedMessage id="SectionHowItWorks.part1Title" />
            </h2>
            <p className={css.stepText}>
              <FormattedMessage id="SectionHowItWorks.part1Text" />
            </p>
          </div>

          <div className={css.step}>
            <div className={css.stepNumber}>2</div>
            <h2 className={css.stepTitle}>
              <FormattedMessage id="SectionHowItWorks.part2Title" />
            </h2>
            <p className={css.stepText}>
              <FormattedMessage id="SectionHowItWorks.part2Text" />
            </p>
            {!isAuthenticated ? (
              <Button className={css.buttonSignUp} onClick={this.redirectToSignUp}>
                <FormattedMessage id="SectionHowItWorks.signUp" />
              </Button>
            ) : null}
          </div>

          <div className={css.step}>
            <div className={css.stepNumber}>3</div>
            <h2 className={css.stepTitle}>
              <FormattedMessage id="SectionHowItWorks.part3Title" />
            </h2>
            <p className={css.stepText}>
              <FormattedMessage id="SectionHowItWorks.part3Text" />
            </p>
            {!isAuthenticated ? (
              <Button className={css.buttonSignUpMobile} onClick={this.redirectToSignUp}>
                <FormattedMessage id="SectionHowItWorks.signUp" />
              </Button>
            ) : null}
          </div>
        </div>
      </div>
    );
  }
}

SectionHowItWorks.propTypes = {
  className: string,
  history: shape({
    push: func.isRequired,
  }).isRequired,
  isAuthenticated: bool.isRequired,
  rootClassName: string,
};

SectionHowItWorks.defaultProps = {
  className: null,
  isAuthenticated: false,
  rootClassName: null,
};

export default SectionHowItWorks;
