import Tippy from '@tippy.js/react';
import React from 'react';
import arrayMutators from 'final-form-arrays';
import { string, func, bool } from 'prop-types';
import { withMessages } from '../../util/localization';
import { Form as FinalForm } from 'react-final-form';
import classNames from 'classnames';
import { Form, FieldCheckbox } from '../../components';
import { propTypes } from '../../util/types';
import css from './ExposeEmailForm.css';

const ExposeEmailForm = (props) => {
  const { className, currentUser, getMessage, isChecked, onToggle } = props;
  return (
    <FinalForm
      initialValues={{
        exposeEmail: [isChecked ? currentUser.attributes.email : false],
      }}
      onSubmit={() => {}}
      mutators={{
        ...arrayMutators,
      }}
      render={() => {
        return (
          <Form className={classNames(css.root, className)} onSubmit={() => {}}>
            <label className={css.exposeEmail} htmlFor={'exposeEmail'}>
              <FieldCheckbox
                className={classNames(css.exposeEmailCheckbox)}
                id={'exposeEmail'}
                name={'exposeEmail'}
                onChange={(values) => onToggle(values)}
                type={'checkbox'}
                value={currentUser.attributes.email}
              />
              <span className={css.exposeEmailLabel}>
                {getMessage('exposeEmail.label')}{' '}
                <Tippy content={getMessage('exposeEmail.toolTip')}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="12"
                    height="12"
                    viewBox="0 0 12 12"
                  >
                    <path
                      fill="#FAC51D"
                      d="M6 0a6 6 0 1 1 0 12A6 6 0 0 1 6 0zm.5 5.5h-1A.5.5 0 0 0 5 6v3.5a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5V6a.5.5 0 0 0-.5-.5zM6 2a1.25 1.25 0 1 0 0 2.5A1.25 1.25 0 0 0 6 2z"
                    />
                  </svg>
                </Tippy>
              </span>
            </label>
          </Form>
        );
      }}
    />
  );
};

ExposeEmailForm.defaultProps = {
  className: null,
  isChecked: true,
  onToggle: () => {},
};

ExposeEmailForm.propTypes = {
  className: string,
  currentUser: propTypes.currentUser,
  isChecked: bool,
  onToggle: func,
};

ExposeEmailForm.displayName = 'ExposeEmailForm';
export default withMessages(ExposeEmailForm, 'ExposeEmailForm');
