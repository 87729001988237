import isArray from 'lodash/isArray';
import reduce from 'lodash/reduce';
import merge from 'lodash/merge';
import { sanitizeEntity } from './sanitize';
import { ONBOARDING_PROGRESS } from '../components/OnboardingWizard/constants';

/**
 * Combine the given relationships objects
 *
 * See: http://jsonapi.org/format/#document-resource-object-relationships
 */
export const combinedRelationships = (oldRels, newRels) => {
  if (!oldRels && !newRels) {
    // Special case to avoid adding an empty relationships object when
    // none of the resource objects had any relationships.
    return null;
  }
  return { ...oldRels, ...newRels };
};

/**
 * Combine the given resource objects
 *
 * See: http://jsonapi.org/format/#document-resource-objects
 */
export const combinedResourceObjects = (oldRes, newRes) => {
  const { id, type } = oldRes;
  if (newRes.id.uuid !== id.uuid || newRes.type !== type) {
    throw new Error('Cannot merge resource objects with different ids or types');
  }
  const attributes = newRes.attributes || oldRes.attributes;
  const attrs = attributes ? { attributes: { ...attributes } } : null;
  const relationships = combinedRelationships(oldRes.relationships, newRes.relationships);
  const rels = relationships ? { relationships } : null;
  return { id, type, ...attrs, ...rels };
};

/**
 * Combine the resource objects form the given api response to the
 * existing entities.
 */
export const updatedEntities = (oldEntities, apiResponse) => {
  const { data, included = [] } = apiResponse;
  const objects = (Array.isArray(data) ? data : [data]).concat(included);

  const newEntities = objects.reduce((entities, curr) => {
    const { id, type } = curr;

    // Some entities (e.g. listing and user) might include extended data,
    // you should check if src/util/sanitize.js needs to be updated.
    const current = sanitizeEntity(curr);

    entities[type] = entities[type] || {};
    const entity = entities[type][id.uuid];
    entities[type][id.uuid] = entity ? combinedResourceObjects({ ...entity }, current) : current;

    return entities;
  }, oldEntities);

  return newEntities;
};

/**
 * Denormalise the entities with the resources from the entities object
 *
 * This function calculates the dernormalised tree structure from the
 * normalised entities object with all the relationships joined in.
 *
 * @param {Object} entities entities object in the SDK Redux store
 * @param {Array<{ id, type }} resources array of objects
 * with id and type
 * @param {Boolean} throwIfNotFound wheather to skip a resource that
 * is not found (false), or to throw an Error (true)
 *
 * @return {Array} the given resource objects denormalised that were
 * found in the entities
 */
export const denormalisedEntities = (entities, resources, throwIfNotFound = true) => {
  const denormalised = resources.map((res) => {
    const { id, type } = res;
    const entityFound = entities[type] && id && entities[type][id.uuid];
    if (!entityFound) {
      if (throwIfNotFound) {
        throw new Error(`Entity with type "${type}" and id "${id ? id.uuid : id}" not found`);
      }
      return null;
    }
    const entity = entities[type][id.uuid];
    const { relationships, ...entityData } = entity;

    if (relationships) {
      // Recursively join in all the relationship entities
      return reduce(
        relationships,
        (ent, relRef, relName) => {
          // A relationship reference can be either a single object or
          // an array of objects. We want to keep that form in the final
          // result.
          const hasMultipleRefs = Array.isArray(relRef.data);
          const multipleRefsEmpty = hasMultipleRefs && relRef.data.length === 0;
          if (!relRef.data || multipleRefsEmpty) {
            ent[relName] = hasMultipleRefs ? [] : null;
          } else {
            const refs = hasMultipleRefs ? relRef.data : [relRef.data];

            // If a relationship is not found, an Error should be thrown
            const rels = denormalisedEntities(entities, refs, true);

            ent[relName] = hasMultipleRefs ? rels : rels[0];
          }
          return ent;
        },
        entityData
      );
    }
    return entityData;
  });
  return denormalised.filter((e) => !!e);
};

/**
 * Denormalise the data from the given SDK response
 *
 * @param {Object} sdkResponse response object from an SDK call
 *
 * @return {Array} entities in the response with relationships
 * denormalised from the included data
 */
export const denormalisedResponseEntities = (sdkResponse) => {
  const apiResponse = sdkResponse.data;
  const data = apiResponse.data;
  const resources = Array.isArray(data) ? data : [data];

  if (!data || resources.length === 0) {
    return [];
  }

  const entities = updatedEntities({}, apiResponse);
  return denormalisedEntities(entities, resources);
};

/**
 * Create shell objects to ensure that attributes etc. exists.
 *
 * @param {Object} transaction entity object, which is to be ensured against null values
 */
export const ensureTransaction = (transaction, booking = null, listing = null, provider = null) => {
  const empty = {
    id: null,
    type: 'transaction',
    attributes: {
      lineItems: [],
      protectedData: {},
      transitions: [],
    },
    booking,
    listing,
    provider,
  };
  return merge(empty, transaction);
};

export const ensureNotification = (notification) => {
  const empty = {
    id: null,
    type: 'transaction',
    attributes: {
      lineItems: [],
      protectedData: {},
      transitions: [],
    },
    customer: ensureUser(notification ? notification.customer : {}),
    provider: ensureUser(notification ? notification.provider : {}),
    listing: ensureListing(notification ? notification.listing : {}),
  };
  return merge(empty, notification);
};

/**
 * Create shell objects to ensure that attributes etc. exists.
 *
 * @param {Object} booking entity object, which is to be ensured against null values
 */
export const ensureBooking = (booking) => {
  const empty = { id: null, type: 'booking', attributes: {} };
  return { ...empty, ...booking };
};

/**
 * Create shell objects to ensure that attributes etc. exists.
 *
 * @param {Object} listing entity object, which is to be ensured against null values
 */
export const ensureListing = (listing) => {
  const empty = {
    id: null,
    type: 'listing',
    attributes: {
      publicData: {
        geolocation: {},
        eventRoles: [],
        following: [],
        interests: [],
        isNPOListing: false,
        isVolunteerListing: false,
        reservableSlots: [],
        supportedNPOs: [],
        methodPriceChoices: {
          chat: 'choice_100',
          inPerson: 'choice_100',
        },
      },
      metadata: {
        stats: {
          nonprofitsImpacted: [],
        },
      },
    },
    images: [],
  };
  return merge(empty, listing);
};

/**
 * Create shell objects to ensure that attributes etc. exists.
 *
 * @param {Object} listing entity object, which is to be ensured against null values
 */
export const ensureOwnListing = (listing) => {
  const empty = {
    id: null,
    type: 'ownListing',
    attributes: {
      publicData: {
        geolocation: {},
        methodPriceChoices: {
          chat: 'choice_100',
          inPerson: 'choice_100',
        },
        reservableSlots: [],
        supportedNPOs: [],
      },
      privateData: {
        following: [],
      },
    },
    images: [],
  };

  return merge(empty, listing);
};

/**
 * Create shell objects for owned NPO listings
 *
 * @param listing
 * @returns {{attributes: {privateData, publicData}, id, type}}
 */
export const ensureOwnNonprofitListing = (listing) => {
  const empty = {
    id: null,
    type: 'ownListing',
    attributes: {
      publicData: {
        isNPOListing: true,
        address: {},
      },
      privateData: {},
    },
  };
  const result = {
    ...empty,
    ...listing,
  };
  if (listing && listing.attributes) {
    result.attributes = { ...empty.attributes, ...listing.attributes };
    result.attributes.publicData = {
      ...empty.attributes.publicData,
      ...listing.attributes.publicData,
    };
    result.attributes.privateData = {
      ...empty.attributes.privateData,
      ...listing.attributes.privateData,
    };
  }
  return result;
};

/**
 * Create shell objects to ensure that attributes etc. exists.
 *
 * @param {Object} user entity object, which is to be ensured against null values
 */
export const ensureUser = (user) => {
  const empty = {
    id: null,
    type: 'user',
    attributes: {
      profile: {
        publicData: {
          geolocation: {
            city: '',
            state: {
              name: '',
              shortCode: '',
            },
            country: {
              name: '',
              shortCode: '',
            },
          },
          listingId: null,
          methodPriceChoices: {
            chat: 'choice_100',
            inPerson: 'choice_100',
          },
          supportedNPOs: [],
          boardMemberIds: [],
        },
      },
    },
  };
  return merge(empty, user);
};

/**
 * Create shell objects to ensure that attributes etc. exists.
 *
 * @param {Object} user current user entity object, which is to be ensured against null values
 */
export const ensureCurrentUser = (user) => {
  const empty = {
    id: null,
    type: 'currentUser',
    attributes: {
      profile: {
        publicData: {
          following: [],
          geolocation: {
            city: '',
            state: {
              name: '',
              shortCode: '',
            },
            country: {
              name: '',
              shortCode: '',
            },
          },
          listingId: null,
          interests: [],
          isVolunteer: false,
          onboardingCompleted: false,
          volunteerListingId: null,
          methodPriceChoices: {
            chat: 'choice_100',
            inPerson: 'choice_100',
          },
          supportedNPOs: [],
          boardMemberIds: [],
        },
        privateData: {
          creditCodes: [],
        },
      },
    },
    profileImage: {},
  };
  return merge(empty, user);
};

/**
 * Create shell objects to ensure that attributes etc. exists.
 *
 * @param {Object} timeSlot time slot entity object, which is to be ensured against null values
 */
export const ensureTimeSlot = (timeSlot) => {
  const empty = { id: null, type: 'timeSlot', attributes: {} };
  return { ...empty, ...timeSlot };
};

/**
 * Create shell objects to ensure that attributes etc. exists.
 *
 * @param {Object} availabilityPlan entity object, which is to be ensured against null values
 */
export const ensureDayAvailabilityPlan = (availabilityPlan) => {
  const empty = { type: 'availability-plan/day', entries: [] };
  return { ...empty, ...availabilityPlan };
};

/**
 * Create shell objects to ensure that attributes etc. exists.
 *
 * @param {Object} availabilityException availability exception entity object, which is to be ensured against null values
 */
export const ensureAvailabilityException = (availabilityException) => {
  const empty = { id: null, type: 'availabilityException', attributes: {} };
  return { ...empty, ...availabilityException };
};

/**
 * Create shell objects to ensure that attributes etc. exists.
 *
 * @param {Object} stripeCustomer entity from API, which is to be ensured against null values
 */
export const ensureStripeCustomer = (stripeCustomer) => {
  const empty = {
    id: null,
    type: 'stripeCustomer',
    attributes: {},
    defaultPaymentMethod: {
      attributes: {
        card: {},
        stripePaymentMethodId: null,
      },
    },
  };
  return { ...empty, ...stripeCustomer };
};

/**
 * Create shell objects to ensure that attributes etc. exists.
 *
 * @param {Object} stripePaymentMethod entity from API, which is to be ensured against null values
 */
export const ensurePaymentMethodCard = (stripePaymentMethod) => {
  const empty = {
    id: null,
    type: 'stripePaymentMethod',
    attributes: { type: 'stripe-payment-method/card', card: {} },
  };
  return { ...empty, ...stripePaymentMethod };
};

export const ensureAvailabilitySlot = (availabilitySlot) => {
  const empty = {
    start: null,
    end: null,
    duration: null,
    methods: [],
    timezone: null,
  };
  return { ...empty, ...availabilitySlot };
};

/**
 * Get the display name of the given user as string. This function handles
 * missing data (e.g. when the user object is still being downloaded),
 * fully loaded users, as well as banned users.
 *
 * For banned or deleted users, a translated name should be provided.
 *
 * @param {propTypes.user} user
 * @param {String} defaultUserDisplayName
 * @param {Boolean} showLastNameInitial
 *
 * @return {String} display name that can be rendered in the UI
 */
export const userDisplayNameAsString = (user, defaultUserDisplayName, showLastNameInitial) => {
  const hasAttributes = user && user.attributes;
  const hasProfile = hasAttributes && user.attributes.profile;
  const hasDisplayName = hasProfile && user.attributes.profile.displayName;

  if (hasDisplayName) {
    if (showLastNameInitial === true || showLastNameInitial === undefined) {
      return user.attributes.profile.displayName;
    } else {
      return user.attributes.profile.displayName.split(' ')[0];
    }
  } else {
    return defaultUserDisplayName || '';
  }
};

/**
 * DEPRECATED: Use userDisplayNameAsString function or UserDisplayName component instead
 *
 * @param {propTypes.user} user
 * @param {String} bannedUserDisplayName
 *
 * @return {String} display name that can be rendered in the UI
 */
export const userDisplayName = (user, bannedUserDisplayName) => {
  console.warn(
    `Function userDisplayName is deprecated!
User function userDisplayNameAsString or component UserDisplayName instead.`
  );

  return userDisplayNameAsString(user, bannedUserDisplayName);
};

/**
 * Get the abbreviated name of the given user. This function handles
 * missing data (e.g. when the user object is still being downloaded),
 * fully loaded users, as well as banned users.
 *
 * For banned  or deleted users, a default abbreviated name should be provided.
 *
 * @param {propTypes.user} user
 * @param {String} defaultUserAbbreviatedName
 *
 * @return {String} abbreviated name that can be rendered in the UI
 * (e.g. in Avatar initials)
 */
export const userAbbreviatedName = (user, defaultUserAbbreviatedName) => {
  const hasAttributes = user && user.attributes;
  const hasProfile = hasAttributes && user.attributes.profile;
  const hasDisplayName = hasProfile && user.attributes.profile.abbreviatedName;

  if (hasDisplayName) {
    return user.attributes.profile.abbreviatedName;
  } else {
    return defaultUserAbbreviatedName || '';
  }
};

/**
 * A customizer function to be used with the
 * mergeWith function from lodash.
 *
 * Works like merge in every way exept that on case of
 * an array the old value is completely overridden with
 * the new value.
 *
 * @param {Object} objValue Value of current field, denoted by key
 * @param {Object} srcValue New value
 * @param {String} key Key of the field currently being merged
 * @param {Object} object Target object that is receiving values from source
 * @param {Object} source Source object that is merged into object param
 * @param {Object} stack Tracks merged values
 *
 * @return {Object} New value for objValue if the original is an array,
 * otherwise undefined is returned, which results in mergeWith using the
 * standard merging function
 */
export const overrideArrays = (objValue, srcValue, key, object, source, stack) => {
  if (isArray(objValue)) {
    return srcValue;
  }
};

/**
 * Humanizes a line item code. Strips the "line-item/" namespace
 * definition from the beginnign, replaces dashes with spaces and
 * capitalizes the first character.
 *
 * @param {string} code a line item code
 *
 * @return {string} returns the line item code humanized
 */
export const humanizeLineItemCode = (code) => {
  if (!/^line-item\/.+/.test(code)) {
    throw new Error(`Invalid line item code: ${code}`);
  }
  const lowercase = code.replace(/^line-item\//, '').replace(/-/g, ' ');

  return lowercase.charAt(0).toUpperCase() + lowercase.slice(1);
};

/**
 * Helper method for the NPO selector, to keep track of the active selection during paging
 *
 * @param user
 * @returns {any}
 */
export const getSelectedNonprofits = (user) => {
  const referrer = document.createElement('a');
  referrer.href = document.referrer;
  let supportedNonprofits;

  if (
    referrer.host === document.location.host &&
    referrer.pathname === document.location.pathname
  ) {
    supportedNonprofits =
      JSON.parse(sessionStorage.getItem(SESSION_KEY_SUPPORTED_NONPROFITS)) ||
      user.attributes.profile.publicData.supportedNPOs;
  } else {
    sessionStorage.removeItem(SESSION_KEY_SUPPORTED_NONPROFITS);
    supportedNonprofits = user.attributes.profile.publicData.supportedNPOs;
  }
  return supportedNonprofits;
};

export const transformUpdateValuesToListingValues = (profileData) => {
  const listingData = { ...profileData };
  listingData.publicData = { ...profileData.publicData }; // Detach from profileData

  if (profileData.firstName || profileData.lastName) {
    listingData.title = `${profileData.firstName} ${profileData.lastName}`;
    delete listingData.firstName;
    delete listingData.lastName;
  }

  if (profileData.profileImageId) {
    delete listingData.profileImageId;
  }

  if (profileData.bio || profileData.bio === '') {
    listingData.description = profileData.bio;
    delete listingData.bio;
  }

  if (profileData.publicData && profileData.publicData.timezone) {
    listingData.availabilityPlan = {
      type: 'availability-plan/time',
      timezone: profileData.publicData.timezone,
      entries: [],
    };
  }

  if (profileData.publicData && profileData.publicData.profileType) {
    delete listingData.publicData.profileType;
  }

  return listingData;
};

export const transformUpdateValuesToUserValues = (updateValues) => {
  const profileData = { ...updateValues };

  if (profileData.geolocation) {
    delete profileData.geolocation;
  }

  return profileData;
};

export const ensureLandscapeImage = (image) => {
  const empty = {
    attributes: {
      variants: {
        'landscape-crop': {
          url: null,
        },
        'landscape-crop2x': {
          url: null,
        },
        'landscape-crop4x': {
          url: null,
        },
        'landscape-crop6x': {
          url: null,
        },
      },
    },
  };
  return merge(empty, image);
};

export const ensureLocation = (location) => {
  const empty = {
    key: null,
    label: null,
    abbreviatedLabel: null,
  };
  return merge(empty, typeof location === 'object' ? location : {});
};

export const isUserWithCompletedProfile = (ensuredUser) => {
  return (
    ensuredUser.attributes.profile.publicData.onboardingCompleted ||
    ensuredUser.attributes.profile.privateData.onboardingCompleted
  );
};

export const getOnboardingProgress = (ensuredUser) => {
  return (
    ensuredUser.attributes.profile.publicData.onboardingProgress ||
    ensuredUser.attributes.profile.privateData.onboardingProgress ||
    0
  );
};

export const getNextOnboardingTab = (ensuredUser) => {
  const currentProgress =
    ensuredUser.attributes.profile.publicData.onboardingProgress ||
    ensuredUser.attributes.profile.privateData.onboardingProgress ||
    0;
  const next = currentProgress >= 60 ? 60 : currentProgress + 10;
  return Object.keys(ONBOARDING_PROGRESS).find((key) => ONBOARDING_PROGRESS[key] === next);
};

export const isPitcherUser = (ensuredUser, strict = true) => {
  const { isPitcher, isVolunteer, listingType } = ensuredUser.attributes.profile.publicData;
  return (
    listingType === PROFILE_TYPE_PITCHER ||
    (isPitcher && !isVolunteer) ||
    (isPitcher && isVolunteer && !strict) ||
    (isPitcher && listingType === PROFILE_TYPE_VOLUNTEER && !strict)
  );
};

export const isNonprofitUser = (ensuredUser) => {
  const { isNPO, listingType } = ensuredUser.attributes.profile.publicData;
  return listingType === PROFILE_TYPE_NONPROFIT || isNPO;
};

export const isVolunteerUser = (ensuredUser) => {
  const { isVolunteer, listingType } = ensuredUser.attributes.profile.publicData;
  return listingType === PROFILE_TYPE_NONPROFIT || isVolunteer;
};

export const isBookableListing = (ensuredListing) => {
  const { reservableSlots = [], supportedNPOs = [] } = ensuredListing.attributes.publicData;
  return reservableSlots.length > 0 && supportedNPOs.length > 0 && hasPricingSet(ensuredListing);
};

export const hasPricingSet = (ensuredListing) => {
  const { methodPriceChoices = {} } = ensuredListing.attributes.publicData;

  if (Object.keys(methodPriceChoices).length > 0) {
    return (
      Object.keys(methodPriceChoices).filter((key) => methodPriceChoices[key] !== null).length > 0
    );
  }

  return false;
};

export const SESSION_KEY_SUPPORTED_NONPROFITS = 'SupportedNonprofitsPanel.supportedNPOs';
export const PROFILE_TYPE_PITCHER = 'pitcher';
export const PROFILE_TYPE_VOLUNTEER = 'volunteer';
export const PROFILE_TYPE_NONPROFIT = 'nonprofit';
export const PROFILE_TYPE_NEW = 'new';
