import React, { useState } from 'react';
import { useMessages } from '../../util/localization';
import css from './OutreachOfferDetails.css';
import {
  ICON_COPY,
  ICON_VIEW,
  STYLE_LIGHT,
  ICON_ARROW_LEFT,
  STYLE_LINK,
  ICON_PENCIL,
} from '../LiteButton/constants';
import LiteButton from '../LiteButton/LiteButton';
import routeConfiguration from '../../routeConfiguration';
import { createResourceLocatorString } from '../../util/routes';
// @ts-ignore
import moment from 'moment-timezone';
import { getDonationOfferMiniAppUrl } from '../../util/getDonationOfferMiniAppUrl';

type NPODetailsProps = {
  npo: string;
  acceptedOffers: number;
  acceptedOffersAmount: string;
  confirmedOffers: number;
  confirmedOffersAmount: string;
};
const NPODetails = (props: NPODetailsProps) => {
  const acceptedOffersAmount = props.acceptedOffersAmount;
  const confirmedOffersAmount = props.confirmedOffersAmount;

  const acceptedOffersString = props.acceptedOffers === 1 ? 'offer' : 'offers';
  const confirmedOffersString = props.confirmedOffers === 1 ? 'donation' : 'donations';

  return (
    <div>
      <h4 className={css.semiBold}>{props.npo}</h4>
      <p className={css.details}>
        {props.acceptedOffers} accepted {acceptedOffersString} (${acceptedOffersAmount}) •{' '}
        {props.confirmedOffers} processed {confirmedOffersString} (${confirmedOffersAmount})
      </p>
    </div>
  );
};

type OutreachOfferDetailsProps = {
  details: any;
  nonprofits: any;
  outreachRequests: any;
  currentUser: any;
  timezone: any;
  onBack: (tab: string) => void;
  editDonationOffer: (id: string) => void;
};

const OutreachOfferDetails = (props: OutreachOfferDetailsProps) => {
  const [copyLinkText, setCopyLinkText] = useState('Copy offer link');

  const copyToClipboard = (text: string) => {
    navigator.clipboard.writeText(text).then(
      () => {
        console.log('Async: Copying to clipboard was successful!');
      },
      (err) => {
        console.error('Async: Could not copy text: ', err);
      }
    );

    setCopyLinkText('Link copied!');
    setTimeout(() => {
      setCopyLinkText('Copy offer link');
    }, 2000);
  };

  const amountToDisplayAmount = (amount: number) => {
    return (amount / 100).toFixed(2);
  };

  const nonprofitIdToName = (id: string) => {
    return props.nonprofits[id];
  };

  const {
    name,
    donationAmount, // current donation amount
    donationCap, // current donation cap
    nonProfitListingIds,
    id,
    state,
    createdAt,
    updatedAt,
    sender,
    archived,
  } = props.details;

  const timezone = props.timezone;
  const {
    firstName,
    lastName,
    publicData: { companyName, jobTitle },
  } = props.currentUser.attributes.profile;

  const fullGreeting =
    sender && sender === 'nameTitleCompany'
      ? `${firstName} ${lastName} (${jobTitle}, ${companyName})`
      : companyName;

  const operatorPercentage =
    props.currentUser.attributes &&
    props.currentUser.attributes.profile.metadata &&
    props.currentUser.attributes.profile.metadata.operatorPercentage >= 0
      ? props.currentUser.attributes.profile.metadata.operatorPercentage
      : 15;

  const countConfirmed = props.outreachRequests.filter((req: any) => {
    return req.outreachOfferId === id && req.state === 'CONFIRMED';
  });
  const countPending = props.outreachRequests.filter((req: any) => {
    return req.outreachOfferId === id && req.state === 'PENDING';
  });

  // need to retrieve all old processed requests since the offer NPO's might have changed
  const nonProfitListingIds2: string[] = nonProfitListingIds.map((id: string) => id);
  props.outreachRequests
    .filter((req: any) => {
      return req.outreachOfferId === id && req.state !== 'DISCARDED';
    })
    .forEach((request: any) => {
      if (nonProfitListingIds2.indexOf(request.selectedNonProfitId) === -1) {
        nonProfitListingIds2.push(request.selectedNonProfitId);
      }
    });

  const confirmed = {
    amount: amountToDisplayAmount(
      countConfirmed.reduce(function (prev: any, current: any) {
        return prev + current.donationAmount;
      }, 0)
    ),
    count: countConfirmed.length,
    total: amountToDisplayAmount(
      countConfirmed.reduce(function (prev: any, current: any) {
        return prev + current.processedAmount;
      }, 0)
    ),
  };

  const pending = {
    amount: amountToDisplayAmount(
      countPending.reduce(function (prev: any, current: any) {
        return prev + current.donationAmount;
      }, 0)
    ),
    count: countPending.length,
    total: amountToDisplayAmount(
      countPending.reduce(function (prev: any, current: any) {
        const givslyFee = (current.donationAmount * operatorPercentage) / 100;
        const serviceFee = (current.donationAmount + givslyFee + 30) * (1 / 0.971 - 1) + 30;
        const processedAmount = current.donationAmount + givslyFee + serviceFee;

        return prev + processedAmount;
      }, 0)
    ),
  };
  const offerType = props.details.offerType || 0;
  const offerSlug = props.details.customSlug || props.details.id;
  const tenantId = props.details.tenantID;
  const miniAppUrl = getDonationOfferMiniAppUrl(
    props.details.id,
    props.details.customSlug,
    props.details.tenantID
  );

  const link =
    offerType === 0
      ? String(process.env.REACT_APP_CANONICAL_ROOT_URL).replace(/\/$/, '') +
        createResourceLocatorString('OutreachLandingPage', routeConfiguration(), { id }, {})
      : miniAppUrl;

  const openPreviewInNewTab = () => {
    if (typeof window !== 'undefined') {
      if (offerType === 0) {
        window.open(link, '_blank');
      } else if (tenantId && offerSlug) {
        window.open(miniAppUrl, '_blank');
      }
    }
  };
  const DATE_TIME_FORMAT = 'MMM D, h:mm A';
  const created = moment(createdAt).tz(timezone);
  const updated = moment(updatedAt).tz(timezone);

  let capitalizedState = state[0].toUpperCase() + state.slice(1);
  let cssState = capitalizedState;
  capitalizedState = capitalizedState.replace('pR', 'p r');

  const getMessage = useMessages('OutreachOfferDetails');
  return name ? (
    <div>
      <LiteButton
        className={css.buttonBack}
        icon={ICON_ARROW_LEFT}
        style={STYLE_LINK}
        onClick={() => props.onBack(archived ? 'archive' : 'overview')}
      >
        Back
      </LiteButton>
      <h1 className={css.title}>{name}</h1>
      <span className={css.status}>
        <span className={css[`status${cssState}`]}>{capitalizedState}</span> &bull;&nbsp;
        {updated.format(DATE_TIME_FORMAT) !== created.format(DATE_TIME_FORMAT)
          ? `last updated on ${updated.format(DATE_TIME_FORMAT)} • created on ${created.format(
              DATE_TIME_FORMAT
            )}`
          : `${created.format(DATE_TIME_FORMAT)}`}
      </span>
      <h3 className={css.header}>{getMessage('donationOfferLink')}</h3>
      <p className={css.copy}>{getMessage('donationCopy')}</p>
      <h3 className={css.link}>{link}</h3>
      <div className={css.btnCont}>
        <LiteButton onClick={() => copyToClipboard(link)} icon={ICON_COPY}>
          {copyLinkText}
        </LiteButton>
        <LiteButton onClick={openPreviewInNewTab} style={STYLE_LIGHT} icon={ICON_VIEW}>
          {getMessage('previewLink')}
        </LiteButton>
        <div className={css['edit-container']}>
          <LiteButton
            disabled={offerType !== 0}
            onClick={() => props.editDonationOffer(id)}
            style={STYLE_LIGHT}
            icon={ICON_PENCIL}
          >
            Edit Donation Offer
          </LiteButton>
          {offerType !== 0 && (
            <span className={css['edit-text']}>New offer cannot be edited in legacy editor</span>
          )}
        </div>
      </div>
      <h3 className={css.header}>{getMessage('linkSender')}</h3>
      <h3 className={css.semiBold}>{fullGreeting}</h3>

      <h3 className={css.header}>{getMessage('nonProfits')}</h3>

      {nonProfitListingIds2.map((nonprofitId: string) => {
        const countConfirmed = props.outreachRequests
          .filter((req: any) => {
            return (
              nonprofitId === req.selectedNonProfitId &&
              req.outreachOfferId === id &&
              req.state === 'CONFIRMED'
            );
          })
          .reduce(function (prev: any, current: any) {
            return prev + current.donationAmount;
          }, 0);

        const countPending = props.outreachRequests
          .filter((req: any) => {
            return (
              nonprofitId === req.selectedNonProfitId &&
              req.outreachOfferId === id &&
              req.state === 'PENDING'
            );
          })
          .reduce(function (prev: any, current: any) {
            return prev + current.donationAmount;
          }, 0);

        const confirmed = {
          count: countConfirmed.length,
          amount: amountToDisplayAmount(countConfirmed),
        };

        const pending = {
          count: countPending.length,
          amount: amountToDisplayAmount(countPending),
        };

        return (
          <NPODetails
            key={`npo-details-${nonprofitId}`}
            npo={nonprofitIdToName(nonprofitId)}
            acceptedOffers={pending.count}
            acceptedOffersAmount={pending.amount}
            confirmedOffers={confirmed.count}
            confirmedOffersAmount={confirmed.amount}
          />
        );
      })}

      {/* <NPODetails npo={"Rethink Food NYC Inc."} acceptedOffers={2} acceptedOffersAmount={300} confirmedOffers={1} confirmedOffersAmount={150} /> */}

      <h3 className={css.header}>{getMessage('donationAmount')}</h3>
      <h3 className={css.semiBold}>${amountToDisplayAmount(donationAmount)}</h3>

      <h3 className={css.header}>Email settings</h3>
      <table className={css['settings-table']}>
        <tbody>
          <tr>
            <td>Receive email when this Donation Offer is accepted</td>
            <td>{props.details.emailOwner ? 'ON' : 'OFF'}</td>
          </tr>
          <tr>
            <td>Send email to acceptor when an accepted Donation Offer is processed</td>
            <td>{props.details.emailThankYou ? 'ON' : 'OFF'}</td>
          </tr>
        </tbody>
      </table>

      <h3 className={css.header}>{getMessage('totalDonationBudgetCap')}</h3>
      <h3 className={css.semiBold}>${amountToDisplayAmount(donationCap)}</h3>

      <h3 className={css.header}>{getMessage('confirmedOffers')}</h3>
      <h3 className={css.semiBold}>
        {pending.count} (${pending.amount} in unprocessed donations)
        <br />
        <span className={css.smallPrint}>
          <b>${pending.total}</b> unprocessed including fees
        </span>
      </h3>

      <h3 className={css.header}>{getMessage('processedOffers')}</h3>
      <h3 className={css.semiBold}>
        {confirmed.count}/{confirmed.count + pending.count} (${confirmed.amount} in processed
        donations)
        <br />
        <span className={css.smallPrint}>
          <b>${confirmed.total}</b> processed including fees
        </span>
        {state === 'capReached' && <p className={css.capWarning}>Total budget cap reached</p>}
      </h3>
    </div>
  ) : null;
};

export default OutreachOfferDetails;
