import React from 'react';
import { propTypes } from '../../util/types';
import { array, bool, func, number, object, shape, string } from 'prop-types';
import {
  ensureCurrentUser,
  transformUpdateValuesToListingValues,
  transformUpdateValuesToUserValues,
} from '../../util/data';
import { createResourceLocatorString } from '../../util/routes';
import routeConfiguration from '../../routeConfiguration';

import { PanelHeader, PersonalInfoPanel, SupportedNonprofitsPanel } from '../index';
import { FormattedMessage } from 'react-intl';

import {
  PERSONAL_INFO,
  SUPPORTED_NONPROFITS,
  PUBLISH,
  ONBOARDING_PAGE_NAME,
  ONBOARDING_PROGRESS,
} from './constants';

class OnboardingWizard extends React.Component {
  FINAL_TAB = SUPPORTED_NONPROFITS;

  NEXT_TAB = {
    [PERSONAL_INFO]: SUPPORTED_NONPROFITS,
    [SUPPORTED_NONPROFITS]: PUBLISH,
  };

  constructor(props) {
    super(props);
    this.state = {
      activeTab: PERSONAL_INFO,
    };
  }

  /**
   * Redirects the user to the given tab. Should be used to 'progress' the user along the
   * onboarding process.
   *
   * @param tab
   */
  redirectToTab(tab) {
    const to = createResourceLocatorString('OnboardingPage', routeConfiguration(), { tab }, {});
    this.props.history.push(to);
  }

  getProfileDataValues(action, updatedValues) {
    const user = ensureCurrentUser(this.props.currentUser);
    const { onboardingProgress } = user.attributes.profile.publicData;

    const profileData = { ...updatedValues };
    profileData.publicData = { ...updatedValues.publicData }; // Detach from updatedValues
    profileData.publicData.isNPO = false;

    // Check and update the onboarding progression
    const tabProgress = ONBOARDING_PROGRESS[action];
    if (tabProgress > (onboardingProgress || 0)) {
      profileData.publicData.onboardingProgress = tabProgress;
    }

    return transformUpdateValuesToUserValues(profileData);
  }

  getListingDataValues(updatedValues) {
    const listingData = transformUpdateValuesToListingValues(updatedValues);
    listingData.id = this.props.currentUserListing.id.uuid;
    listingData.publicData.isNPOListing = false;

    return listingData;
  }

  saveAndPublish(profileData, listingData) {
    profileData.publicData = {
      ...profileData.publicData,
      isPitcher: true,
      isVolunteer: true,
      onboardingCompleted: true,
    };
    listingData.publicData = {
      ...listingData.publicData,
      isPitcherListing: false,
      isVolunteerListing: true,
    };

    return Promise.all([
      this.props.onUpdateProfile(profileData),
      this.props.onUpdateListing(listingData),
      this.props.onPublishListing(listingData.id),
    ]);
  }

  save(profileData, listingData) {
    return Promise.all([
      this.props.onUpdateProfile(profileData),
      this.props.onUpdateListing(listingData),
    ]);
  }

  render() {
    const {
      history,
      currentUser,
      currentUserListing,
      nonprofitListings,
      nonprofitListingsError,
      nonprofitListingsInProgress,
      nonprofitPagination,
      onManageDisableScrolling,
      onOpenModal,
      onCloseModal,
      onImageUpload,
      params,
      profileImage,
      showAsModalMaxWidth,
      supportedNonprofits,
      updateListingError,
      updateListingInProgress,
      uploadProfileImageError,
      uploadProfileImageInProgress,
      updateProfileInProgress,
      updateProfileProfileError,
      trackEvent,
    } = this.props;

    const { tab: activeTab } = params;

    /**
     * Handles submission of tabbed forms. Generally works for all profile and listing tabs. Pushes
     * data (listing and user) back to the ShareTribe API, keeps track of onboarding progress and
     * redirects to the next step.
     *
     * @param action
     * @param updatedValues
     */
    const handleSubmit = async (action, updatedValues) => {
      trackEvent({
        category: 'Onboarding',
        action: 'Submit',
        label: action,
      });

      const profileData = this.getProfileDataValues(action, updatedValues);
      const listingData = this.getListingDataValues(updatedValues);

      if (action === this.FINAL_TAB) {
        await this.saveAndPublish(profileData, listingData);
        history.push(
          createResourceLocatorString('OnboardingCompletedPage', routeConfiguration(), {})
        );
      } else {
        await this.save(profileData, listingData);
        this.redirectToTab(this.NEXT_TAB[action]);
      }
    };

    const user = ensureCurrentUser(currentUser);
    const { firstName } = user.attributes.profile;

    /**
     * Main content switch which determines which tab content is displayed.
     */
    switch (activeTab) {
      case PERSONAL_INFO:
        return (
          <>
            <PanelHeader
              header={
                <FormattedMessage
                  id="OnboardingWizard.personalInfoHeading"
                  values={{ firstName: firstName }}
                />
              }
            />
            <PersonalInfoPanel
              currentUser={currentUser}
              handleSubmit={handleSubmit}
              onImageUpload={onImageUpload}
              profileImage={profileImage}
              uploadImageError={uploadProfileImageError}
              uploadInProgress={uploadProfileImageInProgress}
              updateInProgress={updateProfileInProgress}
              updateProfileError={updateProfileProfileError}
              trackEvent={trackEvent}
            />
          </>
        );
      case SUPPORTED_NONPROFITS:
        return (
          <>
            <PanelHeader
              header={<FormattedMessage id="OnboardingWizard.supportedNonprofitsHeading" />}
            />
            <SupportedNonprofitsPanel
              currentUser={currentUser}
              currentUserListing={currentUserListing}
              handleSubmit={handleSubmit}
              nonprofitPagination={nonprofitPagination}
              nonprofitListings={nonprofitListings}
              nonprofitListingsInProgress={nonprofitListingsInProgress}
              nonprofitListingsError={nonprofitListingsError}
              onOpenModal={onOpenModal}
              onCloseModal={onCloseModal}
              pageName={ONBOARDING_PAGE_NAME}
              pagePathParams={{
                tab: activeTab,
              }}
              showAsModalMaxWidth={showAsModalMaxWidth}
              supportedNonprofits={supportedNonprofits}
              updateListingError={updateListingError}
              updateListingInProgress={updateListingInProgress}
              updateProfileError={updateProfileProfileError}
              updateProfileInProgress={updateProfileInProgress}
              onManageDisableScrolling={onManageDisableScrolling}
              trackEvent={trackEvent}
            />
          </>
        );
      default:
        return null;
    }
  }
}

OnboardingWizard.propTypes = {
  currentUser: propTypes.currentUser.isRequired,
  currentUserListing: object,
  nonprofitPagination: propTypes.pagination,
  nonprofitListingsError: propTypes.error,
  nonprofitListingsInProgress: bool,
  nonprofitListings: array,
  onCloseModal: func.isRequired,
  onOpenModal: func.isRequired,
  onImageUpload: func.isRequired,
  onManageDisableScrolling: func.isRequired,
  onUpdateListing: func.isRequired,
  onPublishListing: func.isRequired,
  onUpdateProfile: func.isRequired,
  params: shape({
    tab: string.isRequired,
  }).isRequired,
  profileImage: object,
  showAsModalMaxWidth: number,
  supportedNonprofits: array,
  updateListingError: propTypes.error,
  updateListingInProgress: bool,
  uploadProfileImageError: propTypes.error,
  uploadProfileImageInProgress: bool,
  updateProfileInProgress: bool,
  updateProfileProfileError: propTypes.error,
  trackEvent: func.isRequired,
};

OnboardingWizard.defaultProps = {
  currentUserListing: {},
  updateListingError: null,
  updateListingInProgress: false,
  uploadProfileImageError: null,
  uploadProfileImageInProgress: false,
  updateProfileInProgress: false,
  updateProfileProfileError: null,
};

export default OnboardingWizard;
