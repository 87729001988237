import Axios from 'axios';

export const FETCH_GEOIP_STATUS = 'app/GeoIP/FETCH_GEOIP_STATUS';
export const FETCH_GEOIP_SUCCESS = 'app/GeoIP/FETCH_GEOIP_SUCCESS';
export const FETCH_GEOIP_FAILURE = 'app/GeoIP/FETCH_GEOIP_FAILURE';

/* {
 *   country: 'XX',      // 2 letter ISO-3166-1 country code
 *   eu: '0',            // 1 if the country is a member state of eu, 0 otherwise
 *   timezone: 'Country/Zone',  // timezone from IANA Time Zone Database
 *   city: "City Name",         // full city name
 * }
 */

const initialState = {
  country: null,
  eu: null,
  timezone: null,
  city: null,
  loading: false,
  error: false,
};

const geoipReducer = (state = initialState, action = {}) => {
  const { type, payload } = action;
  switch (type) {
    case FETCH_GEOIP_STATUS:
      return { ...state, loading: true };
    case FETCH_GEOIP_SUCCESS: {
      const { country, eu, timezone, city } = payload;
      return { ...state, loading: false, country, eu, timezone, city };
    }
    case FETCH_GEOIP_FAILURE:
      return { ...initialState, error: true };
    default:
      return initialState;
  }
};

export default geoipReducer;

export const fetchGeoipStatus = () => async (dispatch) => {
  try {
    dispatch({ type: FETCH_GEOIP_STATUS });
    const { data: payload } = await Axios.get(`${process.env.REACT_APP_OUTREACH_API}/v1/geoip`);
    console.log({ payload });
    dispatch({ type: FETCH_GEOIP_SUCCESS, payload });
  } catch (error) {
    dispatch({ type: FETCH_GEOIP_FAILURE });
  }
};
