import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { fetchCurrentUser } from '../../ducks/user.duck';
import { FormattedMessage } from '../../util/reactIntl';

import {
  LayoutSideNavigation,
  LayoutWrapperMain,
  LayoutWrapperAccountSettingsSideNav,
  LayoutWrapperTopbar,
  LayoutWrapperFooter,
  Footer,
  Page,
  UserNav,
  NamedLink,
} from '../../components';

import { TopbarContainer } from '../../containers';
import css from './AccountNotificationsPage.css';
import { isScrollingDisabled } from '../../ducks/UI.duck';
import { ensureCurrentUser } from '../../util/data';

function AccountNotificationsPageComponent({ currentUser, scrollingDisabled }) {
  const user = ensureCurrentUser(currentUser);

  const {
    attributes: { profile: { protectedData: { agreeMarketing = [] } = {} } = {} } = {},
  } = user;

  return user.id ? (
    <Page title="Notifications" scrollingDisabled={scrollingDisabled}>
      <LayoutSideNavigation>
        <LayoutWrapperTopbar>
          <TopbarContainer
            currentPage="ContactDetailsPage"
            desktopClassName={css.desktopTopbar}
            mobileClassName={css.mobileTopbar}
          />
          <UserNav currentUser={user} selectedPageName="AccountNotificationsPage" />
        </LayoutWrapperTopbar>
        <LayoutWrapperAccountSettingsSideNav
          currentTab="AccountNotificationsPage"
          currentUser={user}
        />
        <LayoutWrapperMain>
          <div className={css.content}>
            <h1 className={css.header}>Notifications</h1>
            <div className={css.divider} />
            <p className={css.row}>
              <FormattedMessage id="AccountNotificationsPage.promotionState" />
              <b>{agreeMarketing.length > 0 && agreeMarketing.includes('Yes') ? 'YES' : 'NO'}</b>
            </p>
            <p className={css.small}>
              <FormattedMessage id="AccountNotificationsPage.disclaimer" />{' '}
              <b>
                <a href="mailto:privacy@givsly.com?subject=Please withdraw my marketing consent on Givsly">
                  <FormattedMessage id="AccountNotificationsPage.thisAddress" />
                </a>
              </b>
              .
            </p>
            <div className={css.divider} />
            <p className={css.small}>
              <FormattedMessage id="AccountNotificationsPage.readAbout" />{' '}
              <NamedLink name="PrivacyPolicyPage">
                <b>
                  <FormattedMessage id="AccountNotificationsPage.privacyPolicy" />
                </b>
              </NamedLink>
              .
            </p>
          </div>
        </LayoutWrapperMain>
        <LayoutWrapperFooter>
          <Footer />
        </LayoutWrapperFooter>
      </LayoutSideNavigation>
    </Page>
  ) : null;
}

const mapStateToProps = (state) => {
  const { currentUser } = state.user;
  return {
    currentUser,
    scrollingDisabled: isScrollingDisabled(state),
  };
};

const AccountNotificationsPage = compose(connect(mapStateToProps))(
  AccountNotificationsPageComponent
);

AccountNotificationsPage.loadData = () => {
  return fetchCurrentUser();
};

export default AccountNotificationsPage;
