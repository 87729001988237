import React, { Component } from 'react';
import { NonprofitInfoForm } from '../../forms';
import { bool, func, object } from 'prop-types';
import { intlShape } from '../../util/reactIntl';
import { LISTING_STATE_PUBLISHED, propTypes } from '../../util/types';
import { ensureCurrentUser, ensureOwnNonprofitListing } from '../../util/data';
import { NONPROFIT_INFO } from '../MyNonprofitWizard/constants';

const onImageUploadHandler = (values, fn) => {
  const { id, imageId, file } = values;
  if (file) {
    fn({ id, imageId, file });
  }
};

class NonprofitInfoPanel extends Component {
  render() {
    const {
      currentUser,
      currentUserListing,
      image,
      intl,
      onImageUpload,
      uploadProfileImageError,
      uploadProfileImageInProgress,
      updateProfileError,
      updateProfileInProgress,
      updateProfileReady,
    } = this.props;

    const user = ensureCurrentUser(currentUser);
    const profileImageId = user.profileImage ? user.profileImage.id : null;
    const profileImage = image || { imageId: profileImageId };

    /**
     * Pre-process the submit and compose the data sets that will be updated.
     *
     * @param values
     */
    const handleSubmit = (values) => {
      // Update profileImage only if file system has been accessed
      const uploadedImage = image;
      const updatedProfileValues =
        uploadedImage && uploadedImage.imageId && uploadedImage.file
          ? { profileImageId: uploadedImage.imageId }
          : {};

      const updatedListingValues = {
        title: values.name.trim(),
        description: values.bio,
        publicData: {
          organizationCategory: values.category,
          organizationLocation: values.location,
          einNumber: values.einNumber,
          nonprofitType: values.nonprofitType,
          email: values.publicEmail,
          phone: values.phone,
          website: values.website,
          address: {
            street: values.street,
            postalCode: values.postalCode,
            city: values.city,
            state: values.state,
          },
          facebookUrl: values.facebookUrl,
          twitterUrl: values.twitterUrl,
          instagramUrl: values.instagramUrl,
          linkedInUrl: values.linkedInUrl,
          youtubeUrl: values.youtubeUrl,
        },
        privateData: {
          email: values.privateEmail,
        },
      };

      this.props.handleSubmit(updatedProfileValues, updatedListingValues, NONPROFIT_INFO);
    };

    const listing = ensureOwnNonprofitListing(currentUserListing);
    const { title, description, publicData, privateData, state } = listing.attributes;

    return (
      <NonprofitInfoForm
        currentUser={user}
        initialValues={{
          bio: description,
          category: publicData.organizationCategory,
          city: publicData.address.city,
          einNumber: publicData.einNumber,
          facebookUrl: publicData.facebookUrl,
          instagramUrl: publicData.instagramUrl,
          linkedInUrl: publicData.linkedInUrl,
          location: publicData.organizationLocation,
          name: title,
          nonprofitType: publicData.nonprofitType,
          phone: publicData.phone,
          postalCode: publicData.address.postalCode,
          privateEmail: privateData.email,
          publicEmail: publicData.email,
          state: publicData.address.state,
          street: publicData.address.street,
          twitterUrl: publicData.twitterUrl,
          website: publicData.website,
          youtubeUrl: publicData.youtubeUrl,
        }}
        intl={intl}
        isPublished={state === LISTING_STATE_PUBLISHED}
        onImageUpload={(e) => onImageUploadHandler(e, onImageUpload)}
        profileImage={profileImage}
        uploadProfileImageError={uploadProfileImageError}
        uploadProfileImageInProgress={uploadProfileImageInProgress}
        updateProfileError={updateProfileError}
        updateProfileInProgress={updateProfileInProgress}
        updateProfileReady={updateProfileReady}
        onSubmit={handleSubmit}
      />
    );
  }
}

NonprofitInfoPanel.propTypes = {
  currentUser: propTypes.currentUser.isRequired,
  currentUserListing: propTypes.ownListing.isRequired,
  handleSubmit: func.isRequired,
  image: object,
  intl: intlShape.isRequired,
  onImageUpload: func.isRequired,
  uploadProfileImageError: propTypes.error,
  uploadProfileImageInProgress: bool.isRequired,
  updateProfileError: propTypes.error,
  updateProfileInProgress: bool.isRequired,
  updateProfileReady: bool,
};

NonprofitInfoPanel.defaultProps = {
  uploadImageError: null,
  uploadInProgress: false,
  updateProfileError: null,
  updateProfileReady: false,
};

export default NonprofitInfoPanel;
