/**
 * Note: This form is using card from Stripe Elements https://stripe.com/docs/stripe-js#elements
 * Card is not a Final Form field so it's not available trough Final Form.
 * It's also handled separately in handleSubmit function.
 */
import React, { Component } from 'react';
import { bool, func, object, string } from 'prop-types';
import { Form as FinalForm } from 'react-final-form';
import classNames from 'classnames';
import config from '../../config';
import {
  Form,
  PrimaryButton,
  FieldTextInput,
  StripePaymentAddress,
  NamedLink,
  SecondaryButton,
} from '../../components';
import css from './PaymentMethodsForm.css';
import { withMessages } from '../../util/localization';

/**
 * Payment methods form that asks for credit card info using Stripe Elements.
 *
 * When the card is valid and the user submits the form, a request is
 * sent to the Stripe API to handle card setup. `stripe.handleCardSetup`
 * may ask more details from cardholder if 3D security steps are needed.
 *
 * See: https://stripe.com/docs/payments/payment-intents
 *      https://stripe.com/docs/elements
 */
class PaymentMethodsForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      cardValueValid: false,
    };
    this.handleCardValueChange = this.handleCardValueChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.paymentForm = this.paymentForm.bind(this);
    this.finalFormAPI = null;
    this.stripe = null;

    this.getStripeErrorMessage = this.getStripeErrorMessage.bind(this);
  }

  get cardStyles() {
    return {
      base: {
        fontFamily: '"GTWalsheimPro", Helvetica, Arial, sans-serif',
        fontSize: '18px',
        fontSmoothing: 'antialiased',
        lineHeight: '24px',
        letterSpacing: '-0.1px',
        color: '#4A4A4A',
        '::placeholder': {
          color: '#B2B2B2',
        },
      },
    };
  }

  get stripeElementsOptions() {
    return {
      fonts: [
        {
          family: 'GTWalsheimPro',
          fontSmoothing: 'antialiased',
          src:
            'local("GTWalsheimPro"), local("GTWalsheimPro"), local("GT Walsheim Pro"), url("/static/fonts/GT-Walsheim-Medium.woff2") format("woff2")',
        },
      ],
    };
  }

  /**
   * Translate Stripe API error object messages
   *
   * @see https://stripe.com/docs/api#errors
   * @param stripeError
   * @returns {string}
   */
  getStripeErrorMessage = (stripeError) => {
    const { getMessage } = this.props;
    const { message, code, type } = stripeError;

    if (!code || !type) {
      return getMessage('genericError');
    }

    return getMessage(
      type === 'validation_error' ? `stripe.validation_error.${code}` : `stripe.${type}`,
      {},
      null,
      message
    );
  };

  componentDidMount() {
    if (!window.Stripe) {
      throw new Error('Stripe must be loaded for PaymentMethodsForm');
    }

    if (config.stripe.publishableKey) {
      this.stripe = window.Stripe(config.stripe.publishableKey);

      const elements = this.stripe.elements(this.stripeElementsOptions);
      this.card = elements.create('card', { style: this.cardStyles });
      this.card.mount(this.cardContainer);
      this.card.addEventListener('change', this.handleCardValueChange);
      // EventListener is the only way to simulate breakpoints with Stripe.
      window.addEventListener('resize', () => {
        if (window.innerWidth < 1024) {
          this.card.update({ style: { base: { fontSize: '18px', lineHeight: '24px' } } });
        } else {
          this.card.update({ style: { base: { fontSize: '20px', lineHeight: '32px' } } });
        }
      });
    }
  }

  componentWillUnmount() {
    if (this.card) {
      this.card.removeEventListener('change', this.handleCardValueChange);
      this.card.unmount();
    }
  }

  handleCardValueChange(event) {
    const { error, complete } = event;

    const postalCode = event.value.postalCode;
    if (this.finalFormAPI) {
      this.finalFormAPI.change('postal', postalCode);
    }

    this.setState(() => {
      return {
        error: error ? this.getStripeErrorMessage(error) : null,
        cardValueValid: complete,
      };
    });
  }

  handleCancel(form) {
    const { onCancel } = this.props;
    form.reset();
    onCancel();
  }

  handleSubmit(values, form) {
    const { onSubmit, inProgress, formId } = this.props;
    const cardInputNeedsAttention = !this.state.cardValueValid;

    if (inProgress || cardInputNeedsAttention) {
      // Already submitting or card value incomplete/invalid
      return;
    }

    const params = {
      stripe: this.stripe,
      card: this.card,
      formId,
      formValues: values,
    };

    onSubmit(params).then(() => {
      this.handleCancel(form);
    });
  }

  paymentForm(formRenderProps) {
    const {
      className,
      rootClassName,
      inProgress: submitInProgress,
      formId,
      getMessage,
      invalid,
      handleSubmit,
      addPaymentMethodError,
      deletePaymentMethodError,
      createStripeCustomerError,
      handleCardSetupError,
      form,
    } = formRenderProps;

    this.finalFormAPI = form;
    const cardInputNeedsAttention = !this.state.cardValueValid;
    const submitDisabled = invalid || cardInputNeedsAttention || submitInProgress;
    const hasCardError = this.state.error && !submitInProgress;
    const classes = classNames(rootClassName || css.root, className);
    const cardClasses = classNames(css.card, {
      [css.cardSuccess]: this.state.cardValueValid,
      [css.cardError]: hasCardError,
    });

    const hasErrors =
      addPaymentMethodError ||
      deletePaymentMethodError ||
      createStripeCustomerError ||
      handleCardSetupError;

    // Stripe recommends asking billing address.
    // In PaymentMethodsForm, we send name and email as billing details, but address only if it exists.
    const billingAddress = <StripePaymentAddress form={form} fieldId={formId} card={this.card} />;

    const hasStripeKey = config.stripe.publishableKey;

    return hasStripeKey ? (
      <Form className={classes} onSubmit={(values) => handleSubmit(values, form)}>
        <label className={css.paymentLabel} htmlFor={`${formId}-card`}>
          {getMessage('paymentCardDetails')}
        </label>

        <div
          className={cardClasses}
          id={`${formId}-card`}
          ref={(el) => {
            this.cardContainer = el;
          }}
        />
        <div className={css.infoText}>
          {getMessage('authorizeOperator', {
            termsOfServiceLink: (
              <NamedLink name="TermsOfServicePage" key={'termsOfServiceLink'}>
                {getMessage('authorizeOperator.linkTitle')}
              </NamedLink>
            ),
          })}
        </div>
        {hasCardError ? <span className={css.error}>{this.state.error}</span> : null}
        <div className={css.paymentAddressField}>
          <h3 className={css.billingHeading}>{getMessage('billingDetails')}</h3>

          <FieldTextInput
            className={css.field}
            type="text"
            id="name"
            name="name"
            autoComplete="cc-name"
            label={getMessage('billingDetailsNameLabel')}
            placeholder={getMessage('billingDetailsNamePlaceholder')}
          />

          {billingAddress}
        </div>
        <div className={css.footNote}>
          {getMessage('footNote', {
            faqLink: (
              <NamedLink key={'faqLink'} name={'FaqPage'}>
                {getMessage('footNote.linkTitle')}
              </NamedLink>
            ),
          })}
        </div>
        {hasErrors ? (
          <div className={css.errorMessage}>
            {hasErrors.message ? hasErrors.message : getMessage('genericError')}
          </div>
        ) : null}
        <div className={css.submitContainer}>
          <SecondaryButton
            className={css.cancelButton}
            type={'button'}
            onClick={() => this.handleCancel(form)}
          >
            {getMessage('cancel')}
          </SecondaryButton>
          <PrimaryButton
            className={css.submitButton}
            type="submit"
            inProgress={submitInProgress}
            disabled={submitDisabled}
          >
            {getMessage('submitPaymentInfo')}
          </PrimaryButton>
        </div>
      </Form>
    ) : (
      <div className={css.missingStripeKey}>{getMessage('missingStripeKey')}</div>
    );
  }

  render() {
    const { onSubmit, ...rest } = this.props;
    return <FinalForm onSubmit={this.handleSubmit} {...rest} render={this.paymentForm} />;
  }
}

PaymentMethodsForm.defaultProps = {
  className: null,
  rootClassName: null,
  inProgress: false,
  handleSubmit: null,
  invalid: false,
  addPaymentMethodError: null,
  deletePaymentMethodError: null,
  createStripeCustomerError: null,
  handleCardSetupError: null,
  form: null,
};

PaymentMethodsForm.propTypes = {
  formId: string,
  getMessage: func.isRequired,
  invalid: bool,
  onCancel: func.isRequired,
  handleSubmit: func,
  addPaymentMethodError: object,
  deletePaymentMethodError: object,
  createStripeCustomerError: object,
  handleCardSetupError: object,
  form: object,
};

export default withMessages(PaymentMethodsForm, 'PaymentMethodsForm');
