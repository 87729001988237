import React from 'react';
import css from './SearchLocationFilterMobile.css';
import {
  FeaturedLocationsMenu,
  Form,
  IconSpinner,
  LocationAutocompleteInputField,
  Menu,
  MenuContent,
  MenuItem,
  MenuLabel,
} from '../index';
import { intlShape } from '../../util/reactIntl';
import { Form as FinalForm } from 'react-final-form';
import Geocoder from '../LocationAutocompleteInput/GeocoderMapbox';
import { highlightWord } from '../../util/richText';
import { createResourceLocatorString } from '../../util/routes';
import routeConfiguration from '../../routeConfiguration';
import { func, object, shape, string } from 'prop-types';

const identity = (v) => v;

class SearchLocationFilterMobile extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      fetchingPredictions: false,
      keywords: '',
      predictions: [],
      isOpen: false,
      previousPlace: null,
    };
    this.getGeocoder = this.getGeocoder.bind(this);
    this.goToLocationResults = this.goToLocationResults.bind(this);
    this.handleFindPredictions = this.handleFindPredictions.bind(this);
    this.onInputChange = this.onInputChange.bind(this);
    this.onToggleActive = this.onToggleActive.bind(this);
  }

  /**
   * Close the filters if the selection has changed
   *
   * @param props
   * @param state
   * @returns {null|{isOpen: boolean}}
   */
  static getDerivedStateFromProps(props, state) {
    if (props.urlQueryParams.placeName !== state.previousPlace) {
      return {
        isOpen: false,
        previousPlace: props.urlQueryParams.placeName,
      };
    }
    return null;
  }

  getGeocoder() {
    // Create the Geocoder as late as possible only when it is needed.
    if (!this._geocoder) {
      this._geocoder = new Geocoder();
    }
    return this._geocoder;
  }

  getTranslation = (name) => {
    return this.props.intl.formatMessage({
      id: `SearchLocationFilter.${name}`,
    });
  };

  goToLocationResults = (bounds, origin, name) => {
    const { history, urlQueryParams } = this.props;
    this.onToggleActive(false);
    history.push(
      createResourceLocatorString(
        this.props.pageName,
        routeConfiguration(),
        this.props.pathParams,
        {
          ...urlQueryParams,
          origin: origin.join(','),
          bounds: bounds.join(','),
          placeName: name,
        }
      )
    );
  };

  handleFindPredictions({ results, fetchingPredictions }) {
    this.setState({
      predictions: results ? results.predictions : this.state.predictions,
      fetchingPredictions,
    });
  }

  onInputChange(e) {
    if (e.target.value === '') {
      this.setState({
        keywords: '',
        predictions: [],
      });
    } else {
      this.setState({
        keywords: e.target.value,
      });
    }
  }

  onToggleActive(newState) {
    this.setState({
      isOpen: newState,
    });
  }

  onCancel = (event) => {
    event.preventDefault(); // This is inside the form in a btn so need to stop sending the form
    this.onToggleActive(false);
  };

  handleClear() {
    this.props.input.onChange({
      search: '',
      predictions: [],
      selectedPlace: null,
    });

    if (typeof this.props.onInputChange === 'function') {
      this.props.onInputChange({ target: { value: '' } });
    }

    this.input.focus();
  }

  render() {
    const hasResults = this.state.predictions && this.state.predictions.length > 0;
    const { urlQueryParams, buttonContent, pageName, pathParams } = this.props;

    const placeName = urlQueryParams.placeName || this.getTranslation('anywhere');
    return (
      <div className={css.root}>
        <Menu
          className={css.menu}
          useArrow={false}
          isOpen={this.state.isOpen}
          onToggleActive={this.onToggleActive}
        >
          <MenuLabel className={css.menuButton}>
            {buttonContent ? buttonContent : placeName}
          </MenuLabel>
          <MenuContent className={css.menuContent} contentClassName={css.menuItems}>
            <MenuItem key="search">
              <FinalForm
                {...this.props}
                initialValues={{
                  location: '',
                }}
                onSubmit={() => {}}
                render={(fieldRenderProps) => {
                  const { history, values } = fieldRenderProps;
                  const { fetchingPredictions } = this.state;
                  const hasKeywords = !!this.state.keywords;
                  const keywords = values.location || { search: '' };

                  return (
                    <Form onSubmit={() => {}}>
                      <div className={css.formHeader}>
                        <button className={css.closeButton} onClick={this.onCancel}>
                          Cancel
                        </button>
                        <LocationAutocompleteInputField
                          handleFindPredictions={this.handleFindPredictions}
                          displayPredictions={false}
                          rootClassName={css.location}
                          inputClassName={css.input}
                          iconClassName={css.icon}
                          predictionsClassName={css.predictionsRoot}
                          validClassName={css.validLocation}
                          name="location"
                          onInputChange={this.onInputChange}
                          placeholder={this.getTranslation('placeholder')}
                          useDefaultPredictions={true}
                          format={identity}
                          valueFromForm={values.location}
                        />
                      </div>
                      {!hasResults && !hasKeywords ? (
                        <>
                          <span className={css.topLocations}>
                            {this.getTranslation('topLocations')}
                          </span>
                          <FeaturedLocationsMenu
                            className={css.featuredLocations}
                            history={history}
                            pageName={pageName}
                            pathParams={pathParams}
                            urlQueryParams={urlQueryParams}
                          />
                        </>
                      ) : null}
                      {fetchingPredictions && !hasResults ? (
                        <IconSpinner className={css.isLoading} />
                      ) : null}
                      {hasKeywords && !hasResults && !fetchingPredictions ? (
                        <div className={css.noResults}>No results found</div>
                      ) : null}
                      {hasKeywords && hasResults ? (
                        <ol className={css.predictions}>
                          {this.state.predictions.map((prediction, i) => {
                            const city = this.getGeocoder().getCityFromPrediction(prediction);
                            const state = this.getGeocoder().getStateNameFromPrediction(prediction);
                            const bounds = [...prediction.bbox].reverse();
                            const coordinates = [...prediction.geometry.coordinates].reverse();
                            return (
                              <li className={css.prediction} key={`prediction-${i}`}>
                                <span
                                  className={css.searchLink}
                                  onClick={() =>
                                    this.goToLocationResults(
                                      bounds,
                                      coordinates,
                                      `${city}, ${state}`
                                    )
                                  }
                                >
                                  <span className={css.place}>
                                    {highlightWord(city, keywords.search)}
                                  </span>
                                  <span className={css.region}>
                                    {highlightWord(state, keywords.search)}
                                  </span>
                                </span>
                              </li>
                            );
                          })}
                        </ol>
                      ) : null}
                    </Form>
                  );
                }}
              />
            </MenuItem>
          </MenuContent>
        </Menu>
      </div>
    );
  }
}

SearchLocationFilterMobile.propTypes = {
  history: shape({
    push: func.isRequired,
  }).isRequired,
  intl: intlShape.isRequired,
  urlQueryParams: object,
  pageName: string,
  pathParams: object,
};

SearchLocationFilterMobile.defaultProps = {
  urlQueryParams: {},
  pageName: 'SearchPage',
  pathParams: {},
};

export default SearchLocationFilterMobile;
