/**
 * Note: This form is using card from Stripe Elements https://stripe.com/docs/stripe-js#elements
 * Card is not a Final Form field so it's not available trough Final Form.
 * It's also handled separately in handleSubmit function.
 */
import React, { Component } from 'react';
import { array, bool, func, node, number, object, shape, string } from 'prop-types';
import {
  METHOD_CREDIT_CARD,
  METHOD_GIVSLY_CREDIT,
} from '../../components/PaymentMethods/constants';
import { withMessages } from '../../util/localization';
import { Form as FinalForm } from 'react-final-form';
import classNames from 'classnames';
import config from '../../config';
import { propTypes } from '../../util/types';
import { ensurePaymentMethodCard } from '../../util/data';

import { Form, PrimaryButton, IconSpinner, PaymentMethods, Button } from '../../components';
import css from './PaymentForm.css';

/**
 * Payment form that asks for credit card info using Stripe Elements.
 *
 * When the card is valid and the user submits the form, a request is
 * sent to the Stripe API to handle payment. `stripe.handleCardPayment`
 * may ask more details from cardholder if 3D security steps are needed.
 *
 * See: https://stripe.com/docs/payments/payment-intents
 *      https://stripe.com/docs/elements
 */
class PaymentForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      cardValueValid: false,
      // The mode can be 'onetimePayment', 'defaultCard', or 'replaceCard'
      // Check SavedCardDetails component for more information
      paymentMethod: null,
    };
    this.handleCardValueChange = this.handleCardValueChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.initializeStripeElement = this.initializeStripeElement.bind(this);
    this.handleStripeElementRef = this.handleStripeElementRef.bind(this);
    this.changePaymentMethod = this.changePaymentMethod.bind(this);
    this.translateStripeError = this.translateStripeError.bind(this);
    this.finalFormAPI = null;
    this.cardContainer = null;
  }

  componentDidMount() {
    if (!window.Stripe) {
      throw new Error('Stripe must be loaded for PaymentForm');
    }

    if (config.stripe.publishableKey) {
      const { onStripeInitialized } = this.props;
      this.stripe = window.Stripe(config.stripe.publishableKey);
      onStripeInitialized(this.stripe);
    }
  }

  componentWillUnmount() {
    if (this.card) {
      this.card.removeEventListener('change', this.handleCardValueChange);
      this.card.unmount();
      this.card = null;
    }
  }

  translateStripeError = (stripeError) => {
    const { message, code, type } = stripeError;
    const { getMessage } = this.props;

    if (!code || !type) {
      // Not a proper Stripe error object
      return getMessage('genericError');
    }

    const translationId =
      type === 'validation_error' ? `stripe.validation_error.${code}` : `stripe.${type}`;

    return getMessage(translationId, {}, null, message);
  };

  initializeStripeElement(element) {
    const elements = this.stripe.elements({
      fonts: [
        {
          family: 'GTWalsheimPro',
          fontSmoothing: 'antialiased',
          src:
            'local("GTWalsheimPro"), local("GTWalsheimPro"), local("GT Walsheim Pro"), url("/static/fonts/GT-Walsheim-Medium.woff2") format("woff2")',
        },
      ],
    });

    if (!this.card) {
      this.card = elements.create('card', {
        style: {
          base: {
            fontFamily: '"GTWalsheimPro", Helvetica, Arial, sans-serif',
            fontSize: '18px',
            fontSmoothing: 'antialiased',
            lineHeight: '24px',
            letterSpacing: '-0.1px',
            color: '#4A4A4A',
            '::placeholder': {
              color: '#B2B2B2',
            },
          },
        },
      });
      this.card.mount(element || this.cardContainer);
      this.card.addEventListener('change', this.handleCardValueChange);
      // EventListener is the only way to simulate breakpoints with Stripe.
      window.addEventListener('resize', () => {
        if (this.card) {
          if (window.innerWidth < 1024) {
            this.card.update({ style: { base: { fontSize: '18px', lineHeight: '24px' } } });
          } else {
            this.card.update({ style: { base: { fontSize: '20px', lineHeight: '32px' } } });
          }
        }
      });
    }
  }

  changePaymentMethod(changedTo) {
    if (this.card && changedTo === 'defaultCard') {
      this.card.removeEventListener('change', this.handleCardValueChange);
      this.card.unmount();
      this.card = null;
    }
    this.setState({ paymentMethod: changedTo });
  }

  handleStripeElementRef(el) {
    this.cardContainer = el;
    if (this.stripe && el) {
      this.initializeStripeElement(el);
    }
  }

  handleCardValueChange(event) {
    const { intl } = this.props;
    const { error, complete } = event;

    const postalCode = event.value.postalCode;
    if (this.finalFormAPI) {
      this.finalFormAPI.change('postal', postalCode);
    }

    this.setState((prevState) => {
      return {
        error: error ? this.translateStripeError(intl, error) : null,
        cardValueValid: complete,
      };
    });
  }

  handleSubmit(values) {
    const { onSubmit, inProgress, formId, defaultPaymentMethod } = this.props;
    const { initialMessage } = values;

    if (inProgress) {
      // Already submitting
      return;
    }

    const ensuredPaymentMethod = ensurePaymentMethodCard(defaultPaymentMethod);
    const params = {
      message: initialMessage ? initialMessage.trim() : null,
      card: ensuredPaymentMethod.attributes.card,
      formId,
      formValues: values,
      paymentMethod: 'defaultCard',
    };
    onSubmit(params);
  }

  render() {
    // TODO - test this!
    // console.log(this.props);
    const {
      addCreditCardInProgress,
      breakdown,
      confirmPaymentError,
      creditTotal,
      creditCards,
      defaultPaymentMethod,
      dueAmount,
      ensuredCurrentUser,
      hasHandledCardPayment,
      isProposal,
      isOutreachOffer,
      loadingData,
      onAddCredit,
      onAddCreditCard,
      onGetBalance,
      onManageDisableScrolling,
      onSelectPaymentMethod,
      onSubmit,
      selectedPaymentIdentifier,
      selectedPaymentMethod,
      ...rest
    } = this.props;

    const ensuredDefaultPaymentMethod = ensurePaymentMethodCard(defaultPaymentMethod);
    const billingDetailsNeeded = !(hasHandledCardPayment || confirmPaymentError);
    const billingDetailsKnown = hasHandledCardPayment || ensuredDefaultPaymentMethod;

    return (
      <>
        {!loadingData ? (
          <PaymentMethods
            addCreditCardInProgress={addCreditCardInProgress}
            breakdown={breakdown}
            creditCards={creditCards}
            creditTotal={creditTotal}
            dueAmount={dueAmount}
            ensuredCurrentUser={ensuredCurrentUser}
            isOutreachOffer={isOutreachOffer}
            onAddCredit={onAddCredit}
            onAddCreditCard={onAddCreditCard}
            onGetBalance={onGetBalance}
            onManageDisableScrolling={onManageDisableScrolling}
            onSelectPaymentMethod={(selectedMethod, selectedIdentifier) => {
              onSelectPaymentMethod(selectedMethod, selectedIdentifier);
            }}
            selectedCreditCard={selectedPaymentIdentifier}
            selectedMethod={selectedPaymentMethod}
          />
        ) : loadingData ? (
          <p className={css.spinner}>
            <IconSpinner />
          </p>
        ) : null}
        <FinalForm
          onSubmit={this.handleSubmit}
          {...rest}
          render={(formRenderProps) => {
            const {
              className,
              rootClassName,
              hasSelectedNonprofit,
              inProgress: submitInProgress,
              priceBreakdown,
              getMessage,
              initiateOrderError,
              handleCardPaymentError,
              invalid,
              handleSubmit,
              onCancel,
              form,
            } = formRenderProps;

            this.finalFormAPI = form;
            const onetimePaymentNeedsAttention = !billingDetailsKnown && !this.state.cardValueValid;
            const submitDisabled =
              invalid ||
              onetimePaymentNeedsAttention ||
              submitInProgress ||
              !hasSelectedNonprofit ||
              (defaultPaymentMethod === null && selectedPaymentMethod === METHOD_CREDIT_CARD) ||
              (defaultPaymentMethod === null &&
                selectedPaymentMethod === METHOD_GIVSLY_CREDIT &&
                dueAmount > 0);
            const hasPaymentErrors = handleCardPaymentError || confirmPaymentError;
            const classes = classNames(rootClassName || css.root, className);

            // TODO: handleCardPayment can create all kinds of errors.
            // Currently, we provide translation support for one:
            // https://stripe.com/docs/error-codes
            const piAuthenticationFailure = 'payment_intent_authentication_failure';
            const paymentErrorMessage =
              handleCardPaymentError && handleCardPaymentError.code === piAuthenticationFailure
                ? getMessage('handleCardPaymentError')
                : handleCardPaymentError
                ? handleCardPaymentError.message
                : confirmPaymentError
                ? getMessage('confirmPaymentError')
                : getMessage('genericError');

            // Asking billing address is recommended in PaymentIntent flow.
            // In CheckoutPage, we send name and email as billing details, but address only if it exists.

            const hasStripeKey = config.stripe.publishableKey;
            // const showPaymentMethodSelector = ensuredDefaultPaymentMethod.id;
            // const selectedPaymentMethod = getPaymentMethod(
            //   this.state.paymentMethod,
            //   showPaymentMethodSelector
            // );
            // const showOnetimePaymentFields = [ 'onetimeCardPayment', 'replaceCard' ].includes(
            //   selectedPaymentMethod
            // );
            return hasStripeKey ? (
              <Form className={classes} onSubmit={handleSubmit}>
                {initiateOrderError ? (
                  <span className={css.errorMessage}>{initiateOrderError.message}</span>
                ) : null}
                <div className={css.submitContainer}>
                  {hasPaymentErrors ? (
                    <span className={css.errorMessage}>{paymentErrorMessage}</span>
                  ) : null}
                  {priceBreakdown}
                  <PrimaryButton
                    className={css.submitButton}
                    type="submit"
                    inProgress={submitInProgress}
                    disabled={submitDisabled}
                  >
                    {getMessage(
                      billingDetailsNeeded
                        ? isProposal
                          ? 'proposeMeeting'
                          : isOutreachOffer
                          ? 'confirmPayment'
                          : 'requestMeeting'
                        : 'submitConfirmPaymentInfo'
                    )}
                  </PrimaryButton>
                  {isOutreachOffer ? (
                    <Button
                      className={css.buttonCancel}
                      disabled={submitDisabled}
                      onClick={onCancel}
                    >
                      {getMessage('cancel')}
                    </Button>
                  ) : null}
                </div>
              </Form>
            ) : (
              <div className={css.missingStripeKey}>{getMessage('missingStripeKey')}</div>
            );
          }}
        />
      </>
    );
  }
}

PaymentForm.defaultProps = {
  className: null,
  rootClassName: null,
  hasSelectedNonprofit: false,
  isProposal: false,
  isOutreachOffer: false,
  inProgress: false,
  loadingData: false,
  hasHandledCardPayment: false,
  defaultPaymentMethod: null,
  initiateOrderError: null,
  handleCardPaymentError: null,
  confirmPaymentError: null,
  priceBreakdown: null,
  onCancel: () => {},
};

PaymentForm.propTypes = {
  addCreditCardInProgress: bool.isRequired,
  breakdown: shape({
    donation: number,
    operatorFee: number,
    paymentFee: number,
    creditDeduction: number,
    subTotal: number,
    total: number,
  }),
  className: string,
  creditCards: array,
  creditTotal: number.isRequired,
  dueAmount: number, // @todo Refactor/remove
  ensuredCurrentUser: propTypes.currentUser.isRequired,
  rootClassName: string,
  hasSelectedNonprofit: bool.isRequired,
  inProgress: bool,
  loadingData: bool,
  initiateOrderError: object,
  isProposal: bool,
  isOutreachOffer: bool,
  handleCardPaymentError: object,
  confirmPaymentError: object,
  formId: string.isRequired,
  getMessage: func.isRequired,
  onAddCredit: func.isRequired,
  onAddCreditCard: func.isRequired,
  onGetBalance: func.isRequired,
  onManageDisableScrolling: func.isRequired,
  onSelectPaymentMethod: func.isRequired,
  onSubmit: func.isRequired,
  onCancel: func,
  paymentInfo: string.isRequired,
  priceBreakdown: node,
  hasHandledCardPayment: bool,
  defaultPaymentMethod: propTypes.defaultPaymentMethod,
  selectedPaymentMethod: string,
  selectedPaymentIdentifier: string,
};

export default withMessages(PaymentForm, 'PaymentForm');
