export const ACCEPT_COOKIES = 'app/CookieConsent/ACCEPT_COOKIES';

const initialState = {
  country: null,
  disableCookies: false,
  cookiesAccepted: null,
};

export default function reducer(state = initialState, action = {}) {
  const { type } = action;

  switch (type) {
    case ACCEPT_COOKIES:
      return {
        ...state,
        cookiesAccepted: true,
      };
    default:
      return state;
  }
}

// ================ Action creators ================ //
const acceptCookiesAction = () => ({ type: ACCEPT_COOKIES });

// ================ Thunks ================ //
export const acceptCookies = () => (dispatch, getState, sdk) => {
  dispatch(acceptCookiesAction());
};
