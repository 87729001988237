import React from 'react';
import { node, number, string, shape } from 'prop-types';
import { compose } from 'redux';
import { FormattedMessage } from '../../util/reactIntl';
import { withViewport } from '../../util/contextHelpers';
import { LayoutWrapperSideNav } from '../../components';
import { INTERESTS, PERSONAL_INFO, SUPPORTED_NONPROFITS } from '../MyProfileWizard/MyProfileWizard';
import { propTypes } from '../../util/types';

const MAX_HORIZONTAL_NAV_SCREEN_WIDTH = 1023;

const scrollToTab = (currentTab) => {
  const el = document.querySelector(`#${currentTab}Tab`);

  if (el) {
    el.scrollIntoView({
      block: 'end',
      inline: 'end',
      behavior: 'smooth',
    });
  }
};

const LayoutWrapperMyProfileSideNavComponent = (props) => {
  const { currentTab, viewport } = props;

  let hasScrolledToTab = false;

  const { width } = viewport;
  const hasViewport = width > 0;
  const hasHorizontalTabLayout = hasViewport && width <= MAX_HORIZONTAL_NAV_SCREEN_WIDTH;
  const hasFontsLoaded = hasViewport && document.documentElement.classList.contains('fontsLoaded');

  if (hasHorizontalTabLayout && !hasScrolledToTab && hasFontsLoaded) {
    scrollToTab(currentTab);
  }

  const tabs = [
    {
      text: <FormattedMessage id="LayoutWrapperMyProfileSideNav.personalInfoTabTitle" />,
      selected: currentTab === PERSONAL_INFO,
      completed: true,
      id: 'MyProfilePageTab',
      linkProps: {
        name: 'MyProfilePage',
        params: {
          tab: PERSONAL_INFO,
        },
      },
    },
    {
      text: <FormattedMessage id="LayoutWrapperMyProfileSideNav.NPOTabTitle" />,
      selected: currentTab === SUPPORTED_NONPROFITS,
      completed: true,
      id: 'ProfileNPOPageTab',
      linkProps: {
        name: 'MyProfilePage',
        params: {
          tab: SUPPORTED_NONPROFITS,
        },
      },
    },
  ];

  return <LayoutWrapperSideNav tabs={tabs} />;
};

LayoutWrapperMyProfileSideNavComponent.defaultProps = {
  className: null,
  rootClassName: null,
  children: null,
  currentTab: null,
};

LayoutWrapperMyProfileSideNavComponent.propTypes = {
  currentUser: propTypes.currentUser,
  children: node,
  className: string,
  rootClassName: string,
  currentTab: string,

  // from withViewport
  viewport: shape({
    width: number.isRequired,
    height: number.isRequired,
  }).isRequired,
};

const LayoutWrapperMyProfileSideNav = compose(withViewport)(LayoutWrapperMyProfileSideNavComponent);

export default LayoutWrapperMyProfileSideNav;
