import Tippy from '@tippy.js/react';
import React from 'react';
import css from './Tooltip.css';
import { node } from 'prop-types';

const infoIcon = (
  <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12">
    <path
      fill="#FAC51D"
      d="M6 0a6 6 0 1 1 0 12A6 6 0 0 1 6 0zm.5 5.5h-1A.5.5 0 0 0 5 6v3.5a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5V6a.5.5 0 0 0-.5-.5zM6 2a1.25 1.25 0 1 0 0 2.5A1.25 1.25 0 0 0 6 2z"
    />
  </svg>
);

export const Tooltip = ({ content, icon }) => (
  <Tippy className={css.tooltip} content={content} placement="auto">
    {icon || infoIcon}
  </Tippy>
);

Tooltip.propTypes = {
  content: node.isRequired,
  icon: node,
};
