import React, { useCallback, useMemo, useState } from 'react';
import { Form as FinalForm } from 'react-final-form';
import NonprofitPreferencesForm from './NonprofitPreferencesForm';
import { ensureCurrentUser } from '../../util/data';
import {
  BRAND_TEMPLATE,
  EVENT_TEMPLATE,
  EXCLUDED_AREA_TEMPLATE,
  IMPACT_AREA_TEMPLATE,
} from './constants';

const NonprofitPreferencesPanel = (props) => {
  const {
    currentUserListing = {},
    onUpdateListing,
    isPublished,
    onUploadS3Image,
    currentUser,
  } = props;
  const ensuredCurrentUser = ensureCurrentUser(currentUser);

  const [isSubmitting, setIsSubmitting] = useState(false);

  const {
    attributes: {
      publicData: {
        approvePortfolioPresentation,
        partners,
        collaborationWishes,
        events,
        brandingGuidelines,
        approvalContact = {},
        exclusions: { exclude = [], brands, industries, keywords, areas } = {},
        impactArea,
        causes,
      } = {},
    } = {},
  } = currentUserListing;

  const initialValues = useMemo(
    () => ({
      approvePortfolioPresentation: approvePortfolioPresentation ? ['Yes'] : [],
      approvalContact: {
        name: approvalContact.name || '',
        email: approvalContact.email || '',
      },
      exclusions: {
        exclude: exclude ? ['Yes'] : [],
        brands: brands && brands.length > 0 ? brands : [{ ...BRAND_TEMPLATE }],
        industries: industries && industries.length > 0 ? industries : [],
        keywords: keywords && keywords.length > 0 ? keywords : [''],
        areas: areas && areas.length > 0 ? areas : [{ ...EXCLUDED_AREA_TEMPLATE }],
      },
      impactArea: impactArea || [{ ...IMPACT_AREA_TEMPLATE }],
      partners: partners && partners.length > 0 ? partners : [{ ...BRAND_TEMPLATE }],
      collaborationWishes:
        collaborationWishes && collaborationWishes.length > 0
          ? collaborationWishes
          : [{ ...BRAND_TEMPLATE }],
      events:
        events && events.length > 0
          ? events.map((event) => ({
              ...event,
              start: event.start ? { date: new Date(event.start) } : {},
              end: event.end ? { date: new Date(event.end) } : {},
              yearRound: event.yearRound ? ['Yes'] : [],
            }))
          : [{ ...EVENT_TEMPLATE }],
      brandingGuidelines: brandingGuidelines || {
        guidelineUrl: '',
        logoLightUrl: '',
        logoDarkUrl: '',
      },
      causes: causes && causes.length > 0 ? causes : [''],
    }),
    [
      approvalContact.email, 
      approvalContact.name, 
      approvePortfolioPresentation, 
      areas, brandingGuidelines, 
      brands, causes, collaborationWishes, 
      events, exclude, impactArea,
      industries, keywords, partners]
  );

  const onSubmit = useCallback(async (values) => {
    try {
      setIsSubmitting(true);
      const {
        brandingGuidelines: { guidelineUrl, logoLightUrl, logoDarkUrl },
        approvalContact,
        impactArea,
      } = values;

      const approvePortfolioPresentation =
        Array.isArray(values.approvePortfolioPresentation) &&
        values.approvePortfolioPresentation.length === 1 &&
        values.approvePortfolioPresentation.includes('Yes');

      const exclude =
        Array.isArray(values.exclusions.exclude) &&
        values.exclusions.exclude.length === 1 &&
        values.exclusions.exclude.includes('Yes');

      const [logoLightUploadResult, logoDarkUploadResult] = await Promise.allSettled(
        [logoLightUrl, logoDarkUrl].map(async (url) => {
          // if blob data url
          if (url && /^data:image/.test(url)) {
            const { message: s3Url } = await onUploadS3Image({ image: url, type: 'logo' });
            return s3Url;
          }
          return url;
        })
      );

      const brandingGuidelines = {
        guidelineUrl: guidelineUrl || '',
        logoLightUrl:
          logoLightUploadResult.status === 'fulfilled' ? logoLightUploadResult.value : '',
        logoDarkUrl: logoDarkUploadResult.status === 'fulfilled' ? logoDarkUploadResult.value : '',
      };

      const filteredEvents = values.events
        .filter((event) => event.name !== '')
        .map((event) => {
          const start = event.start.date ? event.start.date.toJSON() : null;
          const end = event.end.date ? event.end.date.toJSON() : null;
          const yearRound =
            Array.isArray(event.yearRound) &&
            event.yearRound.length === 1 &&
            event.yearRound.includes('Yes');
          return { ...event, start, end, yearRound };
        });
      const filteredPartners = values.partners.filter((brand) => brand.name !== '');
      const filteredCollaborationWishes = values.collaborationWishes.filter(
        (brand) => brand.name !== ''
      );
      const filteredExcludedBrands = values.exclusions.brands.filter((brand) => brand.name !== '');
      const filteredExclusionKeywords = values.exclusions.keywords.filter(
        (keyword) => keyword !== ''
      );
      const filteredCauses = values.causes.filter((cause) => cause !== '');
      const filteredExcludedAreas = values.exclusions.areas.filter((area) => area.country !== '');

      await onUpdateListing({
        id: currentUserListing.id,
        publicData: {
          approvePortfolioPresentation,
          approvalContact,
          exclusions: {
            exclude,
            brands: filteredExcludedBrands,
            industries: values.exclusions.industries,
            keywords: filteredExclusionKeywords,
            areas: filteredExcludedAreas,
          },
          collaborationWishes: filteredCollaborationWishes,
          events: filteredEvents,
          causes: filteredCauses,
          partners: filteredPartners,
          brandingGuidelines,
          impactArea,
        },
      });
      setIsSubmitting(false);
    } catch (err) {
      console.warn(err);
      setIsSubmitting(false);
    }
  }, [
    currentUserListing.id, 
    onUpdateListing, 
    onUploadS3Image
  ]);

  return (
    <FinalForm
      initialValues={initialValues}
      onSubmit={onSubmit}
      isPublished={isPublished}
      isSubmitting={isSubmitting}
      ensuredCurrentUser={ensuredCurrentUser}
      render={(formRenderProps) => <NonprofitPreferencesForm {...formRenderProps} />}
    />
  );
};

export default NonprofitPreferencesPanel;
