import React from 'react';
import { number } from 'prop-types';
import { withMessages } from '../../util/localization';
import css from './BookingBreakdown.css';

const LineItemMeetingDuration = (props) => {
  const { getMessage, meetingDuration } = props;

  return (
    <div className={css.detailItem}>
      <div className={css.detailItemLabel}>{getMessage('meetingDuration')}</div>
      <div className={css.detailItemValue}>{getMessage('minutes', { meetingDuration })}</div>
    </div>
  );
};

LineItemMeetingDuration.propTypes = {
  meetingDuration: number.isRequired,
};

export default withMessages(LineItemMeetingDuration, 'BookingBreakdown');
