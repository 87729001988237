/**
 * Booking breakdown estimation
 *
 * Transactions have payment information that can be shown with the
 * BookingBreakdown component. However, when selecting booking
 * details, there is no transaction object present and we have to
 * estimate the breakdown of the transaction without data from the
 * API.
 *
 * If the payment process of a customized marketplace is something
 * else than simply daily or nightly bookings, the estimation will
 * most likely need some changes.
 *
 * To customize the estimation, first change the BookingDatesForm to
 * collect all booking information from the user (in addition to the
 * default date pickers), and provide that data to the
 * EstimatedBreakdownMaybe components. You can then make customization
 * within this file to create a fake transaction object that
 * calculates the breakdown information correctly according to the
 * process.
 *
 * In the future, the optimal scenario would be to use the same
 * transactions.initiateSpeculative API endpoint as the CheckoutPage
 * is using to get the breakdown information from the API, but
 * currently the API doesn't support that for logged out users, and we
 * are forced to estimate the information here.
 */
import React, { useEffect, useState } from 'react';
import { getEstimatedTransaction } from '../../util/transaction';
import { LINE_ITEM_UNITS } from '../../util/types';
import { BookingBreakdown } from '../../components';
import config from '../../config';
import { array, bool, number, string } from 'prop-types';
import { METHOD_GIVSLY_CREDIT } from '../PaymentMethods/constants';
import { connect } from 'react-redux';
import { fetchCurrentUser } from '../../ducks/user.duck';

const EstimatedBreakdownComponent = ({ user, ...props }) => {
  const {
    className,
    creditTotal,
    deductFee,
    isProposal,
    meetingDuration,
    onDeductFeeChange,
    selectedPaymentMethod,
    showBookingDetails,
    suggestedTimes,
    timezone,
  } = props;
  const { startDate, endDate, meetingMethod, methodPriceChoices, quantity } = props.bookingData;

  const [operatorPercentage, setOperatorPercentage] = useState(15);

  useEffect(() => {
    (async () => {
      const response = await user();
      const op =
        response &&
        response.attributes &&
        response.attributes.profile &&
        response.attributes.profile.metadata &&
        response.attributes.profile.metadata.operatorPercentage;
      setOperatorPercentage(op >= 0 ? op : 15);
    })();
  }, [user]);

  const canEstimatePrice =
    meetingMethod &&
    methodPriceChoices &&
    methodPriceChoices[meetingMethod] &&
    config.custom.fixedMethodPrices &&
    config.custom.fixedMethodPrices[meetingMethod] &&
    config.custom.fixedMethodPrices[meetingMethod][methodPriceChoices[meetingMethod]];
  if (!canEstimatePrice) {
    return null;
  }

  const tx = getEstimatedTransaction(
    startDate,
    endDate,
    meetingMethod,
    methodPriceChoices,
    quantity,
    deductFee,
    selectedPaymentMethod === METHOD_GIVSLY_CREDIT ? creditTotal : 0,
    operatorPercentage
  );

  return (
    <BookingBreakdown
      className={className}
      isProposal={isProposal}
      userRole="customer"
      unitType={LINE_ITEM_UNITS}
      transaction={tx}
      booking={tx.booking}
      timezone={timezone}
      deductFee={deductFee}
      meetingDuration={meetingDuration}
      onDeductFeeChange={onDeductFeeChange}
      showBookingDetails={showBookingDetails}
      suggestedTimes={suggestedTimes}
    />
  );
};

// EstimatedBreakdown.propTypes = {
//   applyCredit: bool,
//   className: string,
//   creditTotal: number,
//   isProposal: bool,
//   meetingDuration: number,
//   suggestedTimes: array,
//   timezone: string.isRequired,
// };

// EstimatedBreakdown.defaultProps = {
//   applyCredit: false,
//   className: null,
//   creditTotal: 0,
//   isProposal: false,
//   meetingDuration: null,
//   showBookingDetails: false,
//   suggestedTimes: [],
// };

const mapDispatchToProps = (dispatch) => {
  return {
    user: () => dispatch(fetchCurrentUser()),
  };
};

const EstimatedBreakdown = connect(() => ({}), mapDispatchToProps)(EstimatedBreakdownComponent);

export default EstimatedBreakdown;
