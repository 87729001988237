import React from 'react';
import { PrimaryButton, SecondaryButton } from '..';
import IconCard from '../icons/IconCard/IconCard';
import LiteButton from '../LiteButton/LiteButton';
import { ICON_PLUS } from '../LiteButton/constants';
import Modal from '../Modal/Modal';
import css from './SavedCards.css';
import { array, bool, func } from 'prop-types';
import { withMessages } from '../../util/localization';
// import classNames from 'classnames';

class SavedCards extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      openContextMenu: null,
      isConfirmDeleteModalOpen: false,
      deleteCardNumber: null,
    };

    this.handleClickAdd = this.handleClickAdd.bind(this);
    this.handleClickDelete = this.handleClickDelete.bind(this);
    this.handleClickEdit = this.handleClickEdit.bind(this);
    this.handleClickOutsideContextMenu = this.handleClickOutsideContextMenu.bind(this);
    this.handleClickSetAsDefault = this.handleClickSetAsDefault.bind(this);
    this.handleCloseConfirmDeleteModal = this.handleCloseConfirmDeleteModal.bind(this);
    this.handleConfirmDelete = this.handleConfirmDelete.bind(this);
    this.handleOpenContextMenu = this.handleOpenContextMenu.bind(this);
    this.contextMenuRef = React.createRef();
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutsideContextMenu);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutsideContextMenu);
  }

  handleClickOutsideContextMenu(event) {
    if (
      this.contextMenuRef &&
      this.contextMenuRef.current &&
      !this.contextMenuRef.current.contains(event.target)
    ) {
      this.setState({ openContextMenu: null });
    }
  }

  handleClickAdd() {
    const { onAddCard } = this.props;
    onAddCard();
  }

  handleClickSetAsDefault() {
    this.setState({ openContextMenu: null });
    const { onSetAsDefault } = this.props;
    onSetAsDefault();
  }

  handleClickEdit() {
    this.setState({ openContextMenu: null });
    const { onEditCard } = this.props;
    onEditCard();
  }

  handleClickDelete(lastDigits) {
    this.setState({
      deleteCardNumber: lastDigits,
      isConfirmDeleteModalOpen: true,
      openContextMenu: null,
    });
  }

  handleCloseConfirmDeleteModal() {
    this.setState({
      isConfirmDeleteModalOpen: false,
      deleteCardNumber: null,
    });
  }

  handleConfirmDelete() {
    this.handleCloseConfirmDeleteModal();
    const { onDeleteCard } = this.props;
    onDeleteCard();
  }

  handleOpenContextMenu(index) {
    this.setState({ openContextMenu: index === this.state.index ? null : index });
  }

  render() {
    const { cards, getMessage, onManageDisableScrolling, stripeCustomerFetched } = this.props;
    return (
      <div className={css.root}>
        <h2 className={css.header}>{getMessage('creditCard')}</h2>
        {cards.length > 0 ? (
          <div className={css.cardsContainer}>
            <table className={css.cards} cellSpacing={0} cellPadding={0}>
              {stripeCustomerFetched ? (
                <tbody>
                  {cards.map((card, index) => {
                    const { brand, last4Digits, expirationMonth, expirationYear } = card;
                    // const contextMenuClasses = classNames(
                    //   css.contextMenu,
                    //   this.state.openContextMenu === index ? css.contextMenuOpen : null
                    // );
                    return (
                      <tr key={`savedCard-${index}`}>
                        <td className={css.columnIcon}>
                          <IconCard brand={brand} className={css.cardIcon} />
                        </td>
                        <td className={css.columnCardNumber}>
                          <span className={css.cardNumberMobile}>…{last4Digits}</span>
                          <span className={css.cardNumberDesktop}>
                            •••• •••• •••• {last4Digits}
                          </span>
                        </td>
                        <td className={css.columnExpiration}>
                          <span className={css.mobileLabel}>
                            {String(expirationMonth).padStart(2, '0')}/
                            {String(expirationYear).substr(2)}
                          </span>
                          <span className={css.desktopLabel}>
                            {getMessage('expiresOn', {
                              expirationDate: `${String(expirationMonth).padStart(2, '0')}/${String(
                                expirationYear
                              ).substr(2)}`,
                            })}
                          </span>
                        </td>
                        <td className={css.columnControls}>
                          <button
                            className={css.cardButtonDelete}
                            onClick={() => this.handleClickDelete(last4Digits)}
                            type={'button'}
                          />
                          {/*<button*/}
                          {/*  className={css.cardButton}*/}
                          {/*  onClick={() => this.handleOpenContextMenu(index)} type={'button'}*/}
                          {/*/>*/}
                          {/*<ul className={contextMenuClasses} ref={this.contextMenuRef}>*/}
                          {/*<li className={css.contextMenuItem}>*/}
                          {/*  <button*/}
                          {/*    className={css.buttonDefault}*/}
                          {/*    onClick={this.handleClickSetAsDefault}*/}
                          {/*    type={'button'}*/}
                          {/*  >*/}
                          {/*    {getMessage('setAsDefault')}*/}
                          {/*  </button>*/}
                          {/*</li>*/}
                          {/*<li>*/}
                          {/*  <button*/}
                          {/*    className={css.buttonEdit}*/}
                          {/*    onClick={this.handleClickEdit}*/}
                          {/*    type={'button'}*/}
                          {/*  >*/}
                          {/*    {getMessage('edit')}*/}
                          {/*  </button>*/}
                          {/*</li>*/}
                          {/*  <li>*/}
                          {/*    <button*/}
                          {/*      className={css.buttonDelete}*/}
                          {/*      onClick={() => this.handleClickDelete(last4Digits)}*/}
                          {/*      type={'button'}*/}
                          {/*    >*/}
                          {/*      {getMessage('delete')}*/}
                          {/*    </button>*/}
                          {/*  </li>*/}
                          {/*</ul>*/}
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              ) : (
                <tbody>
                  <tr>
                    <td className={css.columnIcon}>
                      <span className={css.skeletonLabel} />
                    </td>
                    <td className={css.columnCardNumber}>
                      <span className={css.skeletonLabel} />
                    </td>
                    <td className={css.columnExpiration}>
                      <span className={css.skeletonLabel} />
                    </td>
                    <td className={css.columnControls}>
                      <span className={css.skeletonLabel} />
                    </td>
                  </tr>
                </tbody>
              )}
            </table>
          </div>
        ) : (
          <div className={css.placeholder}>{getMessage('noCards')}</div>
        )}
        <div className={css.controls}>
          <LiteButton icon={ICON_PLUS} onClick={this.handleClickAdd}>
            {getMessage(cards.length > 0 ? 'replaceCard' : 'addCard')}
          </LiteButton>
        </div>
        <Modal
          hasCloseButton={false}
          id={'confirmDeleteModal'}
          isOpen={this.state.isConfirmDeleteModalOpen}
          onClose={this.handleCloseConfirmDeleteModal}
          onManageDisableScrolling={onManageDisableScrolling}
        >
          <h2 className={css.modalHeader}>{getMessage('deleteCard.header')}</h2>
          <p className={css.modalBody}>
            {getMessage('deleteCard.body', {
              lastDigits: <strong key={'deleteCardNumber'}>{this.state.deleteCardNumber}</strong>,
            })}
          </p>
          <div className={css.modalControls}>
            <SecondaryButton onClick={this.handleCloseConfirmDeleteModal} type={'button'}>
              {getMessage('cancel')}
            </SecondaryButton>
            <PrimaryButton onClick={this.handleConfirmDelete} type={'button'}>
              {getMessage('delete')}
            </PrimaryButton>
          </div>
        </Modal>
      </div>
    );
  }
}

SavedCards.propTypes = {
  cards: array,
  getMessage: func.isRequired,
  onAddCard: func.isRequired,
  onDeleteCard: func.isRequired,
  onEditCard: func.isRequired,
  onSetAsDefault: func.isRequired,
  onManageDisableScrolling: func.isRequired,
  stripeCustomerFetched: bool,
};

SavedCards.defaultProps = {
  cards: [],
  stripeCustomerFetched: false,
};

export default withMessages(SavedCards, 'SavedCards');
