import React, { Component } from 'react';
import {
  bool,
  func,
  instanceOf,
  object,
  oneOfType,
  shape,
  string,
  array,
  arrayOf,
} from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import remove from 'lodash/remove';
import {
  METHOD_CREDIT_CARD,
  METHOD_GIVSLY_CREDIT,
} from '../../components/PaymentMethods/constants';
import { trackEventAction } from '../../ducks/Analytics.duck';
import { addCredit, getBalance } from '../../ducks/credit.duck';
import { withRouter } from 'react-router-dom';
import moment from 'moment-timezone/builds/moment-timezone-with-data-10-year-range.min';
import config from '../../config';
import { handlePayment } from '../../ducks/payment.duck';
import { fetchCurrentUser } from '../../ducks/user.duck';
import { updateProfile } from '../../ducks/UserProfile.duck';
import DonationForm from '../../forms/DonationForm/DonationForm';
import routeConfiguration from '../../routeConfiguration';
import { createSecret } from '../../util/credit';
import { pathByRouteName, findRouteByRouteName } from '../../util/routes';
import { getClientSecret, getPaymentParams } from '../../util/stripe';
import {
  propTypes,
  DATE_TYPE_DATETIME,
  ERROR_CODE_TRANSACTION_LISTING_NOT_FOUND,
  ERROR_CODE_TRANSACTION_BOOKING_TIME_NOT_AVAILABLE,
  ERROR_CODE_PAYMENT_FAILED,
  ERROR_CODE_MISSING_STRIPE_ACCOUNT,
  ERROR_CODE_CHARGE_ZERO_PAYIN,
} from '../../util/types';
import {
  ensureListing,
  ensureCurrentUser,
  ensureUser,
  ensureTransaction,
  ensureBooking,
  ensureStripeCustomer,
  ensurePaymentMethodCard,
} from '../../util/data';
import { getNotificationDateTime, minutesBetween } from '../../util/dates';
import { createSlug } from '../../util/urlHelpers';
import {
  isTransactionInitiateAmountTooLowError,
  isTransactionChargeDisabledError,
  transactionInitiateOrderStripeErrors,
  errorAPIErrors,
} from '../../util/errors';
import {
  txIsPaymentPending,
  txIsPaymentExpired,
  getEstimatedTransaction,
} from '../../util/transaction';
import {
  AvatarHuge,
  BookingBreakdown,
  EstimatedBreakdown,
  Logo,
  MobileAvatarHero,
  NamedLink,
  NamedRedirect,
  NeedHelp,
  Page,
} from '../../components';
import { MeetingDetailsForm, PaymentForm, ProposeMeetingForm } from '../../forms';
import { isScrollingDisabled, manageDisableScrolling } from '../../ducks/UI.duck';
import { handleCardPayment, handleCardSetup, retrievePaymentIntent } from '../../ducks/stripe.duck';
import { savePaymentMethod } from '../../ducks/paymentMethods.duck';
import { createStripeSetupIntent } from '../PaymentMethodsPage/PaymentMethodsPage.duck';
import {
  initiateOrder,
  initiateBookingOrder,
  setInitialValues,
  speculateTransaction,
  speculatePaymentTransaction,
  stripeCustomer,
  confirmPayment,
  sendMessage,
  NPOListings,
  applyFeesAndDeductions,
  fetchBlockedSlots,
} from './CheckoutPage.duck';
import {
  storeData,
  storedData,
  clearData,
  generateCardIdentifier,
  canSpeculateTransaction,
} from './CheckoutPageHelpers';
import css from './CheckoutPage.css';
import { withMessages } from '../../util/localization';
import { isSameUser } from '@givsly/sharetribe-utils/src/data/user';
import utils from '@givsly/sharetribe-utils';
import givslyConfig from '@givsly/config';
const {
  calculateBreakdown,
  convertToMoneyBreakdown,
  convertBreakdownToLineItems,
} = utils.transaction;

const STORAGE_KEY = 'CheckoutPage';

// Stripe PaymentIntent statuses, where user actions are already completed
// https://stripe.com/docs/payments/payment-intents/status
const STRIPE_PI_USER_ACTIONS_DONE_STATUSES = ['processing', 'requires_capture', 'succeeded'];

const initializeOrderPage = (initialValues, routes, dispatch) => {
  const OrderPage = findRouteByRouteName('OrderDetailsPage', routes);

  // Transaction is already created, but if the initial message
  // sending failed, we tell it to the OrderDetailsPage.
  dispatch(OrderPage.setInitialValues(initialValues));
};

const checkIsPaymentExpired = (existingTransaction) => {
  return txIsPaymentExpired(existingTransaction)
    ? true
    : txIsPaymentPending(existingTransaction)
    ? minutesBetween(existingTransaction.attributes.lastTransitionedAt, new Date()) >= 15
    : false;
};

export class CheckoutPageComponent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      addCreditCardInProgress: false,
      pageData: {},
      hasSelectedNonprofit: false,
      dataLoaded: false,
      submitting: false,
      meetingDetailsEntered: false,
      meetingDetails: {},
      timeSlots: [],
      updatePaymentMethodInProgress: false,
    };
    this.stripe = null;

    this.handleAddCreditCard = this.handleAddCreditCard.bind(this);
    this.handleChangeNonprofit = this.handleChangeNonprofit.bind(this);
    this.handleChangeDeductFee = this.handleChangeDeductFee.bind(this);
    this.handleChangeQuantity = this.handleChangeQuantity.bind(this);
    this.handleStripeInitialized = this.handleStripeInitialized.bind(this);
    this.loadInitialData = this.loadInitialData.bind(this);
    this.handlePaymentIntent = this.handlePaymentIntent.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleSubmitProposeMeetingDetails = this.handleSubmitProposeMeetingDetails.bind(this);
    this.handleSubmitMeetingDetails = this.handleSubmitMeetingDetails.bind(this);
    this.handleSelectPaymentMethod = this.handleSelectPaymentMethod.bind(this);
  }

  componentDidMount() {
    if (window) {
      this.loadInitialData();
    }
  }

  /**
   * Load initial data for the page
   *
   * Since the data for the checkout is not passed in the URL (there
   * might be lots of options in the future), we must pass in the data
   * some other way. Currently the ListingPage sets the initial data
   * for the CheckoutPage's Redux store.
   *
   * For some cases (e.g. a refresh in the CheckoutPage), the Redux
   * store is empty. To handle that case, we store the received data
   * to window.sessionStorage and read it from there if no props from
   * the store exist.
   *
   * This function also sets of fetching the speculative transaction
   * based on this initial data.
   */
  loadInitialData() {
    const {
      bookingData,
      bookingDates,
      dispatch,
      ensuredListing,
      eventNonprofits = [],
      bookingTransaction,
      paymentTransaction,
      fetchSpeculatedTransaction,
      fetchStripeCustomer,
      fetchNPOListings,
      handleFetchCurrentUser,
      history,
      proposeMeeting,
    } = this.props;

    const defaultPaymentMethod = {};

    // Fetch current user with stripeCustomer entity
    fetchStripeCustomer()
      .then((stripeCustomer) => {
        // Set default card (if any)
        const ensuredStripeCustomer = ensureStripeCustomer(stripeCustomer);
        const { card, stripePaymentMethodId } =
          ensurePaymentMethodCard(ensuredStripeCustomer.defaultPaymentMethod).attributes || {};

        // Set default payment method to credit card if any card has been added to the users account
        if (stripePaymentMethodId) {
          defaultPaymentMethod.selectedPaymentMethod = METHOD_CREDIT_CARD;
          defaultPaymentMethod.selectedPaymentIdentifier = generateCardIdentifier(card);
        }

        return handleFetchCurrentUser();
      })
      .then((currentUser) => {
        // Ensure that the user has a payment API secret
        const ensuredCurrentUser = ensureCurrentUser(currentUser);
        // Check if the paymentSecret has been set, if not then do so
        if (!ensuredCurrentUser.attributes.profile.privateData.paymentSecret) {
          return dispatch(
            updateProfile({
              privateData: {
                paymentSecret: createSecret(),
              },
            })
          ).then(() => handleFetchCurrentUser());
        }
      })
      .then(() => {
        // Get Givsly credit balance, requires current user
        return dispatch(getBalance());
      })
      .then(({ creditTotal } = {}) => {
        // The return is used to determine whether or not the user has credit. If the user has
        // credit the Givsly credit option will be used as a default payment method.
        if (creditTotal > 0) {
          defaultPaymentMethod.selectedPaymentMethod = METHOD_GIVSLY_CREDIT;
          defaultPaymentMethod.selectedPaymentIdentifier = null;
        }
        return Promise.resolve();
      })
      .then(() => {
        // Browser's back navigation should not rewrite data in session store.
        // Action is 'POP' on both history.back() and page refresh cases.
        // Action is 'PUSH' when user has directed through a link
        // Action is 'REPLACE' when user has directed through login/signup process
        const hasNavigatedThroughLink = history.action === 'PUSH' || history.action === 'REPLACE';

        const hasDataInProps = !(
          !hasNavigatedThroughLink ||
          !bookingData ||
          !ensuredListing.id ||
          (!proposeMeeting && !bookingDates)
        );

        if (hasDataInProps) {
          // Store data only if data is passed through props and user has navigated through a link.
          storeData(
            bookingData,
            bookingDates,
            ensuredListing,
            bookingTransaction,
            paymentTransaction,
            STORAGE_KEY,
            defaultPaymentMethod.selectedPaymentMethod,
            defaultPaymentMethod.selectedPaymentIdentifier
          );
        }

        // NOTE: stored data can be empty if user has already successfully completed transaction.
        const pageData = hasDataInProps
          ? {
              bookingData,
              bookingDates,
              listing: ensuredListing,
              bookingTransaction,
              paymentTransaction,
              ...defaultPaymentMethod,
            }
          : storedData(STORAGE_KEY);

        const NPOListingIds =
          pageData.listing && pageData.listing.author
            ? pageData.listing.author.attributes.profile.publicData.supportedNPOs
            : null;

        if (NPOListingIds) {
          fetchNPOListings(NPOListingIds);
        }

        // Check if a booking is already created according to stored data.
        const ensuredTransaction = ensureTransaction(pageData ? pageData.bookingTransaction : null);

        if (!proposeMeeting && canSpeculateTransaction(pageData, ensuredTransaction)) {
          const bookingListingId = pageData.listing.id;
          const { bookingStart, bookingEnd } = pageData.bookingDates;
          const { quantity } = pageData.bookingData;

          fetchSpeculatedTransaction('booking', config.timeBookingProcessAlias, {
            listingId: bookingListingId,
            bookingStart,
            bookingEnd,
            quantity,
          });
        } else if (proposeMeeting && ensuredListing.id) {
          // In case of a proposal, we'll need to fetch the blocked slots. This can be done
          // asynchronous and is even permitted to fail.
          dispatch(fetchBlockedSlots());
        }

        // Preselect the first NPO if event NPOs are available
        if (eventNonprofits.length && eventNonprofits[0].id) {
          this.handleChangeNonprofit(eventNonprofits[0].id.uuid);
        }

        this.setState({ pageData: pageData || {}, dataLoaded: true }, () => Promise.resolve());
      })
      .catch((err) => {
        console.log('ERROR', err);
      });
  }

  /**
   * Handles payment transaction initiation
   *
   * This requires a base of the transaction to be setup manually. The line items need to be pre-
   * defined because at the start of the process we need to determine if any monetary payment is
   * required for this transaction.
   */
  handlePaymentIntent(handlePaymentParams) {
    const { creditTotal, ensuredCurrentUser, onHandlePayment } = this.props;
    const { pageData, speculatedPaymentTransaction } = handlePaymentParams;
    const { methodPriceChoices } = pageData.listing.attributes.publicData;
    const { meetingMethod, deductFee, quantity } = pageData.bookingData;
    const { bookingStart, bookingEnd } = pageData.bookingDates;

    const ensuredStripeCustomer = ensureStripeCustomer(ensuredCurrentUser.stripeCustomer);
    const ensuredDefaultPaymentMethod = ensurePaymentMethodCard(
      ensuredStripeCustomer.defaultPaymentMethod
    );
    const { stripePaymentMethodId = null } = ensuredDefaultPaymentMethod.attributes;
    const paymentTransaction = utils.transaction.ensure(speculatedPaymentTransaction);
    const ensuredListing = utils.listing.ensureListing(pageData.listing);

    // Set transaction props required for the transaction to flow through the payment
    const unitPrice = parseFloat(methodPriceChoices[meetingMethod].split('_').pop()) * 100;

    const operatorPercentage =
      ensuredCurrentUser &&
      ensuredCurrentUser.attributes &&
      ensuredCurrentUser.attributes.profile &&
      ensuredCurrentUser.attributes.profile.metadata &&
      ensuredCurrentUser.attributes.profile.metadata.operatorPercentage;
    const breakdown = calculateBreakdown(
      unitPrice,
      quantity,
      deductFee,
      this.state.pageData.selectedPaymentMethod === METHOD_GIVSLY_CREDIT ? creditTotal : 0,
      operatorPercentage
    );
    paymentTransaction.attributes = {
      ...paymentTransaction.attributes,
      bookingStart,
      bookingEnd,
      listingId: pageData.selectedNPO,
      lineItems: convertBreakdownToLineItems(convertToMoneyBreakdown(breakdown)),
      protectedData: {
        ...paymentTransaction.attributes.protectedData,
        deductFee,
        meetingMethod,
        // @todo verify if this is used anywhere explicitly, if not it can be omitted
        volunteerListingId: ensuredListing.id.uuid,
        volunteerListingName: ensuredListing.attributes.title,
      },
      metadata: {
        breakdown,
      },
    };
    return onHandlePayment(paymentTransaction, this.stripe, stripePaymentMethodId);
  }

  handleBookingIntent(handleBookingParams) {
    const { onInitiateBookingOrder, proposeMeeting } = this.props;
    const {
      pageData,
      speculatedBookingTransaction,
      paymentOrderId,
      meetingDetails,
      paymentListingTitle,
      eventKey,
      creditDeduction,
      creditCardCharge,
      donationValue,
      paymentTotal,
    } = handleBookingParams;

    const storedTx = ensureTransaction(pageData.bookingTransaction);
    const tx = speculatedBookingTransaction ? speculatedBookingTransaction : storedTx;

    if (proposeMeeting) {
      const automaticallyExpiresAt = moment()
        .tz('UTC')
        .add(givslyConfig.transaction.proposal.expiresAfter, 'hours');
      const latestSuggestTime = moment
        .tz(
          pageData.bookingData.suggestedTimes.reduce((a, c) => (a < c ? c : a)),
          'UTC'
        )
        .add(-givslyConfig.transaction.proposal.timeSlotBookableOffset, 'hours');
      const duration = pageData.bookingData ? pageData.bookingData.quantity * 15 : null;

      // Booking proposal transaction
      const orderParams = {
        listingId: pageData.listing.id,
        protectedData: {
          deductFee: pageData.bookingData.deductFee,
          donationValue,
          meetingDetails,
          meetingMethod: pageData.bookingData.meetingMethod,
          notification: {
            creditCardCharge: creditCardCharge > 0 ? (creditCardCharge / 100).toFixed(2) : null,
            creditDeduction: creditDeduction > 0 ? (creditDeduction / 100).toFixed(2) : null,
            donation: (donationValue / 100).toFixed(2),
            duration: duration,
            nonprofit: paymentListingTitle,
            expiresAt: getNotificationDateTime(
              latestSuggestTime.isBefore(automaticallyExpiresAt)
                ? latestSuggestTime
                : automaticallyExpiresAt
            ),
            paymentTotal: (paymentTotal / 100).toFixed(2),
            suggestedTimes: pageData.bookingData.suggestedTimes.map((d) => {
              const m = moment.tz(d, 'UTC');
              return {
                start: getNotificationDateTime(m),
                end: getNotificationDateTime(m.clone().add(duration, 'minutes')),
              };
            }),
          },
          paymentListingId: pageData.selectedNPO,
          paymentListingTitle,
          paymentOrderId,
          quantity: pageData.bookingData ? pageData.bookingData.quantity : null,
          suggestedTimes: pageData.bookingData.suggestedTimes.map((x) => x.toISOString()),
        },
      };

      return onInitiateBookingOrder('proposal', orderParams, storedTx.id);
    } else {
      // Booking transaction
      const orderParams = {
        listingId: pageData.listing.id,
        bookingStart: tx.booking.attributes.start,
        bookingEnd: tx.booking.attributes.end,
        quantity: pageData.bookingData ? pageData.bookingData.quantity : null,
        protectedData: {
          meetingMethod: pageData.bookingData.meetingMethod,
          deductFee: pageData.bookingData.deductFee,
          paymentOrderId,
          meetingDetails,
          paymentListingId: pageData.selectedNPO,
          paymentListingTitle,
          eventKey,
          donationValue,
          notification: {
            creditCardCharge: creditCardCharge > 0 ? (creditCardCharge / 100).toFixed(2) : null,
            creditDeduction: creditDeduction > 0 ? (creditDeduction / 100).toFixed(2) : null,
            donation: (donationValue / 100).toFixed(2),
            paymentTotal: (paymentTotal / 100).toFixed(2),
          },
        },
      };

      return onInitiateBookingOrder('booking', orderParams, storedTx.id);
    }
  }

  handleSubmitProposeMeetingDetails(values) {
    const { onUpdateProfile, timezone } = this.props;
    const { suggestedTimes, initialMessage, keywords, saveMessage = 0 } = values;

    const { quantity } = this.state.pageData.bookingData;

    const timeSlot = config.custom.timeSlotDurations[quantity - 1];

    const bookingStart = suggestedTimes[0];
    const bookingEnd = moment.tz(bookingStart, timezone).add(timeSlot, 'minutes').toDate();

    this.setState(
      (prevState) => ({
        meetingDetailsEntered: true,
        meetingDetails: {
          initialMessage,
          keywords,
        },
        pageData: {
          ...prevState.pageData,
          bookingData: {
            ...prevState.pageData.bookingData,
            suggestedTimes,
          },
          bookingDates: {
            bookingStart,
            bookingEnd,
          },
        },
      }),
      () => {
        const { fetchSpeculatedTransaction } = this.props;
        const { pageData } = this.state;

        const bookingListingId = pageData.listing.id;
        const { quantity } = pageData.bookingData;

        // Store the default initial message already at this stage, this can happen asynchronously
        if (saveMessage) {
          onUpdateProfile({ privateData: { defaultInitialMessage: initialMessage } }).catch(
            console.error
          );
        }

        fetchSpeculatedTransaction('proposal', config.timeBookingProcessAlias, {
          listingId: bookingListingId,
          quantity,
        });
      }
    );
  }

  handleSubmitMeetingDetails(values) {
    const { initialMessage = '', saveMessage = [] } = values.formValues;

    if (saveMessage.length) {
      this.props
        .onUpdateProfile({ privateData: { defaultInitialMessage: initialMessage } })
        .catch(console.error);
    }
    this.setState({
      meetingDetailsEntered: true,
      meetingDetails: values && values.formValues ? values.formValues : null,
    });
  }

  handleSubmit(values) {
    if (this.state.submitting) {
      return;
    }
    this.setState({ submitting: true });

    const {
      history,
      speculatedPaymentTransaction,
      speculatedBookingTransaction,
      ensuredCurrentUser,
      paymentIntent,
      dispatch,
      npoListingChoices,
      onTrackEvent,
    } = this.props;

    const { card, paymentMethod, formValues } = values;
    const {
      name,
      addressLine1,
      addressLine2,
      postal,
      city,
      state,
      country,
      saveAfterOnetimePayment,
    } = formValues;

    // Billing address is recommended.
    // However, let's not assume that <StripePaymentAddress> data is among formValues.
    // Read more about this from Stripe's docs
    // https://stripe.com/docs/stripe-js/reference#stripe-handle-card-payment-no-element
    const addressMaybe =
      addressLine1 && postal
        ? {
            address: {
              city: city,
              country: country,
              line1: addressLine1,
              line2: addressLine2,
              postal_code: postal,
              state: state,
            },
          }
        : {};
    const billingDetails = {
      name,
      email: ensuredCurrentUser.attributes.email,
      ...addressMaybe,
    };

    const requestPaymentParams = {
      pageData: this.state.pageData,
      speculatedPaymentTransaction,
      stripe: this.stripe,
      card,
      billingDetails,
      paymentIntent,
      selectedPaymentMethod: paymentMethod,
      saveAfterOnetimePayment: !!saveAfterOnetimePayment,
    };

    this.handlePaymentIntent(requestPaymentParams)
      .then((ensuredTransaction) => {
        const donationValue = Math.round(ensuredTransaction.attributes.metadata.breakdown.donation);

        let paymentListingTitle = null;
        if (npoListingChoices.length) {
          const selectedNPOListing = npoListingChoices.filter(
            (l) => l.id.uuid === this.state.pageData.selectedNPO
          );
          if (selectedNPOListing.length === 1) {
            paymentListingTitle = selectedNPOListing[0].attributes.title;
          }
        }

        const requestBookingParams = {
          pageData: this.state.pageData,
          speculatedBookingTransaction,
          paymentOrderId: ensuredTransaction.id.uuid,
          paymentListingTitle,
          meetingDetails: this.state.meetingDetails,
          eventKey: this.props.event ? this.props.event.key : null,
          donationValue,
          creditDeduction: ensuredTransaction.attributes.metadata.breakdown.creditDeduction,
          creditCardCharge: ensuredTransaction.attributes.metadata.breakdown.total,
          paymentTotal: ensuredTransaction.attributes.metadata.breakdown.subTotal,
        };

        this.handleBookingIntent(requestBookingParams)
          .then((bookingRes) => {
            const bookingOrderId = bookingRes.id;
            this.setState({ submitting: false });

            const routes = routeConfiguration();
            // const initialMessageFailedToTransaction = bookingMessageSuccess ? null : bookingOrderId;
            const orderDetailsPath = pathByRouteName('OrderDetailsPage', routes, {
              id: bookingOrderId.uuid,
            });
            const initialValues = {
              // initialMessageFailedToTransaction,
              savePaymentMethodFailed: false,
            };

            initializeOrderPage(initialValues, routes, dispatch);
            clearData(STORAGE_KEY);
            history.push(orderDetailsPath);
          })
          .catch((err) => {
            console.log('handlebooking error', err);
            this.setState({ submitting: false });
          });
      })
      .then(() => {
        // Track event data for meeting proposals
        const { suggestedTimes = [] } = this.state.bookingData;
        if (suggestedTimes.length) {
          onTrackEvent({
            category: 'Propose meeting',
            action: 'Submit',
            label: 'Payment',
          });
        }
      })
      .catch((err) => {
        console.log('handlepayment error', err);
        this.setState({ submitting: false });
      });
  }

  handleStripeInitialized(stripe) {
    this.stripe = stripe;

    const { paymentIntent, onRetrievePaymentIntent } = this.props;
    const tx = this.state.pageData ? this.state.pageData.transaction : null;

    // We need to get up to date PI, if booking is created but payment is not expired.
    const shouldFetchPaymentIntent =
      this.stripe &&
      !paymentIntent &&
      tx &&
      tx.id &&
      tx.booking &&
      tx.booking.id &&
      txIsPaymentPending(tx) &&
      !checkIsPaymentExpired(tx);

    if (shouldFetchPaymentIntent) {
      const { stripePaymentIntentClientSecret } =
        tx.attributes.protectedData && tx.attributes.protectedData.stripePaymentIntents
          ? tx.attributes.protectedData.stripePaymentIntents.default
          : {};

      // Fetch up to date PaymentIntent from Stripe
      onRetrievePaymentIntent({ stripe, stripePaymentIntentClientSecret });
    }
  }

  // @todo simplify page data merger/extension. this is repetitive
  handleChangeNonprofit(uuid) {
    const { fetchSpeculatedPaymentTransaction } = this.props;
    this.setState(
      (prevState) => ({
        hasSelectedNonprofit: true,
        pageData: {
          ...prevState.pageData,
          selectedNPO: uuid,
        },
      }),
      () => {
        fetchSpeculatedPaymentTransaction(this.state.pageData);
      }
    );
  }

  // @todo simplify object mergers
  handleChangeDeductFee() {
    const { fetchSpeculatedPaymentTransaction } = this.props;

    this.setState(
      (prevState) => ({
        pageData: {
          ...prevState.pageData,
          bookingData: {
            ...prevState.pageData.bookingData,
            deductFee: !prevState.pageData.bookingData.deductFee,
          },
        },
      }),
      () => {
        fetchSpeculatedPaymentTransaction(this.state.pageData);
      }
    );
  }

  // @todo simplify object mergers
  handleChangeQuantity(quantity) {
    const { fetchSpeculatedPaymentTransaction, onTrackEvent } = this.props;

    onTrackEvent({
      category: 'Propose meeting',
      action: 'Select',
      label: 'Change meeting duration',
      value: quantity * 15,
    });

    this.setState(
      (prevState) => ({
        pageData: {
          ...prevState.pageData,
          bookingData: {
            ...prevState.pageData.bookingData,
            quantity,
          },
        },
      }),
      () => {
        fetchSpeculatedPaymentTransaction(this.state.pageData);
      }
    );
  }

  handleSelectPaymentMethod(selectedMethod, selectedIdentifier) {
    const { fetchSpeculatedPaymentTransaction } = this.props;
    this.setState(
      {
        pageData: {
          ...this.state.pageData,
          selectedPaymentMethod: selectedMethod,
          selectedPaymentIdentifier: selectedIdentifier,
        },
        updatePaymentMethodInProgress: true,
      },
      () => {
        fetchSpeculatedPaymentTransaction(this.state.pageData).then(() => {
          this.setState({
            updatePaymentMethodInProgress: false,
          });
        });
      }
    );
  }

  handleAddCreditCard = (params) => {
    this.setState({ addCreditCardInProgress: true });

    const {
      ensuredCurrentUser,
      fetchStripeCustomer,
      onCreateSetupIntent,
      onHandleCardSetup,
      onSavePaymentMethod,
    } = this.props;
    const stripeCustomer = ensuredCurrentUser.stripeCustomer;
    const { stripe, card, formValues } = params;

    return onCreateSetupIntent()
      .then((setupIntent) => {
        const stripeParams = {
          stripe,
          card,
          setupIntentClientSecret: getClientSecret(setupIntent),
          paymentParams: getPaymentParams(ensuredCurrentUser, formValues),
        };

        return onHandleCardSetup(stripeParams);
      })
      .then((result) => {
        const newPaymentMethod = result.setupIntent.payment_method;
        // Note: stripe.handleCardSetup might return an error inside successful call (200), but those are rejected in thunk functions.

        return onSavePaymentMethod(stripeCustomer, newPaymentMethod);
      })
      .then(() => {
        // Update currentUser entity and its sub entities: stripeCustomer and defaultPaymentMethod
        return fetchStripeCustomer();
      })
      .then((fetchedStripeCustomer) => {
        // Un-flag the save in progress once the new cards have been fetched (this way the form
        // won't flicker in and out of existence).
        const ensuredFetchedStripeCustomer = ensureStripeCustomer(fetchedStripeCustomer);
        const ensuredPaymentMethodCard = ensurePaymentMethodCard(
          ensuredFetchedStripeCustomer.defaultPaymentMethod
        );
        const { card } = ensuredPaymentMethodCard.attributes;

        this.setState({
          addCreditCardInProgress: false,
          pageData: {
            ...this.state.pageData,
            selectedPaymentMethod: METHOD_CREDIT_CARD,
            selectedPaymentIdentifier: generateCardIdentifier(card),
          },
        });
      })
      .catch((error) => {
        console.error(error);
        this.setState({ addCreditCardInProgress: false });
      });
  };

  getTransactionErrorMessageProps(error) {
    switch (typeof error === 'object' ? error.code : null) {
      case ERROR_CODE_MISSING_STRIPE_ACCOUNT:
        return { id: 'providerStripeAccountMissingError' };
      case ERROR_CODE_TRANSACTION_BOOKING_TIME_NOT_AVAILABLE:
        return { id: 'bookingTimeNotAvailableMessage' };
      case ERROR_CODE_CHARGE_ZERO_PAYIN:
        return { id: 'initiateOrderAmountTooLow' };
      default:
        return { id: error && error.code ? 'speculateFailedMessage' : null };
    }
  }

  get unitPrice() {
    const { publicData } = this.state.pageData.listing.attributes;
    const methodPriceChoices = publicData['methodPriceChoices']
      ? publicData['methodPriceChoices']
      : {};
    const { meetingMethod } = this.state.pageData.bookingData;
    return parseFloat(methodPriceChoices[meetingMethod].split('_').pop()) * 100;
  }

  get bookingBreakdown() {
    const {
      creditTotal,
      ensuredListing,
      ensuredTransaction,
      proposeMeeting,
      timezone,
    } = this.props;
    const { bookingData = {}, bookingDates = {}, selectedPaymentMethod } = this.state.pageData;
    const { deductFee } = bookingData;
    const { methodPriceChoices } = ensuredListing.attributes.publicData;
    const ensuredBooking = ensureBooking(ensuredTransaction.booking);

    if (ensuredTransaction.id && ensuredBooking.id) {
      return (
        <div className={css.bookingBreakdownWrapper}>
          <BookingBreakdown
            userRole="customer"
            unitType={config.bookingUnitType}
            transaction={ensuredTransaction}
            booking={ensuredBooking}
            dateType={DATE_TYPE_DATETIME}
            isProposal={proposeMeeting}
            timezone={timezone}
            deductFee={deductFee}
            onDeductFeeChange={this.handleChangeDeductFee}
          />
        </div>
      );
    } else if (this.state.pageData.bookingData) {
      const bookingData = {
        ...this.state.pageData.bookingData,
        startDate: bookingDates && moment.tz(bookingDates.bookingStart, timezone),
        endDate: bookingDates && moment.tz(bookingDates.bookingEnd, timezone),
        timezone,
        methodPriceChoices,
      };

      return (
        <div className={css.bookingBreakdownWrapper}>
          <EstimatedBreakdown
            applyCredit={selectedPaymentMethod === METHOD_GIVSLY_CREDIT}
            bookingData={bookingData}
            className={css.receipt}
            creditTotal={creditTotal}
            deductFee={deductFee}
            isProposal={proposeMeeting}
            meetingDuration={(bookingData.quantity | 0) * 15}
            onDeductFeeChange={this.handleChangeDeductFee}
            selectedPaymentMethod={selectedPaymentMethod}
            showBookingDetails={true}
            suggestedTimes={bookingData.suggestedTimes}
            timezone={timezone}
          />
        </div>
      );
    }
    return null;
  }

  get errorMessage() {
    const { getMessage } = this.props;
    const errors = [];

    const initiationError = this.initiationError;
    if (initiationError) errors.push(initiationError);
    const paymentError = this.paymentError;
    if (paymentError) errors.push(paymentError);
    const bookingError = this.bookingError;
    if (bookingError) errors.push(bookingError);

    if (errors.length > 0) {
      const { id, values = null } = errors[0];
      return id ? <p className={css.orderError}>{getMessage(id, values)}</p> : null;
    }
    return null;
  }

  get initiationError() {
    const { initiateOrderError } = this.props;
    const initiationError = errorAPIErrors(initiateOrderError).shift();
    const stripeErrors = transactionInitiateOrderStripeErrors(initiateOrderError);

    switch (typeof initiationError === 'object' ? initiationError.code : null) {
      case ERROR_CODE_TRANSACTION_LISTING_NOT_FOUND:
        return { id: 'listingNotFoundError' };
      case ERROR_CODE_TRANSACTION_BOOKING_TIME_NOT_AVAILABLE:
        return { id: 'bookingTimeNotAvailableMessage' };
      case ERROR_CODE_PAYMENT_FAILED:
        if (isTransactionInitiateAmountTooLowError(initiationError)) {
          return { id: 'initiateOrderAmountTooLow' };
        } else if (isTransactionChargeDisabledError(initiationError)) {
          return { id: 'chargeDisabledMessage' };
        } else if (stripeErrors && stripeErrors.length) {
          return {
            id: 'initiateOrderStripeError',
            values: {
              stripeErrors: stripeErrors.join(', '),
            },
          };
        } else {
          return { id: 'initiateOrderError', values: { listingLink: this.listingLink } };
        }
      default:
        return null;
    }
  }

  get listingLink() {
    const { ensuredListing, getMessage } = this.props;
    return (
      <NamedLink
        name="ListingPage"
        params={{ id: ensuredListing.id.uuid, slug: createSlug(ensuredListing.attributes.title) }}
      >
        {getMessage('errorlistingLinkText')}
      </NamedLink>
    );
  }

  get bookingError() {
    const { speculateBookingTransactionError } = this.props;
    const bookingError = errorAPIErrors(speculateBookingTransactionError).shift();
    return this.getTransactionErrorMessageProps(bookingError);
  }

  get paymentError() {
    const { speculatePaymentTransactionError } = this.props;
    const paymentError = errorAPIErrors(speculatePaymentTransactionError).shift();
    return this.getTransactionErrorMessageProps(paymentError);
  }

  get pageProps() {
    const { ensuredListing, getMessage, scrollingDisabled } = this.props;
    const { title } = ensuredListing.attributes;
    return {
      scrollingDisabled,
      title: title ? title : getMessage('title', { listingTitle: title }),
    };
  }

  get topBar() {
    const label = this.props.getMessage('goToLandingPage');
    return (
      <div className={css.topbar}>
        <NamedLink className={css.home} name="LandingPage">
          <Logo className={css.logoMobile} title={label} format="mobile" />
          <Logo className={css.logoDesktop} alt={label} format="desktop" />
        </NamedLink>
      </div>
    );
  }

  get dueAmount() {
    const {
      creditTotal,
      deductFee,
      ensuredListing,
      speculatedPaymentTransactionMaybe,
      ensuredCurrentUser,
    } = this.props;
    const speculatedPaymentTransaction = ensureTransaction(
      speculatedPaymentTransactionMaybe,
      {},
      null
    );

    if (speculatedPaymentTransaction.attributes.lineItems.length) {
      // If the transaction has already been speculated then the sum can retrieved from there.
      const { payinTotal } = speculatedPaymentTransaction.attributes;
      return typeof payinTotal === 'object' ? payinTotal.amount : 0;
    } else {
      // If there is no speculated transaction it must be estimated. This will be the same value as
      // is depicted inside the booking breakdown.
      const { meetingMethod, quantity } = this.state.pageData.bookingData;
      const { bookingStart: startDate, bookingEnd: endDate } = this.state.pageData.bookingDates;
      const { methodPriceChoices } = ensuredListing.attributes.publicData;

      const operatorPercentage =
        ensuredCurrentUser &&
        ensuredCurrentUser.attributes &&
        ensuredCurrentUser.attributes.profile &&
        ensuredCurrentUser.attributes.profile.metadata &&
        ensuredCurrentUser.attributes.profile.metadata.operatorPercentage;

      const estimatedTransaction = getEstimatedTransaction(
        moment(startDate),
        moment(endDate),
        meetingMethod,
        methodPriceChoices,
        quantity,
        deductFee,
        this.state.pageData.selectedPaymentMethod === METHOD_GIVSLY_CREDIT ? creditTotal : 0,
        operatorPercentage
      );
      return estimatedTransaction.attributes.payinTotal.amount;
    }
  }

  render() {
    // @todo sort and clean
    const {
      blockedSlots,
      confirmPaymentError,
      creditTotal,
      ensuredCurrentUser,
      ensuredListing,
      event,
      eventNonprofits,
      handleCardPaymentError,
      initiateOrderError,
      stripeCustomerFetched,
      timezone,
      proposeMeeting,
      onAddCredit,
      onGetBalance,
      speculateBookingTransactionInProgress,
      speculatedBookingTransaction: speculatedBookingTransactionMaybe,
      speculatedPaymentTransaction: speculatedPaymentTransactionMaybe,
      onManageDisableScrolling,
      onTrackEvent,
      getMessage,
      params,
      paymentIntent,
      retrievePaymentIntentError,
      npoListingChoices,
    } = this.props;

    // const isLoading = !this.state.dataLoaded || speculateBookingTransactionInProgress || speculatePaymentTransactionInProgress;
    const isLoading = !this.state.dataLoaded || speculateBookingTransactionInProgress;
    const errorMessage = this.errorMessage;

    const { listing, bookingDates, ensuredTransaction, selectedNPO } = this.state.pageData;

    const speculatedBookingTransaction = ensureTransaction(
      speculatedBookingTransactionMaybe,
      {},
      null
    );
    const speculatedPaymentTransaction = ensureTransaction(
      speculatedPaymentTransactionMaybe,
      {},
      null
    );
    const ensuredAuthor = ensureUser(ensuredListing.author);
    const { companyName, firstName, jobTitle, interests } = ensuredListing.attributes.publicData;
    const { defaultInitialMessage } = ensuredCurrentUser.attributes.profile.privateData;
    const { title } = ensuredListing.attributes;

    if (isLoading) {
      return <Page {...this.pageProps}>{this.topBar}</Page>;
    }

    // Flags
    const isOwnListing = isSameUser(ensuredCurrentUser, ensuredAuthor);
    const hasListingAndAuthor = !!(ensuredListing.id && ensuredAuthor.id);
    const hasBookingDates = !!(
      bookingDates &&
      bookingDates.bookingStart &&
      bookingDates.bookingEnd
    );

    // Redirect back to ListingPage if data is missing.
    // Redirection must happen before any data format error is thrown (e.g. wrong currency)
    if (
      !isLoading &&
      (!hasListingAndAuthor || isOwnListing || (!proposeMeeting && !hasBookingDates))
    ) {
      // eslint-disable-next-line no-console
      console.error('Missing or invalid data for checkout, redirecting back to listing page.', {
        bookingTransaction: speculatedBookingTransaction,
        paymentTransaction: speculatedPaymentTransaction,
        bookingDates,
        listing,
      });
      return <NamedRedirect name="ListingPage" params={params} />;
    }

    const isPaymentExpired = checkIsPaymentExpired(ensuredTransaction);

    // If paymentIntent status is not waiting user action,
    // handleCardPayment has been called previously.
    const hasPaymentIntentUserActionsDone =
      paymentIntent && STRIPE_PI_USER_ACTIONS_DONE_STATUSES.includes(paymentIntent.status);

    let npoListingChoicesAll = [...npoListingChoices];
    if (event && eventNonprofits && eventNonprofits.length > 0) {
      const eventNonprofitsUuids = eventNonprofits.map((npo) => {
        const ensuredNonprofit = ensureListing(npo);
        return ensuredNonprofit.id ? ensuredNonprofit.id.uuid : null;
      });
      remove(npoListingChoicesAll, (listing) => eventNonprofitsUuids.includes(listing.id.uuid)); // Remove possible duplicate listings

      const eventNonprofitsChoices = eventNonprofits.map((npo) => ({
        ...npo,
        eventTitle: event.title,
      }));
      npoListingChoicesAll = [...eventNonprofitsChoices, ...npoListingChoicesAll];
    }

    // Credit card
    const ensuredStripeCustomer = ensureStripeCustomer(ensuredCurrentUser.stripeCustomer);
    const ensuredPaymentMethodCard = ensuredStripeCustomer.defaultPaymentMethod;
    const creditCards =
      ensuredCurrentUser.id &&
      ensuredStripeCustomer.attributes.stripeCustomerId &&
      ensuredPaymentMethodCard &&
      ensuredPaymentMethodCard.id
        ? [ensuredPaymentMethodCard.attributes.card]
        : [];
    const ensuredDefaultPaymentMethod = ensurePaymentMethodCard(
      ensuredStripeCustomer.defaultPaymentMethod
    );

    const hasDefaultPaymentMethod = !!(
      stripeCustomerFetched &&
      ensuredStripeCustomer.attributes.stripeCustomerId &&
      ensuredDefaultPaymentMethod.id
    );

    const { deductFee, quantity } = this.state.pageData.bookingData;
    const operatorPercentage =
      ensuredCurrentUser &&
      ensuredCurrentUser.attributes &&
      ensuredCurrentUser.attributes.profile &&
      ensuredCurrentUser.attributes.profile.metadata &&
      ensuredCurrentUser.attributes.profile.metadata.operatorPercentage;
    const breakdown = calculateBreakdown(
      this.unitPrice,
      quantity,
      deductFee,
      creditTotal,
      operatorPercentage
    );

    return (
      <Page {...this.pageProps}>
        {this.topBar}
        <div className={css.contentContainer}>
          <MobileAvatarHero user={ensuredAuthor} />
          <div className={css.bookListingContainer}>
            <div className={css.heading}>
              <h1 className={css.title}>
                {getMessage(proposeMeeting ? 'proposeAMeeting' : 'requestAMeeting')}
              </h1>
              <div className={css.author}>{getMessage('with', { name: title })}</div>
            </div>
            {this.state.meetingDetailsEntered ? (
              <>
                <div className={css.NPOChoicesContainer}>
                  <h2 className={css.NPORecipientHeading}>{getMessage('nonprofitRecipient')}</h2>
                  <p className={css.NPORecipientDescription}>
                    {getMessage('nonprofitDescription', { name: firstName })}
                  </p>
                  <DonationForm
                    nonprofits={npoListingChoicesAll}
                    onChange={this.handleChangeNonprofit}
                    selectedNonprofit={selectedNPO}
                  />
                </div>
                <section className={css.paymentContainer}>
                  {this.errorMessage}
                  {retrievePaymentIntentError ? (
                    <p className={css.orderError}>
                      {getMessage('retrievingStripePaymentIntentFailed', {
                        listingLink: this.listingLink,
                      })}
                    </p>
                  ) : null}
                  {speculatedPaymentTransaction.attributes.payinTotal ||
                  this.state.updatePaymentMethodInProgress ? (
                    <PaymentForm
                      addCreditCardInProgress={this.state.addCreditCardInProgress}
                      breakdown={breakdown}
                      className={css.paymentForm}
                      creditCards={creditCards}
                      creditTotal={creditTotal}
                      dueAmount={this.dueAmount}
                      ensuredCurrentUser={ensuredCurrentUser}
                      hasSelectedNonprofit={this.state.hasSelectedNonprofit}
                      inProgress={this.state.submitting}
                      formId="CheckoutPagePaymentForm"
                      paymentInfo={getMessage('paymentInfo', {
                        faqLink: <NamedLink name={'FaqPage'}>FAQ</NamedLink>,
                      }).toString()}
                      initialValues={{}}
                      initiateOrderError={initiateOrderError}
                      isProposal={proposeMeeting}
                      handleCardPaymentError={handleCardPaymentError}
                      confirmPaymentError={confirmPaymentError}
                      hasHandledCardPayment={hasPaymentIntentUserActionsDone}
                      loadingData={!stripeCustomerFetched}
                      defaultPaymentMethod={
                        hasDefaultPaymentMethod ? ensuredDefaultPaymentMethod : null
                      }
                      paymentIntent={paymentIntent}
                      priceBreakdown={
                        <div className={css.priceBreakdownContainer}>
                          <h4 className={css.bookingSummary}>{getMessage('bookingSummary')}</h4>
                          {errorMessage}
                          {this.bookingBreakdown}
                        </div>
                      }
                      onStripeInitialized={this.handleStripeInitialized}
                      onAddCredit={onAddCredit}
                      onAddCreditCard={this.handleAddCreditCard}
                      onGetBalance={onGetBalance}
                      onSelectPaymentMethod={this.handleSelectPaymentMethod}
                      onSubmit={this.handleSubmit}
                      onManageDisableScrolling={onManageDisableScrolling}
                      selectedPaymentIdentifier={this.state.pageData.selectedPaymentIdentifier}
                      selectedPaymentMethod={this.state.pageData.selectedPaymentMethod}
                    />
                  ) : null}
                  {isPaymentExpired ? (
                    <p className={css.orderError}>
                      {getMessage('paymentExpiredMessage', { listingLink: this.listingLink })}
                    </p>
                  ) : null}
                </section>
              </>
            ) : proposeMeeting ? (
              <ProposeMeetingForm
                authorFirstName={firstName}
                authorInterests={interests}
                blockedSlots={blockedSlots}
                defaultInitialMessage={defaultInitialMessage}
                formId="CheckoutProposeMeetingForm"
                inProgress={this.state.submitting}
                onQuantityChange={this.handleChangeQuantity}
                onSubmit={this.handleSubmitProposeMeetingDetails}
                onTrackEvent={onTrackEvent}
                reservableSlots={ensuredListing.attributes.publicData.reservableSlots}
                timezone={timezone}
                timeSlots={this.state.timeSlots}
                unitPrice={this.unitPrice}
              />
            ) : (
              <MeetingDetailsForm
                onSubmit={this.handleSubmitMeetingDetails}
                inProgress={this.state.submitting}
                defaultInitialMessage={defaultInitialMessage}
                formId="CheckoutPageMeetingDetailsForm"
                authorFirstName={firstName}
                authorInterests={interests}
              />
            )}
            <NeedHelp />
          </div>
          <div className={css.detailsContainerDesktop}>
            <div className={css.avatarWrapper}>
              <AvatarHuge user={ensuredAuthor} />
            </div>
            <div className={css.detailsHeadings}>
              <h2 className={css.detailsTitle}>{title}</h2>
              <div className={css.detailsSubtitle}>{jobTitle}</div>
              <div className={css.detailsSubtitle}>{companyName}</div>
            </div>
            {errorMessage}
            {this.bookingBreakdown}
          </div>
        </div>
      </Page>
    );
  }
}

CheckoutPageComponent.defaultProps = {
  blockedSlots: [],
  initiateOrderError: null,
  confirmPaymentError: null,
  proposeMeeting: false,
  bookingData: {},
  bookingDates: null,
  speculateBookingTransactionError: null,
  speculatedBookingTransaction: null,
  speculatePaymentTransactionError: null,
  speculatedPaymentTransaction: null,
  transaction: null,
  paymentIntent: null,
  npoListingChoices: [],
  eventNonprofits: [],
};

CheckoutPageComponent.propTypes = {
  blockedSlots: array,
  scrollingDisabled: bool.isRequired,
  ensuredListing: object,
  bookingData: object,
  bookingDates: shape({
    bookingStart: instanceOf(Date).isRequired,
    bookingEnd: instanceOf(Date).isRequired,
  }),
  event: propTypes.event,
  eventNonprofits: arrayOf(propTypes.listing),
  fetchStripeCustomer: func.isRequired,
  fetchNPOListings: func.isRequired,
  getMessage: func.isRequired,
  stripeCustomerFetched: bool.isRequired,
  fetchSpeculatedTransaction: func.isRequired,
  speculateBookingTransactionInProgress: bool.isRequired,
  speculateBookingTransactionError: propTypes.error,
  speculatedBookingTransaction: propTypes.transaction,
  speculatePaymentTransactionInProgress: bool.isRequired,
  speculatePaymentTransactionError: propTypes.error,
  speculatedPaymentTransaction: propTypes.transaction,
  ensuredTransaction: object,
  ensuredCurrentUser: object,
  params: shape({
    id: string,
    slug: string,
  }).isRequired,
  onAddCredit: func.isRequired,
  proposeMeeting: bool,
  onConfirmPayment: func.isRequired,
  onInitiateOrder: func.isRequired,
  onHandleCardPayment: func.isRequired,
  onManageDisableScrolling: func.isRequired,
  onRetrievePaymentIntent: func.isRequired,
  onSavePaymentMethod: func.isRequired,
  onSendMessage: func.isRequired,
  onTrackEvent: func.isRequired,
  onUpdateProfile: func.isRequired,
  initiateOrderError: propTypes.error,
  confirmPaymentError: propTypes.error,
  handleCardPaymentError: oneOfType([propTypes.error, object]),
  paymentIntent: object,
  npoListingChoices: array.isRequired,
  dispatch: func.isRequired,
  history: shape({
    push: func.isRequired,
  }).isRequired,
  timezone: string.isRequired,
};

const mapStateToProps = (state) => {
  const {
    listing,
    blockedSlots,
    bookingData,
    bookingDates,
    event,
    eventNonprofits,
    stripeCustomerFetched,
    speculateBookingTransactionInProgress,
    speculateBookingTransactionError,
    speculatedBookingTransaction,
    speculatePaymentTransactionInProgress,
    speculatePaymentTransactionError,
    speculatedPaymentTransaction,
    transaction,
    initiateOrderError,
    confirmPaymentError,
    npoListingChoices,
  } = state.CheckoutPage;
  const { currentUser, timezone } = state.user;
  const { handleCardPaymentError, paymentIntent, retrievePaymentIntentError } = state.stripe;
  const { getBalanceError, getBalanceInProgress, creditTotal } = state.credit;

  return {
    blockedSlots,
    bookingData,
    bookingDates,
    confirmPaymentError,
    creditTotal,
    ensuredCurrentUser: ensureCurrentUser(currentUser),
    ensuredListing: ensureListing(listing),
    ensuredTransaction: ensureTransaction(transaction),
    event,
    eventNonprofits,
    getBalanceError,
    getBalanceInProgress,
    handleCardPaymentError,
    initiateOrderError,
    npoListingChoices,
    paymentIntent,
    retrievePaymentIntentError,
    scrollingDisabled: isScrollingDisabled(state),
    speculateBookingTransactionInProgress,
    speculateBookingTransactionError,
    speculatedBookingTransaction,
    speculatePaymentTransactionInProgress,
    speculatePaymentTransactionError,
    speculatedPaymentTransaction,
    stripeCustomerFetched,
    timezone,
  };
};

const mapDispatchToProps = (dispatch) => ({
  dispatch,
  handleFetchCurrentUser: () => dispatch(fetchCurrentUser()),
  fetchSpeculatedTransaction: (type, processAlias, params) =>
    dispatch(speculateTransaction(type, processAlias, params)),
  fetchSpeculatedPaymentTransaction: (pageData) => dispatch(speculatePaymentTransaction(pageData)),
  fetchStripeCustomer: () => dispatch(stripeCustomer()),
  fetchNPOListings: (listingIds) => dispatch(NPOListings(listingIds)),
  onAddCredit: (code) => dispatch(addCredit(code)),
  onGetBalance: () => dispatch(getBalance()),
  onCreateSetupIntent: (params) => dispatch(createStripeSetupIntent(params)),
  onHandleCardSetup: (params) => dispatch(handleCardSetup(params)),
  onHandlePayment: (ensuredTransaction, stripe, stripePaymentMethodId) => {
    return dispatch(handlePayment(ensuredTransaction, stripe, stripePaymentMethodId));
  },
  onInitiateOrder: (params, transactionId) => dispatch(initiateOrder(params, transactionId)),
  onInitiateBookingOrder: (params, transactionId) =>
    dispatch(initiateBookingOrder(params, transactionId)),
  onManageDisableScrolling: (componentId, disableScrolling) =>
    dispatch(manageDisableScrolling(componentId, disableScrolling)),
  onRetrievePaymentIntent: (params) => dispatch(retrievePaymentIntent(params)),
  onHandleCardPayment: (params) => dispatch(handleCardPayment(params)),
  onConfirmPayment: (params) => dispatch(confirmPayment(params)),
  onApplyFeesAndDeductions: (ensuredCurrentUser, transactionId, deductFee, applyCredit) =>
    dispatch(applyFeesAndDeductions(ensuredCurrentUser, transactionId, deductFee, applyCredit)),
  onSendMessage: (params) => dispatch(sendMessage(params)),
  onSavePaymentMethod: (stripeCustomer, stripePaymentMethodId) =>
    dispatch(savePaymentMethod(stripeCustomer, stripePaymentMethodId)),
  onTrackEvent: (params) => dispatch(trackEventAction(params)),
  onUpdateProfile: (data) => dispatch(updateProfile(data)),
});

const withLocalizedMessages = (component) => {
  return withMessages(component, 'CheckoutPage');
};

const CheckoutPage = compose(
  withRouter,
  withLocalizedMessages,
  connect(mapStateToProps, mapDispatchToProps)
)(CheckoutPageComponent);

CheckoutPage.setInitialValues = (initialValues) => setInitialValues(initialValues);
CheckoutPage.displayName = 'CheckoutPage';

export default CheckoutPage;
