import React from 'react';
import { array, bool, func, number, shape, string } from 'prop-types';
import { generateCardIdentifier } from '../../containers/CheckoutPage/CheckoutPageHelpers';
import { SECTION_GIVSLY_CREDIT_AND_PAYMENT } from '../../containers/FaqPage/constants';
import { AddCreditForm, PaymentMethodsForm } from '../../forms';
import { withMessages } from '../../util/localization';
import { propTypes } from '../../util/types';
import Balloon from '../Balloon/Balloon';
import {
  BALLOON_STYLE_FAILURE,
  BALLOON_STYLE_INFO,
  BALLOON_STYLE_REGULAR,
} from '../Balloon/constants';
import { IconSpinner } from '../index';
import LiteButton from '../LiteButton/LiteButton';
import { ICON_PLUS, STYLE_DARK } from '../LiteButton/constants';
import Modal from '../Modal/Modal';
import NamedLink from '../NamedLink/NamedLink';
import { METHOD_CREDIT_CARD, METHOD_GIVSLY_CREDIT } from './constants';
import css from './PaymentMethods.css';
import classNames from 'classnames';
import creditExampleImage from './img-givsly-credit@3x.png';

// Binary literals for ability to pay. Bit positions;
// 1. Credit card,
// 2. Givsly credit,
// 3. Sufficient Givsly credit for the whole amount.
const PAYMENT_NO_CREDIT_NO_CREDIT_CARD = 0b000;
const PAYMENT_NO_CREDIT_CREDIT_CARD = 0b001;
const PAYMENT_INSUFFICIENT_CREDIT_NO_CREDIT_CARD = 0b010;
const PAYMENT_INSUFFICIENT_CREDIT_CREDIT_CARD = 0b011;
const PAYMENT_SUFFICIENT_CREDIT_NO_CREDIT_CARD = 0b110;
const PAYMENT_SUFFICIENT_CREDIT_CREDIT_CARD = 0b111;

class PaymentMethods extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isAddCreditModalOpen: false,
      showAddCardDetailsForm: false,
    };

    this.handleAddCreditCard = this.handleAddCreditCard.bind(this);
    this.handleAddCredit = this.handleAddCredit.bind(this);
    this.handleCloseAddCreditModal = this.handleCloseAddCreditModal.bind(this);
    this.handleOpenAddCreditModal = this.handleOpenAddCreditModal.bind(this);
    this.handleSelectPaymentMethod = this.handleSelectPaymentMethod.bind(this);
    this.handleToggleAddCreditCardForm = this.handleToggleAddCreditCardForm.bind(this);
  }

  async handleAddCredit(code) {
    const { onAddCredit, onGetBalance } = this.props;
    return await onAddCredit(code).then((response) => {
      if (!response.success) {
        return {
          code: response.reason,
        };
      } else {
        onGetBalance();
        this.handleCloseAddCreditModal();
      }
    });
  }

  handleAddCreditCard(values) {
    const { onAddCreditCard } = this.props;
    return onAddCreditCard(values).then(() => {
      this.setState({
        showAddCardDetailsForm: false,
      });
    });
  }

  handleToggleAddCreditCardForm() {
    const { addCreditCardInProgress } = this.props;
    this.setState({
      showAddCardDetailsForm: addCreditCardInProgress ? false : !this.state.showAddCardDetailsForm,
    });
  }

  handleCloseAddCreditModal() {
    this.setState({
      isAddCreditModalOpen: false,
    });
  }

  handleOpenAddCreditModal() {
    this.setState({
      isAddCreditModalOpen: true,
    });
  }

  handleSelectPaymentMethod(method, identifier = null) {
    const { onSelectPaymentMethod } = this.props;
    this.setState({
      showAddCardDetailsForm: false,
    });
    onSelectPaymentMethod(method, identifier);
  }

  get creditBalloon() {
    const { creditCards, breakdown, getMessage, selectedMethod } = this.props;
    const { creditDeduction, total } = breakdown;

    // Using binary literals to determine the payable status (credits, no credits, sufficient
    // credit, insufficient credit, credit card, no credit card). Starting with 0 and turning on
    // different bits once their conditions are met.
    const payableStatus =
      PAYMENT_NO_CREDIT_NO_CREDIT_CARD |
      (creditCards.length ? PAYMENT_NO_CREDIT_CREDIT_CARD : PAYMENT_NO_CREDIT_NO_CREDIT_CARD) |
      (creditDeduction > 0
        ? PAYMENT_INSUFFICIENT_CREDIT_NO_CREDIT_CARD
        : PAYMENT_NO_CREDIT_NO_CREDIT_CARD) |
      (total === 0 ? PAYMENT_SUFFICIENT_CREDIT_NO_CREDIT_CARD : PAYMENT_NO_CREDIT_NO_CREDIT_CARD);

    const labels = () => {
      switch (payableStatus) {
        case PAYMENT_NO_CREDIT_NO_CREDIT_CARD:
          return {
            header: getMessage('noCredit.header'),
            body: getMessage('noCreditNoCreditCard.body'),
            style: BALLOON_STYLE_FAILURE,
          };
        case PAYMENT_NO_CREDIT_CREDIT_CARD:
          return {
            header: getMessage('noCredit.header'),
            body: getMessage('noCredit.body'),
            style: BALLOON_STYLE_INFO,
          };
        case PAYMENT_INSUFFICIENT_CREDIT_NO_CREDIT_CARD:
          return {
            header: getMessage('insufficientCredit.header'),
            body: getMessage('insufficientCreditNoCreditCard.body', {
              availableCredit: (
                <strong key={'availableCredit'}>${(creditDeduction / 100).toFixed(2)}</strong>
              ),
              chargedAmount: <strong key={'chargedAmount'}>${(total / 100).toFixed(2)}</strong>,
            }),
            style: BALLOON_STYLE_FAILURE,
          };
        case PAYMENT_INSUFFICIENT_CREDIT_CREDIT_CARD:
          return {
            header: getMessage('insufficientCredit.header'),
            body: getMessage('insufficientCredit.body', {
              creditCard: <strong key={'last4Digits'}>{creditCards[0].last4Digits}</strong>,
              usedCredit: <strong key={'usedCredit'}>${(creditDeduction / 100).toFixed(2)}</strong>,
              chargedAmount: <strong key={'chargedAmount'}>${(total / 100).toFixed(2)}</strong>,
              style: BALLOON_STYLE_REGULAR,
            }),
          };
        // These options are not displayed but are included for visibility
        default:
        case PAYMENT_SUFFICIENT_CREDIT_NO_CREDIT_CARD:
        case PAYMENT_SUFFICIENT_CREDIT_CREDIT_CARD:
          return {};
      }
    };
    const { body, header, style } = labels();

    // Display only if the Givsly credit option is selected and the user does not have sufficient
    // credit to pay for the transaction.
    return selectedMethod === METHOD_GIVSLY_CREDIT && header && body ? (
      <Balloon balloonStyle={style} body={body} header={header} />
    ) : null;
  }

  render() {
    const {
      addCreditCardInProgress,
      breakdown,
      ensuredCurrentUser,
      className,
      creditCards,
      creditTotal,
      getMessage,
      isOutreachOffer,
      onManageDisableScrolling,
      selectedCreditCard,
      selectedMethod,
    } = this.props;
    const classes = classNames(css.root, className);
    const hasSelectedCredit = selectedMethod === METHOD_GIVSLY_CREDIT;
    const creditOptionClasses = classNames(
      css.paymentOption,
      hasSelectedCredit ? css.selectedPaymentOption : null
    );
    const addCreditCardDetailsClasses = classNames(
      css.paymentOption,
      css.addCreditCardOption,
      this.state.showAddCardDetailsForm && !addCreditCardInProgress
        ? css.showAddCreditCardDetailsForm
        : null
    );
    const { firstName, lastName } = ensuredCurrentUser.attributes.profile;
    const { city, country, state } = ensuredCurrentUser.attributes.profile.publicData.geolocation;

    const showGivslyCreditFooter = selectedMethod === METHOD_GIVSLY_CREDIT;
    const showCreditCardFooter =
      selectedMethod !== METHOD_GIVSLY_CREDIT || breakdown.total > creditTotal;
    const footNoteLink = (
      <NamedLink
        key={'faqPageLink-1'}
        name={'FaqPage'}
        to={{ hash: SECTION_GIVSLY_CREDIT_AND_PAYMENT }}
      >
        {getMessage('footNote.linkTitle')}
      </NamedLink>
    );

    return (
      <div className={classes}>
        <h2 className={css.header}>{getMessage('header')}</h2>
        <div className={css.paymentOptions}>
          {creditCards.length === 0 ? (
            <div className={addCreditCardDetailsClasses}>
              <div
                className={css.addCreditCardOptionLabel}
                onClick={this.handleToggleAddCreditCardForm}
              >
                <span className={css.iconCreditCard} />
                <span className={css.label}>{getMessage('creditCard')}</span>
                {addCreditCardInProgress ? <IconSpinner /> : null}
              </div>
              <PaymentMethodsForm
                className={css.addCreditCardForm}
                initialValues={{
                  name: `${firstName} ${lastName}`,
                  city,
                  state: state ? state.name : null,
                  country: country ? country.shortCode : null,
                }}
                onCancel={this.handleToggleAddCreditCardForm}
                onSubmit={this.handleAddCreditCard}
              />
            </div>
          ) : null}
          {creditCards.map((card) => {
            const { last4Digits, expirationMonth, expirationYear } = card;
            const identifier = generateCardIdentifier(card);
            const isSelected =
              selectedCreditCard === identifier && selectedMethod === METHOD_CREDIT_CARD;
            const optionClasses = classNames(
              css.paymentOption,
              isSelected ? css.selectedPaymentOption : null
            );

            return (
              <div
                className={optionClasses}
                id={identifier}
                key={`${identifier}`}
                onClick={() => this.handleSelectPaymentMethod(METHOD_CREDIT_CARD, identifier)}
              >
                <span className={css.iconCreditCard} />
                <span className={css.cardNumber}>•••• •••• •••• {last4Digits}</span>
                <span className={css.expiresOn}>
                  {String(expirationMonth).padStart(2, '0')}/{String(expirationYear).substr(2)}
                </span>
              </div>
            );
          })}
          <div
            className={creditOptionClasses}
            onClick={() => this.handleSelectPaymentMethod(METHOD_GIVSLY_CREDIT)}
          >
            <span className={css.iconCredit} />
            <span className={css.label}>{getMessage('givslyCredit')}</span>
            <span>$ {(creditTotal / 100).toFixed(2)}</span>
          </div>
        </div>
        {hasSelectedCredit ? (
          <>
            {ensuredCurrentUser.attributes.profile.privateData.creditCodes.length === 0 ? (
              <section className={css.creditDescription}>
                <img alt={getMessage('creditDescription.alt')} src={creditExampleImage} />
                <h4>{getMessage('creditDescription.header')}</h4>
                <p>
                  {getMessage('creditDescription.body', {
                    paymentMethodsLink: (
                      <NamedLink name={'PaymentMethodsPage'} target={'_blank'}>
                        {getMessage('creditDescription.body.paymentMethodsLinkTitle')}
                      </NamedLink>
                    ),
                  })}
                </p>
              </section>
            ) : null}
            {this.creditBalloon}
            <div className={css.controls}>
              <LiteButton
                icon={ICON_PLUS}
                onClick={this.handleOpenAddCreditModal}
                style={STYLE_DARK}
              >
                {getMessage('addCredit')}
              </LiteButton>
              {/*<LiteButton*/}
              {/*  icon={ICON_CART}*/}
              {/*  style={STYLE_LIGHT}*/}
              {/*>*/}
              {/*  {getMessage('buyCredit')}*/}
              {/*</LiteButton>*/}
            </div>
          </>
        ) : null}
        <div className={css.footNote}>
          {showGivslyCreditFooter && !showCreditCardFooter
            ? getMessage(`footNote.${isOutreachOffer ? 'outreach.' : ''}givslyCredit`, {
                link: footNoteLink,
              })
            : null}
          {showCreditCardFooter && !showGivslyCreditFooter
            ? getMessage(`footNote.${isOutreachOffer ? 'outreach.' : ''}creditCard`, {
                link: footNoteLink,
              })
            : null}
          {showCreditCardFooter && showGivslyCreditFooter
            ? getMessage(
                `footNote.${isOutreachOffer ? 'outreach.' : ''}creditCardAndGivslyCredit`,
                {
                  link: footNoteLink,
                }
              )
            : null}
        </div>
        <Modal
          hasCloseButton={false}
          id={'addCreditModal'}
          onManageDisableScrolling={onManageDisableScrolling}
          isOpen={this.state.isAddCreditModalOpen}
          onClose={this.handleCloseAddCreditModal}
        >
          <AddCreditForm
            onSubmit={this.handleAddCredit}
            onCancel={this.handleCloseAddCreditModal}
          />
        </Modal>
      </div>
    );
  }
}

PaymentMethods.propTypes = {
  addCreditCardInProgress: bool,
  breakdown: shape({
    donation: number,
    operatorFee: number,
    paymentFee: number,
    creditDeduction: number,
    subTotal: number,
    total: number,
  }),
  className: string,
  creditCards: array.isRequired,
  creditTotal: number.isRequired,
  dueAmount: number.isRequired,
  ensuredCurrentUser: propTypes.currentUser,
  isOutreachOffer: bool,
  onAddCredit: func.isRequired,
  onGetBalance: func.isRequired,
  onAddCreditCard: func.isRequired,
  onManageDisableScrolling: func.isRequired,
  onSelectPaymentMethod: func.isRequired,
  selectedMethod: string.isRequired,
  selectedCreditCard: string,
};

PaymentMethods.defaultProps = {
  addCreditCardInProgress: false,
  className: null,
  isOutreachOffer: false,
  selectedMethod: METHOD_CREDIT_CARD,
  selectedCreditCard: null,
};

export default withMessages(PaymentMethods, 'PaymentMethods');
