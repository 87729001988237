import { string } from 'prop-types';
import React from 'react';
import css from './SectionTitle.css';
import classNames from 'classnames';

const SectionTitle = props => {
  const {
    className,
    mobileTitle,
    title,
  } = props;

  return (
    <div className={classNames(css.root, className)}>
      <div className={css.sectionTitle}>
        {mobileTitle ? (
          <span className={css.mobileText}>
            {mobileTitle}
          </span>
        ): null}
        <span className={css.regularText}>
          {title}
        </span>
      </div>
    </div>
  );
}

SectionTitle.propTypes = {
  className: string,
  mobileTitle: string,
  title: string,
}

SectionTitle.defaultProps = {
  className: null,
  mobileTitle: null,
}

export default SectionTitle;
