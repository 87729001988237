import React, { useCallback, useState } from 'react';
import * as css from '../NonprofitPreferencesPanel.css';
import { Field } from 'react-final-form';
import { useMessages } from '../../../util/localization';
import FieldTextInput from '../../FieldTextInput/FieldTextInput';
import { UPLOAD_MESSAGES, ACCEPT_IMAGES } from '../constants';
import RemoveFieldButton from '../RemoveFieldButton';

const BrandingGuidelinesSection = (props) => {
  const { values, form } = props;
  const getMessage = useMessages('NonprofitPreferencesForm');

  const [imageUploadValidations, setImageUploadValidations] = useState({});

  const readImageToFormValue = useCallback(
    /**
     *
     * @param {React.ChangeEvent<HTMLInputElement>} imageChangeEvent
     */
    (imageChangeEvent, key) => {
      imageChangeEvent.persist();

      const siteFileUrl = (url) =>
        form.change('brandingGuidelines', {
          ...values.brandingGuidelines,
          [key]: url,
        });

      const file = imageChangeEvent.target.files[0];
      const inputName = imageChangeEvent.target.name;
      const allowedSize = file.size <= 5000000;
      const allowedType = /(jpeg|jpg|png|pdf|eps|svg)/.test(file.type);

      if (!allowedSize) {
        setImageUploadValidations((state) => ({
          ...state,
          [inputName]: UPLOAD_MESSAGES.TOO_BIG,
        }));
        siteFileUrl('');
        return;
      }
      if (!allowedType) {
        setImageUploadValidations((state) => ({
          ...state,
          [inputName]: UPLOAD_MESSAGES.WRONG_FORMAT,
        }));
        siteFileUrl('');
        return;
      }

      var reader = new FileReader();
      reader.onload = function (fileReaderEvent) {
        console.log(fileReaderEvent.target.result);
        siteFileUrl(fileReaderEvent.target.result);
      };

      setImageUploadValidations((state) => ({
        ...state,
        [inputName]: '',
      }));
      reader.readAsDataURL(imageChangeEvent.target.files[0]);
    },
    [form, values.brandingGuidelines]
  );

  return (
    <section className={css.section}>
      <h3 className={css.sectionTitle}>{getMessage('section.branding')}</h3>
      <p className={css.small}>{getMessage('paragraph.branding')}</p>
      <div className={css.uploadRow}>
        <Field name={`brandingGuidelines.logoLightUrl`} type="file">
          {(fieldRenderProps) => (
            <label htmlFor={fieldRenderProps.input.name} className={css.imageUploadLabel}>
              <img
                src={values.brandingGuidelines.logoLightUrl}
                className={css.imageUpload}
                alt={
                  imageUploadValidations[`brandingGuidelines.logoLightUrl`] ||
                  'Upload logo for light'
                }
              />
              <input
                accept={ACCEPT_IMAGES}
                type={fieldRenderProps.input.type}
                id={fieldRenderProps.input.name}
                name={fieldRenderProps.input.name}
                onChange={(event) => readImageToFormValue(event, 'logoLightUrl')}
              />
              <RemoveFieldButton
                onClick={() => {
                  form.change('brandingGuidelines', {
                    ...values.brandingGuidelines,
                    logoLightUrl: '',
                  });
                }}
              />
            </label>
          )}
        </Field>
        <Field name={`brandingGuidelines.logoDarkUrl`} type="file">
          {(fieldRenderProps) => (
            <label htmlFor={fieldRenderProps.input.name} className={css.imageUploadLabel}>
              <img
                src={values.brandingGuidelines.logoDarkUrl}
                className={css.imageUpload}
                alt={
                  imageUploadValidations[`brandingGuidelines.logoDarkUrl`] || 'Upload logo for dark'
                }
              />
              <input
                accept={ACCEPT_IMAGES}
                type={fieldRenderProps.input.type}
                id={fieldRenderProps.input.name}
                name={fieldRenderProps.input.name}
                onChange={(event) => readImageToFormValue(event, 'logoDarkUrl')}
              />
              <RemoveFieldButton
                onClick={() => {
                  form.change('brandingGuidelines', {
                    ...values.brandingGuidelines,
                    logoDarkUrl: '',
                  });
                }}
              />
            </label>
          )}
        </Field>
      </div>

      <p className={css.disclaimer}>{getMessage('paragraph.fileUpload')}</p>
      <FieldTextInput
        className={css.field}
        id="brandingGuidelines.guidelineUrl"
        label={getMessage('field.branding.URL.label')}
        name="brandingGuidelines.guidelineUrl"
        placeholder={getMessage('field.branding.URL.placeholder')}
        type="url"
        labelSuffix={getMessage('optional')}
      />
      <p className={css.disclaimer}>{getMessage('field.branding.URL.tip')}</p>
    </section>
  );
};

export default BrandingGuidelinesSection;
