import React, { Component } from 'react';
import PropTypes, { func, shape } from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import debounce from 'lodash/debounce';
import { withRouter } from 'react-router-dom';
import classNames from 'classnames';
import { FormattedMessage } from 'react-intl';

import { intlShape, injectIntl } from '../../util/reactIntl';
import { IconSpinner } from '../../components';
import { sanitizeStringForRegEx } from '../../util/sanitize';
import IconHourGlass from '../LocationAutocompleteInput/IconHourGlass';
import { onChangeSearchString, doSearch, clearSerach } from '../../ducks/GivslySearch.duck';
import { getMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import { createSlug, parse, stringify } from '../../util/urlHelpers';

import css from './GivslySearch.css';
import { createResourceLocatorString } from '../../util/routes';
import routeConfiguration from '../../routeConfiguration';
import pickBy from 'lodash/pickBy';

const DEBOUNCE_WAIT_TIME = 600;
const MINIMUM_KEYWORDS_LENGTH = 2;
const MOBILE_BREAKPOINT = 768;

const redirectToURLWithModalState = (props, modalStateParam) => {
  const { history, location } = props;
  const { pathname, search, state } = location;
  const searchString = `?${stringify({ [modalStateParam]: 'open', ...parse(search) })}`;
  history.push(`${pathname}${searchString}`, state);
};

const redirectToURLWithoutModalState = (props, modalStateParam) => {
  const { history, location } = props;
  const { pathname, search, state } = location;
  const queryParams = pickBy(parse(search), (v, k) => {
    return k !== modalStateParam;
  });
  const stringified = stringify(queryParams);
  const searchString = stringified ? `?${stringified}` : '';
  history.push(`${pathname}${searchString}`, state);
};

export const getCompaniesFromResults = (results, searchString) => {
  if (!searchString || !results || !results.length) {
    return [];
  }

  // Find distinct company names that match the search string
  const companyNames = Array.from(
    new Set(
      results
        .map((l) => l.attributes.publicData.companyNameFilter)
        .filter(
          (companyName) =>
            companyName &&
            companyName.search(new RegExp(sanitizeStringForRegEx(searchString), 'i')) !== -1
        )
    )
  );
  companyNames.sort();

  return companyNames;
};

const getNonprofitsFromResults = (results, searchString) => {
  return results.filter((l) => {
    return (
      l.attributes.publicData.isNPOListing &&
      l.attributes.title.search(new RegExp(sanitizeStringForRegEx(searchString), 'i')) !== -1 &&
      l.attributes.publicData.isApproved
    );
  });
};

const debouncedSearch = debounce(
  (onSearch, id, searchString) => {
    onSearch(id, searchString);
  },
  DEBOUNCE_WAIT_TIME,
  { leading: false, trailing: true }
);

class GivslySearchComponent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      inputHasFocus: false,
      keywords: '',
      isMobile: false,
      isMobileOpen: false,
    };

    // ref to this component
    this.rootNode = null;
    this.onChange = this.onChange.bind(this);
    this.handleOnFocus = this.handleOnFocus.bind(this);
    this.handleOnBlur = this.handleOnBlur.bind(this);
    this.handleClickFeaturedLocation = this.handleClickFeaturedLocation.bind(this);
    this.handleClickNonprofits = this.handleClickNonprofits.bind(this);
    this.handleClickPeople = this.handleClickPeople.bind(this);
    this.handleClickResult = this.handleClickResult.bind(this);
    this.handleOnMouseDown = this.handleOnMouseDown.bind(this);
    this.onKeyUp = this.onKeyUp.bind(this);

    this.input = React.createRef();
  }

  static getDerivedStateFromProps(props, state) {
    const search = parse(props.history.location.search);
    return {
      isMobile: global.window && global.window.innerWidth < MOBILE_BREAKPOINT,
      isMobileOpen: search.mobilesearch === 'open',
    };
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevState.isMobileOpen !== this.state.isMobileOpen && this.state.isMobileOpen) {
      this.input.current.focus();
      if (global.window) {
        global.window.scrollTo({
          top: 0,
          behavior: 'smooth',
        });
      }
    }
  }

  handleOnFocus() {
    if (this.state.isMobile) {
      redirectToURLWithModalState(this.props, 'mobilesearch');
    }
    this.setState({
      inputHasFocus: true,
    });
  }

  handleOnBlur(e) {
    // FocusEvent is fired faster than the link elements native click handler
    // gets its own event. Therefore, we need to check the origin of this FocusEvent.
    // console.log('Blurring that shit!');
    if (this.rootNode && !this.rootNode.contains(e.relatedTarget)) {
      debouncedSearch.cancel();
      this.setState({
        inputHasFocus: false,
        keywords: '',
      });
    } else {
      this.setState({
        keywords: '',
      });
    }
    e.target.value = '';

    if (this.state.isMobile) {
      redirectToURLWithoutModalState(this.props, 'mobilesearch');
    }
  }

  handleOnMouseDown(e) {
    // Avoid blur event on Safari causing the modal to close and the click not to register
    e.preventDefault();
  }

  handleClickResult(url) {
    debouncedSearch.cancel();
    this.setState({
      inputHasFocus: false,
      keywords: '',
    });
    this.props.history.push(url);
  }

  handleClickPeople() {
    debouncedSearch.cancel();
    this.setState({
      inputHasFocus: false,
      keywords: '',
    });
    this.props.history.push(createResourceLocatorString('SearchPage', routeConfiguration()));
  }

  handleClickNonprofits() {
    debouncedSearch.cancel();
    this.setState({
      inputHasFocus: false,
      keywords: '',
    });
    this.props.history.push(
      createResourceLocatorString('NonprofitSearchPage', routeConfiguration())
    );
  }

  handleClickFeaturedLocation() {
    debouncedSearch.cancel();
    this.setState({
      inputHasFocus: false,
      keywords: '',
    });
    this.input.current.blur();
  }

  onChange(e) {
    const { id, onChange, onSearch } = this.props;
    const newValue = e.target.value;

    onChange(id, newValue);
    this.setState({ keywords: newValue });

    if (newValue && newValue.length >= MINIMUM_KEYWORDS_LENGTH) {
      debouncedSearch(onSearch, id, newValue);
    }
  }

  onKeyUp(e) {
    if (e.keyCode === 13) {
      // Enter
      debouncedSearch.flush();
    }
    if (e.keyCode === 27) {
      // Esc
      debouncedSearch.cancel();
    }
  }

  render() {
    const {
      id,
      rootClassName,
      className,
      searchResults,
      searchResultsClassName,
      searchInProgress,
      searchError,
      intl,
      placeholderMobileText,
      placeholderText,
    } = this.props;

    const placeholder =
      placeholderText && placeholderMobileText
        ? this.state.isMobile
          ? placeholderMobileText
          : placeholderText
        : intl.formatMessage({
            id: !this.state.isMobile
              ? 'GivslySearch.placeholder'
              : 'GivslySearch.mobilePlaceholder',
          });
    const classes = classNames(rootClassName, className);
    const { keywords } = this.state;
    const nonprofitResults = getNonprofitsFromResults(searchResults, '');

    const error = searchError ? (
      <p className={css.error}>
        <FormattedMessage id="GivslySearch.searchFailed" />
      </p>
    ) : null;

    return (
      <div
        id={id}
        className={classes}
        ref={(node) => {
          this.rootNode = node;
        }}
      >
        <div className={css.searchBar}>
          {searchInProgress ? <IconSpinner className={css.iconSpinner} /> : <IconHourGlass />}
          <input
            className={css.input}
            type="search"
            autoComplete="off"
            autoFocus={false}
            placeholder={placeholder}
            name={`${id}-input`}
            value={this.state.keywords}
            disabled={false}
            onFocus={this.handleOnFocus}
            onBlur={this.handleOnBlur}
            onChange={this.onChange}
            onKeyUp={this.onKeyUp}
            ref={this.input}
          />
        </div>
        {keywords.length > 0 && nonprofitResults.length > 0 ? (
          <div className={classNames(css.searchResults, searchResultsClassName)}>
            {error}
            {nonprofitResults && nonprofitResults.length > 0 ? (
              <ul className={css.searchResultList}>
                {nonprofitResults.slice(0, 3).map((l) => {
                  const title = l.attributes.title;
                  const url = createResourceLocatorString(
                    'ListingPageCanonical',
                    routeConfiguration(),
                    { id: l.id.uuid, slug: createSlug(title) },
                    {}
                  );

                  return (
                    <li
                      key={`nonprofit-${l.id.uuid}`}
                      className={classNames(css.searchResultItem, css.searchResultNonprofit)}
                    >
                      <a
                        href={url}
                        onClick={() => this.handleClickResult(url)}
                        onMouseDown={this.handleOnMouseDown}
                      >
                        <span className={css.searchResultItemTitle}>{title}</span>
                      </a>
                    </li>
                  );
                })}
              </ul>
            ) : null}
          </div>
        ) : null}
      </div>
    );
  }
}

const { string, bool } = PropTypes;

GivslySearchComponent.defaultProps = {
  className: null,
  rootClassName: null,
  keepSuggestionsOpen: false,
  placeholderText: null,
  placeholderMobileText: null,
  searchResultsClassName: null,
};

GivslySearchComponent.propTypes = {
  className: string,
  history: shape({
    push: func.isRequired,
  }).isRequired,
  id: string.isRequired,
  intl: intlShape.isRequired,
  rootClassName: string,
  searchResultsClassName: string,
  keepSuggestionsOpen: bool,
  placeholderText: string,
  placeholderMobileText: string,
};

const mapStateToProps = (state, props) => {
  const { searchResults, searchesInProgress, searchErrors } = state.GivslySearch;

  const listings =
    searchResults[props.id] && searchResults[props.id].length
      ? getMarketplaceEntities(state, searchResults[props.id])
      : [];

  return {
    searchResults: listings,
    searchInProgress: searchesInProgress[props.id],
    searchError: searchErrors[props.id],
  };
};

const mapDispatchToProps = (dispatch) => ({
  onChange: (componentId, searchString) =>
    dispatch(onChangeSearchString(componentId, searchString)),
  onSearch: (componentId, searchString) => dispatch(doSearch(componentId, searchString)),
  onClear: (componentId) => dispatch(clearSerach(componentId)),
});

const GivslySearch = compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  injectIntl
)(GivslySearchComponent);

export default GivslySearch;
