import { bool, func, string } from 'prop-types';
import React from 'react';
import Button from '../Button/Button';
import classNames from 'classnames';
import IconSpinner from '../icons/IconSpinner/IconSpinner';
import css from './LiteButton.css';
import { NO_ICON, STYLE_DARK } from './constants';

const LiteButton = (props) => {
  const { children, className, icon, inProgress, labelClassName, style, ...rest } = props;
  const classes = classNames(css.root, style, className);
  const labelClasses = classNames(
    css.label,
    labelClassName,
    icon,
    icon !== NO_ICON ? css.withIcon : null
  );
  return (
    <Button className={classes} {...rest}>
      {inProgress ? <IconSpinner /> : <span className={labelClasses}>{children}</span>}
    </Button>
  );
};

LiteButton.propTypes = {
  disabled: bool,
  children: string,
  className: string,
  icon: string,
  inProgress: bool,
  labelClassName: string,
  onClick: func,
  style: string,
  type: string,
};

LiteButton.defaultProps = {
  className: null,
  icon: NO_ICON,
  inProgress: false,
  labelClassName: null,
  style: STYLE_DARK,
  type: 'button',
  disabled: false,
};

export default LiteButton;
