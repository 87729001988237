import axios from 'axios';
import qs from 'query-string';
import { getBaseHeaders } from './api';

/**
 * Gets the API end point for the payment API
 *
 * @return {string}
 */
const getApiEndpoint = () => {
  return process.env.REACT_APP_PAYMENT_API;
};

/**
 * Gets the headers used by the payment API
 *
 * @param {string} userId
 * @param {string} secret
 * @param {string} method
 * @return {{Authorization: string, Marketplace: string, UserID: string, Accepts: string}}
 */
const getHeaders = (userId, secret, method = 'get') => {
  const headers = getBaseHeaders(userId, secret, method);

  // Content type only needs to be added when submitting data to the API
  if (method === 'post') {
    headers['Content-Type'] = 'application/x-www-form-urlencoded';
  }

  return headers;
};

/***************************************************************************************************
 * API Methods
 **************************************************************************************************/

export const getBalance = (ensuredCurrentUser) => {
  return axios({
    method: 'get',
    url: `${getApiEndpoint()}credit/balance`,
    headers: getHeaders(
      ensuredCurrentUser.id.uuid,
      ensuredCurrentUser.attributes.profile.privateData.paymentSecret
    ),
  });
};

export const addBalance = (ensuredCurrentUser, code) => {
  return axios({
    method: 'post',
    url: `${getApiEndpoint()}credit/add`,
    headers: getHeaders(
      ensuredCurrentUser.id.uuid,
      ensuredCurrentUser.attributes.profile.privateData.paymentSecret,
      'post'
    ),
    data: qs.stringify({ code }),
  });
};

export const applyFeesAndDeductions = (
  ensuredCurrentUser,
  transactionId,
  deductFees,
  applyCredit
) => {
  return axios({
    method: 'post',
    url: `${getApiEndpoint()}transaction/apply-fees-deductions`,
    headers: getHeaders(
      ensuredCurrentUser.id.uuid,
      ensuredCurrentUser.attributes.profile.privateData.paymentSecret,
      'post'
    ),
    data: qs.stringify({
      transactionId,
      deductFees,
      applyCredit,
    }),
  });
};

export const accept = (ensuredCurrentUser, transactionId, exposeEmail = null) => {
  return axios({
    method: 'post',
    url: `${getApiEndpoint()}transaction/accept`,
    headers: getHeaders(
      ensuredCurrentUser.id.uuid,
      ensuredCurrentUser.attributes.profile.privateData.paymentSecret,
      'post'
    ),
    data: qs.stringify({
      transactionId,
      exposeEmail,
    }),
  });
};

/**
 * Process the credit transfer stage for outreach payment transactions
 *
 * @param ensuredCurrentUser
 * @param transactionId
 * @return {AxiosPromise}
 */
export const transferOutreachCredit = (ensuredCurrentUser, transactionId) => {
  return axios({
    method: 'post',
    url: `${getApiEndpoint()}transaction/transfer-outreach-credit`,
    headers: getHeaders(
      ensuredCurrentUser.id.uuid,
      ensuredCurrentUser.attributes.profile.privateData.paymentSecret,
      'post'
    ),
    data: qs.stringify({
      transactionId,
    }),
  });
};

export const decline = (ensuredCurrentUser, transactionId) => {
  return axios({
    method: 'post',
    url: `${getApiEndpoint()}transaction/decline`,
    headers: getHeaders(
      ensuredCurrentUser.id.uuid,
      ensuredCurrentUser.attributes.profile.privateData.paymentSecret,
      'post'
    ),
    data: qs.stringify({
      transactionId,
    }),
  });
};

export const createSecret = () => {
  const charSet = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  return new Array(32)
    .fill(0)
    .map(() => {
      return charSet.substr(Math.floor(Math.random() * charSet.length), 1);
    })
    .join('');
};
