import React from 'react';
import css from './NonprofitFeatureCard.css';
import { propTypes } from '../../util/types';
import { func, shape, string } from 'prop-types';
import { createResourceLocatorString } from '../../util/routes';
import routeConfiguration from '../../routeConfiguration';
import { ensureLandscapeImage, ensureLocation } from '../../util/data';
import config from '../../config';
import { cropText } from '../../util/richText';
import classNames from 'classnames';

class NonprofitFeatureCard extends React.Component {
  constructor(props) {
    super(props);
    this.openProfile = this.openProfile.bind(this);
  }

  openProfile(id, title) {
    const { history, trackEvent, trackingCategory } = this.props;

    if (trackEvent) {
      trackEvent({
        category: trackingCategory,
        action: 'ClickFeaturedNonprofit',
        label: 'FeaturedNonprofit',
        value: title,
      });
    }

    const link = createResourceLocatorString('ListingPageCanonical', routeConfiguration(), { id });
    history.push(link);
  }

  render() {
    const { className, listing } = this.props;
    const { title, description } = listing.attributes;
    const { organizationCategory, organizationLocation } = listing.attributes.publicData;
    const image =
      listing.images && listing.images.length > 0
        ? ensureLandscapeImage(listing.images[0]).attributes.variants['landscape-crop2x'].url
        : null;
    const location = ensureLocation(
      config.custom.nonprofitLocations.find((location) => location.key === organizationLocation)
    );
    const classes = classNames(css.card, className);

    return (
      <div className={classes} onClick={() => this.openProfile(listing.id.uuid, title)}>
        {image ? (
          <div className={css.imageWrapper}>
            <img className={css.image} src={image} alt="" />
          </div>
        ) : null}
        <div className={css.cardTitle}>{title}</div>
        <div className={css.cardSubTitle}>{organizationCategory}</div>
        <p className={css.text}>{cropText(description || '', 200)}</p>
        <div className={css.footerText}>{location.label}</div>
      </div>
    );
  }
}

NonprofitFeatureCard.propTypes = {
  className: string,
  history: shape({
    push: func.isRequired,
  }).isRequired,
  listing: propTypes.listing,
  trackEvent: func,
  trackingCategory: string,
};

NonprofitFeatureCard.defaultProps = {
  trackEvent: null,
  trackingCategory: null,
};

export default NonprofitFeatureCard;
