import axios from 'axios';
import { storableError } from './errors';
import config from '../config';

export const validateBookingAccessCode = (code) => {
  const url = `${config.custom.aw2020BookingAccessCodeValidationUrl}&code=${code}`;

  return axios
    .get(url)
    .then((res) => {
      return !!res.data.success;
    })
    .catch((err) => {
      console.error('Cannot validate access code', storableError(err));
      return false;
    });
};
