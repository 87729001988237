import React from 'react';
import { NamedLink } from '../../../../components';
import classNames from 'classnames';
import css from './HowItWorks.css';
import { func, string } from 'prop-types';
import image3x from './img-process@3x.png';

class HowItWorks extends React.Component {
  constructor(props) {
    super(props);
    this.handleClick = this.handleClick.bind(this);
  }

  handleClick = (e) => {
    const { trackEvent, trackingCategory } = this.props;
    trackEvent({
      category: trackingCategory,
      action: 'ClickSignUp',
      label: 'HowItWorks',
    });
    return e;
  };

  render() {
    const { buttonLabel, subTitle, text, title } = this.props;
    return (
      <div className={classNames(css.sectionContent, css.feature)}>
        <div className={css.content}>
          <h2 className={css.title}>{title}</h2>
          <h3 className={css.subTitle}>{subTitle}</h3>
          <p className={css.text}>{text}</p>
          <div className={css.actions}>
            <NamedLink className={css.link} name={'SignupPage'} onClick={this.handleClick}>
              {buttonLabel}
            </NamedLink>
          </div>
        </div>
        <div className={css.process}>
          <img alt={subTitle} className={css.processImage} src={image3x} />
        </div>
      </div>
    );
  }
}

HowItWorks.propTypes = {
  buttonLabel: string.isRequired,
  subTitle: string.isRequired,
  text: string.isRequired,
  title: string.isRequired,
  trackEvent: func.isRequired,
  trackingCategory: string.isRequired,
};

export default HowItWorks;
