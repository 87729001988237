import { PanelHeader, PrimaryButton } from '../index';
import { FormattedMessage } from 'react-intl';
import React from 'react';
import classNames from 'classnames';
import publishedImage from './img-published.svg';
import css from './OnboardingCompletedContent.css';
import { func } from 'prop-types';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import { createResourceLocatorString } from '../../util/routes';
import routeConfiguration from '../../routeConfiguration';
import { TAB_OVERVIEW } from '../../containers/OutreachOffersPage/constants';

const OnboardingCompletedContentComponent = ({ trackEvent, history }) => {
  const goToDonationOffersPage = () => {
    if (trackEvent) {
      trackEvent({
        category: 'Onboarding',
        action: 'Click',
        label: 'Onboarding completed',
        value: 'MyAvailabilityPageButton',
      });
    }

    history.push(
      createResourceLocatorString('OutreachOffersPage', routeConfiguration(), { tab: TAB_OVERVIEW })
    );
  };

  const goToSearchPage = () => {
    if (trackEvent) {
      trackEvent({
        category: 'Onboarding',
        action: 'Click',
        label: 'Onboarding completed',
        value: 'SearchPageButton',
      });
    }

    history.push(createResourceLocatorString('SearchPage', routeConfiguration()));
  };

  return (
    <div className={css.content}>
      <img src={publishedImage} alt="" className={css.contentBlock} />
      <PanelHeader
        className={css.contentBlock}
        header={
          <span className={css.heading}>
            <FormattedMessage id="OnboardingWizard.publishedHeading" />
          </span>
        }
        subHeader={<FormattedMessage id="OnboardingWizard.publishedSubHeading" />}
      />

      <PrimaryButton
        className={classNames(css.volunteerButton, css.contentBlock)}
        onClick={goToDonationOffersPage}
      >
        <FormattedMessage id="OnboardingWizard.publishedDonationOffers.button" />
      </PrimaryButton>

      {/*  <span className={classNames(css.tinyText, css.contentBlock)}>
        <FormattedMessage id="OnBoardingWizard.publishedSubText" />
      </span> */}
    </div>
  );
};

OnboardingCompletedContentComponent.propTypes = {
  trackEvent: func,
};

const OnboardingCompletedContent = compose(withRouter)(OnboardingCompletedContentComponent);

export default OnboardingCompletedContent;
