import React from 'react';
import classNames from 'classnames';
import { AvatarMedium, NamedLink } from '../';

import css from './MobileAvatarHero.css';

const MobileAvatarHero = (props) => {
  const { className, rootClassName, avatarWrapperClassName, user } = props;

  const classes = classNames(rootClassName || css.root, className);
  const publicData =
    user && user.attributes && user.attributes.profile && user.attributes.profile.publicData
      ? user.attributes.profile.publicData
      : {};

  const detailCardTitle = user ? user.attributes.profile.displayName : null;

  return (
    <>
      <div className={classes}>
        <div className={avatarWrapperClassName || css.avatarWrapper}>
          <AvatarMedium user={user} />
        </div>

        <div className={css.heroDetails}>
          <div className={css.nameWrapper}>
            <span className={css.nameText}>{detailCardTitle}</span>
            {publicData.listingId ? (
              <NamedLink name="ListingPageCanonical" params={{ id: publicData.listingId }}>
                <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12">
                  <g fill="none" fillRule="evenodd" stroke="#FAC51D" strokeWidth="1.5">
                    <path d="M8.75 8.25v3h-8v-8h3M6.25.75h5v5" />
                    <path strokeLinecap="square" d="M10.25 1.75l-5 5" />
                  </g>
                </svg>
              </NamedLink>
            ) : null}
          </div>
          <div className={css.jobTitle}>{publicData.jobTitle}</div>
          <div className={css.companyName}>{publicData.companyName}</div>
        </div>
      </div>
    </>
  );
};

export default MobileAvatarHero;
