import React from 'react';
import { Button, NamedLink } from '../../../components';
import { useMessages } from '../../../util/localization';
import { createSlug } from '../../../util/urlHelpers';
import css from '../PartnershipsPage.css';

const SuccessTab = ({ listingId, listingTitle }) => {
  const getMessage = useMessages('PartnershipsPage');
  return (
    <div className={css.successCol}>
      <svg width="130" height="112" viewBox="0 0 130 112" xmlns="http://www.w3.org/2000/svg">
        <g fill="none" fill-rule="evenodd">
          <path
            d="M1.083 109h127.834c.598 0 1.083.672 1.083 1.5s-.485 1.5-1.083 1.5H1.083C.485 112 0 111.328 0 110.5s.485-1.5 1.083-1.5"
            fill="#F2F2F2"
          />
          <path
            d="M100 112H30a5 5 0 0 1-5-5V17a5 5 0 0 1 5-5h70a5 5 0 0 1 5 5v90a5 5 0 0 1-5 5"
            fill="#FAF4D5"
          />
          <path
            d="M105 41.756V17a5 5 0 0 0-5-5H30a5 5 0 0 0-5 5v46.318l80-21.562z"
            fill="#FCE283"
          />
          <path
            d="M81 24c0-13.255 10.745-24 24-24s24 10.745 24 24-10.745 24-24 24-24-10.745-24-24"
            fill="#2ECC71"
          />
          <path
            d="M65 68c8.837 0 16-7.163 16-16 0-8.836-7.163-16-16-16s-16 7.164-16 16c0 8.837 7.163 16 16 16"
            fill="#FAC51D"
          />
          <path
            d="M111.43 13.453a3 3 0 0 1 5.277 2.84l-.136.255-10.835 18a3 3 0 0 1-4.456.785l-.215-.191-7.165-7.025a3 3 0 0 1 3.97-4.488l.23.203 4.452 4.367 8.878-14.746zM65 52.067a4.033 4.033 0 1 1 0-8.066 4.033 4.033 0 0 1 0 8.066M71.857 60h-2.689A4.174 4.174 0 0 0 65 55.832 4.173 4.173 0 0 0 60.832 60h-2.69A6.865 6.865 0 0 1 65 53.143 6.865 6.865 0 0 1 71.857 60"
            fill="#FFF"
          />
          <path
            fill="#FAC51D"
            d="M38.767 96h27v-3h-27zM38.767 88h54v-3h-54zM38.767 80h54v-3h-54z"
          />
          <path
            d="M25.422 109A4.996 4.996 0 0 0 30 112h70a4.996 4.996 0 0 0 4.578-3H25.422z"
            fill="#FCE283"
          />
        </g>
      </svg>
      <h1>{getMessage('success.title')}</h1>
      <p>{getMessage('success.text')}</p>
      <NamedLink
        name="ListingPage"
        params={{
          id: listingId,
          slug: createSlug(listingTitle),
        }}
      >
        <Button className={css.disclaimerButton}>{getMessage('success.button')}</Button>
      </NamedLink>
    </div>
  );
};

export default SuccessTab;
