import React, { Component } from 'react';
import { bool, func, shape, string } from 'prop-types';
import classNames from 'classnames';
import css from './EventLandingPageHero.css';
import EventLandingPageButton from '../EventLandingPageButton/EventLandingPageButton';
import { BackgroundResponsiveContainer } from '../../../../components';

export class EventLandingPageHero extends Component {
  render() {
    const {
      backgroundImageUrlSet,
      button,
      isAuthenticated,
      eventKey,
      logo,
      subTitle,
      title,
      body,
      trackEvent,
    } = this.props;

    const textContent = (
      <div>
        {logo && (
          <div className={css.logo}>
            <img src={logo} alt="" />
          </div>
        )}
        <h1 className={css.title}>{title}</h1>
        <p className={css.text}>{body}</p>
        <EventLandingPageButton
          className={css.button}
          button={button}
          eventKey={eventKey}
          isAuthenticated={isAuthenticated}
          trackEvent={(params) => trackEvent({ label: 'Hero block', ...params })}
        />
      </div>
    );

    return (
      <>
        <BackgroundResponsiveContainer backgroundImageUrlSet={backgroundImageUrlSet}>
          <div className={classNames(css.heroContainer)}>
            <div>
              <span className={classNames(css.text, css.eventName)}>{subTitle}</span>
            </div>
            <div className={classNames(css.textContainer, css.textContainerDesktop)}>
              {textContent}
            </div>
          </div>
        </BackgroundResponsiveContainer>
        <div className={classNames(css.textContainer, css.textContainerMobile)}>{textContent}</div>
      </>
    );
  }
}

EventLandingPageHero.propTypes = {
  backgroundImageUrlSet: shape({
    small: string.isRequired,
    medium: string,
    large: string,
    xlarge: string,
  }).isRequired,
  button: shape({
    loggedIn: shape({
      label: string.isRequired,
      name: string.isRequired,
    }),
    notLoggedIn: shape({
      label: string.isRequired,
      name: string.isRequired,
    }),
  }),
  eventKey: string.isRequired,
  isAuthenticated: bool.isRequired,
  logo: string,
  subTitle: string.isRequired,
  title: string.isRequired,
  body: string.isRequired,
  trackEvent: func.isRequired,
};
