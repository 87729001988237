import React from 'react';
import css from './SlidePanelMultiple.css';
import { node, number, string } from 'prop-types';
import classNames from 'classnames';

export const CONTENT_INITIAL = 0;
export const CONTENT_MAIN = 1;
export const CONTENT_SIDE = 2;
export const CONTENT_THIRD = 3;
export const CONTENT_FOURTH = 4;

const classResolver = (activeContent, previousContent) => {
  if (activeContent === CONTENT_INITIAL) {
    return css.showMain;
  } else if (activeContent === CONTENT_MAIN) {
    if (previousContent === CONTENT_SIDE) {
      return css.side_to_main;
    } else if (previousContent === CONTENT_THIRD) {
      return css.third_to_main;
    }
  } else if (activeContent === CONTENT_SIDE) {
    if (previousContent === CONTENT_MAIN) {
      return css.main_to_side;
    } else if (previousContent === CONTENT_THIRD) {
      return css.third_to_side;
    }
  } else if (activeContent === CONTENT_THIRD) {
    if (previousContent === CONTENT_SIDE) {
      return css.side_to_third;
    } else if (previousContent === CONTENT_FOURTH) {
      return css.fourth_to_third;
    }
  } else if (activeContent === CONTENT_FOURTH) {
    return css.third_to_fourth;
  }
};

const SlidePanelMultiple = (props) => {
  const {
    activeContent,
    previousContent,
    className,
    mainContent,
    sideContent,
    thirdContent,
    fourthContent,
  } = props;
  const classes = classNames(css.root, classResolver(activeContent, previousContent), className);

  return (
    <div className={classes}>
      <div className={css.mainPanel}>{mainContent}</div>
      <div className={css.sidePanel}>{sideContent}</div>
      <div className={css.thirdPanel}>{thirdContent}</div>
      <div className={css.thirdPanel}>{fourthContent}</div>
    </div>
  );
};

SlidePanelMultiple.propTyps = {
  previousContent: number,
  activeContent: number,
  className: string,
  mainContent: node.isRequired,
  sideContent: node.isRequired,
  thirdContent: node.isRequired,
  fourthContent: node.isRequired,
};

SlidePanelMultiple.defaultProps = {
  previousContent: CONTENT_INITIAL,
  activeContent: CONTENT_INITIAL,
  className: null,
};

export default SlidePanelMultiple;
