import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './IconSocialMediaLinkedIn.css';

const IconSocialMediaLinkedIn = (props) => {
  const { rootClassName, className } = props;
  const classes = classNames(rootClassName || css.root, className);
  return (
    <svg
      className={classes}
      width="16"
      height="16"
      viewBox="0 0 16 16"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M12.667 0H3.333C1.493 0 0 1.493 0 3.333v9.334C0 14.507 1.493 16 3.333 16h9.334C14.508 16 16 14.507 16 12.667V3.333C16 1.493 14.508 0 12.667 0zM5.333 12.667h-2V5.333h2v7.334zm-1-8.179c-.644 0-1.166-.527-1.166-1.176 0-.65.522-1.176 1.166-1.176.644 0 1.167.527 1.167 1.176 0 .65-.522 1.176-1.167 1.176zm9 8.179h-2V8.93c0-2.246-2.666-2.076-2.666 0v3.736h-2V5.333h2V6.51c.93-1.724 4.666-1.851 4.666 1.65v4.507z" />
    </svg>
  );
};

IconSocialMediaLinkedIn.defaultProps = { rootClassName: null, className: null };

const { string } = PropTypes;

IconSocialMediaLinkedIn.propTypes = { rootClassName: string, className: string };

export default IconSocialMediaLinkedIn;
