import { PERSONAL_INFO } from '../../components/OnboardingWizard/constants';
import { array, bool, func, resetWarningCache, shape, string } from 'prop-types';
import React, { useEffect, useState } from 'react';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import {
  ICON_ARROW_LEFT,
  STYLE_LINK,
  ICON_PLUS,
  ICON_DOWNLOAD,
  ICON_DOWN_ARROW,
} from '../../components/LiteButton/constants';
import { FormattedMessage } from '../../util/reactIntl';
import LiteButton from '../../components/LiteButton/LiteButton';
import { getMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import { fetchAllNonprofits } from '../../ducks/NonprofitListing.duck';
import {
  createOutreachOffer,
  queryOutreachOffers,
  queryOutreachRequests,
  deleteOutreachRequest,
  deleteOutreachOffer,
  updateOutreachRequest,
  updateOutreachOffer,
  uploadOfferImage,
  queryUnsplash,
  queryUnsplashForGivslyCollection,
  pingUnsplashDownload,
} from '../../ducks/outreach.duck';
import { fetchCurrentUser } from '../../ducks/user.duck';
import { isScrollingDisabled } from '../../ducks/UI.duck';
import OutreachOfferForm from '../../forms/OutreachOfferForm/OutreachOfferForm';
import routeConfiguration from '../../routeConfiguration';
import { ensureCurrentUser } from '../../util/data';
import {
  Button,
  Footer,
  LayoutSideNavigation,
  LayoutWrapperOutreachSettingsSideNav,
  LayoutWrapperFooter,
  LayoutWrapperMain,
  LayoutWrapperTopbar,
  OutreachOffers,
  OutreachOfferDetails,
  OutreachRequests,
  OutreachRequestDetails,
  Page,
  UserNav,
  IconSpinner,
  Modal,
  Form,
  FieldSelect,
  Menu,
  MenuLabel,
  MenuItem,
  MenuContent,
  IconArrowHead,
} from '../../components';
import { Form as FinalForm } from 'react-final-form';
import { useMessages } from '../../util/localization';
import { createResourceLocatorString } from '../../util/routes';
import { types as sdkTypes } from '../../util/sdkLoader';
import { TopbarContainer } from '../index';
import {
  TAB_CREATE_OFFER,
  TAB_EDIT_OFFER,
  TAB_OFFER_DETAIL,
  TAB_OVERVIEW,
  TAB_REQUEST_DETAIL,
  TAB_CONFIRMED,
  TAB_ARCHIVE,
} from './constants';
import css from './OutreachOffersPage.css';
import sharetribe from '@givsly/sharetribe-utils';
import moment from 'moment';
const { UUID } = sdkTypes;

const downloadRequests = (
  outreachRequests,
  outreachOffers,
  approvedNonprofitNameMap,
  filename = 'requests.csv'
) => {
  const headers = [
    'Donation Offer link name',
    'Acceptor first name',
    'Acceptor last name',
    'Acceptor company',
    'Acceptor business title',
    'Acceptor email',
    'Donation sum',
    'Total sum inc. fees',
    'Status',
    'Chosen nonprofit',
    'date/time of acceptance',
    'date/time of processing',
    'Acceptor message',
  ];

  const csvJson = outreachRequests
    .filter((request) => {
      const offer = outreachOffers.find((offer) => offer.id === request.outreachOfferId);
      return !!offer;
    })
    .map((request) => {
      const { name } = outreachOffers.filter((offer) => offer.id === request.outreachOfferId)[0];
      const datify = (date) => new Date(date).toLocaleString();
      return {
        [headers[0]]: name,
        [headers[1]]: request.bookerFirstName ? request.bookerFirstName : '',
        [headers[2]]: request.bookerLastName ? request.bookerLastName : '',
        [headers[3]]: request.bookerCompany ? request.bookerCompany : '',
        [headers[4]]: request.bookerBusinessTitle ? request.bookerBusinessTitle : '',
        [headers[5]]: request.bookerEmail ? request.bookerEmail : '',
        [headers[6]]: '$' + (request.donationAmount / 100).toFixed(2),
        [headers[7]]: request.processedAmount
          ? '$' + (request.processedAmount / 100).toFixed(2)
          : '',
        [headers[8]]: request.state,
        [headers[9]]: approvedNonprofitNameMap[request.selectedNonProfitId],
        [headers[10]]: datify(request.createdAt),
        [headers[11]]: request.state !== 'PENDING' ? datify(request.updatedAt) : '',
        [headers[12]]: request.bookerMessage ? request.bookerMessage : '',
      };
    });
  let csv = [
    headers.join(','),
    ...csvJson.map((row) => headers.map((header) => JSON.stringify(row[header])).join(',')),
  ].join('\r\n');
  const blob = new Blob([csv], { type: 'text/csv' });
  const elem = window.document.createElement('a');
  elem.href = window.URL.createObjectURL(blob);
  elem.download = filename;
  document.body.appendChild(elem);
  elem.click();
  document.body.removeChild(elem);
};

const OutreachOffersPage = (props) => {
  const [newOfferId, setnewOfferId] = useState('');

  const [modalOfferId, setModelOfferId] = useState('');
  const [isCapBreachModalOpen, setIsCapBreachModalOpen] = useState(false);
  const [isCapAboutToBeReachedModalOpen, setIsCapAboutToBeReachedModalOpen] = useState(false);

  const [isImageInstructionModalOpen, setIsImageInstructionModalOpen] = useState(false);

  const [uploadedBackgroundImageToBigWarning, setUploadedBackgroundImageToBigWarning] = useState(
    false
  );
  const [uploadedLogoImageToBigWarning, setUploadedLogoImageToBigWarning] = useState(false);

  const [uploadedBackgroundImage, setUploadedBackgroundImage] = useState('');
  const [confirmedUploadedBackgroundImage, setConfirmedUploadedBackgroundImage] = useState('');
  const [isBackgroundImageModalOpen, setIsBackgroundImageModalOpen] = useState(false);

  const [uploadedLogoImage, setUploadedLogoImage] = useState('');
  const [confirmedUploadedLogoImage, setConfirmedUploadedLogoImage] = useState('');
  const [isLogoImageModalOpen, setIsLogoImageModalOpen] = useState(false);

  const [modalArchiveActiveOfferId, setModalArchiveActiveOfferId] = useState('');
  const [isArchiveActiveOfferModalOpen, setIsArchiveActiveOfferModalOpen] = useState(false);

  const [modalReactivateOfferId, setModalReactivateOfferId] = useState('');
  const [isReactivateOfferModalOpen, setIsReactivateOfferModalOpen] = useState(false);

  const [offerType, setOfferType] = useState('default');
  const [isCreateOfferTypeModalOpen, setIsCreateOfferTypeModalOpen] = useState(false);

  const {
    currentUser,
    createOutreachOfferInProgress,
    history,
    isLoading,
    getListing,
    ownListing,
    approvedNonprofitNameMap,
    onCreateOffer,
    onDeleteOffer,
    outreachOffers,
    onDeleteRequest,
    outreachRequests,
    onRefreshOffers,
    onRefreshRequests,
    onUpdateOutreachRequest,
    onUpdateOutreachOffer,
    onUploadOfferImage,
    onQueryUnsplash,
    onQueryUnsplashForGivslyCollection,
    onPingUnsplashDownload,
    params,
    queryOutreachOffersInProgress,
    queryOutreachRequestsInProgress,
    scrollingDisabled,
    timezone,
  } = props;
  const user = ensureCurrentUser(currentUser);

  useEffect(() => {
    if (user.id && !user.attributes.profile.publicData.onboardingCompleted) {
      // redirect to login page for people who haven't completed onboarding...
      history.push(
        createResourceLocatorString('OnboardingPage', routeConfiguration(), { tab: PERSONAL_INFO })
      );
    }
  }, [user, history]);

  // probably would've been best to handle this in the payment page
  // but that page doesn't have access to all the requests + offers
  // which need to be looped over in order to see if one of the offer's caps
  // has been met...
  // so rather just check when this page is loaded... Not the most efficient, but hey.

  useEffect(() => {
    // precheck to deactivate offers that have reached the cap
    // only loop if there are some offers and requests
    if (outreachOffers.length && outreachRequests.length) {
      outreachOffers.forEach((offer) => {
        // only interested in active offers with a set donation cap
        if (offer.state === 'active' && offer.donationCap > 0) {
          // array of confirmed requests for this offer
          const confirmedRequestsAmount = outreachRequests
            .filter((request) => {
              return request.state === 'CONFIRMED' && request.outreachOfferId === offer.id;
            })
            .reduce(function (prev, current) {
              return prev + current.processedAmount;
            }, 0);
          // if confirmed requests is equeal to, or greater than allowed donations
          // deactivate the offer
          if (confirmedRequestsAmount >= offer.donationCap) {
            handleDeactivateOutreachOfferCapReached(offer.id);
          }
        }
      });
    }
  }, [outreachOffers, outreachRequests]);

  useEffect(() => {
    // check for requests with null donationAmount or donationCap

    // only loop if there are some offers and requests
    if (outreachRequests.length && outreachOffers.length) {
      outreachRequests.forEach((request) => {
        // only interested in requests with null donationAmount
        if (request.donationAmount === null) {
          // find offer linked to donation
          const linkedOffer = outreachOffers.filter(
            (offer) => offer.id === request.outreachOfferId
          );
          const { donationAmount, donationCap } = linkedOffer[0];
          // update request with the doantion amount and cap
          handleAddDonationAmountAndCapToOutreachRequest(request.id, donationAmount, donationCap);
        }
      });
    }
  }, [outreachOffers, outreachRequests]);

  useEffect(() => {
    // check for requests with null processedAmount

    // only loop if there are some offers and requests
    if (outreachRequests.length && outreachOffers.length) {
      outreachRequests.forEach((request) => {
        // only interested in confirmed requests with null processedAmount
        if (request.state === 'CONFIRMED' && request.processedAmount === null) {
          const operatorPercentage =
            user.attributes &&
            user.attributes.profile.metadata &&
            user.attributes.profile.metadata.operatorPercentage >= 0
              ? user.attributes.profile.metadata.operatorPercentage
              : 15;
          const givslyFee = (request.donationAmount * operatorPercentage) / 100;
          const serviceFee = (request.donationAmount + givslyFee + 30) * (1 / 0.971 - 1) + 30;
          const processedAmount = request.donationAmount + givslyFee + serviceFee;
          // Update req.
          if (new Date(request.updatedAt) < new Date('2022-01-03')) {
            // TODO ADJUST THIS!!
            // all where done with no transaction/givsly fee before this date
            handleAddProcessedAmountToOutreachRequest(request.id, request.donationAmount);
          } else {
            handleAddProcessedAmountToOutreachRequest(request.id, processedAmount);
          }
        }
      });
    }
  }, [outreachOffers, outreachRequests]);

  const getMessage = useMessages('OutreachOffersPage');

  const base64ToFileObj = async (b64) => {
    const mime = b64.match(/data:(.*?);base64,/)[1];
    const res = await fetch(b64);
    const blob = await res.blob();
    // const fileObject = new File([blob], "image", { type: mime });
    // const formData = new FormData();
    // formData.append("image", blob);
    console.log(blob);
    return blob;
  };

  const [spinning, setSpinning] = useState(false);
  const handleCreateOffer = async (details) => {
    setSpinning(true);
    if (details.image && details.image.indexOf('data:') === 0) {
      const backgroundUrl = await onUploadOfferImage({
        image: details.image,
        type: 'background',
      });
      details.image = backgroundUrl.message;
    }
    if (details.logo && details.logo.indexOf('data:') === 0) {
      const logoUrl = await onUploadOfferImage({
        image: details.logo,
        type: 'logo',
      });
      details.logo = logoUrl.message;
    }

    if (!details.image) {
      delete details.image;
    }
    if (details.logo === null) {
      details.logo = 'empty';
    }
    if (details.unsplashAttrib === null) {
      delete details.unsplashAttrib;
    }
    if (
      details.unsplashDownloadLink &&
      details.image &&
      details.image.indexOf('https://images.unsplash.com') === 0
    ) {
      onPingUnsplashDownload({
        download_location: details.unsplashDownloadLink,
      });
    }
    delete details.unsplashDownloadLink;
    if (!details.logo) {
      delete details.logo;
    }
    if (!details.title) {
      delete details.title;
    }
    if (!details.ingress) {
      delete details.ingress;
    }

    // Create the new offer
    const res = await onCreateOffer(details);
    // Refresh the list of offers so the new offer is directly visible
    onRefreshOffers();
    onRefreshRequests();

    // Display new offer details
    setnewOfferId(res.id);
    setSpinning(false);
    navigateToTab(TAB_OFFER_DETAIL, res.id);
  };

  const handleUpdateOffer = async (details) => {
    setSpinning(true);
    details.id = params.id;
    details.state = 'active';

    if (details.image && details.image.indexOf('data:') === 0) {
      const backgroundUrl = await onUploadOfferImage({
        image: details.image,
        type: 'background',
      });
      details.image = backgroundUrl.message;
    }
    if (details.logo && details.logo.indexOf('data:') === 0) {
      const logoUrl = await onUploadOfferImage({
        image: details.logo,
        type: 'logo',
      });
      details.logo = logoUrl.message;
    }

    if (!details.image) {
      delete details.image;
    }
    if (details.logo === null) {
      details.logo = 'empty';
    }
    if (details.unsplashAttrib === null) {
      delete details.unsplashAttrib;
    }
    if (
      details.unsplashDownloadLink &&
      details.image &&
      details.image.indexOf('https://images.unsplash.com') === 0
    ) {
      onPingUnsplashDownload({
        download_location: details.unsplashDownloadLink,
      });
    }
    delete details.unsplashDownloadLink;
    if (!details.logo) {
      delete details.logo;
    }
    if (!details.title) {
      delete details.title;
    }
    if (!details.ingress) {
      delete details.ingress;
    }

    onUpdateOutreachOffer(details);

    onRefreshOffers();
    onRefreshRequests();

    setSpinning(false);
    setnewOfferId(params.id);
    navigateToTab(TAB_OFFER_DETAIL, params.id);
  };

  const handleDeleteOffer = async (id) => {
    // Delete the offer
    await onDeleteOffer(id);

    // Refresh the list of offers so the new offer is directly visible
    onRefreshOffers();
    onRefreshRequests();
  };

  const handleDeleteRequest = async (id) => {
    // Delete the new request
    await onDeleteRequest(id);

    // Refresh the list of requests so the new request is directly visible
    onRefreshRequests();
  };

  const handleProcessOutreachRequest = async (id) => {
    let warnCapBreached = false;
    outreachOffers.forEach((offer) => {
      const request = outreachRequests.filter((req) => req.id === id)[0];
      if (offer.id === request.outreachOfferId && offer.state === 'capReached') {
        warnCapBreached = true;
      }
    });

    let warnCapAboutToBeBreached = false;
    if (!warnCapBreached) {
      const request = outreachRequests.filter((req) => req.id === id)[0];
      const offerId = request.outreachOfferId;
      const offer = outreachOffers.filter((offer) => offer.id === offerId)[0];
      const requests = outreachRequests.filter(
        (req) => req.outreachOfferId === offerId && req.state === 'CONFIRMED'
      );
      const totalSoFar = requests.reduce(function (prev, current) {
        return prev + current.processedAmount;
      }, 0);
      if (offer.donationCap > 0 && totalSoFar + request.donationAmount >= offer.donationCap) {
        warnCapAboutToBeBreached = true;
      }
    }

    if (warnCapBreached) {
      setModelOfferId(id);
      setIsCapBreachModalOpen(true);
    } else if (warnCapAboutToBeBreached) {
      console.log('warnCapAboutToBeBreached');
      setModelOfferId(id);
      setIsCapAboutToBeReachedModalOpen(true);
    } else {
      history.push(
        createResourceLocatorString(
          'OutreachCheckoutPage',
          routeConfiguration(),
          { requestId: id },
          {}
        )
      );
    }
  };

  const handleDiscardOutreachRequest = async (id) => {

    const outreachRequest = {
      id,
      state: 'DISCARDED',
    };
    await onUpdateOutreachRequest(outreachRequest);
    await onRefreshRequests();
  };

  const handleAddProcessedAmountToOutreachRequest = async (id, processedAmount) => {

    const outreachRequest = {
      id,
      processedAmount,
    };
    await onUpdateOutreachRequest(outreachRequest);
    await onRefreshRequests();
    await onRefreshOffers();
  };

  const handleAddDonationAmountAndCapToOutreachRequest = async (
    id,
    donationAmount,
    donationCap
  ) => {

    const outreachRequest = {
      id,
      donationAmount,
      donationCap,
    };
    await onUpdateOutreachRequest(outreachRequest);
    await onRefreshRequests();
    await onRefreshOffers();
  };

  const handleDeactivateOutreachOffer = async (id) => {
    const outreachOffer = {
      id,
      state: 'inactive',
    };
    await onUpdateOutreachOffer(outreachOffer);
    await onRefreshOffers();
    await onRefreshRequests();
  };

  const handleEditOutreachOffer = async (offer) => {
    navigateToTab(TAB_EDIT_OFFER, offer.id);
  };

  const handleEditOutreachOfferById = async (id) => {
    navigateToTab(TAB_EDIT_OFFER, id);
  };

  const handleProcessArchiveActiveOfferModal = async () => {
    const outreachOffer = {
      id: modalArchiveActiveOfferId,
      archived: true,
    };
    await onUpdateOutreachOffer(outreachOffer);

    if (offerHasPendingRequests(modalArchiveActiveOfferId)) {
      outreachRequests.forEach(async (request) => {
        if (request.state === 'PENDING' && request.outreachOfferId === modalArchiveActiveOfferId) {
          await handleDiscardOutreachRequest(request.id);
        }
      });
    }

    await onRefreshOffers();
    await onRefreshRequests();
    setIsArchiveActiveOfferModalOpen(false);
    setModalArchiveActiveOfferId('');
  };

  const handleArchiveOutreachOffer = async (offer) => {
    // needs to be some check if offer is active
    if (offer.state === 'active' || offerHasPendingRequests(offer.id)) {
      setIsArchiveActiveOfferModalOpen(true);
      setModalArchiveActiveOfferId(offer.id);
    } else {
      const outreachOffer = {
        id: offer.id,
        archived: true,
      };
      await onUpdateOutreachOffer(outreachOffer);
      await onRefreshOffers();
      await onRefreshRequests();
    }
  };

  const offerHasPendingRequests = (offerId) => {
    // loop over all requests to see if any pending ones match the offersId
    return outreachRequests.some((request) => {
      return request.state === 'PENDING' && request.outreachOfferId === offerId;
    });
  };

  const handleCancelArchiveActiveOfferModal = () => {
    setIsArchiveActiveOfferModalOpen(false);
    setModalReactivateOfferId('');
  };

  const handleArchiveOutreachRequest = async (id) => {
    const outreachRequest = {
      id,
      archived: true,
    };
    await onUpdateOutreachRequest(outreachRequest);
    await onRefreshRequests();
  };

  const handleUnarchiveOutreachOffer = async (offer) => {
    const outreachOffer = {
      id: offer.id,
      archived: false,
    };
    await onUpdateOutreachOffer(outreachOffer);
    await onRefreshOffers();
    await onRefreshRequests();
  };

  const handleDeactivateOutreachOfferCapReached = async (id) => {
    const outreachOffer = {
      id,
      state: 'capReached',
    };
    await onUpdateOutreachOffer(outreachOffer);
    await onRefreshOffers();
    await onRefreshRequests();
  };

  const handleReactivateOutreachOffer = async (id) => {
    const outreachOffer = {
      id,
      state: 'active',
    };
    await onUpdateOutreachOffer(outreachOffer);
    await onRefreshOffers();
    await onRefreshRequests();
  };

  const handleOpenReactivateOfferModal = (id) => {
    setModalReactivateOfferId(id);
    setIsReactivateOfferModalOpen(true);
  };

  const handleReactivateCapReachedOutreachOffer = async (id) => {

    const outreachOffer = {
      id,
      state: 'active',
      donationCap: 0,
    };
    await onUpdateOutreachOffer(outreachOffer);
    await onRefreshOffers();
    await onRefreshRequests();
  };

  // Easily navigate between tabs with history preservation
  const navigateToTab = (tab = TAB_OVERVIEW, id = null) => {
    history.push(
      createResourceLocatorString(
        id ? 'OutreachOffersDetailPage' : 'OutreachOffersPage',
        routeConfiguration(),
        id ? { tab, id } : { tab },
        {}
      )
    );
  };

  const onManageDisableScrolling = () => {
    return false; // this doesn't seem to make any difference
  };

  const handleCloseModal = () => {
    return false;
  };

  const handleCancelCapBreachModal = () => {
    setModelOfferId('');
    setIsCapBreachModalOpen(false);
  };

  const handleCancelCapAboutToBeReachedModal = () => {
    setModelOfferId('');
    setIsCapAboutToBeReachedModalOpen(false);
  };

  const handleCancelReactivateOfferModal = () => {
    setModalReactivateOfferId('');
    setIsReactivateOfferModalOpen(false);
  };

  const handleProcessCapAboutToBeReachedModal = () => {
    history.push(
      createResourceLocatorString(
        'OutreachCheckoutPage',
        routeConfiguration(),
        { requestId: modalOfferId },
        {}
      )
    );

    setModelOfferId('');
    setIsCapAboutToBeReachedModalOpen(false);
  };

  const handleProcessCapBreachModal = () => {
    history.push(
      createResourceLocatorString(
        'OutreachCheckoutPage',
        routeConfiguration(),
        { requestId: modalOfferId },
        {}
      )
    );

    setModelOfferId('');
    setIsCapBreachModalOpen(false);
  };

  const handleProcessReactivateOfferModal = () => {
    handleReactivateCapReachedOutreachOffer(modalReactivateOfferId);

    setModalReactivateOfferId('');
    setIsReactivateOfferModalOpen(false);
  };

  const offerTypes = [
    {
      key: 'default',
      value: 'default',
      label: 'Standard Donation Offer',
    },
    {
      key: 'sws2021',
      value: 'sws2021',
      label: 'Season without swag 2021',
    },
  ];

  const handleCreateOfferType = () => {
    navigateToTab(TAB_CREATE_OFFER);
    // if (user.id && user.attributes.profile.metadata.enableOfferTemplates) {
    //   // show modal of choices
    //   setIsCreateOfferTypeModalOpen(true);
    // } else {
    //   // normal offer
    //   navigateToTab(TAB_CREATE_OFFER);
    // }
  };

  const handleSubmitCreateOfferType = (values, e) => {
    const type = values.value ? values.value : values;
    e.preventDefault();
    setOfferType(type);
    setIsCreateOfferTypeModalOpen(false);
    navigateToTab(TAB_CREATE_OFFER);
  };

  const handleCancelCreateOfferType = (e) => {
    e.preventDefault();
    setIsCreateOfferTypeModalOpen(false);
  };

  const downloadOffers = () => {
    const headers = [
      'Offer creator first name',
      'Offer creator last name',
      'Offer creator company',
      'Donation Offer Name',
      'state',
      'Individual donation sum for offer',
      'NPO1',
      'NPO2 (optional)',
      'NPO3 (optional)',
      'Cap amount (optional)',
      'Offer template',
      'Offer creation date',
      'Offer last updated',
      'Offer link url',
      'confirmed responses',
      'processed offers',
      'processed offers total ($)',
      'unprocessed offers',
      'unprocessed offers total ($)',
    ];

    const csvJson = outreachOffers.map((offer) => {
      const reqs = outreachRequests.filter((req) => req.outreachOfferId === offer.id);

      const confirmedResponses = reqs.length;
      const processedResponses = reqs.filter((req) => req.state === 'CONFIRMED');
      const unprocessedResponses = reqs.filter((req) => req.state === 'PENDING');
      const processedAmount = processedResponses
        ? processedResponses.reduce(function (prev, current) {
            return prev + current.donationAmount;
          }, 0)
        : 0;
      const unprocessedAmount = unprocessedResponses
        ? unprocessedResponses.reduce(function (prev, current) {
            return prev + current.donationAmount;
          }, 0)
        : 0;

      const {
        id,
        createdAt,
        updatedAt,
        name,
        state,
        archived,
        donationAmount,
        donationCap,
        template,
        nonProfitListingIds,
      } = offer;

      const { firstName, lastName, companyName } = currentUser.attributes.profile.publicData;

      const datify = (date) => new Date(date).toLocaleString();

      const NPOnames = nonProfitListingIds.map((id) => approvedNonprofitNameMap[id]);

      return {
        [headers[0]]: firstName,
        [headers[1]]: lastName,
        [headers[2]]: companyName,
        [headers[3]]: name,
        [headers[4]]: archived ? 'archived' : state,
        [headers[5]]: '$' + donationAmount / 100,
        [headers[6]]: NPOnames[0],
        [headers[7]]: NPOnames[1] || '',
        [headers[8]]: NPOnames[2] || '',
        [headers[9]]: '$' + donationCap / 100,
        [headers[10]]: template === null ? 'default' : template,
        [headers[11]]: datify(createdAt),
        [headers[12]]: datify(updatedAt),
        [headers[13]]: `https://givsly.com/outreach/${id}`,
        [headers[14]]: confirmedResponses,
        [headers[15]]: processedResponses.length,
        [headers[16]]: processedAmount / 100,
        [headers[17]]: unprocessedResponses.length,
        [headers[18]]: unprocessedAmount / 100,
      };
    });
    let csv = [
      headers.join(','),
      ...csvJson.map((row) => headers.map((header) => JSON.stringify(row[header])).join(',')),
    ].join('\r\n');
    const blob = new Blob([csv], { type: 'text/csv' });
    const elem = window.document.createElement('a');
    elem.download = 'donation-offers.csv';
    elem.href = window.URL.createObjectURL(blob);
    document.body.appendChild(elem);
    elem.click();
    document.body.removeChild(elem);
  };

  const initialValues = {
    offerTypeSelect: offerTypes[0],
  };

  // const offerTypeSelectionModal = (
  //   <Modal
  //     className={css.modal}
  //     id={'offerTypeSelectionModal'}
  //     hasCloseButton={false}
  //     onManageDisableScrolling={onManageDisableScrolling}
  //     isOpen={isCreateOfferTypeModalOpen}
  //     onClose={handleCloseModal}
  //   >
  //     <FinalForm
  //       initialValues={initialValues}
  //       onSubmit={() => console.log('Submitted')}
  //       render={(formRenderProps) => {
  //         const { values } = formRenderProps;
  //         return (
  //           <Form>
  //             <h3 className={css.modalHeader}>Choose donation offer type</h3>
  //             <p className={css.modalCopy}>
  //               You can create a blank donation offer from scratch or use a saved template to get
  //               started.
  //             </p>
  //             <FieldSelect
  //               id="offerTypeSelect"
  //               defaultOptionLabel="Standard outreach offer"
  //               defaultOptionValue="default"
  //               label={'Donation offer template'}
  //               name="offerTypeSelect"
  //               options={offerTypes}
  //             />
  //             <div className={css.formControls}>
  //               <Button onClick={(event) => handleCancelCreateOfferType(event)}>Cancel</Button>
  //               <Button
  //                 onClick={(event) => handleSubmitCreateOfferType(values.offerTypeSelect, event)}
  //               >
  //                 Continue
  //               </Button>
  //             </div>
  //           </Form>
  //         );
  //       }}
  //     />
  //   </Modal>
  // );

  const capAboutToBeReachedConfirmationModal = (
    <Modal
      className={css.modal}
      id={'capAboutToBeReachedConfirmationModal'}
      hasCloseButton={false}
      onManageDisableScrolling={onManageDisableScrolling}
      isOpen={isCapAboutToBeReachedModalOpen}
      onClose={handleCloseModal}
    >
      <h3 className={css.modalHeader}>Budget cap reached</h3>
      <p className={css.modalCopy}>
        You are about to reach the budget cap for this Donation Offer link. If you wish to exceed
        the cap, continue to the payment details to process the offer.
      </p>
      <div className={css.modalBtnContatiner}>
        <LiteButton className={css.modalCancel} onClick={handleCancelCapAboutToBeReachedModal}>
          Cancel
        </LiteButton>
        <LiteButton className={css.modalContinue} onClick={handleProcessCapAboutToBeReachedModal}>
          Continue
        </LiteButton>
      </div>
    </Modal>
  );

  const imageInstructionsModal = (
    <Modal
      className={css.modal}
      id={'isImageInstructionModalOpen'}
      hasCloseButton={false}
      onManageDisableScrolling={onManageDisableScrolling}
      isOpen={isImageInstructionModalOpen}
      onClose={handleCloseModal}
    >
      <button className={css.modalFloatClose} onClick={() => setIsImageInstructionModalOpen(false)}>
        Close
      </button>
      <img
        className={css.imageInstructionPreview}
        src="/static/images/donation-offer-instructions.png"
      />
      <p className={css.modalCopy}>
        <b>Tip:</b> Choose an image that best represents your company or the theme of your offer.
        For the best results choose an image with the preferred minimum size of 1920x1080 pixels.
        JPG, GIF or PNG max. 5MB. Note that some of the image may be covered by the offer details.
      </p>
    </Modal>
  );

  const handleBackgroundImageUpload = (event) => {
    var reader = new FileReader();
    reader.onload = function (e) {
      if (e.target.result.length < 7000000) {
        setUploadedBackgroundImage(e.target.result);
        setUploadedBackgroundImageToBigWarning(false);
      } else {
        setUploadedBackgroundImageToBigWarning(true);
      }
    };
    reader.readAsDataURL(event.target.files[0]);
  };

  const handleSetConfirmedUploadedBackgroundImage = () => {
    setConfirmedUploadedBackgroundImage(uploadedBackgroundImage);
    setIsBackgroundImageModalOpen(false);
  };

  const handleLogoImageUpload = (event) => {
    var reader = new FileReader();
    reader.onload = function (e) {
      if (e.target.result.length < 7000000) {
        setUploadedLogoImage(e.target.result);
        setUploadedLogoImageToBigWarning(false);
      } else {
        setUploadedLogoImageToBigWarning(true);
      }
    };
    reader.readAsDataURL(event.target.files[0]);
  };

  const handleSetConfirmedUploadedLogoImage = () => {
    setConfirmedUploadedLogoImage(uploadedLogoImage);
    setIsLogoImageModalOpen(false);
  };

  const cancelSelectImage = (cancelCallback, selectCallback) => (
    <div className={css.cancelSelect}>
      <span onClick={() => cancelCallback(false)}>Cancel</span>
      <span onClick={selectCallback}>Select</span>
    </div>
  );

  const imageUploaderBackground = (
    <>
      {uploadedBackgroundImage ? (
        <div className={css.uploader}>
          <label for="bgimg" className={css.labelImage}>
            <img className={css.uploadedImageImg} src={uploadedBackgroundImage} />
            <span className={css.replaceImg}></span>
          </label>
          <input
            className={css.input}
            onChange={(event) => handleBackgroundImageUpload(event)}
            type="file"
            id="bgimg"
            name="bgimg"
            accept="image/*"
          />
        </div>
      ) : (
        <div className={css.uploader}>
          <label for="bgimg" className={css.label}>
            <span className={css.title}>
              <ins>Upload</ins> an image here
            </span>
            <span className={css.copy}>
              Tip: Choose an image that best represents your company or the theme of your offer. For
              the best results choose an image with the preferred minimum size of 1920x1080 pixels.
              JPG, GIF or PNG max. 5MB. Note that some of the image may be covered by the offer
              details.
            </span>
            {uploadedBackgroundImageToBigWarning ? (
              <p className={css.imageWarn}>Your image exceeds the 5Mb upload limit</p>
            ) : null}
          </label>
          <input
            className={css.input}
            onChange={(event) => handleBackgroundImageUpload(event)}
            type="file"
            id="bgimg"
            name="bgimg"
            accept="image/*"
          />
        </div>
      )}
      {cancelSelectImage(setIsBackgroundImageModalOpen, handleSetConfirmedUploadedBackgroundImage)}
    </>
  );

  const imageUploaderLogo = (
    <>
      {uploadedLogoImage ? (
        <div className={css.uploader}>
          <label for="logoimg" className={css.labelImage}>
            <img className={css.uploadedImageImg} src={uploadedLogoImage} />
            <span className={css.replaceImg}></span>
          </label>
          <input
            className={css.input}
            onChange={(event) => handleLogoImageUpload(event)}
            type="file"
            id="logoimg"
            name="logoimg"
            accept="image/*"
          />
        </div>
      ) : (
        <div className={css.uploader}>
          <label for="logoimg" className={css.label}>
            <span className={css.title}>
              <ins>Upload</ins> an image here
            </span>
            <span className={css.copy}>
              Tip: Choose an image that best represents your company or the theme of your offer. For
              the best results choose an image with the preferred minimum size of 1000x1000 pixels.
              JPG, GIF or PNG max. 5MB. Note that some of the image may be covered by the offer
              details.
            </span>
            {uploadedLogoImageToBigWarning ? (
              <p className={css.imageWarn}>Your image exceeds the 5Mb upload limit</p>
            ) : null}
          </label>
          <input
            className={css.input}
            onChange={(event) => handleLogoImageUpload(event)}
            type="file"
            id="logoimg"
            name="logoimg"
            accept="image/*"
          />
        </div>
      )}
      {cancelSelectImage(setIsLogoImageModalOpen, handleSetConfirmedUploadedLogoImage)}
    </>
  );

  const featuredImageUploaderBackground = () => {
    const image = (n) => `/static/images/bg-${n}.${n > 1 ? 'jpg' : 'png'}`;
    return (
      <div className={css.featuredImages}>
        {[0, 1, 2, 3, 5, 6, 7, 8, 9].map((img) => (
          <img
            className={
              image(img) == uploadedBackgroundImage ? css.selectedFeaturedImg : css.featuredImg
            }
            onClick={() => setUploadedBackgroundImage(image(img))}
            src={image(img)}
          />
        ))}
        {cancelSelectImage(
          setIsBackgroundImageModalOpen,
          handleSetConfirmedUploadedBackgroundImage
        )}
      </div>
    );
  };

  const [unsplashQueryResponse, setUnsplashQueryResponse] = useState([]);
  const [unsplashAttrib, setUnsplashAttrib] = useState(null);
  const [unsplashDownloadLink, setUnsplashDownloadLink] = useState(null);

  const unsplashResults = async (query) => {
    const images = await onQueryUnsplash({
      page: 1,
      query: query,
    });
    setUnsplashQueryResponse(images.results);
  };

  const initialUnsplashResults = async () => {
    const images = await onQueryUnsplashForGivslyCollection({
      page: 1,
    });
    setUnsplashQueryResponse(images);
  };

  useEffect(() => {
    initialUnsplashResults();
  }, []);

  const handleClickUnsplashImage = (img) => {
    const image = (img) => img.replace('&q=80&w=1080', '&q=50&w=1920');
    const attrib = `<a target="_blank" rel="noopener noreferrer" href=${img.user.links.html}>${img.user.name}</a>`;
    setUnsplashAttrib(attrib);
    setUnsplashDownloadLink(img.links.download_location);
    setUploadedBackgroundImage(image(img.urls.regular));
  };

  const handleUnsplashChange = (event) => {
    if (event.keyCode === 13) {
      unsplashResults(event.target.value);
    }
  };

  const findImage = () => {
    const image = (img) => img.replace('&q=80&w=1080', '&q=50&w=1920');
    return (
      <div className={css.unsplashContainer}>
        <div className={css.searchUnsplashWrapper}>
          <input
            className={css.unsplashInput}
            type="search"
            placeholder="Search images by keyword…"
            onKeyDown={(event) => handleUnsplashChange(event)}
          />
          <span className={css.imagesByUnsplash}>
            Images by{' '}
            <a href="www.unsplash.com" target="_blank" rel="noopener noreferrer">
              Unsplash
            </a>
          </span>
        </div>
        <div className={css.unsplashResultsWrapper}>
          {unsplashQueryResponse.map((img) => (
            <figure>
              <img
                src={img.urls.small}
                className={
                  image(img.urls.regular) === uploadedBackgroundImage
                    ? css.activeUnsplashImage
                    : css.unsplashImage
                }
                onClick={(event) => handleClickUnsplashImage(img)}
              />
              <figcaption>
                <a target="_blank" href={img.user.links.html} rel="noopener noreferrer">
                  {img.user.name}
                </a>
              </figcaption>
            </figure>
          ))}
        </div>
        {cancelSelectImage(
          setIsBackgroundImageModalOpen,
          handleSetConfirmedUploadedBackgroundImage
        )}
      </div>
    );
  };

  // const imageUploader = (<Field name="image">
  //   {(props) => (
  //     <div className={css.imageSelectors}>
  //       <div className={css.uploader}>
  //         {uploadedImage ? (
  //           <div className={css.imgAndInput}>
  //             <img
  //               className={props.input.value === uploadedImage ? css.activeImage : ''}
  //               onClick={() => props.input.onChange(uploadedImage)}
  //               src={uploadedImage}
  //               alt=""
  //             />
  //             <FieldTextInput
  //               className={css.replaceImg}
  //               id="uploadedImage"
  //               label={'replace'}
  //               name="uploadedImage"
  //               type="file"
  //               accept="image/*"
  //               onChange={(event: any) => handleBackgroundImageUpload(event)}
  //             />
  //           </div>
  //         ) : (
  //           <FieldTextInput
  //             className={css.inputCont}
  //             id="uploadedImage"
  //             label={'Add your own...'}
  //             name="uploadedImage"
  //             type="file"
  //             accept="image/*"
  //             onChange={(event: any) => handleBackgroundImageUpload(event)}
  //           />
  //         )}
  //       </div>
  //     </div>
  //   )}
  // </Field>);

  // const [recentImage, setRecentImage] = useState('');
  const recentImages = () => {
    let unique = outreachOffers
      .map((offer) => offer.image)
      .filter((value, index, self) => self.indexOf(value) === index);
    return (
      <div className={css.featuredImages}>
        {unique.map((image) => (
          <img
            className={image == uploadedBackgroundImage ? css.selectedFeaturedImg : css.featuredImg}
            onClick={() => setUploadedBackgroundImage(image)}
            src={image}
          />
        ))}
        {cancelSelectImage(
          setIsBackgroundImageModalOpen,
          handleSetConfirmedUploadedBackgroundImage
        )}
      </div>
    );
  };

  const [backgroundImageSelectModalTab, setBackgroundImageSelectModalTab] = useState('Upload');
  const backgroundImageSelectModal = (
    <Modal
      className={css.imageSelectModal}
      id={'backgroundImageSelectModal'}
      hasCloseButton={false}
      onManageDisableScrolling={onManageDisableScrolling}
      isOpen={isBackgroundImageModalOpen}
      onClose={handleCloseModal}
    >
      <header className={css.imageSelectModalHeader}>
        <span>Select background image</span>
        <button
          className={css.modalFloatClose}
          onClick={() => setIsBackgroundImageModalOpen(false)}
        >
          Close
        </button>
      </header>
      <div className={css.imageSelectModalContent}>
        <div className={css.imageSelectModalTabs}>
          <span
            className={
              backgroundImageSelectModalTab === 'Upload'
                ? css.imageSelectModalTabActiveItem
                : css.imageSelectModalTabItem
            }
            onClick={() => setBackgroundImageSelectModalTab('Upload')}
          >
            Upload
          </span>
          <span
            className={
              backgroundImageSelectModalTab === 'Featured'
                ? css.imageSelectModalTabActiveItem
                : css.imageSelectModalTabItem
            }
            onClick={() => setBackgroundImageSelectModalTab('Featured')}
          >
            Featured
          </span>
          <span
            className={
              backgroundImageSelectModalTab === 'Find images'
                ? css.imageSelectModalTabActiveItem
                : css.imageSelectModalTabItem
            }
            onClick={() => setBackgroundImageSelectModalTab('Find images')}
          >
            Find images
          </span>
          <span
            className={
              backgroundImageSelectModalTab === 'Your recent'
                ? css.imageSelectModalTabActiveItem
                : css.imageSelectModalTabItem
            }
            onClick={() => setBackgroundImageSelectModalTab('Your recent')}
          >
            Your recent
          </span>
        </div>
        <div className={css.imageSelectModalMain}>
          {backgroundImageSelectModalTab === 'Upload' && imageUploaderBackground}
          {backgroundImageSelectModalTab === 'Featured' && featuredImageUploaderBackground()}
          {backgroundImageSelectModalTab === 'Find images' && findImage()}
          {backgroundImageSelectModalTab === 'Your recent' && recentImages()}
        </div>
      </div>
    </Modal>
  );

  const [logoImageSelectModalTab, setLogoImageSelectModalTab] = useState('Upload');
  const logoImageSelectModal = (
    <Modal
      className={css.imageSelectModal}
      id={'logoImageSelectModal'}
      hasCloseButton={false}
      onManageDisableScrolling={onManageDisableScrolling}
      isOpen={isLogoImageModalOpen}
      onClose={handleCloseModal}
    >
      <header className={css.imageSelectModalHeader}>
        <span>Select company logo</span>
        <button className={css.modalFloatClose} onClick={() => setIsLogoImageModalOpen(false)}>
          Close
        </button>
      </header>
      <div className={css.imageSelectModalContent}>
        <div className={css.imageSelectModalTabs}>
          <span
            className={
              logoImageSelectModalTab === 'Upload'
                ? css.imageSelectModalTabActiveItem
                : css.imageSelectModalTabItem
            }
            onClick={() => setLogoImageSelectModalTab('Upload')}
          >
            Upload
          </span>
          {/* <span
            className={
              logoImageSelectModalTab === 'Your recent'
                ? css.imageSelectModalTabActiveItem
                : css.imageSelectModalTabItem
            }
            onClick={() => setLogoImageSelectModalTab('Your recent')}
          >
            Your recent
          </span> */}
        </div>
        <div className={css.imageSelectModalMain}>
          {logoImageSelectModalTab === 'Upload' && imageUploaderLogo}
          {/* {logoImageSelectModalTab === 'Your recent' && 'recent images...'} */}
        </div>
      </div>
    </Modal>
  );

  const capBreachConfirmationModal = (
    <Modal
      className={css.modal}
      id={'capBreachConfirmationModal'}
      hasCloseButton={false}
      onManageDisableScrolling={onManageDisableScrolling}
      isOpen={isCapBreachModalOpen}
      onClose={handleCloseModal}
    >
      <h3 className={css.modalHeader}>Donation budget cap reached</h3>
      <p className={css.modalCopy}>
        You have already reached the budget cap for this Donation Offer link. If you wish to exceed
        the cap, continue to the payment details and process the offer.
      </p>
      <div className={css.modalBtnContatiner}>
        <LiteButton className={css.modalCancel} onClick={handleCancelCapBreachModal}>
          Cancel
        </LiteButton>
        <LiteButton className={css.modalContinue} onClick={handleProcessCapBreachModal}>
          Continue
        </LiteButton>
      </div>
    </Modal>
  );

  const archiveActiveOfferConfirmationModal = (
    <Modal
      className={css.modal}
      id={'archiveActiveOfferConfirmationModal'}
      hasCloseButton={false}
      onManageDisableScrolling={onManageDisableScrolling}
      isOpen={isArchiveActiveOfferModalOpen}
      onClose={handleCloseModal}
    >
      <h3 className={css.modalHeader}>Are you sure you want to archive an active offer?</h3>
      <p className={css.modalCopy}>
        Archiving an active Donation Offer link will deactivate the offer. All unprocessed confirmed
        offers will be discarded.
      </p>
      <div className={css.modalBtnContatiner}>
        <LiteButton className={css.modalCancel} onClick={handleCancelArchiveActiveOfferModal}>
          Cancel
        </LiteButton>
        <LiteButton className={css.modalContinue} onClick={handleProcessArchiveActiveOfferModal}>
          Continue
        </LiteButton>
      </div>
    </Modal>
  );

  const reactivateOfferConfirmationModal = (
    <Modal
      className={css.modal}
      id={'reactivateOfferConfirmationModal'}
      hasCloseButton={false}
      onManageDisableScrolling={onManageDisableScrolling}
      isOpen={isReactivateOfferModalOpen}
      onClose={handleCloseModal}
    >
      <h3 className={css.modalHeader}>Reactivate Donation Offer link?</h3>
      <p className={css.modalCopy}>
        Please note that by reactivating this Donation Offer link this link will not have a budget
        cap anymore.
      </p>
      <div className={css.modalBtnContatiner}>
        <LiteButton className={css.modalCancel} onClick={handleCancelReactivateOfferModal}>
          Cancel
        </LiteButton>
        <LiteButton className={css.modalContinue} onClick={handleProcessReactivateOfferModal}>
          Reactivate
        </LiteButton>
      </div>
    </Modal>
  );

  const buttonBack = (
    <LiteButton
      className={css.buttonBack}
      icon={ICON_ARROW_LEFT}
      style={STYLE_LINK}
      onClick={() => navigateToTab(TAB_OVERVIEW)}
    >
      Back
    </LiteButton>
  );

  // Main content switch. This replaces the former slider
  let mainContent = null;
  const perPage = 6;
  function sortBy(a, b) {
    if (Date.now(a.createdAt) < Date.now(b.createdAt)) {
      return -1;
    }
    if (Date.now(a.createdAt) > Date.now(b.createdAt)) {
      return 1;
    }
    return 0;
  }

  const returnAllRequests = (array, archived) => {
    array.sort((a, b) => {
      return new Date(a.createdAt) > new Date(b.createdAt) ? 1 : -1;
    });
    const sortOrder = ['active', 'capReached', 'inactive', 'PENDING', 'CONFIRMED', 'DISCARDED'];
    array.sort((a, b) => {
      return sortOrder.indexOf(a.state) > sortOrder.indexOf(b.state) ? 1 : -1;
    });
    return array.filter((e) => (archived ? e.archived : !e.archived));
  };

  const returnSix = (array, archived, page, order, reverse) => {
    const mutatedArray = [...array];
    if (order === 'byDate') {
      mutatedArray.sort((a, b) => {
        return new Date(b.updatedAt) > new Date(a.updatedAt) ? 1 : -1;
      });
    } else if (order === 'oldest') {
      mutatedArray.sort((a, b) => {
        return new Date(a.createdAt) > new Date(b.createdAt) ? 1 : -1;
      });
    } else if (order === 'byStatus') {
      mutatedArray.sort((a, b) => {
        return new Date(a.createdAt) > new Date(b.createdAt) ? 1 : -1;
      });
      const sortOrder = ['active', 'capReached', 'inactive', 'PENDING', 'CONFIRMED', 'DISCARDED'];
      mutatedArray.sort((a, b) => {
        return sortOrder.indexOf(a.state) > sortOrder.indexOf(b.state) ? 1 : -1;
      });
    } else if (order === 'byOffer') {
      mutatedArray.sort((a, b) => {
        return new Date(a.createdAt) > new Date(b.createdAt) ? 1 : -1;
      });
      const sortOrder = ['DISCARDED', 'CONFIRMED', 'PENDING'];
      mutatedArray.sort((a, b) => {
        return sortOrder.indexOf(a.state) > sortOrder.indexOf(b.state) ? 1 : -1;
      });
      const ids = outreachOffers.map((offer) => offer.id);
      mutatedArray.sort((a, b) => {
        return ids.indexOf(a.outreachOfferId) > ids.indexOf(b.outreachOfferId) ? 1 : -1;
      });
    } else if (order === 'byAtoZ') {
      mutatedArray.sort((a, b) => {
        return a.bookerLastName > b.bookerLastName ? 1 : -1;
      });
    } else if (order === 'byZtoA') {
      mutatedArray.sort((a, b) => {
        return b.bookerLastName > a.bookerLastName ? 1 : -1;
      });
    }
    if (reverse) {
      mutatedArray.reverse();
    }
    return mutatedArray
      .filter((e) => (archived ? e.archived : !e.archived))
      .filter((offer, index) => {
        const start = (page - 1) * perPage;
        const end = start + perPage;
        return index >= start && index < end;
      });
  };
  const [offersState, setOffersState] = useState({
    page: 1,
    order: 'byDate',
    reverse: false,
  });
  const [requestsState, setRequestsState] = useState({
    page: 1,
    order: 'byStatus',
    reverse: false,
  });
  const [archivedOffersState, setArchivedOffersState] = useState({
    page: 1,
    order: 'byDate',
    reverse: false,
  });
  const [archivedRequestsState, setArchivedRequestsState] = useState({
    page: 1,
    order: 'byStatus',
    reverse: false,
  });

  const [isSortOffersMenuOpen, setIsSortOffersMenuOpen] = useState(false);

  const paginate = (array, archived, state, type) => {
    let mutatedArray = [...array];
    mutatedArray = mutatedArray.filter((e) => (archived ? e.archived : !e.archived));
    const pages = Math.ceil(mutatedArray.length / perPage);

    let previousActive = state.page > 1 ? true : false;
    let nextActive = state.page < pages ? true : false;
    const previousPage = () => {
      if (state.page > 1) {
        const obj = {
          page: state.page - 1,
          order: state.order,
          reverse: state.reverse,
        };
        if (type === 'activeOffers') {
          setOffersState(obj);
        } else if (type === 'activeRequests') {
          setRequestsState(obj);
        } else if (type === 'archivedOffers') {
          setArchivedOffersState(obj);
        } else if (type === 'archivedRequests') {
          setArchivedRequestsState(obj);
        }
      }
    };

    const nextPage = () => {
      if (state.page < pages) {
        const obj = {
          page: state.page + 1,
          order: state.order,
          reverse: state.reverse,
        };
        if (type === 'activeOffers') {
          setOffersState(obj);
        } else if (type === 'activeRequests') {
          setRequestsState(obj);
        } else if (type === 'archivedOffers') {
          setArchivedOffersState(obj);
        } else if (type === 'archivedRequests') {
          setArchivedRequestsState(obj);
        }
      }
    };
    return mutatedArray.length > perPage ? (
      <div>
        <span className={css.previous} onClick={previousPage}>
          <IconArrowHead
            direction="left"
            size="big"
            rootClassName={previousActive ? css.arrowIconActive : css.arrowIcon}
          />
        </span>
        <span className={css.next} onClick={nextPage}>
          <IconArrowHead
            direction="right"
            size="big"
            rootClassName={nextActive ? css.arrowIconActive : css.arrowIcon}
          />
        </span>
        <span className={css.pageOf}>
          {state.page} of {pages}
        </span>
      </div>
    ) : null;
  };

  const sorter = (state, type) => {
    let update, label;
    if (type === 'activeOffers') {
      label = getMessage(offersState.order);
      update = (obj) => setOffersState(obj);
    } else if (type === 'activeRequests') {
      label = getMessage(requestsState.order);
      update = (obj) => setRequestsState(obj);
    } else if (type === 'archivedOffers') {
      label = getMessage(archivedOffersState.order);
      update = (obj) => setArchivedOffersState(obj);
    } else if (type === 'archivedRequests') {
      label = getMessage(archivedRequestsState.order);
      update = (obj) => setArchivedRequestsState(obj);
    }

    const byDate = () => {
      if (state.order !== 'byDate') {
        let obj = {
          page: 1,
          order: 'byDate',
          reverse: false,
        };
        update(obj);
      }
    };

    const byStatus = () => {
      if (state.order !== 'byStatus') {
        let obj = {
          page: 1,
          order: 'byStatus',
          reverse: false,
        };
        update(obj);
      }
    };

    const byOffer = () => {
      if (state.order !== 'byOffer') {
        let obj = {
          page: 1,
          order: 'byOffer',
          reverse: false,
        };
        update(obj);
      }
    };

    const byName = () => {
      if (state.order !== 'byName') {
        let obj = {
          page: 1,
          order: 'byName',
          reverse: false,
        };
        update(obj);
      }
    };

    const sort = () => {
      let obj = {
        page: 1,
        order: state.order,
        reverse: !state.reverse,
      };
      update(obj);
    };

    let arrow = state.reverse ? (
      <button className={css.sortAsc} onClick={sort}></button>
    ) : (
      <button className={css.sortDesc} onClick={sort}></button>
    );

    let menuContent = (
      <MenuContent className={css.menuContent} arrowPosition={12}>
        <MenuItem key="byDate" className={css.menuItem} onClick={byDate}>
          {getMessage('byDate')}
        </MenuItem>
      </MenuContent>
    );

    if (type === 'activeRequests' || type === 'archivedRequests') {
      menuContent = (
        <MenuContent className={css.menuContent} arrowPosition={12}>
          <MenuItem key="byDate" className={css.menuItem} onClick={byDate}>
            {getMessage('byDate')}
          </MenuItem>
          <MenuItem key="byStatus" className={css.menuItem} onClick={byStatus}>
            {getMessage('byStatus')}
          </MenuItem>
          <MenuItem key="byOffer" className={css.menuItem} onClick={byOffer}>
            {getMessage('byOffer')}
          </MenuItem>
        </MenuContent>
      );
    }

    if (type === 'activeOffers') {
      menuContent = (
        <MenuContent className={css.menuContent} arrowPosition={12}>
          <MenuItem key="byDate" className={css.menuItem} onClick={byDate}>
            {getMessage('byDate')}
          </MenuItem>
          <MenuItem key="byStatus" className={css.menuItem} onClick={byStatus}>
            {getMessage('byStatus')}
          </MenuItem>
        </MenuContent>
      );
    }

    return {
      menu: (
        <Menu
          className={css.profileMenu}
          isOpen={isSortOffersMenuOpen}
          onToggleActive={(bool) => setIsSortOffersMenuOpen(bool)}
        >
          <MenuLabel className={css.menuLabel} isOpenClassName={css.menuIsOpen}>
            {label}
          </MenuLabel>
          {menuContent}
        </Menu>
      ),
      arrow,
    };
  };

  const outreachOffer = outreachOffers.find((offer) => {
    return offer.id === params.id || offer.id === newOfferId;
  });

  const outreachRequest = outreachRequests.find((request) => request.id === params.id);

  const linkedOutreachOffer = outreachRequest
    ? outreachOffers.find((offer) => offer.id === outreachRequest.outreachOfferId)
    : null;

  const archiveOffers = (
    <>
      <div className={css.headerAndButtonContainer}>
        <h2 className={css.grayHeader}>Created Donation Offers</h2>
        <div className={css.buttonContainer}>
          {sorter(archivedOffersState, 'archivedOffers').menu}
          {sorter(archivedOffersState, 'archivedOffers').arrow}
        </div>
      </div>
      <OutreachOffers
        onDeleteOutreachOffer={handleDeleteOffer}
        onDeactivateOutreachOffer={handleDeactivateOutreachOffer}
        onEditOutreachOffer={handleEditOutreachOffer}
        onArchiveOutreachOffer={handleArchiveOutreachOffer}
        onUnarchiveOutreachOffer={handleUnarchiveOutreachOffer}
        onReactivateOutreachOffer={handleReactivateOutreachOffer}
        onReactivateCapReachedOutreachOffer={handleOpenReactivateOfferModal}
        onViewOfferDetails={(id) => navigateToTab(TAB_OFFER_DETAIL, id)}
        outreachOffers={returnSix(
          outreachOffers,
          true,
          archivedOffersState.page,
          archivedOffersState.order,
          archivedOffersState.reverse
        )}
        outreachRequests={outreachRequests}
        timezone={timezone}
        archived={true}
      />
      {paginate(outreachOffers, true, archivedOffersState, 'archivedOffers')}
    </>
  );

  const archiveRequests = (
    <>
      <div className={css.headerAndButtonContainer}>
        <h2 className={css.grayHeader}>Confirmed offers</h2>
        <div className={css.buttonContainer}>
          {sorter(archivedRequestsState, 'archivedRequests').menu}
          {sorter(archivedRequestsState, 'archivedRequests').arrow}
        </div>
      </div>
      <OutreachRequests
        getListing={getListing}
        onDeleteRequest={handleDeleteRequest}
        onDiscardRequest={handleDiscardOutreachRequest}
        onArchiveRequest={handleArchiveOutreachRequest}
        onProcessRequest={handleProcessOutreachRequest}
        onViewRequestDetails={(id) => navigateToTab(TAB_REQUEST_DETAIL, id)}
        outreachRequests={returnSix(
          outreachRequests,
          true,
          archivedRequestsState.page,
          archivedRequestsState.order,
          archivedRequestsState.reverse
        )}
        outreachOffers={outreachOffers}
        nonprofitNameMap={approvedNonprofitNameMap}
        timezone={timezone}
      />
      {paginate(outreachRequests, true, archivedRequestsState, 'archivedRequests')}
    </>
  );

  const [archiveContent, setArchiveContent] = useState('archiveOffers');

  const isCustomizable =
    user &&
    user.attributes &&
    user.attributes.profile &&
    user.attributes.profile.metadata &&
    user.attributes.profile.metadata.enableCustomOffers;

  switch (params.tab) {
    default:
    case TAB_OVERVIEW:
      mainContent = (
        <div className={css.content}>
          <h1 className={css.header}>{getMessage('title')}</h1>
          <p>{getMessage('description')}</p>
          <div className={css.headerAndButtonContainer}>
            <h2 className={css.grayHeader}>{getMessage('outreachOffers')}</h2>
            <div className={css.buttonContainer}>
              <LiteButton
                className={css.createBtn}
                icon={ICON_PLUS}
                onClick={() => handleCreateOfferType()}
              >
                {getMessage('createOffer')}
              </LiteButton>
              {sorter(offersState, 'activeOffers').menu}
              {sorter(offersState, 'activeOffers').arrow}
              {/* <LiteButton
                className={css.sortBtn}
                icon={ICON_DOWN_ARROW}
                onClick={() => handleSortOffers()}
              >
                Latest first
              </LiteButton> */}
            </div>
          </div>
          <OutreachOffers
            onDeleteOutreachOffer={handleDeleteOffer}
            onDeactivateOutreachOffer={handleDeactivateOutreachOffer}
            onEditOutreachOffer={handleEditOutreachOffer}
            onArchiveOutreachOffer={handleArchiveOutreachOffer}
            onUnarchiveOutreachOffer={handleUnarchiveOutreachOffer}
            onReactivateOutreachOffer={handleReactivateOutreachOffer}
            onReactivateCapReachedOutreachOffer={handleOpenReactivateOfferModal}
            onViewOfferDetails={(id) => navigateToTab(TAB_OFFER_DETAIL, id)}
            outreachOffers={returnSix(
              outreachOffers,
              false,
              offersState.page,
              offersState.order,
              offersState.reverse
            )}
            outreachRequests={outreachRequests}
            timezone={timezone}
            archived={false}
          />
          <div className={css.paginatorAndbuttonContainer}>
            {paginate(outreachOffers, false, offersState, 'activeOffers')}
            <LiteButton
              className={css.downloadBtn}
              icon={ICON_DOWNLOAD}
              onClick={() => downloadOffers()}
            >
              Download CSV
            </LiteButton>
          </div>
        </div>
      );
      break;
    case TAB_CONFIRMED:
      mainContent = (
        <div className={css.content}>
          <h1 className={css.header}>{getMessage('titleConfirmed')}</h1>
          <p>{getMessage('descriptionConfirmed')}</p>
          <div className={css.headerAndButtonContainer}>
            <h2 className={css.grayHeader}>{getMessage('outreachRequests')}</h2>
            <div className={css.buttonContainer}>
              <LiteButton
                className={css.downloadBtn}
                icon={ICON_DOWNLOAD}
                onClick={() =>
                  downloadRequests(outreachRequests, outreachOffers, approvedNonprofitNameMap)
                }
              >
                Download CSV
              </LiteButton>
              {sorter(requestsState, 'activeRequests').menu}
              {sorter(requestsState, 'activeRequests').arrow}
            </div>
          </div>
          <OutreachRequests
            getListing={getListing}
            onDeleteRequest={handleDeleteRequest}
            onDiscardRequest={handleDiscardOutreachRequest}
            onArchiveRequest={handleArchiveOutreachRequest}
            onProcessRequest={handleProcessOutreachRequest}
            onViewRequestDetails={(id) => navigateToTab(TAB_REQUEST_DETAIL, id)}
            outreachRequests={returnSix(
              outreachRequests,
              false,
              requestsState.page,
              requestsState.order,
              requestsState.reverse
            )}
            outreachOffers={outreachOffers}
            nonprofitNameMap={approvedNonprofitNameMap}
            timezone={timezone}
          />
          {paginate(outreachRequests, false, requestsState, 'activeRequests')}
        </div>
      );
      break;
    case TAB_ARCHIVE:
      mainContent = (
        <div className={css.content}>
          <h1 className={css.header}>Archive</h1>
          <div className={css.switcher}>
            <span
              className={(css.switcherLink, archiveContent === 'archiveOffers' && css.active)}
              onClick={() => setArchiveContent('archiveOffers')}
            >
              Created Donation Offers
            </span>
            <span
              className={(css.switcherLink, archiveContent === 'archiveRequests' && css.active)}
              onClick={() => setArchiveContent('archiveRequests')}
            >
              Confirmed offers
            </span>
          </div>
          {archiveContent === 'archiveOffers' && archiveOffers}
          {archiveContent === 'archiveRequests' && archiveRequests}
        </div>
      );
      break;
    case TAB_CREATE_OFFER:
      mainContent = (
        <div>
          {buttonBack}
          <OutreachOfferForm
            unsplashAttrib={unsplashAttrib}
            unsplashDownloadLink={unsplashDownloadLink}
            customizable={isCustomizable}
            setIsImageInstructionModalOpen={setIsImageInstructionModalOpen}
            setIsBackgroundImageModalOpen={setIsBackgroundImageModalOpen}
            setIsLogoImageModalOpen={setIsLogoImageModalOpen}
            uploadedBackgroundImageUrl={confirmedUploadedBackgroundImage}
            uploadedLogoImageUrl={confirmedUploadedLogoImage}
            createOutreachOfferInProgress={createOutreachOfferInProgress}
            currentUser={currentUser}
            isLoading={isLoading || spinning}
            ownListing={ownListing}
            nonprofits={approvedNonprofitNameMap}
            onCancel={() => navigateToTab(TAB_OVERVIEW)}
            onSubmit={handleCreateOffer}
            template={offerTypes.filter((offer) => offer.value === offerType)[0]}
          />
        </div>
      );
      break;
    case TAB_EDIT_OFFER:
      mainContent = (
        <div>
          {buttonBack}
          <OutreachOfferForm
            unsplashAttrib={unsplashAttrib}
            unsplashDownloadLink={unsplashDownloadLink}
            customizable={isCustomizable}
            setIsImageInstructionModalOpen={setIsImageInstructionModalOpen}
            setIsBackgroundImageModalOpen={setIsBackgroundImageModalOpen}
            setIsLogoImageModalOpen={setIsLogoImageModalOpen}
            uploadedBackgroundImageUrl={confirmedUploadedBackgroundImage}
            uploadedLogoImageUrl={confirmedUploadedLogoImage}
            createOutreachOfferInProgress={createOutreachOfferInProgress}
            currentUser={currentUser}
            isLoading={isLoading || spinning}
            ownListing={ownListing}
            nonprofits={approvedNonprofitNameMap}
            onCancel={() => navigateToTab(TAB_OVERVIEW)}
            onSubmit={handleUpdateOffer}
            template={offerTypes.filter((offer) => offer.value === offerType)[0]}
            prePopulate={outreachOffers.filter((offer) => offer.id === params.id)[0]}
            outreachRequests={outreachRequests.filter((req) => req.outreachOfferId === params.id)}
          />
        </div>
      );
      break;
    case TAB_OFFER_DETAIL:
      mainContent = outreachOffer ? (
        <div>
          <OutreachOfferDetails
            onBack={(tab) => navigateToTab(tab)}
            editDonationOffer={(id) => handleEditOutreachOfferById(id)}
            details={outreachOffer}
            timezone={timezone}
            nonprofits={approvedNonprofitNameMap}
            outreachRequests={outreachRequests}
            currentUser={currentUser}
          />
          {returnAllRequests(outreachRequests).filter(
            (request) => request.outreachOfferId === outreachOffer.id
          ).length > 0 && (
            <LiteButton
              style={css.outreachDetailRequestDownloadButton}
              className={css.downloadBtn}
              icon={ICON_DOWNLOAD}
              onClick={() =>
                downloadRequests(
                  returnAllRequests(outreachRequests),
                  [outreachOffer],
                  approvedNonprofitNameMap,
                  `Donation offer report ${moment().format('MM/DD/YYYY')}`
                )
              }
            >
              Download CSV
            </LiteButton>
          )}
          <OutreachRequests
            onViewRequestDetails={(id) => navigateToTab(TAB_REQUEST_DETAIL, id)}
            outreachRequests={returnAllRequests(outreachRequests)}
            outreachOffers={[outreachOffer]}
            onDeleteRequest={handleDeleteRequest}
            onDiscardRequest={handleDiscardOutreachRequest}
            onArchiveRequest={handleArchiveOutreachRequest}
            onProcessRequest={handleProcessOutreachRequest}
            nonprofitNameMap={approvedNonprofitNameMap}
            timezone={timezone}
            getListing={getListing}
          />
        </div>
      ) : (
        <IconSpinner />
      );
      break;
    case TAB_REQUEST_DETAIL:
      mainContent =
        outreachRequest && linkedOutreachOffer ? (
          <>
            <OutreachRequestDetails
              onBack={(tab) => navigateToTab(tab)}
              nonprofitNameMap={approvedNonprofitNameMap}
              onDiscardRequest={handleDiscardOutreachRequest}
              onProcessRequest={handleProcessOutreachRequest}
              outreachRequest={outreachRequest}
              outreachOffer={linkedOutreachOffer}
              timezone={timezone}
            />
          </>
        ) : (
          <IconSpinner />
        );
      break;
  }

  return user.id ? (
    <Page title="Outreach offers" scrollingDisabled={scrollingDisabled}>
      <LayoutSideNavigation>
        <LayoutWrapperTopbar>
          <TopbarContainer
            currentPage="ContactDetailsPage"
            desktopClassName={css.desktopTopbar}
            mobileClassName={css.mobileTopbar}
          />
          {/* <UserNav currentUser={user} selectedPageName="OutreachOffersPage" /> */}
        </LayoutWrapperTopbar>
        <LayoutWrapperOutreachSettingsSideNav
          tab={params.tab}
          className={css.sideNav}
          currentTab="OutreachOffersPage"
          currentUser={user}
        />
        <LayoutWrapperMain className={css.mainContent}>{mainContent}</LayoutWrapperMain>
        <LayoutWrapperFooter>
          <Footer />
        </LayoutWrapperFooter>
      </LayoutSideNavigation>
      {spinning ? <h1>Loading</h1> : null}
      {capBreachConfirmationModal}
      {reactivateOfferConfirmationModal}
      {/* {offerTypeSelectionModal} */}
      {archiveActiveOfferConfirmationModal}
      {capAboutToBeReachedConfirmationModal}
      {imageInstructionsModal}
      {backgroundImageSelectModal}
      {logoImageSelectModal}
    </Page>
  ) : null;
};

OutreachOffersPage.propTypes = {
  history: shape({
    push: func.isRequired,
  }).isRequired,
  outreachOffers: array,
  outreachRequests: array,
  params: shape({
    tab: string.isRequired,
  }),
  queryOutreachOffersInProgress: bool,
  queryOutreachRequestsInProgress: bool,
};

const mapStateToProps = (state) => {
  const { currentUser, currentUserListing, timezone } = state.user;
  const { approvedNonprofitNameMap } = state.NonprofitListing;
  const {
    createOutreachOfferInProgress,
    outreachOffers,
    outreachRequests,
    queryOutreachOffersInProgress,
    queryOutreachRequestsInProgress,
  } = state.outreach;

  const ensuredOwnListing = sharetribe.listing.ensureOwnListing(currentUserListing);
  const ensuredCurrentUser = sharetribe.user.ensureCurrentUser(currentUser);

  // Get listing method, retrieves the listing with the given UUID from the global state
  const getListing = (id) => {
    const ref = { id: new UUID(id), type: 'listing' };
    const listings = getMarketplaceEntities(state, [ref]);
    return listings.length === 1 ? listings[0] : null;
  };

  return {
    ownListing: ensuredOwnListing,
    currentUser: ensuredCurrentUser,
    isLoading: !(ensuredOwnListing.id && ensuredCurrentUser.id),
    getListing,
    approvedNonprofitNameMap,
    scrollingDisabled: isScrollingDisabled(state),
    timezone,
    createOutreachOfferInProgress,
    outreachOffers,
    outreachRequests,
    queryOutreachOffersInProgress,
    queryOutreachRequestsInProgress,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onCreateOffer: (details) => dispatch(createOutreachOffer(details)),
    onUploadOfferImage: (obj) => dispatch(uploadOfferImage(obj)),
    onQueryUnsplash: (obj) => dispatch(queryUnsplash(obj)),
    onQueryUnsplashForGivslyCollection: (obj) => dispatch(queryUnsplashForGivslyCollection(obj)),
    onPingUnsplashDownload: (obj) => dispatch(pingUnsplashDownload(obj)),
    onDeleteOffer: (id) => dispatch(deleteOutreachOffer(id)),
    onDeleteRequest: async (id) => {
      await dispatch(deleteOutreachRequest(id));
      await dispatch(queryOutreachRequests());
    },
    onRefreshOffers: () => dispatch(queryOutreachOffers()),
    onRefreshRequests: () => dispatch(queryOutreachRequests()),
    onUpdateOutreachRequest: (outreachRequest) => dispatch(updateOutreachRequest(outreachRequest)),
    onUpdateOutreachOffer: (outreachOffer) => dispatch(updateOutreachOffer(outreachOffer)),
  };
};

OutreachOffersPage.loadData = () => async (dispatch) => {
  dispatch(fetchAllNonprofits(1, true));
  dispatch(queryOutreachOffers());
  dispatch(queryOutreachRequests());
  fetchCurrentUser();
};

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(OutreachOffersPage);
