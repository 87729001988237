import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { ensureCurrentUser } from '../../util/data';
import { createResourceLocatorString } from '../../util/routes';
import routeConfiguration from '../../routeConfiguration';
import { PERSONAL_INFO } from '../../components/OnboardingWizard/constants';
import {
  LayoutSingleColumn,
  LayoutWrapperTopbar,
  LayoutWrapperMain,
  Page,
  Footer,
  LayoutWrapperFooter,
} from '../../components';
import { TopbarContainer } from '../index';
import { isScrollingDisabled } from '../../ducks/UI.duck';
import OnboardingCompletedContent from '../../components/OnboardingCompletedContent/OnBoardingCompletedContent';
import { trackEventAction } from '../../ducks/Analytics.duck';

export class OnboardingCompletedPageComponent extends Component {
  render() {
    const { scrollingDisabled, currentUser, history, intl, trackEvent } = this.props;

    const user = ensureCurrentUser(currentUser);

    // If onboarding is not completed, go to onboarding page
    if (user.id && !user.attributes.profile.publicData.onboardingCompleted) {
      history.push(
        createResourceLocatorString('OnboardingPage', routeConfiguration(), { tab: PERSONAL_INFO })
      );
    }

    return (
      <Page
        title={intl.formatMessage({ id: 'OnboardingPage.title' })}
        scrollingDisabled={scrollingDisabled}
      >
        <LayoutSingleColumn>
          <LayoutWrapperTopbar>
            <TopbarContainer currentPage="OnboardingCompletedPage" />
          </LayoutWrapperTopbar>
          <LayoutWrapperMain>
            <OnboardingCompletedContent trackEvent={trackEvent} />
          </LayoutWrapperMain>
          <LayoutWrapperFooter>
            <Footer />
          </LayoutWrapperFooter>
        </LayoutSingleColumn>
      </Page>
    );
  }
}

const mapStateToProps = (state) => ({
  currentUser: state.user,
  scrollingDisabled: isScrollingDisabled(state),
});

const mapDispatchToProps = (dispatch) => ({
  trackEvent: (params) => dispatch(trackEventAction(params)),
});

const OnboardingCompletedPage = compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  injectIntl
)(OnboardingCompletedPageComponent);

export default OnboardingCompletedPage;
