import { bool, node, oneOfType, string } from 'prop-types';
import React, { useState } from 'react';
import css from './Balloon.css';
import classNames from 'classnames';
import { BALLOON_STYLE_REGULAR } from './constants';

const Balloon = (props) => {
  const { balloonStyle, body, className, dismissible, header } = props;
  const [isDismissed, setIsDismissed] = useState(false);

  const classes = classNames(css.root, balloonStyle, className);
  return !isDismissed ? (
    <div className={classes}>
      {header ? <h3 className={css.header}>{header}</h3> : null}
      <p className={css.body}>{body}</p>
      {dismissible ? <button className={css.dismiss} onClick={() => setIsDismissed(true)} /> : null}
    </div>
  ) : null;
};

Balloon.propTypes = {
  balloonStyle: string,
  body: oneOfType([string, node]).isRequired,
  className: string,
  dismissible: bool,
  header: oneOfType([string, node]),
};

Balloon.defaultProps = {
  balloonStyle: BALLOON_STYLE_REGULAR,
  className: null,
  dismissible: false,
  header: null,
};

export default Balloon;
