export const PARTNERSHIP_TABS = {
  APPROVAL: 'approval',
  CAUSES: 'causes',
  COLLABORATIONS: 'collaborations',
  EXCLUSIONS: 'exclusions',
  ASSETS: 'assets',
  SUCCESS: 'success',
};

export const UPLOAD_MESSAGES = {
  DEFAULT: 'Upload an logo..',
  TOO_BIG: 'File is too big! Please choose another file.',
  WRONG_FORMAT: 'File has wrong format! Please choose another file.',
};

export const ACCEPT_IMAGES = 'image/*';
export const REACT_SELECT_STYLES = {
  placeholder: (styles) => ({
    ...styles,
    fontWeight: 300,
    color: '#b2b2b2',
  }),
  input: (styles) => ({
    ...styles,
    height: 48,
    display: 'inline-flex',
    alignItems: 'center',
    fontWeight: 300,
    borderColor: '#b2b2b2',
    borderWidth: 2,
  }),
  option: (styles) => ({
    ...styles,
    fontSize: 18,
    lineHeight: '40px',
    fontWeight: 300,
  }),
  multiValue: (styles) => ({
    ...styles,
    borderRadius: 4,
    overflow: 'hidden',
  }),
  multiValueLabel: () => ({
    backgroundColor: '#fac51d',
    padding: 8,
    paddingLeft: 16,
    fontSize: 14,
    height: 42,
  }),
  multiValueRemove: () => ({
    backgroundColor: '#fac51d',
    paddingRight: 8,
    paddingTop: 6,
    '& svg': {
      backgroundColor: ' #fff',
      borderRadius: 50,
      color: '#fac51d',
    },
  }),
};

export const IAB_OPTIONS = [
  { name: 'Books and Literature', code: 'IAB1-1', id: '42' },
  { name: 'Medical Health', code: 'IAB7', id: '286' },
  { name: 'News and Politics', code: 'IAB12', id: '379' },
  { name: 'Science', code: 'IAB15', id: '464' },
  { name: 'Shopping', code: 'IAB22', id: '473' },
  { name: 'Television', code: 'IAB1-7', id: '640' },
  { name: 'Careers', code: 'IAB4', id: '123' },
  { name: 'Education', code: 'IAB5', id: '132' },
  { name: 'Family and Relationships', code: 'IAB6', id: '186' },
  { name: 'Hobbies & Interests', code: 'IAB9', id: '239' },
  { name: 'Music and Audio', code: 'IAB1-6', id: '338' },
  { name: 'Personal Finance', code: 'IAB13', id: '391' },
  { name: 'Pop Culture', code: 'IAB1-2', id: '432' },
  { name: 'Real Estate', code: 'IAB21', id: '441' },
  { name: 'Travel', code: 'IAB20', id: '653' },
  { name: 'Home & Garden', code: 'IAB10', id: '274' },
  { name: 'Pets', code: 'IAB16', id: '422' },
  { name: 'Fine Art', code: 'IAB1-3', id: '201' },
  { name: 'Food & Drink', code: 'IAB8', id: '210' },
  { name: 'Healthy Living', code: 'IAB7-38', id: '223' },
  { name: 'Movies', code: 'IAB1-5', id: '324' },
  { name: 'Religion & Spirituality', code: 'IAB23-10', id: '453' },
  { name: 'Sports', code: 'IAB17', id: '483' },
  { name: 'Style & Fashion', code: 'IAB18', id: '552' },
  { name: 'Technology & Computing', code: 'IAB19', id: '596' },
  { name: 'Automotive', code: 'IAB2', id: '1' },
  { name: 'Business and Finance', code: 'IAB13-3', id: '52' },
  { name: 'Video Gaming', code: 'IAB9-30', id: '680' },
];

export const EVENT_TEMPLATE = {
  name: '',
  url: '',
  info: '',
  start: {},
  end: {},
  yearRound: [],
};

export const BRAND_TEMPLATE = {
  name: '',
  domain: '',
};

export const IMPACT_AREA_TEMPLATE = {
  region: 'Northern America',
  country: 'US',
  state: '',
  city: '',
};

export const EXCLUDED_AREA_TEMPLATE = {
  country: '',
  state: '',
  city: '',
};

export const ONBOARDING_STEPS = {
  PARTNERSHIP_INFORMATION: 'PARTNERSHIP_INFORMATION',
  CAUSES_AND_EVENTS: 'CAUSES_AND_EVENTS',
  BRAND_COLLABORATIONS: 'BRAND_COLLABORATIONS',
  EXCLUSIONS: 'EXCLUSIONS',
};
