import React from 'react';
import css from './ImpactCard.css';
import { string } from 'prop-types';
import classNames from 'classnames';
import { ICON_PEOPLE } from './constants';

const ImpactCard = (props) => {
  const { icon, label, value } = props;
  return (
    <div className={css.root}>
      <span className={classNames(css.icon, icon)} />
      <span className={css.label}>{label}</span>
      <span className={css.value}>{value}</span>
    </div>
  );
};

ImpactCard.propTypes = {
  icon: string,
  label: string.isRequired,
  value: string.isRequired,
};

ImpactCard.defaultProps = {
  icon: ICON_PEOPLE,
};

export default ImpactCard;
