import React, { Component } from 'react';
import { object, string, bool, number, func, shape, array, arrayOf, oneOf } from 'prop-types';
import classNames from 'classnames';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import { withRouter } from 'react-router-dom';
import omit from 'lodash/omit';

import routeConfiguration from '../../routeConfiguration';
import { createResourceLocatorString } from '../../util/routes';
import {
  ModalInMobile,
  Button,
  SelectMultipleFilter,
  SearchLocationFilterMobile,
  SelectSingleFilter,
} from '../../components';
import { propTypes } from '../../util/types';
import css from './SearchFiltersMobile.css';
import SearchAvailabilityFilter from '../SearchAvailabilityFilter/SearchAvailabilityFilter';
import {
  FILTER_AVAILABILITY,
  FILTER_AVAILABILITY_EVENT,
  FILTER_EVENT_ROLES,
  FILTER_INDUSTRY,
  FILTER_INTERESTS,
  FILTER_LOCATION,
  FILTERS,
  PARAM_END,
  PARAM_START,
} from '../SearchFilters/SearchFilters';
import { getDateSelectOptions } from '../../util/dates';
import moment from 'moment';

const EVENT_DATE_FILTER_FORMAT = 'ddd, D MMM';
const EVENT_DATE_FILTER_KEY_FORMAT = 'YYYYMMDD';

class SearchFiltersMobileComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isFiltersOpenOnMobile: false,
      initialQueryParams: null,
      isLocationModalOpen: false,
    };

    this.openFilters = this.openFilters.bind(this);
    this.cancelFilters = this.cancelFilters.bind(this);
    this.closeFilters = this.closeFilters.bind(this);
    this.resetAll = this.resetAll.bind(this);
    this.handleAvailabilitySelection = this.handleAvailabilitySelection.bind(this);
    this.handleAvailabilityEventDateSelection = this.handleAvailabilityEventDateSelection.bind(
      this
    );
    this.handleSelectSingle = this.handleSelectSingle.bind(this);
    this.handleSelectMultiple = this.handleSelectMultiple.bind(this);
    this.initialValue = this.initialValue.bind(this);
    this.initialValues = this.initialValues.bind(this);
  }

  handleAvailabilityEventDateSelection(urlParam, date) {
    if (!date) {
      this.handleAvailabilitySelection(urlParam, null);
      return null;
    }

    const options = {
      start: moment.tz(date, this.props.timezone).startOf('day').toDate().toISOString(),
      end: moment.tz(date, this.props.timezone).endOf('day').toDate().toISOString(),
    };

    return this.handleAvailabilitySelection(urlParam, options);
  }

  handleAvailabilitySelection(urlParam, options) {
    const { history, urlQueryParams, pageName, pathParams } = this.props;
    const queryParams =
      options !== null && typeof options === 'object'
        ? {
            ...urlQueryParams,
            [PARAM_START]: options.start,
            [PARAM_END]: options.end,
          }
        : omit(omit(urlQueryParams, PARAM_START), PARAM_END);

    history.push(
      createResourceLocatorString(pageName, routeConfiguration(), pathParams, queryParams)
    );
  }

  // Open filters modal, set the initial parameters to current ones
  openFilters() {
    const { onOpenModal, urlQueryParams } = this.props;
    onOpenModal();
    this.setState({ isFiltersOpenOnMobile: true, initialQueryParams: urlQueryParams });
  }

  // Close the filters by clicking cancel, revert to the initial params
  cancelFilters() {
    const { history, onCloseModal, pageName, pathParams } = this.props;

    history.push(
      createResourceLocatorString(
        pageName,
        routeConfiguration(),
        pathParams,
        this.state.initialQueryParams
      )
    );
    onCloseModal();
    this.setState({ isFiltersOpenOnMobile: false, initialQueryParams: null });
  }

  // Close the filter modal
  closeFilters() {
    this.props.onCloseModal();
    this.setState({ isFiltersOpenOnMobile: false });
  }

  handleSelectSingle(urlParam, option) {
    const { urlQueryParams, history, pageName, pathParams } = this.props;

    // query parameters after selecting the option
    // if no option is passed, clear the selection for the filter
    const queryParams = option
      ? { ...urlQueryParams, [urlParam]: option }
      : omit(urlQueryParams, urlParam);

    history.push(
      createResourceLocatorString(pageName, routeConfiguration(), pathParams, queryParams)
    );
  }

  handleSelectMultiple(urlParam, options) {
    const { urlQueryParams, history, pageName, pathParams } = this.props;

    const queryParams =
      options && options.length > 0
        ? {
            ...urlQueryParams,
            [urlParam]:
              (urlParam === 'pub_industry' || urlParam === 'pub_eventRoles' ? '' : 'has_any:') +
              options.join(','),
          }
        : omit(urlQueryParams, urlParam);

    history.push(
      createResourceLocatorString(pageName, routeConfiguration(), pathParams, queryParams)
    );
  }

  // Reset all filter query parameters
  resetAll(e) {
    const { urlQueryParams, history, filterParamNames, pageName, pathParams } = this.props;

    const queryParams = omit(urlQueryParams, filterParamNames);
    history.push(
      createResourceLocatorString(pageName, routeConfiguration(), pathParams, queryParams)
    );

    // blur event target if event is passed
    if (e && e.currentTarget) {
      e.currentTarget.blur();
    }
  }

  get eventDateFilterOptions() {
    if (!this.props.event) return [];

    const { active } = this.props.event;

    if (!active.from || !active.to) return [];

    return getDateSelectOptions(
      active.from,
      active.to,
      EVENT_DATE_FILTER_KEY_FORMAT,
      EVENT_DATE_FILTER_FORMAT
    );
  }

  // resolve initial value for a single value filter
  initialValue(paramName) {
    return this.props.urlQueryParams[paramName];
  }

  // resolve initial values for a multi value filter
  initialValues(paramName) {
    const urlQueryParams = this.props.urlQueryParams;
    return !!urlQueryParams[paramName] ? urlQueryParams[paramName].split(',') : [];
  }

  showFilter(filterName) {
    return (
      !this.props.showOnlySelectedFilters || this.props.showOnlySelectedFilters.includes(filterName)
    );
  }

  render() {
    const {
      rootClassName,
      className,
      listingsAreLoaded,
      resultsCount,
      searchInProgress,
      showAsModalMaxWidth,
      onManageDisableScrolling,
      selectedFiltersCount,
      industryFilter,
      interestsFilter,
      eventRolesFilter,
      intl,
      urlQueryParams,
      history,
      pageName,
      pathParams,
      event,
    } = this.props;

    const classes = classNames(rootClassName || css.root, className);

    const resultsFound = (
      <FormattedMessage id="SearchFilters.foundResults" values={{ count: resultsCount }} />
    );
    const noResults = <FormattedMessage id="SearchFilters.noResultsMobile" />;
    const loadingResults = <FormattedMessage id="SearchFilters.loadingResultsMobile" />;
    const filtersHeading = intl.formatMessage({ id: 'SearchFiltersMobile.heading' });
    const modalCloseButtonMessage = intl.formatMessage({ id: 'SearchFiltersMobile.cancel' });

    const showListingsLabel = intl.formatMessage(
      { id: 'SearchFiltersMobile.showListings' },
      { count: resultsCount }
    );

    const filtersButtonClasses =
      selectedFiltersCount > 0 ? css.filtersButtonSelected : css.filtersButton;

    const placeName =
      urlQueryParams.placeName ||
      intl.formatMessage({
        id: `SearchFiltersMobile.anywhere`,
      });
    const [city, state] = placeName.split(', ');

    const locationFilterElement = (
      <div className={css.locationFilterWrapper}>
        <h2 className={css.locationHeader}>
          <FormattedMessage id="SearchFiltersMobile.locationHeader" />
        </h2>
        <div className={css.locationRow}>
          <div className={css.selectedLocation}>
            <span className={css.place}>{city}</span>
            <span className={css.region}>{state}</span>
          </div>
          <SearchLocationFilterMobile
            history={history}
            intl={intl}
            urlQueryParams={urlQueryParams}
            buttonContent={<FormattedMessage id="SearchFiltersMobile.locationChangeBtn" />}
            pageName={pageName}
            pathParams={pathParams}
          />
        </div>
      </div>
    );

    const industryLabel = intl.formatMessage({
      id: 'SearchFiltersMobile.industryLabel',
    });

    const initialIndustry = this.initialValues(industryFilter.paramName);

    const industryFilterElement = industryFilter ? (
      <SelectMultipleFilter
        id="SearchFiltersMobile.industryFilter"
        name="industry"
        urlParam={industryFilter.paramName}
        label={industryLabel}
        onSubmit={this.handleSelectMultiple}
        liveEdit
        options={industryFilter.options}
        initialValues={initialIndustry}
      />
    ) : null;

    const interestsLabel = intl.formatMessage({
      id: 'SearchFiltersMobile.interestsLabel',
    });

    const initialInterests = this.initialValues(interestsFilter.paramName);

    const interestsFilterElement = interestsFilter ? (
      <SelectMultipleFilter
        id="SearchFiltersMobile.interestsFilter"
        name="interests"
        urlParam={interestsFilter.paramName}
        label={interestsLabel}
        onSubmit={this.handleSelectMultiple}
        liveEdit
        options={interestsFilter.options}
        initialValues={initialInterests}
      />
    ) : null;

    const initialEventRole = eventRolesFilter && this.initialValue(eventRolesFilter.paramName);

    const eventRolesFilterElement = eventRolesFilter ? (
      <SelectSingleFilter
        id="SearchFiltersMobile.eventRolesFilter"
        name="eventRoles"
        options={eventRolesFilter.options}
        urlParam={eventRolesFilter.paramName}
        label={intl.formatMessage({ id: 'SearchFiltersMobile.allAttendeesLabel' })}
        onSelect={this.handleSelectSingle}
        initialValue={initialEventRole}
      />
    ) : null;

    const initialAvailabilityFilterValues =
      urlQueryParams.start && urlQueryParams.end
        ? {
            start: urlQueryParams.start,
            end: urlQueryParams.end,
          }
        : {};

    return (
      <>
        <div className={classes}>
          <div className={css.searchResultSummary}>
            {listingsAreLoaded && resultsCount > 0 ? resultsFound : null}
            {listingsAreLoaded && resultsCount === 0 ? noResults : null}
            {searchInProgress ? loadingResults : null}
          </div>
          <div className={css.buttons}>
            <Button rootClassName={filtersButtonClasses} onClick={this.openFilters}>
              <FormattedMessage id="SearchFilters.filtersButtonLabel" className={css.mapIconText} />
            </Button>
          </div>
        </div>
        <ModalInMobile
          id="SearchFiltersMobile.filters"
          isModalOpenOnMobile={this.state.isFiltersOpenOnMobile}
          onClose={this.cancelFilters}
          showAsModalMaxWidth={showAsModalMaxWidth}
          onManageDisableScrolling={onManageDisableScrolling}
          containerClassName={css.modalContainer}
          closeButtonMessage={modalCloseButtonMessage}
        >
          <div className={css.modalHeadingWrapper}>
            <span className={css.modalHeading}>{filtersHeading}</span>
            <button className={css.resetAllButton} onClick={(e) => this.resetAll(e)}>
              <FormattedMessage id={'SearchFiltersMobile.resetAll'} />
            </button>
          </div>
          {this.state.isFiltersOpenOnMobile ? (
            <div className={css.filtersWrapper}>
              {this.showFilter(FILTER_LOCATION) && locationFilterElement}
              {this.showFilter(FILTER_AVAILABILITY) && (
                <div className={css.availabilityFilterWrapper}>
                  <h2 className={css.locationHeader}>
                    <FormattedMessage id="SearchFiltersMobile.availabilityHeader" />
                  </h2>
                  <SearchAvailabilityFilter
                    id={'SearchFiltersMobile.availabilityFilter'}
                    initialValues={initialAvailabilityFilterValues}
                    isMobile={true}
                    onSubmit={this.handleAvailabilitySelection}
                  />
                </div>
              )}
              {this.showFilter(FILTER_AVAILABILITY_EVENT) && (
                <div className={css.availabilityFilterWrapper}>
                  <h2 className={css.locationHeader}>
                    <FormattedMessage id="SearchFiltersMobile.availabilityHeader" />
                  </h2>
                  <SearchAvailabilityFilter
                    id={'SearchFiltersMobile.availabilityFilter'}
                    initialValues={initialAvailabilityFilterValues}
                    isMobile={true}
                    onSubmit={this.handleAvailabilitySelection}
                    minimumDate={
                      event && moment.tz(event.active.from, event.timezone).startOf('day')
                    }
                    maximumDate={event && moment.tz(event.active.to, event.timezone).startOf('day')}
                    showPreDefinedOptions={false}
                  />
                </div>
              )}
              {this.showFilter(FILTER_EVENT_ROLES) && eventRolesFilterElement}
              {this.showFilter(FILTER_INDUSTRY) && industryFilterElement}
              {this.showFilter(FILTER_INTERESTS) && interestsFilterElement}
            </div>
          ) : null}

          <div className={css.showListingsContainer}>
            <Button
              className={css.showListingsButton}
              inProgress={searchInProgress}
              onClick={this.closeFilters}
            >
              {showListingsLabel}
            </Button>
          </div>
        </ModalInMobile>
      </>
    );
  }
}

SearchFiltersMobileComponent.defaultProps = {
  rootClassName: null,
  className: null,
  resultsCount: null,
  searchInProgress: false,
  selectedFiltersCount: 0,
  filterParamNames: [],
  industryFilter: null,
  interestsFilter: null,
  categoryFilter: null,
  amenitiesFilter: null,
  priceFilter: null,
  pageName: 'SearchPage',
  pathParams: {},
};

SearchFiltersMobileComponent.propTypes = {
  event: propTypes.event,
  rootClassName: string,
  className: string,
  urlQueryParams: object.isRequired,
  listingsAreLoaded: bool.isRequired,
  resultsCount: number,
  searchingInProgress: bool,
  showAsModalMaxWidth: number.isRequired,
  onManageDisableScrolling: func.isRequired,
  onOpenModal: func.isRequired,
  onCloseModal: func.isRequired,
  searchInProgress: bool,
  selectedFiltersCount: number,
  filterParamNames: array,
  industryFilter: propTypes.filterConfig,
  interestsFilter: propTypes.filterConfig,
  timezone: string.isRequired,

  // from injectIntl
  intl: intlShape.isRequired,

  // from withRouter
  history: shape({
    push: func.isRequired,
  }).isRequired,

  pageName: string,
  pathParams: object,
  showOnlySelectedFilters: arrayOf(oneOf(FILTERS)),
};

const SearchFiltersMobile = injectIntl(withRouter(SearchFiltersMobileComponent));

export default SearchFiltersMobile;
