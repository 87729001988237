import React from 'react';
import { Button, ListingCard } from '../../components';
import classNames from 'classnames';
import { arrayOf, bool, func, node, object, shape, string } from 'prop-types';
import NonprofitFeatureCard from '../../components/NonprofitFeatureCard/NonprofitFeatureCard';
import css from './FeatureBlockWithListings.css';
import { intlShape } from '../../util/reactIntl';
import { AVATAR_SIZES_LARGE } from '../Avatar/Avatar';
import { types as sdkTypes } from '../../util/sdkLoader';
const { UUID } = sdkTypes;

const CYCLE_INTERVAL = 3000;

class FeatureBlockWithListings extends React.Component {
  constructor(props) {
    super(props);

    this.cycle = this.cycle.bind(this);

    const cycleInterval = setInterval(this.cycle, CYCLE_INTERVAL);
    this.state = {
      activeListingIndex: 0,
      cycleInterval,
      sliderHeight: 'auto',
      sliderTopPosition: '64px',
    };

    this.slideContainer = React.createRef();
    this.contentContainer = React.createRef();
  }

  componentDidMount() {
    this.setSliderHeight();

    if (!this.props.isNonprofit) {
      window.addEventListener('resize', this.setSliderHeight);
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.children === this.props.children) {
      return;
    }

    this.setSliderHeight();
  }

  componentWillUnmount() {
    clearInterval(this.state.cycleInterval);
    window.removeEventListener('resize', this.setSliderHeight);
  }

  setSliderHeight() {
    if (this.props.isNonprofit) return;

    // Forces the slides to be same size
    this.setState({ sliderHeight: 'auto', sliderTopPosition: '64px' });

    const isMobile = document.body.clientWidth < 1024;

    if (isMobile) {
      this.setState({ sliderTopPosition: '-48px' });
      return;
    }

    const sliderHeight =
      this.slideContainer &&
      this.slideContainer.current &&
      this.slideContainer.current.clientHeight;

    const contentHeight =
      this.contentContainer &&
      this.contentContainer.current &&
      this.contentContainer.current.clientHeight;

    if (!sliderHeight || !contentHeight) {
      return;
    }

    const sliderTopPosition = (contentHeight - sliderHeight) / 2 - 15;

    this.setState({
      sliderHeight: `${sliderHeight}px`,
      sliderTopPosition: `${sliderTopPosition}px`,
    });
  }

  cycle() {
    const { listingIds } = this.props;

    if (!listingIds.length) return;

    const index = listingIds.indexOf(this.activeListing);
    const newIndex = index < listingIds.length - 1 ? index + 1 : 0;
    this.setState({
      activeListingIndex: newIndex,
    });
  }

  get activeListing() {
    return this.props.listingIds[this.state.activeListingIndex];
  }

  get cards() {
    const {
      listingIds,
      getListing,
      isNonprofit,
      history,
      nonprofitNameMap,
      intl,
      trackEvent,
    } = this.props;

    return listingIds.map((id) => {
      const classes = classNames(css.featureCard);

      const listing = getListing(new UUID(id));
      if (!listing || !listing.id) return null;

      const slideClasses = classNames(css.featureCardSlide, {
        [css.featureCardSlideActive]: this.activeListing === id,
      });

      if (isNonprofit) {
        return (
          <div className={slideClasses} key={`feature-${listing.id.uuid}`}>
            <NonprofitFeatureCard
              className={classes}
              history={history}
              listing={listing}
              trackEvent={trackEvent}
            />
          </div>
        );
      }

      return (
        <div className={slideClasses} key={`feature-${listing.id.uuid}`}>
          <ListingCard
            className={classes}
            intl={intl}
            listing={listing}
            npoListingNames={nonprofitNameMap}
            avatarSize={AVATAR_SIZES_LARGE}
            maxInterestRows={2}
            trackEvent={trackEvent}
          />
        </div>
      );
    });
  }

  get dots() {
    const { listingIds } = this.props;

    if (listingIds.length <= 1) return null;

    return listingIds.map((id) => {
      if (!id) return null;

      const classes = classNames(
        css.featureCardsLink,
        this.activeListing === id ? css.featureCardsLinkActive : null
      );
      return (
        <Button
          className={classes}
          key={`feature-select-${id}`}
          onClick={() => {
            this.setState({ activeListingIndex: listingIds.indexOf(id) });
            clearInterval(this.state.cycleInterval);
          }}
          type="button"
        />
      );
    });
  }

  get content() {
    if (this.state.activeListingIndex >= this.props.contents.length) {
      return this.props.contents[this.props.contents.length - 1];
    }

    return this.props.contents[this.state.activeListingIndex];
  }

  render() {
    const { footer, featureContentClassName, featureContentStyle } = this.props;

    return (
      <div className={classNames(css.sectionContent, css.feature)}>
        <div
          className={classNames(featureContentClassName, css.featureContent)}
          ref={this.contentContainer}
          style={featureContentStyle}
        >
          {this.content}
        </div>
        <div
          className={css.featureCardContainer}
          ref={this.slideContainer}
          style={{ height: this.state.sliderHeight, top: this.state.sliderTopPosition }}
        >
          {this.cards}
        </div>
        <div className={css.featureCardsBrowser}>{this.dots}</div>
        <div className={css.footer}>{footer}</div>
      </div>
    );
  }
}

FeatureBlockWithListings.defaultProps = {
  isNonprofit: false,
  buttons: [],
  contents: [],
};

FeatureBlockWithListings.propTypes = {
  contents: arrayOf(node).isRequired,
  history: shape({
    push: func.isRequired,
  }).isRequired,
  listingIds: arrayOf(string).isRequired,
  getListing: func.isRequired,
  isNonprofit: bool,
  intl: intlShape.isRequired,
  nonprofitNameMap: object,
  footer: node,
  featureContentClassName: string,
  featureContentStyle: object,
  trackEvent: func,
};

export default FeatureBlockWithListings;
