import React from 'react';
import { arrayOf, bool, node, object, string } from 'prop-types';
import classNames from 'classnames';
import { NamedLink } from '../../components';

import css from './TabNav.css';

const Tab = (props) => {
  const {
    className,
    id,
    disabled,
    text,
    selected,
    linkProps,
    completed,
    wider,
    type = 'NamedLink',
  } = props;
  const linkClasses = classNames(css.link, {
    [css.selectedLink]: selected,
    [css.completedLink]: completed && !selected,
    [css.disabled]: disabled,
    [css.wider]: wider,
  });

  if (type === 'anchor') {
    return (
      <div id={id} className={className}>
        <a className={linkClasses} {...linkProps}>
          {text}
        </a>
      </div>
    );
  }

  return (
    <div id={id} className={className}>
      <NamedLink className={linkClasses} {...linkProps}>
        {text}
      </NamedLink>
    </div>
  );
};

Tab.defaultProps = {
  className: null,
  completed: false,
  disabled: false,
  selected: false,
  wider: false,
  type: 'NamedLink',
};

Tab.propTypes = {
  className: string,
  completed: bool,
  disabled: bool,
  id: string.isRequired,
  linkProps: object.isRequired,
  selected: bool,
  text: node.isRequired,
  type: string,
};

const TabNav = (props) => {
  const { className, rootClassName, tabRootClassName, tabs } = props;
  const classes = classNames(rootClassName || css.root, className);
  const tabClasses = tabRootClassName || css.tab;
  return (
    <nav className={classes}>
      {tabs.map((tab, index) => {
        const id = typeof tab.id === 'string' ? tab.id : `${index}`;
        return <Tab key={id} id={id} className={tabClasses} {...tab} />;
      })}
    </nav>
  );
};

TabNav.defaultProps = {
  className: null,
  rootClassName: null,
  tabRootClassName: null,
  tabClassName: null,
};

TabNav.propTypes = {
  className: string,
  rootClassName: string,
  tabRootClassName: string,
  tabs: arrayOf(object).isRequired,
};

export default TabNav;
