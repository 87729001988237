import React from 'react';
import { ExternalLink, NonprofitSearch } from '../../components';
import config from '../../config';
import { validateSubscription } from '../../util/validateSubsccription';
import css from './Hero.css';

export const Hero = ({ currentUser }) => {
  const hasValidSubscription = validateSubscription(currentUser);
  return (
    <div className={css.root}>
      <div className={css.content}>
        <h1 className={css.headLine}>Meet our nonprofits and their inspiring missions.</h1>
        <h2 className={css.subHeader}>
          Givsly offers a new way to support your favorite nonprofits from the comfort of your home
          or office. Our tools help you connect your business goals with your social impact goals.
        </h2>
        <h2 className={css.subHeader}>Find nonprofits who share a common mission.</h2>
        {hasValidSubscription && <NonprofitSearch className={css.search} id="hero" />}
        <p className={css.footNote}>
          {hasValidSubscription ? 'Or' : 'As a nonprofit -'}{' '}
          <ExternalLink className={css.contactLink} href={config.custom.companySiteUrl}>
            contact us
          </ExternalLink>{' '}
          to add your organization to Givsly.
        </p>
      </div>
      <div className={css.image} />
    </div>
  );
};
