import React, { Component } from 'react';
import { node, shape, string } from 'prop-types';
import classNames from 'classnames';
import css from './BackgroundResponsiveContainer.css';

class BackgroundResponsiveContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      currentBreakpoint: 'small',
    };

    this.updateCurrentBreakpoint = this.updateCurrentBreakpoint.bind(this);
  }

  componentDidMount() {
    this.updateCurrentBreakpoint();
    window.addEventListener('resize', this.updateCurrentBreakpoint);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateCurrentBreakpoint);
  }

  updateCurrentBreakpoint() {
    this.setState({ currentBreakpoint: this.currentBreakpoint });
  }

  get currentBreakpoint() {
    const pageWidth = document.body.clientWidth;

    const breakpoints = {
      xlarge: 1921,
      large: 1024,
      medium: 768,
      small: 0,
    };

    const existingBackgroundBreakpoints = Object.keys(this.props.backgroundImageUrlSet);
    return Object.keys(breakpoints).find(
      (size) => existingBackgroundBreakpoints.includes(size) && pageWidth >= breakpoints[size]
    );
  }

  render() {
    const { backgroundImageUrlSet, ...otherProps } = this.props;

    return (
      <div {...otherProps} className={classNames(css.container)}>
        <div className={css.image}>
          <img src={backgroundImageUrlSet[this.state.currentBreakpoint]} alt="" />
        </div>
        <div className={css.content}>{this.props.children}</div>
      </div>
    );
  }
}

BackgroundResponsiveContainer.propTypes = {
  children: node.isRequired,
  backgroundImageUrlSet: shape({
    small: string.isRequired,
    medium: string,
    large: string,
    xlarge: string,
  }).isRequired,
  className: string,
};

export default BackgroundResponsiveContainer;
