import React from 'react';
import css from '../PartnershipsPage.css';
import { FormattedMessage } from '../../../util/reactIntl';
import { useMessages } from '../../../util/localization';
import RemoveFieldButton from '../../../components/NonprofitPreferencesPanel/RemoveFieldButton';
import LiteButton from '../../../components/LiteButton/LiteButton';
import { BRAND_TEMPLATE } from '../constants';
import { Button, FieldTextInput } from '../../../components';
import { ICON_PLUS, STYLE_LIGHT } from '../../../components/LiteButton/constants';

const CollaborationTab = (formRenderProps) => {
  const getMessage = useMessages('NonprofitPreferencesForm');
  const { values, form, isSubmitting, brandCollaborationsComplete } = formRenderProps;
  return (
    <div className={css.content}>
      <h1 className={css.title}>
        <FormattedMessage id="PartnershipsPage.CollaborationTab.title" />
      </h1>

      <section className={css.section}>
        <h3 className={css.sectionTitle}>
          {getMessage('section.partners')}
          <span className={css.optional}>{getMessage('optional')}</span>
        </h3>
        <p className={css.small}>{getMessage('paragraph.partners')}</p>
        {values.partners.map((brand, index) => (
          <div key={index} className={css.inputRow}>
            {index > 0 && <br />}
            <FieldTextInput
              className={css.field}
              id={`partners.${index}.name`}
              label={getMessage('field.partners.label')}
              name={`partners.${index}.name`}
              placeholder={getMessage('field.partners.placeholder')}
              type={'text'}
            />
            <RemoveFieldButton
              onClick={() =>
                form.change(
                  'partners',
                  values.partners.filter((_brand) => _brand !== brand)
                )
              }
            />
          </div>
        ))}
        <br />
        <LiteButton
          icon={ICON_PLUS}
          onClick={() => form.change('partners', [...values.partners, { ...BRAND_TEMPLATE }])}
          style={STYLE_LIGHT}
        >
          {getMessage('liteButton.new')}
        </LiteButton>
      </section>

      <section className={css.section}>
        <h3 className={css.sectionTitle}>
          {getMessage('section.collaborationWishes')}
          <span className={css.optional}>{getMessage('optional')}</span>
        </h3>
        <p className={css.small}>{getMessage('paragraph.collaborationWishes')}</p>
        {values.collaborationWishes.map((brand, index) => (
          <div key={index} className={css.inputRow}>
            {index > 0 && <br />}
            <FieldTextInput
              className={css.field}
              id={`collaborationWishes.${index}.name`}
              label={getMessage('field.collaborationWishes.label')}
              name={`collaborationWishes.${index}.name`}
              placeholder={getMessage('field.collaborationWishes.placeholder')}
              type={'text'}
            />
            <RemoveFieldButton
              onClick={() =>
                form.change(
                  'collaborationWishes',
                  values.collaborationWishes.filter((_brand) => _brand !== brand)
                )
              }
            />
          </div>
        ))}
        <br />
        <LiteButton
          icon={ICON_PLUS}
          onClick={() =>
            form.change('collaborationWishes', [
              ...values.collaborationWishes,
              { ...BRAND_TEMPLATE },
            ])
          }
          style={STYLE_LIGHT}
        >
          {getMessage('liteButton.new')}
        </LiteButton>
      </section>

      <section className={[css.section, css.stickySaveButtonSection].join(' ')}>
        <Button className={css.submit} type="submit" inProgress={isSubmitting}>
          {brandCollaborationsComplete ? (
            <FormattedMessage id="PartnershipsPage.CollaborationTab.submit" />
          ) : (
            <FormattedMessage id="PartnershipsPage.CollaborationTab.next" />
          )}
        </Button>
      </section>
    </div>
  );
};

export default CollaborationTab;
