import React, { Component } from 'react';
import { bool, func, object, shape, string } from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { injectIntl, intlShape } from '../../util/reactIntl';
import { propTypes } from '../../util/types';
import { ensureCurrentUser, ensureOwnListing, getSelectedNonprofits } from '../../util/data';
import { isScrollingDisabled, manageDisableScrolling } from '../../ducks/UI.duck';
import {
  Page,
  UserNav,
  LayoutWrapperTopbar,
  LayoutWrapperMain,
  LayoutWrapperFooter,
  LayoutWrapperMyProfileSideNav,
  Footer,
  MyProfileWizard,
} from '../../components';
import { TopbarContainer } from '../../containers';

import { updateProfile, uploadImage } from './MyProfilePage.duck';
import css from './MyProfilePage.css';
import LayoutSideNavigation from '../../components/LayoutSideNavigation/LayoutSideNavigation';
import { withRouter } from 'react-router-dom';
import { requestUpdateListing } from '../../ducks/UserListing.duck';
import { fetchCurrentUserListings } from '../../ducks/user.duck';
import { getListingsById } from '../../ducks/marketplaceData.duck';
import { SUPPORTED_NONPROFITS } from '../../components/OnboardingWizard/constants';
import { fetchSelectedNonprofits, queryNonProfits } from '../../ducks/NonprofitListing.duck';
import {
  emailVerificationRequired,
  onboardingCompletedRequired,
} from '../../util/pageAuthorization';

const MODAL_BREAKPOINT = 768;

export class MyProfilePageComponent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isMobileModalOpen: false,
    };

    this.onOpenMobileModal = this.onOpenMobileModal.bind(this);
    this.onCloseMobileModal = this.onCloseMobileModal.bind(this);
  }

  onOpenMobileModal() {
    this.setState({ isMobileModalOpen: true });
  }

  onCloseMobileModal() {
    this.setState({ isMobileModalOpen: false });
  }

  render() {
    const {
      currentUser,
      currentUserListing,
      history,
      intl,
      nonprofitListings,
      nonprofitListingsError,
      nonprofitListingsInProgress,
      nonprofitPagination,
      onImageUpload,
      onManageDisableScrolling,
      onUpdateListing,
      onUpdateProfile,
      params,
      profileImage,
      scrollingDisabled,
      searchParams,
      supportedNonprofits,
      uploadProfileImageError,
      uploadProfileImageInProgress,
      updateProfileInProgress,
      updateProfileProfileError,
    } = this.props;

    const user = ensureCurrentUser(currentUser);

    emailVerificationRequired(history, user);
    onboardingCompletedRequired(history, user);

    const profileSettingsWizard = user.id ? (
      <MyProfileWizard
        currentUser={user}
        currentUserListing={currentUserListing}
        history={history}
        intl={intl}
        nonprofitListings={nonprofitListings}
        nonprofitListingsError={nonprofitListingsError}
        nonprofitListingsInProgress={nonprofitListingsInProgress}
        nonprofitPagination={nonprofitPagination}
        onImageUpload={onImageUpload}
        onManageDisableScrolling={onManageDisableScrolling}
        onUpdateListing={onUpdateListing}
        onUpdateProfile={onUpdateProfile}
        onOpenModal={this.onOpenMobileModal}
        onCloseModal={this.onCloseMobileModal}
        params={params}
        profileImage={profileImage}
        searchParams={searchParams}
        showAsModalMaxWidth={MODAL_BREAKPOINT}
        supportedNonprofits={supportedNonprofits}
        uploadProfileImageError={uploadProfileImageError}
        uploadProfileImageInProgress={uploadProfileImageInProgress}
        updateProfileInProgress={updateProfileInProgress}
        updateProfileProfileError={updateProfileProfileError}
      />
    ) : null;

    return (
      <Page
        className={css.root}
        title={intl.formatMessage({ id: 'MyProfilePage.title' })}
        scrollingDisabled={scrollingDisabled}
      >
        <LayoutSideNavigation>
          <LayoutWrapperTopbar>
            <TopbarContainer currentPage="MyProfilePage" />
            {user.id ? <UserNav currentUser={user} selectedPageName="MyProfilePage" /> : null}
          </LayoutWrapperTopbar>
          <LayoutWrapperMyProfileSideNav currentTab={params.tab} currentUser={currentUser} />
          <LayoutWrapperMain>
            <div className={css.content}>{profileSettingsWizard}</div>
          </LayoutWrapperMain>
          <LayoutWrapperFooter>
            <Footer />
          </LayoutWrapperFooter>
        </LayoutSideNavigation>
      </Page>
    );
  }
}

MyProfilePageComponent.defaultProps = {
  currentUser: null,
  currentUserListing: null,
  uploadImageError: null,
  updateProfileError: null,
  image: null,
};

MyProfilePageComponent.propTypes = {
  currentUser: propTypes.currentUser,
  currentUserListing: object,
  history: shape({
    push: func.isRequired,
  }).isRequired,
  image: shape({
    id: string,
    imageId: propTypes.uuid,
    file: object,
    uploadedImage: propTypes.image,
  }),
  intl: intlShape.isRequired,
  onImageUpload: func.isRequired,
  onUpdateListing: func.isRequired,
  onUpdateProfile: func.isRequired,
  params: shape({
    tab: string.isRequired,
  }).isRequired,
  scrollingDisabled: bool.isRequired,
  updateInProgress: bool.isRequired,
  updateListingError: propTypes.error,
  updateListingInProgress: bool,
  updateProfileError: propTypes.error,
  uploadProfileImageError: propTypes.error,
  uploadProfileImageInProgress: bool.isRequired,
};

const mapStateToProps = (state) => {
  const { currentUser, currentUserListings } = state.user;
  const {
    image,
    uploadImageError,
    uploadInProgress,
    updateInProgress,
    updateProfileError,
  } = state.MyProfilePage;

  const {
    pagination,
    searchInProgress,
    searchListingsError,
    searchParams,
    currentPageResultIds,
    selectedNonprofits,
  } = state.NonprofitListing;

  const nonprofitListings = getListingsById(state, currentPageResultIds);

  const currentUserListing = ensureOwnListing(
    Array.isArray(currentUserListings) && currentUserListings.length > 0
      ? currentUserListings[0]
      : {}
  );

  return {
    currentUser,
    currentUserListing,
    nonprofitPagination: pagination,
    nonprofitListingsError: searchListingsError,
    nonprofitListingsInProgress: searchInProgress,
    nonprofitListings,
    profileImage: image,
    scrollingDisabled: isScrollingDisabled(state),
    searchParams,
    supportedNonprofits: selectedNonprofits,
    updateInProgress,
    updateProfileError,
    uploadProfileImageError: uploadImageError,
    uploadProfileImageInProgress: uploadInProgress,
  };
};

const mapDispatchToProps = (dispatch) => ({
  onImageUpload: (data) => dispatch(uploadImage(data)),
  onManageDisableScrolling: (componentId, disableScrolling) =>
    dispatch(manageDisableScrolling(componentId, disableScrolling)),
  onUpdateListing: (data) => dispatch(requestUpdateListing(data)),
  onUpdateProfile: (data) => dispatch(updateProfile(data)),
});

const MyProfilePage = compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  injectIntl
)(MyProfilePageComponent);

MyProfilePage.loadData = (params, search) => (dispatch, getState, sdk) => {
  // Fetch additional data for supported NPOs (NPOs, selected NPOs)
  if (params.tab === SUPPORTED_NONPROFITS) {
    const user = ensureCurrentUser(getState().user.currentUser);
    const selectedNonprofits = getSelectedNonprofits(user);
    dispatch(queryNonProfits(search));
    dispatch(fetchSelectedNonprofits(selectedNonprofits));
  }

  return dispatch(fetchCurrentUserListings());
};

export default MyProfilePage;
