import React from 'react';
import Tippy from '@tippy.js/react';
import { bool, func, object, oneOfType, shape, string } from 'prop-types';
import { FormattedMessage, intlShape } from '../../util/reactIntl';
import classNames from 'classnames';

// Import moment from moment-timezone. 10-year range only.
// The full data included in moment-timezone dependency is mostly irrelevant
// and slows down the first paint.
import moment from 'moment-timezone/builds/moment-timezone-with-data-10-year-range.min';

import {
  calculateQuantityFromHours,
  dateIsAfter,
  getMonthStartInTimeZone,
  nextMonthFn,
  prevMonthFn,
  resetToStartOfDay,
} from '../../util/dates';
import { propTypes } from '../../util/types';
import { Button, DayPicker, EstimatedBreakdown, Modal, PrimaryButton } from '../../components';

import css from './BookingDateAndSlotPicker.css';
import ReservableSlotsComponent from './ReservableSlots';
import { createAvailabilityMap, getReservableSlotsForDate } from '../../util/timeSlots';
import config from '../../config';
import { isInclusivelyAfterDay, isInclusivelyBeforeDay } from 'react-dates';
import PreviousMonthIcon from './PreviousMonthIcon';
import NextMonthIcon from './NextMonthIcon';
import CalendarDay from './CalendarDay';
import {
  ensureCurrentUser,
  getNextOnboardingTab,
  getOnboardingProgress,
  isUserWithCompletedProfile,
  PROFILE_TYPE_VOLUNTEER,
} from '../../util/data';
import { createResourceLocatorString } from '../../util/routes';
import routeConfiguration from '../../routeConfiguration';
import BookingAccessCodeModal from './BookingAccessCodeModal';
import { calendarStartTimeOffSetHours } from '../../marketplace-custom-config';
import { getEvent } from '../../util/events';

const endOfRange = (date, timezone) => {
  return resetToStartOfDay(date, timezone, config.dayCountAvailableForBooking - 1);
};

const COMPLETE_PROFILE = 'completeProfile';
const PUBLISH_PROFILE = 'publishProfile';
const VERIFY_EMAIL = 'verifyEmail';

export class BookingDateAndSlotPicker extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      availabilityMap: {},
      availabilityRange: null,
      completeProfileModalIsOpen: false,
      forcedModalType: null,
      modalActionInProgress: false,
      modalActionReady: false,
      timezone: moment.tz.guess(),
      isBookingAccessCodeModalOpen: false,
    };
    this.getModalType = this.getModalType.bind(this);
    this.onBookButtonClick = this.onBookButtonClick.bind(this);
    this.onModalButtonClick = this.onModalButtonClick.bind(this);
    this.onModalCloseButtonClick = this.onModalCloseButtonClick.bind(this);
    this.startBookingProcess = this.startBookingProcess.bind(this);
    this.onSubmitBookingAccessCode = this.onSubmitBookingAccessCode.bind(this);
    this.onCloseBookingAccessCodeModal = this.onCloseBookingAccessCodeModal.bind(this);
    this.onOpenBookingAccessCodeModal = this.onOpenBookingAccessCodeModal.bind(this);
  }

  /**
   * This assures the availability map is only (re)generated when needed by comparing the start and
   * end months of the monthly time slots.
   *
   * @param props
   * @param state
   * @returns {null|{availabilityMap: {}, availabilityRange: string, timezone: string}}
   */
  static getDerivedStateFromProps(props, state) {
    const { monthlyTimeSlots, reservableSlots, timezone } = props;
    if (reservableSlots.length) {
      const monthlyKeys = Object.keys(monthlyTimeSlots);
      const availabilityRange = monthlyKeys[0] + monthlyKeys[monthlyKeys.length - 1];
      if (state.availabilityRange !== availabilityRange || state.timezone !== timezone) {
        // Filter reservable slots to reduce load
        const reservableSlotsInRange = reservableSlots.filter((reservableSlot) => {
          if (!reservableSlot) {
            return false;
          }

          const prefix = reservableSlot.start.substring(0, 7);
          return monthlyKeys.indexOf(prefix) >= 0;
        });

        return {
          availabilityMap: createAvailabilityMap(
            monthlyTimeSlots,
            reservableSlotsInRange,
            timezone
          ),
          availabilityRange,
          timezone,
        };
      }
    }
    return null;
  }

  startBookingProcess() {
    const { timezone } = this.props;
    const startMoment = this.props.selectedReservableSlot
      ? moment
          .tz(this.props.selectedReservableSlot.start, this.props.selectedReservableSlot.timezone)
          .tz(timezone)
      : null;
    const endMoment = this.props.selectedReservableSlot
      ? moment
          .tz(this.props.selectedReservableSlot.end, this.props.selectedReservableSlot.timezone)
          .tz(timezone)
      : null;

    if (startMoment && endMoment && this.props.selectedMethod) {
      this.props.onSubmit({
        bookingStartTime: startMoment,
        bookingEndTime: endMoment,
        eventKey: this.props.selectedReservableSlot.event,
        meetingMethod: this.props.selectedMethod,
        deductFee: this.props.deductFee,
      });
    }
  }

  onSubmitBookingAccessCode() {
    this.setState({
      isBookingAccessCodeModalOpen: false,
    });
    this.startBookingProcess();
  }

  onOpenBookingAccessCodeModal() {
    this.setState({
      isBookingAccessCodeModalOpen: true,
    });
  }

  onCloseBookingAccessCodeModal() {
    this.setState({
      isBookingAccessCodeModalOpen: false,
    });
  }

  getModalType = () => {
    const ensuredCurrentUser = ensureCurrentUser(this.props.currentUser);
    const { emailVerified } = ensuredCurrentUser.attributes;
    const onboardingProgress = getOnboardingProgress(ensuredCurrentUser);
    const onboardingCompleted = isUserWithCompletedProfile(ensuredCurrentUser);

    if (!emailVerified) {
      return VERIFY_EMAIL;
    } else if ((onboardingProgress === 50 || onboardingProgress === 60) && !onboardingCompleted) {
      return PUBLISH_PROFILE;
    } else {
      return COMPLETE_PROFILE;
    }
  };

  onBookButtonClick() {
    const ensuredCurrentUser = ensureCurrentUser(this.props.currentUser);
    if (isUserWithCompletedProfile(ensuredCurrentUser)) {
      // If the user has completed their profile, the onboarding can proceed
      this.startBookingProcess();
    } else {
      // If the user has not completed their profile, they are requested to do so in a modal
      this.setState({
        completeProfileModalIsOpen: true,
        modalActionReady: false,
      });
    }
  }

  onModalButtonClick() {
    const { currentUser, history, onResendVerificationEmail } = this.props;
    const ensuredCurrentUser = ensureCurrentUser(currentUser);

    switch (this.getModalType()) {
      default:
        break;
      case VERIFY_EMAIL:
        // Resend the verification e-mail to the user
        this.setState({ modalActionInProgress: true });
        onResendVerificationEmail().then(() => {
          this.setState({
            modalActionInProgress: false,
            modalActionReady: true,
          });
        });
        break;
      case PUBLISH_PROFILE:
        // Publish the user's, followed by running through the regular booking process
        this.setState({
          forcedModalType: PUBLISH_PROFILE,
          modalActionInProgress: true,
        });

        const { onPublishListing, onUpdateListing, onUpdateProfile } = this.props;
        const currentUserListingId = ensuredCurrentUser.attributes.profile.publicData.listingId;

        onPublishListing(currentUserListingId)
          .then(() => {
            // Update the profile
            return onUpdateProfile({
              publicData: {
                onboardingCompleted: true,
                onboardingProgress: 61,
                isPitcher: true,
                isVolunteer: true,
                profileType: PROFILE_TYPE_VOLUNTEER,
              },
              privateData: {
                onboardingCompleted: true,
                onboardingProgress: 61,
              },
            });
          })
          .then(() => {
            // Update the listing
            return onUpdateListing({
              id: currentUserListingId,
              publicData: {
                isVolunteerListing: true,
                listingType: PROFILE_TYPE_VOLUNTEER,
              },
            });
          })
          .then(() => {
            // Close the modal and start the booking process
            this.setState({
              completeProfileModalIsOpen: false,
              modalActionInProgress: false,
            });
            this.startBookingProcess();
          })
          .catch((e) => {
            console.log('Something went wrong', e);
          });
        break;
      case COMPLETE_PROFILE:
        // Redirect to the onboarding process to complete the user profile, landing on the latest
        // stage that was not yet completed.
        history.push(
          createResourceLocatorString('OnboardingPage', routeConfiguration(), {
            tab: getNextOnboardingTab(ensuredCurrentUser),
          })
        );
        break;
    }
  }

  onModalCloseButtonClick() {
    this.setState({
      completeProfileModalIsOpen: false,
    });
  }

  render() {
    const {
      authorDisplayName,
      currentDateReservableSlots,
      currentUser,
      className,
      deductFee,
      intl,
      isAccessCodeRequiredForSlot,
      isOwnListing,
      listing,
      listingId,
      monthlyTimeSlots,
      onManageDisableScrolling,
      onSelectDate,
      onSelectReservableSlot,
      onSelectMethod,
      onToggleDeductFee,
      reservableSlots,
      rootClassName,
      submitButtonWrapperClassName,
      selectedDate,
      selectedMethod,
      selectedReservableSlot,
      timezone,
      validateBookingAccessCode,
    } = this.props;

    const dateRangeLowerBound = moment().tz(timezone).add(calendarStartTimeOffSetHours, 'hours');
    const dateRangeUpperBound = moment()
      .tz(timezone)
      .add(config.dayCountAvailableForBooking - 1, 'days');

    const classes = classNames(rootClassName || css.root, className);

    const startMoment = selectedReservableSlot
      ? moment.tz(selectedReservableSlot.start, selectedReservableSlot.timezone)
      : null;
    const endMoment = selectedReservableSlot
      ? moment.tz(selectedReservableSlot.end, selectedReservableSlot.timezone)
      : null;

    const methodPriceChoices = listing.attributes.publicData['methodPriceChoices']
      ? listing.attributes.publicData['methodPriceChoices']
      : {};

    // This is the place to collect breakdown estimation data. See the
    // EstimatedBreakdownMaybe component to change the calculations
    // for customized payment processes.
    const bookingData =
      startMoment && endMoment && selectedMethod
        ? {
            startDate: startMoment,
            endDate: endMoment,
            meetingMethod: selectedMethod,
            quantity: calculateQuantityFromHours(startMoment, endMoment),
            timezone,
            methodPriceChoices,
          }
        : null;

    const bookingInfo =
      bookingData && !config.custom.limitUi ? (
        <div className={css.priceBreakdownContainer} id={'priceBreakdownContainer'}>
          <EstimatedBreakdown
            className={css.receipt}
            bookingData={bookingData}
            deductFee={deductFee}
            onDeductFeeChange={onToggleDeductFee}
            timezone={timezone}
          />
        </div>
      ) : null;

    const submitButtonClasses = classNames(submitButtonWrapperClassName || css.submitButtonWrapper);

    const isOutsideRange = (day) => {
      return (
        !isInclusivelyAfterDay(day, dateRangeLowerBound) ||
        !isInclusivelyBeforeDay(day, dateRangeUpperBound)
      );
    };

    const isDayHighlighted = (day) => {
      const dayInTimeZone = moment.tz(day.toArray().slice(0, 3), timezone);

      if (isOutsideRange(dayInTimeZone)) {
        return false;
      }

      return !!(
        this.state.availabilityMap[day.format('YYYY-MM-DD')] &&
        this.state.availabilityMap[day.format('YYYY-MM-DD')].length
      );
    };

    const fullyBookedTooltipContent = (
      <div className={css.fullyBookedTooltipText}>
        <FormattedMessage id="BookingDateAndSlotPicker.fullyBooked" />
      </div>
    );

    const renderDayContents = (day) => {
      const daySlots = getReservableSlotsForDate(reservableSlots, day, timezone);

      const slotsExist = !!daySlots.length;
      const eventSlotsExist = slotsExist && !!daySlots.find((slot) => !!slot.event);

      const dayClasses = classNames({
        renderedDay: true,
        eventSlots: eventSlotsExist,
        [css.slotsExist]: slotsExist,
      });

      const dayContent =
        slotsExist && !isDayHighlighted(day) ? (
          <Tippy
            className={css.fullyBookedTooltip}
            maxWidth={240}
            content={fullyBookedTooltipContent}
          >
            <span>{day.format('D')}</span>
          </Tippy>
        ) : (
          day.format('D')
        );

      return <span className={dayClasses}>{dayContent}</span>;
    };

    const renderCalendarDay = (props) => {
      return <CalendarDay {...props} />;
    };

    const renderNavPrevButton = (buttonProps) => {
      const { ariaLabel, disabled, onClick, onKeyUp, onMouseUp, currentMonth } = buttonProps;

      if (disabled) {
        return null;
      }

      const today = moment().tz(timezone);
      const prevMonthDate = prevMonthFn(currentMonth, timezone);
      const currentMonthDate = getMonthStartInTimeZone(today, timezone);

      if (!dateIsAfter(prevMonthDate, currentMonthDate)) {
        return null;
      }

      return (
        <div
          className={classNames(css.navButton, css.navPrevButton)}
          onClick={onClick}
          onKeyUp={onKeyUp}
          onMouseUp={onMouseUp}
          aria-label={ariaLabel}
        >
          <PreviousMonthIcon />
        </div>
      );
    };

    const renderNavNextButton = (props) => {
      const { ariaLabel, currentMonth, disabled, onClick, onKeyUp, onMouseUp } = props;

      if (disabled) {
        return null;
      }

      const nextMonthDate = nextMonthFn(currentMonth, timezone);
      const today = moment().tz(timezone);

      if (dateIsAfter(nextMonthDate, endOfRange(today, timezone))) {
        return null;
      }

      return (
        <div
          className={classNames(css.navButton, css.navNextButton)}
          onClick={onClick}
          onKeyUp={onKeyUp}
          onMouseUp={onMouseUp}
          aria-label={ariaLabel}
        >
          <NextMonthIcon />
        </div>
      );
    };

    const modalType = this.state.forcedModalType || this.getModalType();

    return (
      <div className={classes}>
        <DayPicker
          listingId={listingId}
          monthlyTimeSlots={monthlyTimeSlots}
          onDateChange={(date) => {
            return onSelectDate(date);
          }}
          onFetchTimeSlots={() => {}}
          isDayHighlighted={isDayHighlighted}
          isDayBlocked={(day) => !isDayHighlighted(day)}
          renderDayContents={renderDayContents}
          renderCalendarDay={renderCalendarDay}
          reservableSlots={reservableSlots}
          methodPriceChoices={methodPriceChoices}
          selectedDate={selectedDate}
          timezone={timezone}
          renderNavPrevButton={renderNavPrevButton}
          renderNavNextButton={renderNavNextButton}
          weekDayFormat="ddd"
        />
        <div className={css.slotsListWrapper}>
          <h3 className={css.selectedDay}>{selectedDate.format('dddd, MMMM D')}</h3>
          <ReservableSlotsComponent
            currentUser={currentUser}
            currentDateReservableSlots={currentDateReservableSlots}
            isAccessCodeRequiredForSlot={(slot) => isAccessCodeRequiredForSlot(slot, listing)}
            methodPriceChoices={methodPriceChoices}
            selectedDate={selectedDate}
            selectedReservableSlot={selectedReservableSlot}
            onReservableSlotSelect={onSelectReservableSlot}
            selectedMethod={selectedMethod}
            onMethodSelect={onSelectMethod}
            timezone={timezone}
          />
        </div>
        {!isOwnListing ? bookingInfo : null}
        {!isOwnListing ? (
          <div className={submitButtonClasses}>
            {selectedReservableSlot &&
              !isAccessCodeRequiredForSlot(selectedReservableSlot, listing) && (
                <PrimaryButton
                  onClick={this.onBookButtonClick}
                  disabled={!bookingData}
                  id={'requestMeetingButton'}
                >
                  <FormattedMessage id="BookingDateAndSlotPicker.requestAMeeting" />
                </PrimaryButton>
              )}
            {selectedReservableSlot &&
              isAccessCodeRequiredForSlot(selectedReservableSlot, listing) && (
                <PrimaryButton
                  onClick={this.onOpenBookingAccessCodeModal}
                  disabled={!bookingData}
                  id={'requestMeetingButton'}
                >
                  <FormattedMessage id="BookingDateAndSlotPicker.requestAMeeting" />
                </PrimaryButton>
              )}
            <p className={css.smallPrint}>
              <FormattedMessage
                id={
                  isOwnListing
                    ? 'BookingDateAndSlotPicker.ownListing'
                    : 'BookingDateAndSlotPicker.youWontBeChargedInfo'
                }
              />
            </p>
            <Modal
              id="BookingDateAndSlotPicker.modal"
              hasCloseButton={false}
              isOpen={this.state.completeProfileModalIsOpen}
              onClose={() => this.setState({ completeProfileModalIsOpen: false })}
              onManageDisableScrolling={onManageDisableScrolling}
            >
              <h2 className={css.modalHeader}>
                <FormattedMessage id={`BookingDateAndSlotPicker.${modalType}.title`} />
              </h2>
              <p className={css.modalDescription}>
                <FormattedMessage
                  id={`BookingDateAndSlotPicker.${modalType}.body`}
                  values={{ volunteer: authorDisplayName }}
                />
              </p>
              <Button
                className={css.modalButton}
                onClick={this.onModalButtonClick}
                inProgress={this.state.modalActionInProgress}
                ready={this.state.modalActionReady}
                type="button"
                value={modalType}
              >
                <FormattedMessage id={`BookingDateAndSlotPicker.${modalType}.button`} />
              </Button>
              <Button
                className={css.modalCloseButton}
                onClick={this.onModalCloseButtonClick}
                type="button"
              >
                <FormattedMessage id={`BookingDateAndSlotPicker.modal.closeButton`} />
              </Button>
            </Modal>
          </div>
        ) : null}
        {selectedReservableSlot && selectedReservableSlot.event ? (
          <BookingAccessCodeModal
            event={selectedReservableSlot && getEvent(selectedReservableSlot.event)}
            onClose={this.onCloseBookingAccessCodeModal}
            onSubmit={this.onSubmitBookingAccessCode}
            onValidate={validateBookingAccessCode}
            onManageDisableScrolling={onManageDisableScrolling}
            intl={intl}
            isOpen={this.state.isBookingAccessCodeModalOpen}
          />
        ) : null}
      </div>
    );
  }
}

BookingDateAndSlotPicker.defaultProps = {
  authorDisplayName: null,
  currentUser: null,
  className: null,
  isAccessCodeRequiredForSlot: func.isRequired,
  isOwnListing: false,
  listingId: null,
  monthlyTimeSlots: null,
  rootClassName: null,
  submitButtonWrapperClassName: null,
};

BookingDateAndSlotPicker.propTypes = {
  authorDisplayName: string,
  currentUser: propTypes.currentUser,
  currentListing: propTypes.listing,
  className: string,
  history: shape({
    push: func.isRequired,
  }).isRequired,
  intl: intlShape.isRequired,
  isAccessCodeRequiredForSlot: func.isRequired,
  isOwnListing: bool,
  listing: oneOfType([propTypes.listing, propTypes.ownListing]),
  listingId: propTypes.uuid,
  monthlyTimeSlots: object,
  onFetchTimeSlots: func.isRequired,
  onManageDisableScrolling: func.isRequired,
  onPublishListing: func.isRequired,
  onResendVerificationEmail: func.isRequired,
  onUpdateListing: func.isRequired,
  onUpdateProfile: func.isRequired,
  rootClassName: string,
  submitButtonWrapperClassName: string,
  timezone: string.isRequired,
};
BookingDateAndSlotPicker.displayName = 'BookingDateAndSlotPicker';

export default BookingDateAndSlotPicker;
