import css from './AvailabilityForm.css';
import { Button } from '../../components';
import React from 'react';
import { func } from 'prop-types';
import { compose } from 'redux';
import { injectIntl } from 'react-intl';

const AvailabilityFormModalWeeklyLimitExceededComponent = ({ handleModalClose, intl }) => (
  <div className={css.modalPadding}>
    <h2 className={css.modalHeader}>
      {intl.formatMessage({ id: 'AvailabilityForm.weeklyLimitExceeded.header' })}
    </h2>
    <p className={css.modalContent}>
      {intl.formatMessage({ id: 'AvailabilityForm.weeklyLimitExceeded.notice' })}
    </p>
    <p className={css.modalContent}>
      {intl.formatMessage({ id: 'AvailabilityForm.weeklyLimitExceeded.tip' })}
    </p>
    <Button className={css.modalButton} onClick={handleModalClose} type="button">
      {intl.formatMessage({ id: 'AvailabilityForm.weeklyLimitExceeded.ok' })}
    </Button>
  </div>
);

AvailabilityFormModalWeeklyLimitExceededComponent.propTypes = {
  handleModalClose: func.isRequired,
};

export const AvailabilityFormModalWeeklyLimitExceeded = compose(injectIntl)(
  AvailabilityFormModalWeeklyLimitExceededComponent
);
