import pick from 'lodash/pick';
import { addMarketplaceEntities } from './marketplaceData.duck';
import { storableError } from '../util/errors';
import { ensureListing, ensureUser } from '../util/data';
import { merge } from 'lodash';

// ================ Action types ================ //
export const SET_INITIAL_VALUES = 'app/listings/SET_INITIAL_VALUES';

export const LISTINGS_REQUEST = 'app/listings/LISTINGS_REQUEST';
export const LISTINGS_SUCCESS = 'app/listings/LISTINGS_SUCCESS';

export const ALL_NPO_LISTINGS_REQUEST = 'app/listings/ALL_NPO_LISTINGS_REQUEST';
export const ALL_NPO_LISTINGS_SUCCESS = 'app/listings/ALL_NPO_LISTINGS_SUCCESS';
export const ALL_NPO_LISTINGS_ERROR = 'app/listings/ALL_NPO_LISTINGS_ERROR';

export const QUERY_LISTINGS_REQUEST = 'app/listings/QUERY_LISTINGS_REQUEST';
export const QUERY_LISTINGS_SUCCESS = 'app/listings/QUERY_LISTINGS_SUCCESS';
export const QUERY_LISTINGS_ERROR = 'app/listings/QUERY_LISTINGS_ERROR';

const SHOW_LISTING_REQUEST = 'app/listings/SHOW_LISTING_REQUEST';
const SHOW_LISTING_SUCCESS = 'app/listings/SHOW_LISTING_SUCCESS';
const SHOW_LISTING_ERROR = 'app/listings/SHOW_LISTING_ERROR';

// ================ Reducer ================ //

const initialState = {
  currentPageResultIds: [],
  listingIds: [],
  listingsInProgress: false,
  npoListingNames: {},
  pagination: null,
  queryInProgress: false,
  queryError: null,
  queryResults: [],
  showListingInProgress: false,
  showListingError: null,
};

export const getListingNamesGroupedByUuid = (listings, existingObj) =>
  listings.reduce(
    (listingsObj, listing) => ({
      ...listingsObj,
      [listing.id.uuid]: listing.attributes.title,
    }),
    existingObj
  );

export default function listingsReducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case SET_INITIAL_VALUES:
      return { ...initialState, ...payload };

    case LISTINGS_REQUEST:
      return {
        ...state,
        listingIds: payload,
        listingsInProgress: true,
      };
    case LISTINGS_SUCCESS:
      return {
        ...state,
        listingsInProgress: false,
        currentPageResultIds: payload,
      };
    case ALL_NPO_LISTINGS_REQUEST:
      return {
        ...state,
      };
    case ALL_NPO_LISTINGS_SUCCESS:
      return {
        ...state,
        npoListingNames: getListingNamesGroupedByUuid(payload.data.data, state.npoListingNames),
      };
    case ALL_NPO_LISTINGS_ERROR:
      // eslint-disable-next-line no-console
      console.error(payload);
      return { ...state };
    case QUERY_LISTINGS_REQUEST:
      return {
        ...state,
        queryError: false,
        queryInProgress: true,
        queryResults: payload.appendResults ? state.queryResults : [],
      };
    case QUERY_LISTINGS_SUCCESS:
      return {
        ...state,
        queryInProgress: false,
        queryResults: [...state.queryResults, ...payload],
      };
    case QUERY_LISTINGS_ERROR:
      return {
        ...state,
        queryError: payload,
        queryInProgress: false,
      };
    case SHOW_LISTING_REQUEST:
      return {
        ...state,
        showListingError: null,
        showListingInProgress: true,
      };
    case SHOW_LISTING_SUCCESS:
      return {
        ...state,
        showListingInProgress: false,
      };
    case SHOW_LISTING_ERROR:
      return {
        ...state,
        showListingError: payload,
        showListingInProgress: false,
      };
    default:
      return state;
  }
}

// ================ Action creators ================ //

export const setInitialValues = (initialValues) => ({
  type: SET_INITIAL_VALUES,
  payload: pick(initialValues, Object.keys(initialState)),
});

export const listingsRequest = () => ({ type: LISTINGS_REQUEST });
export const listingsSuccess = (listingIds) => ({
  type: LISTINGS_SUCCESS,
  payload: listingIds,
});

export const allNPOListingsSuccess = (response) => ({
  type: ALL_NPO_LISTINGS_SUCCESS,
  payload: { data: response.data },
});

export const allNPOListingsError = (e) => ({
  type: ALL_NPO_LISTINGS_ERROR,
  error: true,
  payload: e,
});

const queryListingsRequest = (appendResults) => ({
  type: QUERY_LISTINGS_REQUEST,
  payload: {
    appendResults,
  },
});
const queryListingsSuccess = (listings) => ({
  type: QUERY_LISTINGS_SUCCESS,
  payload: listings,
});
const queryListingsError = (error) => ({
  type: QUERY_LISTINGS_ERROR,
  payload: error,
});
const showListingRequest = () => ({
  type: SHOW_LISTING_REQUEST,
});
const showListingSuccess = () => ({
  type: SHOW_LISTING_SUCCESS,
});
const showListingError = (error) => ({
  type: SHOW_LISTING_ERROR,
  payload: error,
});

// ================ Thunks ================ //

export const listings = (listingIds) => async (dispatch, getState, sdk) => {
  dispatch(listingsRequest(listingIds));
  await Promise.all(
    listingIds.map((listingId) =>
      sdk.listings
        .show({
          id: listingId,
          include: ['author', 'author.user', 'author.profileImage', 'images'],
          'fields.listing': ['title', 'description', 'geolocation', 'publicData', 'metadata'],
          'fields.image': [
            'variants.square-small',
            'variants.square-small2x',
            'variants.landscape-crop',
            'variants.landscape-crop2x',
          ],
        })
        .then((data) => {
          return dispatch(addMarketplaceEntities(data));
        })
        .catch(() => {
          return null;
        })
    )
  );

  return dispatch(listingsSuccess(listingIds));
};

export const allNPOListings = (pageNum) => (dispatch, getState, sdk) => {
  const params = {
    pub_isNPOListing: true,
    page: pageNum ? pageNum : 1,
    per_page: 100,
    'fields.listing': ['title'],
  };

  return sdk.listings
    .query(params)
    .then((response) => {
      dispatch(allNPOListingsSuccess(response));
      if (params.page < response.data.meta.totalPages) {
        dispatch(allNPOListings(params.page + 1));
      }

      return response;
    })
    .catch((e) => {
      dispatch(allNPOListingsError(storableError(e)));
      throw e;
    });
};

export const queryListings = (params, returnAllResults = false, appendResults = false) => (
  dispatch,
  getState,
  sdk
) => {
  dispatch(queryListingsRequest(appendResults));
  const defaultParams = {
    pub_isNPOListing: false,
    page: 1,
    per_page: 100,
    include: ['author', 'author.user'],
  };

  return sdk.listings
    .query(merge(defaultParams, params))
    .then((sdkResponse) => {
      dispatch(addMarketplaceEntities(sdkResponse));
      return sdkResponse;
    })
    .then((sdkResponse) => {
      const resultIds = [];

      sdkResponse.data.data.forEach((listing) => {
        const ensuredListing = ensureListing(listing);
        resultIds.push(ensuredListing.id.uuid);
      });

      // @todo Handle the returnAllResults flag to fetch all listings if more than 100
      if (returnAllResults) {
        // implement
      }

      dispatch(queryListingsSuccess(resultIds));
      return sdkResponse;
    })
    .catch((e) => {
      dispatch(queryListingsError(storableError(e)));
      throw e;
    });
};

export const showListing = (listingId, params = {}) => (dispatch, getState, sdk) => {
  dispatch(showListingRequest());

  const defaultParams = {
    id: listingId,
    include: ['author', 'author.user', 'author.profileImage', 'images'],
    'fields.image': [
      // Avatars
      'variants.square-small',
      'variants.square-small2x',
    ],
  };
  return sdk.listings
    .show({
      ...defaultParams,
      ...params,
    })
    .then((sdkResponse) => {
      dispatch(addMarketplaceEntities(sdkResponse));
      dispatch(showListingSuccess());
      return listingId;
    })
    .catch((error) => {
      dispatch(showListingError(storableError(error)));
    });
};

// ================= Utils ================================= //
export const getNPOIdsFromResponseUsers = (response) => {
  if (!response || !response.data || !response.data.included) {
    return [];
  }
  const resultUsers = response.data.included.filter((i) => i.type === 'user');

  // Gather all of the possible NPO ids from the response to a Set
  const usersNPOIds = resultUsers
    .map((u) => ensureUser(u).attributes.profile.publicData.supportedNPOs)
    .reduce((acc, val) => {
      val.forEach((i) => acc.add(i));
      return acc;
    }, new Set());

  return Array.from(usersNPOIds);
};
