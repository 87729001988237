var _defineProperty = require("/tmp/build_c174fc36/services/app/node_modules/@babel/runtime/helpers/defineProperty");

var _credit, _propose, _credit2, _refundCredit;

/**
 * Capabilities on different marketplaces depend mainly on the transaction processes that are implemented on those
 * marketplaces. Since transaction process changes are asynchronous from codebase changes. Capabilities are based on
 * transaction process versions. These versions are described in here. All versions represent a minimal version
 * requirement.
 */
var _require = require("./marketplace"),
    MARKETPLACE_PRODUCTION = _require.MARKETPLACE_PRODUCTION,
    MARKETPLACE_SANDBOX = _require.MARKETPLACE_SANDBOX,
    MARKETPLACE_TEST = _require.MARKETPLACE_TEST;

module.exports = {
  booking: {
    credit: (_credit = {}, _defineProperty(_credit, MARKETPLACE_PRODUCTION, 9), _defineProperty(_credit, MARKETPLACE_SANDBOX, 0), _defineProperty(_credit, MARKETPLACE_TEST, 22), _credit),
    propose: (_propose = {}, _defineProperty(_propose, MARKETPLACE_PRODUCTION, 10), _defineProperty(_propose, MARKETPLACE_SANDBOX, 0), _defineProperty(_propose, MARKETPLACE_TEST, 35), _propose)
  },
  payment: {
    credit: (_credit2 = {}, _defineProperty(_credit2, MARKETPLACE_PRODUCTION, 218), _defineProperty(_credit2, MARKETPLACE_SANDBOX, 0), _defineProperty(_credit2, MARKETPLACE_TEST, 449), _credit2),
    refundCredit: (_refundCredit = {}, _defineProperty(_refundCredit, MARKETPLACE_PRODUCTION, 218), _defineProperty(_refundCredit, MARKETPLACE_SANDBOX, 0), _defineProperty(_refundCredit, MARKETPLACE_TEST, 449), _refundCredit)
  }
};