import React, { Component } from 'react';
import { array, bool, func, string } from 'prop-types';
import { withMessages } from '../../util/localization';
import { Form as FinalForm } from 'react-final-form';
import classNames from 'classnames';
import { FieldCheckboxRadioGroup, Hint } from '../../components';
import arrayMutators from 'final-form-arrays';
import { Form, PrimaryButton, FieldTextInput } from '../../components';
import css from './MeetingDetailsForm.css';
import { required } from '../../util/validators';

class MeetingDetailsForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
    };
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleSubmit(values) {
    const { onSubmit, inProgress, formId } = this.props;
    if (inProgress) {
      return;
    }

    const params = {
      formId,
      formValues: values,
    };
    onSubmit(params);
  }

  render() {
    const { defaultInitialMessage, getMessage, onSubmit, ...rest } = this.props;
    return (
      <FinalForm
        initialValues={{
          saveMessage: defaultInitialMessage ? ['yes'] : [],
          initialMessage: defaultInitialMessage,
        }}
        mutators={{ ...arrayMutators }}
        onSubmit={this.handleSubmit}
        {...rest}
        render={(formRenderProps) => {
          const {
            className,
            rootClassName,
            inProgress: submitInProgress,
            formId,
            showInitialMessageInput,
            invalid,
            handleSubmit,
            authorInterests,
          } = formRenderProps;

          const classes = classNames(rootClassName || css.root, className);
          const submitDisabled = invalid || submitInProgress;
          const optionalText = getMessage('optionalText');
          const detailsPlaceholder = getMessage('detailsPlaceholder');

          return (
            <Form className={classes} onSubmit={handleSubmit}>
              {showInitialMessageInput ? (
                <div>
                  <h3 className={css.blockHeading}>{getMessage('messageHeading')}</h3>
                  <p className={css.blockContent}>{getMessage('messageDescription')}</p>
                  <section className={css.message}>
                    <FieldTextInput
                      type="textarea"
                      id={`${formId}-message`}
                      name="initialMessage"
                      label={getMessage('messageLabel')}
                      placeholder={getMessage('messagePlaceholder')}
                      validate={required(getMessage('message.required'))}
                    />
                    <FieldCheckboxRadioGroup
                      className={css.saveMessage}
                      id={`${formId}-saveMessage`}
                      name="saveMessage"
                      options={[
                        {
                          key: 'yes',
                          label: getMessage('saveMessage'),
                          value: 'yes',
                        },
                      ]}
                      twoColumns={false}
                    />
                    <Hint
                      text={getMessage('initialMessageHint.text')}
                      title={getMessage('initialMessageHint.title')}
                    />
                  </section>
                </div>
              ) : null}

              {authorInterests.length ? (
                <>
                  <h3 className={css.blockHeading}>{getMessage('chooseKeywords')}</h3>
                  <FieldCheckboxRadioGroup
                    className={css.keywords}
                    id="keywords"
                    name="keywords"
                    options={authorInterests.map((key) => ({
                      key: key,
                      label: key,
                    }))}
                    twoColumns={true}
                  />
                </>
              ) : null}

              <h3 className={css.blockHeading}>{getMessage('meetingDetailsHeading')}</h3>

              <p className={css.blockContent}>{getMessage('meetingDetailsDescription')}</p>

              <FieldTextInput
                type="textarea"
                id={`${formId}-videoCallURL`}
                name="audioVideoCallURL"
                label={getMessage('audioVideoCallURLLabel')}
                labelSuffix={optionalText}
                placeholder={detailsPlaceholder}
                className={css.audioVideoCall}
              />

              <FieldTextInput
                type="textarea"
                id={`${formId}-conferenceCallNumber`}
                name="conferenceCallNumber"
                label={getMessage('conferenceCallNumberLabel')}
                labelSuffix={optionalText}
                placeholder={detailsPlaceholder}
                className={css.conferenceCall}
              />

              <div className={css.submitContainer}>
                <PrimaryButton
                  className={css.submitButton}
                  type="submit"
                  inProgress={submitInProgress}
                  disabled={submitDisabled}
                >
                  {getMessage('nextPaymentDetails')}
                </PrimaryButton>
              </div>
            </Form>
          );
        }}
      />
    );
  }
}

MeetingDetailsForm.propTypes = {
  className: string,
  rootClassName: string,
  inProgress: bool,
  loadingData: bool,
  formId: string.isRequired,
  getMessage: func.isRequired,
  onSubmit: func.isRequired,
  showInitialMessageInput: bool,
  authorFirstName: string,
  authorInterests: array,
  defaultInitialMessage: string,
};

MeetingDetailsForm.defaultProps = {
  className: null,
  rootClassName: null,
  inProgress: false,
  loadingData: false,
  showInitialMessageInput: true,
  authorFirstName: '',
  authorInterests: [],
  defaultInitialMessage: null,
};

export default withMessages(MeetingDetailsForm, 'MeetingDetailsForm');
