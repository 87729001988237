import React, { Component } from 'react';
import { object, string, bool, number, func, shape, array } from 'prop-types';
import classNames from 'classnames';
import { injectIntl, intlShape } from '../../util/reactIntl';
import { withRouter } from 'react-router-dom';
import omit from 'lodash/omit';

import routeConfiguration from '../../routeConfiguration';
import { createResourceLocatorString } from '../../util/routes';
import { ModalInMobile, Button, SelectMultipleFilter, SelectSingleFilter } from '../../components';
import { propTypes } from '../../util/types';
import css from './NonprofitSearchFiltersMobile.css';

class SearchFiltersMobileComponent extends Component {
  constructor(props) {
    super(props);
    this.state = { isFiltersOpenOnMobile: false, initialQueryParams: null };

    this.openFilters = this.openFilters.bind(this);
    this.cancelFilters = this.cancelFilters.bind(this);
    this.closeFilters = this.closeFilters.bind(this);
    this.resetAll = this.resetAll.bind(this);
    this.handleSelectSingle = this.handleSelectSingle.bind(this);
    this.handleSelectMultiple = this.handleSelectMultiple.bind(this);
    this.initialValue = this.initialValue.bind(this);
    this.initialValues = this.initialValues.bind(this);
  }

  // Open filters modal, set the initial parameters to current ones
  openFilters() {
    const { onOpenModal, urlQueryParams } = this.props;
    onOpenModal();
    this.setState({ isFiltersOpenOnMobile: true, initialQueryParams: urlQueryParams });
  }

  // Close the filters by clicking cancel, revert to the initial params
  cancelFilters() {
    const { history, onCloseModal } = this.props;

    history.push(
      createResourceLocatorString(
        this.props.pageName,
        routeConfiguration(),
        this.props.pagePathParams,
        this.state.initialQueryParams
      )
    );
    onCloseModal();
    this.setState({ isFiltersOpenOnMobile: false, initialQueryParams: null });
  }

  // Close the filter modal
  closeFilters() {
    this.props.onCloseModal();
    this.setState({ isFiltersOpenOnMobile: false });
  }

  handleSelectSingle(urlParam, option) {
    const { urlQueryParams, history } = this.props;

    // query parameters after selecting the option
    // if no option is passed, clear the selection for the filter
    const queryParams = option
      ? { ...urlQueryParams, [urlParam]: option }
      : omit(urlQueryParams, urlParam);

    history.push(
      createResourceLocatorString(
        this.props.pageName,
        routeConfiguration(),
        this.props.pagePathParams,
        queryParams
      )
    );
  }

  handleSelectMultiple(urlParam, options) {
    const { urlQueryParams, history } = this.props;

    const queryParams =
      options && options.length > 0
        ? { ...urlQueryParams, [urlParam]: options.join(',') }
        : omit(urlQueryParams, urlParam);

    history.push(
      createResourceLocatorString(
        this.props.pageName,
        routeConfiguration(),
        this.props.pagePathParams,
        queryParams
      )
    );
  }

  // Reset all filter query parameters
  resetAll(e) {
    const { urlQueryParams, history, filterParamNames } = this.props;

    console.log(urlQueryParams, filterParamNames);

    const queryParams = omit(urlQueryParams, filterParamNames);
    history.push(
      createResourceLocatorString(
        this.props.pageName,
        routeConfiguration(),
        this.props.pagePathParams,
        queryParams
      )
    );

    // blur event target if event is passed
    if (e && e.currentTarget) {
      e.currentTarget.blur();
    }
  }

  // resolve initial value for a single value filter
  initialValue(paramName) {
    return this.props.urlQueryParams[paramName];
  }

  // resolve initial values for a multi value filter
  initialValues(paramName) {
    const urlQueryParams = this.props.urlQueryParams;
    return !!urlQueryParams[paramName] ? urlQueryParams[paramName].split(',') : [];
  }

  render() {
    const {
      getTranslation,
      rootClassName,
      className,
      listingsAreLoaded,
      resultsCount,
      searchInProgress,
      showAsModalMaxWidth,
      onManageDisableScrolling,
      locationFilter,
      categoryFilter,
      intl,
    } = this.props;

    const classes = classNames(rootClassName || css.root, className);
    const showListingsLabel = intl.formatMessage(
      { id: 'NonprofitSearchFiltersMobile.showListings' },
      { count: resultsCount }
    );

    return (
      <div className={classes}>
        <div className={css.searchResultSummary}>
          <span className={css.searchResultSummaryText}>
            {listingsAreLoaded && resultsCount > 0
              ? getTranslation('foundResults', { count: resultsCount })
              : null}
            {listingsAreLoaded && resultsCount === 0 ? getTranslation('noResultsMobile') : null}
            {searchInProgress ? getTranslation('loadingResultsMobile') : null}
          </span>
          <Button rootClassName={css.filtersButton} onClick={this.openFilters}>
            {getTranslation('filters')}
          </Button>
        </div>
        <ModalInMobile
          id="NonprofitSearchFiltersMobile.filters"
          isModalOpenOnMobile={this.state.isFiltersOpenOnMobile}
          onClose={this.cancelFilters}
          showAsModalMaxWidth={showAsModalMaxWidth}
          onManageDisableScrolling={onManageDisableScrolling}
          containerClassName={css.modalContainer}
          closeButtonMessage={getTranslation('cancel')}
        >
          <div className={css.modalHeadingWrapper}>
            <span className={css.modalHeading}>{getTranslation('filters')}</span>
            <button className={css.resetAllButton} onClick={(e) => this.resetAll(e)}>
              {getTranslation('resetAll')}
            </button>
          </div>
          {this.state.isFiltersOpenOnMobile ? (
            <div className={css.filtersWrapper}>
              {locationFilter ? (
                <SelectSingleFilter
                  id="NonprofitSearchFiltersMobile.locationFilter"
                  name="location"
                  urlParam={locationFilter.paramName}
                  label={getTranslation('locationFilter')}
                  onSelect={this.handleSelectSingle}
                  liveEdit
                  options={locationFilter.options}
                  initialValue={this.initialValue(locationFilter.paramName)}
                />
              ) : null}
              {categoryFilter ? (
                <SelectMultipleFilter
                  id="NonprofitSearchFiltersMobile.interestsFilter"
                  name="category"
                  urlParam={categoryFilter.paramName}
                  label={getTranslation('categoryFilter')}
                  onSubmit={this.handleSelectMultiple}
                  liveEdit
                  options={categoryFilter.options}
                  initialValues={this.initialValues(categoryFilter.paramName)}
                />
              ) : null}
            </div>
          ) : null}

          <div className={css.showListingsContainer}>
            <Button className={css.showListingsButton} onClick={this.closeFilters}>
              {showListingsLabel}
            </Button>
          </div>
        </ModalInMobile>
      </div>
    );
  }
}

SearchFiltersMobileComponent.defaultProps = {
  rootClassName: null,
  className: null,
  resultsCount: null,
  searchingInProgress: false,
  selectedFiltersCount: 0,
  filterParamNames: [],
  industryFilter: null,
  interestsFilter: null,
  categoryFilter: null,
  amenitiesFilter: null,
  pagePathParams: {},
  priceFilter: null,
};

SearchFiltersMobileComponent.propTypes = {
  className: string,
  pageName: string.isRequired,
  getTranslation: func.isRequired,
  rootClassName: string,
  urlQueryParams: object.isRequired,
  listingsAreLoaded: bool.isRequired,
  resultsCount: number,
  searchingInProgress: bool,
  showAsModalMaxWidth: number.isRequired,
  onManageDisableScrolling: func.isRequired,
  onOpenModal: func.isRequired,
  onCloseModal: func.isRequired,
  pagePathParams: object,
  selectedFiltersCount: number,
  filterParamNames: array,
  industryFilter: propTypes.filterConfig,
  interestsFilter: propTypes.filterConfig,
  intl: intlShape.isRequired,
  history: shape({
    push: func.isRequired,
  }).isRequired,
};

const NonprofitSearchFiltersMobile = injectIntl(withRouter(SearchFiltersMobileComponent));

export default NonprofitSearchFiltersMobile;
