import classNames from 'classnames';
import { node, string } from 'prop-types';
import React from 'react';
import css from './FormSectionHeading.css';

const FormSectionHeading = (props) => {
  const { className, header, subHeader } = props;
  const classes = classNames(css.headingContainer, className);
  return (
    <div className={classes}>
      {header && <span className={css.heading}>{header}</span>}
      {subHeader && <p className={css.subHeading}>{subHeader}</p>}
    </div>
  );
};

FormSectionHeading.propTypes = {
  className: string,
  header: node,
  subHeader: node,
};

FormSectionHeading.defaultProps = {
  className: null,
  subHeader: null,
};

export default FormSectionHeading;
