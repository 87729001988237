import React, { Component } from 'react';
import css from './MyAvailabilityPage.css';
import {
  Footer,
  LayoutSingleColumn,
  LayoutWrapperFooter,
  LayoutWrapperMain,
  LayoutWrapperTopbar,
  MyAvailabilityWizard,
  Page,
  UserNav,
} from '../../components';
import { TopbarContainer } from '../index';
import { injectIntl } from 'react-intl';
import { bool, func, object, shape, string, arrayOf } from 'prop-types';
import { propTypes } from '../../util/types';
import { intlShape } from '../../util/reactIntl';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { ensureCurrentUser, getSelectedNonprofits } from '../../util/data';
import { logActivity, updateProfile } from '../../ducks/UserProfile.duck';
import { withRouter } from 'react-router-dom';
import {
  requestCreateAvailabilityException,
  requestDeleteAvailabilityException,
  requestFetchAvailabilityExceptions,
  requestPublishListingDraft,
  requestUpdateListing,
} from '../../ducks/UserListing.duck';
import { fetchCurrentUser } from '../../ducks/user.duck';
import { fetchTimeSlots } from '../ListingPage/ListingPage.duck';
import { fetchSelectedNonprofits, queryNonProfits } from '../../ducks/NonprofitListing.duck';
import { getListingsById, getMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import { createResourceLocatorString } from '../../util/routes';
import routeConfiguration from '../../routeConfiguration';
import { trackEventAction } from '../../ducks/Analytics.duck';
import { showOwnListing } from '../../ducks/ownListing.duck';
import { isScrollingDisabled, manageDisableScrolling } from '../../ducks/UI.duck';
import { NONPROFIT_INFO } from '../../components/MyNonprofitWizard/constants';
import { currentUserBookings } from '../../ducks/Bookings.duck';
import {
  emailVerificationRequired,
  onboardingCompletedRequired,
} from '../../util/pageAuthorization';
import { createNotification, updateOrCreateNotifications } from '../../ducks/notifications.duck';
import { getEventsAvailableForVolunteering } from '../../util/events';

export class MyAvailabilityPageComponent extends Component {
  render() {
    const {
      bookings,
      currentUser,
      eventsOpenVolunteering,
      fetchBookingsInProgress,
      history,
      intl,
      location,
      onCreateAvailabilityException,
      onDeleteAvailabilityException,
      onFetchAvailabilityExceptions,
      onFetchTimeSlots,
      onLogActivity,
      onManageDisableScrolling,
      onPublishListing,
      onUpdateListing,
      onUpdateOrCreateNotifications,
      onUpdateProfile,
      ownListing,
      params,
      scrollingDisabled,
      trackEvent,
    } = this.props;

    const user = ensureCurrentUser(currentUser);

    // Prevent NPO users from entering the availability settings
    if (user.id && user.attributes.profile.publicData.isNPO) {
      history.push(
        createResourceLocatorString('MyNonprofitPage', routeConfiguration(), {
          tab: NONPROFIT_INFO,
        })
      );
    }

    emailVerificationRequired(history, user);
    onboardingCompletedRequired(history, user);

    const showAvailabilityWizard = user.id && ownListing && !fetchBookingsInProgress;

    const onboardingWizard = showAvailabilityWizard ? (
      <MyAvailabilityWizard
        bookings={bookings}
        currentUser={currentUser}
        currentUserListing={ownListing}
        eventsOpenVolunteering={eventsOpenVolunteering}
        history={history}
        intl={intl}
        location={location}
        onCreateAvailabilityException={onCreateAvailabilityException}
        onDeleteAvailabilityException={onDeleteAvailabilityException}
        onFetchAvailabilityExceptions={onFetchAvailabilityExceptions}
        onFetchTimeSlots={onFetchTimeSlots}
        onLogActivity={onLogActivity}
        onManageDisableScrolling={onManageDisableScrolling}
        onPublishListing={onPublishListing}
        onUpdateListing={onUpdateListing}
        onUpdateOrCreateNotifications={onUpdateOrCreateNotifications}
        onUpdateProfile={onUpdateProfile}
        params={params}
        trackEvent={trackEvent}
      />
    ) : null;

    return ownListing ? (
      <Page
        className={css.root}
        title={intl.formatMessage({ id: 'MyAvailabilityPage.title' })}
        scrollingDisabled={scrollingDisabled}
      >
        <LayoutSingleColumn>
          <LayoutWrapperTopbar>
            <TopbarContainer currentPage="MyAvailabilityPage" />
            <UserNav currentUser={currentUser} selectedPageName="MyAvailabilityPage" />
          </LayoutWrapperTopbar>
          <LayoutWrapperMain>
            <div className={css.content}>{onboardingWizard}</div>
          </LayoutWrapperMain>
          <LayoutWrapperFooter>
            <Footer />
          </LayoutWrapperFooter>
        </LayoutSingleColumn>
      </Page>
    ) : null;
  }
}

MyAvailabilityPageComponent.defaultProps = {
  bookings: [],
  currentUser: null,
  fetchBookingsInProgress: false,
  eventsOpenVolunteering: [],
  scrollingDisabled: false,
  updateListingError: null,
  updateListingInProgress: false,
  uploadProfileImageError: null,
  uploadProfileImageInProgress: false,
  updateProfileInProgress: false,
  updateProfileProfileError: null,
};

MyAvailabilityPageComponent.propTypes = {
  // currentUser: propTypes.currentUser,
  // currentUserListing: propTypes.ownListing,
  bookings: arrayOf(propTypes.booking),
  eventsOpenVolunteering: arrayOf(propTypes.event),
  fetchBookingsInProgress: bool,
  history: shape({
    push: func.isRequired,
  }).isRequired,
  intl: intlShape.isRequired,
  onFetchTimeSlots: func,
  onLogActivity: func.isRequired,
  onPublishListing: func,
  onUpdateListing: func,
  onUpdateOrCreateNotifications: func.isRequired,
  onUpdateProfile: func,
  ownListing: propTypes.ownListing,
  params: shape({
    tab: string.isRequired,
    eventKey: string,
  }).isRequired,
  profileImage: object,
  updateListingError: propTypes.error,
  updateListingInProgress: bool,
  uploadProfileImageError: propTypes.error,
  uploadProfileImageInProgress: bool,
  updateProfileInProgress: bool,
  updateProfileProfileError: propTypes.error,
};

const mapStateToProps = (state) => {
  const { currentUser } = state.user;
  const { ownListing } = state.ownListing;
  const {
    profileImage,
    uploadProfileInProgress,
    updateProfileInProgress,
    updateProfileProfileError,
  } = state.UserProfile;
  const { updateListingError, updateListingInProgress } = state.UserListing;

  const {
    pagination,
    searchInProgress,
    searchListingsError,
    currentPageResultIds,
    selectedNonprofits,
  } = state.NonprofitListing;

  const { bookingRefs, fetchBookingsInProgress } = state.Bookings;
  const bookings = getMarketplaceEntities(state, bookingRefs);
  const ensuredCurrentUser = ensureCurrentUser(currentUser);
  const nonprofitListings = getListingsById(state, currentPageResultIds);

  const filteredEvents = getEventsAvailableForVolunteering(ensuredCurrentUser, ownListing);

  return {
    bookings,
    currentUser: ensuredCurrentUser,
    ownListing,
    eventsOpenVolunteering: filteredEvents,
    fetchBookingsInProgress,
    nonprofitPagination: pagination,
    nonprofitListingsError: searchListingsError,
    nonprofitListingsInProgress: searchInProgress,
    nonprofitListings,
    profileImage,
    scrollingDisabled: isScrollingDisabled(state),
    selectedNonprofits,
    updateListingError,
    updateListingInProgress,
    uploadProfileInProgress,
    updateProfileInProgress,
    updateProfileProfileError,
  };
};

const mapDispatchToProps = (dispatch) => ({
  onCreateAvailabilityException: (params) => dispatch(requestCreateAvailabilityException(params)),
  onCreateNotification: (params) => {
    return dispatch(createNotification(params));
  },
  onDeleteAvailabilityException: (params) => dispatch(requestDeleteAvailabilityException(params)),
  onFetchAvailabilityExceptions: (fetchParams) =>
    dispatch(requestFetchAvailabilityExceptions(fetchParams)),
  onFetchTimeSlots: (listingId, start, end, timeZone) =>
    dispatch(fetchTimeSlots(listingId, start, end, timeZone)),
  onLogActivity: (activity) => dispatch(logActivity(activity)),
  onManageDisableScrolling: (componentId, disableScrolling) =>
    dispatch(manageDisableScrolling(componentId, disableScrolling)),
  onPublishListing: (listingId) => dispatch(requestPublishListingDraft(listingId)),
  onUpdateListing: (data) =>
    dispatch(requestUpdateListing(data)).then((response) => {
      // Refresh the listing after updating it
      return dispatch(showOwnListing()).then(() => response);
    }),
  onUpdateOrCreateNotifications: (ensuredCurrentUser, notificationType, protectedData) => {
    return dispatch(
      updateOrCreateNotifications(ensuredCurrentUser, notificationType, protectedData)
    );
  },
  onUpdateProfile: (data) => dispatch(updateProfile(data)),
  trackEvent: (params) => dispatch(trackEventAction(params)),
});

const MyAvailabilityPage = compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  injectIntl
)(MyAvailabilityPageComponent);

MyAvailabilityPage.loadData = (params, search) => (dispatch, getState) => {
  return Promise.all([dispatch(fetchCurrentUser()), dispatch(queryNonProfits(search))]).then(() => {
    const user = ensureCurrentUser(getState().user.currentUser);
    const selectedNonprofits = getSelectedNonprofits(user);

    return Promise.all([
      dispatch(fetchSelectedNonprofits(selectedNonprofits)),
      dispatch(showOwnListing()),
      dispatch(currentUserBookings(user, 'accepted')),
    ]);
  });
};

export default MyAvailabilityPage;
