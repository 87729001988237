import { compose } from 'redux';
import { injectIntl } from 'react-intl';
import css from './AvailabilityForm.css';
import { FormattedMessage } from '../../util/reactIntl';
import { FieldSelect } from '../../components';
import config from '../../config';
import React from 'react';
import * as validators from '../../util/validators';
import { func } from 'prop-types';

const AvailabilityFormDonationValuesComponent = ({ intl, onDonationValueChange }) => {
  const chatKeys = Object.keys(config.custom.fixedMethodPrices.chat);
  const inPersonKeys = Object.keys(config.custom.fixedMethodPrices.inPerson);
  const eventKeys = Object.keys(config.custom.fixedMethodPrices.event);

  const donationFieldProps = (type, keys) => ({
    type: 'text',
    id: type,
    name: `methodPriceChoices.${type}`,
    label: intl.formatMessage({ id: 'AvailabilityForm.selectorLabel' }),
    defaultOptionLabel: intl.formatMessage({
      id: 'AvailabilityForm.selectorPlaceholder',
    }),
    defaultOptionValue: '',
    showDefaultOption: true,
    validate: validators.isInList(intl.formatMessage({ id: 'AvailabilityForm.isInList' }), keys),
    onChange: onDonationValueChange(type),
  });

  return (
    <div className={css.donationFields}>
      <div className={css.donationField}>
        <span className={css.donationValueFieldLabel}>
          <FormattedMessage
            id="AvailabilityForm.audioVideoField"
            className={css.donationValueFieldLabel}
          />
        </span>
        <FieldSelect {...donationFieldProps('chat', chatKeys)}>
          {chatKeys.map((key) => (
            <option key={`chat-${key}`} value={key}>
              {config.custom.fixedMethodPrices.chat[key].donation / 100}
            </option>
          ))}
        </FieldSelect>
      </div>

      <div className={css.donationField}>
        <span className={css.donationValueFieldLabel}>
          <FormattedMessage id="AvailabilityForm.inPersonField" />
        </span>
        <FieldSelect {...donationFieldProps('inPerson', inPersonKeys)}>
          {inPersonKeys.map((key) => (
            <option key={`inPerson-${key}`} value={key}>
              {config.custom.fixedMethodPrices.inPerson[key].donation / 100}
            </option>
          ))}
        </FieldSelect>
      </div>

      {config.custom.meetingTypes.filter((option) => option.key === 'event').length > 0 && (
        <div className={css.donationField}>
          <span className={css.donationValueFieldLabel}>
            <FormattedMessage id="AvailabilityForm.eventsField" />
          </span>
          <FieldSelect {...donationFieldProps('event', eventKeys)}>
            {eventKeys.map((key) => (
              <option key={`event-${key}`} value={key}>
                {config.custom.fixedMethodPrices.event[key].donation / 100}
              </option>
            ))}
          </FieldSelect>
        </div>
      )}
    </div>
  );
};

AvailabilityFormDonationValuesComponent.propTypes = {
  onDonationValueChange: func.isRequired,
};

export const AvailabilityFormDonationValues = compose(injectIntl)(
  AvailabilityFormDonationValuesComponent
);
