import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import ReactMarkdown from 'react-markdown';
import AnchorTitle from '../AnchorTitle/AnchorTitle';

import css from './TermsOfService.css';

const TermsOfServiceNPO = (props) => {
  const { rootClassName, className } = props;
  const classes = classNames(rootClassName || css.root, className);

  const [markdownContent, setMarkdownContent] = useState('');

  useEffect(() => {
    const fetchMarkdown = async () => {
      const response = await fetch('/static/md/TermsOfServiceNPO.md');
      const text = await response.text();
      setMarkdownContent(text);
    };

    fetchMarkdown();
  }, []);

  const markdownComponents = {
    h2: ({ node, ...props }) => <AnchorTitle className={css.block} {...props} />,
    a: ({ node, ...props }) => <a {...props} className={css.link} />,
  };

  return (
    <div className={classes}>
    <ReactMarkdown
        children={markdownContent}
        components={markdownComponents}
    />
  </div>
  );
};

TermsOfServiceNPO.defaultProps = {
  rootClassName: null,
  className: null,
};

const { string } = PropTypes;

TermsOfServiceNPO.propTypes = {
  rootClassName: string,
  className: string,
};

export default TermsOfServiceNPO;
