import moment from 'moment-timezone';
import { RECURS_EVERY_TWO_WEEKS, RECURS_MONTHLY, RECURS_WEEKLY } from './dates';

// Internal cache for parsed UTC dates
const _cache = {};

/**
 * Sorts an array of reservable slots by their starting date and time ascending. This requires all
 * reservable slots to be in UTC format.
 *
 * @param   {Array}   reservableSlots
 * @return  {Array}
 */
export const sort = (reservableSlots) => {
  return reservableSlots.sort((a, b) => {
    const startA = _cache[a.start] || new Date(a + 'Z');
    _cache[a.start] = startA;
    const startB = _cache[b.start] || new Date(b + 'Z');
    _cache[b.start] = startB;
    return startA < startB ? a : b;
  });
};

/**
 * Converts a reservable slot to UTC, only in case it was not in UTC already. Otherwise this
 * function simply returns the unmodified reservable slot. This is intended to convert legacy
 * reservable slots to UTC. Those reservable slots were stored in the users own timezone.
 *
 * Once UTC is used as a general standard for reservable slot this method becomes obsolete.
 *
 * @param   {Object} reservableSlot
 * @returns {Promise<Object>}
 */
export const convertToUtc = (reservableSlot) => {
  return new Promise((resolve) => {
    if (reservableSlot.timezone && reservableSlot.timezone.toUpperCase() !== 'UTC') {
      const { end, start, timezone } = reservableSlot;
      return resolve({
        ...reservableSlot,
        end: moment.tz(end, timezone).tz('UTC').toDate().toISOString().slice(0, -1),
        start: moment.tz(start, timezone).tz('UTC').toDate().toISOString().slice(0, -1),
        timezone: 'UTC',
      });
    }
    return resolve(reservableSlot);
  });
};

/**
 * Computes recurring times (acts as a proxy for different forms of recurrence).
 *
 * @param   {Date}    start
 * @param   {Date}    end
 * @param   {String}  recurrence
 * @returns {Promise<Array>}
 */
export const computeRecurringTimes = (start, end, recurrence) => {
  switch (recurrence) {
    default:
      return new Promise((resolve) =>
        resolve([
          {
            start,
            end,
          },
        ])
      );
    case RECURS_EVERY_TWO_WEEKS:
      return computeFixedDayIntervalRecurringTimes(start, end, 14);
    case RECURS_MONTHLY:
      return computeMonthlyRecurringTimes(start, end);
    case RECURS_WEEKLY:
      return computeFixedDayIntervalRecurringTimes(start, end, 7);
  }
};

/**
 * Finds all reservable slots which are already booked from a given array of reservable slots and
 * time slots (availability exceptions).
 *
 * @param     {Array}   reservableSlots
 * @param     {Array}   timeSlots
 * @returns   {Array<Object>}
 */
export const findBookedReservableSlots = async (reservableSlots, timeSlots) => {
  const visibleStart = new Date();
  visibleStart.setDate(1);
  const visibleEnd = new Date(visibleStart.getTime());
  visibleEnd.setMonth(visibleEnd.getMonth() + 3);
  const bookedReservableSlots = [];
  const promises = [];

  /* eslint-disable no-unused-vars */
  for (const rs of reservableSlots) {
    const reservableSlot = await convertToUtc(rs);
    promises.push(reservableSlot);

    const slotStart = new Date(reservableSlot.start);
    const slotEnd = new Date(reservableSlot.end);

    if (
      ((slotStart >= visibleStart && slotStart <= visibleEnd) ||
        (slotEnd >= visibleStart && slotEnd <= visibleEnd)) &&
      !timeSlots.some((ts) => ts.attributes.start === slotStart)
    ) {
      bookedReservableSlots.push(reservableSlot);
    }
  }

  await Promise.all(promises);
  return bookedReservableSlots;
};

/**
 * Finds all reservable slots on a given date
 *
 * @param     {Array}   reservableSlots
 * @param     {Date}    date
 * @param     {String}  timezone
 * @returns   {Array<Object>}
 */
export const findReservableSlotsOnDate = async (reservableSlots, date, timezone = 'UTC') => {
  const reservableSlotsOnDate = [];

  // Determine the range start and end for the given day. Uses moment only for timezone conversion
  // and then switching back to native DateTime objects.
  const m = moment.tz(date.toISOString(), timezone);
  m.startOf('day');
  const rangeStart = m.toDate();
  m.add(1, 'day');
  const rangeEnd = m.toDate();

  for (const rs of reservableSlots) {
    const reservableSlot = await convertToUtc(rs);
    const slotStart = new Date(reservableSlot.start);
    const slotEnd = new Date(reservableSlot.end);

    if (
      (slotStart >= rangeStart && slotStart < rangeEnd) ||
      (slotEnd >= rangeStart && slotEnd < rangeEnd)
    ) {
      reservableSlotsOnDate.push(reservableSlot);
    }
  }

  return reservableSlotsOnDate;
};

/**
 * Computes recurring dates of a given start and end date and time. The given interval is in days.
 * This may be used to compute for example weekly or bi-weekly recurring dates and times. This takes
 * the future threshold into account (max +365 days).
 *
 * @see getFutureThreshold
 * @param start
 * @param end
 * @param interval
 * @returns {Promise<unknown>}
 */
const computeFixedDayIntervalRecurringTimes = (start, end, interval) => {
  return new Promise((resolve, reject) => {
    if (interval < 1) {
      return reject('Interval must be at least 1');
    }

    const futureThreshold = getFutureThreshold();
    const recurringTimes = [];

    while (end < futureThreshold) {
      // Add the current block
      recurringTimes.push({
        start: new Date(start.getTime()),
        end: new Date(end.getTime()),
      });

      // Compute the next block
      start.setDate(start.getDate() + interval);
      end.setDate(end.getDate() + interval);
    }

    return resolve(recurringTimes);
  });
};

/**
 * Computes monthly recurring dates and times from a given start date and time. This takes into
 * account the week number within the month (nth X days of each month) and also accounts for the
 * future threshold (max +365 days).
 *
 * @see getFutureThreshold
 * @param start
 * @param end
 * @returns {Promise<unknown>}
 */
const computeMonthlyRecurringTimes = (start, end) => {
  return new Promise((resolve) => {
    const futureThreshold = getFutureThreshold();
    const recurringTimes = [];
    const initialWeekOfTheMonth = Math.ceil(start.getDate() / 7);

    while (end < futureThreshold) {
      // Add the current block
      recurringTimes.push({
        start: new Date(start.getTime()),
        end: new Date(end.getTime()),
      });

      // Compute the next block. Monthly recurring time slots occur on the same day of the week at
      // the same weekly offset in the next month. For example "every 2nd Monday of the month".
      const currentMonth = start.getMonth();
      const targetMonth = currentMonth + 1 > 11 ? 0 : currentMonth + 1;
      const nextStart = new Date(start.getTime());
      const nextEnd = new Date(end.getTime());

      while (start.getMonth() !== targetMonth) {
        nextStart.setDate(nextStart.getDate() + 7);
        nextEnd.setDate(nextEnd.getDate() + 7);

        if (nextStart.getMonth() === targetMonth) {
          // We are in the correct month, now we need to find the correct day
          const weekOfTheMonth = Math.ceil(nextStart.getDate() / 7);
          while (weekOfTheMonth < initialWeekOfTheMonth && nextStart.getMonth() === targetMonth) {
            nextStart.setDate(nextStart.getDate() + 7);
            nextEnd.setDate(nextEnd.getDate() + 7);
          }

          // If we passed the target month, we go back a week and take the last possibility in that
          // month. This is because the number of weeks is not always the same in each month (some
          // months have 4 weeks, others 5).
          if (nextStart.getMonth() !== targetMonth) {
            nextStart.setDate(nextStart.getDate() - 7);
            nextEnd.setDate(nextEnd.getDate() - 7);
          }
        }
      }

      // Update start and end
      start.setTime(nextStart.getTime());
      end.setTime(nextEnd.getTime());
    }

    return resolve(recurringTimes);
  });
};

/**
 * Computes the maximum future date at which availability exceptions may be created within the
 * Sharetribe platform. This is 365 days (not 1 year) into the future. The 365 days are important
 * to handle with leap years (366 days).
 *
 * @returns {Date}
 */
const getFutureThreshold = () => {
  const futureThreshold = new Date();
  futureThreshold.setDate(futureThreshold.getUTCDate() + 365);
  return futureThreshold;
};
