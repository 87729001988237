import jwt from 'jsonwebtoken';
import moment from 'moment-timezone';
import { getMarketplaceForEnvironment } from './environment';

/**
 * Basic API functionality. This functionality originates from the payment API but can be used to
 * communicate with other internal APIs as well. It provides some basis of authentication based on
 * a token set in the user profile.
 */

/**
 * Creates an authorization token used for the request
 *
 * @param {string} userId
 * @param {string} secret
 * @return {string}
 */
export const createToken = (userId, secret) => {
  const issuedAt = moment().tz('utc');
  const expiresAt = issuedAt.clone().add(300, 'seconds');
  return jwt.sign(
    {
      id: userId,
      iat: parseInt(issuedAt.format('X')),
      exp: parseInt(expiresAt.format('X')),
    },
    secret
  );
};

/**
 * Gets the headers used by the payment API
 *
 * @param {string} userId
 * @param {string} secret
 * @return {{Authorization: string, Marketplace: string, UserID: string, Accepts: string}}
 */
export const getBaseHeaders = (userId, secret) => {
  return {
    Authorization: `Bearer ${createToken(userId, secret)}`,
    Accepts: 'application/json',
    UserID: userId,
    Marketplace: getMarketplaceForEnvironment(),
  };
};
