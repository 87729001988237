import React, { useState } from 'react';
import { Form as FinalForm } from 'react-final-form';
import { FieldTextInput, Form, PrimaryButton, SecondaryButton } from '../../components';
import css from './AddCreditForm.css';
import { bool, func, string } from 'prop-types';
import { useMessages } from '../../util/localization';
import { isEqual } from 'lodash';
import classNames from 'classnames';
import * as validators from '../../util/validators';

//78N6H

const AddCreditForm = (props) => {
  const { addCreditInProgress, className, onCancel, onSubmit } = props;
  const [submittedValues, setSubmittedValues] = useState({});
  const getMessage = useMessages('AddCreditForm');
  const classes = classNames(css.root, className);

  return (
    <FinalForm
      onSubmit={async (values) => {
        const code =
          'code' in values && typeof values['code'] === 'string' ? values['code'].trim() : '';
        setSubmittedValues({ code });
        return await onSubmit(code);
      }}
      render={(fieldRenderProps) => {
        const { form, handleSubmit, invalid, submitting, pristine, values } = fieldRenderProps;

        const pristineSinceLastSubmit = isEqual(values, submittedValues);
        const submitDisabled = submitting || pristine || (invalid && pristineSinceLastSubmit);
        const isSubmitting = submitting || addCreditInProgress;

        return (
          <Form className={classes} onSubmit={handleSubmit}>
            <h2 className={css.header}>{getMessage('header')}</h2>
            <p className={css.description}>{getMessage('description')}</p>
            <FieldTextInput
              className={css.field}
              id={'code'}
              label={getMessage('code.label')}
              name={'code'}
              placeholder={getMessage('code.placeholder')}
              type={'text'}
              validate={validators.required(getMessage('code.required'))}
            />
            <div className={css.controls}>
              <SecondaryButton
                type={'button'}
                onClick={() => {
                  form.reset();
                  onCancel();
                }}
              >
                {getMessage('cancel')}
              </SecondaryButton>
              <PrimaryButton inProgress={isSubmitting} disabled={submitDisabled}>
                {getMessage('submit')}
              </PrimaryButton>
            </div>
          </Form>
        );
      }}
    />
  );
};

AddCreditForm.propTypes = {
  addCreditInProgress: bool,
  className: string,
  onSubmit: func.isRequired,
  onCancel: func.isRequired,
};

AddCreditForm.defaultProps = {
  addCreditInProgress: false,
  className: null,
};

export default AddCreditForm;
