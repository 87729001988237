import React from 'react';
import {
  Footer,
  LayoutSingleColumn,
  LayoutWrapperFooter,
  LayoutWrapperMain,
  LayoutWrapperTopbar,
  Page,
  PrimaryButton,
} from '../../components';
import TopbarContainer from '../TopbarContainer/TopbarContainer';
import css from './GivslyChangesPage.css';
import chadAvatarUrl from './chad-avatar.png';

const GivslyChangesPage = ({ history, location, match }) => {
  return (
    <Page title="Givsly">
      <LayoutSingleColumn>
        <LayoutWrapperTopbar>
          <TopbarContainer
            currentPage="MeetingSunsetPage"
            history={history}
            location={location}
            match={match}
          />
        </LayoutWrapperTopbar>
        <LayoutWrapperMain>
          <div className={css['bg-meeting-sunset']}>
            <div className={css['card-meeting-sunset']}>
              <h1>Hello Friend!</h1>
              <p>
                It’s great to see you again - we have missed you! We at Givsly have continued 
                our work to help amplify our customer’s business results by integrating purpose 
                into both their corporate marketing and advertising strategy. We also have 
                onboarded over 400 nonprofit partners to engage with through our platform.
              </p>
              <p>
                Our marketing subscription partners to Givsly Platfrom have exclusive access 
                to Givsly’s responsible marketing tools as well to our account team to support 
                clients with ideas and best practices on how to use Givsly in their client 
                outreach, marketing events, user research, client gifting, recruiting and more.
              </p>
              <p>
                Givsly’s responsible advertising and media services help marketers to increase 
                performance in their advertising campaigns and to elevate causes important to 
                their own and their consumers' values. Givsly also enables media publishers to 
                meet the expectations of sustainability, DEI and purpose focused initiatives.  
              </p>
              <p>
                For both marketing and advertising clients the Givsly Platform unlocks easy and 
                compliant nonprofit partnering and provides tools for campaign measurement, 
                donation processing and impact reporting.
              </p>
              <p>
                Your company can purchase a subscription to Givsly Platform’s marketing and 
                advertising services separately or together as a package. We offer 
                subscriptions on an annual and monthly basis. Each corporate subscription 
                includes a number of user logins per company to our Platform. We would 
                love to talk with you & your team again about how you can best benefit 
                from Givsly today!
              </p>

              <div className={css['profile-strip']}>
                <img aria-hidden="true" src={chadAvatarUrl} width="80" height="80" />
                <div>
                  <span>Chad Hickey</span>
                  <span>CEO, Givsly</span>
                </div>
              </div>
              
              <a
                href="https://form.typeform.com/to/HDQogf6n"
                target="_blank"
                className={css['connect-btn']}
              >
                <PrimaryButton>Let’s discuss how your company can benefit from Givsly</PrimaryButton>
              </a>

              <p>
                <small className={css['small-meeting-sunset']}>
                  Thanks I’d rather first read more about{' '}
                  <a href="https://company.givsly.com/marketing/" target="_blank">
                    Givsly's responsible marketing
                  </a>
                  ? or about {' '}
                  <a href="https://company.givsly.com/advertising/" target="_blank">
                    Givsly’s responsible advertising and media
                  </a>
                  ?
                </small>
              </p>
            </div>
          </div>
        </LayoutWrapperMain>
        <LayoutWrapperFooter>
          <Footer />
        </LayoutWrapperFooter>
      </LayoutSingleColumn>
    </Page>
  );
};

export default GivslyChangesPage;
