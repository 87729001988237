import React, { Component } from 'react';
import { bool, object } from 'prop-types';
import classNames from 'classnames';
import css from './PersonalInfoForm.css';
import { FormattedMessage } from '../../util/reactIntl';
import { Avatar, IconSpinner, ImageFromFile, IconAdd, IconEdit } from '../../components';
import { propTypes } from '../../util/types';

class AvatarLabel extends Component {
  isFileExisting() {
    return !!this.props.profileImage.file;
  }

  isFileUploadInProgress() {
    return this.props.uploadInProgress && this.isFileExisting();
  }

  getImageFromFileComponent() {
    const delayAfterUpload = this.props.profileImage.imageId && this.props.uploadDelay;
    if (!this.isFileExisting() || !(this.isFileUploadInProgress() || delayAfterUpload)) {
      return null;
    }

    const uploadingOverlay =
      this.props.uploadInProgress || this.props.uploadDelay ? (
        <div className={css.uploadingImageOverlay}>
          <IconSpinner />
        </div>
      ) : null;

    return (
      <ImageFromFile
        id={this.props.profileImage.id}
        rootClassName={css.uploadingImage}
        aspectRatioClassName={css.squareAspectRatio}
        file={this.props.profileImage.file}
      >
        {uploadingOverlay}
      </ImageFromFile>
    );
  }

  getAvatarComponent() {
    if (this.isFileUploadInProgress()) {
      return null;
    }

    // Avatar is rendered in hidden during the upload delay
    // Upload delay smoothes image change process:
    // responsive img has time to load srcset stuff before it is shown to user.
    const avatarClasses = classNames(css.avatar, {
      [css.avatarInvisible]: this.props.uploadDelay,
    });

    const transientUserProfileImage =
      this.props.profileImage.uploadedImage || this.props.currentUser.profileImage;
    const transientUser = { ...this.props.currentUser, profileImage: transientUserProfileImage };

    return (
      <Avatar
        rootClassName={avatarClasses}
        renderSizes="(max-width: 767px) 96px, 240px"
        user={transientUser}
      />
    );
  }

  render() {
    const hasUploadedImage =
      !!this.props.profileImage.uploadedImage || !!this.props.currentUser.profileImage;

    const avatarButtonLabel = hasUploadedImage ? (
      <>
        <IconEdit rootClassName={css.avatarLabelIconEdit} />
        <FormattedMessage id="PersonalInfoForm.changeAvatar" />
      </>
    ) : (
      <>
        <IconAdd rootClassName={css.avatarLabelIconAdd} />
        <FormattedMessage id="PersonalInfoForm.addAvatar" />
      </>
    );

    return (
      <div className={css.avatarContainer}>
        {this.getImageFromFileComponent()}
        {this.getAvatarComponent()}
        <div className={css.avatarLabel}>{avatarButtonLabel}</div>
      </div>
    );
  }
}

AvatarLabel.propTypes = {
  currentUser: propTypes.currentUser.isRequired,
  profileImage: object,
  uploadDelay: bool.isRequired,
  uploadImageError: propTypes.error,
  uploadInProgress: bool,
};

export default AvatarLabel;
