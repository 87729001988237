import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './IconSocialMediaYoutube.css';

const IconSocialMediaYoutube = (props) => {
  const { rootClassName, className } = props;
  const classes = classNames(rootClassName || css.root, className);
  return (
    <svg
      className={classes}
      width="22"
      height="16"
      viewBox="0 0 22 16"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill="#1C1A34"
        fillRule="evenodd"
        d="M19.386 16H2.66C1.31 16 .206 14.862.206 13.472.206 13.472 0 11.229 0 8c0-3.229.206-5.472.206-5.472C.206 1.138 1.31 0 2.66 0h16.726c1.35 0 2.454 1.138 2.454 2.528 0 0 .16 2.083.16 5.472 0 3.52-.16 5.472-.16 5.472 0 1.39-1.105 2.528-2.454 2.528zM8.755 11.229l6.046-3.22L8.755 4.77v6.458z"
      />
    </svg>
  );
};

IconSocialMediaYoutube.defaultProps = { rootClassName: null, className: null };

const { string } = PropTypes;

IconSocialMediaYoutube.propTypes = { rootClassName: string, className: string };

export default IconSocialMediaYoutube;
