import React from 'react';
import classNames from 'classnames';
import config from '../../config';
import { ensureBooking } from '../../util/data';
import { DATE_TYPE_DATETIME, propTypes } from '../../util/types';
import { injectIntl, intlShape } from '../../util/reactIntl';
import { BookingBreakdown } from '../../components';
import utils from '@givsly/sharetribe-utils';
import css from './TransactionPanel.css';
import { string } from 'prop-types';

// Functional component as a helper to build BookingBreakdown
const BreakdownMaybeComponent = (props) => {
  const {
    bookingTransaction,
    className,
    event,
    nonprofit,
    rootClassName,
    breakdownClassName,
    transaction,
    transactionRole,
    timezone,
  } = props;
  const loaded =
    bookingTransaction &&
    bookingTransaction.id &&
    ((bookingTransaction.booking && bookingTransaction.booking.id) ||
      utils.transaction.isProposal(bookingTransaction));
  const classes = classNames(rootClassName || css.breakdownMaybe, className);
  const breakdownClasses = classNames(breakdownClassName || css.breakdown);
  const meetingDuration =
    bookingTransaction && bookingTransaction.attributes
      ? (bookingTransaction.attributes.protectedData.quantity | 0) * 15
      : 0;

  return loaded ? (
    <div className={classes}>
      <BookingBreakdown
        bookingTransaction={bookingTransaction}
        className={breakdownClasses}
        event={event}
        meetingDuration={meetingDuration}
        nonprofit={nonprofit}
        userRole={transactionRole}
        unitType={config.bookingUnitType}
        transaction={transaction}
        booking={ensureBooking(bookingTransaction.booking)}
        dateType={DATE_TYPE_DATETIME}
        timezone={timezone}
      />
    </div>
  ) : null;
};

BreakdownMaybeComponent.propTypes = {
  bookingTransaction: propTypes.transaction,
  event: propTypes.event,
  intl: intlShape,
  nonprofit: propTypes.listing,
  timezone: string.isRequired,
};

BreakdownMaybeComponent.defaultProps = {
  bookingTransaction: null,
};

const BreakdownMaybe = injectIntl(BreakdownMaybeComponent);
export default BreakdownMaybe;
