import React from 'react';
import { string, bool } from 'prop-types';
import { injectIntl, intlShape } from '../../util/reactIntl';
import classNames from 'classnames';
import { propTypes } from '../../util/types';
import {
  ensureUser,
  ensureCurrentUser,
  userDisplayNameAsString,
  ensureListing,
} from '../../util/data';
import {
  ResponsiveImage,
  IconBannedUser,
  IconDefaultNPOAvatar,
  IconDefaultUserAvatar,
  NamedLink,
} from '../../components/';

import css from './Avatar.css';
import { createSlug } from '../../util/urlHelpers';

// Responsive image sizes hint
export const AVATAR_SIZES = '40px';
export const AVATAR_SIZES_MEDIUM = '60px';
export const AVATAR_SIZES_LARGE = '96px';
export const AVATAR_SIZES_EXTRA_LARGE = '120px';
export const AVATAR_SIZES_HUGE = '180px';
export const AVATAR_SIZES_MEGA = '240px';

const AVATAR_IMAGE_VARIANTS = [
  // 240x240
  'square-small',

  // 480x480
  'square-small2x',
];

export const AvatarComponent = (props) => {
  const {
    className,
    enableListingLink,
    intl,
    listing,
    renderSizes,
    rootClassName,
    title,
    user,
  } = props;
  const classes = classNames(rootClassName || css.root, className);

  const userIsCurrentUser = user && user.type === 'currentUser';
  const avatarUser = userIsCurrentUser ? ensureCurrentUser(user) : ensureUser(user);

  const isBannedUser = avatarUser.attributes.banned;
  const isDeletedUser = avatarUser.attributes.deleted;

  const bannedUserDisplayName = intl.formatMessage({
    id: 'Avatar.bannedUserDisplayName',
  });

  const deletedUserDisplayName = intl.formatMessage({
    id: 'Avatar.deletedUserDisplayName',
  });

  const defaultUserDisplayName = isBannedUser
    ? bannedUserDisplayName
    : isDeletedUser
    ? deletedUserDisplayName
    : '';

  const ensuredListing = ensureListing(listing);
  const displayName = title || userDisplayNameAsString(avatarUser, defaultUserDisplayName);
  const rootProps = { className: classes, title: displayName };
  const linkProps =
    enableListingLink && ensuredListing.id.uuid
      ? {
          name: 'ListingPage',
          params: {
            id: ensuredListing.id.uuid,
            slug: createSlug(ensuredListing.attributes.title),
          },
        }
      : null;
  const hasProfileImage = avatarUser.profileImage && avatarUser.profileImage.id;
  const isNPO =
    avatarUser.attributes.profile &&
    avatarUser.attributes.profile.publicData &&
    avatarUser.attributes.profile.publicData.isNPO;

  if (isBannedUser || isDeletedUser) {
    return (
      <span {...rootProps}>
        <IconBannedUser className={css.bannedUserIcon} />
      </span>
    );
  } else if (hasProfileImage && linkProps) {
    return (
      <NamedLink {...rootProps} {...linkProps}>
        <ResponsiveImage
          rootClassName={css.avatarImage}
          alt={displayName}
          image={avatarUser.profileImage}
          variants={AVATAR_IMAGE_VARIANTS}
          sizes={renderSizes}
        />
      </NamedLink>
    );
  } else if (hasProfileImage) {
    return (
      <span {...rootProps}>
        <ResponsiveImage
          rootClassName={css.avatarImage}
          alt={displayName}
          image={avatarUser.profileImage}
          variants={AVATAR_IMAGE_VARIANTS}
          sizes={renderSizes}
        />
      </span>
    );
  } else if (linkProps) {
    // Placeholder avatar
    return (
      <NamedLink {...rootProps} {...linkProps}>
        {isNPO ? <IconDefaultNPOAvatar /> : <IconDefaultUserAvatar />}
      </NamedLink>
    );
  } else {
    // Placeholder avatar
    return (
      <span {...rootProps}>{isNPO ? <IconDefaultNPOAvatar /> : <IconDefaultUserAvatar />}</span>
    );
  }
};

AvatarComponent.defaultProps = {
  className: null,
  enableListingLink: false,
  listing: null,
  renderSizes: AVATAR_SIZES,
  rootClassName: null,
  title: null,
  user: null,
};

AvatarComponent.propTypes = {
  className: string,
  enableListingLink: bool,
  intl: intlShape.isRequired,
  listing: propTypes.listing,
  renderSizes: string,
  rootClassName: string,
  title: string,
};

const Avatar = injectIntl(AvatarComponent);

export default Avatar;

export const AvatarMedium = (props) => (
  <Avatar rootClassName={css.mediumAvatar} renderSizes={AVATAR_SIZES_MEDIUM} {...props} />
);
AvatarMedium.displayName = 'AvatarMedium';

export const AvatarLarge = (props) => (
  <Avatar rootClassName={css.largeAvatar} renderSizes={AVATAR_SIZES_LARGE} {...props} />
);
AvatarLarge.displayName = 'AvatarLarge';

export const AvatarExtraLarge = (props) => (
  <Avatar rootClassName={css.extraLargeAvatar} renderSizes={AVATAR_SIZES_EXTRA_LARGE} {...props} />
);
AvatarExtraLarge.displayName = 'AvatarExtraLarge';

export const AvatarHuge = (props) => (
  <Avatar rootClassName={css.hugeAvatar} renderSizes={AVATAR_SIZES_HUGE} {...props} />
);
AvatarHuge.displayName = 'AvatarHuge';

export const AvatarMega = (props) => (
  <Avatar rootClassName={css.megaAvatar} renderSizes={AVATAR_SIZES_MEGA} {...props} />
);
AvatarMega.displayName = 'AvatarMega';
