/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { Component } from 'react';
import { array, arrayOf, bool, func, object, shape, string, oneOf, number } from 'prop-types';
import { FormattedMessage, intlShape, injectIntl } from '../../util/reactIntl';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import config from '../../config';
import routeConfiguration from '../../routeConfiguration';
import { LISTING_STATE_PENDING_APPROVAL, propTypes } from '../../util/types';
import { types as sdkTypes } from '../../util/sdkLoader';
import {
  LISTING_PAGE_DRAFT_VARIANT,
  LISTING_PAGE_PENDING_APPROVAL_VARIANT,
  createSlug,
} from '../../util/urlHelpers';
import { createResourceLocatorString, findRouteByRouteName } from '../../util/routes';
import {
  ensureCurrentUser,
  ensureListing,
  ensureUser,
  PROFILE_TYPE_VOLUNTEER,
  userDisplayNameAsString,
} from '../../util/data';
import { calculateQuantityFromHours } from '../../util/dates';
import { richText } from '../../util/richText';
import { getListingsById, getMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import { manageDisableScrolling, isScrollingDisabled } from '../../ducks/UI.duck';
import { initializeCardPaymentData } from '../../ducks/stripe.duck.js';
import {
  Page,
  NamedRedirect,
  LayoutSingleColumn,
  LayoutWrapperTopbar,
  LayoutWrapperMain,
  LayoutWrapperFooter,
  Footer,
} from '../../components';
import { TopbarContainer, NotFoundPage } from '../../containers';

import {
  sendEnquiry,
  loadData,
  setInitialValues,
  fetchTimeSlots,
  selectDate,
  selectReservableSlot,
  selectMethod,
  toggleDeductFee,
} from './ListingPage.duck';
import css from './ListingPage.css';
import UserProfile from './UserProfile';
import NonprofitProfile from './nonprofit-profile/NonprofitProfile';
import moment from 'moment-timezone/builds/moment-timezone-with-data-10-year-range.min';
import { getBookingPanelPropsForListing } from '../../util/timeSlots';
import { sendVerificationEmail } from '../../ducks/user.duck';
import { requestPublishListingDraft, requestUpdateListing } from '../../ducks/UserListing.duck';
import { updateProfile } from '../../ducks/UserProfile.duck';
import { setActiveListing } from '../SearchPage/SearchPage.duck';
import {
  getEvent,
  getUserVisibleEventRoleBadges,
  getUserVisibleEventRoleNames,
  isAccessCodeRequiredForSlot,
} from '../../util/events';
import { trackEventAction } from '../../ducks/Analytics.duck';
import { listings } from '../../ducks/Listings.duck';

const MIN_LENGTH_FOR_LONG_WORDS_IN_TITLE = 16;

const { UUID } = sdkTypes;

export class ListingPageComponent extends Component {
  constructor(props) {
    super(props);
    const { enquiryModalOpenForListingId, params } = props;
    this.state = {
      pageClassNames: [],
      imageCarouselOpen: false,
      enquiryModalOpen: enquiryModalOpenForListingId === params.id,
    };

    this.getTranslation = this.getTranslation.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.onContactUser = this.onContactUser.bind(this);
    this.onSubmitEnquiry = this.onSubmitEnquiry.bind(this);
  }

  getTranslation = (name, variables = {}) => {
    return this.props.intl.formatMessage({ id: `ListingPage.${name}` }, variables);
  };

  async handleSubmit(values) {
    const {
      history,
      getListing,
      params,
      callSetInitialValues,
      onFetchEventNonprofits,
      onInitializeCardPaymentData,
    } = this.props;
    const listingId = new UUID(params.id);
    const listing = getListing(listingId);

    const {
      bookingStartTime,
      bookingEndTime,
      eventKey,
      proposeMeeting = false,
      ...restOfValues
    } = values;

    const pageName = proposeMeeting ? 'ProposeMeetingPage' : 'CheckoutPage';

    let initialValues = {
      listing,
      bookingData: {
        deductFee: false,
        meetingMethod: 'chat',
        quantity: 1,
      },
      confirmPaymentError: null,
    };

    if (!proposeMeeting) {
      const bookingData = {
        quantity: calculateQuantityFromHours(bookingStartTime, bookingEndTime),
        ...restOfValues,
      };

      // If this time slot is related to an event, dynamically fetch the event related NPOs if any
      // exist
      const event = eventKey ? getEvent(eventKey) : null;
      if (event && event.supportedNPOs) {
        await onFetchEventNonprofits(event.supportedNPOs);
      }
      const eventNonprofits =
        event && event.supportedNPOs
          ? event.supportedNPOs.map((npo) => getListing(new UUID(npo)))
          : [];

      initialValues = {
        ...initialValues,
        bookingData,
        bookingDates: {
          bookingStart: bookingStartTime.toDate(),
          bookingEnd: bookingEndTime.toDate(),
        },
        event,
        eventNonprofits,
      };
    }

    const routes = routeConfiguration();
    // Customize checkout page state with current listing and selected bookingDates
    const { setInitialValues } = findRouteByRouteName(pageName, routes);
    callSetInitialValues(setInitialValues, initialValues);

    // Clear previous Stripe errors from store if there is any
    onInitializeCardPaymentData();

    // Redirect to CheckoutPage
    history.push(
      createResourceLocatorString(
        pageName,
        routes,
        { id: listing.id.uuid, slug: createSlug(listing.attributes.title) },
        {}
      )
    );
  }

  onContactUser() {
    const { currentUser, history, callSetInitialValues, params, location } = this.props;

    if (!currentUser) {
      const state = { from: `${location.pathname}${location.search}${location.hash}` };

      // We need to log in before showing the modal, but first we need to ensure
      // that modal does open when user is redirected back to this listingpage
      callSetInitialValues(setInitialValues, { enquiryModalOpenForListingId: params.id });

      // signup and return back to listingPage.
      history.push(createResourceLocatorString('SignupPage', routeConfiguration(), {}, {}), state);
    } else {
      this.setState({ enquiryModalOpen: true });
    }
  }

  onSubmitEnquiry(values) {
    const { history, params, onSendEnquiry } = this.props;
    const routes = routeConfiguration();
    const listingId = new UUID(params.id);
    const { message } = values;

    onSendEnquiry(listingId, message.trim())
      .then((txId) => {
        this.setState({ enquiryModalOpen: false });

        // Redirect to OrderDetailsPage
        history.push(
          createResourceLocatorString('OrderDetailsPage', routes, { id: txId.uuid }, {})
        );
      })
      .catch(() => {
        // Ignore, error handling in duck file
      });
  }

  render() {
    const {
      unitType,
      canRenderCalendar,
      currentDateReservableSlots,
      currentUser,
      currentListing,
      deductFee,
      eventRoleBadges,
      fetchBoardMemberTotalCountProgress,
      getEventRoleBadges,
      getListing,
      history,
      intl,
      isAuthenticated,
      listingsInProgress,
      location,
      monthlyTimeSlots,
      onActivateListing,
      onFetchTimeSlots,
      onManageDisableScrolling,
      onPublishListing,
      onResendVerificationEmail,
      onSelectDate,
      onSelectReservableSlot,
      onSelectMethod,
      onToggleDeductFee,
      onUpdateListing,
      onUpdateProfile,
      pagination,
      params: rawParams,
      reservableSlots,
      scrollingDisabled,
      searchInProgress,
      searchListingsError,
      searchParams,
      selectedDate,
      selectedReservableSlot,
      selectedMethod,
      showListingError,
      supporterIds,
      boardMembers,
      boardMemberTotalCount,
      npoListingNames,
      tab,
      timezone,
      onTrackEvent,
    } = this.props;

    const isPendingApprovalVariant = rawParams.variant === LISTING_PAGE_PENDING_APPROVAL_VARIANT;
    const isDraftVariant = rawParams.variant === LISTING_PAGE_DRAFT_VARIANT;

    const listingSlug = rawParams.slug || createSlug(currentListing.attributes.title || '');
    const params = { slug: listingSlug, ...rawParams };

    const isApproved =
      currentListing.id && currentListing.attributes.state !== LISTING_STATE_PENDING_APPROVAL;

    const pendingIsApproved = isPendingApprovalVariant && isApproved;

    // If a /pending-approval URL is shared, the UI requires
    // authentication and attempts to fetch the listing from own
    // listings. This will fail with 403 Forbidden if the author is
    // another user. We use this information to try to fetch the
    // public listing.
    const pendingOtherUsersListing =
      (isPendingApprovalVariant || isDraftVariant) &&
      showListingError &&
      showListingError.status === 403;
    const shouldShowPublicListingPage = pendingIsApproved || pendingOtherUsersListing;

    if (shouldShowPublicListingPage) {
      return <NamedRedirect name="ListingPage" params={params} search={location.search} />;
    }

    const { description = '', title = '' } = currentListing.attributes;

    const richTitle = (
      <span>
        {richText(title, {
          longWordMinLength: MIN_LENGTH_FOR_LONG_WORDS_IN_TITLE,
          longWordClass: css.longWord,
        })}
      </span>
    );

    if (showListingError && showListingError.status === 404) {
      // 404 listing not found

      return <NotFoundPage />;
    } else if (showListingError) {
      // Other error in fetching listing

      const errorTitle = intl.formatMessage({
        id: 'ListingPage.errorLoadingListingTitle',
      });

      return (
        <Page title={errorTitle} scrollingDisabled={scrollingDisabled}>
          <LayoutSingleColumn className={css.pageRoot}>
            <LayoutWrapperTopbar>
              <TopbarContainer />
            </LayoutWrapperTopbar>
            <LayoutWrapperMain>
              <p className={css.errorText}>
                <FormattedMessage id="ListingPage.errorLoadingListingMessage" />
              </p>
            </LayoutWrapperMain>
            <LayoutWrapperFooter>
              <Footer />
            </LayoutWrapperFooter>
          </LayoutSingleColumn>
        </Page>
      );
    } else if (!currentListing.id) {
      // Still loading the listing

      const loadingTitle = intl.formatMessage({
        id: 'ListingPage.loadingListingTitle',
      });

      return (
        <Page title={loadingTitle} scrollingDisabled={scrollingDisabled}>
          <LayoutSingleColumn className={css.pageRoot}>
            <LayoutWrapperTopbar>
              <TopbarContainer />
            </LayoutWrapperTopbar>
            <LayoutWrapperMain>
              <p className={css.loadingText}>{this.getTranslation('loadingListingMessage')}</p>
            </LayoutWrapperMain>
            <LayoutWrapperFooter>
              <Footer />
            </LayoutWrapperFooter>
          </LayoutSingleColumn>
        </Page>
      );
    }

    const authorAvailable = currentListing && currentListing.author;
    const userAndListingAuthorAvailable = !!(currentUser && authorAvailable);
    const isOwnListing =
      userAndListingAuthorAvailable && currentListing.author.id.uuid === currentUser.id.uuid;

    const currentAuthor = authorAvailable ? currentListing.author : null;
    const ensuredAuthor = ensureUser(currentAuthor);

    // When user is banned or deleted the listing is also deleted.
    // Because listing can be never showed with banned or deleted user we don't have to provide
    // banned or deleted display names for the function
    const authorDisplayName = userDisplayNameAsString(currentAuthor, '', true);

    const {
      isNPOListing,
      isVolunteerListing,
      listingType,
      methodPriceChoices,
      supportedNPOs,
    } = currentListing.attributes.publicData;
    const volunteerMeetsRequirements =
      (isVolunteerListing || listingType === PROFILE_TYPE_VOLUNTEER) &&
      typeof methodPriceChoices === 'object' &&
      Object.keys(methodPriceChoices).length > 0 &&
      supportedNPOs.length > 0;

    let profile;
    if (!isNPOListing) {
      profile = (
        <UserProfile
          authorDisplayName={authorDisplayName}
          canRenderCalendar={canRenderCalendar}
          currentDateReservableSlots={currentDateReservableSlots}
          currentListing={currentListing}
          currentUser={currentUser}
          deductFee={deductFee}
          description={description}
          ensuredAuthor={ensuredAuthor}
          eventRoleBadges={eventRoleBadges}
          getListing={getListing}
          getTranslation={this.getTranslation}
          handleSubmit={this.handleSubmit}
          isAccessCodeRequiredForSlot={(slot) => isAccessCodeRequiredForSlot(slot, currentListing)}
          isAuthenticated={isAuthenticated}
          isOwnListing={isOwnListing}
          isVolunteer={isVolunteerListing || listingType === PROFILE_TYPE_VOLUNTEER}
          listingsInProgress={listingsInProgress}
          monthlyTimeSlots={monthlyTimeSlots}
          onFetchTimeSlots={onFetchTimeSlots}
          onManageDisableScrolling={onManageDisableScrolling}
          onPublishListing={onPublishListing}
          onResendVerificationEmail={onResendVerificationEmail}
          onSelectDate={onSelectDate}
          onSelectReservableSlot={onSelectReservableSlot}
          onSelectMethod={onSelectMethod}
          onToggleDeductFee={onToggleDeductFee}
          onUpdateListing={onUpdateListing}
          onUpdateProfile={onUpdateProfile}
          params={params}
          reservableSlots={reservableSlots}
          richTitle={richTitle}
          selectedDate={selectedDate}
          selectedMethod={selectedMethod}
          selectedReservableSlot={selectedReservableSlot}
          unitType={unitType}
          timezone={timezone}
          onTrackEvent={onTrackEvent}
          volunteerMeetsRequirements={volunteerMeetsRequirements}
        />
      );
    } else {
      profile = (
        <NonprofitProfile
          boardMembers={boardMembers}
          boardMemberTotalCount={boardMemberTotalCount}
          currentListing={currentListing}
          description={description}
          ensuredAuthor={ensuredAuthor}
          fetchBoardMemberTotalCountProgress={fetchBoardMemberTotalCountProgress}
          getEventRoleBadges={getEventRoleBadges}
          getListing={getListing}
          getTranslation={this.getTranslation}
          isOwnListing={isOwnListing}
          history={history}
          location={location}
          npoListingNames={npoListingNames}
          onActivateListing={onActivateListing}
          onManageDisableScrolling={onManageDisableScrolling}
          pagination={pagination}
          params={params}
          searchInProgress={searchInProgress}
          searchListingsError={searchListingsError}
          searchParams={searchParams}
          supporterIds={supporterIds}
          tab={tab}
          timezone={timezone}
        />
      );
    }

    const siteTitle = config.siteTitle;
    const type = isNPOListing ? 'npo' : 'person';
    const schemaTitle = intl.formatMessage(
      { id: `ListingPage.schemaTitle.${type}` },
      { title, siteTitle }
    );
    const schemaDescription = intl.formatMessage(
      { id: `ListingPage.schemaDescription.${type}` },
      { title, siteTitle }
    );

    return (
      <Page
        title={schemaTitle}
        description={schemaDescription}
        scrollingDisabled={scrollingDisabled}
        author={authorDisplayName}
        contentType="website"
        schema={{
          '@context': 'http://schema.org',
          '@type': 'WebPage',
          description: schemaDescription,
          name: schemaTitle,
        }}
      >
        <LayoutSingleColumn className={css.pageRoot}>
          <LayoutWrapperTopbar>
            <TopbarContainer />
          </LayoutWrapperTopbar>
          <LayoutWrapperMain>{profile}</LayoutWrapperMain>
          <LayoutWrapperFooter>
            <Footer />
          </LayoutWrapperFooter>
        </LayoutSingleColumn>
      </Page>
    );
  }
}

ListingPageComponent.defaultProps = {
  boardMembers: [],
  eventRoleBadges: [],
  unitType: config.bookingUnitType,
  currentUser: null,
  currentListing: null,
  enquiryModalOpenForListingId: null,
  showListingError: null,
  reviews: [],
  fetchReviewsError: null,
  monthlyTimeSlots: null,
  sendEnquiryError: null,
  categoriesConfig: config.custom.categories,
  npoListingNames: {},
  timezone: moment.tz.guess(),
  reservableSlots: [],
};

ListingPageComponent.propTypes = {
  history: shape({
    push: func.isRequired,
  }).isRequired,
  location: shape({
    search: string,
  }).isRequired,

  intl: intlShape.isRequired,
  params: shape({
    id: string.isRequired,
    slug: string,
    variant: oneOf([LISTING_PAGE_DRAFT_VARIANT, LISTING_PAGE_PENDING_APPROVAL_VARIANT]),
  }).isRequired,

  isAuthenticated: bool.isRequired,

  currentUser: propTypes.currentUser,
  eventRoleBadges: arrayOf(string),
  geEventRoleBadges: func,
  getListing: func.isRequired,
  getOwnListing: func.isRequired,
  onManageDisableScrolling: func.isRequired,
  scrollingDisabled: bool.isRequired,
  enquiryModalOpenForListingId: string,
  showListingError: propTypes.error,
  callSetInitialValues: func.isRequired,
  reviews: arrayOf(propTypes.review),
  fetchReviewsError: propTypes.error,
  monthlyTimeSlots: object,
  // }
  sendEnquiryInProgress: bool.isRequired,
  sendEnquiryError: propTypes.error,
  onActivateListing: func.isRequired,
  onSendEnquiry: func.isRequired,
  onInitializeCardPaymentData: func.isRequired,
  categoriesConfig: array,
  timezone: string.isRequired,
  unitType: propTypes.bookingUnitType,

  supporterIds: array,
  boardMembers: array,
  boardMemberTotalCount: number,

  npoListingNames: object,
  tab: string,
  pagination: propTypes.pagination,
  searchInProgress: bool.isRequired,
  searchListingsError: propTypes.error,
  searchParams: object,
  reservableSlots: array,
};

const mapStateToProps = (state) => {
  const { isAuthenticated } = state.Auth;
  const {
    deductFee,
    enquiryModalOpenForListingId,
    fetchMonthlyTimeSlotsComplete,
    fetchReviewsError,
    id,
    monthlyTimeSlots,
    reviews,
    selectedDate,
    selectedReservableSlot,
    selectedMethod,
    sendEnquiryInProgress,
    sendEnquiryError,
    showListingError,
    supporterIds,
    boardMemberTotalCount,
    fetchBoardMemberTotalCountProgress,
  } = state.ListingPage;

  const {
    searchInProgress,
    searchListingsError,
    searchParams,
    pagination,
    currentPageResultIds,
  } = state.SearchPage;
  const { currentUser, timezone } = state.user;
  const { listingsInProgress, npoListingNames } = state.Listings;
  const { events } = state.Events;

  const getListing = (id) => {
    const ref = { id, type: 'listing' };
    const listings = getMarketplaceEntities(state, [ref]);
    return listings.length === 1 ? listings[0] : null;
  };

  const getOwnListing = (id) => {
    const ref = { id, type: 'ownListing' };
    const listings = getMarketplaceEntities(state, [ref]);
    return listings.length === 1 ? listings[0] : null;
  };

  const getEventRoleBadges = (ensuredListing) =>
    getUserVisibleEventRoleBadges(events, ensuredListing, timezone);

  const ensuredListing = ensureListing(getListing(id));
  const ensuredCurrentUser = ensureCurrentUser(currentUser);
  const eventRoleBadges = getUserVisibleEventRoleNames(events, ensuredListing, timezone);
  const bookingPanelProps = {
    canRenderCalendar: false,
    currentDateReservableSlots: [],
    selectedDate: null,
    selectedReservableSlot: null,
    selectedMethod: null,
    reservableSlots: [],
  };

  if (
    ensuredCurrentUser.id &&
    ensuredListing.id &&
    !listingsInProgress &&
    fetchMonthlyTimeSlotsComplete
  ) {
    const populatedBookingPanelProps = getBookingPanelPropsForListing(
      ensuredListing,
      events,
      monthlyTimeSlots,
      selectedDate,
      selectedReservableSlot,
      selectedMethod,
      timezone
    );
    Object.assign(bookingPanelProps, populatedBookingPanelProps);
  }

  const boardMembers = getListingsById(state, currentPageResultIds);

  return {
    ...bookingPanelProps,
    isAuthenticated,
    boardMembers,
    currentListing: ensuredListing,
    currentUser,
    deductFee,
    eventRoleBadges,
    fetchBoardMemberTotalCountProgress,
    getEventRoleBadges,
    getListing,
    getOwnListing,
    scrollingDisabled: isScrollingDisabled(state),
    enquiryModalOpenForListingId,
    showListingError,
    reviews,
    fetchReviewsError,
    monthlyTimeSlots,
    sendEnquiryInProgress,
    sendEnquiryError,
    supporterIds,
    boardMemberTotalCount,
    listingsInProgress,
    npoListingNames,
    pagination,
    searchInProgress,
    searchListingsError,
    searchParams,
    timezone,
  };
};

const mapDispatchToProps = (dispatch) => ({
  callSetInitialValues: (setInitialValues, values) => dispatch(setInitialValues(values)),
  onFetchTimeSlots: (listingId, start, end, timeZone) =>
    dispatch(fetchTimeSlots(listingId, start, end, timeZone)),
  onFetchEventNonprofits: (listingIds) => dispatch(listings(listingIds)),
  onInitializeCardPaymentData: () => dispatch(initializeCardPaymentData()),
  onResendVerificationEmail: () => dispatch(sendVerificationEmail()),
  onManageDisableScrolling: (componentId, disableScrolling) =>
    dispatch(manageDisableScrolling(componentId, disableScrolling)),
  onPublishListing: (listingId) => dispatch(requestPublishListingDraft(listingId)),
  onSelectDate: (date) => dispatch(selectDate(date)),
  onSelectReservableSlot: (reservableSlot) => dispatch(selectReservableSlot(reservableSlot)),
  onSelectMethod: (method) => dispatch(selectMethod(method)),
  onSendEnquiry: (listingId, message) => dispatch(sendEnquiry(listingId, message)),
  onToggleDeductFee: () => dispatch(toggleDeductFee()),
  onUpdateProfile: (data) => dispatch(updateProfile(data)),
  onUpdateListing: (data) => dispatch(requestUpdateListing(data)),
  onActivateListing: (listingId) => dispatch(setActiveListing(listingId)),
  onTrackEvent: (params) => dispatch(trackEventAction(params)),
});

// Note: it is important that the withRouter HOC is **outside** the
// connect HOC, otherwise React Router won't rerender any Route
// components since connect implements a shouldComponentUpdate
// lifecycle hook.
//
// See: https://github.com/ReactTraining/react-router/issues/4671
const ListingPage = compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  injectIntl
)(ListingPageComponent);

ListingPage.setInitialValues = (initialValues) => setInitialValues(initialValues);
ListingPage.loadData = loadData;

export default ListingPage;
