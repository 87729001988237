import { softWall as config } from '../marketplace-custom-config';

const STORAGE_KEY_PAGE_COUNT = '_swPageCnt';
export const VARIANT_DEFAULT = 0;

const getPageCount = () => {
  if (typeof window !== 'undefined' && window.sessionStorage) {
    return parseInt(sessionStorage.getItem(STORAGE_KEY_PAGE_COUNT) || 0);
  }
  return 0;
};

function updatePageCount(newValue) {
  if (typeof window !== 'undefined' && window.sessionStorage) {
    sessionStorage.setItem(STORAGE_KEY_PAGE_COUNT, String(newValue));
  }
}

const isExcludedPage = (currentPage) => {
  return config.excludePages.indexOf(currentPage) >= 0;
};

const isExcludedFromPageCount = (currentPage) => {
  return config.excludePagesFromCount.indexOf(currentPage) >= 0;
};

const isLastTrigger = (pageCount) => {
  return config.pageTriggers.indexOf(pageCount) === config.pageTriggers.length - 1;
};

const isTriggeredPageCount = (pageCount) => {
  return config.pageTriggers.indexOf(pageCount) >= 0;
};

/**
 * Increases the soft wall page counter used for triggering. Verifies the current page is not
 * excluded.
 *
 * @param currentPage
 * @returns {*}
 */
export const increasePageCount = (currentPage) => {
  const pageCount = getPageCount();
  const newPageCount =
    isExcludedFromPageCount(currentPage) || isLastTrigger(pageCount) ? pageCount : pageCount + 1;
  updatePageCount(newPageCount);
  return newPageCount;
};

export const canCloseModal = (currentPage, pageCount = getPageCount()) => {
  return (
    canRenderModal(currentPage, pageCount) &&
    (!isLastTrigger(pageCount) ||
      (isLastTrigger(pageCount) && !config.disabledBrowsingAfterFinalTrigger))
  );
};

/**
 * Verifies whether the soft wall modal can be rendered or not
 *
 * @param currentPage
 * @param pageCount
 * @returns {boolean|boolean}
 */
export const canRenderModal = (currentPage, pageCount = getPageCount()) => {
  return !isExcludedPage(currentPage) && isTriggeredPageCount(pageCount);
};

export const getVariant = (pageCount = getPageCount()) => {
  return isTriggeredPageCount(pageCount) ? config.pageTriggers.indexOf(pageCount) : 0;
};
