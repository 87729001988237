import { bool } from 'prop-types';
import React from 'react';
import { useMessages } from '../../util/localization';
import NamedLink from '../NamedLink/NamedLink';
import css from './Hero.css';
import nbc from './nbc-universal-logo@3x.png';
import twitter from './twitter-logo@3x.png';
import fox from './fox-broadcasting-company-logo@3x.png';
import cnet from './cnet-logo@3x.png';
import yahoo from './yahoo-logo@3x.png';
import liveramp from './liveramp-logo@3x.png';

const Hero = (props) => {
  const getMessage = useMessages('Hero');

  const { isAuthenticated } = props;

  return (
    <>
      <section className={css.root}>
        <div className={css.content}>
          <h1 className={css.title}>{getMessage('title')}</h1>
          <p className={css.body}>{getMessage('body')}</p>
          {/* <p className={css.footer}>
          {getMessage('footer', {
            link: (
              <NamedLink
                className={css.footerLink}
                name={isAuthenticated ? 'MyAvailabilityPage' : 'SignupPage'}
                key={'hero-sign-up-availability'}
                params={
                  isAuthenticated
                    ? {
                        tab: AVAILABILITY,
                      }
                    : {}
                }
              >
                {getMessage(
                  `footer.linkText.${isAuthenticated ? 'authenticated' : 'unauthenticated'}`
                )}
              </NamedLink>
            ),
          })}
        </p> */}

          {/* only not signed up people should see this */}
          {isAuthenticated ? (
            <div className={css.ctaWrapper}>
              <NamedLink
                className={css.ctaBtn}
                name="OutreachOffersPage"
                params={{ tab: 'overview' }}
              >
                <span>Create Donation Offer</span>
              </NamedLink>

              <NamedLink className={css.ctaLink} name="SearchPage">
                <span>View professionals on Givsly</span>
              </NamedLink>
            </div>
          ) : (
            <div className={css.ctaWrapper}>
              <NamedLink className={css.ctaBtn} name="SignupPage">
                <span>Sign up</span>
              </NamedLink>
              <a
                className={css.ctaLink}
                href="https://form.typeform.com/to/yKoPyBT9"
                target="_blank" rel="noopener noreferrer"
              >
                <span>{getMessage('reqDemo')}</span>
              </a>
            </div>
          )}
        </div>
      </section>
      <section className={css.companies}>
        <p className="companies-500">{getMessage('companiesCopy')}</p>
        <img className={css.nbc} src={nbc} alt="NBC logo"></img>
        <img src={twitter} alt="Twitter/X social media logo "></img>
        <img src={fox} alt="Fox logo"></img>
        <img src={cnet} alt="CNET logo"></img>
        <img src={yahoo} alt="Yahoo social media logo "></img>
        <img src={liveramp} alt="Liveramp logo"></img>
      </section>
    </>
  );
};

Hero.propTypes = {
  isAuthenticated: bool,
};

Hero.defaultProps = {
  isAuthenticated: false,
};

export default Hero;
