import { StaticPage, TopbarContainer } from '../index';
import {
  ExternalLink,
  Footer,
  LayoutSingleColumn,
  LayoutWrapperFooter,
  LayoutWrapperMain,
  LayoutWrapperTopbar,
  NamedLink,
} from '../../components';
import css from './FaqPage.css';
import FaqPageContent from './FaqPageContent';
import React from 'react';
import { connect } from 'react-redux';
import ReactDOMServer from 'react-dom/server';
import { isScrollingDisabled } from '../../ducks/UI.duck';
import { getEvent } from '../../util/events';

// TODO: These questions should be in event configuration.
// Need some system so html formatted questions can be saved to configuration as string.
const aw2020Questions = [
  {
    title: 'Event Questions',
    items: [
      {
        question: (
          <>
            What is <span className={css.highlight}>Advertising Week 2020</span> ?
          </>
        ),
        answer: (
          <>
            Taking place September 29-October 8,{' '}
            <span className={css.highlight}>Advertising Week 2020 (AW2020)</span> is a reimagined
            event for a new world. Over eight days and across six global markets, advertising and
            marketing industry professionals will come together to drive action and change through a
            series of inspirational presentations, educational masterclasses, social learning
            workshops, immersive and entertaining networking experiences, impactful initiatives,
            creative campaigns and more. You can read more about the main event at{' '}
            <ExternalLink href="http://www.advertisingweek.com/">
              http://www.advertisingweek.com/
            </ExternalLink>
          </>
        ),
      },
      {
        question: (
          <>
            How is Givsly collaborating with{' '}
            <span className={css.highlight}>Advertising Week 2020</span> ?
          </>
        ),
        answer: (
          <>
            Givsly and <span className={css.highlight}>Advertising Week 2020</span> are partnering
            together to make it easy to connect virtually around the{' '}
            <span className={css.highlight}>AW2020</span> virtual event, between September 14 and
            October 9, 2020, while supporting great nonprofit causes at the same time. <br />
            <br />
            Givsly will be featuring
            <span className={css.highlight}>AW2020</span> speakers who have volunteered meeting
            times via the Givsly platform. Givsly will also highlight specific nonprofits that are
            especially popular within the advertising industry. Additionally, you can volunteer your
            own <span className={css.highlight}>AW2020</span> meeting times during this timeframe
            and support nonprofits that you care about. All{' '}
            <span className={css.highlight}>Advertising Week 2020</span> meetings will be
            specifically marked on Givsly and visible also through our special pages for{' '}
            <span className={css.highlight}>Advertising Week 2020</span> from mid-September.
          </>
        ),
      },
      {
        question: (
          <>
            How can I participate in <span className={css.highlight}>Advertising Week 2020</span> on
            Givsly?
          </>
        ),
        answer: (
          <>
            You can participate by signing up for Givsly to volunteer{' '}
            <span className={css.highlight}>Advertising Week 2020</span> meeting times between
            September 14th and October 9th, 2020 or book meetings with speakers, delegates or any
            members of Givsly, who have volunteered <span className={css.highlight}>AW2020</span>{' '}
            designated time slots during this timeframe. <br />
            <br />
            To volunteer your time for the event, sign in/up and when proceeding to{' '}
            <strong>Add New Timeslot</strong>, select the <strong>Event Time Slot</strong> tab which
            will allow you to set your times especially for{' '}
            <span className={css.highlight}>Advertising Week 2020</span>. Meetings designated this
            way will become visible and bookable by others on Givsly from mid-September 2020.
          </>
        ),
      },
      {
        question: (
          <>
            Do I have to have a delegate pass or be a speaker for{' '}
            <span className={css.highlight}>Advertising Week 2020</span> to participate on Givsly?
          </>
        ),
        answer: (
          <>
            No. Every registered Givsly member can participate in volunteering, requesting and
            booking Advertising Week 2020 meetings with the Speakers, VIPs and with other Givsly
            members.
          </>
        ),
      },
      {
        question: 'Do I have to sign up for Givsly to participate?',
        answer:
          'Yes. In order to volunteer or book meetings on Givsly in general you have to be a signed up member who has verified their email address, agreed to our membership terms & conditions and completed your Givsly profile. \n\nThis is key for us to be able to direct the donations to nonprofits that you want to support, as well as to give a better idea of you professionally, when other Givsly members are considering whether to accept your meeting requests. Please note, at this time our services are directed to users in the United States only.',
      },
      {
        question: (
          <>
            Which nonprofits can I support on Givsly during{' '}
            <span className={css.highlight}>Advertising Week 2020</span> ?
          </>
        ),
        answer: (
          <>
            While <span className={css.highlight}>Advertising Week 2020</span> has chosen the Ad
            Council as the featured nonprofit partner, Givsly members and event speakers can choose
            any 1-3 nonprofits from the currently 100+ nonprofit organizations that are present on
            Givsly. A member who is booking a meeting with a volunteer can then select whether
            either the volunteer’s 1-3 organizations, or the featured nonprofit, receives the
            meeting donation.
            <br />
            <br />
            While we encourage you to initially make a selection from the many great nonprofit
            organizations in our current network, we are always happy to hear more about
            organizations to add to Givsly. You can contact our partner team at
            <a href="mailto:nonprofits@givsly.com">nonprofits@givsly.com</a> with your suggestions.
          </>
        ),
      },
    ],
  },
  {
    title: (
      <>
        Meetings at <span className={css.highlight}>Advertising Week 2020</span>
      </>
    ),
    items: [
      {
        question: (
          <>
            Do all speakers volunteer meetings on Givsly during{' '}
            <span className={css.highlight}>Advertising Week 2020</span> ?
          </>
        ),
        answer: (
          <>
            All speakers for <span className={css.highlight}>Advertising Week 2020</span> have been
            sent invites to sign up and participate on Givsly. However, volunteering meeting times
            to support their causes or accept a specific meeting request with a Givsly member is up
            to their discretion.
          </>
        ),
      },
      {
        question: 'How many meetings can I volunteer or book?',
        answer: (
          <>
            While we still apply a one hour per week meeting time cap when volunteering regular
            Givsly meeting times, we do not limit how many meeting times you volunteer during
            special event windows like <span className={css.highlight}>Advertising Week 2020</span>.{' '}
            <br />
            <br />
            We understand that this is the time to connect and do good for the nonprofits, so event
            meeting times do not count towards the regular meeting volunteering time cap. <br />
            <br />
            There is no limit for the number of bookings during a period of time, although we
            generally advise not to book more than one time slot from the same person at a time as
            this will likely adversely affect your chances of being accepted by them.
          </>
        ),
      },
      {
        question: 'Can I still volunteer regular Givsly meeting slots during this time?',
        answer:
          'Yes, you can also volunteer regular Givsly meeting slots or make and accept booking requests for those. We encourage all Givsly users to volunteer meetings throughout the year to continue their impact on a regular basis.',
      },
    ],
  },
  {
    title: 'Signing up to Givsly',
    items: [
      {
        question: 'What if I do not seem to receive a verification email from Givsly upon sign-up?',
        answer: (
          <>
            When you first sign up for Givsly using your name and typically your work email, you are
            asked to verify your email with Givsly, so we know that this email is yours and that you
            have access to it, since it is an essential part of interacting with the Givsly
            community.
            <br />
            <br />
            Requests to accept meetings that in turn support your chosen nonprofit organizations
            will be sent using this address so it is good to use an email that is in active use by
            you.
            <br />
            <br />
            If you do not see the verification email come through from Givsly (
            <a href="mailto:hello@givsly.com">hello@givsly.com</a>) you can go to the sign-up and
            ask for the verification email to be re-sent. Occasionally corporate email systems do
            not let verification emails through. If you feel this may be the case you can try with
            another email address or contact us{' '}
            <a href="mailto:support@givsly.com">support@givsly.com</a> so we can help you
            troubleshoot.
          </>
        ),
      },
    ],
  },
];

export const FaqPageEventComponent = ({ scrollingDisabled, params }) => {
  const event = getEvent(params.eventKey);
  if (!event) return null;

  const footNote = (
    <>
      We are here to help. To read more about Givsly, to guide you with sign up and to manage the
      booking and donation process you can find more detail in our{' '}
      <NamedLink name="FaqPage">General Givsly Member FAQ</NamedLink>.
    </>
  );

  const title = (
    <>
      FAQ for <span className={css.highlight}>Advertising Week 2020</span> on Givsly
    </>
  );

  const text = (
    <>
      Want to know how <span className={css.highlight}>Advertising Week 2020</span> and Givsly are
      collaborating to support industry causes and other nonprofits this fall and how you can join
      the effort ?
    </>
  );

  const schemaMainEntity = aw2020Questions.reduce(
    (questionEntities, section) => [
      ...questionEntities,
      ...section.items.map((item) => ({
        '@type': 'Question',
        name: ReactDOMServer.renderToString(item.question),
        acceptedAnswer: {
          '@type': 'Answer',
          text: ReactDOMServer.renderToString(item.answer),
        },
      })),
    ],
    []
  );

  return (
    <StaticPage
      title="Frequently Asked Questions"
      schema={{
        '@context': 'http://schema.org',
        '@type': 'FaqPage',
        description: 'Frequently Asked Questions',
        name: 'Frequently Asked Questions Page',
        mainEntity: schemaMainEntity,
      }}
      scrollingDisabled={scrollingDisabled}
    >
      <LayoutSingleColumn>
        <LayoutWrapperTopbar>
          <TopbarContainer alwaysAccessible={true} currentPage="FaqPage" />
        </LayoutWrapperTopbar>

        <LayoutWrapperMain className={css.staticPageWrapper}>
          <FaqPageContent
            questions={aw2020Questions}
            title={title}
            text={text}
            footNote={footNote}
          />
        </LayoutWrapperMain>

        <LayoutWrapperFooter>
          <Footer />
        </LayoutWrapperFooter>
      </LayoutSingleColumn>
    </StaticPage>
  );
};

const mapStateToProps = (state) => {
  return {
    scrollingDisabled: isScrollingDisabled(state),
  };
};

const FaqPageEvent = connect(mapStateToProps)(FaqPageEventComponent);
export default FaqPageEvent;
