import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import css from './SectionGoodBusiness.css';
import NamedLink from '../NamedLink/NamedLink';

const SectionGoodBusiness = (props) => {
  const { rootClassName, className, isAuthenticated } = props;

  const classes = classNames(rootClassName || css.root, className);
  return (
    <div className={classes}>
      <div className={css.container}>
        <h1>
          <FormattedMessage id="SectionGoodBusiness.title" />
        </h1>
        <p>
          <FormattedMessage id="SectionGoodBusiness.copy" />
        </p>

        {isAuthenticated ? (
          <div className={css.ctaWrapper}>
            <NamedLink
              className={css.ctaBtn}
              name="OutreachOffersPage"
              params={{ tab: 'overview' }}
            >
              <span>Create Donation Offer</span>
            </NamedLink>

            <NamedLink className={css.ctaLink} name="SearchPage">
              <span>View professionals on Givsly</span>
            </NamedLink>
          </div>
        ) : (
          <div className={css.ctaWrapper}>
            <NamedLink className={css.ctaBtn} name="SignupPage">
              <span>Sign up and start creating an impact</span>
            </NamedLink>
            <a className={css.ctaLink} href="https://form.typeform.com/to/yKoPyBT9" target="_blank" rel="noopener noreferrer">
              <span>Request a demo</span>
            </a>
          </div>
        )}
      </div>
    </div>
  );
};

SectionGoodBusiness.defaultProps = { rootClassName: null, className: null };

const { string } = PropTypes;

SectionGoodBusiness.propTypes = {
  rootClassName: string,
  className: string,
};

export default SectionGoodBusiness;
