import SectionMaybe from '../../SectionMaybe';
import css from '../NonprofitProfile.css';
import React from 'react';
import { string } from 'prop-types';
import classNames from 'classnames';
import { ExternalLink } from '../../../../components';

class NonprofitSocialSectionMaybe extends SectionMaybe {
  render() {
    const { facebookUrl, instagramUrl, linkedInUrl, title, twitterUrl, youtubeUrl } = this.props;
    return title && (facebookUrl || instagramUrl || linkedInUrl || twitterUrl || youtubeUrl) ? (
      <div className={css.section}>
        <h2 className={css.sectionHeader}>{title}</h2>
        <div className={css.sectionContent}>
          {facebookUrl ? (
            <ExternalLink
              className={classNames(css.socialButton, css.facebookButton)}
              href={facebookUrl}
            >
              &nbsp;
            </ExternalLink>
          ) : null}
          {twitterUrl ? (
            <ExternalLink
              className={classNames(css.socialButton, css.twitterButton)}
              href={twitterUrl}
            >
              &nbsp;
            </ExternalLink>
          ) : null}
          {instagramUrl ? (
            <ExternalLink
              className={classNames(css.socialButton, css.instagramButton)}
              href={instagramUrl}
            >
              &nbsp;
            </ExternalLink>
          ) : null}
          {linkedInUrl ? (
            <ExternalLink
              className={classNames(css.socialButton, css.linkedInButton)}
              href={linkedInUrl}
            >
              &nbsp;
            </ExternalLink>
          ) : null}
          {youtubeUrl ? (
            <ExternalLink
              className={classNames(css.socialButton, css.youtubeButton)}
              href={youtubeUrl}
            >
              &nbsp;
            </ExternalLink>
          ) : null}
        </div>
      </div>
    ) : null;
  }
}

NonprofitSocialSectionMaybe.propTypes = {
  facebookUrl: string,
  instagramUrl: string,
  linkedInUrl: string,
  title: string,
  twitterUrl: string,
  youtubeUrl: string,
};

export default NonprofitSocialSectionMaybe;
