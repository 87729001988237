import React from 'react';
import { array, bool, func, number, object, shape, string } from 'prop-types';
import { propTypes } from '../../util/types';
import {
  Button,
  IconCheckmark,
  InterestsPanel,
  PanelHeader,
  PersonalInfoPanel,
  SupportedNonprofitsPanel,
} from '../index';
import { FormattedMessage } from 'react-intl';
import classNames from 'classnames';

import css from './MyProfileWizard.css';
import { intlShape } from '../../util/reactIntl';
import {
  ensureOwnListing,
  isBookableListing,
  transformUpdateValuesToListingValues,
  transformUpdateValuesToUserValues,
} from '../../util/data';
import { createResourceLocatorString } from '../../util/routes';
import routeConfiguration from '../../routeConfiguration';
import { createSlug } from '../../util/urlHelpers';
import merge from 'lodash/merge';
import config from '../../config';
import { Tooltip } from '../Tooltip/Tooltip';

export const PERSONAL_INFO = 'personal-info';
export const SUPPORTED_NONPROFITS = 'supported-nonprofits';
export const INTERESTS = 'interests';

class MyProfileWizard extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      urlCopyReady: false,
    };

    this.getTranslation = this.getTranslation.bind(this);
    this.onCopyProfileLink = this.onCopyProfileLink.bind(this);
  }

  getTranslation = (name) => {
    return this.props.intl.formatMessage({ id: `MyProfileWizard.${name}` });
  };

  onManageDisableScrolling() {
    // @todo fixme
  }

  onCopyProfileLink() {
    if (!this.isListingVisible) {
      return;
    }

    const element = document.getElementById('your-profile-url');
    element.focus();
    element.select();
    element.setSelectionRange(0, 99999);
    document.execCommand('copy');
    element.setSelectionRange(0, 0);
    this.setState({
      urlCopyReady: true,
    });

    setTimeout(() => {
      this.setState({
        urlCopyReady: false,
      });
    }, 1500);
  }

  get profileUrl() {
    const { currentUserListing } = this.props;
    const listing = ensureOwnListing(currentUserListing);

    if (!listing.id) return '';

    const slug = createSlug(listing.attributes.title);

    return `${config.canonicalRootURL}/l/${slug}/${listing.id.uuid}`;
  }

  get isListingVisible() {
    const { currentUserListing } = this.props;
    const listing = ensureOwnListing(currentUserListing);

    if (!listing.id) return false;

    return listing.attributes.metadata.isListingVisible;
  }

  render() {
    const {
      currentUser,
      currentUserListing,
      history,
      nonprofitPagination,
      nonprofitListings,
      nonprofitListingsInProgress,
      nonprofitListingsError,
      supportedNonprofits,
      updateListingError,
      updateListingInProgress,
      onManageDisableScrolling,
      onImageUpload,
      onUpdateListing,
      onUpdateProfile,
      onOpenModal,
      onCloseModal,
      params,
      profileImage,
      searchParams,
      showAsModalMaxWidth,
      uploadProfileImageError,
      uploadProfileImageInProgress,
      updateProfileInProgress,
      updateProfileProfileError,
    } = this.props;

    const { firstName } = currentUser.attributes.profile;
    const { tab: activeTab } = params;
    const listing = ensureOwnListing(currentUserListing);

    /**
     * Updates the profile
     *
     * @param tab
     * @param updatedValues
     */
    const handleSubmit = (tab, updatedValues) => {
      onUpdateProfile(transformUpdateValuesToUserValues(updatedValues));
      listing.attributes = merge(listing.attributes, updatedValues);
      updatedValues.publicData.isBookable = isBookableListing(listing);

      if (currentUserListing.id) {
        onUpdateListing(
          transformUpdateValuesToListingValues({
            id: currentUserListing.id.uuid,
            ...updatedValues,
          })
        );
      }
    };

    /**
     * Handle view profile click.
     *
     */
    const handleViewProfileClick = () => {
      const to = createResourceLocatorString(
        'ListingPage',
        routeConfiguration(),
        { id: listing.id.uuid, slug: createSlug(listing.attributes.title) },
        {}
      );
      history.push(to);
    };
    const shouldShowViewProfileLink = listing.id && listing.id.uuid;

    /**
     * Main content switch which determines which tab content is displayed.
     */
    let tabContent;
    switch (activeTab) {
      case PERSONAL_INFO:
        tabContent = (
          <>
            <div className={css.panelHeaderContainer}>
              <div className={css.hiddenInputContainer}>
                <input
                  aria-hidden={true}
                  type="text"
                  value={this.profileUrl}
                  id="your-profile-url"
                  readOnly
                  className={css.hiddenInput}
                />
              </div>
              <PanelHeader
                header={
                  <FormattedMessage
                    id="MyProfileWizard.personalInfoHeading"
                    values={{ firstName: firstName }}
                  />
                }
              />
              <div className={css.panelHeaderButtons}>
                {shouldShowViewProfileLink ? (
                  <>
                    <Button
                      className={classNames(css.viewProfileDesktop)}
                      onClick={handleViewProfileClick}
                    >
                      {this.getTranslation('viewYourProfile')}
                    </Button>
                  </>
                ) : null}
              </div>
            </div>
            <PersonalInfoPanel
              currentUser={currentUser}
              handleSubmit={handleSubmit}
              onImageUpload={onImageUpload}
              profileImage={profileImage}
              uploadImageError={uploadProfileImageError}
              uploadInProgress={uploadProfileImageInProgress}
              updateInProgress={updateProfileInProgress}
              updateProfileError={updateProfileProfileError}
            />
            <div className={css.viewProfileMobileWrapper}>
              {shouldShowViewProfileLink ? (
                <>
                  <Button
                    className={classNames(css.viewProfileMobile)}
                    onClick={handleViewProfileClick}
                  >
                    {this.getTranslation('viewYourProfile')}
                  </Button>
                </>
              ) : null}
            </div>
          </>
        );
        break;
      case SUPPORTED_NONPROFITS:
        return (
          <>
            <PanelHeader
              header={<FormattedMessage id="MyProfileWizard.supportedNonprofitsHeading" />}
            />
            <SupportedNonprofitsPanel
              currentUser={currentUser}
              handleSubmit={handleSubmit}
              nonprofitPagination={nonprofitPagination}
              nonprofitListings={nonprofitListings}
              nonprofitListingsInProgress={nonprofitListingsInProgress}
              nonprofitListingsError={nonprofitListingsError}
              onOpenModal={onOpenModal}
              onCloseModal={onCloseModal}
              pageName="MyProfilePage"
              pagePathParams={{
                tab: activeTab,
              }}
              searchParams={searchParams}
              showAsModalMaxWidth={showAsModalMaxWidth}
              supportedNonprofits={supportedNonprofits}
              updateListingError={updateListingError}
              updateListingInProgress={updateListingInProgress}
              updateProfileError={updateProfileProfileError}
              updateProfileInProgress={updateProfileInProgress}
              onManageDisableScrolling={onManageDisableScrolling}
            />
          </>
        );
      case INTERESTS:
        tabContent = (
          <>
            <PanelHeader
              header={<FormattedMessage id="MyProfileWizard.interestsHeading" />}
              subHeader={<FormattedMessage id="MyProfileWizard.interestsSubHeading" />}
            />
            <InterestsPanel
              currentUser={currentUser}
              handleSubmit={handleSubmit}
              updateInProgress={updateProfileInProgress}
              updateProfileError={updateProfileProfileError}
            />
          </>
        );
        break;
      default:
        tabContent = null;
    }
    return tabContent;
  }
}

MyProfileWizard.propTypes = {
  currentUser: propTypes.currentUser.isRequired,
  currentUserListing: object,
  history: shape({
    push: func.isRequired,
  }).isRequired,
  intl: intlShape.isRequired,
  nonprofitPagination: propTypes.pagination,
  nonprofitListingsError: propTypes.error,
  nonprofitListingsInProgress: bool,
  nonprofitListings: array,
  onImageUpload: func.isRequired,
  onOpenModal: func.isRequired,
  onCloseModal: func.isRequired,
  onUpdateListing: func.isRequired,
  onUpdateProfile: func.isRequired,
  params: shape({
    tab: string.isRequired,
  }).isRequired,
  profileImage: object,
  showAsModalMaxWidth: number,
  supportedNonprofits: array,
  uploadProfileImageError: propTypes.error,
  uploadProfileImageInProgress: bool,
  updateProfileInProgress: bool,
  updateProfileProfileError: propTypes.error,
};

MyProfileWizard.defaultProps = {
  currentUserListing: null,
  updateListingError: null,
  updateListingInProgress: false,
  uploadProfileImageError: null,
  uploadProfileImageInProgress: false,
  updateProfileInProgress: false,
  updateProfileProfileError: null,
};

export default MyProfileWizard;
