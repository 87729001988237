import React from 'react';
import css from './Testimonials.css';
import { array, bool, func, string } from 'prop-types';
import { NamedLink } from '../../../../components';
import { TestimonialCard } from '../index';
import classNames from 'classnames';

class Testimonials extends React.Component {
  constructor(props) {
    super(props);
    this.handleClick = this.handleClick.bind(this);
  }

  handleClick = (e) => {
    const { trackEvent, trackingCategory } = this.props;
    trackEvent({
      category: trackingCategory,
      action: 'ClickBrowseAll',
      label: 'Testimonials',
    });
    return e;
  };

  render() {
    const {
      browseAllLabel,
      browseAllMobileLabel,
      className,
      listings,
      loadingInProgress,
      title,
      trackEvent,
      trackingCategory,
    } = this.props;
    const classes = classNames(css.root, className);
    return (
      <div className={classes}>
        <div className={css.header}>
          <h2 className={css.title}>{title}</h2>
          <NamedLink className={css.browseAllLink} name={'SearchPage'} onClick={this.handleClick}>
            {browseAllLabel}
          </NamedLink>
        </div>
        <div className={css.cards}>
          {listings.map((listing, index) => {
            return !loadingInProgress && listing ? (
              <TestimonialCard
                key={`testimonial-${index}`}
                listing={listing}
                trackEvent={trackEvent}
                trackingCategory={trackingCategory}
              />
            ) : null;
          })}
        </div>
        <div className={css.mobileActions}>
          <NamedLink
            className={css.browseAllMobileLink}
            name={'SearchPage'}
            onClick={this.handleClick}
          >
            {browseAllMobileLabel}
          </NamedLink>
        </div>
      </div>
    );
  }
}

Testimonials.propTypes = {
  browseAllLabel: string.isRequired,
  browseAllMobileLabel: string.isRequired,
  className: string,
  listings: array,
  loadingInProgress: bool,
  title: string.isRequired,
  trackEvent: func.isRequired,
  trackingCategory: string.isRequired,
};

Testimonials.defaultProps = {
  className: null,
  listings: [],
  loadingInProgress: false,
};

export default Testimonials;
