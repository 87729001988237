import React from 'react';
import { FieldSelect, FieldTextInput, Form, ModalButtonContainer } from '../../components';
import css from './TimeSlotForm.css';
import { FormattedMessage } from 'react-intl';
import { array, arrayOf, bool, func, object, string } from 'prop-types';
import { Form as FinalForm } from 'react-final-form';
import arrayMutators from 'final-form-arrays';
import { propTypes } from '../../util/types';
import moment from 'moment';
import { RECURS_EVENT_DAYS, RECURS_NEVER } from '../../util/dates';
import FieldDateTimeDurationSelector, {
  DATE_FIELD_TYPE_SELECT,
} from './FieldDateTimeDurationSelector';
import FieldMeetingType from './FieldMeetingType';
import * as validators from '../../util/validators';

class TimeSlotFormEvent extends React.Component {
  get originalDate() {
    return this.props.originalDate || moment().tz(this.props.timezone);
  }

  clearField(name) {
    if (name === 'bookingDate') return { date: null };

    return null;
  }

  onRecurrenceChange = (value) => {
    if (!this.props.trackEvent) return;

    this.props.trackEvent({
      category: 'Availability',
      action: 'Select',
      label: 'TimeSlotRepeats',
      value,
    });
  };

  getRecurrenceOptions(selectedRecurrence, timeSlotId) {
    return [
      {
        key: RECURS_NEVER,
        value: RECURS_NEVER,
        disabled: !!selectedRecurrence && !!timeSlotId,
        label: this.props.intl.formatMessage({ id: 'TimeSlotForm.recurrence.none' }),
      },
      {
        key: RECURS_EVENT_DAYS,
        value: RECURS_EVENT_DAYS,
        label: this.props.intl.formatMessage({ id: 'TimeSlotForm.recurrence.eventDays' }),
      },
    ];
  }

  render() {
    const {
      events,
      timezone,
      originalDuration,
      exceedsWeeklyLimit,
      inEditMode,
      reservableSlots,
    } = this.props;

    return (
      <FinalForm
        {...this.props}
        mutators={{
          ...arrayMutators,
          touch: ([name], state, { changeValue }) => changeValue(state, name, (value) => value),
          clear: ([name], state, { changeValue }) =>
            changeValue(state, name, (value) => this.clearField(name)),
          setValue: ([name, value], state, { changeValue }) =>
            changeValue(state, name, () => value),
        }}
        render={(fieldRenderProps) => {
          const {
            form,
            handleSubmit,
            intl,
            invalid,
            onFetchTimeSlots,
            pristine,
            pristineSinceLastSubmit,
            updateInProgress,
            values,
          } = fieldRenderProps;

          const selectedDate = values.bookingDate.date
            ? moment.tz(values.bookingDate.date, timezone)
            : null;
          const submitDisabled =
            invalid || updateInProgress || pristine || pristineSinceLastSubmit || !selectedDate;

          const selectedEvent = values.event
            ? events.find((event) => event.key === values.event)
            : null;

          const allowedDates = selectedEvent
            ? {
                startDate: selectedEvent.active.from,
                endDate: selectedEvent.active.to,
              }
            : { startDate: null, endDate: null };

          const editRecurringTimeslot = values.id && values.recurrence !== RECURS_NEVER;

          return (
            <Form
              onSubmit={(e) => {
                this.submittedValues = values;

                handleSubmit(e);
              }}
            >
              <div>
                <div className={css.formContainer}>
                  <div>
                    <FieldTextInput id="id" name="id" type="hidden" />

                    <FieldSelect
                      id="event"
                      name="event"
                      label={intl.formatMessage({ id: 'TimeSlotForm.event' })}
                      defaultOptionLabel={intl.formatMessage({
                        id: 'TimeSlotForm.event.placeholder',
                      })}
                      defaultOptionValue={''}
                      showDefaultOption={true}
                      validate={validators.requiredAndNonEmptyString(
                        intl.formatMessage({
                          id: 'TimeSlotForm.event.required',
                        })
                      )}
                      options={events.map((event) => ({
                        key: event.key,
                        value: event.key,
                        label: event.title,
                      }))}
                      onChange={() => form.mutators.clear('bookingDate')}
                      className={css.formField}
                    />

                    <FieldDateTimeDurationSelector
                      allowedDates={allowedDates}
                      bookingDate={values.bookingDate}
                      bookingTime={values.bookingTime}
                      duration={values.duration}
                      exceedsWeeklyLimit={exceedsWeeklyLimit}
                      eventTimezone={selectedEvent && selectedEvent.timezone}
                      form={form}
                      inEditMode={inEditMode}
                      intl={intl}
                      isEvent={true}
                      onFetchTimeSlots={onFetchTimeSlots}
                      originalDate={this.originalDate}
                      originalDuration={originalDuration}
                      reservableSlots={reservableSlots}
                      timeSlotId={values.id}
                      timezone={timezone}
                      type={DATE_FIELD_TYPE_SELECT}
                    />
                  </div>

                  <div className={css.formField}>
                    {values.recurrence !== RECURS_NEVER && values.id && (
                      <FieldTextInput
                        id="updateSiblingsInFuture"
                        name="updateSiblingsInFuture"
                        type="hidden"
                      />
                    )}

                    {!!values.updateSiblingsInFuture && (
                      <FieldTextInput id="recurrenceParent" name="recurrenceParent" type="hidden" />
                    )}

                    <FieldSelect
                      id="recurrence"
                      label={intl.formatMessage({ id: 'TimeSlotForm.recurrence' })}
                      name="recurrence"
                      onChange={this.onRecurrenceChange}
                      disabled={!selectedDate}
                      options={this.getRecurrenceOptions(values.recurrence, values.id)}
                    />
                  </div>

                  <div className={css.formField}>
                    <FieldMeetingType intl={intl} name="meetingType" id="meetingType" />
                  </div>
                </div>

                <ModalButtonContainer
                  className={css.modalButtonContainer}
                  secondaryButton={
                    editRecurringTimeslot
                      ? {
                          className: css.secondaryButton,
                          inProgress: updateInProgress,
                          disabled: submitDisabled,
                          ready: pristineSinceLastSubmit,
                          onClick: () => {
                            form.mutators.setValue('updateSiblingsInFuture', 1);
                            form.submit();
                          },
                          text: <FormattedMessage id="TimeSlotForm.save.thisAndFuture" />,
                        }
                      : null
                  }
                  primaryButton={{
                    className: css.primaryButton,
                    inProgress: updateInProgress,
                    disabled: submitDisabled,
                    ready: pristineSinceLastSubmit,
                    text: <FormattedMessage id="TimeSlotForm.save" />,
                  }}
                />
              </div>
            </Form>
          );
        }}
      />
    );
  }
}

TimeSlotFormEvent.propTypes = {
  events: arrayOf(propTypes.event).isRequired,
  initialValues: object,
  exceedsWeeklyLimit: func.isRequired,
  inEditMode: bool.isRequired,
  isVisible: bool,
  onFetchTimeSlots: func.isRequired,
  originalDate: object,
  originalDuration: string.isRequired,
  reservableSlots: array,
  selectedDate: object,
  timezone: string.isRequired,
  updateError: propTypes.error,
  updateInProgress: bool,
  trackEvent: func,
};

TimeSlotFormEvent.defaultProps = {
  isVisible: false,
  reservableSlots: [],
  updateError: null,
  updateInProgress: false,
};

export default TimeSlotFormEvent;
