import config from '../../config';
import { listings } from '../../ducks/Listings.duck';
import { fetchAllNonprofits } from '../../ducks/NonprofitListing.duck';

export const loadData = () => (dispatch, getState) => {
  return Promise.all([
    dispatch(fetchAllNonprofits()),
    dispatch(listings(
      config.custom.impactEstimateListings
        .concat([config.custom.impactEstimateHighlightedNonprofit])
    )),
  ]);
}
