import React, { useEffect, useState } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import sharetribe from '@givsly/sharetribe-utils';
import { types as sdkTypes } from '../../util/sdkLoader';
import { injectIntl } from '../../util/reactIntl';
import { ensureCurrentUser } from '../../util/data';
import { fetchCurrentUser } from '../../ducks/user.duck';
import {
  LayoutSideNavigation,
  LayoutWrapperMain,
  LayoutWrapperTopbar,
  LayoutWrapperFooter,
  Footer,
  Page,
  UserNav,
  IconSpinner,
} from '../../components';
import { TopbarContainer } from '../../containers';
import ApprovalTab from './tabs/ApprovalTab';
import EmptyTab from './tabs/EmptyTab';
import CausesTab from './tabs/CausesTab';
import CollaborationTab from './tabs/CollaborationTab';
import ExclusionsTab from './tabs/ExclusionsTab';
import SuccessTab from './tabs/SuccessTab';

import { isScrollingDisabled } from '../../ducks/UI.duck';
import css from './PartnershipsPage.css';

import PartnerhipsSideNav from './PartnershipsSideNav';
import { ONBOARDING_STEPS, PARTNERSHIP_TABS } from './constants';
import { requestUpdateListing } from '../../ducks/UserListing.duck';
import { uploadOfferImage } from '../../ducks/outreach.duck';
import { getMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import PartnershipsForm from './Partnershipsform';
import PartnershipDisclaimer from './PartnershipDisclaimer';
import { FormattedMessage } from 'react-intl';
import { withRouter } from 'react-router-dom';

const { UUID } = sdkTypes;

const ActiveTab = ({ tab, ...rest }) => {
  const { values, setTabsDisabled } = rest;

  const approvePortfolioPresentation =
    values.approvePortfolioPresentation &&
    values.approvePortfolioPresentation.length === 1 &&
    values.approvePortfolioPresentation.includes('Yes');

  useEffect(() => {
    setTabsDisabled(!approvePortfolioPresentation);
  }, [approvePortfolioPresentation, setTabsDisabled]);

  switch (true) {
    case PARTNERSHIP_TABS.APPROVAL === tab:
      return <ApprovalTab {...rest} />;
    case PARTNERSHIP_TABS.CAUSES === tab && approvePortfolioPresentation:
      return <CausesTab {...rest} />;
    case PARTNERSHIP_TABS.COLLABORATIONS === tab && approvePortfolioPresentation:
      return <CollaborationTab {...rest} />;
    case PARTNERSHIP_TABS.EXCLUSIONS === tab && approvePortfolioPresentation:
      return <ExclusionsTab {...rest} />;
    case PARTNERSHIP_TABS.SUCCESS === tab && approvePortfolioPresentation:
      return <SuccessTab {...rest} />;
    default:
      return <EmptyTab />;
  }
};

export const PartnershipsPageComponent = (props) => {
  const {
    scrollingDisabled,
    params,
    ensuredListing,
    ensuredCurrentUser,
    onUpdateListing,
    onUploadS3Image,
    history,
  } = props;
  const { tab = PARTNERSHIP_TABS.APPROVAL } = params;

  const user = ensuredCurrentUser && ensuredCurrentUser.id ? ensuredCurrentUser : null;
  const listing = ensuredListing && ensuredListing.id ? ensuredListing : null;

  const [disclaimerVisible, setDisclaimerVisible] = useState(false);
  const [tabsDisabled, setTabsDisabled] = useState(true);
  const [sideNavVisible ] = useState(true);

  const onboardingProgress =
    (listing && listing.attributes.publicData.goodAdvertisingOnboardingProgress) || {};
  const PARTNERSHIP_INFORMATION_DISABLED =
    onboardingProgress && onboardingProgress[ONBOARDING_STEPS.PARTNERSHIP_INFORMATION]
      ? false
      : true;
  const CAUSES_AND_EVENTS_DISABLED =
    onboardingProgress && onboardingProgress[ONBOARDING_STEPS.CAUSES_AND_EVENTS] ? false : true;
  const BRAND_COLLABORATIONS_DISABLED =
    onboardingProgress && onboardingProgress[ONBOARDING_STEPS.BRAND_COLLABORATIONS] ? false : true;
  const EXCLUSIONS_DISABLED =
    onboardingProgress && onboardingProgress[ONBOARDING_STEPS.EXCLUSIONS] ? false : true;

  return (
    <Page title="Partnerships" scrollingDisabled={scrollingDisabled}>
      <LayoutSideNavigation>
        <LayoutWrapperTopbar>
          <TopbarContainer
            currentPage="PartnershipsPage"
            desktopClassName={css.desktopTopbar}
            mobileClassName={css.mobileTopbar}
          />
          <UserNav currentUser={user} selectedPageName="PartnershipsPage" />
        </LayoutWrapperTopbar>
        {sideNavVisible && (
          <PartnerhipsSideNav
            currentTab={tab}
            currentUser={user}
            tabsDisabled={tabsDisabled}
            partnershipInformationDisabled={PARTNERSHIP_INFORMATION_DISABLED}
            causesAndEventsDisabled={CAUSES_AND_EVENTS_DISABLED}
            brandCollaborationsDisabled={BRAND_COLLABORATIONS_DISABLED}
            exclusionsDisabled={EXCLUSIONS_DISABLED}
          />
        )}
        <LayoutWrapperMain>
          {!user || !listing ? (
            <p>
              <IconSpinner /> Loading...
            </p>
          ) : !user.attributes.profile.publicData.isNPO ? (
            <div className={css.error}>
              <FormattedMessage id="PartnershipsPage.notNPO" />
            </div>
          ) : (
            <PartnershipsForm
              user={user}
              listing={listing}
              onUpdateListing={onUpdateListing}
              onUploadS3Image={onUploadS3Image}
              setDisclaimerVisible={setDisclaimerVisible}
              history={history}
            >
              {(formRenderProps) =>
                disclaimerVisible ? (
                  <PartnershipDisclaimer setDisclaimerVisible={setDisclaimerVisible} />
                ) : (
                  <ActiveTab {...formRenderProps} tab={tab} setTabsDisabled={setTabsDisabled} />
                )
              }
            </PartnershipsForm>
          )}
        </LayoutWrapperMain>
        <LayoutWrapperFooter>
          <Footer />
        </LayoutWrapperFooter>
      </LayoutSideNavigation>
    </Page>
  );
};

const mapStateToProps = (state) => {
  // Topbar needs user info.
  const { currentUser } = state.user;
  const getOwnListing = (id) => {
    const listings = getMarketplaceEntities(state, [{ id, type: 'ownListing' }]);
    return listings.length === 1 ? listings[0] : null;
  };
  const ensuredListing = currentUser
    ? sharetribe.listing.ensureOwnNonprofitListing(
        getOwnListing(new UUID(currentUser.attributes.profile.publicData.listingId))
      )
    : null;

  return {
    currentUser,
    scrollingDisabled: isScrollingDisabled(state),
    ensuredListing,
    ensuredCurrentUser: ensureCurrentUser(currentUser),
  };
};

const mapDispatchToProps = (dispatch) => ({
  onUpdateListing: (data) => dispatch(requestUpdateListing(data)),
  onUploadS3Image: (imageData) => dispatch(uploadOfferImage(imageData)),
});

const PartnershipsPage = compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  injectIntl
)(PartnershipsPageComponent);

PartnershipsPage.loadData = () => {
  // Since verify email happens in separate tab, current user's data might be updated
  return fetchCurrentUser();
};

export default PartnershipsPage;
