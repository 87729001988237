import classNames from 'classnames';
import { string } from 'prop-types';
import React from 'react';
import Button from '../Button/Button';
import css from './BookingBreakdown.css';
import uuid from 'uuid/v4';

class LineItemCopyable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isCopied: false,
    };
    this.fieldUuid = uuid();
    this.handleCopyValue = this.handleCopyValue.bind(this);
  }

  handleCopyValue() {
    this.setState({ isCopied: true });

    const element = document.getElementById(this.fieldUuid);
    element.select();
    element.setSelectionRange(0, 99999);
    document.execCommand('copy');
    element.setSelectionRange(0, 0);

    setTimeout(() => {
      this.setState({ isEmailCopied: false });
    }, 1500);
  }

  render() {
    const { label, value } = this.props;
    return (
      <div className={css.detailItem}>
        <div className={css.detailItemLabel}>{label}</div>
        <div className={classNames(css.detailItemValue, css.copyFieldContent)}>
          <input className={css.copyField} id={this.fieldUuid} readOnly={true} value={value} />
          <Button
            className={classNames(
              css.copyButton,
              this.state.isCopied ? css.copyButtonIsReady : null
            )}
            ready={this.state.isCopied}
            onClick={this.handleCopyValue}
            type="button"
          />
        </div>
      </div>
    );
  }
}

LineItemCopyable.propTypes = {
  label: string.isRequired,
  value: string.isRequired,
};

export default LineItemCopyable;
