import { countNewNotifications, queryNotifications } from '../../ducks/notifications.duck';
import { TAB_SETTINGS } from './NotificationsPage';
import { ensureCurrentUser } from '../../util/data';
import { listings } from '../../ducks/Listings.duck';
import { VISIBLE_TRANSITIONS_ONLY } from '../../util/notifications';
import { storableError } from '../../util/errors';
import { fetchCurrentUser } from '../../ducks/user.duck';

export const loadData = (params) => (dispatch, getState) => {
  const { tab } = params;

  return Promise.all([
    dispatch(fetchCurrentUser()),
    dispatch(countNewNotifications()),
    dispatch(queryNotifications(VISIBLE_TRANSITIONS_ONLY)),
  ])
    .then(() => {
      if (tab === TAB_SETTINGS) {
        const ensuredCurrentUser = ensureCurrentUser(getState().user.currentUser);
        const { following } = ensuredCurrentUser.attributes.profile.publicData;
        return dispatch(listings(following));
      }
    })
    .catch((err) => {
      console.error(storableError(err));
    });
};
