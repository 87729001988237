var merge = require("lodash.merge");

var ACTIVITY_LOGIN = "login";
var ACTIVITY_LOGOUT = "logout";
var ACTIVITY_PUBLISH_PROFILE = "publish";
var ACTIVITY_ONLINE = "online";
var ACTIVITY_UPDATE_ACCOUNT_SETTINGS = "updateAccountSettings";
var ACTIVITY_UPDATE_AVAILABILITY = "updateAvailability";
var ACTIVITY_UPDATE_EMAIL = "updateEmail";
var ACTIVITY_UPDATE_NOTIFICATION_SETTINGS = "updateNotificationSettings";
var ACTIVITY_UPDATE_PASSWORD = "updatePassword";
var ACTIVITY_UPDATE_PROFILE = "updateProfile";
var ACTIVITIES = [ACTIVITY_LOGIN, ACTIVITY_LOGOUT, ACTIVITY_ONLINE, ACTIVITY_PUBLISH_PROFILE, ACTIVITY_UPDATE_AVAILABILITY, ACTIVITY_UPDATE_PROFILE];
/**
 * Creates a shell object of a minimal current user and merges that with the input. This ensures that the necessary user
 * structure is defined.
 *
 * @param user
 */

var ensureCurrentUser = function ensureCurrentUser(user) {
  var empty = {
    id: null,
    type: "currentUser",
    attributes: {
      profile: {
        metadata: {},
        publicData: {
          following: [],
          geolocation: {
            city: "",
            state: {
              name: "",
              shortCode: ""
            },
            country: {
              name: "",
              shortCode: ""
            }
          },
          listingId: null,
          methodPriceChoices: {
            chat: "choice_100",
            inPerson: "choice_100"
          },
          interests: [],
          isVolunteer: false,
          onboardingCompleted: false,
          volunteerListingId: null,
          supportedNPOs: [],
          boardMemberIds: []
        },
        privateData: {
          activity: {}
        }
      }
    },
    profileImage: {}
  };
  return merge(empty, user);
};
/**
 * Creates a shell object of a minimal user and merges that with the input. This ensures that the necessary user
 * structure is defined.
 *
 * @param user
 */


var ensureUser = function ensureUser(user) {
  var empty = {
    id: null,
    type: "user",
    attributes: {
      profile: {
        publicData: {
          geolocation: {
            city: "",
            state: {
              name: "",
              shortCode: ""
            },
            country: {
              name: "",
              shortCode: ""
            }
          },
          listingId: null,
          methodPriceChoices: {
            chat: "choice_100",
            inPerson: "choice_100"
          },
          supportedNPOs: [],
          boardMemberIds: []
        }
      }
    }
  };
  return merge(empty, user);
};
/**
 * Checks if two users are the same based on their identifier
 *
 * @param ensuredUser
 * @param ensuredOtherUser
 * @returns {boolean}
 */


var isSameUser = function isSameUser(ensuredUser, ensuredOtherUser) {
  return ensuredUser.id && ensuredOtherUser.id && ensuredUser.id.uuid === ensuredOtherUser.id.uuid;
};

module.exports = {
  ACTIVITY_LOGIN: ACTIVITY_LOGIN,
  ACTIVITY_LOGOUT: ACTIVITY_LOGOUT,
  ACTIVITY_PUBLISH_PROFILE: ACTIVITY_PUBLISH_PROFILE,
  ACTIVITY_ONLINE: ACTIVITY_ONLINE,
  ACTIVITY_UPDATE_ACCOUNT_SETTINGS: ACTIVITY_UPDATE_ACCOUNT_SETTINGS,
  ACTIVITY_UPDATE_AVAILABILITY: ACTIVITY_UPDATE_AVAILABILITY,
  ACTIVITY_UPDATE_EMAIL: ACTIVITY_UPDATE_EMAIL,
  ACTIVITY_UPDATE_NOTIFICATION_SETTINGS: ACTIVITY_UPDATE_NOTIFICATION_SETTINGS,
  ACTIVITY_UPDATE_PASSWORD: ACTIVITY_UPDATE_PASSWORD,
  ACTIVITY_UPDATE_PROFILE: ACTIVITY_UPDATE_PROFILE,
  ACTIVITIES: ACTIVITIES,
  ensureCurrentUser: ensureCurrentUser,
  ensureUser: ensureUser,
  isSameUser: isSameUser
};