import React from 'react';
import css from './SearchTypeMenu.css';
import { Menu, MenuContent, MenuItem, MenuLabel } from '../index';
import { intlShape } from '../../util/reactIntl';
import classNames from 'classnames';
import { func, shape, string } from 'prop-types';
import { createResourceLocatorString } from '../../util/routes';
import routeConfiguration from '../../routeConfiguration';

class SearchTypeMenu extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isOpen: false,
    };

    this.handleClickNonprofits = this.handleClickNonprofits.bind(this);
    this.handleClickPeople = this.handleClickPeople.bind(this);
    this.onToggleActive = this.onToggleActive.bind(this);
  }

  getTranslation = (name) => {
    return this.props.intl.formatMessage({
      id: `SearchTypeMenu.${name}`,
    });
  };

  handleClickPeople() {
    this.onToggleActive(false);
    this.props.history.push(createResourceLocatorString('SearchPage', routeConfiguration()));
  }

  handleClickNonprofits() {
    this.onToggleActive(false);
    this.props.history.push(
      createResourceLocatorString('NonprofitSearchPage', routeConfiguration())
    );
  }

  onToggleActive(newState) {
    this.setState({
      isOpen: newState,
    });
  }

  render() {
    const { activeItem } = this.props;
    return (
      <Menu
        className={css.root}
        isOpen={this.state.isOpen}
        onToggleActive={this.onToggleActive}
        useArrow={false}
      >
        <MenuLabel
          className={classNames(css.menuButton, css[activeItem])}
          isOpenClassName={css.menuButtonOpen}
        >
          <span className={css.menuButtonContent}>{this.getTranslation(activeItem)}</span>
        </MenuLabel>
        <MenuContent hideArrow={true} className={css.menuContent} contentClassName={css.menuItems}>
          <MenuItem
            className={css.menuItem}
            key="searchType-people"
            onClick={this.handleClickPeople}
          >
            {this.getTranslation('people')}
          </MenuItem>
          <MenuItem
            className={css.menuItem}
            key="searchType-nonprofits"
            onClick={this.handleClickNonprofits}
          >
            {this.getTranslation('nonprofits')}
          </MenuItem>
        </MenuContent>
      </Menu>
    );
  }
}

SearchTypeMenu.propTypes = {
  activeItem: string.isRequired,
  history: shape({
    push: func.isRequired,
  }).isRequired,
  intl: intlShape.isRequired,
};

export default SearchTypeMenu;
