import React from 'react';
import { compose } from 'redux';
import { injectIntl } from 'react-intl';
import { func, string } from 'prop-types';
import classNames from 'classnames';
import css from './AvailabilityForm.css';
import { Button } from '../../components';
import { CalendarDownloadOptionsButtonICS } from '../../components/CalendarDownloadOptions/CalendarDownloadOptionsButtonICS';
import { DOWNLOAD_ALL } from '../../components/CalendarDownloadOptions/CalendarDownloadOptions';
import { propTypes } from '../../util/types';

const AvailabilityFormModalFirstMeetingTimeAddedComponent = ({
  intl,
  eventTitle,
  onAddMoreMeetingTimes,
  onConnectToCalendar,
  ensuredCurrentUser,
  transactionId,
}) => (
  <div className={css.modalPadding}>
    <h2 className={css.modalHeader}>
      {intl.formatMessage(
        {
          id: `AvailabilityForm.firstMeetingTimesAdded.${eventTitle ? 'event' : 'regular'}.header`,
        },
        { eventTitle }
      )}
    </h2>
    <p className={css.modalContent}>
      {intl.formatMessage(
        {
          id: `AvailabilityForm.firstMeetingTimesAdded.${
            eventTitle ? 'event' : 'regular'
          }.bodyText`,
        },
        { eventTitle }
      )}
    </p>
    <Button
      className={classNames(css.buttonConfirm, css.buttonList)}
      onClick={onAddMoreMeetingTimes}
      type="button"
    >
      {intl.formatMessage({ id: 'AvailabilityForm.firstMeetingTimesAdded.moreTimesButton' })}
    </Button>
    <Button
      className={classNames(css.buttonCancel, css.buttonList)}
      onClick={onConnectToCalendar}
      type="button"
    >
      {intl.formatMessage({ id: 'AvailabilityForm.firstMeetingTimesAdded.connectCalendar' })}
    </Button>
    <CalendarDownloadOptionsButtonICS
      className={classNames(css.buttonCancel, css.buttonList)}
      option={DOWNLOAD_ALL}
      ensuredCurrentUser={ensuredCurrentUser}
      transactionId={transactionId}
    >
      {intl.formatMessage({ id: 'AvailabilityForm.firstMeetingTimesAdded.downloadICS' })}
    </CalendarDownloadOptionsButtonICS>
  </div>
);

AvailabilityFormModalFirstMeetingTimeAddedComponent.propTypes = {
  eventTitle: string,
  onAddMoreMeetingTimes: func.isRequired,
  onConnectToCalendar: func.isRequired,
  ensuredCurrentUser: propTypes.currentUser.isRequired,
  transactionId: string,
};

export const AvailabilityFormModalFirstMeetingTimeAdded = compose(injectIntl)(
  AvailabilityFormModalFirstMeetingTimeAddedComponent
);
