import React from 'react';
import css from '../LandingPage.css';
import { FormattedMessage } from 'react-intl';

import ParamountLogoURL from './paramount-logo.svg';
import RedditLogoURL from './reddit-logo.svg';
import DentsuLogoURL from './dentsu-logo.svg';
import MaybellineLogoURL from './maybelline-logo.svg';
import NBALogoURL from './nba-logo.svg';
import TradeDeskLogoURL from './the-trade-desk-logo.svg';
import IPGLogoURL from './ipg-logo.svg';

const LandingPageCompanies = () => {
  return (
    <>
      <p>
        <FormattedMessage id="LandingPage.companies.text" />
      </p>
      <div className={css['section-2022-companies-row']}>
        <img src={RedditLogoURL} alt="Reddit logo" />
        <img src={ParamountLogoURL} alt="Paramount logo" />
        <img src={DentsuLogoURL} alt="Dentsu logo" />
        <img src={TradeDeskLogoURL} alt="TheTradeDesk logo" />
        <img src={NBALogoURL} alt="NBA logo" />
        <img src={IPGLogoURL} alt="IPG logo" />
        <img src={MaybellineLogoURL} alt="Maybelline logo" />
      </div>
    </>
  );
};

export default LandingPageCompanies;
