import React from 'react';
import css from './RowControls.css';
import { withMessages } from '../../util/localization';
import { Button } from '../index';
import { bool, func, string } from 'prop-types';
import classNames from 'classnames';

/**
 * Row controls can be used to add additional controls to entities listed in rows (for example in
 * tabular data or lists). This configurable component can have multiple controls for each row. Each
 * component can be enabled or disabled by default and all actions contain hooks so additional event
 * triggers can be added.
 */
class RowControls extends React.Component {
  constructor(props) {
    super(props);

    this.handleClickDeleteCancel = this.handleClickDeleteCancel.bind(this);
    this.handleClickDeleteConfirm = this.handleClickDeleteConfirm.bind(this);
    this.handleClickDeleteToggle = this.handleClickDeleteToggle.bind(this);

    this.state = {
      showDeleteOptions: false,
    };
  }

  handleClickDeleteCancel() {
    const { onClickDeleteCancel } = this.props;
    if (onClickDeleteCancel) {
      onClickDeleteCancel();
    }
    this.setState({
      showDeleteOptions: false,
    });
  }

  handleClickDeleteConfirm() {
    const { onClickDeleteConfirm } = this.props;
    if (onClickDeleteConfirm) {
      onClickDeleteConfirm();
    }
    this.setState({
      showDeleteOptions: false,
    });
  }

  handleClickDeleteToggle() {
    const { onClickDeleteToggle } = this.props;
    if (onClickDeleteToggle) {
      onClickDeleteToggle();
    }
    this.setState({
      showDeleteOptions: true,
    });
  }

  get deleteControl() {
    const { className, getMessage } = this.props;
    const { showDeleteOptions } = this.state;

    const classes = classNames(css.root, className);

    return (
      <div className={classes}>
        {!showDeleteOptions ? (
          <Button
            className={css.toggleDelete}
            onClick={this.handleClickDeleteToggle}
            typ={'button'}
          />
        ) : (
          <div className={css.options}>
            <Button
              className={css.buttonDelete}
              onClick={this.handleClickDeleteConfirm}
              type={'button'}
            >
              {getMessage('delete')}
            </Button>
            <Button
              className={css.buttonCancel}
              onClick={this.handleClickDeleteCancel}
              type={'button'}
            >
              {getMessage('cancel')}
            </Button>
          </div>
        )}
      </div>
    );
  }

  render() {
    const { hasDeleteControl } = this.props;
    return <div className={css.root}>{hasDeleteControl ? this.deleteControl : null}</div>;
  }
}

RowControls.propTypes = {
  className: string,
  getMessage: func.isRequired,
  hasDeleteControl: bool,
  onClickDeleteToggle: func,
  onClickDeleteConfirm: func,
  onClickDeleteCancel: func,
};

RowControls.defaultProps = {
  className: null,
  hasDeleteControl: true,
};

export default withMessages(RowControls, 'RowControls');
