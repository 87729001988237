import { types as sdkTypes } from '../util/sdkLoader';
const { LatLng: SDKLatLng, LatLngBounds: SDKLatLngBounds } = sdkTypes;

export const fromUserShortFormat = (ensuredUser) => {
  const { city, state } = ensuredUser.attributes.profile.publicData.geolocation;
  if (city && state) {
    return city + ', ' + state.shortCode;
  }
  return null;
};

export const flattenSdkBounds = (sdkBounds) => {
  if (typeof sdkBounds !== 'object') {
    return null;
  }

  return {
    ne: flattenSdkLatLng(sdkBounds.ne),
    sw: flattenSdkLatLng(sdkBounds.sw),
  };
};

export const flattenSdkBoundsForSearch = (sdkBounds) => {
  if (typeof sdkBounds !== 'object') {
    return null;
  }

  return [...sdkBounds.ne, ...sdkBounds.sw].join(',');
};

export const flattenSdkLatLng = (sdkLatLng) => {
  if (typeof sdkLatLng !== 'object') {
    return null;
  }
  return {
    latitude: sdkLatLng.lat,
    longitude: sdkLatLng.lng,
  };
};

export const mapboxLngLatToSDKLatLng = (lngLat) => {
  const mapboxLng = lngLat.lng;

  // For bounding boxes that overlap the anti-meridian Mapbox sometimes gives
  // longitude values outside -180 and 180 degrees.Those values are converted
  // so that longitude is always between -180 and 180.
  const lng = mapboxLng > 180 ? mapboxLng - 360 : mapboxLng < -180 ? mapboxLng + 360 : mapboxLng;

  return new SDKLatLng(lngLat.lat, lng);
};

export const mapboxBoundsToSDKBounds = (mapboxBounds) => {
  if (!mapboxBounds) {
    return null;
  }

  return new SDKLatLngBounds(
    mapboxLngLatToSDKLatLng(mapboxBounds.ne),
    mapboxLngLatToSDKLatLng(mapboxBounds.sw)
  );
};
