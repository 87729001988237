import { useState, useEffect } from 'react';

const useMediaQuery = (mediaQuery) => {
  const [match, setMatch] = useState(true);

  useEffect(() => {
    if (window && Window && window instanceof Window) {
      const query = window.matchMedia(mediaQuery);
      setMatch(query.matches);

      const onMatchChange = (mediaChangeEvent) => {
        setMatch(mediaChangeEvent.matches);
      };

      query.addEventListener('change', onMatchChange);

      return () => {
        query.removeEventListener('change', onMatchChange);
      };
    }
  }, [
    setMatch
  ]);

  return match;
};

export default useMediaQuery;
