import React from 'react';
import { string, func, oneOf, object, number } from 'prop-types';
import { useMessages } from '../../util/localization';
import classNames from 'classnames';
import { propTypes } from '../../util/types';
import sharetribe from '@givsly/sharetribe-utils';
import { richText } from '../../util/richText';
import { createSlug } from '../../util/urlHelpers';
import { AvatarHuge, NamedLink } from '../../components';
import config from '../../config';
import { FOOTER_TYPE_DEFAULT, FOOTER_TYPE_IMPACT, FOOTER_TYPE_IMPACT_TOTAL } from './constants';

import css from './ListingCard.css';
import {
  AVATAR_SIZES_EXTRA_LARGE,
  AVATAR_SIZES_HUGE,
  AVATAR_SIZES_LARGE,
  AVATAR_SIZES_MEDIUM,
  AvatarExtraLarge
} from '../Avatar/Avatar';
import { AvatarLarge, AvatarMedium } from '..';
import { getUserVisibleEventRoleBadges } from '../../util/events';

const MIN_LENGTH_FOR_LONG_WORDS = 10;


export const ListingCardComponent = (props) => {
  const {
    avatarSize,
    className,
    footerItemLimit,
    footerType,
    rootClassName,
    listing,
    setActiveListing,
    nonprofitImpactMap,
    npoListingNames,
    onClick,
    maxInterestRows,
    backgroundImage,
    trackEvent
  } = props;
  const getMessage = useMessages('ListingCard');
  const classes = classNames(rootClassName || css.root, className);
  const currentListing = sharetribe.listing.ensureListing(listing);
  const currentAuthor = sharetribe.user.ensureUser(listing.author);
  const userName = currentAuthor.attributes.profile.publicData.firstName;
  const id = currentListing.id.uuid;
  const { title = '' } = currentListing.attributes;
  const slug = createSlug(title);
  let footerItemCount = 0;

  const { companyName, firstName, lastName, interests, jobTitle, supportedNPOs } = currentListing.attributes.publicData;

  const nonprofits = supportedNPOs.map((i) =>
    npoListingNames[i] ? { id: i, name: npoListingNames[i] } : { id: i, name: 'UNKNOWN' }
  );
  nonprofits.sort((a, b) => (a.name > b.name ? 1 : -1));

  // Filter the interests so that only approved interests are shown on the cards, with a limit of
  // 3 topics
  const filteredInterests = config.custom.interests
    .filter((i) => interests.indexOf(i.label) >= 0)
    .map((i) => i.label)
    .slice(0, 3);

  let avatar = null;
  switch (avatarSize) {
    default:
      avatar = <AvatarHuge user={currentAuthor}/>;
      break;
    case AVATAR_SIZES_EXTRA_LARGE:
      avatar = <AvatarExtraLarge user={currentAuthor}/>;
      break;
    case AVATAR_SIZES_LARGE:
      avatar = <AvatarLarge user={currentAuthor}/>;
      break;
    case AVATAR_SIZES_MEDIUM:
      avatar = <AvatarMedium user={currentAuthor}/>;
      break;
  }

  const backgroundImageStyle = backgroundImage
    ? { backgroundImage: `url(${backgroundImage})` }
    : {};

  const eventRoleBadges = getUserVisibleEventRoleBadges(currentListing);

  return (
    <NamedLink className={classes} name="ListingPage" onClick={() => onClick(`${firstName} ${lastName}`)} params={{ id, slug }}>
      <div
        className={css.threeToTwoWrapper}
        style={backgroundImageStyle}
        onMouseEnter={() => setActiveListing(currentListing.id)}
        onMouseLeave={() => setActiveListing(null)}
        onClick={() =>
          trackEvent && trackEvent({ action: 'ClickListing', label: 'Listing', value: title })
        }
      >
        <div className={css.badgeWrapper}>
          {eventRoleBadges.map((badge, idx) => (
            <span key={idx} className={css.badge}>
              {badge}
            </span>
          ))}
        </div>
        <div className={css.avatarWrapper}>{avatar}</div>
        <div className={css.info}>
          <div className={css.mainInfo}>
            <div className={css.title}>
              {richText(title, {
                longWordMinLength: MIN_LENGTH_FOR_LONG_WORDS,
                longWordClass: css.longWord
              })}
            </div>
            <div className={css.jobTitle}>
              {richText(jobTitle, {
                longWordMinLength: MIN_LENGTH_FOR_LONG_WORDS,
                longWordClass: css.longWord
              })}
            </div>
            <div className={css.company}>
              {richText(companyName, {
                longWordMinLength: MIN_LENGTH_FOR_LONG_WORDS,
                longWordClass: css.longWord
              })}
            </div>
            <div
              className={classNames(
                css.authorInterests,
                maxInterestRows && css[`authorInterests-max-rows-${maxInterestRows}`]
              )}
            >
              {filteredInterests.map((kw) => (
                <span className={css.authorInterest} key={kw}>
                  {kw}
                </span>
              ))}
            </div>

            <div>
              <hr className={css.divider}/>
              {footerType === FOOTER_TYPE_IMPACT ? (
                <>
                  {nonprofits.map(nonprofit => {
                    const key = `${currentAuthor.id.uuid}-${nonprofit.id}}`;
                    if (nonprofitImpactMap[nonprofit.id]) {
                      const impact = nonprofitImpactMap[nonprofit.id];
                      const hasImpact = impact.impactUnits > 0 && impact.impactType.plural && impact.description;
                      return hasImpact ? (
                        <div key={key}>
                          <div className={css.impactType}>{impact.impactUnits} {impact.impactType.plural}</div>
                          <div className={css.impactDescription}>{impact.description}</div>
                        </div>
                      ) : (
                        <React.Fragment key={key} />
                      );
                    }
                    return <React.Fragment key={key} />

                  })}
                </>
              ) : footerType === FOOTER_TYPE_IMPACT_TOTAL ?(
                <>
                  {nonprofits.map(nonprofit => {
                    const key = `${currentAuthor.id.uuid}-${nonprofit.id}}`;

                    if (nonprofitImpactMap[nonprofit.id]) {
                      const impact = nonprofitImpactMap[nonprofit.id];
                      impact.impactUnits = parseInt(impact.impactUnits);
                      const actualImpact = currentListing.attributes.metadata.stats.actualImpact[nonprofit.id];

                      const hasImpact = actualImpact
                        && impact.impactUnits > 0
                        && impact.impactType.plural
                        && impact.description
                        && footerItemCount < footerItemLimit;
                      footerItemCount = hasImpact ? footerItemCount + 1 : footerItemCount;

                      return hasImpact ? (
                        <div key={key}>
                          <div className={css.impactType}>
                            {Math.round(actualImpact.donationTotal / impact.donationSum * impact.impactUnits)} {impact.impactType.plural}
                          </div>
                          <div className={css.impactDescription}>{impact.description}</div>
                        </div>
                      ) : (
                        <React.Fragment key={key} />
                      );
                    }
                    return <React.Fragment key={key} />

                  })}
                </>
              ) : (
                <>
                  <div className={css.supportingHeading}>{getMessage('nonprofitsImSupporting')}</div>
                    {nonprofits.length ? (
                      nonprofits.map((npo) => (
                        <div className={css.authorNPO} key={npo.id}>
                          {npo.name}
                        </div>
                      ))
                    ) : (
                      <div className={css.noSupportedNonprofits}>
                        {getMessage('noSupportedNonprofitsSelected', { name: userName })}
                      </div>
                    )}
                </>
                )}
            </div>
          </div>
        </div>
      </div>
    </NamedLink>
);
};

ListingCardComponent.defaultProps = {
  avatarSize: AVATAR_SIZES_HUGE,
  className: null,
  footerItemLimit: 3,
  footerType: FOOTER_TYPE_DEFAULT,
  maxInterestRows: null,
  nonprofitImpactMap: {},
  onClick: () => {},
  renderSizes: null,
  rootClassName: null,
  setActiveListing: () => null,
};

ListingCardComponent.propTypes = {
  avatarSize: string,
  backgroundImage: string,
  className: string,
  footerItemLimit: number,
  footerType: string,
  listing: propTypes.listing.isRequired,
  maxInterestRows: oneOf([1, 2]),
  nonprofitImpactMap: object,
  onClick: func,
  renderSizes: string,
  rootClassName: string,
  setActiveListing: func,
  trackEvent: func,
};

export default ListingCardComponent;
