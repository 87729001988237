import React, { Component } from 'react';
import { arrayOf, bool, func, shape, string } from 'prop-types';
import { FeatureBlockWithListings, FeatureBlockWithListingsContent } from '../../../../components';
import EventLandingPageButton from '../EventLandingPageButton/EventLandingPageButton';
import css from './EventLandingPageNonprofit.css';
import { intlShape } from '../../../../util/reactIntl';

class EventLandingPageNonprofit extends Component {
  render() {
    const {
      isAuthenticated,
      getListing,
      history,
      intl,
      listingIds,
      title,
      text,
      smallTitle,
      buttons,
      background,
      trackEvent,
    } = this.props;

    if (!listingIds) return null;

    return (
      <div className={css.container}>
        <FeatureBlockWithListings
          contents={[
            <FeatureBlockWithListingsContent
              smallTitle={smallTitle}
              title={title}
              text={text}
              actions={buttons.map((button, idx) => (
                <EventLandingPageButton
                  key={idx}
                  className={idx === 0 ? css.button : css.buttonSecondary}
                  isAuthenticated={isAuthenticated}
                  button={button}
                  listingId={listingIds[0]}
                  trackEvent={(params) => trackEvent({ ...params, label: 'Nonprofit block' })}
                />
              ))}
            />,
          ]}
          getListing={getListing}
          listingIds={listingIds}
          intl={intl}
          history={history}
          isNonprofit={true}
          featureContentStyle={{ backgroundImage: `url(${background})` }}
          featureContentClassName={css.featureContent}
          trackEvent={(params) => trackEvent({ ...params, label: 'Nonprofit block' })}
        />
      </div>
    );
  }
}

EventLandingPageNonprofit.propTypes = {
  background: string,
  getListing: func.isRequired,
  history: shape({
    push: func.isRequired,
  }).isRequired,
  intl: intlShape.isRequired,
  isAuthenticated: bool.isRequired,
  listingIds: arrayOf(string).isRequired,
  title: string.isRequired,
  text: string.isRequired,
  smallTitle: string,
  buttons: arrayOf(
    shape({
      loggedIn: shape({
        label: string.isRequired,
        name: string.isRequired,
      }),
      notLoggedIn: shape({
        label: string.isRequired,
        name: string.isRequired,
      }),
    })
  ),
  trackEvent: func.isRequired,
};

export default EventLandingPageNonprofit;
