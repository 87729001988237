import React from 'react';
import { string, bool, shape, func } from 'prop-types';
import { FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';

import css from './SectionHero.css';
import { GivslySearch, NamedLink, PrimaryButton } from '../../components';
import config from '../../config';
import image from './hero-people.png';
import IconHourGlass from '../LocationAutocompleteInput/IconHourGlass';
import { parse, stringify } from '../../util/urlHelpers';

const MOBILE_SEARCH_KEY = 'mobilesearch';

class SectionHero extends React.Component {
  constructor(props) {
    super(props);
    this.openMobileSearch = this.openMobileSearch.bind(this);
  }

  openMobileSearch() {
    const { history, location } = this.props;
    const { pathname, search, state } = location;
    const searchString = `?${stringify({ [MOBILE_SEARCH_KEY]: 'open', ...parse(search) })}`;
    history.push(`${pathname}${searchString}`, state);
  }

  render() {
    const { rootClassName, className, history, isAuthenticated } = this.props;

    const classes = classNames(rootClassName || css.root, className);

    const signupLink = (
      <NamedLink name="SignupPage" className={css.signupLink}>
        <FormattedMessage id="SectionHero.signupLink" />
      </NamedLink>
    );

    const loginLink = (
      <NamedLink name="LoginPage" className={css.loginLink}>
        <FormattedMessage id="SectionHero.loginLink" />
      </NamedLink>
    );

    const signUpSection = (
      <div className={css.signUp}>
        <FormattedMessage id="SectionHero.signup" values={{ signupLink }} />
      </div>
    );

    const limitedSignUpSection = (
      <>
        <NamedLink name="SignupPage" className={css.signUpButtonLink}>
          <PrimaryButton className={css.signUpButton}>
            <FormattedMessage id="SectionHero.signupLink" />
          </PrimaryButton>
        </NamedLink>

        <div className={css.login}>
          <FormattedMessage id="SectionHero.login" values={{ loginLink }} />
        </div>
      </>
    );

    return (
      <div className={classes}>
        <img className={css.heroImage} src={image} alt={''} />
        <div className={css.heroContent}>
          <h1 className={css.heroMainTitle}>
            <FormattedMessage id="SectionHero.title" />
          </h1>
          <h2 className={css.heroSubTitle}>
            <FormattedMessage id="SectionHero.subTitle" />
          </h2>
          <div className={css.desktopSearch}>
            <GivslySearch history={history} id={'herosearch'} className={css.heroSearch} />
          </div>
          <div className={css.mobileSearch} onClick={this.openMobileSearch}>
            <IconHourGlass />
            <FormattedMessage id="GivslySearch.mobilePlaceholder" />
          </div>
          {!config.custom.limitUi && !isAuthenticated ? signUpSection : null}
          {config.custom.limitUi && !isAuthenticated ? limitedSignUpSection : null}
        </div>
      </div>
    );
  }
}

SectionHero.defaultProps = {
  rootClassName: null,
  className: null,
  isAuthenticated: false,
};

SectionHero.propTypes = {
  history: shape({
    push: func.isRequired,
  }).isRequired,
  rootClassName: string,
  className: string,
  isAuthenticated: bool,
};

export default SectionHero;
