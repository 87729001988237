import React from 'react';
import css from './FeaturedLocationsMenu.css';
import config from '../../config';
import { createResourceLocatorString } from '../../util/routes';
import routeConfiguration from '../../routeConfiguration';
import { flattenSdkBoundsForSearch } from '../../util/location';
import { func, object, shape, string } from 'prop-types';
import classNames from 'classnames';
import { Button } from '../index';

class FeaturedLocationsMenu extends React.Component {
  constructor(props) {
    super(props);
    this.goToResults = this.goToResults.bind(this);
    this.handleOnMouseDown = this.handleOnMouseDown.bind(this);
  }

  handleOnMouseDown(e) {
    // Avoid blur event on Safari causing the modal to close and the click not to register
    e.preventDefault();
  }

  goToResults(featuredLocation) {
    const { onClickLocation, urlQueryParams } = this.props;
    if (typeof onClickLocation === 'function') {
      onClickLocation(featuredLocation);
    }

    const url = createResourceLocatorString(
      this.props.pageName,
      routeConfiguration(),
      this.props.pathParams,
      {
        ...urlQueryParams,
        origin: featuredLocation.origin,
        bounds: flattenSdkBoundsForSearch(featuredLocation.bounds),
        placeName:
          featuredLocation.place === 'Anywhere'
            ? 'Anywhere'
            : `${featuredLocation.place}, ${featuredLocation.region}`,
      }
    );
    this.props.history.push(url);
  }

  render() {
    const { className } = this.props;
    const classes = classNames(css.locations, className);
    return (
      <ol className={classes}>
        {config.custom.featuredLocations.map((featuredLocation) => {
          return (
            <li
              className={css.location}
              key={`location-${featuredLocation.place}-${featuredLocation.region}`}
            >
              <Button
                className={css.link}
                onClick={() => this.goToResults(featuredLocation)}
                onMouseDown={this.handleOnMouseDown}
                type="button"
              >
                <span className={css.place}>{featuredLocation.place}</span>
                <span className={css.region}>{featuredLocation.region}</span>
              </Button>
            </li>
          );
        })}
      </ol>
    );
  }
}

FeaturedLocationsMenu.propTypes = {
  className: string,
  history: shape({
    push: func.isRequired,
  }).isRequired,
  onClickLocation: func,
  pageName: string,
  pathParams: object,
  urlQueryParams: object,
};

FeaturedLocationsMenu.defaultProps = {
  className: null,
  onClickLocation: null,
  pageName: 'SearchPage',
  pathParams: {},
  urlQueryParams: {},
};

export default FeaturedLocationsMenu;
