import React from 'react';
import css from './PanelHeader.css';
import { node, string } from 'prop-types';
import classNames from 'classnames';

const PanelHeader = (props) => {
  const { className, header, subHeader } = props;
  const classes = classNames(css.headingContainer, className);
  return (
    <div className={classes}>
      <h1 className={css.heading}>{header}</h1>
      {subHeader ? <p className={css.subHeading}>{subHeader}</p> : null}
    </div>
  );
};

PanelHeader.propTypes = {
  className: string,
  header: node,
  subHeader: node,
};

PanelHeader.defaultProps = {
  className: null,
  subHeader: null,
};

export default PanelHeader;
