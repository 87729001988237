import { listings } from './Listings.duck';
import { isBefore, isSameOrAfter } from '../util/timeSlots';
import { ensureCurrentUser, ensureOwnListing } from '../util/data';
import eventsData from '../assets/events/events-configuration.json';
import config from '../config';

// This date formatting is set, so we can test event in staging before any event has been visible in production
const getFromToDatesForTesting = (originalDates) => {
  if (config.custom.isProduction) return originalDates;

  console.log('This is staging, sandbox or local environment. Using custom dates for the event.');
  return {
    ...originalDates,
    from: '2020-07-01T12:00:00',
  };
};
const getNonprofitsForTesting = (supportedNPOs) => {
  if (config.custom.isProduction) return supportedNPOs;

  if (config.custom.isSandbox) {
    console.log('This is sandbox environment. Using sandbox non profits for the event.');
    return ['5f314155-d015-465c-83c8-594ebc387de4']; // Ad Council on sandbox
  }

  console.log('This is staging or local environment. Using Dev nonprofits for the event.');
  return ['5ece48e0-c4f4-42dd-b9f6-ad774ef6ea6e', '5e15c995-d611-415b-9f02-2935d40067e7'];
};

const eventAvailableForTesting = (event) => ({
  ...event,
  visible: getFromToDatesForTesting(event.visible),
  bookingOpen: getFromToDatesForTesting(event.bookingOpen),
  supportedNPOs: getNonprofitsForTesting(event.supportedNPOs),
});

const eventsDataFormatted = () => {
  if (config.custom.isProduction) {
    return eventsData.events;
  }

  return eventsData.events.map(eventAvailableForTesting);
};

// ================ Action types ================ //
export const FETCH_EVENTS_REQUEST = 'app/Events/FETCH_EVENTS_REQUEST';
export const FETCH_EVENTS_SUCCESS = 'app/Events/FETCH_EVENTS_SUCCESS';
export const FETCH_EVENTS_ERROR = 'app/Events/FETCH_EVENTS_ERROR';

export const FETCH_EVENT_REQUEST = 'app/Events/FETCH_EVENT_REQUEST';
export const FETCH_EVENT_SUCCESS = 'app/Events/FETCH_EVENT_SUCCESS';
export const FETCH_EVENT_ERROR = 'app/Events/FETCH_EVENT_ERROR';

// ================ Reducer ================ //

const initialState = {
  events: [],
  fetchEventsInProgress: false,
  fetchEventsError: null,
};

export default function eventsReducer(state = initialState, action = {}) {
  const { type, payload } = action;

  switch (type) {
    case FETCH_EVENTS_REQUEST:
    case FETCH_EVENT_REQUEST:
      return {
        ...state,
        fetchEventsInProgress: true,
        fetchEventsError: null,
      };

    case FETCH_EVENTS_SUCCESS:
      return {
        ...state,
        events: payload,
        fetchEventsInProgress: false,
        fetchEventsError: null,
      };

    case FETCH_EVENTS_ERROR:
    case FETCH_EVENT_ERROR:
      return {
        ...state,
        fetchEventsInProgress: false,
        fetchEventsError: payload,
      };

    case FETCH_EVENT_SUCCESS:
      return {
        ...state,
        events: [...state.events, payload],
        fetchEventsInProgress: false,
        fetchEventsError: null,
      };

    default:
      return state;
  }
}

// ================ Action creators ================ //
export const fetchEventsRequest = () => ({ type: FETCH_EVENTS_REQUEST });
export const fetchEventsSuccess = (response) => ({ type: FETCH_EVENTS_SUCCESS, payload: response });
export const fetchEventsError = (error) => ({ type: FETCH_EVENTS_ERROR, payload: error });

export const fetchEventRequest = () => ({ type: FETCH_EVENT_REQUEST });
export const fetchEventSuccess = (response) => ({ type: FETCH_EVENT_SUCCESS, payload: response });
export const fetchEventError = (error) => ({ type: FETCH_EVENT_ERROR, payload: error });

// ================ Thunks ================ //
const fetchEventsSupportedNPOs = (dispatch, events) => {
  const npoIds = events
    ? events.reduce((arr, event) => [...arr, ...(event.supportedNPOs || [])], [])
    : [];

  return dispatch(listings(npoIds));
};

export const events = () => async (dispatch, getState, sdk) => {
  dispatch(fetchEventsRequest());

  // TODO: Get events from givsly api
  const events = eventsDataFormatted();

  dispatch(fetchEventsSuccess(events));

  fetchEventsSupportedNPOs(dispatch, events);
};

export const event = (eventKey) => async (dispatch, getState, sdk) => {
  const thisState = getState().Events;
  const currentEvent = thisState.events.find((event) => event.key === eventKey);

  if (currentEvent) {
    return currentEvent;
  }

  dispatch(fetchEventRequest());

  const event = eventsDataFormatted().find((event) => event.key === eventKey);

  return Promise.all([
    dispatch(fetchEventSuccess(event)),
    fetchEventsSupportedNPOs(dispatch, [event]),
  ]).then(() => event);
};

/**
 * Checks if given roles includes one of user event roles and returns that role or 'default'
 */
const getUserEventRole = (currentUserListing, roles) => {
  const userEventRoles =
    ensureOwnListing(currentUserListing).attributes.publicData.eventRoles || [];

  return roles.find((role) => userEventRoles.includes(role)) || 'default';
};

const isVolunteeringOpenForUser = (event, currentUserListing, currentUserTimezone) => {
  if (!event.volunteeringOpen) return true; // No volunteer opening time defined. Volunteering is open for all.

  const eventVolunteerOpenTimeRoles = Object.keys(event.volunteeringOpen).filter(
    (role) => role !== 'default'
  );

  const volunteerOpenTimeKey = getUserEventRole(currentUserListing, eventVolunteerOpenTimeRoles);

  return isSameOrAfter(
    new Date(),
    currentUserTimezone,
    event.volunteeringOpen[volunteerOpenTimeKey].from,
    event.timezone,
    'minute'
  );
};
/**
 * Get only events whose volunteering is open for the current user
 */
export const filterEventsAvailableForVolunteering = (events, currentUser, currentUserListing) => {
  const timezone = ensureCurrentUser(currentUser).attributes.profile.publicData.timezone;

  return events
    .filter((event) => isBefore(new Date(), timezone, event.active.to, event.timezone, 'minute')) // Filter out events that are ended
    .filter((event) => isVolunteeringOpenForUser(event, currentUserListing, timezone)); // Filter out events that are not open for volunteering for current user
};
