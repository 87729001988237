import React from 'react';
import { string, bool } from 'prop-types';
import css from './Hero.css';
import { NamedLink } from '../../../../components';
import classNames from 'classnames';

class Hero extends React.Component {
  constructor(props) {
    super(props);
    this.handleClick = this.handleClick.bind(this);
  }

  handleClick = (e) => {
    const { trackEvent, trackingCategory } = this.props;
    trackEvent({
      category: trackingCategory,
      action: 'ClickSignUp',
      label: 'Hero',
    });
    return e;
  };

  render() {
    const { buttonLabel, className, text, title } = this.props;
    return (
      <div className={classNames(css.root, className)}>
        <div className={css.content}>
          <h1 className={css.title}>{title}</h1>
          <p className={css.text}>{text}</p>
          <div className={css.buttonContainer}>
            <NamedLink className={css.button} name={'SignupPage'} onClick={this.handleClick}>
              {buttonLabel}
            </NamedLink>
          </div>
        </div>
      </div>
    );
  }
}

Hero.propTypes = {
  buttonLabel: string.isRequired,
  className: string,
  isAuthenticated: bool,
  title: string.isRequired,
  text: string.isRequired,
  trackingCategory: string.isRequired,
};

Hero.defaultProps = {
  className: null,
  isAuthenticated: false,
};

export default Hero;
