// @todo Transfer functionality to @givsly/sharetribe-utils

import moment from 'moment-timezone';
import {
  getMarketplaceForEnvironment,
  MARKETPLACE_PRODUCTION,
  MARKETPLACE_SANDBOX,
  MARKETPLACE_TEST,
} from './environment';

export const TRANSITION_CREATE_NOTIFICATION = 'transition/create-notification';
export const TRANSITION_PROVIDER_ARCHIVE = 'transition/provider-archive';
export const TRANSITION_PROVIDER_SEEN = 'transition/provider-seen';
export const TRANSITION_PROVIDER_SEEN_SENT = 'transition/provider-seen-sent';
export const TRANSITION_PROVIDER_UPDATE = 'transition/provider-update';
export const TRANSITION_CUSTOMER_ARCHIVE = 'transition/customer-archive';
export const TRANSITION_CUSTOMER_SEEN = 'transition/customer-seen';
export const TRANSITION_CUSTOMER_SEEN_SENT = 'transition/customer-seen-sent';
export const TRANSITION_CUSTOMER_UPDATE = 'transition/customer-update';
export const TRANSITION_OPERATOR_SEND_NEW = 'transition/operator-send-new';
export const TRANSITION_OPERATOR_SEND_SEEN = 'transition/operator-send-seen';
export const TRANSITION_OPERATOR_UPDATE_NEW = 'transition/operator-update-new';
export const TRANSITION_OPERATOR_UPDATE_SEEN = 'transition/operator-update-seen';
export const TRANSITION_OPERATOR_UPDATE_ARCHIVED = 'transition/operator-update-archived';
export const TRANSITION_OPERATOR_ARCHIVE_NEW = 'transition/operator-archive-new';
export const TRANSITION_OPERATOR_ARCHIVE_SEEN = 'transition/operator-archive-seen';
export const TRANSITION_OPERATOR_ARCHIVE_SEEN_SENT = 'transition/operator-archive-seen-sent';
export const TRANSITION_OPERATOR_ARCHIVE_SENT = 'transition/operator-archive-sent';

export const CAPABILITY_ARCHIVE = 'archive';
export const CAPABILITY_SEE = 'see';
export const CAPABILITY_UPDATE = 'update';

export const STATE_ARCHIVED = 'archived';
export const STATE_NEW = 'new';
export const STATE_SEEN = 'seen';
export const STATE_SEEN_SENT = 'seen-sent';
export const STATE_SENT = 'sent';

export const AS_RECEIVER = 'sale';
export const AS_SENDER = 'order';

export const TYPE_ASK_FOR_TIME = 'ask-for-time';
export const TYPE_NEW_TIMES_AVAILABLE = 'new-times-available';

export const ROLE_CUSTOMER = 'customer';
export const ROLE_PROVIDER = 'provider';

export const FREQUENCY_HOURLY = 'hourly';
export const FREQUENCY_DAILY = 'daily';
export const FREQUENCY_WEEKLY = 'weekly';

export const CUSTOMER_TRANSITIONS = [
  TRANSITION_CREATE_NOTIFICATION,
  TRANSITION_CUSTOMER_ARCHIVE,
  TRANSITION_CUSTOMER_SEEN,
  TRANSITION_CUSTOMER_SEEN_SENT,
  TRANSITION_CUSTOMER_UPDATE,
];

export const OPERATOR_TRANSITIONS = [
  TRANSITION_OPERATOR_ARCHIVE_NEW,
  TRANSITION_OPERATOR_ARCHIVE_SEEN,
  TRANSITION_OPERATOR_ARCHIVE_SEEN_SENT,
  TRANSITION_OPERATOR_ARCHIVE_SENT,
  TRANSITION_OPERATOR_SEND_NEW,
  TRANSITION_OPERATOR_SEND_SEEN,
  TRANSITION_OPERATOR_UPDATE_NEW,
  TRANSITION_OPERATOR_UPDATE_SEEN,
  TRANSITION_OPERATOR_UPDATE_ARCHIVED,
];

export const PROVIDER_TRANSITIONS = [
  TRANSITION_PROVIDER_ARCHIVE,
  TRANSITION_PROVIDER_SEEN,
  TRANSITION_PROVIDER_SEEN_SENT,
  TRANSITION_PROVIDER_UPDATE,
];

export const TRANSITIONS_TO_NEW = [
  TRANSITION_CREATE_NOTIFICATION,
  TRANSITION_CUSTOMER_UPDATE,
  TRANSITION_OPERATOR_UPDATE_NEW,
  TRANSITION_PROVIDER_UPDATE,
  TRANSITION_OPERATOR_SEND_NEW,
];

export const ALL_TRANSITIONS = [
  ...CUSTOMER_TRANSITIONS,
  ...OPERATOR_TRANSITIONS,
  ...PROVIDER_TRANSITIONS,
];

// These states indicate a notification is visible to the user (not archived)
export const VISIBLE_TRANSITIONS_ONLY = [
  TRANSITION_CREATE_NOTIFICATION,
  TRANSITION_CUSTOMER_SEEN,
  TRANSITION_CUSTOMER_SEEN_SENT,
  TRANSITION_CUSTOMER_UPDATE,
  TRANSITION_OPERATOR_UPDATE_NEW,
  TRANSITION_OPERATOR_UPDATE_SEEN,
  TRANSITION_OPERATOR_SEND_NEW,
  TRANSITION_OPERATOR_SEND_SEEN,
  TRANSITION_PROVIDER_SEEN,
  TRANSITION_PROVIDER_SEEN_SENT,
  TRANSITION_PROVIDER_UPDATE,
];

export const NEW_TRANSITIONS_ONLY = [
  TRANSITION_CREATE_NOTIFICATION,
  TRANSITION_CUSTOMER_UPDATE,
  TRANSITION_PROVIDER_UPDATE,
  TRANSITION_OPERATOR_UPDATE_NEW,
];

export const SEEN_TRANSITIONS_ONLY = [
  TRANSITION_CUSTOMER_SEEN,
  TRANSITION_CUSTOMER_SEEN_SENT,
  TRANSITION_PROVIDER_SEEN,
  TRANSITION_PROVIDER_SEEN_SENT,
];

export const SENT_TRANSITIONS_ONLY = [TRANSITION_OPERATOR_SEND_NEW, TRANSITION_OPERATOR_SEND_SEEN];

export const ARCHIVED_TRANSITIONS_ONLY = [
  TRANSITION_CUSTOMER_ARCHIVE,
  TRANSITION_PROVIDER_ARCHIVE,
  TRANSITION_OPERATOR_ARCHIVE_NEW,
  TRANSITION_OPERATOR_ARCHIVE_SEEN,
  TRANSITION_OPERATOR_ARCHIVE_SEEN_SENT,
  TRANSITION_OPERATOR_ARCHIVE_SENT,
];

export const UNSEEN_TRANSITIONS_ONLY = [...NEW_TRANSITIONS_ONLY, TRANSITION_OPERATOR_SEND_NEW];

export const humanReadableDate = (date, timezone) => {
  try {
    const comparableDate = date instanceof moment ? date.clone() : moment.tz(date, timezone);

    const today = moment.tz(timezone);
    const yesterday = today.clone().add(-1, 'days');

    if (comparableDate.isSame(today, 'day')) {
      return `Today, ${comparableDate.format('hh:mm a')}`;
    } else if (comparableDate.isSame(yesterday, 'day')) {
      return `Yesterday, ${comparableDate.format('hh:mm a')}`;
    } else {
      return comparableDate.format('MMM D YYYY, hh:mm a');
    }
  } catch (e) {
    return null;
  }
};

export const isNewForCurrentUser = (ensuredNotification, ensuredCurrentUser) => {
  return (
    UNSEEN_TRANSITIONS_ONLY.indexOf(ensuredNotification.attributes.lastTransition) >= 0 &&
    ensuredNotification.provider.id.uuid === ensuredCurrentUser.id.uuid
  );
};

export const getCurrentState = (ensuredNotification) => {
  const { lastTransition } = ensuredNotification.attributes;
  if (NEW_TRANSITIONS_ONLY.indexOf(lastTransition) >= 0) {
    return STATE_NEW;
  } else if (lastTransition === TRANSITION_OPERATOR_SEND_SEEN) {
    return STATE_SEEN_SENT;
  } else if (SEEN_TRANSITIONS_ONLY.indexOf(lastTransition) >= 0) {
    return STATE_SEEN;
  } else if (SENT_TRANSITIONS_ONLY.indexOf(lastTransition) >= 0) {
    return STATE_SENT;
  } else {
    return STATE_ARCHIVED;
  }
};

export const getUserRole = (ensuredCurrentUser, ensuredNotification) => {
  if (ensuredNotification.customer.id && ensuredNotification.provider.id) {
    if (ensuredNotification.customer.id.uuid === ensuredCurrentUser.id.uuid) {
      return ROLE_CUSTOMER;
    } else if (ensuredNotification.provider.id.uuid === ensuredCurrentUser.id.uuid) {
      return ROLE_PROVIDER;
    } else {
      return null;
    }
  }
  return null;
};

/**
 * Checks if a given transition is possible and allowed for the current user. This will check if the
 * user role matches that of the transition and if the current user has the capability to perform
 * that transition on the given notification.
 *
 * @param ensuredCurrentUser
 * @param ensuredNotification
 * @param transition
 * @returns {boolean|boolean}
 */
export const isTransitionAllowed = (ensuredCurrentUser, ensuredNotification, transition) => {
  const transitionObject = getTransitionObject(transition);
  const { capability, userRole: transitionUserRole } = transitionObject;
  const currentUserRole = getUserRole(ensuredCurrentUser, ensuredNotification);

  return (
    currentUserRole === transitionUserRole &&
    hasCapability(capability, ensuredNotification, ensuredCurrentUser)
  );
};

/**
 * Gets the transition that is needed to archive the given notification by the current user. Returns
 * null if no such transition exists or the user is not permitted to transition the given
 * transaction to the archived state (for example the user is not part of the notification
 * transaction or the notification is not in a state from which it can transition to archived).
 *
 * @param ensuredCurrentUser
 * @param ensuredNotification
 * @returns {string|null}
 */
export const getArchiveTransition = (ensuredCurrentUser, ensuredNotification) => {
  const currentState = getCurrentState(ensuredNotification, ensuredCurrentUser);
  const userRole = getUserRole(ensuredCurrentUser, ensuredNotification);
  const userCanArchive = hasCapability(CAPABILITY_ARCHIVE, ensuredNotification, ensuredCurrentUser);
  if (userRole && userCanArchive && currentState !== STATE_ARCHIVED) {
    return `transition/${userRole}-archive-${currentState}`;
  }
  return null;
};

/**
 * This returns the capabilities for a given user and given notification. The capabilities are based
 * on the transaction process, alias and version. While the process and alias are unlikely to
 * change, the version can be different on each marketplace.
 *
 * @param ensuredNotification
 * @param ensuredCurrentUser
 * @returns {[string, string]}
 */
export const getCapabilities = (ensuredNotification, ensuredCurrentUser) => {
  const { processVersion } = ensuredNotification.attributes;
  const marketplace = getMarketplaceForEnvironment();
  const userRole = getUserRole(ensuredCurrentUser, ensuredNotification);
  const capabilities = [CAPABILITY_SEE, CAPABILITY_UPDATE];

  // Capabilities for all roles
  switch (marketplace) {
    default:
      break;
    case MARKETPLACE_PRODUCTION:
      if (processVersion >= 2 && userRole === ROLE_PROVIDER) {
        capabilities.push(CAPABILITY_ARCHIVE);
      }
      break;
    case MARKETPLACE_SANDBOX:
      if (userRole === ROLE_PROVIDER) {
        capabilities.push(CAPABILITY_ARCHIVE);
      }
      break;
    case MARKETPLACE_TEST:
      if (processVersion >= 8 && userRole === ROLE_PROVIDER) {
        capabilities.push(CAPABILITY_ARCHIVE);
      }
      break;
  }

  return capabilities;
};

/**
 * Checks if the current user has a given capability for the given notification
 *
 * @param capability
 * @param ensuredNotification
 * @param ensuredCurrentUser
 * @returns {boolean}
 */
export const hasCapability = (capability, ensuredNotification, ensuredCurrentUser) => {
  return getCapabilities(ensuredNotification, ensuredCurrentUser).indexOf(capability) >= 0;
};

/**
 * Creates a transition object out of the transition string. Transitions are built up in a fixed
 * format and can therefore be easily processed. The transition format is as follows:
 * transition/{userRole}-{capability}-{fromState}
 *
 * The transition object resulting out of this is constructed in the following way:
 * {
 *   userRole: enum (customer, provider (... operator)),
 *   capability: enum (seen, archive, update),
 *   fromState: enum (new, seen, sent, seen-sent, archived)
 * }
 *
 * Note: Operator based transitions are not possible using the Sharetribe Marketplace API
 *
 * @param transition
 * @returns {object}
 */
const getTransitionObject = (transition) => {
  const transitionParts = transition.split('/');
  const transitionSplitParts =
    transitionParts.length > 1 ? transitionParts[1].split('-') : [null, null, null];
  return {
    userRole: transitionSplitParts[0],
    capability: transitionSplitParts[1],
    // If the third segment of the transition split parts is not set, 'new' is assumed
    fromState: transitionSplitParts.length > 1 ? transitionSplitParts[2] : 'new',
  };
};
