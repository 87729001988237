import React from 'react';
import css from './Notification.css';
import { withMessages } from '../../util/localization';
import { propTypes } from '../../util/types';
import { Avatar, Button } from '../index';
import { func, shape, string } from 'prop-types';
import classNames from 'classnames';
import { humanReadableDate } from '../../util/notifications';
import { createResourceLocatorString } from '../../util/routes';
import routeConfiguration from '../../routeConfiguration';
import { AVAILABILITY } from '../MyAvailabilityWizard/MyAvailabilityWizard';
import { withRouter } from 'react-router-dom';

class RequestToMeetProviderNotification extends React.Component {
  constructor(props) {
    super(props);
    this.handleAddMeetingTimesClick = this.handleAddMeetingTimesClick.bind(this);
  }

  handleAddMeetingTimesClick() {
    const { history } = this.props;
    history.push(
      createResourceLocatorString('MyAvailabilityPage', routeConfiguration(), { tab: AVAILABILITY })
    );
  }

  render() {
    const { currentUser, getMessage, notification, otherUser, timezone } = this.props;
    const providerName = currentUser.attributes.profile.publicData.firstName;
    const customerName = otherUser.attributes.profile.publicData.firstName;

    const {
      keywords,
      preferredMeetingTimes,
      whyYouWantToMeet,
    } = notification.attributes.protectedData;

    return (
      <div className={css.root}>
        <h1 className={css.header}>{getMessage('title', { customerName, providerName })}</h1>
        <p className={css.introduction}>{getMessage('introduction', { customerName })}</p>
        <div className={css.messages}>
          <div className={css.theirMessage}>
            <Avatar className={css.avatar} user={otherUser} />
            <div className={css.message}>
              <div className={css.text}>
                {whyYouWantToMeet}
                {keywords ? (
                  <div className={css.keywords}>
                    {keywords.map((keyword) => {
                      return (
                        <span className={css.keyword} key={`keyword-${keyword}`}>
                          {keyword}
                        </span>
                      );
                    })}
                  </div>
                ) : null}
                {preferredMeetingTimes ? (
                  <>
                    <span className={css.subHeader}>{getMessage('preferredMeetingTimes')}</span>
                    {preferredMeetingTimes}
                  </>
                ) : null}
              </div>
              <div className={classNames(css.theirMessageTimestamp, css.timestamp)}>
                {humanReadableDate(notification.attributes.createdAt, timezone)}
              </div>
            </div>
          </div>
        </div>
        <Button
          className={css.buttonAddMeetingTimes}
          onClick={this.handleAddMeetingTimesClick}
          type={'button'}
        >
          {getMessage('addMeetingTimes')}
        </Button>
      </div>
    );
  }
}

RequestToMeetProviderNotification.propTypes = {
  currentUser: propTypes.currentUser,
  history: shape({
    push: func.isRequired,
  }).isRequired,
  notification: propTypes.notification,
  otherUser: propTypes.user,
  timezone: string.isRequired,
};

export default withMessages(
  withRouter(RequestToMeetProviderNotification),
  'Notification.RequestToMeetProviderNotification'
);
