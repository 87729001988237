import React from 'react';
import { node, number, object, string, shape } from 'prop-types';
import { compose } from 'redux';
import { FormattedMessage } from '../../util/reactIntl';
import { withViewport } from '../../util/contextHelpers';
import { LayoutWrapperSideNav } from '../../components';
import { ensureCurrentUser } from '../../util/data';
import {
  ONBOARDING_PROGRESS,
  PERSONAL_INFO,
  SUPPORTED_NONPROFITS,
} from '../OnboardingWizard/constants';

const MAX_HORIZONTAL_NAV_SCREEN_WIDTH = 1023;

const scrollToTab = (activeTab) => {
  const el = document.querySelector(`#${activeTab}Tab`);

  if (el) {
    el.scrollIntoView({
      block: 'end',
      inline: 'end',
      behavior: 'smooth',
    });
  }
};

const LayoutWrapperOnboardingSideNavComponent = (props) => {
  const { activeTab, viewport, currentUser } = props;

  let hasScrolledToTab = false;

  const user = ensureCurrentUser(currentUser);
  const { onboardingProgress } = user.attributes.profile.publicData;
  const { width } = viewport;
  const hasViewport = width > 0;
  const hasHorizontalTabLayout = hasViewport && width <= MAX_HORIZONTAL_NAV_SCREEN_WIDTH;
  const hasVerticalTabLayout = hasViewport && width > MAX_HORIZONTAL_NAV_SCREEN_WIDTH;
  const hasFontsLoaded = hasViewport && document.documentElement.classList.contains('fontsLoaded');

  // Check if scrollToTab call is needed (tab is not visible on mobile)
  if (hasVerticalTabLayout) {
    hasScrolledToTab = true;
  } else if (hasHorizontalTabLayout && !hasScrolledToTab && hasFontsLoaded) {
    scrollToTab(activeTab);
    hasScrolledToTab = true;
  }

  const pitcherTabs = [
    {
      text: <FormattedMessage id="LayoutWrapperOnboardingSideNav.personalInfo" />,
      selected: activeTab === PERSONAL_INFO,
      completed: onboardingProgress >= ONBOARDING_PROGRESS[PERSONAL_INFO],
      id: PERSONAL_INFO,
      linkProps: {
        name: 'OnboardingPage',
        params: {
          tab: PERSONAL_INFO,
        },
      },
    },
    {
      text: <FormattedMessage id="LayoutWrapperOnboardingSideNav.supportedNonprofits" />,
      selected: activeTab === SUPPORTED_NONPROFITS,
      completed: onboardingProgress >= ONBOARDING_PROGRESS[SUPPORTED_NONPROFITS],
      disabled:
        !onboardingProgress ||
        (onboardingProgress < ONBOARDING_PROGRESS[SUPPORTED_NONPROFITS] &&
          activeTab !== SUPPORTED_NONPROFITS),
      id: SUPPORTED_NONPROFITS,
      linkProps: {
        name: 'OnboardingPage',
        params: {
          tab: SUPPORTED_NONPROFITS,
        },
      },
    },
  ];

  return <LayoutWrapperSideNav tabs={pitcherTabs} />;
};

LayoutWrapperOnboardingSideNavComponent.defaultProps = {
  className: null,
  rootClassName: null,
  children: null,
  activeTab: null,
};

LayoutWrapperOnboardingSideNavComponent.propTypes = {
  activeTab: string.isRequired,
  currentUser: object,
  children: node,
  className: string,
  rootClassName: string,

  // from withViewport
  viewport: shape({
    width: number.isRequired,
    height: number.isRequired,
  }).isRequired,
};

const LayoutWrapperOnboardingSideNav = compose(withViewport)(
  LayoutWrapperOnboardingSideNavComponent
);

export default LayoutWrapperOnboardingSideNav;
