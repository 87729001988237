import React, { Component } from 'react';
import { arrayOf, bool, func, object, shape, string } from 'prop-types';
import { EventLandingPageHero } from '../EventLandingPageHero/EventLandingPageHero';
import { propTypes } from '../../../../util/types';
import css from './EventLandingPageContainer.css';
import EventLandingPagePeople from '../EventLandingPagePeople/EventLandingPagePeople';
import EventLandingPageTextBlock, {
  EventLandingPageTextBlockWithBackground,
} from '../EventLandingPageTextBlock/EventLandingPageTextBlock';
import EventLandingPageColumns from '../EventLandingPageColumns/EventLandingPageColumns';
import EventLandingPageNonprofit from '../EventLandingPageNonprofit/EventLandingPageNonprofit';
import { intlShape } from '../../../../util/reactIntl';
import { ensureListing } from '../../../../util/data';
import { types as sdkTypes } from '../../../../util/sdkLoader';
import { isMomentAfter } from '../../../../util/dates';
import EventLandingPageImage from '../EventLandingPageImage/EventLandingPageImage';

const { UUID } = sdkTypes;

export const EVENT_CONTENT_IMAGE = 'image';
export const EVENT_CONTENT_HERO = 'hero';
export const EVENT_CONTENT_PEOPLE = 'people';
export const EVENT_CONTENT_TEXT = 'text';
export const EVENT_CONTENT_TEXT_WITH_BACKGROUND = 'text-with-background';
export const EVENT_CONTENT_COLUMNS = 'columns';
export const EVENT_CONTENT_NONPROFIT = 'nonprofit';

class EventLandingPageContainer extends Component {
  getListingsFromIdList(idList) {
    const { getListing } = this.props;
    return idList.map((listingId) => ensureListing(getListing(new UUID(listingId))));
  }

  getImageUrl(image) {
    if (!image) return '';

    return `/static/events/${this.props.event.key}/${image}`;
  }

  getImageComponent(contentBlock) {
    const imageUrl = this.getImageUrl(contentBlock.url);

    return (
      <EventLandingPageImage
        url={imageUrl}
        height={contentBlock.height}
        width={contentBlock.width}
      />
    );
  }

  getHeroComponent(contentBlock) {
    const backgroundImageUrls = Object.keys(contentBlock.backgroundImageUrlSet).reduce(
      (obj, key) => ({
        ...obj,
        [key]: this.getImageUrl(contentBlock.backgroundImageUrlSet[key]),
      }),
      {}
    );

    return (
      <EventLandingPageHero
        backgroundImageUrlSet={backgroundImageUrls}
        button={contentBlock.button}
        eventKey={this.props.event.key}
        isAuthenticated={this.props.isAuthenticated}
        logo={this.getImageUrl(contentBlock.logo)}
        subTitle={contentBlock.subTitle}
        title={contentBlock.title}
        body={contentBlock.body}
        trackEvent={this.props.trackEvent}
      />
    );
  }

  getPeopleComponent(contentBlock) {
    const listings = contentBlock.listings
      ? this.getListingsFromIdList(contentBlock.listings)
      : this.props.getListingsByEventRole(contentBlock.userRole);
    const hasLoadedListings =
      listings.filter((listing) => listing && listing.id && listing.id.uuid).length > 0;

    return hasLoadedListings ? (
      <div className={css.section}>
        <EventLandingPagePeople
          browseAll={contentBlock.browseAll}
          browseAllPageName={contentBlock.browseAllPageName}
          browseAllSearchParams={contentBlock.browseAllSearchParams}
          listings={listings}
          npoListingNames={this.props.npoListingNames}
          title={contentBlock.title}
          userRole={contentBlock.userRole}
          eventKey={this.props.event.key}
          backgroundImage={this.getImageUrl(contentBlock.cardBackground)}
          trackEvent={this.props.trackEvent}
        />
      </div>
    ) : null;
  }

  getTextComponent(contentBlock) {
    return (
      <div>
        <EventLandingPageTextBlock
          buttons={contentBlock.buttons}
          eventKey={this.props.event.key}
          isAuthenticated={this.props.isAuthenticated}
          links={contentBlock.links}
          text={contentBlock.text}
          title={contentBlock.title}
          trackEvent={this.props.trackEvent}
        />
      </div>
    );
  }

  getTextWithBackgroundComponent(contentBlock) {
    return (
      <div>
        <EventLandingPageTextBlockWithBackground
          background={this.getImageUrl(contentBlock.background)}
          buttons={contentBlock.buttons}
          eventKey={this.props.event.key}
          isAuthenticated={this.props.isAuthenticated}
          links={contentBlock.links}
          text={contentBlock.text}
          title={contentBlock.title}
          trackEvent={this.props.trackEvent}
        />
      </div>
    );
  }

  getColumnsComponent(contentBlock) {
    const columns = contentBlock.columns.map(({ image, title, text }) => ({
      title,
      text,
      image: this.getImageUrl(image),
    }));
    return (
      <div className={css.section}>
        <EventLandingPageColumns columns={columns} title={contentBlock.title} />
      </div>
    );
  }

  getNonprofitComponent(contentBlock) {
    return (
      this.props.supportedNonprofits && (
        <div>
          <EventLandingPageNonprofit
            background={this.getImageUrl(contentBlock.background)}
            buttons={contentBlock.buttons}
            getListing={this.props.getListing}
            intl={this.props.intl}
            isAuthenticated={this.props.isAuthenticated}
            history={this.props.history}
            listingIds={this.props.supportedNonprofits}
            title={contentBlock.title}
            text={contentBlock.text}
            smallTitle={contentBlock.smallTitle}
            trackEvent={this.props.trackEvent}
          />
        </div>
      )
    );
  }

  get eventHasEnded() {
    return (
      this.props.event.open &&
      this.props.event.open.to &&
      isMomentAfter(new Date(), this.props.event.open.to, this.props.event.timezone)
    );
  }

  get components() {
    if (!this.props.event) {
      return null;
    }

    return this.props.event[this.eventHasEnded ? 'postEventPage' : 'landingPage'].contentBlocks.map(
      (block, index) => {
        switch (block.type) {
          case EVENT_CONTENT_IMAGE:
            return <div key={index}>{this.getImageComponent(block)}</div>;
          case EVENT_CONTENT_HERO:
            return <div key={index}>{this.getHeroComponent(block)}</div>;
          case EVENT_CONTENT_PEOPLE:
            return <div key={index}>{this.getPeopleComponent(block)}</div>;
          case EVENT_CONTENT_TEXT:
            return <div key={index}>{this.getTextComponent(block)}</div>;
          case EVENT_CONTENT_TEXT_WITH_BACKGROUND:
            return <div key={index}>{this.getTextWithBackgroundComponent(block)}</div>;
          case EVENT_CONTENT_COLUMNS:
            return <div key={index}>{this.getColumnsComponent(block)}</div>;
          case EVENT_CONTENT_NONPROFIT:
            return <div key={index}>{this.getNonprofitComponent(block)}</div>;
          default:
            return null;
        }
      }
    );
  }

  render() {
    return (
      <div className={css.contentContainer}>
        {this.components}

        <div className={css.logo}>
          <span>In partnership with</span>{' '}
          <img
            src={this.getImageUrl(this.props.event.logo)}
            alt={this.props.event.title}
            className={css.logoImage}
          />
        </div>
      </div>
    );
  }
}

EventLandingPageContainer.defaultProps = {
  supportedNonprofits: [],
};

EventLandingPageContainer.propTypes = {
  event: propTypes.event.isRequired,
  getListingsByEventRole: func.isRequired,
  getListing: func.isRequired,
  history: shape({
    push: func.isRequired,
  }).isRequired,
  intl: intlShape.isRequired,
  isAuthenticated: bool.isRequired,
  listings: arrayOf(propTypes.listing).isRequired,
  npoListingNames: object.isRequired,
  supportedNonprofits: arrayOf(string),
  timezone: string.isRequired,
  trackEvent: func.isRequired,
};

export default EventLandingPageContainer;
