import { ensureCurrentUser } from './data';
import { createResourceLocatorString } from './routes';

// Create return URL for the Stripe onboarding form
export const createReturnURL = (returnURLType, rootURL, routes, pageName, params) => {
  const path = createResourceLocatorString(
    // @todo Make dynamic
    pageName,
    routes,
    params,
    {}
  );
  const root = rootURL.replace(/\/$/, '');
  return `${root}${path}`;
};

// Get attribute: stripeAccountData
export const getStripeAccountData = (stripeAccount) =>
  stripeAccount.attributes.stripeAccountData || null;

// Get last 4 digits of bank account returned in Stripe account
export const getBankAccountLast4Digits = (stripeAccountData) =>
  stripeAccountData && stripeAccountData.external_accounts.data.length > 0
    ? stripeAccountData.external_accounts.data[0].last4
    : null;

// Check if there's requirements on selected type: 'past_due', 'currently_due' etc.
export const hasRequirements = (stripeAccountData, requirementType) =>
  stripeAccountData != null &&
  stripeAccountData.requirements &&
  Array.isArray(stripeAccountData.requirements[requirementType]) &&
  stripeAccountData.requirements[requirementType].length > 0;

// Redirect user to Stripe's hosted Connect account onboarding form
export const handleGetStripeConnectAccountLinkFn = (getLinkFn, commonParams) => (type) => () => {
  getLinkFn({ type, ...commonParams })
    .then((url) => {
      window.location.href = url;
    })
    .catch((err) => console.error(err));
};

// Gets the client secret from the setup intent
export const getClientSecret = (setupIntent) => {
  return setupIntent && setupIntent.attributes ? setupIntent.attributes.clientSecret : null;
};

// Gets the payment params
export const getPaymentParams = (currentUser, formValues) => {
  const { name, addressLine1, addressLine2, postal, state, city, country } = formValues;
  const addressMaybe =
    addressLine1 && postal
      ? {
          address: {
            city: city,
            country: country,
            line1: addressLine1,
            line2: addressLine2,
            postal_code: postal,
            state: state,
          },
        }
      : {};
  const billingDetails = {
    name,
    email: ensureCurrentUser(currentUser).attributes.email,
    ...addressMaybe,
  };

  return {
    payment_method_data: {
      billing_details: billingDetails,
    },
  };
};
