import React from 'react';
import * as css from '../NonprofitPreferencesPanel.css';
import { useMessages } from '../../../util/localization';
import LiteButton from '../../LiteButton/LiteButton';
import { ICON_PLUS, STYLE_LIGHT } from '../../LiteButton/constants';
import FieldTextInput from '../../FieldTextInput/FieldTextInput';
import RemoveFieldButton from '../RemoveFieldButton';

const CausesSection = (props) => {
  const { values, form } = props;
  const getMessage = useMessages('NonprofitPreferencesForm');

  return (
    <section className={css.section}>
      <h3 className={css.sectionTitle}>
        {getMessage('section.causes.title')}
        <span className={css.optional}>{getMessage('optional')}</span>
      </h3>
      <p className={css.small}>{getMessage('section.causes.description')}</p>
      {values.causes.map((cause, index) => (
        <div key={index} className={css.inputRow}>
          {index > 0 && <br />}
          <FieldTextInput
            className={css.field}
            id={`causes.${index}`}
            label={getMessage('field.causes.label')}
            name={`causes.${index}`}
            placeholder={getMessage('field.causes.placeholder')}
            type={'text'}
          />
          <RemoveFieldButton
            onClick={() =>
              form.change(
                'causes',
                values.causes.filter((_cause) => _cause !== cause)
              )
            }
          />
        </div>
      ))}
      <br />
      <LiteButton
        icon={ICON_PLUS}
        onClick={() => form.change('causes', [...values.causes, ''])}
        style={STYLE_LIGHT}
      >
        {getMessage('liteButton.new')}
      </LiteButton>
    </section>
  );
};

export default CausesSection;
