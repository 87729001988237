import React from 'react';
import PropTypes, { bool, func, shape } from 'prop-types';
import classNames from 'classnames';
import { propTypes } from '../../util/types';
import { NamedLink, NonprofitFeatureCard, PaginationLinks } from '../../components';
import css from './NonprofitSearchResultsPanel.css';
import { withMessages } from '../../util/localization';

const NonprofitSearchResultsPanel = (props) => {
  const {
    className,
    getMessage,
    history,
    listings,
    listingsAreLoaded,
    pageName,
    pagination,
    rootClassName,
    search,
  } = props;
  const classes = classNames(rootClassName || css.root, className);

  const paginationLinks =
    pagination && pagination.totalPages > 1 ? (
      <PaginationLinks
        className={css.pagination}
        pageName={pageName}
        pageSearchParams={search}
        pagination={pagination}
      />
    ) : null;

  return listingsAreLoaded && listings.length === 0 ? (
    <div className={css.noResults}>
      <div className={css.placeholder}>
        <svg xmlns="http://www.w3.org/2000/svg" width="130" height="112" viewBox="0 0 130 112">
          <g fill="none" fillRule="evenodd">
            <path
              fill="#F2F2F2"
              d="M1.083 109h127.834c.597 0 1.083.672 1.083 1.5s-.486 1.5-1.083 1.5H1.083C.485 112 0 111.328 0 110.5s.484-1.5 1.083-1.5"
            />
            <path
              fill="#FAC51D"
              d="M50.23 33.016c8.158-8.135 21.384-8.135 29.54 0 8.157 8.135 8.157 21.324 0 29.46-8.157 8.133-21.382 8.134-29.54 0-8.156-8.136-8.156-21.325 0-29.46"
            />
            <path
              fill="#FCE283"
              d="M13.306 10.355c13.845-13.806 36.373-13.806 50.217 0l-9.846 9.82c-8.416-8.393-22.11-8.393-30.524 0-8.416 8.392-8.415 22.049 0 30.442l-9.847 9.819c-13.845-13.807-13.844-36.275 0-50.08M66.477 10.355c13.845-13.806 36.372-13.806 50.217 0 13.846 13.808 13.846 36.273 0 50.08l-9.846-9.82c8.415-8.392 8.415-22.048 0-30.44-8.416-8.393-22.11-8.393-30.524 0l-9.847-9.82zM99.813 57.644L109.659 67.463 64.999 112 20.341 67.463 30.188 57.644 64.999 92.362zM116.347 94.239L114.47 83.819 107.26 92.421 109.753 96.161 119.286 109 126.719 109z"
            />
            <path
              fill="#FAC51D"
              d="M125.28 112s1.512-1.236.438-3h-6.432l-3 3h8.994zM115.73 90.82l-1.26-7-7.21 8.601 2.492 3.74.005.006c2.203-1.917 4.2-3.66 4.554-3.978.46-.412.948-.856 1.42-1.369"
            />
            <path
              fill="#FCE283"
              d="M103.716 83.82l.542 2.717-9.87 9.906V109h6.413v-9.998s10.196-8.864 10.934-9.525c1.538-1.379 2.735-2.392 2.735-5.658h-10.754z"
            />
            <path fill="#FAC51D" d="M91.389 112L100.801 112 100.801 109 94.389 109z" />
            <path
              fill="#FAF4D5"
              d="M106.757 65.017v-5.313c0-2.936-2.38-5.315-5.314-5.315-2.936 0-5.315 2.379-5.315 5.315 0 2.934 2.379 5.313 5.315 5.313l.013-.001h5.3z"
            />
            <path
              fill="#FAC51D"
              d="M101.566 54.074c-2.273-.315-4.51.663-5.033 3.692l-1.513-.286s-.07-.012-.627-.12c-.944-.181-1.153.914-1.153.914l13.516 2.495v-1.962c0-2.936-2.283-4.331-5.19-4.733"
            />
            <path
              fill="#FAC51D"
              d="M111.68 69.327c-.486-2.437-.67-4.31-2.694-4.31h-2.048v-.002l-.003.002H96.48l-9.475-9.474v6.88l7.023 7.023h6.815l2.872 14.373h10.754c-1.352-7.383-2.076-10.923-2.79-14.492"
            />
            <path
              fill="#FCE283"
              d="M85.049 53.586c-.396 1.361-.937 2.688-1.62 3.96l3.577 3.636c.793-.595 2.722-2.45 2.945-2.694l-4.902-4.902z"
            />
          </g>
        </svg>
      </div>
      <span className={css.title}>{getMessage('noResults.title')}</span>
      <p className={css.description}>
        {getMessage('noResults.description', {
          emailLink: <a href={'mailto:nonprofits@givsly.com'}>nonprofits@givsly.com</a>,
        })}
      </p>
      <NamedLink name={'NonprofitSearchPage'}>{getMessage('noResults.clearAllFilters')}</NamedLink>
    </div>
  ) : (
    <div className={classes}>
      <div className={css.listingCards}>
        {listings.map((l) => (
          <NonprofitFeatureCard
            className={css.listingCard}
            history={history}
            key={l.id.uuid}
            listing={l}
          />
        ))}
        {props.children}
      </div>
      {paginationLinks}
    </div>
  );
};

NonprofitSearchResultsPanel.defaultProps = {
  children: null,
  className: null,
  listings: [],
  listingsAreLoaded: false,
  pageName: 'NonprofitSearchPage',
  pagination: null,
  rootClassName: null,
  search: null,
};

const { array, node, object, string } = PropTypes;

NonprofitSearchResultsPanel.propTypes = {
  children: node,
  className: string,
  history: shape({
    push: func.isRequired,
  }).isRequired,
  listings: array,
  listingsAreLoaded: bool,
  pageName: string.isRequired,
  pagination: propTypes.pagination,
  rootClassName: string,
  search: object,
};

export default withMessages(NonprofitSearchResultsPanel, 'NonprofitSearchResultsPanel');
