import React, { Component } from 'react';
import { bool, func, node, object, string } from 'prop-types';
import classNames from 'classnames';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';

import { FilterForm } from '../../forms';
import css from './FilterPlain.css';

class FilterPlainComponent extends Component {
  constructor(props) {
    super(props);
    this.state = { isOpen: true };

    this.handleChange = this.handleChange.bind(this);
    this.handleClear = this.handleClear.bind(this);
    this.toggleIsOpen = this.toggleIsOpen.bind(this);
  }

  handleChange(values) {
    const { onSubmit, urlParam } = this.props;
    console.log('Handle change ', values);
    onSubmit(urlParam, values);
  }

  handleClear() {
    const { onSubmit, onClear, urlParam } = this.props;

    if (onClear) {
      onClear();
    }

    onSubmit(urlParam, null);
  }

  toggleIsOpen() {
    this.setState((prevState) => ({ isOpen: !prevState.isOpen }));
  }

  render() {
    const {
      children,
      className,
      labelClassName,
      contentPlacementOffset,
      id,
      initialValues,
      isSelected,
      keepDirtyOnReinitialize,
      label,
      plainClassName,
      rootClassName,
      showLabel,
    } = this.props;
    const classes = classNames(rootClassName || css.root, className);

    const labelClass = classNames(
      isSelected ? css.filterLabelSelected : css.filterLabel,
      labelClassName
    );

    return (
      <div className={classes}>
        {showLabel ? (
          <div className={labelClass}>
            <button type="button" className={css.labelButton} onClick={this.toggleIsOpen}>
              <span className={labelClass}>{label}</span>
            </button>
            <button type="button" className={css.clearButton} onClick={this.handleClear}>
              <FormattedMessage id={'FilterPlain.clear'} />
            </button>
          </div>
        ) : null}
        <div
          id={id}
          className={classNames(plainClassName, css.plain, { [css.isOpen]: this.state.isOpen })}
          ref={(node) => {
            this.filterContent = node;
          }}
        >
          <FilterForm
            id={`${id}.form`}
            liveEdit
            contentPlacementOffset={contentPlacementOffset}
            onChange={this.handleChange}
            initialValues={initialValues}
            keepDirtyOnReinitialize={keepDirtyOnReinitialize}
          >
            {children}
          </FilterForm>
        </div>
      </div>
    );
  }
}

FilterPlainComponent.defaultProps = {
  rootClassName: null,
  className: null,
  labelClassName: null,
  plainClassName: null,
  initialValues: null,
  keepDirtyOnReinitialize: false,
  showLabel: true,
};

FilterPlainComponent.propTypes = {
  children: node.isRequired,
  className: string,
  initialValues: object,
  id: string.isRequired,
  isSelected: bool.isRequired,
  keepDirtyOnReinitialize: bool,
  label: node,
  labelClassName: string,
  onSubmit: func.isRequired,
  plainClassName: string,
  rootClassName: string,
  showLabel: bool,
  intl: intlShape.isRequired,
};

const FilterPlain = injectIntl(FilterPlainComponent);

export default FilterPlain;
