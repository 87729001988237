import { addMarketplaceEntities } from './marketplaceData.duck';
import { storableError } from '../util/errors';

// ================ Action types ================ //

export const CHANGE_SEARCH_STRING = 'app/GivslySearch/CHANGE_SEARCH_STRING';

export const SEARCH_REQUEST = 'app/GivslySearch/SEARCH_REQUEST';
export const SEARCH_SUCCESS = 'app/GivslySearch/SEARCH_SUCCESS';
export const SEARCH_ERROR = 'app/GivslySearch/SEARCH_ERROR';

// ================ Reducer ================ //

const entityRefs = (entities) =>
  entities.map((entity) => ({
    id: entity.id,
    type: entity.type,
  }));

const initialState = {
  searchStrings: {},
  searchResults: {},
  searchesInProgress: {},
  searchErrors: {},
};

export default function reducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case CHANGE_SEARCH_STRING:
      return {
        ...state,
        searchStrings: {
          ...state.searchStrings,
          [payload.componentId]: payload.searchString,
        },
      };

    case SEARCH_REQUEST:
      return {
        ...state,
        searchesInProgress: {
          ...state.searchesInProgress,
          [payload.componentId]: true,
        },
        searchErrors: {
          ...state.searchErrors,
          [payload.componentId]: null,
        },
      };
    case SEARCH_SUCCESS:
      return {
        ...state,
        searchesInProgress: {
          ...state.searchesInProgress,
          [payload.componentId]: false,
        },
        searchResults: {
          ...state.searchResults,
          [payload.componentId]: entityRefs(payload.results),
        },
      };
    case SEARCH_ERROR:
      // eslint-disable-next-line no-console
      console.error(payload);
      return {
        ...state,
        searchesInProgress: {
          ...state.searchesInProgress,
          [payload.componentId]: false,
        },
        searchErrors: {
          ...state.searchErrors,
          [payload.componentId]: payload.error,
        },
      };

    default:
      return state;
  }
}

// ================ Selectors ================ //

// ================ Action creators ================ //

export const changeSearchString = (componentId, searchString) => ({
  type: CHANGE_SEARCH_STRING,
  payload: { componentId, searchString },
});

export const searchRequest = (componentId) => ({
  type: SEARCH_REQUEST,
  payload: { componentId },
});

export const searchSuccess = (componentId, results) => ({
  type: SEARCH_SUCCESS,
  payload: { componentId, results },
});

export const searchError = (componentId, e) => ({
  type: SEARCH_ERROR,
  error: true,
  payload: { componentId, error: e },
});

// ================ Thunks ================ //

export const onChangeSearchString = (componentId, searchString) => (dispatch) => {
  dispatch(changeSearchString(componentId, searchString));
  if (searchString.length < 1) {
    dispatch(searchSuccess(componentId, []));
  }
};

export const clearSerach = (componentId) => (dispatch) => dispatch(searchSuccess(componentId, []));

export const doSearch = (componentId, searchString) => (dispatch, getState, sdk) => {
  dispatch(searchRequest(componentId));

  const paramsNpo = {
    keywords: searchString,
    pub_isNPOListing: true,
    per_page: 100,
    'fields.listing': ['title', 'publicData'],
  };

  return sdk.listings
    .query(paramsNpo)
    .then((npoResponse) => {
      dispatch(addMarketplaceEntities(npoResponse));
      const npoData = (npoResponse.data && npoResponse.data.data) || [];

      dispatch(searchSuccess(componentId, npoData));
      return npoResponse;
    })
    .catch((e) => {
      console.log('Error?!');
      dispatch(searchError(componentId, storableError(e)));
      throw e;
    });
};
