import { LOCATION_CHANGED } from '../ducks/Routing.duck';
import { parse } from '../util/urlHelpers';

// ================ Action types ================ //

export const CHANGE_MARKET = 'app/TopBar/CHANGE_MARKET';

// ================ Reducer ================ //

const initialState = {
  currentMarket: null,
  searchString: null,
  searchResults: {},
};

export default function reducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case LOCATION_CHANGED:
      // Get the currentMarket from the URL if it's there
      const urlParams = parse(payload.location.search);
      const currentMarket = !!urlParams['pub_location']
        ? { currentMarket: urlParams['pub_location'] }
        : {};

      return {
        ...state,
        ...currentMarket,
      };

    case CHANGE_MARKET:
      return { ...state, currentMarket: payload };

    default:
      return state;
  }
}

// ================ Selectors ================ //

// ================ Action creators ================ //

export const changeMarket = (market) => ({ type: CHANGE_MARKET, payload: market });

// ================ Thunks ================ //
