import React from 'react';
import classNames from 'classnames';
import { arrayOf, func, number, shape, string } from 'prop-types';
import css from './Slots.css';
import { FormattedMessage } from 'react-intl';

class Slots extends React.Component {
  constructor(props) {
    super(props);
    this.onClickHandler = this.onClickHandler.bind(this);
  }

  onClickHandler({ target }) {
    this.props.onClickHandler(target.value, '');
  }

  render() {
    const { slots, slotLimit } = this.props;
    const placeholders = [];
    const placeholderClassNames = classNames(css.slotPlaceholder, css.slot);
    const slotButtonClassNames = classNames(css.slotButton, css.slot);

    if (slots.length < slotLimit) {
      for (let i = 0; i < slotLimit - slots.length; i++) {
        placeholders.push(
          <span className={placeholderClassNames} key={`placeholder-${i}`}>
            <FormattedMessage id="Slots.placeholder" />
          </span>
        );
      }
    }

    return (
      <div className={css.slots}>
        {slots.map((slot) => {
          return (
            <button
              className={slotButtonClassNames}
              onClick={this.onClickHandler}
              value={slot.key}
              key={`slot-${slot.key}`}
              type="button"
            >
              {slot.label}
            </button>
          );
        })}
        {placeholders}
      </div>
    );
  }
}

Slots.defaultProps = {
  slotLimit: 3,
  slots: [],
};

Slots.propTypes = {
  fieldName: string.isRequired,
  onClickHandler: func.isRequired,
  slotLimit: number,
  slots: arrayOf(
    shape({
      key: string.isRequired,
      label: string.isRequired,
    })
  ),
};

Slots.propTypes = {
  name: string.isRequired,
};

export default Slots;
