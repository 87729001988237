import { array, bool, func, number, string } from 'prop-types';
import React from 'react';
import { SECTION_GIVSLY_CREDIT_AND_PAYMENT } from '../../containers/FaqPage/constants';
import { AddCreditForm } from '../../forms';
import { withMessages } from '../../util/localization';
import { ICON_PLUS } from '../LiteButton/constants';
import LiteButton from '../LiteButton/LiteButton';
import Modal from '../Modal/Modal';
import NamedLink from '../NamedLink/NamedLink';
import css from './SavedCredit.css';
import classNames from 'classnames';
import moment from 'moment-timezone';

class SavedCredit extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      addModalIsOpen: false,
    };

    this.handleAddCredit = this.handleAddCredit.bind(this);
    this.handleClickAdd = this.handleClickAdd.bind(this);
    this.handleCloseModal = this.handleCloseModal.bind(this);
  }

  async handleAddCredit(code) {
    const { onAddCredit, onGetBalance } = this.props;

    return await onAddCredit(code).then((response) => {
      if (!response.success) {
        return {
          code: response.reason,
        };
      } else {
        onGetBalance();
        this.handleCloseModal();
      }
    });
  }

  handleClickAdd() {
    this.setState({
      addModalIsOpen: true,
    });
  }

  handleCloseModal() {
    this.setState({
      addModalIsOpen: false,
    });
  }

  render() {
    const {
      className,
      creditCodes,
      creditTotal,
      getBalanceInProgress,
      getMessage,
      onManageDisableScrolling,
    } = this.props;
    const classes = classNames(css.root, className);
    return (
      <div className={classes}>
        <h2 className={css.header}>{getMessage('title')}</h2>
        <p className={css.description}>
          {getMessage('description', {
            learnMoreLink: (
              <NamedLink
                key={'descriptionLink'}
                name={'FaqPage'}
                to={{ hash: SECTION_GIVSLY_CREDIT_AND_PAYMENT }}
              >
                {getMessage('description.linkTitle')}
              </NamedLink>
            ),
          })}
        </p>
        <table className={css.creditCodes}>
          <tbody>
            {getBalanceInProgress ? (
              <tr>
                <td className={css.iconCreditTotal} />
                <td className={css.label}>
                  <span className={css.skeletonLabel} />
                </td>
                <td className={css.expiration}>
                  <span className={css.skeletonLabel} />
                </td>
                <td className={css.balance}>
                  <span className={css.skeletonLabel} />
                </td>
              </tr>
            ) : (
              creditCodes.map((creditCode, index) => {
                const { code, balance, expiresOn } = creditCode;
                return balance > 0 ? (
                  <tr className={css.creditCode} key={`creditCode-${index}`}>
                    <td className={css.iconCreditSingle} />
                    <td className={css.label}>{code}</td>
                    <td className={css.expiration}>
                      {expiresOn ? (
                        <span>
                          <span className={css.mobileLabel}>
                            {moment.tz(expiresOn, 'utc').format('M/D/YY')}
                          </span>
                          <span className={css.desktopLabel}>
                            {getMessage('expiresOn', {
                              expirationDate: moment.tz(expiresOn, 'utc').format('M/D/Y'),
                            })}
                          </span>
                        </span>
                      ) : (
                        <em>{getMessage('never')}</em>
                      )}
                    </td>
                    <td className={css.balance}>$ {(parseFloat(balance) / 100).toFixed(2)}</td>
                  </tr>
                ) : null;
              })
            )}
          </tbody>
          <tfoot>
            {getBalanceInProgress ? (
              <tr className={css.creditTotal}>
                <td className={css.iconCreditTotal} />
                <td className={css.label} colSpan={2}>
                  <span className={css.skeletonLabel} />
                </td>
                <td className={css.balance}>
                  <span className={css.skeletonLabel} />
                </td>
              </tr>
            ) : (
              <tr className={css.creditTotal}>
                <td className={css.iconCreditTotal} />
                <td className={css.label} colSpan={2}>
                  {getMessage('total')}
                </td>
                <td className={css.balance}>$ {(parseFloat(creditTotal) / 100).toFixed(2)}</td>
              </tr>
            )}
          </tfoot>
        </table>
        <div className={css.controls}>
          <LiteButton icon={ICON_PLUS} onClick={this.handleClickAdd}>
            {getMessage('addCredit')}
          </LiteButton>
          {/*<LiteButton icon={ICON_CART} style={STYLE_LIGHT}>*/}
          {/*  {getMessage('purchaseCredit')}*/}
          {/*</LiteButton>*/}
        </div>
        <Modal
          hasCloseButton={false}
          id={'addCreditModal'}
          onManageDisableScrolling={onManageDisableScrolling}
          isOpen={this.state.addModalIsOpen}
          onClose={this.handleCloseModal}
        >
          <AddCreditForm onSubmit={this.handleAddCredit} onCancel={this.handleCloseModal} />
        </Modal>
      </div>
    );
  }
}

SavedCredit.propTypes = {
  creditCodes: array,
  creditTotal: number,
  getBalanceInProgress: bool.isRequired,
  getMessage: func.isRequired,
  onAddCredit: func.isRequired,
  onGetBalance: func.isRequired,
  onManageDisableScrolling: func.isRequired,
  timezone: string.isRequired,
};

SavedCredit.defaultProps = {
  creditCodes: [],
  creditTotal: 0,
  className: null,
  getBalanceInProgress: false,
};

export default withMessages(SavedCredit, 'SavedCredit');
