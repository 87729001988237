import React from 'react';
import { array, func, shape } from 'prop-types';
import css from './FeaturedLocations.css';
import { Button } from '../index';
import { createResourceLocatorString } from '../../util/routes';
import routeConfiguration from '../../routeConfiguration';

class FeaturedLocations extends React.Component {
  constructor(props) {
    super(props);
    this.redirectToSearch = this.redirectToSearch.bind(this);
  }

  redirectToSearch(key = null) {
    const { history } = this.props;
    history.push(
      createResourceLocatorString(
        'NonprofitSearchPage',
        routeConfiguration(),
        {},
        key ? { pub_organizationLocation: key } : {}
      )
    );
  }

  render() {
    const { locations } = this.props;
    return (
      <div className={css.root}>
        <div className={css.header}>
          <h2 className={css.headerText}>Find local nonprofits</h2>
          <Button className={css.headerBrowse} onClick={() => this.redirectToSearch()}>
            Browse all →
          </Button>
        </div>
        <ul className={css.locations}>
          {locations.map((location) => {
            return (
              <li
                className={css.location}
                key={location.key}
                onClick={() => this.redirectToSearch(location.key)}
              >
                <img
                  alt={`${location.title}, ${location.subtitle}`}
                  className={css.image}
                  src={location.image}
                />
                <span className={css.label}>
                  <span className={css.title}>{location.title}</span>
                  <span className={css.subTitle}>{location.subTitle}</span>
                </span>
              </li>
            );
          })}
        </ul>
        <Button className={css.footerBrowse} onClick={() => this.redirectToSearch()}>
          Browse all
        </Button>
      </div>
    );
  }
}

FeaturedLocations.propTypes = {
  history: shape({
    push: func.isRequired,
  }).isRequired,
  locations: array,
};

FeaturedLocations.defaultProps = {
  locations: [],
};

export default FeaturedLocations;
