/**
 * DayPicker wraps DayPickerSingleDateController from React-dates and gives a list of all
 * default props we use.
 *
 * N.B. *isOutsideRange* in defaultProps is defining what dates are available to booking.
 */
import React, { Component } from 'react';
import { bool, func, instanceOf, string, object, array } from 'prop-types';
import {
  DayPickerSingleDateController,
  isInclusivelyAfterDay,
  isInclusivelyBeforeDay,
} from 'react-dates';

// Import moment from moment-timezone. 10-year range only.
// The full data included in moment-timezone dependency is mostly irrelevant
// and slows down the first paint.
import moment from 'moment-timezone/builds/moment-timezone-with-data-10-year-range.min';

import classNames from 'classnames';
import config from '../../config';

import { intlShape, injectIntl } from '../../util/reactIntl';

import NextMonthIcon from './NextMonthIcon';
import PreviousMonthIcon from './PreviousMonthIcon';
import css from './DayPicker.css';
import {
  dateIsAfter,
  getMonthStartInTimeZone,
  isInRange,
  monthIdStringInTimeZone,
  nextMonthFn,
  prevMonthFn,
  resetToStartOfDay,
} from '../../util/dates';

export const HORIZONTAL_ORIENTATION = 'horizontal';
const MAX_TIME_SLOTS_RANGE = 90;
const TODAY = new Date();
const DATE_COMPARISON_FORMAT = 'YYYY-MM-DD';

const endOfRange = (date, timezone) => {
  return resetToStartOfDay(date, timezone, MAX_TIME_SLOTS_RANGE - 1);
};

// Possible configuration options of React-dates
const defaultProps = {
  initialDate: null,
  // date: null,
  // calendar presentation and interaction related props
  renderMonthText: null,
  orientation: HORIZONTAL_ORIENTATION,
  withPortal: false,
  firstDayOfWeek: config.i18n.firstDayOfWeek,
  numberOfMonths: 1,
  keepOpenOnDateSelect: false,
  renderCalendarInfo: null,
  hideKeyboardShortcutsPanel: true,
  daySize: 38,
  isRTL: false,

  // navigation related props
  navPrev: <PreviousMonthIcon />,
  navNext: <NextMonthIcon />,
  onPrevMonthClick() {},
  onNextMonthClick() {},
  renderNavPrevButton: null,
  renderNavNextButton: null,
  onClose() {},
  transitionDuration: 200, // milliseconds between next month changes etc.

  // day presentation and interaction related props
  renderCalendarDay: undefined, // If undefined, renders react-dates/lib/components/CalendarDay
  // day presentation and interaction related props
  renderDayContents: (day) => {
    return <span className="renderedDay">{day.format('D')}</span>;
  },
  enableOutsideDays: true,
  isDayBlocked: () => false,

  // outside range -><- today ... today+available days -1 -><- outside range
  isOutsideRange: (day) => {
    const endOfRange = config.dayCountAvailableForBooking - 1;
    return (
      !isInclusivelyAfterDay(day, moment()) ||
      !isInclusivelyBeforeDay(day, moment().add(endOfRange, 'days'))
    );
  },

  isDayHighlighted: () => {},

  // Internationalization props
  // Multilocale support can be achieved with displayFormat like moment.localeData.longDateFormat('L')
  // https://momentjs.com/
  // displayFormat: 'ddd, MMM D',
  monthFormat: 'MMMM YYYY',
  weekDayFormat: 'dd',
  // phrases: {
  //   closeDatePicker: null, // Handled inside component
  //   clearDate: null, // Handled inside component
  // },
};

const Next = (props) => {
  const { currentMonth, timezone } = props;
  const nextMonthDate = nextMonthFn(currentMonth, timezone);

  return dateIsAfter(nextMonthDate, endOfRange(TODAY, timezone)) ? null : <NextMonthIcon />;
};

const Prev = (props) => {
  const { currentMonth, timezone } = props;
  const prevMonthDate = prevMonthFn(currentMonth, timezone);
  const currentMonthDate = getMonthStartInTimeZone(TODAY, timezone);

  return dateIsAfter(prevMonthDate, currentMonthDate) ? <PreviousMonthIcon /> : null;
};

class DayPickerComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentMonth: getMonthStartInTimeZone(TODAY, props.timezone),
    };

    this.onMonthClick = this.onMonthClick.bind(this);
  }

  shouldComponentUpdate(nextProps, nextState, nextContext) {
    return (
      Object.keys(nextProps.monthlyTimeSlots).join('') !==
        Object.keys(this.props.monthlyTimeSlots).join('') ||
      !this.props.selectedDate ||
      !nextProps.selectedDate ||
      nextProps.selectedDate.format(DATE_COMPARISON_FORMAT) !==
        this.props.selectedDate.format(DATE_COMPARISON_FORMAT) ||
      nextState.currentMonth !== this.state.currentMonth
    );
  }

  fetchMonthData(date) {
    const { listingId, timezone, onFetchTimeSlots } = this.props;
    const endOfRangeDate = endOfRange(TODAY, timezone);

    // Don't fetch timeSlots for past months or too far in the future
    if (isInRange(date, TODAY, endOfRangeDate)) {
      // Use "today", if the first day of given month is in the past
      const start = dateIsAfter(TODAY, date) ? TODAY : date;

      // Use endOfRangeDate, if the first day of the next month is too far in the future
      const nextMonthDate = nextMonthFn(date, timezone);
      const end = dateIsAfter(nextMonthDate, endOfRangeDate)
        ? resetToStartOfDay(endOfRangeDate, timezone, 0)
        : nextMonthDate;

      // Fetch time slots for given time range
      onFetchTimeSlots(listingId, start, end, timezone);
    }
  }

  onMonthClick(monthFn) {
    const { onMonthChanged, timezone } = this.props;

    this.setState(
      (prevState) => ({ currentMonth: monthFn(prevState.currentMonth, timezone) }),
      () => {
        // Callback function after month has been updated.
        // react-dates component has next and previous months ready (but invisible).
        // we try to populate those invisible months before user advances there.
        this.fetchMonthData(monthFn(this.state.currentMonth, timezone));

        // If previous fetch for month data failed, try again.
        const monthId = monthIdStringInTimeZone(this.state.currentMonth, timezone);
        const currentMonthData = this.props.monthlyTimeSlots[monthId];
        if (currentMonthData && currentMonthData.fetchTimeSlotsError) {
          this.fetchMonthData(this.state.currentMonth, timezone);
        }

        // Call onMonthChanged function if it has been passed in among props.
        if (onMonthChanged) {
          onMonthChanged(monthId);
        }
      }
    );
  }

  render() {
    /* eslint-disable no-unused-vars */
    const {
      className,
      listingId,
      initialDate,
      intl,
      useMobileMargins,
      children,
      render,
      onFetchTimeSlots,
      monthlyTimeSlots,
      reservableSlots,
      methodPriceChoices,
      onDateChange,
      onMonthChanged,
      timezone,
      selectedDate,
      renderNavPrevButton,
      renderNavNextButton,
      ...dayPickerProps
    } = this.props;
    /* eslint-enable no-unused-vars */

    // const initialMoment = initialDate ? moment(initialDate) : null;

    const classes = classNames(className || css.inputRoot, {
      [css.withMobileMargins]: useMobileMargins,
    });

    return (
      <div className={classes}>
        <DayPickerSingleDateController
          {...dayPickerProps}
          date={selectedDate}
          focused={true}
          initialVisibleMonth={() => selectedDate}
          onDateChange={onDateChange}
          onPrevMonthClick={() => this.onMonthClick(prevMonthFn)}
          onNextMonthClick={() => this.onMonthClick(nextMonthFn)}
          navNext={<Next currentMonth={this.state.currentMonth} timezone={timezone} />}
          navPrev={<Prev currentMonth={this.state.currentMonth} timezone={timezone} />}
          renderNavPrevButton={
            renderNavPrevButton
              ? (buttonProps) =>
                  renderNavPrevButton({
                    currentMonth: this.state.currentMonth,
                    ...buttonProps,
                  })
              : null
          }
          renderNavNextButton={
            renderNavNextButton
              ? (buttonProps) =>
                  renderNavNextButton({
                    currentMonth: this.state.currentMonth,
                    ...buttonProps,
                  })
              : null
          }
        />
      </div>
    );
  }
}

DayPickerComponent.defaultProps = {
  className: null,
  initialDate: null,
  useMobileMargins: false,
  ...defaultProps,
  monthlyTimeSlots: {},
  reservableSlots: [],
  methodPriceChoices: {},
};

DayPickerComponent.propTypes = {
  className: string,
  id: string,
  intl: intlShape.isRequired,
  initialDate: instanceOf(Date),
  useMobileMargins: bool,
  monthlyTimeSlots: object,
  onFetchTimeSlots: func.isRequired,
  reservableSlots: array,
  methodPriceChoices: object,
  timezone: string.isRequired,
};

export default injectIntl(DayPickerComponent);
