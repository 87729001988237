import React, { Component } from 'react';
import { FormattedMessage, intlShape } from '../../util/reactIntl';
import { Form as FinalForm } from 'react-final-form';
import isEqual from 'lodash/isEqual';
import classNames from 'classnames';
import { ensureAvailabilitySlot, ensureCurrentUser, ensureOwnListing } from '../../util/data';
import { Button, Form, Modal, PrimaryButton } from '../../components';
import { array, arrayOf, bool, func, shape, string } from 'prop-types';
import { propTypes } from '../../util/types';
import { compose } from 'redux';
import css from './AvailabilityForm.css';
import { injectIntl } from 'react-intl';
import arrayMutators from 'final-form-arrays';
import moment from 'moment';
import DayPicker from '../../components/DayPicker/DayPicker';
import {
  getMonthStartInTimeZone,
  dateIsAfter,
  prevMonthFn,
  RECURS_NEVER,
  nextMonthFn,
} from '../../util/dates';
import config from '../../config';
import { isInclusivelyAfterDay, isInclusivelyBeforeDay } from 'react-dates';
import FormSectionHeading from '../../components/FormSectionHeading/FormSectionHeading';
import { AvailabilityFormModalTimeSlotForm } from './AvailabilityFormModalTimeSlotForm';
import { AvailabilityFormModalWeeklyLimitExceeded } from './AvailabilityFormModalWeeklyLimitExceeded';
import { AvailabilityFormModalConfirmDelete } from './AvailabilityFormModalConfirmDelete';
import { AvailabilityFormDonationValues } from './AvailabilityFormDonationValues';
import { AvailabilityFormModalFirstMeetingTimeAdded } from './AvailabilityFormModalFirstMeetingTimeAdded';
import { CalendarDownloadOptionsModalConnect } from '../../components/CalendarDownloadOptions/CalendarDownloadOptionsModalConnect';
import { Tooltip } from '../../components/Tooltip/Tooltip';
import { IconAddMeeting } from '../../components';
import AvailabilityFormMonthlySlots from './AvailabilityFormMonthlySlots';
import { isSame, isSameOrAfter } from '../../util/timeSlots';
import {
  TIMESLOT_FORM_TAB_EVENT,
  TIMESLOT_FORM_TAB_REGULAR,
} from '../TimeSlotForm/TimeSlotFormContainer';
import { getEvent, isEventBookingOpen } from '../../util/events';
import { withRouter } from 'react-router-dom';
import { parse } from '../../util/urlHelpers';

const CONTROL_NEXT = 'next';
const CONTROL_PREV = 'prev';
const MAX_SLOTS_IN_CALENDAR_DAY = 4;
const MODAL_TIME_SLOT_FORM = 'time-slot-form';
const MODAL_WEEKLY_LIMIT_EXCEEDED = 'weekly-limit-exceeded';
const MODAL_CONFIRM_DELETE = 'confirm-delete';
const MODAL_FIRST_MEETING_TIME_ADDED = 'first-meeting-time-added';
const MODAL_CONNECT_CALENDAR = 'connect-calendar';

class AvailabilityFormComponent extends Component {
  constructor(props) {
    super(props);

    const { timezone } = props;
    const now = moment().tz(timezone);

    this.state = {
      editTimeSlot: false,
      displayExceededWeeklyLimitNotice: false,
      modalType: MODAL_TIME_SLOT_FORM,
      modalIsOpen: false,
      modalEventTitle: null,
      selectedId: null,
      selectedDate: now,
      selectedMonth: now.clone().startOf('month'),
      selectedBookingTime: this.props.defaultBookingTime,
      selectedDuration: this.props.defaultDuration,
      selectedMeetingType: this.props.defaultMeetingType,
      selectedTimezone: this.props.timezone,
      selectedRecurrence: null,
      selectedRecurrenceParent: null,
      activeDownloadOptions: null,
      selectedEvent: this.selectedEvent,
    };

    if (this.state.selectedEvent) {
      this.state.modalIsOpen = true;
    }

    this.submittedValues = {};

    this.setMeetingTimesFirstTime = props.reservableSlots.length === 0;
    this.meetingSlotCountsByEvent = props.reservableSlots.reduce((slotsByEvent, slot) => {
      if (!slot.event) return slotsByEvent;

      return {
        ...slotsByEvent,
        [slot.event]: (slotsByEvent[slot.event] || 0) + 1,
      };
    }, {});

    this.methodPriceChoices = this.props.initialValues.methodPriceChoices || {};
    this.allOnboardingPricesSelected =
      !!this.methodPriceChoices.inPerson && !!this.methodPriceChoices.chat;

    this.handleDateChanged = this.handleDateChanged.bind(this);
    this.handleModalClose = this.handleModalClose.bind(this);
    this.handleSubmitTimeSlot = this.handleSubmitTimeSlot.bind(this);
    this.renderDay = this.renderDay.bind(this);
    this.renderNavNext = this.renderNavNext.bind(this);
    this.renderNavPrev = this.renderNavPrev.bind(this);
    this.refreshCalendar = this.refreshCalendar.bind(this);

    // Force the default timezone setting in moment to make sure that all newly generated timezones
    // are correct. The DayPicker unfortunately doesn't allow us to override the default timezone
    // in any other way.
    moment.tz.setDefault(this.props.timezone);
  }

  static getDerivedStateFromProps(props, state) {
    const derivedState = {};

    // Display the weekly limit exceeded modal if needed
    const { modalType, modalIsOpen } = state;
    if (props.hasExceededWeeklyLimit && modalType !== MODAL_WEEKLY_LIMIT_EXCEEDED && !modalIsOpen) {
      derivedState.modalIsOpen = true;
      derivedState.modalType = MODAL_WEEKLY_LIMIT_EXCEEDED;
    }

    return Object.keys(derivedState).length > 0 ? derivedState : null;
  }

  get selectedEvent() {
    const { eventKey, location } = this.props;
    const params = parse(location.search);

    if (eventKey) {
      return getEvent(eventKey);
    } else if (params.eventKey) {
      return getEvent(params.eventKey);
    }
    return null;
  }

  handleModalClose = () => {
    this.props.trackEvent({
      category: 'Availability',
      action: 'Close',
      label: 'Modal',
    });

    this.setState({
      modalIsOpen: false,
      modalType: null,
    });
  };

  handleOpenFirstTimeTimeSlotAddedModal() {
    this.setState({ modalType: MODAL_FIRST_MEETING_TIME_ADDED });
    this.setMeetingTimesFirstTime = false;
  }

  handleOpenFirstTimeEventTimeSlotAddedModal(eventKey) {
    const event = getEvent(eventKey);
    this.setState({
      modalType: MODAL_FIRST_MEETING_TIME_ADDED,
      modalEventTitle: event.shortTitle || event.title,
    });
    this.setMeetingTimesFirstTime = false;
    this.meetingSlotCountsByEvent[eventKey] = 1;
  }

  /**
   * Handles the time slot submission. The callback is used to update the day picker calendar so it
   * properly loads the newly added time slots in the days. Days are normally only re-rendered once
   * they are hovered. Perhaps not the most elegant solution, but a workaround for the lack of this
   * option in the day picker.
   *
   * @param values
   */
  handleSubmitTimeSlot = (values) => {
    this.props.trackEvent({
      category: 'Availability',
      action: 'Submit',
      label: values.event ? 'Event Time Slot' : 'Regular Time Slot',
      value: {
        date: moment.tz(values.bookingDate.date, this.props.timezone).format('YYYY-MM-DD'),
        time: values.bookingTime,
        meetingType: values.meetingType,
      },
    });

    // Display the event modal always if the event hasn't started yet and this is an event time slot
    const { timezone } = this.props;
    const event = values.event ? getEvent(values.event) : {};
    if (
      (values.event && !this.meetingSlotCountsByEvent[values.event]) ||
      (values.event && !isEventBookingOpen(event, timezone))
    ) {
      this.handleOpenFirstTimeEventTimeSlotAddedModal(values.event);
    } else if (this.setMeetingTimesFirstTime) {
      this.handleOpenFirstTimeTimeSlotAddedModal();
    } else {
      this.setState({ modalIsOpen: false });
    }

    this.props.onSubmitTimeSlot(values).then(() => {
      this.refreshCalendar();
    });
  };

  handleConfirmDeleteTimeSlot = (slot) => {
    this.setState({
      modalIsOpen: true,
      modalType: MODAL_CONFIRM_DELETE,
      selectedDate: moment.tz(slot.start, slot.timezone),
      selectedId: slot.id,
      selectedRecurrence: slot.recurrence,
      selectedRecurrenceParent: slot.recurrenceParent,
    });
  };

  handleDeleteTimeSlot = (deleteFutureSiblings) => {
    return this.props.onDeleteTimeSlot(
      this.state.selectedId,
      this.state.selectedDate,
      this.state.selectedRecurrenceParent,
      deleteFutureSiblings
    );
  };

  handleEditTimeSlot = ({ target }) => {
    this.props.trackEvent({
      category: 'Availability',
      action: 'Edit',
      label: 'Time Slot',
    });
    const selectedListings = this.props.reservableSlots.filter((slot) => slot.id === target.value);
    if (selectedListings.length > 0) {
      const slot = ensureAvailabilitySlot(selectedListings[0]);
      const startDate = moment.tz(slot.start, slot.timezone);
      startDate.set({
        seconds: 0,
        milliseconds: 0,
      });
      const endDate = moment.tz(slot.end, slot.timezone);
      endDate.set({
        seconds: 0,
        milliseconds: 0,
      });
      this.setState({
        editTimeSlot: true,
        selectedBookingTime: startDate.format('HH:mm'),
        selectedDate: startDate,
        selectedDuration: moment.duration(endDate.diff(startDate)).asMinutes().toString(),
        selectedId: slot.id,
        selectedMeetingType: slot.methods,
        selectedRecurrence: slot.recurrence,
        selectedRecurrenceParent: slot.recurrenceParent,
        selectedTimezone: slot.timezone,
        selectedEvent: slot.event,
        modalIsOpen: true,
        modalType: MODAL_TIME_SLOT_FORM,
      });
    }
  };

  handleDateChanged = (selectedDate) => {
    this.props.trackEvent({
      category: 'Availability',
      action: 'Open',
      label: 'Modal',
    });
    const { defaultBookingTime, defaultDuration } = this.props;
    this.setState({
      editTimeSlot: false,
      selectedBookingTime: defaultBookingTime,
      selectedDate,
      selectedDuration: defaultDuration,
      selectedId: null,
      selectedRecurrence: RECURS_NEVER,
      selectedRecurrenceParent: null,
      selectedEvent: null,
      modalIsOpen: true,
      modalType: MODAL_TIME_SLOT_FORM,
    });
  };

  handleMonthChanged = (monthId) => {
    this.setState({
      selectedMonth: moment(monthId),
    });
  };

  renderDaySlot = (date) => (slot, index) => {
    const isEventSlot = !!slot.event;
    const isBooked = slot.booked;

    const slotClasses = classNames(css.daySlot, {
      [css.daySlotAvailable]: !isEventSlot && !isBooked,
      [css.daySlotBooked]: !isEventSlot && isBooked,
      [css.daySlotEventAvailable]: isEventSlot && !isBooked,
      [css.daySlotEventBooked]: isEventSlot && isBooked,
    });

    return (
      <li className={slotClasses} key={`${date.format('YYYYMMDD')}-${index}`}>
        <span className={css.daySlotTime}>
          {moment.tz(slot.start, this.props.timezone).format('hh:mm a')}
        </span>
      </li>
    );
  };

  renderDay = (date) => {
    const { timezone } = this.props;
    const today = moment.tz(new Date(), timezone);
    const isToday = today.isSame(date, 'd');
    const slots = this.reservableSlotsWithBookings.filter((slot) => {
      if (!slot || !slot.start) return false;
      const dayDate = date.format('YYYY-MM-DD');
      const slotDate = moment.tz(slot.start, timezone).format('YYYY-MM-DD');
      return dayDate === slotDate;
    });

    const hasEventSlots = slots.find((slot) => !!slot.event);
    const hasRegularSlots = slots.length > 0 && !hasEventSlots;

    const calendarDayClasses = classNames(css.calendarDay, {
      [css.calendarDayEventSlots]: hasEventSlots,
      [css.calendarDayRegularSlots]: hasRegularSlots,
    });

    return (
      <div className={calendarDayClasses} id={`day-${date.format('YYYYMMDD')}`}>
        {slots && (
          <ul className={css.daySlots}>
            {slots.slice(0, MAX_SLOTS_IN_CALENDAR_DAY).map(this.renderDaySlot(date))}
          </ul>
        )}
        <span className={classNames(css.dayName, isToday ? css.dayNameToday : null)}>
          <span>{date.date()}</span>
        </span>
      </div>
    );
  };

  renderNavNext = (buttonProps) => {
    const { ariaLabel, disabled, onClick, onKeyUp, onMouseUp, currentMonth } = buttonProps;
    const { timezone } = this.props;

    const endOfRangeDate = moment().tz(timezone);
    endOfRangeDate.add(config.custom.dayCountAvailableForTimeSlots, 'days');
    const nextMonthDate = nextMonthFn(currentMonth, timezone);
    const endOfRangeMonthDate = getMonthStartInTimeZone(endOfRangeDate, timezone);

    if (disabled || dateIsAfter(nextMonthDate, endOfRangeMonthDate)) {
      return (
        <div
          className={classNames(css.arrowButton, css.arrowButtonRight, css.controlDisabled)}
          aria-label={ariaLabel}
        >
          <span className={css.arrow} />
        </div>
      );
    } else {
      return (
        <div
          className={classNames(css.arrowButton, css.arrowButtonRight)}
          onClick={onClick}
          onKeyUp={onKeyUp}
          onMouseUp={onMouseUp}
          aria-label={ariaLabel}
        >
          <span className={css.arrow} />
        </div>
      );
    }
  };

  renderNavPrev = (buttonProps) => {
    const { ariaLabel, disabled, onClick, onKeyUp, onMouseUp, currentMonth } = buttonProps;
    const { timezone } = this.props;

    const today = moment().tz(timezone);
    const prevMonthDate = prevMonthFn(currentMonth, timezone);
    const currentMonthDate = getMonthStartInTimeZone(today, timezone);

    if (disabled || !dateIsAfter(prevMonthDate, currentMonthDate)) {
      return (
        <div
          className={classNames(css.arrowButton, css.arrowButtonLeft, css.controlDisabled)}
          aria-label={ariaLabel}
        >
          <span className={css.arrow} />
        </div>
      );
    } else {
      return (
        <div
          className={classNames(css.arrowButton, css.arrowButtonLeft)}
          onClick={onClick}
          onKeyUp={onKeyUp}
          onMouseUp={onMouseUp}
          aria-label={ariaLabel}
        >
          <span className={css.arrow} />
        </div>
      );
    }
  };

  renderCalendarMonthWithButton = ({ month }) => {
    const monthText = moment(month).format('MMMM YYYY');

    return (
      <div className={css.calendarMonth}>
        <span>{monthText}</span>
        <PrimaryButton
          onClick={() => this.handleDateChanged(null)}
          className={css.calendarMonthButton}
          type="button"
        >
          <IconAddMeeting className={css.calendarMonthButtonIcon} />
          <span className={css.calendarMonthButtonText}>
            <FormattedMessage id="AvailabilityForm.addMeetingTime.button" />
          </span>
        </PrimaryButton>
      </div>
    );
  };

  get calendarLegends() {
    const showEventLegends =
      (this.props.eventsOpenVolunteering && this.props.eventsOpenVolunteering.length > 0) ||
      this.hasReservableEventTimeslots;

    return (
      <div className={css.dayPickerLegends}>
        <div className={classNames(css.dayPickerLegend)}>
          <FormattedMessage id="AvailabilityForm.available" />
        </div>
        <div className={classNames(css.dayPickerLegend, css.dayPickerBooked)}>
          <FormattedMessage id="AvailabilityForm.booked" />
        </div>
        {showEventLegends && (
          <>
            <div className={classNames(css.dayPickerLegend, css.dayPickerEvent)}>
              <FormattedMessage id="AvailabilityForm.availableAtEvent" />
            </div>
            <div className={classNames(css.dayPickerLegend, css.dayPickerEventBooked)}>
              <FormattedMessage id="AvailabilityForm.bookedAtEvent" />
            </div>
          </>
        )}
      </div>
    );
  }

  refreshCalendar = () => {
    const event = new MouseEvent('mouseover', {
      view: window,
      bubbles: true,
      cancelable: true,
    });

    Array.from(document.getElementsByClassName('CalendarDay')).forEach((element) => {
      element.dispatchEvent(event);
    });
  };

  get reservableSlotsWithBookings() {
    const { reservableSlots, bookings, timezone } = this.props;
    return reservableSlots.map((slot) => {
      const booking = bookings.find((booking) =>
        isSame(booking.attributes.start, 'UTC', slot.start, slot.timezone, 'minute')
      );

      const slotEvent = slot.event && getEvent(slot.event);
      const bookingOpenAlways = !slotEvent || !slotEvent.bookingOpen || !slotEvent.bookingOpen.from;
      const pendingToOpen =
        !bookingOpenAlways &&
        !isSameOrAfter(
          new Date(),
          timezone,
          slotEvent.bookingOpen.from,
          slotEvent.timezone,
          'minute'
        );

      return {
        ...slot,
        booked: !!booking,
        pendingToOpen,
      };
    });
  }

  get hasReservableEventTimeslots() {
    return !!this.props.reservableSlots.find((slot) => !!slot.event);
  }

  render() {
    const {
      currentUser,
      currentUserListing,
      eventsOpenVolunteering,
      exceedsWeeklyLimit,
      history,
      intl,
      onFetchTimeSlots,
      onManageDisableScrolling,
      reservableSlots,
      timezone,
      timeSlotUpdateInProgress,
      trackEvent,
    } = this.props;
    const ensuredCurrentUser = ensureCurrentUser(currentUser);

    let modalContent = null;

    switch (this.state.modalType) {
      case MODAL_TIME_SLOT_FORM:
        modalContent = (
          <AvailabilityFormModalTimeSlotForm
            exceedsWeeklyLimit={exceedsWeeklyLimit}
            eventsOpenVolunteering={eventsOpenVolunteering}
            initialValues={{
              bookingDate: {
                date: this.state.selectedDate && this.state.selectedDate.toDate(),
              },
              bookingTime: this.state.selectedBookingTime,
              duration: this.state.selectedDuration,
              meetingType: this.state.selectedMeetingType,
              id: this.state.selectedId,
              recurrence: this.state.selectedRecurrence,
              recurrenceParent: this.state.selectedRecurrenceParent,
              event: this.state.selectedEvent,
            }}
            inEditMode={this.state.editTimeSlot}
            isVisible={this.state.modalIsOpen && this.state.modalType === MODAL_TIME_SLOT_FORM}
            onFetchTimeSlots={onFetchTimeSlots}
            originalDate={this.state.selectedDate}
            originalDuration={this.state.selectedDuration}
            reservableSlots={reservableSlots}
            selectedDate={this.state.selectedDate}
            timezone={timezone}
            onClose={this.handleModalClose}
            onSubmit={this.handleSubmitTimeSlot}
            tab={this.state.selectedEvent ? TIMESLOT_FORM_TAB_EVENT : TIMESLOT_FORM_TAB_REGULAR}
            trackEvent={trackEvent}
          />
        );
        break;
      case MODAL_WEEKLY_LIMIT_EXCEEDED:
        modalContent = (
          <AvailabilityFormModalWeeklyLimitExceeded handleModalClose={this.handleModalClose} />
        );
        break;
      case MODAL_CONFIRM_DELETE:
        modalContent = (
          <AvailabilityFormModalConfirmDelete
            onSubmit={(values) => {
              trackEvent({
                category: 'Availability',
                action: 'Delete',
                label: 'Time Slot',
              });
              this.setState({ modalIsOpen: false });
              this.handleDeleteTimeSlot(
                values.deleteFutureSiblings === 'yes' &&
                  this.state.selectedRecurrence !== RECURS_NEVER
              ).then(() => {
                this.refreshCalendar();
              });
            }}
            onCancel={() => this.setState({ modalIsOpen: false })}
            selectedDate={this.state.selectedDate}
            selectedRecurrence={this.state.selectedRecurrence}
          />
        );
        break;
      case MODAL_FIRST_MEETING_TIME_ADDED:
        modalContent = (
          <AvailabilityFormModalFirstMeetingTimeAdded
            onAddMoreMeetingTimes={() => {
              this.handleModalClose();
              this.refreshCalendar();
            }}
            onConnectToCalendar={() => this.setState({ modalType: MODAL_CONNECT_CALENDAR })}
            ensuredCurrentUser={ensuredCurrentUser}
            eventTitle={this.state.modalEventTitle}
          />
        );
        break;
      case MODAL_CONNECT_CALENDAR:
        modalContent = (
          <CalendarDownloadOptionsModalConnect
            ensuredCurrentUser={ensuredCurrentUser}
            intl={intl}
            isOpen={this.state.modalIsOpen}
            onManageDisableScrolling={onManageDisableScrolling}
            onClose={() => this.setState({ modalIsOpen: false })}
          />
        );
        break;
      default:
        modalContent = null;
        break;
    }

    return (
      <>
        <FinalForm
          {...this.props}
          initialValues={{
            ...this.props.initialValues,
            methodPriceChoices: this.methodPriceChoices,
          }}
          mutators={{
            ...arrayMutators,
          }}
          render={(fieldRenderProps) => {
            const {
              handleSubmit,
              invalid,
              reservableSlots,
              updateInProgress,
              updateAvailabilityError,
              values,
            } = fieldRenderProps;

            const listing = ensureOwnListing(currentUserListing);
            const { supportedNPOs, methodPriceChoices } = listing.attributes.publicData;

            const submitError = updateAvailabilityError ? (
              <div className={css.error}>
                <FormattedMessage id="AvailabilityForm.updateAvailabilityFailed" />
              </div>
            ) : null;

            const navigationButton = (direction, isDisabled) => {
              const classes = [css.arrowButton];

              const arrowClass =
                direction === CONTROL_PREV ? css.arrowButtonLeft : css.arrowButtonRight;
              classes.push([arrowClass]);

              if (isDisabled) {
                classes.push([css.controlDisabled]);
              }

              return (
                <div className={classNames(...classes)}>
                  <span className={css.arrow}> </span>
                </div>
              );
            };

            const submitInProgress = updateInProgress;
            const submittedOnce = Object.keys(this.submittedValues).length > 0;
            const pristineSinceLastSubmit = submittedOnce && isEqual(values, this.submittedValues);
            const submitDisabled =
              invalid ||
              submitInProgress ||
              !supportedNPOs ||
              !methodPriceChoices ||
              !reservableSlots;

            const onDonationValueChange = (type) => (value) => {
              if (!value) return;

              if (trackEvent) {
                trackEvent({
                  category: 'Availability',
                  action: 'Select',
                  label: type,
                  value,
                });
              }

              const methodPriceChoices = { ...values.methodPriceChoices, [type]: value };

              // Some prices were not set, but now they are
              const allOnboardingPricesSelectedEvent =
                !this.allOnboardingPricesSelected &&
                methodPriceChoices.chat &&
                methodPriceChoices.inPerson;
              if (allOnboardingPricesSelectedEvent && trackEvent) {
                trackEvent({
                  category: 'Availability',
                  action: 'All set',
                  label: 'Donation prices',
                });

                this.allOnboardingPricesSelected = true;
              }

              this.methodPriceChoices = methodPriceChoices;

              this.props.onSubmit({ publicData: { methodPriceChoices } });
            };

            return (
              <Form
                className={css.form}
                onSubmit={(e) => {
                  this.submittedValues = values;
                  handleSubmit(e);
                }}
              >
                <div className={css.sectionContainer}>
                  <FormSectionHeading
                    header={
                      <>
                        <FormattedMessage id="AvailabilityForm.donationValue.heading" />{' '}
                        <Tooltip
                          content={<FormattedMessage id="AvailabilityForm.donationValue.tooltip" />}
                        />
                      </>
                    }
                  />
                  <AvailabilityFormDonationValues onDonationValueChange={onDonationValueChange} />
                </div>

                <div className={classNames(css.sectionContainer, css.lastSection)}>
                  <FormSectionHeading
                    header={<FormattedMessage id="AvailabilityForm.meetingTimes.heading" />}
                    subHeader={<FormattedMessage id="AvailabilityForm.meetingTimes.subHeading" />}
                  />
                  <div className={css.dayPicker}>
                    <DayPicker
                      className={css.dayPicker}
                      enableOutsideDays={true}
                      hideKeyboardShortcutsPanel={true}
                      isOutsideRange={(day) => {
                        return (
                          !isInclusivelyAfterDay(day, moment()) ||
                          !isInclusivelyBeforeDay(
                            day,
                            moment().add(config.custom.dayCountAvailableForTimeSlots, 'days')
                          )
                        );
                      }}
                      navNext={navigationButton(CONTROL_NEXT)}
                      navPrev={navigationButton(CONTROL_PREV)}
                      noBorder={true}
                      onDateChange={this.handleDateChanged}
                      onFetchTimeSlots={onFetchTimeSlots}
                      onMonthChanged={this.handleMonthChanged}
                      numberOfMonths={1}
                      renderDayContents={this.renderDay}
                      selectedDate={this.state.selectedDate}
                      timezone={timezone}
                      transitionDuration={1}
                      weekDayFormat="ddd"
                      withPortal={false}
                      renderNavNextButton={this.renderNavNext}
                      renderNavPrevButton={this.renderNavPrev}
                      renderMonthElement={this.renderCalendarMonthWithButton}
                    />
                  </div>
                  {this.calendarLegends}

                  <AvailabilityFormMonthlySlots
                    currentUser={currentUser}
                    handleConfirmDeleteTimeSlot={this.handleConfirmDeleteTimeSlot}
                    handleEditTimeSlot={this.handleEditTimeSlot}
                    history={history}
                    intl={intl}
                    onManageDisableScrolling={onManageDisableScrolling}
                    selectedMonth={this.state.selectedMonth}
                    reservableSlotsWithBookings={this.reservableSlotsWithBookings}
                    timezone={timezone}
                  />

                  {submitError}
                  {currentUserListing.attributes.state !== 'published' ? (
                    <Button
                      className={css.submitButton}
                      type="submit"
                      inProgress={submitInProgress}
                      disabled={submitDisabled}
                      ready={pristineSinceLastSubmit}
                    >
                      <FormattedMessage id="AvailabilityForm.publish" />
                    </Button>
                  ) : null}
                </div>
              </Form>
            );
          }}
        />
        <Modal
          id="AvailabilityForm.modal"
          containerClassName={css.modal}
          isOpen={this.state.modalIsOpen && !timeSlotUpdateInProgress}
          onClose={this.handleModalClose}
          onManageDisableScrolling={onManageDisableScrolling}
        >
          {modalContent}
        </Modal>
      </>
    );
  }
}

AvailabilityFormComponent.defaultProps = {
  bookings: [],
  rootClassName: null,
  className: null,
  defaultBookingTime: '07:00',
  defaultDuration: '15',
  defaultMeetingType: [],
  hasExceededWeeklyLimit: false,
  reservableSlots: [],
  timeSlotUpdateInProgress: false,
};

AvailabilityFormComponent.propTypes = {
  bookings: arrayOf(propTypes.booking),
  className: string,
  currentUser: propTypes.currentUser.isRequired,
  currentUserListing: propTypes.ownListing.isRequired,
  defaultBookingTime: string,
  defaultDuration: string,
  defaultMeetingType: array,
  eventKey: string,
  eventsOpenVolunteering: arrayOf(propTypes.event),
  exceedsWeeklyLimit: func.isRequired,
  hasExceededWeeklyLimit: bool,
  history: shape({
    push: func.isRequired,
  }).isRequired,
  intl: intlShape.isRequired,
  onDeleteTimeSlot: func.isRequired,
  onFetchTimeSlots: func.isRequired,
  onManageDisableScrolling: func.isRequired,
  onSubmit: func.isRequired,
  onSubmitTimeSlot: func.isRequired,
  reservableSlots: array,
  timezone: string.isRequired,
  trackEvent: func,
};

const AvailabilityForm = compose(injectIntl, withRouter)(AvailabilityFormComponent);
AvailabilityForm.displayName = 'AvailabilityForm';

export default AvailabilityForm;
