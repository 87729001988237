var merge = require("lodash.merge");
/**
 * Ensures a time slot's data structure
 *
 * @param   {Object}  t
 * @returns {Object}
 */


var ensureTimeSlot = function ensureTimeSlot(t) {
  var empty = {
    id: null,
    type: "timeSlot",
    attributes: {
      end: null,
      seats: 1,
      start: null,
      type: ""
    }
  };
  return merge(empty, t);
};

module.exports = {
  ensureTimeSlot: ensureTimeSlot
};