import { array, func, string } from 'prop-types';
import React from 'react';
import { ExternalLink } from '../index';
import NamedLink from '../NamedLink/NamedLink';
import css from './SectionImpactCards.css';
import classNames from 'classnames';


const ImpactCard = props => {
  const {
    image,
    bodyText,
    title,
    linkTitle,
    linkTarget,
    onClick
  } = props;

  const hasExternalLink = linkTitle && linkTarget && linkTarget.startsWith('https://');
  const hasInternalLink = linkTitle && linkTarget && !hasExternalLink;

  return (
    <div className={css.card}>
      <img alt={title} className={css.cardImage} src={image} />
      {title ? <h3 className={css.cardTitle}>{title}</h3> : null}
      {bodyText ? <p className={css.cardBodyText}>{bodyText}</p> : null}
      {hasExternalLink ? (
        <p className={css.cardLinkContainer}>
          <ExternalLink className={css.cardLink} href={linkTarget}>
            {linkTitle}
          </ExternalLink>
        </p>
      ) : hasInternalLink ? (
        <p className={css.cardLinkContainer}>
          <NamedLink className={css.cardLink} name={linkTarget} onClick={onClick}>
            {linkTitle}
          </NamedLink>
        </p>
      ) : null}
    </div>
  )
}

ImpactCard.propTypes = {
  bodyText: string,
  image: string.isRequired,
  linkTarget: string,
  linkTitle: string,
  onClick: func,
  title: string,
}

ImpactCard.defaultProps = {
  bodyText: null,
  linkTitle: null,
  linkTarget: null,
  onClick: () => {},
  title: null,
}

const SectionImpactCards = props => {
  const {
    cards,
    className
  } = props;
  return (
    <li className={classNames(css.root, className)}>
      {cards.map((card, index) => {
        return (
          <ImpactCard
            bodyText={card.bodyText}
            image={card.image}
            key={`impact-card-${index}`}
            linkTarget={card.linkTarget}
            linkTitle={card.linkTitle}
            onClick={card.onClick}
            title={card.title}
          />
        );
      })}
    </li>
  )
}

SectionImpactCards.propTypes = {
  cards: array,
  className: string,
};

SectionImpactCards.defaultProps = {
  cards: [],
  className: null,
}

export default SectionImpactCards;
