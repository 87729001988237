import { addMarketplaceEntities } from './marketplaceData.duck';
import { storableError } from '../util/errors';
import { parse } from '../util/urlHelpers';
import config from '../config';
import { fetchCurrentUserListings } from './user.duck';
import { ensureOwnListing } from '../util/data';
import sharetribe from '@givsly/sharetribe-utils';

const RESULT_PAGE_SIZE = 5;
const RESULT_GROUP_ALL = 'all';

// ================ Action types ================ //
export const SEARCH_NONPROFITS_REQUEST = 'app/NonprofitListing/SEARCH_NONPROFITS_REQUEST';
export const SEARCH_NONPROFITS_SUCCESS = 'app/NonprofitListing/SEARCH_NONPROFITS_SUCCESS';
export const SEARCH_NONPROFITS_ERROR = 'app/NonprofitListing/SEARCH_NONPROFITS_ERROR';

export const FETCH_ALL_NONPROFITS_REQUEST = 'app/NonprofitListing/FETCH_ALL_NONPROFITS_REQUEST';
export const FETCH_ALL_NONPROFITS_SUCCESS = 'app/NonprofitListing/FETCH_ALL_NONPROFITS_SUCCESS';
export const FETCH_ALL_NONPROFITS_ERROR = 'app/NonprofitListing/FETCH_ALL_NONPROFITS_ERROR';

export const FETCH_SELECTED_NONPROFITS_REQUEST =
  'app/NonprofitListing/FETCH_SELECTED_NONPROFITS_REQUEST';
export const FETCH_SELECTED_NONPROFITS_SUCCESS =
  'app/NonprofitListing/FETCH_SELECTED_NONPROFITS_SUCCESS';
export const FETCH_SELECTED_NONPROFITS_ERROR =
  'app/NonprofitListing/FETCH_SELECTED_NONPROFITS_ERROR';

// ================ Reducer ================ //
const initialState = {
  currentPageResultIds: [],
  groupedResultIds: [],
  nonprofitImpactMap: {},
  nonprofitNameMap: {},
  approvedNonprofitNameMap: {},
  pagination: null,
  searchParams: null,
  searchInProgress: false,
  searchListingsError: null,
  selectedNonprofits: [],
};

const resultIds = (data) => data.data.map((l) => l.id);

const listingPageReducer = (state = initialState, action = {}) => {
  const { type, payload } = action;
  switch (type) {
    case SEARCH_NONPROFITS_REQUEST:
      return {
        ...state,
        currentPageResultIds: [],
        searchParams: payload.searchParams,
        searchInProgress: true,
        searchMapListingIds: [],
        searchListingsError: null,
      };
    case SEARCH_NONPROFITS_SUCCESS:
      const searchResultIds = resultIds(payload.data);
      const groupedResultIds = state.groupedResultIds;
      groupedResultIds[payload.resultGroup] = searchResultIds;

      return {
        ...state,
        groupedResultIds,
        currentPageResultIds: searchResultIds,
        pagination: payload.data.meta,
        searchInProgress: false,
      };
    case SEARCH_NONPROFITS_ERROR:
      // eslint-disable-next-line no-console
      console.error(payload);
      return { ...state, searchInProgress: false, searchListingsError: payload };

    case FETCH_ALL_NONPROFITS_REQUEST:
      return {
        ...state,
      };
    case FETCH_ALL_NONPROFITS_SUCCESS:
      return {
        ...state,
        nonprofitImpactMap: payload.data.data.reduce(
          (acc, val) => ({
            ...acc,
            [val.id.uuid]: sharetribe.listing.ensureNonprofitListing(val).attributes.publicData
              .impact,
          }),
          state.nonprofitImpactMap
        ),
        nonprofitNameMap: payload.data.data.reduce(
          (acc, val) => ({
            ...acc,
            [val.id.uuid]: val.attributes.title,
          }),
          state.nonprofitNameMap
        ),
        approvedNonprofitNameMap: payload.data.data
          .filter(
            (npo) =>
              npo.attributes && npo.attributes.publicData && npo.attributes.publicData.isApproved
          )
          .reduce(
            (acc, val) => ({
              ...acc,
              [val.id.uuid]: val.attributes.title,
            }),
            state.approvedNonprofitNameMap
          ),
      };
    case FETCH_ALL_NONPROFITS_ERROR:
      // eslint-disable-next-line no-console
      console.error(payload);
      return { ...state };

    case FETCH_SELECTED_NONPROFITS_REQUEST:
      return {
        ...state,
        fetchSelectedNonprofitsInProgress: true,
        fetchSelectedNonprofitsListingIds: payload.listingIds,
        fetchSelectedNonprofitsError: null,
      };
    case FETCH_SELECTED_NONPROFITS_SUCCESS:
      return {
        ...state,
        selectedNonprofits: payload.data,
        fetchSelectedNonprofitsInProgress: false,
      };
    case FETCH_SELECTED_NONPROFITS_ERROR:
      console.error(payload);
      return {
        ...state,
        fetchSelectedNonprofitsInProgress: false,
        fetchSelectedNonprofitsError: payload,
      };
    default:
      return state;
  }
};

export default listingPageReducer;

// ================ Action creators ================ //

export const searchNonprofitListingsRequest = (searchParams) => ({
  type: SEARCH_NONPROFITS_REQUEST,
  payload: { searchParams },
});

export const searchNonprofitListingsSuccess = (response, resultGroup = RESULT_GROUP_ALL) => ({
  type: SEARCH_NONPROFITS_SUCCESS,
  payload: { data: response.data, resultGroup },
});

export const searchNonprofitListingsError = (e) => ({
  type: SEARCH_NONPROFITS_ERROR,
  error: true,
  payload: e,
});

export const fetchAllNonprofitsRequest = () => ({
  type: FETCH_ALL_NONPROFITS_REQUEST,
});
export const fetchAllNonprofitsSuccess = (response) => ({
  type: FETCH_ALL_NONPROFITS_SUCCESS,
  payload: { data: response.data },
});

export const fetchAllNonprofitsError = (e) => ({
  type: FETCH_ALL_NONPROFITS_ERROR,
  error: true,
  payload: e,
});

export const fetchSelectedNonprofitsRequest = (listingIds) => ({
  type: FETCH_SELECTED_NONPROFITS_REQUEST,
  payload: { listingIds },
});

export const fetchSelectedNonprofitsSuccess = (response) => ({
  type: FETCH_SELECTED_NONPROFITS_SUCCESS,
  payload: { data: response },
});

export const fetchSelectedNonprofitsError = (e) => ({
  type: FETCH_SELECTED_NONPROFITS_ERROR,
  error: true,
  payload: e,
});

export const searchNonprofitListings = (searchParams, resultGroup = RESULT_GROUP_ALL) => (
  dispatch,
  getState,
  sdk
) => {
  dispatch(searchNonprofitListingsRequest(searchParams));

  const { perPage, ...rest } = searchParams;

  const params = {
    ...rest,
    per_page: perPage,
    pub_isNPOListing: true,
    pub_isApproved: true,
  };

  return sdk.listings
    .query(params)
    .then((response) => {
      dispatch(addMarketplaceEntities(response));

      dispatch(searchNonprofitListingsSuccess(response, resultGroup));
      return response;
    })
    .catch((e) => {
      dispatch(searchNonprofitListingsError(storableError(e)));
      throw e;
    });
};

export const queryNonProfits = (
  search,
  resultPageSize = RESULT_PAGE_SIZE,
  resultGroup = RESULT_GROUP_ALL
) => {
  const queryParams = parse(search, {
    latlng: ['origin'],
    latlngBounds: ['bounds'],
  });
  const { address, origin, ...rest } = queryParams;
  const originMaybe = config.sortSearchByDistance && origin ? { origin } : {};
  const pub_organizationCategory =
    queryParams.pub_organizationCategory || search.pub_organizationCategory;
  const pub_organizationLocation =
    queryParams.pub_organizationLocation || search.pub_organizationLocation;
  const page = search.page || queryParams.page;

  return searchNonprofitListings(
    {
      ...rest,
      ...originMaybe,
      page,
      pub_organizationCategory,
      pub_organizationLocation,
      perPage: resultPageSize,
      include: ['author', 'images', 'author.profileImage'],
      'fields.listing': ['title', 'description', 'geolocation', 'publicData'],
      'fields.user': [
        'profile.displayName',
        'profile.abbreviatedName',
        'profile.profileImage',
        'profile.publicData',
      ],
      'fields.image': [
        'variants.square-small',
        'variants.square-small2x',
        'variants.landscape-crop',
        'variants.landscape-crop2x',
      ],
      'limit.images': 1,
    },
    resultGroup
  );
};

export const fetchAllNonprofits = (pageNum, isApproved) => (dispatch, getState, sdk) => {
  dispatch(fetchAllNonprofitsRequest());

  const params = {
    pub_isNPOListing: true,
    page: pageNum ? pageNum : 1,
    per_page: 100,
    'fields.listing': ['title', 'publicData'],
  };

  return sdk.listings
    .query(params)
    .then((response) => {
      dispatch(fetchAllNonprofitsSuccess(response));
      if (params.page < response.data.meta.totalPages) {
        dispatch(fetchAllNonprofits(params.page + 1));
      }
      if (isApproved) {
        const approvedNPOs = response.data.data.filter(
          (npo) =>
            npo.attributes && npo.attributes.publicData && npo.attributes.publicData.isApproved
        );
        response.data.data = approvedNPOs;
      }
      return response;
    })
    .catch((e) => {
      dispatch(fetchAllNonprofitsError(storableError(e)));
      throw e;
    });
};

export const fetchSelectedNonprofits = (listingIds) => async (dispatch, getState, sdk) => {
  dispatch(fetchSelectedNonprofitsRequest(listingIds));

  let selectedNonprofits = [];
  if (listingIds && listingIds.length > 0) {
    dispatch(fetchSelectedNonprofitsRequest(listingIds));
    selectedNonprofits = await Promise.all(
      listingIds.map((listingId) =>
        sdk.listings
          .show({
            id: listingId,
          })
          .then((result) => result.data.data)
          .catch((e) => {
            dispatch(fetchSelectedNonprofitsError(storableError(e)));
            throw e;
          })
      )
    );
  }
  return dispatch(fetchSelectedNonprofitsSuccess(selectedNonprofits));
};

export const loadData = (search) => (dispatch, getState, sdk) => {
  return Promise.all([dispatch(fetchCurrentUserListings())]).then(async (responses) => {
    const listings = responses[0];
    const listing = ensureOwnListing(
      Array.isArray(listings) && listings.length > 0 ? listings[0] : { nope: true }
    );
    const supportedListingIds = listing.attributes.publicData.supportedNPOs
      ? listing.attributes.publicData.supportedNPOs
      : [];
    return await Promise.all([
      dispatch(fetchSelectedNonprofits(supportedListingIds)),
      dispatch(queryNonProfits(search)),
    ]);
  });
};
