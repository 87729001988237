import React from 'react';
import { bool, node } from 'prop-types';
import classNames from 'classnames';
import css from './BottomBarSticky.css';

export const BottomBarSticky = (props) => {
  const classes = classNames(css.bottomBarContainer, { [css.hiddenBottomBar]: props.disabled });

  return (
    <div className={classes}>
      <div {...props}>{props.children}</div>
    </div>
  );
};

BottomBarSticky.propTypes = {
  children: node.isRequired,
  disabled: bool,
};
