import React from 'react';
import { Form as FinalForm } from 'react-final-form';
import arrayMutators from 'final-form-arrays';
import { Avatar, Button, FieldCheckboxRadioGroup, Form } from '../../components';
import css from './NotificationSettingsForm.css';
import { withMessages } from '../../util/localization';
import { FIELD_TYPE_RADIO } from '../../components/FieldCheckboxRadioGroup/FieldCheckboxRadioGroup';
import { propTypes } from '../../util/types';
import { ensureCurrentUser, ensureListing } from '../../util/data';
import { func } from 'prop-types';
import config from '../../config';
import { types as sdkTypes } from '../../util/sdkLoader';
import { FREQUENCY_DAILY, FREQUENCY_HOURLY, FREQUENCY_WEEKLY } from '../../util/notifications';
const { UUID } = sdkTypes;

class NotificationSettingsForm extends React.Component {
  render() {
    const {
      currentUser,
      getListing,
      getMessage,
      listing,
      onSubmit,
      onUnfollow,
      ...rest
    } = this.props;
    const ensuredCurrentUser = ensureCurrentUser(currentUser);
    const {
      followerDigestFrequency = config.custom.notifications.defaultFollowerDigestFrequency,
      followingDigestFrequency = config.custom.notifications.defaultFollowingDigestFrequency,
    } = ensuredCurrentUser.attributes.profile.privateData;
    const { following } = ensuredCurrentUser.attributes.profile.publicData;
    const initialValues = {
      followerDigestFrequency,
      followingDigestFrequency,
    };

    return (
      <FinalForm
        initialValues={initialValues}
        mutators={{ ...arrayMutators }}
        onSubmit={onSubmit}
        {...rest}
        render={(formRenderProps) => {
          const { formId, handleSubmit } = formRenderProps;

          const frequencies = [
            {
              key: FREQUENCY_HOURLY,
              label: getMessage('frequency.hourly'),
            },
            {
              key: FREQUENCY_DAILY,
              label: getMessage('frequency.daily'),
            },
            {
              key: FREQUENCY_WEEKLY,
              label: getMessage('frequency.weekly'),
            },
          ];

          return (
            <Form className={css.root} onSubmit={handleSubmit}>
              <h1>{getMessage('title')}</h1>
              <p className={css.text}>{getMessage('introduction')}</p>
              <h3 className={css.blockHeading}>{getMessage('summaries.title')}</h3>
              <p className={css.fieldDescription}>
                {getMessage('followingDigestFrequency.description')}
              </p>
              <FieldCheckboxRadioGroup
                className={css.radioGroup}
                id={`${formId}-followingDigestFrequency`}
                name="followingDigestFrequency"
                options={frequencies}
                onChange={(value) => onSubmit({ followingDigestFrequency: value })}
                type={FIELD_TYPE_RADIO}
              />
              <p className={css.fieldDescription}>
                {getMessage('followerDigestFrequency.description')}
              </p>
              <FieldCheckboxRadioGroup
                className={css.radioGroup}
                id={`${formId}-followerDigestFrequency`}
                name="followerDigestFrequency"
                options={frequencies}
                onChange={(value) => onSubmit({ followerDigestFrequency: value })}
                type={FIELD_TYPE_RADIO}
              />
              {following ? (
                <>
                  <h3 className={css.blockHeading}>{getMessage('following.title')}</h3>
                  <p className={css.fieldDescription}>{getMessage('following.description')}</p>
                  <ul className={css.following}>
                    {following.map((listingId) => {
                      const listing = ensureListing(getListing(new UUID(listingId)));
                      return (
                        <li className={css.followingPerson} key={`following-${listingId}`}>
                          <Avatar className={css.personImage} user={listing.author} />
                          <span className={css.followingPersonInnerWrapper}>
                            <span className={css.personDetail}>
                              <span className={css.name}>{listing.attributes.title}</span>
                              <span className={css.jobTitle}>
                                {listing.attributes.publicData.jobTitle}
                              </span>
                              <span className={css.companyName}>
                                {listing.attributes.publicData.companyName}
                              </span>
                            </span>
                            <Button
                              className={css.unfollowButton}
                              onClick={() => onUnfollow(listingId)}
                              type={'button'}
                            >
                              {getMessage('following.unfollow')}
                            </Button>
                          </span>
                        </li>
                      );
                    })}
                  </ul>
                </>
              ) : null}
            </Form>
          );
        }}
      />
    );
  }
}

NotificationSettingsForm.propTypes = {
  currentUser: propTypes.currentUser,
  getListing: func.isRequired,
  onUnfollow: func.isRequired,
};

NotificationSettingsForm.defaultProps = {};

export default withMessages(NotificationSettingsForm, 'NotificationSettingsForm');
