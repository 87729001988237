import React from 'react';
import { compose } from 'redux';
import { object, string, bool, number, func, shape } from 'prop-types';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import classNames from 'classnames';
import { withRouter } from 'react-router-dom';
import omit from 'lodash/omit';
import { SelectMultipleFilter, SelectSingleFilter } from '../../components';
import routeConfiguration from '../../routeConfiguration';
import { createResourceLocatorString } from '../../util/routes';
import { propTypes } from '../../util/types';
import css from './NonprofitSearchFilters.css';

// Dropdown container can have a positional offset (in pixels)
const FILTER_DROPDOWN_OFFSET = -14;

// resolve initial values for a multi value filter
const initialValues = (queryParams, paramName) => {
  return !!queryParams[paramName] ? queryParams[paramName].split(',') : [];
};

const SearchFiltersComponent = (props) => {
  const {
    getTranslation,
    rootClassName,
    className,
    urlQueryParams,
    listingsAreLoaded,
    resultsCount,
    searchInProgress,
    locationFilter,
    categoryFilter,
    history,
  } = props;

  const hasNoResult = listingsAreLoaded && resultsCount === 0;
  const classes = classNames(rootClassName || css.root, { [css.longInfo]: hasNoResult }, className);

  const handleSelectOptions = (urlParam, options) => {
    const queryParams =
      options && options.length > 0 && Array.isArray(options)
        ? {
            ...urlQueryParams,
            [urlParam]:
              (urlParam === 'pub_organizationCategory' ? '' : 'has_any:') + options.join(','),
          }
        : omit(urlQueryParams, urlParam);

    if (urlParam === 'pub_organizationLocation') {
      queryParams[urlParam] = options;
    }

    history.push(
      createResourceLocatorString('NonprofitSearchPage', routeConfiguration(), {}, queryParams)
    );
  };

  return (
    <div className={classes}>
      <div className={css.filters}>
        {locationFilter ? (
          <SelectSingleFilter
            id={'NonprofitSearchFilters.locationFilter'}
            name="location"
            urlParam={locationFilter.paramName}
            label={getTranslation('locationFilter')}
            onSelect={handleSelectOptions}
            showAsPopup
            options={locationFilter.options}
            initialValue={urlQueryParams[locationFilter.paramName]}
            contentPlacementOffset={FILTER_DROPDOWN_OFFSET}
          />
        ) : null}
        {categoryFilter ? (
          <SelectMultipleFilter
            id={'NonprofitSearchFilters.categoryFilter'}
            name="category"
            urlParam={categoryFilter.paramName}
            label={getTranslation('categoryFilter')}
            onSubmit={handleSelectOptions}
            showAsPopup
            options={categoryFilter.options}
            initialValues={initialValues(urlQueryParams, categoryFilter.paramName)}
            contentPlacementOffset={FILTER_DROPDOWN_OFFSET}
          />
        ) : null}
      </div>

      {searchInProgress ? (
        <div className={css.loadingResults}>
          <FormattedMessage id="SearchFilters.loadingResults" />
        </div>
      ) : null}

      {listingsAreLoaded && resultsCount > 0 ? (
        <div className={css.searchResultSummary}>
          <span className={css.resultsFound}>
            <FormattedMessage
              id="NonprofitSearchFilters.foundResults"
              values={{ count: resultsCount }}
            />
          </span>
        </div>
      ) : null}

      {hasNoResult ? (
        <div className={css.searchResultSummary}>
          <FormattedMessage id="NonprofitSearchFilters.noResults" />
        </div>
      ) : null}
    </div>
  );
};

SearchFiltersComponent.defaultProps = {
  rootClassName: null,
  className: null,
  resultsCount: null,
  searchingInProgress: false,
  locationFilter: null,
  categoryFilter: null,
  isSearchFiltersPanelOpen: false,
  toggleSearchFiltersPanel: null,
  searchFiltersPanelSelectedCount: 0,
};

SearchFiltersComponent.propTypes = {
  rootClassName: string,
  className: string,
  urlQueryParams: object.isRequired,
  listingsAreLoaded: bool.isRequired,
  resultsCount: number,
  searchingInProgress: bool,
  onManageDisableScrolling: func.isRequired,
  industryFilter: propTypes.filterConfig,
  interestsFilter: propTypes.filterConfig,
  isSearchFiltersPanelOpen: bool,
  toggleSearchFiltersPanel: func,
  searchFiltersPanelSelectedCount: number,
  history: shape({
    push: func.isRequired,
  }).isRequired,
  intl: intlShape.isRequired,
};

const NonprofitSearchFilters = compose(withRouter, injectIntl)(SearchFiltersComponent);

export default NonprofitSearchFilters;
