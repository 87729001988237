import React from 'react';
import css from './ListingPage.css';
import { propTypes } from '../../util/types';
import { object } from 'prop-types';
import UserAvatar from './UserAvatar';
import { ExternalLink } from '../../components';
import { ensureUser } from '../../util/data';

class UserHero extends React.Component {
  render() {
    const { listing, params, user } = this.props;

    const name = listing.attributes.title;
    const { companyName, jobTitle } = listing.attributes.publicData;
    const { linkedInUrl } = user.attributes.profile.publicData;

    return (
      <div className={css.userHero}>
        <div className={css.userHeader}>
          <div className={css.userDetails}>
            <h1 className={css.authorName}>{name}</h1>
            {jobTitle ? <div className={css.authorDescription}>{jobTitle}</div> : null}
            {companyName ? <div className={css.authorCompany}>{companyName}</div> : null}
            {linkedInUrl ? (
              <ExternalLink className={css.heroLinkedIn} href={linkedInUrl}>
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
                  <path
                    fill="#1C1A34"
                    fillRule="nonzero"
                    d="M12.667 0H3.333A3.334 3.334 0 0 0 0 3.333v9.334A3.334 3.334 0 0 0 3.333 16h9.334A3.333 3.333 0 0 0 16 12.667V3.333A3.333 3.333 0 0 0 12.667 0zM5.333 12.667h-2V5.333h2v7.334zm-1-8.179a1.172 1.172 0 0 1-1.166-1.176c0-.65.522-1.176 1.166-1.176.644 0 1.167.527 1.167 1.176 0 .65-.522 1.176-1.167 1.176zm9 8.179h-2V8.93c0-2.246-2.666-2.076-2.666 0v3.736h-2V5.333h2V6.51c.93-1.724 4.666-1.851 4.666 1.65v4.507z"
                  />
                </svg>
              </ExternalLink>
            ) : null}
          </div>
          {user && user.id ? <UserAvatar ensuredUser={ensureUser(user)} params={params} /> : null}
        </div>
      </div>
    );
  }
}

UserHero.propTypes = {
  listing: propTypes.listing,
  params: object.isRequired,
};

export default UserHero;
