import arrayMutators from 'final-form-arrays';
import { array, func, string } from 'prop-types';
import React from 'react';
import { Form as FinalForm } from 'react-final-form';
import { FieldRadioButton, Form, IconLink, NamedLink } from '../../components';
import { withMessages } from '../../util/localization';
import { createSlug } from '../../util/urlHelpers';
import css from './DonationForm.css';
import classNames from 'classnames';

class DonationForm extends React.Component {
  render() {
    const { getMessage, nonprofits, onChange, onSubmit, selectedNonprofit, ...rest } = this.props;
    return (
      <FinalForm
        mutators={{ ...arrayMutators }}
        initialValues={{
          selectedNonprofit,
        }}
        onSubmit={(e) => e.preventDefault()}
        {...rest}
        render={(formRenderProps) => {
          const { className } = formRenderProps;
          const formClasses = classNames(css.root, className);
          return (
            <Form className={formClasses} onSubmit={(e) => e.preventDefault()}>
              <ul className={css.nonprofits}>
                {nonprofits.map((nonprofit) => {
                  return (
                    <li className={css.listItem} key={`nonprofit-${nonprofit.id.uuid}`}>
                      <FieldRadioButton
                        id={`nonprofit-${nonprofit.id.uuid}-radio`}
                        label={nonprofit.attributes.title}
                        name={'selectedNonprofit'}
                        onChange={(e) => (typeof onChange === 'function' ? onChange(e) : null)}
                        value={nonprofit.id.uuid}
                      />
                      <NamedLink
                        className={css.itemLink}
                        params={{
                          id: nonprofit.id.uuid,
                          slug: createSlug(nonprofit.attributes.title),
                        }}
                        name="ListingPage"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <IconLink />
                      </NamedLink>
                    </li>
                  );
                })}
              </ul>
            </Form>
          );
        }}
      />
    );
  }
}

DonationForm.propTypes = {
  className: string,
  nonprofits: array.isRequired,
  onChange: func,
};

DonationForm.defaultProps = {
  className: null,
  onChange: null,
};

export default withMessages(DonationForm, 'DonationForm');
