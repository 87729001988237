import React from 'react';
import Button from '../Button/Button';
import css from './OutreachRequestDetails.css';
import { ICON_ARROW_LEFT, STYLE_LINK } from '../LiteButton/constants';
import LiteButton from '../LiteButton/LiteButton';
// @ts-ignore
import moment from 'moment-timezone';
import { useHistory } from 'react-router-dom';


type OutreachRequestDetailsProps = {
  onDiscardRequest: (id: string) => void;
  onProcessRequest: (id: string) => void;
  onBack: (tab: string) => void;
  outreachOffer: OutreachOffer;
  outreachRequest: OutreachRequest;
  nonprofitNameMap: { [key: string]: string };
  timezone: string;
};

const DATE_TIME_FORMAT = 'MMM D, h:mm A';

const OutreachRequestDetails = (props: OutreachRequestDetailsProps) => {
  const {
    nonprofitNameMap,
    onDiscardRequest,
    onProcessRequest,
    outreachRequest,
    outreachOffer,
    timezone,
  } = props;
  const {
    bookerFirstName,
    bookerEmail,
    bookerMessage,
    bookerLastName,
    bookerCompany,
    bookerBusinessTitle,
    bookerAgreeTermsAndConditions,
    bookerAgreePrivacyPolicy,
    state,
    selectedNonProfitId,
    archived,
    donationAmount,
    processedAmount,
  } = outreachRequest;
  const capReached = outreachOffer.state === 'capReached' ? true : false;

  const createdAt = moment(outreachRequest.createdAt).tz(timezone);
  const updatedAt = moment(outreachRequest.updatedAt).tz(timezone);

  const capitalizedState = state[0].toUpperCase() + state.slice(1).toLowerCase();

  const history = useHistory();
  const handleBackClick = () => {
    const url = `/account/outreach-offers/offer/${outreachOffer.id}`;
    history.push(url);
    // old behaviour
    // props.onBack(archived ? 'archive' : 'confirmed')
  };

  return (
    <div className={css.root}>
      <LiteButton
        className={css.buttonBack}
        icon={ICON_ARROW_LEFT}
        style={STYLE_LINK}
        onClick={handleBackClick}
      >
        Back
      </LiteButton>
      <h1 className={css.title}>
        {bookerFirstName} {bookerLastName} offer details
      </h1>
      <span className={css.status}>
        <span className={css[`status${capitalizedState}`]}>{capitalizedState}</span> &bull;&nbsp;
        {state === 'pending'
          ? createdAt.format(DATE_TIME_FORMAT)
          : updatedAt.format(DATE_TIME_FORMAT)}
        {capReached && (
          <span>
            &nbsp;&bull;&nbsp; <span className={css.statusCapReached}>Cap Reached</span>
          </span>
        )}
      </span>
      <h2 className={css.heading}>Name</h2>
      <div className={css.userData}>
        {bookerFirstName} {bookerLastName}
      </div>
      <h2 className={css.heading}>Email</h2>
      <div className={css.userData}>{bookerEmail}</div>
      <h2 className={css.heading}>Company</h2>
      <div className={css.userData}>{bookerCompany || '-'}</div>
      <h2 className={css.heading}>Business Title</h2>
      <div className={css.userData}>{bookerBusinessTitle || '-'}</div>
      <h2 className={css.heading}>Nonprofit donation</h2>
      <div className={css.userData}>
        ${(donationAmount / 100).toFixed(2)} to {nonprofitNameMap[selectedNonProfitId]}
      </div>

      {state === 'CONFIRMED' && (
        <>
          <h2 className={css.heading}>Total paid</h2>
          <div className={css.userData}>${(processedAmount / 100).toFixed(2)}</div>
        </>
      )}

      <h2 className={css.heading}>Message</h2>
      <div className={css.userData}>{bookerMessage || '-'}</div>
      <h2 className={css.heading}>Donation offer</h2>
      <div className={css.userData}>{outreachOffer.name}</div>
      {state === 'PENDING' && (
        <div className={css.controls}>
          <Button
            className={css.buttonProcess}
            onClick={() => onProcessRequest(outreachRequest.id)}
          >
            Process donation
          </Button>
          <Button
            className={css.buttonDiscard}
            onClick={() => onDiscardRequest(outreachRequest.id)}
          >
            Discard
          </Button>
        </div>
      )}
    </div>
  );
};

export default OutreachRequestDetails;
