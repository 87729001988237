import React, { Component } from 'react';
import { string } from 'prop-types';
import config from '../../config';
import { requiredFieldArrayCheckbox } from '../../util/validators';
import { FieldCheckboxRadioGroup } from '../../components';
import { intlShape } from '../../util/reactIntl';

class FieldMeetingType extends Component {
  onMeetingTypeChange = (value) => {
    if (!this.props.trackEvent) return;

    this.props.trackEvent({
      category: 'Availability',
      action: value.checked ? 'Select' : 'Remove',
      label: 'TimeSlotMeetingType',
      value: value.value,
    });
  };

  render() {
    const { intl, ...rest } = this.props;

    return (
      <FieldCheckboxRadioGroup
        options={config.custom.meetingTypes}
        label={intl.formatMessage({ id: 'TimeSlotForm.meetingType' })}
        twoColumns={true}
        validate={requiredFieldArrayCheckbox(
          intl.formatMessage({ id: 'TimeSlotForm.requiredMeetingType' })
        )}
        onChange={this.onMeetingTypeChange}
        {...rest}
      />
    );
  }
}

FieldMeetingType.propTypes = {
  intl: intlShape.isRequired,
  name: string.isRequired,
  id: string,
};

export default FieldMeetingType;
