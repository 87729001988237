import Tippy from '@tippy.js/react';
import moment from 'moment';
import React, { Component } from 'react';
import arrayMutators from 'final-form-arrays';
import { string, bool, func, node } from 'prop-types';
import { withMessages } from '../../util/localization';
import { Form as FinalForm } from 'react-final-form';
import classNames from 'classnames';
import {
  Form,
  FieldTiledSelector,
  SecondaryButton,
  PrimaryButton,
  IconEarth,
  FieldCheckbox,
} from '../../components';
import { propTypes } from '../../util/types';
import { FIELD_TYPE_RADIO } from '../../components/FieldCheckboxRadioGroup/FieldCheckboxRadioGroup';
import givslyConfig from '@givsly/config';
import css from './AcceptProposalForm.css';

class AcceptProposalForm extends Component {
  constructor(props) {
    super(props);
    this.handleIsDisabled = this.handleIsDisabled.bind(this);
  }

  /**
   * Checks if the given option should be disabled
   *
   * @param option
   * @returns {boolean}
   */
  handleIsDisabled = (option) => {
    const m = moment
      .tz(option.key, 'UTC')
      .add(givslyConfig.transaction.proposal.timeSlotBookableOffset, 'hours');
    const threshold = moment().tz('UTC');

    // @todo check if this overlaps with another time slot?

    return m.isBefore(threshold);
  };

  get acceptErrorMessage() {
    const { acceptProposalError = null, getMessage } = this.props;
    let key = '';

    if (
      acceptProposalError &&
      typeof acceptProposalError === 'object' &&
      acceptProposalError.apiErrors &&
      Array.isArray(acceptProposalError.apiErrors) &&
      acceptProposalError.apiErrors.length &&
      typeof acceptProposalError.apiErrors[0] === 'object' &&
      acceptProposalError.apiErrors[0].code === 'transaction-booking-time-not-available'
    ) {
      key = 'acceptProposalFailedTimeNotAvailable';
    } else if (acceptProposalError) {
      key = 'acceptProposalFailed';
    }

    return key ? <p className={css.actionError}>{getMessage(key)}</p> : null;
  }

  get declineErrorMessage() {
    const { declineProposalError = null, getMessage } = this.props;
    return declineProposalError ? (
      <p className={css.actionError}>{getMessage('declineProposalFailed')}</p>
    ) : null;
  }

  get options() {
    const { duration, suggestedTimes = [], timezone } = this.props;
    return suggestedTimes.map((date) => {
      const startTime = moment.tz(date, timezone);
      const endTime = startTime.clone().add(duration, 'minutes');

      return {
        key: date,
        label: (
          <div className={css.suggestion}>
            <strong>{startTime.format('ddd, D MMM')}</strong>
            <span>
              {startTime.format('hh:mm A')}
              {' - '}
              {endTime.format('hh:mm A')}
            </span>
          </div>
        ),
      };
    });
  }

  render() {
    const { bookingDetails, currentUser, getMessage, onAcceptProposal } = this.props;
    return (
      <FinalForm
        {...this.props}
        initialValues={{
          exposeEmail: [currentUser.attributes.email],
        }}
        onSubmit={onAcceptProposal}
        mutators={{
          ...arrayMutators,
        }}
        render={(formRenderProps) => {
          const {
            timezone,
            acceptInProgress,
            actionButtonsClassName,
            actionButtonWrapperClassName,
            className,
            declineInProgress,
            form,
            formId,
            handleSubmit,
            inProgress,
            invalid,
            onDeclineProposal,
            rootClassName,
            values,
          } = formRenderProps;
          const acceptDisabled = invalid || inProgress || !values.selectedMeetingTime;

          return (
            <Form
              className={classNames(rootClassName || css.root, className)}
              onSubmit={(values) => handleSubmit(values, form)}
            >
              <FieldTiledSelector
                className={css.tiles}
                disabledHint={getMessage('optionDisabledHint')}
                id={formId ? `${formId}.suggestedTimes` : 'suggestedTimes'}
                options={this.options}
                name="selectedMeetingTime"
                twoColumns={true}
                onChange={() => {}}
                onIsDisabled={this.handleIsDisabled}
                type={FIELD_TYPE_RADIO}
              />

              <span className={css.timezone}>
                <span className={css.timezoneLabel}>{getMessage('timezone')}</span>
                <IconEarth />{' '}
                <span className={css.timezoneText}>
                  {timezone.replace('_', ' ')} (UTC {moment.tz(timezone).format('Z')})
                </span>
              </span>
              <div className={css.bookingDetails}>{bookingDetails}</div>
              <label className={css.exposeEmail} htmlFor={'exposeEmail'}>
                <FieldCheckbox
                  className={css.exposeEmailCheckbox}
                  id={'exposeEmail'}
                  name={'exposeEmail'}
                  value={currentUser.attributes.email}
                />
                <span className={css.exposeEmailLabel}>
                  {getMessage('exposeEmail.label')}{' '}
                  <Tippy content={getMessage('exposeEmail.toolTip')}>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="12"
                      height="12"
                      viewBox="0 0 12 12"
                    >
                      <path
                        fill="#FAC51D"
                        d="M6 0a6 6 0 1 1 0 12A6 6 0 0 1 6 0zm.5 5.5h-1A.5.5 0 0 0 5 6v3.5a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5V6a.5.5 0 0 0-.5-.5zM6 2a1.25 1.25 0 1 0 0 2.5A1.25 1.25 0 0 0 6 2z"
                      />
                    </svg>
                  </Tippy>
                </span>
              </label>
              <div className={css.errors}>
                {this.acceptErrorMessage}
                {this.declineErrorMessage}
              </div>
              <div className={classNames(css.actionButtons, actionButtonsClassName)}>
                <div
                  className={classNames(actionButtonWrapperClassName || css.actionButtonWrapper)}
                >
                  <SecondaryButton
                    inProgress={declineInProgress}
                    disabled={inProgress}
                    onClick={onDeclineProposal}
                    type="button"
                  >
                    {getMessage('declineButton')}
                  </SecondaryButton>
                  <PrimaryButton
                    inProgress={acceptInProgress}
                    disabled={acceptDisabled}
                    onClick={handleSubmit}
                  >
                    {getMessage('acceptButton')}
                  </PrimaryButton>
                </div>
              </div>
            </Form>
          );
        }}
      />
    );
  }
}

AcceptProposalForm.defaultProps = {
  className: null,
  inProgress: false,
  messagePlaceholder: null,
  onBlur: () => null,
  onFocus: () => null,
  rootClassName: null,
  sendMessageError: null,
  showBookingDetails: null,
};

AcceptProposalForm.propTypes = {
  bookingDetails: node,
  currentUser: propTypes.currentUser.isRequired,
  className: string,
  inProgress: bool,
  messagePlaceholder: string,
  onBlur: func,
  onFocus: func,
  rootClassName: string,
  sendMessageError: propTypes.error,
};

AcceptProposalForm.displayName = 'AcceptProposalForm';

export default withMessages(AcceptProposalForm, 'AcceptProposalForm');
