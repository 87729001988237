import {
  ALL_TRANSITIONS,
  AS_SENDER,
  getArchiveTransition,
  getCurrentState,
  isNewForCurrentUser,
  isTransitionAllowed,
  NEW_TRANSITIONS_ONLY,
  STATE_NEW,
  STATE_SENT,
  TRANSITION_PROVIDER_SEEN,
  TRANSITION_PROVIDER_SEEN_SENT,
  TYPE_NEW_TIMES_AVAILABLE,
  UNSEEN_TRANSITIONS_ONLY,
} from '../util/notifications';
import { addMarketplaceEntities } from './marketplaceData.duck';
import { types as sdkTypes } from '../util/sdkLoader';
import { queryListings } from './Listings.duck';
import { ensureCurrentUser, ensureListing, ensureNotification, ensureUser } from '../util/data';
import { getListing } from '../util/listings';
import { storableError } from '../util/errors';
const { UUID } = sdkTypes;

/***************************************************************************************************
 * Action types
 **************************************************************************************************/
const ARCHIVE_NOTIFICATION_REQUEST = 'app/notifications/ARCHIVE_NOTIFICATION_REQUEST';
const ARCHIVE_NOTIFICATION_SUCCESS = 'app/notifications/ARCHIVE_NOTIFICATION_SUCCESS';
const ARCHIVE_NOTIFICATION_ERROR = 'app/notifications/ARCHIVE_NOTIFICATION_ERROR';
const COUNT_NEW_NOTIFICATIONS_REQUEST = 'app/notifications/COUNT_NEW_NOTIFICATIONS_REQUEST';
const COUNT_NEW_NOTIFICATIONS_SUCCESS = 'app/notifications/COUNT_NEW_NOTIFICATIONS_SUCCESS';
const COUNT_NEW_NOTIFICATIONS_ERROR = 'app/notifications/COUNT_NEW_NOTIFICATIONS_ERROR';
const CREATE_NOTIFICATION_REQUEST = 'app/notifications/CREATE_NOTIFICATION_REQUEST';
const CREATE_NOTIFICATION_SUCCESS = 'app/notifications/CREATE_NOTIFICATION_SUCCESS';
const CREATE_NOTIFICATION_ERROR = 'app/notifications/CREATE_NOTIFICATION_ERROR';
const MARK_NOTIFICATION_AS_SEEN_REQUEST = 'app/notifications/MARK_NOTIFICATION_AS_SEEN_REQUEST';
const MARK_NOTIFICATION_AS_SEEN_SUCCESS = 'app/notifications/MARK_NOTIFICATION_AS_SEEN_SUCCESS';
const MARK_NOTIFICATION_AS_SEEN_ERROR = 'app/notifications/MARK_NOTIFICATION_AS_SEEN_ERROR';
const QUERY_NOTIFICATIONS_REQUEST = 'app/notifications/QUERY_NOTIFICATIONS_REQUEST';
const QUERY_NOTIFICATIONS_SUCCESS = 'app/notifications/QUERY_NOTIFICATIONS_SUCCESS';
const QUERY_NOTIFICATIONS_ERROR = 'app/notifications/QUERY_NOTIFICATIONS_ERROR';
const REMOVE_FROM_QUERY_RESULT = 'app/notifications/REMOVE_FROM_QUERY_RESULT';
const SHOW_NOTIFICATION_REQUEST = 'app/notifications/SHOW_NOTIFICATION_REQUEST';
const SHOW_NOTIFICATION_SUCCESS = 'app/notifications/SHOW_NOTIFICATION_SUCCESS';
const SHOW_NOTIFICATION_ERROR = 'app/notifications/SHOW_NOTIFICATION_ERROR';
const UPDATE_NOTIFICATION_REQUEST = 'app/notifications/UPDATE_NOTIFICATION_REQUEST';
const UPDATE_NOTIFICATION_SUCCESS = 'app/notifications/UPDATE_NOTIFICATION_SUCCESS';
const UPDATE_NOTIFICATION_ERROR = 'app/notifications/UPDATE_NOTIFICATION_ERROR';
const UPDATE_OR_CREATE_NOTIFICATIONS_REQUEST =
  'app/notifications/UPDATE_OR_CREATE_NOTIFICATIONS_REQUEST';
const UPDATE_OR_CREATE_NOTIFICATIONS_SUCCESS =
  'app/notifications/UPDATE_OR_CREATE_NOTIFICATIONS_SUCCESS';
const UPDATE_OR_CREATE_NOTIFICATIONS_ERROR =
  'app/notifications/UPDATE_OR_CREATE_NOTIFICATIONS_ERROR';

/***************************************************************************************************
 * Initial state
 **************************************************************************************************/
const initialState = {
  archiveNotificationError: null,
  archiveNotificationInProgress: false,
  countNewNotificationsError: null,
  countNewNotificationsInProgress: false,
  createdNotification: null,
  createNotificationError: null,
  createNotificationInProgress: false,
  hasCountedNewNotifications: false,
  markNotificationAsSeenError: null,
  markNotificationAsSeenInProgress: false,
  newNotificationCount: 0,
  notification: null,
  notifications: {
    default: [],
  },
  pushedNotification: null,
  pushNotificationError: null,
  pushNotificationInProgress: false,
  queryNotificationsError: null,
  queryNotificationsInProgress: false,
  showNotificationError: null,
  showNotificationInProgress: false,
  updateOrCreateNotificationsError: null,
  updateOrCreateNotificationsInProgress: false,
};

/***************************************************************************************************
 * Reducer
 **************************************************************************************************/
export default function notificationsReducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    default:
      return state;
    case ARCHIVE_NOTIFICATION_REQUEST:
      return {
        ...state,
        archiveNotificationError: null,
        archiveNotificationInProgress: true,
      };
    case ARCHIVE_NOTIFICATION_SUCCESS:
      return {
        ...state,
        archiveNotificationInProgress: false,
      };
    case ARCHIVE_NOTIFICATION_ERROR:
      return {
        ...state,
        archiveNotificationError: payload,
        archiveNotificationInProgress: false,
      };
    case COUNT_NEW_NOTIFICATIONS_REQUEST:
      return {
        ...state,
        countNewNotificationsError: null,
        countNewNotificationsInProgress: true,
      };
    case COUNT_NEW_NOTIFICATIONS_SUCCESS:
      return {
        ...state,
        hasCountedNewNotifications: true,
        countNewNotificationsInProgress: false,
        newNotificationCount: payload,
      };
    case COUNT_NEW_NOTIFICATIONS_ERROR:
      return {
        ...state,
        countNewNotificationsError: payload,
        countNewNotificationsInProgress: false,
      };
    case CREATE_NOTIFICATION_REQUEST:
      return {
        ...state,
        createdNotification: null,
        createNotificationInProgress: true,
        createNotificationError: null,
      };
    case CREATE_NOTIFICATION_SUCCESS:
      return {
        ...state,
        createdNotification: payload,
        createNotificationInProgress: false,
      };
    case CREATE_NOTIFICATION_ERROR:
      return {
        ...state,
        createNotificationError: payload,
        createNotificationInProgress: false,
      };
    case MARK_NOTIFICATION_AS_SEEN_REQUEST:
      return {
        ...state,
        markNotificationAsSeenError: null,
        markNotificationAsSeenInProgress: true,
      };
    case MARK_NOTIFICATION_AS_SEEN_SUCCESS:
      return {
        ...state,
        markNotificationAsSeenInProgress: false,
      };
    case MARK_NOTIFICATION_AS_SEEN_ERROR:
      return {
        ...state,
        markNotificationAsSeenError: payload,
        markNotificationAsSeenInProgress: false,
      };
    case QUERY_NOTIFICATIONS_REQUEST:
      return {
        ...state,
        queryNotificationsError: null,
        queryNotificationsInProgress: true,
      };
    case QUERY_NOTIFICATIONS_SUCCESS:
      return {
        ...state,
        notifications: {
          ...state.notifications,
          [payload.resultSet]: payload.notifications,
        },
        queryNotificationsInProgress: false,
      };
    case QUERY_NOTIFICATIONS_ERROR:
      return {
        ...state,
        queryNotificationsError: payload,
        queryNotificationsInProgress: false,
      };
    case REMOVE_FROM_QUERY_RESULT:
      return {
        ...state,
        notifications: removeNotificationFromAllResultSets(state.notifications, payload),
      };
    case SHOW_NOTIFICATION_REQUEST:
      return {
        ...state,
        notification: null,
        showNotificationError: null,
        showNotificationInProgress: true,
      };
    case SHOW_NOTIFICATION_SUCCESS:
      return {
        ...state,
        notification: payload,
        showNotificationInProgress: false,
      };
    case SHOW_NOTIFICATION_ERROR:
      return {
        ...state,
        showNotificationError: payload,
        showNotificationInProgress: false,
      };
    case UPDATE_NOTIFICATION_REQUEST:
      return {
        ...state,
        pushedNotification: null,
        pushNotificationError: null,
        pushNotificationInProgress: false,
      };
    case UPDATE_NOTIFICATION_SUCCESS:
      return {
        ...state,
        pushedNotification: payload,
        pushNotificationInProgress: false,
      };
    case UPDATE_NOTIFICATION_ERROR:
      return {
        ...state,
        pushNotificationInProgress: false,
        pushNotificationError: payload,
      };
    case UPDATE_OR_CREATE_NOTIFICATIONS_REQUEST:
      return {
        ...state,
        updateOrCreateNotificationsError: null,
        updateOrCreateNotificationsInProgress: true,
      };
    case UPDATE_OR_CREATE_NOTIFICATIONS_SUCCESS:
      return {
        ...state,
        updateOrCreateNotificationsInProgress: false,
      };
    case UPDATE_OR_CREATE_NOTIFICATIONS_ERROR:
      return {
        ...state,
        updateOrCreateNotificationsError: payload,
        updateOrCreateNotificationsInProgress: false,
      };
  }
}

/***************************************************************************************************
 * Action creators
 **************************************************************************************************/
const archiveNotificationRequest = () => ({
  type: ARCHIVE_NOTIFICATION_REQUEST,
});
const archiveNotificationSuccess = () => ({
  type: ARCHIVE_NOTIFICATION_SUCCESS,
});
const archiveNotificationError = (err) => ({
  type: ARCHIVE_NOTIFICATION_ERROR,
  payload: err,
});
const countNewNotificationsRequest = () => ({
  type: COUNT_NEW_NOTIFICATIONS_REQUEST,
});
const countNewNotificationsSuccess = (newNotifications) => ({
  type: COUNT_NEW_NOTIFICATIONS_SUCCESS,
  payload: newNotifications,
});
const countNewNotificationsError = (error) => ({
  type: COUNT_NEW_NOTIFICATIONS_ERROR,
  payload: error,
});
const createNotificationRequest = () => ({
  type: CREATE_NOTIFICATION_REQUEST,
});
const createNotificationSuccess = (transaction) => ({
  type: CREATE_NOTIFICATION_SUCCESS,
  payload: transaction,
});
const createNotificationError = (error) => ({
  type: CREATE_NOTIFICATION_ERROR,
  payload: error,
});
const markNotificationAsSeenRequest = () => ({
  type: MARK_NOTIFICATION_AS_SEEN_REQUEST,
});
const markNotificationAsSeenSuccess = () => ({
  type: MARK_NOTIFICATION_AS_SEEN_SUCCESS,
});
const markNotificationAsSeenError = (error) => ({
  type: MARK_NOTIFICATION_AS_SEEN_ERROR,
  payload: error,
});
export const queryNotificationsRequest = (resultSet) => ({
  type: QUERY_NOTIFICATIONS_REQUEST,
  payload: resultSet,
});
export const queryNotificationsSuccess = (notifications, resultSet) => ({
  type: QUERY_NOTIFICATIONS_SUCCESS,
  payload: {
    notifications,
    resultSet,
  },
});
export const queryNotificationsError = (error) => ({
  type: QUERY_NOTIFICATIONS_ERROR,
  payload: error,
});
const removeFromQueryResult = (ensuredNotification) => ({
  type: REMOVE_FROM_QUERY_RESULT,
  payload: ensuredNotification,
});
const showNotificationRequest = (id) => ({
  type: SHOW_NOTIFICATION_REQUEST,
  payload: id,
});
const showNotificationSuccess = (transaction) => ({
  type: SHOW_NOTIFICATION_SUCCESS,
  payload: transaction,
});
const showNotificationError = (error) => ({
  type: SHOW_NOTIFICATION_ERROR,
  payload: error,
});
const updateNotificationRequest = () => ({
  type: UPDATE_NOTIFICATION_REQUEST,
});
const updateNotificationSuccess = (transaction) => ({
  type: UPDATE_NOTIFICATION_SUCCESS,
  payload: transaction,
});
const updateNotificationError = (error) => ({
  type: UPDATE_NOTIFICATION_ERROR,
  payload: error,
});
const updateOrCreateNotificationsRequest = () => ({
  type: UPDATE_OR_CREATE_NOTIFICATIONS_REQUEST,
});
const updateOrCreateNotificationsSuccess = () => ({
  type: UPDATE_OR_CREATE_NOTIFICATIONS_SUCCESS,
});
const updateOrCreateNotificationsError = (error) => ({
  type: UPDATE_OR_CREATE_NOTIFICATIONS_ERROR,
  payload: error,
});

/***************************************************************************************************
 * Thunks
 **************************************************************************************************/
export const createNotification = (params) => (dispatch, getState, sdk) => {
  dispatch(createNotificationRequest());

  return sdk.transactions
    .initiate(
      {
        processAlias: 'notification/release-1',
        transition: 'transition/create-notification',
        params,
      },
      {
        expand: true,
      }
    )
    .then((res) => {
      dispatch(createNotificationSuccess(res.data.data));
      return res.data.data;
    })
    .catch((e) => {
      dispatch(createNotificationError(storableError(e)));
    });
};

export const updateNotification = (transactionId, params) => (dispatch, getState, sdk) => {
  dispatch(updateNotificationRequest());

  return sdk.transactions
    .transition({
      id: transactionId,
      transition: 'transition/customer-update',
      params,
    })
    .then((res) => {
      dispatch(updateNotificationSuccess(res.data.data));
      return res.data.data;
    })
    .catch((e) => {
      dispatch(updateNotificationError(e));
    });
};

export const queryNotifications = (
  lastTransitions = ALL_TRANSITIONS,
  only = null,
  resultSet = 'default'
) => (dispatch, getState, sdk) => {
  dispatch(queryNotificationsRequest(resultSet));

  return sdk.transactions
    .query({
      only,
      lastTransitions,
      include: [
        'provider',
        'provider.profileImage',
        'customer',
        'customer.profileImage',
        'listing',
      ],
      'fields.transaction': [
        'createdAt',
        'lastTransition',
        'lastTransitionedAt',
        'transitions',
        'protectedData',
        'processName',
        'processVersion',
      ],
      'fields.user': ['profile.displayName', 'profile.abbreviatedName', 'profile.publicData'],
      'fields.image': ['variants.square-small', 'variants.square-small2x'],
    })
    .then((sdkResponse) => {
      dispatch(addMarketplaceEntities(sdkResponse));
      const mappedTransactions = mapRelationships(sdkResponse);
      dispatch(queryNotificationsSuccess(mappedTransactions.data.data, resultSet));
      return sdkResponse.data.data;
    })
    .catch((err) => {
      dispatch(queryNotificationsError(storableError(err)));
    });
};

export const showNotification = (id) => (dispatch, getState, sdk) => {
  dispatch(showNotificationRequest(id));

  return sdk.transactions
    .show({
      id: new UUID(id),
      include: [
        'provider',
        'provider.profileImage',
        'customer',
        'customer.profileImage',
        'listing',
      ],
      'fields.transaction': [
        'createdAt',
        'lastTransition',
        'lastTransitionedAt',
        'transitions',
        'protectedData',
        'processName',
        'processVersion',
      ],
      'fields.user': ['profile.displayName', 'profile.abbreviatedName', 'profile.publicData'],
      'fields.image': ['variants.square-small', 'variants.square-small2x'],
    })
    .then((response) => {
      dispatch(addMarketplaceEntities(response));
      const mappedTransactions = mapRelationships(response);
      return dispatch(showNotificationSuccess(mappedTransactions.data.data[0]));
    })
    .catch((e) => {
      return dispatch(showNotificationError(e));
    });
};

export const updateOrCreateNotifications = (
  ensuredCurrentUser,
  notificationType,
  protectedData
) => async (dispatch, getState, sdk) => {
  dispatch(updateOrCreateNotificationsRequest());

  // Fetch all listings with their associated users
  const { listingId } = ensuredCurrentUser.attributes.profile.publicData;
  await Promise.all([
    dispatch(
      queryListings({
        pub_following: listingId,
      })
    ),
    dispatch(queryNotifications(NEW_TRANSITIONS_ONLY, AS_SENDER, 'updateOrCreate')),
  ])
    .then(async () => {
      const followers = getState().Listings.queryResults;
      for (let i = 0; i < followers.length; i++) {
        const ensuredListing = ensureListing(getListing(followers[i], getState()));
        const ensuredOtherUser = ensureUser(ensuredListing.author);

        const existingNotification = getState().notifications.notifications['updateOrCreate'].find(
          (notification) => {
            const ensuredNotification = ensureNotification(notification);
            const { isSent, type } = ensuredNotification.attributes.protectedData;
            return (
              ensuredNotification.provider.id.uuid === ensuredOtherUser.id.uuid &&
              type === TYPE_NEW_TIMES_AVAILABLE &&
              isSent === false
            );
          }
        );

        if (existingNotification) {
          // Update existing transaction
          dispatch(
            updateNotification(existingNotification.id, {
              protectedData: {
                ...protectedData,
                timeSlots: [
                  ...existingNotification.attributes.protectedData.timeSlots,
                  ...protectedData.timeSlots,
                ],
              },
            })
          );
        } else {
          // Create new notification!
          dispatch(
            createNotification({
              listingId: ensuredListing.id,
              protectedData: {
                ...protectedData,
                isSent: false,
                type: notificationType,
              },
            })
          );
        }
      }
    })
    .then(() => {
      dispatch(updateOrCreateNotificationsSuccess());
    })
    .catch((error) => {
      dispatch(updateOrCreateNotificationsError(storableError(error)));
    });
};

export const countNewNotifications = (force = false) => (dispatch, getState, sdk) => {
  // The count only needs to be done once, once it's stored in the state we can omit further
  // requests and just return the result, unless we want to enforce it.
  if (getState().notifications.hasCountedNewNotifications && !force) {
    return Promise.resolve().then(() => getState().notifications.newNotificationCount);
  }

  // Regular count flow
  const resultSet = 'newCount';
  dispatch(countNewNotificationsRequest());
  return dispatch(queryNotifications(UNSEEN_TRANSITIONS_ONLY, null, resultSet))
    .then(() => {
      const ensuredCurrentUser = ensureCurrentUser(getState().user.currentUser);
      const newNotifications = getState().notifications.notifications[resultSet].filter(
        (notification) => {
          const ensuredNotification = ensureNotification(notification);
          return isNewForCurrentUser(ensuredNotification, ensuredCurrentUser);
        }
      );
      dispatch(countNewNotificationsSuccess(newNotifications.length));

      return newNotifications;
    })
    .catch((err) => {
      dispatch(countNewNotificationsError(storableError(err)));
    });
};

export const markNotificationAsSeen = (ensuredNotification, ensuredCurrentUser) => (
  dispatch,
  getState,
  sdk
) => {
  const currentState = getCurrentState(ensuredNotification);
  if (ensuredNotification.provider.id.uuid === ensuredCurrentUser.id.uuid) {
    const params = {
      id: ensuredNotification.id,
      params: {},
    };

    switch (currentState) {
      default:
        // No transition needed, user not eligible
        break;
      case STATE_SENT:
        params.transition = TRANSITION_PROVIDER_SEEN_SENT;
        break;
      case STATE_NEW:
        params.transition = TRANSITION_PROVIDER_SEEN;
        break;
    }

    if (params.transition) {
      dispatch(markNotificationAsSeenRequest());

      return sdk.transactions
        .transition(params)
        .then(() => {
          dispatch(countNewNotifications());
          dispatch(markNotificationAsSeenSuccess());
          return true;
        })
        .catch((err) => {
          dispatch(markNotificationAsSeenError(storableError(err)));
        });
    }
  }

  return Promise.resolve();
};

export const archiveNotification = (ensuredNotification, ensuredCurrentUser) => (
  dispatch,
  getState,
  sdk
) => {
  dispatch(archiveNotificationRequest());

  const transition = getArchiveTransition(ensuredCurrentUser, ensuredNotification);
  if (isTransitionAllowed(ensuredCurrentUser, ensuredNotification, transition)) {
    const params = {
      id: ensuredNotification.id,
      transition,
      params: {},
    };
    return sdk.transactions
      .transition(params)
      .then(() => {
        dispatch(removeFromQueryResult(ensuredNotification));
        dispatch(countNewNotifications());
        dispatch(archiveNotificationSuccess());
        return Promise.resolve(true);
      })
      .catch((err) => {
        dispatch(archiveNotificationError(storableError(err)));
      });
  } else {
    const error = `Transition ${transition} not permitted for this user`;
    dispatch(archiveNotificationError(error));
    return Promise.reject(error);
  }
};

/***************************************************************************************************
 * Helpers
 **************************************************************************************************/

/**
 * Maps included relations to the notification object based on the SDK response.
 *
 * @param response
 * @returns response
 */
const mapRelationships = (response) => {
  if (!Array.isArray(response.data.data)) {
    response.data.data = [response.data.data];
  }
  response.data.data.map((transaction) => {
    transaction.provider = response.data.included.find((entity) => {
      return (
        entity.id.uuid === transaction.relationships.provider.data.id.uuid && entity.type === 'user'
      );
    });
    if (transaction.provider) {
      transaction.provider.profileImage = response.data.included.find((entity) => {
        return (
          transaction.provider.relationships.profileImage.data &&
          entity.id.uuid === transaction.provider.relationships.profileImage.data.id.uuid &&
          entity.type === 'image'
        );
      });
    }

    transaction.customer = response.data.included.find((entity) => {
      return (
        entity.id.uuid === transaction.relationships.customer.data.id.uuid && entity.type === 'user'
      );
    });
    if (transaction.customer) {
      transaction.customer.profileImage = response.data.included.find((entity) => {
        return (
          transaction.customer.relationships.profileImage.data &&
          entity.id.uuid === transaction.customer.relationships.profileImage.data.id.uuid &&
          entity.type === 'image'
        );
      });
    }

    return transaction;
  });
  return response;
};

/**
 * Removes a single notification from all result sets.
 *
 * @see removeNotificationFromResultSet
 * @param resultSets
 * @param ensuredNotification
 * @returns {*}
 */
const removeNotificationFromAllResultSets = (resultSets, ensuredNotification) => {
  Object.keys(resultSets).forEach((setName) => {
    resultSets[setName] = removeNotificationFromResultSet(resultSets[setName], ensuredNotification);
  });
  return resultSets;
};

/**
 * Removes a single notification form a single notification result set. This can be used to silently
 * remove a notification once it's archived without having to call the Sharetribe API.
 *
 * @param resultSet
 * @param ensuredNotification
 * @returns {*}
 */
const removeNotificationFromResultSet = (resultSet, ensuredNotification) => {
  return resultSet.filter((resultNotification) => {
    const ensuredResultNotification = ensureNotification(resultNotification);
    return ensuredResultNotification.id.uuid !== ensuredNotification.id.uuid;
  });
};
