import React, { Component } from 'react';
import { arrayOf, node, string } from 'prop-types';
import classNames from 'classnames';
import css from './SectionSideScroll.css';
import { IconArrowHead } from '../../index';

class SectionSideScroll extends Component {
  constructor(props) {
    super(props);

    this.state = {
      activeSlide: 0,
      sliderHeight: 'auto',
    };

    this.slideContainer = React.createRef();
    this.setSliderHeight = this.setSliderHeight.bind(this);
  }

  componentDidMount() {
    window.addEventListener('resize', this.setSliderHeight);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.children === this.props.children) {
      return;
    }

    this.setSliderHeight();
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.setSliderHeight);
  }

  setSliderHeight() {
    // Forces the slides to be same size
    this.setState({ sliderHeight: 'auto' });

    if (
      !this.slideContainer ||
      !this.slideContainer.current ||
      !this.slideContainer.current.clientHeight
    ) {
      return;
    }

    this.setState({ sliderHeight: `${this.slideContainer.current.clientHeight}px` });
  }

  get totalSlides() {
    return this.props.children.length;
  }

  nextSlide() {
    const next = this.state.activeSlide + 1;
    if (next >= this.totalSlides) {
      this.setSlide(0);
    } else {
      this.setSlide(next);
    }
  }

  prevSlide() {
    const prev = this.state.activeSlide - 1;
    if (prev < 0) {
      this.setSlide(this.totalSlides - 1);
    } else {
      this.setSlide(prev);
    }
  }

  setSlide(slide) {
    this.setState({ activeSlide: slide });
  }

  showPrevArrow() {
    return this.state.activeSlide > 0;
  }

  showNextArrow() {
    return this.state.activeSlide < this.totalSlides - 1;
  }

  render() {
    const slides = this.props.children.map((slide, index) => {
      return (
        <div
          key={index}
          className={classNames(css.slide, { [css.active]: index === this.state.activeSlide })}
          aria-hidden={index === this.state.activeSlide ? undefined : true}
        >
          {slide}
        </div>
      );
    });

    const dots = slides.map((slide, index) => (
      <span
        className={classNames(css.dot, this.props.dotStyle, {
          [css.dotActive]: index === this.state.activeSlide,
          [this.props.dotActiveStyle]: index === this.state.activeSlide,
        })}
        key={index}
        onClick={() => this.setSlide(index)}
      >
        {''}
      </span>
    ));

    return (
      <div className={css.section}>
        <button
          className={classNames(css.button, this.props.buttonStyle, css.prevButton, {
            [css.hiddenButton]: !this.showPrevArrow(),
          })}
          onClick={() => this.prevSlide()}
        >
          <IconArrowHead direction="left" size="big" className={css.buttonIcon} />
        </button>

        <div
          className={css.slidesContainer}
          ref={this.slideContainer}
          style={{ height: this.state.sliderHeight }}
        >
          {slides}
        </div>

        <button
          className={classNames(css.button, this.props.buttonStyle, css.nextButton, {
            [css.hiddenButton]: !this.showNextArrow(),
          })}
          onClick={() => this.nextSlide()}
        >
          <IconArrowHead direction="right" size="big" className={css.buttonIcon} />
        </button>

        {dots.length > 1 && <div className={css.dots}>{dots}</div>}
      </div>
    );
  }
}

SectionSideScroll.propTypes = {
  children: arrayOf(node).isRequired,
  buttonStyle: string,
  dotStyle: string,
  dotActiveStyle: string,
};

export default SectionSideScroll;
