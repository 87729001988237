import React, { Component } from 'react';
import { arrayOf, shape, string } from 'prop-types';
import { SectionCards } from '../../../../components';
import css from './EventLandingPageColumns.css';

class EventLandingPageColumns extends Component {
  get cards() {
    return this.props.columns.map((column) => ({
      ...column,
      className: css.card,
    }));
  }

  render() {
    return <SectionCards title={this.props.title} cards={this.cards} className={css.cards} />;
  }
}

EventLandingPageColumns.propTypes = {
  columns: arrayOf(
    shape({
      title: string.isRequired,
      image: string.isRequired,
      text: string.isRequired,
    })
  ).isRequired,
  title: string.isRequired,
};

export default EventLandingPageColumns;
