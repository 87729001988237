import css from './LiteButton.css';

export const ICON_ARROW_LEFT = css.iconArrowLeft;
export const ICON_ARROW_DOWN = css.iconArrowDown;
export const ICON_CART = css.iconCart;
export const ICON_ENVELOPE = css.iconEnvelope;
export const ICON_PLUS = css.iconPlus;
export const NO_ICON = css.noIcon;
export const STYLE_LIGHT = css.styleLight;
export const STYLE_DARK = css.styleDark;
export const STYLE_SUCCESS = css.styleSuccess;
export const STYLE_LINK = css.styleLink;
export const ICON_COPY = css.iconCopy;
export const ICON_VIEW = css.iconView;
export const ICON_DOWNLOAD = css.iconDownload;
export const ICON_DOWN_ARROW = css.iconDownArrow;
export const ICON_PENCIL = css.iconPencil;
