import { func, object, string } from 'prop-types';
import React from 'react';
import classNames from 'classnames';
import css from './SectionCallToAction.css';
import Button from '../Button/Button';

const SectionCallToAction = props => {
  const {
    bodyText,
    className,
    innerRef,
    linkButtonLabel,
    onClick,
    title,
  } = props;

  return (
    <li className={classNames(css.root, className)} ref={innerRef}>
      {title ? (
        <h2 className={css.title}>{title}</h2>
      ) : null}
      {bodyText ? (
        <p className={css.bodyText}>
          {bodyText}
        </p>
      ) : null}
      <span className={css.actionContainer}>
        <Button className={css.actionLinkButton} onClick={onClick}>
          {linkButtonLabel}
        </Button>
      </span>
    </li>
  );
}

SectionCallToAction.propTypes = {
  bodyText: string,
  className: string,
  innerRef: object,
  linkButtonLabel: string.isRequired,
  onClick: func.isRequired,
  title: string,
}

SectionCallToAction.defaultProps = {
  bodyText: null,
  className: null,
  innerRef: null,
  onClick: () => {},
  title: null,
}

export default SectionCallToAction;
