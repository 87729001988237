import React from 'react';
import { bool, func, shape, string } from 'prop-types';
import { withRouter } from 'react-router-dom';
import {
  Avatar,
  Footer,
  IconSpinner,
  LayoutSingleColumn,
  LayoutWrapperFooter,
  LayoutWrapperMain,
  LayoutWrapperTopbar,
  ModalInMobile,
  NamedLink,
  Page,
  RequestToMeetCustomerNotification,
  RequestToMeetProviderNotification,
} from '../../components';
import css from './NotificationDetailPage.css';
import { TopbarContainer } from '../index';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withMessages } from '../../util/localization';
import { ensureCurrentUser, ensureListing, ensureNotification } from '../../util/data';
import { LISTING_STATE_CLOSED, propTypes } from '../../util/types';
import { loadData } from './NotificationDetailPage.duck';
import { AVATAR_SIZES_HUGE } from '../../components/Avatar/Avatar';
import { isScrollingDisabled, manageDisableScrolling } from '../../ducks/UI.duck';
import NewTimesAvailableNotification from '../../components/Notification/NewTimesAvailableNotification';
import { TYPE_ASK_FOR_TIME, TYPE_NEW_TIMES_AVAILABLE } from '../../util/notifications';
import { getListing as getListingFromState } from '../../util/listings';
import BookingDateAndSlotPicker from '../ListingPage/BookingDateAndSlotPicker';
import { injectIntl } from 'react-intl';
import { intlShape } from '../../util/reactIntl';
import {
  fetchTimeSlots,
  selectDate,
  selectMethod,
  selectReservableSlot,
  toggleDeductFee,
} from '../ListingPage/ListingPage.duck';
import { sendVerificationEmail } from '../../ducks/user.duck';
import { requestPublishListingDraft, requestUpdateListing } from '../../ducks/UserListing.duck';
import { updateProfile } from '../../ducks/UserProfile.duck';
import { getEvent, isAccessCodeRequiredForSlot } from '../../util/events';
import config from '../../config';
import { getBookingPanelPropsForListing } from '../../util/timeSlots';
import { calculateQuantityFromHours } from '../../util/dates';
import routeConfiguration from '../../routeConfiguration';
import { createResourceLocatorString, findRouteByRouteName } from '../../util/routes';
import { createSlug, parse, stringify } from '../../util/urlHelpers';
import { types as sdkTypes } from '../../util/sdkLoader';
import { initializeCardPaymentData } from '../../ducks/stripe.duck';
import omit from 'lodash/omit';

const { UUID } = sdkTypes;
const MODAL_BREAKPOINT = 1023;
const closeBookModal = (history, location) => {
  const { pathname, search, state } = location;
  const searchParams = omit(parse(search), 'book');
  const searchString = `?${stringify(searchParams)}`;
  history.push(`${pathname}${searchString}`, state);
};

class NotificationDetailPageComponent extends React.Component {
  constructor(props) {
    super(props);
    this.handleBookingSubmit = this.handleBookingSubmit.bind(this);
    this.handleScrollToBookingInfo = this.handleScrollToBookingInfo.bind(this);
  }

  handleBookingSubmit = (values) => {
    const { ensuredListing, isOwnListing } = this.props;
    const isCurrentlyClosed = ensuredListing.attributes.state === LISTING_STATE_CLOSED;

    if (isOwnListing || isCurrentlyClosed) {
      window.scrollTo(0, 0);
    } else {
      const { history, getListing, callSetInitialValues, onInitializeCardPaymentData } = this.props;

      const { bookingStartTime, bookingEndTime, eventKey, ...restOfValues } = values;

      const bookingData = {
        quantity: calculateQuantityFromHours(bookingStartTime, bookingEndTime),
        ...restOfValues,
      };

      const event = eventKey ? getEvent(eventKey) : null;
      const eventNonprofits =
        event && event.supportedNPOs
          ? event.supportedNPOs.map((npo) => getListing(new UUID(npo)))
          : [];

      const initialValues = {
        listing: ensuredListing,
        bookingData,
        bookingDates: {
          bookingStart: bookingStartTime.toDate(),
          bookingEnd: bookingEndTime.toDate(),
        },
        confirmPaymentError: null,
        event,
        eventNonprofits,
      };

      const routes = routeConfiguration();
      // Customize checkout page state with current listing and selected bookingDates
      const { setInitialValues } = findRouteByRouteName('CheckoutPage', routes);
      callSetInitialValues(setInitialValues, initialValues);

      // Clear previous Stripe errors from store if there is any
      onInitializeCardPaymentData();

      // Redirect to CheckoutPage
      history.push(
        createResourceLocatorString(
          'CheckoutPage',
          routes,
          { id: ensuredListing.id.uuid, slug: createSlug(ensuredListing.attributes.title) },
          {}
        )
      );
    }
  };

  handleScrollToBookingInfo() {
    const selector = `#priceBreakdownContainer`;
    const el = document.querySelector(selector);
    if (el) {
      el.scrollIntoView({
        block: 'center',
        behavior: 'smooth',
      });
    }
  }

  get dynamicContent() {
    const {
      canRenderCalendar,
      currentUser,
      getListing,
      monthlyTimeSlots,
      notification,
      onSelectDate,
      onSelectReservableSlot,
      timezone,
    } = this.props;

    const isReady = this.isReady;
    const isCustomer = isReady && notification.customer.id.uuid === currentUser.id.uuid;

    const ensuredNotification = ensureNotification(notification);
    const ensuredCurrentUser = ensureCurrentUser(currentUser);
    const ensuredOtherUser =
      isReady && ensuredNotification.customer.id.uuid === ensuredCurrentUser.id.uuid
        ? ensuredNotification.provider
        : ensuredNotification.customer;

    switch (ensuredNotification.attributes.protectedData.type) {
      default:
        return <IconSpinner />;
      case TYPE_ASK_FOR_TIME:
        if (isCustomer) {
          return (
            <RequestToMeetCustomerNotification
              currentUser={ensuredCurrentUser}
              notification={ensuredNotification}
              otherUser={ensuredOtherUser}
              timezone={timezone}
            />
          );
        } else {
          return (
            <RequestToMeetProviderNotification
              currentUser={ensuredCurrentUser}
              notification={ensuredNotification}
              otherUser={ensuredOtherUser}
              timezone={timezone}
            />
          );
        }
      case TYPE_NEW_TIMES_AVAILABLE:
        return (
          <NewTimesAvailableNotification
            canRenderCalendar={canRenderCalendar}
            currentUser={ensuredCurrentUser}
            getListing={getListing}
            monthlyTimeSlots={monthlyTimeSlots}
            notification={ensuredNotification}
            onScrollToBookingInfo={this.handleScrollToBookingInfo}
            onSelectDate={onSelectDate}
            onSelectReservableSlot={onSelectReservableSlot}
            otherUser={ensuredOtherUser}
            timezone={timezone}
          />
        );
    }
  }

  get card() {
    const { currentUser, notification } = this.props;

    const isReady = this.isReady;
    const ensuredNotification = ensureNotification(notification);
    const ensuredCurrentUser = ensureCurrentUser(currentUser);
    const ensuredOtherUser =
      isReady && ensuredNotification.customer.id.uuid === ensuredCurrentUser.id.uuid
        ? ensuredNotification.provider
        : ensuredNotification.customer;
    const {
      companyName,
      firstName,
      geolocation,
      jobTitle,
      lastName,
    } = ensuredOtherUser.attributes.profile.publicData;

    return isReady ? (
      <>
        <Avatar className={css.cardImage} user={ensuredOtherUser} renderSizes={AVATAR_SIZES_HUGE} />
        <span className={css.cardInnerWrapper}>
          <span className={css.cardTitle}>
            {firstName} {lastName}
          </span>
          <span className={css.cardSubTitle}>{jobTitle}</span>
          <span className={css.cardSubTitle}>{companyName}</span>
          {geolocation && geolocation.city ? (
            <span className={css.cardLocation}>
              {geolocation.city}, {geolocation.state.shortCode}
            </span>
          ) : null}
        </span>
      </>
    ) : (
      <IconSpinner />
    );
  }

  get otherUser() {
    const { currentUser, notification } = this.props;
    const ensuredCurrentUser = ensureCurrentUser(currentUser);

    const isReady = this.isReady;
    const ensuredNotification = ensureNotification(notification);
    return isReady && ensuredNotification.customer.id.uuid === ensuredCurrentUser.id.uuid
      ? ensuredNotification.provider
      : ensuredNotification.customer;
  }

  get isReady() {
    const { currentUser, notification, showNotificationInProgress } = this.props;
    return (
      notification &&
      notification.id &&
      currentUser &&
      currentUser.id &&
      !showNotificationInProgress
    );
  }

  get showBookingDateAndSlotPicker() {
    const { notification } = this.props;
    const ensuredNotification = ensureNotification(notification);
    return ensuredNotification.attributes.protectedData.type === TYPE_NEW_TIMES_AVAILABLE;
  }

  render() {
    const {
      canRenderCalendar,
      currentDateReservableSlots,
      currentUser,
      deductFee,
      ensuredListing,
      getMessage,
      history,
      intl,
      location,
      monthlyTimeSlots,
      onFetchTimeSlots,
      onManageDisableScrolling,
      onSelectDate,
      onSelectMethod,
      onSelectReservableSlot,
      onPublishListing,
      onResendVerificationEmail,
      onToggleDeductFee,
      onUpdateListing,
      onUpdateProfile,
      pageProps,
      reservableSlots,
      scrollingDisabled,
      selectedDate,
      selectedMethod,
      selectedReservableSlot,
      unitType,
      timezone,
      validateBookingAccessCode,
    } = this.props;
    const isBookingModalOpen = !!parse(location.search).book;

    return (
      <Page {...pageProps} scrollingDisabled={scrollingDisabled} title={getMessage('pageTitle')}>
        <LayoutSingleColumn>
          <LayoutWrapperTopbar>
            <TopbarContainer currentPage="NotificationDetailPage" />
          </LayoutWrapperTopbar>
          <LayoutWrapperMain>
            <div className={css.root}>
              <section className={css.content}>{this.dynamicContent}</section>
              <aside className={css.profile}>
                <div className={css.card}>
                  {this.card}
                  {this.showBookingDateAndSlotPicker && canRenderCalendar ? (
                    <ModalInMobile
                      containerClassName={css.modalContainer}
                      id="NotificationBookingDateAndSlotPickerInModal"
                      isModalOpenOnMobile={isBookingModalOpen}
                      onClose={() => closeBookModal(history, location)}
                      showAsModalMaxWidth={MODAL_BREAKPOINT}
                      onManageDisableScrolling={onManageDisableScrolling}
                    >
                      <div className={css.bookingForm}>
                        <h3 className={css.sideBarHeader}>{getMessage('bookAMeeting')}</h3>
                        <BookingDateAndSlotPicker
                          authorDisplayName={ensuredListing.attributes.title}
                          currentDateReservableSlots={currentDateReservableSlots}
                          currentListing={ensuredListing}
                          currentUser={currentUser}
                          deductFee={deductFee}
                          getEvent={getEvent}
                          history={history}
                          intl={intl}
                          isAccessCodeRequiredForSlot={isAccessCodeRequiredForSlot}
                          isOwnListing={false}
                          listing={ensuredListing}
                          listingId={ensuredListing.id}
                          monthlyTimeSlots={monthlyTimeSlots}
                          onFetchTimeSlots={onFetchTimeSlots}
                          onManageDisableScrolling={onManageDisableScrolling}
                          onPublishListing={onPublishListing}
                          onResendVerificationEmail={onResendVerificationEmail}
                          onSelectDate={onSelectDate}
                          onSelectReservableSlot={onSelectReservableSlot}
                          onSelectMethod={onSelectMethod}
                          onToggleDeductFee={onToggleDeductFee}
                          onSubmit={this.handleBookingSubmit}
                          onUpdateListing={onUpdateListing}
                          onUpdateProfile={onUpdateProfile}
                          price={ensuredListing.attributes.price}
                          reservableSlots={reservableSlots}
                          selectedDate={selectedDate}
                          selectedMethod={selectedMethod}
                          selectedReservableSlot={selectedReservableSlot}
                          submitButtonWrapperClassName={css.submitButtonWrapper}
                          timezone={timezone}
                          unitType={unitType}
                          validateBookingAccessCode={validateBookingAccessCode}
                        />
                      </div>
                    </ModalInMobile>
                  ) : ensuredListing.id ? (
                    <div className={css.profileLinkContainer}>
                      <NamedLink
                        className={css.profileLink}
                        name={'ListingPage'}
                        params={{
                          id: ensuredListing.id.uuid,
                          slug: createSlug(ensuredListing.attributes.title),
                        }}
                      >
                        <span className={css.profileLinkLabel}>{getMessage('viewProfile')}</span>
                      </NamedLink>
                    </div>
                  ) : null}
                </div>
              </aside>
            </div>
          </LayoutWrapperMain>
          <LayoutWrapperFooter>
            <Footer />
          </LayoutWrapperFooter>
        </LayoutSingleColumn>
      </Page>
    );
  }
}

NotificationDetailPageComponent.propTypes = {
  callSetInitialValues: func.isRequired,
  canRenderCalendar: bool,
  currentUser: propTypes.currentUser,
  history: shape({
    push: func.isRequired,
  }).isRequired,
  intl: intlShape.isRequired,
  notification: propTypes.notification,
  scrollingDisabled: bool.isRequired,
  showNotificationInProgress: bool,
  timezone: string,
  unitType: propTypes.bookingUnitType,
};

NotificationDetailPageComponent.defaultProps = {
  canRenderCalendar: false,
  notification: null,
  showNotificationInProgress: false,
  unitType: config.bookingUnitType,
};

const mapStateToProps = (state) => {
  const { currentUser, timezone } = state.user;
  const { notification, showNotificationInProgress } = state.notifications;
  const { showListingInProgress } = state.Listings;
  const {
    deductFee,
    fetchMonthlyTimeSlotsComplete,
    monthlyTimeSlots,
    selectedDate,
    selectedReservableSlot,
    selectedMethod,
  } = state.ListingPage;
  const { events } = state.Events;

  const ensuredNotification = ensureNotification(notification);
  const ensuredCurrentUser = ensureCurrentUser(currentUser);
  const bookingPanelProps = {
    canRenderCalendar: false,
    currentDateReservableSlots: [],
    selectedDate: null,
    selectedReservableSlot: null,
    selectedMethod: null,
    reservableSlots: [],
  };
  const ensuredListing = ensureListing({});

  if (ensuredCurrentUser.id && ensuredNotification.customer.id && !showListingInProgress) {
    const ensuredOtherUser =
      ensuredNotification.customer.id.uuid === ensuredCurrentUser.id.uuid
        ? ensuredNotification.provider
        : ensuredNotification.customer;
    const { listingId: id } = ensuredOtherUser.attributes.profile.publicData;

    const populatedListing = ensureListing(getListingFromState(id, state));
    Object.assign(ensuredListing, populatedListing);

    // Monthly time slots are not always needed and thus not always fetched
    if (fetchMonthlyTimeSlotsComplete) {
      const populatedBookingPanelProps = getBookingPanelPropsForListing(
        populatedListing,
        events,
        monthlyTimeSlots,
        selectedDate,
        selectedReservableSlot,
        selectedMethod,
        timezone
      );
      Object.assign(bookingPanelProps, populatedBookingPanelProps);
    }
  }

  console.log('MT', monthlyTimeSlots);

  return {
    ...bookingPanelProps,
    currentUser,
    deductFee,
    ensuredListing,
    getListing: (id) => getListingFromState(id, state),
    monthlyTimeSlots,
    notification,
    scrollingDisabled: isScrollingDisabled(state),
    showNotificationInProgress,
    timezone,
  };
};

const mapDispatchToProps = (dispatch) => ({
  callSetInitialValues: (setInitialValues, values) => dispatch(setInitialValues(values)),
  onFetchTimeSlots: (listingId, start, end, timeZone) =>
    dispatch(fetchTimeSlots(listingId, start, end, timeZone)),
  onInitializeCardPaymentData: () => dispatch(initializeCardPaymentData()),
  onManageDisableScrolling: (componentId, disableScrolling) =>
    dispatch(manageDisableScrolling(componentId, disableScrolling)),
  onPublishListing: (listingId) => dispatch(requestPublishListingDraft(listingId)),
  onResendVerificationEmail: () => dispatch(sendVerificationEmail()),
  onSelectDate: (date) => dispatch(selectDate(date)),
  onSelectReservableSlot: (reservableSlot) => dispatch(selectReservableSlot(reservableSlot)),
  onSelectMethod: (method) => dispatch(selectMethod(method)),
  onToggleDeductFee: () => dispatch(toggleDeductFee()),
  onUpdateProfile: (data) => dispatch(updateProfile(data)),
  onUpdateListing: (data) => dispatch(requestUpdateListing(data)),
});

const withLocalizedMessages = (component) => {
  return withMessages(component, 'NotificationDetailPage');
};

const NotificationDetailPage = compose(
  withLocalizedMessages,
  withRouter,
  injectIntl,
  connect(mapStateToProps, mapDispatchToProps)
)(NotificationDetailPageComponent);

NotificationDetailPage.loadData = loadData;
export default NotificationDetailPage;
