const MINI_APP_BASE_URL = process.env.REACT_APP_DONATION_OFFER_URL;

/**
 * Parses url pointing to new donation offer mini app offer
 * @param {string} offer_id
 * @param {string|undefined} custom_slug
 * @param {string} tenant_id
 * @returns {string} url to donation offer mini app
 */
export function getDonationOfferMiniAppUrl(offer_id, custom_slug, tenant_id) {
  if (typeof offer_id !== 'string' || offer_id.length === 0) {
    console.warn('Cannot parse url without id');
    return undefined;
  }

  if (typeof tenant_id !== 'string' || tenant_id.length === 0) {
    console.warn('Cannot parse url without tenant id');
    return undefined;
  }

  const tenantSlug = tenant_id.replace(/\.com$/, '');

  const offerSlug = custom_slug || offer_id;

  return `${MINI_APP_BASE_URL}/${tenantSlug}/${offerSlug}`;
}
