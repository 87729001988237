/**
 *  TopbarMobileMenu prints the menu content for authenticated user or
 * shows login actions for those who are not authenticated.
 */
import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import { ACCOUNT_SETTINGS_PAGES } from '../../routeConfiguration';
import { propTypes } from '../../util/types';
import { ensureCurrentUser, isNonprofitUser } from '../../util/data';
import { createSlug } from '../../util/urlHelpers';
import {
  AvatarMedium,
  ExternalLink,
  InlineTextButton,
  NamedLink,
  NotificationBadge,
  IconSocialMediaFacebook,
  IconSocialMediaTwitter,
  IconSocialMediaInstagram,
  IconSocialMediaLinkedIn,
  IconSocialMediaYoutube,
} from '../../components';
import css from './TopbarMobileMenu.css';
import config from '../../config';
import { PERSONAL_INFO } from '../MyProfileWizard/MyProfileWizard';
import { NONPROFIT_INFO } from '../MyNonprofitWizard/constants';

const TopbarMobileMenu = (props) => {
  const {
    isAuthenticated,
    currentPage,
    currentUserHasListings,
    currentUser,
    onLogout,
    notificationCount,
  } = props;

  const user = ensureCurrentUser(currentUser);

  const emailVerified = user.attributes.emailVerified;

  const inboxCountBadge =
    notificationCount > 0 ? (
      <NotificationBadge className={css.notificationBadge} count={notificationCount} />
    ) : null;

  const notificationCountBadge =
    notificationCount > 0 ? (
      <NotificationBadge className={css.notificationBadge} count={notificationCount} />
    ) : null;

  const displayName = user.attributes.profile.firstName;
  const { listingId } = user.attributes.profile.publicData;
  const onboardingCompleted =
    user.attributes.profile.privateData.onboardingCompleted ||
    user.attributes.profile.publicData.onboardingCompleted;
  const isNonprofit = isNonprofitUser(user);

  const currentPageClass = (page) => {
    const isAccountSettingsPage =
      page === 'AccountSettingsPage' && ACCOUNT_SETTINGS_PAGES.includes(currentPage);
    return currentPage === page || isAccountSettingsPage ? css.currentPage : null;
  };

  const topSection = (
    <div className={classNames(css.section, css.topSection)}>
      <div className={css.greetingSection}>
        <p className={css.greetingText}>
          <FormattedMessage id="TopbarMobileMenu.greeting" values={{ displayName }} />
        </p>
        {user.id && onboardingCompleted && listingId && (
          <NamedLink
            className={classNames(css.viewProfileLink)}
            name="ListingPage"
            params={{
              id: listingId,
              slug: createSlug(
                `${user.attributes.profile.firstName} ${user.attributes.profile.lastName}`
              ),
            }}
          >
            <FormattedMessage id="TopbarMobileMenu.viewProfileLink" />
          </NamedLink>
        )}
      </div>
      <AvatarMedium className={css.avatar} user={currentUser} />
    </div>
  );

  const loggedInSection = (
    <div className={css.section}>
      {isNonprofit ? (
        <NamedLink
          className={classNames(css.navigationLink, currentPageClass('MyAvailabilityPage'))}
          name="MyAvailabilityPage"
          params={{ tab: NONPROFIT_INFO }}
        >
          <FormattedMessage id="TopbarMobileMenu.myNonprofitLink" />
        </NamedLink>
      ) : (
        <>
          {emailVerified && (
            <>

              {user.id && (
                <NamedLink
                  className={classNames(
                    css.navigationLink,
                    css.notificationsLink,
                    currentPageClass('OutreachOffersPage')
                  )}
                  name="OutreachOffersPage"
                  params={{
                    tab: 'overview',
                  }}
                >
                  <FormattedMessage id="TopbarMobileMenu.donationOffersLink" />
                </NamedLink>
              )}
            </>
          )}
          <NamedLink
            className={classNames(css.navigationLink, currentPageClass('MyProfilePage'))}
            name="MyProfilePage"
            params={{ tab: PERSONAL_INFO }}
          >
            <FormattedMessage id="TopbarMobileMenu.profileSettingsLink" />
          </NamedLink>
        </>
      )}
      <NamedLink
        className={classNames(css.navigationLink, currentPageClass('AccountSettingsPage'))}
        name="AccountSettingsPage"
      >
        <FormattedMessage id="TopbarMobileMenu.accountSettingsLink" />
      </NamedLink>
    </div>
  );

  const siteLinkSection = (
    <div className={css.section}>
      <NamedLink
        className={classNames(css.navigationLink, currentPageClass('LandingPage'))}
        name="LandingPage"
      >
        <FormattedMessage id="TopbarMobileMenu.homePageLink" />
      </NamedLink>

      <ExternalLink
        className={classNames(css.navigationLink, currentPageClass('AboutPage'))}
        href={`${config.custom.companySiteUrl}`}
      >
        <FormattedMessage id="TopbarMobileMenu.aboutPageLink" />
      </ExternalLink>


      <NamedLink
        className={classNames(css.navigationLink, currentPageClass('NonprofitsLandingPage'))}
        name="NonprofitsLandingPage"
      >
        <FormattedMessage id="TopbarMobileMenu.nonprofitsLink" />
      </NamedLink>

      <ExternalLink
        className={classNames(css.navigationLink, currentPageClass('ContactPage'))}
        href={`${config.custom.companySiteUrl}/contact`} >
        <FormattedMessage id="TopbarMobileMenu.contactPageLink" />
      </ExternalLink>

    </div>
  );

  const sigunupSection = (
    <div className={css.section}>
      <h3 style={{ fontWeight: 500, fontSize: 16, lineHeight: '18.6px', marginTop: 0 }}>
        <FormattedMessage id="TopbarMobileMenu.loginLink" />
      </h3>

      <a href={`${process.env.REACT_APP_PLATFORM_URL}/log-in`} className={css.navigationLink}>
        <div className={css['login-link-stack']}>
          <span>Givsly Platform</span>
          <span className={css['login-link-small']}>New</span>
        </div>
      </a>

      <NamedLink name="LoginPage" className={css.navigationLink}>
        <div className={css['login-link-stack']}>
          <span>Givsly Platform</span>
          <span className={css['login-link-small']}>Legacy & donation processing</span>
        </div>
      </NamedLink>
    </div>
  );

  const menuSolutions = (
    <div className={css.section}>
      <h3 style={{ fontWeight: 500, fontSize: 16, lineHeight: '18.6px', marginTop: 0 }}>
        <FormattedMessage id="TopbarMobileMenu.solutions" />
      </h3>

      <ExternalLink
          className={css.navigationLink}
          href={`${config.custom.companySiteUrl}/advertising`}
        >
        <div class={css['login-link-stack']}>
          <span className={css.menuItemBorder} />
          <span>Responsible Advertising</span>
          <span className={css['login-link-small']}>Ad performance through shared values</span>
        </div>
      </ExternalLink>

      <ExternalLink
          className={css.navigationLink}
          href={`${config.custom.companySiteUrl}/marketing`}
        >
        <div class={css['login-link-stack']}>
          <span className={css.menuItemBorder} />
          <span>Responsible Marketing</span>
          <span className={css['login-link-small']}>Demand generation that gives back</span>
        </div>
      </ExternalLink>

      <ExternalLink
          className={css.navigationLink}
          href={`${config.custom.companySiteUrl}/experiences`}
        >
        <div class={css['login-link-stack']}>
          <span className={css.menuItemBorder} />
          <span>Responsible Experiences</span>
          <span className={css['login-link-small']}>Client events with more meaning</span>
        </div>
      </ExternalLink>

    </div>
  );

  // @todo: add correct links
  const socialMediaRow = (
    <ul className={css.inlineSocialMediaList}>
      <li>
        <a href={config.siteFacebookPage} className={css.iconLink} title="Facebook Page">
          <IconSocialMediaFacebook />
        </a>
      </li>
      <li>
        <a href={config.siteTwitterPage} className={css.iconLink} title="Twitter Profile">
          <IconSocialMediaTwitter />
        </a>
      </li>
      <li>
        <a href={config.siteInstagramPage} className={css.iconLink} title="Instagram Profile">
          <IconSocialMediaInstagram />
        </a>
      </li>
      <li>
        <a href={config.siteLinkedInPage} className={css.iconLink} title="Linkedin Profile">
          <IconSocialMediaLinkedIn />
        </a>
      </li>
      <li>
        <a href={config.siteYoutubePage} className={css.iconLink} title="Youtube Profile">
          <IconSocialMediaYoutube />
        </a>
      </li>
    </ul>
  );

  const miscLinkSection = (
    <div className={css.section}>
      {socialMediaRow}
      <NamedLink name="PrivacyPolicyPage" className={css.miscLink}>
        <FormattedMessage id="TopbarMobileMenu.webPrivacyPolicy" />
      </NamedLink>
      <NamedLink name="PlatformPrivacyPolicyPage" className={css.miscLink}>
        <FormattedMessage id="TopbarMobileMenu.platformPrivacyPolicy" />
      </NamedLink>
      <NamedLink name="TermsOfServicePage" className={css.miscLink}>
        <FormattedMessage id="TopbarMobileMenu.terms" />
      </NamedLink>
    </div>
  );

  return (
    <div className={css.root}>
      <div className={css.content}>
        {isAuthenticated && topSection}
        {isAuthenticated && loggedInSection}
        {siteLinkSection}
        {!isAuthenticated && menuSolutions}



        {!isAuthenticated && sigunupSection}
        {miscLinkSection}
      </div>
      {isAuthenticated && (
        <div className={css.footer}>
          <InlineTextButton onClick={onLogout} className={css.logoutButton}>
            <FormattedMessage id="TopbarMobileMenu.logoutLink" />
          </InlineTextButton>
        </div>
      )}
    </div>
  );
};

TopbarMobileMenu.defaultProps = { currentUser: null, notificationCount: 0, currentPage: null };

const { bool, func, number, string } = PropTypes;

TopbarMobileMenu.propTypes = {
  isAuthenticated: bool.isRequired,
  currentUserHasListings: bool.isRequired,
  currentUser: propTypes.currentUser,
  currentPage: string,
  notificationCount: number,
  onLogout: func.isRequired,
};

export default TopbarMobileMenu;
