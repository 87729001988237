import React from 'react';
import { FormattedMessage, intlShape } from '../../util/reactIntl';
import classNames from 'classnames';
import { createSlug, stringify } from '../../util/urlHelpers';
import { NamedLink } from '../../components';
import { bool, number, string } from 'prop-types';
import css from './TransactionPanel.css';
import { propTypes } from '../../util/types';
import { formatMoney } from '../../util/currency';

export const HEADING_ENQUIRED = 'enquired';
export const HEADING_PENDING = 'pending';
export const HEADING_PROPOSED = 'proposed';
export const HEADING_PAYMENT_PENDING = 'pending-payment';
export const HEADING_PAYMENT_EXPIRED = 'payment-expired';
export const HEADING_REQUESTED = 'requested';
export const HEADING_ACCEPTED = 'accepted';
export const HEADING_DECLINED = 'declined';
export const HEADING_EXPIRED = 'expired';
export const HEADING_CUSTOMER_CANCELED = 'customer-canceled';
export const HEADING_PROVIDER_CANCELED = 'provider-canceled';
export const HEADING_DELIVERED = 'delivered';

const createListingLink = (listingId, label, listingDeleted, searchParams = {}, className = '') => {
  if (!listingDeleted) {
    const params = { id: listingId, slug: createSlug(label) };
    const to = { search: stringify(searchParams) };
    return (
      <NamedLink className={className} name="ListingPage" params={params} to={to}>
        {label}
      </NamedLink>
    );
  } else {
    return <FormattedMessage id="TransactionPanel.deletedListingOrderTitle" />;
  }
};

const ListingDeletedInfoMaybe = (props) => {
  return props.listingDeleted ? (
    <p className={css.transactionInfoMessage}>
      <FormattedMessage id="TransactionPanel.messageDeletedListing" />
    </p>
  ) : null;
};

const HeadingCustomer = (props) => {
  const { className, id, values, listingDeleted, children } = props;
  return (
    <React.Fragment>
      <h1 className={className}>
        <span className={css.mainTitle}>
          <FormattedMessage id={id} values={values} />
        </span>
      </h1>
      {children}
      <ListingDeletedInfoMaybe listingDeleted={listingDeleted} />
    </React.Fragment>
  );
};

const HeadingCustomerWithSubtitle = (props) => {
  const { className, id, values, subtitleId, subtitleValues, children, listingDeleted } = props;
  return (
    <React.Fragment>
      <h1 className={className}>
        <span className={css.mainTitle}>
          <FormattedMessage id={id} values={values} />
        </span>
        <FormattedMessage id={subtitleId} values={subtitleValues} />
      </h1>
      {children}
      <ListingDeletedInfoMaybe listingDeleted={listingDeleted} />
    </React.Fragment>
  );
};

const CustomerBannedInfoMaybe = (props) => {
  return props.isCustomerBanned ? (
    <p className={css.transactionInfoMessage}>
      <FormattedMessage id="TransactionPanel.customerBannedStatus" />
    </p>
  ) : null;
};

const HeadingProvider = (props) => {
  const { className, id, values, isCustomerBanned, children } = props;
  return (
    <React.Fragment>
      <h1 className={className}>
        <span className={css.mainTitle}>
          <FormattedMessage id={id} values={values} />
        </span>
      </h1>
      {children}
      <CustomerBannedInfoMaybe isCustomerBanned={isCustomerBanned} />
    </React.Fragment>
  );
};

// Functional component as a helper to choose and show Order or Sale heading info:
// title, subtitle, and message
const PanelHeading = (props) => {
  const {
    className,
    donation,
    duration,
    rootClassName,
    panelHeadingState,
    customerName,
    intl,
    isProposal,
    providerName,
    listingId,
    listingTitle,
    listingDeleted,
    isCustomerBanned,
    nonprofitName,
  } = props;

  const isCustomer = props.transactionRole === 'customer';

  const defaultRootClassName = isCustomer ? css.headingOrder : css.headingSale;
  const titleClasses = classNames(rootClassName || defaultRootClassName, className);
  const listingLink = createListingLink(listingId, listingTitle, listingDeleted);
  const labelPrefix = !isCustomer
    ? isProposal
      ? 'provider.proposal'
      : 'sale'
    : isProposal
    ? 'customer.proposal'
    : 'order';

  switch (panelHeadingState) {
    case HEADING_ENQUIRED:
      return isCustomer ? (
        <HeadingCustomer
          className={titleClasses}
          id="TransactionPanel.orderEnquiredTitle"
          values={{ listingLink }}
          listingDeleted={listingDeleted}
        />
      ) : (
        <HeadingProvider
          className={titleClasses}
          id="TransactionPanel.saleEnquiredTitle"
          values={{ customerName, listingLink }}
          isCustomerBanned={isCustomerBanned}
        />
      );
    case HEADING_PROPOSED:
      return isCustomer ? (
        <HeadingCustomer
          className={titleClasses}
          id="TransactionPanel.orderProposedTitle"
          values={{ customerName, providerName }}
          listingDeleted={listingDeleted}
        >
          {!listingDeleted ? (
            <p className={css.transactionInfoMessage}>
              <FormattedMessage
                id="TransactionPanel.orderPreauthorizedInfo"
                values={{ providerName }}
              />
            </p>
          ) : null}
        </HeadingCustomer>
      ) : (
        <HeadingProvider
          className={titleClasses}
          id="TransactionPanel.saleProposedTitle"
          values={{ customerName, providerName }}
          isCustomerBanned={isCustomerBanned}
        >
          {!listingDeleted ? (
            <p className={classNames(css.transactionInfoMessage, css.bookingProposalInfoMessage)}>
              <FormattedMessage
                id="TransactionPanel.saleProposedInfo"
                values={{
                  nonprofitName,
                  donation:
                    donation !== null && typeof donation === 'object'
                      ? Math.round(donation.amount / 100).toFixed(2)
                      : '0.00',
                }}
              />
            </p>
          ) : null}
        </HeadingProvider>
      );
    case HEADING_PENDING:
      return isCustomer ? (
        <HeadingCustomer
          className={titleClasses}
          id="TransactionPanel.orderPendingTitle"
          values={{ customerName, providerName }}
          listingDeleted={listingDeleted}
        >
          {!listingDeleted ? (
            <p className={css.transactionInfoMessage}>
              <FormattedMessage
                id="TransactionPanel.orderPreauthorizedInfo"
                values={{ providerName }}
              />
            </p>
          ) : null}
        </HeadingCustomer>
      ) : (
        <HeadingProvider
          className={titleClasses}
          id="TransactionPanel.salePendingTitle"
          values={{ providerName, customerName }}
          isCustomerBanned={isCustomerBanned}
        />
      );
    case HEADING_PAYMENT_PENDING:
      return isCustomer ? (
        <HeadingCustomer
          className={titleClasses}
          id="TransactionPanel.orderPaymentPendingTitle"
          values={{ listingLink }}
          listingDeleted={listingDeleted}
        />
      ) : (
        <HeadingProvider
          className={titleClasses}
          id="TransactionPanel.salePaymentPendingTitle"
          values={{ customerName, listingLink }}
          isCustomerBanned={isCustomerBanned}
        >
          <p className={css.transactionInfoMessage}>
            <FormattedMessage
              id="TransactionPanel.salePaymentPendingInfo"
              values={{ customerName }}
            />
          </p>
        </HeadingProvider>
      );
    case HEADING_PAYMENT_EXPIRED:
      return isCustomer ? (
        <HeadingCustomer
          className={titleClasses}
          id="TransactionPanel.orderPaymentExpiredTitle"
          values={{ listingLink }}
          listingDeleted={listingDeleted}
        />
      ) : (
        <HeadingProvider
          className={titleClasses}
          id="TransactionPanel.salePaymentExpiredTitle"
          values={{ customerName, listingLink }}
          isCustomerBanned={isCustomerBanned}
        />
      );
    case HEADING_REQUESTED:
      return isCustomer ? (
        <HeadingCustomerWithSubtitle
          className={titleClasses}
          id="TransactionPanel.orderPreauthorizedTitle"
          values={{ customerName }}
          subtitleId="TransactionPanel.orderPreauthorizedSubtitle"
          subtitleValues={{ listingLink }}
        >
          {!listingDeleted ? (
            <p className={css.transactionInfoMessage}>
              <FormattedMessage
                id="TransactionPanel.orderPreauthorizedInfo"
                values={{ providerName }}
              />
            </p>
          ) : null}
        </HeadingCustomerWithSubtitle>
      ) : (
        <HeadingProvider
          className={titleClasses}
          id="TransactionPanel.saleRequestedTitle"
          values={{ customerName, listingLink }}
        >
          {!isCustomerBanned ? (
            <p className={titleClasses}>
              <FormattedMessage id="TransactionPanel.saleRequestedInfo" values={{ customerName }} />
            </p>
          ) : null}
        </HeadingProvider>
      );
    case HEADING_ACCEPTED:
      return isCustomer ? (
        <HeadingCustomer
          className={titleClasses}
          id={`TransactionPanel.${labelPrefix}Accepted.title`}
          values={{ customerName, providerName }}
        >
          {donation && duration ? (
            <p className={css.transactionInfoMessage}>
              <FormattedMessage
                id={`TransactionPanel.${labelPrefix}Accepted.description`}
                values={{
                  customerName,
                  nonprofitName,
                  donation: formatMoney(intl, donation),
                  duration,
                  providerName,
                }}
              />
            </p>
          ) : null}
        </HeadingCustomer>
      ) : (
        <HeadingProvider
          className={titleClasses}
          id={`TransactionPanel.${labelPrefix}Accepted.title`}
          values={{ customerName, listingLink }}
        >
          {donation && duration ? (
            <p className={css.transactionInfoMessage}>
              <FormattedMessage
                id={`TransactionPanel.${labelPrefix}Accepted.description`}
                values={{
                  customerName,
                  nonprofitName,
                  donation: formatMoney(intl, donation),
                  duration,
                }}
              />
            </p>
          ) : null}
        </HeadingProvider>
      );
    case HEADING_DECLINED:
      return isCustomer ? (
        <HeadingCustomer
          className={titleClasses}
          id="TransactionPanel.orderDeclinedTitle"
          values={{ customerName, providerName }}
        />
      ) : (
        <HeadingProvider
          className={titleClasses}
          id="TransactionPanel.saleDeclinedTitle"
          values={{ customerName }}
          isCustomerBanned={isCustomerBanned}
        />
      );
    case HEADING_CUSTOMER_CANCELED:
      return isCustomer ? (
        <>
          <HeadingCustomer
            className={titleClasses}
            id="TransactionPanel.orderCustomerCancelledTitle"
            values={{ customerName, providerName }}
          />
        </>
      ) : (
        <HeadingProvider
          className={titleClasses}
          id="TransactionPanel.saleCustomerCancelledTitle"
          values={{ customerName }}
        />
      );
    case HEADING_PROVIDER_CANCELED:
      return isCustomer ? (
        <>
          <HeadingCustomer
            className={titleClasses}
            id="TransactionPanel.orderProviderCancelledTitle"
            values={{ customerName, providerName }}
          />
        </>
      ) : (
        <HeadingProvider
          className={titleClasses}
          id="TransactionPanel.saleProviderCancelledTitle"
          values={{ customerName }}
        />
      );
    case HEADING_EXPIRED:
      return isCustomer ? (
        <HeadingProvider
          className={titleClasses}
          id="TransactionPanel.orderExpired.title"
          values={{ customerName, providerName }}
        >
          <p className={css.transactionInfoMessage}>
            <FormattedMessage
              id="TransactionPanel.orderExpired.description"
              values={{ providerName }}
            />
          </p>
        </HeadingProvider>
      ) : (
        <HeadingProvider
          className={titleClasses}
          id="TransactionPanel.saleExpired.title"
          values={{ customerName }}
        >
          <p className={css.transactionInfoMessage}>
            <FormattedMessage
              id="TransactionPanel.saleExpired.description"
              values={{ customerName }}
            />
          </p>
        </HeadingProvider>
      );
    case HEADING_DELIVERED:
      return isCustomer ? (
        <HeadingCustomer
          className={titleClasses}
          id="TransactionPanel.orderDelivered.title"
          values={{ providerName }}
          isCustomerBanned={isCustomerBanned}
        >
          {donation ? (
            <p className={css.transactionInfoMessage}>
              <FormattedMessage
                id="TransactionPanel.orderDelivered.description"
                values={{ customerName, donation: formatMoney(intl, donation), nonprofitName }}
              />
            </p>
          ) : null}
        </HeadingCustomer>
      ) : (
        <HeadingProvider
          className={titleClasses}
          id="TransactionPanel.saleDelivered.title"
          values={{ customerName, listingLink }}
          isCustomerBanned={isCustomerBanned}
        >
          {donation ? (
            <p className={css.transactionInfoMessage}>
              <FormattedMessage
                id="TransactionPanel.saleDelivered.description"
                values={{ customerName, donation: formatMoney(intl, donation), nonprofitName }}
              />
            </p>
          ) : null}
        </HeadingProvider>
      );
    default:
      console.warn('Unknown state given to panel heading.');
      return null;
  }
};

PanelHeading.propTypes = {
  donation: propTypes.money,
  duration: number,
  intl: intlShape.isRequired,
  isProposal: bool,
  nonprofitName: string,
};

PanelHeading.defaultProps = {
  donation: null,
  duration: 0,
  isProposal: false,
  nonprofitName: null,
};

export default PanelHeading;
