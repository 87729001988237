import React, { useCallback, useState, useEffect } from 'react';
import css from '../PartnershipsPage.css';
import { FormattedMessage } from '../../../util/reactIntl';
import { Button, FieldSelect, FieldTextInput } from '../../../components';
import { useMessages } from '../../../util/localization';
import { fetchCountries, fetchStates, searchIab } from '../../../util/outreach';
import FieldCheckboxComponent from '../../../components/FieldCheckbox/FieldCheckbox';
import { Field } from 'react-final-form';
import Select from 'react-select';
import { BRAND_TEMPLATE, EXCLUDED_AREA_TEMPLATE, REACT_SELECT_STYLES } from '../constants';
import RemoveFieldButton from '../../../components/NonprofitPreferencesPanel/RemoveFieldButton';
import LiteButton from '../../../components/LiteButton/LiteButton';
import { ICON_PLUS, STYLE_LIGHT } from '../../../components/LiteButton/constants';

const ExclusionArea = ({ area, index, countries, formRenderProps }) => {
  const getMessage = useMessages('NonprofitPreferencesForm');
  const { form, values, user } = formRenderProps;

  const [states, setStates] = useState([]);
  const [statesError, setStatesError] = useState([]);
  const [statesLoading, setStatesLoading] = useState([]);
  useEffect(() => {
    if (area.country) {
      setStatesLoading(true);
      setStates([]);
      setStatesError(undefined);
      const abortController = new AbortController();
      fetchStates(user, area.country, abortController.signal)
        .then((_states) => setStates(_states))
        .catch((error) => setStatesError(error))
        .finally(() => setStatesLoading(false));

      return () => {
        abortController.abort();
      };
    }
  }, [user, area.country]);

  return (
    <div className={css.excludedAreasRow}>
      <FieldSelect
        className={css.field}
        defaultOptionLabel={getMessage('field.exclusions.areas.country.placeholder')}
        defaultOptionValue=""
        id={`exclusions.areas.${index}.country`}
        label={getMessage('field.exclusions.areas.country.label')}
        name={`exclusions.areas.${index}.country`}
        showDefaultOption={true}
      >
        {countries.map((country) => {
          return (
            <option key={country.name} value={country['alpha-2']}>
              {country.name}
            </option>
          );
        })}
      </FieldSelect>

      <FieldSelect
        className={css.field}
        defaultOptionLabel={getMessage('field.exclusions.areas.state.placeholder')}
        defaultOptionValue=""
        id={`exclusions.areas.${index}.state`}
        label={getMessage('field.exclusions.areas.state.label')}
        name={`exclusions.areas.${index}.state`}
        showDefaultOption={true}
      >
        {states.map((state) => {
          return (
            <option key={state.code} value={state.code}>
              {state.name}
            </option>
          );
        })}
      </FieldSelect>
      <FieldTextInput
        className={css.field}
        id={`exclusions.areas.${index}.city`}
        label={getMessage('field.exclusions.areas.city.label')}
        name={`exclusions.areas.${index}.city`}
        placeholder={getMessage('field.exclusions.areas.city.placeholder')}
        type={'text'}
      />

      <RemoveFieldButton
        onClick={() =>
          form.change(
            'exclusions.areas',
            values.exclusions.areas.filter((_area) => _area !== area)
          )
        }
      />
    </div>
  );
};

const ExclusionsTab = (formRenderProps) => {
  const { form, values, isSubmitting, user, exclusionsComplete } = formRenderProps;
  const getMessage = useMessages('NonprofitPreferencesForm');

  const [iabOptions, setIabOptions] = useState([]);
  const [iabSearchInputValue, setIabSearchInputValue] = useState('');
  const [iabSearchInProgress, setIabSearchInProgress] = useState(false);
  const [iabSearchError, setIabSearchError] = useState();

  const [countries, setCountries] = useState([]);

  useEffect(() => {
    const abortController = new AbortController();
    fetchCountries(user, null, abortController.signal)
      .then((data) => setCountries(data))
      .catch((error) => console.warn(error));

    return () => {
      abortController.abort();
    };
  }, []);

  const searchIabOptions = useCallback((searchString) => {
    setIabSearchInProgress(true);
    setIabSearchError(undefined);
    setIabOptions([]);
    searchIab(user, searchString)
      .then((result) => setIabOptions(result))
      .catch((error) => setIabSearchError(error))
      .finally(() => setIabSearchInProgress(false));
  }, []);

  useEffect(() => {
    setIabOptions([]);
    if (iabSearchInputValue) {
      setIabSearchInProgress(true);
      const timeout = setTimeout(() => {
        searchIabOptions(iabSearchInputValue);
      }, 1000);

      return () => {
        setIabSearchInProgress(false);
        window.clearTimeout(timeout);
      };
    }
  }, [iabSearchInputValue, searchIabOptions]);

  const hasExclusions =
    values.exclusions.exclude &&
    values.exclusions.exclude.length === 1 &&
    values.exclusions.exclude.includes('Yes');

  return (
    <div className={css.content}>
      <h1 className={css.title}>
        <FormattedMessage id="PartnershipsPage.ExclusionsTab.title" />
      </h1>
      <section className={css.section}>
        <h3 className={css.sectionTitle}>{getMessage('section.exclusions')}</h3>
        <p className={css.small}>{getMessage('paragraph.exclusions')}</p>

        <FieldCheckboxComponent
          id="exclusions.exclude"
          label={getMessage('field.exclusions.exclude.label')}
          name="exclusions.exclude"
          value="Yes"
          kind="checkbox"
        />
      </section>
      {hasExclusions && (
        <>
          <section className={css.section}>
            <Field name="exclusions.industries">
              {(props) => (
                <>
                  <label htmlFor="exclusions.industries">
                    {getMessage('field.exclusions.iab.label')}
                    <span className={css.disclaimer}>{getMessage('optional')}</span>
                  </label>
                  <Select
                    isClearable
                    isSearchable
                    filterOption={() => true}
                    className={css.select}
                    value={props.input.value}
                    id="exclusions.industries"
                    isMulti
                    options={iabOptions}
                    onChange={(value) => form.change(props.input.name, value)}
                    styles={REACT_SELECT_STYLES}
                    getOptionLabel={(option) => option.name}
                    getOptionValue={(option) => option}
                    isLoading={iabSearchInProgress}
                    loadingMessage={() => 'Searching ...'}
                    onInputChange={(value) => setIabSearchInputValue(value)}
                    inputValue={iabSearchInputValue}
                    noOptionsMessage={(data) =>
                      data.inputValue && !iabSearchInProgress
                        ? `No results found for "${data.inputValue}"`
                        : `Type to search for options`
                    }
                  />
                </>
              )}
            </Field>
          </section>
          <section className={css.section}>
            {values.exclusions.brands.map((brand, index) => (
              <div key={index} className={css.inputRow}>
                <br />
                <FieldTextInput
                  className={css.field}
                  id={`exclusions.brands.${index}.name`}
                  label={getMessage('field.exclusions.brands.label')}
                  name={`exclusions.brands.${index}.name`}
                  placeholder={getMessage('field.exclusions.brands.placeholder')}
                  type={'text'}
                  labelSuffix={getMessage('optional')}
                />
                <RemoveFieldButton
                  onClick={() =>
                    form.change(
                      'exclusions.brands',
                      values.exclusions.brands.filter((_brand) => _brand !== brand)
                    )
                  }
                />
              </div>
            ))}
            <br />
            <LiteButton
              icon={ICON_PLUS}
              onClick={() =>
                form.change('exclusions.brands', [
                  ...values.exclusions.brands,
                  { ...BRAND_TEMPLATE },
                ])
              }
              style={STYLE_LIGHT}
            >
              {getMessage('liteButton.new')}
            </LiteButton>
          </section>
          <section className={css.section}>
            {values.exclusions.keywords.map((keyword, index) => (
              <div key={index} className={css.inputRow}>
                <br />
                <FieldTextInput
                  className={css.field}
                  id={`exclusions.keywords.${index}`}
                  label={getMessage('field.exclusions.keywords.label')}
                  name={`exclusions.keywords.${index}`}
                  placeholder={getMessage('field.exclusions.keywords.placeholder')}
                  type={'text'}
                  labelSuffix={getMessage('optional')}
                />
                <RemoveFieldButton
                  onClick={() =>
                    form.change(
                      'exclusions.keywords',
                      values.exclusions.keywords.filter((_keyword) => _keyword !== keyword)
                    )
                  }
                />
              </div>
            ))}
            <br />
            <LiteButton
              icon={ICON_PLUS}
              onClick={() =>
                form.change('exclusions.keywords', [...values.exclusions.keywords, ''])
              }
              style={STYLE_LIGHT}
            >
              {getMessage('liteButton.new')}
            </LiteButton>
          </section>

          <section className={css.section}>
            <p className={css.small}>
              Geographic exclusions<span className={css.optional}>{getMessage('optional')}</span>
            </p>
            {values.exclusions.areas.map((area, index) => (
              <ExclusionArea
                key={index}
                area={area}
                index={index}
                countries={countries}
                formRenderProps={formRenderProps}
              />
            ))}
            <br />
            <LiteButton
              icon={ICON_PLUS}
              onClick={() =>
                form.change('exclusions.areas', [
                  ...values.exclusions.areas,
                  { ...EXCLUDED_AREA_TEMPLATE },
                ])
              }
              style={STYLE_LIGHT}
            >
              {getMessage('liteButton.new')}
            </LiteButton>
          </section>
        </>
      )}

      <section className={[css.section, css.stickySaveButtonSection].join(' ')}>
        <Button className={css.submit} type="submit" inProgress={isSubmitting}>
          {exclusionsComplete ? (
            <FormattedMessage id="PartnershipsPage.ExclusionsTab.submit" />
          ) : (
            <FormattedMessage id="PartnershipsPage.ExclusionsTab.next" />
          )}
        </Button>
      </section>
    </div>
  );
};

export default ExclusionsTab;
