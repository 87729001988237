import React, { useState } from 'react';
import { useMessages } from '../../util/localization';
import css from './OutreachOffers.css';
import classNames from 'classnames';
import Menu from '../Menu/Menu';
import MenuLabel from '../MenuLabel/MenuLabel';
import MenuContent from '../MenuContent/MenuContent';
import MenuItem from '../MenuItem/MenuItem';
// @ts-ignore
import moment from 'moment-timezone';
import { createResourceLocatorString } from '../../util/routes';
import routeConfiguration from '../../routeConfiguration';
import { getDonationOfferMiniAppUrl } from '../../util/getDonationOfferMiniAppUrl';

const DATE_TIME_FORMAT = 'MMM D, h:mm a';

type OutreachOfferRowProps = {
  confirmed: number;
  outreachOffer: OutreachOffer;
  onDeleteOutreachOffer: () => void;
  onDeactivateOutreachOffer: () => void;
  onEditOutreachOffer: () => void;
  onArchiveOutreachOffer: () => void;
  onUnarchiveOutreachOffer: () => void;
  onReactivateOutreachOffer: () => void;
  onReactivateCapReachedOutreachOffer: () => void;
  onViewOfferDetails: () => void;
  timezone: string;
  pendingRequests: number;
};

const OutreachOfferRow = (props: OutreachOfferRowProps) => {
  const [copyLinkText, setCopyLinkText] = useState('Copy offer link');

  const copyToClipboard = (id: string) => {
    const text =
      String(process.env.REACT_APP_CANONICAL_ROOT_URL).replace(/\/$/, '') +
      createResourceLocatorString('OutreachLandingPage', routeConfiguration(), { id }, {});

    const offerType = props.outreachOffer.offerType || 0;
    const miniAppUrl = getDonationOfferMiniAppUrl(
      props.outreachOffer.id,
      props.outreachOffer.customSlug,
      props.outreachOffer.tenantID
    );

    const copyText = offerType !== 0 ? miniAppUrl : text;

    navigator.clipboard.writeText(copyText).then(
      () => {
        console.log('Async: Copying to clipboard was successful!');
      },
      (err) => {
        console.error('Async: Could not copy text: ', err);
      }
    );

    setCopyLinkText('Link copied!');
    setTimeout(() => {
      setCopyLinkText('Copy offer link');
    }, 2000);
  };

  const {
    confirmed,
    onDeactivateOutreachOffer,
    onEditOutreachOffer,
    onReactivateOutreachOffer,
    onReactivateCapReachedOutreachOffer,
    onViewOfferDetails,
    onArchiveOutreachOffer,
    onUnarchiveOutreachOffer,
    timezone,
    pendingRequests,
  } = props;
  const { id, name, donationCap, state, archived, offerType } = props.outreachOffer;
  console.log({ offerType });
  const createdAt = moment(props.outreachOffer.createdAt).tz(timezone);
  const updatedAt = moment(props.outreachOffer.updatedAt).tz(timezone);

  // @todo should be amount of/cap
  const amount = (Number(donationCap) / 100).toFixed(2);
  let capitalizedState = archived ? 'Archived' : state[0].toUpperCase() + state.slice(1);
  let cssState = capitalizedState;
  capitalizedState = capitalizedState.replace('pR', 'p r');

  const menuContents = [
    <MenuItem
      className={classNames(css.menuItem, css.menuItemDetails)}
      key="details"
      onClick={onViewOfferDetails}
    >
      View details
    </MenuItem>,
    <MenuItem
      className={classNames(css.menuItem, css.menuItemCopy)}
      key="copy"
      onClick={() => copyToClipboard(id)}
    >
      {copyLinkText}
    </MenuItem>,
  ];
  state === 'active' &&
    !archived &&
    offerType === 0 &&
    menuContents.push(
      <MenuItem
        className={classNames(css.menuItem, css.menuItemEdit)}
        key="edit"
        onClick={onEditOutreachOffer}
      >
        Edit
      </MenuItem>
    );
  state === 'capReached' &&
    !archived &&
    offerType === 0 &&
    menuContents.push(
      <MenuItem
        className={classNames(css.menuItem, css.menuItemEdit)}
        key="edit"
        onClick={onEditOutreachOffer}
      >
        Edit and reactivate
      </MenuItem>
    );
  state === 'inactive' &&
    !archived &&
    offerType === 0 &&
    menuContents.push(
      <MenuItem
        className={classNames(css.menuItem, css.menuItemEdit)}
        key="edit"
        onClick={onEditOutreachOffer}
      >
        Edit and reactivate
      </MenuItem>
    );
  state === 'inactive' &&
    !archived &&
    menuContents.push(
      <MenuItem
        className={classNames(css.menuItem, css.menuItemDeactivate)}
        key="reactivate"
        onClick={onReactivateOutreachOffer}
      >
        Reactivate
      </MenuItem>
    );
  state === 'active' &&
    !archived &&
    menuContents.push(
      <MenuItem
        className={classNames(css.menuItem, css.menuItemDeactivate)}
        key="deactivate"
        onClick={onDeactivateOutreachOffer}
      >
        Deactivate
      </MenuItem>
    );
  archived &&
    menuContents.push(
      <MenuItem
        className={classNames(css.menuItem, css.menuItemArchive)}
        key="unarchive"
        onClick={onUnarchiveOutreachOffer}
      >
        Unarchive
      </MenuItem>
    );
  !archived &&
    menuContents.push(
      <MenuItem
        className={classNames(css.menuItem, css.menuItemArchive)}
        key="archive"
        onClick={onArchiveOutreachOffer}
      >
        Archive
      </MenuItem>
    );

  return (
    <tr className={css.offer}>
      <td className={css.nameCol}>
        <span className={css.name}>
          {pendingRequests > 0 && <span className={css.indicator}>{pendingRequests}</span>}
          {name}
        </span>
        {createdAt.format(DATE_TIME_FORMAT) != updatedAt.format(DATE_TIME_FORMAT) ? (
          <span className={css.foot}>
            last updated on {updatedAt.format(DATE_TIME_FORMAT)}, created on{' '}
            {createdAt.format(DATE_TIME_FORMAT)}
          </span>
        ) : (
          <span className={css.foot}>created on {createdAt.format(DATE_TIME_FORMAT)}</span>
        )}
      </td>
      <td className={css.donationCol}>
        <span className={css.donation}>$ {amount}</span>
        <span className={css.foot}>Donation cap</span>
      </td>
      <td className={css.donationCol}>
        <span className={css.donation}>{confirmed}</span>
        <span className={css.foot}>confirmed</span>
      </td>
      <td className={css.statusCol}>
        <span className={classNames(css.status, css[`status${cssState}`])}>{capitalizedState}</span>
        {archived && <span className={css.foot}>{updatedAt.format(DATE_TIME_FORMAT)}</span>}
      </td>
      <td className={css.controlsCol}>
        <Menu>
          <MenuLabel className={css.menuLabel}>&hellip;</MenuLabel>
          <MenuContent hideArrow={true} className={css.menuContent}>
            {menuContents}
          </MenuContent>
        </Menu>
      </td>
    </tr>
  );
};

type OutreachOffersProps = {
  onDeleteOutreachOffer: (id: string) => void;
  onDeactivateOutreachOffer: (id: string) => void;
  onEditOutreachOffer: (offer: object) => void;
  onReactivateOutreachOffer: (id: string) => void;
  onReactivateCapReachedOutreachOffer: (id: string) => void;
  onArchiveOutreachOffer: (offer: object) => void;
  onUnarchiveOutreachOffer: (offer: object) => void;
  onViewOfferDetails: (id: string) => void;
  outreachOffers: OutreachOffer[];
  outreachRequests: OutreachRequest[];
  timezone: string;
  archived: Boolean;
};

const OutreachOffers = (props: OutreachOffersProps) => {
  const {
    onDeleteOutreachOffer,
    onDeactivateOutreachOffer,
    onEditOutreachOffer,
    onReactivateOutreachOffer,
    onReactivateCapReachedOutreachOffer,
    onArchiveOutreachOffer,
    onUnarchiveOutreachOffer,
    onViewOfferDetails,
    outreachOffers,
    outreachRequests = [],
    timezone,
    archived,
  } = props;
  const getMessage = useMessages('OutreachOffers');

  return outreachOffers && outreachOffers.length ? (
    <div className={css.root}>
      <section className={css.outreachOffers}>
        <table className={css.offers}>
          <tbody>
            {outreachOffers.map((outreachOffer: OutreachOffer) => {
              // @todo this should be done properly, this way of counting is inefficient and does not scale well
              const matchingOutreachRequests = outreachRequests.filter(
                (request) =>
                  request.outreachOfferId === outreachOffer.id && request.state === 'CONFIRMED'
              );
              const matchingPendingOutreachRequests = outreachRequests.filter(
                (request) =>
                  request.outreachOfferId === outreachOffer.id && request.state === 'PENDING'
              );
              return (
                <OutreachOfferRow
                  pendingRequests={matchingPendingOutreachRequests.length}
                  confirmed={matchingOutreachRequests.length}
                  key={`oo-${outreachOffer.id}`}
                  onDeleteOutreachOffer={() => onDeleteOutreachOffer(outreachOffer.id)}
                  onDeactivateOutreachOffer={() => onDeactivateOutreachOffer(outreachOffer.id)}
                  onEditOutreachOffer={() => onEditOutreachOffer(outreachOffer)}
                  onReactivateOutreachOffer={() => onReactivateOutreachOffer(outreachOffer.id)}
                  onReactivateCapReachedOutreachOffer={() =>
                    onReactivateCapReachedOutreachOffer(outreachOffer.id)
                  }
                  onViewOfferDetails={() => onViewOfferDetails(outreachOffer.id)}
                  onArchiveOutreachOffer={() => onArchiveOutreachOffer(outreachOffer)}
                  onUnarchiveOutreachOffer={() => onUnarchiveOutreachOffer(outreachOffer)}
                  outreachOffer={outreachOffer}
                  timezone={timezone}
                />
              );
            })}
          </tbody>
        </table>
      </section>
    </div>
  ) : null;
};

export default OutreachOffers;
