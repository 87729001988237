import { func, object, string } from 'prop-types';
import React from 'react';
import Button from '../Button/Button';
import css from './SectionImpactHero.css';
import classNames from 'classnames';
import primaryImage from './smiling-woman.jpg';
import secondaryImage from './smiling-man.jpg';

const SectionImpactHero = props => {
  const {
    bodyText,
    className,
    highlightedNonprofit,
    primaryButtonOnClick,
    primaryButtonText,
    secondaryButtonText,
    secondaryButtonOnClick,
    title,
  } = props;

  const {
    impact,
    organizationCategory,
  } = highlightedNonprofit.attributes.publicData;

  const image = impact.pictureId
    ? highlightedNonprofit.images.find(i => i.id.uuid === impact.pictureId)
    : null;

  return (
    <li className={classNames(css.root, className)}>
      <div className={css.contentContainer}>
        <div className={css.bodyContainer}>
          <h2 className={css.title}>{title}</h2>
          <p className={css.bodyText}>{bodyText}</p>
          <div className={css.buttonContainer}>
            <Button
              className={css.primaryButton}
              onClick={primaryButtonOnClick}
            >
              {primaryButtonText}
            </Button>
            <Button
              className={css.secondaryButton}
              onClick={secondaryButtonOnClick}
            >
              {secondaryButtonText}
            </Button>
          </div>
        </div>
        <div className={css.highlightContainer}>
          <div className={css.primaryImageContainer}>
            <img alt="Smiling woman" className={css.image} src={primaryImage} />
          </div>
          <div className={css.secondaryImageContainer}>
            <img alt="Smiling man" className={css.image} src={secondaryImage} />
          </div>
          {highlightedNonprofit && impact.impactUnits ? (
            <div className={css.impactCard}>
              <h3 className={css.name}>{highlightedNonprofit.attributes.title}</h3>
              <h4 className={css.category}>{organizationCategory}</h4>
              {image ? (
                <img
                  alt={highlightedNonprofit.attributes.title}
                  className={css.impactImage}
                  src={image.attributes.variants["square-small2x"].url}
                />
              ) : null}
              <p className={css.impactType}>{impact.impactUnits} {impact.impactType.plural}</p>
              <p className={css.impactDescription}>{impact.description}</p>
            </div>
          ) : null}
        </div>
      </div>
    </li>
  );
}

SectionImpactHero.propTypes = {
  bodyText: string.isRequired,
  className: string,
  highlightedNonprofit: object,
  primaryButtonOnClick: func.isRequired,
  primaryButtonText: string.isRequired,
  secondaryButtonOnClick: func.isRequired,
  secondaryButtonText: string.isRequired,
  title: string.isRequired,
}

SectionImpactHero.defaultProps = {
  className: null,
}

export default SectionImpactHero;
