import React from 'react';
import css from './Notification.css';
import { withMessages } from '../../util/localization';
import { propTypes } from '../../util/types';
import classNames from 'classnames';
import { humanReadableDate } from '../../util/notifications';
import { string } from 'prop-types';

class RequestToMeetCustomerNotification extends React.Component {
  render() {
    const { getMessage, notification, otherUser, timezone } = this.props;
    const providerName = otherUser.attributes.profile.publicData.firstName;

    const {
      keywords,
      preferredMeetingTimes,
      whyYouWantToMeet,
    } = notification.attributes.protectedData;

    return (
      <div className={css.root}>
        <h1 className={css.header}>{getMessage('title', { providerName })}</h1>
        <p className={css.introduction}>{getMessage('introduction', { providerName })}</p>
        <div className={css.messages}>
          <div className={css.myMessage}>
            <div className={css.message}>
              <div className={css.text}>
                {whyYouWantToMeet}
                {keywords ? (
                  <div className={css.keywords}>
                    {keywords.map((keyword) => {
                      return (
                        <span className={css.keyword} key={`keyword-${keyword}`}>
                          {keyword}
                        </span>
                      );
                    })}
                  </div>
                ) : null}
                {preferredMeetingTimes ? (
                  <>
                    <span className={css.subHeader}>{getMessage('preferredMeetingTimes')}</span>
                    {preferredMeetingTimes}
                  </>
                ) : null}
              </div>
              <div className={classNames(css.myMessageTimestamp, css.timestamp)}>
                {humanReadableDate(notification.attributes.createdAt, timezone)}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

RequestToMeetCustomerNotification.propTypes = {
  currentUser: propTypes.currentUser,
  notification: propTypes.notification,
  otherUser: propTypes.user,
  timezone: string.isRequired,
};

export default withMessages(
  RequestToMeetCustomerNotification,
  'Notification.RequestToMeetCustomerNotification'
);
