import React from 'react';

export const InfoIcon = () => (
  <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_1841_13985)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 6C12 9.31371 9.31371 12 6 12C2.68629 12 0 9.31371 0 6C0 2.68629 2.68629 0 6 0C9.31371 0 12 2.68629 12 6ZM7 3.5C7 4.05228 6.55228 4.5 6 4.5C5.44772 4.5 5 4.05228 5 3.5C5 2.94772 5.44772 2.5 6 2.5C6.55228 2.5 7 2.94772 7 3.5ZM6 5.5C6.27614 5.5 6.5 5.72386 6.5 6V8.5H7C7.27614 8.5 7.5 8.72386 7.5 9C7.5 9.27614 7.27614 9.5 7 9.5H5C4.72386 9.5 4.5 9.27614 4.5 9C4.5 8.72386 4.72386 8.5 5 8.5H5.5V6.5H5C4.72386 6.5 4.5 6.27614 4.5 6C4.5 5.72386 4.72386 5.5 5 5.5H6Z"
        fill="currentColor"
      />
    </g>
    <defs>
      <clipPath id="clip0_1841_13985">
        <rect width="12" height="12" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export const LockIcon = () => (
  <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g>
      <path
        d="M1.25 5.75C1.25 4.92157 1.92157 4.25 2.75 4.25H9.25C10.0784 4.25 10.75 4.92157 10.75 5.75V11.5H1.25V5.75Z"
        fill="currentColor"
        stroke="currentColor"
      />
      <path
        d="M3.5 3C3.5 1.61929 4.61929 0.5 6 0.5C7.38071 0.5 8.5 1.61929 8.5 3V4H3.5V3Z"
        stroke="currentColor"
        fill="#fff"
      />
    </g>
  </svg>
);
