import React, { Component } from 'react';
import { bool, func, object, oneOf, shape } from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter, Redirect } from 'react-router-dom';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import config from '../../config';
import { propTypes } from '../../util/types';
import { ensureCurrentUser } from '../../util/data';
import {
  isSignupEmailTakenError,
  isTooManyEmailVerificationRequestsError,
} from '../../util/errors';
import {
  Page,
  NamedRedirect,
  IconEmailSent,
  InlineTextButton,
  LayoutSingleColumn,
  LayoutWrapperTopbar,
  LayoutWrapperMain,
  LayoutWrapperFooter,
  Footer,
  Modal,
  TermsOfService,
} from '../../components';
import { LoginForm, SignupForm } from '../../forms';
import { TopbarContainer } from '../../containers';
import { login, authenticationInProgress, signup } from '../../ducks/Auth.duck';
import { isScrollingDisabled } from '../../ducks/UI.duck';
import { sendVerificationEmail } from '../../ducks/user.duck';
import { manageDisableScrolling } from '../../ducks/UI.duck';

import css from './AuthenticationPage.css';
import { ONBOARDING_PROGRESS, PERSONAL_INFO } from '../../components/OnboardingWizard/constants';
import { NONPROFIT_INFO } from '../../components/MyNonprofitWizard/constants';
import { ONBOARDING_PROGRESS as NONPROFIT_ONBOARDING_PROGRESS } from '../../components/NonprofitOnboardingWizard/constants';
import { parse } from '../../util/urlHelpers';
import { trackEventAction } from '../../ducks/Analytics.duck';
import { fetchGeoipStatus } from '../../ducks/GeoIP.duck';

export class AuthenticationPageComponent extends Component {
  constructor(props) {
    super(props);
    this.state = { tosModalOpen: false };
  }

  trackEvent(payload) {
    if (!this.props.trackEvent) return;

    this.props.trackEvent({
      category: 'AuthenticationPage',
      ...payload,
    });
  }

  render() {
    const {
      authInProgress,
      currentUser,
      intl,
      isAuthenticated,
      location,
      loginError,
      scrollingDisabled,
      signupError,
      submitLogin,
      submitSignup,
      tab,
      sendVerificationEmailInProgress,
      sendVerificationEmailError,
      onResendVerificationEmail,
      onManageDisableScrolling,
      geoip,
      params,
      role,
    } = this.props;

    const isLogin = tab === 'login';
    const isNpoSignup = role === 'nonprofit';
    const from = location.state && location.state.from ? location.state.from : null;
    const organization = params.organization || '';

    const user = ensureCurrentUser(currentUser);
    const onboardingCompleted =
      user.attributes.profile.publicData.onboardingCompleted ||
      user.attributes.profile.privateData.onboardingCompleted;
    const onboardingProgress =
      user.attributes.profile.publicData.onboardingProgress ||
      user.attributes.profile.privateData.onboardingProgress;
    const { isNPO } = user.attributes.profile.publicData;
    const currentUserLoaded = !!user.id;

    // We only want to show the email verification dialog in the signup
    // tab if the user isn't being redirected somewhere else
    // (i.e. `from` is present). We must also check the `emailVerified`
    // flag only when the current user is fully loaded.
    const showEmailVerification = currentUserLoaded && !user.attributes.emailVerified;

    // Already authenticated, redirect away from auth page
    if (isAuthenticated && from) {
      return <Redirect to={from} />;
    } else if (
      isAuthenticated &&
      currentUserLoaded &&
      !showEmailVerification &&
      onboardingCompleted
    ) {
      return <NamedRedirect name="LandingPage" />;
    } else if (
      isAuthenticated &&
      currentUserLoaded &&
      !onboardingCompleted &&
      !showEmailVerification
    ) {
      // Onboarding not completed, redirect to next step in the process
      let tab;
      if (isNPO) {
        tab = Object.keys(NONPROFIT_ONBOARDING_PROGRESS).find(
          (key) => NONPROFIT_ONBOARDING_PROGRESS[key] === onboardingProgress + 100
        );
        return (
          <NamedRedirect name="NonprofitOnboardingPage" params={{ tab: tab || NONPROFIT_INFO }} />
        );
      } else {
        tab = Object.keys(ONBOARDING_PROGRESS).find(
          (key) => ONBOARDING_PROGRESS[key] === onboardingProgress + 10
        );
        return <NamedRedirect name="OnboardingPage" params={{ tab: tab || PERSONAL_INFO }} />;
      }
    }

    const loginErrorMessage = (
      <div className={css.error}>
        <FormattedMessage id="AuthenticationPage.loginFailed" />
      </div>
    );

    const signupErrorMessage = (
      <div className={css.error}>
        {isSignupEmailTakenError(signupError) ? (
          <FormattedMessage id="AuthenticationPage.signupFailedEmailAlreadyTaken" />
        ) : signupError && signupError.name === 'TooManySignupRetriesError' ? (
          signupError.message
        ) : (
          <FormattedMessage id="AuthenticationPage.signupFailed" />
        )}
      </div>
    );

    // eslint-disable-next-line no-confusing-arrow
    const errorMessage = (error, message) => (error ? message : null);
    let loginOrSignupError = isLogin
      ? errorMessage(loginError, loginErrorMessage)
      : errorMessage(signupError, signupErrorMessage);

    const handleSubmitSignup = (values) => {
      const { fname, lname, ...rest } = values;
      const params = { isNpoSignup, firstName: fname.trim(), lastName: lname.trim(), ...rest };

      this.trackEvent({
        action: 'Submit',
        label: 'SignUp',
      });

      submitSignup(params);
    };

    const handleSubmitLogin = (values) => {
      this.trackEvent({
        action: 'Submit',
        label: 'Login',
      });

      submitLogin(values);
    };

    const signUpForm = () => {
      const parameters = parse(location.search);
      return (
        <SignupForm
          className={css.form}
          onSubmit={handleSubmitSignup}
          inProgress={authInProgress}
          token={parameters.t}
          onOpenTermsOfService={() => this.setState({ tosModalOpen: true })}
          geoip={geoip}
          organization={organization}
          isNpoSignup={isNpoSignup}
        />
      );
    };
    const formContent = (
      <>
        <div className={css.content}>
          <h1 className={css.heading}>
            {isLogin ? (
              <FormattedMessage id="AuthenticationPage.loginLinkText" />
            ) : (
              <FormattedMessage id="AuthenticationPage.signupLinkText" />
            )}
          </h1>
          {loginOrSignupError}
          {isLogin ? (
            <LoginForm
              className={css.form}
              onSubmit={handleSubmitLogin}
              inProgress={authInProgress}
            />
          ) : (
            signUpForm()
          )}
        </div>
      </>
    );

    const name = user.attributes.profile.firstName;
    const email = <span className={css.email}>{user.attributes.email}</span>;

    const resendEmailLink = (
      <InlineTextButton rootClassName={css.modalHelperLink} onClick={onResendVerificationEmail}>
        <FormattedMessage id="AuthenticationPage.resendEmailLinkText" />
      </InlineTextButton>
    );

    const resendErrorTranslationId = isTooManyEmailVerificationRequestsError(
      sendVerificationEmailError
    )
      ? 'AuthenticationPage.resendFailedTooManyRequests'
      : 'AuthenticationPage.resendFailed';
    const resendErrorMessage = sendVerificationEmailError ? (
      <p className={css.error}>
        <FormattedMessage id={resendErrorTranslationId} />
      </p>
    ) : null;

    const emailVerificationContent = (
      <div className={css.content}>
        <IconEmailSent className={css.modalIcon} />
        <h1 className={css.modalTitle}>
          <FormattedMessage id="AuthenticationPage.verifyEmailTitle" values={{ name }} />
        </h1>
        <p className={css.modalMessage}>
          <FormattedMessage id="AuthenticationPage.verifyEmailText" values={{ email }} />
        </p>
        {resendErrorMessage}

        <div className={css.bottomWrapper}>
          <p className={css.modalHelperText}>
            {sendVerificationEmailInProgress ? (
              <FormattedMessage id="AuthenticationPage.sendingEmail" />
            ) : (
              <FormattedMessage id="AuthenticationPage.resendEmail" values={{ resendEmailLink }} />
            )}
          </p>
        </div>
      </div>
    );

    const siteTitle = config.siteTitle;
    const schemaTitle = isLogin
      ? intl.formatMessage({ id: 'AuthenticationPage.schemaTitleLogin' }, { siteTitle })
      : intl.formatMessage({ id: 'AuthenticationPage.schemaTitleSignup' }, { siteTitle });

    const topbarClasses = null;

    return (
      <Page
        title={schemaTitle}
        scrollingDisabled={scrollingDisabled}
        schema={{
          '@context': 'http://schema.org',
          '@type': 'WebPage',
          name: schemaTitle,
        }}
      >
        <LayoutSingleColumn>
          <LayoutWrapperTopbar>
            <TopbarContainer className={topbarClasses} />
          </LayoutWrapperTopbar>
          <LayoutWrapperMain className={css.layoutWrapperMain}>
            <div className={css.root}>
              {showEmailVerification ? emailVerificationContent : formContent}
            </div>
            <Modal
              id="AuthenticationPage.tos"
              isOpen={this.state.tosModalOpen}
              onClose={() => this.setState({ tosModalOpen: false })}
              onManageDisableScrolling={onManageDisableScrolling}
            >
              <div className={css.termsWrapper}>
                <h2 className={css.termsHeading}>
                  <FormattedMessage id="AuthenticationPage.termsHeading" />
                </h2>
                <TermsOfService />
              </div>
            </Modal>
          </LayoutWrapperMain>
          <LayoutWrapperFooter>
            <Footer />
          </LayoutWrapperFooter>
        </LayoutSingleColumn>
      </Page>
    );
  }
}

AuthenticationPageComponent.defaultProps = {
  currentUser: null,
  loginError: null,
  signupError: null,
  tab: 'signup',
  sendVerificationEmailError: null,
};

AuthenticationPageComponent.propTypes = {
  authInProgress: bool.isRequired,
  currentUser: propTypes.currentUser,
  isAuthenticated: bool.isRequired,
  loginError: propTypes.error,
  scrollingDisabled: bool.isRequired,
  signupError: propTypes.error,
  submitLogin: func.isRequired,
  submitSignup: func.isRequired,
  tab: oneOf(['login', 'signup']),

  sendVerificationEmailInProgress: bool.isRequired,
  sendVerificationEmailError: propTypes.error,
  onResendVerificationEmail: func.isRequired,
  onManageDisableScrolling: func.isRequired,
  trackEvent: func,

  // from withRouter
  location: shape({ state: object }).isRequired,

  // from injectIntl
  intl: intlShape.isRequired,
};

const mapStateToProps = (state) => {
  const { isAuthenticated, loginError, signupError } = state.Auth;
  const { currentUser, sendVerificationEmailInProgress, sendVerificationEmailError } = state.user;
  return {
    authInProgress: authenticationInProgress(state),
    currentUser,
    isAuthenticated,
    loginError,
    scrollingDisabled: isScrollingDisabled(state),
    signupError,
    sendVerificationEmailInProgress,
    sendVerificationEmailError,
    geoip: state.GeoIP,
  };
};

const mapDispatchToProps = (dispatch) => ({
  submitLogin: ({ email, password }) => dispatch(login(email, password)),
  submitSignup: (params) => dispatch(signup(params)),
  onResendVerificationEmail: () => dispatch(sendVerificationEmail()),
  onManageDisableScrolling: (componentId, disableScrolling) =>
    dispatch(manageDisableScrolling(componentId, disableScrolling)),
  trackEvent: (params) => dispatch(trackEventAction(params)),
});

// Note: it is important that the withRouter HOC is **outside** the
// connect HOC, otherwise React Router won't rerender any Route
// components since connect implements a shouldComponentUpdate
// lifecycle hook.
//
// See: https://github.com/ReactTraining/react-router/issues/4671
const AuthenticationPage = compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  injectIntl
)(AuthenticationPageComponent);

AuthenticationPage.loadData = (params) => (dispatch) => {
  console.log({ params });
  return dispatch(fetchGeoipStatus());
};

export default AuthenticationPage;
