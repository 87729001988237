import { clearCurrentUser, fetchCurrentUser } from './user.duck';
import { storableError } from '../util/errors';
import * as log from '../util/log';
import { requestCreateListingDraft } from './UserListing.duck';
import * as moment from 'moment';
import { logActivity, updateProfile } from './UserProfile.duck';
import { ensureCurrentUser, PROFILE_TYPE_NEW } from '../util/data';
import uuid from 'uuid/v4';
import { getHiddenValuesFromDecodedToken, permittedHiddenFields } from '../util/invitation';
import { identifyAction, resetAction } from './Analytics.duck';
import sharetribe from '@givsly/sharetribe-utils';
import { createSecret } from '../util/credit';
import { syncUserToCognito, validateCognitoUser } from '../util/outreach';

const { ACTIVITY_LOGIN, ACTIVITY_LOGOUT } = sharetribe.user;

const authenticated = (authInfo) => authInfo && authInfo.isAnonymous === false;

// ================ Action types ================ //

export const AUTH_INFO_REQUEST = 'app/Auth/AUTH_INFO_REQUEST';
export const AUTH_INFO_SUCCESS = 'app/Auth/AUTH_INFO_SUCCESS';

export const LOGIN_REQUEST = 'app/Auth/LOGIN_REQUEST';
export const LOGIN_SUCCESS = 'app/Auth/LOGIN_SUCCESS';
export const LOGIN_ERROR = 'app/Auth/LOGIN_ERROR';

export const LOGOUT_REQUEST = 'app/Auth/LOGOUT_REQUEST';
export const LOGOUT_SUCCESS = 'app/Auth/LOGOUT_SUCCESS';
export const LOGOUT_ERROR = 'app/Auth/LOGOUT_ERROR';

export const SIGNUP_REQUEST = 'app/Auth/SIGNUP_REQUEST';
export const SIGNUP_SUCCESS = 'app/Auth/SIGNUP_SUCCESS';
export const SIGNUP_ERROR = 'app/Auth/SIGNUP_ERROR';

// Generic user_logout action that can be handled elsewhere
// E.g. src/reducers.js clears store as a consequence
export const USER_LOGOUT = 'app/USER_LOGOUT';

// ================ Reducer ================ //

const initialState = {
  isAuthenticated: false,

  // auth info
  authInfoLoaded: false,

  // login
  loginError: null,
  loginInProgress: false,

  // logout
  logoutError: null,
  logoutInProgress: false,

  // signup
  signupError: null,
  signupInProgress: false,
};

export default function reducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case AUTH_INFO_REQUEST:
      return state;
    case AUTH_INFO_SUCCESS:
      return { ...state, authInfoLoaded: true, isAuthenticated: authenticated(payload) };

    case LOGIN_REQUEST:
      return {
        ...state,
        loginInProgress: true,
        loginError: null,
        logoutError: null,
        signupError: null,
      };
    case LOGIN_SUCCESS:
      return { ...state, loginInProgress: false, isAuthenticated: true };
    case LOGIN_ERROR:
      return { ...state, loginInProgress: false, loginError: payload };

    case LOGOUT_REQUEST:
      return { ...state, logoutInProgress: true, loginError: null, logoutError: null };
    case LOGOUT_SUCCESS:
      return { ...state, logoutInProgress: false, isAuthenticated: false };
    case LOGOUT_ERROR:
      return { ...state, logoutInProgress: false, logoutError: payload };

    case SIGNUP_REQUEST:
      return { ...state, signupInProgress: true, loginError: null, signupError: null };
    case SIGNUP_SUCCESS:
      return { ...state, signupInProgress: false };
    case SIGNUP_ERROR:
      return { ...state, signupInProgress: false, signupError: payload };

    default:
      return state;
  }
}

// ================ Selectors ================ //

export const authenticationInProgress = (state) => {
  const { loginInProgress, logoutInProgress, signupInProgress } = state.Auth;
  return loginInProgress || logoutInProgress || signupInProgress;
};

// ================ Action creators ================ //

export const authInfoRequest = () => ({ type: AUTH_INFO_REQUEST });
export const authInfoSuccess = (info) => ({ type: AUTH_INFO_SUCCESS, payload: info });

export const loginRequest = () => ({ type: LOGIN_REQUEST });
export const loginSuccess = () => ({ type: LOGIN_SUCCESS });
export const loginError = (error) => ({ type: LOGIN_ERROR, payload: error, error: true });

export const logoutRequest = () => ({ type: LOGOUT_REQUEST });
export const logoutSuccess = () => ({ type: LOGOUT_SUCCESS });
export const logoutError = (error) => ({ type: LOGOUT_ERROR, payload: error, error: true });

export const signupRequest = () => ({ type: SIGNUP_REQUEST });
export const signupSuccess = () => ({ type: SIGNUP_SUCCESS });
export const signupError = (error) => ({ type: SIGNUP_ERROR, payload: error, error: true });

export const userLogout = () => ({ type: USER_LOGOUT });

// ================ Thunks ================ //

export const authInfo = () => (dispatch, getState, sdk) => {
  dispatch(authInfoRequest());
  return sdk
    .authInfo()
    .then((info) => dispatch(authInfoSuccess(info)))
    .catch((e) => {
      // Requesting auth info just reads the token from the token
      // store (i.e. cookies), and should not fail in normal
      // circumstances. If it fails, it's due to a programming
      // error. In that case we mark the operation done and dispatch
      // `null` success action that marks the user as unauthenticated.
      log.error(e, 'auth-info-failed');
      dispatch(authInfoSuccess(null));
    });
};

export const login = (username, password) => (dispatch, getState, sdk) => {
  if (authenticationInProgress(getState())) {
    return Promise.reject(new Error('Login or logout already in progress'));
  }
  dispatch(loginRequest());

  // Note that the thunk does not reject when the login fails, it
  // just dispatches the login error action.
  return sdk
    .login({ username, password })
    .then(() => dispatch(loginSuccess()))
    .then(() => dispatch(fetchCurrentUser({}, true)))
    .then((user) => {
      const ensuredUser = ensureCurrentUser(user);
      if (!ensuredUser || !ensuredUser.id) return null;

      return dispatch(
        identifyAction(ensuredUser.id.uuid, {
          firstName: ensuredUser.attributes.profile.firstName,
          lastName: ensuredUser.attributes.profile.lastName,
        })
      );
    })
    .then((res) => {
      dispatch(logActivity(ACTIVITY_LOGIN));
      return res;
    })
    .catch((e) => dispatch(loginError(storableError(e))));
};

export const logout = () => (dispatch, getState, sdk) => {
  if (authenticationInProgress(getState())) {
    return Promise.reject(new Error('Login or logout already in progress'));
  }
  dispatch(logActivity(ACTIVITY_LOGOUT));
  dispatch(logoutRequest());

  // Note that the thunk does not reject when the logout fails, it
  // just dispatches the logout error action.
  return sdk
    .logout()
    .then(() => {
      // The order of the dispatched actions
      dispatch(logoutSuccess());
      dispatch(clearCurrentUser());
      log.clearUserId();
      dispatch(userLogout());
      dispatch(resetAction());
    })
    .catch((e) => dispatch(logoutError(storableError(e))));
};

export const signup = (params) => (dispatch, getState, sdk) => {
  if (authenticationInProgress(getState())) {
    return Promise.reject(new Error('Login or logout already in progress'));
  }
  dispatch(signupRequest());
  const {
    email,
    password,
    firstName,
    lastName,
    cognitoUsername,
    tenant_id,
    isNpoSignup,
    ...decodedValues
  } = params;

  //features only go to the listings, publicData goes to users and listings
  const { features, ...publicData } = parseParamsToPublicData(decodedValues);

  Object.keys(decodedValues).forEach((key) => {
    if (permittedHiddenFields.indexOf(key) >= 0) {
      delete decodedValues[key];
    }
  });

  // Sign-up source
  let signUpSource = '';
  if (publicData.signUpSource) {
    signUpSource = publicData.signUpSource;
    delete publicData['signUpSource'];
  }

  // Decode JSON data
  if (publicData.eventRoles) {
    try {
      publicData.eventRoles = JSON.parse(publicData.eventRoles);
    } catch (e) {
      console.log('Failed to decode JSON data');
    }
  }

  // Generate a unique calendar token on sign-up
  const calendarToken = uuid();
  const paymentSecret = createSecret();

  const createUserParams = {
    email,
    password,
    firstName,
    lastName,
    publicData: {
      firstName,
      isPendingActivation: true,
      lastName,
      profileType: PROFILE_TYPE_NEW,
      ...publicData,
    },
    protectedData: {
      ...decodedValues,
    },
    privateData: {
      signUpSource,
      calendarToken,
      paymentSecret,
    },
  };

  // bypass cognito check if NPO signup
  const validationPromise = isNpoSignup
    ? Promise.resolve({ usename: null })
    : validateCognitoUser(email, tenant_id);
  // We must login the user if signup succeeds since the API doesn't
  // do that automatically.
  return validationPromise
    .then(async (data) => {
      await sdk.currentUser.create(createUserParams);
      return data.username;
    })
    .then(async (cognitoUsername) => {
      await dispatch(signupSuccess());
      await dispatch(login(email, password));
      if (!getState().Auth.isAuthenticated) {
        // Sometimes, Sharetribe bounces back the login with a 401. In that case we simply try again
        await dispatch(login(email, password));
      }

      if (cognitoUsername) {
        const currentUser = await dispatch(fetchCurrentUser());
        const ensuredCurrentUser = ensureCurrentUser(currentUser);
        await syncUserToCognito(
          ensuredCurrentUser,
          cognitoUsername,
          tenant_id,
          currentUser.id.uuid,
          password
        ).catch((e) => e);
      }

      if (getState().Auth.isAuthenticated) {
        const sdkResponse = await dispatch(
          requestCreateListingDraft({
            availabilityPlan: {
              type: 'availability-plan/time',
              timezone: moment.tz.guess(),
              entries: [],
            },
            title: `${firstName} ${lastName}`,
            publicData: {
              firstName,
              isPendingActivation: true,
              lastName,
              listingType: PROFILE_TYPE_NEW,
              features,
              ...publicData,
            },
            privateData: {
              calendarToken,
              signUpSource,
            },
          })
        );
        await dispatch(
          updateProfile({
            publicData: {
              isPitcher: true,
              listingId: sharetribe.listing.ensureListing(sdkResponse.data.data).id.uuid,
            },
          })
        );
      }
    })
    .catch((e) => {
      dispatch(signupError(storableError(e)));
      log.error(e, 'signup-failed', {
        email: params.email,
        firstName: params.firstName,
        lastName: params.lastName,
      });
    });
};

/**
 * normalize the decoded values to match the expected publicData schema
 * @param decodedValues
 * @returns *
 */
function parseParamsToPublicData(decodedValues) {
  // Pre-filled data from invitations is stored inside publicData
  const {
    activationDate,
    isProposalDisabled,
    companyName,
    companyUrl,
    ...publicDataRest
  } = getHiddenValuesFromDecodedToken(decodedValues);

  return {
    ...publicDataRest,
    companyName: Array.isArray(companyName) ? companyName[0] : companyName,
    companyUrl: Array.isArray(companyUrl) ? companyUrl[0] : companyUrl,
    features: { activationDate, isProposalDisabled: isProposalDisabled === 'TRUE' },
  };
}
