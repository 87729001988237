import React from 'react';
import {
  AuthenticationPage,
  CheckoutPage,
  ContactDetailsPage,
  EmailVerificationPage,
  EventLandingPage,
  FaqPage,
  ImpactEstimatePage,
  InboxPage,
  IntegrationsPage,
  AccountNotificationsPage,
  LandingPage,
  ListingPage,
  MyAvailabilityPage,
  MyNonprofitPage,
  MyProfilePage,
  NonprofitOnboardingPage,
  NonprofitSearchPage,
  NonprofitsLandingPage,
  NotFoundPage,
  NotificationDetailPage,
  NotificationsPage,
  OnboardingCompletedPage,
  OnboardingPage,
  OutreachCheckoutPage,
  OutreachFaqPage,
  OutreachLandingPage,
  OutreachOffersPage,
  PasswordChangePage,
  PasswordRecoveryPage,
  PasswordResetPage,
  PaymentMethodsPage,
  PrivacyPolicyPage,
  SearchPage,
  StripePayoutPage,
  SummerCampaignOnePage,
  SummerCampaignTooPage,
  TermsOfServicePage,
  TransactionPage,
  PartnershipsPage,
} from './containers';

// routeConfiguration needs to initialize containers first
// Otherwise, components will import form container eventually and
// at that point css bundling / imports will happen in wrong order.
import { NamedRedirect } from './components';
import { AVAILABILITY, PERSONAL_INFO } from './components/OnboardingWizard/constants';
import { NONPROFIT_INFO } from './components/MyNonprofitWizard/constants';
import FaqPageEvent from './containers/FaqPage/FaqPageEvent';
import { TAB_NOTIFICATIONS } from './containers/NotificationsPage/NotificationsPage';
import { PARTNERSHIP_TABS } from './containers/PartnershipsPage/constants';
import MeetingSunsetPage from './containers/MeetingSunsetPage/MeetingSunsetPage';
import GivslyChangesPage from './containers/GivslyChangesPage/GivslyChangesPage';
import PlatformPrivacyPolicyPage from './containers/PlatformPrivacyPolicyPage/PlatformPrivacyPolicyPage';

export const ACCOUNT_SETTINGS_PAGES = [
  'ContactDetailsPage',
  'PasswordChangePage',
  'StripePayoutPage',
  'PaymentMethodsPage',
  'IntegrationsPage',
  'AccountNotificationsPage',
];

const RedirectToLandingPage = () => <NamedRedirect name="LandingPage" />;

// Our routes are exact by default.
// See behaviour from Routes.js where Route is created.
const routeConfiguration = () => {
  return [
    {
      path: '/',
      name: 'LandingPage',
      component: (props) => <LandingPage {...props} />,
      loadData: LandingPage.loadData,
    },
    {
      path: '/impactestimate',
      name: 'ImpactEstimatePage',
      component: (props) => <ImpactEstimatePage {...props} />,
      loadData: ImpactEstimatePage.loadData,
    },
    {
      path: '/faq',
      name: 'FaqPage',
      component: (props) => <FaqPage {...props} />,
    },
    {
      path: '/faq/beta',
      name: 'OutreachFaqPage',
      component: OutreachFaqPage,
    },
    {
      path: '/s',
      name: 'SearchPage',
      component: (props) => <NotFoundPage />,
      loadData: SearchPage.loadData,
    },
    {
      path: '/s/filters',
      name: 'SearchFiltersPage',
      component: (props) => <NotFoundPage />,
      loadData: SearchPage.loadData,
    },
    {
      path: '/l',
      name: 'ListingBasePage',
      component: RedirectToLandingPage,
    },
    {
      path: '/l/:slug/:id',
      name: 'ListingPage',
      component: (props) => <ListingPage {...props} />,
      loadData: ListingPage.loadData,
      auth: true,
      authPage: 'LoginPage',
    },
    {
      path: '/l/:slug/:id/checkout',
      name: 'CheckoutPage',
      auth: true,
      component: (props) => <CheckoutPage {...props} />,
      setInitialValues: CheckoutPage.setInitialValues,
    },
    {
      path: '/l/:slug/:id/propose-meeting',
      name: 'ProposeMeetingPage',
      auth: true,
      authPage: 'LoginPage',
      component: (props) => <CheckoutPage {...props} proposeMeeting />,
      setInitialValues: CheckoutPage.setInitialValues,
    },
    {
      path: '/l/:slug/:id/:variant',
      name: 'ListingPageVariant',
      auth: true,
      authPage: 'LoginPage',
      component: (props) => <ListingPage {...props} />,
      loadData: ListingPage.loadData,
    },
    {
      path: '/l/:id',
      name: 'ListingPageCanonical',
      component: (props) => <ListingPage {...props} />,
      loadData: ListingPage.loadData,
      auth: true,
      authPage: 'LoginPage',
    },
    {
      path: '/onboarding/completed',
      name: 'OnboardingCompletedPage',
      auth: true,
      authPage: 'LoginPage',
      component: () => <OnboardingCompletedPage />,
      loadData: OnboardingPage.loadData,
    },
    {
      path: '/onboarding/:tab',
      name: 'OnboardingPage',
      auth: true,
      authPage: 'LoginPage',
      params: { tab: PERSONAL_INFO },
      component: (props) => <OnboardingPage {...props} />,
      loadData: OnboardingPage.loadData,
    },
    {
      path: '/onboarding/nonprofit/:tab',
      name: 'NonprofitOnboardingPage',
      auth: true,
      authPage: 'LoginPage',
      params: { tab: NONPROFIT_INFO },
      component: (props) => <NonprofitOnboardingPage {...props} />,
      loadData: NonprofitOnboardingPage.loadData,
    },
    {
      path: '/outreach/:id',
      name: 'OutreachLandingPage',
      component: (props) => <OutreachLandingPage {...props} />,
      loadData: OutreachLandingPage.loadData,
    },
    {
      path: '/nonprofits',
      name: 'NonprofitsLandingPage',
      component: (props) => <NonprofitsLandingPage {...props} />,
      loadData: NonprofitsLandingPage.loadData,
    },
    {
      path: '/nonprofits/s',
      name: 'NonprofitSearchPage',
      component: (props) => <NonprofitSearchPage {...props} />,
      loadData: NonprofitSearchPage.loadData,
    },
    {
      path: '/my-profile/:tab',
      name: 'MyProfilePage',
      auth: true,
      authPage: 'LoginPage',
      params: { tab: PERSONAL_INFO },
      component: (props) => <MyProfilePage {...props} />,
      loadData: MyProfilePage.loadData,
    },
    {
      /**@TODO meetins are gone page */
      path: '/my-availability/:tab/:eventKey?',
      name: 'MyAvailabilityPage',
      auth: true,
      authPage: 'LoginPage',
      params: { tab: AVAILABILITY },
      component: (props) => <MeetingSunsetPage {...props} />,
      loadData: MyAvailabilityPage.loadData,
    },
    {
      path: '/my-nonprofit/:tab',
      name: 'MyNonprofitPage',
      auth: true,
      authPage: 'LoginPage',
      params: { tab: NONPROFIT_INFO },
      component: (props) => <MyNonprofitPage {...props} />,
      loadData: MyNonprofitPage.loadData,
    },
    {
      path: '/login',
      name: 'LoginPage',
      component: (props) => <AuthenticationPage {...props} tab="login" />,
      loadData: AuthenticationPage.loadData,
    },
    {
      path: '/signup',
      name: 'SignupPage',
      component: (props) => <NotFoundPage {...props} />,
    },
    {
      path: '/signup/:organization',
      name: 'SignupPage',
      component: (props) => <AuthenticationPage {...props} tab="signup" role="organization" />,
      loadData: AuthenticationPage.loadData,
    },
    {
      path: '/nonprofits/signup',
      name: 'SignupPage',
      component: (props) => <AuthenticationPage {...props} tab="signup" role="nonprofit" />,
      loadData: AuthenticationPage.loadData,
    },
    {
      path: '/recover-password',
      name: 'PasswordRecoveryPage',
      component: (props) => <PasswordRecoveryPage {...props} />,
    },
    {
      path: '/notifications/detail/:id',
      name: 'NotificationsDetailPage',
      auth: true,
      authPage: 'LoginPage',
      component: (props) => <NotificationDetailPage {...props} />,
      loadData: NotificationDetailPage.loadData,
    },
    {
      path: '/notifications',
      name: 'NotificationsBasePage',
      auth: true,
      authPage: 'LoginPage',
      component: () => (
        <NamedRedirect name="NotificationsPage" params={{ tab: TAB_NOTIFICATIONS }} />
      ),
    },
    {
      path: '/notifications/:tab',
      name: 'NotificationsPage',
      auth: true,
      authPage: 'LoginPage',
      component: (props) => <NotificationsPage {...props} />,
      loadData: NotificationsPage.loadData,
    },
    {
      path: '/inbox',
      name: 'InboxBasePage',
      auth: true,
      authPage: 'LoginPage',
      component: (props) => <MeetingSunsetPage {...props} />,
    },
    {
      path: '/inbox/:tab',
      name: 'InboxPage',
      auth: true,
      authPage: 'LoginPage',
      component: (props) => <MeetingSunsetPage {...props} />,
      loadData: InboxPage.loadData,
    },
    {
      path: '/order/:id',
      name: 'OrderPage',
      auth: true,
      authPage: 'LoginPage',
      component: (props) => <NamedRedirect name="OrderDetailsPage" params={{ ...props.params }} />,
    },
    {
      path: '/order/:id/details',
      name: 'OrderDetailsPage',
      auth: true,
      authPage: 'LoginPage',
      component: (props) => <TransactionPage {...props} transactionRole="customer" />,
      loadData: (params) => TransactionPage.loadData({ ...params, transactionRole: 'customer' }),
      setInitialValues: TransactionPage.setInitialValues,
    },
    {
      path: '/sale/:id',
      name: 'SalePage',
      auth: true,
      authPage: 'LoginPage',
      component: (props) => <NamedRedirect name="SaleDetailsPage" params={{ ...props.params }} />,
    },
    {
      path: '/sale/:id/details',
      name: 'SaleDetailsPage',
      auth: true,
      authPage: 'LoginPage',
      component: (props) => <TransactionPage {...props} transactionRole="provider" />,
      loadData: (params) => TransactionPage.loadData({ ...params, transactionRole: 'provider' }),
    },
    {
      path: '/account',
      name: 'AccountSettingsPage',
      auth: true,
      authPage: 'LoginPage',
      component: () => <NamedRedirect name="ContactDetailsPage" />,
    },
    {
      path: '/account/contact-details',
      name: 'ContactDetailsPage',
      auth: true,
      authPage: 'LoginPage',
      component: (props) => <ContactDetailsPage {...props} />,
      loadData: ContactDetailsPage.loadData,
    },
    {
      /**@TODO implement password sync to cognito */
      path: '/account/change-password',
      name: 'PasswordChangePage',
      auth: true,
      authPage: 'LoginPage',
      component: (props) => <PasswordChangePage {...props} />,
    },
    {
      path: '/account/payments',
      name: 'StripePayoutPage',
      auth: true,
      authPage: 'LoginPage',
      component: (props) => <StripePayoutPage {...props} />,
      loadData: StripePayoutPage.loadData,
    },
    {
      path: '/account/payments/:returnURLType',
      name: 'StripePayoutOnboardingPage',
      auth: true,
      authPage: 'LoginPage',
      component: (props) => <StripePayoutPage {...props} />,
      loadData: StripePayoutPage.loadData,
    },
    {
      path: '/account/payment-methods',
      name: 'PaymentMethodsPage',
      auth: true,
      authPage: 'LoginPage',
      component: (props) => <PaymentMethodsPage {...props} />,
      loadData: PaymentMethodsPage.loadData,
    },
    {
      path: '/account/integrations',
      name: 'IntegrationsPage',
      auth: true,
      authPage: 'LoginPage',
      component: (props) => <IntegrationsPage {...props} />,
      loadData: IntegrationsPage.loadData,
    },
    {
      path: '/account/notifications',
      name: 'AccountNotificationsPage',
      auth: true,
      authPage: 'LoginPage',
      component: (props) => <AccountNotificationsPage {...props} />,
      loadData: AccountNotificationsPage.loadData,
    },
    {
      path: '/account/outreach-offers/request/:requestId/checkout',
      name: 'OutreachCheckoutPage',
      auth: true,
      authPage: 'LoginPage',
      component: (props) => <OutreachCheckoutPage {...props} />,
      setInitialValues: OutreachCheckoutPage.setInitialValues,
    },
    {
      path: '/account/outreach-offers/:tab',
      name: 'OutreachOffersPage',
      auth: true,
      authPage: 'LoginPage',
      component: (props) => <OutreachOffersPage {...props} />,
      loadData: OutreachOffersPage.loadData,
    },
    {
      path: '/account/outreach-offers/:tab/:id',
      name: 'OutreachOffersDetailPage',
      auth: true,
      authPage: 'LoginPage',
      component: (props) => <OutreachOffersPage {...props} />,
      loadData: OutreachOffersPage.loadData,
    },
    {
      path: '/partnerships',
      name: 'PartnershipsPage',
      auth: true,
      authPage: 'LoginPage',
      component: () => (
        <NamedRedirect name="PartnershipsTabPage" params={{ tab: PARTNERSHIP_TABS.APPROVAL }} />
      ),
    },
    {
      path: '/partnerships/:tab',
      name: 'PartnershipsTabPage',
      auth: true,
      authPage: 'LoginPage',
      component: (props) => <PartnershipsPage {...props} />,
      loadData: PartnershipsPage.loadData,
    },
    {
      path: '/events/:eventKey/attendees',
      name: 'EventAttendeesPage',
      component: (props) => <SearchPage {...props} />,
      loadData: SearchPage.loadData,
    },
    {
      path: '/events/:eventKey/faq',
      name: 'EventFaqPage',
      component: (props) => <FaqPageEvent {...props} />,
      loadData: FaqPageEvent.loadData,
    },
    {
      path: '/events/:eventKey',
      name: 'EventLandingPage',
      component: (props) => <EventLandingPage {...props} />,
      loadData: EventLandingPage.loadData,
    },
    {
      path: '/nonprofits/terms-of-service',
      name: 'TermsOfServiceNPOPage',
      component: (props) => <TermsOfServicePage npo={true} updates={false} {...props} />,
    },
    {
      path: '/nonprofits/terms-of-service/updates',
      name: 'TermsOfServiceNPOPage',
      component: (props) => <TermsOfServicePage npo={true} updates={true}  {...props} />,
    },
    {
      path: '/terms-of-service',
      name: 'TermsOfServicePage',
      component: (props) => <TermsOfServicePage {...props} />,
    },
    {
      path: '/privacy-policy',
      name: 'PrivacyPolicyPage',
      component: () => <NamedRedirect name="WebPrivacyPolicyPage" />,
    },
    {
      path: '/web-privacy-policy',
      name: 'WebPrivacyPolicyPage',
      component: (props) => <PrivacyPolicyPage {...props} />,
    },
    {
      path: '/platform-privacy-policy',
      name: 'PlatformPrivacyPolicyPage',
      component: (props) => <PlatformPrivacyPolicyPage {...props} />,
    },
    {
      path: '/platform-privacy-policy/updates',
      name: 'PlatformPrivacyPolicyPage',
      component: (props) => <PlatformPrivacyPolicyPage updates={true} {...props} />,
    },
    {
      // in case someone still points to 2023
      path: '/changes-2023',
      name: 'GivslyChangesPage',
      component: (props) => <GivslyChangesPage {...props} />,
    },
    {
      // keep it generic - so we don't need to change in the future 
      path: '/changes',
      name: 'GivslyChangesPage',
      component: (props) => <GivslyChangesPage {...props} />,
    },
    {
      path: '/notfound',
      name: 'NotFoundPage',
      component: (props) => <NotFoundPage {...props} />,
    },
    {
      path: '/about',
      name: 'AboutPage',
      component: (props) => <NotFoundPage {...props} />,
    },
    {
      path: '/contact',
      name: 'ContactPage',
      component: (props) => <NotFoundPage {...props} />,
    },
    {
      path: '/summerone',
      name: 'SummerCampaignOnePage',
      component: (props) => <SummerCampaignOnePage {...props} />,
      loadData: SummerCampaignOnePage.loadData,
    },
    {
      path: '/summertoo',
      name: 'SummerCampaignTooPage',
      component: (props) => <SummerCampaignTooPage {...props} />,
      loadData: SummerCampaignTooPage.loadData,
    },
    // Do not change this path!
    //
    // The API expects that the application implements /reset-password endpoint
    {
      path: '/reset-password',
      name: 'PasswordResetPage',
      component: (props) => <PasswordResetPage {...props} />,
    },

    // Do not change this path!
    //
    // The API expects that the application implements /verify-email endpoint
    {
      path: '/verify-email',
      name: 'EmailVerificationPage',
      auth: true,
      authPage: 'LoginPage',
      component: (props) => <EmailVerificationPage {...props} />,
      loadData: EmailVerificationPage.loadData,
    },
  ].filter((i) => i !== null);
};

export default routeConfiguration;
