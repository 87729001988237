import React, { Component } from 'react';
import isEqual from 'lodash/isEqual';

export function withTheme(WrappedComponent) {
  return class extends Component {
    constructor(props) {
      super(props);

      this.setTheme = this.setTheme.bind(this);

      this.state = {
        currentTheme: {},
      };
    }

    componentWillUnmount() {
      Object.keys(this.state.currentTheme).forEach((key) => {
        document.documentElement.style.setProperty(key, undefined);
      });
      this.setState({ currentTheme: {} });
    }

    setTheme(theme) {
      if (isEqual(theme, this.state.currentTheme)) return;

      Object.keys(theme).forEach((key) => {
        document.documentElement.style.setProperty(key, theme[key]);
      });
      this.setState({ currentTheme: theme });
    }

    render() {
      return <WrappedComponent {...this.props} setTheme={this.setTheme} />;
    }
  };
}
