import React from 'react';
import css from './ModalButtonContainer.css';
import { Button } from '../index';
import classNames from 'classnames';
import { bool, func, node, oneOfType, shape, string } from 'prop-types';

const ModalButtonContainer = (props) => {
  const { className, primaryButton, secondaryButton } = props;
  const { text: primaryButtonText, ...primaryButtonProps } = props.primaryButton;

  const { text: secondaryButtonText, ...secondaryButtonProps } = props.secondaryButton || {};
  const classes = classNames(css.submitButtonContainer, className);

  return (
    <div className={classes}>
      {secondaryButtonText && (
        <Button
          className={classNames(
            css.submitButton,
            css.submitButtonSecondary,
            secondaryButton.className
          )}
          type="button"
          {...secondaryButtonProps}
        >
          {secondaryButtonText}
        </Button>
      )}
      <Button
        className={classNames(css.submitButton, css.submitButtonPrimary, primaryButton.className)}
        type="submit"
        {...primaryButtonProps}
      >
        {primaryButtonText}
      </Button>
    </div>
  );
};

ModalButtonContainer.propTypes = {
  className: string,
  primaryButton: shape({
    inProgress: bool,
    type: string,
    disabled: bool,
    ready: bool,
    onClick: func,
    text: oneOfType([node, string]),
  }).isRequired,
  secondaryButton: shape({
    inProgress: bool,
    type: string,
    disabled: bool,
    ready: bool,
    onClick: func,
    text: oneOfType([node, string]),
  }),
};

ModalButtonContainer.defaultProps = {
  className: null,
  secondaryButton: null,
};

export default ModalButtonContainer;
