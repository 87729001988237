import React, { Component } from 'react';
import { array, func, object, string } from 'prop-types';
import {
  Button,
  InlineTextButton,
  ListingCard,
  NamedLink,
  SectionSideScroll,
  SectionThreeColumns,
} from '../../../../components';
import { AVATAR_SIZES_EXTRA_LARGE } from '../../../../components/Avatar/Avatar';
import css from './EventLandingPagePeople.css';
import { stringify } from '../../../../util/urlHelpers';
import moment from 'moment-timezone';
import { DATE_SEARCH_FORMAT } from '../../../../util/dates';

class EventLandingPagePeople extends Component {
  get browseAllLinkParams() {
    return {
      name: this.props.browseAllPageName,
      params: {
        eventKey: this.props.eventKey,
      },
      to: {
        search: this.browseAllSearchParams,
      },
    };
  }

  get browseAllSearchParams() {
    const { browseAllSearchParams, userRole } = this.props;

    if (browseAllSearchParams) {
      // Pre-process search parameters
      if (browseAllSearchParams.start && browseAllSearchParams.end) {
        const now = moment();
        const start = moment(new Date(browseAllSearchParams.start));
        const end = moment(new Date(browseAllSearchParams.end));

        // Prevent errors in the date range filter
        if (end.isSameOrBefore(now)) {
          // If today is the final date of the range or goes beyond that range then reset filter
          delete browseAllSearchParams.start;
          delete browseAllSearchParams.end;
        } else if (start.isBefore(now)) {
          // If the range start is before today then set the start to today
          browseAllSearchParams.start = now.format(DATE_SEARCH_FORMAT);
        }
      }

      return stringify(browseAllSearchParams);
    } else if (userRole) {
      return `pub_eventRoles=${this.props.userRole}`;
    }
    return '';
  }

  get listingSlides() {
    return this.props.listings
      .reduce((slides, listing, index) => {
        const startNextRow = index % 3 === 0;

        const listingCard = this.getListingCard(listing, index);

        if (startNextRow) {
          return [...slides, [this.getListingCard(listing, index)]];
        }

        const lastSlide = slides.pop();
        return [...slides, [...lastSlide, listingCard]];
      }, [])
      .map((listings, index) => <SectionThreeColumns key={index}>{listings}</SectionThreeColumns>);
  }

  getListingCard(listing, index) {
    const panelMediumWidth = 50;
    const panelLargeWidth = 62.5;
    const cardRenderSizes = [
      '(max-width: 767px) 100vw',
      `(max-width: 1023px) ${panelMediumWidth}vw`,
      `(max-width: 1920px) ${panelLargeWidth / 2}vw`,
      `${panelLargeWidth / 3}vw`,
    ].join(', ');

    return listing.id && listing.id.uuid ? (
      <ListingCard
        className={css.card}
        avatarSize={AVATAR_SIZES_EXTRA_LARGE}
        key={index}
        listing={listing}
        renderSizes={cardRenderSizes}
        setActiveListing={() => null}
        npoListingNames={this.props.npoListingNames}
        backgroundImage={this.props.backgroundImage}
        trackEvent={this.props.trackEvent}
      />
    ) : null;
  }

  render() {
    return (
      <div>
        <span className={css.titleContainer}>
          <h2 className={css.title}>{this.props.title}</h2>
          <NamedLink {...this.browseAllLinkParams}>
            <InlineTextButton
              className={css.browseAllButtonDesktop}
              onClick={() =>
                this.props.trackEvent({
                  action: 'Click',
                  label: 'People block',
                  value: this.props.browseAll,
                })
              }
            >
              {this.props.browseAll} <span className={css.arrow}>&rarr;</span>
            </InlineTextButton>
          </NamedLink>
        </span>

        <SectionSideScroll
          buttonStyle={css.sliderNextPrevButton}
          dotStyle={css.sliderDot}
          dotActiveStyle={css.sliderDotActive}
        >
          {this.listingSlides}
        </SectionSideScroll>

        <NamedLink {...this.browseAllLinkParams}>
          <Button
            onClick={() =>
              this.props.trackEvent({
                action: 'Click',
                label: 'People block',
                value: this.props.browseAll,
              })
            }
            className={css.browseAllButtonMobile}
            type={'button'}
          >
            {this.props.browseAll}
          </Button>
        </NamedLink>
      </div>
    );
  }
}

EventLandingPagePeople.propTypes = {
  title: string.isRequired,
  browseAll: string.isRequired,
  browseAllPageName: string.isRequired,
  listings: array.isRequired,
  npoListingNames: object.isRequired,
  userRole: string,
  eventKey: string.isRequired,
  backgroundImage: string,
  trackEvent: func.isRequired,
};

export default EventLandingPagePeople;
