import React from 'react';
import PropTypes, { func, shape } from 'prop-types';
import classNames from 'classnames';
import { propTypes } from '../../util/types';
import { Button, ListingCard, PaginationLinks } from '../../components';
import css from './SearchResultsPanel.css';
import { createResourceLocatorString } from '../../util/routes';
import routeConfiguration from '../../routeConfiguration';
import { FormattedMessage } from 'react-intl';

class SearchResultsPanel extends React.Component {
  constructor(props) {
    super(props);
    this.clearAllFilters = this.clearAllFilters.bind(this);
  }

  clearAllFilters() {
    this.props.history.push(
      createResourceLocatorString(this.props.pageName, routeConfiguration(), this.props.pathParams)
    );
  }

  render() {
    const {
      children,
      className,
      event,
      rootClassName,
      listings,
      pathParams,
      pageName,
      pagination,
      search,
      setActiveListing,
      npoListingNames,
    } = this.props;
    const classes = classNames(rootClassName || css.root, className);

    const paginationLinks =
      pagination && pagination.totalPages > 1 ? (
        <PaginationLinks
          className={css.pagination}
          pageName={pageName}
          pageSearchParams={search}
          pagePathParams={pathParams}
          pagination={pagination}
        />
      ) : null;

    // Panel width relative to the viewport
    const panelMediumWidth = 50;
    const panelLargeWidth = 62.5;
    const cardRenderSizes = [
      '(max-width: 767px) 100vw',
      `(max-width: 1023px) ${panelMediumWidth}vw`,
      `(max-width: 1920px) ${panelLargeWidth / 2}vw`,
      `${panelLargeWidth / 3}vw`,
    ].join(', ');

    return (
      <div className={classes}>
        {listings.length > 0 ? (
          <>
            <div className={css.listingCards}>
              {listings.map((l) =>
                l.id && l.id.uuid ? (
                  <ListingCard
                    className={css.listingCard}
                    key={l.id.uuid}
                    listing={l}
                    renderSizes={cardRenderSizes}
                    setActiveListing={setActiveListing}
                    npoListingNames={npoListingNames}
                  />
                ) : null
              )}
              {children}
            </div>
            {paginationLinks}
          </>
        ) : (
          <div className={css.noResults}>
            <div className={css.noResultsImage}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="180"
                height="112"
                viewBox="0 0 180 112"
              >
                <g fill="none" fillRule="evenodd">
                  <path
                    fill="#F2F2F2"
                    d="M1.5 109h177c.829 0 1.5.672 1.5 1.5s-.671 1.5-1.5 1.5H1.5c-.828 0-1.5-.672-1.5-1.5s.672-1.5 1.5-1.5"
                  />
                  <path
                    fill="#FCE283"
                    d="M40.255 112L48.513 68 54.127 68 42.568 112zM102.127 112L93.87 68 88.256 68 99.815 112zM90.728 60H54.936c-8.754 0-9.374-5.844-10.718-12-2.328-10.668-4.76-22.832-7.816-38C35.207 4.062 29.228 0 24.83 0h-16C.303 0-.793 4.062.402 10 3.457 25.168 7.62 45.332 9.95 56c1.343 6.155 1.963 12 10.716 12H98s-2.207-8-7.27-8"
                  />
                  <path
                    fill="#FAC51D"
                    d="M60.728 60H24.936c-8.754 0-9.374-5.844-10.718-12-2.328-10.668-4.76-22.832-7.816-38C5.207 4.062 6.303 0 14.83 0h-6C.303 0-.793 4.062.402 10 3.457 25.168 7.62 45.332 9.95 56c1.343 6.155 1.964 12 10.716 12H68s-2.207-8-7.27-8"
                  />
                  <path
                    fill="#FCE283"
                    d="M10.255 112L18.512 68 24.127 68 12.568 112zM72.127 112L63.87 68 58.256 68 69.815 112z"
                  />
                  <path fill="#FAF4D5" d="M60 47L180 47 180 41 60 41z" />
                  <path
                    fill="#FCE283"
                    d="M60 47L84 47 84 41 60 41zM122 104.37V47h-4v57.37c0 5.63-12 7.63-12 7.63h28s-12-2-12-7.63M80 41L92 41 92 23 80 23z"
                  />
                  <path fill="#FAC51D" d="M80 41L84 41 84 23 80 23zM118 50L122 50 122 47 118 47z" />
                  <path
                    fill="#FAC51D"
                    d="M80 37c-1.635 0-2.97-.266-4.171-1.562-1.831-1.974-1.83-4.075-1.83-5.085C74 28.677 75.31 27 77.5 27H80v2h-2.5c-1.224 0-1.5.962-1.5 1.299 0 .981 0 2.381 1.297 3.779.646.698 1.304.922 2.704.922v2z"
                  />
                  <path
                    fill="#FCE283"
                    d="M82.04 6.874c-.375 2.344 1.917 4.106 3.768 5.764V2.305s-3.23 1.228-3.767 4.569"
                  />
                  <path
                    fill="#FCE283"
                    d="M87.69 14.733c-.386-.704-1.096-1.39-1.883-2.096v7.667s3.177-3.214 1.883-5.57"
                  />
                </g>
              </svg>
            </div>
            <h3 className={css.noResultsHeader}>
              {(!event || !event.title) && (
                <FormattedMessage id="SearchResultsPanel.noResults.title" />
              )}
              {event && event.title && (
                <FormattedMessage
                  id="SearchResultsPanel.noResults.title.event"
                  values={{ event: event.title }}
                />
              )}
            </h3>
            <p className={css.noResultsDescription}>
              {(!event || !event.title) && (
                <FormattedMessage id="SearchResultsPanel.noResults.body" />
              )}
              {event && event.title && (
                <FormattedMessage
                  id="SearchResultsPanel.noResults.body.event"
                  values={{ event: event.title }}
                />
              )}
            </p>
            <Button className={css.buttonClearAllFilters} onClick={this.clearAllFilters}>
              Clear all filters
            </Button>
          </div>
        )}
      </div>
    );
  }
}

SearchResultsPanel.defaultProps = {
  children: null,
  className: null,
  listings: [],
  pageName: 'SearchPage',
  pathParams: {},
  pagination: null,
  rootClassName: null,
  search: null,
};

const { array, node, object, string } = PropTypes;

SearchResultsPanel.propTypes = {
  children: node,
  className: string,
  event: propTypes.event,
  history: shape({
    push: func.isRequired,
  }).isRequired,
  listings: array,
  npoListingNames: object.isRequired,
  pageName: string.isRequired,
  pagination: propTypes.pagination,
  rootClassName: string,
  search: object,
  pathParams: object,
};

export default SearchResultsPanel;
