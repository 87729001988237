import { createResourceLocatorString } from './routes';
import routeConfiguration from '../routeConfiguration';
import { PERSONAL_INFO } from '../components/OnboardingWizard/constants';

export const emailVerificationRequired = (history, ensuredUser) => {
  if (ensuredUser.id && ensuredUser.attributes.emailVerified === false) {
    history.push(createResourceLocatorString('LoginPage', routeConfiguration(), {}, {}));
  }
};

export const onboardingCompletedRequired = (history, ensuredUser) => {
  if (ensuredUser.id && !ensuredUser.attributes.profile.publicData.onboardingCompleted) {
    history.push(
      createResourceLocatorString('OnboardingPage', routeConfiguration(), { tab: PERSONAL_INFO })
    );
  }
};
