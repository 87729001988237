import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './PrivacyPolicy.css';
import { ExternalLink, NamedLink } from '../index';
import AnchorTitle from '../AnchorTitle/AnchorTitle';

const PlatformPrivacyPolicyLink = ({ children = 'Platform privacy policy' }) => (
  <NamedLink name="PlatformPrivacyPolicyPage">{children}</NamedLink>
);
const PrivacyPolicy = (props) => {
  const { rootClassName, className } = props;
  const classes = classNames(rootClassName || css.root, className);

  const mailToPrivacyAtGivsly = <a href="mailto:privacy@givsly.com">privacy@givsly.com</a>;

  const anchorCookies = <a href="#cookies-and-other-tracking-technologies">cookies</a>;

  const extGoogleAnalyticsPrivacyPolicy = (
    <ExternalLink
      href="https://policies.google.com/privacy?hl=en"
      target="_blank"
      rel="noopener noreferrer nofollow"
    >
      https://policies.google.com/privacy?hl=en
    </ExternalLink>
  );

  const extNetworkAdvertising = (
    <ExternalLink
      href="https://optout.networkadvertising.org/?c=1"
      target="_blank"
      rel="noopener noreferrer nofollow"
    >
      Network Advertising Initiative opt-out page
    </ExternalLink>
  );

  const extYourOnlineChoices = (
    <ExternalLink
      href="https://youronlinechoices.com"
      target="_blank"
      rel="noopener noreferrer nofollow"
    >
      https://youronlinechoices.com
    </ExternalLink>
  );

  const extYourAdChoicesCookies = (
    <ExternalLink
      href="https://youradchoices.com/control#cookies"
      target="_blank"
      rel="noopener noreferrer nofollow"
    >
      https://youradchoices.com/control#cookies
    </ExternalLink>
  );

  const extAboutAds = (
    <ExternalLink
      href="http://www.aboutads.info/choices"
      target="_blank"
      rel="noopener noreferrer nofollow"
    >
      http://www.aboutads.info/choices
    </ExternalLink>
  );

  // prettier-ignore
  return (
    <div className={classes}>

      <p className={css.lastUpdated}>Last Updated October 1st, 2023</p>

      <h2>Our Privacy Commitment</h2>

      <p>This Privacy Policy (“Policy”) explains how Givsly PBC, ("Givsly"), and its subsidiaries and affiliated companies (collectively, “Givsly”, “we”, “our”, or “us”) collect, use, and disclose your personal information when you use Givsly.com, and company.Givsly.com (“Site”, “Sites”).</p>

      <p><b>Givsly</b> operates an online platform for purpose-led advertising and marketing creating opportunities to give back and donate to various nonprofit organizations.</p>

      <p>We ask that you take the time to read this Policy carefully.</p>

      <AnchorTitle>Table of Contents</AnchorTitle>
      <ol>
        <li><a style={{color:"#1c1a34"}} href="#changes-to-this-privacy-policy">Changes to this Privacy Policy</a></li>
        <li><a style={{color:"#1c1a34"}} href="#what-this-privacy-policy-covers">What this Privacy Policy Covers</a></li>
        <li><a style={{color:"#1c1a34"}} href="#information-collected-used-and-disclosed">Information Collected, Used, and Disclosed</a></li>
        <li><a style={{color:"#1c1a34"}} href="#how-we-use-personal-information">How we use personal information</a></li>
        <li><a style={{color:"#1c1a34"}} href="#disclosure-of-your-information-to-third-parties">Disclosure of your information to third parties</a></li>
        <li><a style={{color:"#1c1a34"}} href="#cookies-and-other-tracking-technologies">Cookies and other Tracking Technologies</a></li>
        <li><a style={{color:"#1c1a34"}} href="#your-privacy-choices">Your Privacy Choices</a></li>
        <li><a style={{color:"#1c1a34"}} href="#children">Children</a></li>
        <li><a style={{color:"#1c1a34"}} href="#safeguarding-and-retaining-of-your-personal-information">Safeguarding &amp; Retaining of Your Personal Information</a></li>
        <li><a style={{color:"#1c1a34"}} href="#for-users-in-the-united-kingdom-european-economic-area-or-the-european-union">For Users in the United Kingdom (UK), European Economic Area (EEA), or the European Union (EU)</a></li>
        <li><a style={{color:"#1c1a34"}} href="#your-california-privacy-rights">Your California Privacy Rights</a></li>
        <li><a style={{color:"#1c1a34"}} href="#contact-us">Contact Us</a></li>
      </ol>
      <AnchorTitle id="changes-to-this-privacy-policy" className={css.anchor}>1. Changes to this Privacy Policy</AnchorTitle>
      <p>We encourage you to review this Policy whenever you access the Site to make sure that you understand our information collection, use and disclosure practices. We may change the provisions of this Policy at any time and will indicate when changes have been made by revising the date at the top of this Policy. If we make material changes to this Policy, we will provide you with additional notice of such changes by updating our Site and/or by sending you a notification via email, and/or get your permission to use your personal information accordingly where applicable.</p>

      <AnchorTitle id="what-this-privacy-policy-covers" className={css.anchor}>2. What this Privacy Policy Covers</AnchorTitle>

      <p>This Policy is effective as of the date first posted above and applies only to information collected by Givsly on this Site. When Givsly processes personal information from users visiting this Site, it is acting as a “data controller” or a “business” under certain data protection laws where applicable.  Givsly does not “sell” or “share” personal information collected from its Site as may be defined under certain data protection laws. Please note, this policy does not apply to data processed when Givsly provides its Services. You can find more information about how Givsly processes personal information when providing its Services, (including Donation Offers) in its <PlatformPrivacyPolicyLink>Services privacy policy here</PlatformPrivacyPolicyLink>.</p>

      <p>For clarity, this Policy does not apply to any other mobile apps, or any apps or websites maintained by other companies, organizations, or third-party sites to which we may link. Your use of any third-party app and/or website is subject to such third party’s applicable privacy policies and terms of use, and we encourage you to read the third parties’ privacy policies and terms of use before submitting personal or other information or using that app and/or website. This Policy applies only to givsly.com, and company.givsly.com. Please note, this Policy does not apply to do.givsly.com, offer.givsly.com or Givsly’s Donation Offerings Service. This is covered under our Services policy that can be found <PlatformPrivacyPolicyLink>here</PlatformPrivacyPolicyLink>.</p>

      <AnchorTitle id="information-collected-used-and-disclosed" className={css.anchor}>3. Information Collected, Used, and Disclosed</AnchorTitle>
      <h2>Personal Information</h2>

      <p>Personal information (or personal data as it may be referred to) is defined differently in different jurisdictions. Generally, it is any information related to an identifiable individual. Givsly collects certain personal information from you when you visit Givsly’s Site to the extent reasonably necessary to fulfill your requests.</p>

      <p>At times, you may voluntarily supply us with personal information. For example, by completing the form in the “Contact Us” or “request a demo” section of this Site. Givsly collects the following elements of personal information: </p>
      <ul>
        <li>Name</li>
        <li>Email address</li>
        <li>Phone number</li>
        <li>Company name and job title</li>
        <li>IP address</li>
        <li>Cookie ID, (or similar technologies) A cookie is a tiny text files that is stored on a user's browser. Most cookies contain a unique identifier called a cookie ID: a string of characters that websites and servers associate with the browser on which the cookie is stored. Other persistent or unique identifiers, such as an advertising ID, or device ID may be collected from use of the Site.</li>
        <li>Personal information provided by you, such as name, email, employment history, when apply for a job with Givsly.</li>
      </ul>
      <p>To learn more about how we use cookies please see our section on {anchorCookies}.</p>
      <AnchorTitle id="how-we-use-personal-information">How we use personal information?</AnchorTitle>
      <p>We will use your personal information collected from our Site in the following ways:</p>
      <ul>
        <li>to provide you with information and materials you have requested and to respond to specific queries via our “Contact Us” or “request a demo” section;</li>

        <li>to understand what content you interact with on our Site to provide a better experience on our Site, and to provide you with better ways of accessing information on this Site;</li>

        <li>to send you information and promotional materials which we determine would be of interest or relevant to you such as new business information about Givsly, newsletters, bulletins;</li>

        <li>for marketing purposes to market Givsly’s services;</li>

        <li>to better understand marketing efforts;</li>

        <li>to allow you to submit information to our new business team;</li>

        <li>Givsly may use, to administer compliance policies and procedures;</li>

        <li>and to comply with applicable laws.</li>
      </ul>

      <p>Givsly may also collect and store aggregate or anonymous information about a user’s interaction with the Site. We may use this aggregate or anonymous information we collect about our visitors to our site, traffic patterns, and related site information to better design our Site and to share with reputable third-party vendors.</p>

      <p>We use a third-party service provider, Google Analytics, and YouTube to assist us in gathering analytics and statistical data in connection with the Site, and to provide video content within the Site. This data is used to assist us in gathering aggregate analytics and statistical data regarding use of the Site (e.g., session length, bounce rates, etc.). Please note, aggregate and anonymous information is not personal information and cannot directly or indirectly identify an individual, but where analytics data may be classified as personal information, we will treat it as such.</p>

      <p>We collect and use certain demographic information:</p>
      <ul>
        <li>geography (city and state), nonprofit interest, and similar information for statistical purposes.</li>
        <li>While demographic data is often, on its own, nonpersonal information, when demographic information is linked to personal information, we treat this data as personal information.</li>
      </ul>


      <AnchorTitle id="disclosure-of-your-information-to-third-parties">Disclosure of Your Information to Third Parties</AnchorTitle>
      <ul>
        <li>We may disclose your personal information if we or any of our assets are the subject of a sale or similar corporate transaction. We require that the third parties who receive your personal information are required to keep it confidential</li>
        <li>We may also disclose your personal information with third parties where required for processing, like to our service providers.</li>
        <li>We may disclose personal information to third parties when we reasonably believe we are required or permitted by law (such as third parties who provide services on our behalf);</li>
        <li>To investigate, prevent, or take action regarding suspected or actual unlawful or otherwise prohibited activities, including, but not limited to, fraud.</li>
        <li>We may use your personal information for marketing purposes, like inviting you to an event or promotion. Where required we will obtain your consent to use your information for marketing purposes.</li>
      </ul>

      <AnchorTitle id="cookies-and-other-tracking-technologies">Cookies and Other Tracking Technologies</AnchorTitle>

      <p>We use first party and third-party cookies, pixel tags, and other tracking technologies for several reasons. Some cookies are required for technical reasons for our Site to operate, and we refer to these as "essential" or "strictly necessary" cookies. Other cookies enable us and the third parties we work with to track and target the interests of visitors to our websites, and we refer to these as "performance" cookies. For example, we use cookies to tailor content and information that we may send or display to you and otherwise personalize your experience while interacting with our Site and to otherwise improve the functionality of the Site. Finally, third-parties serve cookies through our websites for analytics, and other purposes.</p>

      <h2>Google Analytics</h2>

      <p>We have enabled Google Analytics features that give us important information about how our users engage with our content, such as to assist us in gathering analytics and statistical data in connection with the Site. For more information about how Google collects and manages your information, you may review Google’s privacy policy at {extGoogleAnalyticsPrivacyPolicy}. We have enabled the following Google Analytics features</p>
      <ul>
        <li><em><strong>Statistical and Analytics  Data</strong></em>Among other things, Google Analytics uses cookies to collect information about the number of visits to the Website, the webpage that referred visitors to the Website, language, device, browser and operating system, the pages visitors view within the Site and other similar details. When used this way, the Google Analytics cookie is a persistent cookie and will remain on your device until the cookie expires or you delete it. These cookies may appear as _utma, _utmb, _utmc, _utmz, _utmv, _utmt.</li>
        <li><em><strogn></strogn></em> In addition to any opt-out feature provided by Google or described in The Your Choices section of this Policy, you may opt out of this feature by visiting the {extNetworkAdvertising}.</li>
      </ul>

      <AnchorTitle id="your-privacy-choices" className={css.anchor}>Your Privacy Choices</AnchorTitle>
      <p style={{textDecoration: "underline"}}>Access, Correction, Deletion and Opt Out</p>
      <p>Depending on where you reside and other legal limitations, you may have the right to (1) request to know more about and access the categories and specific pieces of personal information we collect, use, and disclose, (2) request deletion or restriction of your personal information, (3) request correction of inaccurate personal information, (4)opt out of certain processing activities, such as selling, sharing, or targeted advertising,  and (5) obtain your User Information in a portable format.</p>
      <p>If you would like to exercise any of these rights, please see our email us at privacy@givsly.com You may also opt out of receiving marketing communications from Givsly by clicking on the “unsubscribe” or “opt-out” link in the marketing e-mails we send to you.</p>
      <p>We will not discriminate against you for exercising your privacy rights. Note that when you exercise your rights, we may ask for additional information to verify your request. If we deny your request, you may have the right to appeal our decision. If you have concerns about the results of an appeal, you may contact the attorney general in the state where you reside, or supervisory authority in your local jurisdiction.</p>
      <p>How to opt-out of collection and, or use of data from our Site:</p>
      <p>In browser environments, (i.e. on a website using a browser like Chrome), you may refuse or remove cookies via the following methods:</p>
      <ul>
        <li>Adjusting your browser settings to refuse or remove cookies. Please note, that by doing so you may not be able to use certain features on certain web sites you visit or take full advantage of all web site offerings. You may refuse or remove cookies by following the directions provided in your browser settings.</li>
      </ul>
      <p>Further information about cookies is available at:</p>
      <ul>
        <li>{extYourOnlineChoices}</li>
        <li>and {extYourAdChoicesCookies}</li>
        <li>{extNetworkAdvertising}</li>
        <li>{extAboutAds}</li>
      </ul>
      <p>If you delete cookies, install a new browser or get a new computer, the opt-out cookie will need to be reset by coming back to the links above.</p>
      <p>For Mobile opt outs:</p>

      <p>Most modern mobile operating systems (iOS, Android) provide mobile advertising identifiers for mobile devices. These identifiers have different names depending on the brand. For example, they are called Google Advertising ID (GAID) on Android devices and Identifiers for Advertisers (IDFA) on iOS. These identifiers are commonly used to help enable digital advertising across mobile applications. The operating systems that provide these mobile advertising identifiers include built-in settings to express privacy preferences for online advertising across applications. Please look up the operating system you are using for instructions on how to “limit ad tracking” for your device.</p>
      <p>Our Site does not respond to Do Not Track (“DNT”) signals that can be sent by some browsers, because no DNT standard has been adopted by the World Wide Web Consortium at this time.</p>
      <p>
        If you would like to unsubscribe from our mailing lists, review, update, or have us delete your personal information please contact us at {mailToPrivacyAtGivsly} or submit a request through the “Contact Us” form on our websites.  You can also unsubscribe from the link provided to do so in any message or communication you may receive from Givsly. Please note, where we are being asked to disclose personal information to you, we may require verification of your identity.
      </p>

      <AnchorTitle id="children" className={css.anchor}>Children</AnchorTitle>
      <p>Our Site is not intended for or directed to children. Different jurisdictions set different thresholds for the age of a child. Givsly does not knowingly collect or store any personal information about children under the age of 13 or 16 respective of jurisdiction. If you feel we may have unknowingly collected information about your child, please contact us at {mailToPrivacyAtGivsly} so that we can delete this data.</p>
      
      <AnchorTitle id="safeguarding-and-retaining-of-your-personal-information" className={css.anchor}>Safeguarding & Retaining Your Personal Information</AnchorTitle>

      <p>We take appropriate technical and organizational measures to ensure that your personal information disclosed to us is kept secure, accurate and up to date. Givsly’s hosting environments and security measures are continuously reviewed and assessed. We process and store your information in the European Union. We may transfer your personal information to countries outside of the European Union, including the United States. We will, where the country to which your data is transferred has not been found to provide an adequate level of protection, put in place appropriate safeguards to ensure your information is protected.</p>

      <p>Givsly retains your information for business purposes, and for as long as is reasonably necessary to provide you with access to the Site. Givsly will also retain your information as reasonably necessary to comply with our legal obligations, resolve disputes and enforce our agreements.</p>

      <p>Despite our efforts, please be aware that no security measure is ever perfect or impenetrable so please be careful about what information you share online.</p>

      <AnchorTitle id="for-users-in-the-united-kingdom-european-economic-area-or-the-european-union" className={css.anchor}>For Users in the United Kingdom (UK), European Economic Area (EEA), or the European Union (EU).</AnchorTitle>
      <p>For UK, EEA, and EU users, below you will find information regarding our processing of your personal information. When we collect personal information (or personal data in some jurisdictions) from our Site, we act as a “data controller”. The legal basis for processing your personal information below are for when we are acting as a data controller.</p>
      <table>
        <thead>
          <th>How we will use your personal information</th>
          <th>Our legal basis for processing</th>
        </thead>
        <tbody>
          <tr>
            <td>To provide you with information and materials that you have requested e.g. via our "contact" section, “request a demo” or from a registration form at an Event</td>
            <td>Consent – we use your personal information to assess the information you have submitted to us and to communicate and respond accordingly.</td>
          </tr>
          <tr>
            <td>To send you marketing communications. We may send you communications related to marketing of Givsly’s services. This may include communications about events and how to register.</td>
            <td>Consent - we will use personal information to provide a better user experience and will ask for your consent prior to collecting your personal information for our marketing activities such as emails, or prior to setting an analytics or advertising tracker.</td>
          </tr>
          <tr>
            <td>If you are a client, we may also send you information and materials which we determine would be of interest or relevant to you such as new business information, updates about our platform and services, and bulletins. </td>
            <td>Our legitimate interests - we use your business-related contact information such as your email address to communicate such information to you.</td>
          </tr>
          <tr>
            <td>To provide you with a better user experience when accessing information from this Site,</td>
            <td>
            Consent – we will use analytics data to provide a better user experience and will ask for your consent prior to setting an analytics  tracker.
            </td>
          </tr>
        </tbody>
      </table>
      <h2>YOUR INFORMATION RIGHTS</h2>
      <p>If you would like to review, update, or have us delete your personal information please contact us at {mailToPrivacyAtGivsly}</p>
      <p>You can exercise the rights outlined below at any time by sending an email to {mailToPrivacyAtGivsly} or submitting a request through the “Contact Us” form on our Sites.</p>
      <p>You have right to:</p>
      <ul>
        <li>be informed. You have the right to be provided with clear, transparent and easily understandable information about how we use your personal information and your rights.</li>
        <li>access and rectification. You have the right to access, correct or update your personal information at any time.</li>
        <li>data portability. The personal information you have provided us with is portable. This means it can be moved, copied or transmitted electronically under certain circumstances.</li>
        <li>be forgotten. Under certain circumstances, you have right to request that we delete your data. If you wish to delete the personal information we hold about you, please let us know and we will take reasonable steps to respond to your request in accordance with legal requirements.</li>
        <li>restrict processing. Under certain circumstances, you have the right to restrict the processing of your personal information.</li>
        <li>object. Under certain circumstances, you have the right to object to certain types of processing, including processing for direct marketing (i.e., receiving emails from us notifying you or being contacted with varying potential opportunities).</li>
        <li>lodge a complaint with a supervisory authority. You have the right to lodge a complaint directly with any local supervisory authority about how we process your personal information.</li>
        <li>withdraw consent. If you have given your consent to anything we do with your personal information (i.e., we rely on consent as a legal basis for processing your personal information), you have the right to withdraw your consent at any time.</li>
      </ul>
      <p>Further information about your rights can be obtained from the data protection or supervisory authority in your country.</p>
      <AnchorTitle id="your-california-privacy-rights" className={css.anchor}>Your California Privacy Rights</AnchorTitle>
      <p>California law requires us to disclose the categories of personal information we collect and how we use them, the categories of sources from which we collect personal information, and the third parties with whom we share personal information. We collect information from end users like you.</p>
      <p>While Givsly may be a business as defined by the California Consumer Privacy Act as amended (CCPA), it does not sell or share your personal information as these terms are defined under the CCPA. In the preceding 12 months, we have disclosed the following categories of personal information to the following categories of recipients for the stated purposes:</p>
      <table>
        <thead>
          <th>Category of Personal Information</th>
          <th>Categories of Recipients</th>
          <th>Purpose of disclosure</th>
        </thead>
        <tbody>
          <tr>
            <td>Name, email address, phone</td>
            <td>Our Service Providers such as fraud prevention partners, cloud-based providers, nonprofit organization, fulfillment service providers</td>
            <td>
              <ul>
                <li>To protect, investigate, and deter against fraudulent, unauthorized, or illegal activity.</li>
                <li>To enable services for events admissions, promotions, and other activities;</li>
                <li>To respond to your requests or to provide you with information requested by you;</li>
                <li>To communicate with you about updates to our products and services; changes to our Site;</li>
                <li>For other business purposes, such as surveys and, research;</li>
              </ul>
            </td>
          </tr>
          <tr>
            <td>Pseudonymous identifiers</td>
            <td>Data analysis partners, fraud prevention partners, cloud-based service providers</td>
            <td>
              <ul>
                <li>To compile statistics for our internal business purposes about your use of our Site and to better tailor content on our site to you;</li>
                <li>To protect, investigate, fraudulent, unauthorized, or illegal activity;</li>
                <li>For other business purposes, such as determining the effectiveness of our marketing, customize and improve our websites, products and services through.</li>
              </ul>
            </td>
          </tr>
        </tbody>
      </table>
      <p>Under California law, you have the right to access, correct, and request deletion of your data. To exercise these rights, please email {mailToPrivacyAtGivsly}.</p>
      <AnchorTitle id="contact-us" className={css.anchor}>Contact Us</AnchorTitle>
      <p>If you wish to contact us in relation to your personally identifiable information or if you have any queries about how we use your personally identifiable information, please let us know by contacting us at {mailToPrivacyAtGivsly}, or on the “Contact Us” section of our Site.</p>
    </div>
  );
};

PrivacyPolicy.defaultProps = {
  rootClassName: null,
  className: null,
};

const { string } = PropTypes;

PrivacyPolicy.propTypes = {
  rootClassName: string,
  className: string,
};

export default PrivacyPolicy;
