import {
  IMPACT,
  NONPROFIT_INFO,
  NONPROFIT_PHOTOS,
  PAYOUT_DETAILS,
} from '../MyNonprofitWizard/constants';

export const ONBOARDING_PROGRESS = {
  [NONPROFIT_INFO]: 100,
  [NONPROFIT_PHOTOS]: 200,
  [IMPACT]: 250,
  [PAYOUT_DETAILS]: 300,
};
