import React, { Component } from 'react';
import { bool, string } from 'prop-types';
import { compose } from 'redux';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import { Field, Form as FinalForm } from 'react-final-form';
import isEqual from 'lodash/isEqual';
import classNames from 'classnames';
import { ensureCurrentUser } from '../../util/data';
import { propTypes } from '../../util/types';
import * as validators from '../../util/validators';
import {
  Form,
  FieldTextInput,
  FieldTimeZoneSelect,
  LocationAutocompleteInputField,
  FieldSelect,
  ProfileSubmitButtonWrapper,
} from '../../components';
import {
  autocompleteSearchRequired,
  autocompletePlaceSelected,
  composeValidators,
} from '../../util/validators';
import css from './PersonalInfoForm.css';
import AvatarLabel from './AvatarLabel';
import { FieldFileUpload } from './FieldFileUpload';
import config from '../../config';
import FormSectionHeading from '../../components/FormSectionHeading/FormSectionHeading';
import { ONBOARDING_TAB_NUMBERS, PERSONAL_INFO } from '../../components/OnboardingWizard/constants';
import FieldCompanyAutoCompleteInput from '../../components/FieldCompanyAutoCompleteInput/FieldCompanyAutoCompleteInput';

const identity = (v) => v;

const ACCEPT_IMAGES = 'image/*';
const UPLOAD_CHANGE_DELAY = 2000; // Show spinner so that browser has time to load img srcset

// @todo refactor/clean up component
class PersonalInfoFormComponent extends Component {
  constructor(props) {
    super(props);

    this.uploadDelayTimeoutId = null;
    this.state = {
      imageHasChanged: false,
      uploadDelay: false,
    };
    this.submittedValues = {};

    // For some reason uploading image initializes other form fields. To prevent losing filled data, saved current form values to variable.
    this.initialValues = { ...props.initialValues };

    this.getTranslation = this.getTranslation.bind(this);
  }

  componentDidUpdate(prevProps) {
    // Upload delay is additional time window where Avatar is added to the DOM,
    // but not yet visible (time to load image URL from srcset)
    if (prevProps.uploadInProgress && !this.props.uploadInProgress) {
      this.setState({ uploadDelay: true });
      this.uploadDelayTimeoutId = window.setTimeout(() => {
        this.setState({ uploadDelay: false });
      }, UPLOAD_CHANGE_DELAY);
    }

    // Due to some glitch (most likely in react-final-form) the form's pristine state is set back
    // to 'true' after the image upload is processed. So we use this flag as an addition to check
    // if the form may be submitted
    const currentImageId = this.props.profileImage.imageId
      ? this.props.profileImage.imageId.uuid
      : null;
    const previousImageId = prevProps.profileImage.imageId
      ? prevProps.profileImage.imageId.uuid
      : null;
    if (currentImageId !== previousImageId) {
      this.setState({
        imageHasChanged: true,
      });
    }
  }

  componentWillUnmount() {
    window.clearTimeout(this.uploadDelayTimeoutId);
  }

  getTranslation = (name, variables = {}) => {
    return this.props.intl.formatMessage({ id: `PersonalInfoForm.${name}` }, variables);
  };

  render() {
    return (
      <FinalForm
        {...this.props}
        initialValues={this.initialValues}
        mutators={{
          updateLocationBasedTimezone: (args, state, utils) => {
            utils.changeValue(state, 'timezone', (value) => {
              return args[0] || value;
            });
          },
        }}
        render={(fieldRenderProps) => {
          const {
            className,
            currentUser,
            handleSubmit,
            intl,
            invalid,
            onImageUpload,
            pristine,
            profileImage,
            rootClassName,
            updateInProgress,
            updateProfileError,
            uploadImageError,
            uploadInProgress,
            form,
            values,
          } = fieldRenderProps;

          this.initialValues = { ...values };

          const user = ensureCurrentUser(currentUser);
          const { onboardingCompleted, source } = user.attributes.profile.publicData;

          const submitLabelId = onboardingCompleted
            ? 'PersonalInfoForm.saveChanges'
            : 'PersonalInfoForm.next';

          const submitError = updateProfileError ? (
            <div className={css.error}>
              <FormattedMessage id="PersonalInfoForm.updateProfileFailed" />
            </div>
          ) : null;

          const classes = classNames(rootClassName || css.root, className);
          const submitInProgress = updateInProgress;
          const submittedOnce = Object.keys(this.submittedValues).length > 0;
          const pristineSinceLastSubmit = submittedOnce && isEqual(values, this.submittedValues);
          const pristineForm = pristine && !this.state.imageHasChanged;

          const submitDisabled =
            invalid ||
            uploadInProgress ||
            submitInProgress ||
            (onboardingCompleted && (pristineForm || pristineSinceLastSubmit));

          const onSelectPrediction = (predictionData) => {
            if (predictionData.timezone) {
              form.mutators.updateLocationBasedTimezone(predictionData.timezone);
            }
          };

          const avatarLabel = (
            <AvatarLabel
              currentUser={user}
              profileImage={profileImage}
              uploadDelay={this.state.uploadDelay}
              uploadImageError={uploadImageError}
              uploadInProgress={uploadInProgress}
            />
          );

          return (
            <Form
              className={classes}
              onSubmit={(e) => {
                this.submittedValues = values;
                handleSubmit(e);
              }}
            >
              <div className={css.sectionContainer}>
                <FormSectionHeading
                  header={
                    <>
                      <FormattedMessage id="PersonalInfoForm.yourProfilePicture" />
                      <span className={css.sectionTitleSuffix}>
                        {' '}
                        <FormattedMessage id="PersonalInfoForm.yourProfilePicture.suffix" />
                      </span>
                    </>
                  }
                />
                <Field
                  accept={ACCEPT_IMAGES}
                  id="profileImage"
                  name="profileImage"
                  label={avatarLabel}
                  type="file"
                  uploadImageError={uploadImageError}
                  onImageUpload={onImageUpload}
                  disabled={uploadInProgress}
                >
                  {(fieldProps) => <FieldFileUpload {...fieldProps} />}
                </Field>
                <div className={css.tip}>
                  <FormattedMessage id="PersonalInfoForm.tip" />
                </div>
                <div className={css.fileInfo}>
                  <FormattedMessage id="PersonalInfoForm.fileInfo" />
                </div>
              </div>

              <div className={css.sectionContainer}>
                <FormSectionHeading
                  header={intl.formatMessage({ id: 'PersonalInfoForm.bioHeading' })}
                />

                <div className={classNames(css.nameContainer, css.formField)}>
                  <FieldTextInput
                    className={classNames(css.firstName, css.formField)}
                    type="text"
                    id="firstName"
                    name="firstName"
                    label={this.getTranslation('firstNameLabel')}
                    placeholder={this.getTranslation('firstNamePlaceholder')}
                    validate={validators.required(this.getTranslation('firstNameRequired'))}
                  />
                  <FieldTextInput
                    className={classNames(css.lastName, css.formField)}
                    type="text"
                    id="lastName"
                    name="lastName"
                    label={this.getTranslation('lastNameLabel')}
                    placeholder={this.getTranslation('lastNamePlaceholder')}
                    validate={validators.required(this.getTranslation('lastNameRequired'))}
                  />
                </div>
                <LocationAutocompleteInputField
                  rootClassName={css.formField}
                  validClassName={css.validLocation}
                  name="geolocation"
                  label={this.getTranslation('geographicalLocation')}
                  onSelectPrediction={onSelectPrediction}
                  placeholder={this.getTranslation('geographicalLocation.placeholder')}
                  useDefaultPredictions={true}
                  format={identity}
                  valueFromForm={values.geolocation}
                  validate={composeValidators(
                    autocompleteSearchRequired(
                      this.getTranslation('geographicalLocation.required')
                    ),
                    autocompletePlaceSelected(
                      this.getTranslation('geographicalLocation.notRecognized')
                    )
                  )}
                />
                <FieldTimeZoneSelect
                  className={css.formField}
                  id="timezone"
                  name="timezone"
                  label={intl.formatMessage({ id: 'PersonalInfoForm.timezone' })}
                  validate={validators.required(
                    intl.formatMessage({ id: 'PersonalInfoForm.timezoneRequired' })
                  )}
                />
                <FieldTextInput
                  className={css.formField}
                  type="text"
                  id="linkedIn"
                  name="linkedInUrl"
                  label={this.getTranslation('linkedInLabel')}
                  labelSuffix={this.getTranslation('linkedInLabel.suffix')}
                  placeholder={this.getTranslation('linkedInPlaceholder')}
                  validate={validators.validLinkedInURL(this.getTranslation('linkedInUrlValid'))}
                />

                {(!onboardingCompleted || !source) && (
                  <FieldSelect
                    label={intl.formatMessage({ id: 'PersonalInfoForm.source' })}
                    hint={intl.formatMessage({ id: 'PersonalInfoForm.sourceTip' })}
                    className={css.formField}
                    id="source"
                    name="source"
                    validate={validators.required(
                      intl.formatMessage({ id: 'PersonalInfoForm.sourceRequired' })
                    )}
                  >
                    <option value="" disabled>
                      {' '}
                      {intl.formatMessage({ id: 'PersonalInfoForm.sourceOption.placeholder' })}
                    </option>
                    {[
                      'myCompany',
                      'industryContact',
                      'friend',
                      'event',
                      'linkedIn',
                      'facebook',
                      'instagram',
                      'twitter',
                      'podcast',
                      'blog',
                      'search',
                      'other',
                    ].map((translation) => (
                      <option value={translation} key={translation}>
                        {intl.formatMessage({ id: `PersonalInfoForm.sourceOption.${translation}` })}
                      </option>
                    ))}
                  </FieldSelect>
                )}
              </div>

              <div className={classNames(css.sectionContainer, css.lastSection)}>
                <FormSectionHeading
                  header={intl.formatMessage({ id: 'PersonalInfoForm.companyHeading' })}
                />
                <FieldCompanyAutoCompleteInput
                  className={css.formField}
                  type="input"
                  id="companyName"
                  name="companyName"
                  label={this.getTranslation('companyNameLabel')}
                  placeholder={this.getTranslation('companyNamePlaceholder')}
                  validate={validators.required(
                    intl.formatMessage({ id: 'PersonalInfoForm.companyNameRequired' })
                  )}
                />
                <FieldTextInput
                  className={css.formField}
                  type="text"
                  id="companyUrl"
                  name="companyUrl"
                  label={this.getTranslation('companyUrlLabel')}
                  placeholder={this.getTranslation('companyUrlPlaceholder')}
                  validate={validators.validBusinessURL(this.getTranslation('companyUrlValid'))}
                />
                <FieldSelect
                  className={css.formField}
                  defaultOptionLabel={this.getTranslation('industryPlaceholder')}
                  defaultOptionValue={null}
                  id="industry"
                  label={this.getTranslation('industryLabel')}
                  name="industry"
                  showDefaultOption={true}
                  validate={validators.requiredAndNonEmptyString(
                    this.getTranslation('industryRequired')
                  )}
                >
                  {config.custom.industries.sort().map((industry) => {
                    return (
                      <option key={industry} value={industry}>
                        {industry}
                      </option>
                    );
                  })}
                  <option key="Other" value="Other">
                    Other
                  </option>
                </FieldSelect>
                <FieldTextInput
                  className={css.formField}
                  type="text"
                  id="jobTitle"
                  name="jobTitle"
                  label={this.getTranslation('jobTitleLabel')}
                  placeholder={this.getTranslation('jobTitlePlaceholder')}
                  validate={validators.required(this.getTranslation('jobTitleRequired'))}
                />
              </div>

              {submitError}
              <ProfileSubmitButtonWrapper
                stepNumber={ONBOARDING_TAB_NUMBERS[PERSONAL_INFO]}
                inProgress={submitInProgress}
                disabled={submitDisabled}
                ready={pristineSinceLastSubmit}
                onboardingCompleted={onboardingCompleted}
              >
                <FormattedMessage id={submitLabelId} />
              </ProfileSubmitButtonWrapper>
            </Form>
          );
        }}
      />
    );
  }
}

PersonalInfoFormComponent.defaultProps = {
  className: null,
  rootClassName: null,
  updateProfileError: null,
  updateProfileReady: false,
  uploadImageError: null,
};

PersonalInfoFormComponent.propTypes = {
  className: string,
  intl: intlShape.isRequired,
  rootClassName: string,
  updateInProgress: bool.isRequired,
  updateProfileError: propTypes.error,
  updateProfileReady: bool,
  uploadImageError: propTypes.error,
  uploadInProgress: bool.isRequired,
};

const PersonalInfoForm = compose(injectIntl)(PersonalInfoFormComponent);

PersonalInfoForm.displayName = 'PersonalInfoForm';

export default PersonalInfoForm;
