/**
 * This is a wrapper component for different Layouts.
 * Navigational 'aside' content should be added to this wrapper.
 */
import React from 'react';
import { node, number, object, string, shape } from 'prop-types';
import { compose } from 'redux';
import { FormattedMessage } from '../../util/reactIntl';
import { withViewport } from '../../util/contextHelpers';
import { LayoutWrapperSideNav } from '../../components';
import { PARTNERSHIP_TABS } from './constants';

const MAX_HORIZONTAL_NAV_SCREEN_WIDTH = 1023;

const scrollToTab = (currentTab) => {
  const el = document.querySelector(`#${currentTab}Tab`);

  if (el) {
    el.scrollIntoView({
      block: 'end',
      inline: 'end',
      behavior: 'smooth',
    });
  }
};

const PartnerhipsSideNavComponent = (props) => {
  const {
    className = null,
    currentTab,
    viewport,
    tabsDisabled,
    partnershipInformationDisabled,
    causesAndEventsDisabled,
    brandCollaborationsDisabled,
    exclusionsDisabled,
  } = props;

  let hasScrolledToTab = false;
  const { width } = viewport;
  const hasViewport = width > 0;
  const hasHorizontalTabLayout = hasViewport && width <= MAX_HORIZONTAL_NAV_SCREEN_WIDTH;
  const hasVerticalTabLayout = hasViewport && width > MAX_HORIZONTAL_NAV_SCREEN_WIDTH;
  const hasFontsLoaded = hasViewport && document.documentElement.classList.contains('fontsLoaded');

  // Check if scrollToTab call is needed (tab is not visible on mobile)
  if (!hasVerticalTabLayout && hasHorizontalTabLayout && !hasScrolledToTab && hasFontsLoaded) {
    scrollToTab(currentTab);
  }

  const tabs = [
    {
      text: <FormattedMessage id="PartnershipSidenav.approval" />,
      selected: currentTab === PARTNERSHIP_TABS.APPROVAL,
      id: PARTNERSHIP_TABS.APPROVAL,
      completed: !partnershipInformationDisabled,
      disabled: tabsDisabled,
      linkProps: {
        name: 'PartnershipsTabPage',
        params: {
          tab: PARTNERSHIP_TABS.APPROVAL,
        },
      },
    },
    {
      text: <FormattedMessage id="PartnershipSidenav.causes" />,
      selected: currentTab === PARTNERSHIP_TABS.CAUSES,
      completed: !causesAndEventsDisabled,
      id: PARTNERSHIP_TABS.CAUSES,
      disabled: tabsDisabled || partnershipInformationDisabled,
      linkProps: {
        name: 'PartnershipsTabPage',
        params: {
          tab: PARTNERSHIP_TABS.CAUSES,
        },
      },
    },
    {
      text: <FormattedMessage id="PartnershipSidenav.collaborations" />,
      selected: currentTab === PARTNERSHIP_TABS.COLLABORATIONS,
      id: PARTNERSHIP_TABS.COLLABORATIONS,
      completed: !brandCollaborationsDisabled,
      disabled: tabsDisabled || causesAndEventsDisabled,
      linkProps: {
        name: 'PartnershipsTabPage',
        params: {
          tab: PARTNERSHIP_TABS.COLLABORATIONS,
        },
      },
    },
    {
      text: <FormattedMessage id="PartnershipSidenav.exclusions" />,
      selected: currentTab === PARTNERSHIP_TABS.EXCLUSIONS,
      completed: !exclusionsDisabled,
      id: PARTNERSHIP_TABS.EXCLUSIONS,
      disabled: tabsDisabled || brandCollaborationsDisabled,
      linkProps: {
        name: 'PartnershipsTabPage',
        params: {
          tab: PARTNERSHIP_TABS.EXCLUSIONS,
        },
      },
    },
    // {
    //   text: <FormattedMessage id="PartnershipSidenav.assets" />,
    //   selected: currentTab === PARTNERSHIP_TABS.ASSETS,
    //   id: PARTNERSHIP_TABS.ASSETS,
    //   disabled:tabsDisabled,
    //   linkProps: {
    //     name: 'PartnershipsTabPage',
    //     params: {
    //       tab: PARTNERSHIP_TABS.ASSETS,
    //     },
    //   },
    // },
  ];

  return <LayoutWrapperSideNav className={className} tabs={tabs} />;
};

PartnerhipsSideNavComponent.defaultProps = {
  className: null,
  currentUser: null,
  rootClassName: null,
  children: null,
  currentTab: null,
};

PartnerhipsSideNavComponent.propTypes = {
  children: node,
  className: string,
  currentUser: object,
  rootClassName: string,
  currentTab: string,

  // from withViewport
  viewport: shape({
    width: number.isRequired,
    height: number.isRequired,
  }).isRequired,
};

const PartnerhipsSideNav = compose(withViewport)(PartnerhipsSideNavComponent);

export default PartnerhipsSideNav;
