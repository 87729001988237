import { array, bool, func, oneOfType } from 'prop-types';
import React, { useRef, useState, useMemo } from 'react';
import { Button, FieldTextInput, Form, ImageFromFile, NonprofitImpactCard } from '../../components';
import { ICON_PLUS, STYLE_LIGHT } from '../../components/LiteButton/constants';
import LiteButton from '../../components/LiteButton/LiteButton';
import { isUploadImageOverLimitError } from '../../util/errors';
import { useMessages } from '../../util/localization';
import { propTypes } from '../../util/types';
import css from './NonprofitImpactForm.css';
import { Field, Form as FinalForm } from 'react-final-form';
import {} from '../../util/validators';
import { IMPACT_AREA_TEMPLATE } from './constants';
import ImpactAreaSection from './ImpactAreaFields';
import { ensureCurrentUser } from '../../util/data';
import InputFieldWithInfo from './InputFieldWithInfo';
import { LockIcon } from './Icons';
import NewNonprofitImpactCard from '../../components/NewNonprofitImpactCard/NewNonprofitImpactCard';

const IMPACT_VERB_PREFILL = [
  'acuires',
  'adds',
  'assists',
  'compensates for',
  'connects',
  'covers',
  'creates',
  'delivers',
  'deploys',
  'enables',
  'feeds',
  'funds',
  'gifts',
  'gives',
  'grants',
  'helps',
  'launches',
  'offers',
  'offsets',
  'partially funds',
  'plants',
  'provides',
  'removes',
  'restores',
  'shipps',
  'supplies',
  'supports',
  'teaches',
  'trains',
  'transports',
  'treats',
  'tutors',
  'vacciates',
];

const IMPACT_PREPOSITION_PREFILL = ['to', 'for', 'with', 'into'];

const NonprofitImpactForm = (props) => {
  const getMessage = useMessages('NonprofitImpactForm');
  const {
    images,
    isPublished,
    nonprofit,
    onImageUpload,
    onRemoveImage,
    onSubmit,
    impactArea,
    currentUser,
  } = props;
  let pictureRef = useRef(null);
  const [uploadRequested, setUploadRequested] = useState(false);
  const [pictureId, setPictureId] = useState(nonprofit.attributes.publicData.impact.pictureId);
  const [pictureIsDirty, setPictureIsDirty] = useState(false);

  const { description, impactType, impactUnits } = nonprofit.attributes.publicData.impact;

  const {
    impactCost = 100,
    impactVerb = '',
    impactUnitCount = 1,
    impactUnitNameSingle = impactType.singular || '',
    impactUnitNamePlural = impactType.plural || '',
    impactRecipient = description || '',
    impactPreposition = '',
    impactImageUrl = '',
  } = nonprofit.attributes.publicData.impact;

  const picture = pictureId
    ? images.find((i) => {
        return (
          (typeof i.id === 'string' && i.id.startsWith(pictureId)) ||
          (typeof i.id === 'object' && i.id.uuid === pictureId)
        );
      })
    : null;

  const handleImageUpload = async (file) => {
    if (file) {
      setUploadRequested(true);
      if (picture && typeof picture.id) {
        await onRemoveImage(picture.id);
      }
      return onImageUpload({ id: `${file.name}_${Date.now()}`, file })
        .then(() => {
          setPictureId(file.name);
          setPictureIsDirty(true);
        })
        .catch(console.error)
        .finally(() => setUploadRequested(false));
    }
  };

  const initialValues = {
    impactArea: impactArea || [{ ...IMPACT_AREA_TEMPLATE }],
    description,
    impactType: impactType.plural,
    impactUnits,
    pictureId: picture && picture.imageId ? picture.imageId.uuid : picture ? picture.id.uuid : null,
    impactCost,
    impactVerb,
    impactUnitCount,
    impactUnitNameSingle,
    impactUnitNamePlural,
    impactRecipient,
    impactPreposition,
    impactImageUrl,
  };

  return (
    <FinalForm
      onSubmit={onSubmit}
      initialValues={initialValues}
      render={(formRenderProps) => {
        const {
          form,
          handleSubmit,
          pristine,
          submitting,
          uploadImageError,
          uploadImageInProgress,
          values,
        } = formRenderProps;

        // Setup nonprofit preview data set
        const previewNonprofit = {
          ...nonprofit,
        };

        const {
          impactCost,
          impactVerb,
          impactUnitCount,
          impactUnitNameSingle,
          impactUnitNamePlural,
          impactRecipient,
          impactPreposition,
        } = values;

        previewNonprofit.attributes.publicData.impact = {
          pictureId: picture && picture.imageId ? pictureId : picture ? picture.id.uuid : null,
          description: values.description,
          donationSum: 10000,
          impactType: {
            plural: values.impactType,
            singular: values.impactType,
          },
          impactUnits: values.impactUnits,
          impactCost,
          impactVerb,
          impactUnitCount,
          impactUnitNameSingle,
          impactUnitNamePlural,
          impactRecipient,
          impactPreposition,
        };
        previewNonprofit.images = images;

        const disableSubmit = uploadRequested && !pictureIsDirty;
        const ensuredCurrentUser = ensureCurrentUser(currentUser);

        const previewName = previewNonprofit.attributes.title;
        const previewCategory = previewNonprofit.attributes.publicData.organizationCategory;
        const previewMission = previewNonprofit.attributes.description;

        const singleImpactCost = impactCost / impactUnitCount;
        const previewImpactCost = 550;
        const previewImpactUnitCount = Math.round(previewImpactCost / singleImpactCost) || 0;

        let previewImageUrl = '';
        if (picture && 'file' in picture) {
          previewImageUrl = URL.createObjectURL(picture.file);
        }
        if (picture && 'attributes' in picture) {
          previewImageUrl = picture.attributes.variants['square-small2x'].url;
        }

        return (
          <Form
            className={css.root}
            onSubmit={(e) => {
              handleSubmit(e)
                .then(() => setPictureIsDirty(false))
                .catch(console.error);
            }}
          >
            <section className={css.section}>
              <h3 className={css.sectionTitle}>{getMessage('section.picture.title')}</h3>
              <Field
                accept={'image/*'}
                id="picture"
                name="picture"
                type="file"
                form={null}
                uploadImageError={uploadImageError}
                disabled={uploadImageInProgress}
              >
                {(fieldProps) => {
                  const { accept, id, input, disabled, uploadImageError } = fieldProps;
                  const { name, type } = input;
                  const onChange = (e) => {
                    const file = e.target.files[0];
                    form.change(`addImage`, file);
                    form.blur(`addImage`);
                    handleImageUpload(file).catch(console.error);
                  };

                  let error = null;

                  if (isUploadImageOverLimitError(uploadImageError)) {
                    error = (
                      <div className={css.error}>
                        {getMessage('field.picture.error.fileTooLarge')}
                      </div>
                    );
                  } else if (uploadImageError) {
                    error = (
                      <div className={css.error}>
                        {getMessage('field.picture.error.uploadFailed')}
                      </div>
                    );
                  }

                  return (
                    <div className={css.pictureUploadWrapper}>
                      <div className={css.picturePlaceholder}>
                        {picture && picture.file ? (
                          <ImageFromFile
                            aspectRatioClassName={css.pictureAspectWrapper}
                            className={css.picture}
                            id={picture.id}
                            file={picture.file}
                          />
                        ) : picture ? (
                          <img
                            alt={picture.id.uuid}
                            className={css.picture}
                            src={picture.attributes.variants['square-small2x'].url}
                          />
                        ) : (
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="240"
                            height="240"
                            viewBox="0 0 240 240"
                          >
                            <g fill="none" fillRule="evenodd">
                              <g>
                                <g>
                                  <g>
                                    <g transform="translate(-492 -509) translate(407 44) translate(84 401) translate(1 64)">
                                      <mask id="0a7wsst68b" fill="#fff">
                                        <circle id="3pt36ynmza" cx="120" cy="120" r="120" />
                                      </mask>
                                      <circle id="3pt36ynmza" cx="120" cy="120" r="120" />
                                      <path
                                        fill="#FAC51D"
                                        d="M159.597 131.393l11.34 11.31L119.5 194l-51.437-51.297 11.34-11.31L119.5 171.38l40.097-39.987zm-56.55-28.366c9.395-9.37 24.628-9.37 34.023 0 9.395 9.37 9.395 24.561 0 33.931-9.396 9.369-24.628 9.37-34.023 0-9.396-9.37-9.396-24.56 0-33.93zm18.154-26.1c15.946-15.903 41.893-15.903 57.84 0 15.946 15.903 15.946 41.779 0 57.682l-11.342-11.31c9.692-9.667 9.692-25.395 0-35.062-9.693-9.666-25.464-9.666-35.157 0zm-61.242 0c15.947-15.903 41.893-15.903 57.84 0l-11.341 11.31c-9.693-9.666-25.465-9.666-35.158 0-9.693 9.667-9.692 25.395 0 35.062l-11.34 11.31c-15.947-15.903-15.946-41.78 0-57.682z"
                                        mask="url(#0a7wsst68b)"
                                      />
                                    </g>
                                  </g>
                                </g>
                              </g>
                            </g>
                          </svg>
                        )}
                      </div>
                      <input
                        accept={accept}
                        id={id}
                        name={name}
                        className={css.pictureUploadInput}
                        disabled={disabled}
                        onChange={onChange}
                        ref={pictureRef}
                        type={type}
                      />
                      <input type={'hidden'} id={'pictureId'} name={'pictureId'} />
                      <LiteButton
                        className={css.pictureButton}
                        icon={ICON_PLUS}
                        inProgress={uploadRequested}
                        onClick={() => pictureRef.current.click()}
                        style={STYLE_LIGHT}
                      >
                        {getMessage('field.picture.buttonLabel')}
                      </LiteButton>
                      {error}
                    </div>
                  );
                }}
              </Field>
            </section>

            <section>
              <h3 className={css.sectionTitle}>{getMessage('section.details.title')}</h3>
              <p className={css.sectionDescription}>{getMessage('section.details.description')}</p>
              <div className={css['impact-input-section']}>
                <div className={css['input-row']}>
                  <InputFieldWithInfo
                    width={128}
                    name="impactCost"
                    additionalRightIcon={<span>$</span>}
                    type="text"
                    infoLabel="Cost of impact"
                    info="Express what a unit of your impact costs to you so we calculate what a donation can cover"
                  />
                  <InputFieldWithInfo
                    grow
                    name="impactVerb"
                    type="text"
                    dataList={IMPACT_VERB_PREFILL}
                    dataListId={'impact-verbs'}
                    infoLabel="What the donation sum does"
                    info="Provide a verb on how you bring impact"
                  />
                </div>
                <div className={css['input-row']}>
                  <InputFieldWithInfo
                    width={128}
                    name="impactUnitCount"
                    type="text"
                    additionalRightIcon={<LockIcon />}
                    inputMode="numeric"
                    infoLabel="Units of impact"
                    disabled
                    info="We have locked this to 1 to help us calculate the impact for different amounts"
                  />
                  <InputFieldWithInfo
                    grow
                    name="impactUnitNameSingle"
                    type="text"
                    additionalRightIcon={<span>single</span>}
                    infoLabel="What is your impact unit"
                    info="Describe what type of impact you create"
                  />
                  <InputFieldWithInfo
                    grow
                    name="impactUnitNamePlural"
                    type="text"
                    infoLabel="What is your impact unit in plural"
                    info="Give the same unit as a plural to help us express multiples"
                    additionalRightIcon={<span>plural</span>}
                  />
                </div>
                <div className={css['input-row']}>
                  <InputFieldWithInfo
                    width={128}
                    name="impactPreposition"
                    type="text"
                    infoLabel="Preposition"
                    info="Help us complete the sentence"
                    dataList={IMPACT_PREPOSITION_PREFILL}
                    dataListId={'impact-prepositions'}
                  />
                  <InputFieldWithInfo
                    grow
                    name="impactRecipient"
                    type="text"
                    infoLabel="The recipient of benefactor"
                    info="Elaborate who experiences or gets the impact you deliver"
                  />
                </div>
              </div>
            </section>
            <section className={css.section}>
              <h3 className={css.sectionTitle}>{getMessage('section.preview.title')}</h3>
              <div className={css.preview}>
                <NewNonprofitImpactCard
                  name={previewName}
                  category={previewCategory}
                  mission={previewMission}
                  impactCost={previewImpactCost}
                  impactVerb={impactVerb}
                  impactUnitCount={previewImpactUnitCount}
                  impactUnitNameSingle={impactUnitNameSingle}
                  impactUnitNamePlural={impactUnitNamePlural}
                  impactRecipient={impactRecipient}
                  impactPreposition={impactPreposition}
                  imageUrl={previewImageUrl}
                />
                <p className={css.caption}>
                  The preview is shown as it would look with a donation of $550.00.
                </p>
              </div>
            </section>
            <ImpactAreaSection
              ensuredCurrentUser={ensuredCurrentUser}
              values={values}
              form={form}
            />

            <section className={css.section}>
              <Button className={css.submit} disabled={disableSubmit} inProgress={submitting}>
                {getMessage(isPublished ? 'button.save' : 'button.continue')}
              </Button>
            </section>
          </Form>
        );
      }}
    />
  );
};

NonprofitImpactForm.propTypes = {
  images: array,
  isPublished: bool,
  nonprofit: oneOfType([propTypes.listing, propTypes.ownListing]),
  onImageUpload: func.isRequired,
  onRemoveImage: func.isRequired,
  onSubmit: func.isRequired,
};

NonprofitImpactForm.defaultProps = {
  images: [],
  isPublished: true,
};

export default NonprofitImpactForm;
