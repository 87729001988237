import React, { Component } from 'react';
import { compose } from 'redux';
import { object, string, bool, number, func, shape } from 'prop-types';
import { injectIntl, intlShape } from '../../util/reactIntl';
import classNames from 'classnames';
import { withRouter } from 'react-router-dom';
import omit from 'lodash/omit';

import { KeywordFilter, MultiSelectFilter } from '../../components';
import routeConfiguration from '../../routeConfiguration';
import { createResourceLocatorString } from '../../util/routes';
import { propTypes } from '../../util/types';
import css from './NonprofitFilters.css';

// Dropdown container can have a positional offset (in pixels)
const FILTER_DROPDOWN_OFFSET = -14;

// resolve initial value for a single value filter
const initialValue = (queryParams, paramName) => {
  return queryParams[paramName];
};

class NonprofitFiltersComponent extends Component {
  render() {
    const {
      categoryFilter,
      className,
      hash,
      intl,
      listingsAreLoaded,
      locationFilter,
      resultsCount,
      history,
      keywordFilter,
      pageName,
      pagePathParams,
      rootClassName,
      urlQueryParams,
      trackEvent,
    } = this.props;

    const hasNoResult = listingsAreLoaded && resultsCount === 0;
    const classes = classNames(
      rootClassName || css.root,
      { [css.longInfo]: hasNoResult },
      className
    );
    const allLabel = intl.formatMessage({ id: 'NonprofitFilters.all' });

    const initialCategory = categoryFilter
      ? initialValue(urlQueryParams, categoryFilter.paramName)
      : null;

    const initialLocation = locationFilter
      ? initialValue(urlQueryParams, locationFilter.paramName)
      : null;

    const initialKeyword = keywordFilter
      ? initialValue(urlQueryParams, keywordFilter.paramName)
      : null;

    const handleSelectOption = (urlParam, option) => {
      if (trackEvent) {
        trackEvent({
          action: 'Select',
          label: 'Nonprofit-location',
          value: option.location || 'all',
        });
      }
      // query parameters after selecting the option
      // if no option is passed, clear the selection for the filter
      const queryParams = option
        ? { ...urlQueryParams, [urlParam]: option.location }
        : omit(urlQueryParams, urlParam);

      history.push(
        createResourceLocatorString(
          pageName,
          routeConfiguration(),
          pagePathParams,
          queryParams,
          hash
        )
      );
    };

    const handleSelectCategory = (urlParam, option) => {
      if (trackEvent) {
        trackEvent({
          action: 'Select',
          label: 'Nonprofit-category',
          value: option.category || 'all',
        });
      }

      const queryParams = option
        ? { ...urlQueryParams, [urlParam]: option.category }
        : omit(urlQueryParams, urlParam);

      history.push(
        createResourceLocatorString(
          pageName,
          routeConfiguration(),
          pagePathParams,
          queryParams,
          hash
        )
      );
    };

    const handleKeyword = (urlParam, values) => {
      if (trackEvent && values) {
        trackEvent({
          action: 'Select',
          label: 'Nonprofit-keyword',
          value: values,
        });
      }

      const queryParams = values
        ? { ...urlQueryParams, [urlParam]: values }
        : omit(urlQueryParams, urlParam);

      history.push(
        createResourceLocatorString(
          pageName,
          routeConfiguration(),
          pagePathParams,
          queryParams,
          hash
        )
      );
    };

    return (
      <div className={classes}>
        <div className={css.filters}>
          <MultiSelectFilter
            defaultOptionKey="all"
            defaultOptionLabel={allLabel}
            defaultOptionValue=""
            id={'NonprofitFilters.locationFilter'}
            initialValue={initialLocation}
            label={intl.formatMessage({ id: 'NonprofitFilters.locationLabel' })}
            name="location"
            onSelect={handleSelectOption}
            options={locationFilter.options}
            rootClassName={css.locationFilter}
            showAsPopup={false}
            showDefaultOption={true}
            showLabel={false}
            urlParam={locationFilter.paramName}
          />
          <MultiSelectFilter
            defaultOptionKey="all"
            defaultOptionLabel={allLabel}
            defaultOptionValue=""
            id={'NonprofitFilters.categoryFilter'}
            initialValue={initialCategory}
            label={intl.formatMessage({ id: 'NonprofitFilters.categoryLabel' })}
            name="category"
            onSelect={handleSelectCategory}
            options={categoryFilter.options}
            rootClassName={css.categoryFilter}
            showAsPopup={false}
            showDefaultOption={true}
            showLabel={false}
            urlParam={categoryFilter.paramName}
          />
          <KeywordFilter
            contentPlacementOffset={FILTER_DROPDOWN_OFFSET}
            id={'NonprofitFilters.keywordFilter'}
            inputRootClass={css.keywordFilterInput}
            name="keyword"
            onSubmit={handleKeyword}
            label={''}
            rootClassName={css.keywordFilter}
            showAsPopup={false}
            showLabel={false}
            initialValues={initialKeyword}
            urlParam={keywordFilter.paramName}
          />
        </div>
      </div>
    );
  }
}

NonprofitFiltersComponent.defaultProps = {
  className: null,
  categoryFilter: null,
  locationFilter: null,
  pageName: 'SearchPage',
  pagePathParams: {},
  resultsCount: null,
  rootClassName: null,
  searchFiltersPanelSelectedCount: 0,
};

NonprofitFiltersComponent.propTypes = {
  categoriesFilter: propTypes.filterConfig,
  className: string,
  hash: string,
  // from withRouter
  history: shape({
    push: func.isRequired,
  }).isRequired,
  // from injectIntl
  intl: intlShape.isRequired,
  listingsAreLoaded: bool.isRequired,
  locationFilter: propTypes.filterConfig,
  onManageDisableScrolling: func.isRequired,
  pageName: string,
  pagePathParams: object,
  rootClassName: string,
  resultsCount: number,
  searchFiltersPanelSelectedCount: number,
  urlQueryParams: object.isRequired,
  trackEvent: func,
};

const NonprofitFilters = compose(withRouter, injectIntl)(NonprofitFiltersComponent);

export default NonprofitFilters;
