import React, { useState, useCallback, useEffect, useMemo } from 'react';
import * as css from '../NonprofitPreferencesPanel.css';
import { useMessages } from '../../../util/localization';
import FieldTextInput from '../../FieldTextInput/FieldTextInput';

import FieldSelect from '../../FieldSelect/FieldSelect';
import LiteButton from '../../LiteButton/LiteButton';
import { ICON_PLUS, STYLE_LIGHT } from '../../LiteButton/constants';
import { fetchRegions, fetchCountries, fetchStates } from '../../../util/outreach';
import { IMPACT_AREA_TEMPLATE } from '../constants';
import RemoveFieldButton from '../RemoveFieldButton';

const ImpactAreaFields = ({ ensuredCurrentUser, scope, values, impactArea, regions, form }) => {
  const getMessage = useMessages('NonprofitPreferencesForm');
  const [impactCountries, setImpactCountries] = useState([]);
  const [setImpactCountriesError] = useState();
  const [setImpactCountriesLoading] = useState();

  const selectedRegion = useMemo(() => {
    const [key, index] = scope.split('.');
    const areaValue = values[key][index] || {};
    return regions.find((region) => region.name === areaValue.region);
  }, [scope, values, regions]);

  const getCountries = useCallback((region, signal) => {
    setImpactCountriesLoading(true);
    setImpactCountries([]);
    setImpactCountriesError(undefined);
    fetchCountries(ensuredCurrentUser, region, signal)
      .then((result) => setImpactCountries(result))
      .catch((error) => setImpactCountriesError(error))
      .finally(() => setImpactCountriesLoading(false));
  }, [
    ensuredCurrentUser
  ]);

  useEffect(() => {
    if (selectedRegion) {
      const abortController = new AbortController();
      getCountries(selectedRegion, abortController.signal);
      return () => {
        abortController.abort();
      };
    }
  }, [getCountries, selectedRegion]);

  const [impactStates, setImpactStates] = useState([]);
  const [setImpactStatesError] = useState();
  const [setImpactStatesLoading] = useState();

  const selectedCountry = useMemo(() => {
    const [key, index] = scope.split('.');
    const areaValue = values[key][index] || {};
    return areaValue.country;
  }, [scope, values]);

  const getStates = useCallback((country, signal) => {
    setImpactStatesLoading(true);
    setImpactStates([]);
    setImpactStatesError(undefined);
    fetchStates(ensuredCurrentUser, country, signal)
      .then((result) => setImpactStates(result))
      .catch((error) => setImpactStatesError(error))
      .finally(() => setImpactStatesLoading(false));
  }, [
    ensuredCurrentUser
  ]);

  useEffect(() => {
    if (selectedCountry) {
      const abortController = new AbortController();
      getStates(selectedCountry, abortController.signal);
      return () => {
        abortController.abort();
      };
    }
  }, [getStates, selectedCountry]);

  const selectedState = useMemo(() => {
    const [key, index] = scope.split('.');
    const areaValue = values[key][index] || {};
    return areaValue.state;
  }, [scope, values]);

  return (
    <div className={css.impactAreaRow}>
      <FieldSelect
        className={css.field}
        defaultOptionLabel={getMessage('field.impactArea.region.placeholder')}
        defaultOptionValue=""
        id={`${scope}.region`}
        label={getMessage('field.impactArea.region.label')}
        name={`${scope}.region`}
        showDefaultOption={true}
        onChange={(region) => {
          form.change(scope, { region });
        }}
      >
        {regions.map((region) => {
          return (
            <option key={region.name} value={region.name}>
              {region.name}
            </option>
          );
        })}
      </FieldSelect>
      <FieldSelect
        disabled={!selectedRegion}
        className={css.field}
        defaultOptionLabel={getMessage('field.impactArea.country.placeholder')}
        defaultOptionValue=""
        id={`${scope}.country`}
        label={getMessage('field.impactArea.country.label')}
        name={`${scope}.country`}
        showDefaultOption={true}
        onChange={(country) => {
          const [key, index] = scope.split('.');
          const { region } = values[key][index] || {};
          form.change(scope, { region, country });
        }}
      >
        {impactCountries.map((country) => {
          return (
            <option key={country.name} value={country['alpha-2']}>
              {country.name}
            </option>
          );
        })}
      </FieldSelect>

      <FieldSelect
        disabled={!selectedCountry}
        className={css.field}
        defaultOptionLabel={getMessage('field.impactArea.state.placeholder')}
        defaultOptionValue=""
        id={`${scope}.state`}
        label={getMessage('field.impactArea.state.label')}
        name={`${scope}.state`}
        showDefaultOption={true}
        onChange={(state) => {
          const [key, index] = scope.split('.');
          const { country, region } = values[key][index] || {};
          form.change(scope, { country, region, state });
        }}
      >
        {impactStates.map((state) => {
          return (
            <option key={state.name} value={state.code}>
              {state.name}
            </option>
          );
        })}
      </FieldSelect>

      <div style={{ position: 'relative' }}>
        <FieldTextInput
          disabled={!selectedState}
          className={css.field}
          label={getMessage('field.impactArea.city.label')}
          id={`${scope}.city`}
          name={`${scope}.city`}
          placeholder={getMessage('field.impactArea.city.placeholder')}
          type={'text'}
        />
        <RemoveFieldButton
          onClick={() =>
            form.change(
              'impactArea',
              values.impactArea.filter((_area) => _area !== impactArea)
            )
          }
        />
      </div>
    </div>
  );
};

const ImpactAreaSection = (formRenderProps) => {
  const getMessage = useMessages('NonprofitPreferencesForm');
  const { values, ensuredCurrentUser, form } = formRenderProps;

  const [impactRegions, setImpactRegions] = useState([]);

  useEffect(() => {
    const abortController = new AbortController();
    fetchRegions(ensuredCurrentUser, abortController.signal).then((result) =>
      setImpactRegions(result)
    );
    return () => {
      abortController.abort();
    };
  }, [
    ensuredCurrentUser
  ]);

  return (
    <section className={css.section}>
      <h3 className={css.sectionTitle}>{getMessage('section.impactArea.title')}</h3>
      <p className={css.small}>{getMessage('section.impactArea.description')}</p>
      {values.impactArea.map((impactArea, index) => (
        <React.Fragment key={index}>
          {index > 0 && <hr className={css.divider} />}
          <ImpactAreaFields
            form={form}
            regions={impactRegions}
            values={formRenderProps.values}
            impactArea={impactArea}
            scope={`impactArea.${index}`}
            ensuredCurrentUser={ensuredCurrentUser}
          />
        </React.Fragment>
      ))}
      <br />
      <LiteButton
        icon={ICON_PLUS}
        onClick={() =>
          form.change('impactArea', [...values.impactArea, { ...IMPACT_AREA_TEMPLATE }])
        }
        style={STYLE_LIGHT}
      >
        {getMessage('liteButton.new')}
      </LiteButton>
    </section>
  );
};

export default ImpactAreaSection;
