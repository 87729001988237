import React from 'react';
import { array, bool, func, number, object } from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import {
  CONTENT_INITIAL,
  CONTENT_MAIN,
  CONTENT_SIDE,
} from '../../components/SlidePanel/SlidePanel';
import { addCredit, getBalance } from '../../ducks/credit.duck';
import { withMessages } from '../../util/localization';
import { ensureCurrentUser, ensureStripeCustomer, ensurePaymentMethodCard } from '../../util/data';
import { getClientSecret, getPaymentParams } from '../../util/stripe';
import { propTypes } from '../../util/types';
import { savePaymentMethod, deletePaymentMethod } from '../../ducks/paymentMethods.duck';
import { handleCardSetup } from '../../ducks/stripe.duck';
import { manageDisableScrolling, isScrollingDisabled } from '../../ducks/UI.duck';
import {
  LayoutSideNavigation,
  LayoutWrapperMain,
  LayoutWrapperAccountSettingsSideNav,
  LayoutWrapperTopbar,
  LayoutWrapperFooter,
  Footer,
  Page,
  UserNav,
  SavedCards,
  SavedCredit,
  SlidePanel,
} from '../../components';
import { TopbarContainer } from '../../containers';
import { PaymentMethodsForm } from '../../forms';
import { createStripeSetupIntent, stripeCustomer, loadData } from './PaymentMethodsPage.duck.js';
import css from './PaymentMethodsPage.css';

class PaymentMethodsPageComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      activeContent: CONTENT_INITIAL,
      cardState: null,
      isSubmitting: false,
      paymentMethodFormMode: 'add',
    };

    this.handleBack = this.handleBack.bind(this);
    this.handleRemovePaymentMethod = this.handleRemovePaymentMethod.bind(this);
    this.handleSetDefaultPaymentMethod = this.handleSetDefaultPaymentMethod.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleUpdatePaymentMethod = this.handleUpdatePaymentMethod.bind(this);
  }

  handleBack() {
    this.setState({ activeContent: CONTENT_MAIN });
  }

  handleSubmit = (params) => {
    this.setState({ isSubmitting: true });

    const {
      currentUser,
      fetchStripeCustomer,
      onCreateSetupIntent,
      onHandleCardSetup,
      onSavePaymentMethod,
    } = this.props;
    const ensuredCurrentUser = ensureCurrentUser(currentUser);
    const stripeCustomer = ensuredCurrentUser.stripeCustomer;
    const { stripe, card, formValues } = params;

    return onCreateSetupIntent()
      .then((setupIntent) => {
        const stripeParams = {
          stripe,
          card,
          setupIntentClientSecret: getClientSecret(setupIntent),
          paymentParams: getPaymentParams(currentUser, formValues),
        };
        console.log('Stripe params', stripeParams);

        return onHandleCardSetup(stripeParams);
      })
      .then((result) => {
        const newPaymentMethod = result.setupIntent.payment_method;
        // Note: stripe.handleCardSetup might return an error inside successful call (200), but those are rejected in thunk functions.

        return onSavePaymentMethod(stripeCustomer, newPaymentMethod);
      })
      .then(() => {
        // Update currentUser entity and its sub entities: stripeCustomer and defaultPaymentMethod
        fetchStripeCustomer();
        this.setState({
          cardState: 'default',
          isSubmitting: false,
        });
      })
      .catch((error) => {
        console.error(error);
        this.setState({ isSubmitting: false });
      });
  };

  handleRemovePaymentMethod = () => {
    const { onDeletePaymentMethod, fetchStripeCustomer } = this.props;
    onDeletePaymentMethod().then(() => {
      fetchStripeCustomer();
    });
  };

  handleSetDefaultPaymentMethod() {
    // @todo implement
  }
  handleUpdatePaymentMethod() {
    // @todo implement
  }

  render() {
    const {
      creditCodes,
      creditTotal,
      currentUser,
      getBalanceInProgress,
      getMessage,
      addPaymentMethodError,
      deletePaymentMethodError,
      createStripeCustomerError,
      handleCardSetupError,
      deletePaymentMethodInProgress,
      scrollingDisabled,
      onAddCredit,
      onGetBalance,
      onManageDisableScrolling,
      stripeCustomerFetched,
      timezone,
    } = this.props;

    const { isSubmitting } = this.state;

    const ensuredCurrentUser = ensureCurrentUser(currentUser);
    const ensuredPaymentMethodCard = ensuredCurrentUser.stripeCustomer
      ? ensurePaymentMethodCard(ensuredCurrentUser.stripeCustomer.defaultPaymentMethod)
      : ensurePaymentMethodCard({});
    const ensuredStripeCustomer = ensureStripeCustomer(ensuredCurrentUser.stripeCustomer);
    const currentUserLoaded = !!ensuredCurrentUser.id;

    const hasDefaultPaymentMethod =
      currentUserLoaded &&
      ensuredStripeCustomer.attributes.stripeCustomerId &&
      ensuredPaymentMethodCard.id;

    // Get first and last name of the current user and use it in the StripePaymentForm to autofill the name field
    const userName = currentUserLoaded
      ? `${ensuredCurrentUser.attributes.profile.firstName} ${ensuredCurrentUser.attributes.profile.lastName}`
      : null;

    const initialValuesForStripePayment = { name: userName };

    const card = hasDefaultPaymentMethod ? ensuredPaymentMethodCard.attributes.card : null;

    // const showForm = cardState === 'replaceCard' || !hasDefaultPaymentMethod;
    // const showCardDetails = !!hasDefaultPaymentMethod;

    return (
      <Page title={getMessage('pageTitle')} scrollingDisabled={scrollingDisabled}>
        <LayoutSideNavigation>
          <LayoutWrapperTopbar>
            <TopbarContainer
              currentPage="PaymentMethodsPage"
              desktopClassName={css.desktopTopbar}
              mobileClassName={css.mobileTopbar}
            />
            <UserNav currentUser={ensuredCurrentUser} selectedPageName="PaymentMethodsPage" />
          </LayoutWrapperTopbar>
          <LayoutWrapperAccountSettingsSideNav
            currentTab="PaymentMethodsPage"
            currentUser={ensuredCurrentUser}
          />
          <LayoutWrapperMain className={css.layoutWrapper}>
            <div className={css.content}>
              <SlidePanel
                activeContent={this.state.activeContent}
                mainContent={
                  <div className={css.mainPanel}>
                    <h1 className={css.title}>{getMessage('heading')}</h1>
                    <SavedCards
                      cards={card ? [card] : []}
                      onAddCard={() =>
                        this.setState({
                          activeContent: CONTENT_SIDE,
                          paymentMethodFormMode: 'add',
                        })
                      }
                      onEditCard={() =>
                        this.setState({
                          activeContent: CONTENT_SIDE,
                          paymentMethodFormMode: 'edit',
                        })
                      }
                      onManageDisableScrolling={onManageDisableScrolling}
                      onChange={(newCardState) => this.setState({ cardState: newCardState })}
                      onDeleteCard={this.handleRemovePaymentMethod}
                      onSetAsDefault={this.handleSetDefaultPaymentMethod}
                      deletePaymentMethodInProgress={deletePaymentMethodInProgress}
                      stripeCustomerFetched={stripeCustomerFetched}
                    />
                    <SavedCredit
                      className={css.savedCredit}
                      creditCodes={creditCodes}
                      creditTotal={creditTotal}
                      getBalanceInProgress={getBalanceInProgress}
                      onAddCredit={onAddCredit}
                      onGetBalance={onGetBalance}
                      onManageDisableScrolling={onManageDisableScrolling}
                      timezone={timezone}
                    />
                  </div>
                }
                sideContent={
                  <div className={css.sidePanel}>
                    <button className={css.back} onClick={this.handleBack} type={'button'}>
                      {getMessage('back')}
                    </button>
                    <h1 className={css.title}>
                      {getMessage(`form.${this.state.paymentMethodFormMode}`)}
                    </h1>
                    <PaymentMethodsForm
                      className={css.paymentForm}
                      formId="PaymentMethodsForm"
                      initialValues={initialValuesForStripePayment}
                      onSubmit={this.handleSubmit}
                      handleRemovePaymentMethod={this.handleRemovePaymentMethod}
                      hasDefaultPaymentMethod={hasDefaultPaymentMethod}
                      addPaymentMethodError={addPaymentMethodError}
                      deletePaymentMethodError={deletePaymentMethodError}
                      createStripeCustomerError={createStripeCustomerError}
                      handleCardSetupError={handleCardSetupError}
                      inProgress={isSubmitting}
                      onCancel={this.handleBack}
                    />
                  </div>
                }
              />
            </div>
          </LayoutWrapperMain>
          <LayoutWrapperFooter>
            <Footer />
          </LayoutWrapperFooter>
        </LayoutSideNavigation>
      </Page>
    );
  }
}

PaymentMethodsPageComponent.defaultProps = {
  creditCodes: [],
  creditTotal: 0,
  currentUser: null,
  addPaymentMethodError: null,
  deletePaymentMethodError: null,
  createStripeCustomerError: null,
  handleCardSetupError: null,
};

PaymentMethodsPageComponent.propTypes = {
  creditCodes: array,
  creditTotal: number,
  currentUser: propTypes.currentUser,
  getMessage: func.isRequired,
  scrollingDisabled: bool.isRequired,
  addPaymentMethodError: object,
  deletePaymentMethodError: object,
  createStripeCustomerError: object,
  handleCardSetupError: object,
  onAddCredit: func.isRequired,
  onCreateSetupIntent: func.isRequired,
  onHandleCardSetup: func.isRequired,
  onSavePaymentMethod: func.isRequired,
  onDeletePaymentMethod: func.isRequired,
  fetchStripeCustomer: func.isRequired,
};

const mapStateToProps = (state) => {
  const { currentUser, timezone } = state.user;

  const {
    deletePaymentMethodInProgress,
    addPaymentMethodError,
    deletePaymentMethodError,
    createStripeCustomerError,
  } = state.paymentMethods;

  const { creditCodes, creditTotal, getBalanceInProgress } = state.credit;

  const { stripeCustomerFetched } = state.PaymentMethodsPage;

  const { handleCardSetupError } = state.stripe;
  return {
    creditCodes,
    creditTotal,
    currentUser,
    getBalanceInProgress,
    scrollingDisabled: isScrollingDisabled(state),
    deletePaymentMethodInProgress,
    addPaymentMethodError,
    deletePaymentMethodError,
    createStripeCustomerError,
    handleCardSetupError,
    stripeCustomerFetched,
    timezone,
  };
};

const mapDispatchToProps = (dispatch) => ({
  onAddCredit: (code) => dispatch(addCredit(code)),
  onGetBalance: () => dispatch(getBalance()),
  onManageDisableScrolling: (componentId, disableScrolling) =>
    dispatch(manageDisableScrolling(componentId, disableScrolling)),
  fetchStripeCustomer: () => dispatch(stripeCustomer()),
  onHandleCardSetup: (params) => dispatch(handleCardSetup(params)),
  onCreateSetupIntent: (params) => dispatch(createStripeSetupIntent(params)),
  onSavePaymentMethod: (stripeCustomer, newPaymentMethod) =>
    dispatch(savePaymentMethod(stripeCustomer, newPaymentMethod)),
  onDeletePaymentMethod: (params) => dispatch(deletePaymentMethod(params)),
});

const withLocalizedMessages = (component) => {
  return withMessages(component, 'PaymentMethodsPage');
};

const PaymentMethodsPage = compose(
  connect(mapStateToProps, mapDispatchToProps),
  withLocalizedMessages
)(PaymentMethodsPageComponent);

PaymentMethodsPage.loadData = loadData;

export default PaymentMethodsPage;
