import React from 'react';
import { AvailabilityForm } from '../../forms/index';
import { arrayOf, func, shape } from 'prop-types';
import { propTypes } from '../../util/types';
import { string } from 'prop-types';
import { ensureCurrentUser, ensureOwnListing } from '../../util/data';
import moment from 'moment';
import { AVAILABILITY, PUBLISH } from '../OnboardingWizard/constants';
import { types as sdkTypes } from '../../util/sdkLoader';
import {
  calculateNthWeekdayOfMonth,
  RECURS_EVENT_DAYS,
  RECURS_EVERY_TWO_WEEKS,
  RECURS_MONTHLY,
  RECURS_NEVER,
  RECURS_WEEKLY,
} from '../../util/dates';
import { IconSpinner } from '../index';
import classNames from 'classnames';
import css from './AvailabilityPanel.css';
import {
  convertAllToTimezone,
  DATE_FORMAT,
  expandDates,
  filterWithinRange,
} from '../../util/timeSlots';
import { TYPE_NEW_TIMES_AVAILABLE } from '../../util/notifications';
import { getEvent } from '../../util/events';
import { userDataUtil } from '@givsly/sharetribe-utils';

const { ACTIVITY_UPDATE_AVAILABILITY } = userDataUtil;
const { UUID } = sdkTypes;

/**
 * @todo refactor
 *
 * This component should be refactored such that the mechanism that creates and stores reservable
 * slots and availability exceptions inside availabilityException.duck and reservableSlot.duck is
 * used. These ducks contain logic which attempts to be centralized and free of MomentJS usage as
 * much as possible.
 *
 * Finally, this component should only handle view logic.
 */
class AvailabilityPanel extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      exceededWeeklyLimitModalIsOpen: false,
      hasExceededWeeklyLimit: false,
      timeSlotUpdateInProgress: false,
      timeSlotUpdateErrors: false,
      reservableSlots: [],
    };

    this.onDeleteTimeSlot = this.onDeleteTimeSlot.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.onSubmitTimeSlot = this.onSubmitTimeSlot.bind(this);
    this.createTimeSlot = this.createTimeSlot.bind(this);
    this.createTimeSlots = this.createTimeSlots.bind(this);
    this.createMonthlyRecurringSlots = this.createMonthlyRecurringSlots.bind(this);
    this.createWeeklyRecurringSlots = this.createWeeklyRecurringSlots.bind(this);
    this.createEventDaysRecurringSlots = this.createEventDaysRecurringSlots.bind(this);
    this.clearTimeSlot = this.clearTimeSlot.bind(this);
    this.exceedsWeeklyLimit = this.exceedsWeeklyLimit.bind(this);

    this.props.currentUserListing.attributes.publicData.reservableSlots = this.props
      .currentUserListing.attributes.publicData.reservableSlots
      ? this.props.currentUserListing.attributes.publicData.reservableSlots
      : [];
  }

  static getDerivedStateFromProps(props, state) {
    const ensuredOwnListing = ensureOwnListing(props.currentUserListing);
    const { timezone } = props;
    const { reservableSlots } = ensuredOwnListing.attributes.publicData;
    const derivedState = {};

    // Convert and sort the reservable slots if they have changed
    if (state.reservableSlots.length !== reservableSlots.length && timezone) {
      derivedState.reservableSlots = convertAllToTimezone(reservableSlots, timezone);
    }

    return Object.keys(derivedState).length > 0 ? derivedState : null;
  }

  /**
   * Gets the weekly time limit on reservable slots. Either from the user profile or the default
   * setting.
   *
   * @return {number}
   */
  get weeklyTimeLimit() {
    const { currentUser } = this.props;
    return parseInt(currentUser.attributes.profile.publicData.weeklyTimeLimit) || 60;
  }

  /**
   * Checks if the given duration on the given date exceeds the weekly total slot duration limit
   * that is imposed.
   *
   * @param givenDate
   * @param givenDuration
   * @returns {boolean}
   */
  exceedsWeeklyLimit = (givenDate, givenDuration) => {
    const startOfWeek = givenDate.clone().startOf('week');
    const endOfWeek = startOfWeek.clone().endOf('week');

    const { reservableSlots } = this.state;
    const weekSlots = expandDates(filterWithinRange(reservableSlots, startOfWeek, endOfWeek));

    let weekDuration = 0;
    weekSlots.forEach((slot) => {
      const isEvent = !!slot.event;
      const duration = isEvent ? 0 : moment.duration(slot.end.diff(slot.start)).asMinutes();
      weekDuration += duration;
    });

    return weekDuration + givenDuration > this.weeklyTimeLimit;
  };

  onDeleteTimeSlot = (
    id,
    startDate,
    recurrenceParent = null,
    clearRecurringSiblingsInFuture = true
  ) => {
    const { onLogActivity } = this.props;
    onLogActivity(ACTIVITY_UPDATE_AVAILABILITY);

    this.setState({
      timeSlotUpdateInProgress: true,
    });

    const promises = this.clearTimeSlot(
      id,
      startDate,
      recurrenceParent,
      clearRecurringSiblingsInFuture
    );
    return Promise.all(promises).then((response) => {
      this.setState({ timeSlotUpdateInProgress: false });
      return response;
    });
  };

  onSubmit = (values) => {
    const { handleSubmit } = this.props;
    handleSubmit(PUBLISH, values);
  };

  /**
   * Processes time slot data,
   *
   * @param id
   * @param startDate
   * @param endDate
   * @param meetingType
   * @param recurrence
   * @param recurrenceParent
   * @param event
   */
  createTimeSlot = (
    id,
    startDate,
    endDate,
    meetingType,
    recurrence,
    recurrenceParent,
    event = null
  ) => {
    const { currentUserListing, onCreateAvailabilityException, timezone } = this.props;

    // First create the availability exception
    return onCreateAvailabilityException({
      listingId: currentUserListing.id,
      start: startDate.toDate(),
      end: endDate.toDate(),
      seats: 1,
    })
      .then((availabilityException) => {
        // Update the reservableSlots inside the publicData of the listing, this is done once the
        // availability exception has been stored since we need its' ID
        const createdAvailabilityExceptionId = availabilityException.id.uuid;

        const slot = {
          id: createdAvailabilityExceptionId,
          timezone: timezone,
          start: startDate.format(DATE_FORMAT),
          end: endDate.format(DATE_FORMAT),
          methods: meetingType,
          recurrence,
          recurrenceParent: null,
          event,
        };

        // If no parent is specified but the slot is recurring then assume the current slot as parent
        if (recurrence) {
          slot.recurrenceParent = recurrenceParent
            ? recurrenceParent
            : createdAvailabilityExceptionId;
        }

        // Return the slot so it may be used as a recurring parent
        return slot;
      })
      .catch((e) => {
        console.log('Error creating availability exception', e);
        if (!this.state.timeSlotUpdateErrors) {
          this.setState({ timeSlotUpdateErrors: true });
        }
      });
  };

  /**
   * Clears a slot (both from the listing and availability exceptions). If the
   * `clearRecurringSiblingsInFuture` flag is set, all siblings which occur in the future, will be
   * removed as well.
   *
   * @param id The slot ID, is the same as the availability exception ID
   * @param startDate The start date of the slot
   * @param recurrenceParent The recurrence parent of the slot (if any)
   * @param clearRecurringSiblingsInFuture
   */
  clearTimeSlot = (
    id,
    startDate,
    recurrenceParent = null,
    clearRecurringSiblingsInFuture = true
  ) => {
    const { onDeleteAvailabilityException } = this.props;
    let { reservableSlots } = this.state;
    const deletedIds = [id];
    const promises = [];

    // Remove the availability exceptions for the removed slots
    reservableSlots.forEach((slot) => {
      if (
        (slot.id === id ||
          slot.recurrenceParent === id ||
          slot.recurrenceParent === recurrenceParent) &&
        (clearRecurringSiblingsInFuture
          ? moment.tz(slot.start, slot.timezone).isSameOrAfter(startDate)
          : moment.tz(slot.start, slot.timezone).isSame(startDate))
      ) {
        deletedIds.push(slot.id);
        promises.push(
          onDeleteAvailabilityException({
            id: new UUID(slot.id),
          })
        );
      }
    });

    // Remove the reservable slots from the listing, after everything else is done
    const promise = Promise.all(promises).then(() => {
      const { id: listingId } = this.props.currentUserListing;

      const updatedReservableSlots = reservableSlots.filter((slot) => {
        return deletedIds.indexOf(slot.id) === -1;
      });

      const deletedSlot = reservableSlots.find((slot) => slot.id === id);

      this.setState({
        reservableSlots: updatedReservableSlots,
      });

      return this.props.onUpdateListing({
        id: listingId.uuid,
        publicData: {
          reservableSlots: updatedReservableSlots,
          eventRoles: this.getUserEventRolesAfterSlotRemove(
            deletedSlot,
            updatedReservableSlots,
            this.props.currentUserListing.attributes.publicData.eventRoles
          ),
        },
      });
    });

    return [promise];
  };

  // After deleting event time slot, check if user still has some time slots for the same event
  // If not, remove event's default role from user data, because user is not attendee anymore
  // Possible other roles for the event will be left, because they are manually added
  getUserEventRolesAfterSlotRemove(deletedSlot, reservableSlots, currentEventRoles) {
    if (!currentEventRoles || currentEventRoles.length === 0) return [];
    if (!deletedSlot.event) return currentEventRoles;

    const slotEvent = getEvent(deletedSlot.event);

    const slotEventDefaultRole =
      slotEvent && slotEvent.userRoles && slotEvent.userRoles.find((role) => role.default);
    if (!slotEventDefaultRole) return currentEventRoles;

    const hasTimeSlotForEvent = reservableSlots.find(
      (slot) => slot.event && slot.event === deletedSlot.event
    );
    if (hasTimeSlotForEvent) return currentEventRoles;

    return currentEventRoles.filter((role) => role !== slotEventDefaultRole.key);
  }

  createWeeklyRecurringSlots(startDate, endDate, recurrence, meetingType, parentSlotId) {
    const { timezone } = this.props;
    const recurrenceStartDate = startDate.clone();
    const recurrenceEndDate = endDate.clone();
    const recurrencePeriodEndDate = moment().tz(timezone);

    // 365 days is the hard limit that Sharetribe uses (using 1 year will break on leap years)
    recurrencePeriodEndDate.add(365, 'days').startOf('day');
    const durationInMinutes = moment.duration(endDate.diff(startDate)).asMinutes();
    const promises = [];

    // Weekly & bi-weekly recurrence
    if (recurrence === RECURS_WEEKLY || recurrence === RECURS_EVERY_TWO_WEEKS) {
      const recurrenceValue = recurrence === RECURS_WEEKLY ? 1 : 2;
      recurrenceStartDate.add(recurrenceValue, 'weeks');
      recurrenceEndDate.add(recurrenceValue, 'weeks');

      // Repeat adding new entries until the recurrence period end is reached
      while (recurrenceStartDate.isBefore(recurrencePeriodEndDate)) {
        if (!this.exceedsWeeklyLimit(recurrenceStartDate, durationInMinutes)) {
          promises.push(
            this.createTimeSlot(
              null,
              recurrenceStartDate.clone(),
              recurrenceEndDate.clone(),
              meetingType,
              recurrence,
              parentSlotId
            )
          );
        } else {
          this.setState({
            hasExceededWeeklyLimit: true,
          });
        }

        recurrenceStartDate.add(recurrenceValue, 'weeks');
        recurrenceEndDate.add(recurrenceValue, 'weeks');
      }
    }

    return promises;
  }

  createMonthlyRecurringSlots(startDate, endDate, recurrence, meetingType, parentSlotId) {
    const { timezone } = this.props;
    const recurrenceStartDate = startDate.clone();
    const recurrenceEndDate = endDate.clone();
    const recurrencePeriodEndDate = moment().tz(timezone);
    // 365 days is the hard limit that Sharetribe uses (using 1 year will break on leap years)
    recurrencePeriodEndDate.add(365, 'days').startOf('day');
    const endHours = recurrenceEndDate.hours();
    const endMinutes = recurrenceEndDate.minutes();
    const startHours = recurrenceStartDate.hours();
    const startMinutes = recurrenceStartDate.minutes();
    const durationInMinutes = moment.duration(endDate.diff(startDate)).asMinutes();

    const promises = [];
    const nth = calculateNthWeekdayOfMonth(recurrenceStartDate, false);
    const targetWeekday = recurrenceStartDate.weekday();
    let exceedsLimit = false;

    recurrenceStartDate.startOf('month').add(1, 'month');
    recurrenceEndDate.startOf('month').add(1, 'month');

    while (recurrenceStartDate.isBefore(recurrencePeriodEndDate)) {
      let addDays;
      const referenceStartDate = recurrenceStartDate.clone();
      let monthlyWeekday;

      switch (nth) {
        case 'first':
          // Set start and end dates to the start of the next month
          recurrenceStartDate.startOf('month');
          recurrenceEndDate.startOf('month');
          monthlyWeekday = recurrenceStartDate.weekday();

          if (monthlyWeekday < targetWeekday) {
            addDays = targetWeekday - monthlyWeekday;
          } else if (monthlyWeekday > targetWeekday) {
            addDays = 7 - (monthlyWeekday - targetWeekday);
          } else {
            addDays = 0;
          }
          break;
        case 'last':
          // Set the start and end dates to the end of next month
          recurrenceStartDate.endOf('month');
          recurrenceEndDate.endOf('month');
          monthlyWeekday = recurrenceStartDate.weekday();

          if (monthlyWeekday < targetWeekday) {
            addDays = -(7 - targetWeekday) - monthlyWeekday;
          } else if (monthlyWeekday > targetWeekday) {
            addDays = -(monthlyWeekday - targetWeekday);
          } else {
            addDays = 0;
          }
          break;
        default:
          // Set start and end dates to the start of the next month
          recurrenceStartDate.startOf('month');
          recurrenceEndDate.startOf('month');
          monthlyWeekday = recurrenceStartDate.weekday();

          if (monthlyWeekday < targetWeekday) {
            addDays = targetWeekday - monthlyWeekday + 7 * (nth - 1);
          } else if (monthlyWeekday > targetWeekday) {
            addDays = 7 - (monthlyWeekday - targetWeekday) + 7 * (nth - 1);
          } else {
            addDays = 7 * (nth - 1);
          }

          // Verify the that given date is in the correct month (might nog be if nth === 4)
          if (!referenceStartDate.isSame(recurrenceStartDate, 'month')) {
            recurrenceStartDate.add(-7, 'days');
            recurrenceEndDate.add(-7, 'days');
          }

          break;
      }

      // Add the amount of required days to reach the correct start and end date
      if (addDays !== 0) {
        recurrenceStartDate.add(addDays, 'days');
        recurrenceEndDate.add(addDays, 'days');
      }

      // Set the correct time on the start and end date
      recurrenceStartDate.set({
        hours: startHours,
        minutes: startMinutes,
        seconds: 0,
        milliseconds: 0,
      });
      recurrenceEndDate.set({
        hours: endHours,
        minutes: endMinutes,
        seconds: 0,
        milliseconds: 0,
      });

      // Assure not slot are attempted to be created after the end date as there were reset by
      // startOf() and endOf()
      if (recurrenceStartDate.isSameOrAfter(recurrencePeriodEndDate)) {
        break;
      }

      exceedsLimit = this.exceedsWeeklyLimit(recurrenceStartDate, durationInMinutes);
      if (
        !exceedsLimit &&
        recurrenceStartDate.format(DATE_FORMAT) !== startDate.format(DATE_FORMAT)
      ) {
        promises.push(
          this.createTimeSlot(
            null,
            recurrenceStartDate.clone(),
            recurrenceEndDate.clone(),
            meetingType,
            recurrence,
            parentSlotId
          )
        );
      } else if (exceedsLimit) {
        this.setState({
          hasExceededWeeklyLimit: true,
        });
      }

      recurrenceStartDate.add(1, 'months');
      recurrenceEndDate.add(1, 'months');
    }

    return promises;
  }

  createEventDaysRecurringSlots(startDate, endDate, recurrence, meetingType, parentSlotId, event) {
    const { timezone } = this.props;

    const selectedEvent = getEvent(event);
    if (!selectedEvent || recurrence !== RECURS_EVENT_DAYS) {
      return [];
    }

    const recurrencePeriodEndDate = moment.tz(selectedEvent.active.to, timezone);

    const promises = [];

    const recurrenceStartDate = startDate.clone().add(1, 'days');
    const recurrenceEndDate = endDate.clone().add(1, 'days');

    while (recurrenceEndDate.isBefore(recurrencePeriodEndDate)) {
      promises.push(
        this.createTimeSlot(
          null,
          recurrenceStartDate.clone(),
          recurrenceEndDate.clone(),
          meetingType,
          recurrence,
          parentSlotId,
          event
        )
      );

      recurrenceStartDate.add(1, 'days');
      recurrenceEndDate.add(1, 'days');
    }

    return promises;
  }

  createTimeSlots = (
    updateAllFutureSiblings,
    id,
    startDate,
    endDate,
    meetingType,
    recurrence,
    event,
    handleSubmit
  ) => {
    const { currentUser, onUpdateOrCreateNotifications, timezone } = this.props;
    // Reset the exceeding weekly limit check
    this.setState({
      hasExceededWeeklyLimit: false,
    });

    // Create the initial time slot. When singular this is the only thing that is generated,
    // otherwise it's used as a parent for the recurring slots. The ID of this slot is required so
    // it must be fully created before continuing.
    return this.createTimeSlot(
      updateAllFutureSiblings ? null : id,
      startDate,
      endDate,
      meetingType,
      recurrence,
      null,
      event
    ).then((parentSlot) => {
      if (!parentSlot) return Promise.reject();

      let promises = [];
      if (recurrence === RECURS_WEEKLY || recurrence === RECURS_EVERY_TWO_WEEKS) {
        promises = this.createWeeklyRecurringSlots(
          startDate,
          endDate,
          recurrence,
          meetingType,
          parentSlot.id
        );
      } else if (recurrence === RECURS_MONTHLY) {
        promises = this.createMonthlyRecurringSlots(
          startDate,
          endDate,
          recurrence,
          meetingType,
          parentSlot.id
        );
      } else if (recurrence === RECURS_EVENT_DAYS) {
        promises = this.createEventDaysRecurringSlots(
          startDate,
          endDate,
          recurrence,
          meetingType,
          parentSlot.id,
          event
        );
      }

      return Promise.all(promises)
        .catch((e) => {
          console.log('There was an error creating one of the time slots', e);
        })
        .then((slots) => {
          // Sort and persist the reservable slots
          const {
            reservableSlots,
            eventRoles = [],
          } = this.props.currentUserListing.attributes.publicData;
          reservableSlots.push(parentSlot);
          reservableSlots.push(...slots);

          const updatedReservableSlots = convertAllToTimezone(reservableSlots, timezone);
          this.setState({
            reservableSlots: updatedReservableSlots,
          });

          return handleSubmit(AVAILABILITY, {
            publicData: {
              reservableSlots: updatedReservableSlots,
              eventRoles: this.getUserEventRolesAfterSlotCreated(event, eventRoles),
            },
          });
        })
        .then(() => {
          if (this.state.timeSlotUpdateErrors) {
            console.log(
              'One or more of the time slots could not be created because they overlap with already existing time slots.'
            );
          }

          if (this.state.hasExceededWeeklyLimit) {
            this.setState({ exceededWeeklyLimitModalIsOpen: true });
          }

          this.setState({ timeSlotUpdateInProgress: false });
          return Promise.resolve();
        })
        .then(() => {
          return onUpdateOrCreateNotifications(
            ensureCurrentUser(currentUser),
            TYPE_NEW_TIMES_AVAILABLE,
            {
              timeSlots: [parentSlot.id],
            }
          );
        });
    });
  };

  // After creating time slot, check if this is event time slot and if the event has a default user role
  // Add default user role for the user, if she/he does not have it already,
  // because user is now attendee of the event
  getUserEventRolesAfterSlotCreated(eventKey, currentEventRoles) {
    if (!eventKey) return currentEventRoles;

    const timeSlotEvent = getEvent(eventKey);
    const defaultEventRole =
      timeSlotEvent &&
      timeSlotEvent.userRoles &&
      timeSlotEvent.userRoles.find((role) => role.default);

    if (!defaultEventRole || currentEventRoles.includes(defaultEventRole.key)) {
      return currentEventRoles;
    }

    return [...currentEventRoles, defaultEventRole.key];
  }

  /**
   * Time slot form submission handler. Processes the action required for the time slot. It might
   * be edited or newly created. Singular or recurring. This method will handle that process.
   *
   * @param values The form values
   */
  onSubmitTimeSlot = (values) => {
    const { handleSubmit, onLogActivity, timezone } = this.props;
    onLogActivity(ACTIVITY_UPDATE_AVAILABILITY);

    this.setState({
      timeSlotUpdateInProgress: true,
      timeSlotUpdateErrors: false,
    });
    const {
      bookingDate,
      bookingTime,
      duration,
      event,
      id,
      meetingType,
      recurrence,
      recurrenceParent,
      updateSiblingsInFuture,
    } = values;

    const [hours, minutes] = bookingTime.split(':');
    const updateAllFutureSiblings = !!updateSiblingsInFuture;
    const editOnlyThisSlot = !!id && !updateAllFutureSiblings;

    const selectedRecurrence = editOnlyThisSlot ? RECURS_NEVER : recurrence;
    const selectedRecurrenceParent = editOnlyThisSlot ? null : recurrenceParent;

    // Set start and end dates for the initial/parent slot
    const startDate = moment.tz(new Date(bookingDate.date), timezone).set({
      hours,
      minutes,
    });
    const endDate = startDate.clone();
    endDate.add(duration, 'minutes');

    // If the ID is given, clear the current slot and if needed any recurring siblings.
    let result;
    if (id) {
      // The deletion process needs to finish before new entries can be submitted
      result = Promise.all(
        this.clearTimeSlot(id, startDate, selectedRecurrenceParent, updateAllFutureSiblings)
      ).then(() => {
        return this.createTimeSlots(
          updateAllFutureSiblings,
          id,
          startDate,
          endDate,
          meetingType,
          selectedRecurrence,
          event,
          handleSubmit
        );
      });
    } else {
      result = this.createTimeSlots(
        updateAllFutureSiblings,
        id,
        startDate,
        endDate,
        meetingType,
        selectedRecurrence,
        event,
        handleSubmit
      );
    }

    return result;
  };

  render() {
    const {
      bookings,
      currentUser,
      currentUserListing,
      eventKey,
      eventsOpenVolunteering,
      history,
      onFetchTimeSlots,
      onManageDisableScrolling,
      timezone,
      trackEvent,
    } = this.props;

    const { hasExceededWeeklyLimit, reservableSlots, timeSlotUpdateInProgress } = this.state;

    const classes = classNames(css.formContainer, timeSlotUpdateInProgress ? css.isLoading : null);

    return (
      <div className={classes}>
        <AvailabilityForm
          bookings={bookings}
          currentUser={currentUser}
          currentUserListing={currentUserListing}
          eventKey={eventKey}
          eventsOpenVolunteering={eventsOpenVolunteering}
          exceedsWeeklyLimit={this.exceedsWeeklyLimit}
          hasExceededWeeklyLimit={hasExceededWeeklyLimit}
          history={history}
          initialValues={{
            methodPriceChoices: currentUserListing.attributes.publicData.methodPriceChoices,
          }}
          onDeleteTimeSlot={this.onDeleteTimeSlot}
          onFetchTimeSlots={onFetchTimeSlots}
          onManageDisableScrolling={onManageDisableScrolling}
          reservableSlots={reservableSlots}
          timezone={timezone}
          onSubmit={this.onSubmit}
          onSubmitTimeSlot={this.onSubmitTimeSlot}
          trackEvent={trackEvent}
        />
        <div className={css.loader}>
          <IconSpinner />
        </div>
      </div>
    );
  }
}

AvailabilityPanel.propTypes = {
  bookings: arrayOf(propTypes.booking),
  currentUser: propTypes.currentUser.isRequired,
  currentUserListing: propTypes.ownListing.isRequired,
  eventKey: string,
  eventsOpenVolunteering: arrayOf(propTypes.event),
  handleSubmit: func.isRequired,
  history: shape({
    push: func.isRequired,
  }).isRequired,
  onCreateAvailabilityException: func.isRequired,
  onDeleteAvailabilityException: func.isRequired,
  onFetchAvailabilityExceptions: func.isRequired,
  onFetchTimeSlots: func.isRequired,
  onLogActivity: func.isRequired,
  onManageDisableScrolling: func.isRequired,
  onUpdateListing: func.isRequired,
  timezone: string.isRequired,
};

export default AvailabilityPanel;
