import React from 'react';
import { withMessages } from '../../util/localization';
import css from './ListingPage.css';
import UserHero from './UserHero';
import { LISTING_STATE_CLOSED, propTypes } from '../../util/types';
import { bool, func, oneOf, object, shape, string, array, arrayOf } from 'prop-types';
import { ensureListing } from '../../util/data';
import {
  LISTING_PAGE_DRAFT_VARIANT,
  LISTING_PAGE_PENDING_APPROVAL_VARIANT,
} from '../../util/urlHelpers';
import { types as sdkTypes } from '../../util/sdkLoader';
import { richText } from '../../util/richText';
import SectionMaybe from './SectionMaybe';
import { NonprofitImpactCard } from '../../components';
import { fromUserShortFormat } from '../../util/location';
import sharetribe from '@givsly/sharetribe-utils';

const { UUID } = sdkTypes;
const MIN_LENGTH_FOR_LONG_WORDS_IN_DESCRIPTION = 20;

class UserProfile extends React.Component {
  constructor(props) {
    super(props);
    this.handleBookingSubmit = this.handleBookingSubmit.bind(this);
  }

  handleBookingSubmit = (values) => {
    const { currentListing, handleSubmit, isOwnListing } = this.props;
    const isCurrentlyClosed = currentListing.attributes.state === LISTING_STATE_CLOSED;
    if (isOwnListing || isCurrentlyClosed) {
      window.scrollTo(0, 0);
    } else {
      handleSubmit(values);
    }
  };

  render() {
    const {
      currentListing,
      description,
      ensuredAuthor,
      eventRoleBadges,
      getListing,
      getMessage,
      getTranslation,
      listingsInProgress,
      params,
    } = this.props;

    let supportedNonprofits = [];

    if (
      !listingsInProgress &&
      ensuredAuthor.attributes.profile.publicData.supportedNPOs.length > 0
    ) {
      supportedNonprofits = ensuredAuthor.attributes.profile.publicData.supportedNPOs
        .map((id) => ensureListing(getListing(new UUID(id))))
        .filter((npo) => !!npo.id);
    }

    return (
      <div className={css.mainWrapper}>
        <UserHero listing={currentListing} params={params} user={ensuredAuthor} />
        <div className={css.contentContainer}>
          <div className={css.mainContent}>
            <div className={css.volunteerDetails}>
              <SectionMaybe
                content={richText(description, {
                  longWordMinLength: MIN_LENGTH_FOR_LONG_WORDS_IN_DESCRIPTION,
                  longWordClass: css.longWord,
                })}
                title={getTranslation('description')}
              />
              {supportedNonprofits.length ? (
                <>
                  <h2 className={css.sectionHeader}>{getMessage('nonProfitsImSupporting')}</h2>
                  <div className={css.supportedNonprofits}>
                    {supportedNonprofits.map((nonprofit) => {
                      const ensuredNonprofit = sharetribe.listing.ensureNonprofitListing(nonprofit);
                      return (
                        <NonprofitImpactCard
                          key={`nonprofit-${ensuredNonprofit.id.uuid}`}
                          nonprofit={ensuredNonprofit}
                        />
                      );
                    })}
                  </div>
                </>
              ) : null}
              {eventRoleBadges.length > 0 && (
                <SectionMaybe
                  content={eventRoleBadges.map((badge, idx) => (
                    <span key={`contributing-${idx}`} className={css.eventRole}>
                      {badge}
                    </span>
                  ))}
                  title={getTranslation('contributing')}
                />
              )}
              <SectionMaybe
                content={ensuredAuthor.attributes.profile.publicData.industry}
                title={getTranslation('industry')}
              />
              <SectionMaybe
                content={fromUserShortFormat(ensuredAuthor)}
                title={getTranslation('location')}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

UserProfile.propTypes = {
  authorDisplayName: string.isRequired,
  currentListing: propTypes.listing,
  currentUser: propTypes.currentUser,
  description: string,
  eventRoleBadges: arrayOf(string),
  getListing: func.isRequired,
  getTranslation: func.isRequired,
  handleSubmit: func.isRequired,
  isAccessCodeRequiredForSlot: func.isRequired,
  isAuthenticated: bool.isRequired,
  isOwnListing: bool.isRequired,
  isVolunteer: bool.isRequired,
  listingsInProgress: bool.isRequired,
  monthlyTimeSlots: object,
  onFetchTimeSlots: func.isRequired,
  onManageDisableScrolling: func.isRequired,
  onPublishListing: func.isRequired,
  onResendVerificationEmail: func.isRequired,
  onUpdateListing: func.isRequired,
  onUpdateProfile: func.isRequired,
  params: shape({
    id: string.isRequired,
    slug: string,
    variant: oneOf([LISTING_PAGE_DRAFT_VARIANT, LISTING_PAGE_PENDING_APPROVAL_VARIANT]),
  }).isRequired,
  reservableSlots: array,
  richTitle: object.isRequired,
  timezone: string.isRequired,
  onTrackEvent: func.isRequired,
  unitType: propTypes.bookingUnitType,
  volunteerMeetsRequirements: bool,
};

UserProfile.defaultProps = {
  volunteerMeetsRequirements: false,
};

export default withMessages(UserProfile, 'ListingPage.UserProfile');
