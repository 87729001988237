import moment from 'moment-timezone';
import givslyConfig from '@givsly/config';
import utils from '@givsly/sharetribe-utils';
import { ensureAvailabilityException } from '../util/data';
import { storableError } from '../util/errors';
import { types as sdkTypes } from '../util/sdkLoader';

const { UUID } = sdkTypes;
const { formatDateTime } = utils.common;

// Action types
export const CREATE_AVAILABILITY_EXCEPTION_REQUEST =
  'app/timeSlot/CREATE_AVAILABILITY_EXCEPTION_REQUEST';
export const CREATE_AVAILABILITY_EXCEPTION_SUCCESS =
  'app/timeSlot/CREATE_AVAILABILITY_EXCEPTION_SUCCESS';
export const CREATE_AVAILABILITY_EXCEPTION_FAILURE =
  'app/timeSlot/CREATE_AVAILABILITY_EXCEPTION_FAILURE';
export const DELETE_AVAILABILITY_EXCEPTION_REQUEST =
  'app/timeSlot/DELETE_AVAILABILITY_EXCEPTION_REQUEST';
export const DELETE_AVAILABILITY_EXCEPTION_SUCCESS =
  'app/timeSlot/DELETE_AVAILABILITY_EXCEPTION_SUCCESS';
export const DELETE_AVAILABILITY_EXCEPTION_FAILURE =
  'app/timeSlot/DELETE_AVAILABILITY_EXCEPTION_FAILURE';
export const QUERY_AVAILABILITY_EXCEPTION_REQUEST =
  'app/timeSlot/QUERY_AVAILABILITY_EXCEPTION_REQUEST';
export const QUERY_AVAILABILITY_EXCEPTION_SUCCESS =
  'app/timeSlot/QUERY_AVAILABILITY_EXCEPTION_SUCCESS';
export const QUERY_AVAILABILITY_EXCEPTION_FAILURE =
  'app/timeSlot/QUERY_AVAILABILITY_EXCEPTION_FAILURE';

// Initial state
const initialState = {
  availabilityException: null,
  availabilityExceptions: [],
  createAvailabilityExceptionError: null,
  createAvailabilityExceptionInProgress: false,
  deleteAvailabilityExceptionError: null,
  deleteAvailabilityExceptionInProgress: false,
  deleteAvailabilityId: null,
  queryAvailabilityExceptionsError: null,
  queryAvailabilityExceptionsInProgress: false,
};

// Reducer
export default function reducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    default:
      return initialState;
    case CREATE_AVAILABILITY_EXCEPTION_REQUEST:
      return {
        ...state,
        createAvailabilityExceptionError: null,
        createAvailabilityExceptionInProgress: true,
      };
    case CREATE_AVAILABILITY_EXCEPTION_SUCCESS:
      return {
        ...state,
        availabilityException: payload.availabilityException,
        createAvailabilityExceptionInProgress: false,
      };
    case CREATE_AVAILABILITY_EXCEPTION_FAILURE:
      return {
        ...state,
        createAvailabilityExceptionError: payload.err,
        createAvailabilityExceptionInProgress: false,
      };
    case DELETE_AVAILABILITY_EXCEPTION_REQUEST:
      return {
        ...state,
        deleteAvailabilityExceptionError: null,
        deleteAvailabilityExceptionInProgress: true,
        deleteAvailabilityId: payload.deleteAvailabilityId,
      };
    case DELETE_AVAILABILITY_EXCEPTION_SUCCESS:
      return {
        ...state,
        deleteAvailabilityExceptionInProgress: false,
      };
    case DELETE_AVAILABILITY_EXCEPTION_FAILURE:
      return {
        ...state,
        deleteAvailabilityExceptionError: payload.err,
        deleteAvailabilityExceptionInProgress: false,
      };
    case QUERY_AVAILABILITY_EXCEPTION_REQUEST:
      return {
        ...state,
        queryAvailabilityExceptionsError: null,
        queryAvailabilityExceptionsInProgress: true,
      };
    case QUERY_AVAILABILITY_EXCEPTION_SUCCESS:
      return {
        availabilityExceptions: payload.availabilityExceptions,
        queryAvailabilityExceptionsInProgress: false,
      };
    case QUERY_AVAILABILITY_EXCEPTION_FAILURE:
      return {
        queryAvailabilityExceptionsError: payload.err,
        queryAvailabilityExceptionsInProgress: false,
      };
  }
}

// Action creators
const createAvailabilityExceptionRequest = () => ({
  type: CREATE_AVAILABILITY_EXCEPTION_REQUEST,
});
const createAvailabilityExceptionSuccess = (availabilityException) => ({
  type: CREATE_AVAILABILITY_EXCEPTION_SUCCESS,
  payload: {
    availabilityException,
  },
});
const createAvailabilityExceptionFailure = (err) => ({
  type: CREATE_AVAILABILITY_EXCEPTION_FAILURE,
  payload: {
    err,
  },
});
const deleteAvailabilityExceptionRequest = (deleteAvailabilityId) => ({
  type: DELETE_AVAILABILITY_EXCEPTION_REQUEST,
  payload: {
    deleteAvailabilityId,
  },
});
const deleteAvailabilityExceptionSuccess = () => ({
  type: DELETE_AVAILABILITY_EXCEPTION_SUCCESS,
});
const deleteAvailabilityExceptionFailure = (err) => ({
  type: DELETE_AVAILABILITY_EXCEPTION_FAILURE,
  payload: {
    err,
  },
});
const queryAvailabilityExceptionRequest = () => ({
  type: QUERY_AVAILABILITY_EXCEPTION_REQUEST,
});
const queryAvailabilityExceptionsSuccess = (availabilityExceptions) => ({
  type: QUERY_AVAILABILITY_EXCEPTION_SUCCESS,
  payload: {
    availabilityExceptions,
  },
});
const queryAvailabilityExceptionsFailure = (err) => ({
  type: QUERY_AVAILABILITY_EXCEPTION_FAILURE,
  payload: {
    err,
  },
});

// Thunks
/**
 * Create a new availability exception
 *
 * @param listingId
 * @param start
 * @param end
 * @param seats
 * @returns {function(*, *, *): *}
 */
export const createAvailabilityException = (listingId, start, end, seats = 1) => (
  dispatch,
  getState,
  sdk
) => {
  dispatch(createAvailabilityExceptionRequest());

  return sdk.availabilityExceptions
    .create({
      listingId,
      start,
      end,
      seats,
    })
    .then((sdkResponse) => {
      const availabilityException = ensureAvailabilityException(sdkResponse.data.data);
      dispatch(createAvailabilityExceptionSuccess(availabilityException));
      return availabilityException;
    })
    .catch((err) => {
      dispatch(createAvailabilityExceptionFailure(storableError(err)));
      return Promise.reject(err);
    });
};

/**
 * Delete a given availability exception
 *
 * @param uuid
 * @returns {function(*, *, *): *}
 */
export const deleteAvailabilityException = (uuid) => (dispatch, getState, sdk) => {
  dispatch(deleteAvailabilityExceptionRequest(uuid));

  return sdk.availabilityExceptions
    .delete({
      id: new UUID(uuid),
    })
    .then(() => {
      return dispatch(deleteAvailabilityExceptionSuccess());
    })
    .catch((err) => {
      dispatch(deleteAvailabilityExceptionFailure(err));
      return Promise.reject(err);
    });
};

/**
 * Query availability exceptions for a given listing and time period. If no time period is specified
 * the next ~3 months is taken as a default range.
 *
 * @param listingId
 * @param start
 * @param end
 * @param seats
 * @returns {function(*, *, *): *}
 */
export const queryAvailabilityExceptions = (listingId, start = null, end = null, seats = 1) => (
  dispatch,
  getState,
  sdk
) => {
  dispatch(queryAvailabilityExceptionRequest());

  const defaultStart = moment().add(
    givslyConfig.availabilityException.maximumFutureVisibility,
    'days'
  );
  const defaultEnd = moment();

  return sdk.availabilityExceptions
    .query({
      listingId,
      end: formatDateTime(end, defaultEnd),
      start: formatDateTime(start, defaultStart),
      seats,
    })
    .then((sdkResponse) => {
      const availabilityExceptions = sdkResponse.data.data.map((a) =>
        ensureAvailabilityException(a)
      );
      dispatch(queryAvailabilityExceptionsSuccess(availabilityExceptions));
      return availabilityExceptions;
    })
    .catch((err) => {
      dispatch(queryAvailabilityExceptionsFailure(err));
      return Promise.reject();
    });
};
