/**
 * NOTE: This legacy should be slowly replaced by functionality in reservableSlot.js. The goal is to
 * move away from moment.js as much as possible, as well as the ambiguous naming of these entities.
 * Time slots are used withing Sharetribe's booking system as well and might be confused with the
 * actual reservable slots (which are stored inside a users listing).
 *
 * @see reservableSlot.js
 */
// Checks if time slot (propTypes.timeSlot) contains a day (moment)
// Returns true if the day is inside the timeslot or if the timeslot
// starts or ends between start and end of the day.
//
// By default react-dates handles dates in the browser's timezone so
// we need to convert the value `day` to given timezone before comparing it
// to timeslot.
import moment from 'moment-timezone';
import { monthIdStringInTimeZone } from './dates';
import { getEvent, isEventBookingOpen } from './events';
import { PROFILE_TYPE_VOLUNTEER } from './data';
import { calendarStartTimeOffSetHours } from '../marketplace-custom-config';

export const DATE_FORMAT = 'YYYY-MM-DDTHH:mm:ss.SSS';

/**
 * Because much date/time comparison is done using moment objects, those objects should be cached in
 * order to save performance.
 */
const momentCache = {};
const getMoment = (date, timezone) => {
  if (date === undefined || timezone === undefined) {
    console.error('Undefined date or timezone');
  }
  const cacheKey = date + '-' + timezone;
  if (!momentCache[cacheKey]) {
    momentCache[cacheKey] = moment.tz(date, timezone);
  }
  return momentCache[cacheKey];
};

const timeSlotContainsDay = (timeSlot, day, timeZone) => {
  const startOfDay = moment.tz(day.toArray().slice(0, 3), timeZone);
  const endOfDay = startOfDay.clone().add(1, 'days');

  const startDate = getMoment(timeSlot.attributes.start, timeZone);
  const endDate = getMoment(timeSlot.attributes.end, timeZone);

  if (startOfDay.isSameOrAfter(startDate) && endDate.isSameOrAfter(endOfDay)) {
    return true;
  } else if (startDate.isBetween(startOfDay, endOfDay, null, '[)')) {
    return true;
  } else if (endDate.isBetween(startOfDay, endOfDay, null, '[)')) {
    return true;
  }

  return false;
};

const getMonthlyTimeSlots = (monthlyTimeSlots, date, timeZone) => {
  const monthId = monthIdStringInTimeZone(date, timeZone);

  return !monthlyTimeSlots || Object.keys(monthlyTimeSlots).length === 0
    ? []
    : monthlyTimeSlots[monthId] && monthlyTimeSlots[monthId].timeSlots
    ? monthlyTimeSlots[monthId].timeSlots
    : [];
};

const getTimeSlotsForDate = (monthlyTimeSlots, day, timeZone) => {
  const timeSlots = getMonthlyTimeSlots(monthlyTimeSlots, day, timeZone);

  if (timeSlots.length === 0) {
    return [];
  }

  return timeSlots.filter((timeSlot) => timeSlotContainsDay(timeSlot, day, timeZone));
};

export const getReservableSlotsForDate = (reservableSlots, date, timeZone) => {
  if (!reservableSlots || !reservableSlots.length || !date || !timeZone) {
    return [];
  }

  // const startOfDay = moment.tz(date.toArray().slice(0, 3), timeZone);
  // const endOfDay = startOfDay.clone().add(1, 'days');
  const startOfDay = getMoment(date, timeZone).startOf('day');
  const endOfDay = startOfDay.clone().endOf('day');

  return reservableSlots.filter((rs) => {
    if (!rs) {
      return false;
    }

    // Filter out event time slots that are not bookable
    if (rs.event) {
      const event = getEvent(rs.event);
      if (!isEventBookingOpen(event, timeZone)) {
        return false;
      }
    }

    const startDate = getMoment(rs.start, rs.timezone);
    const endDate = getMoment(rs.end, rs.timezone);

    if (startOfDay.isSameOrAfter(startDate) && endDate.isSameOrAfter(endOfDay)) {
      return true;
    } else if (startDate.isBetween(startOfDay, endOfDay, null, '[)')) {
      return true;
    } else if (endDate.isBetween(startOfDay, endOfDay, null, '[)')) {
      return true;
    }

    return false;
  });
};

export const createAvailabilityMap = (monthlyTimeSlots, reservableSlots, timezone) => {
  const map = {};
  reservableSlots.forEach((slot) => {
    const start = getMoment(slot.start, slot.timezone);
    const key = start.tz(timezone).format('YYYY-MM-DD');
    map[key] = getAvailableReservableSlotsForDate(
      monthlyTimeSlots,
      reservableSlots,
      start,
      timezone
    );
  });
  return map;
};

// @todo refactor, performance bottleneck
export const getAvailableReservableSlotsForDate = (
  monthlyTimeSlots,
  reservableSlots,
  date,
  timeZone
) => {
  if (
    !monthlyTimeSlots ||
    !Object.keys(monthlyTimeSlots).length ||
    !reservableSlots ||
    !reservableSlots.length ||
    !date ||
    !timeZone
  ) {
    return [];
  }

  const reservableSlotsForDate = getReservableSlotsForDate(reservableSlots, date, timeZone);
  const timeSlotsForDate = getTimeSlotsForDate(monthlyTimeSlots, date, timeZone);
  const startLowerBound = moment().tz(timeZone).add(6, 'hours');

  reservableSlotsForDate.sort((a, b) => {
    return getMoment(a.start, a.timezone) - getMoment(b.start, b.timezone);
  });

  return reservableSlotsForDate.filter((rs) => {
    const startDate = getMoment(rs.start, rs.timezone);
    const endDate = getMoment(rs.end, rs.timezone);

    return timeSlotsForDate.find((timeSlot) => {
      const tsStartDate = getMoment(timeSlot.attributes.start, timeZone);
      const tsEndDate = getMoment(timeSlot.attributes.end, timeZone);

      return (
        startDate.isBetween(tsStartDate, tsEndDate, null, '[]') &&
        endDate.isBetween(tsStartDate, tsEndDate, null, '[]') &&
        startDate.isAfter(startLowerBound)
      );
    });
  });
};

export const isTimeSlotBooked = (monthlyTimeSlots, timeSlot, timezone) => {
  const date = moment.tz(timeSlot.start, timeSlot.timezone).tz(timezone);
  const filteredTimeSlots = getAvailableReservableSlotsForDate(
    monthlyTimeSlots,
    [timeSlot],
    date,
    timezone
  );
  return filteredTimeSlots.length === 0;
};

const dateComparator = (a, b) => {
  if (!a.attributes || !b.attributes) {
    return 0;
  }
  return getMoment(a.attributes.start, 'UTC') - getMoment(b.attributes.start, 'UTC');
};

export const isSame = (a, timezoneA, b, timezoneB, granularity) => {
  if (!a || !b || !timezoneA || !timezoneB) {
    return false;
  }

  return getMoment(a, timezoneA).isSame(getMoment(b, timezoneB), granularity);
};

export const isSameOrAfter = (a, timezoneA, b, timezoneB, granularity) => {
  if (!a || !b || !timezoneA || !timezoneB) {
    return false;
  }

  return getMoment(a, timezoneA).isSameOrAfter(getMoment(b, timezoneB), granularity);
};

export const isBefore = (a, timezoneA, b, timezoneB, granularity) => {
  if (!a || !b || !timezoneA || !timezoneB) {
    return false;
  }

  return getMoment(a, timezoneA).isBefore(getMoment(b, timezoneB), granularity);
};

export const findFirstAvailableReservableSlot = (timeSlots, reservableSlots, date, timeZone) => {
  if (
    !timeSlots ||
    !timeSlots.length ||
    !reservableSlots ||
    !reservableSlots.length ||
    !date ||
    !timeZone
  ) {
    return null;
  }

  timeSlots.sort(dateComparator);

  // (bug in eslint with react-create-app. https://github.com/eslint/eslint/issues/12117)
  // eslint-disable-next-line no-unused-vars
  for (const timeSlot of timeSlots) {
    const tsStartDate = getMoment(timeSlot.attributes.start, timeZone);
    const tsEndDate = getMoment(timeSlot.attributes.end, timeZone);

    if (tsStartDate.isBefore(date)) {
      continue;
    }

    const reservableSlotsForDate = getReservableSlotsForDate(
      reservableSlots,
      tsStartDate,
      timeZone
    );
    if (!reservableSlotsForDate.length) {
      continue;
    }

    reservableSlotsForDate.sort(dateComparator);

    // (bug in eslint with react-create-app. https://github.com/eslint/eslint/issues/12117)
    // eslint-disable-next-line no-unused-vars
    for (const reservableSlot of reservableSlotsForDate) {
      const rsStartDate = getMoment(reservableSlot.start, reservableSlot.timezone);
      const rsEndDate = getMoment(reservableSlot.end, reservableSlot.timezone);

      if (
        rsStartDate.isBetween(tsStartDate, tsEndDate, null, '[]') &&
        rsEndDate.isBetween(tsStartDate, tsEndDate, null, '[]')
      ) {
        return reservableSlot;
      }
    }
  }

  return null;
};

/**
 * Generates a human readable description of a slot
 *
 * @param methods
 * @param intl
 * @returns {*|string|React.ReactNodeArray|Error}
 */
export const getDescription = (methods, intl) => {
  let labelId = '';
  if (methods.length === 1) {
    labelId = `AvailabilityForm.${methods[0]}`;
  } else if (methods.length === 2) {
    labelId = 'AvailabilityForm.doubleOption';
  } else if (methods.length === 3) {
    labelId = 'AvailabilityForm.tripleOption';
  }
  return intl.formatMessage(
    { id: labelId },
    Object.assign(
      {},
      methods.map((method) => intl.formatMessage({ id: `AvailabilityForm.${method}` }))
    )
  );
};

/**
 * Converts and sorts a given collection of time slots to a different timezone
 *
 * @param slots
 * @param timezone
 * @returns {Array}
 */
export const convertAllToTimezone = (slots, timezone) => {
  return slots
    .map((slot) => convertToTimezone(slot, timezone))
    .sort((a, b) => {
      return getMoment(a.start, a.timezone) - getMoment(b.start, b.timezone);
    });
};

/**
 * Converts a single time slot to a different timezone
 *
 * @param slot
 * @param timezone
 * @returns {{timezone: *, start: *, end: *}}
 */
export const convertToTimezone = (slot, timezone) => {
  return {
    ...slot,
    start: getMoment(slot.start, slot.timezone).tz(timezone).format(DATE_FORMAT),
    end: getMoment(slot.end, slot.timezone).tz(timezone).format(DATE_FORMAT),
    timezone,
  };
};

/**
 * Filters a given collection and returns only items within the range. This method utilizes cached
 * moment objects for increased performance.
 *
 * @param slots
 * @param start
 * @param end
 * @returns {*}
 */
export const filterWithinRange = (slots, start, end) => {
  return slots.filter((slot) => {
    return slot && getMoment(slot.start, slot.timezone).isBetween(start, end);
  });
};

export const expandDates = (slots) => {
  return slots.map((slot) => {
    return {
      ...slot,
      start: getMoment(slot.start, slot.timezone),
      end: getMoment(slot.end, slot.timezone),
    };
  });
};

export const slotIsOpenForBooking = (slot, events, timezone) => {
  if (!slot.event) return true;

  const slotEvent = events.find((event) => event.key === slot.event);

  if (!slotEvent || !slotEvent.bookingOpen || !slotEvent.bookingOpen.from) return true;

  const now = getMoment(new Date(), timezone);
  const eventBookingStart = getMoment(slotEvent.bookingOpen.from, slotEvent.timezone);

  return now.isSameOrAfter(eventBookingStart);
};

export const sortByStart = (timeSlots) => {
  return timeSlots.sort((a, b) => {
    return getMoment(a.start, a.timezone) - getMoment(b.start, b.timezone);
  });
};

export const getBookingPanelPropsForListing = (
  ensuredListing,
  events,
  monthlyTimeSlots,
  selectedDate,
  selectedReservableSlot,
  selectedMethod,
  timezone
) => {
  const {
    isVolunteerListing,
    listingType,
    price,
    reservableSlots,
  } = ensuredListing.attributes.publicData;

  const props = {
    canRenderCalendar: false,
    currentDateReservableSlots: [],
    price,
    reservableSlots: [],
    selectedDate: moment().tz(timezone),
    selectedReservableSlot: null,
    selectedMethod: null,
  };

  props.reservableSlots = reservableSlots
    ? reservableSlots.filter((slot) => slotIsOpenForBooking(slot, events, timezone))
    : [];

  if (isVolunteerListing || listingType === PROFILE_TYPE_VOLUNTEER) {
    // The 6 hours are added because it's not possible to book meetings within 6 hours of their
    // starting time.
    const now = moment().tz(timezone).add(calendarStartTimeOffSetHours, 'hours');
    const currentMonthId = monthIdStringInTimeZone(now, timezone);

    props.selectedDate = selectedDate;
    if (!props.selectedDate && monthlyTimeSlots[currentMonthId]) {
      const allTimeSlots = [];
      // @todo Check if this needs to be remapped to match the current users timezone
      Object.keys(monthlyTimeSlots).forEach((monthId) => {
        allTimeSlots.push(...monthlyTimeSlots[monthId].timeSlots);
      });
      if (allTimeSlots.length > 0) {
        const firstReservableSlot = findFirstAvailableReservableSlot(
          allTimeSlots,
          props.reservableSlots,
          now,
          timezone
        );

        props.selectedDate = allTimeSlots[0].attributes.start;

        if (firstReservableSlot) {
          props.selectedDate = moment
            .tz(firstReservableSlot.start, firstReservableSlot.timezone)
            .tz(timezone);
        }
      }
    }

    // If the final selected date is not a moment object it means that there was no upcoming time
    // slot available with open meeting times. It might be that the user is fully booked for the
    // foreseeable future or that the user only had available time slots in the past.
    if (props.selectedDate instanceof moment) {
      props.currentDateReservableSlots = getAvailableReservableSlotsForDate(
        monthlyTimeSlots,
        props.reservableSlots,
        props.selectedDate,
        timezone
      );

      props.selectedReservableSlot = selectedReservableSlot;
      if (!props.selectedReservableSlot && props.currentDateReservableSlots.length === 1) {
        props.selectedReservableSlot = props.currentDateReservableSlots[0];
      }

      props.selectedMethod = selectedMethod;
      if (
        !selectedMethod &&
        props.selectedReservableSlot &&
        props.selectedReservableSlot.methods.length === 1
      ) {
        props.selectedMethod = props.selectedReservableSlot.methods[0];
      }
      props.canRenderCalendar = true;
    }
  }

  return props;
};
