import React from 'react';
import { FormattedMessage, intlShape } from '../../util/reactIntl';
import { propTypes } from '../../util/types';

import css from './BookingBreakdown.css';
import { bool, number } from 'prop-types';
import config from '../../config';
import { types as sdkTypes } from '../../util/sdkLoader';
import { formatMoney } from '../../util/currency';

const { Money } = sdkTypes;

const LineItemListingTitle = (props) => {
  const { donation, intl, isCompleted, listing, event } = props;

  if (!listing || !listing.attributes || !listing.attributes.title) {
    return null;
  }

  const donationMoney = new Money(donation, config.currency);

  const donationText = donation && (
    <FormattedMessage
      id={`BookingBreakdown.${isCompleted ? 'valueHasBeenDonated' : 'valueWillBeDonated'}`}
      values={{
        donation: formatMoney(intl, donationMoney),
        nonprofit: listing.attributes.title,
      }}
    />
  );

  const isEventFeaturedNonprofit =
    event && event.supportedNpo && event.supportedNpo === listing.id.uuid;
  const eventNonprofitText = isEventFeaturedNonprofit && (
    <>
      {', '}{' '}
      <FormattedMessage
        id="BookingBreakdown.valueWillBeDonatedToEvent"
        values={{ event: event.title }}
      />
    </>
  );

  return (
    <div className={css.detailItem}>
      <div className={css.detailItemLabel}>
        <FormattedMessage id="BookingBreakdown.listingTitle" />
      </div>
      <div className={css.detailItemValue}>
        {donation ? (
          <>
            {donationText}
            {eventNonprofitText}
          </>
        ) : (
          listing.attributes.title
        )}
      </div>
    </div>
  );
};

LineItemListingTitle.propTypes = {
  donation: number,
  intl: intlShape.isRequired,
  isCompleted: bool,
  listing: propTypes.listing,
  event: propTypes.event,
};

LineItemListingTitle.defaultProps = {
  donation: null,
  isCompleted: false,
};

export default LineItemListingTitle;
