import React from 'react';
import { injectIntl } from 'react-intl';
import { useIntl } from 'react-intl';

export const withMessages = (Component, componentName) => {
  class WithMessagesComponent extends React.Component {
    getMessage = (id, values = {}, labelPrefix = null, defaultMessage = null) => {
      const { intl } = this.props;
      return intl.formatMessage(
        {
          id: `${labelPrefix || componentName}.${id}`,
          defaultMessage,
        },
        typeof values === 'object' ? values : {}
      );
    };

    render() {
      return <Component {...this.props} getMessage={this.getMessage} />;
    }
  }

  return injectIntl(WithMessagesComponent);
};

export function useMessages(componentName) {
  const intl = useIntl();

  return (id, values, labelPrefix = null, defaultMessage = null) => {
    return intl.formatMessage(
      {
        id: `${labelPrefix || componentName}.${id}`,
        defaultMessage,
      },
      typeof values === 'object' ? values : {}
    );
  };
}
