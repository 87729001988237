// ================ Action types ================ //

export const TRACK_EVENT = 'app/Analytics/TRACK_EVENT';

const initialState = {
  analyticsHandlers: [],
};

export default function reducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case TRACK_EVENT:
      state.analyticsHandlers.forEach((handler) => {
        handler.trackEvent(payload);
      });
      return state;
    default:
      return state;
  }
}

export const trackEventAction = (params) => ({ type: TRACK_EVENT, payload: params });

export const identifyAction = (id, params) => (dispatch, getState, sdk) => {
  const state = getState();
  const promises = state.Analytics.analyticsHandlers.map((handler) => {
    return handler.identify(id, params);
  });

  return Promise.all(promises);
};

export const resetAction = () => (dispatch, getState, sdk) => {
  getState().Analytics.analyticsHandlers.forEach((handler) => handler.reset());
};
