import React from 'react';
import { arrayOf, bool, func, node, object, shape, string } from 'prop-types';
import { Field } from 'react-final-form';
import classNames from 'classnames';
import { ValidationError } from '../../components';

import css from './FieldSelect.css';

const handleChange = (propsOnChange, inputOnChange) => (event) => {
  // If "onChange" callback is passed through the props,
  // it can notify the parent when the content of the input has changed.
  if (propsOnChange) {
    // "handleChange" function is attached to the low level <select> component
    // value of the element needs to be picked from target
    const value = event.nativeEvent.target.value;
    propsOnChange(value);
  }
  // Notify Final Form that the input has changed.
  // (Final Form knows how to deal with synthetic events of React.)
  inputOnChange(event);
};

const FieldSelectComponent = (props) => {
  const {
    children,
    className,
    defaultOptionLabel,
    defaultOptionValue,
    id,
    input,
    label,
    meta,
    onChange,
    options,
    rootClassName,
    selectClassName,
    showDefaultOption,
    hint,
    ...rest
  } = props;

  if (label && !id) {
    throw new Error('id required when a label is given');
  }

  const { valid, invalid, touched, error } = meta;

  // Error message and input error styles are only shown if the
  // field has been touched and the validation has failed.
  const hasError = touched && invalid && error;

  const selectClasses = classNames(selectClassName, css.select, {
    [css.selectSuccess]: input.value && valid,
    [css.selectError]: hasError,
  });

  const { onChange: inputOnChange, ...restOfInput } = input;
  const selectProps = {
    className: selectClasses,
    id,
    onChange: handleChange(onChange, inputOnChange),
    ...restOfInput,
    ...rest,
  };

  const defaultOption = showDefaultOption ? (
    <option key={defaultOptionValue} value={defaultOptionValue}>
      {defaultOptionLabel}
    </option>
  ) : null;

  const fieldOptions =
    children ||
    options.map((option) => (
      <option key={option.key} value={option.value} disabled={option.disabled}>
        {option.label}
      </option>
    ));

  const classes = classNames(rootClassName || css.root, className);
  return (
    <div className={classes}>
      {label ? <label htmlFor={id}>{label}</label> : null}
      <select {...selectProps}>
        {defaultOption}
        {fieldOptions}
      </select>
      <ValidationError fieldMeta={meta} />
      {!hasError && hint && <span className={css.selectHint}>{hint}</span>}
    </div>
  );
};

FieldSelectComponent.defaultProps = {
  children: null,
  className: null,
  defaultOptionLabel: null,
  defaultOptionValue: null,
  id: null,
  label: null,
  rootClassName: null,
  selectClassName: null,
  showDefaultOption: false,
  hint: null,
};

FieldSelectComponent.propTypes = {
  children: node,
  className: string,
  defaultOptionLabel: string,
  defaultOptionValue: string,
  id: string,
  input: object.isRequired,
  label: string,
  meta: object.isRequired,
  onChange: func,
  options: arrayOf(
    shape({
      key: string.isRequired,
      value: string.isRequired,
      disabled: bool,
      label: string.isRequired,
    })
  ),
  rootClassName: string,
  selectClassName: string,
  showDefaultOption: bool,
};

const FieldSelect = (props) => {
  return <Field component={FieldSelectComponent} {...props} />;
};

export default FieldSelect;
