import React from 'react';
import { injectIntl, intlShape } from '../../util/reactIntl';
import { string } from 'prop-types';
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from 'react-accessible-accordion';
import classNames from 'classnames';

import css from './NeedHelp.css';
import { FormattedMessage } from '../../util/reactIntl';
import { NamedLink } from '../../components';

const NeedHelpComponent = (props) => {
  const { className, rootClassName } = props;

  const classes = classNames(rootClassName || css.root, className);

  const faqLink = (
    <NamedLink name="FaqPage" target="_blank" rel="noopener noreferrer">
      <FormattedMessage id="NeedHelp.FAQ" />
    </NamedLink>
  );

  return (
    <div className={classes}>
      <h3 className={css.heading}>
        <FormattedMessage id={'NeedHelp.heading'} />
      </h3>
      <Accordion allowMultipleExpanded={true} allowZeroExpanded={true}>
        <AccordionItem>
          <AccordionItemHeading>
            <AccordionItemButton>
              What if the person does not initially respond to my meeting request or rejects my
              meeting?
            </AccordionItemButton>
          </AccordionItemHeading>
          <AccordionItemPanel>
            <p>
              If the person does not respond to your initial request or accept your meeting within
              72 hours then the donation will not be charged and the reservation on your credit card
              will be released. The donation and fees are charged when a meeting is accepted by both
              parties.
            </p>
          </AccordionItemPanel>
        </AccordionItem>
        <AccordionItem>
          <AccordionItemHeading>
            <AccordionItemButton>What if I need to reschedule my meeting?</AccordionItemButton>
          </AccordionItemHeading>
          <AccordionItemPanel>
            <p>
              If your meeting date needs to be rescheduled then you can utilize the messaging
              feature within Givsly to agree with the other party how to reschedule the time of the
              meeting. We are not yet able to alter the already accepted and scheduled meeting times
              or methods in the platform but provide the messaging feature to communicate between
              parties.
            </p>
            <p>
              Currently the easiest way to reschedule is to decline or cancel the existing meeting
              and to make a new meeting request to a time slot that the parties have agreed to
              through our messaging feature. The cancellation also cancels and refunds the initial
              nonprofit donation and the new booking creates another one instead. If after an
              initially accepted meeting the volunteering party cancels the meeting, the donation
              and the Givsly fee will be refunded but payment processing fee is charged. If after an
              initially accepted meeting the booking party cancels the meeting, the donation amount
              will be refunded but the Givsly fee and payment processing fee will be charged.
            </p>
          </AccordionItemPanel>
        </AccordionItem>
        <AccordionItem>
          <AccordionItemHeading>
            <AccordionItemButton>What if my meeting gets canceled?</AccordionItemButton>
          </AccordionItemHeading>
          <AccordionItemPanel>
            <p>
              If your meeting is canceled after it has been initially accepted by both parties, we
              recommend promptly trying to reschedule with the other party using the messaging
              function in Givsly.
            </p>
            <p>
              The cancellation also cancels and refunds the initial nonprofit donation. If after an
              initially accepted meeting the volunteering party cancels the meeting, the donation
              and the Givsly fee will be refunded but payment processing fee is charged. If after an
              initially accepted meeting the booking party cancels the meeting, the donation amount
              will be refunded but the Givsly fee and payment processing fee will be charged.
            </p>
          </AccordionItemPanel>
        </AccordionItem>
        <AccordionItem>
          <AccordionItemHeading>
            <AccordionItemButton>Is the donation a tax write off?</AccordionItemButton>
          </AccordionItemHeading>
          <AccordionItemPanel>
            <p>
              No. The donation made to a nonprofit on the platform is not a tax write off because
              there is value in the meeting in exchange for the donation.
            </p>
          </AccordionItemPanel>
        </AccordionItem>
        <AccordionItem>
          <AccordionItemHeading>
            <AccordionItemButton>Can I get a refund?</AccordionItemButton>
          </AccordionItemHeading>
          <AccordionItemPanel>
            <p>
              When a booking request is made, an initial hold for the amount of the booking donation
              and Givsly fee as well as payment processing fee, is made on the booker's credit card.
            </p>
            <p>
              If the meeting request is not responded to by the volunteer within 72 hours, the
              credit card hold on the booker’s credit card is released. If the meeting volunteer
              accepts the meeting within the allotted time, the reserved funds are charged from the
              credit card.
            </p>
            <p>
              For an initially accepted meeting, should the volunteering party cancel, then the
              donation and the Givsly fee will be refunded but the payment processing fee is
              charged. For an initially accepted meeting, should the booking party cancel the
              meeting, then the donation amount will be refunded but the Givsly fee and payment
              processing fee will be charged.
            </p>
            <p>
              In order to consider changes to any captured payments after the meeting has passed,
              Givsly should be made aware of initially accepted but then cancelled meetings or
              no-shows within 24 hours after the original meeting time and date. Givsly will need to
              confirm the cancellation from the other meeting party. For these scenarios or any such
              other potential situations, users should reach out to{' '}
              <a href="mailto:support@givsly.com">support@givsly.com</a>.
            </p>
          </AccordionItemPanel>
        </AccordionItem>
      </Accordion>
      <p className={css.footer}>
        <FormattedMessage id={'NeedHelp.dontFind'} values={{ faqLink }} />
      </p>
    </div>
  );
};

NeedHelpComponent.defaultProps = {
  className: null,
  rootClassName: null,
};

NeedHelpComponent.propTypes = {
  className: string,
  rootClassName: string,
  intl: intlShape.isRequired,
};

const NeedHelp = injectIntl(NeedHelpComponent);

export default NeedHelp;
