import React from 'react';
import css from '../PartnershipsPage.css';
import { FormattedMessage } from '../../../util/reactIntl';
import { useMessages } from '../../../util/localization';
import RemoveFieldButton from '../../../components/NonprofitPreferencesPanel/RemoveFieldButton';
import { Button, FieldDateInput, FieldTextInput } from '../../../components';
import LiteButton from '../../../components/LiteButton/LiteButton';
import { ICON_PLUS, STYLE_LIGHT } from '../../../components/LiteButton/constants';
import FieldCheckboxComponent from '../../../components/FieldCheckbox/FieldCheckbox';
import { EVENT_TEMPLATE } from '../constants';
import { required } from '../../../util/validators';

const CausesTab = (formRenderProps) => {
  const getMessage = useMessages('NonprofitPreferencesForm');
  const { form, values, isSubmitting, causesAndEventsComplete } = formRenderProps;

  return (
    <div className={css.content}>
      <h1 className={css.title}>
        <FormattedMessage id="PartnershipsPage.CausesTab.title" />
      </h1>
      <section className={css.section}>
        <h3 className={css.sectionTitle}>
          {getMessage('section.causes.title')}
          <span className={css.optional}>{getMessage('optional')}</span>
        </h3>
        <p className={css.small}>{getMessage('section.causes.description')}</p>
        {values.causes.map((cause, index) => (
          <div key={index} className={css.inputRow}>
            {index > 0 && <br />}
            <FieldTextInput
              className={css.field}
              id={`causes.${index}`}
              label={getMessage('field.causes.label')}
              name={`causes.${index}`}
              placeholder={getMessage('field.causes.placeholder')}
              type={'text'}
              validate={required(getMessage('field.causes.required'))}
            />
            <RemoveFieldButton
              onClick={() =>
                form.change(
                  'causes',
                  values.causes.filter((_cause) => _cause !== cause)
                )
              }
            />
          </div>
        ))}
        <br />
        <LiteButton
          icon={ICON_PLUS}
          onClick={() => form.change('causes', [...values.causes, ''])}
          style={STYLE_LIGHT}
        >
          {getMessage('liteButton.new')}
        </LiteButton>
      </section>

      <section className={css.section}>
        <h3 className={css.sectionTitle}>
          {getMessage('section.events')}
          <span className={css.optional}>{getMessage('optional')}</span>
        </h3>
        <p className={css.small}>{getMessage('paragraph.events')}</p>
        {values.events.map((event, index) => (
          <div key={index}>
            {index > 0 && <hr className={css.divider} />}
            <div className={css.inputRow}>
              <FieldTextInput
                className={css.field}
                id={`events.${index}.name`}
                label={getMessage('field.event.name.label')}
                name={`events.${index}.name`}
                placeholder={getMessage('field.event.name.placeholder')}
                type={'text'}
              />
              <RemoveFieldButton
                onClick={() =>
                  form.change(
                    'events',
                    values.events.filter((_event) => _event !== event)
                  )
                }
              />
            </div>

            <div className={css.dateRow}>
              <FieldDateInput
                id={`events.${index}.start`}
                name={`events.${index}.start`}
                label={
                  <>
                    {getMessage('field.event.start.label')}
                    <span className={css.optional}>{getMessage('optional')}</span>
                  </>
                }
                isDayBlocked={() => false}
                isOutsideRange={(date) => false}
                disabled={
                  values.events[index].yearRound.length === 1 &&
                  values.events[index].yearRound.includes('Yes')
                }
              />
              <FieldDateInput
                id={`events.${index}.end`}
                name={`events.${index}.end`}
                label={
                  <>
                    {getMessage('field.event.end.label')}
                    <span className={css.optional}>{getMessage('optional')}</span>
                  </>
                }
                disabled={
                  values.events[index].yearRound.length === 1 &&
                  values.events[index].yearRound.includes('Yes')
                }
                isDayBlocked={(date) => date.isBefore(values.events[index].start.date)}
                isOutsideRange={(date) => date.isBefore(values.events[index].start.date)}
              />
            </div>
            <FieldTextInput
              className={css.field}
              id={`events.${index}.url`}
              label={getMessage('field.event.url.label')}
              name={`events.${index}.url`}
              placeholder={getMessage('field.event.url.placeholder')}
              type="url"
              labelSuffix={getMessage('optional')}
            />
            <FieldTextInput
              className={css.field}
              id={`events.${index}.info`}
              label={getMessage('field.event.info.label')}
              name={`events.${index}.info`}
              placeholder={getMessage('field.event.info.placeholder')}
              type={'text'}
              labelSuffix={getMessage('optional')}
            />
            <br />
            <FieldCheckboxComponent
              id={`events.${index}.yearRound`}
              label={getMessage('field.event.yearRound.label')}
              name={`events.${index}.yearRound`}
              value="Yes"
              kind="checkbox"
              onChange={({ checked }) => {
                if (checked) {
                  form.change(`events.${index}.end`, {});
                  form.change(`events.${index}.start`, {});
                }
              }}
            />
          </div>
        ))}
        <br />
        <LiteButton
          icon={ICON_PLUS}
          onClick={() => form.change('events', [...values.events, { ...EVENT_TEMPLATE }])}
          style={STYLE_LIGHT}
        >
          {getMessage('liteButton.new')}
        </LiteButton>
      </section>

      <section className={[css.section, css.stickySaveButtonSection].join(' ')}>
        <Button className={css.submit} type="submit" inProgress={isSubmitting}>
          {causesAndEventsComplete ? (
            <FormattedMessage id="PartnershipsPage.CausesTab.submit" />
          ) : (
            <FormattedMessage id="PartnershipsPage.CausesTab.next" />
          )}
        </Button>
      </section>
    </div>
  );
};

export default CausesTab;
