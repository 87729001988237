import React, { Component } from 'react';
import { compose } from 'redux';
import { injectIntl } from 'react-intl';
import { withRouter } from 'react-router-dom';
import { intlShape } from '../../util/reactIntl';
import { connect } from 'react-redux';
import { arrayOf, bool, func, object, shape, string } from 'prop-types';
import { propTypes } from '../../util/types';
import {
  Footer,
  LayoutSingleColumn,
  LayoutWrapperFooter,
  LayoutWrapperMain,
  LayoutWrapperTopbar,
  NamedRedirect,
  Page,
} from '../../components';
import { isScrollingDisabled } from '../../ducks/UI.duck';
import { TopbarContainer } from '../index';
import EventLandingPageContainer from './components/EventLandingPageContainer/EventLandingPageContainer';
import css from './EventLandingPage.css';
import { loadPeopleSearchData } from '../SearchPage/SearchPage.duck';
import { getListingsById, getMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import { withTheme } from '../../high-order-components/withTheme/withTheme';
import { ensureCurrentUser, ensureListing } from '../../util/data';
import { isMomentAfter, isMomentInsideRange } from '../../util/dates';
import { trackEventAction } from '../../ducks/Analytics.duck';
import { listings } from '../../ducks/Listings.duck';
import { getEvent } from '../../util/events';

class EventLandingPageComponent extends Component {
  constructor(props) {
    super(props);

    this.trackEvent = this.trackEvent.bind(this);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (!this.event) return;

    this.props.setTheme({
      ...this.event.theme,
      ...(this.eventHasEnded ? this.event.postEventPage.theme : {}),
    });
  }

  get event() {
    return getEvent(this.props.params.eventKey);
  }

  get eventHasEnded() {
    return (
      this.event.open &&
      this.event.open.to &&
      isMomentAfter(new Date(), this.event.open.to, this.event.timezone)
    );
  }

  get isEventVisible() {
    return (
      this.event &&
      isMomentInsideRange(
        new Date(),
        this.event.visible.from,
        this.event.visible.to,
        this.event.timezone
      )
    );
  }

  trackEvent(params) {
    if (!this.event) return;

    this.props.trackEvent({
      ...params,
      category: `EventLandingPage-${this.event.key}`,
    });
  }

  render() {
    const {
      getListingsByEventRole,
      getListing,
      intl,
      isAuthenticated,
      history,
      listings,
      npoListingNames,
      scrollingDisabled,
      timezone,
    } = this.props;

    if (!this.event || !this.isEventVisible) {
      return <NamedRedirect name={'LandingPage'} />;
    }

    return (
      <Page title={this.event.title} scrollingDisabled={scrollingDisabled}>
        <LayoutSingleColumn className={css.pageRoot}>
          <LayoutWrapperTopbar>
            <TopbarContainer />
          </LayoutWrapperTopbar>
          <LayoutWrapperMain>
            <EventLandingPageContainer
              intl={intl}
              event={this.event}
              getListingsByEventRole={getListingsByEventRole}
              getListing={getListing}
              history={history}
              isAuthenticated={isAuthenticated}
              listings={listings}
              npoListingNames={npoListingNames}
              supportedNonprofits={this.event.supportedNPOs}
              timezone={timezone}
              trackEvent={this.trackEvent}
            />
          </LayoutWrapperMain>
          <LayoutWrapperFooter>
            <Footer />
          </LayoutWrapperFooter>
        </LayoutSingleColumn>
      </Page>
    );
  }
}

EventLandingPageComponent.defaultProps = {
  timezone: 'America/New_York',
};

EventLandingPageComponent.propTypes = {
  currentUser: propTypes.currentUser,
  getListing: func.isRequired,
  getListingsByEventRole: func.isRequired,
  history: shape({
    push: func.isRequired,
  }).isRequired,
  isAuthenticated: bool.isRequired,
  intl: intlShape.isRequired,
  listings: arrayOf(propTypes.listing).isRequired,
  npoListingNames: object.isRequired,
  params: shape({
    eventKey: string.isRequired,
  }).isRequired,
  scrollingDisabled: bool.isRequired,
  timezone: string.isRequired,
  trackEvent: func.isRequired,
};

const mapStateToProps = (state) => {
  const { isAuthenticated } = state.Auth;
  const { currentUser } = state.user;
  const { currentPageResultIds } = state.SearchPage;
  const { npoListingNames } = state.Listings;

  const pageListings = getListingsById(state, currentPageResultIds);

  const getListing = (id) => {
    const ref = { id, type: 'listing' };
    const listings = getMarketplaceEntities(state, [ref]);

    return listings.length === 1 ? listings[0] : null;
  };

  const getListingsByEventRole = (eventRole) => {
    if (!eventRole) return pageListings;
    return pageListings.filter((listing) =>
      ensureListing(listing).attributes.publicData.eventRoles.includes(eventRole)
    );
  };

  const scrollingDisabled = isScrollingDisabled(state);

  const timezone = ensureCurrentUser(currentUser).attributes.profile.publicData.timezone;

  return {
    currentUser,
    getListing,
    getListingsByEventRole,
    isAuthenticated,
    listings: pageListings,
    npoListingNames,
    scrollingDisabled,
    timezone,
  };
};

const mapDispatchToProps = (dispatch) => ({
  trackEvent: (params) => dispatch(trackEventAction(params)),
});

const EventLandingPage = compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  injectIntl,
  withTheme
)(EventLandingPageComponent);

EventLandingPage.loadData = (params, search) => async (dispatch) => {
  const event = getEvent(params.eventKey);

  // Load event NPOs
  await dispatch(listings(event.supportedNPOs));

  // Process content blocks, as some blocks require additional data to be loaded from Sharetribe
  for (let i = 0; i < event.landingPage.contentBlocks.length; i++) {
    const contentBlock = event.landingPage.contentBlocks[i];
    if (contentBlock.type === 'people' && contentBlock.listings && contentBlock.listings.length) {
      // If the content block has pre-defined listings, these will be loaded individually
      await dispatch(listings(contentBlock.listings));
    } else if (contentBlock.type === 'people') {
      // If the content block does not have any pre-defined listings, a filter is used based on
      // the user roles.
      await dispatch(
        loadPeopleSearchData(params, search, { pub_eventRoles: `has_any:${contentBlock.userRole}` })
      );
    }
  }
};

export default EventLandingPage;
