import React from 'react';
import { bool, func, oneOf, shape, string } from 'prop-types';
import routeConfiguration from '../../routeConfiguration';
import config from '../../config';
import { FormattedMessage, intlShape } from '../../util/reactIntl';
import { LISTING_STATE_PUBLISHED, propTypes } from '../../util/types';
import { NamedRedirect, StripeConnectAccountStatusBox } from '../../components';
import { StripeConnectAccountForm } from '../../forms';
import {
  STRIPE_ONBOARDING_RETURN_URL_FAILURE,
  STRIPE_ONBOARDING_RETURN_URL_SUCCESS,
  STRIPE_ONBOARDING_RETURN_URL_TYPES,
} from '../../containers/NonprofitOnboardingPage/constants';
import {
  createReturnURL,
  getBankAccountLast4Digits,
  getStripeAccountData,
  handleGetStripeConnectAccountLinkFn,
  hasRequirements,
} from '../../util/stripe';

class PayoutDetailsPanel extends React.Component {
  render() {
    const {
      createStripeAccountError,
      currentUser,
      currentUserListing,
      fetchStripeAccountError,
      getAccountLinkInProgress,
      handlePublish,
      intl,
      onPayoutDetailsFormChange,
      onPayoutDetailsFormSubmit,
      onGetStripeConnectAccountLink,
      pageName,
      params,
      payoutDetailsSaveInProgress,
      payoutDetailsSaved,
      stripeAccount,
      stripeAccountFetched,
      updateStripeAccountError,
    } = this.props;

    const { returnURLType } = params;
    const currentUserLoaded = !!currentUser.id;
    const stripeConnected = currentUserLoaded && !!stripeAccount && !!stripeAccount.id;

    const formDisabled = getAccountLinkInProgress;

    const rootURL = config.canonicalRootURL;
    const routes = routeConfiguration();
    const successURL = createReturnURL(
      STRIPE_ONBOARDING_RETURN_URL_SUCCESS,
      rootURL,
      routes,
      pageName,
      params
    );
    const failureURL = createReturnURL(
      STRIPE_ONBOARDING_RETURN_URL_FAILURE,
      rootURL,
      routes,
      pageName,
      params
    );

    const accountId = stripeConnected ? stripeAccount.id : null;
    const stripeAccountData = stripeConnected ? getStripeAccountData(stripeAccount) : null;
    const requirementsMissing =
      stripeAccount &&
      (hasRequirements(stripeAccountData, 'past_due') ||
        hasRequirements(stripeAccountData, 'currently_due'));

    const savedCountry = stripeAccountData ? stripeAccountData.country : null;

    const handleGetStripeConnectAccountLink = handleGetStripeConnectAccountLinkFn(
      onGetStripeConnectAccountLink,
      {
        accountId,
        successURL,
        failureURL,
      }
    );

    const returnedNormallyFromStripe = returnURLType === STRIPE_ONBOARDING_RETURN_URL_SUCCESS;
    const showVerificationError = returnURLType === STRIPE_ONBOARDING_RETURN_URL_FAILURE;
    const showVerificationNeeded = stripeConnected && requirementsMissing;

    // Redirect from success URL to basic path for StripePayoutPage
    if (returnedNormallyFromStripe && stripeConnected && !requirementsMissing) {
      return <NamedRedirect name="StripePayoutPage" />;
    }

    return (
      <>
        {!currentUserLoaded ? (
          <FormattedMessage id="StripePayoutPage.loadingData" />
        ) : (
          <StripeConnectAccountForm
            disabled={formDisabled}
            inProgress={payoutDetailsSaveInProgress}
            intl={intl}
            isPublished={currentUserListing.attributes.state === LISTING_STATE_PUBLISHED}
            ready={payoutDetailsSaved}
            stripeBankAccountLastDigits={getBankAccountLast4Digits(stripeAccountData)}
            savedCountry={savedCountry}
            submitButtonText={intl.formatMessage({
              id: stripeConnected
                ? 'PayoutDetailsPanel.publishProfile'
                : 'PayoutDetailsPanel.saveChanges',
            })}
            stripeAccountError={
              createStripeAccountError || updateStripeAccountError || fetchStripeAccountError
            }
            stripeAccountFetched={stripeAccountFetched}
            onChange={onPayoutDetailsFormChange}
            onSubmit={onPayoutDetailsFormSubmit}
            onPublish={handlePublish}
            onGetStripeConnectAccountLink={handleGetStripeConnectAccountLink}
            stripeConnected={stripeConnected}
          >
            {stripeConnected && (showVerificationError || showVerificationNeeded) ? (
              <StripeConnectAccountStatusBox
                type={showVerificationError ? 'verificationError' : 'verificationNeeded'}
                inProgress={getAccountLinkInProgress}
                onGetStripeConnectAccountLink={handleGetStripeConnectAccountLink(
                  'custom_account_verification'
                )}
              />
            ) : stripeConnected && savedCountry ? (
              <StripeConnectAccountStatusBox
                type="verificationSuccess"
                inProgress={getAccountLinkInProgress}
                disabled={payoutDetailsSaveInProgress}
                onGetStripeConnectAccountLink={handleGetStripeConnectAccountLink(
                  'custom_account_update'
                )}
              />
            ) : null}
          </StripeConnectAccountForm>
        )}
      </>
    );
  }
}

PayoutDetailsPanel.defaultProps = {
  currentUser: null,
  createStripeAccountError: null,
  fetchStripeAccountError: null,
  pageName: 'NonprofitOnboardingPage',
  params: {
    returnURLType: null,
  },
  stripeAccount: null,
  updateStripeAccountError: null,
};

PayoutDetailsPanel.propTypes = {
  createStripeAccountError: propTypes.error,
  currentUser: propTypes.currentUser,
  currentUserListing: propTypes.ownListing.isRequired,
  fetchStripeAccountError: propTypes.error,
  getAccountLinkInProgress: bool.isRequired,
  handlePublish: func.isRequired,
  intl: intlShape.isRequired,
  onPayoutDetailsFormChange: func.isRequired,
  onPayoutDetailsFormSubmit: func.isRequired,
  onGetStripeConnectAccountLink: func.isRequired,
  pageName: string,
  params: shape({
    returnURLType: oneOf(STRIPE_ONBOARDING_RETURN_URL_TYPES),
  }),
  payoutDetailsSaved: bool.isRequired,
  payoutDetailsSaveInProgress: bool.isRequired,
  stripeAccount: propTypes.stripeAccount,
  updateStripeAccountError: propTypes.error,
};

export default PayoutDetailsPanel;
