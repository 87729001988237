import React, { useState, useCallback } from 'react';
import css from '../PartnershipsPage.css';
import { FormattedMessage } from '../../../util/reactIntl';
import { Button, FieldTextInput } from '../../../components';
import { useMessages } from '../../../util/localization';
import { emailFormatValid, required } from '../../../util/validators';
import FieldCheckboxComponent from '../../../components/FieldCheckbox/FieldCheckbox';
import { ACCEPT_IMAGES, UPLOAD_MESSAGES } from '../constants';
import RemoveFieldButton from '../../../components/NonprofitPreferencesPanel/RemoveFieldButton';
import { Field } from 'react-final-form';

const ApprovalTab = (formRenderProps) => {
  const { isSubmitting, values, form, invalid, partnershipInformationComplete } = formRenderProps;
  const getMessage = useMessages('NonprofitPreferencesForm');
  const getTranslation = useMessages('PartnershipsPage');

  const [imageUploadValidations, setImageUploadValidations] = useState({});

  const approvePortfolioPresentation =
    values.approvePortfolioPresentation &&
    values.approvePortfolioPresentation.length === 1 &&
    values.approvePortfolioPresentation.includes('Yes');

  const readImageToFormValue = useCallback(
    /**
     *
     * @param {React.ChangeEvent<HTMLInputElement>} imageChangeEvent
     */
    (imageChangeEvent, key) => {
      imageChangeEvent.persist();

      const siteFileUrl = (url) =>
        form.change('brandingGuidelines', {
          ...values.brandingGuidelines,
          [key]: url,
        });

      const file = imageChangeEvent.target.files[0];
      const inputName = imageChangeEvent.target.name;
      const allowedSize = file.size <= 5000000;
      const allowedType = /(jpeg|jpg|png|pdf|eps|svg)/.test(file.type);

      if (!allowedSize) {
        setImageUploadValidations((state) => ({
          ...state,
          [inputName]: UPLOAD_MESSAGES.TOO_BIG,
        }));
        siteFileUrl('');
        return;
      }
      if (!allowedType) {
        setImageUploadValidations((state) => ({
          ...state,
          [inputName]: UPLOAD_MESSAGES.WRONG_FORMAT,
        }));
        siteFileUrl('');
        return;
      }

      var reader = new FileReader();
      reader.onload = function (fileReaderEvent) {
        console.log(fileReaderEvent.target.result);
        siteFileUrl(fileReaderEvent.target.result);
      };

      setImageUploadValidations((state) => ({
        ...state,
        [inputName]: '',
      }));
      reader.readAsDataURL(imageChangeEvent.target.files[0]);
    },
    [form, values.brandingGuidelines]
  );
  return (
    <div className={css.content}>
      <h1 className={css.title}>
        <FormattedMessage id="PartnershipsPage.ApprovalTab.title" />
      </h1>
      <section className={css.section}>
        <h3 className={css.sectionTitle}>
          <FormattedMessage id="PartnershipsPage.ApprovalTab.approval.title" />
        </h3>
        <FieldCheckboxComponent
          id="approvePortfolioPresentation"
          label={getTranslation('ApprovalTab.approval.label')}
          name="approvePortfolioPresentation"
          value="Yes"
          kind="checkbox"
          validate={
            !partnershipInformationComplete
              ? required('You must join program in order to proceed')
              : undefined
          }
        />
        <span className={css.disclaimer}>{getTranslation('ApprovalTab.approval.note')}</span>
      </section>
      {approvePortfolioPresentation && (
        <>
          <section className={css.section}>
            <h3 className={css.sectionTitle}>{getTranslation('ApprovalTab.contact.title')}</h3>
            <p className={css.small} style={{ fontWeight: 'lighter' }}>
              {getTranslation('ApprovalTab.contact.description')}
            </p>
            <FieldTextInput
              className={css.field}
              id="approvalContact.name"
              label={getMessage('field.approvalContact.name.label')}
              name="approvalContact.name"
              placeholder={getMessage('field.approvalContact.name.placeholder')}
              type="text"
              required
              validate={required(getMessage('field.approvalContact.name.required'))}
            />

            <FieldTextInput
              className={css.field}
              id="approvalContact.email"
              label={getMessage('field.approvalContact.email.label')}
              name="approvalContact.email"
              placeholder={getMessage('field.approvalContact.email.placeholder')}
              type="email"
              validate={emailFormatValid(getMessage('field.approvalContact.email.required'), true)}
            />
          </section>
          <section className={css.section}>
            <h3 className={css.sectionTitle}>{getMessage('section.branding')}</h3>
            <p className={css.small}>{getMessage('paragraph.branding')}</p>
            <div className={css.uploadRow}>
              <Field
                name={`brandingGuidelines.logoLightUrl`}
                type="file"
                value={values.brandingGuidelines.logoLightUrl}
                validate={required(getMessage('field.branding.logoLightUrl.required'))}
              >
                {(fieldRenderProps) => (
                  <label htmlFor={fieldRenderProps.input.name} className={css.imageUploadLabel}>
                    <img
                      src={values.brandingGuidelines.logoLightUrl}
                      className={css.imageUpload}
                      data-invalid={fieldRenderProps.meta.invalid}
                      alt={
                        fieldRenderProps.meta.error ||
                        imageUploadValidations[`brandingGuidelines.logoLightUrl`] ||
                        'Upload logo for light'
                      }
                    />
                    <input
                      accept={ACCEPT_IMAGES}
                      type={fieldRenderProps.input.type}
                      id={fieldRenderProps.input.name}
                      name={fieldRenderProps.input.name}
                      onChange={(event) => readImageToFormValue(event, 'logoLightUrl')}
                    />
                    <RemoveFieldButton
                      onClick={() => {
                        form.change('brandingGuidelines', {
                          ...values.brandingGuidelines,
                          logoLightUrl: '',
                        });
                      }}
                    />
                  </label>
                )}
              </Field>
              <Field name={`brandingGuidelines.logoDarkUrl`} type="file">
                {(fieldRenderProps) => (
                  <label htmlFor={fieldRenderProps.input.name} className={css.imageUploadLabel}>
                    <img
                      src={values.brandingGuidelines.logoDarkUrl}
                      className={css.imageUpload}
                      alt={
                        imageUploadValidations[`brandingGuidelines.logoDarkUrl`] ||
                        'Upload logo for dark'
                      }
                    />
                    <input
                      accept={ACCEPT_IMAGES}
                      type={fieldRenderProps.input.type}
                      id={fieldRenderProps.input.name}
                      name={fieldRenderProps.input.name}
                      onChange={(event) => readImageToFormValue(event, 'logoDarkUrl')}
                    />
                    <RemoveFieldButton
                      onClick={() => {
                        form.change('brandingGuidelines', {
                          ...values.brandingGuidelines,
                          logoDarkUrl: '',
                        });
                      }}
                    />
                  </label>
                )}
              </Field>
            </div>

            <p className={css.disclaimer}>{getMessage('paragraph.fileUpload')}</p>
            <FieldTextInput
              className={css.field}
              id="brandingGuidelines.guidelineUrl"
              label={getMessage('field.branding.URL.label')}
              name="brandingGuidelines.guidelineUrl"
              placeholder={getMessage('field.branding.URL.placeholder')}
              type="url"
              labelSuffix={getMessage('optional')}
            />
            <p className={css.disclaimer}>{getMessage('field.branding.URL.tip')}</p>
          </section>
        </>
      )}
      <section className={[css.section, css.stickySaveButtonSection].join(' ')}>
        <Button className={css.submit} type="submit" disabled={invalid} inProgress={isSubmitting}>
          {partnershipInformationComplete ? (
            <FormattedMessage id="PartnershipsPage.ApprovalTab.submit" />
          ) : (
            <FormattedMessage id="PartnershipsPage.ApprovalTab.next" />
          )}
        </Button>
      </section>
    </div>
  );
};

export default ApprovalTab;
