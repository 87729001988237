import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';

import { NamedLink } from '../../components';

import css from './SectionLocations.css';

const SectionLocations = (props) => {
  const { rootClassName, className } = props;

  const classes = classNames(rootClassName || css.root, className);

  return (
    <div className={classes}>
      <div className={css.title}>
        <FormattedMessage id="SectionLocations.title" values={{ location: 'New York, NY' }} />
      </div>

      <NamedLink name="SearchPage" to={{ search: 'pub_location=newyork' }} className={css.location}>
        <div className={css.linkText}>
          <FormattedMessage id="SectionLocations.browseAll" />
        </div>
      </NamedLink>
    </div>
  );
};

SectionLocations.defaultProps = { rootClassName: null, className: null };

const { string } = PropTypes;

SectionLocations.propTypes = {
  rootClassName: string,
  className: string,
};

export default SectionLocations;
