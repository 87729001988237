import React, { Component } from 'react';
import { bool, func, string } from 'prop-types';
import { compose } from 'redux';
import { Form as FinalForm } from 'react-final-form';
import isEqual from 'lodash/isEqual';
import classNames from 'classnames';
import arrayMutators from 'final-form-arrays';
import { FieldArray } from 'react-final-form-arrays';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import { ensureCurrentUser } from '../../util/data';
import { propTypes } from '../../util/types';
import {
  Form,
  FieldCheckboxRadioGroup,
  Button,
  FieldTextInput,
  ProfileSubmitButtonWrapper,
} from '../../components';
import css from './InterestsForm.css';
import config from '../../config';
import FormSectionHeading from '../../components/FormSectionHeading/FormSectionHeading';
import { INTERESTS, ONBOARDING_TAB_NUMBERS } from '../../components/OnboardingWizard/constants';

class InterestsFormComponent extends Component {
  constructor(props) {
    super(props);
    this.state = { customTopics: [], hasCustomInterests: false };
    this.submittedValues = {};
  }

  onCheckboxChange = (value) => {
    if (!this.props.trackEvent) return;

    this.props.trackEvent({
      action: value.checked ? 'Select' : 'Remove',
      label: 'Meeting topic',
      value: value.value,
    });
  };

  render() {
    const { intl } = this.props;

    return (
      <FinalForm
        {...this.props}
        mutators={{
          ...arrayMutators,
        }}
        onChange={this.handleChange}
        validate={(values) => {
          const errors = {};
          const interestsSelectedTotal = values.interests.length;
          const customInterestsSelectedTotal = values.customInterests.filter(
            (interest) => !!interest
          ).length; // Do not count empty string
          if (interestsSelectedTotal === 0 && customInterestsSelectedTotal === 0) {
            errors.interests = intl.formatMessage({ id: 'InterestsForm.selectedAtLeastOne' });
          }
          return errors;
        }}
        render={(fieldRenderProps) => {
          const {
            className,
            currentUser,
            handleSubmit,
            invalid,
            pristine,
            rootClassName,
            updateInProgress,
            updateCompanyInfoError,
            values,
          } = fieldRenderProps;

          const user = ensureCurrentUser(currentUser);
          const { onboardingCompleted } = user.attributes.profile.publicData;
          const customTopicLabel = intl.formatMessage({ id: 'InterestsForm.customTopicLabel' });
          const customTopicPlaceholder = intl.formatMessage({
            id: 'InterestsForm.customTopicPlaceholder',
          });

          const submitError = updateCompanyInfoError ? (
            <div className={css.error}>
              <FormattedMessage id="InterestsForm.updateCompanyInfoFailed" />
            </div>
          ) : null;

          const classes = classNames(rootClassName || css.root, className);
          const submitInProgress = updateInProgress;
          const submittedOnce = Object.keys(this.submittedValues).length > 0;
          const pristineSinceLastSubmit = submittedOnce && isEqual(values, this.submittedValues);
          const submitDisabled =
            invalid ||
            submitInProgress ||
            (onboardingCompleted && (pristine || pristineSinceLastSubmit));

          const submitLabelId = onboardingCompleted
            ? 'InterestsForm.saveChanges'
            : 'InterestsForm.next';

          return (
            <Form
              className={classes}
              onSubmit={(e) => {
                this.submittedValues = values;
                handleSubmit(e);
              }}
            >
              <div className={classNames(css.sectionContainer, css.lastSection)}>
                <FieldCheckboxRadioGroup
                  className={css.topics}
                  id="interests"
                  name="interests"
                  options={config.custom.interests}
                  twoColumns={true}
                  onChange={this.onCheckboxChange}
                />
                <FormSectionHeading
                  subHeader={<FormattedMessage id="InterestsForm.additionalTopics" />}
                />
                <FieldArray className={css.root} name="customInterests">
                  {({ fields }) => {
                    if (fields.length === 0) {
                      fields.push('');
                    }
                    return (
                      <div>
                        {fields.map((name, index) => (
                          <div className={css.additionalTopic} key={index}>
                            <FieldTextInput
                              className={css.additionalTopicField}
                              type="text"
                              id={`customInterests-${index}`}
                              name={name}
                              label={customTopicLabel}
                              placeholder={customTopicPlaceholder}
                            />
                            <Button
                              className={css.deleteButton}
                              onClick={() => fields.remove(index)}
                              type="button"
                            />
                          </div>
                        ))}
                        <Button
                          className={css.addTopicButton}
                          onClick={() => fields.push('')}
                          type="button"
                        >
                          <FormattedMessage id="InterestsForm.addTopic" />
                        </Button>
                      </div>
                    );
                  }}
                </FieldArray>
              </div>
              {submitError}
              <ProfileSubmitButtonWrapper
                stepNumber={ONBOARDING_TAB_NUMBERS[INTERESTS]}
                inProgress={submitInProgress}
                disabled={submitDisabled}
                ready={pristineSinceLastSubmit}
                onboardingCompleted={onboardingCompleted}
              >
                <FormattedMessage id={submitLabelId} />
              </ProfileSubmitButtonWrapper>
            </Form>
          );
        }}
      />
    );
  }
}

InterestsFormComponent.defaultProps = {
  rootClassName: null,
  className: null,
  updateCompanyInfoError: null,
  updateCompanyInfoReady: false,
};

InterestsFormComponent.propTypes = {
  rootClassName: string,
  className: string,
  intl: intlShape.isRequired,
  updateInProgress: bool.isRequired,
  updateCompanyInfoError: propTypes.error,
  updateCompanyInfoReady: bool,
  trackEvent: func,
};

const InterestsForm = compose(injectIntl)(InterestsFormComponent);

InterestsForm.displayName = 'InterestsForm';

export default InterestsForm;
