import React, { Component } from 'react';
import {
  array,
  arrayOf,
  bool,
  func,
  instanceOf,
  object,
  oneOfType,
  shape,
  string,
} from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import remove from 'lodash/remove';
import {
  METHOD_CREDIT_CARD,
  METHOD_GIVSLY_CREDIT,
} from '../../components/PaymentMethods/constants';
import { trackEventAction } from '../../ducks/Analytics.duck';
import { addCredit, getBalance } from '../../ducks/credit.duck';
import { withRouter } from 'react-router-dom';
import moment from 'moment-timezone/builds/moment-timezone-with-data-10-year-range.min';
import config from '../../config';
import { listings } from '../../ducks/Listings.duck';
import { getMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import {
  getOutreachOffer,
  queryOutreachOffers,
  getOutreachRequest,
  updateOutreachRequest,
  queryOutreachRequests,
} from '../../ducks/outreach.duck';
import { handlePayment } from '../../ducks/payment.duck';
import { fetchCurrentUser } from '../../ducks/user.duck';
import { updateProfile } from '../../ducks/UserProfile.duck';
import routeConfiguration from '../../routeConfiguration';
import { createSecret } from '../../util/credit';
import { createResourceLocatorString } from '../../util/routes';
import { types as sdkTypes } from '../../util/sdkLoader';
import { getClientSecret, getPaymentParams } from '../../util/stripe';
import {
  DATE_TYPE_DATETIME,
  ERROR_CODE_CHARGE_ZERO_PAYIN,
  ERROR_CODE_MISSING_STRIPE_ACCOUNT,
  ERROR_CODE_PAYMENT_FAILED,
  ERROR_CODE_TRANSACTION_BOOKING_TIME_NOT_AVAILABLE,
  ERROR_CODE_TRANSACTION_LISTING_NOT_FOUND,
  propTypes,
} from '../../util/types';
import {
  ensureBooking,
  ensureCurrentUser,
  ensureListing,
  ensurePaymentMethodCard,
  ensureStripeCustomer,
  ensureTransaction,
  ensureUser,
} from '../../util/data';
import { minutesBetween } from '../../util/dates';
import { createSlug } from '../../util/urlHelpers';
import {
  errorAPIErrors,
  isTransactionChargeDisabledError,
  isTransactionInitiateAmountTooLowError,
  transactionInitiateOrderStripeErrors,
} from '../../util/errors';
import {
  getEstimatedTransaction,
  txIsPaymentExpired,
  txIsPaymentPending,
} from '../../util/transaction';
import {
  AvatarHuge,
  BookingBreakdown,
  EstimatedBreakdown,
  Logo,
  MobileAvatarHero,
  NamedLink,
  Page,
} from '../../components';
import { PaymentForm } from '../../forms';
import { isScrollingDisabled, manageDisableScrolling } from '../../ducks/UI.duck';
import { handleCardPayment, handleCardSetup, retrievePaymentIntent } from '../../ducks/stripe.duck';
import { savePaymentMethod } from '../../ducks/paymentMethods.duck';
import { TAB_REQUEST_DETAIL } from '../OutreachOffersPage/constants';
import { createStripeSetupIntent } from '../PaymentMethodsPage/PaymentMethodsPage.duck';
import {
  applyFeesAndDeductions,
  confirmPayment,
  getNearestHour,
  initiateBookingOrder,
  initiateOrder,
  NPOListings,
  sendMessage,
  setInitialValues,
  speculateOutreachPaymentTransaction,
  speculateTransaction,
  stripeCustomer,
} from '../CheckoutPage/CheckoutPage.duck';
import { generateCardIdentifier, storeData } from '../CheckoutPage/CheckoutPageHelpers';
import css from '../CheckoutPage/CheckoutPage.css';
import { withMessages } from '../../util/localization';
import utils from '@givsly/sharetribe-utils';

const { UUID } = sdkTypes;

const {
  calculateBreakdown,
  convertToMoneyBreakdown,
  convertBreakdownToLineItems,
} = utils.transaction;

const STORAGE_KEY = 'OutreachCheckoutPage';

// Stripe PaymentIntent statuses, where user actions are already completed
// https://stripe.com/docs/payments/payment-intents/status
const STRIPE_PI_USER_ACTIONS_DONE_STATUSES = ['processing', 'requires_capture', 'succeeded'];

const checkIsPaymentExpired = (existingTransaction) => {
  return txIsPaymentExpired(existingTransaction)
    ? true
    : txIsPaymentPending(existingTransaction)
    ? minutesBetween(existingTransaction.attributes.lastTransitionedAt, new Date()) >= 15
    : false;
};

// @todo Add 404 redirect when request/offer does not exist
// @todo Add verification that item has not already been paid
// @todo Ensure user is authenticated
// @todo Pre-select payment method
// @todo Add company name to booking panel
// @todo Update the request once it's paid

export class CheckoutPageComponent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      addCreditCardInProgress: false,
      pageData: {},
      hasSelectedNonprofit: false,
      dataLoaded: false,
      submitting: false,
      meetingDetailsEntered: false,
      meetingDetails: {},
      timeSlots: [],
      updatePaymentMethodInProgress: false,
    };
    this.stripe = null;

    this.handleAddCreditCard = this.handleAddCreditCard.bind(this);
    this.handleStripeInitialized = this.handleStripeInitialized.bind(this);
    this.loadInitialData = this.loadInitialData.bind(this);
    this.handlePaymentIntent = this.handlePaymentIntent.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleSelectPaymentMethod = this.handleSelectPaymentMethod.bind(this);
    this.redirectToRequestDetail = this.redirectToRequestDetail.bind(this);
  }

  componentDidMount() {
    if (window) {
      this.loadInitialData();
    }
  }

  /**
   * Load initial data for the page
   *
   * Since the data for the checkout is not passed in the URL (there
   * might be lots of options in the future), we must pass in the data
   * some other way. Currently the ListingPage sets the initial data
   * for the CheckoutPage's Redux store.
   *
   * For some cases (e.g. a refresh in the CheckoutPage), the Redux
   * store is empty. To handle that case, we store the received data
   * to window.sessionStorage and read it from there if no props from
   * the store exist.
   *
   * This function also sets of fetching the speculative transaction
   * based on this initial data.
   */
  async loadInitialData() {
    const {
      bookingData,
      bookingDates,
      dispatch,
      ensuredListing,
      bookingTransaction,
      paymentTransaction,
      fetchStripeCustomer,
      handleFetchCurrentUser,
      history,
      proposeMeeting,
      params,
    } = this.props;

    const defaultPaymentMethod = {};

    // Fetch outreach data
    const outreachRequest = await dispatch(getOutreachRequest(params.requestId));
    const outreachOffer = await dispatch(getOutreachOffer(outreachRequest.outreachOfferId));

    // Fetch current user with stripeCustomer entity
    fetchStripeCustomer()
      .then((stripeCustomer) => {
        // Set default card (if any)
        const ensuredStripeCustomer = ensureStripeCustomer(stripeCustomer);
        const { card, stripePaymentMethodId } = ensurePaymentMethodCard(
          ensuredStripeCustomer.defaultPaymentMethod
        ).attributes;

        // Set default payment method to credit card if any card has been added to the users account
        if (stripePaymentMethodId) {
          defaultPaymentMethod.selectedPaymentMethod = METHOD_CREDIT_CARD;
          defaultPaymentMethod.selectedPaymentIdentifier = generateCardIdentifier(card);
        }

        return handleFetchCurrentUser();
      })
      .then((currentUser) => {
        // Ensure that the user has a payment API secret
        const ensuredCurrentUser = ensureCurrentUser(currentUser);

        // Check if the paymentSecret has been set, if not then do so
        if (!ensuredCurrentUser.attributes.profile.privateData.paymentSecret) {
          return dispatch(
            updateProfile({
              privateData: {
                paymentSecret: createSecret(),
              },
            })
          ).then(() => handleFetchCurrentUser());
        }
      })
      .then(async () => {
        // Fetch nonprofit
        await dispatch(listings([outreachRequest.selectedNonProfitId]));

        // Get Givsly credit balance, requires current user
        return dispatch(getBalance());
      })
      .then(({ creditTotal } = {}) => {
        // The return is used to determine whether or not the user has credit. If the user has
        // credit the Givsly credit option will be used as a default payment method.
        if (creditTotal > 0) {
          defaultPaymentMethod.selectedPaymentMethod = METHOD_GIVSLY_CREDIT;
          defaultPaymentMethod.selectedPaymentIdentifier = null;
        }
        return Promise.resolve();
      })
      .then(async () => {
        // Browser's back navigation should not rewrite data in session store.
        // Action is 'POP' on both history.back() and page refresh cases.
        // Action is 'PUSH' when user has directed through a link
        // Action is 'REPLACE' when user has directed through login/signup process
        const hasNavigatedThroughLink = history.action === 'PUSH' || history.action === 'REPLACE';

        const hasDataInProps = !(
          !hasNavigatedThroughLink ||
          !bookingData ||
          !ensuredListing.id ||
          (!proposeMeeting && !bookingDates)
        );

        if (hasDataInProps) {
          // Store data only if data is passed through props and user has navigated through a link.
          storeData(
            bookingData,
            bookingDates,
            ensuredListing,
            bookingTransaction,
            paymentTransaction,
            STORAGE_KEY,
            defaultPaymentMethod.selectedPaymentMethod,
            defaultPaymentMethod.selectedPaymentIdentifier
          );
        }

        // NOTE: stored data can be empty if user has already successfully completed transaction.
        // const pageData = hasDataInProps
        //   ? {
        //       bookingData,
        //       bookingDates,
        //       listing: ensuredListing,
        //       bookingTransaction,
        //       paymentTransaction,
        //       ...defaultPaymentMethod,
        //     }
        //   : storedData(STORAGE_KEY);
        const { pageData } = this.state;
        const bookingStart = new Date();
        bookingStart.setMinutes(0);
        bookingStart.setSeconds(0);
        bookingStart.setMilliseconds(0);
        bookingStart.setHours(bookingStart.getHours() + 24);
        const bookingEnd = new Date(bookingStart.toISOString());
        bookingEnd.setMinutes(bookingEnd.getMinutes() + 15);
        pageData.selectedPaymentMethod = defaultPaymentMethod.selectedPaymentMethod;
        pageData.selectedPaymentIdentifier = defaultPaymentMethod.selectedPaymentIdentifier;
        pageData.defaultPaymentMethod = defaultPaymentMethod;
        pageData.bookingDates = {
          bookingStart,
          bookingEnd,
        };
        pageData.bookingData = {
          meetingMethod: 'chat',
          quantity: 1,
          deductFee: false,
        };
        pageData.listing = {
          id: {
            uuid: outreachRequest.selectedNonProfitId,
          },
          attributes: {
            publicData: {
              methodPriceChoices: {
                chat: `choice_${Math.round(outreachRequest.donationAmount / 100)}`,
              },
            },
          },
        };
        pageData.selectedNPO = outreachRequest.selectedNonProfitId;

        this.setState({ pageData: pageData || {}, dataLoaded: true });
        await dispatch(
          speculateOutreachPaymentTransaction({
            outreachOffer,
            outreachRequest,
            paymentMethod: defaultPaymentMethod.selectedPaymentMethod,
          })
        );
      })
      .catch((err) => {
        console.log('ERROR', err);
      });
  }

  /**
   * Handles payment transaction initiation
   *
   * This requires a base of the transaction to be setup manually. The line items need to be pre-
   * defined because at the start of the process we need to determine if any monetary payment is
   * required for this transaction.
   */
  async handlePaymentIntent(handlePaymentParams) {
    const {
      creditTotal,
      ensuredCurrentUser,
      onHandlePayment,
      outreachRequest,
      onRefreshOffers,
      onRefreshRequests,
      onUpdateOutreachRequest,
    } = this.props;
    const { speculatedOutreachPaymentTransaction } = handlePaymentParams;
    const meetingMethod = 'outreach';
    const deductFee = false;
    const quantity = 1;

    const ensuredStripeCustomer = ensureStripeCustomer(ensuredCurrentUser.stripeCustomer);
    const ensuredDefaultPaymentMethod = ensurePaymentMethodCard(
      ensuredStripeCustomer.defaultPaymentMethod
    );
    const { stripePaymentMethodId = null } = ensuredDefaultPaymentMethod.attributes;
    const paymentTransaction = utils.transaction.ensure(speculatedOutreachPaymentTransaction);
    const bookingStart = getNearestHour(new Date());
    const bookingEnd = new Date(+bookingStart + 1000 * 60 * 5);

    // Set transaction props required for the transaction to flow through the payment
    const unitPrice = outreachRequest.donationAmount;
    const operatorPercentage =
      ensuredCurrentUser &&
      ensuredCurrentUser.attributes &&
      ensuredCurrentUser.attributes.profile &&
      ensuredCurrentUser.attributes.profile.metadata &&
      ensuredCurrentUser.attributes.profile.metadata.operatorPercentage;
    const breakdown = calculateBreakdown(
      unitPrice,
      quantity,
      deductFee,
      this.state.pageData.selectedPaymentMethod === METHOD_GIVSLY_CREDIT ? creditTotal : 0,
      operatorPercentage
    );

    paymentTransaction.attributes = {
      ...paymentTransaction.attributes,
      bookingStart,
      bookingEnd,
      listingId: outreachRequest.selectedNonProfitId,
      lineItems: convertBreakdownToLineItems(convertToMoneyBreakdown(breakdown)),
      protectedData: {
        ...paymentTransaction.attributes.protectedData,
        deductFee,
        meetingMethod,
      },
      metadata: {
        breakdown,
      },
    };

    // Process the payment
    const response = await onHandlePayment(
      paymentTransaction,
      this.stripe,
      stripePaymentMethodId,
      ensuredCurrentUser
    );

    // Update the outreach request state and associated transaction ID
    onUpdateOutreachRequest({
      id: outreachRequest.id,
      paymentTransactionId: response.id.uuid,
      state: 'CONFIRMED',
    });

    await onRefreshOffers();
    await onRefreshRequests();

    // Head back to the outreach overview
    this.redirectToRequestDetail();
  }

  handleSubmit(values) {
    if (this.state.submitting) {
      return;
    }
    this.setState({ submitting: true });

    const { speculatedOutreachPaymentTransaction, ensuredCurrentUser, paymentIntent } = this.props;

    const { card, paymentMethod, formValues } = values;
    const {
      name,
      addressLine1,
      addressLine2,
      postal,
      city,
      state,
      country,
      saveAfterOnetimePayment,
    } = formValues;

    // Billing address is recommended.
    // However, let's not assume that <StripePaymentAddress> data is among formValues.
    // Read more about this from Stripe's docs
    // https://stripe.com/docs/stripe-js/reference#stripe-handle-card-payment-no-element
    const addressMaybe =
      addressLine1 && postal
        ? {
            address: {
              city: city,
              country: country,
              line1: addressLine1,
              line2: addressLine2,
              postal_code: postal,
              state: state,
            },
          }
        : {};
    const billingDetails = {
      name,
      email: ensuredCurrentUser.attributes.email,
      ...addressMaybe,
    };

    const requestPaymentParams = {
      pageData: this.state.pageData,
      speculatedOutreachPaymentTransaction,
      stripe: this.stripe,
      card,
      billingDetails,
      paymentIntent,
      selectedPaymentMethod: paymentMethod,
      saveAfterOnetimePayment: !!saveAfterOnetimePayment,
    };

    this.handlePaymentIntent(requestPaymentParams).catch((err) => {
      console.log('handlepayment error', err);
      this.setState({ submitting: false });
    });
  }

  handleStripeInitialized(stripe) {
    this.stripe = stripe;

    const { paymentIntent, onRetrievePaymentIntent } = this.props;
    const tx = this.state.pageData ? this.state.pageData.transaction : null;

    // We need to get up to date PI, if booking is created but payment is not expired.
    const shouldFetchPaymentIntent =
      this.stripe &&
      !paymentIntent &&
      tx &&
      tx.id &&
      tx.booking &&
      tx.booking.id &&
      txIsPaymentPending(tx) &&
      !checkIsPaymentExpired(tx);

    if (shouldFetchPaymentIntent) {
      const { stripePaymentIntentClientSecret } =
        tx.attributes.protectedData && tx.attributes.protectedData.stripePaymentIntents
          ? tx.attributes.protectedData.stripePaymentIntents.default
          : {};

      // Fetch up to date PaymentIntent from Stripe
      onRetrievePaymentIntent({ stripe, stripePaymentIntentClientSecret });
    }
  }

  handleSelectPaymentMethod(selectedMethod, selectedIdentifier) {
    const { fetchSpeculatedPaymentTransaction, outreachOffer, outreachRequest } = this.props;

    this.setState(
      {
        pageData: {
          ...this.state.pageData,
          selectedPaymentMethod: selectedMethod,
          selectedPaymentIdentifier: selectedIdentifier,
        },
        updatePaymentMethodInProgress: true,
      },
      async () => {
        await fetchSpeculatedPaymentTransaction(outreachOffer, outreachRequest, selectedMethod);
        this.setState({
          updatePaymentMethodInProgress: false,
        });
      }
    );
  }

  handleAddCreditCard = (params) => {
    this.setState({ addCreditCardInProgress: true });

    const {
      ensuredCurrentUser,
      fetchStripeCustomer,
      onCreateSetupIntent,
      onHandleCardSetup,
      onSavePaymentMethod,
    } = this.props;
    const stripeCustomer = ensuredCurrentUser.stripeCustomer;
    const { stripe, card, formValues } = params;

    return onCreateSetupIntent()
      .then((setupIntent) => {
        const stripeParams = {
          stripe,
          card,
          setupIntentClientSecret: getClientSecret(setupIntent),
          paymentParams: getPaymentParams(ensuredCurrentUser, formValues),
        };

        return onHandleCardSetup(stripeParams);
      })
      .then((result) => {
        const newPaymentMethod = result.setupIntent.payment_method;
        // Note: stripe.handleCardSetup might return an error inside successful call (200), but those are rejected in thunk functions.

        return onSavePaymentMethod(stripeCustomer, newPaymentMethod);
      })
      .then(() => {
        // Update currentUser entity and its sub entities: stripeCustomer and defaultPaymentMethod
        return fetchStripeCustomer();
      })
      .then((fetchedStripeCustomer) => {
        // Un-flag the save in progress once the new cards have been fetched (this way the form
        // won't flicker in and out of existence).
        const ensuredFetchedStripeCustomer = ensureStripeCustomer(fetchedStripeCustomer);
        const ensuredPaymentMethodCard = ensurePaymentMethodCard(
          ensuredFetchedStripeCustomer.defaultPaymentMethod
        );
        const { card } = ensuredPaymentMethodCard.attributes;

        const selectedPaymentMethod = METHOD_CREDIT_CARD;
        const selectedPaymentIdentifier = generateCardIdentifier(card);

        this.setState({
          addCreditCardInProgress: false,
          pageData: {
            ...this.state.pageData,
            selectedPaymentMethod,
            selectedPaymentIdentifier,
            defaultPaymentMethod: {
              selectedPaymentMethod,
              selectedPaymentIdentifier,
            },
          },
        });
      })
      .catch((error) => {
        console.error(error);
        this.setState({ addCreditCardInProgress: false });
      });
  };

  getTransactionErrorMessageProps(error) {
    switch (typeof error === 'object' ? error.code : null) {
      case ERROR_CODE_MISSING_STRIPE_ACCOUNT:
        return { id: 'providerStripeAccountMissingError' };
      case ERROR_CODE_TRANSACTION_BOOKING_TIME_NOT_AVAILABLE:
        return { id: 'bookingTimeNotAvailableMessage' };
      case ERROR_CODE_CHARGE_ZERO_PAYIN:
        return { id: 'initiateOrderAmountTooLow' };
      default:
        return { id: error && error.code ? 'speculateFailedMessage' : null };
    }
  }

  get unitPrice() {
    const { outreachRequest } = this.props;
    return outreachRequest.donationAmount;
  }

  get bookingBreakdown() {
    const {
      creditTotal,
      ensuredListing,
      getListing,
      outreachRequest,
      speculatedOutreachPaymentTransaction,
      proposeMeeting,
      timezone,
    } = this.props;
    const ensuredTransaction = ensureTransaction(speculatedOutreachPaymentTransaction);
    const { bookingData = {}, bookingDates = {}, selectedPaymentMethod } = this.state.pageData;
    const { deductFee } = bookingData;
    const { methodPriceChoices } = ensuredListing.attributes.publicData;
    const ensuredBooking = ensureBooking(ensuredTransaction.booking);

    // Grab the nonprofit from the state
    const nonprofit = getListing(new UUID(outreachRequest.selectedNonProfitId));

    // The booking transaction is mocked in order to display the nonprofit and donation amount
    const mockBookingTransaction = { ...ensuredTransaction };
    mockBookingTransaction.attributes.protectedData.donationValue =
      ensuredTransaction.attributes.protectedData.donationAmount;

    if (ensuredTransaction.id && ensuredBooking.id) {
      return (
        <div className={css.bookingBreakdownWrapper}>
          <BookingBreakdown
            userRole="customer"
            unitType={config.bookingUnitType}
            bookingTransaction={mockBookingTransaction}
            transaction={ensuredTransaction}
            booking={ensuredBooking}
            nonprofit={nonprofit}
            dateType={DATE_TYPE_DATETIME}
            isProposal={proposeMeeting}
            timezone={timezone}
            deductFee={deductFee}
            onDeductFeeChange={this.handleChangeDeductFee}
          />
        </div>
      );
    } else if (this.state.pageData.bookingData) {
      const bookingData = {
        ...this.state.pageData.bookingData,
        startDate: bookingDates && moment.tz(bookingDates.bookingStart, timezone),
        endDate: bookingDates && moment.tz(bookingDates.bookingEnd, timezone),
        timezone,
        methodPriceChoices,
      };

      return (
        <div className={css.bookingBreakdownWrapper}>
          <EstimatedBreakdown
            applyCredit={selectedPaymentMethod === METHOD_GIVSLY_CREDIT}
            bookingData={bookingData}
            className={css.receipt}
            creditTotal={creditTotal}
            deductFee={deductFee}
            isProposal={proposeMeeting}
            meetingDuration={(bookingData.quantity | 0) * 15}
            onDeductFeeChange={this.handleChangeDeductFee}
            selectedPaymentMethod={selectedPaymentMethod}
            showBookingDetails={true}
            suggestedTimes={bookingData.suggestedTimes}
            timezone={timezone}
          />
        </div>
      );
    }
    return null;
  }

  get errorMessage() {
    const { getMessage } = this.props;
    const errors = [];

    const initiationError = this.initiationError;
    if (initiationError) errors.push(initiationError);
    const paymentError = this.paymentError;
    if (paymentError) errors.push(paymentError);
    const bookingError = this.bookingError;
    if (bookingError) errors.push(bookingError);

    if (errors.length > 0) {
      const { id, values = null } = errors[0];
      return id ? <p className={css.orderError}>{getMessage(id, values)}</p> : null;
    }
    return null;
  }

  get initiationError() {
    const { initiateOrderError } = this.props;
    const initiationError = errorAPIErrors(initiateOrderError).shift();
    const stripeErrors = transactionInitiateOrderStripeErrors(initiateOrderError);

    switch (typeof initiationError === 'object' ? initiationError.code : null) {
      case ERROR_CODE_TRANSACTION_LISTING_NOT_FOUND:
        return { id: 'listingNotFoundError' };
      case ERROR_CODE_TRANSACTION_BOOKING_TIME_NOT_AVAILABLE:
        return { id: 'bookingTimeNotAvailableMessage' };
      case ERROR_CODE_PAYMENT_FAILED:
        if (isTransactionInitiateAmountTooLowError(initiationError)) {
          return { id: 'initiateOrderAmountTooLow' };
        } else if (isTransactionChargeDisabledError(initiationError)) {
          return { id: 'chargeDisabledMessage' };
        } else if (stripeErrors && stripeErrors.length) {
          return {
            id: 'initiateOrderStripeError',
            values: {
              stripeErrors: stripeErrors.join(', '),
            },
          };
        } else {
          return { id: 'initiateOrderError', values: { listingLink: this.listingLink } };
        }
      default:
        return null;
    }
  }

  get listingLink() {
    const { ensuredListing, getMessage } = this.props;
    return (
      <NamedLink
        name="ListingPage"
        params={{ id: ensuredListing.id.uuid, slug: createSlug(ensuredListing.attributes.title) }}
      >
        {getMessage('errorlistingLinkText')}
      </NamedLink>
    );
  }

  get paymentError() {
    const { speculatePaymentTransactionError } = this.props;
    const paymentError = errorAPIErrors(speculatePaymentTransactionError).shift();
    return this.getTransactionErrorMessageProps(paymentError);
  }

  get pageProps() {
    const { ensuredListing, getMessage, scrollingDisabled } = this.props;
    const { title } = ensuredListing.attributes;
    return {
      scrollingDisabled,
      title: title ? title : getMessage('title', { listingTitle: title }),
    };
  }

  get topBar() {
    const label = this.props.getMessage('goToLandingPage');
    return (
      <div className={css.topbar}>
        <NamedLink className={css.home} name="LandingPage">
          <Logo className={css.logoMobile} title={label} format="mobile" />
          <Logo className={css.logoDesktop} alt={label} format="desktop" />
        </NamedLink>
      </div>
    );
  }

  get dueAmount() {
    const {
      creditTotal,
      deductFee,
      ensuredListing,
      speculatedOutreachPaymentTransaction,
      ensuredCurrentUser,
    } = this.props;

    if (speculatedOutreachPaymentTransaction.attributes.lineItems.length) {
      // If the transaction has already been speculated then the sum can retrieved from there.
      const { payinTotal } = speculatedOutreachPaymentTransaction.attributes;
      return typeof payinTotal === 'object' ? payinTotal.amount : 0;
    } else {
      // If there is no speculated transaction it must be estimated. This will be the same value as
      // is depicted inside the booking breakdown.
      const { meetingMethod, quantity } = this.state.pageData.bookingData;
      const { bookingStart: startDate, bookingEnd: endDate } = this.state.pageData.bookingDates;
      const { methodPriceChoices } = ensuredListing.attributes.publicData;

      const operatorPercentage =
        ensuredCurrentUser &&
        ensuredCurrentUser.attributes &&
        ensuredCurrentUser.attributes.profile &&
        ensuredCurrentUser.attributes.profile.metadata &&
        ensuredCurrentUser.attributes.profile.metadata.operatorPercentage;

      const estimatedTransaction = getEstimatedTransaction(
        moment(startDate),
        moment(endDate),
        meetingMethod,
        methodPriceChoices,
        quantity,
        deductFee,
        this.state.pageData.selectedPaymentMethod === METHOD_GIVSLY_CREDIT ? creditTotal : 0,
        operatorPercentage
      );
      return estimatedTransaction.attributes.payinTotal.amount;
    }
  }

  redirectToRequestDetail = () => {
    const { history, params } = this.props;
    history.push(
      createResourceLocatorString('OutreachOffersDetailPage', routeConfiguration(), {
        tab: TAB_REQUEST_DETAIL,
        id: params.requestId,
      })
    );
  };

  render() {
    const {
      confirmPaymentError,
      creditTotal,
      ensuredCurrentUser,
      ensuredListing,
      event,
      eventNonprofits,
      handleCardPaymentError,
      initiateOrderError,
      stripeCustomerFetched,
      onAddCredit,
      onGetBalance,
      outreachRequest,
      speculateBookingTransactionInProgress,
      speculatedOutreachPaymentTransaction,
      onManageDisableScrolling,
      getMessage,
      paymentIntent,
      npoListingChoices,
    } = this.props;

    const isLoading = !this.state.dataLoaded || speculateBookingTransactionInProgress;
    const errorMessage = this.errorMessage;

    const { ensuredTransaction } = this.state.pageData;
    const ensuredAuthor = ensureUser(ensuredListing.author);

    if (isLoading) {
      return <Page {...this.pageProps}>{this.topBar}</Page>;
    }

    const isPaymentExpired = checkIsPaymentExpired(ensuredTransaction);

    // If paymentIntent status is not waiting user action,
    // handleCardPayment has been called previously.
    const hasPaymentIntentUserActionsDone =
      paymentIntent && STRIPE_PI_USER_ACTIONS_DONE_STATUSES.includes(paymentIntent.status);

    let npoListingChoicesAll = [...npoListingChoices];
    if (event && eventNonprofits && eventNonprofits.length > 0) {
      const eventNonprofitsUuids = eventNonprofits.map((npo) => {
        const ensuredNonprofit = ensureListing(npo);
        return ensuredNonprofit.id ? ensuredNonprofit.id.uuid : null;
      });
      remove(npoListingChoicesAll, (listing) => eventNonprofitsUuids.includes(listing.id.uuid)); // Remove possible duplicate listings
    }

    // Credit card
    const ensuredStripeCustomer = ensureStripeCustomer(ensuredCurrentUser.stripeCustomer);
    const ensuredPaymentMethodCard = ensuredStripeCustomer.defaultPaymentMethod;
    const creditCards =
      ensuredCurrentUser.id &&
      ensuredStripeCustomer.attributes.stripeCustomerId &&
      ensuredPaymentMethodCard &&
      ensuredPaymentMethodCard.id
        ? [ensuredPaymentMethodCard.attributes.card]
        : [];
    const ensuredDefaultPaymentMethod = ensurePaymentMethodCard(
      ensuredStripeCustomer.defaultPaymentMethod
    );

    const hasDefaultPaymentMethod = !!(
      stripeCustomerFetched &&
      ensuredStripeCustomer.attributes.stripeCustomerId &&
      ensuredDefaultPaymentMethod.id
    );

    const { deductFee = false, quantity = 1 } = this.state.pageData.bookingData || {};
    const operatorPercentage =
      ensuredCurrentUser &&
      ensuredCurrentUser.attributes &&
      ensuredCurrentUser.attributes.profile &&
      ensuredCurrentUser.attributes.profile.metadata &&
      ensuredCurrentUser.attributes.profile.metadata.operatorPercentage;

    const breakdown = calculateBreakdown(
      this.unitPrice,
      quantity,
      deductFee,
      creditTotal,
      operatorPercentage
    );

    const isAlreadyPaid = outreachRequest.paymentTransactionId;

    return (
      <Page {...this.pageProps}>
        {this.topBar}
        <div className={css.contentContainer}>
          <MobileAvatarHero user={ensuredAuthor} />
          <div className={css.bookListingContainer}>
            <div className={css.heading}>
              <h1 className={css.title}>{getMessage('title')}</h1>
              <div className={css.author}>{getMessage('description')}</div>
            </div>
            <section className={css.paymentContainer}>
              {this.errorMessage}
              {!isAlreadyPaid &&
              speculatedOutreachPaymentTransaction &&
              (speculatedOutreachPaymentTransaction.attributes.payinTotal ||
                this.state.updatePaymentMethodInProgress) ? (
                <>
                  <PaymentForm
                    addCreditCardInProgress={this.state.addCreditCardInProgress}
                    breakdown={breakdown}
                    className={css.paymentForm}
                    creditCards={creditCards}
                    creditTotal={creditTotal}
                    dueAmount={this.dueAmount}
                    ensuredCurrentUser={ensuredCurrentUser}
                    hasSelectedNonprofit={true}
                    inProgress={this.state.submitting}
                    formId="CheckoutPagePaymentForm"
                    paymentInfo={getMessage('paymentInfo', {
                      faqLink: <NamedLink name={'FaqPage'}>FAQ</NamedLink>,
                    }).toString()}
                    initialValues={{}}
                    initiateOrderError={initiateOrderError}
                    isProposal={false}
                    isOutreachOffer={true}
                    handleCardPaymentError={handleCardPaymentError}
                    confirmPaymentError={confirmPaymentError}
                    hasHandledCardPayment={hasPaymentIntentUserActionsDone}
                    loadingData={!stripeCustomerFetched}
                    defaultPaymentMethod={
                      hasDefaultPaymentMethod ? ensuredDefaultPaymentMethod : null
                    }
                    paymentIntent={paymentIntent}
                    priceBreakdown={
                      <div className={css.priceBreakdownContainer}>
                        <h4 className={css.bookingSummary}>{getMessage('bookingSummary')}</h4>
                        {errorMessage}
                        {this.bookingBreakdown}
                      </div>
                    }
                    onStripeInitialized={this.handleStripeInitialized}
                    onAddCredit={onAddCredit}
                    onAddCreditCard={this.handleAddCreditCard}
                    onGetBalance={onGetBalance}
                    onSelectPaymentMethod={this.handleSelectPaymentMethod}
                    onSubmit={this.handleSubmit}
                    onCancel={this.redirectToRequestDetail}
                    onManageDisableScrolling={onManageDisableScrolling}
                    selectedPaymentIdentifier={this.state.pageData.selectedPaymentIdentifier}
                    selectedPaymentMethod={this.state.pageData.selectedPaymentMethod}
                  />
                </>
              ) : null}
              {isAlreadyPaid ? <div>This request has already been confirmed</div> : null}
              {isPaymentExpired ? (
                <p className={css.orderError}>
                  {getMessage('paymentExpiredMessage', { listingLink: this.listingLink })}
                </p>
              ) : null}
            </section>
            {/*<NeedHelp />*/}
          </div>
          <div className={css.detailsContainerDesktop}>
            <div className={css.avatarWrapper}>
              <AvatarHuge user={ensuredAuthor} />
            </div>
            <div className={css.detailsHeadings}>
              <h2 className={css.detailsTitle}>
                {outreachRequest.bookerFirstName} {outreachRequest.bookerLastName}
              </h2>
              <div className={css.detailsSubtitle}>{outreachRequest.bookerEmail}</div>
            </div>
            {errorMessage}
            {this.bookingBreakdown}
          </div>
        </div>
      </Page>
    );
  }
}

CheckoutPageComponent.defaultProps = {
  blockedSlots: [],
  initiateOrderError: null,
  confirmPaymentError: null,
  proposeMeeting: false,
  bookingData: {},
  bookingDates: null,
  speculateBookingTransactionError: null,
  speculatedBookingTransaction: null,
  speculatePaymentTransactionError: null,
  speculatedPaymentTransaction: null,
  transaction: null,
  paymentIntent: null,
  npoListingChoices: [],
  eventNonprofits: [],
};

CheckoutPageComponent.propTypes = {
  blockedSlots: array,
  scrollingDisabled: bool.isRequired,
  ensuredListing: object,
  bookingData: object,
  bookingDates: shape({
    bookingStart: instanceOf(Date).isRequired,
    bookingEnd: instanceOf(Date).isRequired,
  }),
  event: propTypes.event,
  eventNonprofits: arrayOf(propTypes.listing),
  fetchStripeCustomer: func.isRequired,
  fetchNPOListings: func.isRequired,
  getMessage: func.isRequired,
  stripeCustomerFetched: bool.isRequired,
  fetchSpeculatedTransaction: func.isRequired,
  speculateBookingTransactionInProgress: bool.isRequired,
  speculateBookingTransactionError: propTypes.error,
  speculatedBookingTransaction: propTypes.transaction,
  speculatePaymentTransactionInProgress: bool.isRequired,
  speculatePaymentTransactionError: propTypes.error,
  speculatedPaymentTransaction: propTypes.transaction,
  ensuredTransaction: object,
  ensuredCurrentUser: object,
  params: shape({
    id: string,
    slug: string,
  }).isRequired,
  onAddCredit: func.isRequired,
  proposeMeeting: bool,
  onConfirmPayment: func.isRequired,
  onInitiateOrder: func.isRequired,
  onHandleCardPayment: func.isRequired,
  onManageDisableScrolling: func.isRequired,
  onRetrievePaymentIntent: func.isRequired,
  onSavePaymentMethod: func.isRequired,
  onSendMessage: func.isRequired,
  onTrackEvent: func.isRequired,
  onUpdateProfile: func.isRequired,
  onUpdateOutreachRequest: func.isRequired,
  outreachOffer: object,
  outreachRequest: object,
  initiateOrderError: propTypes.error,
  confirmPaymentError: propTypes.error,
  handleCardPaymentError: oneOfType([propTypes.error, object]),
  paymentIntent: object,
  npoListingChoices: array.isRequired,
  dispatch: func.isRequired,
  history: shape({
    push: func.isRequired,
  }).isRequired,
  timezone: string.isRequired,
};

const mapStateToProps = (state) => {
  const {
    listing,
    blockedSlots,
    bookingData,
    bookingDates,
    stripeCustomerFetched,
    speculateBookingTransactionInProgress,
    speculateBookingTransactionError,
    speculatedBookingTransaction,
    speculatePaymentTransactionInProgress,
    speculatePaymentTransactionError,
    speculatedPaymentTransaction,
    speculatedOutreachPaymentTransaction,
    transaction,
    initiateOrderError,
    confirmPaymentError,
    npoListingChoices,
  } = state.CheckoutPage;
  const { currentUser, timezone } = state.user;
  const { handleCardPaymentError, paymentIntent, retrievePaymentIntentError } = state.stripe;
  const { getBalanceError, getBalanceInProgress, creditTotal } = state.credit;

  const getListing = (id) => {
    const ref = { id, type: 'listing' };
    const listings = getMarketplaceEntities(state, [ref]);

    return listings.length === 1 ? listings[0] : null;
  };

  // Outreach data
  const { outreachOffer, outreachRequest } = state.outreach;

  return {
    blockedSlots,
    bookingData,
    bookingDates,
    confirmPaymentError,
    creditTotal,
    ensuredCurrentUser: ensureCurrentUser(currentUser),
    ensuredListing: ensureListing(listing),
    ensuredTransaction: ensureTransaction(transaction),
    getBalanceError,
    getBalanceInProgress,
    getListing,
    handleCardPaymentError,
    initiateOrderError,
    npoListingChoices,
    outreachOffer,
    outreachRequest,
    paymentIntent,
    retrievePaymentIntentError,
    scrollingDisabled: isScrollingDisabled(state),
    speculateBookingTransactionInProgress,
    speculateBookingTransactionError,
    speculatedBookingTransaction,
    speculatePaymentTransactionInProgress,
    speculatePaymentTransactionError,
    speculatedPaymentTransaction,
    speculatedOutreachPaymentTransaction,
    stripeCustomerFetched,
    timezone,
  };
};

const mapDispatchToProps = (dispatch) => ({
  dispatch,
  handleFetchCurrentUser: () => dispatch(fetchCurrentUser()),
  fetchSpeculatedTransaction: (type, processAlias, params) =>
    dispatch(speculateTransaction(type, processAlias, params)),
  fetchSpeculatedPaymentTransaction: (outreachOffer, outreachRequest, paymentMethod) => {
    return dispatch(
      speculateOutreachPaymentTransaction({ outreachOffer, outreachRequest, paymentMethod })
    );
  },
  fetchStripeCustomer: () => dispatch(stripeCustomer()),
  fetchNPOListings: (listingIds) => dispatch(NPOListings(listingIds)),
  onAddCredit: (code) => dispatch(addCredit(code)),
  onGetBalance: () => dispatch(getBalance()),
  onCreateSetupIntent: (params) => dispatch(createStripeSetupIntent(params)),
  onHandleCardSetup: (params) => dispatch(handleCardSetup(params)),
  onHandlePayment: (ensuredTransaction, stripe, stripePaymentMethodId, ensuredCurrentUser) => {
    return dispatch(
      handlePayment(
        ensuredTransaction,
        stripe,
        stripePaymentMethodId,
        config.outreachPaymentBookingProcessAlias,
        ensuredCurrentUser
      )
    );
  },
  onInitiateOrder: (params, transactionId) => dispatch(initiateOrder(params, transactionId)),
  onInitiateBookingOrder: (params, transactionId) =>
    dispatch(initiateBookingOrder(params, transactionId)),
  onManageDisableScrolling: (componentId, disableScrolling) =>
    dispatch(manageDisableScrolling(componentId, disableScrolling)),
  onRetrievePaymentIntent: (params) => dispatch(retrievePaymentIntent(params)),
  onHandleCardPayment: (params) => dispatch(handleCardPayment(params)),
  onConfirmPayment: (params) => dispatch(confirmPayment(params)),
  onApplyFeesAndDeductions: (ensuredCurrentUser, transactionId, deductFee, applyCredit) =>
    dispatch(applyFeesAndDeductions(ensuredCurrentUser, transactionId, deductFee, applyCredit)),
  onSendMessage: (params) => dispatch(sendMessage(params)),
  onSavePaymentMethod: (stripeCustomer, stripePaymentMethodId) =>
    dispatch(savePaymentMethod(stripeCustomer, stripePaymentMethodId)),
  onTrackEvent: (params) => dispatch(trackEventAction(params)),
  onUpdateProfile: (data) => dispatch(updateProfile(data)),
  onUpdateOutreachRequest: (data) => dispatch(updateOutreachRequest(data)),
  onRefreshOffers: () => dispatch(queryOutreachOffers()),
  onRefreshRequests: () => dispatch(queryOutreachRequests()),
});

const withLocalizedMessages = (component) => {
  return withMessages(component, 'OutreachCheckoutPage');
};

const OutreachCheckoutPage = compose(
  withRouter,
  withLocalizedMessages,
  connect(mapStateToProps, mapDispatchToProps)
)(CheckoutPageComponent);

OutreachCheckoutPage.setInitialValues = (initialValues) => setInitialValues(initialValues);
OutreachCheckoutPage.displayName = 'OutreachCheckoutPage';

export default OutreachCheckoutPage;
