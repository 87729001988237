// Action types
import * as credit from '../util/credit';
import { ensureCurrentUser } from '../util/data';
import { storableError } from '../util/errors';

const ADD_CREDIT_REQUEST = 'app/user/ADD_CREDIT_REQUEST';
const ADD_CREDIT_SUCCESS = 'app/user/ADD_CREDIT_SUCCESS';
const ADD_CREDIT_FAILURE = 'app/user/ADD_CREDIT_FAILURE';
const GET_BALANCE_REQUEST = 'app/user/GET_BALANCE_REQUEST';
const GET_BALANCE_SUCCESS = 'app/user/GET_BALANCE_SUCCESS';
const GET_BALANCE_FAILURE = 'app/user/GET_BALANCE_FAILURE';

// Initial state
const initialState = {
  addCreditInProgress: false,
  addCreditError: null,
  creditCodes: [],
  creditTotal: 0,
  getBalanceInProgress: false,
  getBalanceError: null,
};

// Reducer
export default function reducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case ADD_CREDIT_REQUEST:
      return {
        ...state,
        addCreditError: null,
        addCreditInProgress: true,
      };
    case ADD_CREDIT_SUCCESS:
      return {
        ...state,
        addCreditInProgress: false,
      };
    case ADD_CREDIT_FAILURE:
      return {
        ...state,
        addCreditError: payload,
        addCreditInProgress: false,
      };
    case GET_BALANCE_REQUEST:
      return {
        ...state,
        getBalanceError: null,
        getBalanceInProgress: true,
      };
    case GET_BALANCE_SUCCESS:
      return {
        ...state,
        creditCodes: payload.breakdown,
        creditTotal: parseFloat(payload.total),
        getBalanceInProgress: false,
      };
    case GET_BALANCE_FAILURE:
      return {
        ...state,
        creditCodes: [],
        creditTotal: 0,
        getBalanceError: storableError(payload),
        getBalanceInProgress: false,
      };
    default:
      return state;
  }
}

// Action creators
const addCreditRequest = () => ({
  type: ADD_CREDIT_REQUEST,
});
const addCreditSuccess = () => ({
  type: ADD_CREDIT_SUCCESS,
});
const addCreditFailure = (err) => ({
  type: ADD_CREDIT_FAILURE,
  payload: err,
});
const getBalanceRequest = () => ({
  type: GET_BALANCE_REQUEST,
});
const getBalanceSuccess = (data) => ({
  type: GET_BALANCE_SUCCESS,
  payload: data,
});
const getBalanceFailure = (err) => ({
  type: GET_BALANCE_FAILURE,
  payload: err,
});

// Thunks
/**
 * Get balance from the credit API
 *
 * @returns {function(*, *, *): (undefined|Promise<void>)}
 */
export const getBalance = () => (dispatch, getState) => {
  dispatch(getBalanceRequest());

  const ensuredCurrentUser = ensureCurrentUser(getState().user.currentUser);
  if (!ensuredCurrentUser.id.uuid) {
    dispatch(getBalanceFailure('User not authenticated'));
    return Promise.reject();
  }

  return credit
    .getBalance(ensuredCurrentUser)
    .then((response) => {
      return dispatch(getBalanceSuccess(response.data));
    })
    .then(() => {
      const { creditCodes, creditTotal } = getState().credit;
      return { creditCodes, creditTotal };
    })
    .catch((err) => {
      return dispatch(getBalanceFailure(err));
    });
};

/**
 * Add balance through the credit API
 *
 * @param code
 * @returns {function(*, *): (undefined|Promise<void>)}
 */
export const addCredit = (code) => (dispatch, getState) => {
  dispatch(addCreditRequest());

  const ensuredCurrentUser = ensureCurrentUser(getState().user.currentUser);
  if (!ensuredCurrentUser.id.uuid) {
    dispatch(addCreditFailure('User not authenticated'));
    return;
  }

  return credit
    .addBalance(ensuredCurrentUser, code)
    .then((response) => {
      dispatch(addCreditSuccess());
      return {
        success: true,
      };
    })
    .catch((err) => {
      dispatch(addCreditFailure(err.response.data.reason));
      return {
        success: false,
        reason: err.response.data.reason,
      };
    });
};
