import React from 'react';
import { injectIntl } from 'react-intl';
import { compose } from 'redux';
import css from '../LandingPage/LandingPage.css';
import {
  FeaturedLocations,
  Footer,
  LayoutSingleColumn,
  LayoutWrapperFooter,
  LayoutWrapperMain,
  LayoutWrapperTopbar,
  FeaturedNonprofits,
  Page,
} from '../../components';
import { TopbarContainer } from '../index';
import { bool, func, shape } from 'prop-types';
import { Hero } from './Hero';
import atlantaImage from '../../assets/locations/card-img-atlanta@2x.png';
import chicagoImage from '../../assets/locations/card-img-chicago@2x.png';
import losAngelesImage from '../../assets/locations/card-img-la@2x.png';
import nationalImage from '../../assets/locations/card-img-national@2x.png';
import newYorkImage from '../../assets/locations/card-img-new-york@2x.png';
import sanFranciscoImage from '../../assets/locations/card-img-sf@2x.png';
import { withRouter } from 'react-router-dom';
import config from '../../config';
import { queryNonProfits } from '../../ducks/NonprofitListing.duck';
import { getMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import { isScrollingDisabled } from '../../ducks/UI.duck';
import { connect } from 'react-redux';

class NonprofitsLandingPageComponent extends React.Component {
  constructor(props) {
    super(props);
    this.getFeaturedLocations = this.getFeaturedLocations.bind(this);
    this.getCategoryResults = this.getCategoryResults.bind(this);
  }

  getFeaturedLocations = () => {
    return [
      {
        key: 'usa',
        title: 'National',
        subTitle: 'USA',
        image: nationalImage,
      },
      {
        key: 'newyork',
        title: 'New York',
        subTitle: 'New York',
        image: newYorkImage,
      },
      {
        key: 'losangeles',
        title: 'Los Angeles',
        subTitle: 'California',
        image: losAngelesImage,
      },
      {
        key: 'sanfrancisco',
        title: 'San Francisco',
        subTitle: 'California',
        image: sanFranciscoImage,
      },
      {
        key: 'atlanta',
        title: 'Atlanta',
        subTitle: 'Georgia',
        image: atlantaImage,
      },
      {
        key: 'chicago',
        title: 'Chicago',
        subTitle: 'Illinois',
        image: chicagoImage,
      },
    ];
  };

  getCategoryResults = (category) => {
    const { categorizedNonprofitIds, getListing } = this.props;
    const nonprofits = [];

    if (categorizedNonprofitIds[category]) {
      categorizedNonprofitIds[category].map((id) => {
        nonprofits.push(getListing(id));
        return id;
      });
    }

    return nonprofits;
  };

  render() {
    const { history, scrollingDisabled, currentUser } = this.props;
    return (
      <Page
        className={css.root}
        scrollingDisabled={scrollingDisabled}
        contentType="website"
        title="Nonprofits"
      >
        <LayoutSingleColumn>
          <LayoutWrapperTopbar>
            <TopbarContainer />
          </LayoutWrapperTopbar>
          <LayoutWrapperMain className={css.layoutMainWrapper}>
            <Hero currentUser={currentUser} />
            <FeaturedLocations history={history} locations={this.getFeaturedLocations()} />
            {config.custom.categories.map((category) => {
              const nonprofits = this.getCategoryResults(category.label);

              return nonprofits.length ? (
                <FeaturedNonprofits
                  history={history}
                  isFeaturedCategory={true}
                  key={category.label}
                  name={category.label}
                  nonprofits={nonprofits}
                  title={category.label}
                />
              ) : null;
            })}
          </LayoutWrapperMain>
          <LayoutWrapperFooter>
            <Footer />
          </LayoutWrapperFooter>
        </LayoutSingleColumn>
      </Page>
    );
  }
}

NonprofitsLandingPageComponent.propTypes = {
  history: shape({
    push: func.isRequired,
  }).isRequired,
  scrollingDisabled: bool,
};

NonprofitsLandingPageComponent.defaultProps = {
  schemaDescription: '',
  scrollingDisabled: false,
};

const mapStateToProps = (state) => {
  const getListing = (id) => {
    const ref = { id, type: 'listing' };
    const listings = getMarketplaceEntities(state, [ref]);
    return listings.length === 1 ? listings[0] : null;
  };

  const { currentUser } = state.user;
  const { groupedResultIds, searchInProgress } = state.NonprofitListing;

  return {
    currentUser,
    getListing,
    categorizedNonprofitIds: groupedResultIds,
    nonprofitLoadingInProgress: searchInProgress,
    scrollingDisabled: isScrollingDisabled(state),
  };
};

NonprofitsLandingPageComponent.loadData = () => (dispatch, getState) => {
  const promises = [];

  config.custom.categories.forEach((category) => {
    promises.push(
      dispatch(
        queryNonProfits(
          {
            pub_organizationCategory: category.label,
          },
          3,
          category.label
        )
      )
    );
    return category;
  });

  return Promise.all(promises);
};

const NonprofitsLandingPage = compose(
  injectIntl,
  withRouter,
  connect(mapStateToProps)
)(NonprofitsLandingPageComponent);
export default NonprofitsLandingPage;
