import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { fetchCurrentUser } from '../../ducks/user.duck';
import {
  CalendarIntegrationContent,
  LayoutSideNavigation,
  LayoutWrapperMain,
  LayoutWrapperAccountSettingsSideNav,
  LayoutWrapperTopbar,
  LayoutWrapperFooter,
  Footer,
  Page,
  UserNav,
} from '../../components';
import { TopbarContainer } from '../../containers';

import css from './IntegrationsPage.css';
import { isScrollingDisabled } from '../../ducks/UI.duck';
import { ensureCurrentUser } from '../../util/data';

class IntegrationsPageComponent extends React.Component {
  render() {
    const { currentUser, scrollingDisabled } = this.props;
    const user = ensureCurrentUser(currentUser);

    return user.id ? (
      <Page title="Integrations" scrollingDisabled={scrollingDisabled}>
        <LayoutSideNavigation>
          <LayoutWrapperTopbar>
            <TopbarContainer
              currentPage="ContactDetailsPage"
              desktopClassName={css.desktopTopbar}
              mobileClassName={css.mobileTopbar}
            />
            <UserNav currentUser={user} selectedPageName="IntegrationsPage" />
          </LayoutWrapperTopbar>
          <LayoutWrapperAccountSettingsSideNav currentTab="IntegrationsPage" currentUser={user} />
          <LayoutWrapperMain>
            <div className={css.content}>
              <h1 className={css.header}>Integrations</h1>
              <h2 className={css.subHeader}>Connect Givsly to your Calendar</h2>
              <CalendarIntegrationContent ensuredCurrentUser={user} />
            </div>
          </LayoutWrapperMain>
          <LayoutWrapperFooter>
            <Footer />
          </LayoutWrapperFooter>
        </LayoutSideNavigation>
      </Page>
    ) : null;
  }
}

const mapStateToProps = (state) => {
  const { currentUser } = state.user;
  return {
    currentUser,
    scrollingDisabled: isScrollingDisabled(state),
  };
};

const IntegrationsPage = compose(connect(mapStateToProps))(IntegrationsPageComponent);

IntegrationsPage.loadData = () => {
  return fetchCurrentUser();
};

export default IntegrationsPage;
