import React from 'react';
import { array, func, shape, string } from 'prop-types';
import css from './Steps.css';
import { NamedLink } from '../../../../components';
import { withRouter } from 'react-router-dom';

class Steps extends React.Component {
  constructor(props) {
    super(props);
    this.handleClick = this.handleClick.bind(this);
  }

  handleClick = (e) => {
    const { trackEvent, trackingCategory } = this.props;
    trackEvent({
      category: trackingCategory,
      action: 'ClickSignUp',
      label: 'Steps',
    });
    return e;
  };

  render() {
    const { buttonLabel, steps, title } = this.props;

    return (
      <div className={css.root}>
        <div className={css.title}>{title}</div>
        <div className={css.steps}>
          {steps.map((step, index) => {
            return (
              <div className={css.step} key={`step-${index}`}>
                <div className={css.stepTitle}>{step.title}</div>
                <h2 className={css.stepSubTitle}>{step.subTitle}</h2>
                <p className={css.stepText}>{step.text}</p>
              </div>
            );
          })}
        </div>
        <div className={css.actions}>
          <NamedLink className={css.button} name={'SignupPage'} onClick={this.handleClick}>
            {buttonLabel}
          </NamedLink>
        </div>
      </div>
    );
  }
}

Steps.propTypes = {
  buttonLabel: string.isRequired,
  history: shape({
    push: func.isRequired,
  }).isRequired,
  steps: array.isRequired,
  title: string.isRequired,
  trackEvent: func.isRequired,
  trackingCategory: string.isRequired,
};

Steps.defaultProps = {
  steps: [],
};

export default withRouter(Steps);
