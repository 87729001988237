import React from 'react';
import { NonprofitsForm } from '../../forms/index';
import { SUPPORTED_NONPROFITS } from '../OnboardingWizard/constants';
import { array, bool, func, object, string, number } from 'prop-types';
import { propTypes } from '../../util/types';
import { ensureCurrentUser, ensureListing } from '../../util/data';
import config from '../../config';
import { parse, stringify } from '../../util/urlHelpers';

import {
  pickSearchParamsOnly,
  validURLParamsForExtendedData,
} from '../../containers/SearchPage/SearchPage.helpers';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import { SESSION_KEY_SUPPORTED_NONPROFITS } from '../../util/data';

export const SUPPORTED_NONPROFIT_LIMIT = 3;

class SupportedNonprofitsPanelComponent extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      hasLoadedSupportedListings: false,
      hasUpdatedSupportedListings: false,
      isLoadingResults: false,
      isLoadingSupportedListings: false,
      searchQuery: this.props.location.search,
    };

    this.nonProfitListingByUuid = this.getNonprofitListingByUuid();
  }

  componentDidUpdate() {
    this.nonProfitListingByUuid = this.getNonprofitListingByUuid();
  }

  componentDidMount() {
    if (this.props.trackEvent) {
      this.props.trackEvent({ action: 'OpenTab', label: SUPPORTED_NONPROFITS });
    }
  }

  getNonprofitListingByUuid() {
    if (!this.props.nonprofitListings) return {};

    return this.props.nonprofitListings.reduce(
      (obj, nonprofit) => ({
        ...obj,
        [nonprofit.id.uuid]: {
          title: nonprofit.attributes.title,
          location: nonprofit.attributes.publicData.organizationLocation,
          category: nonprofit.attributes.publicData.organizationCategory,
        },
      }),
      {}
    );
  }

  trackEventNonprofitToggle(action, uuid) {
    if (!this.props.trackEvent) return;

    this.props.trackEvent({
      action,
      label: 'supportedNonprofit',
      value: this.nonProfitListingByUuid[uuid]
        ? JSON.stringify(this.nonProfitListingByUuid[uuid])
        : uuid,
    });
  }

  render() {
    const {
      currentUser,
      handleSubmit,
      keywordFilterConfig,
      location,
      nonprofitListings,
      nonprofitListingsError,
      nonprofitListingsInProgress,
      nonprofitPagination,
      onManageDisableScrolling,
      onOpenModal,
      onCloseModal,
      pageName,
      pagePathParams,
      pagination,
      searchInProgress,
      searchListingsError,
      searchParams,
      showAsModalMaxWidth,
      supportedNonprofits,
      updateListingError,
      updateListingInProgress,
      updateProfileError,
      updateProfileInProgress,
      trackEvent,
    } = this.props;

    const user = ensureCurrentUser(currentUser);

    const onSubmit = (values) => {
      const { supportedNPOs, bio } = values;

      const updatedValues = {
        publicData: {
          supportedNPOs: supportedNPOs.length > 0 ? supportedNPOs.split(',') : [],
        },
        bio: bio || '',
      };

      if (trackEvent && bio) {
        trackEvent({
          action: 'Input',
          label: 'Nonprofit-bio',
        });
      }

      handleSubmit(SUPPORTED_NONPROFITS, updatedValues);
    };

    const filters = {
      categoryFilter: {
        paramName: 'pub_organizationCategory',
        options: config.custom.categories,
      },
      keywordFilter: {
        paramName: 'keywords',
        config: keywordFilterConfig,
      },
      locationFilter: {
        paramName: 'pub_organizationLocation',
        options: config.custom.nonprofitLocations,
      },
    };

    const handleToggleNonprofit = (uuid, title) => {
      const supportedIds =
        JSON.parse(sessionStorage.getItem(SESSION_KEY_SUPPORTED_NONPROFITS)) ||
        user.attributes.profile.publicData.supportedNPOs;
      const itemIndex = supportedIds.indexOf(uuid);
      if (itemIndex >= 0) {
        supportedIds.splice(itemIndex, 1);
        supportedNonprofits.splice(itemIndex, 1);

        this.trackEventNonprofitToggle('Remove', uuid);
      } else if (supportedIds.length < SUPPORTED_NONPROFIT_LIMIT) {
        this.trackEventNonprofitToggle('Select', uuid);

        supportedIds.push(uuid);
        supportedNonprofits.push(
          ensureListing({
            id: {
              uuid,
            },
            attributes: {
              title,
            },
          })
        );
      } else {
        // @todo provide feedback to the user
        console.log('All slots occupied');
      }
      user.attributes.profile.publicData.supportedNPOs = supportedIds;
      sessionStorage.setItem(SESSION_KEY_SUPPORTED_NONPROFITS, JSON.stringify(supportedIds));
      this.forceUpdate();
    };

    const { mapSearch, page, keywords, ...searchInURL } = parse(location.search, {
      latlng: ['origin'],
      latlngBounds: ['bounds'],
    });

    const urlQueryParams = pickSearchParamsOnly(searchInURL, filters);
    const urlQueryString = stringify(urlQueryParams);
    const paramsQueryString = stringify(pickSearchParamsOnly(searchParams, filters));
    const searchParamsAreInSync = urlQueryString === paramsQueryString;
    const validQueryParams = validURLParamsForExtendedData(searchInURL, filters);
    validQueryParams.keywords = keywords;

    return (
      <NonprofitsForm
        currentUser={currentUser}
        listings={nonprofitListings}
        handleToggleNonprofit={handleToggleNonprofit}
        nonprofitPagination={nonprofitPagination}
        nonprofitListings={nonprofitListings}
        nonprofitListingsInProgress={nonprofitListingsInProgress}
        nonprofitListingsError={nonprofitListingsError}
        showAsModalMaxWidth={showAsModalMaxWidth}
        searchParams={searchParams}
        onManageDisableScrolling={onManageDisableScrolling}
        onOpenModal={onOpenModal}
        onCloseModal={onCloseModal}
        onSubmit={onSubmit}
        pageName={pageName}
        pagePathParams={pagePathParams}
        pagination={pagination}
        primaryFilters={{
          locationFilter: filters.locationFilter,
          categoryFilter: filters.categoryFilter,
          keywordFilter: filters.keywordFilter,
        }}
        searchInProgress={searchInProgress}
        searchListingsError={searchListingsError}
        searchParamsAreInSync={searchParamsAreInSync}
        searchParamsForPagination={parse(location.search)}
        supportedNonprofits={supportedNonprofits}
        updateInProgress={updateProfileInProgress || updateListingInProgress}
        updateError={updateProfileError || updateListingError}
        urlQueryParams={validQueryParams}
        trackEvent={trackEvent}
      />
    );
  }
}

SupportedNonprofitsPanelComponent.defaultProps = {
  pagePathParams: {},
  updateListingError: null,
  updateListingInProgress: false,
  updateProfileError: null,
  updateProfileInProgress: false,
};

SupportedNonprofitsPanelComponent.propTypes = {
  categories: array,
  currentUser: propTypes.currentUser.isRequired,
  handleSubmit: func.isRequired,
  location: object,
  nonprofitPagination: propTypes.pagination,
  nonprofitListingsError: propTypes.error,
  nonprofitListingsInProgress: bool,
  nonprofitListings: array,
  onManageDisableScrolling: func.isRequired,
  onOpenModal: func.isRequired,
  onCloseModal: func.isRequired,
  pageName: string.isRequired,
  pagePathParams: object.isRequired,
  showAsModalMaxWidth: number,
  supportedNonprofits: array,
  updateListingError: propTypes.error,
  updateListingInProgress: bool,
  updateProfileError: propTypes.error,
  updateProfileInProgress: bool,
  trackEvent: func,
};

const SupportedNonprofitsPanel = compose(withRouter)(SupportedNonprofitsPanelComponent);

export default SupportedNonprofitsPanel;
