import React from 'react';
import * as css from '../NonprofitPreferencesPanel.css';
import { useMessages } from '../../../util/localization';
import FieldTextInput from '../../FieldTextInput/FieldTextInput';
import { emailFormatValid } from '../../../util/validators';

const ApprovalContactInfo = () => {
  const getMessage = useMessages('NonprofitPreferencesForm');

  return (
    <section className={css.section}>
      <h3 className={css.sectionTitle}>{getMessage('section.approvalContact')}</h3>

      <FieldTextInput
        className={css.field}
        id="approvalContact.name"
        label={getMessage('field.approvalContact.name.label')}
        name="approvalContact.name"
        placeholder={getMessage('field.approvalContact.name.placeholder')}
        type="text"
      />

      <FieldTextInput
        className={css.field}
        id="approvalContact.email"
        label={getMessage('field.approvalContact.email.label')}
        name="approvalContact.email"
        placeholder={getMessage('field.approvalContact.email.placeholder')}
        type="email"
        validate={emailFormatValid(getMessage('field.approvalContact.email.required'), true)}
      />
    </section>
  );
};

export default ApprovalContactInfo;
