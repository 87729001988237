import React, { Component } from 'react';
import { array, bool, func, oneOf, object, shape, string } from 'prop-types';
import { injectIntl, intlShape } from '../../util/reactIntl';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import classNames from 'classnames';
import config from '../../config';
import { parse, stringify } from '../../util/urlHelpers';
import { propTypes } from '../../util/types';
import { getListingsById } from '../../ducks/marketplaceData.duck';
import { manageDisableScrolling, isScrollingDisabled } from '../../ducks/UI.duck';
import {
  Footer,
  LayoutWrapperFooter,
  LayoutWrapperMain,
  LayoutWrapperTopbar,
  Page,
} from '../../components';
import { TopbarContainer } from '../../containers';

import { queryNonProfits } from '../../ducks/NonprofitListing.duck';
import {
  pickSearchParamsOnly,
  validURLParamsForExtendedData,
  createSearchResultSchema,
} from './NonprofitSearchPage.helpers';
import MainPanel from './MainPanel';
import css from './NonprofitSearchPage.css';

// Pagination page size might need to be dynamic on responsive page layouts
// Current design has max 3 columns 12 is divisible by 2 and 3
// So, there's enough cards to fill all columns on full pagination pages
// const RESULT_PAGE_SIZE = 24;
const RESULT_PAGE_SIZE = 12;
const MODAL_BREAKPOINT = 768; // Search is in modal on mobile layout

export class NonprofitSearchPageComponent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isMobileModalOpen: false,
    };

    this.filters = this.filters.bind(this);
    this.getTranslation = this.getTranslation.bind(this);
    this.onOpenMobileModal = this.onOpenMobileModal.bind(this);
    this.onCloseMobileModal = this.onCloseMobileModal.bind(this);
  }

  getTranslation = (name, variables = {}) => {
    return this.props.intl.formatMessage({ id: `NonprofitSearchPage.${name}` }, variables);
  };

  filters() {
    const { industries, interests } = this.props;

    industries.sort();
    interests.sort((a, b) => (a.label > b.label ? 1 : -1));

    return {
      locationFilter: {
        paramName: 'pub_organizationLocation',
        options: config.custom.nonprofitLocations.map((location) => {
          return {
            key: location.key,
            label: location.abbreviatedLabel,
          };
        }),
      },
      categoryFilter: {
        paramName: 'pub_organizationCategory',
        options: config.custom.categories,
      },
    };
  }

  // Invoked when a modal is opened from a child component,
  // for example when a filter modal is opened in mobile view
  onOpenMobileModal() {
    this.setState({ isMobileModalOpen: true });
  }

  // Invoked when a modal is closed from a child component,
  // for example when a filter modal is opened in mobile view
  onCloseMobileModal() {
    this.setState({ isMobileModalOpen: false });
  }

  render() {
    const {
      history,
      intl,
      listings,
      location,
      onManageDisableScrolling,
      pagination,
      scrollingDisabled,
      searchInProgress,
      searchListingsError,
      searchParams,
    } = this.props;
    // eslint-disable-next-line no-unused-vars
    const { page, ...searchInURL } = parse(location.search);

    const filters = this.filters();

    // urlQueryParams doesn't contain page specific url params
    // like mapSearch, page or origin (origin depends on config.sortSearchByDistance)
    const urlQueryParams = pickSearchParamsOnly(searchInURL, filters);

    // Page transition might initially use values from previous search
    const urlQueryString = stringify(urlQueryParams);
    const paramsQueryString = stringify(pickSearchParamsOnly(searchParams, filters));
    const searchParamsAreInSync = urlQueryString === paramsQueryString;

    const validQueryParams = validURLParamsForExtendedData(searchInURL, filters);

    const { address } = searchInURL || {};
    const { title, description, schema } = createSearchResultSchema(listings, address, intl);

    // Set topbar class based on if a modal is open in
    // a child component
    const topbarClasses = this.state.isMobileModalOpen
      ? classNames(css.topbarBehindModal, css.topbar)
      : css.topbar;

    // N.B. openMobileMap button is sticky.
    // For some reason, stickyness doesn't work on Safari, if the element is <button>
    /* eslint-disable jsx-a11y/no-static-element-interactions */
    return (
      <Page
        scrollingDisabled={scrollingDisabled}
        description={description}
        title={title}
        schema={schema}
      >
        <LayoutWrapperTopbar>
          <TopbarContainer
            className={topbarClasses}
            currentPage="NonprofitSearchPage"
            currentSearchParams={urlQueryParams}
          />
        </LayoutWrapperTopbar>
        <LayoutWrapperMain>
          <div className={css.container}>
            <MainPanel
              getTranslation={this.getTranslation}
              history={history}
              urlQueryParams={validQueryParams}
              listings={listings}
              searchInProgress={searchInProgress}
              searchListingsError={searchListingsError}
              searchParamsAreInSync={searchParamsAreInSync}
              onActivateListing={() => {
                console.log('Activate');
              }}
              onManageDisableScrolling={onManageDisableScrolling}
              onOpenModal={this.onOpenMobileModal}
              onCloseModal={this.onCloseMobileModal}
              pagination={pagination}
              searchParamsForPagination={parse(location.search)}
              showAsModalMaxWidth={MODAL_BREAKPOINT}
              primaryFilters={{
                locationFilter: filters.locationFilter,
                categoryFilter: filters.categoryFilter,
              }}
            />
          </div>
        </LayoutWrapperMain>
        <LayoutWrapperFooter>
          <Footer />
        </LayoutWrapperFooter>
      </Page>
    );
    /* eslint-enable jsx-a11y/no-static-element-interactions */
  }
}

NonprofitSearchPageComponent.defaultProps = {
  listings: [],
  pagination: null,
  searchListingsError: null,
  searchParams: {},
  tab: 'listings',
  industries: config.custom.industries,
  interests: config.custom.interests,
  activeListingId: null,
};

NonprofitSearchPageComponent.propTypes = {
  listings: array,
  onManageDisableScrolling: func.isRequired,
  pagination: propTypes.pagination,
  scrollingDisabled: bool.isRequired,
  searchInProgress: bool.isRequired,
  searchListingsError: propTypes.error,
  searchParams: object,
  tab: oneOf(['filters', 'listings']).isRequired,
  industries: array,
  interests: array,

  history: shape({
    push: func.isRequired,
  }).isRequired,
  location: shape({
    search: string.isRequired,
  }).isRequired,

  intl: intlShape.isRequired,
};

const mapStateToProps = (state) => {
  const {
    currentPageResultIds,
    pagination,
    searchInProgress,
    searchListingsError,
    searchParams,
    activeListingId,
  } = state.NonprofitListing;
  const pageListings = getListingsById(state, currentPageResultIds);

  return {
    listings: pageListings,
    pagination,
    scrollingDisabled: isScrollingDisabled(state),
    searchInProgress,
    searchListingsError,
    searchParams,
    activeListingId,
  };
};

const mapDispatchToProps = (dispatch) => ({
  onManageDisableScrolling: (componentId, disableScrolling) =>
    dispatch(manageDisableScrolling(componentId, disableScrolling)),
});

// Note: it is important that the withRouter HOC is **outside** the
// connect HOC, otherwise React Router won't rerender any Route
// components since connect implements a shouldComponentUpdate
// lifecycle hook.
//
// See: https://github.com/ReactTraining/react-router/issues/4671
const NonprofitSearchPage = compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  injectIntl
)(NonprofitSearchPageComponent);

NonprofitSearchPage.loadData = (params, search) => {
  const queryParams = parse(search);
  const { page = 1, address, origin, ...rest } = queryParams;
  const originMaybe = config.sortSearchByDistance && origin ? { origin } : {};
  return queryNonProfits(
    {
      ...rest,
      ...originMaybe,
      pub_isNPOListing: true,
      page,
      perPage: RESULT_PAGE_SIZE,
      include: ['author', 'images', 'author.user', 'author.profileImage'],
      'fields.image': [
        'variants.landscape-crop',
        'variants.landscape-crop2x',
        'variants.square-small',
        'variants.square-small2x',
      ],
      'limit.images': 1,
    },
    RESULT_PAGE_SIZE
  );
};

export default NonprofitSearchPage;
