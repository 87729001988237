import React from 'react';
import css from './SearchAvailabilityFilter.css';
import { FormattedMessage } from 'react-intl';
import { Button, FieldTextInput, FilterPlain, FilterPopup } from '../index';
import classNames from 'classnames';
import { bool, func, shape, string } from 'prop-types';
import { DayPickerRangeController } from 'react-dates';
import moment from 'moment';
import { momentObj } from 'react-moment-proptypes';
import { START_DATE } from 'react-dates/constants';

export const OPTION_CUSTOM = 'custom';
export const OPTION_THIS_WEEK = 'thisWeek';
export const OPTION_NEXT_WEEK = 'nextWeek';
export const OPTION_NEXT_TWO_WEEKS = 'nextTwoWeeks';
export const OPTION_NEXT_MONTH = 'nextMonth';
export const OPTION_NEXT_THREE_MONTHS = 'nextThreeMonths';
export const OPTION_EVENT_DATES = 'event';

const today = moment.tz().startOf('day');
const minimumDate = today.clone().startOf('month');
const maximumDate = today.clone().add(90, 'days');

/**
 * The pre-defined options to automatically select a given date range.
 */
const options = (props) => {
  if (props.isMobile && !props.showPreDefinedOptions) {
    return [
      {
        key: OPTION_CUSTOM,
        startDate: null,
        endDate: null,
      },
      {
        key: OPTION_EVENT_DATES,
        startDate: props.minimumDate,
        endDate: props.maximumDate,
      },
    ];
  }

  return [
    {
      key: OPTION_CUSTOM,
      startDate: null,
      endDate: null,
    },
    {
      key: OPTION_THIS_WEEK,
      startDate: today,
      endDate: today.clone().add(1, 'week').endOf('day'),
    },
    {
      key: OPTION_NEXT_WEEK,
      startDate: today.clone().add(1, 'week'),
      endDate: today.clone().add(2, 'weeks').endOf('day'),
    },
    {
      key: OPTION_NEXT_TWO_WEEKS,
      startDate: today,
      endDate: today.clone().add(2, 'weeks').endOf('day'),
    },
    {
      key: OPTION_NEXT_MONTH,
      startDate: today.clone().startOf('month').add(1, 'month'),
      endDate: today.clone().startOf('month').add(1, 'month').endOf('month').endOf('day'),
    },
    {
      key: OPTION_NEXT_THREE_MONTHS,
      startDate: today,
      endDate: maximumDate,
    },
  ];
};

const labelDateFormat = 'MMM D';

const Label = (props) => {
  return <FormattedMessage id={`SearchAvailabilityFilter.${props.id}`} />;
};

class SearchAvailabilityFilter extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedKey: OPTION_CUSTOM,
      startDate: null,
      endDate: null,
      focusedInput: START_DATE,
      isApplied: false,
      appliedStartDate: null,
      appliedEndDate: null,
      mobileCalendarOpen: false,
    };

    this.inputRef = React.createRef();

    this.handleMobileCalendarApply = this.handleMobileCalendarApply.bind(this);
    this.handleMobileCalendarCancel = this.handleMobileCalendarCancel.bind(this);
    this.isDayBlocked = this.isDayBlocked.bind(this);
    this.onClear = this.onClear.bind(this);
    this.onCancel = this.onCancel.bind(this);
    this.onDatesChange = this.onDatesChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.onFocusChange = this.onFocusChange.bind(this);
    this.renderDayContents = this.renderDayContents.bind(this);
    this.selectOption = this.selectOption.bind(this);
  }

  /**
   * This is used to preset the state to a selected state when the initial values are supplied with
   * the props.
   *
   * @param props
   * @param state
   * @returns {*}
   */
  static getDerivedStateFromProps(props, state) {
    const { end, start } = props.initialValues;

    if (start && end && !state.startDate && !state.endDate) {
      state.startDate = moment(start);
      state.endDate = moment(end);
      state.isApplied = true;
      state.appliedStartDate = moment(start);
      state.appliedEndDate = moment(end);

      // Try to determine the selected option
      const selectedOption = options(props).find((option) => {
        return (
          option.key !== OPTION_CUSTOM &&
          option.startDate.isSame(state.startDate, 'day') &&
          option.endDate.isSame(state.endDate, 'day')
        );
      });

      state.selectedKey = selectedOption && selectedOption.key ? selectedOption.key : OPTION_CUSTOM;
    } else if (
      !start &&
      !end &&
      !state.appliedStartDate &&
      !state.appliedEndDate &&
      props.isMobile
    ) {
      state.selectedKey = null;
    } else if (!start && !end && !props.isMobile) {
      state.appliedEndDate = null;
      state.appliedStartDate = null;
    }
    return state;
  }

  handleMobileCalendarCancel() {
    this.setState({
      endDate: null,
      mobileCalendarOpen: false,
      selectedKey: null,
      startDate: null,
    });
  }

  handleMobileCalendarApply() {
    this.setState({
      mobileCalendarOpen: false,
      selectedKey: OPTION_CUSTOM,
    });
    this.props.onSubmit(null, {
      start: this.state.startDate.toDate().toISOString(),
      end: this.state.endDate.toDate().toISOString(),
    });
  }

  /**
   * Handles the option selection behavior by applying the date range selector.
   *
   * @param key
   */
  selectOption = (key) => {
    const selectedOption = options(this.props).find((option) => option.key === key);
    this.setState({
      endDate: selectedOption.endDate,
      selectedKey: key,
      startDate: selectedOption.startDate,
      mobileCalendarOpen: this.props.isMobile && key === OPTION_CUSTOM,
    });

    if (this.props.isMobile && key !== OPTION_CUSTOM) {
      this.props.onSubmit(null, {
        start: selectedOption.startDate.startOf('day').toDate().toISOString(),
        end: selectedOption.endDate.endOf('day').toDate().toISOString(),
      });
    }
  };

  /**
   * Clear this filter and the selected values. This is followed up by an actual form filter
   * submission.
   */
  onClear() {
    this.setState({
      appliedEndDate: null,
      appliedStartDate: null,
      endDate: null,
      isApplied: false,
      startDate: null,
    });
  }

  /**
   * Closes the modal popup and clears the selection if it was not applied.
   */
  onCancel() {
    if (!this.state.isApplied) {
      this.setState({
        endDate: this.state.appliedEndDate,
        focusedInput: START_DATE,
        selectedKey: OPTION_CUSTOM,
        startDate: this.state.appliedStartDate,
      });
    }
  }

  /**
   * Handle calendar date selection
   *
   * @param startDate
   * @param endDate
   */
  onDatesChange = ({ startDate, endDate }) => {
    this.setState({
      selectedKey: OPTION_CUSTOM,
      startDate: startDate.startOf('day'),
      endDate: endDate ? endDate.endOf('day') : startDate.clone().endOf('day'),
    });
  };

  /**
   * Date selection focus handler to keep track of the start and end dates
   *
   * @param focusedInput
   */
  onFocusChange(focusedInput) {
    this.setState({
      focusedInput: !focusedInput ? START_DATE : focusedInput,
    });
  }

  /**
   * Submits the selection and applies the filter. Sets the isApplied flag in the state in order to
   * show the correct filter state before any data loading is done.
   *
   * @param urlParam
   * @param options
   */
  onSubmit(urlParam, options) {
    this.setState({
      isApplied: true,
      appliedStartDate: this.state.startDate,
      appliedEndDate: this.state.endDate,
    });
    this.props.onSubmit(urlParam, options);
  }

  /**
   * Overrides the default calendar day check to see if a day is blocked. A day is blocked if it's
   * in the past or more than 3 months in the future.
   *
   * @param day
   * @returns {boolean}
   */
  isDayBlocked = (day) => {
    const minDate = today.isAfter(this.props.minimumDate, 'day') ? today : this.props.minimumDate;
    return day.isBefore(minDate) || day.isAfter(this.props.maximumDate);
  };

  /**
   * Custom renderer for the day contents of the calendar days.
   *
   * @param day
   * @param options
   * @returns {*}
   */
  renderDayContents = (day, options) => {
    const classes = classNames(
      css.dayContents,
      options.has('blocked') &&
        ((this.state.startDate && !day.isSame(this.state.startDate, 'day')) ||
          !this.state.startDate)
        ? css.blocked
        : null
    );

    return <span className={classes}>{day.format('D')}</span>;
  };

  initialVisibleMonth = () => {
    return this.props.minimumDate;
  };

  render() {
    const {
      id,
      initialValues,
      isMobile,
      minimumDate,
      maximumDate,
      showPreDefinedOptions,
    } = this.props;
    const {
      appliedEndDate,
      appliedStartDate,
      endDate,
      focusedInput,
      isApplied,
      selectedKey,
      startDate,
    } = this.state;

    const values = { ...initialValues };
    if (startDate && endDate) {
      values.start = startDate.startOf('day').toDate().toISOString();
      values.end = endDate.endOf('day').toDate().toISOString();
    }

    const isSelected = appliedStartDate !== null && appliedEndDate !== null && isApplied;
    const calendarClasses = classNames(
      css.calendar,
      isMobile && this.state.mobileCalendarOpen ? css.calendarOpen : null
    );
    const disableApply = !this.state.startDate || !this.state.endDate;

    const filterContent = (
      <div className={css.filterContent}>
        <FieldTextInput name="start" type="hidden" />
        <FieldTextInput name="end" type="hidden" />
        {(isMobile || showPreDefinedOptions) && (
          <ul className={css.options}>
            {options(this.props).map((option) => {
              return (
                <li
                  className={classNames(
                    css.option,
                    selectedKey === option.key ? css.selectedOption : null
                  )}
                  key={option.key}
                  onClick={() => this.selectOption(option.key)}
                >
                  <Label id={option.key} />
                </li>
              );
            })}
          </ul>
        )}
        <div className={calendarClasses}>
          <div className={css.mobileTopControls}>
            <Button
              className={css.buttonClose}
              onClick={this.handleMobileCalendarCancel}
              type={'button'}
            >
              <Label id={'cancel'} />
            </Button>
            <div className={css.mobileSelection}>
              <span className={css.startDate}>
                {this.state.startDate ? this.state.startDate.format('DD/MM/YYYY') : '-'}
              </span>
              <span className={css.arrow} />
              <span className={css.endDate}>
                {this.state.endDate ? this.state.endDate.format('DD/MM/YYYY') : '-'}
              </span>
            </div>
          </div>
          <DayPickerRangeController
            enableOutsideDays={false}
            endDate={endDate}
            onDatesChange={this.onDatesChange}
            onFocusChange={this.onFocusChange}
            focusedInput={focusedInput}
            initialVisibleMonth={this.initialVisibleMonth}
            isDayBlocked={this.isDayBlocked}
            minimumNights={0}
            minDate={minimumDate}
            maxDate={maximumDate}
            numberOfMonths={isMobile ? 3 : 2}
            renderDayContents={this.renderDayContents}
            startDate={startDate}
            weekDayFormat="ddd"
          />
          <div className={css.mobileBottomControls}>
            <Button
              className={css.buttonSetDates}
              disabled={disableApply}
              onClick={this.handleMobileCalendarApply}
              type={'button'}
            >
              <Label id={'setDateRange'} />
            </Button>
          </div>
        </div>
      </div>
    );

    const buttonLabel =
      appliedEndDate !== null && appliedStartDate !== null ? (
        appliedStartDate.isSame(appliedEndDate, 'day') ? (
          appliedStartDate.format(labelDateFormat)
        ) : (
          `${appliedStartDate.format(labelDateFormat)} - ${appliedEndDate.format(labelDateFormat)}`
        )
      ) : (
        <Label id={'label'} />
      );

    return isMobile ? (
      <div className={css.root}>
        <FilterPlain
          className={css.mobileFilter}
          id={id}
          keepDirtyOnReinitialize={false}
          labelClassName={css.mobileFilterLabel}
          initialValues={values}
          isSelected={isSelected}
          liveEdit={true}
          onCancel={this.onCancel}
          onClear={this.onClear}
          onSubmit={this.onSubmit}
        >
          {filterContent}
        </FilterPlain>
      </div>
    ) : (
      <div className={css.root}>
        <FilterPopup
          buttonsWrapperClassName={classNames(css.buttonsWrapper, {
            [css.noOptions]: !showPreDefinedOptions,
          })}
          id={id}
          initialValues={values}
          isSelected={isSelected}
          onCancel={this.onCancel}
          onClear={this.onClear}
          onSubmit={this.onSubmit}
          popupClassName={classNames(css.popup, {
            [css.noOptions]: !showPreDefinedOptions,
          })}
          popupSizeClassName={css.popupSize}
          label={buttonLabel}
          urlParam={'availability'}
        >
          {filterContent}
        </FilterPopup>
      </div>
    );
  }
}

SearchAvailabilityFilter.propTypes = {
  defaultOption: string,
  id: string.isRequired,
  initialValues: shape({
    start: string,
    end: string,
  }),
  isMobile: bool,
  minimumDate: momentObj,
  maximumDate: momentObj,
  onSubmit: func.isRequired,
  showPreDefinedOptions: bool,
};

SearchAvailabilityFilter.defaultProps = {
  defaultOption: OPTION_CUSTOM,
  initialValues: {
    start: null,
    end: null,
  },
  minimumDate,
  maximumDate,
  isMobile: false,
  showPreDefinedOptions: true,
};

export default SearchAvailabilityFilter;
