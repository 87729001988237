// import { types as sdkTypes } from './util/sdkLoader';

// const { LatLng, LatLngBounds } = sdkTypes;

// An array of locations to show in the LocationAutocompleteInput when
// the input is in focus but the user hasn't typed in any search yet.
//
// Each item in the array should be an object with a unique `id` (String) and a
// `predictionPlace` (util.types.place) properties.
export default [
  // {
  //   id: 'place.15278078705964500',
  //   predictionPlace: {
  //     search: 'New York, New York',
  //     origin: new LatLng(40.6974034, -74.1197626),
  //     bounds: new LatLngBounds(new LatLng(40.995, -73.673), new LatLng(40.498, -74.250)),
  //   },
  //
  //   type: 'Feature',
  //   place_type: ['place'],
  //   relevance: 1,
  //   properties: {
  //     wikidata: 'Q60'
  //   },
  //   text_en: 'New York City',
  //   language_en: 'en',
  //   place_name_en: 'New York City, New York, United States of America',
  //   text: 'New York City',
  //   language: 'en',
  //   place_name: 'New York City, New York, United States of America',
  //   matching_text: 'New York',
  //   matching_place_name: 'New York, New York, United States of America',
  //   bbox: [-74.2590879797556, 40.477399, -73.7008392055224, 40.917576401307],
  //   center: [-73.9808, 40.7648],
  //   geometry: {
  //     type: 'Point',
  //     coordinates: [-73.9808, 40.7648]
  //   },
  //   context: [{
  //     id: 'region.10003493535855570',
  //     short_code: 'US-NY',
  //     wikidata: 'Q1384',
  //     text_en: 'New York',
  //     language_en: 'en',
  //     text: 'New York',
  //     language: 'en',
  //   }, {
  //     id: 'country.19352517729256050',
  //     short_code: 'us',
  //     wikidata: 'Q30',
  //     text_en: 'United States of America',
  //     language_en: 'en',
  //     text: 'United States of America',
  //     language: 'en',
  //   }],
  // },
];
