import { NamedLink } from '../../components';
import { SECTION_OUTREACH } from './constants';
import css from './OutreachFaqPage.css';
import {
  Accordion,
  AccordionItem,
  AccordionItemButton,
  AccordionItemHeading,
  AccordionItemPanel,
} from 'react-accessible-accordion';
import React from 'react';
import { node, oneOfType, string } from 'prop-types';

export const OutreachFaqPageContent = ({ text, title, footNote }) => {
  const questions = [
    {
      title: '',
      id: SECTION_OUTREACH,
      items: [
        {
          question: 'What Are Donation Offer Links on Givsly?',
          answer: (
            <>
              Givsly’s new Donation Offer links allow you to reach out to prospects, partners and
              pretty much any professional you would like to connect with in a meaningful way
              through using the power of Givsly donations; even if they are not a member on Givsly.
              <br />
              <br />
              As a registered and logged in Givsly member, you can generate a donation web link
              which you can include in your communications in emails, pages, chats, phone texts,
              social posts and so on. For the web link, you will choose the donation amount and one
              to three nonprofits from Givsly that your link will offer to the link recipient to
              pick out which one they would like to receive a donation.
              <br />
              <br />
              The same link (like a campaign link) can be sent out to multiple people that you would
              like to reach. If they choose to click through, choose a NPO for your donation, leave
              their information and submit the offer response back to you, then you know that they
              are interested in connecting with you. Next steps after that are for you to confirm
              the donation payment via Givsly (paying with credit card or Givsly Credits) and to
              reach back to the person who accepted your link and expressed interest in connecting.
              <br />
              <br />
              Just like with meetings agreed between Givsly members, with the donation links, Givsly
              facilitates the payment of donations to the nonprofit organizations on its platform.
              Givsly will also communicate back to the link recipient with a thank you email from
              the NPO and Givsly when you make the donation payment.
            </>
          ),
        },
        {
          question: 'Do these links still only apply to meetings?',
          answer: (
            <>
              No, although you can definitely send a Donation Offer link out as a meeting request,
              you can also use the links for other conversion requests. For example, you can use it
              to ask for product feedback, as a thank you for event registration or participation or
              a follow-up for visiting your booth or show client/ partner/ employees appreciation.
            </>
          ),
        },
        {
          question: 'Where can I find the Offer Links Beta if I am a member of the beta group?',
          answer: (
            <>
              We are exposing the Beta to selected Givsly members and if you are a part of the beta
              group you will see a “Offer Links” -view under your Account Settings. You can click to
              the page directly from <NamedLink name="OutreachOverViewPage">here</NamedLink>.
            </>
          ),
        },
        {
          question: 'How many links can I create?',
          answer: (
            <>
              Our beta testing phase is all about learning and understanding how Givsly members can
              get the most value from the Donation Offer links and what are some great ways to make
              use of them - thus at this point we have not limited the number of links you can
              create.
            </>
          ),
        },
        {
          question: 'Can I choose nonprofits other than those currently on my profile?',
          answer: (
            <>
              Yes, when you create a Donation Offer link on Givsly, the link generation tool
              initially selects those NPOs which are on your existing profile. You can however
              exchange these for up to any three nonprofits as long as they are one of those that
              exist on Givsly. If there is a nonprofit that you are interested in supporting that is
              not currently available on the Givsly platform, please reach out to us at{' '}
              <a href="mailto:nonprofits@givsly.com">nonprofits@givsly.com</a> to request that we
              reach out to them to see if we can onboard them to Givsly.
            </>
          ),
        },
        {
          question: 'What donation sums can I choose from?',
          answer: (
            <>
              When you create a new Donation Offer link, we present you with a pull-down menu of
              individual donation sum options, starting from $25.
            </>
          ),
        },
        {
          question: 'Can I see what the link recipient can see when they click on the link?',
          answer: (
            <>
              Once you have created a link you can view the link detail page. Here you will also
              find a preview button to see how the landing page looks for a link recipient that
              comes through to your link.
            </>
          ),
        },
        {
          question: 'What does the link landing page say? ',
          answer: (
            <>
              At this point, the landing page is automatically created by Givsly. It lists your
              company name, the individual donation $ value you have chosen, the selected NPOs you
              want to present to choose from and their impact examples. This landing page is where
              the link recipient is asked to make a NPO selection and submit their information for
              your verification. They can leave a short message to you.
              <br />
              <br />
              The landing page also explains that Givsly is the third-party partner who works with
              you and your company to get the donations to NPOs.
            </>
          ),
        },
        {
          question: 'Can I edit a link once I have created it?',
          answer: (
            <>
              At this moment you cannot yet edit a link once it is created. If you do not like a
              link you created, you can make it inactive and simply create a new one.
            </>
          ),
        },
        {
          question: 'When do I make the donation payment?',
          answer: (
            <>
              Once a person responds to your link, including selecting the NPO and leaving their
              name, work email and company (optional) so that you can view it on Givsly, you are
              asked to confirm this specific donation and connection by making the payment. The
              payment is done on Givsly in collaboration with our payment partners & using your
              credit card or your corporate Givsly Credits or a combination of both.
            </>
          ),
        },
        {
          question: "What if someone responds who is not someone that I've sent the link to?",
          answer: (
            <>
              Since the links can be sent out freely it is possible that if forwarded, someone
              outside of your original outreach could respond and select a donation. You however
              have complete control of which response you will pay the donation on. You can simply
              discard the acceptance and not make a donation payment, if it feels like this is not
              the person you were looking to connect with.
            </>
          ),
        },
        {
          question: 'Can I somehow control the budget that I will spend on a Donation Offer link?',
          answer: (
            <>
              To help keep track of the budget you have paid out as donations for a specific
              donation link, you can set a “cap” on your donation payments during the offer link
              creation step.
              <br />
              <br />
              This means that you can confirm donation payments for that Donation Offer until the
              cap is reached (depending on the value set for a single donation, we may cap a bit
              earlier).
              <br />
              <br />
              After the set cap $ amount has been reached, the Donation Offer link will direct the
              next person to a separate landing page which will inform them that this donation
              campaign has reached its target and thank them for their interest.
            </>
          ),
        },
        {
          question: 'What happens if the cap I set is reached?',
          answer: (
            <>
              After the set cap/budget $ amount has been reached, the Donation Offer link will start
              to direct the next persons to a separate landing page which will inform them that this
              specific donation campaign has reached its target and thank them for their interest.
              If you want to continue sending out links at this point it is best to create a new
              link. We have editable links and saving the info of additional interested people on
              the roadmap.
            </>
          ),
        },
        {
          question: 'Can I manually deactivate an offer?',
          answer: (
            <>
              If you would like to manually deactivate an offer link so that visitors to that link
              URL are not able to accept your Donation Offer anymore, you can do so from your
              dashboard under each Offer Links. Making a link inactive means that visitors are
              directed to a thank you page which states that this donation campaign has reached its
              target and thank them for their interest.
            </>
          ),
        },
        {
          question: 'Can I send the same link to multiple people?',
          answer: (
            <>
              When a donation link is created it is not currently tied to any specific recipient. It
              can be sent out as part of your communication to as many people as you see fit.
            </>
          ),
        },
        {
          question: 'How will I be notified if someone responds to my link?',
          answer: (
            <>
              Your Givsly account will have a dashboard to keep track of links you have created and
              the responses that those links have received. To submit an acceptance for your
              donation link, the recipient is asked to also give their first and last name, their
              work email, their company name (optional), and if they want to leave a short message
              for you. You can view this information on the dashboard for each accepted donation
              offer. When a link recipient accepts we will also trigger you an email so you know and
              can view their information and complete the donation payment for their NPO.
            </>
          ),
        },
        {
          question: 'How does the person know that I made the donation to their chosen NPO?',
          answer: (
            <>
              Once you confirm a donation and make the payment, Givsly will send a thank you note
              informing the person responding to your link that you have made the donation and it is
              in the process of being paid out to their NPO.
            </>
          ),
        },
        {
          question:
            'Can Givsly help me if the person I connected with does not respond to me after my initial outreach and donation to the NPO they chose?',
          answer: (
            <>
              Since the Donation Offer links can be sent out freely, using any of your channels and
              accompanying such a variety of, asks to the person that you are reaching out to,
              Givsly is unfortunately not able to follow up in a similar manner as it can when you
              are booking meetings between two Givsly members through our traditional meeting
              outreach.
            </>
          ),
        },
        {
          question: 'Does 100% of my payment go to the NPO donation?',
          answer: (
            <>
              There is a 15% Givsly platform fee and in case of a credit card a payment processing
              fee for our transaction provider deducted from the donation amount but everything else
              goes to the NPO.
            </>
          ),
        },
        {
          question: 'Can I get a refund of the donation payment?',
          answer: (
            <>
              Since the Donation Offer links can be sent out freely, using any of your channels and
              accompanying such a variety of requests to the person that you are reaching out to,
              Givsly is unfortunately not able to refund Donation Offer payments once you have
              confirmed them.
            </>
          ),
        },
        {
          question: 'When is the beta phase over so the rest of my team can use this?',
          answer: (
            <>
              Initially we plan to run this beta phase during October and depending on feedback open
              up the Donation Offer links gradually to a broader Givsly member audience starting
              early November.
              <br />
              <br />
              If you have additional questions not answered in this BETA FAQ sheet, please reach out
              to the givsly team at <a href="mailto:hello@givsly.com">hello@givsly.com</a>
              <br />
              <br />
              If you are experiencing technical difficulties with the beta feature or with
              Givsly.com services in general, please reach out to our support team at{' '}
              <a href="mailto:support@givsly.com">support@givsly.com</a>
              <br />
              <br />
              Thank you for participating in this Givsly Beta!
            </>
          ),
        },
      ],
    },
  ];

  return (
    <>
      <h1 className={css.pageTitle}>FAQ for Givsly Donation Offer Beta</h1>
      <p className={css.description}>
        <em>Updated 10/01/2021</em>
        <br />
        <br />
        This BETA FAQ is designed to answer specific questions from our Givsly Donation Offer link
        beta testing group. This is a closed beta test with a limited number of participants at this
        point.
        <br />
        <br />
        You can find all our other FAQ responses regarding Givsly meetings on our public{' '}
        <NamedLink name="FaqPage">Givsly FAQ here</NamedLink>.
      </p>
      <div className={css.contentWrapper}>
        <div className={css.contentMain}>
          {questions.map((section, i) => (
            <div className={css.section} key={`faq-${i}`}>
              <Accordion
                allowMultipleExpanded={false}
                allowZeroExpanded={false}
                className={css.faqList}
              >
                {section.items.map((item, j) => (
                  <AccordionItem className={css.faqItem} key={`faq-${i}-${j}`}>
                    <AccordionItemHeading>
                      <AccordionItemButton className={css.faqItemButton}>
                        {item.question}
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel className={css.faqItemBody}>
                      {item.answer}
                    </AccordionItemPanel>
                  </AccordionItem>
                ))}
              </Accordion>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

OutreachFaqPageContent.defaultProps = {
  title: 'FAQ',
};

OutreachFaqPageContent.propTypes = {
  title: oneOfType([string, node]),
  text: oneOfType([string, node]),
  footNote: oneOfType([string, node]),
};

export default OutreachFaqPageContent;
