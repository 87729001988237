import React from 'react';
import PropTypes from 'prop-types';

import css from './IconDefaultNPOAvatar.css';

const IconDefaultNPOAvatar = (props) => {
  const { className } = props;
  return (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      width="240"
      height="240"
      viewBox="0 0 240 240"
    >
      <g fill="none" fillRule="evenodd">
        <circle className={css.background} cx="120" cy="120" r="120" />
        <path
          className={css.foreground}
          d="M159.597 131.393l11.34 11.31L119.5 194l-51.437-51.297 11.34-11.31L119.5 171.38l40.097-39.987zm-56.55-28.366c9.395-9.37 24.628-9.37 34.023 0 9.395 9.37 9.395 24.561 0 33.931-9.396 9.369-24.628 9.37-34.023 0-9.396-9.37-9.396-24.56 0-33.93zm18.154-26.1c15.946-15.903 41.893-15.903 57.84 0 15.946 15.903 15.946 41.779 0 57.682l-11.342-11.31c9.692-9.667 9.692-25.395 0-35.062-9.693-9.666-25.464-9.666-35.157 0zm-61.242 0c15.947-15.903 41.893-15.903 57.84 0l-11.341 11.31c-9.693-9.666-25.465-9.666-35.158 0-9.693 9.667-9.692 25.395 0 35.062l-11.34 11.31c-15.947-15.903-15.946-41.78 0-57.682z"
        />
      </g>
    </svg>
  );
};

IconDefaultNPOAvatar.defaultProps = { className: null };

const { string } = PropTypes;

IconDefaultNPOAvatar.propTypes = { className: string };

export default IconDefaultNPOAvatar;
