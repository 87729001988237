import React, { useState, useEffect } from 'react';
import PropTypes, { shape } from 'prop-types';
import { FormattedMessage, intlShape } from '../../util/reactIntl';
import classNames from 'classnames';
import { ACCOUNT_SETTINGS_PAGES } from '../../routeConfiguration';
import { propTypes } from '../../util/types';
import {
  Avatar,
  InlineTextButton,
  Logo,
  Menu,
  MenuLabel,
  MenuContent,
  MenuItem,
  NamedLink,
  GivslySearch,
  ExternalLink,
} from '../../components';

import css from './TopbarDesktop.css';
import { ensureCurrentUser } from '../../util/data';
import config from '../../config';
import { PERSONAL_INFO } from '../MyProfileWizard/MyProfileWizard';
import { NONPROFIT_INFO } from '../MyNonprofitWizard/constants';
import { ONBOARDING_PROGRESS as NONPROFIT_ONBOARDING_PROGRESS } from '../NonprofitOnboardingWizard/constants';
import { createSlug } from '../../util/urlHelpers';
import { ONBOARDING_PROGRESS } from '../OnboardingWizard/constants';
import { PARTNERSHIP_TABS } from '../../containers/PartnershipsPage/constants';
import { validateSubscription } from '../../util/validateSubsccription';

const TopbarDesktop = (props) => {
  const {
    className,
    currentUser,
    currentPage,
    hasNewNotifications,
    history,
    rootClassName,
    currentUserHasListings,
    notificationCount,
    intl,
    isAuthenticated,
    onLogout,
    // searchString,
    // searchResults,
  } = props;
  const [mounted, setMounted] = useState(false);

  useEffect(() => {
    setMounted(true);
  }, []);

  const PLATFORM_URL = process.env.REACT_APP_PLATFORM_URL;

  const authenticatedOnClientSide = mounted && isAuthenticated;
  const isAuthenticatedOrJustHydrated = isAuthenticated || !mounted;

  const classes = classNames(rootClassName || css.root, className);

  const search = !config.custom.limitUi ? (
    <GivslySearch history={history} id={'topbarsearch'} className={css.givslySearchLink} />
  ) : (
    <div className={css.spacer} />
  );

  const user = ensureCurrentUser(currentUser);
  const { isNPO, listingId } = user.attributes.profile.publicData;
  const onboardingCompleted =
    user.attributes.profile.privateData.onboardingCompleted ||
    user.attributes.profile.publicData.onboardingCompleted;
  const onboardingProgress =
    user.attributes.profile.privateData.onboardingProgress ||
    user.attributes.profile.publicData.onboardingProgress;
  const inboxDot = notificationCount > 0 ? <div className={css.notificationDot} /> : null;

  const donationsLink = user.id ? (
    <NamedLink
      className={css.notificationsLink}
      name="OutreachOffersPage"
      params={{ tab: 'overview' }}
    >
      <span className={css.notifications}>
        <FormattedMessage id="TopbarDesktop.donationOffers" />
      </span>
    </NamedLink>
  ) : null;

  const currentPageClass = (page) => {
    const isAccountSettingsPage =
      page === 'AccountSettingsPage' && ACCOUNT_SETTINGS_PAGES.includes(currentPage);
    return currentPage === page || isAccountSettingsPage ? css.currentPage : null;
  };

  const profileMenuItems = [];
  if (onboardingCompleted && listingId) {
    if (!isNPO) {
      profileMenuItems.push([
        <MenuItem key="ListingPage">
          <NamedLink
            className={classNames(css.profileSettingsLink, currentPageClass('ListingPage'))}
            name="ListingPage"
            params={{
              id: listingId,
              slug: createSlug(
                `${user.attributes.profile.firstName} ${user.attributes.profile.lastName}`
              ),
            }}
          >
            <span className={css.menuItemBorder} />
            <FormattedMessage id="TopbarDesktop.viewMyProfile" />
          </NamedLink>
        </MenuItem>,
      ]);
    }

    if (!isNPO && onboardingCompleted) {
      profileMenuItems.push([
        <MenuItem key="MyProfilePage">
          <NamedLink
            className={classNames(css.profileSettingsLink, currentPageClass('MyProfilePage'))}
            name="MyProfilePage"
            params={{ tab: PERSONAL_INFO }}
          >
            <span className={css.menuItemBorder} />
            <FormattedMessage id="TopbarDesktop.profileSettingsLink" />
          </NamedLink>
        </MenuItem>,
      ]);
    } else {
      profileMenuItems.push([
        <MenuItem key="ListingPage">
          <NamedLink
            className={classNames(css.profileSettingsLink, currentPageClass('ListingPage'))}
            name="ListingPage"
            params={{
              id: listingId,
              slug: createSlug(
                `${user.attributes.profile.firstName} ${user.attributes.profile.lastName}`
              ),
            }}
          >
            <span className={css.menuItemBorder} />
            <FormattedMessage id="TopbarDesktop.viewMyNonprofit" />
          </NamedLink>
        </MenuItem>,
      ]);
      profileMenuItems.push([
        <MenuItem key="MyNonprofitPage">
          <NamedLink
            className={classNames(css.profileSettingsLink, currentPageClass('MyNonprofitPage'))}
            name="MyNonprofitPage"
            params={{ tab: NONPROFIT_INFO }}
          >
            <span className={css.menuItemBorder} />
            <FormattedMessage id="TopbarDesktop.myNonprofitLink" />
          </NamedLink>
        </MenuItem>,
      ]);
      profileMenuItems.push([
        <MenuItem key="PartnershipsTabPage">
          <NamedLink
            className={classNames(css.profileSettingsLink, currentPageClass('PartnershipsTabPage'))}
            name="PartnershipsTabPage"
            params={{ tab: PARTNERSHIP_TABS.APPROVAL }}
          >
            <span className={css.menuItemBorder} />
            <FormattedMessage id="TopbarDesktop.partnershipLink" />
          </NamedLink>
        </MenuItem>,
      ]);
    }
  } else if (user.attributes.emailVerified) {
    let tab;
    if (isNPO) {
      tab = Object.keys(NONPROFIT_ONBOARDING_PROGRESS).find(
        (key) => NONPROFIT_ONBOARDING_PROGRESS[key] === onboardingProgress + 100
      );
      profileMenuItems.push([
        <MenuItem key="NonprofitOnboardingPage">
          <NamedLink
            className={classNames(
              css.profileSettingsLink,
              currentPageClass('NonprofitOnboardingPage')
            )}
            name="NonprofitOnboardingPage"
            params={{ tab: tab || NONPROFIT_INFO }}
          >
            <span className={css.menuItemBorder} />
            <FormattedMessage id="TopbarDesktop.continueOnboarding" />
          </NamedLink>
        </MenuItem>,
      ]);
    } else {
      tab = Object.keys(ONBOARDING_PROGRESS).find(
        (key) => ONBOARDING_PROGRESS[key] === onboardingProgress + 10
      );
      profileMenuItems.push([
        <MenuItem key="OnboardingProfile">
          <NamedLink
            className={classNames(css.profileSettingsLink, currentPageClass('OnboardingPage'))}
            name="OnboardingPage"
            params={{ tab: tab || PERSONAL_INFO }}
          >
            <span className={css.menuItemBorder} />
            <FormattedMessage id="TopbarDesktop.continueOnboarding" />
          </NamedLink>
        </MenuItem>,
      ]);
    }
  }

  const profileMenu = authenticatedOnClientSide ? (
    <Menu className={css.profileMenu}>
      <MenuLabel className={css.profileMenuLabel} isOpenClassName={css.profileMenuIsOpen}>
        <Avatar className={css.avatar} user={currentUser} />
      </MenuLabel>
      <MenuContent className={css.profileMenuContent} arrowPosition={12}>
        {profileMenuItems}
        <MenuItem key="AccountSettingsPage">
          <NamedLink
            className={classNames(css.yourListingsLink, currentPageClass('AccountSettingsPage'))}
            name="AccountSettingsPage"
          >
            <span className={css.menuItemBorder} />
            <FormattedMessage id="TopbarDesktop.accountSettingsLink" />
          </NamedLink>
        </MenuItem>
        <MenuItem key="logout">
          <InlineTextButton rootClassName={css.logoutButton} onClick={onLogout}>
            <span className={css.menuItemBorder} />
            <FormattedMessage id="TopbarDesktop.logout" />
          </InlineTextButton>
        </MenuItem>
      </MenuContent>
    </Menu>
  ) : null;

  const requestDemoLink = isAuthenticatedOrJustHydrated ? null : (
    <a className={css.signupLink} href="https://form.typeform.com/to/yKoPyBT9" target="_blank" rel="noopener noreferrer">
      <span className={css.signup}>
        <FormattedMessage id="TopbarDesktop.requestDemo" />
      </span>
    </a>
  );

  const menuSolutions = authenticatedOnClientSide ? null : (
    <Menu className={css.loginMenu}>
      <MenuLabel className={css.profileMenuLabel} isOpenClassName={css.profileMenuIsOpen}>
        <span style={{ fontWeight: 500, fontSize: 16, lineheight: 24 }} >
          <FormattedMessage id="TopbarDesktop.solutions" />
        </span>
      </MenuLabel>
      <MenuContent>
        <MenuItem key="solution-advertising">
          <ExternalLink
              className={css.yourListingsLink}
              href={`${config.custom.companySiteUrl}/advertising`}
            >
            <div class={css['login-menu-stack']}>
              <span className={css.menuItemBorder} />
              <span>Responsible Advertising</span>
              <span className={css['login-menu-small']}>Ad performance through shared values</span>
            </div>
          </ExternalLink>
        </MenuItem>
        <MenuItem key="solution-marketing">
          <ExternalLink
              className={css.yourListingsLink}
              href={`${config.custom.companySiteUrl}/marketing`}
            >
            <div class={css['login-menu-stack']}>
              <span className={css.menuItemBorder} />
              <FormattedMessage id="TopbarDesktop.marketing" />
              <span className={css['login-menu-small']}>Demand generation that gives back</span>
            </div>
          </ExternalLink>
        </MenuItem>

        <MenuItem key="solution-experiences">
          <ExternalLink
              className={css.yourListingsLink}
              href={`${config.custom.companySiteUrl}/experiences`}
            >
            <div class={css['login-menu-stack']}>
              <span className={css.menuItemBorder} />
              <span>Responsible Experiences</span>
              <span className={css['login-menu-small']}>Client events with more meaning</span>
            </div>
          </ExternalLink>
        </MenuItem>

      </MenuContent>
    </Menu>
  );

  const loginMenu = isAuthenticatedOrJustHydrated ? null : (
    <Menu className={css.loginMenu}>
      <MenuLabel className={css.profileMenuLabel} isOpenClassName={css.profileMenuIsOpen}>
        <span
          style={{
            fontWeight: 500,
            fontSize: 16,
            lineheight: 24,
          }}
        >
          <FormattedMessage id="TopbarDesktop.login" />
        </span>
      </MenuLabel>
      <MenuContent>
        <MenuItem key="loginNew">
          <a href={`${PLATFORM_URL}/log-in`} className={css.yourListingsLink}>
            <div class={css['login-menu-stack']}>
              <span className={css.menuItemBorder} />

              <span>Givsly Platform</span>
              <span className={css['login-menu-small']}>New</span>
            </div>
          </a>
        </MenuItem>
        <MenuItem key="loginOld">
          <NamedLink
            name="LoginPage"
            className={classNames(css.yourListingsLink, currentPageClass('LoginPage'))}
          >
            <div class={css['login-menu-stack']}>
              <span className={css.menuItemBorder} />
              <span>Givsly Platform</span>
              <span className={css['login-menu-small']}>Legacy & donation processing</span>
            </div>
          </NamedLink>
        </MenuItem>
      </MenuContent>
    </Menu>
  );

  return (
    <nav className={classes}>
      <NamedLink className={css.logoLink} name="LandingPage">
        <Logo
          format="desktop"
          className={css.logo}
          alt={intl.formatMessage({ id: 'TopbarDesktop.logo' })}
        />
      </NamedLink>


      {validateSubscription(currentUser) ? search : <span style={{ flex: 1 }} />}
      {menuSolutions}

      <NamedLink className={css.link} name="NonprofitsLandingPage">
        <FormattedMessage id="TopbarDesktop.nonprofits" />
      </NamedLink>
      {onboardingCompleted && !isNPO ? donationsLink : null}
      {profileMenu}
      {loginMenu}
      {requestDemoLink}
    </nav>
  );
};

const { bool, func, object, number, string } = PropTypes;

TopbarDesktop.defaultProps = {
  rootClassName: null,
  className: null,
  currentUser: null,
  currentPage: null,
  notificationCount: 0,
  initialSearchFormValues: {},
  currentMarket: null,
  searchString: null,
  searchResults: {},
};

TopbarDesktop.propTypes = {
  rootClassName: string,
  className: string,
  currentUserHasListings: bool.isRequired,
  currentUser: propTypes.currentUser,
  currentPage: string,
  history: shape({
    push: func.isRequired,
  }).isRequired,
  isAuthenticated: bool.isRequired,
  onLogout: func.isRequired,
  notificationCount: number,
  onSearchSubmit: func.isRequired,
  initialSearchFormValues: object,
  currentMarket: string,
  searchString: string,
  searchResults: object,
  intl: intlShape.isRequired,
};

export default TopbarDesktop;
