import React, { FC } from 'react';
import css from './NewNonprofitImpactCard.css';

const ImpactPictureFallback: FC = () => (
  <svg
    className={css.picture}
    xmlns="http://www.w3.org/2000/svg"
    width="144"
    height="144"
    viewBox="0 0 80 80"
  >
    <g fill="none" fillRule="evenodd">
      <g>
        <g>
          <g>
            <g>
              <g>
                <g transform="translate(-904 -1892) translate(407 44) translate(85 1592) translate(0 56) translate(0 200) translate(412)">
                  <mask id="jgoa9j5lob" fill="#fff">
                    <circle id="vgmsrckp9a" cx="40" cy="40" r="40" />
                  </mask>
                  <circle fill="#1C1A34" id="vgmsrckp9a" cx="40" cy="40" r="40" />
                  <path
                    fill="#FAC51D"
                    d="M53.199 43.798l3.78 3.77-17.146 17.099-17.145-17.1 3.78-3.77 13.365 13.33L53.2 43.797zm-18.85-9.456c3.132-3.123 8.21-3.123 11.341 0 3.132 3.124 3.132 8.187 0 11.31-3.132 3.124-8.21 3.124-11.341 0-3.132-3.123-3.132-8.186 0-11.31zm6.051-8.7c5.316-5.3 13.965-5.3 19.28 0 5.316 5.301 5.316 13.927 0 19.228L55.9 41.1c3.23-3.223 3.23-8.465 0-11.688-3.231-3.222-8.488-3.222-11.72 0zm-20.414 0c5.316-5.3 13.965-5.3 19.28 0l-3.78 3.77c-3.231-3.222-8.488-3.222-11.72 0-3.23 3.223-3.23 8.465 0 11.688l-3.78 3.77c-5.315-5.301-5.315-13.927 0-19.228z"
                    mask="url(#jgoa9j5lob)"
                  />
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

interface iNewNonprofitImpactCard {
  imageUrl?: string;
  name: string;
  category: string;
  mission: string;
  impactCost: string;
  impactVerb: string;
  impactUnitCount: string;
  impactUnitNameSingle: string;
  impactUnitNamePlural: string;
  impactRecipient: string;
  impactPreposition: string;
}

const NewNonprofitImpactCard: FC<iNewNonprofitImpactCard> = ({
  imageUrl = '',
  name = '',
  category = '',
  mission = '',
  impactCost = '',
  impactVerb = '',
  impactUnitCount = '',
  impactUnitNameSingle = '',
  impactUnitNamePlural = '',
  impactRecipient = '',
  impactPreposition = '',
}) => {
  const impactPhrase = `${
    parseInt(impactUnitCount) > 1 ? impactUnitNamePlural : impactUnitNameSingle
  } ${impactPreposition} ${impactRecipient}`;

  return (
    <div className={css['nonprofit-impact-card']}>
      {imageUrl ? (
        <img
          src={imageUrl}
          alt="name"
          width={240}
          height={160}
          style={{ minWidth: 240, objectFit: 'cover' }}
        />
      ) : (
        <div className={css['impact-picture-fallback-container']}>
          <ImpactPictureFallback />
        </div>
      )}
      <div className={css['impact-card-content-col']}>
        <h3 className={css['impact-card-name']}>{name}</h3>
        <p className={css['impact-card-category']}>{category}</p>
        <p className={css['impact-card-mission']}>{mission}</p>
      </div>

      <div className={css['impact-card-impact-col']}>
        <div className={css['impact-cost']}>$ {impactCost}</div>
        <div className={css['impact-verb']}>{impactVerb}</div>
        <div className={css['impact-unit-count']}>{impactUnitCount}</div>
        <div className={css['impact-phrase']}>{impactPhrase}</div>
      </div>
    </div>
  );
};

export default NewNonprofitImpactCard;
