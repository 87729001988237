import React, { Component } from 'react';
import { withMessages } from '../../util/localization';
import { propTypes } from '../../util/types';
import { array, bool, func, object, oneOf, shape, string } from 'prop-types';
import { Button, NonprofitInfoPanel, PanelHeader } from '../index';
import { intlShape } from '../../util/reactIntl';
import { ensureOwnListing } from '../../util/data';
import NonprofitImpactPanel from '../NonprofitImpactPanel/NonprofitImpactPanel';
import NonprofitPhotosPanel from '../NonprofitPhotosPanel/NonprofitPhotosPanel';
import NonprofitPreferencesPanel from '../NonprofitPreferencesPanel/NonprofitPreferencesPanel';
import { STRIPE_ONBOARDING_RETURN_URL_TYPES } from '../../containers/NonprofitOnboardingPage/constants';
import PayoutDetailsPanel from '../PayoutDetailsPanel/PayoutDetailsPanel';
import { IMPACT, NONPROFIT_INFO, NONPROFIT_PHOTOS, PAYOUT_DETAILS, PREFERENCES } from './constants';
import css from './MyNonprofitWizard.css';
import { createResourceLocatorString } from '../../util/routes';
import routeConfiguration from '../../routeConfiguration';
import { createSlug } from '../../util/urlHelpers';

class MyNonprofitWizard extends Component {
  constructor(props) {
    super(props);
    this.onSubmit = this.onSubmit.bind(this);
    this.redirectToProfile = this.redirectToProfile.bind(this);
  }

  redirectToProfile = () => {
    const { currentUserListing, history } = this.props;
    const listing = ensureOwnListing(currentUserListing);

    const to = createResourceLocatorString(
      'ListingPage',
      routeConfiguration(),
      { id: listing.id.uuid, slug: createSlug(listing.attributes.title) },
      {}
    );

    history.push(to);
  };

  onSubmit = (updatedProfileValues, updatedListingValues, activeTab) => {
    const { currentUserListing, onPublishListing, onUpdateListing, onUpdateProfile } = this.props;
    const listing = ensureOwnListing(currentUserListing);
    const promises = [];

    if (updatedProfileValues) {
      promises.push(
        onUpdateProfile({
          ...updatedProfileValues,
        })
      );
    }
    if (updatedListingValues) {
      promises.push(
        onUpdateListing({
          ...updatedListingValues,
          id: listing.id.uuid,
        })
      );
    }

    // Publish the listing if this was not done for some case
    // @todo Replace with 'draft' constant once @givsly/sharetribe-utils is added
    if (listing.attributes.state === 'draft') {
      promises.push(onPublishListing(listing.id.uuid));
    }

    return Promise.all(promises);
  };

  render() {
    const {
      createStripeAccountError,
      currentUser,
      currentUserListing,
      getAccountLinkInProgress,
      fetchStripeAccountError,
      getMessage,
      images,
      intl,
      onGetStripeConnectAccountLink,
      onListingImageUpload,
      onPayoutDetailsFormChange,
      onPayoutDetailsFormSubmit,
      onProfileImageUpload,
      onRemoveListingImage,
      onUpdateImageOrder,
      params,
      payoutDetailsSaved,
      payoutDetailsSaveInProgress,
      profileImage,
      stripeAccountFetched,
      stripeAccount,
      uploadProfileImageError,
      uploadProfileImageInProgress,
      updateProfileInProgress,
      updateProfileError,
      updateStripeAccountError,
      onUpdateListing,
      onUploadS3Image,
    } = this.props;
    const { tab: activeTab } = params;
    const nonprofit = ensureOwnListing(currentUserListing);
    let tabContent;
    switch (activeTab) {
      case NONPROFIT_INFO:
        tabContent = (
          <>
            <PanelHeader
              header={
                <span className={css.innerHeader}>
                  {getMessage('nonprofitInfoHeading')}
                  <Button className={css.viewProfileButton} onClick={this.redirectToProfile}>
                    {getMessage('viewNonprofitPage')}
                  </Button>
                </span>
              }
              subHeader={getMessage('nonprofitInfoSubHeading')}
            />
            <NonprofitInfoPanel
              currentUser={currentUser}
              currentUserListing={currentUserListing}
              handleSubmit={this.onSubmit}
              intl={intl}
              onImageUpload={onProfileImageUpload}
              image={profileImage}
              uploadProfileImageError={uploadProfileImageError}
              uploadProfileImageInProgress={uploadProfileImageInProgress}
              updateProfileInProgress={updateProfileInProgress}
              updateProfileError={updateProfileError}
            />
          </>
        );
        break;

      case NONPROFIT_PHOTOS:
        tabContent = (
          <>
            <PanelHeader header={getMessage('nonprofitPhotosHeading')} />
            <NonprofitPhotosPanel
              currentUser={currentUser}
              currentUserListing={currentUserListing}
              handleSubmit={this.onSubmit}
              images={images}
              intl={intl}
              nonprofit={nonprofit}
              onImageUpload={onListingImageUpload}
              onRemoveImage={onRemoveListingImage}
              onSubmit={() => {}}
              onUpdateImageOrder={onUpdateImageOrder}
              panelUpdated={false}
              updateInProgress={false}
            />
          </>
        );
        break;

      case IMPACT:
        tabContent = (
          <>
            <PanelHeader
              header={getMessage('impact.title')}
              subHeader={getMessage('impact.description')}
            />
            <NonprofitImpactPanel
              images={images}
              nonprofit={nonprofit}
              onImageUpload={onListingImageUpload}
              onRemoveImage={onRemoveListingImage}
              onSubmit={this.onSubmit}
              onUpdateImageOrder={onUpdateImageOrder}
              currentUser={currentUser}
              currentUserListing={currentUserListing}
            />
          </>
        );
        break;

      case PREFERENCES:
        tabContent = (
          <>
            <PanelHeader
              header={getMessage('preferences.title')}
              subHeader={getMessage('preferences.description')}
            />
            <NonprofitPreferencesPanel
              currentUserListing={currentUserListing}
              onUpdateListing={onUpdateListing}
              updateProfileInProgress={updateProfileInProgress}
              onUploadS3Image={onUploadS3Image}
              currentUser={currentUser}
            />
          </>
        );
        break;

      case PAYOUT_DETAILS:
        tabContent = (
          <>
            <PanelHeader
              header={getMessage('payoutDetailsHeading')}
              subHeader={getMessage('payoutDetailsSubHeading')}
            />
            <PayoutDetailsPanel
              createStripeAccountError={createStripeAccountError}
              currentUser={currentUser}
              currentUserListing={currentUserListing}
              fetchStripeAccountError={fetchStripeAccountError}
              getAccountLinkInProgress={getAccountLinkInProgress}
              handlePublish={() => {}}
              intl={intl}
              onGetStripeConnectAccountLink={onGetStripeConnectAccountLink}
              onPayoutDetailsFormChange={onPayoutDetailsFormChange}
              onPayoutDetailsFormSubmit={onPayoutDetailsFormSubmit}
              pageName="MyNonprofitPage"
              params={params}
              payoutDetailsSaved={payoutDetailsSaved}
              payoutDetailsSaveInProgress={payoutDetailsSaveInProgress}
              stripeAccountFetched={stripeAccountFetched}
              stripeAccount={stripeAccount}
              updateStripeAccountError={updateStripeAccountError}
            />
          </>
        );
        break;
      default:
        tabContent = null;
    }
    return tabContent;
  }
}

MyNonprofitWizard.propTypes = {
  createStripeAccountError: propTypes.error,
  currentUser: propTypes.currentUser.isRequired,
  currentUserListing: propTypes.ownListing.isRequired,
  fetchStripeAccountError: propTypes.error,
  getAccountLinkInProgress: bool.isRequired,
  history: shape({
    push: func.isRequired,
  }).isRequired,
  images: array.isRequired,
  intl: intlShape.isRequired,
  params: shape({
    returnURLType: oneOf(STRIPE_ONBOARDING_RETURN_URL_TYPES),
    tab: string.isRequired,
  }).isRequired,
  onGetStripeConnectAccountLink: func.isRequired,
  onListingImageUpload: func.isRequired,
  onPayoutDetailsFormChange: func.isRequired,
  onPayoutDetailsFormSubmit: func.isRequired,
  onProfileImageUpload: func.isRequired,
  onPublishListing: func.isRequired,
  onUpdateListing: func.isRequired,
  onUpdateProfile: func.isRequired,
  profileImage: object,
  payoutDetailsSaved: bool.isRequired,
  payoutDetailsSaveInProgress: bool.isRequired,
  stripeAccount: propTypes.stripeAccount,
  updateListingError: propTypes.error,
  updateListingInProgress: bool,
  updateProfileError: propTypes.error,
  uploadProfileImageError: propTypes.error,
  uploadProfileImageInProgress: bool,
  updateProfileInProgress: bool,
  updateStripeAccountError: propTypes.error,
};

MyNonprofitWizard.defaultProps = {
  createStripeAccountError: null,
  currentUserListing: {},
  fetchStripeAccountError: null,
  params: {
    returnURLType: null,
  },
  stripeAccount: null,
  updateListingError: null,
  updateListingInProgress: false,
  updateProfileError: null,
  uploadProfileImageError: null,
  uploadProfileImageInProgress: false,
  updateProfileInProgress: false,
  updateStripeAccountError: null,
};

export default withMessages(MyNonprofitWizard, 'MyNonprofitWizard');
