import React from 'react';
import classNames from 'classnames';
import { FieldSelect, FieldTextInput, Form, ModalButtonContainer } from '../../components';
import css from './TimeSlotForm.css';
import { FormattedMessage } from 'react-intl';
import { array, bool, func, object, string } from 'prop-types';
import { Form as FinalForm } from 'react-final-form';
import arrayMutators from 'final-form-arrays';
import { propTypes } from '../../util/types';
import moment from 'moment';
import {
  calculateNthWeekdayOfMonth,
  RECURS_EVERY_TWO_WEEKS,
  RECURS_NEVER,
  RECURS_MONTHLY,
  RECURS_WEEKLY,
} from '../../util/dates';
import FieldDateTimeDurationSelector from './FieldDateTimeDurationSelector';
import FieldMeetingType from './FieldMeetingType';
import { intlShape } from '../../util/reactIntl';

class TimeSlotFormRegular extends React.Component {
  get originalDate() {
    return this.props.originalDate || moment().tz(this.props.timezone);
  }

  get showWeeklyLimitExceededContent() {
    return (
      !this.props.inEditMode &&
      this.props.selectedDate &&
      this.props.exceedsWeeklyLimit(this.props.selectedDate, 15)
    );
  }

  onRecurrenceChange = (value) => {
    if (!this.props.trackEvent) return;

    this.props.trackEvent({
      category: 'Availability',
      action: 'Select',
      label: 'TimeSlotRepeats',
      value,
    });
  };

  getRecurrenceOptions(selectedRecurrence, selectedDate, timeSlotId) {
    const { intl } = this.props;
    const selectedWeekday = selectedDate ? selectedDate.format('dddd') : null;
    return [
      {
        key: RECURS_NEVER,
        value: RECURS_NEVER,
        disabled: !!selectedRecurrence && !!timeSlotId,
        label: intl.formatMessage({ id: 'TimeSlotForm.recurrence.none' }),
      },
      {
        key: RECURS_WEEKLY,
        value: RECURS_WEEKLY,
        label: intl.formatMessage(
          { id: 'TimeSlotForm.recurrence.weekly' },
          { weekday: selectedWeekday }
        ),
      },
      {
        key: RECURS_EVERY_TWO_WEEKS,
        value: RECURS_EVERY_TWO_WEEKS,
        label: intl.formatMessage(
          { id: 'TimeSlotForm.recurrence.biWeekly' },
          { weekday: selectedWeekday }
        ),
      },
      {
        key: RECURS_MONTHLY,
        value: RECURS_MONTHLY,
        label: intl.formatMessage(
          { id: 'TimeSlotForm.recurrence.monthly' },
          {
            weekday: selectedWeekday,
            nth: selectedDate && calculateNthWeekdayOfMonth(selectedDate, true),
          }
        ),
      },
    ];
  }

  render() {
    const {
      className,
      timezone,
      onClose,
      originalDuration,
      exceedsWeeklyLimit,
      inEditMode,
      reservableSlots,
    } = this.props;

    if (this.showWeeklyLimitExceededContent) {
      return (
        <div>
          <div className={classNames(css.formText, css.formContainer)}>
            <span className={css.formTextHeader}>
              <FormattedMessage id="TimeSlotForm.weeklyLimitReached.heading" />
            </span>
            <p>
              <FormattedMessage id="TimeSlotForm.weeklyLimitReached.text" />
            </p>
            <p>
              <FormattedMessage id="TimeSlotForm.weeklyLimitReached.subText" />
            </p>
          </div>
          <ModalButtonContainer
            primaryButton={{
              type: 'button',
              onClick: onClose,
              text: <FormattedMessage id="TimeSlotForm.weeklyLimitReached.ok" />,
            }}
            secondaryButton={{
              onClick: onClose,
              text: <FormattedMessage id="TimeSlotForm.weeklyLimitReached.cancel" />,
            }}
          />
        </div>
      );
    }

    return (
      <FinalForm
        {...this.props}
        mutators={{
          ...arrayMutators,
          touch: ([name], state, { changeValue }) => changeValue(state, name, (value) => value),
          setValue: ([name, value], state, { changeValue }) =>
            changeValue(state, name, () => value),
        }}
        render={(fieldRenderProps) => {
          const {
            form,
            handleSubmit,
            intl,
            invalid,
            onFetchTimeSlots,
            pristine,
            pristineSinceLastSubmit,
            updateInProgress,
            values,
          } = fieldRenderProps;

          const selectedDate = values.bookingDate.date
            ? moment.tz(values.bookingDate.date, timezone)
            : null;
          const submitDisabled =
            invalid || updateInProgress || pristine || pristineSinceLastSubmit || !selectedDate;

          const editRecurringTimeslot = values.id && values.recurrence !== RECURS_NEVER;

          return (
            <Form
              onSubmit={(e) => {
                this.submittedValues = values;

                handleSubmit(e);
              }}
            >
              <div className={className}>
                <div className={css.formContainer}>
                  <div>
                    <FieldTextInput id="id" name="id" type="hidden" />

                    <FieldDateTimeDurationSelector
                      bookingDate={values.bookingDate}
                      bookingTime={values.bookingTime}
                      duration={values.duration}
                      exceedsWeeklyLimit={exceedsWeeklyLimit}
                      form={form}
                      inEditMode={inEditMode}
                      intl={intl}
                      name="booking"
                      onFetchTimeSlots={onFetchTimeSlots}
                      originalDate={this.originalDate}
                      originalDuration={originalDuration}
                      reservableSlots={reservableSlots}
                      timeSlotId={values.id}
                      timezone={timezone}
                    />
                  </div>
                  <div className={css.formField}>
                    {values.recurrence !== RECURS_NEVER && values.id && (
                      <FieldTextInput
                        id="updateSiblingsInFuture"
                        name="updateSiblingsInFuture"
                        type="hidden"
                      />
                    )}

                    {!!values.updateSiblingsInFuture && (
                      <FieldTextInput id="recurrenceParent" name="recurrenceParent" type="hidden" />
                    )}

                    <FieldSelect
                      id="recurrence"
                      label={intl.formatMessage({ id: 'TimeSlotForm.recurrence' })}
                      name="recurrence"
                      onChange={this.onRecurrenceChange}
                      disabled={!selectedDate}
                      options={this.getRecurrenceOptions(
                        values.recurrence,
                        selectedDate,
                        values.id
                      )}
                    />
                  </div>

                  <div className={css.formField}>
                    <FieldMeetingType intl={intl} name="meetingType" id="meetingType" />
                  </div>
                </div>

                <ModalButtonContainer
                  className={css.modalButtonContainer}
                  secondaryButton={
                    editRecurringTimeslot
                      ? {
                          className: css.secondaryButton,
                          inProgress: updateInProgress,
                          disabled: submitDisabled,
                          ready: pristineSinceLastSubmit,
                          onClick: () => {
                            form.mutators.setValue('updateSiblingsInFuture', 1);
                            form.submit();
                          },
                          text: <FormattedMessage id="TimeSlotForm.save.thisAndFuture" />,
                        }
                      : null
                  }
                  primaryButton={{
                    className: css.primaryButton,
                    inProgress: updateInProgress,
                    disabled: submitDisabled,
                    ready: pristineSinceLastSubmit,
                    text: (
                      <FormattedMessage
                        id={
                          editRecurringTimeslot ? 'TimeSlotForm.save.thisOnly' : 'TimeSlotForm.save'
                        }
                      />
                    ),
                  }}
                />
              </div>
            </Form>
          );
        }}
      />
    );
  }
}

TimeSlotFormRegular.propTypes = {
  className: string,
  initialValues: object,
  exceedsWeeklyLimit: func.isRequired,
  inEditMode: bool.isRequired,
  intl: intlShape.isRequired,
  isVisible: bool,
  onClose: func.isRequired,
  onFetchTimeSlots: func.isRequired,
  onSubmit: func.isRequired,
  originalDate: object,
  originalDuration: string.isRequired,
  reservableSlots: array,
  selectedDate: object,
  timezone: string.isRequired,
  updateError: propTypes.error,
  updateInProgress: bool,
  trackEvent: func,
};

TimeSlotFormRegular.defaultProps = {
  className: null,
  isVisible: false,
  reservableSlots: [],
  updateError: null,
  updateInProgress: false,
};

export default TimeSlotFormRegular;
