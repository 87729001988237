import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './IconLock.css';

const IconLock = (props) => {
  const { rootClassName, className } = props;
  const classes = classNames(rootClassName || css.root, className);
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      className={classes}
    >
      <path
        fillRule="evenodd"
        d="M9 0c1.657 0 3 1.343 3 3v3c1.105 0 2 .895 2 2v6c0 1.105-.895 2-2 2H4c-1.105 0-2-.895-2-2V8c0-1.105.895-2 2-2V3c0-1.657 1.343-3 3-3h2zm0 1.5H7c-.78 0-1.42.595-1.493 1.356L5.5 3v3h5V3c0-.78-.595-1.42-1.356-1.493L9 1.5z"
      />
    </svg>
  );
};

IconLock.defaultProps = {
  rootClassName: null,
  className: null,
};

const { string } = PropTypes;

IconLock.propTypes = {
  rootClassName: string,
  className: string,
};

export default IconLock;
