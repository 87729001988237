import React, { Component } from 'react';
import css from '../NonprofitProfile.css';
import { array, func, shape, string, object, bool, oneOf } from 'prop-types';
import classNames from 'classnames';
import MainPanel from '../../../SearchPage/MainPanel';
import {
  LISTING_PAGE_DRAFT_VARIANT,
  LISTING_PAGE_PENDING_APPROVAL_VARIANT,
  parse,
  stringify,
} from '../../../../util/urlHelpers';
import {
  pickSearchParamsOnly,
  validURLParamsForExtendedData,
} from '../../../SearchPage/SearchPage.helpers';
import { propTypes } from '../../../../util/types';
import config from '../../../../config';
import {
  FILTER_AVAILABILITY,
  FILTER_INDUSTRY,
  FILTER_INTERESTS,
  FILTER_LOCATION,
} from '../../../../components/SearchFilters/SearchFilters';

const MODAL_BREAKPOINT = 768;

class NonProfitTabBoardMembers extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isMobileModalOpen: false,
    };

    this.onOpenMobileModal = this.onOpenMobileModal.bind(this);
    this.onCloseMobileModal = this.onCloseMobileModal.bind(this);
  }

  get filters() {
    const { industries, interests } = this.props;

    industries.sort();
    interests.sort((a, b) => (a.label > b.label ? 1 : -1));

    return {
      industryFilter: {
        paramName: 'pub_industry',
        options: industries.map((i) => ({ key: i, label: i })),
      },
      interestsFilter: {
        paramName: 'pub_interests',
        options: interests,
      },
    };
  }

  onOpenMobileModal() {
    this.setState({ isMobileModalOpen: true });
  }

  onCloseMobileModal() {
    this.setState({ isMobileModalOpen: false });
  }

  render() {
    const {
      boardMembers,
      fetchBoardMemberTotalCountProgress,
      getEventRoleBadges,
      history,
      location,
      npoListingNames,
      onActivateListing,
      onManageDisableScrolling,
      pagination,
      searchInProgress,
      searchListingsError,
      searchParams,
      pageName,
      pathParams,
      timezone,
    } = this.props;

    const { page, ...searchInURL } = parse(location.search);

    const urlQueryParams = pickSearchParamsOnly(searchInURL, this.filters);
    const urlQueryString = stringify(urlQueryParams);

    const validQueryParams = validURLParamsForExtendedData(searchInURL, this.filters);
    const paramsQueryString = stringify(pickSearchParamsOnly(searchParams, this.filters));
    const searchParamsAreInSync = urlQueryString === paramsQueryString;

    return !fetchBoardMemberTotalCountProgress && !searchInProgress ? (
      <div className={classNames(css.mainContent, css.mainContentFullWidth)}>
        <MainPanel
          className={css.searchResultContainer}
          filtersClassName={css.searchFilters}
          getEventRoleBadges={getEventRoleBadges}
          history={history}
          urlQueryParams={validQueryParams}
          listings={boardMembers}
          resultsClassName={css.searchResults}
          searchInProgress={searchInProgress && fetchBoardMemberTotalCountProgress}
          searchListingsError={searchListingsError}
          searchParamsAreInSync={searchParamsAreInSync}
          onActivateListing={onActivateListing}
          onManageDisableScrolling={onManageDisableScrolling}
          onOpenModal={this.onOpenMobileModal}
          onCloseModal={this.onCloseMobileModal}
          pagination={pagination}
          searchParamsForPagination={parse(location.search)}
          showAsModalMaxWidth={MODAL_BREAKPOINT}
          showOnlySelectedFilters={[
            FILTER_LOCATION,
            FILTER_AVAILABILITY,
            FILTER_INTERESTS,
            FILTER_INDUSTRY,
          ]}
          primaryFilters={{
            industryFilter: this.filters.industryFilter,
            interestsFilter: this.filters.interestsFilter,
          }}
          npoListingNames={npoListingNames}
          pageName={pageName}
          pathParams={pathParams}
          timezone={timezone}
        />
      </div>
    ) : null;
  }
}

NonProfitTabBoardMembers.propTypes = {
  boardMembers: array,
  fetchBoardMemberTotalCountProgress: bool,
  history: shape({
    push: func.isRequired,
  }).isRequired,
  interests: array,
  industries: array,
  location: shape({
    search: string.isRequired,
  }).isRequired,
  npoListingNames: object,
  onActivateListing: func.isRequired,
  onManageDisableScrolling: func.isRequired,
  pageName: string,
  pagination: propTypes.pagination,
  pathParams: shape({
    id: string.isRequired,
    slug: string,
    variant: oneOf([LISTING_PAGE_DRAFT_VARIANT, LISTING_PAGE_PENDING_APPROVAL_VARIANT]),
  }).isRequired,
  searchInProgress: bool.isRequired,
  searchListingsError: propTypes.error,
  searchParams: object,
  timezone: string.isRequired,
};

NonProfitTabBoardMembers.defaultProps = {
  boardMembers: [],
  industries: config.custom.industries,
  interests: config.custom.interests,
  npoListingNames: {},
  pageName: 'NonprofitProfile',
  pagination: null,
  searchListingsError: null,
};

export default NonProfitTabBoardMembers;
