import React from 'react';
import { FormattedMessage } from '../../util/reactIntl';
import { ResponsiveImage, Modal, ImageCarousel } from '../../components';
import css from './SectionImages.css';
import { number, string } from 'prop-types';
import classNames from 'classnames';

const SectionImages = (props) => {
  const {
    title,
    listing,
    handleViewPhotosClick,
    imageCarouselOpen,
    imageLimit,
    onImageCarouselClose,
    onManageDisableScrolling,
  } = props;

  // Prep the images, filter out the impact image if any is set
  const { pictureId: impactPictureId = null } = listing.attributes.publicData.impact;
  const images = Array.isArray(listing.images)
    ? listing.images.filter((i) => i.id.uuid !== impactPictureId)
    : [];
  const imagesSlice = images.length ? images.slice(0, imageLimit) : [];

  const viewPhotosButton = images.length ? (
    <button className={css.viewPhotos} onClick={handleViewPhotosClick}>
      <FormattedMessage id="ListingPage.viewImagesButton" values={{ count: images.length }} />
    </button>
  ) : null;

  const aspectWrapperClassNames =
    imagesSlice.length > 2
      ? classNames(css.aspectWrapper, css.aspectWrapperJustified)
      : classNames(css.aspectWrapper, css.aspectWrapperSpaced);

  return (
    <div className={css.sectionImages}>
      <div className={css.threeToTwoWrapper}>
        <div className={aspectWrapperClassNames} onClick={handleViewPhotosClick}>
          {imagesSlice.map((image, index) => {
            return (
              <ResponsiveImage
                className={css.nonprofitImage}
                alt={title}
                image={image}
                key={`section-images-${index}`}
                variants={['square-small', 'square-small2x', 'square-small4x', 'square-small6x']}
              />
            );
          })}
          {viewPhotosButton}
        </div>
      </div>
      <Modal
        id="ListingPage.imageCarousel"
        scrollLayerClassName={css.carouselModalScrollLayer}
        containerClassName={css.carouselModalContainer}
        lightCloseButton
        isOpen={imageCarouselOpen}
        onClose={onImageCarouselClose}
        onManageDisableScrolling={onManageDisableScrolling}
      >
        <ImageCarousel images={listing.images} />
      </Modal>
    </div>
  );
};

SectionImages.propTypes = {
  imageLimit: number,
  title: string.isRequired,
};

SectionImages.defaultProps = {
  imageLimit: 3,
};

export default SectionImages;
