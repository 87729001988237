var PROCESS_NAME_PAYMENT = "payment-only";
var PROCESS_NAME_BOOKING = "no-payment-time-booking";
var PROCESS_NAME_NOTIFICATION = "notification";
var PROCESS_NAME_OUTREACH_PAYMENT = "outreach-payment-only"; // Line item types

var LINE_ITEM_DONATION = "line-item/donation";
var LINE_ITEM_OPERATOR_FEE = "line-item/operator-fee";
var LINE_ITEM_PAYMENT_FEE = "line-item/payment-fee";
var LINE_ITEM_GIVSLY_CREDIT = "line-item/givsly-credit"; // Unique transitions

var TRANSITION_ACCEPT_AFTER_BOOK_PROPOSAL = "transition/accept-after-book-proposal";
var TRANSITION_BOOK = "transition/book";
var TRANSITION_BOOK_AFTER_ENQUIRY = "transition/book-after-enquiry";
var TRANSITION_BOOK_AFTER_PROPOSAL = "transition/book-after-proposal";
var TRANSITION_CANCEL = "transition/cancel";
var TRANSITION_CANCEL_AFTER_CAPTURE = "transition/cancel-after-capture";
var TRANSITION_CANCEL_AFTER_CLOSE = "transition/cancel-after-close";
var TRANSITION_CANCEL_AFTER_DELIVERY = "transition/cancel-after-delivery";
var TRANSITION_CANCEL_BEFORE_CAPTURE = "transition/cancel-before-capture";
var TRANSITION_CONFIRM_PAYMENT = "transition/confirm-payment";
var TRANSITION_CREATE_NOTIFICATION = "transition/create-notification";
var TRANSITION_CUSTOMER_ARCHIVE_NEW = "transition/customer-archive-new";
var TRANSITION_CUSTOMER_ARCHIVE_SEEN = "transition/customer-archive-seen";
var TRANSITION_CUSTOMER_ARCHIVE_SEEN_SENT = "transition/customer-archive-seen-sent";
var TRANSITION_CUSTOMER_ARCHIVE_SENT = "transition/customer-archive-sent";
var TRANSITION_CUSTOMER_CANCEL = "transition/customer-cancel";
var TRANSITION_CUSTOMER_SEEN = "transition/customer-seen";
var TRANSITION_CUSTOMER_SEEN_SENT = "transition/customer-seen-sent";
var TRANSITION_CUSTOMER_UPDATE = "transition/customer-update";
var TRANSITION_DECLINE = "transition/decline";
var TRANSITION_DELIVER = "transition/deliver";
var TRANSITION_ENQUIRE = "transition/enquire";
var TRANSITION_EXPIRE = "transition/expire";
var TRANSITION_EXPIRE_PAYMENT = "transition/expire-payment";
var TRANSITION_OPERATOR_ARCHIVE_NEW = "transition/operator-archive-new";
var TRANSITION_OPERATOR_ARCHIVE_SEEN = "transition/operator-archive-seen";
var TRANSITION_OPERATOR_ARCHIVE_SEEN_SENT = "transition/operator-archive-seen-sent";
var TRANSITION_OPERATOR_ARCHIVE_SENT = "transition/operator-archive-sent";
var TRANSITION_OPERATOR_SEND_NEW = "transition/operator-send-new";
var TRANSITION_OPERATOR_SEND_SEEN = "transition/operator-send-seen";
var TRANSITION_OPERATOR_UPDATE_ARCHIVED = "transition/operator-update-archived";
var TRANSITION_OPERATOR_UPDATE_NEW = "transition/operator-update-new";
var TRANSITION_OPERATOR_UPDATE_SEEN = "transition/operator-update-seen";
var TRANSITION_OPERATOR_UPDATE_SEEN_SENT = "transition/operator-update-seen-sent";
var TRANSITION_OPERATOR_UPDATE_SENT = "transition/operator-update-sent";
var TRANSITION_PROPOSE = "transition/propose";
var TRANSITION_PROVIDER_ARCHIVE_NEW = "transition/provider-archive-new";
var TRANSITION_PROVIDER_ARCHIVE_SEEN = "transition/provider-archive-seen";
var TRANSITION_PROVIDER_ARCHIVE_SEEN_SENT = "transition/provider-archive-seen-sent";
var TRANSITION_PROVIDER_ARCHIVE_SENT = "transition/provider-archive-sent";
var TRANSITION_PROVIDER_CANCEL = "transition/provider-cancel";
var TRANSITION_PROVIDER_SEEN = "transition/provider-seen";
var TRANSITION_PROVIDER_SEEN_SENT = "transition/provider-seen-sent";
var TRANSITION_PROVIDER_UPDATE = "transition/provider-update";
var TRANSITION_REQUEST_PAYMENT = "transition/request-payment";
var TRANSITION_DECLINE_PROPOSAL = "transition/decline-proposal";
var TRANSITION_EXPIRE_PROPOSAL = "transition/expire-proposal";
var TRANSITION_EXPIRE_AUTOMATIC_WITH_PAYMENT = "transition/expire-automatic-with-payment";
var TRANSITION_EXPIRE_AUTOMATIC_WITHOUT_PAYMENT = "transition/expire-automatic-without-payment";
var TRANSITION_APPLY_FEES_DEDUCTIONS_WITH_PAYMENT = "transition/apply-fees-deductions-with-payment";
var TRANSITION_APPLY_FEES_DEDUCTIONS_WITHOUT_PAYMENT = "transition/apply-fees-deductions-without-payment";
var TRANSITION_EXPIRE_FEES_DEDUCTIONS_WITH_PAYMENT = "transition/expire-fees-deductions-with-payment";
var TRANSITION_EXPIRE_FEES_DEDUCTIONS_WITHOUT_PAYMENT = "transition/expire-fees-deductions-without-payment";
var TRANSITION_REQUIRE_PAYMENT = "transition/require-payment";
var TRANSITION_REQUIRE_CREDIT_TRANSFER = "transition/require-credit-transfer";
var TRANSITION_OMIT_PAYMENT = "transition/omit-payment";
var TRANSITION_ACCEPT_WITH_PAYMENT = "transition/accept-with-payment";
var TRANSITION_ACCEPT_WITHOUT_PAYMENT = "transition/accept-without-payment";
var TRANSITION_TRANSFER_CREDIT = "transition/transfer-credit";
var TRANSITION_CREATE_PAYMENT_INTENT = "transition/create-payment-intent";
var TRANSITION_DECLINE_WITH_PAYMENT = "transition/decline-with-payment";
var TRANSITION_DECLINE_WITHOUT_PAYMENT = "transition/decline-without-payment";
var TRANSITION_REFUND_CREDIT = "transition/refund-credit"; // Shared transitions (bookings and payments)

var TRANSITION_ACCEPT = "transition/accept";
var TRANSITION_CANCEL_AFTER_COMPLETION = "transition/cancel-after-completion";
var TRANSITION_CLOSE = "transition/close";
var TRANSITION_COMPLETE = "transition/complete"; // Transaction states (simplified)

var STATE_PENDING = "pending";
var STATE_ACCEPTED = "accepted";
var STATE_DECLINED = "declined";
var STATE_EXPIRED = "expired";
var STATE_COMPLETED = "completed";
var STATE_CANCELED = "canceled";
var STATE_PROPOSED = "proposed"; // Transition sets

var BOOKING_TRANSITIONS = [TRANSITION_ACCEPT, TRANSITION_ACCEPT_AFTER_BOOK_PROPOSAL, TRANSITION_BOOK, TRANSITION_BOOK_AFTER_ENQUIRY, TRANSITION_BOOK_AFTER_PROPOSAL, TRANSITION_CANCEL, TRANSITION_CANCEL_AFTER_COMPLETION, TRANSITION_CANCEL_AFTER_DELIVERY, TRANSITION_CLOSE, TRANSITION_COMPLETE, TRANSITION_CUSTOMER_CANCEL, TRANSITION_DECLINE, TRANSITION_DECLINE_PROPOSAL, TRANSITION_DELIVER, TRANSITION_ENQUIRE, TRANSITION_EXPIRE, TRANSITION_EXPIRE_PROPOSAL, TRANSITION_PROPOSE, TRANSITION_PROVIDER_CANCEL];
var NOTIFICATION_TRANSITIONS = [TRANSITION_CREATE_NOTIFICATION, TRANSITION_CUSTOMER_ARCHIVE_NEW, TRANSITION_CUSTOMER_ARCHIVE_SEEN, TRANSITION_CUSTOMER_ARCHIVE_SEEN_SENT, TRANSITION_CUSTOMER_ARCHIVE_SENT, TRANSITION_CUSTOMER_SEEN, TRANSITION_CUSTOMER_SEEN_SENT, TRANSITION_CUSTOMER_UPDATE, TRANSITION_OPERATOR_ARCHIVE_NEW, TRANSITION_OPERATOR_ARCHIVE_SEEN, TRANSITION_OPERATOR_ARCHIVE_SEEN_SENT, TRANSITION_OPERATOR_ARCHIVE_SENT, TRANSITION_OPERATOR_SEND_NEW, TRANSITION_OPERATOR_SEND_SEEN, TRANSITION_OPERATOR_UPDATE_ARCHIVED, TRANSITION_OPERATOR_UPDATE_NEW, TRANSITION_OPERATOR_UPDATE_SEEN, TRANSITION_OPERATOR_UPDATE_SEEN_SENT, TRANSITION_OPERATOR_UPDATE_SENT, TRANSITION_PROVIDER_ARCHIVE_NEW, TRANSITION_PROVIDER_ARCHIVE_SEEN, TRANSITION_PROVIDER_ARCHIVE_SEEN_SENT, TRANSITION_PROVIDER_ARCHIVE_SENT, TRANSITION_PROVIDER_SEEN, TRANSITION_PROVIDER_SEEN_SENT, TRANSITION_PROVIDER_UPDATE];
var PAYMENT_TRANSITIONS = [TRANSITION_ACCEPT, TRANSITION_CANCEL_AFTER_CAPTURE, TRANSITION_CANCEL_AFTER_COMPLETION, TRANSITION_CANCEL_BEFORE_CAPTURE, TRANSITION_CLOSE, TRANSITION_COMPLETE, TRANSITION_CONFIRM_PAYMENT, TRANSITION_EXPIRE_PAYMENT, TRANSITION_REQUEST_PAYMENT, TRANSITION_EXPIRE_AUTOMATIC_WITH_PAYMENT, TRANSITION_EXPIRE_AUTOMATIC_WITHOUT_PAYMENT, TRANSITION_APPLY_FEES_DEDUCTIONS_WITH_PAYMENT, TRANSITION_APPLY_FEES_DEDUCTIONS_WITHOUT_PAYMENT, TRANSITION_EXPIRE_FEES_DEDUCTIONS_WITH_PAYMENT, TRANSITION_EXPIRE_FEES_DEDUCTIONS_WITHOUT_PAYMENT, TRANSITION_REQUIRE_PAYMENT, TRANSITION_OMIT_PAYMENT, TRANSITION_TRANSFER_CREDIT, TRANSITION_ACCEPT_WITH_PAYMENT, TRANSITION_ACCEPT_WITHOUT_PAYMENT, TRANSITION_CREATE_PAYMENT_INTENT, TRANSITION_DECLINE_WITH_PAYMENT, TRANSITION_DECLINE_WITHOUT_PAYMENT, TRANSITION_REFUND_CREDIT];
var TRANSITIONS = [].concat(BOOKING_TRANSITIONS, NOTIFICATION_TRANSITIONS, PAYMENT_TRANSITIONS);
var PENDING_CREDIT_REFUND_TRANSITIONS = [TRANSITION_DECLINE_WITH_PAYMENT, TRANSITION_DECLINE_WITHOUT_PAYMENT, TRANSITION_EXPIRE_PAYMENT, TRANSITION_EXPIRE_AUTOMATIC_WITH_PAYMENT, TRANSITION_EXPIRE_AUTOMATIC_WITHOUT_PAYMENT]; // Transitions after which messaging is available

var MESSAGING_ENABLED_TRANSITIONS = [TRANSITION_ACCEPT, TRANSITION_ACCEPT_AFTER_BOOK_PROPOSAL, TRANSITION_CANCEL, TRANSITION_CANCEL_AFTER_COMPLETION, TRANSITION_CANCEL_AFTER_DELIVERY, TRANSITION_CLOSE, TRANSITION_COMPLETE, TRANSITION_CUSTOMER_CANCEL, TRANSITION_DECLINE, TRANSITION_DECLINE_PROPOSAL, TRANSITION_DELIVER, TRANSITION_EXPIRE, TRANSITION_PROVIDER_CANCEL];
/**
 * Inbox states
 */
// All transitions which should be displayed as accepted

var ACCEPTED_TRANSITIONS = [TRANSITION_ACCEPT, TRANSITION_ACCEPT_AFTER_BOOK_PROPOSAL]; // All transitions which should be displayed as declined

var DECLINED_TRANSITIONS = [TRANSITION_DECLINE, TRANSITION_DECLINE_PROPOSAL]; // All transitions which should be displayed as expired

var EXPIRED_TRANSITIONS = [TRANSITION_EXPIRE, TRANSITION_EXPIRE_PROPOSAL]; // All transitions which should be displayed as cancelled

var CANCELED_TRANSITIONS = [TRANSITION_CANCEL, TRANSITION_CANCEL_AFTER_COMPLETION, TRANSITION_CANCEL_AFTER_DELIVERY, TRANSITION_CUSTOMER_CANCEL, TRANSITION_PROVIDER_CANCEL]; // All transitions which should be displayed as completed

var COMPLETED_TRANSITIONS = [TRANSITION_DELIVER, TRANSITION_COMPLETE, TRANSITION_CLOSE];
var PENDING_TRANSITIONS = [TRANSITION_BOOK, TRANSITION_BOOK_AFTER_ENQUIRY, TRANSITION_BOOK_AFTER_PROPOSAL]; // Scrubbed transitions, eg. transactions that were declined, cancelled or expired. This should include legacy
// transitions

var SCRUBBED_TRANSITIONS = [TRANSITION_CANCEL, TRANSITION_CANCEL_AFTER_COMPLETION, TRANSITION_CANCEL_AFTER_COMPLETION, TRANSITION_CANCEL_AFTER_CAPTURE, TRANSITION_CANCEL_AFTER_DELIVERY, TRANSITION_CUSTOMER_CANCEL, TRANSITION_PROVIDER_CANCEL, TRANSITION_DECLINE, TRANSITION_DECLINE_PROPOSAL, TRANSITION_DECLINE_WITH_PAYMENT, TRANSITION_DECLINE_WITHOUT_PAYMENT, TRANSITION_EXPIRE, TRANSITION_EXPIRE_PROPOSAL, TRANSITION_EXPIRE_AUTOMATIC_WITH_PAYMENT, TRANSITION_EXPIRE_AUTOMATIC_WITHOUT_PAYMENT, TRANSITION_EXPIRE_FEES_DEDUCTIONS_WITH_PAYMENT, TRANSITION_EXPIRE_FEES_DEDUCTIONS_WITHOUT_PAYMENT, TRANSITION_EXPIRE_PAYMENT];
var PROPOSED_TRANSITIONS = [TRANSITION_PROPOSE];
module.exports = {
  PROCESS_NAME_PAYMENT: PROCESS_NAME_PAYMENT,
  PROCESS_NAME_BOOKING: PROCESS_NAME_BOOKING,
  PROCESS_NAME_NOTIFICATION: PROCESS_NAME_NOTIFICATION,
  PROCESS_NAME_OUTREACH_PAYMENT: PROCESS_NAME_OUTREACH_PAYMENT,
  TRANSITION_ACCEPT_AFTER_BOOK_PROPOSAL: TRANSITION_ACCEPT_AFTER_BOOK_PROPOSAL,
  TRANSITION_BOOK: TRANSITION_BOOK,
  TRANSITION_BOOK_AFTER_ENQUIRY: TRANSITION_BOOK_AFTER_ENQUIRY,
  TRANSITION_BOOK_AFTER_PROPOSAL: TRANSITION_BOOK_AFTER_PROPOSAL,
  TRANSITION_CANCEL: TRANSITION_CANCEL,
  TRANSITION_CANCEL_AFTER_CAPTURE: TRANSITION_CANCEL_AFTER_CAPTURE,
  TRANSITION_CANCEL_AFTER_CLOSE: TRANSITION_CANCEL_AFTER_CLOSE,
  TRANSITION_CANCEL_AFTER_DELIVERY: TRANSITION_CANCEL_AFTER_DELIVERY,
  TRANSITION_CANCEL_BEFORE_CAPTURE: TRANSITION_CANCEL_BEFORE_CAPTURE,
  TRANSITION_CONFIRM_PAYMENT: TRANSITION_CONFIRM_PAYMENT,
  TRANSITION_CREATE_NOTIFICATION: TRANSITION_CREATE_NOTIFICATION,
  TRANSITION_CUSTOMER_ARCHIVE_NEW: TRANSITION_CUSTOMER_ARCHIVE_NEW,
  TRANSITION_CUSTOMER_ARCHIVE_SEEN: TRANSITION_CUSTOMER_ARCHIVE_SEEN,
  TRANSITION_CUSTOMER_ARCHIVE_SEEN_SENT: TRANSITION_CUSTOMER_ARCHIVE_SEEN_SENT,
  TRANSITION_CUSTOMER_ARCHIVE_SENT: TRANSITION_CUSTOMER_ARCHIVE_SENT,
  TRANSITION_CUSTOMER_CANCEL: TRANSITION_CUSTOMER_CANCEL,
  TRANSITION_CUSTOMER_SEEN: TRANSITION_CUSTOMER_SEEN,
  TRANSITION_CUSTOMER_SEEN_SENT: TRANSITION_CUSTOMER_SEEN_SENT,
  TRANSITION_CUSTOMER_UPDATE: TRANSITION_CUSTOMER_UPDATE,
  TRANSITION_DECLINE: TRANSITION_DECLINE,
  TRANSITION_DECLINE_PROPOSAL: TRANSITION_DECLINE_PROPOSAL,
  TRANSITION_DELIVER: TRANSITION_DELIVER,
  TRANSITION_ENQUIRE: TRANSITION_ENQUIRE,
  TRANSITION_EXPIRE: TRANSITION_EXPIRE,
  TRANSITION_EXPIRE_PROPOSAL: TRANSITION_EXPIRE_PROPOSAL,
  TRANSITION_EXPIRE_PAYMENT: TRANSITION_EXPIRE_PAYMENT,
  TRANSITION_OPERATOR_ARCHIVE_NEW: TRANSITION_OPERATOR_ARCHIVE_NEW,
  TRANSITION_OPERATOR_ARCHIVE_SEEN: TRANSITION_OPERATOR_ARCHIVE_SEEN,
  TRANSITION_OPERATOR_ARCHIVE_SEEN_SENT: TRANSITION_OPERATOR_ARCHIVE_SEEN_SENT,
  TRANSITION_OPERATOR_ARCHIVE_SENT: TRANSITION_OPERATOR_ARCHIVE_SENT,
  TRANSITION_OPERATOR_SEND_NEW: TRANSITION_OPERATOR_SEND_NEW,
  TRANSITION_OPERATOR_SEND_SEEN: TRANSITION_OPERATOR_SEND_SEEN,
  TRANSITION_OPERATOR_UPDATE_ARCHIVED: TRANSITION_OPERATOR_UPDATE_ARCHIVED,
  TRANSITION_OPERATOR_UPDATE_NEW: TRANSITION_OPERATOR_UPDATE_NEW,
  TRANSITION_OPERATOR_UPDATE_SEEN: TRANSITION_OPERATOR_UPDATE_SEEN,
  TRANSITION_OPERATOR_UPDATE_SEEN_SENT: TRANSITION_OPERATOR_UPDATE_SEEN_SENT,
  TRANSITION_OPERATOR_UPDATE_SENT: TRANSITION_OPERATOR_UPDATE_SENT,
  TRANSITION_PROPOSE: TRANSITION_PROPOSE,
  TRANSITION_PROVIDER_ARCHIVE_NEW: TRANSITION_PROVIDER_ARCHIVE_NEW,
  TRANSITION_PROVIDER_ARCHIVE_SEEN: TRANSITION_PROVIDER_ARCHIVE_SEEN,
  TRANSITION_PROVIDER_ARCHIVE_SEEN_SENT: TRANSITION_PROVIDER_ARCHIVE_SEEN_SENT,
  TRANSITION_PROVIDER_ARCHIVE_SENT: TRANSITION_PROVIDER_ARCHIVE_SENT,
  TRANSITION_PROVIDER_CANCEL: TRANSITION_PROVIDER_CANCEL,
  TRANSITION_PROVIDER_SEEN: TRANSITION_PROVIDER_SEEN,
  TRANSITION_PROVIDER_SEEN_SENT: TRANSITION_PROVIDER_SEEN_SENT,
  TRANSITION_PROVIDER_UPDATE: TRANSITION_PROVIDER_UPDATE,
  TRANSITION_REQUEST_PAYMENT: TRANSITION_REQUEST_PAYMENT,
  TRANSITION_EXPIRE_AUTOMATIC_WITH_PAYMENT: TRANSITION_EXPIRE_AUTOMATIC_WITH_PAYMENT,
  TRANSITION_EXPIRE_AUTOMATIC_WITHOUT_PAYMENT: TRANSITION_EXPIRE_AUTOMATIC_WITHOUT_PAYMENT,
  TRANSITION_APPLY_FEES_DEDUCTIONS_WITH_PAYMENT: TRANSITION_APPLY_FEES_DEDUCTIONS_WITH_PAYMENT,
  TRANSITION_APPLY_FEES_DEDUCTIONS_WITHOUT_PAYMENT: TRANSITION_APPLY_FEES_DEDUCTIONS_WITHOUT_PAYMENT,
  TRANSITION_EXPIRE_FEES_DEDUCTIONS_WITH_PAYMENT: TRANSITION_EXPIRE_FEES_DEDUCTIONS_WITH_PAYMENT,
  TRANSITION_EXPIRE_FEES_DEDUCTIONS_WITHOUT_PAYMENT: TRANSITION_EXPIRE_FEES_DEDUCTIONS_WITHOUT_PAYMENT,
  TRANSITION_REQUIRE_PAYMENT: TRANSITION_REQUIRE_PAYMENT,
  TRANSITION_REQUIRE_CREDIT_TRANSFER: TRANSITION_REQUIRE_CREDIT_TRANSFER,
  TRANSITION_OMIT_PAYMENT: TRANSITION_OMIT_PAYMENT,
  TRANSITION_TRANSFER_CREDIT: TRANSITION_TRANSFER_CREDIT,
  TRANSITION_ACCEPT_WITH_PAYMENT: TRANSITION_ACCEPT_WITH_PAYMENT,
  TRANSITION_ACCEPT_WITHOUT_PAYMENT: TRANSITION_ACCEPT_WITHOUT_PAYMENT,
  TRANSITION_CREATE_PAYMENT_INTENT: TRANSITION_CREATE_PAYMENT_INTENT,
  TRANSITION_DECLINE_WITH_PAYMENT: TRANSITION_DECLINE_WITH_PAYMENT,
  TRANSITION_DECLINE_WITHOUT_PAYMENT: TRANSITION_DECLINE_WITHOUT_PAYMENT,
  TRANSITION_REFUND_CREDIT: TRANSITION_REFUND_CREDIT,
  TRANSITION_ACCEPT: TRANSITION_ACCEPT,
  TRANSITION_CANCEL_AFTER_COMPLETION: TRANSITION_CANCEL_AFTER_COMPLETION,
  TRANSITION_CLOSE: TRANSITION_CLOSE,
  TRANSITION_COMPLETE: TRANSITION_COMPLETE,
  BOOKING_TRANSITIONS: BOOKING_TRANSITIONS,
  NOTIFICATION_TRANSITIONS: NOTIFICATION_TRANSITIONS,
  PAYMENT_TRANSITIONS: PAYMENT_TRANSITIONS,
  TRANSITIONS: TRANSITIONS,
  MESSAGING_ENABLED_TRANSITIONS: MESSAGING_ENABLED_TRANSITIONS,
  PENDING_CREDIT_REFUND_TRANSITIONS: PENDING_CREDIT_REFUND_TRANSITIONS,
  SCRUBBED_TRANSITIONS: SCRUBBED_TRANSITIONS,
  // Inbox state transition sets
  ACCEPTED_TRANSITIONS: ACCEPTED_TRANSITIONS,
  DECLINED_TRANSITIONS: DECLINED_TRANSITIONS,
  EXPIRED_TRANSITIONS: EXPIRED_TRANSITIONS,
  COMPLETED_TRANSITIONS: COMPLETED_TRANSITIONS,
  CANCELED_TRANSITIONS: CANCELED_TRANSITIONS,
  PROPOSED_TRANSITIONS: PROPOSED_TRANSITIONS,
  PENDING_TRANSITIONS: PENDING_TRANSITIONS,
  // States
  STATE_ACCEPTED: STATE_ACCEPTED,
  STATE_CANCELED: STATE_CANCELED,
  STATE_COMPLETED: STATE_COMPLETED,
  STATE_DECLINED: STATE_DECLINED,
  STATE_EXPIRED: STATE_EXPIRED,
  STATE_PENDING: STATE_PENDING,
  STATE_PROPOSED: STATE_PROPOSED,
  // Line item types
  LINE_ITEM_PAYMENT_FEE: LINE_ITEM_PAYMENT_FEE,
  LINE_ITEM_GIVSLY_CREDIT: LINE_ITEM_GIVSLY_CREDIT,
  LINE_ITEM_DONATION: LINE_ITEM_DONATION,
  LINE_ITEM_OPERATOR_FEE: LINE_ITEM_OPERATOR_FEE
};