/**
 * Check if users have subscription, NPOs don't need one.
 * @param {object} currentUser
 * @returns boolean
 */
export const validateSubscription = (currentUser) => {
  if (currentUser && typeof currentUser === 'object') {
    const { attributes } = currentUser;
    if (attributes && typeof attributes === 'object') {
      const { profile } = attributes;
      if (profile && typeof profile === 'object') {
        const { publicData } = profile;
        if (publicData && typeof publicData === 'object') {
          const { isNPO, profileType } = publicData;
          // return only if user is NPO, else check for subscription
          if (isNPO === true && profileType === 'nonprofit') {
            return true;
          }
        }

        const { metadata } = profile;
        if (metadata && typeof metadata === 'object') {
          const {
            cognitoTenantId,
            cognitoUsername,
            enableCustomOffers,
            operatorPercentage,
          } = metadata;

          const hasValidCognitoTenant =
            typeof cognitoTenantId === 'string' && cognitoTenantId.length > 1;
          const hasValidCognitoUsername =
            typeof cognitoUsername === 'string' && cognitoUsername.length > 1;
          const hasValidCustomOffers =
            typeof enableCustomOffers === 'boolean' && enableCustomOffers === true;
          const hasValidOperatorPercentage =
            typeof operatorPercentage === 'number' && operatorPercentage === 0;

          return (
            hasValidCognitoTenant &&
            hasValidCognitoUsername &&
            hasValidCustomOffers &&
            hasValidOperatorPercentage
          );
        }
      }
    }
  }

  return false;
};
