import React from 'react';
import { createGoogleCalendarUrl, createOutlookLiveUrl } from '../../util/calendar';
import { DOWNLOAD, DOWNLOAD_ALL, GOOGLE, OUTLOOK_LIVE } from './CalendarDownloadOptions';
import { propTypes } from '../../util/types';
import { func, node, string } from 'prop-types';
import classNames from 'classnames';
import css from './CalendarDownloadOptions.css';
import { Button } from '../index';

export const CalendarDownloadOptionsButtonICS = ({
  beforeDownload,
  className,
  children,
  ensuredCurrentUser,
  option,
  transactionId,
}) => {
  const openCalendarUrl = (calendarType) => {
    if (beforeDownload) {
      beforeDownload();
    }

    const { calendarToken } = ensuredCurrentUser.attributes.profile.privateData;
    const urlParts = ['https://', process.env.REACT_APP_GIVSLY_CALENDAR_HOST];

    if (transactionId) {
      urlParts.push(`/event?u=${ensuredCurrentUser.id.uuid}&tx=${transactionId}`);
    } else {
      urlParts.push(`/feed?u=${ensuredCurrentUser.id.uuid}`);
    }
    urlParts.push(`&ct=${calendarToken}`);

    if (calendarType === DOWNLOAD || calendarType === DOWNLOAD_ALL) {
      window.location = urlParts.join('');
    } else if (calendarType === GOOGLE) {
      urlParts.push('&format=json');
      createGoogleCalendarUrl(urlParts.join('')).then((googleUrl) => {
        if (googleUrl) {
          window.location = googleUrl;
        }
      });
    } else if (calendarType === OUTLOOK_LIVE) {
      urlParts.push('&format=json');
      createOutlookLiveUrl(urlParts.join('')).then((outlookUrl) => {
        if (outlookUrl) {
          window.location = outlookUrl;
        }
      });
    }
  };

  return (
    <Button
      className={className || classNames(css.button, css[option])}
      onClick={() => openCalendarUrl(option)}
      type="button"
    >
      {children}
    </Button>
  );
};

CalendarDownloadOptionsButtonICS.propTypes = {
  className: string,
  ensuredCurrentUser: propTypes.currentUser.isRequired,
  transactionId: string,
  beforeDownload: func,
  option: string.isRequired,
  children: node.isRequired,
};
