import React, { Component } from 'react';
import { node, string } from 'prop-types';
import classNames from 'classnames';
import css from './SectionBackground.css';

class SectionBackground extends Component {
  render() {
    const classes = classNames(css.section, this.props.className);
    const style = { backgroundImage: `url(${this.props.background})` };
    return (
      <div className={classes} style={style}>
        {this.props.children}
      </div>
    );
  }
}

SectionBackground.propTypes = {
  className: string,
  children: node,
  background: string,
};

export default SectionBackground;
