import { Button } from '../index';
import { OnBoardingBottomBarSubmit } from '../OnBoardingBottomBar/OnBoardingBottomBarSubmit';
import React from 'react';
import { bool, node, number } from 'prop-types';
import css from './ProfileSubmitButtonWrapper.css';

/**
 * If this is onboarding process, use sticky bottom bar with step number and button, otherwise basic button
 */

export const ProfileSubmitButtonWrapper = (props) => {
  const { onboardingCompleted, stepNumber, children, ...otherProps } = props;

  if (onboardingCompleted) {
    return (
      <Button type="submit" className={css.submitButton} {...otherProps}>
        {children}
      </Button>
    );
  }

  return (
    <OnBoardingBottomBarSubmit {...otherProps} stepNumber={stepNumber}>
      {children}
    </OnBoardingBottomBarSubmit>
  );
};

ProfileSubmitButtonWrapper.propTypes = {
  onboardingCompleted: bool,
  stepNumber: number,
  children: node.isRequired,
};

ProfileSubmitButtonWrapper.defaultProps = {
  onboardingCompleted: false,
};
