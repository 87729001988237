import React from 'react';
import css from './NotificationRow.css';
import { propTypes } from '../../util/types';
import { withMessages } from '../../util/localization';
import { func, string } from 'prop-types';
import { Avatar, RowControls } from '../index';
import { createResourceLocatorString } from '../../util/routes';
import routeConfiguration from '../../routeConfiguration';
import { withRouter } from 'react-router-dom';
import { ensureCurrentUser, ensureNotification, ensureUser } from '../../util/data';
import {
  CAPABILITY_ARCHIVE,
  hasCapability,
  humanReadableDate,
  isNewForCurrentUser,
  TYPE_ASK_FOR_TIME,
  TYPE_NEW_TIMES_AVAILABLE,
} from '../../util/notifications';
import classNames from 'classnames';

class NotificationRow extends React.Component {
  constructor(props) {
    super(props);

    this.handleClickDeleteConfirm = this.handleClickDeleteConfirm.bind(this);
    this.handleClickDeleteToggle = this.handleClickDeleteToggle.bind(this);
    this.handleClickNotification = this.handleClickNotification.bind(this);

    this.state = {
      hasOptionsOpen: false,
      isDeleted: false,
    };
  }

  handleClickDeleteToggle() {
    this.setState({
      hasOptionsOpen: !this.state.hasOptionsOpen,
    });
  }

  handleClickDeleteConfirm() {
    const { notification, onDelete } = this.props;
    this.setState({
      hasOptionsOpen: false,
      isDeleted: true,
    });
    return onDelete(notification);
  }

  handleClickNotification() {
    const { history, notification } = this.props;
    const ensuredNotification = ensureNotification(notification);
    history.push(
      createResourceLocatorString('NotificationsDetailPage', routeConfiguration(), {
        id: ensuredNotification.id.uuid,
      })
    );
  }

  get canRender() {
    const { notification } = this.props;
    const ensuredNotification = ensureNotification(notification);
    return (
      (this.isCustomer === false ||
        ensuredNotification.attributes.protectedData.type !== TYPE_NEW_TIMES_AVAILABLE) &&
      !this.state.isDeleted
    );
  }

  get isCustomer() {
    const { currentUser, notification } = this.props;
    const ensuredCurrentUser = ensureCurrentUser(currentUser);
    const ensuredNotification = ensureNotification(notification);
    return (
      ensuredNotification.customer.id &&
      ensuredCurrentUser.id &&
      ensuredNotification.customer.id.uuid === ensuredCurrentUser.id.uuid
    );
  }

  get message() {
    const { getMessage, notification } = this.props;
    const ensuredNotification = ensureNotification(notification);
    const { type } = ensuredNotification.attributes.protectedData;

    const isCustomer = this.isCustomer;
    const ensuredOtherUser = isCustomer
      ? ensureUser(ensuredNotification.provider)
      : ensureUser(ensuredNotification.customer);

    switch (type) {
      default:
        return 'Unknown notification';
      case TYPE_ASK_FOR_TIME:
        return isCustomer ? (
          <>
            {getMessage('requestForTimeCustomer', {
              providerName: (
                <strong key={`name-${ensuredOtherUser.id.uuid}`} className={css.name}>
                  {ensuredOtherUser.attributes.profile.publicData.firstName}{' '}
                  {ensuredOtherUser.attributes.profile.publicData.lastName}
                </strong>
              ),
            })}
          </>
        ) : (
          <>
            {getMessage('requestForTimeProvider', {
              customerName: (
                <strong key={`name-${ensuredOtherUser.id.uuid}`} className={css.name}>
                  {ensuredOtherUser.attributes.profile.publicData.firstName}{' '}
                  {ensuredOtherUser.attributes.profile.publicData.lastName}
                </strong>
              ),
            })}
          </>
        );
      case TYPE_NEW_TIMES_AVAILABLE:
        return (
          <>
            {getMessage('newTimesAdded', {
              providerName: (
                <strong key={`name-${ensuredOtherUser.id.uuid}`} className={css.name}>
                  {ensuredOtherUser.attributes.profile.publicData.firstName}{' '}
                  {ensuredOtherUser.attributes.profile.publicData.lastName}
                </strong>
              ),
            })}
          </>
        );
    }
  }

  render() {
    const { currentUser, notification, timezone } = this.props;
    const { hasOptionsOpen } = this.state;
    const ensuredNotification = ensureNotification(notification);
    const ensuredCurrentUser = ensureCurrentUser(currentUser);
    const ensuredOtherUser =
      ensuredNotification.customer.id &&
      ensuredNotification.customer.id.uuid === ensuredCurrentUser.id.uuid
        ? ensureUser(ensuredNotification.provider)
        : ensureUser(ensuredNotification.customer);

    const classes = classNames(
      css.root,
      isNewForCurrentUser(ensuredNotification, ensuredCurrentUser) ? css.new : null,
      hasOptionsOpen ? css.optionsOpen : null
    );

    const canBeDeleted = hasCapability(CAPABILITY_ARCHIVE, ensuredNotification, ensuredCurrentUser);

    return this.canRender ? (
      <div className={classes}>
        <Avatar rootClassName={css.profileImage} user={ensuredOtherUser} />
        <div className={css.messageContainer} onClick={this.handleClickNotification}>
          <span className={css.message}>{this.message}</span>
          <span className={css.dateTime}>
            {humanReadableDate(ensuredNotification.attributes.createdAt, timezone)}
          </span>
        </div>
        <RowControls
          className={css.rowControls}
          hasDeleteControl={canBeDeleted}
          onClickDeleteConfirm={this.handleClickDeleteConfirm}
          onClickDeleteToggle={this.handleClickDeleteToggle}
          onClickDeleteCancel={this.handleClickDeleteToggle}
        />
      </div>
    ) : null;
  }
}

NotificationRow.propTypes = {
  currentUser: propTypes.currentUser,
  getMessage: func.isRequired,
  notification: propTypes.notification.isRequired,
  onDelete: func.isRequired,
  timezone: string.isRequired,
};

export default withRouter(withMessages(NotificationRow, 'NotificationRow'));
