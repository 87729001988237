import React from 'react';
import css from './FeatureBlockWithListings.css';

const FeatureBlockWithListingsContent = ({ smallTitle, title, text, actions }) => (
  <div>
    <h2 className={css.featureBlockHeader}>{smallTitle}</h2>
    <h3 className={css.featureHeader}>{title}</h3>
    <p className={css.featureText}>{text} </p>
    <div className={css.featureActions}>{actions}</div>
  </div>
);

export default FeatureBlockWithListingsContent;
