import React from 'react';
import css from './NonprofitProfile.css';
import { propTypes } from '../../../util/types';
import { array, bool, func, number, object, oneOf, shape, string } from 'prop-types';
import {
  LISTING_PAGE_DRAFT_VARIANT,
  LISTING_PAGE_PENDING_APPROVAL_VARIANT,
} from '../../../util/urlHelpers';
import NonprofitHero from './nonprofit-profile-components/NonprofitHero';
import NonProfitTabAbout from './nonprofit-profile-components/NonProfitTabAbout';
import { ButtonTabNavHorizontal } from '../../../components';
import NonProfitTabBoardMembers from './nonprofit-profile-components/NonProfitTabBoardMembers';
import GoodAdvertisingPromoBanner from './GoodAdvertisingPromoBanner';

export const NONPROFIT_PROFILE_TAB_ABOUT = 'about';
export const NONPROFIT_PROFILE_TAB_BOARD_MEMBERS = 'board-members';

class NonprofitProfile extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      activeTab: props.tab || NONPROFIT_PROFILE_TAB_ABOUT,
    };
  }

  /**
   * Tabs are shown when board members are counted and load, or when the board members tab is active
   * @returns {boolean}
   */
  showTabs() {
    const { fetchBoardMemberTotalCountProgress, boardMemberTotalCount, tab } = this.props;
    return (
      (boardMemberTotalCount > 0 && !fetchBoardMemberTotalCountProgress) ||
      tab === NONPROFIT_PROFILE_TAB_BOARD_MEMBERS
    );
  }

  get tabContent() {
    const {
      boardMembers,
      currentListing,
      description,
      fetchBoardMemberTotalCountProgress,
      getEventRoleBadges,
      getListing,
      getTranslation,
      history,
      industries,
      interests,
      isOwnListing,
      location,
      npoListingNames,
      onActivateListing,
      onManageDisableScrolling,
      pagination,
      params,
      searchInProgress,
      searchListingsError,
      searchParams,
      supporterIds,
      timezone,
    } = this.props;

    switch (this.state.activeTab) {
      case NONPROFIT_PROFILE_TAB_ABOUT:
        return (
          <NonProfitTabAbout
            currentListing={currentListing}
            description={description}
            getListing={getListing}
            getTranslation={getTranslation}
            isOwnListing={isOwnListing}
            onManageDisableScrolling={onManageDisableScrolling}
            supporterIds={supporterIds}
          />
        );
      case NONPROFIT_PROFILE_TAB_BOARD_MEMBERS:
        return (
          <NonProfitTabBoardMembers
            boardMembers={boardMembers}
            fetchBoardMemberTotalCountProgress={fetchBoardMemberTotalCountProgress}
            getEventRoleBadges={getEventRoleBadges}
            history={history}
            interests={interests}
            industries={industries}
            location={location}
            npoListingNames={npoListingNames}
            onActivateListing={onActivateListing}
            onManageDisableScrolling={onManageDisableScrolling}
            pagination={pagination}
            searchInProgress={searchInProgress}
            searchListingsError={searchListingsError}
            searchParams={searchParams}
            pageName="ListingPageCanonical"
            pathParams={params}
            timezone={timezone}
          />
        );
      default:
        return null;
    }
  }

  get tabs() {
    const tabs = [NONPROFIT_PROFILE_TAB_ABOUT, NONPROFIT_PROFILE_TAB_BOARD_MEMBERS];

    return tabs.map((tab) => ({
      text: <span>{this.props.getTranslation(`nonprofit.tab.${tab}`)}</span>,
      onClick: () => this.switchTab(tab),
      selected: this.state.activeTab === tab,
      button: {
        className: css.tabNavButton,
        selectedClassName: css.tabNavButtonSelected,
      },
    }));
  }

  switchTab(tab) {
    // @todo add hashbang to store current active tab (auto-open)
    this.setState({
      activeTab: tab,
    });
  }

  render() {
    const { currentListing, ensuredAuthor, getTranslation, params, isOwnListing } = this.props;
    const { isApproved, approvePortfolioPresentation } = currentListing.attributes.publicData;
    return (
      <div className={css.nonprofitWrapper}>
        {isOwnListing && !approvePortfolioPresentation && <GoodAdvertisingPromoBanner />}
        <NonprofitHero
          getTranslation={getTranslation}
          isApproved={isApproved}
          listing={currentListing}
          params={params}
          user={ensuredAuthor}
          tabsVisible={this.showTabs()}
        />
        {this.showTabs() && (
          <div className={css.tabNavContainer}>
            <ButtonTabNavHorizontal className={css.tabNav} tabs={this.tabs} />
          </div>
        )}
        <div className={css.contentContainer}>{this.tabContent}</div>
      </div>
    );
  }
}

NonprofitProfile.propTypes = {
  currentListing: propTypes.listing,
  description: string.isRequired,
  ensuredAuthor: propTypes.user,
  getListing: func.isRequired,
  getTranslation: func.isRequired,
  history: shape({
    push: func.isRequired,
  }).isRequired,
  isOwnListing: bool.isRequired,
  onManageDisableScrolling: func.isRequired,
  params: shape({
    id: string.isRequired,
    slug: string,
    variant: oneOf([LISTING_PAGE_DRAFT_VARIANT, LISTING_PAGE_PENDING_APPROVAL_VARIANT]),
  }).isRequired,
  supporterIds: array,
  boardMembers: array,
  boardMemberTotalCount: number,
  npoListingNames: object,
  tab: string,
  pagination: propTypes.pagination,
  searchInProgress: bool.isRequired,
  searchListingsError: propTypes.error,
  searchParams: object,
  onActivateListing: func.isRequired,
  interests: array,
  industries: array,
  location: shape({
    search: string.isRequired,
  }).isRequired,
  timezone: string.isRequired,
};

NonprofitProfile.defaultProps = {
  supporterIds: [],
  boardMembers: [],
  boardMemberTotalCount: 0,
  npoListingNames: {},
  tab: NONPROFIT_PROFILE_TAB_ABOUT,
};

export default NonprofitProfile;
