const ENVIRONMENT_DEVELOPMENT = 'development';
const ENVIRONMENT_PRODUCTION = 'production';
const ENVIRONMENT_SANDBOX = 'sandbox';
const ENVIRONMENT_STAGING = 'staging';
const ENVIRONMENT_TEST = 'test';

export const MARKETPLACE_TEST = 'givsly-test';
export const MARKETPLACE_SANDBOX = 'givsly-sandbox';
export const MARKETPLACE_PRODUCTION = 'givsly';

export const environments = [
  ENVIRONMENT_DEVELOPMENT,
  ENVIRONMENT_PRODUCTION,
  ENVIRONMENT_SANDBOX,
  ENVIRONMENT_STAGING,
  ENVIRONMENT_TEST,
];

export const getEnvironmentName = () => {
  return process.env.REACT_APP_ENV;
};

export const isKnownEnvironment = () => {
  return environments.indexOf(getEnvironmentName()) >= 0;
};

export const getMarketplaceForEnvironment = () => {
  const environmentName = getEnvironmentName();
  switch (environmentName) {
    default:
    case ENVIRONMENT_DEVELOPMENT:
    case ENVIRONMENT_STAGING:
    case ENVIRONMENT_TEST:
      return MARKETPLACE_TEST;
    case ENVIRONMENT_SANDBOX:
      return MARKETPLACE_SANDBOX;
    case ENVIRONMENT_PRODUCTION:
      return MARKETPLACE_PRODUCTION;
  }
};

export const isTestEnvironment = () => {
  switch (getEnvironmentName()) {
    case ENVIRONMENT_DEVELOPMENT:
    case ENVIRONMENT_STAGING:
    case ENVIRONMENT_SANDBOX:
    case ENVIRONMENT_TEST:
      return true;
    default:
    case ENVIRONMENT_PRODUCTION:
      return false;
  }
};

export const isTestMarketplace = () => {
  return getMarketplaceForEnvironment() === MARKETPLACE_TEST;
};

export const isSandboxMarketplace = () => {
  return getMarketplaceForEnvironment() === MARKETPLACE_SANDBOX;
};
