import debounce from 'lodash/debounce';
import React, { useCallback } from 'react';
import { connect } from 'react-redux';
import { doSearch, onChangeSearchString } from '../../ducks/GivslySearch.duck';
import { getMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import FieldAutoCompleteInput from '../FieldAutoCompleteInput/FieldAutoCompleteInput';
import { getCompaniesFromResults } from '../GivslySearch/GivslySearch';

const SEARCH_COMPONENT_ID = 'companyautocomplete';
const DEBOUNCE_WAIT_TIME = 300;

export const FieldCompanyAutoCompleteInputComponent = ({
  companies,
  doSearch,
  onChange,
  ...props
}) => {
  const handleChange = useCallback(
    debounce(
      (searchString) => {
        onChange(searchString);
        doSearch(searchString);
      },
      DEBOUNCE_WAIT_TIME,
      { leading: false, trailing: true }
    ),
    [doSearch, onChange]
  );

  return <FieldAutoCompleteInput suggestions={companies} onInputChange={handleChange} {...props} />;
};

const mapStateToProps = (state) => {
  const { searchResults, searchStrings } = state.GivslySearch;

  const listings =
    searchResults[SEARCH_COMPONENT_ID] && searchResults[SEARCH_COMPONENT_ID].length
      ? getMarketplaceEntities(state, searchResults[SEARCH_COMPONENT_ID])
      : [];

  const searchString = searchStrings[SEARCH_COMPONENT_ID];

  return {
    companies: getCompaniesFromResults(listings, searchString),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onChange: (searchString) => dispatch(onChangeSearchString(SEARCH_COMPONENT_ID, searchString)),
    doSearch: (searchString) => dispatch(doSearch(SEARCH_COMPONENT_ID, searchString)),
  };
};

const FieldCompanyAutoCompleteInput = connect(
  mapStateToProps,
  mapDispatchToProps
)(FieldCompanyAutoCompleteInputComponent);

export default FieldCompanyAutoCompleteInput;
