import React from 'react';
import { PersonalInfoForm } from '../../forms/index';
import { PERSONAL_INFO } from '../OnboardingWizard/constants';
import { func, string } from 'prop-types';
import { propTypes } from '../../util/types';
import { bool, object } from 'prop-types';
import { ensureCurrentUser } from '../../util/data';
import css from './PersonalInfoPanel.css';
import * as moment from 'moment';
import { types as sdkTypes } from '../../util/sdkLoader';
import { flattenSdkBounds } from '../../util/location';
const { LatLng: SDKLatLng } = sdkTypes;

const onImageUploadHandler = (values, fn) => {
  const { id, imageId, file } = values;
  if (file) {
    fn({ id, imageId, file });
  }
};

class PersonalInfoPanel extends React.Component {
  componentDidMount() {
    if (this.props.trackEvent) {
      this.props.trackEvent({ action: 'OpenTab', label: PERSONAL_INFO });
    }
  }

  render() {
    const {
      currentUser,
      handleSubmit,
      profileImage,
      onImageUpload,
      updateInProgress,
      updateProfileError,
      uploadImageError,
      uploadInProgress,
    } = this.props;
    const user = ensureCurrentUser(currentUser);
    const { firstName, lastName, bio } = user.attributes.profile;
    const {
      location,
      linkedInUrl,
      timezone,
      geolocation,
      companyName,
      companyUrl,
      industry,
      jobTitle,
      source,
    } = user.attributes.profile.publicData;
    const profileImageId = user.profileImage ? user.profileImage.id : null;
    const image = profileImage || { imageId: profileImageId };

    const onSubmit = (values) => {
      // Pre-process and sanitize the user data before sending it back up
      const {
        firstName,
        lastName,
        bio: rawBio,
        linkedInUrl,
        timezone,
        companyName,
        companyUrl,
        industry,
        jobTitle,
        source,
      } = values;
      const { city, selectedPlace, state, country } = values.geolocation;

      const profile = {
        firstName: firstName.trim(),
        lastName: lastName.trim(),
        bio: rawBio || '',
        publicData: {
          firstName: firstName.trim(),
          lastName: lastName.trim(),
          linkedInUrl: linkedInUrl,
          timezone: timezone,
          companyName: companyName.trim(),
          companyNameFilter: companyName.trim(),
          companyUrl: companyUrl.trim(),
          industry,
          jobTitle: jobTitle.trim(),
          source: source ? source.trim() : null,
        },
      };

      // Only update the geolocation if it was changed
      if (city !== undefined && state !== undefined && country !== undefined) {
        profile.geolocation = selectedPlace.origin;
        profile.publicData.geolocation = {
          city,
          latitude: selectedPlace.origin.lat,
          longitude: selectedPlace.origin.lng,
          state,
          country,
          bounds: flattenSdkBounds(selectedPlace.bounds),
        };
      }

      const uploadedImage = image;

      // Update profileImage only if file system has been accessed
      const updatedValues =
        uploadedImage && uploadedImage.imageId && uploadedImage.file
          ? { ...profile, profileImageId: uploadedImage.imageId }
          : profile;

      handleSubmit(PERSONAL_INFO, updatedValues);
    };

    const address =
      geolocation.city && geolocation.state && geolocation.state.name
        ? `${geolocation.city}, ${geolocation.state.name}`
        : geolocation.city;

    return (
      <div className={css.root}>
        <PersonalInfoForm
          currentUser={currentUser}
          initialValues={{
            firstName,
            lastName,
            bio,
            geolocation: geolocation
              ? {
                  search: address,
                  selectedPlace: {
                    address: address,
                    origin: new SDKLatLng(geolocation.latitude, geolocation.longitude),
                  },
                }
              : {},
            profileImage: user.profileImage,
            location,
            linkedInUrl,
            timezone: timezone || moment.tz.guess(),
            companyName,
            companyUrl,
            industry,
            jobTitle,
            source,
          }}
          profileImage={image}
          onImageUpload={(e) => onImageUploadHandler(e, onImageUpload)}
          uploadInProgress={uploadInProgress}
          updateInProgress={updateInProgress}
          uploadImageError={uploadImageError}
          updateProfileError={updateProfileError}
          onSubmit={onSubmit}
        />
      </div>
    );
  }
}

PersonalInfoPanel.defaultProps = {
  uploadImageError: null,
  updateProfileError: null,
  updateProfileReady: false,
};

PersonalInfoPanel.propTypes = {
  pageName: string,
  profileImage: object,
  handleSubmit: func.isRequired,
  onImageUpload: func.isRequired,
  uploadImageError: propTypes.error,
  uploadInProgress: bool.isRequired,
  updateInProgress: bool.isRequired,
  updateProfileError: propTypes.error,
  updateProfileReady: bool,
  trackEvent: func,
};

export default PersonalInfoPanel;
