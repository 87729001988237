import React from 'react';
import classNames from 'classnames';
import css from './AvailabilityForm.css';
import { FormattedMessage } from '../../util/reactIntl';
import { TimeSlotFormContainer } from '../index';
import { func, string, array, bool, object, arrayOf } from 'prop-types';
import { propTypes } from '../../util/types';

export const AvailabilityFormModalTimeSlotForm = (props) => (
  <>
    <h2 className={classNames(css.modalHeader, css.modalHeaderTimeslotForm)}>
      <FormattedMessage
        id={props.inEditMode ? 'AvailabilityForm.editTimeSlot' : 'AvailabilityForm.addTimeSlot'}
      />
    </h2>
    <TimeSlotFormContainer {...props} />
  </>
);

AvailabilityFormModalTimeSlotForm.propTypes = {
  eventsOpenVolunteering: arrayOf(propTypes.event),
  exceedsWeeklyLimit: func.isRequired,
  inEditMode: bool.isRequired,
  isVisible: bool,
  onClose: func.isRequired,
  onFetchTimeSlots: func.isRequired,
  onSubmit: func.isRequired,
  originalDate: object,
  originalDuration: string.isRequired,
  reservableSlots: array,
  selectedDate: object,
  timezone: string.isRequired,
  updateError: propTypes.error,
  updateInProgress: bool,
  trackEvent: func,
};
