import React, { useState } from 'react';
import { Field } from 'react-final-form';
import css from './NonprofitImpactForm.css';
import { InfoIcon } from './Icons';

const InputFieldWithInfo = ({
  name,
  type,
  inputMode,
  additionalRightIcon,
  width,
  dataList = [],
  dataListId,
  grow = false,
  disabled = false,
  info = '',
  label = '',
  placeholder = '',
  infoLabel = '',
}) => {
  const styles = { width, minWidth: width };
  const [dataListVisible, setDataListVisible] = useState(false);

  if (grow) {
    styles.flex = 1;
  }

  return (
    <Field name={name} type={type}>
      {({ input }) => (
        <div className={css['icon-input-group']} style={styles}>
          {label && <label className={css['icon-input-label']}>{label}</label>}
          <div className={css['icon-input-wrapper']}>
            <input
              disabled={disabled}
              className={css['icon-input-input']}
              inputMode={inputMode}
              placeholder={placeholder}
              autoComplete="off"
              onInputCapture={() => setDataListVisible(true)}
              onDoubleClick={() => setDataListVisible(true)}
              onFocusCapture={() => setDataListVisible(true)}
              onKeyDown={(e) => {
                if (dataListVisible) {
                  switch (e.key) {
                    case 'ArrowDown': {
                      e.preventDefault();
                      e.target.nextSibling.children[0].focus();
                      break;
                    }
                    case 'Escape': {
                      e.preventDefault();
                      setDataListVisible(false);
                      break;
                    }
                    case 'Tab': {
                      setDataListVisible(false);
                    }
                  }
                }
              }}
              {...input}
            />
            {dataList.length > 0 && dataListId && dataListVisible && (
              <div
                className={css['data-list-container']}
                tabIndex={0}
                aria-label="Autocomplete options"
              >
                {dataList
                  .filter((value) => new RegExp(input.value).test(value))
                  .map((value, index, arr) => (
                    <div
                      role="option"
                      tabIndex={0}
                      key={value}
                      onClick={() => {
                        input.onChange(value);
                        setDataListVisible(false);
                      }}
                      onKeyDown={(e) => {
                        switch (e.key) {
                          case 'ArrowUp': {
                            e.preventDefault();
                            const nextIndex = index === 0 ? arr.length - 1 : index - 1;
                            e.target.parentElement.children[nextIndex].focus();
                            break;
                          }
                          case 'ArrowDown': {
                            e.preventDefault();
                            const nextIndex = index < arr.length - 1 ? index + 1 : 0;
                            e.target.parentElement.children[nextIndex].focus();
                            break;
                          }
                          case 'Enter': {
                            e.preventDefault();
                            input.onChange(value);
                            e.target.parentElement.previousSibling.focus();

                            setDataListVisible(false);
                            break;
                          }
                          case 'Escape': {
                            e.preventDefault();
                            e.target.blur();
                            setDataListVisible(false);
                            // close list
                            break;
                          }
                        }
                      }}
                    >
                      {value}
                    </div>
                  ))}
              </div>
            )}
            {additionalRightIcon && (
              <div className={css['icon-input-icon']}>{additionalRightIcon}</div>
            )}
            {info && (
              <>
                <div className={css['icon-input-icon']}>
                  <InfoIcon />
                </div>
                <div className={css['icon-input-tooltip']}>
                  {infoLabel && <h5 className={css['icon-input-tooltip-title']}>{infoLabel}</h5>}
                  {info}
                </div>
              </>
            )}
          </div>
        </div>
      )}
    </Field>
  );
};

export default InputFieldWithInfo;
