import React from 'react';
import { bool, node, number } from 'prop-types';
import css from './OnBoardingBottomBarSubmit.css';
import { BottomBarSticky } from '../BottomBarSticky/BottomBarSticky';
import { IconDone } from '../../components';
import { FormattedMessage } from 'react-intl';
import { Button } from '../index';

export const OnBoardingBottomBarSubmit = (props) => {
  const hideBottomBar = props.disabled && !props.inProgress && !props.ready;

  return (
    <BottomBarSticky className={css.container} disabled={hideBottomBar}>
      <span className={css.step}>
        <IconDone />
        <FormattedMessage
          id="OnBoardingBottomBarSubmit.step"
          values={{ stepNumber: props.stepNumber }}
        />
      </span>
      <Button
        className={css.submitButton}
        type="submit"
        inProgress={props.inProgress}
        ready={props.ready}
      >
        {props.children}
      </Button>
    </BottomBarSticky>
  );
};

OnBoardingBottomBarSubmit.propTypes = {
  stepNumber: number.isRequired,
  inProgress: bool,
  ready: bool,
  disabled: bool,
  children: node,
};
