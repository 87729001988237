import React from 'react';
import { string } from 'prop-types';
import classNames from 'classnames';
import css from './IconExternalLink.css';

const IconExternalLink = (props) => {
  const className = classNames(css.icon, props.className);
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 14 14" className={className}>
      <path d="M4.5 3.5V5H2.25v6.5h6.5V9.25h1.5V13H.75V3.5H4.5zM12.75 1v5.75h-1.5V3.56L6 8.81 4.94 7.75l5.249-5.25H7V1h5.75z" />
    </svg>
  );
};

IconExternalLink.propTypes = {
  className: string,
};

export default IconExternalLink;
