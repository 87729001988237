import React from 'react';
import { useIntl } from 'react-intl';
import { formatMoney } from '../../util/currency';
import { humanizeLineItemCode } from '../../util/data';
import { LINE_ITEM_DONATION, propTypes } from '../../util/types';
import classNames from 'classnames';

import css from './BookingBreakdown.css';

const LineItemDonation = (props) => {
  const { transaction } = props;
  const intl = useIntl();

  const lineItems = transaction.attributes.lineItems || [];
  const items = lineItems.filter((item) => item.code === LINE_ITEM_DONATION && !item.reversal);

  return items.length > 0 ? (
    <>
      {items.map((item, i) => {
        const label = humanizeLineItemCode(item.code);
        const formattedTotal = formatMoney(intl, item.lineTotal);
        return (
          <div key={`${i}-item.code`} className={classNames(css.lineItem, css.lineItemRegular)}>
            <span className={css.itemLabel}>{label}</span>
            <span className={css.itemValue}>{formattedTotal}</span>
          </div>
        );
      })}
    </>
  ) : null;
};

LineItemDonation.propTypes = {
  transaction: propTypes.transaction.isRequired,
};

export default LineItemDonation;
