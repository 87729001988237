import React from 'react';
import { FormattedMessage, intlShape, injectIntl } from '../../util/reactIntl';
import classNames from 'classnames';
import { bool, object, string } from 'prop-types';
import { formatMoney } from '../../util/currency';
import config from '../../config';
import moment from 'moment-timezone/builds/moment-timezone-with-data-10-year-range.min';
import { types as sdkTypes } from '../../util/sdkLoader';

import css from './ReservableSlot.css';
import { calculateQuantityFromHours } from '../../util/dates';
import { propTypes } from '../../util/types';
import { IconLock } from '../../components';
import { Tooltip } from '../../components/Tooltip/Tooltip';

const { Money } = sdkTypes;

const priceData = (price, intl) => {
  if (price && price.currency === config.currency) {
    const formattedPrice = formatMoney(intl, price);
    return { formattedPrice, priceTitle: formattedPrice };
  } else if (price) {
    return {
      formattedPrice: intl.formatMessage(
        { id: 'ListingCard.unsupportedPrice' },
        { currency: price.currency }
      ),
      priceTitle: intl.formatMessage(
        { id: 'ListingCard.unsupportedPriceTitle' },
        { currency: price.currency }
      ),
    };
  }
  return {};
};

const MethodsComponent = (props) => {
  const {
    intl,
    methods,
    methodPriceChoices,
    onMethodSelect,
    selectedMethod,
    quantity,
    reservableSlotId,
  } = props;

  const isMethodSelected = (method) => selectedMethod && method && selectedMethod === method;

  const getFormattedPriceForMethod = (method) => {
    if (
      !method ||
      !methodPriceChoices ||
      methodPriceChoices[method] === undefined ||
      config.custom.fixedMethodPrices[method][methodPriceChoices[method]] === undefined
    ) {
      return '';
    }

    const methodPrice = config.custom.fixedMethodPrices[method][methodPriceChoices[method]];

    const { formattedPrice } = priceData(
      new Money(methodPrice.donation * quantity, methodPrice.currency),
      intl
    );

    return formattedPrice;
  };

  const methodChoices = methods.map((method) => {
    const methodId = reservableSlotId + method;

    return (
      <div key={methodId} className={css.methodContainer}>
        {methods.length === 1 ? (
          ''
        ) : (
          <input
            className={css.methodInput}
            type={'radio'}
            id={methodId}
            value={method}
            checked={isMethodSelected(method) ? 'checked' : ''}
            onChange={() => onMethodSelect(method)}
            onClick={(e) => e.stopPropagation()}
          />
        )}
        <label className={css.methodLabel} htmlFor={methodId}>
          <FormattedMessage id={`MeetingMethod.${method}`} className={css.methodText} />
        </label>
        <span className={css.methodPrice}>{getFormattedPriceForMethod(method)}</span>
      </div>
    );
  });

  return (
    <div>
      <hr className={css.separator} />
      <h5 className={css.chooseMethod}>
        {methods.length === 1 ? (
          <FormattedMessage id="ReservableSlot.meetingType" />
        ) : (
          <FormattedMessage id="ReservableSlot.chooseMeetingType" />
        )}
      </h5>
      {methodChoices}
    </div>
  );
};

const tooltipIcon = (
  <span>
    <IconLock className={css.iconLock} />
  </span>
);

export const ReservableSlotComponent = (props) => {
  const {
    event,
    id,
    intl,
    isAccessCodeRequired,
    methodPriceChoices,
    onMethodSelect,
    onReservableSlotSelect,
    reservableSlot,
    rootClassName,
    selectedMethod,
    selectedReservableSlot,
    timezone,
  } = props;

  const startDate = moment.tz(reservableSlot.start, reservableSlot.timezone);
  const convertedStartDate = startDate.clone().tz(timezone);
  const endDate = moment.tz(reservableSlot.end, reservableSlot.timezone);
  const quantity = calculateQuantityFromHours(startDate, endDate);

  const difference = endDate.diff(startDate);
  const duration = moment.duration(difference);

  const eventAccessCodeInfo = !!event && (event.eventAccessCodeInfo || '');

  const isReservableSlotSelected =
    selectedReservableSlot &&
    selectedReservableSlot.start === reservableSlot.start &&
    selectedReservableSlot.end === reservableSlot.end;

  const classes = classNames(rootClassName || css.root, {
    [css.selected]: isReservableSlotSelected,
    [css.eventSlot]: !!event,
  });

  return (
    <div key={id} className={classes} onClick={() => onReservableSlotSelect(reservableSlot)}>
      <div className={css.heading}>
        <time className={css.time} dateTime={convertedStartDate.toString()}>
          {convertedStartDate.format('hh:mm a (z)')}
        </time>
        <span className={css.duration}>
          {isAccessCodeRequired && eventAccessCodeInfo && (
            <Tooltip
              content={<p className={css.tooltipText}>{eventAccessCodeInfo}</p>}
              icon={tooltipIcon}
            ></Tooltip>
          )}
          {duration.asMinutes()} <FormattedMessage id="ReservableSlot.minutes" />
        </span>
      </div>
      {isReservableSlotSelected ? (
        <MethodsComponent
          intl={intl}
          methods={reservableSlot.methods}
          methodPriceChoices={methodPriceChoices}
          onMethodSelect={onMethodSelect}
          selectedMethod={selectedMethod}
          quantity={quantity}
          reservableSlotId={id}
        />
      ) : (
        ''
      )}
    </div>
  );
};

ReservableSlotComponent.defaultProps = {
  isEventSlot: false,
  isAccessCodeRequired: false,
};

ReservableSlotComponent.propTypes = {
  currentUser: propTypes.currentUser,
  event: propTypes.event,
  id: string,
  intl: intlShape.isRequired,
  isEventSlot: bool,
  isAccessCodeRequired: bool,
  reservableSlot: object.isRequired,
  methodPriceChoices: object.isRequired,
  timezone: string.isRequired,
};

export default injectIntl(ReservableSlotComponent);
