import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import { ACCOUNT_SETTINGS_PAGES } from '../../routeConfiguration';
import { LinkTabNavHorizontal } from '../../components';

import css from './UserNav.css';
import { ensureCurrentUser } from '../../util/data';
import { PERSONAL_INFO } from '../MyProfileWizard/MyProfileWizard';
import { NONPROFIT_INFO } from '../MyNonprofitWizard/constants';
import { PARTNERSHIP_TABS } from '../../containers/PartnershipsPage/constants';

const UserNav = (props) => {
  const { className, currentUser, rootClassName, selectedPageName } = props;
  const classes = classNames(rootClassName || css.root, className);
  const user = ensureCurrentUser(currentUser);
  const onboardingCompleted =
    user.attributes.profile.privateData.onboardingCompleted ||
    user.attributes.profile.publicData.onboardingCompleted;
  const { isNPO } = user.attributes.profile.publicData;

  const tabs = [];

  if (onboardingCompleted) {
    if (!isNPO) {
      tabs.push({
        text: <FormattedMessage id="UserNav.myProfile" />,
        selected: selectedPageName === 'MyProfilePage',
        disabled: false,
        linkProps: {
          name: 'MyProfilePage',
          params: {
            tab: PERSONAL_INFO,
          },
        },
      });
    } else {
      tabs.push({
        text: <FormattedMessage id="UserNav.myNonprofit" />,
        selected: selectedPageName === 'MyNonprofitPage',
        linkProps: {
          name: 'MyNonprofitPage',
          params: {
            tab: NONPROFIT_INFO,
          },
        },
      });
      tabs.push({
        text: <FormattedMessage id="UserNav.partnerships" />,
        selected: selectedPageName === 'PartnershipsPage',
        linkProps: {
          name: 'PartnershipsPage',
          params: {
            tab: PARTNERSHIP_TABS.APPROVAL,
          },
        },
      });
    }
  }
  tabs.push({
    text: <FormattedMessage id="UserNav.accountSettings" />,
    selected: ACCOUNT_SETTINGS_PAGES.includes(selectedPageName),
    disabled: false,
    linkProps: {
      name: 'ContactDetailsPage',
    },
  });

  return (
    <LinkTabNavHorizontal className={classes} tabRootClassName={css.tab} tabs={tabs} skin="dark" />
  );
};

UserNav.defaultProps = {
  className: null,
  currentUser: null,
  rootClassName: null,
};

const { string } = PropTypes;

UserNav.propTypes = {
  className: string,
  rootClassName: string,
  selectedPageName: string.isRequired,
};

export default UserNav;
