import { Button, FieldRadioButton, Form } from '../../components';
import { RECURS_NEVER } from '../../util/dates';
import css from './AvailabilityForm.css';
import { Form as FinalForm } from 'react-final-form';
import React from 'react';
import { compose } from 'redux';
import { injectIntl } from 'react-intl';
import { func, object, string } from 'prop-types';

const AvailabilityFormModalConfirmDeleteComponent = ({
  intl,
  onCancel,
  onSubmit,
  selectedDate,
  selectedRecurrence,
}) => (
  <FinalForm
    initialValues={{
      deleteFutureSiblings: 'no',
    }}
    onSubmit={(e) => {
      e.preventDefault();
    }}
    render={(fieldRenderProps) => {
      const { values } = fieldRenderProps;

      return (
        <div className={css.modalPadding}>
          <Form
            onSubmit={(e) => {
              e.preventDefault();
              onSubmit(values);
            }}
          >
            <h2 className={css.modalHeader}>
              {intl.formatMessage({
                id: `AvailabilityForm.confirmDelete.${
                  selectedRecurrence === RECURS_NEVER ? 'regular' : 'recurring'
                }.header`,
              })}
            </h2>
            <p className={css.modalContent}>
              {selectedDate.format('dddd, D MMMM HH:mm a')} ({selectedDate.zoneAbbr()})
            </p>
            {selectedRecurrence !== RECURS_NEVER ? (
              <>
                <FieldRadioButton
                  id="deleteFutureSiblingsNo"
                  label={intl.formatMessage({
                    id: 'AvailabilityForm.confirmDelete.recurring.singleSlotOnly',
                  })}
                  name="deleteFutureSiblings"
                  onClick={({ target }) => (target.disabled = true)}
                  value="no"
                />
                <FieldRadioButton
                  id="deleteFutureSiblingsYes"
                  label={intl.formatMessage({
                    id: 'AvailabilityForm.confirmDelete.recurring.deleteFutureSiblings',
                  })}
                  name="deleteFutureSiblings"
                  value="yes"
                />
              </>
            ) : null}
            <Button className={css.buttonConfirm} type="submit">
              {intl.formatMessage({ id: 'AvailabilityForm.delete' })}
            </Button>
            <Button className={css.buttonCancel} onClick={onCancel} type="button">
              {intl.formatMessage({ id: 'AvailabilityForm.cancel' })}
            </Button>
          </Form>
        </div>
      );
    }}
  />
);

AvailabilityFormModalConfirmDeleteComponent.propTypes = {
  onCancel: func.isRequired,
  onSubmit: func.isRequired,
  selectedDate: object, // Moment date
  selectedRecurrence: string,
};

export const AvailabilityFormModalConfirmDelete = compose(injectIntl)(
  AvailabilityFormModalConfirmDeleteComponent
);
