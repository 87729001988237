module.exports = {
  // The fee with which the gross donation value is multiplied (15%). Per cent.
  operatorFeeMultiplier: 0.15,
  // Apply a cap fee for the operator of 750.00 USD (unit in cents)
  operatorFeeCap: 75000,
  // The fee with which the amount which is payable by credit card is multiplied (2.9866%). Per cent.
  // so that when stripe deducts it's 0.29% we get the original amount
  paymentFeeMultiplier: 1 / (1 - 0.029),
  // ~1.029866
  // The amount which is always added on top of the payment fee multiplier ($0.30). In whole cents.
  paymentFeeAdditionalCharge: 30,
  // Transaction currency
  currency: "USD",
  // Booking specific configuration
  booking: {
    // The minimal time that should be between now and the booking date/time
    bookingOffset: 6,
    // After how much time a booking expires (in hours)
    expiresAfter: 72,
    // Configuration values for booking reminders
    reminders: {
      // First reminder
      first: {
        // When the reminder should be sent
        hoursAfterLastTransition: 24,
        // How many hours there must be at least until the booking will occur
        bookableOffset: 6
      },
      // Second reminder
      second: {
        // When the reminder should be sent
        hoursAfterLastTransition: 48,
        // How many hours there must be at least until the booking will occur
        bookableOffset: 6
      }
    }
  },
  // Proposal specific configurations
  proposal: {
    // The minimal time that should be between now and the booking date/time
    bookingOffset: 48,
    // After how much time a booking expires (in hours)
    expiresAfter: 72,
    // How much time before the latest suggested time the proposal expires (in hours)
    expiresHoursPriorToLatestSuggestedTime: 6,
    // Configuration values for proposal reminders
    reminders: {
      // First reminder
      first: {
        hoursAfterLastTransition: 24
      },
      // Second reminder
      second: {
        hoursPriorToLatestSuggestedTime: 18,
        hoursAfterLastTransition: 48
      }
    },
    // The minimum amount of time that a time slot needs to be in the future in order to propose it
    timeSlotCreationOffset: 48,
    // The minimum amount of time between the current time and a bookable time slot. After this is reach the time slot
    // should no longer be bookable.
    timeSlotBookableOffset: 6
  }
};