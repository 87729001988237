import { storableError } from '../util/errors';
import { types as sdkTypes } from '../util/sdkLoader';
import sharetribe from '@givsly/sharetribe-utils';
const { UUID } = sdkTypes;

// Actions types
const QUERY_TIME_SLOTS_REQUEST = 'app/reservableSlot/QUERY_TIME_SLOTS_REQUEST';
const QUERY_TIME_SLOTS_SUCCESS = 'app/reservableSlot/QUERY_TIME_SLOTS_SUCCESS';
const QUERY_TIME_SLOTS_FAILURE = 'app/reservableSlot/QUERY_TIME_SLOTS_FAILURE';

// Initial state
const initialState = {
  end: null,
  listingId: null,
  queryTimeSlotsError: null,
  queryTimeSlotsInProgress: false,
  start: null,
  timeSlots: [],
};

// Reducer
export default function reducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case QUERY_TIME_SLOTS_REQUEST:
      return {
        ...state,
        end: payload.end,
        listingId: payload.listingId,
        queryTimeSlotsError: null,
        queryTimeSlotsInProgress: true,
        start: payload.start,
      };
    case QUERY_TIME_SLOTS_SUCCESS:
      return {
        ...state,
        queryTimeSlotsInProgress: false,
        timeSlots: payload.timeSlots,
      };
    case QUERY_TIME_SLOTS_FAILURE:
      return {
        ...state,
        queryTimeSlotsError: payload.err,
        queryTimeSlotsInProgress: false,
      };
    default:
      return state;
  }
}

// Action creators
const queryTimeSlotsRequest = (listingId, start, end) => ({
  type: QUERY_TIME_SLOTS_REQUEST,
  payload: {
    end,
    listingId,
    start,
  },
});
const queryTimeSlotsSuccess = (timeSlots) => ({
  type: QUERY_TIME_SLOTS_SUCCESS,
  payload: {
    timeSlots,
  },
});
const queryTimeSlotsFailure = (err) => ({
  type: QUERY_TIME_SLOTS_FAILURE,
  payload: {
    err,
  },
});

// Thunks
/**
 * Queries time slots in a given timeframe
 *
 * @param   {String}  listingId
 * @param   {Date}    start
 * @param   {Date}    end
 * @returns {function(*, *, *): Array}
 */
export const queryTimeSlots = (listingId, start, end) => (dispatch, getState, sdk) => {
  dispatch(queryTimeSlotsRequest(listingId, start, end));

  return sdk.timeslots
    .query({
      listingId: new UUID(listingId),
      start,
      end,
    })
    .then((sdkResponse) => {
      const timeSlots = sdkResponse.data.data.map((ts) => sharetribe.timeSlot.ensureTimeSlot(ts));
      dispatch(queryTimeSlotsSuccess(timeSlots));
      return timeSlots;
    })
    .catch((err) => {
      dispatch(queryTimeSlotsFailure(storableError(err)));
      return Promise.reject(err);
    });
};
