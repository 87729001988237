import React from 'react';
import { propTypes } from '../../util/types';
import { FormattedMessage } from 'react-intl';
import css from './BookingBreakdown.css';

class FullRefundMaybe extends React.Component {
  render() {
    const { lastTransition } = this.props.transaction.attributes;
    return lastTransition.includes('transition/cancel-') ? (
      <div className={css.notice}>
        <FormattedMessage id="BookingBreakdown.fullRefund" />
      </div>
    ) : null;
  }
}

FullRefundMaybe.propTypes = {
  transaction: propTypes.transaction.isRequired,
};

FullRefundMaybe.defaultProps = {
  transaction: null,
};

export default FullRefundMaybe;
