import React from 'react';
import css from './FeaturedNonprofits.css';
import { array, bool, func, shape, string } from 'prop-types';
import { Button, NonprofitFeatureCard } from '../index';
import { ensureListing } from '../../util/data';
import { createResourceLocatorString } from '../../util/routes';
import routeConfiguration from '../../routeConfiguration';
import classNames from 'classnames';

class FeaturedNonprofits extends React.Component {
  constructor(props) {
    super(props);
    this.redirectToSearch = this.redirectToSearch.bind(this);
  }

  redirectToSearch() {
    const { history, isFeaturedCategory, title, trackEvent, trackingCategory } = this.props;

    if (trackEvent && trackingCategory) {
      trackEvent({
        category: trackingCategory,
        action: 'ClickBrowseAll',
        label: 'FeaturedNonprofits',
      });
    }

    history.push(
      createResourceLocatorString(
        'NonprofitSearchPage',
        routeConfiguration(),
        {},
        isFeaturedCategory ? { pub_organizationCategory: title } : {}
      )
    );
  }

  render() {
    const {
      browseAllLabel,
      browseAllMobileLabel,
      cardClassName,
      className,
      history,
      name,
      nonprofits,
      title,
      trackEvent,
      trackingCategory,
    } = this.props;
    return (
      <div className={classNames(css.root, className)}>
        <div className={css.header}>
          <h2 className={css.headerText}>{title}</h2>
          <Button className={css.headerBrowse} onClick={this.redirectToSearch}>
            {browseAllLabel}
          </Button>
        </div>
        <div className={css.cards}>
          {nonprofits.map((nonprofit) => {
            const ensuredListing = ensureListing(nonprofit);
            return ensuredListing.id ? (
              <NonprofitFeatureCard
                className={classNames(css.card, cardClassName)}
                history={history}
                key={`${name}-${ensuredListing.id.uuid}`}
                listing={ensuredListing}
                trackEvent={trackEvent}
                trackingCategory={trackingCategory}
              />
            ) : null;
          })}
        </div>
        <Button className={css.footerBrowse} onClick={this.redirectToSearch}>
          {browseAllMobileLabel}
        </Button>
      </div>
    );
  }
}

FeaturedNonprofits.propTypes = {
  browseAllLabel: string,
  browseAllMobileLabel: string,
  cardClassName: string,
  className: string,
  history: shape({
    push: func.isRequired,
  }).isRequired,
  isFeaturedCategory: bool,
  name: string.isRequired,
  nonprofits: array,
  title: string,
  trackEvent: func,
  trackingCategory: string,
};

FeaturedNonprofits.defaultProps = {
  className: null,
  browseAllLabel: 'Browse all →',
  browseAllMobileLabel: 'Browse all',
  isFeaturedCategory: false,
  nonprofits: [],
  trackEvent: null,
  trackingCategory: null,
};

export default FeaturedNonprofits;
